import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { EmployeeRepository } from "@entity/data/repositories/employee.repository";
import { EmployeesCount } from "@entity/domain/models/employee/employee.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetEmployeesCountByTypeUseCase {
    constructor(
        @inject(EmployeeRepository)
        private readonly employeeRepository: EmployeeRepository,
    ) {}

    async execute(): Promise<Nullable<EmployeesCount>> {
        const typeCountResult = await this.employeeRepository.getCountByType();

        if (typeCountResult.isLeft()) {
            ToastManagerStore.error(typeCountResult.getLeftOrThrow().message);
        }

        return typeCountResult.fold(
            () => new EmployeesCount(0, 0, 0),
            (count) => count,
        );
    }
}
