import { CountryEnum } from "@core/domain/models/country.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { TitularityTypeEnum } from "@entity/domain/models/titularity-type.model";

export class Address {
    // eslint-disable-next-line max-params
    constructor(
        public id: number,
        public name: string,
        public streetName: string,
        public zipCode: string,
        public town: string,
        public province: string,
        public entity: number,
        public streetNumber: string,
        public country: CountryEnum,
        public titularityType: TitularityTypeEnum,
        public region?: Nullable<string>,
        public floor?: Nullable<string>,
        public door?: Nullable<string>,
        public stair?: Nullable<string>,
        public cadastralValue?: Nullable<string>,
        public local?: Nullable<boolean>,
        public principalAddress?: Nullable<boolean>,
    ) {}
}

export class Addresses {
    constructor(
        public readonly addresses: Address[],
        public readonly count: number,
    ) {}
}
