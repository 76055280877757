import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    FinancialEntityType,
    FinancialEntityTypeEnum,
} from "../../../domain/models/financial-entity/financial-entity-type";
import { FinancialEntityTypeDto } from "../../dto/financial-entity/financial-entity-type.dto";
import { FinancialEntityTypeEnumMapper } from "./financial-entity-type-enum.mapper";

@injectable()
export class FinancialEntityTypeMapper {
    constructor(
        @inject(FinancialEntityTypeEnumMapper)
        private readonly financialEntityTypeEnumMapper: FinancialEntityTypeEnumMapper,
    ) {}

    map(
        financialEntityTypeDto: FinancialEntityTypeDto,
    ): Nullable<FinancialEntityType> {
        const errors = validateSync(financialEntityTypeDto);
        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<FinancialEntityTypeEnum> =
            this.financialEntityTypeEnumMapper.map(financialEntityTypeDto.key);

        if (!id) return null;

        return new FinancialEntityType(id, financialEntityTypeDto.value);
    }
}
