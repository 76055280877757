import { Nullable } from "@core/domain/types/nullable.type";
import { PositionTypeEnumDto } from "@entity/data/dto/position-type.dto";
import { PositionTypeEnum } from "@entity/domain/models/position-type.model";
import { injectable } from "inversify";

@injectable()
export class PositionTypeEnumMapper {
    map(positionTypeEnumDto: PositionTypeEnumDto): Nullable<PositionTypeEnum> {
        switch (positionTypeEnumDto) {
            case PositionTypeEnumDto.president:
                return PositionTypeEnum.PRESIDENT;
            case PositionTypeEnumDto.vicepresident:
                return PositionTypeEnum.VICEPRESIDENT;
            case PositionTypeEnumDto.secretary:
                return PositionTypeEnum.SECRETARY;
            case PositionTypeEnumDto.treasurer:
                return PositionTypeEnum.TREASURER;
            case PositionTypeEnumDto.vocal:
                return PositionTypeEnum.VOCAL;
            case PositionTypeEnumDto.other:
                return PositionTypeEnum.OTHER;
            default:
                return null;
        }
    }

    mapToDto(positionTypeEnum: PositionTypeEnum): PositionTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (positionTypeEnum) {
            case PositionTypeEnum.PRESIDENT:
                return PositionTypeEnumDto.president;
            case PositionTypeEnum.VICEPRESIDENT:
                return PositionTypeEnumDto.vicepresident;
            case PositionTypeEnum.SECRETARY:
                return PositionTypeEnumDto.secretary;
            case PositionTypeEnum.TREASURER:
                return PositionTypeEnumDto.treasurer;
            case PositionTypeEnum.VOCAL:
                return PositionTypeEnumDto.vocal;
            case PositionTypeEnum.OTHER:
                return PositionTypeEnumDto.other;
        }
    }
}
