import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { IdentificationDocumentTypeEnumMapper } from "@core/data/mappers/identification-document-type-enum.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import { VolunteerInfoMapper } from "@entity/data/mappers/employee/volunteer-info.mapper";
import { Employee } from "@entity/domain/models/employee/employee.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { EmployeeDto } from "../../dto/employee/employee.dto";
import { EmployeeTypeEnumMapper } from "./employee-type-enum.mapper";

@injectable()
export class EmployeeMapper {
    constructor(
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
        @inject(EmployeeTypeEnumMapper)
        private readonly employeeTypeEnumMapper: EmployeeTypeEnumMapper,
        @inject(IdentificationDocumentTypeEnumMapper)
        private readonly identificationDocumentTypeEnumMapper: IdentificationDocumentTypeEnumMapper,
        @inject(VolunteerInfoMapper)
        private readonly volunteerInfoMapper: VolunteerInfoMapper,
    ) {}
    map(employeeDto: EmployeeDto): Nullable<Employee> {
        const errors = validateSync(employeeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const typeId = this.employeeTypeEnumMapper.map(employeeDto.type);
        if (!typeId) return null;
        const identificationTypeId =
            this.identificationDocumentTypeEnumMapper.map(
                employeeDto.identification_type,
            );
        if (!identificationTypeId) return null;

        const employee = new Employee(
            employeeDto.id,
            employeeDto.name ?? "",
            employeeDto.surname,
            employeeDto.identification,
            identificationTypeId,
            employeeDto.display_identification_type,
            typeId,
            employeeDto.display_type,
            employeeDto.entity,
        );

        if (employeeDto.has_active_contract) {
            employee.isActiveContract = employeeDto.has_active_contract;
        }
        if (employeeDto.is_active) {
            employee.isActive = employeeDto.is_active;
        }
        if (employeeDto.percentage_ocupation) {
            employee.percentageOcupation = Number(
                employeeDto.percentage_ocupation,
            );
        }
        if (employeeDto.availability_hours) {
            employee.availabilityHours = Number(employeeDto.availability_hours);
        }
        if (employeeDto.contracted_hours) {
            employee.contractedHours = employeeDto.contracted_hours;
        }
        if (employeeDto.code_verification) {
            employee.codeVerification = employeeDto.code_verification;
        }
        if (employeeDto.documents_data) {
            employee.documents = employeeDto.documents_data.mapNotNull(
                (document) => this.documentMapper.map(document),
            );
        }

        if (employeeDto.volunteer_info) {
            employee.volunteerInfo = this.volunteerInfoMapper.map(
                employeeDto.volunteer_info,
            );
        }

        return employee;
    }
}
