const colors = require("tailwindcss/colors");
const defaultTheme = require("tailwindcss/defaultTheme");

/** @type {import('tailwindcss').Config} */
module.exports = {
    corePlugins: {
        preflight: false, // https://stackoverflow.com/questions/71715157/tailwinds-ant-design-button-color-is-white-but-has-own-color-wnen-i-hover-it
    },
    content: ["./src/**/*.{js,jsx,ts,tsx}"],

    theme: {
        extend: {
            fontFamily: {
                sans: [
                    "Space Grotesk",
                    "Poppins",
                    ...defaultTheme.fontFamily.sans,
                ],
            },
            colors: {
                "grey-dark": {
                    50: "#F6F6F6",
                    100: "#E7E7E7", 
                    200: "#D1D1D1",
                    300: "#B0B0B0", 
                    500: "#6D6D6D",  
                    600: "#565756",  // official color palette: #5D5D5D
                    700: "#05B2CE", 
                    800: "#454545", 
                    900: "#3D3D3D",  
                    950: "#161616",  // base color for palette
                },
                "cyan-dark": "#00566D", 
                "cyan-strong": "#00A6A6", 
                cyan: {
                    50: "#ECFEFF",
                    100: "#CFFAFE", 
                    200: "#A5F4FC",
                    300: "#55F7FF", // official color palette: #66E9FA
                    500: "#05B2CE",  // base color for palette
                    600: "#0792B3", 
                    700: "#0D7591", 
                    800: "#155F75", 
                    900: "#164E63",  
                    950: "#083344",  
                },
                "grey": {
                    50: "#F7F7F7",
                    100: "#F2F2F2",  // official color palette: #E7E7E7
                    200: "#DBDBDB", // official color palette: #DFDFDF
                    300: "#C8C8C8", // base color for palette
                    500: "#999999",  
                    600: "#888888",  
                    700: "#7B7B7B", 
                    800: "#676767", 
                    900: "#545454",  
                    950: "#363636",  
                },
                white: colors.white, 
                "coral-red":"#FF6767",
                "kanban": {
                    "todo": "#db2777",
                    "inprogress": "#544be6",
                    "intesting": "#dc2626",
                    "done": "#16a34a",
                },
            },
        },
    },
    plugins: [],
    // We use the Tailwind classes prefix via tw-, so mt-4 would be tw-mt-4
    prefix: "tw-",
};