import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum SupplierTypeEnumDto {
    Subcontracting = "subcontracting",
    BeneficiariesCosts = "beneficiaries_costs",
}

export class SupplierTypeDto {
    @IsEnum(SupplierTypeEnumDto)
    @IsNotEmpty()
    key!: SupplierTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
