import { MaritalStatusDto } from "@beneficiary/data/dto/marital-status.dto";
import { MaritalStatus } from "@beneficiary/domain/models/marital-status.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { MaritalStatusEnumMapper } from "./marital-status-enum.mapper";

@injectable()
export class MaritalStatusMapper {
    constructor(
        @inject(MaritalStatusEnumMapper)
        private readonly maritalStatusEnumMapper: MaritalStatusEnumMapper,
    ) {}

    map(maritalStatusDto: MaritalStatusDto): Nullable<MaritalStatus> {
        const errors = validateSync(MaritalStatusDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.maritalStatusEnumMapper.map(maritalStatusDto.key);
        if (!id) return null;

        return new MaritalStatus(id, maritalStatusDto.value);
    }
}
