import { GendersRepository } from "@beneficiary/data/repositories/genders.repository";
import { Gender } from "@beneficiary/domain/models/gender.model";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllGendersUseCase {
    constructor(
        @inject(GendersRepository)
        private readonly genderRepository: GendersRepository,
    ) {}

    async execute(): Promise<Gender[]> {
        const gendersResult = await this.genderRepository.getAll();

        if (gendersResult.isLeft()) {
            ToastManagerStore.error(gendersResult.getLeftOrThrow().message);
        }

        return gendersResult.fold(
            () => [],
            (genders) => genders,
        );
    }
}
