import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { CreateTechnicalProposalBody } from "@project/data/dto/technical-proposal/create-technical-proposal.body";
import { EditTechnicalProposalBody } from "@project/data/dto/technical-proposal/edit-technical-proposal.body";
import { TechnicalProposalDto } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { ObjectiveMapper } from "@project/data/mappers/technical-proposal/objective.mapper";
import {
    CreateTechnicalProposal,
    EditTechnicalProposal,
    Objective,
    TechnicalProposal,
} from "@project/domain/models/technical-proposals.model";
import { TechnicalProposalFormValuesValidated } from "@project/presentation/components/project-form/technical-proposal/technical-proposal-form.component";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class TechnicalProposalMapper {
    constructor(
        @inject(DocumentMapper) private readonly documentMapper: DocumentMapper,
        @inject(ObjectiveMapper)
        private readonly objectiveMapper: ObjectiveMapper,
    ) {}

    map(
        technicalProposalDto: TechnicalProposalDto,
    ): Nullable<TechnicalProposal> {
        const errors = validateSync(technicalProposalDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        let objectives: Objective[] = [];
        if (technicalProposalDto.objetive_data) {
            objectives = technicalProposalDto.objetive_data.mapNotNull(
                (objetiveDto) => this.objectiveMapper.map(objetiveDto),
            );
        }

        let documents: IncDocument[] = [];
        if (technicalProposalDto.documents_data) {
            documents = technicalProposalDto.documents_data.mapNotNull(
                (document) => this.documentMapper.map(document),
            );
        }

        return new TechnicalProposal(
            technicalProposalDto.id,
            technicalProposalDto.project,
            technicalProposalDto.overall_objetive,
            objectives,
            documents,
        );
    }

    mapFromFormToCreate(
        technicalProposalForm: TechnicalProposalFormValuesValidated,
    ): CreateTechnicalProposal {
        if (!technicalProposalForm.projectId)
            throw new Error("Project id is required");

        return new CreateTechnicalProposal(
            technicalProposalForm.overallObjective,
            technicalProposalForm.projectId,
            technicalProposalForm.documents,
        );
    }

    mapFromFormToEdit(
        technicalProposalForm: TechnicalProposalFormValuesValidated,
    ): EditTechnicalProposal {
        if (!technicalProposalForm.id)
            throw new Error("Technical proposal id is required");
        if (!technicalProposalForm.projectId)
            throw new Error("Project id is required");

        return new EditTechnicalProposal(
            technicalProposalForm.id,
            technicalProposalForm.projectId,
            technicalProposalForm.overallObjective,
            technicalProposalForm.documents,
        );
    }

    mapToCreateDto(
        createTechnicalProposal: CreateTechnicalProposal,
    ): CreateTechnicalProposalBody {
        return {
            overall_objetive: createTechnicalProposal.overallObjective,
            project: createTechnicalProposal.projectId,
            documents: convertEmptyToUndefined(
                createTechnicalProposal.documents?.mapNotNull(
                    (document) => document.id,
                ),
            ),
        };
    }

    mapToEditDto(
        editTechnicalProposal: EditTechnicalProposal,
    ): EditTechnicalProposalBody {
        return {
            id: editTechnicalProposal.id,
            overall_objetive: editTechnicalProposal.overallObjective,
            project: editTechnicalProposal.projectId,
            documents: convertEmptyToUndefined(
                editTechnicalProposal.documents?.mapNotNull(
                    (document) => document.id,
                ),
            ),
        };
    }
}
