import { Nullable } from "@core/domain/types/nullable.type";
import { DisabilityTypeDto } from "@entity/data/dto/employee/disability-type.dto";
import { DisabilityTypeEnumMapper } from "@entity/data/mappers/employee/disability-type-enum.mapper";
import {
    DisabilityType,
    DisabilityTypeEnum,
} from "@entity/domain/models/employee/disability-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class DisabilityTypeMapper {
    constructor(
        @inject(DisabilityTypeEnumMapper)
        private disabilityTypeEnumMapper: DisabilityTypeEnumMapper,
    ) {}

    map(
        disabilityTypeEnumMapperDto: DisabilityTypeDto,
    ): Nullable<DisabilityType> {
        const errors = validateSync(disabilityTypeEnumMapperDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<DisabilityTypeEnum> =
            this.disabilityTypeEnumMapper.map(disabilityTypeEnumMapperDto.key);

        if (!id) return null;

        return new DisabilityType(id, disabilityTypeEnumMapperDto.value);
    }
}
