import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { ExpenseTypeEnum } from "../../domain/models/economic-data/expense/expense-type.model";
import { ExpenseTypeEnumDto } from "../dto/expense-type.dto";

@injectable()
export class ExpenseTypeEnumMapper {
    map(relationshipIdDto: ExpenseTypeEnumDto): Nullable<ExpenseTypeEnum> {
        switch (relationshipIdDto) {
            case ExpenseTypeEnumDto.Housing:
                return ExpenseTypeEnum.Housing;
            case ExpenseTypeEnumDto.Supplies:
                return ExpenseTypeEnum.Supplies;
            case ExpenseTypeEnumDto.Transport:
                return ExpenseTypeEnum.Transport;
            case ExpenseTypeEnumDto.Food:
                return ExpenseTypeEnum.Food;
            case ExpenseTypeEnumDto.SchoolStudies:
                return ExpenseTypeEnum.SchoolStudies;
            case ExpenseTypeEnumDto.Debts:
                return ExpenseTypeEnum.Debts;
            case ExpenseTypeEnumDto.Other:
                return ExpenseTypeEnum.Other;

            default:
                return null;
        }
    }
    mapToDto(relationshipId: ExpenseTypeEnum): ExpenseTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (relationshipId) {
            case ExpenseTypeEnum.Housing:
                return ExpenseTypeEnumDto.Housing;
            case ExpenseTypeEnum.Supplies:
                return ExpenseTypeEnumDto.Supplies;
            case ExpenseTypeEnum.Transport:
                return ExpenseTypeEnumDto.Transport;
            case ExpenseTypeEnum.Food:
                return ExpenseTypeEnumDto.Food;
            case ExpenseTypeEnum.SchoolStudies:
                return ExpenseTypeEnumDto.SchoolStudies;
            case ExpenseTypeEnum.Debts:
                return ExpenseTypeEnumDto.Debts;
            case ExpenseTypeEnum.Other:
                return ExpenseTypeEnumDto.Other;
        }
    }
}
