import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { DepositRepository } from "@entity/data/repositories/deposit.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteDepositUseCase {
    constructor(
        @inject(DepositRepository)
        private readonly depositRepository: DepositRepository,
    ) {}

    async execute(costId: number): Promise<boolean> {
        const result = await this.depositRepository.delete(costId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
