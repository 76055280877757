import { DocumentRepository } from "@core/data/repositories/document.repository";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class GetDocumentByIdUseCase {
    constructor(
        @inject(DocumentRepository)
        private readonly documentRepository: DocumentRepository,
    ) {}

    async execute(id: number): Promise<Nullable<IncDocument>> {
        const documentResult = await this.documentRepository.getById(id);

        if (documentResult.isLeft()) {
            ToastManagerStore.error(documentResult.getLeftOrThrow().message);

            return null;
        }

        return documentResult.getOrThrow();
    }
}
