export enum TitularityTypeEnum {
    TitularityOwned = "titularity_owned",
    TitularityRenting = "titularity_renting",
    TitularityTransfered = "titularity_transfered",
}

export class TitularityType {
    constructor(
        public readonly id: TitularityTypeEnum,
        public readonly label: string,
    ) {}
}
