import { Nullable } from "@core/domain/types/nullable.type";
import { ActionLineDto } from "@entity/data/dto/action-line-enum.dto";
import { ActionLineEnumMapper } from "@entity/data/mappers/action-line-enum.mapper";
import {
    ActionLine,
    ActionLineEnum,
} from "@entity/domain/models/action-line.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ActionLinesMapper {
    constructor(
        @inject(ActionLineEnumMapper)
        private readonly actionLineEnumMapper: ActionLineEnumMapper,
    ) {}

    map(actionLinesDto: ActionLineDto): Nullable<ActionLine> {
        const errors = validateSync(actionLinesDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<ActionLineEnum> = this.actionLineEnumMapper.map(
            actionLinesDto.key,
        );

        if (!id) return null;

        return new ActionLine(id, actionLinesDto.value);
    }
}
