import { DistrictDatasource } from "@beneficiary/data/datasource/district.datasource";
import { District } from "@beneficiary/domain/models/district.model";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";

@injectable()
export class DistrictsRepository {
    constructor(
        @inject(DistrictDatasource)
        private readonly districtDatasource: DistrictDatasource,
    ) {}

    async getAll(
        pagination: Pagination,
    ): Promise<Either<FallbackError, District[]>> {
        const districtResult =
            await this.districtDatasource.fetchAll(pagination);

        return districtResult
            .mapLeft(() => new FallbackError())
            .map((district) => district);
    }
}
