import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EditSupplierMapper } from "@entity/data/mappers/supplier/edit-supplier.mapper";
import { Supplier } from "@entity/domain/models/supplier/supplier.model";
import { EditSupplierUseCase } from "@entity/domain/usecases/supplier/edit-supplier.usecase";
import { GetSupplierByIdUseCase } from "@entity/domain/usecases/supplier/get-all-supplier-by-id.usecase";
import { SupplierFormValuesValidated } from "@entity/presentation/component/supplier-form/supplier-form.component";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class EditSupplierPageViewModel extends BaseViewModel {
    supplierId: Undefinable<number>;

    @observable
    supplier: Nullable<Supplier> = null;

    @observable
    initiallyLoading: boolean = false;

    constructor(
        @inject(EditSupplierUseCase)
        private readonly editSupplierUseCase: EditSupplierUseCase,
        @inject(EditSupplierMapper)
        private readonly editSupplierMapper: EditSupplierMapper,
        @inject(GetSupplierByIdUseCase)
        private readonly getSupplierByIdUseCase: GetSupplierByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getSupplier()]);

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    @action
    setSupplier(supplier: Supplier): void {
        this.supplier = supplier;
    }

    @action
    setSupplierId(supplierId: number): void {
        this.supplierId = supplierId;
    }

    async getSupplier(): Promise<void> {
        if (this.supplierId === undefined) throw Error("Missing supplierId");

        const supplier = await this.getSupplierByIdUseCase.execute(
            this.supplierId,
        );

        if (supplier) {
            this.setSupplier(supplier);
        }
    }

    async editSupplier(
        values: SupplierFormValuesValidated,
        entityId: number,
    ): Promise<Nullable<Supplier>> {
        if (!this.supplierId) throw Error("Missing supplierId");

        LoadLayoutStore.start();

        const editSupplierResult = await this.editSupplierUseCase.execute(
            this.editSupplierMapper.mapFromSupplierFormValues(
                values,
                entityId,
                this.supplierId,
            ),
        );

        if (editSupplierResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return editSupplierResult;
    }
}
