import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { AddBudgetFromTemplateFormValidated } from "@project/presentation/components/financial-entity-form/add-budget-from-template-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreateBudgetFromTemplateUseCase {
    constructor(
        @inject(FinancialEntityRepository)
        private readonly financialEntityRepository: FinancialEntityRepository,
    ) {}

    async execute(
        budgetType: AddBudgetFromTemplateFormValidated,
        entityId: number,
    ): Promise<Nullable<boolean>> {
        const creationResult =
            await this.financialEntityRepository.createBudgetFromTemplate(
                budgetType,
                entityId,
            );

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdBudgetType) => createdBudgetType,
        );
    }
}
