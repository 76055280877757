import type { Nullable } from "@core/domain/types/nullable.type";
import { ProjectMaterial } from "@project/domain/models/project-material/project-material.model";
import { DateTime } from "luxon";

export class CreateProjectMaterial {
    constructor(
        public projectId: ProjectMaterial["projectId"],
        public materialId: ProjectMaterial["materialId"],
        public hoursDedicated?: ProjectMaterial["hoursDedicated"],
        public documents?: Nullable<ProjectMaterial["documents"]>,
        public startDate?: Nullable<DateTime>,
        public endDate?: Nullable<DateTime>,
    ) {}
}
