import { DocumentDto } from "@core/data/dto/document.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { ActionLineEnumDto } from "@entity/data/dto/action-line-enum.dto";
import { AddressDto } from "@entity/data/dto/address/address.dto";
import { CollectiveEnumDto } from "@entity/data/dto/collectives.dto";
import { CrosscuttingScopeEnumDto } from "@entity/data/dto/crosscutting-scope-enum.dto";
import { TypologyEnumDto } from "@entity/data/dto/typologies.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEnum,
    IsInt,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { CCAAEnumDto } from "./ccaa-enum.dto";
import { ContactDto } from "./contact.dto";

export class EntityDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    company_name!: string;

    @IsString()
    @IsOptional()
    identification!: Nullable<string>;

    @IsEnum(TypologyEnumDto)
    @IsOptional()
    legal_typology!: Nullable<TypologyEnumDto>;

    @IsString()
    @IsOptional()
    legal_typology_label!: Nullable<string>;

    @IsString()
    @IsOptional()
    other_legal_typology!: Nullable<string>;

    @IsNotEmpty()
    @IsArray()
    @Type(() => AddressDto)
    address_data!: AddressDto[];

    @IsNotEmpty()
    @IsArray()
    @Type(() => ContactDto)
    contacts_data!: ContactDto[];

    @IsString()
    @IsOptional()
    category_display_name!: Nullable<string>;

    @IsEnum(CollectiveEnumDto)
    @IsOptional()
    priority_collective!: Nullable<CollectiveEnumDto>;

    @IsString()
    @IsOptional()
    other_collectives!: Nullable<string>;

    @IsString()
    @IsOptional()
    cnae_code!: Nullable<string>;

    @IsBoolean()
    @IsNotEmpty()
    is_active!: boolean;

    @IsEnum(ActionLineEnumDto, { each: true })
    @IsOptional()
    action_lines!: Nullable<ActionLineEnumDto[]>;

    @IsString()
    @IsOptional()
    other_action_line!: Nullable<string>;

    @IsString()
    @IsOptional()
    action_lines_description!: Nullable<string>;

    @IsEnum(CollectiveEnumDto, { each: true })
    @IsOptional()
    secondary_collectives!: Nullable<CollectiveEnumDto[]>;

    @IsEnum(CrosscuttingScopeEnumDto, { each: true })
    @IsOptional()
    crosscutting_entity_scopes!: Nullable<CrosscuttingScopeEnumDto[]>;

    @IsString()
    @IsOptional()
    other_crosscutting_entity_scope!: Nullable<string>;

    @IsArray()
    @IsNumber(undefined, { each: true })
    @IsOptional()
    documents!: Nullable<number[]>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    documents_data!: Nullable<DocumentDto[]>;

    @IsArray()
    @IsOptional()
    @Type(() => AddressDto)
    address!: Nullable<AddressDto[]>;

    @IsArray()
    @IsOptional()
    @Type(() => ContactDto)
    contacts!: Nullable<ContactDto[]>;

    @IsString()
    @IsOptional()
    entity_purpose!: Nullable<string>;

    @IsArray()
    @IsString({ each: true })
    @IsNotEmpty()
    ccaa_of_action!: CCAAEnumDto[];

    @IsString()
    @IsOptional()
    web_address!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    legal_constitution_date!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    public_interest!: Nullable<boolean>;

    @IsBoolean()
    @IsOptional()
    equality_plan!: Nullable<boolean>;

    @IsDateString()
    @IsOptional()
    equality_plan_expiration_date!: Nullable<string>;
}
