export enum AssociateServiceToDependenceEnum {
    Rehab = "rehab",
    HomeHelp = "home_help",
    ServiceCheck = "service_check",
    Residence = "residence",
    DayCenter = "day_center",
    Telecare = "telecare",
    Empty = "",
}

export class AssociateServiceToDependence {
    constructor(
        public readonly id: AssociateServiceToDependenceEnum,
        public readonly label: string,
    ) {}
}
