import { CreateVolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/create-volunteer-contract.model";
import { VolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/volunteer-contract.model";

export class EditVolunteerContract extends CreateVolunteerContract {
    // eslint-disable-next-line max-params
    constructor(
        public readonly id: VolunteerContract["id"],
        insurance: VolunteerContract["insurance"],
        activity: VolunteerContract["activity"],
        educationLevel: VolunteerContract["educationLevel"],
        availability: VolunteerContract["availability"],
        preferences: VolunteerContract["preferences"],
        weeklyWorkdayHours: VolunteerContract["weeklyWorkdayHours"],
        startDate: VolunteerContract["startDate"],
        employeeId: VolunteerContract["employeeId"],
        startDateInsurance?: VolunteerContract["startDateInsurance"],
        otherPreferences?: VolunteerContract["otherPreferences"],
        door?: VolunteerContract["door"],
        city?: VolunteerContract["city"],
        endDate?: VolunteerContract["endDate"],
        endDateInsurance?: VolunteerContract["endDateInsurance"],
    ) {
        super(
            insurance,
            activity,
            educationLevel,
            availability,
            preferences,
            weeklyWorkdayHours,
            startDate,
            employeeId,
            startDateInsurance,
            otherPreferences,
            door,
            city,
            endDate,
            endDateInsurance,
        );
    }
}
