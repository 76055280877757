import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import {
    ActivitiesSummaryDto,
    ActivitiesSummaryQuery,
} from "@project/data/dto/activity-summary.dto";
import { ActivitiesSummaryMapper } from "@project/data/mappers/activities-summary.mapper";
import { ActivitiesSummary } from "@project/domain/models/activity-summary.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import {
    Activity,
    CreateActivity,
    EditActivity,
} from "../../domain/models/technical-proposals.model";
import {
    ActivityQuery,
    ActivitySearchFilters,
    TechnicalProposalActivitiesDto,
    TechnicalProposalActivityDto,
} from "../dto/technical-proposal/technical-proposal.dto";
import { ActivityMapper } from "../mappers/technical-proposal/activity.mapper";

const PROJECT_ACTIVITIES_PATH = "/technical_proposals_activities/";

@injectable()
export class ProjectActivityDataSource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(ActivityMapper)
        private readonly activityMapper: ActivityMapper,
        @inject(ActivitiesSummaryMapper)
        private readonly activitiesSummaryMapper: ActivitiesSummaryMapper,
    ) {}

    async editKanban(
        activity: Activity,
    ): Promise<Either<FallbackError | ValidationError, Activity>> {
        const editProjectKanbanBody =
            this.activityMapper.mapToEditProjectActivityDto(activity);

        const editProjectActivitytResult =
            await this.http.patch<TechnicalProposalActivityDto>(
                `${PROJECT_ACTIVITIES_PATH}${activity.id}/`,
                editProjectKanbanBody,
            );

        return editProjectActivitytResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const projectActivity = this.activityMapper.map(
                    plainToClass(TechnicalProposalActivityDto, response.data),
                );

                if (!projectActivity) return Either.Left(new FallbackError());

                return Either.Right(projectActivity);
            });
    }

    async edit(
        activity: EditActivity,
    ): Promise<Either<ValidationError | FallbackError, Activity>> {
        const activityBody = this.activityMapper.mapToEditDto(activity);

        const editProjectActivitytResult =
            await this.http.patch<TechnicalProposalActivityDto>(
                `${PROJECT_ACTIVITIES_PATH}${activity.id}/`,
                activityBody,
            );

        return editProjectActivitytResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const projectActivity = this.activityMapper.map(
                    plainToClass(TechnicalProposalActivityDto, response.data),
                );

                if (!projectActivity) return Either.Left(new FallbackError());

                return Either.Right(projectActivity);
            });
    }

    async create(
        activity: CreateActivity,
    ): Promise<Either<ValidationError | FallbackError, Activity>> {
        const activityBody = this.activityMapper.mapToCreateDto(activity);

        const createProjectActivitytResult =
            await this.http.post<TechnicalProposalActivityDto>(
                PROJECT_ACTIVITIES_PATH,
                activityBody,
            );

        return createProjectActivitytResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const projectActivity = this.activityMapper.map(
                    plainToClass(TechnicalProposalActivityDto, response.data),
                );

                if (!projectActivity) return Either.Left(new FallbackError());

                return Either.Right(projectActivity);
            });
    }

    async delete(activityId: number): Promise<Either<FallbackError, true>> {
        const deleteResult = await this.http.delete(
            `${PROJECT_ACTIVITIES_PATH}${activityId}/`,
        );

        return deleteResult.mapLeft(() => new FallbackError()).map(() => true);
    }

    async fetchBy(
        pagination: Pagination,
        filters: ActivitySearchFilters,
    ): Promise<Either<FallbackError, Activity[]>> {
        const query: ActivityQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        const { project, objetive } = filters;

        if (project) query.project = project;
        if (objetive) query.objetive = objetive;

        const responseResult =
            await this.http.get<TechnicalProposalActivitiesDto>(
                PROJECT_ACTIVITIES_PATH,
                {
                    query,
                },
            );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.results.mapNotNull(
                    (technicalProposalActivityDto) =>
                        this.activityMapper.map(
                            plainToClass(
                                TechnicalProposalActivityDto,
                                technicalProposalActivityDto,
                            ),
                        ),
                ),
            );
    }

    async fetchAllActivitiesSummary(
        pagination: Pagination,
        projectId: number,
    ): Promise<Either<FallbackError, ActivitiesSummary>> {
        const query: ActivitiesSummaryQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (projectId) query.project = projectId;

        const responseResult = await this.http.get<ActivitiesSummaryDto>(
            `/technical_proposals_activities/`,
            {
                query,
            },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.activitiesSummaryMapper.map(
                    plainToClass(ActivitiesSummaryDto, response.data),
                ),
            );
    }
}
