import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CreateRegisteredRecordBody } from "@entity/data/dto/registered-record/create-registered-record.body";
import { EditRegisteredRecordBody } from "@entity/data/dto/registered-record/edit-registered-record.body";
import {
    RegisteredRecordDto,
    RegisteredRecordsDto,
} from "@entity/data/dto/registered-record/registered-record.dto";
import { CreateRegisteredRecordMapper } from "@entity/data/mappers/registered-record/create-registered-record.mapper";
import { EditRegisteredRecordMapper } from "@entity/data/mappers/registered-record/edit-registered-record.mapper";
import { RegisteredRecordsMapper } from "@entity/data/mappers/registered-record/registered-records.mapper";
import { CreateRegisteredRecord } from "@entity/domain/models/registered-record/create-registered-record.model";
import { EditRegisteredRecord } from "@entity/domain/models/registered-record/edit-registered-record.model";
import {
    RegisteredRecord,
    RegisteredRecords,
} from "@entity/domain/models/registered-record/registered-record.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const ENTITY_REGISTERED_RECORDS_PATH = "/entities_registered_records/";

@injectable()
export class RegisteredRecordDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(RegisteredRecordsMapper)
        private readonly registeredRecordsMapper: RegisteredRecordsMapper,
        @inject(CreateRegisteredRecordMapper)
        private readonly createRegisteredRecordMapper: CreateRegisteredRecordMapper,
        @inject(EditRegisteredRecordMapper)
        private readonly editRegisteredRecordMapper: EditRegisteredRecordMapper,
    ) {}

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, RegisteredRecords>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const registeredRecordsResult =
            await this.http.get<RegisteredRecordDto>(
                ENTITY_REGISTERED_RECORDS_PATH,
                {
                    query,
                },
            );

        return registeredRecordsResult.map((response) =>
            this.registeredRecordsMapper.map(
                plainToClass(RegisteredRecordsDto, response.data),
            ),
        );
    }

    async create(
        newRegisteredRecord: CreateRegisteredRecord,
    ): Promise<Either<ValidationError | FallbackError, RegisteredRecord>> {
        const registeredRecordBody =
            this.createRegisteredRecordMapper.mapToCreateDto(
                newRegisteredRecord,
            );

        const registeredRecordResult =
            await this.http.post<CreateRegisteredRecordBody>(
                ENTITY_REGISTERED_RECORDS_PATH,
                registeredRecordBody,
            );

        return registeredRecordResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const registeredRecord =
                    this.registeredRecordsMapper.mapRegisteredRecord(
                        plainToClass(RegisteredRecordDto, response.data),
                    );

                if (!registeredRecord) return Either.Left(new FallbackError());

                return Either.Right(registeredRecord);
            });
    }

    async edit(
        editRegisteredRecord: EditRegisteredRecord,
    ): Promise<Either<ValidationError | FallbackError, RegisteredRecord>> {
        const editedRegisteredRecord =
            this.editRegisteredRecordMapper.mapToDto(editRegisteredRecord);

        const editRegisteredRecordResult = await this.http.patch<
            RegisteredRecordDto,
            EditRegisteredRecordBody
        >(
            `${ENTITY_REGISTERED_RECORDS_PATH}${editRegisteredRecord.id}/`,
            editedRegisteredRecord,
        );

        return editRegisteredRecordResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const registeredRecord =
                    this.registeredRecordsMapper.mapRegisteredRecord(
                        plainToClass(RegisteredRecordDto, response.data),
                    );

                if (!registeredRecord) return Either.Left(new FallbackError());

                return Either.Right(registeredRecord);
            });
    }

    async delete(
        registeredRecordId: number,
    ): Promise<Either<FallbackError, true>> {
        const deleteRegisteredRecord = await this.http.delete(
            `${ENTITY_REGISTERED_RECORDS_PATH}${registeredRecordId}`,
        );

        return deleteRegisteredRecord
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
