import { Employee } from "@entity/domain/models/employee/employee.model";

export class CreateEmployee {
    constructor(
        public identification: Employee["identification"],
        public identificationType: Employee["identificationType"],
        public name: Employee["name"],
        public surname: Employee["surname"],
        public type: Employee["type"],
        public entity: Employee["entity"],
        public volunteerInfo?: Employee["volunteerInfo"],
    ) {}
}
