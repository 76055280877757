import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { inject, injectable } from "inversify";

@injectable()
export class LoadCurrentUserUseCase {
    constructor(
        @inject(UserSessionRepository)
        private readonly userSessionRepository: UserSessionRepository,
    ) {}
    async execute(): Promise<boolean> {
        const loadCurrentUserResult =
            await this.userSessionRepository.loadCurrentUser();

        return loadCurrentUserResult.isRight();
    }
}
