import { SuppliersDto } from "@entity/data/dto/supplier/suppliers.dto";
import { SupplierMapper } from "@entity/data/mappers/supplier/supplier.mapper";
import { Suppliers } from "@entity/domain/models/supplier/suppliers.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class SuppliersMapper {
    constructor(
        @inject(SupplierMapper)
        private readonly supplierMapper: SupplierMapper,
    ) {}

    map(suppliersDto: SuppliersDto): Suppliers {
        const errors = validateSync(suppliersDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Suppliers([], 0);
        }

        const suppliers = suppliersDto.results.mapNotNull((supplierDto) =>
            this.supplierMapper.map(supplierDto),
        );

        return new Suppliers(suppliers, suppliersDto.count);
    }
}
