import { Pagination } from "@core/domain/models/pagination";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { TechnicalProposalRepository } from "@project/data/repositories/technical-proposal/technical-proposal.repository";
import {
    TechnicalProposal,
    TechnicalProposalsFilters,
} from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllTechnicalProposalsByUseCase {
    constructor(
        @inject(TechnicalProposalRepository)
        private readonly technicalProposalRepository: TechnicalProposalRepository,
    ) {}

    async execute(
        filters?: TechnicalProposalsFilters,
    ): Promise<TechnicalProposal[]> {
        const pagination = Pagination.NoPagination();

        const technicalProposalResult =
            await this.technicalProposalRepository.getAllBy(
                pagination,
                filters,
            );

        if (technicalProposalResult.isLeft()) {
            ToastManagerStore.error(
                technicalProposalResult.getLeftOrThrow().message,
            );
        }

        return technicalProposalResult.fold(
            () => [],
            (technicalProposals) => technicalProposals.technicalProposals,
        );
    }
}
