import { ExpenseRecurrenceDto } from "@beneficiary/data/dto/expense-recurrence.dto";
import { ExpenseRecurrenceEnumMapper } from "@beneficiary/data/mappers/expense-recurrence-enum.mapper";
import { ExpenseRecurrence } from "@beneficiary/domain/models/economic-data/expense/expense-recurrence.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ExpenseRecurrenceMapper {
    constructor(
        @inject(ExpenseRecurrenceEnumMapper)
        private readonly expenseRecurrenceEnumMapper: ExpenseRecurrenceEnumMapper,
    ) {}
    map(
        expenseRecurrenceDto: ExpenseRecurrenceDto,
    ): Nullable<ExpenseRecurrence> {
        const errors = validateSync(expenseRecurrenceDto);
        if (errors.length) {
            console.error(errors);
            return null;
        }
        const id = this.expenseRecurrenceEnumMapper.map(
            expenseRecurrenceDto.key,
        );
        if (!id) return null;
        return new ExpenseRecurrence(id, expenseRecurrenceDto.value);
    }
}
