import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { CostRepository } from "@entity/data/repositories/cost.repository";
import { inject, injectable } from "inversify";
import { Cost } from "../../models/cost/cost.model";
import { EditCost } from "../../models/cost/edit-cost.model";

@injectable()
export class UpdateCostUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(editCost: EditCost): Promise<Nullable<Cost>> {
        const editResult = await this.costRepository.update(editCost);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedCost) => editedCost,
        );
    }
}
