import { GendersDatasource } from "@beneficiary/data/datasource/genders.datasource";
import { GenderEnumMapper } from "@beneficiary/data/mappers/gender-enum.mapper";
import { GenderMapper } from "@beneficiary/data/mappers/gender.mapper";
import { UpdateCurrentLanguageMapper } from "@beneficiary/data/mappers/update-current-language.mapper";
import { GendersRepository } from "@beneficiary/data/repositories/genders.repository";
import { GetAllGendersUseCase } from "@beneficiary/domain/usecases/get-all-genders.usecase";
import { DocumentDatasource } from "@core/data/datasources/document.datasource";
import { EducationLevelDatasource } from "@core/data/datasources/education-level.datasource";
import { EmploymentStatusDatasource } from "@core/data/datasources/employment-status.datasource";
import { LanguageLocalDatasource } from "@core/data/datasources/language-local.datasource";
import { LanguageRemoteDatasource } from "@core/data/datasources/language-remote.datasource";
import { LocalStorage } from "@core/data/infrastructures/storage/local-storage";
import { CreateDocumentMapper } from "@core/data/mappers/create-document.mapper";
import { DocumentCategoriesMapper } from "@core/data/mappers/document-categories.mapper";
import { DocumentCategoryMapper } from "@core/data/mappers/document-category.mapper";
import { DocumentLicenseTypeMapper } from "@core/data/mappers/document-license-type.mapper";
import { DocumentSignatureMapper } from "@core/data/mappers/document-signature.mapper";
import { DocumentTypeLicenseEnumMapper } from "@core/data/mappers/document-type-license-enum.mapper";
import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { EducationLevelEnumMapper } from "@core/data/mappers/education-level-enum.mapper";
import { EducationLevelMapper } from "@core/data/mappers/education-level.mapper";
import { EmploymentStatusEnumMapper } from "@core/data/mappers/employment-status-enum.mapper";
import { EmploymentStatusMapper } from "@core/data/mappers/employment-status.mapper";
import { LanguageIsoCodeEnumMapper } from "@core/data/mappers/language-iso-code-enum.mapper";
import { LanguageMapper } from "@core/data/mappers/language.mapper";
import { OrderMapper } from "@core/data/mappers/order.mapper";
import { DocumentRepository } from "@core/data/repositories/document.repository";
import { EducationLevelRepository } from "@core/data/repositories/education-level.repository";
import { EmploymentStatusRepository } from "@core/data/repositories/employment-status.repository";
import { EventBusRepository } from "@core/data/repositories/event-bus.repository";
import { LanguageRepository } from "@core/data/repositories/language.repository";
import { CreateDocumentUseCase } from "@core/domain/usecases/create-document.usecase";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { GetAllDocumentCategoriesUseCase } from "@core/domain/usecases/get-all-document-categories.usecase";
import { GetAllDocumentTypesUseCase } from "@core/domain/usecases/get-all-document-types.usecase";
import { GetAllDocumentsUseCase } from "@core/domain/usecases/get-all-documents.usecase";
import { GetAllEducationLevelsUseCase } from "@core/domain/usecases/get-all-education-levels.usecase";
import { GetAllEmploymentStatusUseCase } from "@core/domain/usecases/get-all-employment-status.usecase";
import { GetAllLanguagesUseCase } from "@core/domain/usecases/get-all-languages.usecase";
import { GetCurrentLanguageUseCase } from "@core/domain/usecases/get-current-language.usecase";
import { GetDocumentByIdUseCase } from "@core/domain/usecases/get-document-by-id.usecase";
import { SignDocumentUseCase } from "@core/domain/usecases/sign-document.usecase";
import { UpdateSignDocumentUseCase } from "@core/domain/usecases/update-sign-document.usecase";
import { LoadLayoutViewModel } from "@core/presentacion/component/feedback/load-layout/load-layout.viewmodel";
import { DashboardLayoutViewModel } from "@core/presentacion/component/layout/dashboard-layout/dashboard-layout.viewmodel";
import { IncHeaderViewModel } from "@core/presentacion/component/layout/header/inc-header-viewmodel";
import { DiModuleBuilder } from "@di/builder/di-module-builder";
import { ContainerModule } from "inversify";
import { AppViewModel } from "../../app.viewmodel";
import { coreTypes } from "./core-types.di";
import { IdentificationDocumentTypeDatasource } from "./data/datasources/identification-document-type.datasource";
import { AxiosHttp } from "./data/infrastructures/http/axios-http";
import { AxiosHttpConfig } from "./data/infrastructures/http/axios-http-config";
import { Http } from "./data/infrastructures/http/http";
import { IdentificationDocumentTypeEnumMapper } from "./data/mappers/identification-document-type-enum.mapper";
import { IdentificationDocumentTypeMapper } from "./data/mappers/identification-document-type.mapper";
import { IdentificationDocumentTypeRepository } from "./data/repositories/identification-document-type.repository";
import { GetAllIdentificationDocumentTypesUseCase } from "./domain/usecases/get-all-identification-document-types.usecase";
import { UpdateLanguageUseCase } from "./domain/usecases/update-current-language.usecase";
import { HomePageViewModel } from "./presentacion/page/home-page.viewmodel";

const coreModule = new DiModuleBuilder();

coreModule.registerSubModules(() => ({
    datasources: new ContainerModule((bind) => {
        bind(DocumentDatasource).toSelf();
        bind(EducationLevelDatasource).toSelf();
        bind(EmploymentStatusDatasource).toSelf();
        bind(GendersDatasource).toSelf();
        bind(IdentificationDocumentTypeDatasource).toSelf();
        bind(LanguageLocalDatasource).toSelf();
        bind(LanguageRemoteDatasource).toSelf();
    }),
    mappers: new ContainerModule((bind) => {
        bind(CreateDocumentMapper).toSelf();
        bind(DocumentLicenseTypeMapper).toSelf();
        bind(DocumentMapper).toSelf();
        bind(DocumentCategoriesMapper).toSelf();
        bind(DocumentCategoryMapper).toSelf();
        bind(DocumentSignatureMapper).toSelf();
        bind(DocumentTypeLicenseEnumMapper).toSelf();
        bind(EducationLevelEnumMapper).toSelf();
        bind(EducationLevelMapper).toSelf();
        bind(EmploymentStatusEnumMapper).toSelf();
        bind(EmploymentStatusMapper).toSelf();
        bind(GenderEnumMapper).toSelf();
        bind(GenderMapper).toSelf();
        bind(LanguageIsoCodeEnumMapper).toSelf();
        bind(IdentificationDocumentTypeEnumMapper).toSelf();
        bind(IdentificationDocumentTypeMapper).toSelf();
        bind(LanguageMapper).toSelf();
        bind(OrderMapper).toSelf();
        bind(UpdateCurrentLanguageMapper).toSelf();
    }),
    infrastructure: new ContainerModule((bind) => {
        bind<Http>(coreTypes.infrastructure.Http)
            .to(AxiosHttp)
            .inSingletonScope();
        bind(AxiosHttpConfig).toSelf().inSingletonScope();
        bind(LocalStorage).toSelf();
    }),
    repositories: new ContainerModule((bind) => {
        bind(DocumentRepository).toSelf().inSingletonScope();
        bind(EducationLevelRepository).toSelf();
        bind(EmploymentStatusRepository).toSelf();
        bind(EventBusRepository).toSelf().inSingletonScope();
        bind(GendersRepository).toSelf();
        bind(IdentificationDocumentTypeRepository).toSelf().inSingletonScope();
        bind(LanguageRepository).toSelf().inSingletonScope();
    }),
    useCases: new ContainerModule((bind) => {
        bind(CreateDocumentUseCase).toSelf();
        bind(DeleteDocumentUseCase).toSelf();
        bind(DownloadDocumentUseCase).toSelf();
        bind(GetAllDocumentCategoriesUseCase).toSelf();
        bind(GetAllDocumentsUseCase).toSelf();
        bind(GetAllDocumentTypesUseCase).toSelf();
        bind(GetAllEducationLevelsUseCase).toSelf();
        bind(GetAllEmploymentStatusUseCase).toSelf();
        bind(GetAllGendersUseCase).toSelf();
        bind(GetAllIdentificationDocumentTypesUseCase).toSelf();
        bind(GetAllLanguagesUseCase).toSelf();
        bind(GetCurrentLanguageUseCase).toSelf();
        bind(GetDocumentByIdUseCase).toSelf();
        bind(SignDocumentUseCase).toSelf();
        bind(UpdateLanguageUseCase).toSelf();
        bind(UpdateSignDocumentUseCase).toSelf();
    }),
    viewModels: new ContainerModule((bind) => {
        bind(AppViewModel).toSelf();
        bind(DashboardLayoutViewModel).toSelf();
        bind(HomePageViewModel).toSelf();
        bind(IncHeaderViewModel).toSelf();
        bind(LoadLayoutViewModel).toSelf();
    }),
}));

coreModule.registerModule();

export { coreModule };
