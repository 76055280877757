import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import {
    CreateProjectActivityTaskBody,
    ProjectActivityTask,
} from "../../domain/models/project-activity-task.model";
import { KanbanTaskBody, KanbanTaskDto } from "../dto/kanban/task.dto";

@injectable()
export class ActivityTaskMapper {
    map(activityDto: KanbanTaskDto): Nullable<ProjectActivityTask> {
        const errors = validateSync(activityDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        return new ProjectActivityTask(
            activityDto.id,
            activityDto.description,
            activityDto.technical_proposal_activity,
        );
    }

    mapToCreateTaskDto(
        activityTask: CreateProjectActivityTaskBody,
    ): KanbanTaskBody {
        return {
            description: activityTask.description,
            technical_proposal_activity: activityTask.technicalProposalActivity,
        };
    }

    mapToUpdateTaskDto(activityTask: ProjectActivityTask): KanbanTaskDto {
        return {
            id: activityTask.id,
            description: activityTask.description,
            technical_proposal_activity: activityTask.technicalProposalActivity,
        };
    }
}
