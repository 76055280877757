import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Material } from "@entity/domain/models/material/material.model";
import { CreateProjectMaterialMapper } from "@project/data/mappers/materials/create-project-material.mapper";
import { EditProjectMaterialMapper } from "@project/data/mappers/materials/edit-project-material.mapper";
import {
    ProjectMaterial,
    SearchAllProjectMaterialBy,
} from "@project/domain/models/project-material/project-material.model";
import { GetAllMaterialsUseCase } from "@project/domain/usecases/get-all-materials.usecase";
import { CreateProjectMaterialUseCase } from "@project/domain/usecases/project-material/create-project-material.usecase";
import { DeleteProjectMaterialUseCase } from "@project/domain/usecases/project-material/delete-project-material.usecase";
import { EditProjectMaterialUseCase } from "@project/domain/usecases/project-material/edit-project-material.usecase";
import { GetAllProjectMaterialsUseCase } from "@project/domain/usecases/project-material/get-all-project-materials.usecase";
import type { MaterialFormValuesValidated } from "@project/presentation/components/material-resources/form/material-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class MaterialResourcesViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    materials: Material[] = [];

    @observable
    projectMaterials: ProjectMaterial[] = [];

    @observable
    showAddMaterialModal: boolean = false;

    @observable
    showEditMaterialModal: boolean = false;

    @observable
    projectMaterialToEdit: Undefinable<ProjectMaterial> = undefined;

    projectId: Undefinable<number> = undefined;

    constructor(
        @inject(GetAllMaterialsUseCase)
        private readonly getAllMaterialsUseCase: GetAllMaterialsUseCase,
        @inject(GetAllProjectMaterialsUseCase)
        private readonly getAllProjectMaterialsUseCase: GetAllProjectMaterialsUseCase,
        @inject(CreateProjectMaterialUseCase)
        private readonly createProjectMaterialUseCase: CreateProjectMaterialUseCase,
        @inject(EditProjectMaterialUseCase)
        private readonly editProjectMaterialUseCase: EditProjectMaterialUseCase,
        @inject(DeleteProjectMaterialUseCase)
        private readonly deleteProjectMaterialUseCase: DeleteProjectMaterialUseCase,
        @inject(CreateProjectMaterialMapper)
        private readonly createProjectMaterialMapper: CreateProjectMaterialMapper,
        @inject(EditProjectMaterialMapper)
        private readonly editProjectMaterialMapper: EditProjectMaterialMapper,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getAllProjectMaterials(), this.getMaterials()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async addMaterial(
        projectId: Undefinable<number>,
        values: MaterialFormValuesValidated,
    ): Promise<Nullable<ProjectMaterial>> {
        if (!projectId) throw Error("Missing projectId");

        LoadLayoutStore.start();

        const createMaterialResult =
            await this.createProjectMaterialUseCase.execute(
                this.createProjectMaterialMapper.mapFromProjectFormValues(
                    projectId,
                    values,
                ),
            );

        if (createMaterialResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createMaterialResult;
    }

    @action
    setShowAddMaterialModal(show: boolean): void {
        this.showAddMaterialModal = show;
    }

    @action
    closeEditMaterialModal(): void {
        this.showEditMaterialModal = false;
    }

    @action
    openEditMaterialModal(projectMaterial: Undefinable<ProjectMaterial>): void {
        this.projectMaterialToEdit = projectMaterial;
        this.showEditMaterialModal = true;
    }

    async editMaterial(values: MaterialFormValuesValidated): Promise<void> {
        if (
            !this.projectMaterialToEdit?.id ||
            !this.projectMaterialToEdit.projectId
        )
            throw Error("Missing projectMaterial or projectId");

        LoadLayoutStore.start();
        const editMaterialResult =
            await this.editProjectMaterialUseCase.execute(
                this.editProjectMaterialMapper.mapFromProjectFormValues(
                    this.projectMaterialToEdit.id,
                    this.projectMaterialToEdit.projectId,
                    values,
                ),
            );

        if (editMaterialResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async deleteMaterial(projectMaterialId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteProjectMaterialUseCase.execute(projectMaterialId);
        LoadLayoutStore.finish();
    }

    async getAllProjectMaterials(): Promise<void> {
        if (!this.projectId) throw Error("Missing projectId");

        const filters: SearchAllProjectMaterialBy = {
            projectId: this.projectId,
        };

        const projectMaterials =
            await this.getAllProjectMaterialsUseCase.execute(filters);

        runInAction(() => {
            this.projectMaterials = projectMaterials;
        });
    }

    async getMaterials(): Promise<void> {
        const materials = await this.getAllMaterialsUseCase.execute();

        runInAction(() => {
            this.materials = materials;
        });
    }

    async reloadProjectMaterials(): Promise<void> {
        await this.getAllProjectMaterials();
    }
}
