import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { CreateSupplierBody } from "@entity/data/dto/supplier/create-supplier.body";
import { ServiceProvidedEnumMapper } from "@entity/data/mappers/supplier/service-provided-enum.mapper";
import { SupplierIdentificationEnumMapper } from "@entity/data/mappers/supplier/supplier-identification-enum.mapper";
import { SupplierTypeEnumMapper } from "@entity/data/mappers/supplier/supplier-type-enum.mapper";
import { CreateSupplier } from "@entity/domain/models/supplier/create-supplier.model";
import { SupplierFormValuesValidated } from "@entity/presentation/component/supplier-form/supplier-form.component";
import { inject, injectable } from "inversify";

@injectable()
export class CreateSupplierMapper {
    constructor(
        @inject(SupplierIdentificationEnumMapper)
        private readonly supplierIdentificationEnumMapper: SupplierIdentificationEnumMapper,
        @inject(SupplierTypeEnumMapper)
        private readonly supplierTypeEnumMapper: SupplierTypeEnumMapper,
        @inject(ServiceProvidedEnumMapper)
        private serviceProvidedEnumMapper: ServiceProvidedEnumMapper,
    ) {}
    mapToCreateDto(createSupplier: CreateSupplier): CreateSupplierBody {
        const identification_type =
            this.supplierIdentificationEnumMapper.mapToDto(
                createSupplier.identificationType,
            );

        const type = this.supplierTypeEnumMapper.mapToDto(createSupplier.type);

        const services_provided = createSupplier.serviceProvided
            ? createSupplier.serviceProvided.map((serviceProv) =>
                  this.serviceProvidedEnumMapper.mapToDto(serviceProv),
              )
            : undefined;

        return {
            entity: createSupplier.entity,
            company_name: createSupplier.companyName,
            contact_person: createSupplier.contactPerson,
            responsable_email: createSupplier.responsableEmail,
            phone1: createSupplier.phone1,
            type,
            identification_type,
            identification: createSupplier.identification,
            street: createSupplier.street,
            zip_code: createSupplier.zipCode,
            town: createSupplier.town,
            province: createSupplier.province,
            contract_start_date: convertEmptyToUndefined(
                createSupplier.contractStartDate?.toISODate(),
            ),
            contract_end_date: convertEmptyToUndefined(
                createSupplier.contractEndDate?.toISODate(),
            ),
            services_provided,
            other_services_provided: convertEmptyToUndefined(
                createSupplier.otherServiceProvided,
            ),
            details_services_provided: convertEmptyToUndefined(
                createSupplier.serviceProvidedDescription,
            ),
            quality_certifications:
                createSupplier.qualityCertifications ?? false,
            quality_certifications_date: convertEmptyToUndefined(
                createSupplier.qualityCertificationsDate?.toISODate(),
            ),
            service_evaluation: createSupplier.serviceEvaluation ?? false,
            service_evaluation_date: convertEmptyToUndefined(
                createSupplier.serviceEvaluationDate?.toISODate(),
            ),
            service_evaluation_detail: convertEmptyToUndefined(
                createSupplier.serviceEvaluationDetail,
            ),
            active: true,
            documents: convertEmptyToUndefined(
                createSupplier.documents?.map((document) => document.id),
            ),
        };
    }

    mapFromSupplierFormValues(
        supplierForm: SupplierFormValuesValidated,
        entityId: number,
    ): CreateSupplier {
        const createSupplier = new CreateSupplier(
            entityId,
            supplierForm.companyName,
            supplierForm.contactPerson,
            supplierForm.responsableEmail,
            supplierForm.phone1,
            supplierForm.type,
            supplierForm.identificationType,
            supplierForm.identification,
            supplierForm.street,
            supplierForm.zipCode,
            supplierForm.town,
            supplierForm.province,
        );

        createSupplier.contractStartDate = convertEmptyToUndefined(
            supplierForm.contractStartDate,
        );

        createSupplier.contractEndDate = convertEmptyToUndefined(
            supplierForm.contractEndDate,
        );

        createSupplier.serviceProvided = convertEmptyToUndefined(
            supplierForm.serviceProvided,
        );

        createSupplier.otherServiceProvided = convertEmptyToUndefined(
            supplierForm.otherServiceProvided,
        );

        createSupplier.serviceProvidedDescription = convertEmptyToUndefined(
            supplierForm.serviceProvidedDescription,
        );

        createSupplier.qualityCertifications =
            supplierForm.qualityCertifications;

        createSupplier.qualityCertificationsDate = convertEmptyToUndefined(
            supplierForm.qualityCertificationsDate,
        );

        createSupplier.serviceEvaluation = supplierForm.serviceEvaluation;

        createSupplier.serviceEvaluationDate = convertEmptyToUndefined(
            supplierForm.serviceEvaluationDate,
        );

        createSupplier.serviceEvaluationDetail = convertEmptyToUndefined(
            supplierForm.serviceEvaluationDetail,
        );

        createSupplier.active = true;

        createSupplier.documents = supplierForm.documents;

        return createSupplier;
    }
}
