/* eslint-disable max-lines */
import { RequireEntityGuard } from "@core/presentacion/component/routing/require-entity/require-entity.guard";
import { RequireRoleGuard } from "@core/presentacion/component/routing/require-role/require-role.guard";
import { UserRoleEnum } from "@user/domain/models/user-role.model";
import { lazy } from "react";
import { Route } from "react-router-dom";

const EntityListPage = lazy(async () =>
    import(
        /* webpackChunkName: "EntityListPage" */ "@entity/presentation/pages/entity/list/entity-list.page"
    ).then((module) => ({ default: module.EntityListPage })),
);

const CreateEntityPage = lazy(async () =>
    import(
        /* webpackChunkName: "CreateEntityPage" */ "@entity/presentation/pages/entity/create/create-entity.page"
    ).then((module) => ({ default: module.CreateEntityPage })),
);

const EditEntityPage = lazy(async () =>
    import(
        /* webpackChunkName: "EditEntityPage" */ "@entity/presentation/pages/entity/edit/edit-entity.page"
    ).then((module) => ({ default: module.EditEntityPage })),
);

const EntityDetailPage = lazy(async () =>
    import(
        /* webpackChunkName: "EntityDetailPage" */ "@entity/presentation/pages/entity/detail/entity-detail.page"
    ).then((module) => ({ default: module.EntityDetailPage })),
);

const MaterialListPage = lazy(async () =>
    import(
        /* webpackChunkName: "MaterialListPage" */ "@entity/presentation/pages/material/list/material-list.page"
    ).then((module) => ({ default: module.MaterialListPage })),
);
const MaterialCreatePage = lazy(async () =>
    import(
        /* webpackChunkName: "MaterialCreatePage" */ "@entity/presentation/pages/material/create/create-material.page"
    ).then((module) => ({ default: module.CreateMaterialPage })),
);
const MaterialEditPage = lazy(async () =>
    import(
        /* webpackChunkName: "MaterialEditPage" */ "@entity/presentation/pages/material/edit/edit-material.page"
    ).then((module) => ({ default: module.EditMaterialPage })),
);
const MaterialDetailPage = lazy(async () =>
    import(
        /* webpackChunkName: "MaterialDetailPage" */ "@entity/presentation/pages/material/detail/detail-material.page"
    ).then((module) => ({ default: module.DetailMaterialPage })),
);
const CostListPage = lazy(async () =>
    import(
        /* webpackChunkName: "CostListPage" */ "@entity/presentation/pages/cost/list/cost-list-page"
    ).then((module) => ({ default: module.CostListPage })),
);

const EmployeeListPage = lazy(async () =>
    import(
        /* webpackChunkName: "EmployeeListPage" */ "@entity/presentation/pages/employee/list/employee-list.page"
    ).then((module) => ({ default: module.EmployeeListPage })),
);

const CreateEmployeePage = lazy(async () =>
    import(
        /* webpackChunkName: "CreateEmployeePage" */ "@entity/presentation/pages/employee/create/create-employee.page"
    ).then((module) => ({ default: module.CreateEmployeePage })),
);

const EditEmployeePage = lazy(async () =>
    import(
        /* webpackChunkName: "EditEmployeePage" */ "@entity/presentation/pages/employee/edit/edit-employee.page"
    ).then((module) => ({ default: module.EditEmployeePage })),
);

const EmployeeDetailPage = lazy(async () =>
    import(
        /* webpackChunkName: "EmployeeDetailPage" */ "@entity/presentation/pages/employee/detail/employee-detail.page"
    ).then((module) => ({ default: module.EmployeeDetailPage })),
);

const CreateCreditorPage = lazy(async () =>
    import(
        /* webpackChunkName: "CreateCreditorPage" */ "@entity/presentation/pages/creditor/create/create-creditor.page"
    ).then((module) => ({ default: module.CreateCreditorPage })),
);

const EditCreditorPage = lazy(async () =>
    import(
        /* webpackChunkName: "EditCreditorPage" */ "@entity/presentation/pages/creditor/edit/edit-creditor.page"
    ).then((module) => ({ default: module.EditCreditorPage })),
);

const CreditorListPage = lazy(async () =>
    import(
        /* webpackChunkName: "CreditorListPage" */ "@entity/presentation/pages/creditor/list/creditor-list.page"
    ).then((module) => ({ default: module.CreditorListPage })),
);

const CreditorDetailPage = lazy(async () =>
    import(
        /* webpackChunkName: "CreditorDetailPage" */ "@entity/presentation/pages/creditor/detail/creditor-detail.page"
    ).then((module) => ({ default: module.CreditorDetailPage })),
);

const CreateCostPage = lazy(async () =>
    import(
        /* webpackChunkName: "CreateCostPage" */ "@entity/presentation/pages/cost/create/create-cost-page"
    ).then((module) => ({ default: module.CreateCostPage })),
);

const EditCostPage = lazy(async () =>
    import(
        /* webpackChunkName: "EditCostPage" */ "@entity/presentation/pages/cost/edit/edit-cost-page"
    ).then((module) => ({ default: module.EditCostPage })),
);

const CostDetailPage = lazy(async () =>
    import(
        /* webpackChunkName: "CostDetailPage" */ "@entity/presentation/pages/cost/detail/cost-detail-page"
    ).then((module) => ({ default: module.CostDetailPage })),
);

const SupplierListPage = lazy(async () =>
    import(
        /* webpackChunkName: "SupplierListPage" */ "@entity/presentation/pages/supplier/list/supplier-list.page"
    ).then((module) => ({ default: module.SupplierListPage })),
);

const CreateSupplierPage = lazy(async () =>
    import(
        /* webpackChunkName: "CreateSupplierPage" */ "@entity/presentation/pages/supplier/create/create-supplier.page"
    ).then((module) => ({ default: module.CreateSupplierPage })),
);

const EditSupplierPage = lazy(async () =>
    import(
        /* webpackChunkName: "EditSupplierPage" */ "@entity/presentation/pages/supplier/edit/edit-supplier.page"
    ).then((module) => ({ default: module.EditSupplierPage })),
);

const SupplierDetailPage = lazy(async () =>
    import(
        /* webpackChunkName: "SupplierDetailPage" */ "@entity/presentation/pages/supplier/detail/supplier-detail.page"
    ).then((module) => ({ default: module.SupplierDetailPage })),
);

export class EntityPaths {
    static readonly Root = "entity";
    static readonly List = "list";

    static readonly Create = "create";

    static readonly EditParam = ":entityId";
    static readonly Edit = `edit/${EntityPaths.EditParam}`;

    static readonly DetailParam = ":entityId";
    static readonly Detail = `detail/${EntityPaths.DetailParam}`;

    static readonly EmployeeRoot = "employees";

    static readonly ListEmployees = "list";
    static readonly CreateEmployee = "create";
    static readonly EditEmployeeParam = ":employeeId";
    static readonly EditEmployee = `edit/${EntityPaths.EditEmployeeParam}`;
    static readonly DetailEmployeeParam = ":employeeId";
    static readonly DetailEmployee = `detail/${EntityPaths.DetailEmployeeParam}`;

    static readonly MaterialRoot = "materials";
    static readonly ListMaterials = "list";
    static readonly CreateMaterial = "create";

    static readonly EditMaterialParam = ":materialId";
    static readonly EditMaterial = `edit/${EntityPaths.EditMaterialParam}`;

    static readonly DetailMaterialParam = ":materialId";
    static readonly DetailMaterial = `detail/${EntityPaths.DetailMaterialParam}`;

    static readonly CostRoot = "cost";
    static readonly ListCost = "list";
    static readonly CreateCost = "create";

    static readonly CreditorRoot = "creditors";
    static readonly ListCreditor = "list";
    static readonly CreateCreditor = "create";
    static DetailCreditorParam = ":creditorId";
    static readonly DetailCreditor = `detail/${EntityPaths.DetailCreditorParam}`;
    static EditCreditorParam = ":creditorId";
    static readonly EditCreditor = `edit/${EntityPaths.EditCreditorParam}`;

    static readonly EditCostParam = ":costId";
    static readonly EditCost = `edit/${EntityPaths.EditCostParam}`;

    static readonly DetailCostParam = ":costId";
    static readonly DetailCost = `detail/${EntityPaths.DetailCostParam}`;
}

export class SupplierPaths {
    static readonly Root = "supplier";

    static readonly SupplierCreate = "create";

    static readonly SupplierEditParam = ":supplierId";
    static readonly SupplierEdit = `edit/${SupplierPaths.SupplierEditParam}`;

    static readonly SupplierDetailParam = ":supplierId";
    static readonly SupplierDetail = `detail/${SupplierPaths.SupplierDetailParam}`;
}

export class EntityRoutes {
    static LIST = `/${EntityPaths.Root}/${EntityPaths.List}`;

    static CREATE = `/${EntityPaths.Root}/${EntityPaths.Create}`;

    static LIST_MATERIAL = `/${EntityPaths.Root}/${EntityPaths.MaterialRoot}/${EntityPaths.ListMaterials}`;
    static CREATE_MATERIAL = `/${EntityPaths.Root}/${EntityPaths.MaterialRoot}/${EntityPaths.CreateMaterial}`;

    static EDIT_MATERIAL = (materialId: number): string =>
        `/${EntityPaths.Root}/${
            EntityPaths.MaterialRoot
        }/${EntityPaths.EditMaterial.replace(
            EntityPaths.EditMaterialParam,
            materialId.toString(),
        )}`;

    static DETAIL_MATERIAL = (materialId: number): string =>
        `/${EntityPaths.Root}/${
            EntityPaths.MaterialRoot
        }/${EntityPaths.DetailMaterial.replace(
            EntityPaths.DetailMaterialParam,
            materialId.toString(),
        )}`;

    static LIST_COST = `/${EntityPaths.Root}/${EntityPaths.CostRoot}/${EntityPaths.ListCost}`;
    static CREATE_COST = `/${EntityPaths.Root}/${EntityPaths.CostRoot}/${EntityPaths.CreateCost}`;

    static LIST_CREDITORS = `/${EntityPaths.Root}/${EntityPaths.CreditorRoot}/${EntityPaths.ListCreditor}`;
    static CREATE_CREDITOR = `/${EntityPaths.Root}/${EntityPaths.CreditorRoot}/${EntityPaths.CreateCreditor}`;
    static DETAIL_CREDITOR = (creditorId: number): string =>
        `/${EntityPaths.Root}/${
            EntityPaths.CreditorRoot
        }/${EntityPaths.DetailCreditor.replace(
            EntityPaths.DetailCreditorParam,
            creditorId.toString(),
        )}`;
    static EDIT_CREDITOR = (creditorId: number): string =>
        `/${EntityPaths.Root}/${
            EntityPaths.CreditorRoot
        }/${EntityPaths.EditCreditor.replace(
            EntityPaths.EditCreditorParam,
            creditorId.toString(),
        )}`;

    static LIST_EMPLOYEE = `/${EntityPaths.Root}/${EntityPaths.EmployeeRoot}/${EntityPaths.ListEmployees}`;

    static CREATE_EMPLOYEE = `/${EntityPaths.Root}/${EntityPaths.EmployeeRoot}/${EntityPaths.CreateEmployee}`;

    static EDIT = (entityId: number): string =>
        `/${EntityPaths.Root}/${EntityPaths.Edit.replace(
            EntityPaths.EditParam,
            entityId.toString(),
        )}`;

    static EDIT_COST = (costId: number): string =>
        `/${EntityPaths.Root}/${
            EntityPaths.CostRoot
        }/${EntityPaths.EditCost.replace(
            EntityPaths.EditCostParam,
            costId.toString(),
        )}`;

    static EDIT_EMPLOYEE = (employeeId: number): string =>
        `/${EntityPaths.Root}/${
            EntityPaths.EmployeeRoot
        }/${EntityPaths.EditEmployee.replace(
            EntityPaths.EditEmployeeParam,
            employeeId.toString(),
        )}`;

    static DETAIL = (entityId: number): string =>
        `/${EntityPaths.Root}/${EntityPaths.Detail.replace(
            EntityPaths.DetailParam,
            entityId.toString(),
        )}`;

    static DETAIL_COST = (costId: number): string =>
        `/${EntityPaths.Root}/${
            EntityPaths.CostRoot
        }/${EntityPaths.DetailCost.replace(
            EntityPaths.DetailCostParam,
            costId.toString(),
        )}`;

    static DETAIL_EMPLOYEE = (employeeId: number): string =>
        `/${EntityPaths.Root}/${
            EntityPaths.EmployeeRoot
        }/${EntityPaths.DetailEmployee.replace(
            EntityPaths.DetailEmployeeParam,
            employeeId.toString(),
        )}`;

    static LIST_SUPPLIER = `/${EntityPaths.Root}/${SupplierPaths.Root}`;

    static readonly CREATE_SUPPLIER = `/${EntityPaths.Root}/${SupplierPaths.Root}/${SupplierPaths.SupplierCreate}`;

    static readonly EDIT_SUPPLIER = (supplierId: number): string =>
        `/${EntityPaths.Root}/${
            SupplierPaths.Root
        }/${SupplierPaths.SupplierEdit.replace(
            SupplierPaths.SupplierEditParam,
            supplierId.toString(),
        )}`;

    static readonly DETAIL_SUPPLIER = (supplierId: number): string =>
        `/${EntityPaths.Root}/${
            SupplierPaths.Root
        }/${SupplierPaths.SupplierDetail.replace(
            SupplierPaths.SupplierDetailParam,
            supplierId.toString(),
        )}`;
}

export const entityRoutes = (
    <Route path={EntityPaths.Root}>
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                        UserRoleEnum.Professional,
                    ]}
                >
                    <EntityListPage />
                </RequireRoleGuard>
            }
            path={EntityPaths.List}
        />
        <Route
            element={
                <RequireRoleGuard rolesIn={[UserRoleEnum.SuperAdmin]}>
                    <CreateEntityPage />
                </RequireRoleGuard>
            }
            path={EntityPaths.Create}
        />
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                    ]}
                >
                    <RequireEntityGuard redirectTo={EntityRoutes.LIST}>
                        <EditEntityPage />
                    </RequireEntityGuard>
                </RequireRoleGuard>
            }
            path={EntityPaths.Edit}
        />
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                        UserRoleEnum.Professional,
                    ]}
                >
                    <EntityDetailPage />
                </RequireRoleGuard>
            }
            path={EntityPaths.Detail}
        />
        <Route path={EntityPaths.MaterialRoot}>
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                            UserRoleEnum.Professional,
                        ]}
                    >
                        <MaterialListPage />
                    </RequireRoleGuard>
                }
                path={EntityPaths.ListMaterials}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard
                            redirectTo={EntityPaths.ListMaterials}
                        >
                            <MaterialCreatePage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={EntityPaths.CreateMaterial}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard
                            redirectTo={EntityPaths.ListMaterials}
                        >
                            <MaterialEditPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={EntityPaths.EditMaterial}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                            UserRoleEnum.Professional,
                        ]}
                    >
                        <MaterialDetailPage />
                    </RequireRoleGuard>
                }
                path={EntityPaths.DetailMaterial}
            />
        </Route>
        <Route path={EntityPaths.CostRoot}>
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                            UserRoleEnum.Professional,
                        ]}
                    >
                        <CostListPage />
                    </RequireRoleGuard>
                }
                path={EntityPaths.ListCost}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard redirectTo={EntityRoutes.LIST_COST}>
                            <CreateCostPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={EntityPaths.CreateCost}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard redirectTo={EntityRoutes.LIST}>
                            <EditCostPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={EntityPaths.EditCost}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                            UserRoleEnum.Professional,
                        ]}
                    >
                        <CostDetailPage />
                    </RequireRoleGuard>
                }
                path={EntityPaths.DetailCost}
            />
        </Route>
        <Route path={SupplierPaths.Root}>
            <Route
                index
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                            UserRoleEnum.Professional,
                        ]}
                    >
                        <SupplierListPage />
                    </RequireRoleGuard>
                }
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard
                            redirectTo={EntityRoutes.LIST_SUPPLIER}
                        >
                            <CreateSupplierPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={SupplierPaths.SupplierCreate}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard
                            redirectTo={EntityRoutes.LIST_SUPPLIER}
                        >
                            <EditSupplierPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={SupplierPaths.SupplierEdit}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                            UserRoleEnum.Professional,
                        ]}
                    >
                        <SupplierDetailPage />
                    </RequireRoleGuard>
                }
                path={SupplierPaths.SupplierDetail}
            />
        </Route>
        <Route path={EntityPaths.EmployeeRoot}>
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                            UserRoleEnum.Professional,
                        ]}
                    >
                        <EmployeeListPage />
                    </RequireRoleGuard>
                }
                path={EntityPaths.ListEmployees}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard
                            redirectTo={EntityRoutes.LIST_EMPLOYEE}
                        >
                            <CreateEmployeePage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={EntityPaths.CreateEmployee}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard
                            redirectTo={EntityRoutes.LIST_EMPLOYEE}
                        >
                            <EditEmployeePage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={EntityPaths.EditEmployee}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                            UserRoleEnum.Professional,
                        ]}
                    >
                        <EmployeeDetailPage />
                    </RequireRoleGuard>
                }
                path={EntityPaths.DetailEmployee}
            />
        </Route>
        <Route path={EntityPaths.CreditorRoot}>
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                            UserRoleEnum.Professional,
                        ]}
                    >
                        <CreditorListPage />
                    </RequireRoleGuard>
                }
                path={EntityPaths.ListCreditor}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard
                            redirectTo={EntityRoutes.LIST_CREDITORS}
                        >
                            <CreateCreditorPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={EntityPaths.CreateCreditor}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard
                            redirectTo={EntityRoutes.LIST_CREDITORS}
                        >
                            <EditCreditorPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={EntityPaths.EditCreditor}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                            UserRoleEnum.Professional,
                        ]}
                    >
                        <RequireEntityGuard
                            redirectTo={EntityRoutes.LIST_CREDITORS}
                        >
                            <CreditorDetailPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={EntityPaths.DetailCreditor}
            />
        </Route>
    </Route>
);
