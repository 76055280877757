import { CreateFinancialEntityBudget } from "@project/domain/models/budget/create-financial-entity-budget.model";
import { FinancialEntityBudget } from "@project/domain/models/budget/financial-entity-budget.model";

export class EditFinancialEntityBudgetModel extends CreateFinancialEntityBudget {
    constructor(
        public readonly id: FinancialEntityBudget["id"],
        name: CreateFinancialEntityBudget["name"],
        description: CreateFinancialEntityBudget["description"],
        financialEntityId: CreateFinancialEntityBudget["financialEntityId"],
    ) {
        super(name, description, financialEntityId);
    }
}
