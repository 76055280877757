import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { PolicyProtocolRepository } from "@entity/data/repositories/policy-protocol.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeletePolicyProtocolUseCase {
    constructor(
        @inject(PolicyProtocolRepository)
        private readonly policyProtocolRepository: PolicyProtocolRepository,
    ) {}

    async execute(policyProtocolId: number): Promise<void> {
        const result =
            await this.policyProtocolRepository.delete(policyProtocolId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        ToastManagerStore.success();
    }
}
