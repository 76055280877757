import { Pagination } from "@core/domain/models/pagination";
import { AuditoryRepository } from "@entity/data/repositories/auditory.repository";
import { Auditory } from "@entity/domain/models/quality-framework/auditory/auditory.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllAuditoriesUsecase {
    constructor(
        @inject(AuditoryRepository)
        private readonly auditoryRepository: AuditoryRepository,
    ) {}

    async execute(): Promise<Auditory[]> {
        const auditoryResults = await this.auditoryRepository.getAll(
            Pagination.NoPagination(),
        );

        return auditoryResults.fold(
            () => [],
            (auditories) => auditories.auditories,
        );
    }
}
