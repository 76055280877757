import { EditProjectAuditoryBody } from "@entity/data/dto/quality-framework/project-auditory/edit-project-auditory.body";
import { EditProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/edit-project-auditory.model";
import { ProjectAuditoryFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/project-auditory/form/project-auditory-form";
import { injectable } from "inversify";

@injectable()
export class EditProjectAuditoryMapper {
    mapToDto(
        editProjectAuditory: EditProjectAuditory,
    ): EditProjectAuditoryBody {
        const editProjectAuditoryBody: EditProjectAuditoryBody = {
            id: editProjectAuditory.id,
            certification_entity: editProjectAuditory.certificationEntity,
            year: editProjectAuditory.year,
            audited_project: editProjectAuditory.auditedProject,
            entity: editProjectAuditory.entity,
        };
        return editProjectAuditoryBody;
    }
    mapFromModal(
        id: number,
        entityId: number,
        projectAuditory: ProjectAuditoryFormValuesValidated,
    ): EditProjectAuditory {
        const editProjectAuditory: EditProjectAuditory =
            new EditProjectAuditory(
                id,
                projectAuditory.certificationEntity,
                projectAuditory.year.year,
                projectAuditory.auditedProject,
                entityId,
            );
        return editProjectAuditory;
    }
}
