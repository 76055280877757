import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { Proceeding } from "@proceeding/domain/models/proceeding.model";
import { inject, injectable } from "inversify";
import { ProceedingRepository } from "../../data/repositories/proceeding.repository";
import { CreateProceeding } from "../models/create-proceeding.model";

@injectable()
export class CreateProceedingUseCase {
    constructor(
        @inject(ProceedingRepository)
        private readonly proceedingRepository: ProceedingRepository,
    ) {}

    async execute(project: CreateProceeding): Promise<Nullable<Proceeding>> {
        const creationResult = await this.proceedingRepository.create(project);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdProceeding) => createdProceeding,
        );
    }
}
