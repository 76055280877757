import { IdentificationDocumentTypeEnumMapper } from "@core/data/mappers/identification-document-type-enum.mapper";
import { CreateEmployeeBody } from "@entity/data/dto/employee/create-employee.body";
import { EmployeeTypeEnumMapper } from "@entity/data/mappers/employee/employee-type-enum.mapper";
import { VolunteerInfoMapper } from "@entity/data/mappers/employee/volunteer-info.mapper";
import { CreateEmployee } from "@entity/domain/models/employee/create-employee.model";
import { CreateEmployeeFormValuesValidated } from "@entity/presentation/component/employee-form/employee-form.component";
import { inject, injectable } from "inversify";

@injectable()
export class CreateEmployeeMapper {
    constructor(
        @inject(IdentificationDocumentTypeEnumMapper)
        private readonly identificationDocumentTypeEnumMapper: IdentificationDocumentTypeEnumMapper,
        @inject(EmployeeTypeEnumMapper)
        private readonly employeeTypeEnumMapper: EmployeeTypeEnumMapper,
        @inject(VolunteerInfoMapper)
        private readonly volunteerInfoMapper: VolunteerInfoMapper,
    ) {}

    mapFromEmployeeFormValues(
        entityId: number,
        employeeForm: CreateEmployeeFormValuesValidated,
    ): CreateEmployee {
        const employee = new CreateEmployee(
            employeeForm.general.identification,
            employeeForm.general.identificationType,
            employeeForm.general.name,
            employeeForm.general.surname,
            employeeForm.general.type,
            entityId,
            employeeForm.general.volunteerInfo,
        );

        if (employeeForm.general.volunteerInfo) {
            employee.volunteerInfo = this.volunteerInfoMapper.mapFromFormValues(
                employeeForm.general.volunteerInfo,
            );
        }

        return employee;
    }

    mapToCreateDto(employee: CreateEmployee): CreateEmployeeBody {
        const identificationType =
            this.identificationDocumentTypeEnumMapper.mapToDto(
                employee.identificationType,
            );

        const type = this.employeeTypeEnumMapper.mapToDto(employee.type);

        const employeeBody: CreateEmployeeBody = {
            name: employee.name,
            surname: employee.surname,
            identification: employee.identification,
            identification_type: identificationType,
            type: type,
            entity: employee.entity,
        };

        if (employee.volunteerInfo) {
            employeeBody.volunteer_info = this.volunteerInfoMapper.mapToDto(
                employee.volunteerInfo,
            );
        }

        return employeeBody;
    }
}
