import { CenterTypeDto } from "@beneficiary/data/dto/center-type.dto";
import { CenterTypeEnumMapper } from "@beneficiary/data/mappers/center-type-enum.mapper";
import { CenterType } from "@beneficiary/domain/models/center-type.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class CenterTypeMapper {
    constructor(
        @inject(CenterTypeEnumMapper)
        private readonly centerTypeEnumMapper: CenterTypeEnumMapper,
    ) {}
    map(centerTypeDto: CenterTypeDto): Nullable<CenterType> {
        const errors = validateSync(centerTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.centerTypeEnumMapper.map(centerTypeDto.key);
        if (!id) return null;

        return new CenterType(id, centerTypeDto.value);
    }
}
