import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { EditQualityCertificateBody } from "@entity/data/dto/quality-framework/quality-certificate/edit-quality-certificate.body";
import { QualityCertificateTypeEnumMapper } from "@entity/data/mappers/quality-certificate-type-enum.mapper";
import { EditQualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/edit-quality-certificate.model";
import { QualityCertificateFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/quality-certificate/form/quality-certificate-form";
import { inject, injectable } from "inversify";

@injectable()
export class EditQualityCertificateMapper {
    constructor(
        @inject(QualityCertificateTypeEnumMapper)
        private readonly qualityCertificateTypeEnumMapper: QualityCertificateTypeEnumMapper,
    ) {}
    mapToDto(
        editQualityCertificate: EditQualityCertificate,
    ): EditQualityCertificateBody {
        const quality_certificate_type =
            this.qualityCertificateTypeEnumMapper.mapToDto(
                editQualityCertificate.qualityCertificateType,
            );

        const editQualityCertificateBody: EditQualityCertificateBody = {
            id: editQualityCertificate.id,
            quality_certificate_type,
            entity: editQualityCertificate.entity,
            year: editQualityCertificate.year,
            expiration_date: editQualityCertificate.expirationDate
                ? editQualityCertificate.expirationDate.toISODate()
                : undefined,
            other_quality_certificate:
                editQualityCertificate.otherQualityCertificate,
        };
        return editQualityCertificateBody;
    }
    mapFromModal(
        id: number,
        entityId: number,
        qualityCertificate: QualityCertificateFormValuesValidated,
    ): EditQualityCertificate {
        const editQualityCertificate: EditQualityCertificate =
            new EditQualityCertificate(
                id,
                qualityCertificate.qualityCertificateType,
                entityId,
                qualityCertificate.year.year,
                convertEmptyToUndefined(qualityCertificate.expirationDate),
                qualityCertificate.otherQualityCertificate,
            );
        return editQualityCertificate;
    }
}
