import { IsNotEmpty, IsNumber } from "class-validator";
export class EmployeesTypeCountDto {
    @IsNumber()
    @IsNotEmpty()
    employee!: number;

    @IsNumber()
    @IsNotEmpty()
    volunteer!: number;

    @IsNumber()
    @IsNotEmpty()
    freelance!: number;
}
