import { Nullable } from "@core/domain/types/nullable.type";
import { EmployeeContractDto } from "@entity/data/dto/employee/contracts/employee/employee-contract.dto";
import { DisabilityTypeEnumMapper } from "@entity/data/mappers/employee/disability-type-enum.mapper";
import { ProfessionalGroupEnumMapper } from "@entity/data/mappers/employee/professional-group-enum.mapper";
import { SubsidyReasonEnumMapper } from "@entity/data/mappers/employee/subsidy-reason-enum.mapper";
import { TerminationReasonEnumMapper } from "@entity/data/mappers/employee/termination-reason-enum.mapper";
import { EmployeeContract } from "@entity/domain/models/employee/contracts/employee/employee-contract.model";
import { ContractTypeEnumMapper } from "@project/data/mappers/expenses/contract-type-enum.mapper";
import { ContributionGroupTypeEnumMapper } from "@project/data/mappers/expenses/contribution-group-type-enum.mapper";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class EmployeeContractMapper {
    constructor(
        @inject(ContributionGroupTypeEnumMapper)
        private readonly contributionGroupTypeEnumMapper: ContributionGroupTypeEnumMapper,
        @inject(ContractTypeEnumMapper)
        private readonly contractTypeEnumMapper: ContractTypeEnumMapper,
        @inject(DisabilityTypeEnumMapper)
        private readonly disabilityTypeEnumMapper: DisabilityTypeEnumMapper,
        @inject(ProfessionalGroupEnumMapper)
        private readonly professionalGroupEnumMapper: ProfessionalGroupEnumMapper,
        @inject(TerminationReasonEnumMapper)
        private readonly terminationReasonEnumMapper: TerminationReasonEnumMapper,
        @inject(SubsidyReasonEnumMapper)
        private readonly subsidyReasonEnumMapper: SubsidyReasonEnumMapper,
    ) {}
    map(employeeContractDto: EmployeeContractDto): Nullable<EmployeeContract> {
        const errors = validateSync(employeeContractDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        const contractType = this.contractTypeEnumMapper.map(
            employeeContractDto.type,
        );
        if (!contractType) return null;

        const professionalGroup = this.professionalGroupEnumMapper.map(
            employeeContractDto.professional_group,
        );
        if (!professionalGroup) return null;

        const contributionGroup = this.contributionGroupTypeEnumMapper.map(
            employeeContractDto.contribution_group,
        );
        if (!contributionGroup) return null;

        let disabilityType = null;
        if (employeeContractDto.disability_type) {
            disabilityType = this.disabilityTypeEnumMapper.map(
                employeeContractDto.disability_type,
            );
        }

        let terminationReason = null;
        if (employeeContractDto.termination_reason) {
            terminationReason = this.terminationReasonEnumMapper.map(
                employeeContractDto.termination_reason,
            );
        }

        let subsidyReason = null;
        if (employeeContractDto.subsidy_reason) {
            subsidyReason = this.subsidyReasonEnumMapper.map(
                employeeContractDto.subsidy_reason,
            );
        }

        const employeeContract = new EmployeeContract(
            employeeContractDto.id,
            contractType,
            DateTime.fromISO(employeeContractDto.start_date),
            employeeContractDto.has_disability,
            professionalGroup,
            contributionGroup,
            Number(employeeContractDto.weekly_workday_hours),
            Number(employeeContractDto.net_salary),
            Number(employeeContractDto.IRPF),
            Number(employeeContractDto.social_security_contribution),
            Number(employeeContractDto.monthly_cost),
            Number(employeeContractDto.social_security_contribution_company),
            Number(employeeContractDto.total_cost),
            Number(employeeContractDto.deductible_amount_disability),
            employeeContractDto.employee,
            disabilityType ?? undefined,
            terminationReason ?? undefined,
            employeeContractDto.other_type ?? undefined,
            employeeContractDto.is_subsidized,
            Number(employeeContractDto.subsidy_deductible_amount),
            subsidyReason ?? undefined,
            employeeContractDto.other_subsidy_reason ?? undefined,
            employeeContractDto.subsidy_scope ?? undefined,
        );

        if (employeeContractDto.end_date) {
            employeeContract.endDate = DateTime.fromISO(
                employeeContractDto.end_date,
            );
        }

        return employeeContract;
    }
}
