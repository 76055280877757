import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { FinancialEntitiesSearchFilters } from "@project/domain/models/financial-entity/financial-entities-search-filters";
import { FinancialEntity } from "@project/domain/models/financial-entity/financial-entities.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllFinancialEntitiesUseCase {
    constructor(
        @inject(FinancialEntityRepository)
        private readonly financialEntityRepository: FinancialEntityRepository,
    ) {}

    async execute(
        filters?: FinancialEntitiesSearchFilters,
    ): Promise<FinancialEntity[]> {
        const financialEntitiesResult =
            await this.financialEntityRepository.getAllBy(filters);

        return financialEntitiesResult.fold(
            () => [],
            (financialEntities) => financialEntities.financialEntities,
        );
    }
}
