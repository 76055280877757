import { Nullable } from "@core/domain/types/nullable.type";
import { AdministrativeRecordScopeEnumDto } from "@entity/data/dto/administrative-record-scope-enum.dto";
import { AdministrativeRecordScopeEnum } from "@entity/domain/models/administrative-record-scope.model";
import { injectable } from "inversify";

@injectable()
export class AdministrativeRecordScopeEnumMapper {
    map(
        administrativeRecordScopeEnumDto: AdministrativeRecordScopeEnumDto,
    ): Nullable<AdministrativeRecordScopeEnum> {
        switch (administrativeRecordScopeEnumDto) {
            case AdministrativeRecordScopeEnumDto.Local:
                return AdministrativeRecordScopeEnum.Local;
            case AdministrativeRecordScopeEnumDto.National:
                return AdministrativeRecordScopeEnum.National;
            case AdministrativeRecordScopeEnumDto.AutonomousCommunity:
                return AdministrativeRecordScopeEnum.AutonomousCommunity;
            default:
                return null;
        }
    }

    mapToDto(
        administrativeRecordScopeEnum: AdministrativeRecordScopeEnum,
    ): AdministrativeRecordScopeEnumDto {
        // eslint-disable-next-line default-case
        switch (administrativeRecordScopeEnum) {
            case AdministrativeRecordScopeEnum.Local:
                return AdministrativeRecordScopeEnumDto.Local;
            case AdministrativeRecordScopeEnum.National:
                return AdministrativeRecordScopeEnumDto.National;
            case AdministrativeRecordScopeEnum.AutonomousCommunity:
                return AdministrativeRecordScopeEnumDto.AutonomousCommunity;
        }
    }
}
