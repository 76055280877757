import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreatePublicUtilityMapper } from "@entity/data/mappers/public-utility/create-public-interest.mapper";
import { EditPublicUtilityMapper } from "@entity/data/mappers/public-utility/edit-public-interest.mapper";
import { PublicUtility } from "@entity/domain/models/public-utility/public-utility.model";
import { CreatePublicUtilityUseCase } from "@entity/domain/usecases/public-utility/create-public-utilityusecase";
import { DeletePublicUtilityUseCase } from "@entity/domain/usecases/public-utility/delete-public-utility.usecase";
import { EditPublicUtilityUseCase } from "@entity/domain/usecases/public-utility/edit-public-utility.usecase";
import { GetAllPublicUtilitiesUsecase } from "@entity/domain/usecases/public-utility/get-all-public-utility.usecase";
import { PublicUtilityFormValuesValidated } from "@entity/presentation/component/entity-form/general/public-utility/form/public-utility-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class PublicUtilityViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    publicUtilities: PublicUtility[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddPublicUtilityModal: boolean = false;

    @observable
    showEditPublicUtilityModal: boolean = false;

    @observable
    publicUtilityToEdit: Undefinable<PublicUtility> = undefined;

    constructor(
        @inject(GetAllPublicUtilitiesUsecase)
        private readonly getAllPublicUtilitiesUsecase: GetAllPublicUtilitiesUsecase,
        @inject(CreatePublicUtilityMapper)
        private readonly createPublicUtilityMapper: CreatePublicUtilityMapper,
        @inject(EditPublicUtilityMapper)
        private readonly editPublicUtilityMapper: EditPublicUtilityMapper,
        @inject(CreatePublicUtilityUseCase)
        private readonly createPublicUtilityUseCase: CreatePublicUtilityUseCase,
        @inject(EditPublicUtilityUseCase)
        private readonly editPublicUtilityUseCase: EditPublicUtilityUseCase,
        @inject(DeletePublicUtilityUseCase)
        private readonly deletePublicUtilityUseCase: DeletePublicUtilityUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getPublicUtilities()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getPublicUtilities(): Promise<void> {
        const publicUtilities =
            await this.getAllPublicUtilitiesUsecase.execute();
        runInAction(() => {
            this.publicUtilities = publicUtilities;
        });
    }

    @action
    setShowAddPublicUtilityModal(show: boolean): void {
        this.showAddPublicUtilityModal = show;
    }

    @action
    closeEditPublicUtilityModal(): void {
        this.showEditPublicUtilityModal = false;
    }

    @action
    openEditPublicUtilityModal(
        publicUtility: Undefinable<PublicUtility>,
    ): void {
        this.publicUtilityToEdit = publicUtility;
        this.showEditPublicUtilityModal = true;
    }

    async addPublicUtility(
        entityId: Undefinable<number>,
        values: PublicUtilityFormValuesValidated,
    ): Promise<Nullable<PublicUtility>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createPublicUtilityResult =
            await this.createPublicUtilityUseCase.execute(
                this.createPublicUtilityMapper.mapFromFormValues(
                    entityId,
                    values,
                ),
            );

        if (createPublicUtilityResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createPublicUtilityResult;
    }

    async editPublicUtility(
        values: PublicUtilityFormValuesValidated,
    ): Promise<void> {
        if (!this.publicUtilityToEdit?.id || !this.publicUtilityToEdit.entity)
            throw Error("Missing publicUtility");

        LoadLayoutStore.start();
        const editPublicUtilityResult =
            await this.editPublicUtilityUseCase.execute(
                this.editPublicUtilityMapper.mapFromModal(
                    this.publicUtilityToEdit.id,
                    this.publicUtilityToEdit.entity,
                    values,
                ),
            );

        if (editPublicUtilityResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadPublicUtilities(): Promise<void> {
        await this.getPublicUtilities();
    }

    async deletePublicUtility(publicUtilityId: number): Promise<void> {
        LoadLayoutStore.start();

        await this.deletePublicUtilityUseCase.execute(publicUtilityId);

        LoadLayoutStore.finish();
    }
}
