import { InternalNotesRepository } from "@beneficiary/data/repositories/internal-notes.repository";
import { CreateInternalNote } from "@beneficiary/domain/models/internal-notes/create-internal-note.model";
import { InternalNote } from "@beneficiary/domain/models/internal-notes/internal-note.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class CreateInternalNoteUseCase {
    constructor(
        @inject(InternalNotesRepository)
        private readonly internalNotesRepository: InternalNotesRepository,
    ) {}

    async execute(
        internalNote: CreateInternalNote,
    ): Promise<Nullable<InternalNote>> {
        const creationResult =
            await this.internalNotesRepository.create(internalNote);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdInternalNote) => createdInternalNote,
        );
    }
}
