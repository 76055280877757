import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum MandateTerminationReasonEnumDto {
    resignation = "resignation",
    electoral_mandate_end = "electoral_mandate_end",
    sickness = "sickness",
    incompatibility = "incompatibility",
    death = "death",
    no_renovation = "no_renovation",
    other = "other",
    "" = "",
}

export class MandateTerminationReasonDto {
    @IsEnum(MandateTerminationReasonEnumDto)
    @IsNotEmpty()
    key!: MandateTerminationReasonEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
