import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum EntityTerritorialScopeEnumDto {
    LOCAL = "local",
    AUTONOMOUS_COMMUNITY = "autonomous_community",
    NATIONAL = "national",
    INTERNATIONAL = "international",
}

export class EntityTerritorialScopeDto {
    @IsEnum(EntityTerritorialScopeEnumDto)
    @IsNotEmpty()
    key!: EntityTerritorialScopeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
