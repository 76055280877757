import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { TechnicalProposalRepository } from "@project/data/repositories/technical-proposal/technical-proposal.repository";
import { inject, injectable } from "inversify";

@injectable()
export class ExportTechnicalProposalUseCase {
    constructor(
        @inject(TechnicalProposalRepository)
        private readonly technicalProposalRepository: TechnicalProposalRepository,
    ) {}

    async execute(technicalProposalId: number): Promise<Blob> {
        const technicalProposalResult =
            await this.technicalProposalRepository.export(technicalProposalId);

        const blob = technicalProposalResult.getOrThrow();

        if (technicalProposalResult.isLeft()) {
            ToastManagerStore.error(
                technicalProposalResult.getLeftOrThrow().message,
            );
        }

        return blob;
    }
}
