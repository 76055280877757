import { LanguageRepository } from "@core/data/repositories/language.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { Language } from "../models/language.model";

@injectable()
export class GetAllLanguagesUseCase {
    constructor(
        @inject(LanguageRepository)
        private readonly languageRepository: LanguageRepository,
    ) {}

    async execute(): Promise<Language[]> {
        const languagesResult = await this.languageRepository.getAll();

        if (languagesResult.isLeft()) {
            ToastManagerStore.error(languagesResult.getLeftOrThrow().message);
        }

        return languagesResult.fold(
            () => [],
            (languages) => languages,
        );
    }
}
