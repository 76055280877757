import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum PositionTypeEnumDto {
    president = "president",
    vicepresident = "vicepresident",
    secretary = "secretary",
    treasurer = "treasurer",
    vocal = "vocal",
    other = "other",
}

export class PositionTypeDto {
    @IsEnum(PositionTypeEnumDto)
    @IsNotEmpty()
    key!: PositionTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
