import { FinancialEntitiesDto } from "@project/data/dto/financial-entity/financial-entities.dto";
import { FinancialEntityMapper } from "@project/data/mappers/financial-entity/financial-entity-mapper";
import { FinancialEntities } from "@project/domain/models/financial-entity/financial-entities.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class FinancialEntitiesMapper {
    constructor(
        @inject(FinancialEntityMapper)
        private readonly financialEntityMapper: FinancialEntityMapper,
    ) {}

    map(financialEntitiesDto: FinancialEntitiesDto): FinancialEntities {
        const errors = validateSync(financialEntitiesDto);

        if (errors.length) {
            console.error(errors);

            return new FinancialEntities([], 0);
        }

        const financialEntities = financialEntitiesDto.results.mapNotNull(
            (financialEntityDto) =>
                this.financialEntityMapper.map(financialEntityDto),
        );

        return new FinancialEntities(
            financialEntities,
            financialEntitiesDto.count,
        );
    }
}
