import { Nullable } from "@core/domain/types/nullable.type";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { GlobalEntityDto } from "../dto/global-entity.dto";

@injectable()
export class GlobalEntityMapper {
    map(globalEntityDto: GlobalEntityDto): Nullable<GlobalEntity> {
        const errors = validateSync(globalEntityDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        return new GlobalEntity(
            globalEntityDto.id,
            globalEntityDto.company_name,
        );
    }
}
