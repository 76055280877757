import { EmployeesTypeCountDto } from "@entity/data/dto/employee/employee-type-count.dto";
import { EmployeesCount } from "@entity/domain/models/employee/employee.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class EmployeesCountMapper {
    map(employeesCountDto: EmployeesTypeCountDto): EmployeesCount {
        const errors = validateSync(employeesCountDto);

        if (errors.length) {
            console.error(errors);
        }

        return new EmployeesCount(
            employeesCountDto.employee,
            employeesCountDto.volunteer,
            employeesCountDto.freelance,
        );
    }
}
