import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum StatusTypeEnumDto {
    LOAN = "loan",
    PROPERTY = "property",
    RENT = "rent",
    OTHER = "other",
}

export class StatusTypeDto {
    @IsEnum(StatusTypeEnumDto)
    @IsNotEmpty()
    key!: StatusTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
