import { ProjectMaterialRepository } from "@project/data/repositories/project-material.repository";
import {
    ProjectMaterial,
    SearchAllProjectMaterialBy,
} from "@project/domain/models/project-material/project-material.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllProjectMaterialsUseCase {
    constructor(
        @inject(ProjectMaterialRepository)
        private readonly projectMaterialRepository: ProjectMaterialRepository,
    ) {}
    async execute(
        filters?: SearchAllProjectMaterialBy,
    ): Promise<ProjectMaterial[]> {
        const projectMaterialsResult =
            await this.projectMaterialRepository.getAllBy(filters);

        return projectMaterialsResult.fold(
            () => [],
            (projectMaterials) => projectMaterials,
        );
    }
}
