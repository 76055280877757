import { EntitySummaryMapper } from "@entity/data/mappers/entity-summary.mapper";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { EntitiesSummary } from "../../domain/models/entity-summary.model";
import { EntitiesSummaryDto } from "../dto/entities-summary.dto";

@injectable()
export class EntitiesSummaryMapper {
    constructor(
        @inject(EntitySummaryMapper)
        private readonly entitySummaryMapper: EntitySummaryMapper,
    ) {}

    map(entitiesSummaryDto: EntitiesSummaryDto): EntitiesSummary {
        const errors = validateSync(entitiesSummaryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new EntitiesSummary([], 0);
        }

        const entitiesSummary = entitiesSummaryDto.results.mapNotNull(
            (collectiveDto) => this.entitySummaryMapper.map(collectiveDto),
        );

        return new EntitiesSummary(entitiesSummary, entitiesSummaryDto.count);
    }
}
