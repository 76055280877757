import {
    ProjectSummary,
    ProjectSummaryTransformer,
} from "@project/domain/models/project-summary.model";

export class ProjectsSummary {
    constructor(
        public readonly projectsSummary: ProjectSummary[],
        public readonly count: number,
    ) {}
}

// This interface is intended to be used as an intermediate step to build the final ProjectsSummary model
export interface ProjectsSummaryTransformer {
    projects: ProjectSummaryTransformer[];
    count: number;
}
