import { LanguageIsoCodeEnumMapper } from "@core/data/mappers/language-iso-code-enum.mapper";
import { LanguageIsoCodeEnum } from "@core/domain/models/language.model";
import { inject, injectable } from "inversify";
import { UpdateCurrentLanguageBody } from "../dto/update-current-language.dto";

@injectable()
export class UpdateCurrentLanguageMapper {
    constructor(
        @inject(LanguageIsoCodeEnumMapper)
        private readonly languageIsoCodeEnumMapper: LanguageIsoCodeEnumMapper,
    ) {}

    mapToDto(language: LanguageIsoCodeEnum): UpdateCurrentLanguageBody {
        const languageDto = this.languageIsoCodeEnumMapper.mapToDto(language);
        return { language: languageDto };
    }
}
