import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum MaritalStatusEnumDto {
    Single = "single",
    Married = "married",
    CommonLaw = "common_law",
    Separated = "separated",
    Divorced = "divorced",
    Widowed = "widowed",
}

export class MaritalStatusDto {
    @IsEnum(MaritalStatusEnumDto)
    @IsNotEmpty()
    key!: MaritalStatusEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
