import { Undefinable } from "@core/domain/types/undefinable.type";
import { DisabilityTypeEnum } from "@entity/domain/models/employee/disability-type.model";
import { ProfessionalGroupEnum } from "@entity/domain/models/employee/professional-group.model";
import { SubsidyReasonEnum } from "@entity/domain/models/employee/subsidy-reason.model";
import { TerminationReasonEnum } from "@entity/domain/models/employee/termination-reason.model";
import { ContractTypeEnum } from "@project/domain/models/expenses/contract-type.model";
import { ContributionGroupTypeEnum } from "@project/domain/models/expenses/contribution-group-type.model";
import { DateTime } from "luxon";

export class EmployeeContract {
    // eslint-disable-next-line max-params
    constructor(
        public readonly id: number,
        public type: ContractTypeEnum,
        public startDate: DateTime,
        public hasDisability: boolean,
        public professionalGroup: ProfessionalGroupEnum,
        public contributionGroup: ContributionGroupTypeEnum,
        public weeklyWorkdayHours: number,
        public netSalary: number,
        public irpf: number,
        public socialSecurityContribution: number,
        public monthlyCost: number,
        public socialSecurityContributionCompany: number,
        public totalCost: number,
        public deductibleAmountDisability: number,
        public employeeId: number,
        public disabilityType?: Undefinable<DisabilityTypeEnum>,
        public terminationReason?: Undefinable<TerminationReasonEnum>,
        public otherType?: Undefinable<string>,
        public isSubsidized?: Undefinable<boolean>,
        public subsidyDeductibleAmount?: Undefinable<number>,
        public subsidyReason?: Undefinable<SubsidyReasonEnum>,
        public otherSubsidyReason?: Undefinable<string>,
        public subsidyScope?: Undefinable<string>,
        public endDate?: Undefinable<DateTime>,
    ) {}
}

export class EmployeeContracts {
    constructor(
        public readonly employeeContracts: EmployeeContract[],
        public readonly count: number,
    ) {}
}

export interface EmployeeContractSearchFilters {
    employee?: Undefinable<number>;
}
