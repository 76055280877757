import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { BudgetType } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllBudgetTypesUseCase {
    constructor(
        @inject(FinancialEntityRepository)
        private readonly financialEntityRepository: FinancialEntityRepository,
    ) {}

    async execute(): Promise<BudgetType[]> {
        const budgetTypesResult =
            await this.financialEntityRepository.getBudgetTypes();

        return budgetTypesResult.getOrElse([]);
    }
}
