import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { AuthorityManagerRepository } from "@entity/data/repositories/authority-manager.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteAuthorityManagerUseCase {
    constructor(
        @inject(AuthorityManagerRepository)
        private readonly authorityManagerRepository: AuthorityManagerRepository,
    ) {}

    async execute(authorityManagerId: number): Promise<void> {
        const result =
            await this.authorityManagerRepository.delete(authorityManagerId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        ToastManagerStore.success();
    }
}
