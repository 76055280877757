import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectRepository } from "@project/data/repositories/project.repository";
import { inject, injectable } from "inversify";

@injectable()
export class ExportProjectsUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(entityId: number): Promise<Blob> {
        const projectssResult =
            await this.projectRepository.exportProjects(entityId);

        if (projectssResult.isLeft()) {
            ToastManagerStore.error(projectssResult.getLeftOrThrow().message);
        }

        return projectssResult.getOrThrow();
    }
}
