export enum AdministrativeRecordScopeEnum {
    Local = "local",
    National = "national",
    AutonomousCommunity = "autonomous_community",
}

export class AdministrativeRecordScope {
    constructor(
        public readonly id: AdministrativeRecordScopeEnum,
        public readonly label: string,
    ) {}
}
