import { IncDocument } from "@core/domain/models/inc-document.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { DateTime } from "luxon";

export class MaterialData {
    constructor(
        public readonly id: number,
        public readonly name: string,
    ) {}
}

export class ProjectMaterial {
    constructor(
        public readonly id: number,
        public materialId: number,
        public projectId: number,
        public hoursDedicated?: Nullable<number>,
        public documents?: Nullable<IncDocument[]>,
        public materialData?: Nullable<MaterialData>,
        public startDate?: Nullable<DateTime>,
        public endDate?: Nullable<DateTime>,
    ) {}
}

export class ProjectMaterials {
    constructor(
        public readonly projectMaterials: ProjectMaterial[],
        public readonly count: number,
    ) {}
}

export interface SearchAllProjectMaterialBy {
    projectId?: Undefinable<number>;
    materialId?: Undefinable<number>;
}
