import { PolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/policy-protocol.model";
export class CreatePolicyProtocol {
    constructor(
        public name: PolicyProtocol["name"],
        public policiesProtocols: PolicyProtocol["policiesProtocols"],
        public realizationYear: PolicyProtocol["realizationYear"],
        public entity: PolicyProtocol["entity"],
        public otherPoliciesProtocols?: PolicyProtocol["otherPoliciesProtocols"],
        public expirationDate?: PolicyProtocol["expirationDate"],
    ) {}
}
