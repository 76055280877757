import { CenterTypeEnumDto } from "@beneficiary/data/dto/center-type.dto";
import { DocumentDto } from "@core/data/dto/document.dto";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class ExternalDerivationDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    reason!: string;

    @IsEnum(CenterTypeEnumDto)
    @IsNotEmpty()
    center_type!: CenterTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    center_name!: string;

    @IsBoolean()
    @IsOptional()
    is_derivate_to_external!: Nullable<boolean>;

    @IsNumber()
    @IsNotEmpty()
    beneficiary!: number;

    @IsString()
    @IsOptional()
    contact_name!: Nullable<string>;

    @IsString()
    @IsOptional()
    contact_phone!: Nullable<string>;

    @IsString()
    @IsOptional()
    contact_email!: Nullable<string>;

    @IsNumber(undefined, { each: true })
    @IsOptional()
    documents!: Nullable<number[]>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    documents_data!: Nullable<DocumentDto[]>;
}

export class ExternalDerivationsDto extends PaginatedDto {
    @IsArray()
    @Type(() => ExternalDerivationDto)
    results!: ExternalDerivationDto[];
}

export type ExternalDerivationsQuery = PaginatedQuery & {
    beneficiary_id?: number;
};
