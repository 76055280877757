import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { SocialNetworkRepository } from "@entity/data/repositories/social-network.repository";
import { inject, injectable } from "inversify";
import { EditSocialNetwork } from "../../models/edit-social-network.model";
import { SocialNetwork } from "../../models/social-network.model";

@injectable()
export class EditSocialNetworkUseCase {
    constructor(
        @inject(SocialNetworkRepository)
        private readonly socialNetworkRepository: SocialNetworkRepository,
    ) {}

    async execute(
        socialNetwork: EditSocialNetwork,
    ): Promise<Nullable<SocialNetwork>> {
        const editResult =
            await this.socialNetworkRepository.edit(socialNetwork);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedSocialNetwork) => editedSocialNetwork,
        );
    }
}
