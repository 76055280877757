export enum SupplierIdentificationTypeEnum {
    nif = "nif",
    cif = "cif",
}

export class SupplierIdentificationType {
    constructor(
        public readonly id: SupplierIdentificationTypeEnum,
        public readonly label: string,
    ) {}
}
