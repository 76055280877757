import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { CreditorRepository } from "@entity/data/repositories/creditor.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteCreditorUseCase {
    constructor(
        @inject(CreditorRepository)
        private readonly creditorRepository: CreditorRepository,
    ) {}

    async execute(creditorId: number): Promise<void> {
        const result = await this.creditorRepository.delete(creditorId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        if (result.isRight()) {
            ToastManagerStore.success("Creditor deleted successfully");
        }
    }
}
