import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    DepositAnotherSourcesFinancingTypeEnum,
    DepositAnotherSourcesFinancingTypes,
    DepositGeneralTypes,
    DepositOwnFinancingTypeEnum,
    DepositOwnFinancingTypes,
    DepositSubventionTypeEnum,
    DepositSubventionTypes,
    DepositTypeEnum,
    DepositTypes,
} from "../../domain/models/deposit-type.model";
import {
    DepositAnotherSourcesFinancingTypesDto,
    DepositGeneralTypesDto,
    DepositOwnFinancingTypesDto,
    DepositSubventionTypesDto,
    DepositTypesDto,
} from "../dto/deposit-types.dto";
import { DepositTypeEnumMapper } from "./deposit-type-enum.mapper";
import { DepositAnotherSourceTypeEnumMapper } from "./deposit-type-other-source-financing-enum.mapper";
import { DepositOwnFinancingTypeEnumMapper } from "./deposit-type-own-financing-enum.mapper";
import { DepositSubventionTypeEnumMapper } from "./deposit-type-subvention-enum.mapper";

@injectable()
export class DepositTypesMapper {
    constructor(
        @inject(DepositTypeEnumMapper)
        private readonly depositTypeEnumMapper: DepositTypeEnumMapper,
        @inject(DepositOwnFinancingTypeEnumMapper)
        private readonly depositOwnFinancingTypeEnumMapper: DepositOwnFinancingTypeEnumMapper,
        @inject(DepositSubventionTypeEnumMapper)
        private readonly depositSubventionTypeEnumMapper: DepositSubventionTypeEnumMapper,
        @inject(DepositAnotherSourceTypeEnumMapper)
        private readonly depositAnotherSourceTypeEnumMapper: DepositAnotherSourceTypeEnumMapper,
    ) {}

    map(
        depositTypesDto: DepositGeneralTypesDto,
    ): Nullable<DepositGeneralTypes> {
        const errors = validateSync(DepositGeneralTypesDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const depositTypes: Nullable<DepositTypes>[] =
            depositTypesDto.types.map((type) => this.mapTypeFromDto(type));
        const depositTypesFiltered = depositTypes.filter(
            (type) => type !== null,
        ) as DepositTypes[];

        const depositOwnFinancingTypes: Nullable<DepositOwnFinancingTypes>[] =
            depositTypesDto.own_financing_types.map((type) =>
                this.mapTypeOwnFinancingFromDto(type),
            );
        const depositOwnFinancingTypesFiltered =
            depositOwnFinancingTypes.filter(
                (type) => type !== null,
            ) as DepositOwnFinancingTypes[];

        const depositAnotherSourcesFinancingTypes: Nullable<DepositAnotherSourcesFinancingTypes>[] =
            depositTypesDto.others_source_financing_type.map((type) =>
                this.mapTypeAnotherSourceFinancingFromDto(type),
            );
        const depositAnotherSourcesFinancingTypesFiltered =
            depositAnotherSourcesFinancingTypes.filter(
                (type) => type !== null,
            ) as DepositAnotherSourcesFinancingTypes[];

        const depositSubventionTypes: Nullable<DepositSubventionTypes>[] =
            depositTypesDto.subvention_types.map((type) =>
                this.mapTypeSubventionFromDto(type),
            );
        const depositSubventionTypesFiltered = depositSubventionTypes.filter(
            (type) => type !== null,
        ) as DepositSubventionTypes[];

        return new DepositGeneralTypes(
            depositTypesFiltered,
            depositOwnFinancingTypesFiltered,
            depositAnotherSourcesFinancingTypesFiltered,
            depositSubventionTypesFiltered,
        );
    }

    mapTypeFromDto(depositTypesDto: DepositTypesDto): Nullable<DepositTypes> {
        const errors = validateSync(DepositTypesDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const depositTypeEnum: Nullable<DepositTypeEnum> =
            this.depositTypeEnumMapper.map(depositTypesDto.key);

        if (!depositTypeEnum) {
            return null;
        }

        return new DepositTypes(depositTypeEnum, depositTypesDto.value);
    }

    mapTypeOwnFinancingFromDto(
        depositTypesDto: DepositOwnFinancingTypesDto,
    ): Nullable<DepositOwnFinancingTypes> {
        const errors = validateSync(DepositTypesDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const depositOwnFinancingTypeEnum: Nullable<DepositOwnFinancingTypeEnum> =
            this.depositOwnFinancingTypeEnumMapper.map(depositTypesDto.key);

        if (!depositOwnFinancingTypeEnum) {
            return null;
        }

        return new DepositOwnFinancingTypes(
            depositOwnFinancingTypeEnum,
            depositTypesDto.value,
        );
    }

    mapTypeAnotherSourceFinancingFromDto(
        depositTypesDto: DepositAnotherSourcesFinancingTypesDto,
    ): Nullable<DepositAnotherSourcesFinancingTypes> {
        const errors = validateSync(DepositTypesDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const depositAnotherSourceTypeEnum: Nullable<DepositAnotherSourcesFinancingTypeEnum> =
            this.depositAnotherSourceTypeEnumMapper.map(depositTypesDto.key);

        if (!depositAnotherSourceTypeEnum) {
            return null;
        }

        return new DepositAnotherSourcesFinancingTypes(
            depositAnotherSourceTypeEnum,
            depositTypesDto.value,
        );
    }

    mapTypeSubventionFromDto(
        depositTypesDto: DepositSubventionTypesDto,
    ): Nullable<DepositSubventionTypes> {
        const errors = validateSync(DepositTypesDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const depositSubventionTypeEnum: Nullable<DepositSubventionTypeEnum> =
            this.depositSubventionTypeEnumMapper.map(depositTypesDto.key);

        if (!depositSubventionTypeEnum) {
            return null;
        }

        return new DepositSubventionTypes(
            depositSubventionTypeEnum,
            depositTypesDto.value,
        );
    }
}
