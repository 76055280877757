import { PartnersNumberDto } from "@entity/data/dto/partners-number/partners-number.dto";
import { CCAAEnumMapper } from "@entity/data/mappers/ccaa-enum.mapper";
import { PartnersNumber } from "@entity/domain/models/partners-number/partners-number.model";
import { inject, injectable } from "inversify";

@injectable()
export class PartnersNumberMapper {
    constructor(
        @inject(CCAAEnumMapper)
        private readonly ccaaEnumMapper: CCAAEnumMapper,
    ) {}
    mapToDto(partnersNumber: PartnersNumber): PartnersNumberDto {
        const registeredCcaa = this.ccaaEnumMapper.mapToDto(
            partnersNumber.registeredCcaa,
        );

        const partnersNumberDto: PartnersNumberDto = {
            id: partnersNumber.id,
            number_of_members: partnersNumber.numberOfMembers,
            registered_ccaa: registeredCcaa,
            entity: partnersNumber.entity,
        };

        return partnersNumberDto;
    }
}
