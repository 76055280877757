import {
    LocalStorage,
    LocalStorageKeysEnum,
} from "@core/data/infrastructures/storage/local-storage";
import { Nullable } from "@core/domain/types/nullable.type";
import { inject, injectable } from "inversify";

@injectable()
export class AuthenticationStorageDatasource {
    constructor(
        @inject(LocalStorage) private readonly localStorage: LocalStorage,
    ) {}

    getAccessToken(): Nullable<string> {
        return this.localStorage.get(LocalStorageKeysEnum.AccessToken);
    }

    saveAccessToken(accessToken: string): void {
        this.localStorage.set(LocalStorageKeysEnum.AccessToken, accessToken);
    }

    deleteAccessToken(): void {
        this.localStorage.remove(LocalStorageKeysEnum.AccessToken);
    }
}
