import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum CountryEnumDto {
    Afghanistan = "AF",
    AlandIslands = "AX",
    Albania = "AL",
    Algeria = "DZ",
    AmericanSamoa = "AS",
    Andorra = "AD",
    Angola = "AO",
    Anguilla = "AI",
    Antarctica = "AQ",
    AntiguaAndBarbuda = "AG",
    Argentina = "AR",
    Armenia = "AM",
    Aruba = "AW",
    Australia = "AU",
    Austria = "AT",
    Azerbaijan = "AZ",
    Bahamas = "BS",
    Bahrain = "BH",
    Bangladesh = "BD",
    Barbados = "BB",
    Belarus = "BY",
    Belgium = "BE",
    Belize = "BZ",
    Benin = "BJ",
    Bermuda = "BM",
    Bhutan = "BT",
    Bolivia = "BO",
    BonaireSintEustatiusAndSaba = "BQ",
    BosniaAndHerzegovina = "BA",
    Botswana = "BW",
    BouvetIsland = "BV",
    Brazil = "BR",
    BritishIndianOceanTerritory = "IO",
    Brunei = "BN",
    Bulgaria = "BG",
    BurkinaFaso = "BF",
    Burundi = "BI",
    CaboVerde = "CV",
    Cambodia = "KH",
    Cameroon = "CM",
    Canada = "CA",
    CaymanIslands = "KY",
    CentralAfricanRepublic = "CF",
    Chad = "TD",
    Chile = "CL",
    China = "CN",
    ChristmasIsland = "CX",
    CocosKeelingIslands = "CC",
    Colombia = "CO",
    Comoros = "KM",
    Congo = "CG",
    CongoTheDemocraticRepublicOfThe = "CD",
    CookIslands = "CK",
    CostaRica = "CR",
    CoteDIvoire = "CI",
    Croatia = "HR",
    Cuba = "CU",
    Curacao = "CW",
    Cyprus = "CY",
    Czechia = "CZ",
    Denmark = "DK",
    Djibouti = "DJ",
    Dominica = "DM",
    DominicanRepublic = "DO",
    Ecuador = "EC",
    Egypt = "EG",
    ElSalvador = "SV",
    EquatorialGuinea = "GQ",
    Eritrea = "ER",
    Estonia = "EE",
    Eswatini = "SZ",
    Ethiopia = "ET",
    FalklandIslandsMalvinas = "FK",
    FaroeIslands = "FO",
    Fiji = "FJ",
    Finland = "FI",
    France = "FR",
    FrenchGuiana = "GF",
    FrenchPolynesia = "PF",
    FrenchSouthernTerritories = "TF",
    Gabon = "GA",
    Gambia = "GM",
    Georgia = "GE",
    Germany = "DE",
    Ghana = "GH",
    Gibraltar = "GI",
    Greece = "GR",
    Greenland = "GL",
    Grenada = "GD",
    Guadeloupe = "GP",
    Guam = "GU",
    Guatemala = "GT",
    Guernsey = "GG",
    Guinea = "GN",
    GuineaBissau = "GW",
    Guyana = "GY",
    Haiti = "HT",
    HeardIslandAndMcDonaldIslands = "HM",
    HolySee = "VA",
    Honduras = "HN",
    HongKong = "HK",
    Hungary = "HU",
    Iceland = "IS",
    India = "IN",
    Indonesia = "ID",
    Iran = "IR",
    Iraq = "IQ",
    Ireland = "IE",
    IsleOfMan = "IM",
    Israel = "IL",
    Italy = "IT",
    Jamaica = "JM",
    Japan = "JP",
    Jersey = "JE",
    Jordan = "JO",
    Kazakhstan = "KZ",
    Kenya = "KE",
    Kiribati = "KI",
    Kuwait = "KW",
    Kyrgyzstan = "KG",
    Laos = "LA",
    Latvia = "LV",
    Lebanon = "LB",
    Lesotho = "LS",
    Liberia = "LR",
    Libya = "LY",
    Liechtenstein = "LI",
    Lithuania = "LT",
    Luxembourg = "LU",
    Macao = "MO",
    Madagascar = "MG",
    Malawi = "MW",
    Malaysia = "MY",
    Maldives = "MV",
    Mali = "ML",
    Malta = "MT",
    MarshallIslands = "MH",
    Martinique = "MQ",
    Mauritania = "MR",
    Mauritius = "MU",
    Mayotte = "YT",
    Mexico = "MX",
    MicronesiaFederatedStatesOf = "FM",
    Moldova = "MD",
    Monaco = "MC",
    Mongolia = "MN",
    Montenegro = "ME",
    Montserrat = "MS",
    Morocco = "MA",
    Mozambique = "MZ",
    Myanmar = "MM",
    Namibia = "NA",
    Nauru = "NR",
    Nepal = "NP",
    Netherlands = "NL",
    NewCaledonia = "NC",
    NewZealand = "NZ",
    Nicaragua = "NI",
    Niger = "NE",
    Nigeria = "NG",
    Niue = "NU",
    NorfolkIsland = "NF",
    NorthKorea = "KP",
    NorthMacedonia = "MK",
    NorthernMarianaIslands = "MP",
    Norway = "NO",
    Oman = "OM",
    Pakistan = "PK",
    Palau = "PW",
    PalestineStateOf = "PS",
    Panama = "PA",
    PapuaNewGuinea = "PG",
    Paraguay = "PY",
    Peru = "PE",
    Philippines = "PH",
    Pitcairn = "PN",
    Poland = "PL",
    Portugal = "PT",
    PuertoRico = "PR",
    Qatar = "QA",
    Reunion = "RE",
    Romania = "RO",
    Russia = "RU",
    Rwanda = "RW",
    SaintBarthelemy = "BL",
    SaintHelenaAscensionAndTristanDaCunha = "SH",
    SaintKittsAndNevis = "KN",
    SaintLucia = "LC",
    SaintMartinFrenchPart = "MF",
    SaintPierreAndMiquelon = "PM",
    SaintVincentAndTheGrenadines = "VC",
    Samoa = "WS",
    SanMarino = "SM",
    SaoTomeAndPrincipe = "ST",
    SaudiArabia = "SA",
    Senegal = "SN",
    Serbia = "RS",
    Seychelles = "SC",
    SierraLeone = "SL",
    Singapore = "SG",
    SintMaartenDutchPart = "SX",
    Slovakia = "SK",
    Slovenia = "SI",
    SolomonIslands = "SB",
    Somalia = "SO",
    SouthAfrica = "ZA",
    SouthGeorgiaAndTheSouthSandwichIslands = "GS",
    SouthKorea = "KR",
    SouthSudan = "SS",
    Spain = "ES",
    SriLanka = "LK",
    Sudan = "SD",
    Suriname = "SR",
    SvalbardAndJanMayen = "SJ",
    Sweden = "SE",
    Switzerland = "CH",
    Syria = "SY",
    Taiwan = "TW",
    Tajikistan = "TJ",
    Tanzania = "TZ",
    Thailand = "TH",
    TimorLeste = "TL",
    Togo = "TG",
    Tokelau = "TK",
    Tonga = "TO",
    TrinidadAndTobago = "TT",
    Tunisia = "TN",
    Turkiye = "TR",
    Turkmenistan = "TM",
    TurksAndCaicosIslands = "TC",
    Tuvalu = "TV",
    Uganda = "UG",
    Ukraine = "UA",
    UnitedArabEmirates = "AE",
    UnitedKingdom = "GB",
    UnitedStatesMinorOutlyingIslands = "UM",
    UnitedStatesOfAmerica = "US",
    Uruguay = "UY",
    Uzbekistan = "UZ",
    Vanuatu = "VU",
    Venezuela = "VE",
    Vietnam = "VN",
    VirginIslandsBritish = "VG",
    VirginIslandsUS = "VI",
    WallisAndFutuna = "WF",
    WesternSahara = "EH",
    Yemen = "YE",
    Zambia = "ZM",
    Zimbabwe = "ZW",
}

export class CountryDto {
    @IsEnum(CountryEnumDto)
    @IsNotEmpty()
    code!: CountryEnumDto;

    @IsString()
    @IsNotEmpty()
    name!: string;
}
