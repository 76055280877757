import { Pagination } from "@core/domain/models/pagination";
import { PolicyProtocolRepository } from "@entity/data/repositories/policy-protocol.repository";
import { PolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/policy-protocol.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllPolicyProtocolsUsecase {
    constructor(
        @inject(PolicyProtocolRepository)
        private readonly policyProtocolRepository: PolicyProtocolRepository,
    ) {}

    async execute(): Promise<PolicyProtocol[]> {
        const policyProtocolResults =
            await this.policyProtocolRepository.getAll(
                Pagination.NoPagination(),
            );

        return policyProtocolResults.fold(
            () => [],
            (policyProtocols) => policyProtocols.policyProtocols,
        );
    }
}
