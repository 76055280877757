import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { PublicInterestDatasource } from "@entity/data/datasources/public-interest.datasource";
import { CreatePublicInterest } from "@entity/domain/models/public-interest/create-public-interest.model";
import { EditPublicInterest } from "@entity/domain/models/public-interest/edit-public-interest.model";
import {
    PublicInterest,
    PublicInterests,
} from "@entity/domain/models/public-interest/public-interest.model";
import { inject, injectable } from "inversify";

@injectable()
export class PublicInterestRepository {
    constructor(
        @inject(PublicInterestDatasource)
        private readonly publicInterestDatasource: PublicInterestDatasource,
    ) {}

    async getAll(
        pagination: Pagination,
    ): Promise<Either<FallbackError, PublicInterests>> {
        const publicInterestResult =
            await this.publicInterestDatasource.fetchAll(pagination);

        return publicInterestResult.mapLeft(() => new FallbackError());
    }

    async create(
        publicInterest: CreatePublicInterest,
    ): Promise<Either<ValidationError | FallbackError, PublicInterest>> {
        return this.publicInterestDatasource.create(publicInterest);
    }

    async edit(
        editPublicInterest: EditPublicInterest,
    ): Promise<Either<ValidationError | FallbackError, PublicInterest>> {
        return this.publicInterestDatasource.edit(editPublicInterest);
    }

    async delete(
        publicInterestId: number,
    ): Promise<Either<FallbackError, true>> {
        const publicInterestResult =
            await this.publicInterestDatasource.delete(publicInterestId);
        return publicInterestResult.mapLeft(() => new FallbackError());
    }
}
