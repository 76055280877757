import { ProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/project-auditory.model";

export class CreateProjectAuditory {
    constructor(
        public certificationEntity: ProjectAuditory["certificationEntity"],
        public year: ProjectAuditory["year"],
        public auditedProject: ProjectAuditory["auditedProject"],
        public entity: ProjectAuditory["entity"],
    ) {}
}
