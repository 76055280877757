import type { Nullable } from "@core/domain/types/nullable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Proceeding } from "@proceeding/domain/models/proceeding.model";
import { GetProceedingByIdUseCase } from "@proceeding/domain/usecases/get-proceeding-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class ProceedingDetailPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    proceedingId: number = 0;

    @observable
    proceeding?: Nullable<Proceeding>;

    constructor(
        @inject(GetProceedingByIdUseCase)
        private readonly getProceedingByIdUseCase: GetProceedingByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getProceedingById();

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setProceedingId(proceedingId: number): void {
        this.proceedingId = proceedingId;
    }

    async getProceedingById(): Promise<void> {
        const proceeding = await this.getProceedingByIdUseCase.execute(
            this.proceedingId,
        );

        runInAction(() => {
            this.proceeding = proceeding;
        });
    }
}
