import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { Status, StatusEnum } from "../../domain/models/status.model";
import { StatusDto } from "../dto/status.dto";
import { StatusEnumMapper } from "./status-enum.mapper";

@injectable()
export class StatusMapper {
    constructor(
        @inject(StatusEnumMapper)
        private readonly statusEnumMapper: StatusEnumMapper,
    ) {}

    map(proceedingStatus: StatusDto): Nullable<Status> {
        const errors = validateSync(proceedingStatus);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<StatusEnum> = this.statusEnumMapper.map(
            proceedingStatus.key,
        );

        if (!id) return null;

        return new Status(id, proceedingStatus.value);
    }
}
