import { RelativesRepository } from "@beneficiary/data/repositories/relatives.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteRelativesUsecase {
    constructor(
        @inject(RelativesRepository)
        private readonly relativesRepository: RelativesRepository,
    ) {}

    async execute(relativeId: number): Promise<boolean> {
        const relativesResult =
            await this.relativesRepository.delete(relativeId);

        if (relativesResult.isLeft()) {
            ToastManagerStore.error(relativesResult.getLeftOrThrow().message);
        }
        return relativesResult.isRight();
    }
}
