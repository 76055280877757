import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { IndicatorDataSource } from "@project/data/datasource/technical-proposal/indicator.datasource";
import { IndicatorSearchFilters } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { inject, injectable } from "inversify";
import {
    CreateIndicator,
    EditIndicator,
    Indicator,
} from "../../../domain/models/technical-proposals.model";

@injectable()
export class IndicatorRepository {
    constructor(
        @inject(IndicatorDataSource)
        private readonly indicatorDataSource: IndicatorDataSource,
    ) {}

    async getAllBy(
        filters: IndicatorSearchFilters,
    ): Promise<Either<FallbackError, Indicator[]>> {
        const pagination: Pagination = Pagination.NoPagination();
        return this.indicatorDataSource.fetchBy(pagination, filters);
    }

    async edit(
        indicator: EditIndicator,
    ): Promise<Either<ValidationError | FallbackError, Indicator>> {
        return this.indicatorDataSource.edit(indicator);
    }

    async create(
        indicator: CreateIndicator,
    ): Promise<Either<ValidationError | FallbackError, Indicator>> {
        return this.indicatorDataSource.create(indicator);
    }

    async delete(indicatorId: number): Promise<Either<FallbackError, true>> {
        return this.indicatorDataSource.delete(indicatorId);
    }

    async exportIndicatorsBy(
        technicalProposalId: number,
        filters?: IndicatorSearchFilters,
    ): Promise<Either<FallbackError, Blob>> {
        const response = this.indicatorDataSource.exportIndicatorsBy(
            technicalProposalId,
            filters,
        );
        return response;
    }
}
