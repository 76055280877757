import { Pagination } from "@core/domain/models/pagination";
import { inject, injectable } from "inversify";
import { UserRepository } from "../../data/repositories/user.repository";
import { UserSearchFilters } from "../models/user.model";
import { UsersSummary } from "../models/users-summary.model";

@injectable()
export class SearchUsersByUseCase {
    constructor(
        @inject(UserRepository)
        private readonly userRepository: UserRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: UserSearchFilters,
    ): Promise<UsersSummary> {
        const usersResult = await this.userRepository.searchAllBy(
            pagination,
            filters,
        );

        return usersResult.fold(
            () => new UsersSummary([], 0),
            (users) => users,
        );
    }
}
