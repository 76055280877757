import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { AffiliationRepository } from "@entity/data/repositories/affiliation.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteAffiliationUseCase {
    constructor(
        @inject(AffiliationRepository)
        private readonly affiliationRepository: AffiliationRepository,
    ) {}

    async execute(affiliationId: number): Promise<void> {
        const result = await this.affiliationRepository.delete(affiliationId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        ToastManagerStore.success();
    }
}
