import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import {
    IsArray,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class ExportProjectDepositsDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsArray()
    @IsNumber(undefined, { each: true })
    @IsOptional()
    project_ids!: number[];

    @IsString()
    @IsOptional()
    report_start_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    report_end_date!: Nullable<string>;
}

export type ExportProjectDepositsQuery = PaginatedQuery & {
    // Comma separated ids
    project_ids?: string;
    // Comma separated ids
    report_start_date?: string;
    // Comma separated ids
    report_end_date?: string;
};
