import { Nullable } from "@core/domain/types/nullable.type";
import { DisabilityTypeEnumDto } from "@entity/data/dto/employee/disability-type.dto";
import { DisabilityTypeEnum } from "@entity/domain/models/employee/disability-type.model";
import { injectable } from "inversify";

@injectable()
export class DisabilityTypeEnumMapper {
    map(
        disabilityTypeEnumDto: DisabilityTypeEnumDto,
    ): Nullable<DisabilityTypeEnum> {
        switch (disabilityTypeEnumDto) {
            case DisabilityTypeEnumDto.physical:
                return DisabilityTypeEnum.PHYSICAL;
            case DisabilityTypeEnumDto.mental:
                return DisabilityTypeEnum.MENTAL;
            case DisabilityTypeEnumDto.intellectual:
                return DisabilityTypeEnum.INTELLECTUAL;
            case DisabilityTypeEnumDto.sensorial:
                return DisabilityTypeEnum.SENSORIAL;
            case DisabilityTypeEnumDto.pluridiscability:
                return DisabilityTypeEnum.PLURIDISCABILITY;
            case DisabilityTypeEnumDto.none:
                return DisabilityTypeEnum.NONE;
            default:
                return null;
        }
    }

    mapToDto(disabilityTypeEnum: DisabilityTypeEnum): DisabilityTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (disabilityTypeEnum) {
            case DisabilityTypeEnum.PHYSICAL:
                return DisabilityTypeEnumDto.physical;
            case DisabilityTypeEnum.MENTAL:
                return DisabilityTypeEnumDto.mental;
            case DisabilityTypeEnum.INTELLECTUAL:
                return DisabilityTypeEnumDto.intellectual;
            case DisabilityTypeEnum.SENSORIAL:
                return DisabilityTypeEnumDto.sensorial;
            case DisabilityTypeEnum.PLURIDISCABILITY:
                return DisabilityTypeEnumDto.pluridiscability;
            case DisabilityTypeEnum.NONE:
                return DisabilityTypeEnumDto.none;
        }
    }
}
