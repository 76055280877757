import {
    AppstoreOutlined,
    BankOutlined,
    BookOutlined,
    ContainerOutlined,
    EuroOutlined,
    HomeOutlined,
    ProjectOutlined,
    SolutionOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { useUser } from "@authentication/presentation/hooks/use-user.hook";
import { IncCol } from "@core/presentacion/component/layout/col/inc-col.component";
import { IncRow } from "@core/presentacion/component/layout/row/inc-row.component";
import { BeneficiaryRoutes } from "@routes/private/beneficiary.routes";
import { EntityPaths, EntityRoutes } from "@routes/private/entity.routes";
import { HomeRoutes } from "@routes/private/home.routes";
import { ProceedingRoutes } from "@routes/private/proceeding.routes";
import { ProjectPaths, ProjectRoutes } from "@routes/private/project.routes";
import { UserRoutes } from "@routes/private/user.routes";
import { Tooltip } from "antd";
import { MenuItemGroupType, MenuItemType } from "antd/es/menu/hooks/useItems";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../../../../../public/img/logo.png";
import { IncMenu } from "../../navigation/menu/inc-menu.component";
import { IncSider } from "../sider/inc-sider.component";
import * as styles from "./sider-menu.module.scss";

export const IncSiderMenu: FC = () => {
    const navigate = useNavigate();
    const route = useLocation();
    const { t } = useTranslation();
    const user = useUser();

    const SIDER_WIDTH = 225;
    const MENU_FONT_WEIGHT = 400;

    const handleClick: MenuClickEventHandler = useCallback(
        (info): void => {
            navigate(info.key, { unstable_viewTransition: true });
        },
        [navigate],
    );

    // We have to use this method when title is too long
    const getTitleTooltip = (title: string): JSX.Element => (
        <Tooltip
            title={title}
            placement={"right"}
            className={"tooltip"}
        >
            <label>{title}</label>
        </Tooltip>
    );

    const menuItems: (MenuItemType | MenuItemGroupType)[] = useMemo(
        () => [
            {
                label: t("component:layout.incSiderMenu.home"),
                key: HomeRoutes.HOME,
                icon: <HomeOutlined />,
                style: { fontWeight: MENU_FONT_WEIGHT },
            },
            {
                label: t("component:layout.incSiderMenu.userMenuLabel"),
                key: UserRoutes.LIST,
                icon: <UserOutlined />,
                style: { fontWeight: MENU_FONT_WEIGHT },
            },
            {
                label: t("component:layout.incSiderMenu.entity.subMenuLabel"),
                key: EntityPaths.Root,
                icon: <HomeOutlined />,
                style: { fontWeight: MENU_FONT_WEIGHT },
                children: [
                    {
                        label: t(
                            "component:layout.incSiderMenu.entity.entityLabel",
                        ),
                        key: EntityRoutes.LIST,
                        icon: <HomeOutlined />,
                        style: { fontWeight: MENU_FONT_WEIGHT },
                    },
                    {
                        label: getTitleTooltip(
                            t(
                                "component:layout.incSiderMenu.entity.materialLabel",
                            ),
                        ),
                        key: EntityRoutes.LIST_MATERIAL,
                        icon: <ContainerOutlined />,
                    },
                    {
                        label: t(
                            "component:layout.incSiderMenu.entity.invoicesLabel",
                        ),
                        key: EntityRoutes.LIST_COST,
                        icon: <EuroOutlined />,
                    },
                    {
                        label: t(
                            "component:layout.incSiderMenu.entity.creditorsLabel",
                        ),
                        key: EntityRoutes.LIST_CREDITORS,
                        icon: <BankOutlined />,
                    },
                    {
                        label: getTitleTooltip(
                            t(
                                "component:layout.incSiderMenu.entity.supplierLabel",
                            ),
                        ),
                        key: EntityRoutes.LIST_SUPPLIER,
                        icon: <AppstoreOutlined />,
                    },
                    {
                        label: getTitleTooltip(
                            t(
                                "component:layout.incSiderMenu.entity.employeeLabel",
                            ),
                        ),
                        key: EntityRoutes.LIST_EMPLOYEE,
                        icon: <UserOutlined />,
                    },
                ],
            },
            {
                label: t(
                    "component:layout.incSiderMenu.project.projectMenuLabel",
                ),
                key: ProjectPaths.Root,
                icon: <ProjectOutlined />,
                style: { fontWeight: MENU_FONT_WEIGHT },
                children: [
                    {
                        label: getTitleTooltip(
                            t(
                                "component:layout.incSiderMenu.project.financialEntityListLabel",
                            ),
                        ),
                        key: ProjectRoutes.LIST_FINANCIAL_ENTITY,
                        icon: <BankOutlined />,
                        style: { fontWeight: MENU_FONT_WEIGHT },
                    },
                    {
                        label: t(
                            "component:layout.incSiderMenu.project.projectListLabel",
                        ),
                        key: ProjectRoutes.LIST,
                        icon: <ProjectOutlined />,
                        style: { fontWeight: MENU_FONT_WEIGHT },
                    },
                ],
            },
            {
                label: t("component:layout.incSiderMenu.beneficiaryMenuLabel"),
                key: BeneficiaryRoutes.LIST,
                icon: <SolutionOutlined />,
                style: { fontWeight: MENU_FONT_WEIGHT },
            },
            {
                label: t("component:layout.incSiderMenu.proceedingMenuLabel"),
                key: ProceedingRoutes.LIST,
                icon: <BookOutlined />,
                style: { fontWeight: MENU_FONT_WEIGHT },
            },
        ],
        [t],
    );

    const authorizedMenuItems: (MenuItemType | MenuItemGroupType)[] =
        useMemo(() => {
            if (user.isProfessional()) {
                return menuItems.filter(
                    (menuItem) => menuItem.key !== ProjectPaths.Root,
                );
            }
            return menuItems;
        }, [menuItems, user]);

    const selecteMenuItem = useMemo(() => {
        const menuItem = authorizedMenuItems.find((item) =>
            route.pathname.includes(item.key!.toString()),
        );

        if (!menuItem) return "";

        // Submenu
        if (Object.hasOwn(menuItem, "children")) {
            const subMenu = (menuItem as MenuItemGroupType).children!;

            const subMenuItem = subMenu.find((item) =>
                route.pathname.includes(item!.key!.toString()),
            );

            if (!subMenuItem) return "";

            return subMenuItem.key!.toString();
        }

        return menuItem.key!.toString();
    }, [authorizedMenuItems, route.pathname]);

    const openKeys = useMemo(() => {
        let keys: string[] = [];

        const menuItemWithChildrens = authorizedMenuItems.find(
            (item) =>
                route.pathname.includes(item.key!.toString()) &&
                Object.hasOwn(item, "children"),
        );

        if (menuItemWithChildrens) {
            keys = [menuItemWithChildrens.key!.toString()];
        }

        return keys;
    }, [authorizedMenuItems, route.pathname]);

    return (
        <IncSider
            collapsible
            width={SIDER_WIDTH}
        >
            <IncRow>
                <IncCol span={24}>
                    <img
                        alt={"logo"}
                        className={`${styles.logo} tw-px-3`}
                        src={logo}
                    />
                </IncCol>
            </IncRow>
            <IncMenu
                selectedKeys={[selecteMenuItem]}
                defaultOpenKeys={openKeys}
                theme={"dark"}
                mode={"inline"}
                items={authorizedMenuItems}
                onClick={handleClick}
            />
        </IncSider>
    );
};
