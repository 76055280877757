import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateProceedingMapper } from "@proceeding/data/mappers/create-proceeding.mapper";
import { Proceeding } from "@proceeding/domain/models/proceeding.model";
import { CreateProceedingUseCase } from "@proceeding/domain/usecases/create-proceeding.usecase";
import { ProceedingFormValuesValidated } from "@proceeding/presentation/components/proceeding-form.component";
import { inject, injectable } from "inversify";

@injectable()
export class CreateProceedingPageViewModel extends BaseViewModel {
    constructor(
        @inject(CreateProceedingUseCase)
        private readonly createProceedingUseCase: CreateProceedingUseCase,
        @inject(CreateProceedingMapper)
        private readonly createProceedingMapper: CreateProceedingMapper,
    ) {
        super();
    }

    async createProceeding(
        values: ProceedingFormValuesValidated,
    ): Promise<Nullable<Proceeding>> {
        LoadLayoutStore.start();

        const createdProceeding = await this.createProceedingUseCase.execute(
            this.createProceedingMapper.mapFromForm(values),
        );

        LoadLayoutStore.finish();

        if (createdProceeding) {
            ToastManagerStore.success();
        }

        return createdProceeding;
    }
}
