import { ObjectLike } from "@core/domain/types/object-like.type";
import { StringKeyof } from "@core/domain/types/string-keyof.type";

export type OrderDirection = "asc" | "desc";

export type OrderField<T extends ObjectLike<T>> = StringKeyof<T> | "modified";

export class Order<T extends ObjectLike<T>> {
    constructor(
        public readonly field: OrderField<T>,
        public readonly direction: OrderDirection,
    ) {}
}
