import { Nullable } from "@core/domain/types/nullable.type";
import { TitularityTypeDto } from "@entity/data/dto/titularity-type.dto";
import { TitularityTypeEnumMapper } from "@entity/data/mappers/titularity-type-enum.mapper";
import {
    TitularityType,
    TitularityTypeEnum,
} from "@entity/domain/models/titularity-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class TitularityTypeMapper {
    constructor(
        @inject(TitularityTypeEnumMapper)
        private readonly titularityTypeEnumMapper: TitularityTypeEnumMapper,
    ) {}

    map(titularityTypeDto: TitularityTypeDto): Nullable<TitularityType> {
        const errors = validateSync(TitularityTypeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<TitularityTypeEnum> =
            this.titularityTypeEnumMapper.map(titularityTypeDto.key);

        if (!id) return null;

        return new TitularityType(id, titularityTypeDto.value);
    }
}
