import { RelativesRepository } from "@beneficiary/data/repositories/relatives.repository";
import { CreateRelative } from "@beneficiary/domain/models/relatives/create-relative.model";
import { Relative } from "@beneficiary/domain/models/relatives/relative.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class CreateRelativesUsecase {
    constructor(
        @inject(RelativesRepository)
        private readonly relativesRepository: RelativesRepository,
    ) {}

    async execute(
        createRelative: CreateRelative,
        beneficiaryId: number,
    ): Promise<Nullable<Relative>> {
        const relativesResult = await this.relativesRepository.create(
            createRelative,
            beneficiaryId,
        );

        if (relativesResult.isLeft()) {
            ToastManagerStore.error(relativesResult.getLeftOrThrow().message);
        }

        return relativesResult.fold(
            () => null,
            (newRelative) => newRelative,
        );
    }
}
