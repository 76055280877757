import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { MaterialSubtypeEnum } from "./material-subtype.model";
import { MaterialTypeEnum } from "./material-type.model";
import { MetricUnitEnum } from "./metric-unit.model";

export class Material {
    // eslint-disable-next-line max-params
    constructor(
        public readonly id: number,
        public name: string,
        public description: Undefinable<string>,
        public typeId: MaterialTypeEnum,
        public typeLabel: string,
        public subtypeId: Nullable<MaterialSubtypeEnum>,
        public subtypeLabel: Nullable<string>,
        public othersType: Undefinable<string>,
        public metricUnit: MetricUnitEnum,
        public othersTypeUnit: Undefinable<string>,
        public count: number,
        public anualCost: number,
        public entity: number,
    ) {}
}

export class CreateMaterial {
    // eslint-disable-next-line max-params
    constructor(
        public name: Material["name"],
        public description: Material["description"],
        public typeId: Material["typeId"],
        public typeLabel: Material["typeLabel"],
        public subtypeId: Material["subtypeId"],
        public subtypeLabel: string,
        public othersType: Material["othersType"],
        public metricUnit: Material["metricUnit"],
        public othersTypeUnit: Material["othersTypeUnit"],
        public count: Material["count"],
        public anualCost: Material["anualCost"],
        public entity: Material["entity"],
    ) {}
}

export class Materials {
    constructor(
        public readonly materials: Material[],
        public readonly count: number,
    ) {}
}

export interface MaterialSearchFilters {
    name?: string;
    type?: Undefinable<MaterialTypeEnum>;
    entityIds?: Nullable<string[]>;
}
