import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { GlobalEntityRepository } from "@entity/data/repositories/global-entity.repository";
import { CreateEntity } from "@entity/domain/models/create-entity.model";
import { CreateUserEntity } from "@entity/domain/models/create-lite-entity.model";
import { Entity } from "@entity/domain/models/entity.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateEntityUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
        @inject(GlobalEntityRepository)
        private readonly globalEntityRepository: GlobalEntityRepository,
    ) {}

    async execute(
        entity: CreateEntity | CreateUserEntity,
    ): Promise<Nullable<Entity>> {
        const creationResult = await this.entityRepository.create(entity);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        this.globalEntityRepository.getAll();

        return creationResult.fold(
            () => null,
            (createdEntity) => createdEntity,
        );
    }
}
