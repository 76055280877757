import { LoginResetPasswordUseCase } from "@authentication/domain/usecases/login-reset-password.usecase";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { t } from "i18next";
import { inject, injectable } from "inversify";
import { ObjectSchema, object, ref, string } from "yup";

export interface LoginResetFormValues {
    password: string;
    passwordConfirmation: string;
}

@injectable()
export class LoginResetPageViewmodel extends BaseViewModel {
    tokenLogin: Undefinable<string> = undefined;
    private readonly PASSWORD_MIN_LENGTH = 8;

    initialValues: LoginResetFormValues = {
        password: "",
        passwordConfirmation: "",
    };

    validationSchema: ObjectSchema<LoginResetFormValues> = object({
        password: string()
            .required(t("public:authentication.reset.validation.required"))
            .min(
                this.PASSWORD_MIN_LENGTH,
                t("public:authentication.reset.validation.passwordMinLength"),
            )
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&+])[A-Za-z\d@$!%*#?&+]{8,}$/,
                t("public:authentication.reset.validation.passwordCharacters"),
            ),
        passwordConfirmation: string()
            .oneOf(
                [ref("password")],
                t(
                    "public:authentication.reset.validation.passwordConfirmation",
                ),
            )
            .required(t("public:authentication.reset.validation.required")),
    });

    constructor(
        @inject(LoginResetPasswordUseCase)
        private readonly loginResetUseCase: LoginResetPasswordUseCase,
    ) {
        super();
    }

    async submitLogin(values: LoginResetFormValues): Promise<void> {
        LoadLayoutStore.start();
        const loginResult = await this.loginResetUseCase.execute(
            values.password,
            values.passwordConfirmation,
            this.tokenLogin as string,
        );

        if (loginResult) {
            ToastManagerStore.success(t("public:authentication.reset.success"));
        }

        LoadLayoutStore.finish();
    }
}
