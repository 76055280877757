import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { ContractTerminationDatasource } from "@entity/data/datasources/contract-termination.datasource";
import {
    ContractTermination,
    ContractTerminations,
} from "@entity/domain/models/employee/contracts/termination/contract-termination.model";
import { CreateContractTermination } from "@entity/domain/models/employee/contracts/termination/create-contract-termination.model";
import { EditContractTermination } from "@entity/domain/models/employee/contracts/termination/edit-contract-termination.model";
import { TerminationReason } from "@entity/domain/models/employee/termination-reason.model";
import { inject, injectable } from "inversify";

@injectable()
export class ContractTerminationRepository {
    constructor(
        @inject(ContractTerminationDatasource)
        private readonly contractTerminationDatasource: ContractTerminationDatasource,
    ) {}

    async getAllTerminationReasons(): Promise<
        Either<FallbackError, TerminationReason[]>
    > {
        return this.contractTerminationDatasource.fetchAllTerminationReasons();
    }

    async getAllContractTerminations(): Promise<
        Either<FallbackError, ContractTerminations>
    > {
        return this.contractTerminationDatasource.fetchAllContractTerminations();
    }

    async createContractTermination(
        contractTermination: CreateContractTermination,
    ): Promise<Either<ValidationError | FallbackError, ContractTermination>> {
        return this.contractTerminationDatasource.createContractTermination(
            contractTermination,
        );
    }

    async editContractTermination(
        contractTermination: EditContractTermination,
    ): Promise<Either<ValidationError | FallbackError, ContractTermination>> {
        return this.contractTerminationDatasource.updateContractTermination(
            contractTermination,
        );
    }

    async deleteContractTermination(
        contractTerminationId: number,
    ): Promise<Either<FallbackError, true>> {
        return this.contractTerminationDatasource.deleteContractTermination(
            contractTerminationId,
        );
    }
}
