import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    DietType,
    DietTypeEnum,
} from "../../../domain/models/expenses/diet-type.model";
import { DietTypeDto } from "../../dto/expenses/diet-type.dto";
import { DietTypeEnumMapper } from "./diet-type-enum.mapper";

@injectable()
export class DietTypeMapper {
    constructor(
        @inject(DietTypeEnumMapper)
        private readonly dietTypeEnumMapper: DietTypeEnumMapper,
    ) {}

    map(dietTypeDto: DietTypeDto): Nullable<DietType> {
        const errors = validateSync(dietTypeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<DietTypeEnum> = this.dietTypeEnumMapper.map(
            dietTypeDto.key,
        );

        if (!id) return null;

        return new DietType(id, dietTypeDto.value);
    }
}
