import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { TitularityTypesRepository } from "@entity/data/repositories/titularity-types.repository";
import { TitularityType } from "@entity/domain/models/titularity-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllTitularityTypesUseCase {
    constructor(
        @inject(TitularityTypesRepository)
        private readonly titularityTypesRepository: TitularityTypesRepository,
    ) {}

    async execute(): Promise<TitularityType[]> {
        const titularityTypesResult =
            await this.titularityTypesRepository.getAllTitularityTypes();

        if (titularityTypesResult.isLeft()) {
            ToastManagerStore.error(
                titularityTypesResult.getLeftOrThrow().message,
            );
        }

        return titularityTypesResult.fold(
            () => [],
            (titularityTypes) => titularityTypes,
        );
    }
}
