import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum IRPFTypeEnumDto {
    Profesional = "profesional",
    Entrepeneur = "entrepeneur",
    ActModule = "act_module",
    Farm = "farm",
    Forest = "forest",
    PigFarm = "pig_farm",
    "" = "",
}

export class IRPFTypeDto {
    @IsEnum(IRPFTypeEnumDto)
    @IsNotEmpty()
    key!: IRPFTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
