import { CostsSummary } from "@entity/domain/models/cost/cost-summary.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { CostsDto } from "../../dto/cost/costs.dto";
import { CostMapper } from "./cost.mapper";

@injectable()
export class CostsMapper {
    constructor(
        @inject(CostMapper)
        private readonly costMapper: CostMapper,
    ) {}

    map(costsDto: CostsDto): CostsSummary {
        const errors = validateSync(costsDto);

        if (errors.length) {
            console.error(errors);
            return new CostsSummary([], 0);
        }

        const costsSummary = costsDto.results.mapNotNull((costDto) =>
            this.costMapper.mapToCostSummary(costDto),
        );

        return new CostsSummary(costsSummary, costsDto.count);
    }
}
