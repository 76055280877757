import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    MonthType,
    MonthTypeEnum,
} from "../../../domain/models/expenses/month-type.model";
import { MonthTypeDto } from "../../dto/expenses/month-type.dto";
import { MonthTypeEnumMapper } from "./month-type-enum.mapper";

@injectable()
export class MonthTypeMapper {
    constructor(
        @inject(MonthTypeEnumMapper)
        private readonly transportTypeEnumMapper: MonthTypeEnumMapper,
    ) {}

    map(transportTypeDto: MonthTypeDto): Nullable<MonthType> {
        const errors = validateSync(MonthTypeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<MonthTypeEnum> = this.transportTypeEnumMapper.map(
            transportTypeDto.key,
        );

        if (!id) return null;

        return new MonthType(id, transportTypeDto.value);
    }
}
