import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum ContributionGroupTypeEnumDto {
    CONTRIBUTION_GROUP_1 = "contribution_group_1",
    CONTRIBUTION_GROUP_2 = "contribution_group_2",
    CONTRIBUTION_GROUP_3 = "contribution_group_3",
    CONTRIBUTION_GROUP_4 = "contribution_group_4",
    CONTRIBUTION_GROUP_5 = "contribution_group_5",
    CONTRIBUTION_GROUP_6 = "contribution_group_6",
    CONTRIBUTION_GROUP_7 = "contribution_group_7",
    CONTRIBUTION_GROUP_8 = "contribution_group_8",
    CONTRIBUTION_GROUP_9 = "contribution_group_9",
    CONTRIBUTION_GROUP_10 = "contribution_group_10",
    CONTRIBUTION_GROUP_11 = "contribution_group_11",
}

export class ContributionGroupTypeDto {
    @IsEnum(ContributionGroupTypeEnumDto)
    @IsNotEmpty()
    key!: ContributionGroupTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
