import { Nullable } from "@core/domain/types/nullable.type";
import { PolicyProtocolTypeDto } from "@entity/data/dto/policy-protocol-type-enum.dto";
import { PolicyProtocolTypeEnumMapper } from "@entity/data/mappers/policy-protocol-type-enum.mapper";
import {
    PolicyProtocolType,
    PolicyProtocolTypeEnum,
} from "@entity/domain/models/policy-protocol-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class PolicyProtocolTypeMapper {
    constructor(
        @inject(PolicyProtocolTypeEnumMapper)
        private policyProtocolEnumMapper: PolicyProtocolTypeEnumMapper,
    ) {}

    map(
        policyProtocolEnumMapperDto: PolicyProtocolTypeDto,
    ): Nullable<PolicyProtocolType> {
        const errors = validateSync(policyProtocolEnumMapperDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<PolicyProtocolTypeEnum> =
            this.policyProtocolEnumMapper.map(policyProtocolEnumMapperDto.key);

        if (!id) return null;

        return new PolicyProtocolType(id, policyProtocolEnumMapperDto.value);
    }
}
