import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { BeneficiaryIdentificationType } from "../../domain/models/identification-document-type.model";
import { BeneficiaryIdentificationTypeDto } from "../dto/beneficiary-identification-type.dto";
import { BeneficiaryIdentificationTypeEnumMapper } from "./beneficiary-identification-type-enum.mapper";

@injectable()
export class BeneficiaryIdentificationTypeMapper {
    constructor(
        @inject(BeneficiaryIdentificationTypeEnumMapper)
        private readonly beneficiaryIdentificationTypeEnumMapper: BeneficiaryIdentificationTypeEnumMapper,
    ) {}
    map(
        identificationTypeDto: BeneficiaryIdentificationTypeDto,
    ): Nullable<BeneficiaryIdentificationType> {
        const errors = validateSync(identificationTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.beneficiaryIdentificationTypeEnumMapper.map(
            identificationTypeDto.key,
        );
        if (!id) return null;

        return new BeneficiaryIdentificationType(
            id,
            identificationTypeDto.value,
        );
    }
}
