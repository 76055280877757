import { IncError } from "@core/domain/errors/inc.error";
import i18n from "@i18n";

export class NoEntityError extends IncError {
    constructor() {
        super(i18n.t("error:authenticatedUser.noEntity"));

        Object.setPrototypeOf(this, NoEntityError.prototype);
    }
}
