import { inject, injectable } from "inversify";
import { ProjectExpenseRepository } from "../../../data/repositories/project-expense.repository";
import { TransportType } from "../../models/expenses/transport-type.model";

@injectable()
export class GetAllTransportTypesUseCase {
    constructor(
        @inject(ProjectExpenseRepository)
        private readonly projectExpenseRepository: ProjectExpenseRepository,
    ) {}

    async execute(): Promise<TransportType[]> {
        const dietTypesResult =
            await this.projectExpenseRepository.getAllTransportTypes();

        return dietTypesResult.getOrElse([]);
    }
}
