import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { IVATypeEnumDto } from "@entity/data/dto/iva-type.dto";
import { IVAEnumMapper } from "@entity/data/mappers/cost/iva-enum.mapper";
import { CreateInvestmentExpenseBody } from "@project/data/dto/expenses/create-expense.body";
import { EditInvestmentExpenseBody } from "@project/data/dto/expenses/edit-expense.body";
import { ExpenseSubTypeEnumDto } from "@project/data/dto/expenses/expense-sub-type.dto";
import { ExpenseTypeEnumDto } from "@project/data/dto/expenses/expense-type.dto";
import { ExpenseDto } from "@project/data/dto/expenses/expenses.dto";
import { InvestmentDto } from "@project/data/dto/expenses/investment.dto";
import { ExpenseSubTypeEnumMapper } from "@project/data/mappers/expenses/expense-sub-type-enum.mapper";
import { PaymentMethodTypeEnumMapper } from "@project/data/mappers/expenses/payment-method-type-enum.mapper";
import { CreateMaintenanceExpense } from "@project/domain/models/expenses/create-expense.model";
import { EditMaintenanceExpense } from "@project/domain/models/expenses/edit-expense.model";
import { ExpenseTypeEnum } from "@project/domain/models/expenses/expense-type.model";
import { MaintenanceExpense } from "@project/domain/models/expenses/expense.model";
import { ExpenseSubTypeEnum } from "@project/domain/models/expenses/sub-type.model";
import { ExpenseMaintenanceFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/maintenance/expense-maintenance-form.component";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

const maximumDecimalsNumber = 2;

@injectable()
export class MaintenanceExpenseMapper {
    constructor(
        @inject(ExpenseSubTypeEnumMapper)
        private readonly expenseSubTypeMapper: ExpenseSubTypeEnumMapper,
        @inject(PaymentMethodTypeEnumMapper)
        private readonly paymentMethodTypeEnumMapper: PaymentMethodTypeEnumMapper,
        @inject(IVAEnumMapper)
        private readonly ivaEnumMapper: IVAEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
    ) {}
    map(
        expenseDto: ExpenseDto,
        maintenanceData: InvestmentDto,
    ): Nullable<MaintenanceExpense> {
        const errors = validateSync(maintenanceData);

        if (errors.length > 0) {
            console.error(errors);
            return null;
        }

        const subtype = this.expenseSubTypeMapper.map(
            maintenanceData.subtype_expense,
        );

        let paymentMethod = null;
        if (maintenanceData.payment_method)
            paymentMethod = this.paymentMethodTypeEnumMapper.map(
                maintenanceData.payment_method,
            );

        const ivaEnum = maintenanceData.iva.toString() as IVATypeEnumDto;
        const iva = this.ivaEnumMapper.map(ivaEnum);
        const documents: IncDocument[] = [];
        if (expenseDto.documents_data) {
            expenseDto.documents_data.forEach((document) => {
                const documentMapped = this.documentMapper.map(document);
                if (documentMapped) {
                    documents.push(documentMapped);
                }
            });
        }

        return new MaintenanceExpense(
            expenseDto.id,
            "",
            subtype ?? ExpenseSubTypeEnum.NONE,
            "",
            maintenanceData.provider,
            maintenanceData.nif_provider,
            maintenanceData.description,
            maintenanceData.invoice_number,
            maintenanceData.invoice_date
                ? DateTime.fromISO(maintenanceData.invoice_date)
                : null,
            maintenanceData.expense_date
                ? DateTime.fromISO(maintenanceData.expense_date)
                : null,
            paymentMethod,
            maintenanceData.tax_base ? parseFloat(maintenanceData.tax_base) : 0,
            iva,
            maintenanceData.total_tax_with_iva
                ? parseFloat(maintenanceData.total_tax_with_iva)
                : 0,
            maintenanceData.project_allocation_percentage
                ? parseFloat(maintenanceData.project_allocation_percentage)
                : null,
            maintenanceData.amount_allocated_to_project
                ? parseFloat(maintenanceData.amount_allocated_to_project)
                : null,
            maintenanceData.payment_date
                ? DateTime.fromISO(maintenanceData.payment_date)
                : null,
            maintenanceData.concept,
            documents,
            expenseDto.project,
        );
    }

    mapFromFormValues(
        createFormValues: ExpenseMaintenanceFormValuesValidated,
        projectId: number,
    ): CreateMaintenanceExpense {
        return new CreateMaintenanceExpense(
            projectId,
            ExpenseTypeEnum.MAINTENANCE_ACTIVITIES,
            createFormValues.subTypeId,
            createFormValues.provider,
            "",
            createFormValues.description,
            createFormValues.invoiceNumber,
            createFormValues.invoiceDate,
            createFormValues.invoiceDate,
            createFormValues.paymentMethod,
            createFormValues.base,
            createFormValues.iva,
            createFormValues.baseIva,
            createFormValues.percentageImputation,
            createFormValues.amountImputation,
            createFormValues.paymentDate,
            createFormValues.documents.map((document) => document.id),
            createFormValues.concept,
        );
    }

    mapFromFormEditValues(
        editFormValues: ExpenseMaintenanceFormValuesValidated,
        expenseId: number,
        projectId: number,
    ): EditMaintenanceExpense {
        return new EditMaintenanceExpense(
            expenseId,
            projectId,
            ExpenseTypeEnum.MAINTENANCE_ACTIVITIES,
            editFormValues.subTypeId,
            editFormValues.provider,
            "",
            editFormValues.description,
            editFormValues.invoiceNumber,
            editFormValues.invoiceDate,
            editFormValues.invoiceDate,
            editFormValues.paymentMethod,
            editFormValues.base,
            editFormValues.iva,
            editFormValues.baseIva,
            editFormValues.percentageImputation,
            editFormValues.amountImputation,
            editFormValues.paymentDate,
            editFormValues.documents.map((document) => document.id),
            editFormValues.concept,
        );
    }

    mapToDto(
        createExpense: CreateMaintenanceExpense,
    ): CreateInvestmentExpenseBody {
        if (!createExpense.paymentMethod) {
            throw new FallbackError();
        }

        const paymentMethod = this.paymentMethodTypeEnumMapper.mapToDto(
            createExpense.paymentMethod,
        );
        let subType = null;
        if (createExpense.subtypeExpense) {
            subType = this.expenseSubTypeMapper.mapToDto(
                createExpense.subtypeExpense,
            );
        }

        const percentageImputation =
            createExpense.percentageImputation?.toFixed(maximumDecimalsNumber);

        const amountAllocatedToProject = createExpense.amountImputation
            ? Number(
                  createExpense.amountImputation.toFixed(maximumDecimalsNumber),
              )
            : 0;

        return {
            project: createExpense.projectId,
            type_expense: ExpenseTypeEnumDto.MAINTENANCE_ACTIVITIES,
            subtype_expense: subType ?? ExpenseSubTypeEnumDto.NONE,
            amount_allocated_to_project: amountAllocatedToProject,
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: createExpense.documents,
            concept: createExpense.concept ?? "",
            provider: createExpense.provider,
            nif_provider: createExpense.nifProvider ?? "",
            description: createExpense.description ?? "",
            invoice_number: createExpense.invoiceNumber ?? "",
            expense_date: createExpense.invoiceDate?.toISODate() ?? "",
            tax_base: createExpense.taxBase?.toString() ?? "0",
            iva: createExpense.iva?.toString() ?? "0",
            total_tax_with_iva:
                createExpense.totalTaxWithIva?.toString() ?? "0",
            payment_method: paymentMethod,
            payment_date: createExpense.paymentDate?.toISODate() ?? "",
        };
    }

    mapToEditDto(
        editExpense: EditMaintenanceExpense,
    ): EditInvestmentExpenseBody {
        if (!editExpense.paymentMethod) {
            throw new FallbackError();
        }

        const paymentMethod = this.paymentMethodTypeEnumMapper.mapToDto(
            editExpense.paymentMethod,
        );
        let subType = null;
        if (editExpense.subtypeExpense) {
            subType = this.expenseSubTypeMapper.mapToDto(
                editExpense.subtypeExpense,
            );
        }

        const percentageImputation = editExpense.percentageImputation?.toFixed(
            maximumDecimalsNumber,
        );

        return {
            id: editExpense.id,
            project: editExpense.projectId,
            type_expense: ExpenseTypeEnumDto.MAINTENANCE_ACTIVITIES,
            subtype_expense: subType ?? ExpenseSubTypeEnumDto.NONE,
            amount_allocated_to_project: editExpense.amountImputation ?? 0,
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: editExpense.documents,
            concept: editExpense.concept ?? "",
            provider: editExpense.provider,
            nif_provider: editExpense.nifProvider ?? "",
            description: editExpense.description ?? "",
            invoice_number: editExpense.invoiceNumber ?? "",
            expense_date: editExpense.invoiceDate?.toISODate() ?? "",
            tax_base: editExpense.taxBase?.toString() ?? "0",
            iva: editExpense.iva?.toString() ?? "0",
            total_tax_with_iva: editExpense.totalTaxWithIva?.toString() ?? "0",
            payment_method: paymentMethod,
            payment_date: editExpense.paymentDate?.toISODate() ?? "",
        };
    }
}
