import { PaginatedDto } from "@core/data/dto/paginated.dto";
import { Type } from "class-transformer";
import { IsArray, IsNotEmpty, IsNumber, IsString } from "class-validator";

export class AuditoryDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    certification_entity!: string;

    @IsNumber()
    @IsNotEmpty()
    year!: number;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class AuditoriesDto extends PaginatedDto {
    @IsArray()
    @Type(() => AuditoryDto)
    results!: AuditoryDto[];
}
