import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { EntityDatasource } from "@entity/data/datasources/entity.datasource";
import { ActionLine } from "@entity/domain/models/action-line.model";
import { AdministrativeRecordScope } from "@entity/domain/models/administrative-record-scope.model";
import { Category } from "@entity/domain/models/category.model";
import { CrosscuttingScope } from "@entity/domain/models/crosscutting-scope.model";
import { EntityTerritorialScope } from "@entity/domain/models/entity-territorial-scope.model";
import { ExportProjectDeposits } from "@entity/domain/models/export-project-deposits.model";
import { ExportProjectExpenses } from "@entity/domain/models/export-project-expenses.model";
import { Typology } from "@entity/domain/models/typology.model";
import { inject, injectable } from "inversify";
import { CreateEntity } from "../../domain/models/create-entity.model";
import { CreateUserEntity } from "../../domain/models/create-lite-entity.model";
import { EditEntity } from "../../domain/models/edit-entity.model";
import {
    EntitiesSummary,
    EntitySearchFilters,
    EntitySummary,
} from "../../domain/models/entity-summary.model";
import { Entity } from "../../domain/models/entity.model";
import { Materials } from "../../domain/models/material/material.model";

@injectable()
export class EntityRepository {
    constructor(
        @inject(EntityDatasource)
        private readonly entityDatasource: EntityDatasource,
    ) {}

    async getBy(
        pagination: Pagination,
        filters?: EntitySearchFilters,
    ): Promise<Either<FallbackError, EntitiesSummary>> {
        const entitiesSummary = await this.entityDatasource.fetchBy(
            pagination,
            filters,
        );

        return entitiesSummary.mapLeft(() => new FallbackError());
    }

    async getAllBy(
        filters?: EntitySearchFilters,
    ): Promise<Either<FallbackError, EntitySummary[]>> {
        const entitiesResult = await this.getBy(
            Pagination.NoPagination(),
            filters,
        );

        return entitiesResult.map((entities) => entities.entitiesSummary);
    }

    async getAllCategories(): Promise<Either<FallbackError, Category[]>> {
        const categoriesResult =
            await this.entityDatasource.fetchAllCategories();

        return categoriesResult
            .mapLeft(() => new FallbackError())
            .map((category) => category);
    }

    async getMaterials(
        pagination: Pagination,
    ): Promise<Either<FallbackError, Materials>> {
        const materialResult =
            await this.entityDatasource.fetchMaterials(pagination);

        return materialResult.mapLeft(() => new FallbackError());
    }

    async create(
        createEntity: CreateEntity | CreateUserEntity,
    ): Promise<Either<ValidationError | FallbackError, Entity>> {
        return this.entityDatasource.create(createEntity);
    }

    async update(
        entity: EditEntity,
    ): Promise<Either<ValidationError | FallbackError, Entity>> {
        return this.entityDatasource.update(entity);
    }

    async getById(id: number): Promise<Either<FallbackError, Entity>> {
        const entityResult = await this.entityDatasource.fetchById(id);

        if (entityResult.isLeft()) Either.Left(entityResult.getLeftOrThrow());

        const entity = entityResult.getOrThrow().entity;

        return Either.Right(entity);
    }

    async getAllTypologies(): Promise<Either<FallbackError, Typology[]>> {
        return this.entityDatasource.fetchTypologies(Pagination.NoPagination());
    }

    async getAllActionLines(): Promise<Either<FallbackError, ActionLine[]>> {
        return this.entityDatasource.fetchActionLines(
            Pagination.NoPagination(),
        );
    }

    async getAllCrosscuttingScopes(): Promise<
        Either<FallbackError, CrosscuttingScope[]>
    > {
        return this.entityDatasource.fetchCrosscuttingScopes(
            Pagination.NoPagination(),
        );
    }

    async getAllAdministrativeRecordScopes(): Promise<
        Either<FallbackError, AdministrativeRecordScope[]>
    > {
        return this.entityDatasource.fetchAllAdministrativeRecordScopes();
    }

    async getAllEntityTerritorialScopes(): Promise<
        Either<FallbackError, EntityTerritorialScope[]>
    > {
        return this.entityDatasource.fetchAllEntityTerritorialScopes();
    }

    async active(entityId: number): Promise<Either<FallbackError, true>> {
        return this.entityDatasource.toggleActivation(entityId);
    }

    async deactivate(entityId: number): Promise<Either<FallbackError, true>> {
        return this.entityDatasource.toggleActivation(entityId);
    }

    async exportProjectDeposit(
        exportProjectDeposits: ExportProjectDeposits,
    ): Promise<Either<FallbackError, Blob>> {
        return this.entityDatasource.exportProjectDeposit(
            exportProjectDeposits,
        );
    }

    async exportProjectExpenses(
        exportProjectExpenses: ExportProjectExpenses,
    ): Promise<Either<FallbackError, Blob>> {
        return this.entityDatasource.exportProjectExpenses(
            exportProjectExpenses,
        );
    }
}
