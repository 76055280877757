import { PaginatedDto } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { MandateTerminationReasonEnumDto } from "@entity/data/dto/mandate-termination-reason-enum.dto";
import { PositionTypeEnumDto } from "@entity/data/dto/position-type.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class AuthorityManagerDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsEnum(PositionTypeEnumDto, { each: true })
    @IsNotEmpty()
    position!: PositionTypeEnumDto[];

    @IsNumber()
    @IsNotEmpty()
    entity!: number;

    @IsDateString()
    @IsOptional()
    appointment_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    election_procedure!: Nullable<string>;

    @IsString()
    @IsOptional()
    other_position!: Nullable<string>;

    @IsEnum(MandateTerminationReasonEnumDto)
    @IsOptional()
    mandate_termination_reason!: Nullable<MandateTerminationReasonEnumDto>;

    @IsString()
    @IsOptional()
    other_mandate_termination_reason!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    mandate_end_date!: Nullable<string>;
}

export class AuthorityManagersDto extends PaginatedDto {
    @IsArray()
    @Type(() => AuthorityManagerDto)
    results!: AuthorityManagerDto[];
}
