import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EditEntityMapper } from "@entity/data/mappers/edit-entity.mapper";
import { Entity } from "@entity/domain/models/entity.model";
import { ActiveEntityUseCase } from "@entity/domain/usecases/entity/active-entity.usecase";
import { DeactivateEntityUseCase } from "@entity/domain/usecases/entity/deactivate-entity.usecase";
import { EditEntityUseCase } from "@entity/domain/usecases/entity/edit-entity.usecase";
import { GetEntityByIdUseCase } from "@entity/domain/usecases/entity/get-entity-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { EditEntityFormValuesValidated } from "../../../component/entity-form/entity-form.component";

@injectable()
export class EditEntityPageViewModel extends BaseViewModel {
    entityId: Undefinable<number> = undefined;

    @observable
    entity: Undefinable<Entity> = undefined;

    @observable
    initiallyLoading: boolean = false;

    constructor(
        @inject(EditEntityUseCase)
        private readonly editEntityUseCase: EditEntityUseCase,
        @inject(ActiveEntityUseCase)
        private readonly activeEntityUseCase: ActiveEntityUseCase,
        @inject(DeactivateEntityUseCase)
        private readonly deactivateEntityUseCase: DeactivateEntityUseCase,
        @inject(EditEntityMapper)
        private readonly editEntityMapper: EditEntityMapper,
        @inject(GetEntityByIdUseCase)
        private readonly getEntityByIdUseCase: GetEntityByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getEntity();

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    @action
    setEntity(entity: Entity): void {
        this.entity = entity;
    }

    async getEntity(): Promise<void> {
        if (this.entityId === undefined) throw Error("Missing entityId");

        const entity = await this.getEntityByIdUseCase.execute(this.entityId);

        if (entity) {
            this.setEntity(entity);
        }
    }

    async editEntity(
        values: EditEntityFormValuesValidated,
    ): Promise<Nullable<Entity>> {
        if (!this.entityId) throw Error("Missing entityId");

        LoadLayoutStore.start();

        const editEntityResult = await this.editEntityUseCase.execute(
            this.editEntityMapper.mapFromEntityFormValues(
                this.entityId,
                values,
            ),
        );

        if (editEntityResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        if (editEntityResult) this.setEntity(editEntityResult);

        return editEntityResult;
    }

    async toggleActivation(entityId: number, active: boolean): Promise<void> {
        LoadLayoutStore.start();

        if (active) {
            const activated = await this.activeEntityUseCase.execute(entityId);

            if (activated) {
                await this.getEntity();
                ToastManagerStore.success();
            }
        } else {
            const deactivated =
                await this.deactivateEntityUseCase.execute(entityId);

            if (deactivated) {
                await this.getEntity();
                ToastManagerStore.success();
            }
        }

        LoadLayoutStore.finish();
    }
}
