import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContractsRepository } from "@entity/data/repositories/contracts.repository";
import { EditEmployeeContract } from "@entity/domain/models/employee/contracts/employee/edit-employee-contract.model";
import { EmployeeContract } from "@entity/domain/models/employee/contracts/employee/employee-contract.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditEmployeeContractUseCase {
    constructor(
        @inject(ContractsRepository)
        private readonly contractsRepository: ContractsRepository,
    ) {}

    async execute(
        employeeContract: EditEmployeeContract,
    ): Promise<Nullable<EmployeeContract>> {
        const editResult =
            await this.contractsRepository.editEmployeeContract(
                employeeContract,
            );

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedEmployeeContract) => editedEmployeeContract,
        );
    }
}
