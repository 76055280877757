import { Nullable } from "@core/domain/types/nullable.type";
import { SupplierTypeDto } from "@entity/data/dto/supplier/supplier-type-enum.dto";
import { SupplierTypeEnumMapper } from "@entity/data/mappers/supplier/supplier-type-enum.mapper";
import { SupplierType } from "@entity/domain/models/supplier/supplier-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class SupplierTypeMapper {
    constructor(
        @inject(SupplierTypeEnumMapper)
        private readonly supplierTypeEnumMapper: SupplierTypeEnumMapper,
    ) {}
    map(supplierTypeDto: SupplierTypeDto): Nullable<SupplierType> {
        const errors = validateSync(supplierTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.supplierTypeEnumMapper.map(supplierTypeDto.key);
        if (!id) return null;

        return new SupplierType(id, supplierTypeDto.value);
    }
}
