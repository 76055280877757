import { IsEnum, IsNotEmpty, IsString } from "class-validator";
import { CategoryTypeEnumDto } from "@entity/data/dto/category-type-enum.dto";

export class CategoryDto {
    @IsEnum(CategoryTypeEnumDto)
    @IsNotEmpty()
    type!: CategoryTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    display_name!: string;
}
