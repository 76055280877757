import { CurrentLanguageDto } from "@beneficiary/data/dto/update-current-language.dto";
import { LanguageDto } from "@core/data/dto/language.dto";
import { LanguageIsoCodeEnumMapper } from "@core/data/mappers/language-iso-code-enum.mapper";
import { Language, LanguageIsoCode } from "@core/domain/models/language.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class LanguageMapper {
    constructor(
        @inject(LanguageIsoCodeEnumMapper)
        private readonly languageIsoCodeEnumMapper: LanguageIsoCodeEnumMapper,
    ) {}

    map(languageDto: LanguageDto): Nullable<Language> {
        const errors = validateSync(languageDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.languageIsoCodeEnumMapper.map(languageDto.key);
        if (!id) return null;

        return new Language(id, languageDto.value);
    }

    mapToIsoCode(languageDto: CurrentLanguageDto): Nullable<LanguageIsoCode> {
        const errors = validateSync(languageDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.languageIsoCodeEnumMapper.map(languageDto.language);
        if (!id) return null;

        return new LanguageIsoCode(id);
    }
}
