import { IncError } from "@core/domain/errors/inc.error";
import i18n from "@i18n";

export class InvalidCredentialsError extends IncError {
    constructor() {
        super(i18n.t("error:authentication.login.invalidCredentialsMessage"));

        Object.setPrototypeOf(this, InvalidCredentialsError.prototype);
    }
}
