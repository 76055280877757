import {
    Address,
    Addresses,
    CreateAddress,
    EditAddress,
} from "@beneficiary/domain/models/address.model";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Order } from "@core/domain/models/order.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";
import { AddressDatasource } from "../datasource/address.datasource";
import { AddressSearchFilters } from "../dto/address.dto";

@injectable()
export class AddressRepository {
    constructor(
        @inject(AddressDatasource)
        private readonly addressDatasource: AddressDatasource,
    ) {}

    async create(
        createAddress: CreateAddress,
    ): Promise<Either<FallbackError, Address>> {
        return this.addressDatasource.create(createAddress);
    }

    async edit(address: EditAddress): Promise<Either<FallbackError, Address>> {
        return this.addressDatasource.update(address);
    }

    async getAllBy(
        pagination: Pagination,
        filters?: AddressSearchFilters,
        order?: Order<Address>,
    ): Promise<Either<FallbackError, Addresses>> {
        const addressesSummary = await this.addressDatasource.fetchBy(
            pagination,
            filters,
            order,
        );

        return addressesSummary.mapLeft(() => new FallbackError());
    }

    async findById(addressId: number): Promise<Either<FallbackError, Address>> {
        const addressResult = await this.addressDatasource.fetchById(addressId);

        if (addressResult.isLeft())
            return Either.Left(addressResult.getLeftOrThrow());

        const { address } = addressResult.getOrThrow();

        return Either.Right(address);
    }

    async delete(addressId: number): Promise<Either<FallbackError, boolean>> {
        return this.addressDatasource.delete(addressId);
    }
}
