import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";

export class HttpUnauthorizedError extends HttpFailedRequestError {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    static HTTP_STATUS_CODE = 401;

    constructor() {
        super(
            HttpUnauthorizedError.HTTP_STATUS_CODE,
            HttpErrorCodeEnum.InvalidToken,
        );

        Object.setPrototypeOf(this, HttpUnauthorizedError.prototype);
    }
}
