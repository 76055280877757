import { Nullable } from "@core/domain/types/nullable.type";
import {
    ProjectAuditoriesDto,
    ProjectAuditoryDto,
} from "@entity/data/dto/quality-framework/project-auditory/project-auditory.dto";
import { ProjectAuditoryMapper } from "@entity/data/mappers/quality-framework/project-auditory/project-auditory.mapper";
import {
    ProjectAuditories,
    ProjectAuditory,
} from "@entity/domain/models/quality-framework/project-auditory/project-auditory.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ProjectAuditoriesMapper {
    constructor(
        @inject(ProjectAuditoryMapper)
        private readonly projectAuditoryMapper: ProjectAuditoryMapper,
    ) {}

    map(projectAuditoriesDto: ProjectAuditoriesDto): ProjectAuditories {
        const errors = validateSync(projectAuditoriesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new ProjectAuditories([], 0);
        }

        const projectAuditories = projectAuditoriesDto.results.mapNotNull(
            (projectAuditoryDto) =>
                this.projectAuditoryMapper.map(projectAuditoryDto),
        );

        return new ProjectAuditories(
            projectAuditories,
            projectAuditoriesDto.count,
        );
    }

    mapProjectAuditory(
        projectAuditoryDto: ProjectAuditoryDto,
    ): Nullable<ProjectAuditory> {
        if (!projectAuditoryDto.id) {
            return null;
        }

        const projectAuditory: ProjectAuditory = {
            id: projectAuditoryDto.id,
            certificationEntity: projectAuditoryDto.certification_entity,
            year: projectAuditoryDto.year,
            auditedProject: projectAuditoryDto.audited_project,
            entity: projectAuditoryDto.entity,
        };
        return projectAuditory;
    }
}
