export enum EntityTerritorialScopeEnum {
    LOCAL = "LOCAL",
    AUTONOMOUS_COMMUNITY = "AUTONOMOUS_COMMUNITY",
    NATIONAL = "NATIONAL",
    INTERNATIONAL = "INTERNATIONAL",
}

export class EntityTerritorialScope {
    constructor(
        public readonly id: EntityTerritorialScopeEnum,
        public readonly label: string,
    ) {}
}
