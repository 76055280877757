import { PublicItinerary } from "@beneficiary/domain/models/public-itinerary/public-itinerary.model";

export class CreatePublicItinerary {
    constructor(
        public objetive: PublicItinerary["objetive"],
        public actions: PublicItinerary["actions"],
        public actionsEvaluation: PublicItinerary["actionsEvaluation"],
        public beneficiary: PublicItinerary["beneficiary"],
    ) {}
}
