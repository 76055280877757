import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import { CollectiveDatasource } from "@entity/data/datasources/collective.datasource";
import { Collective } from "@entity/domain/models/collective.model";
import { inject, injectable } from "inversify";

@injectable()
export class CollectiveRepository {
    constructor(
        @inject(CollectiveDatasource)
        private readonly collectiveDatasource: CollectiveDatasource,
    ) {}

    async getAll(): Promise<Either<FallbackError, Collective[]>> {
        return this.collectiveDatasource.fetchAllPaginated();
    }

    async getAllPaginated(
        limit: number,
        offset: number,
    ): Promise<Either<FallbackError, Collective[]>> {
        return this.collectiveDatasource.fetchAllPaginated(limit, offset);
    }
}
