"use client";
import { IncSkeletonInput } from "@core/presentacion/component/feedback/skeleton/inc-skeleton-input.component";
import { Skeleton, SkeletonProps } from "antd";
import { FC } from "react";
import { IncSkeletonAvatar } from "./inc-skeleton-avatar.component";

export type IncSkeletonProps = SkeletonProps;

export const IncSkeleton: FC<IncSkeletonProps> & {
    Input: typeof IncSkeletonInput;
    Avatar: typeof IncSkeletonAvatar;
} = (props) => {
    const { children, active = true, ...restProps } = props;

    return (
        <Skeleton
            {...restProps}
            active={active}
        >
            {/* This overrides the default behaviour of Ant Design */}
            {/* they always render the children, we will not */}
            {restProps.loading ? null : children}
        </Skeleton>
    );
};

IncSkeleton.Input = IncSkeletonInput;
IncSkeleton.Avatar = IncSkeletonAvatar;
