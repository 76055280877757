import { Nullable } from "@core/domain/types/nullable.type";
import { MaterialTypeEnum } from "@entity/domain/models/material/material-type.model";
import { injectable } from "inversify";
import { MaterialTypeEnumDto } from "../../dto/material/material-type-enum.dto";

@injectable()
export class MaterialTypeEnumMapper {
    map(materialTypeEnumDto: MaterialTypeEnumDto): Nullable<MaterialTypeEnum> {
        switch (materialTypeEnumDto) {
            case MaterialTypeEnumDto.Software:
                return MaterialTypeEnum.Software;
            case MaterialTypeEnumDto.HumanResources:
                return MaterialTypeEnum.HumanResources;
            case MaterialTypeEnumDto.Subcontracting:
                return MaterialTypeEnum.Subcontracting;
            case MaterialTypeEnumDto.MaterialResources:
                return MaterialTypeEnum.MaterialResources;
            case MaterialTypeEnumDto.MethodologicalResources:
                return MaterialTypeEnum.MethodologicalResources;
            case MaterialTypeEnumDto.TechnologicalResources:
                return MaterialTypeEnum.TechnologicalResources;
            case MaterialTypeEnumDto.Others:
                return MaterialTypeEnum.Others;
            default:
                return null;
        }
    }

    mapToDto(materialTypeEnum: MaterialTypeEnum): MaterialTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (materialTypeEnum) {
            case MaterialTypeEnum.Software:
                return MaterialTypeEnumDto.Software;
            case MaterialTypeEnum.HumanResources:
                return MaterialTypeEnumDto.HumanResources;
            case MaterialTypeEnum.Subcontracting:
                return MaterialTypeEnumDto.Subcontracting;
            case MaterialTypeEnum.MaterialResources:
                return MaterialTypeEnumDto.MaterialResources;
            case MaterialTypeEnum.MethodologicalResources:
                return MaterialTypeEnumDto.MethodologicalResources;
            case MaterialTypeEnum.TechnologicalResources:
                return MaterialTypeEnumDto.TechnologicalResources;
            case MaterialTypeEnum.Others:
                return MaterialTypeEnumDto.Others;
        }
    }
}
