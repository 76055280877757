import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { AffiliationRepository } from "@entity/data/repositories/affiliation.repository";
import { Affiliation } from "@entity/domain/models/affiliation/affiliation.model";
import { CreateAffiliation } from "@entity/domain/models/affiliation/create-affiliation.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateAffiliationUseCase {
    constructor(
        @inject(AffiliationRepository)
        private readonly affiliationRepository: AffiliationRepository,
    ) {}

    async execute(
        affiliation: CreateAffiliation,
    ): Promise<Nullable<Affiliation>> {
        const creationResult =
            await this.affiliationRepository.create(affiliation);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdAffiliationEntity) => createdAffiliationEntity,
        );
    }
}
