import { PaginatedDto } from "@core/data/dto/paginated.dto";
import { CCAAEnumDto } from "@entity/data/dto/ccaa-enum.dto";
import { Type } from "class-transformer";
import { IsArray, IsEnum, IsNotEmpty, IsNumber } from "class-validator";

export class PartnersNumberDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsNumber()
    @IsNotEmpty()
    number_of_members!: number;

    @IsEnum(CCAAEnumDto)
    @IsNotEmpty()
    registered_ccaa!: CCAAEnumDto;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class PartnersNumbersDto extends PaginatedDto {
    @IsArray()
    @Type(() => PartnersNumberDto)
    results!: PartnersNumberDto[];
}
