import { EmployeeRepository } from "@entity/data/repositories/employee.repository";
import { inject, injectable } from "inversify";
import { EmployeeType } from "../../models/employee/employee-type.model";

@injectable()
export class GetAllEmployeeTypesUseCase {
    constructor(
        @inject(EmployeeRepository)
        private readonly employeeRepository: EmployeeRepository,
    ) {}

    async execute(): Promise<EmployeeType[]> {
        const typesResult = await this.employeeRepository.getAllEmployeeTypes();

        return typesResult.getOrElse([]);
    }
}
