export enum DiagnosisTypeEnum {
    Social = "social",
    Conectividad = "connectivity",
}

export class DiagnosisType {
    constructor(
        public readonly id: DiagnosisTypeEnum,
        public readonly label: string,
    ) {}
}
