import { Nullable } from "@core/domain/types/nullable.type";
import {
    AuditoriesDto,
    AuditoryDto,
} from "@entity/data/dto/quality-framework/auditory/auditory.dto";
import { AuditoryMapper } from "@entity/data/mappers/quality-framework/auditory/auditory.mapper";
import {
    Auditories,
    Auditory,
} from "@entity/domain/models/quality-framework/auditory/auditory.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class AuditoriesMapper {
    constructor(
        @inject(AuditoryMapper)
        private readonly auditoryMapper: AuditoryMapper,
    ) {}

    map(auditoriesDto: AuditoriesDto): Auditories {
        const errors = validateSync(auditoriesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Auditories([], 0);
        }

        const auditories = auditoriesDto.results.mapNotNull((auditoryDto) =>
            this.auditoryMapper.map(auditoryDto),
        );

        return new Auditories(auditories, auditoriesDto.count);
    }

    mapAuditory(auditoryDto: AuditoryDto): Nullable<Auditory> {
        if (!auditoryDto.id) {
            return null;
        }

        const auditory: Auditory = {
            id: auditoryDto.id,
            certificationEntity: auditoryDto.certification_entity,
            year: auditoryDto.year,
            entity: auditoryDto.entity,
        };
        return auditory;
    }
}
