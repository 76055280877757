import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import {
    IsArray,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { Type } from "class-transformer";

export class CreditorDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    type!: string;

    @IsString()
    @IsNotEmpty()
    category!: string;

    @IsString()
    @IsNotEmpty()
    identification_number!: string;

    @IsString()
    @IsNotEmpty()
    address_street!: string;

    @IsString()
    @IsNotEmpty()
    address_number!: string;

    @IsString()
    @IsOptional()
    address_letter!: Nullable<string>;

    @IsString()
    @IsOptional()
    address_floor!: Nullable<string>;

    @IsString()
    @IsOptional()
    address_stairs!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    postal_code!: string;

    @IsString()
    @IsNotEmpty()
    contact_person!: string;

    @IsString()
    @IsNotEmpty()
    phone!: string;

    @IsString()
    @IsNotEmpty()
    email!: string;

    @IsString()
    @IsOptional()
    email_representative!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    debt_description!: string;

    @IsNumber()
    @IsNotEmpty()
    debt_total!: number;

    @IsNumber()
    @IsNotEmpty()
    pending_amount_debt!: number;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class CreditorsDto extends PaginatedDto {
    @IsArray()
    @Type(() => CreditorDto)
    results!: CreditorDto[];
}

export type CreditorsQuery = PaginatedQuery & {
    search?: string;
    type?: string;
    name?: string;
    entities?: Nullish<string>;
};
