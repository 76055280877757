import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Cost } from "@entity/domain/models/cost/cost.model";
import { GetCostByIdUseCase } from "@entity/domain/usecases/cost/get-cost-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, flow, flowResult, makeObservable, observable } from "mobx";

@injectable()
export class CostDetailPageViewModel extends BaseViewModel {
    costId: Undefinable<number>;

    @observable
    initialLoading: boolean = true;

    @observable
    cost: Undefinable<Cost> = undefined;

    constructor(
        @inject(GetCostByIdUseCase)
        private readonly getCostByIdUseCase: GetCostByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(Promise.all([this.getCostById()]));

        this.initialLoading = false;
    }

    setCostId(costId: number): void {
        this.costId = costId;
    }

    @action
    setCost(cost: Cost): void {
        this.cost = cost;
    }

    async getCostById(): Promise<void> {
        if (this.costId === undefined) throw Error("Missign costId");

        const cost = await this.getCostByIdUseCase.execute(this.costId);

        if (cost) {
            this.setCost(cost);
        }
    }
}
