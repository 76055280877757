import { Nullable } from "@core/domain/types/nullable.type";
import {
    ContractTerminationDto,
    ContractTerminationsDto,
} from "@entity/data/dto/employee/contracts/termination/contract-termination.dto";
import { ContractTerminationMapper } from "@entity/data/mappers/employee/contracts/termination/contract-termination.mapper";
import { TerminationReasonEnumMapper } from "@entity/data/mappers/employee/termination-reason-enum.mapper";
import {
    ContractTermination,
    ContractTerminations,
} from "@entity/domain/models/employee/contracts/termination/contract-termination.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class ContractTerminationsMapper {
    constructor(
        @inject(TerminationReasonEnumMapper)
        private readonly terminationReasonEnumMapper: TerminationReasonEnumMapper,
        @inject(ContractTerminationMapper)
        private readonly contractTerminationMapper: ContractTerminationMapper,
    ) {}

    map(
        contractTerminationsDto: ContractTerminationsDto,
    ): ContractTerminations {
        const errors = validateSync(contractTerminationsDto);

        if (errors.length > 0) {
            console.error(errors);

            return new ContractTerminations([], 0);
        }

        const contractTerminations = contractTerminationsDto.results.mapNotNull(
            (contractTerminationDto) =>
                this.contractTerminationMapper.map(contractTerminationDto),
        );

        return new ContractTerminations(
            contractTerminations,
            contractTerminationsDto.count,
        );
    }

    mapContract(
        contractTerminationDto: ContractTerminationDto,
    ): Nullable<ContractTermination> {
        if (!contractTerminationDto.id) {
            return null;
        }

        const type = this.terminationReasonEnumMapper.map(
            contractTerminationDto.type,
        );
        if (!type) return null;

        const contractTermination: ContractTermination = {
            id: contractTerminationDto.id,
            startDate: DateTime.fromISO(contractTerminationDto.start_date),
            reason: contractTerminationDto.reason,
            type: type,
            contractId: contractTerminationDto.contract,
            cost: contractTerminationDto.cost
                ? Number(contractTerminationDto.cost)
                : null,
            totalCost: contractTerminationDto.total_cost
                ? Number(contractTerminationDto.total_cost)
                : null,
            endDate: contractTerminationDto.end_date
                ? DateTime.fromISO(contractTerminationDto.end_date)
                : null,
        };

        return contractTermination;
    }
}
