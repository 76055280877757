import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { UserRole, UserRoleEnum } from "../../domain/models/user-role.model";
import { UserRoleDto } from "../dto/user-role.dto";
import { UserRoleEnumMapper } from "./user-role-enum.mapper";

@injectable()
export class UserRoleMapper {
    constructor(
        @inject(UserRoleEnumMapper)
        private readonly userRoleEnumMapper: UserRoleEnumMapper,
    ) {}

    map(userRoleDto: UserRoleDto): Nullable<UserRole> {
        const errors = validateSync(userRoleDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<UserRoleEnum> = this.userRoleEnumMapper.map(
            userRoleDto.key,
        );

        if (!id) return null;

        return new UserRole(id, userRoleDto.value);
    }
}
