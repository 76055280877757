import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum LanguageEnumDto {
    Spanish = "es",
    English = "en",
    Catalán = "ca",
    Euskera = "eu",
    Gallego = "gl",
    Italiano = "it",
}

export class LanguageDto {
    @IsEnum(LanguageEnumDto)
    @IsNotEmpty()
    key!: LanguageEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
