import { Pagination } from "@core/domain/models/pagination";
import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { FinancialEntitiesSearchFilters } from "@project/domain/models/financial-entity/financial-entities-search-filters";
import { FinancialEntities } from "@project/domain/models/financial-entity/financial-entities.model";
import { inject, injectable } from "inversify";

@injectable()
export class SearchFinancialEntitiesByUseCase {
    constructor(
        @inject(FinancialEntityRepository)
        private readonly financialEntityRepository: FinancialEntityRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: FinancialEntitiesSearchFilters,
    ): Promise<FinancialEntities> {
        const projectsResult = await this.financialEntityRepository.getBy(
            pagination,
            filters,
        );

        return projectsResult.fold(
            () => new FinancialEntities([], 0),
            (financialEntities) => financialEntities,
        );
    }
}
