import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { CreateBudgetColumn } from "@project/domain/models/budget/budget-column/create-budget-column.model";
import { BudgetColumn } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateBudgetColumnUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(
        budgetColumn: CreateBudgetColumn,
    ): Promise<Nullable<BudgetColumn>> {
        const result = await this.budgetRepository.createColumn(budgetColumn);
        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }
        return result.fold(
            () => null,
            (createdBudgetColumn) => createdBudgetColumn,
        );
    }
}
