import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContactRepository } from "@entity/data/repositories/contact.repository";
import { Contact } from "@proceeding/domain/models/contact.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditContactUseCase {
    constructor(
        @inject(ContactRepository)
        private readonly contactRepository: ContactRepository,
    ) {}

    async execute(contact: Contact): Promise<Nullable<Contact>> {
        const editResult = await this.contactRepository.edit(contact);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedContact) => editedContact,
        );
    }
}
