import {
    IsArray,
    IsDateString,
    IsInt,
    IsNotEmpty,
    IsNumber,
    IsString,
} from "class-validator";

export class DerivationProceedingBody {
    @IsNotEmpty()
    @IsDateString()
    derivation_date!: string;

    @IsNotEmpty()
    @IsString()
    derivation_description!: string;

    @IsNotEmpty()
    @IsNumber()
    derivation_entity!: number;

    @IsArray()
    @IsInt({ each: true })
    @IsNotEmpty()
    derivation_activities!: number[];

    @IsNotEmpty()
    @IsNumber()
    origin_entity!: number;

    @IsNotEmpty()
    @IsNumber()
    beneficiary!: number;

    @IsNotEmpty()
    @IsNumber()
    project!: number;

    @IsNotEmpty()
    @IsString()
    reason!: string;

    @IsNotEmpty()
    @IsNumber()
    derivation_authorization_file!: number;
}
