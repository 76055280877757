import { CreateFinancialEntityBudgetBody } from "@project/data/dto/budget/create-financial-entity-budget.body";
import { CreateFinancialEntityBudget } from "@project/domain/models/budget/create-financial-entity-budget.model";
import { FinancialEntityBudgetFormValuesValidated } from "@project/presentation/components/budget-form/budget-form.component";
import { injectable } from "inversify";

@injectable()
export class CreateFinancialEntityBudgetMapper {
    mapFromFinancialEntityBudgetFormValues(
        financialEntityBudgetForm: FinancialEntityBudgetFormValuesValidated,
    ): CreateFinancialEntityBudget {
        return new CreateFinancialEntityBudget(
            financialEntityBudgetForm.name,
            financialEntityBudgetForm.description,
            financialEntityBudgetForm.financialEntityId,
        );
    }

    mapToDto(
        createFinancialEntityBudget: CreateFinancialEntityBudget,
    ): CreateFinancialEntityBudgetBody {
        return {
            name: createFinancialEntityBudget.name,
            description: createFinancialEntityBudget.description,
            finantial_entity: createFinancialEntityBudget.financialEntityId,
        };
    }
}
