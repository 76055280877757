import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { EditBudgetCategory } from "@project/domain/models/budget/budget-category/edit-budget-category.model";
import { BudgetCategory } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditBudgetCategoryUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(
        category: EditBudgetCategory,
    ): Promise<Nullable<BudgetCategory>> {
        const editResult = await this.budgetRepository.editCategory(category);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }
        return editResult.fold(
            () => null,
            (editedCategory) => editedCategory,
        );
    }
}
