import { BeneficiariesSummaryDto } from "@beneficiary/data/dto/beneficiaries-summary.dto";
import { BeneficiarySummaryMapper } from "@beneficiary/data/mappers/beneficiary-summary.mapper";
import { BeneficiariesSummary } from "@beneficiary/domain/models/beneficiaries-summary.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class BeneficiariesSummaryMapper {
    constructor(
        @inject(BeneficiarySummaryMapper)
        private readonly beneficiarySummaryMapper: BeneficiarySummaryMapper,
    ) {}

    map(
        beneficiariesSummaryDto: BeneficiariesSummaryDto,
    ): BeneficiariesSummary {
        const errors = validateSync(beneficiariesSummaryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new BeneficiariesSummary([], 0);
        }

        const beneficiariesSummary = beneficiariesSummaryDto.results.mapNotNull(
            (beneficiaryDto) =>
                this.beneficiarySummaryMapper.map(beneficiaryDto),
        );

        return new BeneficiariesSummary(
            beneficiariesSummary,
            beneficiariesSummaryDto.count,
        );
    }
}
