import { ExternalDerivationRepository } from "@beneficiary/data/repositories/external-derivation.repository";
import { ExternalDerivation } from "@beneficiary/domain/models/external-derivation/external-derivation.model";
import { Pagination } from "@core/domain/models/pagination";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllExternalDerivationsUseCase {
    constructor(
        @inject(ExternalDerivationRepository)
        private readonly externalDerivationRepository: ExternalDerivationRepository,
    ) {}

    async execute(beneficiaryId: number): Promise<ExternalDerivation[]> {
        const externalDerivationResult =
            await this.externalDerivationRepository.getAllBy(
                beneficiaryId,
                Pagination.NoPagination(),
            );

        return externalDerivationResult.fold(
            () => [],
            (externalDerivations) => externalDerivations.externalDerivations,
        );
    }
}
