import { EditSocialNetwork } from "@entity/domain/models/edit-social-network.model";
import { SocialNetworkFormValuesValidated } from "@entity/presentation/component/entity-form/social-networks/form/social-network-form";
import { injectable } from "inversify";
import { EditSocialNetworkBody } from "../../dto/social-network/edit-social-network.body";

@injectable()
export class EditSocialNetworkMapper {
    mapToDto(editSocialNetwork: EditSocialNetwork): EditSocialNetworkBody {
        const editSocialNetworkBody: EditSocialNetworkBody = {
            id: editSocialNetwork.id,
            entity: editSocialNetwork.entityId,
            name: editSocialNetwork.name,
            link: editSocialNetwork.link,
            followers: editSocialNetwork.followers ?? undefined,
            num_posts: editSocialNetwork.postCount ?? undefined,
        };

        return editSocialNetworkBody;
    }

    mapFromModal(
        id: number,
        entityId: number,
        socialNetwork: SocialNetworkFormValuesValidated,
    ): EditSocialNetwork {
        const editSocialNetwork: EditSocialNetwork = new EditSocialNetwork(
            id,
            entityId,
            socialNetwork.name,
            socialNetwork.url,
            socialNetwork.followersCount,
            socialNetwork.postCount,
        );

        return editSocialNetwork;
    }
}
