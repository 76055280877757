import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum EmploymentSituationRelevantCharacteristicsEnumDto {
    LongTermUnemployed = "long_term_unemployed",
    ExhaustedBenefits = "exhausted_benefits",
    PrecariousWork = "precarious_work",
    Minor = "minor",
    Over65 = "over_65",
    SelfEmployed = "self_employed",
    WithoutContract = "without_contract",
    OtherLaboralStatus = "other_laboral_status",
}

export class EmploymentRelevantCharacteristicsDto {
    @IsEnum(EmploymentSituationRelevantCharacteristicsEnumDto)
    @IsNotEmpty()
    key!: EmploymentSituationRelevantCharacteristicsEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
