export enum TherapyTypeEnum {
    MedicalTherapies = "medical_therapies",
    PsychologicalTherapies = "psychological_therapies",
    OccupationalTherapy = "occupational_therapy",
    MusicTherapy = "music_therapy",
    OtherTherapy = "other_therapy",
}

export class TherapyType {
    constructor(
        public readonly id: TherapyTypeEnum,
        public readonly label: string,
    ) {}
}
