import { StatusEnumDto } from "@proceeding/data/dto/status-enum.dto";
import { StatusEnumMapper } from "@proceeding/data/mappers/status-enum.mapper";
import {
    EditProceeding,
    EditProceedingResolution,
} from "@proceeding/domain/models/edit-proceeding.model";
import { inject, injectable } from "inversify";
import { ProceedingFormValuesValidated } from "../../presentation/components/proceeding-form.component";
import { EditProceedingBody } from "../dto/edit-proceeding.body";

@injectable()
export class EditProceedingMapper {
    constructor(
        @inject(StatusEnumMapper) private statusEnumMapper: StatusEnumMapper,
    ) {}

    mapFromFormValues(
        proceedingId: number,
        proceedingForm: ProceedingFormValuesValidated,
    ): EditProceeding {
        const editProceeding = new EditProceeding(
            proceedingId,
            proceedingForm.general.beneficiary,
            proceedingForm.general.project,
            proceedingForm.general.activities,
            proceedingForm.general.accountableProfessional,
            proceedingForm.general.motivation,
            proceedingForm.documents.documents,
            proceedingForm.general.status,
        );

        if (proceedingForm.general.authorization.uploadedFile?.id) {
            editProceeding.authorizationFile =
                proceedingForm.general.authorization.uploadedFile.id;
        }

        if (
            proceedingForm.general.resolution.description &&
            proceedingForm.general.resolution.date &&
            proceedingForm.general.resolution.uploadedFile?.id
        ) {
            editProceeding.resolution = new EditProceedingResolution(
                proceedingForm.general.resolution.description,
                proceedingForm.general.resolution.date,
                proceedingForm.general.resolution.uploadedFile.id,
            );
        }

        return editProceeding;
    }

    mapToDto(proceeding: EditProceeding): EditProceedingBody {
        const status: StatusEnumDto = this.statusEnumMapper.mapToDto(
            proceeding.status,
        );

        const editProceedingBody: EditProceedingBody = {
            id: proceeding.id,
            beneficiary: proceeding.beneficiary,
            project: proceeding.project,
            activities: proceeding.activities,
            professional_responsible: proceeding.accountableProfessional,
            reason: proceeding.reason,
            documents:
                proceeding.documents?.map((document) => document.id) ?? [],
            status,
            authorization_file: null,
            resolution_description: null,
            resolution_date: null,
            resolution_file: null,
        };

        if (proceeding.authorizationFile) {
            editProceedingBody.authorization_file =
                proceeding.authorizationFile;
        }

        if (proceeding.resolution) {
            editProceedingBody.resolution_description =
                proceeding.resolution.description;
            editProceedingBody.resolution_date =
                proceeding.resolution.date.toISODate() ?? "";
            editProceedingBody.resolution_file =
                proceeding.resolution.authorizationFile;
        }

        return editProceedingBody;
    }
}
