import type { Nullable } from "@core/domain/types/nullable.type";
import { IsNotEmpty, IsNumber, IsOptional, IsString } from "class-validator";

export class SocialNetworkDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    link!: string;

    @IsNumber()
    @IsOptional()
    followers!: Nullable<number>;

    @IsNumber()
    @IsOptional()
    num_posts!: Nullable<number>;
}
