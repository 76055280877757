import { Pagination } from "@core/domain/models/pagination";
import { ProjectExpenseRepository } from "@project/data/repositories/project-expense.repository";
import { inject, injectable } from "inversify";
import { ExpenseSearchFilters } from "../../models/expenses/expense-search-filters";
import { ExpenseAllTypes } from "../../models/expenses/expense.model";

@injectable()
export class GetAllExpensesUseCase {
    constructor(
        @inject(ProjectExpenseRepository)
        private readonly projectExpenseRepository: ProjectExpenseRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: ExpenseSearchFilters,
    ): Promise<ExpenseAllTypes[]> {
        const expensesResult = await this.projectExpenseRepository.searchBy(
            pagination,
            filters,
        );

        return expensesResult.fold(
            () => [],
            (expenses) => expenses.expenses,
        );
    }
}
