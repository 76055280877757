import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { AuthenticatedUser } from "@authentication/domain/models/authenticated-user.model";
import { container } from "@di/inversify.config";
import { useEffect, useState } from "react";
import { GetLoggedUserUseCase } from "../../domain/usecases/get-logged-user.usecase";

export const useUser = (): AuthenticatedUser => {
    const userSessionRepository = container.get(UserSessionRepository);
    const [user, setUser] = useState<AuthenticatedUser>(
        container.get(GetLoggedUserUseCase).execute(),
    );

    useEffect(() => {
        const subscription = userSessionRepository.user.subscribe((newUser) => {
            if (newUser) setUser(newUser);
        });

        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return user;
};
