import { Nullable } from "@core/domain/types/nullable.type";
import { EmployeeType } from "@entity/domain/models/employee/employee-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { EmployeeTypeDto } from "../../dto/employee/employee-type.dto";
import { EmployeeTypeEnumMapper } from "./employee-type-enum.mapper";

@injectable()
export class EmployeeTypeMapper {
    constructor(
        @inject(EmployeeTypeEnumMapper)
        private readonly employeeTypeEnumMapper: EmployeeTypeEnumMapper,
    ) {}
    map(employeeTypeDto: EmployeeTypeDto): Nullable<EmployeeType> {
        const errors = validateSync(employeeTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.employeeTypeEnumMapper.map(employeeTypeDto.key);
        if (!id) return null;

        return new EmployeeType(id, employeeTypeDto.value);
    }
}
