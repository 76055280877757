import { CreateRegisteredRecord } from "@entity/domain/models/registered-record/create-registered-record.model";
import { RegisteredRecord } from "@entity/domain/models/registered-record/registered-record.model";

export class EditRegisteredRecord extends CreateRegisteredRecord {
    constructor(
        public readonly id: RegisteredRecord["id"],
        registeredAdministrativeRecordScope: RegisteredRecord["registeredAdministrativeRecordScope"],
        registeredRecordName: RegisteredRecord["registeredRecordName"],
        recordIdentificationCode: RegisteredRecord["recordIdentificationCode"],
        entity: RegisteredRecord["entity"],
    ) {
        super(
            registeredAdministrativeRecordScope,
            registeredRecordName,
            recordIdentificationCode,
            entity,
        );
    }
}
