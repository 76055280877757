import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectActivityRepository } from "@project/data/repositories/technical-proposal/activity.repository";
import { EditActivity } from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditActivityUseCase {
    constructor(
        @inject(ProjectActivityRepository)
        private readonly projectActivityRepository: ProjectActivityRepository,
    ) {}

    async execute(activity: EditActivity): Promise<boolean> {
        const aditActivityResult =
            await this.projectActivityRepository.edit(activity);

        if (aditActivityResult.isLeft()) {
            ToastManagerStore.error(
                aditActivityResult.getLeftOrThrow().message,
            );
        }

        return aditActivityResult.isRight();
    }
}
