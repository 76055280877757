import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { LogoutUseCase } from "@authentication/domain/usecases/logout.usecase";
import { EventBusRepository } from "@core/data/repositories/event-bus.repository";
import { LanguageRepository } from "@core/data/repositories/language.repository";
import { UnauthorizedEventBus } from "@core/domain/models/event-bus.model";
import { LanguageIsoCodeEnum } from "@core/domain/models/language.model";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { makeObservable, observable } from "mobx";

@injectable()
export class AppViewModel extends BaseViewModel {
    @observable
    currentLanguage: Undefinable<LanguageIsoCodeEnum> = undefined;

    @observable
    entityId: Undefinable<number> = undefined;

    constructor(
        @inject(EventBusRepository)
        private readonly eventBusRepository: EventBusRepository,
        @inject(LogoutUseCase) private readonly logoutUseCase: LogoutUseCase,
        @inject(LanguageRepository)
        private readonly languageRepository: LanguageRepository,
        @inject(UserSessionRepository)
        private readonly userSessionRepository: UserSessionRepository,
    ) {
        super();
        makeObservable(this);

        this.addSub(
            this.languageRepository.currentLanguage.subscribe(
                (currentLanguage) => {
                    this.currentLanguage = currentLanguage;
                },
            ),
        );

        this.addSub(
            this.userSessionRepository.user.subscribe((user) => {
                this.entityId = user?.entityId;
            }),
        );
    }
    override async didMount(): Promise<void> {
        this.addSub(
            this.eventBusRepository.bus.subscribe(async (event) => {
                if (event instanceof UnauthorizedEventBus) {
                    await this.logoutUseCase.execute();
                }
            }),
        );
    }

    attachExit(exitCallback: () => void): void {
        this.addSub(
            this.eventBusRepository.bus.subscribe(async (event) => {
                if (event instanceof UnauthorizedEventBus) {
                    await this.logoutUseCase.execute(true);
                    exitCallback();
                }
            }),
        );
    }
}
