import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { SocialNetworkRepository } from "@entity/data/repositories/social-network.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteSocialNetworkUseCase {
    constructor(
        @inject(SocialNetworkRepository)
        private readonly socialNetworkRepository: SocialNetworkRepository,
    ) {}

    async execute(socialNetworkId: number): Promise<void> {
        const result =
            await this.socialNetworkRepository.delete(socialNetworkId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }
    }
}
