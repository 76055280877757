export enum UserRoleEnum {
    SuperAdmin = "superAdmin",
    EntityAdmin = "entityAdmin",
    Professional = "professional",
}

export class UserRole {
    constructor(
        public readonly id: UserRoleEnum,
        public readonly label: string,
    ) {}
}
