export enum DepositTypeEnum {
    OWN_FINANCING = "own_financing",
    ANOTHER_SOURCES_FINANCING = "another_sources_financing",
    SUBVENTION = "subvention",
    OTHER = "other",
}

export enum DepositOwnFinancingTypeEnum {
    FUNDRAISING_CAMPAIGNS = "fundraising_campaigns",
    MEMBERSHIP_FEE = "membership_fee",
    DONATION = "donation",
    OTHERS_INCOME = "others_income",
    "" = "",
}

export enum DepositAnotherSourcesFinancingTypeEnum {
    CONTRACTING_SERVICES_PUBLIC_ADMINISTRATION = "contracting_services_public_administration",
    CONTRACTING_SERVICES_PRIVATE_ORGANIZATION = "contracting_services_private_organization",
    HIRING_SERVICES_PERSON = "hiring_services_person",
    SALE_PRODUCTS = "sale_products",
    "" = "",
}

export enum DepositSubventionTypeEnum {
    PRIVATE_SUBVENTION = "private_subvention",
    PUBLIC_SUBVENTION = "public_subvention",
    "" = "",
}

export class DepositTypes {
    constructor(
        public readonly id: DepositTypeEnum,
        public readonly label: string,
    ) {}
}

export class DepositOwnFinancingTypes {
    constructor(
        public readonly id: DepositOwnFinancingTypeEnum,
        public readonly label: string,
    ) {}
}

export class DepositAnotherSourcesFinancingTypes {
    constructor(
        public readonly id: DepositAnotherSourcesFinancingTypeEnum,
        public readonly label: string,
    ) {}
}

export class DepositSubventionTypes {
    constructor(
        public readonly id: DepositSubventionTypeEnum,
        public readonly label: string,
    ) {}
}

export class DepositGeneralTypes {
    constructor(
        public readonly types: DepositTypes[],
        public readonly ownFinancingTypes: DepositOwnFinancingTypes[],
        public readonly anotherSourcesFinancingTypes: DepositAnotherSourcesFinancingTypes[],
        public readonly subventionTypes: DepositSubventionTypes[],
    ) {}
}
