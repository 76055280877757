import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateAuditoryMapper } from "@entity/data/mappers/quality-framework/auditory/create-auditory.mapper";
import { EditAuditoryMapper } from "@entity/data/mappers/quality-framework/auditory/edit-auditory.mapper";
import { Auditory } from "@entity/domain/models/quality-framework/auditory/auditory.model";
import { CreateAuditoryUseCase } from "@entity/domain/usecases/quality-framework/auditory/create-auditory.usecase";
import { DeleteAuditoryUseCase } from "@entity/domain/usecases/quality-framework/auditory/delete-auditory.usecase";
import { EditAuditoryUseCase } from "@entity/domain/usecases/quality-framework/auditory/edit-auditory.usecase";
import { GetAllAuditoriesUsecase } from "@entity/domain/usecases/quality-framework/auditory/get-all-auditories.usecase";
import { AuditoryFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/auditory/form/auditory-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class AuditoryViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    auditories: Auditory[] = [];

    @observable
    auditoryToEdit: Undefinable<Auditory> = undefined;

    @observable
    initialLoading: boolean = true;

    @observable
    showAddAuditoryModal: boolean = false;

    @observable
    showEditAuditoryModal: boolean = false;

    constructor(
        @inject(GetAllAuditoriesUsecase)
        private readonly getAllAuditoriesUsecase: GetAllAuditoriesUsecase,
        @inject(CreateAuditoryUseCase)
        private readonly createAuditoryUseCase: CreateAuditoryUseCase,
        @inject(EditAuditoryUseCase)
        private readonly editAuditoryUseCase: EditAuditoryUseCase,
        @inject(DeleteAuditoryUseCase)
        private readonly deleteAuditoryUseCase: DeleteAuditoryUseCase,
        @inject(CreateAuditoryMapper)
        private readonly createAuditoryMapper: CreateAuditoryMapper,
        @inject(EditAuditoryMapper)
        private readonly editAuditoryMapper: EditAuditoryMapper,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getAuditories()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAuditories(): Promise<void> {
        const auditories = await this.getAllAuditoriesUsecase.execute();
        runInAction(() => {
            this.auditories = auditories;
        });
    }

    @action
    setShowAddAuditoryModal(show: boolean): void {
        this.showAddAuditoryModal = show;
    }

    @action
    closeEditAuditoryModal(): void {
        this.showEditAuditoryModal = false;
    }

    @action
    openEditAuditoryModal(auditory: Undefinable<Auditory>): void {
        this.auditoryToEdit = auditory;
        this.showEditAuditoryModal = true;
    }

    async addAuditory(
        entityId: Undefinable<number>,
        values: AuditoryFormValuesValidated,
    ): Promise<Nullable<Auditory>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createAuditoryResult = await this.createAuditoryUseCase.execute(
            this.createAuditoryMapper.mapFromFormValues(entityId, values),
        );

        if (createAuditoryResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createAuditoryResult;
    }

    async editAuditory(values: AuditoryFormValuesValidated): Promise<void> {
        if (!this.auditoryToEdit?.id || !this.auditoryToEdit.entity)
            throw Error("Missing auditory");

        LoadLayoutStore.start();

        const editAuditoryResult = await this.editAuditoryUseCase.execute(
            this.editAuditoryMapper.mapFromModal(
                this.auditoryToEdit.id,
                this.auditoryToEdit.entity,
                values,
            ),
        );

        if (editAuditoryResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadAuditories(): Promise<void> {
        await this.getAuditories();
    }

    async deleteAuditory(auditoryId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteAuditoryUseCase.execute(auditoryId);
        LoadLayoutStore.finish();
    }
}
