import { TerritorialScope } from "@project/domain/models/territorial-scope.model";
import { inject, injectable } from "inversify";
import { ProjectRepository } from "../../data/repositories/project.repository";

@injectable()
export class GetAllTerritorialScopeUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(): Promise<TerritorialScope[]> {
        const territorialScopeResult =
            await this.projectRepository.getAllTerritorialScopes();

        return territorialScopeResult.getOrElse([]);
    }
}
