import { Nullable } from "@core/domain/types/nullable.type";
import { CostPeriodicityType } from "@entity/domain/models/locals/cost-periodicity.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { CostPeriodicityTypeDto } from "../../dto/locals/cost-periodicity.dto";
import { CostPeriodicityTypeEnumMapper } from "./cost-periodicity-type-enum.mapper";

@injectable()
export class CostPeriodicityTypeMapper {
    constructor(
        @inject(CostPeriodicityTypeEnumMapper)
        private readonly costPeriodicityTypeEnumMapper: CostPeriodicityTypeEnumMapper,
    ) {}
    map(
        costPeriodicityDto: CostPeriodicityTypeDto,
    ): Nullable<CostPeriodicityType> {
        const errors = validateSync(costPeriodicityDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const type = this.costPeriodicityTypeEnumMapper.map(
            costPeriodicityDto.key,
        );

        if (!type) return null;

        return new CostPeriodicityType(type, costPeriodicityDto.value);
    }
}
