import { Nullable } from "@core/domain/types/nullable.type";
import { AdministrativeRecordScopeEnum } from "@entity/domain/models/administrative-record-scope.model";

export class RegisteredRecord {
    constructor(
        public id: number,
        public registeredAdministrativeRecordScope: Nullable<AdministrativeRecordScopeEnum>,
        public registeredRecordName: string,
        public recordIdentificationCode: string,
        public entity: number,
    ) {}
}

export class RegisteredRecords {
    constructor(
        public readonly registeredRecords: RegisteredRecord[],
        public readonly count: number,
    ) {}
}
