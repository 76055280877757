import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { PublicUtilityRepository } from "@entity/data/repositories/public-utility.repository";
import { CreatePublicUtility } from "@entity/domain/models/public-utility/create-public-utility.model";
import { PublicUtility } from "@entity/domain/models/public-utility/public-utility.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreatePublicUtilityUseCase {
    constructor(
        @inject(PublicUtilityRepository)
        private readonly publicUtilityRepository: PublicUtilityRepository,
    ) {}

    async execute(
        publicUtility: CreatePublicUtility,
    ): Promise<Nullable<PublicUtility>> {
        const creationResult =
            await this.publicUtilityRepository.create(publicUtility);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdPublicUtility) => createdPublicUtility,
        );
    }
}
