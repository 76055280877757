import { CCAAEnum } from "@entity/domain/models/ccaa.model";
export class RegconRegister {
    constructor(
        public id: number,
        public registerNumber: string,
        public registeredCcaa: CCAAEnum,
        public entity: number,
    ) {}
}

export class RegconRegisters {
    constructor(
        public readonly regconRegisters: RegconRegister[],
        public readonly count: number,
    ) {}
}
