import { Nullable } from "@core/domain/types/nullable.type";
import { AdministrativeRecordScopeDto } from "@entity/data/dto/administrative-record-scope-enum.dto";
import { AdministrativeRecordScopeEnumMapper } from "@entity/data/mappers/administrative-record-scope-enum.mapper";
import {
    AdministrativeRecordScope,
    AdministrativeRecordScopeEnum,
} from "@entity/domain/models/administrative-record-scope.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class AdministrativeRecordScopeMapper {
    constructor(
        @inject(AdministrativeRecordScopeEnumMapper)
        private readonly administrativeRecordScopeEnumMapper: AdministrativeRecordScopeEnumMapper,
    ) {}

    map(
        administrativeRecordScopeDto: AdministrativeRecordScopeDto,
    ): Nullable<AdministrativeRecordScope> {
        const errors = validateSync(AdministrativeRecordScopeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<AdministrativeRecordScopeEnum> =
            this.administrativeRecordScopeEnumMapper.map(
                administrativeRecordScopeDto.key,
            );

        if (!id) return null;

        return new AdministrativeRecordScope(
            id,
            administrativeRecordScopeDto.value,
        );
    }
}
