export enum ContractTypeEnum {
    INDEFINITE = "INDEFINITE",
    TEMPORARY = "TEMPORARY",
    TRAINING = "TRAINING",
    FIXED_DISCONTINUED = "FIXED_DISCONTINUED",
    OTHER = "OTHER",
}

export class ContractType {
    constructor(
        public readonly id: ContractTypeEnum,
        public readonly label: string,
    ) {}
}
