import type { Nullable } from "@core/domain/types/nullable.type";
import {
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { DietTypeEnumDto } from "./diet-type.dto";
import { ExpenseSubTypeEnumDto } from "./expense-sub-type.dto";
import { ExpenseTypeEnumDto } from "./expense-type.dto";

export class MaintenanceDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsDateString()
    @IsNotEmpty()
    created!: string;

    @IsDateString()
    @IsNotEmpty()
    modified!: string;

    @IsBoolean()
    @IsNotEmpty()
    is_active!: boolean;

    @IsEnum(ExpenseTypeEnumDto)
    @IsNotEmpty()
    type_expense!: ExpenseTypeEnumDto;

    @IsEnum(ExpenseSubTypeEnumDto)
    @IsOptional()
    subtype_expense!: ExpenseSubTypeEnumDto;

    @IsString()
    @IsOptional()
    full_name!: Nullable<string>;

    @IsString()
    @IsOptional()
    identification_document!: Nullable<string>;

    @IsString()
    @IsOptional()
    address!: Nullable<string>;

    @IsString()
    @IsOptional()
    position_function!: Nullable<string>;

    @IsString()
    @IsOptional()
    travel_destination!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    departure_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    return_date!: Nullable<string>;

    @IsEnum(DietTypeEnumDto)
    @IsOptional()
    diet_type!: Nullable<DietTypeEnumDto>;

    @IsString()
    @IsOptional()
    daily_expense_accommodation!: Nullable<string>;

    @IsString()
    @IsOptional()
    total_expense_accommodation!: Nullable<string>;

    @IsNumber()
    @IsOptional()
    days!: Nullable<number>;

    @IsNumber()
    @IsOptional()
    days_accommodation!: Nullable<number>;

    @IsString()
    @IsOptional()
    daily_expense!: Nullable<string>;

    @IsString()
    @IsOptional()
    total_expense!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    amount_allocated_to_project!: string;

    @IsString()
    @IsOptional()
    project_allocation_percentage!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    payment_date!: Nullable<string>;

    @IsNumber()
    @IsOptional()
    project_expense!: Nullable<number>;
}
