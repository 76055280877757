import { Nullable } from "@core/domain/types/nullable.type";
import { ProjectEmployeeDedicationDto } from "@project/data/dto/project-employee-dedication/project-employee-dedication.dto";
import { CreateProjectEmployeeDedicationBody } from "@project/data/dto/project-employee/create-project-employee.body";
import { EditProjectEmployeeDedicationBody } from "@project/data/dto/project-employee/edit-project-employee.body";
import {
    CreateProjectEmployeeDedication,
    ProjectEmployeeDedication,
    UpdateProjectEmployeeDedication,
} from "@project/domain/models/project-employee-dedications.model";
import { DedicationFormValuesValidated } from "@project/presentation/components/project-form/employees/add-dedication-modal-form.view";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class ProjectEmployeeDedicationMapper {
    map(
        projectEmployeeDedicationDto: ProjectEmployeeDedicationDto,
    ): Nullable<ProjectEmployeeDedication> {
        const errors = validateSync(projectEmployeeDedicationDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        return new ProjectEmployeeDedication(
            projectEmployeeDedicationDto.id,
            projectEmployeeDedicationDto.project_employee,
            Number(projectEmployeeDedicationDto.hours_dedicated),
            DateTime.fromISO(projectEmployeeDedicationDto.start_date),
            DateTime.fromISO(projectEmployeeDedicationDto.end_date),
        );
    }

    mapFromFormToCreate(
        projectEmployeeId: number,
        projectEmployeeDedicationForm: DedicationFormValuesValidated,
    ): CreateProjectEmployeeDedication {
        return new CreateProjectEmployeeDedication(
            projectEmployeeId,
            projectEmployeeDedicationForm.hoursDedicated,
            projectEmployeeDedicationForm.startDate,
            projectEmployeeDedicationForm.endDate,
        );
    }

    mapToCreateDto(
        dedication: CreateProjectEmployeeDedication,
    ): CreateProjectEmployeeDedicationBody {
        return {
            project_employee: dedication.projectEmployeeId,
            hours_dedicated: dedication.hoursDedicated.toString(),
            start_date: dedication.startDate.toISODate() ?? "",
            end_date: dedication.endDate.toISODate() ?? "",
        };
    }

    mapToEditDto(
        dedication: UpdateProjectEmployeeDedication,
    ): EditProjectEmployeeDedicationBody {
        return {
            id: dedication.id,
            project_employee: dedication.projectEmployeeId,
            hours_dedicated: dedication.hoursDedicated.toString(),
            start_date: dedication.startDate.toISODate() ?? "",
            end_date: dedication.endDate.toISODate() ?? "",
        };
    }
}
