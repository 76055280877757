import { AssociateServiceToDependenceEnum } from "@beneficiary/domain/models/associate-service-to-dependence.enum";
import { DependenceGradeEnum } from "@beneficiary/domain/models/dependence-grade.enum";
import { DisabilityTypeEnum } from "@beneficiary/domain/models/disability-type.enum";
import { TherapyTypeEnum } from "@beneficiary/domain/models/therapy-type.enum";
import { CountryEnum } from "@core/domain/models/country.model";
import { DiagnosisTypeEnum } from "@core/domain/models/diagnosis-type.model";
import { EducationLevelEnum } from "@core/domain/models/education-level.model";
import { EmploymentStatusEnum } from "@core/domain/models/employment-status.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { DateTime } from "luxon";
import { EmploymentSituationRelevantCharacteristicsEnum } from "./employment-situation-relevant-characteristics.enum";
import { GenderEnum } from "./gender.model";
import { BeneficiaryIdentificationTypeEnum } from "./identification-document-type.model";
import { MaritalStatusEnum } from "./marital-status.model";
import { CreateRelative } from "./relatives/create-relative.model";

export class CreateBeneficiaryIdentification {
    constructor(
        public identificationType: BeneficiaryIdentificationTypeEnum,
        public identification: Undefinable<string>,
        public identificationExpiration: Undefinable<DateTime>,
        public beneficiary: Undefinable<number>,
    ) {}
}

export class CreateBeneficiary {
    // eslint-disable-next-line max-params
    constructor(
        public name: string,
        public surname: string,
        public identifications: CreateBeneficiaryIdentification[],
        public gender: GenderEnum,
        public phone: Nullish<string>,
        public phoneOwner: Nullish<string>,
        public email: Nullish<string>,
        public emailOwner: Nullish<string>,
        public nationality: CountryEnum,
        public countryOfOrigin: CountryEnum,
        public birthDate: DateTime,
        public maritalStatus: MaritalStatusEnum,
        public levelOfEducation: EducationLevelEnum,
        public complementaryTraining: Nullish<string>,
        public employmentSituation: EmploymentStatusEnum,
        public employmentSituationDescription: Undefinable<
            EmploymentSituationRelevantCharacteristicsEnum[]
        >,
        public otherEmploymentDescription: Nullable<string>,
        public relatives: CreateRelative[],
        public disability: boolean,
        public disability_type: Nullable<DisabilityTypeEnum>,
        public healthObservations: string,
        public dependence: boolean,
        public dependenceGrade: Nullable<DependenceGradeEnum>,
        public inTherapy: boolean,
        public therapyType: Nullable<TherapyTypeEnum>,
        public isDiagnosed: boolean,
        public diagnosisType: Nullable<DiagnosisTypeEnum>,
        public diagnosisDate: Nullable<DateTime>,
        public underProtection: boolean,
        public associateServiceToDependence: Nullable<AssociateServiceToDependenceEnum>,
        public dependenceDiagnosis: Undefinable<string>,
        public dependenceObservations: Nullable<string>,
        public documentIds: number[] = [],
    ) {}
}
