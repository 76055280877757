import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import {
    CreateSplitDeposit,
    SplitDeposit,
} from "@entity/domain/models/deposit.model";
import { CreateSplitDepositUseCase } from "@entity/domain/usecases/splitDeposit/create-split-deposit.usecase";
import { DeleteSplitDepositUseCase } from "@entity/domain/usecases/splitDeposit/delete-split-deposit.usecase";
import { EditSplitDepositUseCase } from "@entity/domain/usecases/splitDeposit/edit-split-deposit.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable } from "mobx";
import { SplitDepositFormValuesValidated } from "./add-split-deposit/split-deposit-form";

@injectable()
export class DepositFormViewModel extends BaseViewModel {
    @observable
    public splitDeposit: Undefinable<SplitDeposit> = undefined;

    @observable
    public showModal: boolean = false;

    constructor(
        @inject(CreateSplitDepositUseCase)
        public readonly createSplitDepositUseCase: CreateSplitDepositUseCase,
        @inject(EditSplitDepositUseCase)
        public readonly editSplitDepositUseCase: EditSplitDepositUseCase,
        @inject(DeleteSplitDepositUseCase)
        public readonly deleteSplitDepositUseCase: DeleteSplitDepositUseCase,
    ) {
        super();
        makeObservable(this);
    }

    @action
    setSplitDeposit(splitDeposit: Undefinable<SplitDeposit>): void {
        this.splitDeposit = splitDeposit;
    }

    async addSplitDeposit(
        values: SplitDepositFormValuesValidated,
    ): Promise<Nullable<SplitDeposit>> {
        if (!values.projectId) throw Error("Missing projectId");
        if (!values.depositId) throw Error("Missing depositId");

        LoadLayoutStore.start();

        const createSplitDeposit = new CreateSplitDeposit(
            values.depositId,
            values.projectId,
            values.assignedPercentage,
        );

        const createSplitDepositResult =
            await this.createSplitDepositUseCase.execute(createSplitDeposit);

        if (createSplitDepositResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createSplitDepositResult;
    }

    async editSplitDeposit(
        values: SplitDepositFormValuesValidated,
    ): Promise<Nullable<SplitDeposit>> {
        if (!values.id) throw Error("Missing split deposit id");
        if (!values.depositId) throw Error("Missing depositId");

        LoadLayoutStore.start();

        const editSplitDeposit = new SplitDeposit(
            values.id,
            values.depositId,
            values.projectId,
            values.assignedPercentage,
        );

        const editSplitDepositResult =
            await this.editSplitDepositUseCase.execute(editSplitDeposit);

        if (editSplitDepositResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return editSplitDepositResult;
    }

    async deleteSplitDeposit(splitDepositId: number): Promise<void> {
        LoadLayoutStore.start();

        await this.deleteSplitDepositUseCase.execute(splitDepositId);

        LoadLayoutStore.finish();
    }

    @action
    openModal(): void {
        this.showModal = true;
    }

    @action
    closeModal(): void {
        this.showModal = false;
    }
}
