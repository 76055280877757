import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CreateProjectAuditoryBody } from "@entity/data/dto/quality-framework/project-auditory/create-project-auditory.body";
import { EditProjectAuditoryBody } from "@entity/data/dto/quality-framework/project-auditory/edit-project-auditory.body";
import {
    ProjectAuditoriesDto,
    ProjectAuditoryDto,
} from "@entity/data/dto/quality-framework/project-auditory/project-auditory.dto";
import { CreateProjectAuditoryMapper } from "@entity/data/mappers/quality-framework/project-auditory/create-project-auditory.mapper";
import { EditProjectAuditoryMapper } from "@entity/data/mappers/quality-framework/project-auditory/edit-project-auditory.mapper";
import { ProjectAuditoriesMapper } from "@entity/data/mappers/quality-framework/project-auditory/project-auditories.mapper";
import { CreateProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/create-project-auditory.model";
import { EditProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/edit-project-auditory.model";
import {
    ProjectAuditories,
    ProjectAuditory,
} from "@entity/domain/models/quality-framework/project-auditory/project-auditory.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const PROJECT_AUDITORIES_PATH = "/entities_project_auditories/";

@injectable()
export class ProjectAuditoryDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(ProjectAuditoriesMapper)
        private readonly projectAuditoriesMapper: ProjectAuditoriesMapper,
        @inject(CreateProjectAuditoryMapper)
        private readonly createProjectAuditoryMapper: CreateProjectAuditoryMapper,
        @inject(EditProjectAuditoryMapper)
        private readonly editProjectAuditoryMapper: EditProjectAuditoryMapper,
    ) {}

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, ProjectAuditories>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const projectAuditoriesResult = await this.http.get<ProjectAuditoryDto>(
            PROJECT_AUDITORIES_PATH,
            {
                query,
            },
        );

        return projectAuditoriesResult.map((response) =>
            this.projectAuditoriesMapper.map(
                plainToClass(ProjectAuditoriesDto, response.data),
            ),
        );
    }

    async create(
        newProjectAuditory: CreateProjectAuditory,
    ): Promise<Either<ValidationError | FallbackError, ProjectAuditory>> {
        const projectAuditoryBody =
            this.createProjectAuditoryMapper.mapToCreateDto(newProjectAuditory);

        const projectAuditoryResult = await this.http.post<
            ProjectAuditoryDto,
            CreateProjectAuditoryBody
        >(PROJECT_AUDITORIES_PATH, projectAuditoryBody);

        return projectAuditoryResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const projectAuditory =
                    this.projectAuditoriesMapper.mapProjectAuditory(
                        plainToClass(ProjectAuditoryDto, response.data),
                    );

                if (!projectAuditory) return Either.Left(new FallbackError());

                return Either.Right(projectAuditory);
            });
    }

    async edit(
        editProjectAuditory: EditProjectAuditory,
    ): Promise<Either<ValidationError | FallbackError, ProjectAuditory>> {
        const editedProjectAuditory =
            this.editProjectAuditoryMapper.mapToDto(editProjectAuditory);

        const editProjectAuditoryResult = await this.http.put<
            ProjectAuditoryDto,
            EditProjectAuditoryBody
        >(
            `${PROJECT_AUDITORIES_PATH}${editProjectAuditory.id}/`,
            editedProjectAuditory,
        );

        return editProjectAuditoryResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const projectAuditory =
                    this.projectAuditoriesMapper.mapProjectAuditory(
                        plainToClass(ProjectAuditoryDto, response.data),
                    );

                if (!projectAuditory) return Either.Left(new FallbackError());

                return Either.Right(projectAuditory);
            });
    }

    async delete(
        projectAuditoryId: number,
    ): Promise<Either<FallbackError, true>> {
        const deleteProjectAuditory = await this.http.delete(
            `${PROJECT_AUDITORIES_PATH}${projectAuditoryId}`,
        );

        return deleteProjectAuditory
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
