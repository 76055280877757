import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Entity } from "@entity/domain/models/entity.model";
import { GetEntityByIdUseCase } from "@entity/domain/usecases/entity/get-entity-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class EntityDetailPageViewModel extends BaseViewModel {
    entityId: Undefinable<number> = undefined;

    @observable
    entity?: Nullable<Entity>;

    @observable
    initiallyLoading: boolean = true;

    constructor(
        @inject(GetEntityByIdUseCase)
        private readonly getEntityByIdUseCase: GetEntityByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getEntityById();

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    @action
    setEntityId(entityId: number): void {
        this.entityId = entityId;
    }

    async getEntityById(): Promise<void> {
        if (this.entityId === undefined) throw Error("Missing entityId");

        const entity = await this.getEntityByIdUseCase.execute(this.entityId);

        if (entity) {
            this.entity = entity;
        }
    }
}
