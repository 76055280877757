import { CreateRelative } from "@beneficiary/domain/models/relatives/create-relative.model";
import { CreateBeneficiaryFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/beneficiary-form.component";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { injectable } from "inversify";
import { AssociateServiceToDependenceEnum } from "../../domain/models/associate-service-to-dependence.enum";
import {
    CreateBeneficiary,
    CreateBeneficiaryIdentification,
} from "../../domain/models/create-beneficiary.model";
import {
    CreateBeneficiaryBody,
    CreateBeneficiaryIdentificationBody,
} from "../dto/create-beneficiary.body";

@injectable()
export class CreateBeneficiaryMapper {
    mapFromBeneficiaryFormValues(
        beneficiaryForm: CreateBeneficiaryFormValuesValidated,
    ): CreateBeneficiary {
        const relatives: CreateRelative[] = [];

        const beneficiaryIdentifications: CreateBeneficiaryIdentification[] = (
            beneficiaryForm.general.identificationObject ?? []
        ).map(
            (identification) =>
                new CreateBeneficiaryIdentification(
                    identification.documentType,
                    identification.documentNumber,
                    convertEmptyToUndefined(identification.documentExpiration),
                    undefined,
                ),
        );

        const documentIds = beneficiaryForm.documents.documents.map(
            (document) => document.id,
        );

        return new CreateBeneficiary(
            beneficiaryForm.general.name,
            beneficiaryForm.general.surname,
            beneficiaryIdentifications,
            beneficiaryForm.general.gender,
            beneficiaryForm.general.phone,
            beneficiaryForm.general.phoneOwner,
            beneficiaryForm.general.email,
            beneficiaryForm.general.emailOwner,
            beneficiaryForm.general.nationality,
            beneficiaryForm.general.countryOfOrigin,
            beneficiaryForm.general.birthDate,
            beneficiaryForm.general.maritalStatus,
            beneficiaryForm.general.levelOfEducation,
            beneficiaryForm.general.complementaryTraining,
            beneficiaryForm.general.employmentSituation,
            beneficiaryForm.general.employmentSituationDescription ?? undefined,
            beneficiaryForm.general.otherEmploymentDescription ?? null,
            relatives,
            beneficiaryForm.general.disability,
            beneficiaryForm.general.disabilityType
                ? beneficiaryForm.general.disabilityType
                : null,
            beneficiaryForm.general.healthObservations,
            beneficiaryForm.general.dependence,
            beneficiaryForm.general.dependenceGrade,
            beneficiaryForm.general.inTherapy,
            beneficiaryForm.general.therapyType,
            beneficiaryForm.general.isDiagnosed,
            beneficiaryForm.general.diagnosisType,
            beneficiaryForm.general.diagnosisDate,
            beneficiaryForm.general.underProtection,
            beneficiaryForm.general.associateServiceToDependence,
            beneficiaryForm.general.dependenceDiagnosis,
            beneficiaryForm.general.dependenceObservations,
            documentIds,
        );
    }

    mapToDto(createBeneficiary: CreateBeneficiary): CreateBeneficiaryBody {
        const beneficiaryDto: CreateBeneficiaryBody = {
            name: createBeneficiary.name,
            surname: createBeneficiary.surname,
            gender: createBeneficiary.gender,
            phone: convertEmptyToUndefined(createBeneficiary.phone),
            phone_owner: convertEmptyToUndefined(createBeneficiary.phoneOwner),
            email: convertEmptyToUndefined(createBeneficiary.email),
            email_owner: convertEmptyToUndefined(createBeneficiary.emailOwner),
            nationality: createBeneficiary.nationality,
            country_of_origin: createBeneficiary.countryOfOrigin,
            birth_date: createBeneficiary.birthDate.toISODate() ?? "",
            marital_status: createBeneficiary.maritalStatus,
            level_of_education: createBeneficiary.levelOfEducation,
            complementary_training: convertEmptyToUndefined(
                createBeneficiary.complementaryTraining,
            ),
            employment_situation: createBeneficiary.employmentSituation,
            employment_situation_relevant_characteristics:
                createBeneficiary.employmentSituationDescription
                    ? createBeneficiary.employmentSituationDescription
                    : null,
            other_employment_situation_relevant_characteristics:
                createBeneficiary.otherEmploymentDescription ?? null,
            disability: createBeneficiary.disability,
            disability_types: createBeneficiary.disability
                ? createBeneficiary.disability_type
                : null,
            health_observations: createBeneficiary.healthObservations,
            has_dependence: createBeneficiary.dependence,
            dependence_grade: createBeneficiary.dependence
                ? createBeneficiary.dependenceGrade
                : null,
            in_therapy: createBeneficiary.inTherapy,
            therapy_type: createBeneficiary.inTherapy
                ? createBeneficiary.therapyType
                : null,
            is_diagnosed: createBeneficiary.isDiagnosed,
            diagnosis_type: createBeneficiary.diagnosisType ?? "",
            diagnosis_date:
                createBeneficiary.diagnosisDate?.toISODate() ?? null,
            under_protection: createBeneficiary.underProtection,
            associate_service_to_dependence:
                createBeneficiary.associateServiceToDependence
                    ? createBeneficiary.associateServiceToDependence
                    : AssociateServiceToDependenceEnum.Empty,
            dependence_diagnosis: createBeneficiary.dependenceDiagnosis ?? "",
            documents: createBeneficiary.documentIds,
        };

        if (createBeneficiary.dependenceObservations?.length) {
            beneficiaryDto.dependence_observations =
                createBeneficiary.dependenceObservations;
        }

        return beneficiaryDto;
    }

    mapIdentificationToDto(
        createBeneficiaryIdentification: CreateBeneficiaryIdentification,
        beneficiaryId: number,
    ): CreateBeneficiaryIdentificationBody {
        const beneficiaryIdentificationDto: CreateBeneficiaryIdentificationBody =
            {
                identification_type:
                    createBeneficiaryIdentification.identificationType,
                identification: convertEmptyToUndefined(
                    createBeneficiaryIdentification.identification,
                ),
                identification_expiration: convertEmptyToUndefined(
                    createBeneficiaryIdentification.identificationExpiration?.toISODate(),
                ),
                beneficiary: beneficiaryId,
            };
        return beneficiaryIdentificationDto;
    }
}
