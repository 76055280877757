import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { ExpenseRecurrenceEnum } from "../../domain/models/economic-data/expense/expense-recurrence.model";
import { ExpenseRecurrenceEnumDto } from "../dto/expense-recurrence.dto";

@injectable()
export class ExpenseRecurrenceEnumMapper {
    map(
        relationshipIdDto: ExpenseRecurrenceEnumDto,
    ): Nullable<ExpenseRecurrenceEnum> {
        switch (relationshipIdDto) {
            case ExpenseRecurrenceEnumDto.Monthly:
                return ExpenseRecurrenceEnum.Monthly;
            case ExpenseRecurrenceEnumDto.Yearly:
                return ExpenseRecurrenceEnum.Yearly;
            default:
                return null;
        }
    }
    mapToDto(relationshipId: ExpenseRecurrenceEnum): ExpenseRecurrenceEnumDto {
        // eslint-disable-next-line default-case
        switch (relationshipId) {
            case ExpenseRecurrenceEnum.Monthly:
                return ExpenseRecurrenceEnumDto.Monthly;
            case ExpenseRecurrenceEnum.Yearly:
                return ExpenseRecurrenceEnumDto.Yearly;
        }
    }
}
