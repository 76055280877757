import { EmploymentStatusEnum } from "@core/domain/models/employment-status.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { EmploymentStatusEnumDto } from "../dto/employment-status.dto";

@injectable()
export class EmploymentStatusEnumMapper {
    map(
        employmentStatusDto: EmploymentStatusEnumDto,
    ): Nullable<EmploymentStatusEnum> {
        switch (employmentStatusDto) {
            case EmploymentStatusEnumDto.Active:
                return EmploymentStatusEnum.Active;
            case EmploymentStatusEnumDto.Inactive:
                return EmploymentStatusEnum.Inactive;
            case EmploymentStatusEnumDto.Unemployed:
                return EmploymentStatusEnum.Unemployed;
            case EmploymentStatusEnumDto.LongTermUnemployed:
                return EmploymentStatusEnum.LongTermUnemployed;
            case EmploymentStatusEnumDto.ExhaustedBenefits:
                return EmploymentStatusEnum.ExhaustedBenefits;
            case EmploymentStatusEnumDto.PrecariousWork:
                return EmploymentStatusEnum.PrecariousWork;
            case EmploymentStatusEnumDto.Minor:
                return EmploymentStatusEnum.Minor;
            case EmploymentStatusEnumDto.Over65:
                return EmploymentStatusEnum.Over65;
            case EmploymentStatusEnumDto.SelfEmployed:
                return EmploymentStatusEnum.SelfEmployed;
            case EmploymentStatusEnumDto.WithoutContract:
                return EmploymentStatusEnum.WithoutContract;
            default:
                return null;
        }
    }

    mapToDto(employmentStatus: EmploymentStatusEnum): EmploymentStatusEnumDto {
        // eslint-disable-next-line default-case
        switch (employmentStatus) {
            case EmploymentStatusEnum.Active:
                return EmploymentStatusEnumDto.Active;
            case EmploymentStatusEnum.Inactive:
                return EmploymentStatusEnumDto.Inactive;
            case EmploymentStatusEnum.Unemployed:
                return EmploymentStatusEnumDto.Unemployed;
            case EmploymentStatusEnum.LongTermUnemployed:
                return EmploymentStatusEnumDto.LongTermUnemployed;
            case EmploymentStatusEnum.ExhaustedBenefits:
                return EmploymentStatusEnumDto.ExhaustedBenefits;
            case EmploymentStatusEnum.PrecariousWork:
                return EmploymentStatusEnumDto.PrecariousWork;
            case EmploymentStatusEnum.Minor:
                return EmploymentStatusEnumDto.Minor;
            case EmploymentStatusEnum.Over65:
                return EmploymentStatusEnumDto.Over65;
            case EmploymentStatusEnum.SelfEmployed:
                return EmploymentStatusEnumDto.SelfEmployed;
            case EmploymentStatusEnum.WithoutContract:
                return EmploymentStatusEnumDto.WithoutContract;
        }
    }
}
