import { Nullable } from "@core/domain/types/nullable.type";
import {
    RegisteredRecordDto,
    RegisteredRecordsDto,
} from "@entity/data/dto/registered-record/registered-record.dto";
import { AdministrativeRecordScopeEnumMapper } from "@entity/data/mappers/administrative-record-scope-enum.mapper";
import { RegisteredRecordMapper } from "@entity/data/mappers/registered-record/registered-record.mapper";
import {
    RegisteredRecord,
    RegisteredRecords,
} from "@entity/domain/models/registered-record/registered-record.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class RegisteredRecordsMapper {
    constructor(
        @inject(AdministrativeRecordScopeEnumMapper)
        private readonly administrativeRecordScopeEnumMapper: AdministrativeRecordScopeEnumMapper,
        @inject(RegisteredRecordMapper)
        private readonly registeredRecordMapper: RegisteredRecordMapper,
    ) {}

    map(registeredRecordsDto: RegisteredRecordsDto): RegisteredRecords {
        const errors = validateSync(registeredRecordsDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new RegisteredRecords([], 0);
        }

        const registeredRecords = registeredRecordsDto.results.mapNotNull(
            (registeredRecordDto) =>
                this.registeredRecordMapper.map(registeredRecordDto),
        );

        return new RegisteredRecords(
            registeredRecords,
            registeredRecordsDto.count,
        );
    }

    mapRegisteredRecord(
        registeredRecordDto: RegisteredRecordDto,
    ): Nullable<RegisteredRecord> {
        if (!registeredRecordDto.id) {
            return null;
        }

        const registeredAdministrativeRecordScope =
            this.administrativeRecordScopeEnumMapper.map(
                registeredRecordDto.registered_administrative_record_scope,
            );
        if (!registeredAdministrativeRecordScope) return null;

        const registeredRecord: RegisteredRecord = {
            id: registeredRecordDto.id,
            registeredAdministrativeRecordScope,
            registeredRecordName: registeredRecordDto.registered_record_name,
            recordIdentificationCode:
                registeredRecordDto.record_identification_code,
            entity: registeredRecordDto.entity,
        };
        return registeredRecord;
    }
}
