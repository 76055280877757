import { SupplierRepository } from "@entity/data/repositories/supplier.repository";
import { SupplierType } from "@entity/domain/models/supplier/supplier-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllSupplierTypesUseCase {
    constructor(
        @inject(SupplierRepository)
        private readonly supplierRepository: SupplierRepository,
    ) {}

    async execute(): Promise<SupplierType[]> {
        const supplierTypesResult =
            await this.supplierRepository.getAllSupplierTypes();

        return supplierTypesResult.getOrElse([]);
    }
}
