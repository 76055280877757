import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContractTerminationRepository } from "@entity/data/repositories/contract-termination.repository";
import { ContractTermination } from "@entity/domain/models/employee/contracts/termination/contract-termination.model";
import { EditContractTermination } from "@entity/domain/models/employee/contracts/termination/edit-contract-termination.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditContractTerminationUseCase {
    constructor(
        @inject(ContractTerminationRepository)
        private readonly contractTerminationRepository: ContractTerminationRepository,
    ) {}

    async execute(
        contractTermination: EditContractTermination,
    ): Promise<Nullable<ContractTermination>> {
        const editResult =
            await this.contractTerminationRepository.editContractTermination(
                contractTermination,
            );

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedContractTermination) => editedContractTermination,
        );
    }
}
