import { Nullable } from "@core/domain/types/nullable.type";
import { MetricUnitEnum } from "@entity/domain/models/material/metric-unit.model";
import { injectable } from "inversify";
import { MetricUnitEnumDto } from "../../dto/material/metric-unit-enum.dto";

@injectable()
export class MetricUnitEnumMapper {
    map(metricUnitEnumDto: MetricUnitEnumDto): Nullable<MetricUnitEnum> {
        switch (metricUnitEnumDto) {
            case MetricUnitEnumDto.Kg:
                return MetricUnitEnum.Kg;
            case MetricUnitEnumDto.Euro:
                return MetricUnitEnum.Euro;
            case MetricUnitEnumDto.Units:
                return MetricUnitEnum.Units;
            case MetricUnitEnumDto.Litres:
                return MetricUnitEnum.Litres;
            case MetricUnitEnumDto.Other:
                return MetricUnitEnum.Other;
            default:
                return null;
        }
    }

    mapToDto(metricUnitEnum: MetricUnitEnum): MetricUnitEnumDto {
        // eslint-disable-next-line default-case
        switch (metricUnitEnum) {
            case MetricUnitEnum.Kg:
                return MetricUnitEnumDto.Kg;
            case MetricUnitEnum.Euro:
                return MetricUnitEnumDto.Euro;
            case MetricUnitEnum.Units:
                return MetricUnitEnumDto.Units;
            case MetricUnitEnum.Litres:
                return MetricUnitEnumDto.Litres;
            case MetricUnitEnum.Other:
                return MetricUnitEnumDto.Other;
        }
    }
}
