import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { EditPolicyProtocolBody } from "@entity/data/dto/quality-framework/policy-protocol/edit-policy-protocol.body";
import { PolicyProtocolTypeEnumMapper } from "@entity/data/mappers/policy-protocol-type-enum.mapper";
import { EditPolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/edit-policy-protocol.model";
import { PolicyProtocolFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/policy-protocol/form/policy-protocol-form";
import { inject, injectable } from "inversify";

@injectable()
export class EditPolicyProtocolMapper {
    constructor(
        @inject(PolicyProtocolTypeEnumMapper)
        private readonly policyProtocolTypeEnumMapper: PolicyProtocolTypeEnumMapper,
    ) {}
    mapToDto(editPolicyProtocol: EditPolicyProtocol): EditPolicyProtocolBody {
        const policies_protocols = editPolicyProtocol.policiesProtocols.map(
            (policiesProtocolEnum) =>
                this.policyProtocolTypeEnumMapper.mapToDto(
                    policiesProtocolEnum,
                ),
        );

        const editPolicyProtocolBody: EditPolicyProtocolBody = {
            id: editPolicyProtocol.id,
            name: editPolicyProtocol.name,
            policies_protocols,
            realization_year: editPolicyProtocol.realizationYear,
            entity: editPolicyProtocol.entity,
            other_policies_protocols: convertEmptyToUndefined(
                editPolicyProtocol.otherPoliciesProtocols,
            ),
            expiration_date: editPolicyProtocol.expirationDate
                ? editPolicyProtocol.expirationDate.toISODate()
                : undefined,
        };
        return editPolicyProtocolBody;
    }
    mapFromModal(
        id: number,
        entityId: number,
        policyProtocol: PolicyProtocolFormValuesValidated,
    ): EditPolicyProtocol {
        const editPolicyProtocol: EditPolicyProtocol = new EditPolicyProtocol(
            id,
            policyProtocol.name,
            policyProtocol.policiesProtocols,
            policyProtocol.realizationYear.year,
            entityId,
            convertEmptyToUndefined(policyProtocol.otherPoliciesProtocols),
            convertEmptyToUndefined(policyProtocol.expirationDate),
        );
        return editPolicyProtocol;
    }
}
