import { Observable, Subject } from "rxjs";

export class LoadLayoutStore {
    private static _loadSubject = new Subject<boolean>();
    static loading: Observable<boolean> = LoadLayoutStore._loadSubject;

    static start(): void {
        LoadLayoutStore._loadSubject.next(true);
    }

    static finish(): void {
        LoadLayoutStore._loadSubject.next(false);
    }
}
