export enum PaymentMethodTypeEnum {
    bank = "bank",
    credit_card = "credit_card",
    cash = "cash",
    domiciliation = "domiciliation",
    other = "other",
}

export class PaymentMethodType {
    constructor(
        public readonly id: PaymentMethodTypeEnum,
        public readonly label: string,
    ) {}
}
