import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateProjectMapper } from "@project/data/mappers/create-project.mapper";
import { Project } from "@project/domain/models/project.model";
import { CreateProjectUseCase } from "@project/domain/usecases/create-project.usecase";
import { CreateProjectFormValuesValidated } from "@project/presentation/components/project-form/project-form.component";
import { inject, injectable } from "inversify";
import { makeObservable } from "mobx";

@injectable()
export class CreateProjectPageViewModel extends BaseViewModel {
    constructor(
        @inject(CreateProjectUseCase)
        private readonly createProjectUseCase: CreateProjectUseCase,
        @inject(CreateProjectMapper)
        private readonly createProjectMapper: CreateProjectMapper,
    ) {
        super();
        makeObservable(this);
    }

    async createProject(
        values: CreateProjectFormValuesValidated,
    ): Promise<Nullable<Project>> {
        LoadLayoutStore.start();

        const createdProject = await this.createProjectUseCase.execute(
            this.createProjectMapper.mapFromProjectFormValues(values),
        );

        LoadLayoutStore.finish();

        if (createdProject) {
            ToastManagerStore.success();
        }

        return createdProject;
    }
}
