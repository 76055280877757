import { Address } from "@beneficiary/domain/models/address.model";
import { OrderFieldsQuery, OrderQuery } from "@core/data/dto/order.query";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { OrderField } from "@core/domain/models/order.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { DistrictDto } from "./district.dto";

export class AddressDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    address!: string;

    @IsString()
    @IsNotEmpty()
    number!: string;

    @IsString()
    @IsNotEmpty()
    postal_code!: string;

    @IsString()
    @IsNotEmpty()
    city!: string;

    @IsNumber()
    @IsOptional()
    district!: Nullable<number>;

    @IsString()
    @IsOptional()
    stair!: Nullable<string>;

    @IsString()
    @IsOptional()
    door!: Nullable<string>;

    @IsString()
    @IsOptional()
    floor!: Nullable<string>;

    @IsString()
    @IsOptional()
    letter!: Nullable<string>;

    @IsOptional()
    @Type(() => DistrictDto)
    district_data!: Nullable<DistrictDto>;
}

export class AddressesDto extends PaginatedDto {
    @IsArray()
    @Type(() => AddressDto)
    results!: AddressDto[];
}

export interface CreateAddressBody {
    address: AddressDto["address"];
    number: AddressDto["number"];
    postal_code: AddressDto["postal_code"];
    city?: Undefinable<AddressDto["city"]>;
    district?: Undefinable<AddressDto["district"]>;
    stair?: Undefinable<AddressDto["stair"]>;
    door?: Undefinable<AddressDto["door"]>;
    floor?: Undefinable<AddressDto["floor"]>;
    letter?: Undefinable<AddressDto["letter"]>;
}

export interface EditAddressBody extends CreateAddressBody {
    id: AddressDto["id"];
}

export type AddressesQuery = PaginatedQuery & {
    id?: Undefinable<number>;
    address?: Undefinable<string>;
    number?: Undefinable<string>;
    postal_code?: Undefinable<string>;
    city?: Undefinable<string>;
    district?: Undefinable<string>;
    stair?: Undefinable<string>;
    door?: Undefinable<string>;
    floor?: Undefinable<string>;
    letter?: Undefinable<string>;
    ordering?: OrderQuery<AddressDto>;
};

export interface AddressSearchFilters {
    id: Undefinable<number>;
}

export const addressesOrderMap: Record<
    OrderField<Address>,
    Nullable<OrderFieldsQuery<AddressDto>>
> = {
    id: "id",
    address: "address",
    number: "number",
    postalCode: "postal_code",
    city: "city",
    district: "district",
    stair: "stair",
    door: "door",
    floor: "floor",
    letter: "letter",
    modified: "modified",
};
