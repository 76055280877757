import { Nullable } from "@core/domain/types/nullable.type";
import { EmployeeTypeEnum } from "@entity/domain/models/employee/employee-type.model";
import { injectable } from "inversify";
import { EmployeeTypeEnumDto } from "../../dto/employee/employee-type.dto";

@injectable()
export class EmployeeTypeEnumMapper {
    map(employeeTypeEnumDto: EmployeeTypeEnumDto): Nullable<EmployeeTypeEnum> {
        switch (employeeTypeEnumDto) {
            case EmployeeTypeEnumDto.Employee:
                return EmployeeTypeEnum.Employee;
            case EmployeeTypeEnumDto.Freelance:
                return EmployeeTypeEnum.Freelance;
            case EmployeeTypeEnumDto.Volunteer:
                return EmployeeTypeEnum.Volunteer;
            default:
                return null;
        }
    }

    mapToDto(employeeTypeEnum: EmployeeTypeEnum): EmployeeTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (employeeTypeEnum) {
            case EmployeeTypeEnum.Employee:
                return EmployeeTypeEnumDto.Employee;
            case EmployeeTypeEnum.Freelance:
                return EmployeeTypeEnumDto.Freelance;
            case EmployeeTypeEnum.Volunteer:
                return EmployeeTypeEnumDto.Volunteer;
        }
    }
}
