import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EditProjectMapper } from "@project/data/mappers/edit-project.mapper";
import { Project } from "@project/domain/models/project.model";
import { EditProjectUseCase } from "@project/domain/usecases/edit-project.usecase";
import { GetProjectByIdUseCase } from "@project/domain/usecases/get-project-by-id.usecase";
import type { EditProjectFormValuesValidated } from "@project/presentation/components/project-form/project-form.component";
import { inject, injectable } from "inversify";
import {
    action,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

@injectable()
export class EditProjectPageViewModel extends BaseViewModel {
    projectId: Undefinable<number> = undefined;

    @observable
    initiallyLoading = true;

    @observable
    project: Undefinable<Project> = undefined;

    constructor(
        @inject(EditProjectUseCase)
        private readonly editProjectUseCase: EditProjectUseCase,

        @inject(EditProjectMapper)
        private readonly editProjectMapper: EditProjectMapper,

        @inject(GetProjectByIdUseCase)
        private readonly getProjectByIdUseCase: GetProjectByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(this.getProject());

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    @action
    setProject(project: Project): void {
        this.project = project;
    }

    async getProject(): Promise<void> {
        if (this.projectId === undefined) throw Error("Missing projectId");

        const project = await this.getProjectByIdUseCase.execute(
            this.projectId,
        );

        if (project) {
            this.setProject(project);
        }
    }

    async editProject(
        projectForm: EditProjectFormValuesValidated,
    ): Promise<boolean> {
        if (!this.projectId) throw Error("Missing projectId");

        LoadLayoutStore.start();

        const editProject = this.editProjectMapper.mapFromProjectFormValues(
            this.projectId,
            projectForm,
        );

        const editedSuccessfully =
            await this.editProjectUseCase.execute(editProject);

        if (editedSuccessfully) {
            await this.getProject();
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return editedSuccessfully;
    }
}
