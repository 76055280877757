import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { CreateDepositMapper } from "../../data/mappers/deposit/create-deposit.mapper";
import { DepositRepository } from "../../data/repositories/deposit.repository";
import { DepositFormValuesValidated } from "../../presentation/component/entity-form/deposits/form/deposit.form";

@injectable()
export class CreateDepositUseCase {
    constructor(
        @inject(DepositRepository)
        private readonly depositRepository: DepositRepository,
        @inject(CreateDepositMapper)
        private readonly createDepositMapper: CreateDepositMapper,
    ) {}

    async execute(deposit: DepositFormValuesValidated): Promise<void> {
        const createResult = await this.depositRepository.create(
            this.createDepositMapper.mapToDeposit(deposit),
        );

        if (createResult.isLeft()) {
            ToastManagerStore.error(createResult.getLeftOrThrow().message);
        }
    }
}
