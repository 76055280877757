import { Nullable } from "@core/domain/types/nullable.type";
import { IRPFTypeDto } from "@entity/data/dto/irpf-type-enum.dto";
import { IRPFType } from "@entity/domain/models/cost/irpf-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { IRPFTypeEnumMapper } from "./irpf-type-enum.mapper";

@injectable()
export class IRPFTypeMapper {
    constructor(
        @inject(IRPFTypeEnumMapper)
        private readonly irpfTypeEnumMapper: IRPFTypeEnumMapper,
    ) {}

    map(irpfTypeDto: IRPFTypeDto): Nullable<IRPFType> {
        const errors = validateSync(irpfTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.irpfTypeEnumMapper.map(irpfTypeDto.key);
        if (!id) return null;

        return new IRPFType(id, irpfTypeDto.value);
    }
}
