import { IsUserLoggedUseCase } from "@authentication/domain/usecases/is-user-logged.usecase";
import { container } from "@di/inversify.config";
import { HomeRoutes } from "@routes/private/home.routes";
import { LoginRoutes } from "@routes/public/login.routes";
import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

export const MissingRoute: FC = () => {
    const location = useLocation();
    const isUserLogged = container.get(IsUserLoggedUseCase).execute();

    console.error(`unmatched url: ${location.pathname}`);

    // In case no matched route, we redirect to the "main" route in dashboard
    // if user is logged, if not to the login
    const redirectToRoute = isUserLogged ? HomeRoutes.HOME : LoginRoutes.LOGIN;

    return (
        <Navigate
            to={redirectToRoute}
            replace
        />
    );
};
