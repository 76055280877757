import { Nullable } from "@core/domain/types/nullable.type";
import { SubsidyReasonDto } from "@entity/data/dto/employee/subsidy-reason.dto";
import { SubsidyReasonEnumMapper } from "@entity/data/mappers/employee/subsidy-reason-enum.mapper";
import {
    SubsidyReason,
    SubsidyReasonEnum,
} from "@entity/domain/models/employee/subsidy-reason.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class SubsidyReasonMapper {
    constructor(
        @inject(SubsidyReasonEnumMapper)
        private subsidyReasonypeEnumMapper: SubsidyReasonEnumMapper,
    ) {}

    map(subsidyReasonEnumMapperDto: SubsidyReasonDto): Nullable<SubsidyReason> {
        const errors = validateSync(subsidyReasonEnumMapperDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<SubsidyReasonEnum> =
            this.subsidyReasonypeEnumMapper.map(subsidyReasonEnumMapperDto.key);

        if (!id) return null;

        return new SubsidyReason(id, subsidyReasonEnumMapperDto.value);
    }
}
