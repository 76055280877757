import { DocumentDatasource } from "@core/data/datasources/document.datasource";
import { CreateDocumentError } from "@core/domain/errors/create-document.error";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { CreateDocument } from "@core/domain/models/create-document.model";
import { DocumentLicenseType } from "@core/domain/models/document-type.model";
import { DocumentCategories } from "@core/domain/models/documents-category.model";
import {
    DocumentTypeLicenseEnum,
    IncDocument,
} from "@core/domain/models/inc-document.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Map } from "immutable";
import { inject, injectable } from "inversify";

@injectable()
export class DocumentRepository {
    constructor(
        @inject(DocumentDatasource)
        private readonly documentDatasource: DocumentDatasource,
    ) {}

    async create(
        createDocument: CreateDocument,
    ): Promise<Either<CreateDocumentError, IncDocument>> {
        return this.documentDatasource.create(createDocument);
    }

    async getById(id: number): Promise<Either<FallbackError, IncDocument>> {
        return this.documentDatasource.fetchById(id);
    }

    async delete(documentId: number): Promise<Either<FallbackError, true>> {
        return this.documentDatasource.delete(documentId);
    }

    /**
     *
     * @param documentUrl
     * @param name: will override original file name
     */
    async download(
        documentUrl: string,
        name: string | undefined,
    ): Promise<void> {
        await this.documentDatasource.download(documentUrl, name);
    }

    async sign(
        documentUrl: number,
        signature: string,
        userId: number,
    ): Promise<Either<ValidationError | FallbackError, boolean>> {
        return this.documentDatasource.sign(documentUrl, signature, userId);
    }

    async editSignDocument(
        signatureId: number,
        documentId: number,
        signature: string,
        userId: number,
    ): Promise<Either<ValidationError | FallbackError, boolean>> {
        return this.documentDatasource.editSignature(
            signatureId,
            documentId,
            signature,
            userId,
        );
    }

    async getAllDocumentTypes(): Promise<
        Either<FallbackError, Map<DocumentTypeLicenseEnum, DocumentLicenseType>>
    > {
        return (
            await this.documentDatasource.fetchAllDocumentLicenseTypes()
        ).mapLeft(() => new FallbackError());
    }

    async getAllDocumentCategories(
        pagination: Pagination,
    ): Promise<Either<FallbackError, DocumentCategories>> {
        return this.documentDatasource.fetchAllDocumentCategories(pagination);
    }
}
