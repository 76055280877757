import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { PublicUtilityDatasource } from "@entity/data/datasources/public-utility.datasource";
import { CreatePublicUtility } from "@entity/domain/models/public-utility/create-public-utility.model";
import { EditPublicUtility } from "@entity/domain/models/public-utility/edit-public-utility.model";
import {
    PublicUtilities,
    PublicUtility,
} from "@entity/domain/models/public-utility/public-utility.model";
import { inject, injectable } from "inversify";

@injectable()
export class PublicUtilityRepository {
    constructor(
        @inject(PublicUtilityDatasource)
        private readonly publicUtilityDatasource: PublicUtilityDatasource,
    ) {}

    async getAll(
        pagination: Pagination,
    ): Promise<Either<FallbackError, PublicUtilities>> {
        const publicUtilityResult =
            await this.publicUtilityDatasource.fetchAll(pagination);

        return publicUtilityResult.mapLeft(() => new FallbackError());
    }

    async create(
        publicUtility: CreatePublicUtility,
    ): Promise<Either<ValidationError | FallbackError, PublicUtility>> {
        return this.publicUtilityDatasource.create(publicUtility);
    }

    async edit(
        editPublicUtility: EditPublicUtility,
    ): Promise<Either<ValidationError | FallbackError, PublicUtility>> {
        return this.publicUtilityDatasource.edit(editPublicUtility);
    }

    async delete(
        publicUtilityId: number,
    ): Promise<Either<FallbackError, true>> {
        const publicUtilityResult =
            await this.publicUtilityDatasource.delete(publicUtilityId);
        return publicUtilityResult.mapLeft(() => new FallbackError());
    }
}
