import { DateTime } from "luxon";

export class PublicUtility {
    constructor(
        public id: number,
        public organizationType: string,
        public entity: number,
        public date: DateTime,
    ) {}
}

export class PublicUtilities {
    constructor(
        public readonly publicUtilities: PublicUtility[],
        public readonly count: number,
    ) {}
}
