import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { EditSupplierBody } from "@entity/data/dto/supplier/edit-supplier.body";
import { ServiceProvidedEnumMapper } from "@entity/data/mappers/supplier/service-provided-enum.mapper";
import { SupplierIdentificationEnumMapper } from "@entity/data/mappers/supplier/supplier-identification-enum.mapper";
import { SupplierTypeEnumMapper } from "@entity/data/mappers/supplier/supplier-type-enum.mapper";
import { EditSupplier } from "@entity/domain/models/supplier/edit-supplier.model";
import { SupplierFormValuesValidated } from "@entity/presentation/component/supplier-form/supplier-form.component";
import { inject, injectable } from "inversify";

@injectable()
export class EditSupplierMapper {
    constructor(
        @inject(SupplierIdentificationEnumMapper)
        private readonly supplierIdentificationEnumMapper: SupplierIdentificationEnumMapper,
        @inject(SupplierTypeEnumMapper)
        private readonly supplierTypeEnumMapper: SupplierTypeEnumMapper,
        @inject(ServiceProvidedEnumMapper)
        private serviceProvidedEnumMapper: ServiceProvidedEnumMapper,
    ) {}
    mapFromSupplierFormValues(
        supplierForm: SupplierFormValuesValidated,
        entityId: number,
        supplierId: number,
    ): EditSupplier {
        const editSupplier = new EditSupplier(
            supplierId,
            entityId,
            supplierForm.companyName,
            supplierForm.contactPerson,
            supplierForm.responsableEmail,
            supplierForm.phone1,
            supplierForm.type,
            supplierForm.identificationType,
            supplierForm.identification,
            supplierForm.street,
            supplierForm.zipCode,
            supplierForm.town,
            supplierForm.province,
        );

        editSupplier.contractStartDate = convertEmptyToUndefined(
            supplierForm.contractStartDate,
        );
        editSupplier.contractEndDate = convertEmptyToUndefined(
            supplierForm.contractEndDate,
        );
        editSupplier.serviceProvided = convertEmptyToUndefined(
            supplierForm.serviceProvided,
        );
        editSupplier.otherServiceProvided = convertEmptyToUndefined(
            supplierForm.otherServiceProvided,
        );
        editSupplier.serviceProvidedDescription = convertEmptyToUndefined(
            supplierForm.serviceProvidedDescription,
        );
        editSupplier.qualityCertifications = supplierForm.qualityCertifications;
        editSupplier.qualityCertificationsDate = convertEmptyToUndefined(
            supplierForm.qualityCertificationsDate,
        );

        editSupplier.serviceEvaluation = supplierForm.serviceEvaluation;
        editSupplier.serviceEvaluationDate = convertEmptyToUndefined(
            supplierForm.serviceEvaluationDate,
        );
        editSupplier.serviceEvaluationDetail = convertEmptyToUndefined(
            supplierForm.serviceEvaluationDetail,
        );
        editSupplier.active = supplierForm.active;
        editSupplier.documents = convertEmptyToUndefined(
            supplierForm.documents,
        );

        return editSupplier;
    }

    mapToDto(editSupplier: EditSupplier): EditSupplierBody {
        const identification_type =
            this.supplierIdentificationEnumMapper.mapToDto(
                editSupplier.identificationType,
            );

        const type = this.supplierTypeEnumMapper.mapToDto(editSupplier.type);

        const services_provided = editSupplier.serviceProvided
            ? editSupplier.serviceProvided.map((serviceProv) =>
                  this.serviceProvidedEnumMapper.mapToDto(serviceProv),
              )
            : undefined;

        return {
            id: editSupplier.id,
            entity: editSupplier.entity,
            company_name: editSupplier.companyName,
            contact_person: editSupplier.contactPerson,
            responsable_email: editSupplier.responsableEmail,
            phone1: editSupplier.phone1,
            type,
            identification_type,
            identification: editSupplier.identification,
            street: editSupplier.street,
            zip_code: editSupplier.zipCode,
            town: editSupplier.town,
            province: editSupplier.province,
            contract_start_date: convertEmptyToUndefined(
                editSupplier.contractStartDate?.toISODate(),
            ),
            contract_end_date: convertEmptyToUndefined(
                editSupplier.contractEndDate?.toISODate(),
            ),
            services_provided,
            other_services_provided: convertEmptyToUndefined(
                editSupplier.otherServiceProvided,
            ),
            details_services_provided: convertEmptyToUndefined(
                editSupplier.serviceProvidedDescription,
            ),
            quality_certifications: editSupplier.qualityCertifications ?? false,
            quality_certifications_date: convertEmptyToUndefined(
                editSupplier.qualityCertificationsDate?.toISODate(),
            ),
            service_evaluation: editSupplier.serviceEvaluation ?? false,
            service_evaluation_date: convertEmptyToUndefined(
                editSupplier.serviceEvaluationDate?.toISODate(),
            ),
            service_evaluation_detail: convertEmptyToUndefined(
                editSupplier.serviceEvaluationDetail,
            ),
            active: editSupplier.active ?? false,
            documents:
                editSupplier.documents?.map((document) => document.id) ??
                undefined,
        };
    }
}
