export class DocumentSignature {
    constructor(
        public readonly id: number,
        public readonly document: number,
        public readonly user: number,
        public readonly created: string,
        public readonly modified: string,
        public readonly image: string,
        public readonly md5: string,
    ) {}
}

export class DocumentSignatures {
    constructor(
        public readonly documentSignatures: DocumentSignature[],
        public readonly count: number,
    ) {}
}
