import { Nullable } from "@core/domain/types/nullable.type";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import {
    QualityCertificateDto,
    QualityCertificatesDto,
} from "@entity/data/dto/quality-framework/quality-certificate/quality-certificate.dto";
import { QualityCertificateTypeEnumMapper } from "@entity/data/mappers/quality-certificate-type-enum.mapper";
import {
    QualityCertificate,
    QualityCertificates,
} from "@entity/domain/models/quality-framework/quality-certificate/quality-certificate.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class QualityCertificatesMapper {
    constructor(
        @inject(QualityCertificateTypeEnumMapper)
        private readonly qualityCertificateTypeEnumMapper: QualityCertificateTypeEnumMapper,
    ) {}

    map(qualityCertificatesDto: QualityCertificatesDto): QualityCertificates {
        const errors = validateSync(qualityCertificatesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new QualityCertificates([], 0);
        }

        const qualityCertificates = qualityCertificatesDto.results.mapNotNull(
            (qualityCertificateDto) =>
                this.mapQualityCertificate(qualityCertificateDto),
        );

        return new QualityCertificates(
            qualityCertificates,
            qualityCertificatesDto.count,
        );
    }

    mapQualityCertificate(
        qualityCertificateDto: QualityCertificateDto,
    ): Nullable<QualityCertificate> {
        if (!qualityCertificateDto.id) {
            return null;
        }

        const qualityCertificateType =
            this.qualityCertificateTypeEnumMapper.map(
                qualityCertificateDto.quality_certificate_type,
            );
        if (!qualityCertificateType) return null;

        const qualityCertificate: QualityCertificate = {
            id: qualityCertificateDto.id,
            qualityCertificateType,
            entity: qualityCertificateDto.entity,
            year: qualityCertificateDto.year,
            expirationDate: qualityCertificateDto.expiration_date
                ? DateTime.fromISO(qualityCertificateDto.expiration_date)
                : undefined,
            otherQualityCertificate: convertEmptyToUndefined(
                qualityCertificateDto.other_quality_certificate,
            ),
        };
        return qualityCertificate;
    }
}
