import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    ContributionGroupType,
    ContributionGroupTypeEnum,
} from "../../../domain/models/expenses/contribution-group-type.model";
import { ContributionGroupTypeDto } from "../../dto/expenses/contribution-group-type.dto";
import { ContributionGroupTypeEnumMapper } from "./contribution-group-type-enum.mapper";

@injectable()
export class ContributionGroupTypeMapper {
    constructor(
        @inject(ContributionGroupTypeEnumMapper)
        private readonly contributionGroupEnumMapper: ContributionGroupTypeEnumMapper,
    ) {}

    map(
        contributionGroupDto: ContributionGroupTypeDto,
    ): Nullable<ContributionGroupType> {
        const errors = validateSync(contributionGroupDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<ContributionGroupTypeEnum> =
            this.contributionGroupEnumMapper.map(contributionGroupDto.key);

        if (!id) return null;

        return new ContributionGroupType(id, contributionGroupDto.value);
    }
}
