import { SocialNetworkRepository } from "@entity/data/repositories/social-network.repository";
import { inject, injectable } from "inversify";
import {
    SocialNetworkFilters,
    SocialNetworkSummary,
} from "../../models/social-networks-summary.model";

@injectable()
export class GetAllSocialNetworksUseCase {
    constructor(
        @inject(SocialNetworkRepository)
        private readonly socialNetworkRepository: SocialNetworkRepository,
    ) {}

    async execute(
        filters?: SocialNetworkFilters,
    ): Promise<SocialNetworkSummary[]> {
        const socialNetworkResult =
            await this.socialNetworkRepository.getAllBy(filters);

        return socialNetworkResult.getOrElse([]);
    }
}
