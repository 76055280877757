import { ExpenseRepository } from "@beneficiary/data/repositories/expense.repository";
import {
    EditExpense,
    Expense,
} from "@beneficiary/domain/models/economic-data/expense/expense.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class EditExpenseUseCase {
    constructor(
        @inject(ExpenseRepository)
        private readonly expenseRepository: ExpenseRepository,
    ) {}

    async execute(internalNote: EditExpense): Promise<Nullable<Expense>> {
        const editResult = await this.expenseRepository.edit(internalNote);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedExpense) => editedExpense,
        );
    }
}
