import { Nullable } from "@core/domain/types/nullable.type";
import {
    FreelanceContractDto,
    FreelanceContractsDto,
} from "@entity/data/dto/employee/contracts/freelance/freelance-contract.dto";
import { FreelanceContractMapper } from "@entity/data/mappers/employee/contracts/freelance/freelance-contract.mapper";
import { ProfessionalGroupEnumMapper } from "@entity/data/mappers/employee/professional-group-enum.mapper";
import {
    FreelanceContract,
    FreelanceContracts,
} from "@entity/domain/models/employee/contracts/freelance/freelance-contract.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class FreelanceContractsMapper {
    constructor(
        @inject(FreelanceContractMapper)
        private readonly freelanceContractMapper: FreelanceContractMapper,
        @inject(ProfessionalGroupEnumMapper)
        private readonly professionalGroupEnumMapper: ProfessionalGroupEnumMapper,
    ) {}

    map(freelanceContractsDto: FreelanceContractsDto): FreelanceContracts {
        const errors = validateSync(freelanceContractsDto);

        if (errors.length > 0) {
            console.error(errors);

            return new FreelanceContracts([], 0);
        }

        const freelanceContracts = freelanceContractsDto.results.mapNotNull(
            (freelanceDto) => this.freelanceContractMapper.map(freelanceDto),
        );

        return new FreelanceContracts(
            freelanceContracts,
            freelanceContractsDto.count,
        );
    }

    mapContract(
        freelanceContractDto: FreelanceContractDto,
    ): Nullable<FreelanceContract> {
        if (!freelanceContractDto.id) {
            return null;
        }

        const professionalGroup = this.professionalGroupEnumMapper.map(
            freelanceContractDto.professional_group,
        );

        if (!professionalGroup) return null;

        const freelanceContract: FreelanceContract = {
            id: freelanceContractDto.id,
            service: freelanceContractDto.service,
            professionalGroup,
            startDate: DateTime.fromISO(freelanceContractDto.start_date),
            totalHours: Number(freelanceContractDto.total_hours),
            hoursCost: Number(freelanceContractDto.hours_cost),
            totalCost: Number(freelanceContractDto.total_cost),
            invoicesNumber: Number(freelanceContractDto.invoices_number),
            irpf: Number(freelanceContractDto.IRPF),
            employeeId: freelanceContractDto.employee,
            endDate: freelanceContractDto.end_date
                ? DateTime.fromISO(freelanceContractDto.end_date)
                : null,
        };

        return freelanceContract;
    }
}
