import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { StatutoryTerritorialScopeRepository } from "@entity/data/repositories/statutory-territorail-scope.repository";
import { CreateStatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/create-statutory-territorial-scope.model";
import { StatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/statutory-territorial-scope.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateStatutoryTerritorialScopeUseCase {
    constructor(
        @inject(StatutoryTerritorialScopeRepository)
        private readonly statutoryTerritorialScopeRepository: StatutoryTerritorialScopeRepository,
    ) {}

    async execute(
        statutoryTerritorialScope: CreateStatutoryTerritorialScope,
    ): Promise<Nullable<StatutoryTerritorialScope>> {
        const creationResult =
            await this.statutoryTerritorialScopeRepository.create(
                statutoryTerritorialScope,
            );

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdStatutoryTerritorialScope) =>
                createdStatutoryTerritorialScope,
        );
    }
}
