import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { SupplierDto } from "@entity/data/dto/supplier/supplier.dto";
import { ServiceProvidedEnumMapper } from "@entity/data/mappers/supplier/service-provided-enum.mapper";
import { SupplierIdentificationEnumMapper } from "@entity/data/mappers/supplier/supplier-identification-enum.mapper";
import { SupplierTypeEnumMapper } from "@entity/data/mappers/supplier/supplier-type-enum.mapper";
import { Supplier } from "@entity/domain/models/supplier/supplier.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class SupplierMapper {
    constructor(
        @inject(SupplierTypeEnumMapper)
        private readonly supplierTypeEnumMapper: SupplierTypeEnumMapper,
        @inject(SupplierIdentificationEnumMapper)
        private readonly supplierIdentificationEnumMapper: SupplierIdentificationEnumMapper,
        @inject(ServiceProvidedEnumMapper)
        private serviceProvidedEnumMapper: ServiceProvidedEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
    ) {}

    map(supplierDto: SupplierDto): Nullable<Supplier> {
        const errors = validateSync(supplierDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const type = this.supplierTypeEnumMapper.map(supplierDto.type);
        if (!type) return null;

        const identificationType = this.supplierIdentificationEnumMapper.map(
            supplierDto.identification_type,
        );
        if (!identificationType) return null;

        const documentsData: IncDocument[] =
            supplierDto.documents_data?.mapNotNull((doc) =>
                this.documentMapper.map(doc),
            ) ?? [];

        const supplier = new Supplier(
            supplierDto.id,
            supplierDto.entity,
            supplierDto.company_name,
            supplierDto.contact_person,
            supplierDto.responsable_email,
            supplierDto.phone1,
            type,
            identificationType,
            supplierDto.identification,
            supplierDto.street,
            supplierDto.zip_code,
            supplierDto.town,
            supplierDto.province,
        );

        if (supplierDto.contract_start_date) {
            supplier.contractStartDate = DateTime.fromISO(
                supplierDto.contract_start_date,
            );
        }

        if (supplierDto.contract_end_date) {
            supplier.contractEndDate = DateTime.fromISO(
                supplierDto.contract_end_date,
            );
        }

        if (supplierDto.services_provided) {
            supplier.serviceProvided = supplierDto.services_provided.mapNotNull(
                (serviceProv) =>
                    this.serviceProvidedEnumMapper.map(serviceProv),
            );
        }

        supplier.otherServiceProvided = convertEmptyToUndefined(
            supplierDto.other_services_provided,
        );
        supplier.serviceProvidedDescription = convertEmptyToUndefined(
            supplierDto.details_services_provided,
        );

        supplier.qualityCertifications = supplierDto.quality_certifications;

        if (supplierDto.quality_certifications_date) {
            supplier.qualityCertificationsDate = DateTime.fromISO(
                supplierDto.quality_certifications_date,
            );
        }

        supplier.serviceEvaluation = supplierDto.service_evaluation;

        if (supplierDto.service_evaluation_date) {
            supplier.serviceEvaluationDate = DateTime.fromISO(
                supplierDto.service_evaluation_date,
            );
        }

        supplier.serviceEvaluationDetail = convertEmptyToUndefined(
            supplierDto.service_evaluation_detail,
        );
        supplier.active = supplierDto.active;

        supplier.documents = documentsData;

        return supplier;
    }
}
