import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { DepositRepository } from "@entity/data/repositories/deposit.repository";
import {
    CreateSplitDeposit,
    SplitDeposit,
} from "@entity/domain/models/deposit.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateSplitDepositUseCase {
    constructor(
        @inject(DepositRepository)
        private readonly depositRepository: DepositRepository,
    ) {}

    async execute(
        splitDeposit: CreateSplitDeposit,
    ): Promise<Nullable<SplitDeposit>> {
        const createResult =
            await this.depositRepository.createSplitDeposit(splitDeposit);

        if (createResult.isLeft()) {
            ToastManagerStore.error(createResult.getLeftOrThrow().message);
        }

        return createResult.fold(
            () => null,
            (edited) => edited,
        );
    }
}
