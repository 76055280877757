import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import {
    CreateProjectActivityTaskBody,
    ProjectActivityTask,
} from "../../domain/models/project-activity-task.model";
import { KanbanTaskDto } from "../dto/kanban/task.dto";
import { ActivityTaskMapper } from "../mappers/activity-task.mapper";

const PROJECT_ACTIVITIES_TASK_PATH = "/kanban_tasks/";

@injectable()
export class ProjectActivityTaskDataSource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(ActivityTaskMapper)
        private readonly activityTaskMapper: ActivityTaskMapper,
    ) {}

    async create(
        activityTask: CreateProjectActivityTaskBody,
    ): Promise<Either<ValidationError | FallbackError, ProjectActivityTask>> {
        const activityTaskToCreate =
            this.activityTaskMapper.mapToCreateTaskDto(activityTask);

        const activityTaskResult = await this.http.post<KanbanTaskDto>(
            PROJECT_ACTIVITIES_TASK_PATH,
            activityTaskToCreate,
        );

        return activityTaskResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const project = this.activityTaskMapper.map(
                    plainToClass(KanbanTaskDto, response.data),
                );

                if (!project) return Either.Left(new FallbackError());

                return Either.Right(project);
            });
    }

    async update(
        activityTask: ProjectActivityTask,
    ): Promise<Either<ValidationError | FallbackError, ProjectActivityTask>> {
        const activityTaskToCreate =
            this.activityTaskMapper.mapToUpdateTaskDto(activityTask);

        const activityTaskResult = await this.http.patch<KanbanTaskDto>(
            PROJECT_ACTIVITIES_TASK_PATH,
            activityTaskToCreate,
        );

        return activityTaskResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const project = this.activityTaskMapper.map(
                    plainToClass(KanbanTaskDto, response.data),
                );

                if (!project) return Either.Left(new FallbackError());

                return Either.Right(project);
            });
    }

    async delete(taskId: number): Promise<Either<FallbackError, true>> {
        const deleteResult = await this.http.delete(
            `${PROJECT_ACTIVITIES_TASK_PATH}${taskId}/`,
        );

        return deleteResult.mapLeft(() => new FallbackError()).map(() => true);
    }
}
