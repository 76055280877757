import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateEmployeeContractMapper } from "@entity/data/mappers/employee/contracts/employee/create-employee-contract.mapper";
import { EditEmployeeContractMapper } from "@entity/data/mappers/employee/contracts/employee/edit-employee-contract.mapper";
import {
    EmployeeContract,
    type EmployeeContractSearchFilters,
} from "@entity/domain/models/employee/contracts/employee/employee-contract.model";
import { ContractTermination } from "@entity/domain/models/employee/contracts/termination/contract-termination.model";
import { DisabilityType } from "@entity/domain/models/employee/disability-type.model";
import { ProfessionalGroup } from "@entity/domain/models/employee/professional-group.model";
import { SubsidyReason } from "@entity/domain/models/employee/subsidy-reason.model";
import { TerminationReason } from "@entity/domain/models/employee/termination-reason.model";
import { CreateEmployeeContractUseCase } from "@entity/domain/usecases/employee/contracts/employee/create-employee-contract.usecase";
import { DeleteEmployeeContractUseCase } from "@entity/domain/usecases/employee/contracts/employee/delete-employee-contract.usecase";
import { EditEmployeeContractUseCase } from "@entity/domain/usecases/employee/contracts/employee/edit-employee-contract.usecase";
import { GetAllEmployeeContractsByUseCase } from "@entity/domain/usecases/employee/contracts/employee/get-all-employee-contracts-by.usecase";
import { DeleteContractTerminationUseCase } from "@entity/domain/usecases/employee/contracts/termination/delete-contract-termination.usecase";
import { GetAllContractTerminationsUseCase } from "@entity/domain/usecases/employee/contracts/termination/get-all-contract-terminations.usecase";
import { GetAllDisabilityTypesUseCase } from "@entity/domain/usecases/employee/get-all-disability-types.usecase";
import { GetAllProfessionalGroupsUseCase } from "@entity/domain/usecases/employee/get-all-professional-groups.usecase";
import { GetAllSubsidyReasonsUseCase } from "@entity/domain/usecases/employee/get-all-subsidy-reasons.usecase";
import { GetAllTerminationReasonsUseCase } from "@entity/domain/usecases/employee/get-all-termination-reasons.usecase";
import { EmployeeContractFormValuesValidated } from "@entity/presentation/component/employee-form/contract/employee/form/employee-contract-form";
import { ContractType } from "@project/domain/models/expenses/contract-type.model";
import { ContributionGroupType } from "@project/domain/models/expenses/contribution-group-type.model";
import { GetAllContractTypesUseCase } from "@project/domain/usecases/expenses/get-all-contract-types.usecase";
import { GetAllContributionGroupTypesUseCase } from "@project/domain/usecases/expenses/get-all-contribution-group-types.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class EmployeeContractTabViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    professionalGroups: ProfessionalGroup[] = [];

    @observable
    contributionGroups: ContributionGroupType[] = [];

    @observable
    disabilityTypes: DisabilityType[] = [];

    @observable
    terminationReasons: TerminationReason[] = [];

    @observable
    subsidyReasons: SubsidyReason[] = [];

    @observable
    contractTypes: ContractType[] = [];

    @observable
    employeeContracts: EmployeeContract[] = [];

    employeeId: Undefinable<number> = undefined;

    @observable
    contractTerminations: ContractTermination[] = [];

    @observable
    showAddContractModal: boolean = false;

    @observable
    showEditContractModal: boolean = false;

    @observable
    contractToEdit: Undefinable<EmployeeContract> = undefined;

    filtersValue: EmployeeContractSearchFilters = {
        employee: undefined,
    };

    @observable
    filters: EmployeeContractSearchFilters = this.filtersValue;

    constructor(
        @inject(CreateEmployeeContractUseCase)
        private readonly createEmployeeContractUseCase: CreateEmployeeContractUseCase,
        @inject(CreateEmployeeContractMapper)
        private readonly createEmployeeContractMapper: CreateEmployeeContractMapper,
        @inject(DeleteContractTerminationUseCase)
        private readonly deleteContractTerminationUseCase: DeleteContractTerminationUseCase,
        @inject(GetAllContractTerminationsUseCase)
        private readonly getAllContractTerminationsUseCase: GetAllContractTerminationsUseCase,
        @inject(EditEmployeeContractUseCase)
        private readonly editEmployeeContractUseCase: EditEmployeeContractUseCase,
        @inject(EditEmployeeContractMapper)
        private readonly editEmployeeContractMapper: EditEmployeeContractMapper,
        @inject(DeleteEmployeeContractUseCase)
        private readonly deleteEmployeeContractUseCase: DeleteEmployeeContractUseCase,
        @inject(GetAllProfessionalGroupsUseCase)
        private readonly getAllProfessionalGroupsUseCase: GetAllProfessionalGroupsUseCase,
        @inject(GetAllContributionGroupTypesUseCase)
        private readonly getAllContributionGroupTypesUseCase: GetAllContributionGroupTypesUseCase,
        @inject(GetAllContractTypesUseCase)
        private readonly getAllContractTypesUseCase: GetAllContractTypesUseCase,
        @inject(GetAllEmployeeContractsByUseCase)
        private readonly getAllEmployeeContractsByUseCase: GetAllEmployeeContractsByUseCase,
        @inject(GetAllDisabilityTypesUseCase)
        private readonly getAllDisabilityTypesUseCase: GetAllDisabilityTypesUseCase,
        @inject(GetAllTerminationReasonsUseCase)
        private readonly getAllTerminationReasonsUseCase: GetAllTerminationReasonsUseCase,
        @inject(GetAllSubsidyReasonsUseCase)
        private readonly getAllSubsidyReasonsUseCase: GetAllSubsidyReasonsUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getProfessionalGroups(),
            this.getContributionGroups(),
            this.getContractTypes(),
            this.getDisabilityTypes(),
            this.getTerminationReasons(),
            this.getSubsidyReasons(),
            this.getEmployeeContracts(),
            this.getContractTerminations(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getEmployeeContracts(): Promise<void> {
        const employeeContracts =
            await this.getAllEmployeeContractsByUseCase.execute(this.filters);
        runInAction(() => {
            this.employeeContracts = employeeContracts;
        });
    }

    async addContract(
        employee: Undefinable<number>,
        values: EmployeeContractFormValuesValidated,
    ): Promise<Nullable<EmployeeContract>> {
        if (!employee) throw Error("Missing employeeId");

        LoadLayoutStore.start();

        const createContractResult =
            await this.createEmployeeContractUseCase.execute(
                this.createEmployeeContractMapper.mapFromFormValues(
                    employee,
                    values,
                ),
            );

        if (createContractResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createContractResult;
    }

    async editContract(
        values: EmployeeContractFormValuesValidated,
    ): Promise<void> {
        if (!this.contractToEdit?.id) throw Error("Missing employeeContract");

        if (!this.contractToEdit.employeeId) throw Error("Missing employeeId");

        LoadLayoutStore.start();

        const editContractResult =
            await this.editEmployeeContractUseCase.execute(
                this.editEmployeeContractMapper.mapFromEmployeeContractFormValues(
                    this.contractToEdit.id,
                    this.contractToEdit.employeeId,
                    values,
                ),
            );

        if (editContractResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async deleteContract(contractId: number): Promise<void> {
        LoadLayoutStore.start();

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        this.contractTerminations.some(async (contractTermination) => {
            if (contractTermination.contractId === contractId) {
                await this.deleteContractTerminationUseCase.execute(
                    contractTermination.id,
                );
            }
            return true;
        });

        const deleted =
            await this.deleteEmployeeContractUseCase.execute(contractId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    @action
    setEmployeeId(employeeId: number): void {
        this.employeeId = employeeId;
        this.filters = {
            ...this.filters,
            employee: employeeId,
        };
    }

    async reloadContracts(): Promise<void> {
        await this.getEmployeeContracts();
    }

    async getProfessionalGroups(): Promise<void> {
        const professionalGroups =
            await this.getAllProfessionalGroupsUseCase.execute();
        runInAction(() => {
            this.professionalGroups = professionalGroups;
        });
    }

    async getContributionGroups(): Promise<void> {
        const contributionGroups =
            await this.getAllContributionGroupTypesUseCase.execute();

        runInAction(() => {
            this.contributionGroups = contributionGroups;
        });
    }

    async getContractTypes(): Promise<void> {
        const contractTypes = await this.getAllContractTypesUseCase.execute();

        runInAction(() => {
            this.contractTypes = contractTypes;
        });
    }

    async getDisabilityTypes(): Promise<void> {
        const disabilityTypes =
            await this.getAllDisabilityTypesUseCase.execute();

        runInAction(() => {
            this.disabilityTypes = disabilityTypes;
        });
    }

    async getSubsidyReasons(): Promise<void> {
        const subsidyReasons = await this.getAllSubsidyReasonsUseCase.execute();

        runInAction(() => {
            this.subsidyReasons = subsidyReasons;
        });
    }

    async getTerminationReasons(): Promise<void> {
        const terminationReasons =
            await this.getAllTerminationReasonsUseCase.execute();

        runInAction(() => {
            this.terminationReasons = terminationReasons;
        });
    }

    async getContractTerminations(): Promise<void> {
        const contractTerminations =
            await this.getAllContractTerminationsUseCase.execute();

        runInAction(() => {
            this.contractTerminations = contractTerminations;
        });
    }

    @action
    getContractTypeLabel(typeId: string): string {
        const type = this.contractTypes.find(
            (t) => t.id === typeId.toUpperCase(),
        );
        return type ? type.label : "-";
    }

    @action
    setShowAddContractModal(show: boolean): void {
        this.showAddContractModal = show;
    }

    @action
    closeEditContractModal(): void {
        this.showEditContractModal = false;
    }

    @action
    openEditContractModal(
        employeeContract: Undefinable<EmployeeContract>,
    ): void {
        this.contractToEdit = employeeContract;
        this.showEditContractModal = true;
    }
}
