import { IsEnum, IsNotEmpty, IsString } from "class-validator";
export enum FinancialEntityTypeEnumDto {
    ADMIN_INTERNATIONAL = "admin_international",
    ADMIN_LOCAL = "admin_local",
    ADMIN_NATIONAL = "admin_national",
    ADMIN_REGIONAL = "admin_regional",
    ASSOCIATION = "association",
    BANK_ENTITY = "bank_entity",
    CCAA = "ccaa",
    IRPF = "irpf",
    OWN = "own",
    PRIVATE_ENTITY = "private_entity",
    GENERIC_ENTITY = "generic_entity",
}
export class FinancialEntityTypeDto {
    @IsEnum(FinancialEntityTypeEnumDto)
    @IsNotEmpty()
    key!: FinancialEntityTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
