import { Undefinable } from "@core/domain/types/undefinable.type";
import { CatalogueEnum } from "@project/domain/models/catalogue.model";
import { DateTime } from "luxon";
import { FinancialEntityTypeEnum } from "./financial-entity/financial-entity-type";
import { StatusEnum } from "./status.model";

export class GlobalProject {
    constructor(
        public readonly id: number,
        public name: string,
    ) {}
}

export interface GlobalProjectFilters {
    name?: string;
    financialEntityType?: Undefinable<FinancialEntityTypeEnum>;
    statusProject?: Undefinable<StatusEnum>;
    activeFromDate?: Undefinable<DateTime>;
    activeToDate?: Undefinable<DateTime>;
    entityId?: Undefinable<number>;
    catalogueId?: Undefinable<CatalogueEnum>;
    isActive?: Undefinable<boolean>;
}
