import { IncButtonForm } from "@core/presentacion/component/general/button/inc-button-form.component";
import { Button, ButtonProps } from "antd";
import { FC } from "react";

export type IncButtonProps = ButtonProps;

export const IncButton: FC<IncButtonProps> & { Form: typeof IncButtonForm } = (
    props,
) => <Button {...props} />;

IncButton.Form = IncButtonForm;
