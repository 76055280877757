export class Auditory {
    constructor(
        public id: number,
        public certificationEntity: string,
        public year: number,
        public entity: number,
    ) {}
}

export class Auditories {
    constructor(
        public readonly auditories: Auditory[],
        public readonly count: number,
    ) {}
}
