import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContractsRepository } from "@entity/data/repositories/contracts.repository";
import { CreateEmployeeContract } from "@entity/domain/models/employee/contracts/employee/create-employee-contract.model";
import { EmployeeContract } from "@entity/domain/models/employee/contracts/employee/employee-contract.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateEmployeeContractUseCase {
    constructor(
        @inject(ContractsRepository)
        private readonly contractsRepository: ContractsRepository,
    ) {}

    async execute(
        volunteerContract: CreateEmployeeContract,
    ): Promise<Nullable<EmployeeContract>> {
        const creationResult =
            await this.contractsRepository.createEmployeeContract(
                volunteerContract,
            );

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdEmployeeContract) => createdEmployeeContract,
        );
    }
}
