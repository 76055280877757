import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { CreditorRepository } from "@entity/data/repositories/creditor.repository";
import {
    CreateCreditor,
    Creditor,
} from "@entity/domain/models/creditor/creditor.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateCreditorUseCase {
    constructor(
        @inject(CreditorRepository)
        private readonly creditorRepository: CreditorRepository,
    ) {}

    async execute(creditor: CreateCreditor): Promise<Nullable<Creditor>> {
        const creationResult = await this.creditorRepository.create(creditor);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdCreditor) => createdCreditor,
        );
    }
}
