import { Pagination } from "@core/domain/models/pagination";
import { AddressRepository } from "@entity/data/repositories/address.repository";
import { Address } from "@entity/domain/models/address/address.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllAddressesUsecase {
    constructor(
        @inject(AddressRepository)
        private readonly addressRepository: AddressRepository,
    ) {}

    async execute(): Promise<Address[]> {
        const addressesResult = await this.addressRepository.getAll(
            Pagination.NoPagination(),
        );

        return addressesResult.fold(
            () => [],
            (addresses) => addresses.addresses,
        );
    }
}
