import { CreateFinancialEntity } from "./create-financial-entity.model";
import { FinancialEntity } from "./financial-entities.model";

export class EditFinancialEntity extends CreateFinancialEntity {
    constructor(
        public readonly id: FinancialEntity["id"],
        name: CreateFinancialEntity["name"],
        type: CreateFinancialEntity["type"],
        description: CreateFinancialEntity["description"],
        isActive: CreateFinancialEntity["isActive"],
    ) {
        super(name, type, description, isActive);
    }
}
