import { CostRepository } from "@entity/data/repositories/cost.repository";
import { PaymentMethodType } from "@entity/domain/models/cost/payment-method-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllPaymentMethodTypesUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(): Promise<PaymentMethodType[]> {
        const paymentMethodTypesResult =
            await this.costRepository.getAllPaymentMethodTypes();

        return paymentMethodTypesResult.fold(
            () => [],
            (paymentMethodTypes) => paymentMethodTypes,
        );
    }
}
