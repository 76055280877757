import { lazy } from "react";
import { Route } from "react-router-dom";

const LoginPage = lazy(async () =>
    import(
        /* webpackChunkName: "LoginPage" */ "@authentication/presentation/pages/login/login.page"
    ).then((module) => ({ default: module.LoginPage })),
);
const LoginRecoveryPage = lazy(async () =>
    import(
        /* webpackChunkName: "LoginRecoveryPage" */
        "@authentication/presentation/pages/login-recovery/login-recovery.page"
    ).then((module) => ({ default: module.LoginRecoveryPage })),
);
const LoginResetPasswordPage = lazy(async () =>
    import(
        /* webpackChunkName: "LoginResetPasswordPage" */
        "@authentication/presentation/pages/login-reset/login-reset.page"
    ).then((module) => ({ default: module.LoginResetPage })),
);

export class LoginPaths {
    static readonly Login = "login";

    static readonly LoginRecovery = "login-recovery";

    static readonly LoginResetPasswordParam = ":token";
    static readonly LoginResetPassword = `confirm-reset-password/${LoginPaths.LoginResetPasswordParam}`;
}

export class LoginRoutes {
    static LOGIN = `/${LoginPaths.Login}`;

    static LOGIN_RECOVERY = `/${LoginPaths.LoginRecovery}`;

    static LOGIN_RESET_PASSWORD = (token: string): string =>
        `/${LoginPaths.LoginResetPassword.replace(
            LoginPaths.LoginResetPasswordParam,
            token,
        )}`;
}

export const loginRoutes = (
    <Route>
        <Route
            element={<LoginPage />}
            path={LoginPaths.Login}
        />
        <Route
            path={LoginPaths.LoginRecovery}
            element={<LoginRecoveryPage />}
        />
        <Route
            path={LoginPaths.LoginResetPassword}
            element={<LoginResetPasswordPage />}
        />
    </Route>
);
