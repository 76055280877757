/* eslint-disable arrow-body-style */
import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import type { IndicatorSearchFilters } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import {
    ActivitySlot,
    Indicator,
    Objective,
} from "@project/domain/models/technical-proposals.model";
import { ExportIndicatorsByUseCase } from "@project/domain/usecases/monitoring/export-indicators-by.usecase";
import { EditIndicatorUseCase } from "@project/domain/usecases/technical-proposal/indicator/edit-indicator.usecase";
import { GetAllIndicatorsByUseCase } from "@project/domain/usecases/technical-proposal/indicator/get-all-indicator-by.usecase";
import { GetAllObjectivesByUseCase } from "@project/domain/usecases/technical-proposal/objective/get-all-objective-by.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

export type indicatorTable = Indicator & {
    [key: string]: Nullable<string>;
};

export type ActivityIndicatorsData = {
    indicators: indicatorTable[];
    activityName: string;
    activityId: number;
};

export interface ActivitiesIndicators {
    [key: string]: ActivityIndicatorsData;
}

@injectable()
export class MonitoringTabViewModel extends BaseViewModel {
    _projectId: Nullable<number> = null;
    technicalProposalId: Nullable<number> = null;

    @observable
    initialLoading: boolean = true;

    @observable
    _indicators: Indicator[] = [];

    @observable
    objetives: Objective[] = [];

    @observable
    selectedObjetive: Objective | null = null;

    initialFiltersValue: IndicatorSearchFilters = {
        project: undefined,
        objective: undefined,
    };

    @observable
    filters: IndicatorSearchFilters = this.initialFiltersValue;

    @computed
    get activitiesIndicators(): ActivitiesIndicators {
        const indicatorsTable = this._indicators.reduce(
            (accIndicators, indicator) => {
                const activityId = indicator.activityId.toString();
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (!accIndicators[activityId]) {
                    accIndicators[activityId] = {
                        activityName: "",
                        activityId: indicator.activityId,
                        indicators: [],
                    };
                }

                const months = indicator.slots?.reduce(
                    (acc, slot, i) => {
                        return {
                            ...acc,
                            [`month[${i}]`]: slot.value,
                        } as Record<string, ActivitySlot>;
                    },
                    {} as Record<string, ActivitySlot>,
                );

                const newIndicator = {
                    ...indicator,
                    ...months,
                } as indicatorTable;

                accIndicators[activityId].indicators.push(newIndicator);
                accIndicators[activityId].activityName =
                    indicator.activityName ?? String(indicator.activityId);
                return accIndicators;
            },
            // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
            {} as ActivitiesIndicators,
        );

        return indicatorsTable;
    }

    constructor(
        @inject(GetAllIndicatorsByUseCase)
        private readonly getAllIndicatorsByUseCase: GetAllIndicatorsByUseCase,
        @inject(GetAllObjectivesByUseCase)
        private readonly getAllObjectivesByUseCase: GetAllObjectivesByUseCase,
        @inject(EditIndicatorUseCase)
        private readonly editIndicatorUseCase: EditIndicatorUseCase,
        @inject(ExportIndicatorsByUseCase)
        private readonly exportIndicatorsByUseCase: ExportIndicatorsByUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getAllIndicatorsBy(), this.getAllObjectives()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setFilters(filters: IndicatorSearchFilters): void {
        this.filters = filters;
        this.getAllIndicatorsBy();
    }

    async getAllObjectives(): Promise<void> {
        const objectives = await this.getAllObjectivesByUseCase.execute({
            project: this._projectId ?? undefined,
        });

        runInAction(() => {
            this.objetives = objectives;

            // Force to get technicalProposalId from the first objective
            this.technicalProposalId = objectives[0]?.technicalProposalId;
        });
    }

    async getAllIndicatorsBy(): Promise<void> {
        const indicators = await this.getAllIndicatorsByUseCase.execute({
            objective: this.filters.objective,
            project: this._projectId ?? undefined,
        });

        runInAction(() => {
            this._indicators = indicators;
        });
    }

    @action.bound
    async saveIndicators(indicatorsTable: indicatorTable[]): Promise<void> {
        const indicators: Indicator[] = indicatorsTable.map((indicator) => {
            const slots = Object.keys(indicator)
                .filter((key) => key.includes("month"))
                .map((key) => {
                    const slotIndex = key.match(/\d+/)?.[0] ?? "0";
                    return {
                        name: `Slot ${slotIndex}`,
                        value: indicator[key] ?? "",
                    };
                });

            return {
                achievedIndicator: indicator.achievedIndicator,
                activityId: indicator.activityId,
                id: indicator.id,
                estimatedIndicator: indicator.estimatedIndicator,
                slots,
                status: indicator.status,
                verificationFont: indicator.verificationFont,
            };
        });

        const promises = indicators.map(async (indicator: Indicator) => {
            return this.editIndicatorUseCase.execute(indicator);
        });

        await Promise.all(promises)
            .then(() => ToastManagerStore.success())
            .catch(() => ToastManagerStore.error("Error saving indicators"));
    }

    async exportIndicatorsByActivity(
        technicalProposalId: number,
        name: string,
    ): Promise<void> {
        if (!technicalProposalId) return;

        LoadLayoutStore.start();

        const blob = await this.exportIndicatorsByUseCase.execute(
            technicalProposalId,
            this.filters,
        );

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.csv`);
        document.body.appendChild(link);
        link.click();

        LoadLayoutStore.finish();
    }

    async exportIndicatorsByObjective(
        technicalProposalId: number,
        name: string,
    ): Promise<void> {
        if (!technicalProposalId) return;

        LoadLayoutStore.start();

        const blob = await this.exportIndicatorsByUseCase.execute(
            technicalProposalId,
            this.filters,
        );

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.csv`);
        document.body.appendChild(link);
        link.click();

        LoadLayoutStore.finish();
    }
}
