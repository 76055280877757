import { CenterTypeDatasource } from "@beneficiary/data/datasource/center-type.datasource";
import { CenterType } from "@beneficiary/domain/models/center-type.model";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";

@injectable()
export class CenterTypesRepository {
    constructor(
        @inject(CenterTypeDatasource)
        private readonly centerTypeDatasource: CenterTypeDatasource,
    ) {}

    async getAll(): Promise<Either<FallbackError, CenterType[]>> {
        const centerTypeResult = await this.centerTypeDatasource.fetchAll();

        return centerTypeResult.map((centerType) => centerType);
    }
}
