import { DocumentRepository } from "@core/data/repositories/document.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteDocumentUseCase {
    constructor(
        @inject(DocumentRepository)
        private readonly documentRepository: DocumentRepository,
    ) {}

    async execute(documentId: number): Promise<boolean> {
        const creationResult = await this.documentRepository.delete(documentId);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.isRight();
    }
}
