import { Pagination } from "@core/domain/models/pagination";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import {
    CostsSummary,
    type CostSearchFilters,
} from "@entity/domain/models/cost/cost-summary.model";
import { CostType } from "@entity/domain/models/cost/cost-type.model";
import { Cost } from "@entity/domain/models/cost/cost.model";
import { EntitySummary } from "@entity/domain/models/entity-summary.model";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { ProjectSummary } from "@entity/domain/models/project-summary.model";
import { DeleteCostUseCase } from "@entity/domain/usecases/cost/delete-cost.usecase";
import { GetAllCostsTypesUseCase } from "@entity/domain/usecases/cost/get-all-costs-types.usecase";
import { GetCostsByUseCase } from "@entity/domain/usecases/cost/get-costs-by.usecase";
import { GetEntitiesByUseCase } from "@entity/domain/usecases/entity/get-entities-by.usecase";
import { ExportProjectExpensesUseCase } from "@entity/domain/usecases/export-project-expenses.usecase";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { GetAllProjectsUseCase } from "@project/domain/usecases/get-all-projects.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { ExportProjectExpensesFormValuesValidated } from "./export-project-expenses-modal/export-project-expenses-modal-form.component";

export enum ListType {
    entity,
    project,
}

@injectable()
export class CostListPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    listType: ListType = ListType.entity;

    @observable
    costs: CostsSummary = new CostsSummary([], 0);

    @observable
    globalEntities: GlobalEntity[] = [];

    @observable
    types: unknown[] = [];

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: CostSearchFilters = {
        search: "",
        type: null,
    };

    @observable
    costTypes: CostType[] = [];

    @observable
    filters: CostSearchFilters = this.initialFiltersValue;

    @observable
    showExportProjectExpensesModal = false;

    @observable
    projects: ProjectSummary[] = [];

    @observable
    entities: EntitySummary[] = [];

    constructor(
        @inject(GetCostsByUseCase)
        private readonly getCostsByUseCase: GetCostsByUseCase,
        @inject(GetAllCostsTypesUseCase)
        private readonly getAllCostsTypesUseCase: GetAllCostsTypesUseCase,
        @inject(DeleteCostUseCase)
        private readonly deleteCostUseCase: DeleteCostUseCase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
        @inject(ExportProjectExpensesUseCase)
        private readonly exportProjectExpensesUseCase: ExportProjectExpensesUseCase,
        @inject(GetAllProjectsUseCase)
        private readonly getAllProjectsUseCase: GetAllProjectsUseCase,
        @inject(GetEntitiesByUseCase)
        private readonly getEntitiesByUseCase: GetEntitiesByUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.searchCostsBy(this.filters, this.pagination),
            this.getAllCostsTypes(),
            this.getAllGlobalEntities(),
            this.getAllProjects(),
            this.getEntities(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getEntities(): Promise<void> {
        const entities = await this.getEntitiesByUseCase.execute(
            Pagination.NoPagination(),
            {},
        );
        runInAction(() => {
            this.entities = entities.entitiesSummary;
        });
    }

    async getAllProjects(): Promise<void> {
        const projects = await this.getAllProjectsUseCase.execute();

        runInAction(() => {
            this.projects = projects;
        });
    }

    @action
    async setFilters(filters: CostSearchFilters): Promise<void> {
        if (
            this.filters.search !== filters.search ||
            this.filters.type !== filters.type ||
            this.filters.entityIds !== filters.entityIds
        ) {
            this.filters = filters;
            this.pagination.reset();

            await this.searchCostsBy(this.filters, this.pagination);
        }
    }

    @action
    async setPagination(page: number, pageSize: number): Promise<void> {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        await this.searchCostsBy(this.filters, this.pagination);
    }

    @action
    setCosts(costs: CostsSummary): void {
        this.costs = costs;
    }

    @action
    async setListType(type: ListType): Promise<void> {
        this.listType = type;
        if (type === ListType.entity) {
            this.initialLoading = true;
            await this.searchCostsBy(this.initialFiltersValue, this.pagination);
            this.initialLoading = false;
        }
    }

    @action
    async searchCostsBy(
        filters: CostSearchFilters,
        pagination: Pagination,
    ): Promise<void> {
        const costs = await this.getCostsByUseCase.execute(pagination, filters);

        this.setCosts(costs);
    }

    async getAllCostsTypes(): Promise<void> {
        const costsTypes = await this.getAllCostsTypesUseCase.execute();

        runInAction(() => {
            this.costTypes = costsTypes;
        });
    }

    async deleteCost(cost: Cost): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteCostUseCase.execute(cost);
        await this.searchCostsBy(this.filters, this.pagination);
        LoadLayoutStore.finish();
    }

    async getAllGlobalEntities(): Promise<void> {
        const globalEntities = await this.getAllGlobalEntitiesUseCase.execute();

        runInAction(() => {
            this.globalEntities = globalEntities;
        });
    }

    @action
    setShowExportProjectExpensesModal(open: boolean): void {
        this.showExportProjectExpensesModal = open;
    }

    @action
    closeShowExportProjectExpensesModal(): void {
        this.showExportProjectExpensesModal = false;
    }

    async exportExpenses(
        values: ExportProjectExpensesFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();

        const blob = await this.exportProjectExpensesUseCase.execute(values);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Gastos.csv");
        document.body.appendChild(link);
        link.click();

        ToastManagerStore.success();
        this.showExportProjectExpensesModal = false;

        LoadLayoutStore.finish();
    }
}
