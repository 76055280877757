import { CreateRegconRegisterBody } from "@entity/data/dto/quality-framework/regcon-register/create-regcon-register.body";
import { CCAAEnumMapper } from "@entity/data/mappers/ccaa-enum.mapper";
import { CreateRegconRegister } from "@entity/domain/models/quality-framework/regcon-register/create-regcon-register.model";
import { RegconRegisterFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/regcon-register/form/regcon-register-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreateRegconRegisterMapper {
    constructor(
        @inject(CCAAEnumMapper)
        private readonly ccaaEnumMapper: CCAAEnumMapper,
    ) {}
    mapToCreateDto(
        createRegconRegister: CreateRegconRegister,
    ): CreateRegconRegisterBody {
        const registered_ccaa = this.ccaaEnumMapper.mapToDto(
            createRegconRegister.registeredCcaa,
        );

        const regconRegisterDto: CreateRegconRegisterBody = {
            register_number: createRegconRegister.registerNumber,
            registered_ccaa,
            entity: createRegconRegister.entity,
        };

        return regconRegisterDto;
    }

    mapFromFormValues(
        entityId: number,
        regconRegister: RegconRegisterFormValuesValidated,
    ): CreateRegconRegister {
        const createRegconRegister: CreateRegconRegister =
            new CreateRegconRegister(
                regconRegister.registerNumber,
                regconRegister.registeredCcaa,
                entityId,
            );

        return createRegconRegister;
    }
}
