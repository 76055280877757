import { IncomeRepository } from "@beneficiary/data/repositories/income.repository";
import {
    EditIncome,
    Income,
} from "@beneficiary/domain/models/economic-data/income/income.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class EditIncomeUseCase {
    constructor(
        @inject(IncomeRepository)
        private readonly internalNotesRepository: IncomeRepository,
    ) {}

    async execute(internalNote: EditIncome): Promise<Nullable<Income>> {
        const editResult =
            await this.internalNotesRepository.edit(internalNote);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedIncome) => editedIncome,
        );
    }
}
