import { InternalInvalidSessionUserError } from "@authentication/domain/errors/internal/internal-invalid-session-user.error";
import { AuthenticatedUser } from "@authentication/domain/models/authenticated-user.model";
import { LanguageRepository } from "@core/data/repositories/language.repository";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { Nullable } from "@core/domain/types/nullable.type";
import { UserDatasource } from "@user/data/datasources/user.datasource";
import { inject, injectable } from "inversify";
import { BehaviorSubject, Observable } from "rxjs";
@injectable()
export class UserSessionRepository {
    private _user: BehaviorSubject<Nullable<AuthenticatedUser>> =
        new BehaviorSubject<Nullable<AuthenticatedUser>>(null);

    get user(): Observable<Nullable<AuthenticatedUser>> {
        return this._user;
    }

    get userSync(): Nullable<AuthenticatedUser> {
        return this._user.value;
    }

    get userSyncSafe(): AuthenticatedUser {
        if (!this.userSync) throw new InternalInvalidSessionUserError();

        return this.userSync;
    }

    constructor(
        @inject(UserDatasource) private readonly userDatasource: UserDatasource,
        @inject(LanguageRepository)
        private readonly languageRepository: LanguageRepository,
    ) {}

    setUser(authenticatedUser: Nullable<AuthenticatedUser>): void {
        this._user.next(authenticatedUser);
    }

    async impersonateEntity(
        entityId: Nullable<number>,
    ): Promise<Either<FallbackError | ValidationError, true>> {
        const updateUserEntityResult =
            await this.userDatasource.updateUserEntity(entityId);
        if (updateUserEntityResult.isLeft())
            return Either.Left(updateUserEntityResult.getLeftOrThrow());

        const currentUser = this._user.value;
        const updatedUserEntity = updateUserEntityResult.getOrThrow();
        const impersonatedUser = currentUser
            ? currentUser.updateEntity(
                  updatedUserEntity.id,
                  updatedUserEntity.name,
              )
            : null;
        this._user.next(impersonatedUser);
        return Either.Right(true);
    }

    async loadCurrentUser(): Promise<
        Either<FallbackError | ValidationError, true>
    > {
        const currentUserResult = await this.userDatasource.fetchCurrentUser();

        if (currentUserResult.isRight()) {
            this.setUser(currentUserResult.getOrThrow());
            const currentLanguageResult =
                await this.languageRepository.getCurrent();
            const userLanguage = currentUserResult.getOrThrow().language;

            if (currentLanguageResult.isLeft())
                return Either.Left(currentLanguageResult.getLeftOrThrow());
            if (
                userLanguage &&
                currentLanguageResult.getOrThrow() !== userLanguage
            ) {
                const updateLanguageResult =
                    await this.languageRepository.update(userLanguage);
                if (updateLanguageResult.isLeft()) {
                    return Either.Left(updateLanguageResult.getLeftOrThrow());
                }
            }
        }

        return currentUserResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
