import { ExternalDerivationRepository } from "@beneficiary/data/repositories/external-derivation.repository";
import { EditExternalDerivation } from "@beneficiary/domain/models/external-derivation/edit-external-derivation.model";
import { ExternalDerivation } from "@beneficiary/domain/models/external-derivation/external-derivation.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class EditExternalDerivationUseCase {
    constructor(
        @inject(ExternalDerivationRepository)
        private readonly externalDerivationRepository: ExternalDerivationRepository,
    ) {}

    async execute(
        externalDerivation: EditExternalDerivation,
    ): Promise<Nullable<ExternalDerivation>> {
        const result =
            await this.externalDerivationRepository.edit(externalDerivation);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.fold(
            () => null,
            (editedExternalDerivation) => editedExternalDerivation,
        );
    }
}
