import type { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Employee } from "@entity/domain/models/employee/employee.model";
import { ProjectEmployeeMapper } from "@project/data/mappers/project-employee.mapper";
import { CreateProjectEmployeeDedication } from "@project/domain/models/project-employee-dedications.model";
import { CreateProjectEmployeeDedicationUseCase } from "@project/domain/usecases/project-employee-dedication/create-project-employee-dedication.usecase";
import { CreateProjectEmployeeUseCase } from "@project/domain/usecases/project-employee/create-project-employee.usecase";
import { ProjectEmployeeFormValuesValidated } from "@project/presentation/components/project-form/employees/add-employee-modal-form.view";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable } from "mobx";

export interface AddEmployeeFormValues {}

@injectable()
export class AddEmployeeModalFormViewModel extends BaseViewModel {
    projectId: Nullable<number> = null;

    @observable
    selectedEmployee: Nullable<Employee> = null;

    constructor(
        @inject(CreateProjectEmployeeUseCase)
        private readonly createProjectEmployeeUseCase: CreateProjectEmployeeUseCase,
        @inject(CreateProjectEmployeeDedicationUseCase)
        private readonly createProjectEmployeeDedicationUseCase: CreateProjectEmployeeDedicationUseCase,
        @inject(ProjectEmployeeMapper)
        private readonly projectEmployeeMapper: ProjectEmployeeMapper,
    ) {
        super();
        makeObservable(this);
    }

    @action
    setSelectedEmployee(employee: Nullable<Employee>): void {
        this.selectedEmployee = employee;
    }

    async createProjectEmployee(
        projectEmployeeForm: ProjectEmployeeFormValuesValidated,
    ): Promise<boolean> {
        if (!this.projectId) throw new Error("Project id is not defined");

        LoadLayoutStore.start();

        const projectEmployee = this.projectEmployeeMapper.mapFromFormToCreate(
            this.projectId,
            projectEmployeeForm,
        );

        const projectEmployeeCreated =
            await this.createProjectEmployeeUseCase.execute(projectEmployee);

        if (projectEmployeeCreated) {
            const projectEmployeeDedicationCreated =
                await this.createProjectEmployeeDedicationUseCase.execute(
                    new CreateProjectEmployeeDedication(
                        projectEmployeeCreated.id,
                        projectEmployeeForm.dedication.hoursDedicated,
                        projectEmployeeForm.dedication.startDate,
                        projectEmployeeForm.dedication.endDate,
                    ),
                );

            if (projectEmployeeDedicationCreated) {
                ToastManagerStore.success();
            }
        }

        LoadLayoutStore.finish();

        return !!projectEmployeeCreated;
    }
}
