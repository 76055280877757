import { Pagination } from "@core/domain/models/pagination";
import { ProjectEmployeeDedicationRepository } from "@project/data/repositories/project-employee-dedication.repository";
import {
    ProjectEmployeeDedications,
    ProjectEmployeeDedicationsSearchFilters,
} from "@project/domain/models/project-employee-dedications.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetProjectEmployeeDedicationsByUseCase {
    constructor(
        @inject(ProjectEmployeeDedicationRepository)
        private readonly projectEmployeeDedicationRepository: ProjectEmployeeDedicationRepository,
    ) {}

    async execute(
        pagination?: Pagination,
        filters?: ProjectEmployeeDedicationsSearchFilters,
    ): Promise<ProjectEmployeeDedications> {
        const projectEmployeeDedicationsResult =
            await this.projectEmployeeDedicationRepository.getBy(
                pagination,
                filters,
            );

        return projectEmployeeDedicationsResult.fold(
            () => new ProjectEmployeeDedications([], 0),
            (projectEmployeeDedications) => projectEmployeeDedications,
        );
    }
}
