import { CreatePublicUtility } from "@entity/domain/models/public-utility/create-public-utility.model";
import { PublicUtility } from "@entity/domain/models/public-utility/public-utility.model";

export class EditPublicUtility extends CreatePublicUtility {
    constructor(
        public readonly id: PublicUtility["id"],
        organizationType: PublicUtility["organizationType"],
        entity: PublicUtility["entity"],
        date: PublicUtility["date"],
    ) {
        super(organizationType, entity, date);
    }
}
