import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { StatusEnum } from "../../domain/models/status.model";
import { StatusEnumDto } from "../dto/status.dto";

@injectable()
export class StatusEnumMapper {
    map(statusEnumDto: StatusEnumDto): Nullable<StatusEnum> {
        switch (statusEnumDto) {
            case StatusEnumDto.Create:
                return StatusEnum.Create;
            case StatusEnumDto.Design:
                return StatusEnum.Design;
            case StatusEnumDto.Founding:
                return StatusEnum.Founding;
            case StatusEnumDto.Execution:
                return StatusEnum.Execution;
            case StatusEnumDto.Justification:
                return StatusEnum.Justification;
            case StatusEnumDto.Finished:
                return StatusEnum.Finished;
            case StatusEnumDto.Discarded:
                return StatusEnum.Discarded;
            default:
                return null;
        }
    }

    mapToDto(statusEnum: StatusEnum): StatusEnumDto {
        // eslint-disable-next-line default-case
        switch (statusEnum) {
            case StatusEnum.Create:
                return StatusEnumDto.Create;
            case StatusEnum.Design:
                return StatusEnumDto.Design;
            case StatusEnum.Founding:
                return StatusEnumDto.Founding;
            case StatusEnum.Execution:
                return StatusEnumDto.Execution;
            case StatusEnum.Justification:
                return StatusEnumDto.Justification;
            case StatusEnum.Finished:
                return StatusEnumDto.Finished;
            case StatusEnum.Discarded:
                return StatusEnumDto.Discarded;
        }
    }
}
