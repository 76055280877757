import { EmploymentSituationRelevantCharacteristicsEnum } from "@beneficiary/domain/models/employment-situation-relevant-characteristics.enum";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { EmploymentSituationRelevantCharacteristicsEnumDto } from "../dto/employment-situation-relevant-characteristics.dto";

@injectable()
export class EmploymentCharacteristicsEnumMapper {
    map(
        employmentCharacteristicsDto: EmploymentSituationRelevantCharacteristicsEnumDto,
    ): Nullable<EmploymentSituationRelevantCharacteristicsEnum> {
        switch (employmentCharacteristicsDto) {
            case EmploymentSituationRelevantCharacteristicsEnumDto.LongTermUnemployed:
                return EmploymentSituationRelevantCharacteristicsEnum.LongTermUnemployed;
            case EmploymentSituationRelevantCharacteristicsEnumDto.ExhaustedBenefits:
                return EmploymentSituationRelevantCharacteristicsEnum.ExhaustedBenefits;
            case EmploymentSituationRelevantCharacteristicsEnumDto.PrecariousWork:
                return EmploymentSituationRelevantCharacteristicsEnum.PrecariousWork;
            case EmploymentSituationRelevantCharacteristicsEnumDto.Minor:
                return EmploymentSituationRelevantCharacteristicsEnum.Minor;
            case EmploymentSituationRelevantCharacteristicsEnumDto.Over65:
                return EmploymentSituationRelevantCharacteristicsEnum.Over65;
            case EmploymentSituationRelevantCharacteristicsEnumDto.SelfEmployed:
                return EmploymentSituationRelevantCharacteristicsEnum.SelfEmployed;
            case EmploymentSituationRelevantCharacteristicsEnumDto.WithoutContract:
                return EmploymentSituationRelevantCharacteristicsEnum.WithoutContract;
            case EmploymentSituationRelevantCharacteristicsEnumDto.OtherLaboralStatus:
                return EmploymentSituationRelevantCharacteristicsEnum.OtherLaboralStatus;
            default:
                return null;
        }
    }

    mapToDto(
        employmentStatus: EmploymentSituationRelevantCharacteristicsEnum,
    ): EmploymentSituationRelevantCharacteristicsEnumDto {
        // eslint-disable-next-line default-case
        switch (employmentStatus) {
            case EmploymentSituationRelevantCharacteristicsEnum.LongTermUnemployed:
                return EmploymentSituationRelevantCharacteristicsEnumDto.LongTermUnemployed;
            case EmploymentSituationRelevantCharacteristicsEnum.ExhaustedBenefits:
                return EmploymentSituationRelevantCharacteristicsEnumDto.ExhaustedBenefits;
            case EmploymentSituationRelevantCharacteristicsEnum.PrecariousWork:
                return EmploymentSituationRelevantCharacteristicsEnumDto.PrecariousWork;
            case EmploymentSituationRelevantCharacteristicsEnum.Minor:
                return EmploymentSituationRelevantCharacteristicsEnumDto.Minor;
            case EmploymentSituationRelevantCharacteristicsEnum.Over65:
                return EmploymentSituationRelevantCharacteristicsEnumDto.Over65;
            case EmploymentSituationRelevantCharacteristicsEnum.SelfEmployed:
                return EmploymentSituationRelevantCharacteristicsEnumDto.SelfEmployed;
            case EmploymentSituationRelevantCharacteristicsEnum.WithoutContract:
                return EmploymentSituationRelevantCharacteristicsEnumDto.WithoutContract;
            case EmploymentSituationRelevantCharacteristicsEnum.OtherLaboralStatus:
                return EmploymentSituationRelevantCharacteristicsEnumDto.OtherLaboralStatus;
        }
    }
}
