import { AuthenticationRepository } from "@authentication/data/repositories/authentication.repository";
import { inject, injectable } from "inversify";

@injectable()
export class IsUserLoggedUseCase {
    constructor(
        @inject(AuthenticationRepository)
        private readonly authenticationRepository: AuthenticationRepository,
    ) {}
    execute(): boolean {
        return this.authenticationRepository.hasAccessToken;
    }
}
