import { Nullable } from "@core/domain/types/nullable.type";
import { ProjectEmployeeDedicationRepository } from "@project/data/repositories/project-employee-dedication.repository";
import { ProjectEmployeeDedication } from "@project/domain/models/project-employee-dedications.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetProjectEmployeeDedicationByIdUseCase {
    constructor(
        @inject(ProjectEmployeeDedicationRepository)
        private readonly projectEmployeeDedicationRepository: ProjectEmployeeDedicationRepository,
    ) {}

    async execute(id: number): Promise<Nullable<ProjectEmployeeDedication>> {
        const projectEmployeeDedicationResult =
            await this.projectEmployeeDedicationRepository.getById(id);

        return projectEmployeeDedicationResult.fold(
            () => null,
            (projectEmployeeDedication) => projectEmployeeDedication,
        );
    }
}
