import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { ExpenseRecurrence } from "../models/economic-data/expense/expense-recurrence.model";
@injectable()
export class GetAllExpenseRecurrencesUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}
    async execute(): Promise<ExpenseRecurrence[]> {
        const expenseRecurrencesResult =
            await this.beneficiaryRepository.getAllExpenseRecurrences();
        if (expenseRecurrencesResult.isLeft()) {
            ToastManagerStore.error(
                expenseRecurrencesResult.getLeftOrThrow().message,
            );
        }
        return expenseRecurrencesResult.getOrElse([]);
    }
}
