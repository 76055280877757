import { ProjectEmployeesDto } from "@project/data/dto/project-employee/project-employees.dto";
import { ProjectEmployeeMapper } from "@project/data/mappers/project-employee.mapper";
import { ProjectEmployees } from "@project/domain/models/project-employees.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ProjectEmployeesMapper {
    constructor(
        @inject(ProjectEmployeeMapper)
        private readonly projectEmployeeMapper: ProjectEmployeeMapper,
    ) {}

    map(projectEmployeesDto: ProjectEmployeesDto): ProjectEmployees {
        const errors = validateSync(projectEmployeesDto);

        if (errors.length) {
            console.error(errors);

            return new ProjectEmployees([], 0);
        }

        const projectEmployees = projectEmployeesDto.results.mapNotNull(
            (projectEmployeeDto) =>
                this.projectEmployeeMapper.map(projectEmployeeDto),
        );

        return new ProjectEmployees(
            projectEmployees,
            projectEmployeesDto.count,
        );
    }
}
