import { inject, injectable } from "inversify";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { CategoryDto } from "@entity/data/dto/category.dto";
import { Category } from "@entity/domain/models/category.model";
import { CategoryTypeEnumMapper } from "@entity/data/mappers/category-type-enum.mapper";

@injectable()
export class CategoryMapper {
    constructor(
        @inject(CategoryTypeEnumMapper)
        private readonly categoryTypeEnumMapper: CategoryTypeEnumMapper,
    ) {}
    map(categoryDto: CategoryDto): Nullable<Category> {
        const errors = validateSync(categoryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const type = this.categoryTypeEnumMapper.map(categoryDto.type);

        if (!type) return null;

        return new Category(type, categoryDto.display_name);
    }
}
