import { Pagination } from "@core/domain/models/pagination";
import { AffiliationRepository } from "@entity/data/repositories/affiliation.repository";
import { Affiliation } from "@entity/domain/models/affiliation/affiliation.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllAffiliationsUseCase {
    constructor(
        @inject(AffiliationRepository)
        private readonly affiliationRepository: AffiliationRepository,
    ) {}

    async execute(): Promise<Affiliation[]> {
        const affiliationsResult = await this.affiliationRepository.getAll(
            Pagination.NoPagination(),
        );

        return affiliationsResult.fold(
            () => [],
            (affiliations) => affiliations.affiliations,
        );
    }
}
