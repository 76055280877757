import { CreditorsDto } from "@entity/data/dto/creditor/creditor.dto";
import { Creditors } from "@entity/domain/models/creditor/creditor.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { CreditorMapper } from "./creditor.mapper";

@injectable()
export class CreditorsMapper {
    constructor(
        @inject(CreditorMapper)
        private readonly creditorMapper: CreditorMapper,
    ) {}

    map(entitiesSummaryDto: CreditorsDto): Creditors {
        const errors = validateSync(entitiesSummaryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Creditors([], 0);
        }

        const entitiesSummary = entitiesSummaryDto.results.mapNotNull(
            (collectiveDto) => this.creditorMapper.map(collectiveDto),
        );

        return new Creditors(entitiesSummary, entitiesSummaryDto.count);
    }
}
