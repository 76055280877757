import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { EmployeeRepository } from "@entity/data/repositories/employee.repository";
import { CreateEmployee } from "@entity/domain/models/employee/create-employee.model";
import { inject, injectable } from "inversify";
import { Employee } from "../../models/employee/employee.model";

@injectable()
export class CreateEmployeeUseCase {
    constructor(
        @inject(EmployeeRepository)
        private readonly employeeRepository: EmployeeRepository,
    ) {}

    async execute(
        employeeToCreate: CreateEmployee,
    ): Promise<Nullable<Employee>> {
        const employeeResult =
            await this.employeeRepository.create(employeeToCreate);

        if (employeeResult.isLeft()) {
            ToastManagerStore.error(employeeResult.getLeftOrThrow().message);
        }

        return employeeResult.fold(
            () => null,
            (employee) => employee,
        );
    }
}
