export enum DependenceGradeEnum {
    DependenceNotRecognized = "dependence_not_recognized",
    DependenceGrade1 = "dependence_grade_1",
    DependenceGrade2 = "dependence_grade_2",
    DependenceGrade3 = "dependence_grade_3",
}

export class DependenceGrade {
    constructor(
        public readonly id: DependenceGradeEnum,
        public readonly label: string,
    ) {}
}
