import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ExportProjectDepositsMapper } from "@entity/data/mappers/deposit/export-project-deposits.mapper";
import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { ExportProjectDepositFormValuesValidated } from "@entity/presentation/component/entity-form/deposits/export-project-deposits-modal/export-project-deposit-modal-form.component";
import { inject, injectable } from "inversify";

@injectable()
export class ExportProjectDepositsUseCase {
    constructor(
        @inject(ExportProjectDepositsMapper)
        private readonly exportDepositsMapper: ExportProjectDepositsMapper,
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}

    async execute(
        values: ExportProjectDepositFormValuesValidated,
    ): Promise<Blob> {
        const exportResult = await this.entityRepository.exportProjectDeposit(
            this.exportDepositsMapper.mapToDeposit(values),
        );

        if (exportResult.isLeft()) {
            ToastManagerStore.error(exportResult.getLeftOrThrow().message);
        }

        return exportResult.getOrThrow();
    }
}
