import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { PartnersNumberRepository } from "@entity/data/repositories/partners-number.repository";
import { EditPartnersNumber } from "@entity/domain/models/partners-number/edit-partners-number.model";
import { PartnersNumber } from "@entity/domain/models/partners-number/partners-number.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditPartnersNumberUseCase {
    constructor(
        @inject(PartnersNumberRepository)
        private readonly partnersNumberRepository: PartnersNumberRepository,
    ) {}

    async execute(
        address: EditPartnersNumber,
    ): Promise<Nullable<PartnersNumber>> {
        const editResult = await this.partnersNumberRepository.edit(address);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedPartnersNumber) => editedPartnersNumber,
        );
    }
}
