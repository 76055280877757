import { CreateBudgetCategory } from "@project/domain/models/budget/budget-category/create-budget-category.model";

export class EditBudgetCategory extends CreateBudgetCategory {
    constructor(
        public readonly id: number,
        public override name: CreateBudgetCategory["name"],
        public override budget: CreateBudgetCategory["budget"],
    ) {
        super(name, budget);
    }
}
