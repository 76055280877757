import { User } from "@user/domain/models/user.model";
import { DateTime } from "luxon";

export class InternalNote {
    constructor(
        public id: number,
        public note: string,
        public beneficiary: number,
        public user: User,
        public modified: DateTime,
    ) {}
}

export class InternalNotes {
    constructor(
        public readonly internalNotes: InternalNote[],
        public readonly count: number,
    ) {}
}
