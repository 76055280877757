import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ObjectiveRepository } from "@project/data/repositories/technical-proposal/objective.repository";
import {
    EditObjective,
    Objective,
} from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditObjectiveUseCase {
    constructor(
        @inject(ObjectiveRepository)
        private readonly objectiveRepository: ObjectiveRepository,
    ) {}

    async execute(local: EditObjective): Promise<Nullable<Objective>> {
        const localResult = await this.objectiveRepository.edit(local);

        if (localResult.isLeft()) {
            ToastManagerStore.error(localResult.getLeftOrThrow().message);
        }

        return localResult.fold(
            () => null,
            (editedObjective) => editedObjective,
        );
    }
}
