import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { BudgetCategoryConcepts } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllCategoryConceptsByCategoryAndGroupIdUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(
        categoryId: number,
        groupId?: number,
    ): Promise<BudgetCategoryConcepts> {
        const columnsResult =
            await this.budgetRepository.getCategoryConceptsByCategoryAndGroupId(
                categoryId,
                groupId,
            );

        return columnsResult.fold(
            () => new BudgetCategoryConcepts([], 0),
            (columns) => columns,
        );
    }
}
