import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum EmploymentStatusEnumDto {
    Active = "active",
    Inactive = "inactive",
    Unemployed = "unemployed",
    LongTermUnemployed = "long_term_unemployed",
    ExhaustedBenefits = "exhausted_benefits",
    PrecariousWork = "precarious_work",
    Minor = "minor",
    Over65 = "over_65",
    SelfEmployed = "self_employed",
    WithoutContract = "without_contract",
}

export class EmploymentStatusDto {
    @IsEnum(EmploymentStatusEnumDto)
    @IsNotEmpty()
    key!: EmploymentStatusEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
