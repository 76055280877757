import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { EntitySummaryMapper } from "@entity/data/mappers/entity-summary.mapper";
import { StatusEnumMapper } from "@proceeding/data/mappers/status-enum.mapper";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import {
    Proceeding,
    ProceedingDerivation,
    ProceedingResolution,
} from "../../domain/models/proceeding.model";
import { ProceedingDto } from "../dto/proceeding.dto";

@injectable()
export class ProceedingMapper {
    constructor(
        @inject(EntitySummaryMapper)
        private readonly entitySummaryMapper: EntitySummaryMapper,
        @inject(StatusEnumMapper)
        private readonly statusEnumMapper: StatusEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
    ) {}

    map(proceedingDto: ProceedingDto): Nullable<Proceeding> {
        const errors = validateSync(proceedingDto);

        if (Boolean(errors.length)) {
            console.error(errors);
            return null;
        }

        const entity = this.entitySummaryMapper.map(proceedingDto.entity_data);

        if (entity === null) return null;

        const status = this.statusEnumMapper.map(proceedingDto.status);

        if (!status) return null;

        let resolution: Undefinable<ProceedingResolution> = undefined;
        if (
            proceedingDto.resolution_file &&
            proceedingDto.resolution_date &&
            proceedingDto.resolution_description
        ) {
            resolution = new ProceedingResolution(
                proceedingDto.resolution_description,
                DateTime.fromISO(proceedingDto.resolution_date),
                new IncDocument(-1, "", "", DateTime.now(), DateTime.now()), // Not available yet, will be retrieved in repository
            );
        }

        let derivation: Undefinable<ProceedingDerivation> = undefined;
        if (
            proceedingDto.derivation_date &&
            proceedingDto.derivation_description &&
            proceedingDto.derivation_entity &&
            proceedingDto.origin_entity &&
            proceedingDto.origin_entity_data
        ) {
            derivation = new ProceedingDerivation(
                proceedingDto.origin_entity,
                proceedingDto.origin_entity_data.company_name,
                proceedingDto.derivation_entity,
                proceedingDto.derivation_description,
                DateTime.fromISO(proceedingDto.derivation_date),
            );
        }

        const documentsData: IncDocument[] =
            proceedingDto.documents_data?.mapNotNull((doc) =>
                this.documentMapper.map(doc),
            ) ?? [];

        const proceeding = new Proceeding(
            proceedingDto.id,
            entity,
            proceedingDto.beneficiary,
            proceedingDto.beneficiary_data.name,
            proceedingDto.beneficiary_data.surname,
            proceedingDto.project,
            proceedingDto.project_data.name,
            status,
            proceedingDto.activities,
            proceedingDto.professional_responsible,
            proceedingDto.professional_responsible_data.name,
            proceedingDto.professional_responsible_data.surname,
            proceedingDto.reason,
            undefined, // Not available yet, will be retrieved in repository
            resolution,
            derivation,
            documentsData,
        );

        return proceeding;
    }
}
