export class ActivitySummary {
    constructor(
        public readonly id: number,
        public readonly activity: string,
        public readonly description: string,
    ) {}
}

export class ActivitiesSummary {
    constructor(
        public readonly activitySummary: ActivitySummary[],
        public readonly count: number,
    ) {}
}
