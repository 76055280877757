import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ProjectMaterialDto } from "@project/data/dto/materials/project-material.dto";
import { ProjectMaterial } from "@project/domain/models/project-material/project-material.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class ProjectMaterialMapper {
    constructor(
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
    ) {}

    map(projectMaterialDto: ProjectMaterialDto): Nullable<ProjectMaterial> {
        const errors = validateSync(projectMaterialDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const startDate = projectMaterialDto.start_date
            ? DateTime.fromISO(projectMaterialDto.start_date)
            : null;
        const endDate = projectMaterialDto.end_date
            ? DateTime.fromISO(projectMaterialDto.end_date)
            : null;

        let documents: IncDocument[] = [];
        if (projectMaterialDto.documents_data) {
            documents = projectMaterialDto.documents_data.mapNotNull(
                (document) => this.documentMapper.map(document),
            );
        }

        return new ProjectMaterial(
            projectMaterialDto.id,
            projectMaterialDto.material,
            projectMaterialDto.project,
            projectMaterialDto.hours_dedicated
                ? parseInt(projectMaterialDto.hours_dedicated)
                : 0,
            documents,
            projectMaterialDto.material_data,
            startDate,
            endDate,
        );
    }
}
