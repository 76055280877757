/* eslint-disable max-lines */
import {
    BeneficiaryDto,
    BeneficiaryIdentificationDto,
    BeneficiaryIdentificationResultDto,
} from "@beneficiary/data/dto/beneficiary.dto";
import {
    CreateBeneficiaryBody,
    CreateBeneficiaryIdentificationBody,
} from "@beneficiary/data/dto/create-beneficiary.body";
import { DependenceGradeDto } from "@beneficiary/data/dto/dependence-grade-enum.dto";
import { DisabilityTypeDto } from "@beneficiary/data/dto/disability-type-enum.dto";
import {
    EditBeneficiaryBody,
    EditBeneficiaryIdentificationBody,
} from "@beneficiary/data/dto/edit-beneficiary.body";
import { ExpenseRecurrenceDto } from "@beneficiary/data/dto/expense-recurrence.dto";
import { ExpenseTypeDto } from "@beneficiary/data/dto/expense-type.dto";
import { ParentangeDto } from "@beneficiary/data/dto/parentange.dto";
import { TherapyTypeDto } from "@beneficiary/data/dto/therapy-type-enum.dto";
import { BeneficiariesSummaryMapper } from "@beneficiary/data/mappers/beneficiaries-summary.mapper";
import { BeneficiaryMapper } from "@beneficiary/data/mappers/beneficiary.mapper";
import { CreateBeneficiaryMapper } from "@beneficiary/data/mappers/create-beneficiary.mapper";
import { EditBeneficiaryMapper } from "@beneficiary/data/mappers/edit-beneficiary.mapper";
import { ExpenseRecurrenceMapper } from "@beneficiary/data/mappers/expense-recurrence.mapper";
import { RelationshipMapper } from "@beneficiary/data/mappers/relationship.mapper";
import { BeneficiariesSummary } from "@beneficiary/domain/models/beneficiaries-summary.model";
import {
    BeneficiaryIdentificationSearchFilters,
    BeneficiarySearchFilters,
} from "@beneficiary/domain/models/beneficiary-search-filters";
import { BeneficiarySummary } from "@beneficiary/domain/models/beneficiary-summary.model";
import {
    Beneficiary,
    BeneficiaryIdentification,
} from "@beneficiary/domain/models/beneficiary.model";
import {
    CreateBeneficiary,
    CreateBeneficiaryIdentification,
} from "@beneficiary/domain/models/create-beneficiary.model";
import { DependenceGrade } from "@beneficiary/domain/models/dependence-grade.enum";
import { DisabilityType } from "@beneficiary/domain/models/disability-type.enum";
import { ExpenseRecurrence } from "@beneficiary/domain/models/economic-data/expense/expense-recurrence.model";
import { EditBeneficiary } from "@beneficiary/domain/models/edit-beneficiary.model";
import { EmploymentRelevantCharacteristics } from "@beneficiary/domain/models/employment-situation-relevant-characteristics.enum";
import { Relationship } from "@beneficiary/domain/models/relationship.model";
import { TherapyType } from "@beneficiary/domain/models/therapy-type.enum";
import { coreTypes } from "@core/core-types.di";
import { EducationLevelDto } from "@core/data/dto/education-level.dto";
import { EmploymentStatusDto } from "@core/data/dto/employment-status.dto";
import { EXPAND_ALL, FieldsQuery } from "@core/data/dto/fields.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { EducationLevelMapper } from "@core/data/mappers/education-level.mapper";
import { EmploymentStatusMapper } from "@core/data/mappers/employment-status.mapper";
import { OrderMapper } from "@core/data/mappers/order.mapper";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { DiagnosisType } from "@core/domain/models/diagnosis-type.model";
import { EducationLevel } from "@core/domain/models/education-level.model";
import { EmploymentStatus } from "@core/domain/models/employment-status.model";
import { Order } from "@core/domain/models/order.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { AssociateServiceToDependence } from "../../domain/models/associate-service-to-dependence.enum";
import { BeneficiaryIdentificationType } from "../../domain/models/identification-document-type.model";
import {
    AssociateServiceToDependenceDto,
    AssociateServiceToDependenceEnumDto,
} from "../dto/associate-service-to-dependence.enum.dto";
import {
    BeneficiariesSummaryDto,
    BeneficiariesSummaryQuery,
    BeneficiaryIdentificationQuery,
    BeneficiarySummaryDto,
    beneficiariesSummaryOrderMap,
} from "../dto/beneficiaries-summary.dto";
import { BeneficiaryIdentificationTypeDto } from "../dto/beneficiary-identification-type.dto";
import {
    DiagnosisTypeDto,
    DiagnosisTypeEnumDto,
} from "../dto/diagnosis-type.dto";
import {
    EmploymentRelevantCharacteristicsDto,
    EmploymentSituationRelevantCharacteristicsEnumDto,
} from "../dto/employment-situation-relevant-characteristics.dto";
import { AssociateServiceToDependenceMapper } from "../mappers/associate-service-to-dependence.mapper";
import { BeneficiaryIdentificationTypeMapper } from "../mappers/beneficiary-identification-type.mapper";
import { DependenceGradesMapper } from "../mappers/dependence-grades.mapper";
import { DiagnosisTypeMapper } from "../mappers/diagnosis-type.mapper";
import { DisabilityTypesMapper } from "../mappers/disability-type.mapper";
import { EmploymentCharacteristicsMapper } from "../mappers/employment-characteristics.mapper";
import { TherapyTypesMapper } from "../mappers/therapy-type.mapper";

const BENEFICIARY_PATH = "/beneficiaries/";
const BENEFICIARY_IDENTIFICATION_PATH = "/beneficiaries_identification/";

@injectable()
export class BeneficiaryDatasource {
    // eslint-disable-next-line max-params
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(BeneficiaryMapper)
        private readonly beneficiaryMapper: BeneficiaryMapper,
        @inject(CreateBeneficiaryMapper)
        private readonly createBeneficiaryMapper: CreateBeneficiaryMapper,
        @inject(EditBeneficiaryMapper)
        private readonly editBeneficiaryMapper: EditBeneficiaryMapper,
        @inject(ExpenseRecurrenceMapper)
        private readonly expenseRecurrenceMapper: ExpenseRecurrenceMapper,
        @inject(EducationLevelMapper)
        private readonly educationLevelMapper: EducationLevelMapper,
        @inject(EmploymentStatusMapper)
        private readonly employmentStatusMapper: EmploymentStatusMapper,
        @inject(BeneficiaryIdentificationTypeMapper)
        private readonly identificationDocumentTypeMapper: BeneficiaryIdentificationTypeMapper,
        @inject(OrderMapper)
        private readonly orderMapper: OrderMapper,
        @inject(RelationshipMapper)
        private readonly relationshipMapper: RelationshipMapper,
        @inject(BeneficiariesSummaryMapper)
        private readonly beneficiariesSummaryMapper: BeneficiariesSummaryMapper,
        @inject(DependenceGradesMapper)
        private readonly dependenceGradesMapper: DependenceGradesMapper,
        @inject(TherapyTypesMapper)
        private readonly therapyTypesMapper: TherapyTypesMapper,
        @inject(DisabilityTypesMapper)
        private readonly disabilityTypesMapper: DisabilityTypesMapper,
        @inject(AssociateServiceToDependenceMapper)
        private readonly associateServiceToDependenceMapper: AssociateServiceToDependenceMapper,
        @inject(DiagnosisTypeMapper)
        private readonly diagnosisTypeMapper: DiagnosisTypeMapper,
        @inject(EmploymentCharacteristicsMapper)
        private readonly employmentCharacteristicsMapper: EmploymentCharacteristicsMapper,
    ) {}

    async create(
        newBeneficiary: CreateBeneficiary,
    ): Promise<Either<ValidationError | FallbackError, Beneficiary>> {
        const createdBeneficiaryDto =
            this.createBeneficiaryMapper.mapToDto(newBeneficiary);

        const createBeneficiaryQuery: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const createBeneficiaryResult = await this.http.post<
            BeneficiaryDto,
            CreateBeneficiaryBody
        >(BENEFICIARY_PATH, createdBeneficiaryDto, {
            query: createBeneficiaryQuery,
        });

        return createBeneficiaryResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdBeneficiary = this.beneficiaryMapper.map(
                    plainToClass(BeneficiaryDto, response.data),
                );

                if (!createdBeneficiary)
                    return Either.Left(new FallbackError());

                return Either.Right(createdBeneficiary);
            });
    }

    async createIdentification(
        newIdentification: CreateBeneficiaryIdentification,
        beneficiaryId: number,
    ): Promise<
        Either<ValidationError | FallbackError, BeneficiaryIdentification>
    > {
        const createIdentificationDto =
            this.createBeneficiaryMapper.mapIdentificationToDto(
                newIdentification,
                beneficiaryId,
            );

        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const createBeneficiaryIdentificationResult = await this.http.post<
            BeneficiaryIdentificationDto,
            CreateBeneficiaryIdentificationBody
        >(BENEFICIARY_IDENTIFICATION_PATH, createIdentificationDto, {
            query,
        });

        return createBeneficiaryIdentificationResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdBeneficiaryIdentification =
                    this.beneficiaryMapper.mapIdentification(
                        plainToClass(
                            BeneficiaryIdentificationDto,
                            response.data,
                        ),
                    );

                if (!createdBeneficiaryIdentification)
                    return Either.Left(new FallbackError());

                return Either.Right(createdBeneficiaryIdentification);
            });
    }

    async fetchIdentificationBy(
        filters?: BeneficiaryIdentificationSearchFilters,
    ): Promise<Either<FallbackError, BeneficiaryIdentification[]>> {
        const query: BeneficiaryIdentificationQuery = {};

        if (filters?.beneficiaryId)
            query.beneficiary = filters.beneficiaryId.toString();

        const beneficiaryIdentificationResult =
            await this.http.get<BeneficiaryIdentificationResultDto>(
                BENEFICIARY_IDENTIFICATION_PATH,
                { query },
            );

        return beneficiaryIdentificationResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.results.mapNotNull((identification) =>
                    this.beneficiaryMapper.mapIdentification(identification),
                ),
            );
    }

    async fetchIdentificationById(
        identificationId: number,
    ): Promise<Either<FallbackError, BeneficiaryIdentification>> {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const beneficiaryIdentificationResult =
            await this.http.get<BeneficiaryIdentificationDto>(
                `${BENEFICIARY_IDENTIFICATION_PATH}${identificationId}/`,
                { query },
            );

        return beneficiaryIdentificationResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const beneficiaryIdentification =
                    this.beneficiaryMapper.mapIdentification(
                        plainToClass(
                            BeneficiaryIdentificationDto,
                            response.data,
                        ),
                    );

                if (!beneficiaryIdentification)
                    return Either.Left(new FallbackError());

                return Either.Right(beneficiaryIdentification);
            });
    }

    async fetchBy(
        pagination: Pagination,
        filters?: BeneficiarySearchFilters,
        order?: Order<BeneficiarySummary>,
    ): Promise<Either<HttpError, BeneficiariesSummary>> {
        const query: BeneficiariesSummaryQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.name) query.search = filters.name;
        if (filters?.entityIds) query.entities = filters.entityIds.join(",");
        if (filters?.projectIds) query.projects = filters.projectIds.join(",");
        if (filters?.limit) query.limit = filters.limit;
        if (order) {
            const orderQuery = this.orderMapper.mapToDto<
                BeneficiarySummary,
                BeneficiarySummaryDto
            >(order.field, order.direction, beneficiariesSummaryOrderMap);
            if (orderQuery) query.ordering = orderQuery;
        }

        const beneficiariesResult = await this.http.get<BeneficiarySummaryDto>(
            BENEFICIARY_PATH,
            {
                query,
            },
        );

        const beneficiariesDto = beneficiariesResult.map((response) =>
            this.beneficiariesSummaryMapper.map(
                plainToClass(BeneficiariesSummaryDto, response.data),
            ),
        );

        return beneficiariesDto;
    }

    async fetchAllRelationShips(): Promise<
        Either<FallbackError, Relationship[]>
    > {
        const relationShipsResult = await this.http.get<ParentangeDto[]>(
            `${BENEFICIARY_PATH}parentanges/`,
        );

        return relationShipsResult
            .mapLeft(() => new FallbackError())
            .map((relationShipsResponse) =>
                relationShipsResponse.data.mapNotNull((relationShipDto) =>
                    this.relationshipMapper.map(
                        plainToClass(ParentangeDto, relationShipDto),
                    ),
                ),
            );
    }

    async fetchAllExpenseRecurrences(): Promise<
        Either<FallbackError, ExpenseRecurrence[]>
    > {
        const expenseRecurrenceResult = await this.http.get<ExpenseTypeDto[]>(
            "/expenses/recurrences",
        );
        return expenseRecurrenceResult
            .mapLeft(() => new FallbackError())
            .map((expenseRecurrenceResponse) =>
                expenseRecurrenceResponse.data.mapNotNull(
                    (expenseRecurrenceDto) =>
                        this.expenseRecurrenceMapper.map(
                            plainToClass(
                                ExpenseRecurrenceDto,
                                expenseRecurrenceDto,
                            ),
                        ),
                ),
            );
    }

    async fetchAllEducationLevels(): Promise<
        Either<FallbackError, EducationLevel[]>
    > {
        const educationLevelsResult = await this.http.get<EducationLevelDto[]>(
            `${BENEFICIARY_PATH}education_level/`,
        );

        return educationLevelsResult
            .mapLeft(() => new FallbackError())
            .map((educationLevelsResponse) =>
                educationLevelsResponse.data.mapNotNull((educationLevelDto) =>
                    this.educationLevelMapper.map(
                        plainToClass(EducationLevelDto, educationLevelDto),
                    ),
                ),
            );
    }

    async fetchAllEmploymentStatus(): Promise<
        Either<FallbackError, EmploymentStatus[]>
    > {
        const employmentStatusResult = await this.http.get<
            EmploymentStatusDto[]
        >(`${BENEFICIARY_PATH}employment_status/`);

        return employmentStatusResult
            .mapLeft(() => new FallbackError())
            .map((employmentStatusResponse) =>
                employmentStatusResponse.data.mapNotNull(
                    (employmentStatusDto) =>
                        this.employmentStatusMapper.map(
                            plainToClass(
                                EmploymentStatusDto,
                                employmentStatusDto,
                            ),
                        ),
                ),
            );
    }

    async fetchAllBeneficiaryIdentificationTypes(): Promise<
        Either<FallbackError, BeneficiaryIdentificationType[]>
    > {
        const beneficiaryIdentificationTypesResult = await this.http.get<
            BeneficiaryIdentificationTypeDto[]
        >(`${BENEFICIARY_PATH}identification_types/`);

        return beneficiaryIdentificationTypesResult
            .mapLeft(() => new FallbackError())
            .map((beneficiaryIdentificationTypesResponse) =>
                beneficiaryIdentificationTypesResponse.data.mapNotNull(
                    (identificationDocumentType) =>
                        this.identificationDocumentTypeMapper.map(
                            plainToClass(
                                BeneficiaryIdentificationTypeDto,
                                identificationDocumentType,
                            ),
                        ),
                ),
            );
    }

    async fetchById(
        beneficiaryId: number,
    ): Promise<
        Either<
            FallbackError,
            { beneficiary: Beneficiary; documentIds: number[] }
        >
    > {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const beneficiaryResult = await this.http.get<BeneficiaryDto>(
            `${BENEFICIARY_PATH}${beneficiaryId}/`,
            { query },
        );

        return beneficiaryResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const beneficiary = this.beneficiaryMapper.map(
                    plainToClass(BeneficiaryDto, response.data),
                );

                if (!beneficiary) return Either.Left(new FallbackError());

                const documentIds = response.data.documents ?? [];

                return Either.Right({
                    beneficiary,
                    documentIds,
                });
            });
    }

    async update(
        beneficiaryToEdit: EditBeneficiary,
    ): Promise<Either<ValidationError | FallbackError, Beneficiary>> {
        const editBeneficiaryBody =
            this.editBeneficiaryMapper.mapToDto(beneficiaryToEdit);

        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const editBeneficiaryResult = await this.http.patch<
            BeneficiaryDto,
            EditBeneficiaryBody
        >(`${BENEFICIARY_PATH}${beneficiaryToEdit.id}/`, editBeneficiaryBody, {
            query,
        });

        return editBeneficiaryResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const beneficiary = this.beneficiaryMapper.map(
                    plainToClass(BeneficiaryDto, response.data),
                );

                if (!beneficiary) return Either.Left(new FallbackError());

                return Either.Right(beneficiary);
            });
    }

    async updateIdentification(
        beneficiaryIdentificationEdit: BeneficiaryIdentification,
    ): Promise<
        Either<ValidationError | FallbackError, BeneficiaryIdentification>
    > {
        const editBeneficiaryIdentificationBody =
            this.editBeneficiaryMapper.mapIdentificationToDto(
                beneficiaryIdentificationEdit,
            );

        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const editBeneficiaryResult = await this.http.patch<
            BeneficiaryIdentificationDto,
            EditBeneficiaryIdentificationBody
        >(
            `${BENEFICIARY_IDENTIFICATION_PATH}${beneficiaryIdentificationEdit.id}/`,
            editBeneficiaryIdentificationBody,
            {
                query,
            },
        );

        return editBeneficiaryResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const beneficiaryIdentification =
                    this.beneficiaryMapper.mapIdentification(
                        plainToClass(
                            BeneficiaryIdentificationDto,
                            response.data,
                        ),
                    );

                if (!beneficiaryIdentification)
                    return Either.Left(new FallbackError());

                return Either.Right(beneficiaryIdentification);
            });
    }

    async updateAddress(
        beneficiaryId: number,
        usualAddressId?: Undefinable<number>,
        censusAddressId?: Undefinable<number>,
    ): Promise<Either<FallbackError, Beneficiary>> {
        const editBeneficiaryBody = {
            usual_address: usualAddressId,
            census_address: censusAddressId,
        };

        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const editBeneficiaryResult = await this.http.patch<BeneficiaryDto>(
            `${BENEFICIARY_PATH}${beneficiaryId}/`,
            editBeneficiaryBody,
            {
                query,
            },
        );

        return editBeneficiaryResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const beneficiary = this.beneficiaryMapper.map(
                    plainToClass(BeneficiaryDto, response.data),
                );

                if (!beneficiary) return Either.Left(new FallbackError());

                return Either.Right(beneficiary);
            });
    }

    async fetchAllDisabilityTypes(): Promise<
        Either<FallbackError, DisabilityType[]>
    > {
        const disabilityTypesResult = await this.http.get<DisabilityTypeDto[]>(
            `${BENEFICIARY_PATH}disability_type/`,
        );

        return disabilityTypesResult
            .mapLeft(() => new FallbackError())
            .map((relationShipsResponse) =>
                relationShipsResponse.data.mapNotNull((disabilityTypeDto) =>
                    this.disabilityTypesMapper.map(
                        plainToClass(DisabilityTypeDto, disabilityTypeDto),
                    ),
                ),
            );
    }

    async fetchAllDependenceGrades(): Promise<
        Either<FallbackError, DependenceGrade[]>
    > {
        const dependenceGradesResult = await this.http.get<
            DependenceGradeDto[]
        >(`${BENEFICIARY_PATH}dependence_grade/`);

        return dependenceGradesResult
            .mapLeft(() => new FallbackError())
            .map((relationShipsResponse) =>
                relationShipsResponse.data.mapNotNull((dependenceGradeDto) =>
                    this.dependenceGradesMapper.map(
                        plainToClass(DependenceGradeDto, dependenceGradeDto),
                    ),
                ),
            );
    }

    async fetchAllTherapyTypes(): Promise<
        Either<FallbackError, TherapyType[]>
    > {
        const therapyTypesResult = await this.http.get<TherapyTypeDto[]>(
            `${BENEFICIARY_PATH}therapy_type/`,
        );
        return therapyTypesResult
            .mapLeft(() => new FallbackError())
            .map((therapyTypesResponse) =>
                therapyTypesResponse.data.mapNotNull((therapyTypeDto) =>
                    this.therapyTypesMapper.map(
                        plainToClass(TherapyTypeDto, therapyTypeDto),
                    ),
                ),
            );
    }

    async fetchAllAssociateServiceToDependence(): Promise<
        Either<FallbackError, AssociateServiceToDependence[]>
    > {
        const associateServiceToDependenceResult = await this.http.get<
            AssociateServiceToDependenceEnumDto[]
        >(`${BENEFICIARY_PATH}associated_activities/`);
        return associateServiceToDependenceResult
            .mapLeft(() => new FallbackError())
            .map((associateServiceToDependenceResponse) =>
                associateServiceToDependenceResponse.data.mapNotNull(
                    (associateServiceToDependenceDto) =>
                        this.associateServiceToDependenceMapper.map(
                            plainToClass(
                                AssociateServiceToDependenceDto,
                                associateServiceToDependenceDto,
                            ),
                        ),
                ),
            );
    }

    async fetchAllOtherEmploymentCharcteristics(): Promise<
        Either<FallbackError, EmploymentRelevantCharacteristics[]>
    > {
        const employmentRelevantCharacteristics = await this.http.get<
            EmploymentSituationRelevantCharacteristicsEnumDto[]
        >(
            `${BENEFICIARY_PATH}other_employment_situation_relevant_characteristics/`,
        );

        return employmentRelevantCharacteristics
            .mapLeft(() => new FallbackError())
            .map((employmentRelevantCharacteristic) =>
                employmentRelevantCharacteristic.data.mapNotNull(
                    (employmentRelevantCharacDto) =>
                        this.employmentCharacteristicsMapper.map(
                            plainToClass(
                                EmploymentRelevantCharacteristicsDto,
                                employmentRelevantCharacDto,
                            ),
                        ),
                ),
            );
    }

    async fetchAllDiagnosisTypes(): Promise<
        Either<FallbackError, DiagnosisType[]>
    > {
        const diagnosisTypesResult = await this.http.get<
            DiagnosisTypeEnumDto[]
        >(`${BENEFICIARY_PATH}diagnosis_types/`);

        return diagnosisTypesResult
            .mapLeft(() => new FallbackError())
            .map((diagnosisTypeResponse) =>
                diagnosisTypeResponse.data.mapNotNull((diagnosisTypeDto) =>
                    this.diagnosisTypeMapper.map(
                        plainToClass(DiagnosisTypeDto, diagnosisTypeDto),
                    ),
                ),
            );
    }

    async deleteIdentification(
        identificationId: number,
    ): Promise<Either<FallbackError, true>> {
        const deleteIdentification = await this.http.delete(
            `${BENEFICIARY_IDENTIFICATION_PATH}${identificationId}/`,
        );

        return deleteIdentification
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }

    async delete(
        beneficiaryId: number,
    ): Promise<Either<FallbackError, boolean>> {
        const deleteBeneficiaryResult = await this.http.delete(
            `${BENEFICIARY_PATH}${beneficiaryId}/`,
        );

        return deleteBeneficiaryResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
