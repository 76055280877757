export enum ExpenseTypeEnum {
    Housing = "housing",
    Supplies = "supplies",
    Transport = "transport",
    Food = "food",
    SchoolStudies = "school_studies",
    Debts = "debts",
    Other = "other",
}
export class ExpenseType {
    constructor(
        public readonly id: ExpenseTypeEnum,
        public readonly label: string,
    ) {}
}
