import { MaritalStatusRepository } from "@beneficiary/data/repositories/marital-status.repository";
import { MaritalStatus } from "@beneficiary/domain/models/marital-status.model";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class GetMaritalStatusesUseCase {
    constructor(
        @inject(MaritalStatusRepository)
        private readonly maritalStatusRepository: MaritalStatusRepository,
    ) {}

    async execute(): Promise<MaritalStatus[]> {
        const maritalStatusesResult =
            await this.maritalStatusRepository.getAllMaritalStatuses();

        if (maritalStatusesResult.isLeft()) {
            ToastManagerStore.error(
                maritalStatusesResult.getLeftOrThrow().message,
            );
        }

        return maritalStatusesResult.fold(
            () => [],
            (maritalStatuses) => maritalStatuses,
        );
    }
}
