import { ProjectRepository } from "@project/data/repositories/project.repository";
import { ProjectsSummary } from "@project/domain/models/projects-summary.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetRecentsModifiedProjectsUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(): Promise<ProjectsSummary> {
        const projectsResult =
            await this.projectRepository.getRecentModifiedProjects();

        return projectsResult.fold(
            () => new ProjectsSummary([], 0),
            (projects) => projects,
        );
    }
}
