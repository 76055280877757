import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { CreateFinancialEntityBudget } from "@project/domain/models/budget/create-financial-entity-budget.model";
import { FinancialEntityBudget } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateBudgetUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(
        budget: CreateFinancialEntityBudget,
    ): Promise<Nullable<FinancialEntityBudget>> {
        const createResult = await this.budgetRepository.create(budget);

        if (createResult.isLeft()) {
            ToastManagerStore.error(createResult.getLeftOrThrow().message);
        }
        return createResult.fold(
            () => null,
            (createdBudget) => createdBudget,
        );
    }
}
