import { BeneficiaryIdentificationType } from "@beneficiary/domain/models/identification-document-type.model";
import { Relationship } from "@beneficiary/domain/models/relationship.model";
import { GetAllBeneficiaryIdentificationTypesUseCase } from "@beneficiary/domain/usecases/get-all-beneficiary-identification-types.usecase";
import { GetAllRelationshipsUseCase } from "@beneficiary/domain/usecases/get-all-relationships.usecase";
import { EducationLevel } from "@core/domain/models/education-level.model";
import { EmploymentStatus } from "@core/domain/models/employment-status.model";
import { GetAllEducationLevelsUseCase } from "@core/domain/usecases/get-all-education-levels.usecase";
import { GetAllEmploymentStatusUseCase } from "@core/domain/usecases/get-all-employment-status.usecase";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class RelativeFormViewModel extends BaseViewModel {
    @observable
    relationships: Relationship[] = [];

    @observable
    identificationDocumentTypes: BeneficiaryIdentificationType[] = [];

    @observable
    educationLevels: EducationLevel[] = [];

    @observable
    employmentStatus: EmploymentStatus[] = [];

    @observable
    initialLoading: boolean = true;

    constructor(
        @inject(GetAllEducationLevelsUseCase)
        private readonly getAllEducationLevelsUseCase: GetAllEducationLevelsUseCase,
        @inject(GetAllEmploymentStatusUseCase)
        private readonly getAllEmploymentStatusUseCase: GetAllEmploymentStatusUseCase,
        @inject(GetAllBeneficiaryIdentificationTypesUseCase)
        private readonly getAllBeneficiaryIdentificationTypesUseCase: GetAllBeneficiaryIdentificationTypesUseCase,
        @inject(GetAllRelationshipsUseCase)
        private readonly getAllRelationshipsUseCase: GetAllRelationshipsUseCase,
    ) {
        super();
        makeObservable(this);

        this.initViewData();
    }

    private async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllEducationLevels(),
            this.getAllEmploymentStatus(),
            this.getAllBeneficiaryIdentificationTypes(),
            this.getAllRelationships(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllEducationLevels(): Promise<void> {
        const educationLevels =
            await this.getAllEducationLevelsUseCase.execute();

        runInAction(() => {
            this.educationLevels = educationLevels;
        });
    }

    async getAllEmploymentStatus(): Promise<void> {
        const employmentStatus =
            await this.getAllEmploymentStatusUseCase.execute();

        runInAction(() => {
            this.employmentStatus = employmentStatus;
        });
    }

    async getAllBeneficiaryIdentificationTypes(): Promise<void> {
        const identificationDocumentTypes =
            await this.getAllBeneficiaryIdentificationTypesUseCase.execute();

        runInAction(() => {
            this.identificationDocumentTypes = identificationDocumentTypes;
        });
    }

    async getAllRelationships(): Promise<void> {
        const relationships = await this.getAllRelationshipsUseCase.execute();

        runInAction(() => {
            this.relationships = relationships;
        });
    }
}
