import { CreateUpdateDocumentBody } from "@core/data/dto/create-update-document.body";
import { DocumentTypeLicenseEnumDto } from "@core/data/dto/document.dto";
import { DocumentTypeLicenseEnumMapper } from "@core/data/mappers/document-type-license-enum.mapper";
import { CreateDocument } from "@core/domain/models/create-document.model";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { inject, injectable } from "inversify";

@injectable()
export class CreateDocumentMapper {
    constructor(
        @inject(DocumentTypeLicenseEnumMapper)
        private readonly documentTypeLicenseEnumMapper: DocumentTypeLicenseEnumMapper,
    ) {}

    mapToDto(createDocument: CreateDocument): CreateUpdateDocumentBody {
        let typeLicense: Undefinable<DocumentTypeLicenseEnumDto> = undefined;
        if (createDocument.typeLicense)
            typeLicense = this.documentTypeLicenseEnumMapper.mapToDto(
                createDocument.typeLicense,
            );

        return {
            name: createDocument.name,
            file: createDocument.file,
            title: createDocument.title,
            author: createDocument.author,
            description: createDocument.description,
            expiry_date: createDocument.expiryDate,
            visible: createDocument.visible,
            category: createDocument.category,
            type_license: typeLicense,
        };
    }
}
