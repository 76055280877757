import { Nullable } from "@core/domain/types/nullable.type";
import { CatalogueEnumDto } from "@project/data/dto/catalogue.dto";
import { CatalogueEnum } from "@project/domain/models/catalogue.model";
import { injectable } from "inversify";

@injectable()
export class CatalogueEnumMapper {
    map(catalogueEnumDto: CatalogueEnumDto): Nullable<CatalogueEnum> {
        switch (catalogueEnumDto) {
            case CatalogueEnumDto.AccAdministrativeProcedures:
                return CatalogueEnum.AccAdministrativeProcedures;
            case CatalogueEnumDto.SocialSupport:
                return CatalogueEnum.SocialSupport;
            case CatalogueEnumDto.Lodging:
                return CatalogueEnum.Lodging;
            case CatalogueEnumDto.Psychological:
                return CatalogueEnum.Psychological;
            case CatalogueEnumDto.SearchHouse:
                return CatalogueEnum.SearchHouse;
            case CatalogueEnumDto.SocialCanteen:
                return CatalogueEnum.SocialCanteen;
            case CatalogueEnumDto.CoursesLectures:
                return CatalogueEnum.CoursesLectures;
            case CatalogueEnumDto.HousingArragements:
                return CatalogueEnum.HousingArragements;
            case CatalogueEnumDto.Delivery:
                return CatalogueEnum.Delivery;
            case CatalogueEnumDto.Training:
                return CatalogueEnum.Training;
            case CatalogueEnumDto.SocioEducationalTraining:
                return CatalogueEnum.SocioEducationalTraining;
            case CatalogueEnumDto.SocioOcuppationalTraining:
                return CatalogueEnum.SocioOcuppationalTraining;
            case CatalogueEnumDto.SocialIntegration:
                return CatalogueEnum.SocialIntegration;
            case CatalogueEnumDto.Events:
                return CatalogueEnum.Events;
            case CatalogueEnumDto.Couseling:
                return CatalogueEnum.Couseling;
            case CatalogueEnumDto.ActiveJobSearch:
                return CatalogueEnum.ActiveJobSearch;
            case CatalogueEnumDto.Others:
                return CatalogueEnum.Others;
            case CatalogueEnumDto.LegalServices:
                return CatalogueEnum.LegalServices;
            case CatalogueEnumDto.Workshops:
                return CatalogueEnum.Workshops;
            default:
                return null;
        }
    }

    mapToDto(catalogueEnum: CatalogueEnum): CatalogueEnumDto {
        // eslint-disable-next-line default-case
        switch (catalogueEnum) {
            case CatalogueEnum.AccAdministrativeProcedures:
                return CatalogueEnumDto.AccAdministrativeProcedures;
            case CatalogueEnum.SocialSupport:
                return CatalogueEnumDto.SocialSupport;
            case CatalogueEnum.Lodging:
                return CatalogueEnumDto.Lodging;
            case CatalogueEnum.Psychological:
                return CatalogueEnumDto.Psychological;
            case CatalogueEnum.SearchHouse:
                return CatalogueEnumDto.SearchHouse;
            case CatalogueEnum.SocialCanteen:
                return CatalogueEnumDto.SocialCanteen;
            case CatalogueEnum.CoursesLectures:
                return CatalogueEnumDto.CoursesLectures;
            case CatalogueEnum.HousingArragements:
                return CatalogueEnumDto.HousingArragements;
            case CatalogueEnum.Delivery:
                return CatalogueEnumDto.Delivery;
            case CatalogueEnum.Training:
                return CatalogueEnumDto.Training;
            case CatalogueEnum.SocioEducationalTraining:
                return CatalogueEnumDto.SocioEducationalTraining;
            case CatalogueEnum.SocioOcuppationalTraining:
                return CatalogueEnumDto.SocioOcuppationalTraining;
            case CatalogueEnum.SocialIntegration:
                return CatalogueEnumDto.SocialIntegration;
            case CatalogueEnum.Events:
                return CatalogueEnumDto.Events;
            case CatalogueEnum.Couseling:
                return CatalogueEnumDto.Couseling;
            case CatalogueEnum.ActiveJobSearch:
                return CatalogueEnumDto.ActiveJobSearch;
            case CatalogueEnum.Others:
                return CatalogueEnumDto.Others;
            case CatalogueEnum.LegalServices:
                return CatalogueEnumDto.LegalServices;
            case CatalogueEnum.Workshops:
                return CatalogueEnumDto.Workshops;
        }
    }
}
