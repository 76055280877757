export enum GenderEnum {
    Male = "male",
    Female = "female",
    NonBinary = "non_binary",
    Intersexual = "intersexual",
    NotStated = "not_stated",
}

export class Gender {
    constructor(
        public readonly id: GenderEnum,
        public readonly label: string,
    ) {}
}
