import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { MaterialRepository } from "@entity/data/repositories/material.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteMaterialUseCase {
    constructor(
        @inject(MaterialRepository)
        private readonly materialRepository: MaterialRepository,
    ) {}

    async execute(materialId: number): Promise<boolean> {
        const result = await this.materialRepository.delete(materialId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
