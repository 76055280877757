import { AuthorityManagerRepository } from "@entity/data/repositories/authority-manager.repository";
import { PositionType } from "@entity/domain/models/position-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllPositionTypesUseCase {
    constructor(
        @inject(AuthorityManagerRepository)
        private readonly authorityManagerRepository: AuthorityManagerRepository,
    ) {}

    async execute(): Promise<PositionType[]> {
        const positionTypeResults =
            await this.authorityManagerRepository.getAllPositionTypes();

        return positionTypeResults.fold(
            () => [],
            (positionTypes) => positionTypes,
        );
    }
}
