import { Auditory } from "@entity/domain/models/quality-framework/auditory/auditory.model";
import { CreateAuditory } from "@entity/domain/models/quality-framework/auditory/create-auditory.model";

export class EditAuditory extends CreateAuditory {
    constructor(
        public readonly id: Auditory["id"],
        public override certificationEntity: CreateAuditory["certificationEntity"],
        public override year: CreateAuditory["year"],
        public override entity: CreateAuditory["entity"],
    ) {
        super(certificationEntity, year, entity);
    }
}
