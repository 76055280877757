import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { DepositTypeEnum } from "../../domain/models/deposit-type.model";
import { DepositTypeEnumDto } from "../dto/deposit-types.dto";

@injectable()
export class DepositTypeEnumMapper {
    map(depositTypeEnumDto: DepositTypeEnumDto): Nullable<DepositTypeEnum> {
        switch (depositTypeEnumDto) {
            case DepositTypeEnumDto.OWN_FINANCING:
                return DepositTypeEnum.OWN_FINANCING;
            case DepositTypeEnumDto.ANOTHER_SOURCES_FINANCING:
                return DepositTypeEnum.ANOTHER_SOURCES_FINANCING;
            case DepositTypeEnumDto.SUBVENTION:
                return DepositTypeEnum.SUBVENTION;
            case DepositTypeEnumDto.OTHER:
                return DepositTypeEnum.OTHER;
            default:
                return null;
        }
    }

    mapToDto(depositTypeEnum: DepositTypeEnum): DepositTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (depositTypeEnum) {
            case DepositTypeEnum.OWN_FINANCING:
                return DepositTypeEnumDto.OWN_FINANCING;
            case DepositTypeEnum.ANOTHER_SOURCES_FINANCING:
                return DepositTypeEnumDto.ANOTHER_SOURCES_FINANCING;
            case DepositTypeEnum.SUBVENTION:
                return DepositTypeEnumDto.SUBVENTION;
            case DepositTypeEnum.OTHER:
                return DepositTypeEnumDto.OTHER;
        }
    }
}
