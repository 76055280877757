import { DistrictsRepository } from "@beneficiary/data/repositories/districts.repository";
import { District } from "@beneficiary/domain/models/district.model";
import { Pagination } from "@core/domain/models/pagination";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllDistrictsUseCase {
    constructor(
        @inject(DistrictsRepository)
        private readonly districtsRepository: DistrictsRepository,
    ) {}

    async execute(): Promise<District[]> {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const pagination = new Pagination(1, 210);
        const districtsResult =
            await this.districtsRepository.getAll(pagination);

        if (districtsResult.isLeft()) {
            ToastManagerStore.error(districtsResult.getLeftOrThrow().message);
        }

        return districtsResult.getOrElse([]);
    }
}
