import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { RegconRegisterRepository } from "@entity/data/repositories/regcon-register.repository";
import { CreateRegconRegister } from "@entity/domain/models/quality-framework/regcon-register/create-regcon-register.model";
import { RegconRegister } from "@entity/domain/models/quality-framework/regcon-register/regcon-register.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateRegconRegisterUseCase {
    constructor(
        @inject(RegconRegisterRepository)
        private readonly regconRegisterRepository: RegconRegisterRepository,
    ) {}

    async execute(
        regconRegister: CreateRegconRegister,
    ): Promise<Nullable<RegconRegister>> {
        const creationResult =
            await this.regconRegisterRepository.create(regconRegister);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdRegconRegister) => createdRegconRegister,
        );
    }
}
