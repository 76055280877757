import { Nullable } from "@core/domain/types/nullable.type";
import { CCAADto } from "@entity/data/dto/ccaa-enum.dto";
import { CCAAEnumMapper } from "@entity/data/mappers/ccaa-enum.mapper";
import { CCAA, CCAAEnum } from "@entity/domain/models/ccaa.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class CCAAMapper {
    constructor(
        @inject(CCAAEnumMapper)
        private ccaaEnumMapper: CCAAEnumMapper,
    ) {}

    map(ccaaEnumMapperDto: CCAADto): Nullable<CCAA> {
        const errors = validateSync(ccaaEnumMapperDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<CCAAEnum> = this.ccaaEnumMapper.map(
            ccaaEnumMapperDto.key,
        );

        if (!id) return null;

        return new CCAA(id, ccaaEnumMapperDto.value);
    }
}
