import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { CreateAuthorityManagerBody } from "@entity/data/dto/authority-manager/create-authority-manager.body";
import { MandateTerminationReasonEnumMapper } from "@entity/data/mappers/mandate-termination-reason-enum.mapper";
import { PositionTypeEnumMapper } from "@entity/data/mappers/position-type-enum.mapper";
import { CreateAuthorityManager } from "@entity/domain/models/authority-manager/create-authority-manager.model";
import { AuthorityManagerFormValuesValidated } from "@entity/presentation/component/entity-form/authority-manager/form/authority-manager-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreateAuthorityManagerMapper {
    constructor(
        @inject(PositionTypeEnumMapper)
        private readonly positionTypeEnumMapper: PositionTypeEnumMapper,
        @inject(MandateTerminationReasonEnumMapper)
        private readonly mandateTerminationReasonEnumMapper: MandateTerminationReasonEnumMapper,
    ) {}
    mapToCreateDto(
        createAuthorityManager: CreateAuthorityManager,
    ): CreateAuthorityManagerBody {
        const position = createAuthorityManager.position.map((positionEnum) =>
            this.positionTypeEnumMapper.mapToDto(positionEnum),
        );

        let mandateTerminationReason = null;
        if (createAuthorityManager.mandateTerminationReason) {
            mandateTerminationReason =
                this.mandateTerminationReasonEnumMapper.mapToDto(
                    createAuthorityManager.mandateTerminationReason,
                );
        }

        const authorityManagerDto: CreateAuthorityManagerBody = {
            name: createAuthorityManager.name,
            position,
            entity: createAuthorityManager.entity,
            appointment_date: convertEmptyToUndefined(
                createAuthorityManager.appointmentDate?.toISODate(),
            ),
            election_procedure: convertEmptyToUndefined(
                createAuthorityManager.electionProcedure,
            ),
            other_position: convertEmptyToUndefined(
                createAuthorityManager.otherPosition,
            ),
            mandate_termination_reason: convertEmptyToUndefined(
                mandateTerminationReason,
            ),
            other_mandate_termination_reason: convertEmptyToUndefined(
                createAuthorityManager.otherMandateTerminationReason,
            ),
            mandate_end_date: convertEmptyToUndefined(
                createAuthorityManager.terminationDate?.toISODate(),
            ),
        };

        return authorityManagerDto;
    }

    mapFromFormValues(
        entityId: number,
        authorityManager: AuthorityManagerFormValuesValidated,
    ): CreateAuthorityManager {
        const createAuthorityManager: CreateAuthorityManager =
            new CreateAuthorityManager(
                authorityManager.name,
                authorityManager.position,
                entityId,
                convertEmptyToUndefined(authorityManager.appointmentDate),
                convertEmptyToUndefined(authorityManager.electionProcedure),
                convertEmptyToUndefined(authorityManager.otherPosition),
                convertEmptyToUndefined(
                    authorityManager.mandateTerminationReason,
                ),
                convertEmptyToUndefined(
                    authorityManager.otherMandateTerminationReason,
                ),
                convertEmptyToUndefined(authorityManager.terminationDate),
            );

        return createAuthorityManager;
    }
}
