import { ExternalDerivation } from "@beneficiary/domain/models/external-derivation/external-derivation.model";

export class CreateExternalDerivation {
    constructor(
        public reason: ExternalDerivation["reason"],
        public centerType: ExternalDerivation["centerType"],
        public centerName: ExternalDerivation["centerName"],
        public beneficiary: ExternalDerivation["beneficiary"],
        public isDerivateToExternal: ExternalDerivation["isDerivateToExternal"],
        public contactName?: ExternalDerivation["contactName"],
        public contactPhone?: ExternalDerivation["contactPhone"],
        public contactEmail?: ExternalDerivation["contactEmail"],
        public documents?: ExternalDerivation["documents"],
    ) {}
}
