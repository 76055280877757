import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class PublicItineraryDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    objective!: string;

    @IsString()
    @IsNotEmpty()
    actions!: string;

    @IsString()
    @IsNotEmpty()
    actions_evaluation!: string;

    @IsNumber()
    @IsNotEmpty()
    beneficiary!: number;

    @IsDateString()
    @IsOptional()
    created!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    modified!: Nullable<string>;
}

export class PublicItinerariesDto extends PaginatedDto {
    @IsArray()
    @Type(() => PublicItineraryDto)
    results!: PublicItineraryDto[];
}

export type PublicItinerariesQuery = PaginatedQuery & {
    beneficiary?: number;
};
