import { DocumentCategoryDto } from "@core/data/dto/document-categories.dto";
import { DocumentCategory } from "@core/domain/models/documents-category.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class DocumentCategoryMapper {
    map(documentCategoryDto: DocumentCategoryDto): Nullable<DocumentCategory> {
        const errors = validateSync(documentCategoryDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        return new DocumentCategory(
            documentCategoryDto.id,
            documentCategoryDto.name,
            documentCategoryDto.description ?? undefined,
        );
    }
}
