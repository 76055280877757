import { ExpenseTypeDto } from "@beneficiary/data/dto/expense-type.dto";
import { ExpenseTypeEnumMapper } from "@beneficiary/data/mappers/expense-type-enum.mapper";
import { ExpenseType } from "@beneficiary/domain/models/economic-data/expense/expense-type.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ExpenseTypeMapper {
    constructor(
        @inject(ExpenseTypeEnumMapper)
        private readonly expenseTypeEnumMapper: ExpenseTypeEnumMapper,
    ) {}
    map(expenseTypeDto: ExpenseTypeDto): Nullable<ExpenseType> {
        const errors = validateSync(expenseTypeDto);
        if (errors.length) {
            console.error(errors);
            return null;
        }
        const id = this.expenseTypeEnumMapper.map(expenseTypeDto.key);
        if (!id) return null;
        return new ExpenseType(id, expenseTypeDto.value);
    }
}
