import { ProjectRepository } from "@project/data/repositories/project.repository";
import { Status } from "@project/domain/models/status.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllStatusUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(): Promise<Status[]> {
        const statusResult = await this.projectRepository.getAllStatus();

        return statusResult.getOrElse([]);
    }
}
