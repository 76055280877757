import { Gender } from "@beneficiary/domain/models/gender.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { GenderDto } from "../dto/gender.dto";
import { GenderEnumMapper } from "./gender-enum.mapper";

@injectable()
export class GenderMapper {
    constructor(
        @inject(GenderEnumMapper)
        private readonly genderEnumMapper: GenderEnumMapper,
    ) {}

    map(genderDto: GenderDto): Nullable<Gender> {
        const errors = validateSync(GenderDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.genderEnumMapper.map(genderDto.key);
        if (!id) return null;

        return new Gender(id, genderDto.value);
    }
}
