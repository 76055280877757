import { Proceeding } from "@proceeding/domain/models/proceeding.model";

export class CreateProceeding {
    constructor(
        public beneficiary: Proceeding["beneficiaryId"],
        public project: Proceeding["projectId"],
        public activities: Proceeding["activities"],
        public accountableProfessional: Proceeding["professionalResponsibleId"],
        public reason: Proceeding["motivation"],
        public documents?: Proceeding["documents"],
    ) {}
}
