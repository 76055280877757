import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { AssociateServiceToDependenceEnum } from "../../domain/models/associate-service-to-dependence.enum";
import { AssociateServiceToDependenceEnumDto } from "../dto/associate-service-to-dependence.enum.dto";

@injectable()
export class AssociateServiceToDependenceEnumMapper {
    map(
        associateServiceToDependenceIdDto: AssociateServiceToDependenceEnumDto,
    ): Nullable<AssociateServiceToDependenceEnum> {
        switch (associateServiceToDependenceIdDto) {
            case AssociateServiceToDependenceEnumDto.DayCenter:
                return AssociateServiceToDependenceEnum.DayCenter;
            case AssociateServiceToDependenceEnumDto.HomeHelp:
                return AssociateServiceToDependenceEnum.HomeHelp;
            case AssociateServiceToDependenceEnumDto.Rehab:
                return AssociateServiceToDependenceEnum.Rehab;
            case AssociateServiceToDependenceEnumDto.Residence:
                return AssociateServiceToDependenceEnum.Residence;
            case AssociateServiceToDependenceEnumDto.ServiceCheck:
                return AssociateServiceToDependenceEnum.ServiceCheck;
            case AssociateServiceToDependenceEnumDto.Telecare:
                return AssociateServiceToDependenceEnum.Telecare;
            default:
                return null;
        }
    }

    mapToDto(
        associateServiceToDependenceId: AssociateServiceToDependenceEnum,
    ): AssociateServiceToDependenceEnumDto {
        // eslint-disable-next-line default-case
        switch (associateServiceToDependenceId) {
            case AssociateServiceToDependenceEnum.DayCenter:
                return AssociateServiceToDependenceEnumDto.DayCenter;
            case AssociateServiceToDependenceEnum.HomeHelp:
                return AssociateServiceToDependenceEnumDto.HomeHelp;
            case AssociateServiceToDependenceEnum.Rehab:
                return AssociateServiceToDependenceEnumDto.Rehab;
            case AssociateServiceToDependenceEnum.Residence:
                return AssociateServiceToDependenceEnumDto.Residence;
            case AssociateServiceToDependenceEnum.ServiceCheck:
                return AssociateServiceToDependenceEnumDto.ServiceCheck;
            case AssociateServiceToDependenceEnum.Telecare:
                return AssociateServiceToDependenceEnumDto.Telecare;
            case AssociateServiceToDependenceEnum.Empty:
                return AssociateServiceToDependenceEnumDto.Empty;
        }
    }
}
