import { BudgetCategoryConceptsDto } from "@project/data/dto/budget.dto";
import { BudgetCategoryConceptMapper } from "@project/data/mappers/budget/categories/concepts/budget-category-concept.mapper";
import { BudgetCategoryConcepts } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetCategoryConceptsMapper {
    constructor(
        @inject(BudgetCategoryConceptMapper)
        private readonly budgetCategoryConceptMapper: BudgetCategoryConceptMapper,
    ) {}
    map(
        budgetCategoryConceptsDto: BudgetCategoryConceptsDto,
    ): BudgetCategoryConcepts {
        const errors = validateSync(budgetCategoryConceptsDto);
        if (Boolean(errors.length)) {
            console.error(errors);
            return new BudgetCategoryConcepts([], 0);
        }

        const budgetCategoryConcepts =
            budgetCategoryConceptsDto.results.mapNotNull(
                (categoryConceptBudgetDto) =>
                    this.budgetCategoryConceptMapper.map(
                        categoryConceptBudgetDto,
                    ),
            );
        return new BudgetCategoryConcepts(
            budgetCategoryConcepts,
            budgetCategoryConceptsDto.count,
        );
    }
}
