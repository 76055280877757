import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { Type } from "class-transformer";
import { IsArray, IsNotEmpty } from "class-validator";
import { SupplierDto } from "./supplier.dto";

export class SuppliersDto extends PaginatedDto {
    @IsArray()
    @IsNotEmpty()
    @Type(() => SupplierDto)
    results!: SupplierDto[];
}

export type SupplierQuery = PaginatedQuery & {
    search?: Nullable<string>;
    active?: Nullable<boolean>;
    dateTo?: Nullable<string>;
    dateFrom?: Nullable<string>;
    entity__in?: Nullish<string>;
};
