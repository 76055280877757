import { BeneficiarySummaryDto } from "@beneficiary/data/dto/beneficiaries-summary.dto";
import { BeneficiarySummary } from "@beneficiary/domain/models/beneficiary-summary.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { BeneficiaryDto } from "../dto/beneficiary.dto";

@injectable()
export class BeneficiarySummaryMapper {
    map(
        beneficiarySummaryDto: BeneficiarySummaryDto,
    ): Nullable<BeneficiarySummary> {
        const errors = validateSync(beneficiarySummaryDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const project: string[] = [];

        beneficiarySummaryDto.projects_data.forEach((proj) =>
            project.push(proj.name),
        );

        const identification =
            beneficiarySummaryDto.identifications_data?.isNotEmpty()
                ? beneficiarySummaryDto.identifications_data[0]?.identification
                : "";

        return new BeneficiarySummary(
            beneficiarySummaryDto.id,
            beneficiarySummaryDto.name,
            identification ?? "",
            beneficiarySummaryDto.surname,
            project,
        );
    }

    mapFromBeneficiary(
        beneficiaryDto: BeneficiaryDto,
    ): Nullable<BeneficiarySummary> {
        const errors = validateSync(beneficiaryDto);

        const identificationNumber =
            beneficiaryDto.identifications_data?.isNotEmpty()
                ? beneficiaryDto.identifications_data[0]?.identification
                : "";

        if (errors.length) {
            console.error(errors);
            return null;
        }

        return new BeneficiarySummary(
            beneficiaryDto.id,
            beneficiaryDto.name,
            identificationNumber ?? "",
            beneficiaryDto.surname,
            [], // It is not needed for this case
        );
    }
}
