import { CenterTypeDto } from "@beneficiary/data/dto/center-type.dto";
import { CenterTypeMapper } from "@beneficiary/data/mappers/center-type.mapper";
import { CenterType } from "@beneficiary/domain/models/center-type.model";
import { coreTypes } from "@core/core-types.di";
import { type Http } from "@core/data/infrastructures/http/http";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class CenterTypeDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(CenterTypeMapper)
        private readonly centerTypeMapper: CenterTypeMapper,
    ) {}

    async fetchAll(): Promise<Either<FallbackError, CenterType[]>> {
        const centerTypesResult = await this.http.get<CenterTypeDto[]>(
            "/beneficiaries/external_referral_centre/",
        );

        return centerTypesResult
            .mapLeft(() => new FallbackError())
            .map((centerTypesResponse) =>
                centerTypesResponse.data.mapNotNull((centerType) =>
                    this.centerTypeMapper.map(
                        plainToClass(CenterTypeDto, centerType),
                    ),
                ),
            );
    }
}
