import { Nullable } from "@core/domain/types/nullable.type";
import {
    RegconRegisterDto,
    RegconRegistersDto,
} from "@entity/data/dto/quality-framework/regcon-register/regcon-register.dto";
import { CCAAEnumMapper } from "@entity/data/mappers/ccaa-enum.mapper";
import { RegconRegisterMapper } from "@entity/data/mappers/quality-framework/regcon-register/regcon-register.mapper";
import {
    RegconRegister,
    RegconRegisters,
} from "@entity/domain/models/quality-framework/regcon-register/regcon-register.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class RegconRegistersMapper {
    constructor(
        @inject(RegconRegisterMapper)
        private readonly regconRegisterMapper: RegconRegisterMapper,
        @inject(CCAAEnumMapper)
        private readonly ccaaEnumMapper: CCAAEnumMapper,
    ) {}

    map(regconRegistersDto: RegconRegistersDto): RegconRegisters {
        const errors = validateSync(regconRegistersDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new RegconRegisters([], 0);
        }

        const regconRegisters = regconRegistersDto.results.mapNotNull(
            (regconRegisterDto) =>
                this.regconRegisterMapper.map(regconRegisterDto),
        );

        return new RegconRegisters(regconRegisters, regconRegistersDto.count);
    }

    mapRegconRegister(
        regconRegisterDto: RegconRegisterDto,
    ): Nullable<RegconRegister> {
        if (!regconRegisterDto.id) {
            return null;
        }

        const registeredCcaa = this.ccaaEnumMapper.map(
            regconRegisterDto.registered_ccaa,
        );
        if (!registeredCcaa) return null;

        const regconRegister: RegconRegister = {
            id: regconRegisterDto.id,
            registerNumber: regconRegisterDto.register_number,
            registeredCcaa,
            entity: regconRegisterDto.entity,
        };
        return regconRegister;
    }
}
