import { PublicItineraryDto } from "@beneficiary/data/dto/public-itinerary/public-itinerary.dto";
import { PublicItinerary } from "@beneficiary/domain/models/public-itinerary/public-itinerary.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class PublicItineraryMapper {
    map(publicItineraryDto: PublicItineraryDto): Nullable<PublicItinerary> {
        const errors = validateSync(publicItineraryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        return new PublicItinerary(
            publicItineraryDto.id,
            publicItineraryDto.objective,
            publicItineraryDto.actions,
            publicItineraryDto.actions_evaluation,
            publicItineraryDto.beneficiary,
            publicItineraryDto.created
                ? DateTime.fromISO(publicItineraryDto.created)
                : undefined,
            publicItineraryDto.modified
                ? DateTime.fromISO(publicItineraryDto.modified)
                : undefined,
        );
    }

    mapToDto(publicItinerary: PublicItinerary): PublicItineraryDto {
        const publicItineraryDto: PublicItineraryDto = {
            id: publicItinerary.id,
            objective: publicItinerary.objetive,
            actions: publicItinerary.actions,
            actions_evaluation: publicItinerary.actionsEvaluation,
            beneficiary: publicItinerary.beneficiary,
            created: publicItinerary.created?.toISO() ?? null,
            modified: publicItinerary.modified?.toISO() ?? null,
        };

        return publicItineraryDto;
    }
}
