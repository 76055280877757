import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { DateTime } from "luxon";
import { BudgetSummary } from "../../domain/models/budget-summary.model";
import { BudgetDto } from "../dto/budget.dto";

@injectable()
export class BudgetSummaryMapper {
    map(budgetSummaryDto: BudgetDto): Nullable<BudgetSummary> {
        const errors = validateSync(budgetSummaryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }
        const createdAt = DateTime.fromISO(budgetSummaryDto.created);

        return new BudgetSummary(
            budgetSummaryDto.id,
            budgetSummaryDto.name,
            createdAt,
        );
    }
}
