import { Pagination } from "@core/domain/models/pagination";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EntitySummary } from "@entity/domain/models/entity-summary.model";
import { GetEntitiesByUseCase } from "@entity/domain/usecases/entity/get-entities-by.usecase";
import { ExportProjectExpensesUseCase } from "@entity/domain/usecases/export-project-expenses.usecase";
import type { ExpenseSearchFilters } from "@project/domain/models/expenses/expense-search-filters";
import { ExpenseType } from "@project/domain/models/expenses/expense-type.model";
import { ExpenseAllTypes } from "@project/domain/models/expenses/expense.model";
import { ProjectSearchFilters } from "@project/domain/models/project-search-filters";
import { ProjectSummary } from "@project/domain/models/project-summary.model";
import { GetAllExpensesUseCase } from "@project/domain/usecases/expenses/get-all-expenses.usecase";
import { GetAllProjectExpenseTypesUseCase } from "@project/domain/usecases/expenses/get-all-project-expense-types.usecase";
import { SearchProjectsByUseCase } from "@project/domain/usecases/search-projects-by.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { ExportProjectExpensesFormValuesValidated } from "./export-project-expenses-modal/export-project-expenses-modal-form.component";

export interface ExpenseListTable {
    id: number;
    typeLabel: string;
    subTypeLabel: string;
    projectName: string;
    totalCost: number;
    projectId?: Undefinable<number>;
}

interface ExpensesListTable {
    expenses: ExpenseListTable[];
    count: number;
}

@injectable()
export class ExpenseListPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    types: unknown[] = [];

    @observable
    expenseForm: Undefinable<ExpenseAllTypes>;

    @observable
    expenses: ExpenseAllTypes[] = [];

    initialExpensesFiltersValue: Omit<ExpenseSearchFilters, "projectId"> = {
        text: "",
        type: null,
    };

    @observable
    expensesFilters: ExpenseSearchFilters = this.initialExpensesFiltersValue;

    @observable
    expensesTable: ExpensesListTable = { expenses: [], count: 0 };

    @observable
    expensesModalIsOpen: boolean = false;

    @observable
    expensesTypes: ExpenseType[] = [];

    @observable
    pagination: Pagination = new Pagination();

    @observable
    showExportProjectExpensesModal = false;

    @observable
    projects: ProjectSummary[] = [];

    @observable
    entities: EntitySummary[] = [];

    constructor(
        @inject(GetAllExpensesUseCase)
        private readonly getAllExpensesUseCase: GetAllExpensesUseCase,
        @inject(GetAllProjectExpenseTypesUseCase)
        private readonly getAllProjectExpenseTypesUseCase: GetAllProjectExpenseTypesUseCase,
        @inject(SearchProjectsByUseCase)
        private readonly searchProjectsByUseCase: SearchProjectsByUseCase,
        @inject(ExportProjectExpensesUseCase)
        private readonly exportProjectExpensesUseCase: ExportProjectExpensesUseCase,
        @inject(GetEntitiesByUseCase)
        private readonly getEntitiesByUseCase: GetEntitiesByUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(
            Promise.all([
                this.getAllExpenseTypes(),
                this.getAllProjects(),
                this.getEntities(),
            ]),
        );

        this.initialLoading = false;
    }

    async getEntities(): Promise<void> {
        const entities = await this.getEntitiesByUseCase.execute(
            Pagination.NoPagination(),
            {},
        );
        runInAction(() => {
            this.entities = entities.entitiesSummary;
        });
    }

    @action
    async getAllExpenses(projectId: number): Promise<ExpenseAllTypes[]> {
        return this.getAllExpensesUseCase.execute(this.pagination, {
            ...this.expensesFilters,
            projectId,
        });
    }

    @action
    async getAllProjects(): Promise<void> {
        const filters: ProjectSearchFilters = {
            name: "",
            financialEntityType: null,
            statusProject: null,
            activeFromDate: null,
            activeToDate: null,
        };
        const { projectsSummary } = await this.searchProjectsByUseCase.execute(
            Pagination.NoPagination(),
            filters,
        );

        this.projects = projectsSummary;

        const result = await Promise.all(
            projectsSummary.map(async (projectSummary) =>
                this.getAllExpenses(projectSummary.id),
            ),
        );
        runInAction(() => {
            const expenses = result.flat();
            this.expenses = expenses;
            this.expensesTable = {
                count: expenses.length,
                expenses: expenses.map((expense) => ({
                    id: expense.id,
                    typeLabel: expense.typeLabel,
                    subTypeLabel:
                        "subTypeLabel" in expense ? expense.subTypeLabel : "",
                    totalCost: expense.amountImputation ?? 0,
                    projectName:
                        projectsSummary.find(
                            (project) => project.id === expense.projectId,
                        )?.name ?? "",
                    projectId: expense.projectId,
                })),
            };
        });
    }

    @action
    async setExpensesFilters(filters: ExpenseSearchFilters): Promise<void> {
        if (
            filters.type !== this.expensesFilters.type ||
            filters.text !== this.expensesFilters.text
        ) {
            this.expensesFilters = filters;
            await this.getAllProjects();
        }
    }

    @action
    async setPagination(page: number, pageSize: number): Promise<void> {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;
    }

    @action
    setExpenseForm(expenseId: number): void {
        this.expenseForm = this.expenses.find(
            (expense) => expense.id === expenseId,
        );
    }

    async getAllExpenseTypes(): Promise<void> {
        const types = await this.getAllProjectExpenseTypesUseCase.execute();

        runInAction(() => {
            this.expensesTypes = types;
        });
    }

    @action
    setShowExportProjectExpensesModal(open: boolean): void {
        this.showExportProjectExpensesModal = open;
    }

    @action
    closeShowExportProjectExpensesModal(): void {
        this.showExportProjectExpensesModal = false;
    }

    async exportExpenses(
        values: ExportProjectExpensesFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();

        const blob = await this.exportProjectExpensesUseCase.execute(values);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Gastos.csv");
        document.body.appendChild(link);
        link.click();

        ToastManagerStore.success();
        this.showExportProjectExpensesModal = false;

        LoadLayoutStore.finish();
    }
}
