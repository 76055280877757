import { Nullable } from "@core/domain/types/nullable.type";
import {
    PartnersNumberDto,
    PartnersNumbersDto,
} from "@entity/data/dto/partners-number/partners-number.dto";
import { CCAAEnumMapper } from "@entity/data/mappers/ccaa-enum.mapper";
import {
    PartnersNumber,
    PartnersNumbers,
} from "@entity/domain/models/partners-number/partners-number.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class PartnersNumbersMapper {
    constructor(
        @inject(CCAAEnumMapper)
        private readonly ccaaEnumMapper: CCAAEnumMapper,
    ) {}

    map(partnersNumbersDto: PartnersNumbersDto): PartnersNumbers {
        const errors = validateSync(partnersNumbersDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new PartnersNumbers([], 0);
        }

        const partnersNumbers = partnersNumbersDto.results.mapNotNull(
            (partnersNumberDto) => this.mapPartnersNumber(partnersNumberDto),
        );

        return new PartnersNumbers(partnersNumbers, partnersNumbersDto.count);
    }

    mapPartnersNumber(
        partnersNumberDto: PartnersNumberDto,
    ): Nullable<PartnersNumber> {
        if (!partnersNumberDto.id) {
            return null;
        }

        const registeredCcaa = this.ccaaEnumMapper.map(
            partnersNumberDto.registered_ccaa,
        );
        if (!registeredCcaa) return null;

        const partnersNumber: PartnersNumber = {
            id: partnersNumberDto.id,
            numberOfMembers: partnersNumberDto.number_of_members,
            registeredCcaa,
            entity: partnersNumberDto.entity,
        };
        return partnersNumber;
    }
}
