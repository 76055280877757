import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum ProfessionalGroupEnumDto {
    ProfessionalGroup0 = "professional_group_0",
    ProfessionalGroup1 = "professional_group_1",
    ProfessionalGroup2 = "professional_group_2",
    ProfessionalGroup3 = "professional_group_3",
    ProfessionalGroup4 = "professional_group_4",
    Freelance = "Autónomo",
}

export class ProfessionalGroupDto {
    @IsEnum(ProfessionalGroupEnumDto)
    @IsNotEmpty()
    key!: ProfessionalGroupEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
