import { CreateEmployeeContract } from "@entity/domain/models/employee/contracts/employee/create-employee-contract.model";
import { EmployeeContract } from "@entity/domain/models/employee/contracts/employee/employee-contract.model";

export class EditEmployeeContract extends CreateEmployeeContract {
    // eslint-disable-next-line max-params
    constructor(
        public readonly id: EmployeeContract["id"],
        type: EmployeeContract["type"],
        startDate: EmployeeContract["startDate"],
        hasDisability: EmployeeContract["hasDisability"],
        professionalGroup: EmployeeContract["professionalGroup"],
        contributionGroup: EmployeeContract["contributionGroup"],
        weeklyWorkdayHours: EmployeeContract["weeklyWorkdayHours"],
        netSalary: EmployeeContract["netSalary"],
        irpf: EmployeeContract["irpf"],
        socialSecurityContribution: EmployeeContract["socialSecurityContribution"],
        monthlyCost: EmployeeContract["monthlyCost"],
        socialSecurityContributionCompany: EmployeeContract["socialSecurityContributionCompany"],
        totalCost: EmployeeContract["totalCost"],
        deductibleAmountDisability: EmployeeContract["deductibleAmountDisability"],
        employeeId: EmployeeContract["employeeId"],
        disabilityType?: EmployeeContract["disabilityType"],
        terminationReason?: EmployeeContract["terminationReason"],
        otherType?: EmployeeContract["otherType"],
        isSubsidized?: EmployeeContract["isSubsidized"],
        subsidyDeductibleAmount?: EmployeeContract["subsidyDeductibleAmount"],
        subsidyReason?: EmployeeContract["subsidyReason"],
        otherSubsidyReason?: EmployeeContract["otherSubsidyReason"],
        subsidyScope?: EmployeeContract["subsidyScope"],
        endDate?: EmployeeContract["endDate"],
    ) {
        super(
            type,
            startDate,
            hasDisability,
            professionalGroup,
            contributionGroup,
            weeklyWorkdayHours,
            netSalary,
            irpf,
            socialSecurityContribution,
            monthlyCost,
            socialSecurityContributionCompany,
            totalCost,
            deductibleAmountDisability,
            employeeId,
            disabilityType,
            terminationReason,
            otherType,
            isSubsidized,
            subsidyDeductibleAmount,
            subsidyReason,
            otherSubsidyReason,
            subsidyScope,
            endDate,
        );
    }
}
