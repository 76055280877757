import { DependenceGradeDto } from "@beneficiary/data/dto/dependence-grade-enum.dto";
import { DependenceGrade } from "@beneficiary/domain/models/dependence-grade.enum";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DependenceGradesEnumMapper } from "./dependence-grades-enum.mapper";

@injectable()
export class DependenceGradesMapper {
    constructor(
        @inject(DependenceGradesEnumMapper)
        private readonly dependenceGradesEnumMapper: DependenceGradesEnumMapper,
    ) {}
    map(dependenceGradeDto: DependenceGradeDto): Nullable<DependenceGrade> {
        const errors = validateSync(DependenceGradeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const dependenceGradeId = this.dependenceGradesEnumMapper.map(
            dependenceGradeDto.key,
        );

        if (!dependenceGradeId) return null;

        return new DependenceGrade(dependenceGradeId, dependenceGradeDto.value);
    }
}
