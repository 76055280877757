import { Nullable } from "@core/domain/types/nullable.type";
import { ColumnBudgetDto } from "@project/data/dto/budget.dto";
import { BudgetColumnTypeEnumMapper } from "@project/data/mappers/budget/columns/budget-column-type-enum.mapper";
import { BudgetColumn } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetColumnMapper {
    constructor(
        @inject(BudgetColumnTypeEnumMapper)
        private readonly budgetColumnTypeEnumMapper: BudgetColumnTypeEnumMapper,
    ) {}
    map(budgetColumnDto: ColumnBudgetDto): Nullable<BudgetColumn> {
        const errors = validateSync(budgetColumnDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }
        const type = this.budgetColumnTypeEnumMapper.map(budgetColumnDto.type);
        if (!type) return null;

        return new BudgetColumn(
            budgetColumnDto.id,
            budgetColumnDto.name,
            budgetColumnDto.budget,
            type,
        );
    }
}
