import { CreateDocument } from "@core/domain/models/create-document.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Pagination } from "@core/domain/models/pagination";
import { Nullable } from "@core/domain/types/nullable.type";
import { CreateDocumentUseCase } from "@core/domain/usecases/create-document.usecase";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import type {
    BudgetSummary,
    BudgetsSearchFilters,
} from "@project/domain/models/budget-summary.model";
import { FinancialEntitiesSearchFilters } from "@project/domain/models/financial-entity/financial-entities-search-filters";
import { FinancialEntities } from "@project/domain/models/financial-entity/financial-entities.model";
import { ProjectFinancialEntitiesSearchFilters } from "@project/domain/models/project-financial-entities.model";
import { ExportJustificationUseCase } from "@project/domain/usecases/export-justification.usecase";
import { GetAllFinancialEntitiesUseCase } from "@project/domain/usecases/financial-entity/get-all-financial-entities.usecase";
import { GetAllProjectFinancialEntitiesUseCase } from "@project/domain/usecases/financial-entity/get-all-project-financial-entities.usecase";
import { GetAllBudgetsUseCase } from "@project/domain/usecases/get-all-budgets.usecase";
import { Map } from "immutable";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

export interface DocumentTable {
    id: number;
    name: string;
    uploadDate: DateTime;
}

@injectable()
export class JustificationTabViewModel extends BaseViewModel {
    _entityId: Nullable<number> = null;

    projectId: Nullable<number> = null;

    @observable
    initialLoading: boolean = true;

    @observable
    financialEntitiesForm: FinancialEntities = new FinancialEntities([], 0);

    // Documents is a Array of Maps.
    // Each Array's position refers to a different Justification (Financial Entity justification)
    // Then we have a Table of Documents in each Justification.
    // It is model by the Map(numbner, IncDoc), the key is the documentId and the value is de IncDoc object
    @observable
    documents: Map<number, IncDocument>[] = [];

    @observable
    budgetsSummary: BudgetSummary[] = [];

    get entityId(): number {
        if (!this._entityId) {
            throw Error("Missing entity Id");
        }

        return this._entityId;
    }

    @computed
    get documentsTable(): DocumentTable[][] {
        return this.documents.map((documentMap) =>
            documentMap.toArray().map(([_documentId, document]) => ({
                id: document.id,
                name: document.name,
                uploadDate: document.created,
            })),
        );
    }

    constructor(
        @inject(GetAllFinancialEntitiesUseCase)
        private readonly getAllFinancialEntitiesUseCase: GetAllFinancialEntitiesUseCase,
        @inject(GetAllProjectFinancialEntitiesUseCase)
        private readonly getAllProjectFinancialEntitiesUseCase: GetAllProjectFinancialEntitiesUseCase,
        @inject(GetAllBudgetsUseCase)
        private readonly getAllBudgetsUseCase: GetAllBudgetsUseCase,
        @inject(CreateDocumentUseCase)
        private readonly createDocumentUseCase: CreateDocumentUseCase,
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
        @inject(ExportJustificationUseCase)
        private readonly exportJustificationUseCase: ExportJustificationUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllFinancialEntitiesByEntity(),
            this.getAllBudgets(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    addDocumentToList(document: IncDocument, justificationIndex: number): void {
        if (this.documents[justificationIndex]) {
            this.documents[justificationIndex] = this.documents[
                justificationIndex
            ]?.set(document.id, document);
        }
    }

    @action
    removeDocumentFromList(
        documentId: number,
        justificationIndex: number,
    ): void {
        if (this.documents[justificationIndex]) {
            this.documents[justificationIndex] =
                this.documents[justificationIndex]?.remove(documentId);
        }
    }

    @action
    setProjectId(projectId: number): void {
        this.projectId = projectId;
    }

    async getAllFinancialEntitiesByEntity(): Promise<void> {
        const filters: FinancialEntitiesSearchFilters = {
            entityId: this._entityId,
            hasBudget: true,
        };

        const financialEntities =
            await this.getAllFinancialEntitiesUseCase.execute(filters);

        runInAction(() => {
            this.financialEntitiesForm = new FinancialEntities(
                financialEntities,
                financialEntities.length,
            );
        });
    }

    async getAllBudgets(filters?: BudgetsSearchFilters): Promise<void> {
        const budgets = await this.getAllBudgetsUseCase.execute(
            Pagination.NoPagination(),
            filters,
        );

        runInAction(() => {
            this.budgetsSummary = budgets.budgetSummary;
        });
    }

    async uploadDocument(
        createDocument: CreateDocument,
        justificationIndex: number,
    ): Promise<Nullable<IncDocument>> {
        LoadLayoutStore.start();

        const document =
            await this.createDocumentUseCase.execute(createDocument);

        if (document) {
            this.addDocumentToList(document, justificationIndex);

            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return document;
    }

    async deleteDocument(
        documentId: number,
        justificationIndex: number,
    ): Promise<boolean> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            this.removeDocumentFromList(documentId, justificationIndex);

            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return deleted;
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(documentUrl);

        LoadLayoutStore.finish();
    }

    async exportJustification(id: number, description: string): Promise<void> {
        ToastManagerStore.info(description);
        LoadLayoutStore.start();

        const projectFinancialEntityFilters: ProjectFinancialEntitiesSearchFilters =
            { financialEntityId: id, projectId: this.projectId };

        const projectFinancialEntitites =
            await this.getAllProjectFinancialEntitiesUseCase.execute(
                projectFinancialEntityFilters,
            );

        await this.exportJustificationUseCase.execute(
            projectFinancialEntitites[0].id,
        );

        LoadLayoutStore.finish();
    }
}
