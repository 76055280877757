import { AuthorityManager } from "@entity/domain/models/authority-manager/authority-manager.model";

export class CreateAuthorityManager {
    constructor(
        public name: AuthorityManager["name"],
        public position: AuthorityManager["position"],
        public entity: AuthorityManager["entity"],
        public appointmentDate?: AuthorityManager["appointmentDate"],
        public electionProcedure?: AuthorityManager["electionProcedure"],
        public otherPosition?: AuthorityManager["otherPosition"],
        public mandateTerminationReason?: AuthorityManager["mandateTerminationReason"],
        public otherMandateTerminationReason?: AuthorityManager["otherMandateTerminationReason"],
        public terminationDate?: AuthorityManager["terminationDate"],
    ) {}
}
