import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum IdentificationDocumentTypeEnumDto {
    Dni = "dni",
    Nie = "nie",
    Passport = "passport",
    Nif = "nif",
    Cif = "cif",
}

export class IdentificationDocumentTypeDto {
    @IsEnum(IdentificationDocumentTypeEnumDto)
    @IsNotEmpty()
    key!: IdentificationDocumentTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
