import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContractsRepository } from "@entity/data/repositories/contracts.repository";
import { CreateVolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/create-volunteer-contract.model";
import { VolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/volunteer-contract.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateVolunteerContractUseCase {
    constructor(
        @inject(ContractsRepository)
        private readonly contractsRepository: ContractsRepository,
    ) {}

    async execute(
        volunteerContract: CreateVolunteerContract,
    ): Promise<Nullable<VolunteerContract>> {
        const creationResult =
            await this.contractsRepository.createVolunteerContract(
                volunteerContract,
            );

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdVolunteerContract) => createdVolunteerContract,
        );
    }
}
