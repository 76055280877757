export enum MetricUnitEnum {
    Kg = "kg",
    Euro = "euro",
    Units = "units",
    Other = "other",
    Litres = "l",
}

export class MetricUnit {
    constructor(
        public readonly id: MetricUnitEnum,
        public readonly label: string,
    ) {}
}
