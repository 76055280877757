import type { Nullable } from "@core/domain/types/nullable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Objective } from "@project/domain/models/technical-proposals.model";
import { GetObjectiveByIdUseCase } from "@project/domain/usecases/technical-proposal/objective/get-objective-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class KanbanActivityViewModel extends BaseViewModel {
    @observable
    objectiveLoading: boolean = true;

    @observable
    activityObjective: Nullable<Objective> = null;

    @observable
    objectiveId: number = 0;

    constructor(
        @inject(GetObjectiveByIdUseCase)
        private readonly getObjectiveByIdUseCase: GetObjectiveByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getObjective(this.objectiveId);
    }

    async getObjective(objectiveId: number): Promise<void> {
        const activityObjective =
            await this.getObjectiveByIdUseCase.execute(objectiveId);

        runInAction(() => {
            this.activityObjective = activityObjective;
            this.objectiveLoading = false;
        });
    }

    @action
    setObjectiveId(objectiveId: number): void {
        this.objectiveId = objectiveId;
    }
}
