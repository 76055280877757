import { BeneficiarySummaryMapper } from "@beneficiary/data/mappers/beneficiary-summary.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import { EntitySummaryMapper } from "@entity/data/mappers/entity-summary.mapper";
import { ProceedingDto } from "@proceeding/data/dto/proceeding.dto";
import { StatusEnumMapper } from "@proceeding/data/mappers/status-enum.mapper";
import {
    ProceedingEntity,
    ProceedingSummary,
} from "@proceeding/domain/models/proceeding-summary.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class ProceedingSummaryMapper {
    constructor(
        @inject(StatusEnumMapper)
        private readonly statusEnumMapper: StatusEnumMapper,
        @inject(BeneficiarySummaryMapper)
        private readonly beneficiarySummaryMapper: BeneficiarySummaryMapper,
        @inject(EntitySummaryMapper)
        private readonly entitySummaryMapper: EntitySummaryMapper,
    ) {}

    map(proceedingDto: ProceedingDto): Nullable<ProceedingSummary> {
        const errors = validateSync(proceedingDto);

        if (Boolean(errors.length)) {
            console.error(errors);
            return null;
        }

        const status = this.statusEnumMapper.map(proceedingDto.status);
        const beneficiarySummaryData =
            this.beneficiarySummaryMapper.mapFromBeneficiary(
                proceedingDto.beneficiary_data,
            );

        const entityData = this.entitySummaryMapper.map(
            proceedingDto.entity_data,
        );

        const modified = proceedingDto.modified;

        if (
            status === null ||
            beneficiarySummaryData === null ||
            entityData === null
        )
            return null;

        return new ProceedingSummary(
            proceedingDto.id,
            proceedingDto.project_data.name,
            status,
            proceedingDto.status_label,
            DateTime.fromISO(proceedingDto.created),
            beneficiarySummaryData,
            new ProceedingEntity(entityData.id, entityData.name),
            DateTime.fromISO(modified),
            proceedingDto.derivation_date
                ? DateTime.fromISO(proceedingDto.derivation_date)
                : undefined,
        );
    }
}
