import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeactivateEntityUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}

    async execute(entityId: number): Promise<boolean> {
        const result = await this.entityRepository.deactivate(entityId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
