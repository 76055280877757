import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { BudgetCategoryConcepts } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllCategoryConceptsByCatIdUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(id: number): Promise<BudgetCategoryConcepts> {
        const columnsResult =
            await this.budgetRepository.getCategoryConceptsByCategoryId(id);

        return columnsResult.fold(
            () => new BudgetCategoryConcepts([], 0),
            (columns) => columns,
        );
    }
}
