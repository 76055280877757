import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum BudgetTypeEnumDto {
    CAM = "CAM",
    IRPF = "IRPF",
    GENERAL = "GENERAL",
}
export class BudgetTypeDto {
    @IsEnum(BudgetTypeEnumDto)
    @IsNotEmpty()
    key!: BudgetTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
