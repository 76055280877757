import { PaginatedDto } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { QualityCertificateTypeEnumDto } from "@entity/data/dto/quality-certificate-type.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class QualityCertificateDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsEnum(QualityCertificateTypeEnumDto)
    @IsNotEmpty()
    quality_certificate_type!: QualityCertificateTypeEnumDto;

    @IsNumber()
    @IsNotEmpty()
    year!: number;

    @IsString()
    @IsOptional()
    other_quality_certificate!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    expiration_date!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class QualityCertificatesDto extends PaginatedDto {
    @IsArray()
    @Type(() => QualityCertificateDto)
    results!: QualityCertificateDto[];
}
