import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { BudgetSpreadsheetDto } from "@project/data/dto/budget-spreadsheet.dto";
import { EditBudgetSpreadsheetBody } from "@project/data/dto/edit-budget-spreadsheet.body";
import {
    ProjectFinancialEntitiesDto,
    ProjectFinancialEntitiesQuery,
    ProjectFinancialEntityDto,
} from "@project/data/dto/project-financial-entities.dto";
import { ProjectFinancialEntitiesMapper } from "@project/data/mappers/project-financial-entities.mapper";
import { BudgetSpreadsheetMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-mapper";
import { BudgetSpreadsheet } from "@project/domain/models/budget-spreadsheet.model";
import { EditBudgetSpreadsheet } from "@project/domain/models/edit-budget-spreadsheet.model";
import {
    ProjectFinancialEntities,
    ProjectFinancialEntitiesSearchFilters,
    ProjectFinancialEntity,
} from "@project/domain/models/project-financial-entities.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { ProjectFinancialEntityMapper } from "../mappers/project-financial-entity.mapper";

@injectable()
export class ProjectFinancialEntityDataSource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(BudgetSpreadsheetMapper)
        private readonly budgetSpreadsheetMapper: BudgetSpreadsheetMapper,
        @inject(ProjectFinancialEntitiesMapper)
        private readonly projectFinancialEntitiesMapper: ProjectFinancialEntitiesMapper,
        @inject(ProjectFinancialEntityMapper)
        private readonly projectFinancialEntityMapper: ProjectFinancialEntityMapper,
    ) {}

    async fetchAllBy(
        filters?: ProjectFinancialEntitiesSearchFilters,
    ): Promise<Either<FallbackError, ProjectFinancialEntities>> {
        const query: ProjectFinancialEntitiesQuery = {};

        if (filters?.projectId) {
            query.project = filters.projectId;
        }

        if (filters?.financialEntityId) {
            query.finantial_entity = filters.financialEntityId;
        }

        if (filters?.budgetId) {
            query.budget = filters.budgetId;
        }

        const responseResult = await this.http.get<ProjectFinancialEntityDto>(
            "/projects_financial_entities/",
            {
                query,
            },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.projectFinancialEntitiesMapper.map(
                    plainToClass(ProjectFinancialEntitiesDto, response.data),
                ),
            );
    }

    // MODIFY
    async fetchById(
        projectFinancialEntityId: number,
    ): Promise<Either<FallbackError, ProjectFinancialEntity>> {
        const documentResult = await this.http.get<ProjectFinancialEntityDto>(
            `/projects_financial_entities/${projectFinancialEntityId}/`,
        );

        return documentResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const financialEntity = this.projectFinancialEntityMapper.map(
                    plainToClass(ProjectFinancialEntityDto, response.data),
                );

                if (!financialEntity) return Either.Left(new FallbackError());

                return Either.Right(financialEntity);
            });
    }

    async fetchBudgetSpreadsheetById(
        projectFinancialEntityId: number,
    ): Promise<Either<FallbackError, BudgetSpreadsheet>> {
        const responseResult = await this.http.get<BudgetSpreadsheetDto>(
            `/projects_financial_entities/${projectFinancialEntityId}/get_budget`,
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const budgetSpreadsheet = this.budgetSpreadsheetMapper.map(
                    plainToClass(BudgetSpreadsheetDto, response.data),
                );

                if (!budgetSpreadsheet) return Either.Left(new FallbackError());

                return Either.Right(budgetSpreadsheet);
            });
    }

    async updateBudgetSpreadsheet(
        editedBudgetSpreadsheet: EditBudgetSpreadsheet,
    ): Promise<Either<ValidationError | FallbackError, boolean>> {
        const body = this.budgetSpreadsheetMapper.mapToEditDto(
            editedBudgetSpreadsheet,
        );

        const responseResult = await this.http.patch<
            never,
            EditBudgetSpreadsheetBody
        >("categories_item_value_budgets/massive_update/", body);

        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .map(() => true);
    }

    async exportEntity(entityId: number): Promise<Either<FallbackError, Blob>> {
        const technicalProposalResult = await this.http.get<Blob>(
            `/projects_financial_entities/${entityId}/export_csv/`,
            { responseType: "blob" },
        );

        return technicalProposalResult
            .mapLeft(() => new FallbackError())
            .map((response) => response.data);
    }
    async exportJustification(
        entityId: number,
    ): Promise<Either<FallbackError, Blob>> {
        const technicalProposalResult = await this.http.get<Blob>(
            `/projects_financial_entities/${entityId}/export_csv_justification/`,
            { responseType: "blob" },
        );

        return technicalProposalResult
            .mapLeft(() => new FallbackError())
            .map((response) => response.data);
    }
}
