import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { notification } from "antd";
import { ArgsProps } from "antd/es/notification/interface";
import { observer } from "mobx-react";
import { FC, useEffect } from "react";

const _ToastManager: FC = () => {
    // Autorun here is key
    // 1) Mobx cant track observables inside useEffects by default, you need to be explicit
    // 2) When dealing with reactions only based in observables changes, autorun itself
    // is able to track dependencies automatically, so we can leave useEffect dependencies array empty
    useEffect(() => {
        const sub = ToastManagerStore.toast.subscribe((it) => {
            const messageItem: ArgsProps = {
                message: it.title,
                description: it.description,
            };

            switch (it.type) {
                case "success":
                    notification.success(messageItem);
                    break;
                case "info":
                    notification.info(messageItem);
                    break;
                case "warning":
                    notification.warning(messageItem);
                    break;
                case "error":
                    notification.error(messageItem);
                    break;
                default:
                    break;
            }
        });

        return () => {
            sub.unsubscribe();
        };
    }, []);

    return null;
};

export const ToastManager = observer(_ToastManager);
