import { inject, injectable } from "inversify";
import { ServicesDto } from "@entity/data/dto/services.dto";
import { Services } from "@entity/domain/models/services.model";
import { validateSync } from "class-validator";
import { ServiceMapper } from "./service.mapper";

@injectable()
export class ServicesMapper {
    constructor(
        @inject(ServiceMapper) private readonly serviceMapper: ServiceMapper,
    ) {}
    map(servicesDto: ServicesDto): Services {
        const errors = validateSync(servicesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Services([], 0);
        }

        const services = servicesDto.results.mapNotNull((serviceDto) =>
            this.serviceMapper.map(serviceDto),
        );

        return new Services(services, servicesDto.count);
    }
}
