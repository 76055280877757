import { Nullable } from "@core/domain/types/nullable.type";
import { StatusDto } from "@project/data/dto/status.dto";
import { StatusEnumMapper } from "@project/data/mappers/status-enum.mapper";
import { Status, StatusEnum } from "@project/domain/models/status.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class StatusMapper {
    constructor(
        @inject(StatusEnumMapper)
        private readonly statusEnumMapper: StatusEnumMapper,
    ) {}

    map(statusDto: StatusDto): Nullable<Status> {
        const errors = validateSync(statusDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<StatusEnum> = this.statusEnumMapper.map(
            statusDto.key,
        );

        if (!id) return null;

        return new Status(id, statusDto.value);
    }
}
