export enum IVATypeEnum {
    "Cero" = "0",
    "Five" = "5",
    "Ten" = "10",
    "TwentyOne" = "21",
}

export class IVAType {
    constructor(
        public readonly id: IVATypeEnum,
        public readonly label: string,
    ) {}
}
