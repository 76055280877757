import { Nullable } from "@core/domain/types/nullable.type";
import { ProfessionalGroupEnumDto } from "@entity/data/dto/employee/professional-group.dto";
import { ProfessionalGroupEnum } from "@entity/domain/models/employee/professional-group.model";
import { injectable } from "inversify";

@injectable()
export class ProfessionalGroupEnumMapper {
    map(
        employeeTypeEnumDto: ProfessionalGroupEnumDto,
    ): Nullable<ProfessionalGroupEnum> {
        switch (employeeTypeEnumDto) {
            case ProfessionalGroupEnumDto.ProfessionalGroup0:
                return ProfessionalGroupEnum.ProfessionalGroup0;
            case ProfessionalGroupEnumDto.ProfessionalGroup1:
                return ProfessionalGroupEnum.ProfessionalGroup1;
            case ProfessionalGroupEnumDto.ProfessionalGroup2:
                return ProfessionalGroupEnum.ProfessionalGroup2;
            case ProfessionalGroupEnumDto.ProfessionalGroup3:
                return ProfessionalGroupEnum.ProfessionalGroup3;
            case ProfessionalGroupEnumDto.ProfessionalGroup4:
                return ProfessionalGroupEnum.ProfessionalGroup4;
            case ProfessionalGroupEnumDto.Freelance:
                return ProfessionalGroupEnum.Freelance;
            default:
                return null;
        }
    }

    mapToDto(
        employeeTypeEnum: ProfessionalGroupEnum,
    ): ProfessionalGroupEnumDto {
        // eslint-disable-next-line default-case
        switch (employeeTypeEnum) {
            case ProfessionalGroupEnum.ProfessionalGroup0:
                return ProfessionalGroupEnumDto.ProfessionalGroup0;
            case ProfessionalGroupEnum.ProfessionalGroup1:
                return ProfessionalGroupEnumDto.ProfessionalGroup1;
            case ProfessionalGroupEnum.ProfessionalGroup2:
                return ProfessionalGroupEnumDto.ProfessionalGroup2;
            case ProfessionalGroupEnum.ProfessionalGroup3:
                return ProfessionalGroupEnumDto.ProfessionalGroup3;
            case ProfessionalGroupEnum.ProfessionalGroup4:
                return ProfessionalGroupEnumDto.ProfessionalGroup4;
            case ProfessionalGroupEnum.Freelance:
                return ProfessionalGroupEnumDto.Freelance;
        }
    }
}
