import {
    CreateAccommodationExpense,
    CreateExpense,
    CreateInvestmentExpense,
    CreateMaintenanceExpense,
    CreateManagementExpense,
    CreatePersonnelExpense,
    CreateTravelExpense,
} from "./create-expense.model";
import { Expense } from "./expense.model";

export type EditExpenseAllTypes =
    | EditInvestmentExpense
    | EditMaintenanceExpense
    | EditManagementExpense
    | EditAccommodationExpense
    | EditTravelExpense
    | EditPersonnelExpense;

export class EditExpense extends CreateExpense {
    constructor(
        public readonly id: Expense["id"],
        projectId: CreateExpense["projectId"],
        typeId: CreateExpense["typeId"],
        percentageImputation: CreateExpense["percentageImputation"],
        amountImputation: CreateExpense["amountImputation"],
        paymentDate: CreateExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: CreateExpense["concept"],
    ) {
        super(
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class EditInvestmentExpense extends EditExpense {
    // eslint-disable-next-line max-params
    constructor(
        id: EditExpense["id"],
        projectId: EditExpense["projectId"],
        typeId: EditExpense["typeId"],
        public readonly subtypeExpense: CreateInvestmentExpense["subtypeExpense"],
        public readonly provider: CreateInvestmentExpense["provider"],
        public readonly nifProvider: CreateInvestmentExpense["nifProvider"],
        public readonly description: CreateInvestmentExpense["description"],
        public readonly invoiceNumber: CreateInvestmentExpense["invoiceNumber"],
        public readonly invoiceDate: CreateInvestmentExpense["invoiceDate"],
        public readonly expenseDate: CreateInvestmentExpense["expenseDate"],
        public readonly paymentMethod: CreateInvestmentExpense["paymentMethod"],
        public readonly taxBase: CreateInvestmentExpense["taxBase"],
        public readonly iva: CreateInvestmentExpense["iva"],
        public readonly totalTaxWithIva: CreateInvestmentExpense["totalTaxWithIva"],
        percentageImputation: EditExpense["percentageImputation"],
        amountImputation: EditExpense["amountImputation"],
        paymentDate: EditExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: EditExpense["concept"],
    ) {
        super(
            id,
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class EditMaintenanceExpense extends EditExpense {
    // eslint-disable-next-line max-params
    constructor(
        id: EditExpense["id"],
        projectId: EditExpense["projectId"],
        typeId: EditExpense["typeId"],
        public readonly subtypeExpense: CreateMaintenanceExpense["subtypeExpense"],
        public readonly provider: CreateMaintenanceExpense["provider"],
        public readonly nifProvider: CreateMaintenanceExpense["nifProvider"],
        public readonly description: CreateMaintenanceExpense["description"],
        public readonly invoiceNumber: CreateMaintenanceExpense["invoiceNumber"],
        public readonly invoiceDate: CreateMaintenanceExpense["invoiceDate"],
        public readonly expenseDate: CreateMaintenanceExpense["expenseDate"],
        public readonly paymentMethod: CreateMaintenanceExpense["paymentMethod"],
        public readonly taxBase: CreateMaintenanceExpense["taxBase"],
        public readonly iva: CreateMaintenanceExpense["iva"],
        public readonly totalTaxWithIva: CreateMaintenanceExpense["totalTaxWithIva"],
        percentageImputation: EditExpense["percentageImputation"],
        amountImputation: EditExpense["amountImputation"],
        paymentDate: EditExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: EditExpense["concept"],
    ) {
        super(
            id,
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class EditManagementExpense extends EditExpense {
    // eslint-disable-next-line max-params
    constructor(
        id: EditExpense["id"],
        projectId: EditExpense["projectId"],
        typeId: EditExpense["typeId"],
        public readonly subtypeExpense: CreateManagementExpense["subtypeExpense"],
        public readonly provider: CreateMaintenanceExpense["provider"],
        public readonly nifProvider: CreateMaintenanceExpense["nifProvider"],
        public readonly invoiceNumber: CreateMaintenanceExpense["invoiceNumber"],
        public readonly invoiceDate: CreateMaintenanceExpense["invoiceDate"],
        public readonly expenseDate: CreateMaintenanceExpense["expenseDate"],
        public readonly paymentMethod: CreateMaintenanceExpense["paymentMethod"],
        public readonly taxBase: CreateMaintenanceExpense["taxBase"],
        public readonly iva: CreateMaintenanceExpense["iva"],
        public readonly totalTaxWithIva: CreateMaintenanceExpense["totalTaxWithIva"],
        percentageImputation: EditExpense["percentageImputation"],
        amountImputation: EditExpense["amountImputation"],
        paymentDate: EditExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: EditExpense["concept"],
    ) {
        super(
            id,
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class EditTravelExpense extends EditExpense {
    // eslint-disable-next-line max-params
    constructor(
        id: EditExpense["id"],
        projectId: EditExpense["projectId"],
        typeId: EditExpense["typeId"],
        public readonly dni: CreateTravelExpense["dni"],
        public readonly name: CreateTravelExpense["name"],
        public readonly address: CreateTravelExpense["address"],
        public readonly positionFunction: CreateTravelExpense["positionFunction"],
        public readonly travelDestination: CreateTravelExpense["travelDestination"],
        public readonly dateDeparture: CreateTravelExpense["dateDeparture"],
        public readonly dateReturn: CreateTravelExpense["dateReturn"],
        public readonly transport: CreateTravelExpense["transport"],
        public readonly otherTransport: CreateTravelExpense["otherTransport"],
        public readonly amount: CreateTravelExpense["amount"],
        public readonly kilometers: CreateTravelExpense["kilometers"],
        public readonly costPerKilometer: CreateTravelExpense["costPerKilometer"],
        percentageImputation: EditExpense["percentageImputation"],
        amountImputation: EditExpense["amountImputation"],
        paymentDate: EditExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: EditExpense["concept"],
    ) {
        super(
            id,
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class EditAccommodationExpense extends EditExpense {
    // eslint-disable-next-line max-params
    constructor(
        id: EditExpense["id"],
        projectId: EditExpense["projectId"],
        typeId: EditExpense["typeId"],
        public readonly name: CreateAccommodationExpense["name"],
        public readonly dni: CreateAccommodationExpense["dni"],
        public readonly address: CreateAccommodationExpense["address"],
        public readonly position: CreateAccommodationExpense["position"],
        public readonly travel: CreateAccommodationExpense["travel"],
        public readonly startDate: CreateAccommodationExpense["startDate"],
        public readonly endDate: CreateAccommodationExpense["endDate"],
        public readonly diet: CreateAccommodationExpense["diet"],
        public readonly days: CreateAccommodationExpense["days"],
        public readonly expensePersonDay: CreateAccommodationExpense["expensePersonDay"],
        public readonly totalExpense: CreateAccommodationExpense["totalExpense"],
        percentageImputation: EditExpense["percentageImputation"],
        amountImputation: EditExpense["amountImputation"],
        paymentDate: EditExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: EditExpense["concept"],
    ) {
        super(
            id,
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class EditPersonnelExpense extends EditExpense {
    // eslint-disable-next-line max-params
    constructor(
        id: EditExpense["id"],
        projectId: EditExpense["projectId"],
        typeId: EditExpense["typeId"],
        public readonly name: CreatePersonnelExpense["name"],
        public readonly dni: CreatePersonnelExpense["dni"],
        public readonly contractStartDate: CreatePersonnelExpense["contractStartDate"],
        public readonly contractEndDate: CreatePersonnelExpense["contractEndDate"],
        public readonly contractType: CreatePersonnelExpense["contractType"],
        public readonly weeklyHours: CreatePersonnelExpense["weeklyHours"],
        // public readonly professionalGroupExp: Nullable<ProfEnum>,
        public readonly contributionGroup: CreatePersonnelExpense["contributionGroup"],
        public readonly month: CreatePersonnelExpense["month"],
        public readonly daysImputable: CreatePersonnelExpense["daysImputable"],
        public readonly netSalary: CreatePersonnelExpense["netSalary"],
        public readonly irpf: CreatePersonnelExpense["irpf"],
        public readonly socialSecurity: CreatePersonnelExpense["socialSecurity"],
        public readonly monthlyGrossIncome: CreatePersonnelExpense["monthlyGrossIncome"],
        public readonly companySocialSecurity: CreatePersonnelExpense["companySocialSecurity"],
        public readonly totalCost: CreatePersonnelExpense["totalCost"],
        percentageImputation: EditExpense["percentageImputation"],
        amountImputation: EditExpense["amountImputation"],
        paymentDate: EditExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: EditExpense["concept"],
    ) {
        super(
            id,
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}
