import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { DisabilityTypeEnumDto } from "@entity/data/dto/employee/disability-type.dto";
import { ProfessionalGroupEnumDto } from "@entity/data/dto/employee/professional-group.dto";
import { SubsidyReasonEnumDto } from "@entity/data/dto/employee/subsidy-reason.dto";
import { TerminationReasonEnumDto } from "@entity/data/dto/employee/termination-reason.dto";
import { ContractTypeEnumDto } from "@project/data/dto/expenses/contract-type.dto";
import { ContributionGroupTypeEnumDto } from "@project/data/dto/expenses/contribution-group-type.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class EmployeeContractDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsEnum(ContractTypeEnumDto)
    @IsNotEmpty()
    type!: ContractTypeEnumDto;

    @IsString()
    @IsOptional()
    other_type!: Undefinable<string>;

    @IsDateString()
    @IsNotEmpty()
    start_date!: string;

    @IsDateString()
    @IsOptional()
    end_date!: Nullable<string>;

    @IsBoolean()
    @IsNotEmpty()
    has_disability!: boolean;

    @IsEnum(ProfessionalGroupEnumDto)
    @IsNotEmpty()
    professional_group!: ProfessionalGroupEnumDto;

    @IsEnum(ContributionGroupTypeEnumDto)
    @IsNotEmpty()
    contribution_group!: ContributionGroupTypeEnumDto;

    @IsEnum(TerminationReasonEnumDto)
    @IsOptional()
    termination_reason!: Nullable<TerminationReasonEnumDto>;

    @IsString()
    @IsNotEmpty()
    weekly_workday_hours!: string;

    @IsString()
    @IsNotEmpty()
    net_salary!: string;

    @IsString()
    @IsNotEmpty()
    IRPF!: string;

    @IsString()
    @IsNotEmpty()
    social_security_contribution!: string;

    @IsString()
    @IsNotEmpty()
    monthly_cost!: string;

    @IsString()
    @IsNotEmpty()
    social_security_contribution_company!: string;

    @IsString()
    @IsNotEmpty()
    total_cost!: string;

    @IsString()
    @IsNotEmpty()
    deductible_amount_disability!: string;

    @IsEnum(DisabilityTypeEnumDto)
    @IsOptional()
    disability_type!: Undefinable<DisabilityTypeEnumDto>;

    @IsBoolean()
    @IsNotEmpty()
    is_subsidized!: boolean;

    @IsString()
    @IsOptional()
    subsidy_deductible_amount!: Undefinable<string>;

    @IsEnum(SubsidyReasonEnumDto)
    @IsOptional()
    subsidy_reason!: Undefinable<SubsidyReasonEnumDto>;

    @IsString()
    @IsOptional()
    other_subsidy_reason!: Undefinable<string>;

    @IsString()
    @IsOptional()
    subsidy_scope!: Undefinable<string>;

    @IsNumber()
    @IsNotEmpty()
    employee!: number;
}

export class EmployeeContractsDto extends PaginatedDto {
    @IsArray()
    @Type(() => EmployeeContractDto)
    results!: EmployeeContractDto[];
}

export type EmployeeContractQuery = PaginatedQuery & {
    employee?: Nullish<number>;
};
