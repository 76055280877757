import { DocumentRepository } from "@core/data/repositories/document.repository";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllDocumentsUseCase {
    constructor(
        @inject(DocumentRepository)
        private readonly documentRepository: DocumentRepository,
    ) {}

    async execute(): Promise<IncDocument[]> {
        // For the moment commented pending the backend
        // const documentsResult =
        //     await this.documentRepository.getAll();

        // if (documentsResult.isLeft()) {
        //     ToastManagerStore.error(
        //         documentsResult.getLeftOrThrow().message,
        //     );
        // }

        // return documentsResult.getOrElse([])
        return []; // Remove
    }
}
