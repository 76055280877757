import { DiagnosisTypeEnum } from "@core/domain/models/diagnosis-type.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { DiagnosisTypeEnumDto } from "../dto/diagnosis-type.dto";

@injectable()
export class DiagnosisTypeEnumMapper {
    map(
        diagnosisTypeEnumDto: DiagnosisTypeEnumDto,
    ): Nullable<DiagnosisTypeEnum> {
        switch (diagnosisTypeEnumDto) {
            case DiagnosisTypeEnumDto.Conectividad:
                return DiagnosisTypeEnum.Conectividad;
            case DiagnosisTypeEnumDto.Social:
                return DiagnosisTypeEnum.Social;
            default:
                return null;
        }
    }
    mapToDto(diagnosisTypeEnum: DiagnosisTypeEnum): DiagnosisTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (diagnosisTypeEnum) {
            case DiagnosisTypeEnum.Conectividad:
                return DiagnosisTypeEnumDto.Conectividad;
            case DiagnosisTypeEnum.Social:
                return DiagnosisTypeEnumDto.Social;
        }
    }
}
