import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { AuditoryRepository } from "@entity/data/repositories/auditory.repository";
import { Auditory } from "@entity/domain/models/quality-framework/auditory/auditory.model";
import { CreateAuditory } from "@entity/domain/models/quality-framework/auditory/create-auditory.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateAuditoryUseCase {
    constructor(
        @inject(AuditoryRepository)
        private readonly auditoryRepository: AuditoryRepository,
    ) {}

    async execute(auditory: CreateAuditory): Promise<Nullable<Auditory>> {
        const creationResult = await this.auditoryRepository.create(auditory);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdAuditory) => createdAuditory,
        );
    }
}
