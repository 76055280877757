import { CreateInternalNoteBody } from "@beneficiary/data/dto/internal-notes/create-internal-note.body";
import { CreateInternalNote } from "@beneficiary/domain/models/internal-notes/create-internal-note.model";
import { InternalNoteFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/internal-note/form/internal-note-form";
import { injectable } from "inversify";

@injectable()
export class CreateInternalNoteMapper {
    mapToCreateDto(
        createInternalNote: CreateInternalNote,
    ): CreateInternalNoteBody {
        const createInternalNoteDto: CreateInternalNoteBody = {
            note: createInternalNote.note,
            beneficiary: createInternalNote.beneficiary,
        };

        return createInternalNoteDto;
    }

    mapFromFormValues(
        beneficiaryId: number,
        internalNoteForm: InternalNoteFormValuesValidated,
    ): CreateInternalNote {
        const createInternalNote: CreateInternalNote = new CreateInternalNote(
            internalNoteForm.note,
            beneficiaryId,
        );

        return createInternalNote;
    }
}
