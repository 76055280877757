import { PublicItineraryRepository } from "@beneficiary/data/repositories/public-itinerary.repository";
import { CreatePublicItinerary } from "@beneficiary/domain/models/public-itinerary/create-public-itinerary.model";
import { PublicItinerary } from "@beneficiary/domain/models/public-itinerary/public-itinerary.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class CreatePublicItineraryUseCase {
    constructor(
        @inject(PublicItineraryRepository)
        private readonly publicItineraryRepository: PublicItineraryRepository,
    ) {}

    async execute(
        publicItinerary: CreatePublicItinerary,
    ): Promise<Nullable<PublicItinerary>> {
        const creationResult =
            await this.publicItineraryRepository.create(publicItinerary);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdPublicItinerary) => createdPublicItinerary,
        );
    }
}
