import { DocumentDto } from "@core/data/dto/document.dto";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { EmployeeDto } from "@entity/data/dto/employee/employee.dto";
import { ProjectEmployeeDedicationDto } from "@project/data/dto/project-employee-dedication/project-employee-dedication.dto";
import { Type } from "class-transformer";
import { IsArray, IsNotEmpty, IsNumber, IsOptional } from "class-validator";

export class ProjectEmployeeDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsNumber()
    @IsNotEmpty()
    project!: number;

    @IsNumber()
    @IsNotEmpty()
    employee!: number;

    @IsNumber()
    @IsNotEmpty()
    available_dedication!: number;

    @IsNotEmpty()
    @Type(() => EmployeeDto)
    employee_data!: EmployeeDto;

    @IsOptional()
    @IsArray()
    @Type(() => ProjectEmployeeDedicationDto)
    dedications_data!: Nullable<ProjectEmployeeDedicationDto[]>;

    @IsArray()
    @IsNumber(undefined, { each: true })
    @IsOptional()
    documents!: Nullable<number[]>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    documents_data!: Nullable<DocumentDto[]>;
}

export class ProjectEmployeesDto extends PaginatedDto {
    results!: ProjectEmployeeDto[];
}

export type ProjectEmployeesQuery = PaginatedQuery & {
    project?: number;
    employee?: number;
};
