import i18n from "@i18n";
import { Observable, Subject } from "rxjs";

export type ToastType = "success" | "info" | "warning" | "error";

export interface ToastItem {
    type: ToastType;
    title: string | undefined;
    description: string | undefined;
}

export class ToastManagerStore {
    private static _toastSubject = new Subject<ToastItem>();
    static toast: Observable<ToastItem> = ToastManagerStore._toastSubject;

    static success(
        description?: string,
        title: string = i18n.t("component:toastManager.defaultSuccess"),
    ): void {
        ToastManagerStore._toastSubject.next({
            type: "success",
            title: title,
            description: description,
        });
    }

    static error(
        description: string,
        title: string = i18n.t("component:toastManager.defaultError"),
    ): void {
        ToastManagerStore._toastSubject.next({
            type: "error",
            title: title,
            description: description,
        });
    }

    static info(description: string, title?: string): void {
        ToastManagerStore._toastSubject.next({
            type: "info",
            title: title,
            description: description,
        });
    }

    static warning(description: string, title?: string): void {
        ToastManagerStore._toastSubject.next({
            type: "warning",
            title: title,
            description: description,
        });
    }
}
