import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContractsRepository } from "@entity/data/repositories/contracts.repository";
import { EditVolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/edit-volunteer-contract.model";
import { VolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/volunteer-contract.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditVolunteerContractUseCase {
    constructor(
        @inject(ContractsRepository)
        private readonly contractsRepository: ContractsRepository,
    ) {}

    async execute(
        volunteerContract: EditVolunteerContract,
    ): Promise<Nullable<VolunteerContract>> {
        const editResult =
            await this.contractsRepository.editVolunteerContract(
                volunteerContract,
            );

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedVolunteerContract) => editedVolunteerContract,
        );
    }
}
