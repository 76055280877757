export enum MaritalStatusEnum {
    Single = "single",
    Married = "married",
    CommonLaw = "common_law",
    Separated = "separated",
    Divorced = "divorced",
    Widowed = "widowed",
}

export class MaritalStatus {
    constructor(
        public readonly id: MaritalStatusEnum,
        public readonly label: string,
    ) {}
}
