import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Material } from "@entity/domain/models/material/material.model";
import { GetMaterialByIdUseCase } from "@entity/domain/usecases/material/get-material-by-id.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class DetailMaterialPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    private _material: Undefinable<Material> = undefined;

    constructor(
        @inject(GetMaterialByIdUseCase)
        private readonly getMaterialByIdUseCase: GetMaterialByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    get material(): Undefinable<Material> {
        return this._material;
    }

    async fetchMaterialById(materialId: number): Promise<void> {
        const material = await this.getMaterialByIdUseCase.execute(materialId);
        runInAction(() => {
            this._material = material;
            this.initialLoading = false;
        });
    }
}
