import { useUser } from "@authentication/presentation/hooks/use-user.hook";
import { FC, PropsWithChildren } from "react";
import { useRequireEntityGuard } from "../../../hook/use-entity-modal-guard/use-require-entity-guard.hook";

export interface RequireEntityProps {
    redirectTo: string;
}

export const RequireEntityGuard: FC<PropsWithChildren<RequireEntityProps>> = (
    props,
) => {
    const user = useUser();

    useRequireEntityGuard({
        isOpen: true,
        navigateTo: props.redirectTo,
    });
    return user.impersonatedEntity() || user.isAdmin() || user.isProfessional()
        ? props.children
        : null;
};
