import { PaginatedDto } from "@core/data/dto/paginated.dto";
import { EntityTerritorialScopeEnumDto } from "@entity/data/dto/entity-territorial-scope.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsString,
} from "class-validator";

export class StatutoryTerritorialScopeDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsEnum(EntityTerritorialScopeEnumDto)
    @IsNotEmpty()
    scope!: EntityTerritorialScopeEnumDto;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class StatutoryTerritorialScopesDto extends PaginatedDto {
    @IsArray()
    @Type(() => StatutoryTerritorialScopeDto)
    results!: StatutoryTerritorialScopeDto[];
}
