import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { inject, injectable } from "inversify";
import { EditFinancialEntity } from "../../models/financial-entity/edit-financial-entity.model";

@injectable()
export class EditFinancialEntityUseCase {
    constructor(
        @inject(FinancialEntityRepository)
        private readonly financialEntityRepository: FinancialEntityRepository,
    ) {}

    async execute(financialEntity: EditFinancialEntity): Promise<boolean> {
        const editResult =
            await this.financialEntityRepository.edit(financialEntity);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.isRight();
    }
}
