import { Nullable } from "@core/domain/types/nullable.type";
import { TerritorialScopeEnumDto } from "@project/data/dto/territorial-scope.dto";
import { TerritorialScopeEnum } from "@project/domain/models/territorial-scope.model";
import { injectable } from "inversify";

@injectable()
export class TerritorialScopeEnumMapper {
    map(
        entityTypeEnumDto: TerritorialScopeEnumDto,
    ): Nullable<TerritorialScopeEnum> {
        switch (entityTypeEnumDto) {
            case TerritorialScopeEnumDto.LOCAL:
                return TerritorialScopeEnum.LOCAL;
            case TerritorialScopeEnumDto.REGIONAL:
                return TerritorialScopeEnum.REGIONAL;
            case TerritorialScopeEnumDto.NATIONAL:
                return TerritorialScopeEnum.NATIONAL;
            case TerritorialScopeEnumDto.INTERNATIONAL:
                return TerritorialScopeEnum.INTERNATIONAL;
            default:
                return null;
        }
    }

    mapToDto(entityTypeEnum: TerritorialScopeEnum): TerritorialScopeEnumDto {
        // eslint-disable-next-line default-case
        switch (entityTypeEnum) {
            case TerritorialScopeEnum.LOCAL:
                return TerritorialScopeEnumDto.LOCAL;
            case TerritorialScopeEnum.REGIONAL:
                return TerritorialScopeEnumDto.REGIONAL;
            case TerritorialScopeEnum.NATIONAL:
                return TerritorialScopeEnumDto.NATIONAL;
            case TerritorialScopeEnum.INTERNATIONAL:
                return TerritorialScopeEnumDto.INTERNATIONAL;
        }
    }
}
