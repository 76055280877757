import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { LocalRepository } from "@entity/data/repositories/local.repository";
import { CreateLocal, Local } from "@entity/domain/models/locals/local.model";
import { CreateRoom } from "@entity/domain/models/locals/room.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateLocalUseCase {
    constructor(
        @inject(LocalRepository)
        private readonly localRepository: LocalRepository,
    ) {}

    async execute(local: CreateLocal): Promise<Nullable<Local>> {
        const localResult = await this.localRepository.create(local);

        if (localResult.isLeft()) {
            ToastManagerStore.error(localResult.getLeftOrThrow().message);
            return null;
        }

        const newLocal = localResult.getOrThrow();

        const createRoom: CreateRoom = local.rooms[0];
        createRoom.localId = newLocal.id;

        const roomResult = await this.localRepository.createRoom(createRoom);

        if (roomResult.isLeft()) {
            ToastManagerStore.error(roomResult.getLeftOrThrow().message);
            return null;
        }

        const localBack = await this.localRepository.getById(newLocal.id);

        if (localBack.isLeft()) {
            ToastManagerStore.error(localBack.getLeftOrThrow().message);
            return null;
        }

        return localBack.getOrThrow();
    }
}
