import { AdministrativeRecordScopeEnumDto } from "@entity/data/dto/administrative-record-scope-enum.dto";
import { CreateRegisteredRecordBody } from "@entity/data/dto/registered-record/create-registered-record.body";
import { AdministrativeRecordScopeEnumMapper } from "@entity/data/mappers/administrative-record-scope-enum.mapper";
import { CreateRegisteredRecord } from "@entity/domain/models/registered-record/create-registered-record.model";
import { RegisteredRecordFormValuesValidated } from "@entity/presentation/component/entity-form/general/registered-record/form/registered-record-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreateRegisteredRecordMapper {
    constructor(
        @inject(AdministrativeRecordScopeEnumMapper)
        private readonly administrativeRecordScopeEnumMapper: AdministrativeRecordScopeEnumMapper,
    ) {}

    mapToCreateDto(
        createRegisteredRecord: CreateRegisteredRecord,
    ): CreateRegisteredRecordBody {
        const registeredAdministrativeRecordScope =
            createRegisteredRecord.registeredAdministrativeRecordScope
                ? this.administrativeRecordScopeEnumMapper.mapToDto(
                      createRegisteredRecord.registeredAdministrativeRecordScope,
                  )
                : null;

        const registeredRecordDto: CreateRegisteredRecordBody = {
            registered_administrative_record_scope:
                registeredAdministrativeRecordScope ??
                AdministrativeRecordScopeEnumDto.AutonomousCommunity,
            registered_record_name: createRegisteredRecord.registeredRecordName,
            record_identification_code:
                createRegisteredRecord.recordIdentificationCode,
            entity: createRegisteredRecord.entity,
        };

        return registeredRecordDto;
    }

    mapFromFormValues(
        entityId: number,
        registeredRecord: RegisteredRecordFormValuesValidated,
    ): CreateRegisteredRecord {
        const createRegisteredRecord: CreateRegisteredRecord =
            new CreateRegisteredRecord(
                registeredRecord.registeredAdministrativeRecordScope,
                registeredRecord.registeredRecordName,
                registeredRecord.recordIdentificationCode,
                entityId,
            );

        return createRegisteredRecord;
    }
}
