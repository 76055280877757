import { Pagination } from "@core/domain/models/pagination";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import {
    BudgetsSearchFilters,
    BudgetsSummary,
} from "@project/domain/models/budget-summary.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllBudgetsUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: BudgetsSearchFilters,
    ): Promise<BudgetsSummary> {
        const budgetsResult = await this.budgetRepository.fetchAllBy(
            pagination,
            filters,
        );

        return budgetsResult.fold(
            () => new BudgetsSummary([], 0),
            (budgetSummary) => budgetSummary,
        );
    }
}
