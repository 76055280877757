import { Addresses } from "@beneficiary/domain/models/address.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { AddressesDto } from "../../dto/address.dto";
import { AddressMapper } from "./address.mapper";

@injectable()
export class AddressesMapper {
    constructor(
        @inject(AddressMapper)
        private readonly addressMapper: AddressMapper,
    ) {}

    map(addressesDto: AddressesDto): Addresses {
        const errors = validateSync(addressesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Addresses([], 0);
        }

        const addresses = addressesDto.results.mapNotNull((addressDto) =>
            this.addressMapper.map(addressDto),
        );

        return new Addresses(addresses, addressesDto.count);
    }
}
