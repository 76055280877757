import {
    ExternalDerivationDto,
    ExternalDerivationsDto,
} from "@beneficiary/data/dto/external-derivation/external-derivation.dto";
import { CenterTypeEnumMapper } from "@beneficiary/data/mappers/center-type-enum.mapper";
import { ExternalDerivationMapper } from "@beneficiary/data/mappers/external-derivation/external-derivation.mapper";
import {
    ExternalDerivation,
    ExternalDerivations,
} from "@beneficiary/domain/models/external-derivation/external-derivation.model";
import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ExternalDerivationsMapper {
    constructor(
        @inject(ExternalDerivationMapper)
        private readonly externalDerivationMapper: ExternalDerivationMapper,
        @inject(CenterTypeEnumMapper)
        private readonly centerTypeEnumMapper: CenterTypeEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
    ) {}

    map(externalDerivationsDto: ExternalDerivationsDto): ExternalDerivations {
        const errors = validateSync(externalDerivationsDto);

        if (errors.length > 0) {
            console.error(errors);

            return new ExternalDerivations([], 0);
        }

        const externalDerivations = externalDerivationsDto.results.mapNotNull(
            (externalDerivationDto) =>
                this.externalDerivationMapper.map(externalDerivationDto),
        );

        return new ExternalDerivations(
            externalDerivations,
            externalDerivationsDto.count,
        );
    }

    mapExternalDerivation(
        externalDerivationDto: ExternalDerivationDto,
    ): Nullable<ExternalDerivation> {
        const errors = validateSync(externalDerivationDto);

        if (errors.length > 0) {
            console.error(errors);
            return null;
        }

        let documents: IncDocument[] = [];
        if (externalDerivationDto.documents_data) {
            documents = externalDerivationDto.documents_data.mapNotNull(
                (document) => this.documentMapper.map(document),
            );
        }

        const centerType = this.centerTypeEnumMapper.map(
            externalDerivationDto.center_type,
        );
        if (!centerType) return null;

        return new ExternalDerivation(
            externalDerivationDto.id,
            externalDerivationDto.reason,
            centerType,
            externalDerivationDto.center_name,
            externalDerivationDto.is_derivate_to_external ?? false,
            externalDerivationDto.beneficiary,
            externalDerivationDto.contact_name ?? undefined,
            externalDerivationDto.contact_phone ?? undefined,
            externalDerivationDto.contact_email ?? undefined,
            documents,
        );
    }
}
