import { AuthenticatedUser } from "@authentication/domain/models/authenticated-user.model";
import { useUser } from "@authentication/presentation/hooks/use-user.hook";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { HomeRoutes } from "@routes/private/home.routes";
import { UserRoleEnum } from "@user/domain/models/user-role.model";
import { FC, PropsWithChildren, useMemo } from "react";
import { Navigate } from "react-router-dom";

export interface RequireRoleProps {
    rolesIn: UserRoleEnum[];
    redirectTo?: string | ((user: AuthenticatedUser) => Undefinable<string>);
}

export const RequireRoleGuard: FC<PropsWithChildren<RequireRoleProps>> = (
    props,
) => {
    const user = useUser();

    const redirectRoute = useMemo(() => {
        const defaultRedirectRoute = HomeRoutes.HOME;
        if (!props.redirectTo) return defaultRedirectRoute;

        if (typeof props.redirectTo === "string") return props.redirectTo;

        const redirectTo = props.redirectTo(user);

        return redirectTo ?? defaultRedirectRoute;
    }, [props.redirectTo, user]);

    const userWithValidRole = useMemo(
        () => props.rolesIn.includes(user.rol),
        [props.rolesIn, user.rol],
    );

    return userWithValidRole ? (
        props.children
    ) : (
        <Navigate
            to={redirectRoute}
            replace
        />
    );
};
