import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { ProjectActivityTaskRepository } from "../../../data/repositories/project-activity-task.repository";
import { ProjectActivityTask } from "../../models/project-activity-task.model";

@injectable()
export class UpdateActivityTaskUseCase {
    constructor(
        @inject(ProjectActivityTaskRepository)
        private readonly projectActivityTaskRepository: ProjectActivityTaskRepository,
    ) {}

    async update(
        activityTask: ProjectActivityTask,
    ): Promise<Nullable<ProjectActivityTask>> {
        const creationResult =
            await this.projectActivityTaskRepository.update(activityTask);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (updatedActivityTask) => updatedActivityTask,
        );
    }
}
