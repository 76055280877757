import { EditStatutoryTerritorialScopeBody } from "@entity/data/dto/statutory-territorial-scope/edit-statutory-territorial-scope.body";
import { EntityTerritorialScopeEnumMapper } from "@entity/data/mappers/entity-territorial-scope-enum.mapper";
import { EditStatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/edit-statutory-territorial-scope.model";
import { StatutoryTerritorialScopeFormValuesValidated } from "@entity/presentation/component/entity-form/general/statutory-territorial-scope/form/statutory-territorial-scope-form";
import { inject, injectable } from "inversify";

@injectable()
export class EditStatutoryTerritorialScopeMapper {
    constructor(
        @inject(EntityTerritorialScopeEnumMapper)
        private readonly entityTerritorialScopeEnumMapper: EntityTerritorialScopeEnumMapper,
    ) {}
    mapToDto(
        editStatutoryTerritorialScope: EditStatutoryTerritorialScope,
    ): EditStatutoryTerritorialScopeBody {
        const editStatutoryTerritorialScopeBody: EditStatutoryTerritorialScopeBody =
            {
                id: editStatutoryTerritorialScope.id,
                name: editStatutoryTerritorialScope.name,
                scope: this.entityTerritorialScopeEnumMapper.mapToDto(
                    editStatutoryTerritorialScope.scope,
                ),
                entity: editStatutoryTerritorialScope.entity,
            };
        return editStatutoryTerritorialScopeBody;
    }

    mapFromModal(
        id: number,
        entityId: number,
        statutoryTerritorialScope: StatutoryTerritorialScopeFormValuesValidated,
    ): EditStatutoryTerritorialScope {
        const editStatutoryTerritorialScope: EditStatutoryTerritorialScope =
            new EditStatutoryTerritorialScope(
                id,
                statutoryTerritorialScope.name,
                statutoryTerritorialScope.scope,
                entityId,
            );
        return editStatutoryTerritorialScope;
    }
}
