import { CreateRelative } from "./create-relative.model";
import { Relative } from "./relative.model";

export class EditRelative extends CreateRelative {
    constructor(
        public readonly id: Relative["id"],
        relationship: CreateRelative["relationship"],
        birthdate: CreateRelative["birthdate"],
        documentType: CreateRelative["documentType"],
        educationLevel: CreateRelative["educationLevel"],
        employmentStatus?: CreateRelative["employmentStatus"],
        documentId?: CreateRelative["documentId"],
        courseCenter?: CreateRelative["courseCenter"],
        tutorPhone?: CreateRelative["tutorPhone"],
        tutorEmail?: CreateRelative["tutorEmail"],
        netSalary?: CreateRelative["netSalary"],
        otherRelationship?: CreateRelative["otherRelationship"],
    ) {
        super(
            relationship,
            birthdate,
            documentType,
            educationLevel,
            employmentStatus,
            documentId,
            courseCenter,
            tutorPhone,
            tutorEmail,
            netSalary,
            otherRelationship,
        );
    }
}
