import { IsEnum, IsNotEmpty, IsString } from "class-validator";
import { StatusEnumDto } from "./status-enum.dto";

export class StatusDto {
    @IsEnum(StatusEnumDto)
    @IsNotEmpty()
    key!: StatusEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
