export enum MaterialSubtypeEnum {
    Personal = "personal",
    Professional = "professional",
    Volunteering = "volunteering",
    Locals = "locals",
    Supplies = "supplies",
    Phone = "phone",
    Furniture = "furniture",
    FungibleMaterial = "fungible_material",
    SanitaryMaterial = "sanitary_material",
    Insurance = "insurance",
    Vehicles = "vehicles",
    ManagementProcedures = "management_procedures",
    ISOS = "ISOS",
    Quality = "quality",
    Protocols = "protocols",
    Plans = "plans",
    ComputerMaterial = "computer_material",
    ComputerSystems = "computer_systems",
    LicensesSoftware = "licenses_software",
    Platforms = "platforms",
    SocialNetworks = "social_networks",
    Newsletter = "newsletter",
    Others = "others",
}

export class MaterialSubtype {
    constructor(
        public readonly id: MaterialSubtypeEnum,
        public readonly label: string,
    ) {}
}
