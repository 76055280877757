import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { TransportTypeEnum } from "../../../domain/models/expenses/transport-type.model";
import { TransportTypeEnumDto } from "../../dto/expenses/transport-type.dto";

@injectable()
export class TransportTypeEnumMapper {
    map(centerTypeEnumDto: TransportTypeEnumDto): Nullable<TransportTypeEnum> {
        switch (centerTypeEnumDto) {
            case TransportTypeEnumDto.BUS:
                return TransportTypeEnum.BUS;
            case TransportTypeEnumDto.CAR:
                return TransportTypeEnum.CAR;
            case TransportTypeEnumDto.OTHER:
                return TransportTypeEnum.OTHER;
            case TransportTypeEnumDto.PLANE:
                return TransportTypeEnum.PLANE;
            case TransportTypeEnumDto.TAXI:
                return TransportTypeEnum.TAXI;
            case TransportTypeEnumDto.METRO:
                return TransportTypeEnum.METRO;
            default:
                return null;
        }
    }

    mapToDto(centerTypeEnum: TransportTypeEnum): TransportTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (centerTypeEnum) {
            case TransportTypeEnum.BUS:
                return TransportTypeEnumDto.BUS;
            case TransportTypeEnum.CAR:
                return TransportTypeEnumDto.CAR;
            case TransportTypeEnum.NONE:
                return TransportTypeEnumDto.NONE;
            case TransportTypeEnum.OTHER:
                return TransportTypeEnumDto.OTHER;
            case TransportTypeEnum.PLANE:
                return TransportTypeEnumDto.PLANE;
            case TransportTypeEnum.TAXI:
                return TransportTypeEnumDto.TAXI;
            case TransportTypeEnum.METRO:
                return TransportTypeEnumDto.METRO;
        }
    }
}
