import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { DepositRepository } from "@entity/data/repositories/deposit.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteSplitDepositUseCase {
    constructor(
        @inject(DepositRepository)
        private readonly depositRepository: DepositRepository,
    ) {}

    async execute(depositId: number): Promise<void> {
        const result =
            await this.depositRepository.deleteSplitDeposit(depositId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }
    }
}
