import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { EditProceeding } from "@proceeding/domain/models/edit-proceeding.model";
import { Proceeding } from "@proceeding/domain/models/proceeding.model";
import { inject, injectable } from "inversify";
import { ProceedingRepository } from "../../data/repositories/proceeding.repository";

@injectable()
export class EditProceedingUseCase {
    constructor(
        @inject(ProceedingRepository)
        private readonly proceedingRepository: ProceedingRepository,
    ) {}

    async execute(proceeding: EditProceeding): Promise<Nullable<Proceeding>> {
        const editResult = await this.proceedingRepository.update(proceeding);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedProceeding) => editedProceeding,
        );
    }
}
