import type { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectExpenseRepository } from "@project/data/repositories/project-expense.repository";
import { EditExpenseAllTypes } from "@project/domain/models/expenses/edit-expense.model";
import { ExpenseAllTypes } from "@project/domain/models/expenses/expense.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditExpenseUseCase {
    constructor(
        @inject(ProjectExpenseRepository)
        private readonly projectExpenseRepository: ProjectExpenseRepository,
    ) {}

    async execute(
        expense: Nullable<EditExpenseAllTypes>,
    ): Promise<Nullable<ExpenseAllTypes>> {
        if (expense === null) {
            return null;
        }

        const expenseEdited = await this.projectExpenseRepository.edit(expense);
        if (expenseEdited.isLeft()) {
            ToastManagerStore.error(expenseEdited.getLeftOrThrow().message);
        } else {
            ToastManagerStore.success();
        }

        return expenseEdited.fold(
            () => null,
            (editedExpense: Nullable<ExpenseAllTypes>) => editedExpense,
        );
    }
}
