export enum PositionTypeEnum {
    PRESIDENT = "president",
    VICEPRESIDENT = "vicepresident",
    SECRETARY = "secretary",
    TREASURER = "treasurer",
    VOCAL = "vocal",
    OTHER = "other",
}

export class PositionType {
    constructor(
        public readonly id: PositionTypeEnum,
        public readonly label: string,
    ) {}
}
