import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateEmployeeMapper } from "@entity/data/mappers/employee/create-employee.mapper";
import { Employee } from "@entity/domain/models/employee/employee.model";
import { CreateEmployeeUseCase } from "@entity/domain/usecases/employee/create-employee.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable } from "mobx";
import { CreateEmployeeFormValuesValidated } from "../../../component/employee-form/employee-form.component";

@injectable()
export class CreateEmployeePageViewModel extends BaseViewModel {
    @observable
    employee: Undefinable<Employee> = undefined;

    constructor(
        @inject(CreateEmployeeUseCase)
        private readonly createEmployeeUseCase: CreateEmployeeUseCase,
        @inject(CreateEmployeeMapper)
        private readonly createEmployeeMapper: CreateEmployeeMapper,
        @inject(UserSessionRepository)
        private readonly userSessionRepository: UserSessionRepository,
    ) {
        super();
        makeObservable(this);
    }

    async createEmployee(
        values: CreateEmployeeFormValuesValidated,
    ): Promise<Nullable<Employee>> {
        const entityId = this.userSessionRepository.userSyncSafe.entityId;

        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createEmployeeResult = await this.createEmployeeUseCase.execute(
            this.createEmployeeMapper.mapFromEmployeeFormValues(
                entityId,
                values,
            ),
        );

        if (createEmployeeResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createEmployeeResult;
    }
}
