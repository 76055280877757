import { BudgetCategoriesDto } from "@project/data/dto/budget.dto";
import { BudgetCategoryMapper } from "@project/data/mappers/budget/categories/budget-category.mapper";
import { BudgetCategories } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetCategoriesMapper {
    constructor(
        @inject(BudgetCategoryMapper)
        private readonly budgetCategoryMapper: BudgetCategoryMapper,
    ) {}
    map(budgetCategoriesDto: BudgetCategoriesDto): BudgetCategories {
        const errors = validateSync(budgetCategoriesDto);
        if (Boolean(errors.length)) {
            console.error(errors);
            return new BudgetCategories([], 0);
        }

        const budgetCategories = budgetCategoriesDto.results.mapNotNull(
            (categoryBudgetDto) =>
                this.budgetCategoryMapper.map(categoryBudgetDto),
        );
        return new BudgetCategories(
            budgetCategories,
            budgetCategoriesDto.count,
        );
    }
}
