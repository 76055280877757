import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { StatutoryTerritorialScopeDatasource } from "@entity/data/datasources/statutory-territorial-scope.datasource";
import { CreateStatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/create-statutory-territorial-scope.model";
import { EditStatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/edit-statutory-territorial-scope.model";
import {
    StatutoryTerritorialScope,
    StatutoryTerritorialScopes,
} from "@entity/domain/models/statutory-territorial-scope/statutory-territorial-scope.model";
import { inject, injectable } from "inversify";

@injectable()
export class StatutoryTerritorialScopeRepository {
    constructor(
        @inject(StatutoryTerritorialScopeDatasource)
        private readonly statutoryTerritorialScopeDatasource: StatutoryTerritorialScopeDatasource,
    ) {}

    async getAll(
        pagination: Pagination,
    ): Promise<Either<FallbackError, StatutoryTerritorialScopes>> {
        const statutoryTerritorialScopeResult =
            await this.statutoryTerritorialScopeDatasource.fetchAll(pagination);

        return statutoryTerritorialScopeResult.mapLeft(
            () => new FallbackError(),
        );
    }

    async create(
        statutoryTerritorialScope: CreateStatutoryTerritorialScope,
    ): Promise<
        Either<ValidationError | FallbackError, StatutoryTerritorialScope>
    > {
        return this.statutoryTerritorialScopeDatasource.create(
            statutoryTerritorialScope,
        );
    }

    async edit(
        editStatutoryTerritorialScope: EditStatutoryTerritorialScope,
    ): Promise<
        Either<ValidationError | FallbackError, StatutoryTerritorialScope>
    > {
        return this.statutoryTerritorialScopeDatasource.edit(
            editStatutoryTerritorialScope,
        );
    }

    async delete(
        statutoryTerritorialScopeId: number,
    ): Promise<Either<FallbackError, true>> {
        const statutoryTerritorialScopeResult =
            await this.statutoryTerritorialScopeDatasource.delete(
                statutoryTerritorialScopeId,
            );
        return statutoryTerritorialScopeResult.mapLeft(
            () => new FallbackError(),
        );
    }
}
