import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import {
    GlobalActivity,
    GlobalActivityFilters,
} from "@project/domain/models/global-activity.model";
import { injectable } from "inversify";
import { GlobalActivityDatasource } from "../datasource/global-activity.datasource";

@injectable()
export class GlobalActivityRepository {
    constructor(
        private readonly globalActivityDatasource: GlobalActivityDatasource,
    ) {}

    async getAllBy(
        filters?: GlobalActivityFilters,
    ): Promise<Either<FallbackError, GlobalActivity[]>> {
        return this.globalActivityDatasource.fetchAllBy(filters);
    }
}
