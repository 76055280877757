import { IncDocument } from "@core/domain/models/inc-document.model";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { SupplierIdentificationTypeEnum } from "@entity/domain/models/supplier-identification-type.model";
import { ServiceProvidedEnum } from "@entity/domain/models/supplier/service-provided.model";
import { DateTime } from "luxon";
import { SupplierTypeEnum } from "./supplier-type.model";

export class Supplier {
    public contractStartDate?: Undefinable<DateTime>;
    public contractEndDate?: Undefinable<DateTime>;
    public serviceProvided?: Undefinable<ServiceProvidedEnum[]>;
    public otherServiceProvided?: Undefinable<string>;
    public serviceProvidedDescription?: Undefinable<string>;
    public qualityCertifications?: Undefinable<boolean>;
    public qualityCertificationsDate?: Undefinable<DateTime>;
    public serviceEvaluation?: Undefinable<boolean>;
    public serviceEvaluationDate?: Undefinable<DateTime>;
    public serviceEvaluationDetail?: Undefinable<string>;
    public active?: Undefinable<boolean>;
    public documents?: IncDocument[];

    // eslint-disable-next-line max-params
    constructor(
        public readonly id: number,
        public readonly entity: number,
        public readonly companyName: string,
        public readonly contactPerson: string,
        public readonly responsableEmail: string,
        public readonly phone1: string,
        public readonly type: SupplierTypeEnum,
        public readonly identificationType: SupplierIdentificationTypeEnum,
        public readonly identification: string,
        public readonly street: string,
        public readonly zipCode: string,
        public readonly town: string,
        public readonly province: string,
    ) {}
}
