import { Nullable } from "@core/domain/types/nullable.type";
import { SupplierIdentificationTypeEnumDto } from "@entity/data/dto/supplier/supplier-identification-type.dto";
import { SupplierIdentificationTypeEnum } from "@entity/domain/models/supplier-identification-type.model";
import { injectable } from "inversify";

@injectable()
export class SupplierIdentificationEnumMapper {
    map(
        supplierIdentificationTypeEnumDto: SupplierIdentificationTypeEnumDto,
    ): Nullable<SupplierIdentificationTypeEnum> {
        switch (supplierIdentificationTypeEnumDto) {
            case SupplierIdentificationTypeEnumDto.cif:
                return SupplierIdentificationTypeEnum.cif;
            case SupplierIdentificationTypeEnumDto.nif:
                return SupplierIdentificationTypeEnum.nif;
            default:
                return null;
        }
    }

    mapToDto(
        supplierIdentificationTypeEnum: SupplierIdentificationTypeEnum,
    ): SupplierIdentificationTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (supplierIdentificationTypeEnum) {
            case SupplierIdentificationTypeEnum.cif:
                return SupplierIdentificationTypeEnumDto.cif;
            case SupplierIdentificationTypeEnum.nif:
                return SupplierIdentificationTypeEnumDto.nif;
        }
    }
}
