import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import {
    IsArray,
    IsDateString,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

import type { Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";

export class RoomDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsDateString()
    @IsOptional()
    created!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    modified!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsNumber()
    @IsNotEmpty()
    positions!: number;

    @IsNumber()
    @IsNotEmpty()
    local!: number;
}

export class RoomsDto extends PaginatedDto {
    @IsArray()
    @Type(() => RoomDto)
    results!: RoomDto[];
}

export type RoomsQuery = PaginatedQuery & {
    local?: number;
};
