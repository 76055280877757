import { EditEmployeeContractBody } from "@entity/data/dto/employee/contracts/employee/edit-employee-contract.body";
import { DisabilityTypeEnumMapper } from "@entity/data/mappers/employee/disability-type-enum.mapper";
import { ProfessionalGroupEnumMapper } from "@entity/data/mappers/employee/professional-group-enum.mapper";
import { SubsidyReasonEnumMapper } from "@entity/data/mappers/employee/subsidy-reason-enum.mapper";
import { TerminationReasonEnumMapper } from "@entity/data/mappers/employee/termination-reason-enum.mapper";
import { EditEmployeeContract } from "@entity/domain/models/employee/contracts/employee/edit-employee-contract.model";
import { EmployeeContract } from "@entity/domain/models/employee/contracts/employee/employee-contract.model";
import { EmployeeContractFormValuesValidated } from "@entity/presentation/component/employee-form/contract/employee/form/employee-contract-form";
import { ContractTypeEnumMapper } from "@project/data/mappers/expenses/contract-type-enum.mapper";
import { ContributionGroupTypeEnumMapper } from "@project/data/mappers/expenses/contribution-group-type-enum.mapper";
import { inject, injectable } from "inversify";

@injectable()
export class EditEmployeeContractMapper {
    constructor(
        @inject(ContributionGroupTypeEnumMapper)
        private readonly contributionGroupTypeEnumMapper: ContributionGroupTypeEnumMapper,
        @inject(ContractTypeEnumMapper)
        private readonly contractTypeEnumMapper: ContractTypeEnumMapper,
        @inject(DisabilityTypeEnumMapper)
        private readonly disabilityTypeEnumMapper: DisabilityTypeEnumMapper,
        @inject(ProfessionalGroupEnumMapper)
        private readonly professionalGroupEnumMapper: ProfessionalGroupEnumMapper,
        @inject(TerminationReasonEnumMapper)
        private readonly terminationReasonEnumMapper: TerminationReasonEnumMapper,
        @inject(SubsidyReasonEnumMapper)
        private readonly subsidyReasonEnumMapper: SubsidyReasonEnumMapper,
    ) {}

    mapToDto(employeeContract: EmployeeContract): EditEmployeeContractBody {
        const contractType = this.contractTypeEnumMapper.mapToDto(
            employeeContract.type,
        );

        const professionalGroup = this.professionalGroupEnumMapper.mapToDto(
            employeeContract.professionalGroup,
        );

        const contributionGroup = this.contributionGroupTypeEnumMapper.mapToDto(
            employeeContract.contributionGroup,
        );

        let disabilityType = null;
        if (employeeContract.disabilityType) {
            disabilityType = this.disabilityTypeEnumMapper.mapToDto(
                employeeContract.disabilityType,
            );
        }

        let terminationReason = null;
        if (employeeContract.terminationReason) {
            terminationReason = this.terminationReasonEnumMapper.mapToDto(
                employeeContract.terminationReason,
            );
        }

        let subsidyReason = null;
        if (employeeContract.subsidyReason) {
            subsidyReason = this.subsidyReasonEnumMapper.mapToDto(
                employeeContract.subsidyReason,
            );
        }

        return {
            id: employeeContract.id,
            type: contractType,
            start_date: employeeContract.startDate.toISODate() ?? "",
            has_disability: employeeContract.hasDisability,
            professional_group: professionalGroup,
            contribution_group: contributionGroup,
            weekly_workday_hours:
                employeeContract.weeklyWorkdayHours.toString(),
            net_salary: employeeContract.netSalary.toString(),
            IRPF: employeeContract.irpf.toString(),
            social_security_contribution:
                employeeContract.socialSecurityContribution.toString(),
            monthly_cost: employeeContract.monthlyCost.toString(),
            social_security_contribution_company:
                employeeContract.socialSecurityContributionCompany.toString(),
            total_cost: employeeContract.totalCost.toString(),
            deductible_amount_disability:
                employeeContract.deductibleAmountDisability.toString(),
            employee: employeeContract.employeeId,
            termination_reason: terminationReason,
            other_type: employeeContract.otherType ?? undefined,
            is_subsidized: employeeContract.isSubsidized ?? false,
            subsidy_deductible_amount:
                employeeContract.subsidyDeductibleAmount?.toString() ??
                undefined,
            subsidy_reason: subsidyReason ?? undefined,
            other_subsidy_reason:
                employeeContract.otherSubsidyReason ?? undefined,
            subsidy_scope: employeeContract.subsidyScope ?? undefined,
            disability_type: disabilityType ?? undefined,
            end_date: employeeContract.endDate?.toISODate() ?? null,
        };
    }

    mapFromEmployeeContractFormValues(
        contractId: number,
        employeeId: number,
        employeeContractForm: EmployeeContractFormValuesValidated,
    ): EditEmployeeContract {
        if (!contractId) throw Error("Missing income contractId");
        if (!employeeId) throw Error("Missing employeeId");

        const editEmployeeContract = new EditEmployeeContract(
            contractId,
            employeeContractForm.type,
            employeeContractForm.startDate,
            employeeContractForm.hasDisability,
            employeeContractForm.professionalGroup,
            employeeContractForm.contributionGroup,
            employeeContractForm.weeklyWorkdayHours,
            employeeContractForm.netSalary,
            employeeContractForm.irpf,
            employeeContractForm.socialSecurityContribution,
            employeeContractForm.monthlyCost,
            employeeContractForm.socialSecurityContributionCompany,
            employeeContractForm.totalCost,
            employeeContractForm.deductibleAmountDisability,
            employeeId,
            employeeContractForm.disabilityType ?? undefined,
            employeeContractForm.terminationReason ?? undefined,
            employeeContractForm.otherType ?? undefined,
            employeeContractForm.isSubsidized,
            employeeContractForm.subsidyDeductibleAmount ?? undefined,
            employeeContractForm.subsidyReason ?? undefined,
            employeeContractForm.otherSubsidyReason ?? undefined,
            employeeContractForm.subsidyScope ?? undefined,
            employeeContractForm.endDate ?? undefined,
        );

        return editEmployeeContract;
    }
}
