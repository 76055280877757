import { inject, injectable } from "inversify";
import { ProceedingRepository } from "../../data/repositories/proceeding.repository";
import { ProceedingsSummary } from "../models/proceedings-sumary.model";

@injectable()
export class GetRecentModifiedDerivationsUseCase {
    constructor(
        @inject(ProceedingRepository)
        private readonly proceedingRepository: ProceedingRepository,
    ) {}

    async execute(): Promise<ProceedingsSummary> {
        const derivationsResults =
            await this.proceedingRepository.getRecentModifiedDerivatedProceedings();

        return derivationsResults.fold(
            () => new ProceedingsSummary([], 0),
            (proceedingsSummary) => proceedingsSummary,
        );
    }
}
