import { IdentificationType } from "@core/domain/models/identification-document-type.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { IdentificationDocumentTypeDto } from "../dto/identification-document-type.dto";
import { IdentificationDocumentTypeEnumMapper } from "./identification-document-type-enum.mapper";

@injectable()
export class IdentificationDocumentTypeMapper {
    constructor(
        @inject(IdentificationDocumentTypeEnumMapper)
        private readonly identificationTypeEnumMapper: IdentificationDocumentTypeEnumMapper,
    ) {}
    map(
        identificationTypeDto: IdentificationDocumentTypeDto,
    ): Nullable<IdentificationType> {
        const errors = validateSync(identificationTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.identificationTypeEnumMapper.map(
            identificationTypeDto.key,
        );
        if (!id) return null;

        return new IdentificationType(id, identificationTypeDto.value);
    }
}
