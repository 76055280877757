import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { PublicInterestRepository } from "@entity/data/repositories/public-interest.repository";
import { EditPublicInterest } from "@entity/domain/models/public-interest/edit-public-interest.model";
import { PublicInterest } from "@entity/domain/models/public-interest/public-interest.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditPublicInterestUseCase {
    constructor(
        @inject(PublicInterestRepository)
        private readonly publicInterestRepository: PublicInterestRepository,
    ) {}

    async execute(
        publicInterest: EditPublicInterest,
    ): Promise<Nullable<PublicInterest>> {
        const editResult =
            await this.publicInterestRepository.edit(publicInterest);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedPublicInterest) => editedPublicInterest,
        );
    }
}
