import { GenderEnumDto } from "@beneficiary/data/dto/gender.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import {
    IsEmail,
    IsEnum,
    IsNotEmpty,
    IsOptional,
    IsString,
} from "class-validator";

export class VolunteerInfoDto {
    @IsEnum(GenderEnumDto)
    @IsNotEmpty()
    gender!: GenderEnumDto;

    @IsString()
    @IsNotEmpty()
    address!: string;

    @IsString()
    @IsNotEmpty()
    street_number!: string;

    @IsString()
    @IsOptional()
    floor!: Nullable<string>;

    @IsString()
    @IsOptional()
    door!: Nullable<string>;

    @IsString()
    @IsOptional()
    stair!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    city!: string;

    @IsString()
    @IsOptional()
    municipality!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    postal_code!: string;

    @IsString()
    @IsNotEmpty()
    mobile_phone!: string;

    @IsEmail()
    @IsOptional()
    email!: Nullable<string>;
}
