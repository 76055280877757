import { CreateSocialNetwork } from "@entity/domain/models/create-social-network.model";
import { SocialNetworkFormValuesValidated } from "@entity/presentation/component/entity-form/social-networks/form/social-network-form";
import { injectable } from "inversify";
import { CreateSocialNetworkBody } from "../../dto/social-network/create-social-network.body";

@injectable()
export class CreateSocialNetworkMapper {
    mapToDto(
        createSocialNetwork: CreateSocialNetwork,
    ): CreateSocialNetworkBody {
        const createSocialNetworkBody: CreateSocialNetworkBody = {
            entity: createSocialNetwork.entityId,
            name: createSocialNetwork.name,
            link: createSocialNetwork.link,
            followers: createSocialNetwork.followers ?? undefined,
            num_posts: createSocialNetwork.postCount ?? undefined,
        };

        return createSocialNetworkBody;
    }

    mapFromFormValues(
        entityId: number,
        socialNetworkForm: SocialNetworkFormValuesValidated,
    ): CreateSocialNetwork {
        const createSocialNetwork: CreateSocialNetwork =
            new CreateSocialNetwork(
                entityId,
                socialNetworkForm.name,
                socialNetworkForm.url,
                socialNetworkForm.followersCount,
                socialNetworkForm.postCount,
            );

        return createSocialNetwork;
    }
}
