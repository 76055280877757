export type Undefinable<T> = T | undefined;
export type Defined<T> = Exclude<T, undefined>;

export const isDefined = <T>(value: Undefinable<T>): value is T =>
    value !== undefined;
export const isUndefined = <T>(value: Undefinable<T>): value is undefined =>
    value === undefined;

export const convertValueField = <T extends string | number | undefined | null>(
    field: T,
): T | undefined | null => {
    if (field === undefined) return undefined;
    if (!field) return null;
    return field;
};

// This helper will transform from "undefinable" values to undefined, otherwise
// the original value will be returned
export const convertEmptyToUndefined = <
    T extends
        | string
        | number
        | undefined
        | null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        | any[]
        | Record<string, unknown>
        | { [P in keyof T]: T[P] },
>(
    value: T,
): Undefinable<Exclude<T, null>> => {
    if (value === undefined) return undefined;
    if (value === null) return undefined;

    if (typeof value === "object" && !Array.isArray(value)) {
        // Empty object -> {}
        if (Object.keys(value).length === 0) {
            return undefined;
        } else if (
            // Object with all keys undefined --> { name: undefined, email: undefined }
            Object.values(value).every(
                (valueInObject) => valueInObject === undefined,
            )
        ) {
            return undefined;
        }
    }

    // Empty array --> []
    if (Array.isArray(value) && value.length === 0) return undefined;

    // Empty string --> ""
    if (typeof value === "string" && value.length === 0) return undefined;

    return value as Undefinable<Exclude<T, null>>;
};
