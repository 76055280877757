import { createContext, FC, ReactNode, useContext, useMemo } from "react";

export interface IncFilterItemContextProvidedValue {
    name: string;
}

const IncFilterItemContext = createContext<IncFilterItemContextProvidedValue>({
    name: "",
});

export interface IncFilterItemContextProviderProps {
    name: string;
    children: ReactNode;
}

export const IncFilterItemContextProvider: FC<
    IncFilterItemContextProviderProps
> = (props) => {
    const providedValue = useMemo<IncFilterItemContextProvidedValue>(
        () => ({
            name: props.name,
        }),
        [props.name],
    );

    return (
        <IncFilterItemContext.Provider value={providedValue}>
            {props.children}
        </IncFilterItemContext.Provider>
    );
};

export const useIncFilterItemContext = (): IncFilterItemContextProvidedValue =>
    useContext(IncFilterItemContext);
