import { ContractTerminationRepository } from "@entity/data/repositories/contract-termination.repository";
import { TerminationReason } from "@entity/domain/models/employee/termination-reason.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllTerminationReasonsUseCase {
    constructor(
        @inject(ContractTerminationRepository)
        private readonly contractTerminationRepository: ContractTerminationRepository,
    ) {}

    async execute(): Promise<TerminationReason[]> {
        const terminationReasonsResult =
            await this.contractTerminationRepository.getAllTerminationReasons();

        return terminationReasonsResult.fold(
            () => [],
            (terminationReasons) => terminationReasons,
        );
    }
}
