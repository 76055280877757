import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { BudgetValidations } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllValidationsByBudgetIdUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}

    async execute(budgetId: number): Promise<BudgetValidations> {
        const budgetValidationsResult =
            await this.budgetRepository.getBudgetValidations(budgetId);
        return budgetValidationsResult.fold(
            () => new BudgetValidations([], 0),
            (budgetValidations) => budgetValidations,
        );
    }
}
