import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import { GlobalProjectDatasource } from "@project/data/datasource/global-project.datasource";
import {
    GlobalProject,
    GlobalProjectFilters,
} from "@project/domain/models/global-project.model";
import { injectable } from "inversify";

@injectable()
export class GlobalProjectRepository {
    constructor(
        private readonly globalProjectDatasource: GlobalProjectDatasource,
    ) {}

    async getAllBy(
        filters?: GlobalProjectFilters,
    ): Promise<Either<FallbackError, GlobalProject[]>> {
        return this.globalProjectDatasource.fetchAllBy(filters);
    }
}
