import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";

export interface CreditorAddress {
    street: string;
    number: string;
    letter?: Undefinable<string>;
    floor?: Undefinable<string>;
    stairs?: Undefinable<string>;
    postalCode: string;
}

export interface CreditorContact {
    contactPerson: string;
    phone: string;
    email: string;
    emailRepresentative?: Undefinable<string>;
}

export interface CreditorEconomicData {
    debtDescription: string;
    debtTotal: number;
    debtPending: number;
}

export class Creditor {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly type: string,
        public readonly category: string,
        public readonly nieNif: string,
        public readonly address: CreditorAddress,
        public readonly contact: CreditorContact,
        public readonly economicData: CreditorEconomicData,
        public readonly entityId: number,
    ) {}
}

export class CreateCreditor extends Creditor {}

export class EditCreditor extends Creditor {}

export class Creditors {
    constructor(
        public readonly creditors: Creditor[],
        public readonly count: number,
    ) {}
}

export interface CreditorSearchFilters {
    search?: string;
    name?: string;
    type?: string;
    category?: string;
    nieNif?: string;
    debtPending?: string;
    limit?: number;
    offset?: number;
    entityIds?: Nullable<string[]>;
    // TODO: Remove when backend is ready
    entity?: Nullable<string>;
}
