import { BudgetColumnsDto } from "@project/data/dto/budget.dto";
import { BudgetColumnMapper } from "@project/data/mappers/budget/columns/budget-column.mapper";
import { BudgetColumns } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetColumnsMapper {
    constructor(
        @inject(BudgetColumnMapper)
        private readonly budgetColumnMapper: BudgetColumnMapper,
    ) {}
    map(budgetColumnsDto: BudgetColumnsDto): BudgetColumns {
        const errors = validateSync(budgetColumnsDto);
        if (Boolean(errors.length)) {
            console.error(errors);
            return new BudgetColumns([], 0);
        }

        const budgetColumn = budgetColumnsDto.results.mapNotNull(
            (columnBudgetDto) => this.budgetColumnMapper.map(columnBudgetDto),
        );
        return new BudgetColumns(budgetColumn, budgetColumnsDto.count);
    }
}
