import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { IndicatorSearchFilters } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { IndicatorRepository } from "@project/data/repositories/technical-proposal/indicator.repository";
import { inject, injectable } from "inversify";
import { Indicator } from "../../../models/technical-proposals.model";

@injectable()
export class GetAllIndicatorsByUseCase {
    constructor(
        @inject(IndicatorRepository)
        private readonly indicatorRepository: IndicatorRepository,
    ) {}

    async execute(filters: IndicatorSearchFilters): Promise<Indicator[]> {
        const indicatorsResult =
            await this.indicatorRepository.getAllBy(filters);

        if (indicatorsResult.isLeft()) {
            ToastManagerStore.error(indicatorsResult.getLeftOrThrow().message);
        }

        return indicatorsResult.fold(
            () => [],
            (indicators) => indicators,
        );
    }
}
