import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { EditBeneficiary } from "@beneficiary/domain/models/edit-beneficiary.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { Beneficiary } from "../models/beneficiary.model";

@injectable()
export class EditBeneficiaryUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(
        beneficiary: EditBeneficiary,
    ): Promise<Nullable<Beneficiary>> {
        const editResult = await this.beneficiaryRepository.edit(beneficiary);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedBeneficiary) => editedBeneficiary,
        );
    }
}
