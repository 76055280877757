import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";
import { CreateSocialNetwork } from "../../domain/models/create-social-network.model";
import { EditSocialNetwork } from "../../domain/models/edit-social-network.model";
import { SocialNetwork } from "../../domain/models/social-network.model";
import {
    SocialNetworkFilters,
    SocialNetworkSummary,
    SocialNetworksSummary,
} from "../../domain/models/social-networks-summary.model";
import { SocialNetworkDatasource } from "../datasources/social-network.datasource";

@injectable()
export class SocialNetworkRepository {
    constructor(
        @inject(SocialNetworkDatasource)
        private readonly socialNetworkDatasource: SocialNetworkDatasource,
    ) {}

    async getBy(
        pagination: Pagination,
        filters?: SocialNetworkFilters,
    ): Promise<Either<FallbackError, SocialNetworksSummary>> {
        const socialNetworkSummary = await this.socialNetworkDatasource.fetchBy(
            pagination,
            filters,
        );

        return socialNetworkSummary.mapLeft(() => new FallbackError());
    }

    async getAllBy(
        filters?: SocialNetworkFilters,
    ): Promise<Either<FallbackError, SocialNetworkSummary[]>> {
        const socialNetworkResult = await this.getBy(
            Pagination.NoPagination(),
            filters,
        );

        return socialNetworkResult.map(
            (socialNetwork) => socialNetwork.socialNetworkSummary,
        );
    }

    async create(
        createSocialNetwork: CreateSocialNetwork,
    ): Promise<Either<ValidationError | FallbackError, SocialNetwork>> {
        return this.socialNetworkDatasource.create(createSocialNetwork);
    }

    async edit(
        editSocialNetwork: EditSocialNetwork,
    ): Promise<Either<ValidationError | FallbackError, SocialNetwork>> {
        return this.socialNetworkDatasource.edit(editSocialNetwork);
    }

    async delete(
        socialNetworkId: number,
    ): Promise<Either<FallbackError, true>> {
        return this.socialNetworkDatasource.delete(socialNetworkId);
    }
}
