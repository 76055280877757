import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { AssociateServiceToDependence } from "../../domain/models/associate-service-to-dependence.enum";
import { AssociateServiceToDependenceDto } from "../dto/associate-service-to-dependence.enum.dto";
import { AssociateServiceToDependenceEnumMapper } from "./associate-service-to-dependence-enum.mapper";

@injectable()
export class AssociateServiceToDependenceMapper {
    constructor(
        @inject(AssociateServiceToDependenceEnumMapper)
        private readonly associateServiceToDependenceEnumMapper: AssociateServiceToDependenceEnumMapper,
    ) {}

    map(
        associateServiceToDependenceEnumDto: AssociateServiceToDependenceDto,
    ): Nullable<AssociateServiceToDependence> {
        const errors = validateSync(AssociateServiceToDependenceDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const associateServiceToDependenceId =
            this.associateServiceToDependenceEnumMapper.map(
                associateServiceToDependenceEnumDto.key,
            );

        if (!associateServiceToDependenceId) return null;

        return new AssociateServiceToDependence(
            associateServiceToDependenceId,
            associateServiceToDependenceEnumDto.value,
        );
    }
}
