import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { UserDatasource } from "@user/data/datasources/user.datasource";
import { CreateUser } from "@user/domain/models/create-user.model";
import { EditUser } from "@user/domain/models/edit-user.model";
import { UserRole, UserRoleEnum } from "@user/domain/models/user-role.model";
import { User, UserSearchFilters } from "@user/domain/models/user.model";
import {
    UserSummary,
    UsersSummary,
} from "@user/domain/models/users-summary.model";
import { Map } from "immutable";
import { inject, injectable } from "inversify";

@injectable()
export class UserRepository {
    constructor(
        @inject(UserDatasource)
        private readonly userDatasource: UserDatasource,
    ) {}

    async getById(id: number): Promise<Either<FallbackError, User>> {
        return this.userDatasource.fetchById(id);
    }

    async create(
        createUser: CreateUser,
    ): Promise<Either<ValidationError | FallbackError, User>> {
        return this.userDatasource.create(createUser);
    }

    async edit(
        editUser: EditUser,
    ): Promise<Either<ValidationError | FallbackError, User>> {
        return this.userDatasource.edit(editUser);
    }

    async getAllUserRoles(): Promise<Either<FallbackError, UserRole[]>> {
        return this.userDatasource.fetchAllRoles();
    }

    async searchAllBy(
        pagination: Pagination,
        filters?: UserSearchFilters,
    ): Promise<Either<FallbackError, UsersSummary>> {
        const usersResult = await this.userDatasource.fetchAllBy(
            pagination,
            filters,
        );

        if (usersResult.isLeft())
            return Either.Left(usersResult.getLeftOrThrow());

        const users = usersResult.getOrThrow();

        const roleUserResult = await this.getAllUserRoles();
        if (roleUserResult.isLeft())
            return Either.Left(roleUserResult.getLeftOrThrow());

        const roleUserMap: Map<UserRoleEnum, UserRole> = Map(
            roleUserResult
                .getOrThrow()
                .map((userRole) => [userRole.id, userRole]),
        );

        const usersSummaryMap = users.users.mapNotNull((user) => {
            const role = roleUserMap.get(user.role);
            if (!role) return null;

            return new UserSummary(
                user.id,
                user.email,
                user.lastLogin,
                role.label,
                role.id,
                user.relatedEmployee,
            );
        });

        const usersSummary = new UsersSummary(usersSummaryMap, users.count);

        return Either.Right(usersSummary);
    }

    async toggleActivation(
        userId: number,
    ): Promise<Either<FallbackError, boolean>> {
        const userResult = await this.userDatasource.toggleActivation(userId);

        return userResult.mapLeft(() => new FallbackError());
    }
}
