import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContactRepository } from "@entity/data/repositories/contact.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteContactUseCase {
    constructor(
        @inject(ContactRepository)
        private readonly contactRepository: ContactRepository,
    ) {}

    async execute(contactId: number): Promise<void> {
        const result = await this.contactRepository.delete(contactId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }
    }
}
