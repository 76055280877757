import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { TerminationReasonEnumDto } from "@entity/data/dto/employee/termination-reason.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class ContractTerminationDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsDateString()
    @IsNotEmpty()
    start_date!: string;

    @IsDateString()
    @IsOptional()
    end_date!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    reason!: string;

    @IsEnum(TerminationReasonEnumDto)
    @IsNotEmpty()
    type!: TerminationReasonEnumDto;

    @IsString()
    @IsOptional()
    cost!: Nullable<string>;

    @IsString()
    @IsOptional()
    total_cost!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    contract!: number;
}

export class ContractTerminationsDto extends PaginatedDto {
    @IsArray()
    @Type(() => ContractTerminationDto)
    results!: ContractTerminationDto[];
}

export type ContractTerminationQuery = PaginatedQuery & {
    employee?: Nullish<number>;
};
