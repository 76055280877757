import { Undefinable } from "@core/domain/types/undefinable.type";

export class Income {
    public socioeconomicBenefit?: Undefinable<number>;
    public familyUnitIncome?: Undefinable<number>;
    public observations?: Undefinable<string>;
    public netSalary?: Undefinable<number>;
    public othersIncome?: Undefinable<number>;

    constructor(
        public readonly id: number,
        public readonly beneficiaryId: number,
    ) {}
}

export class Incomes {
    constructor(
        public readonly incomes: Income[],
        public readonly count: number,
    ) {}
}

export class CreateIncome {
    public netSalary?: Undefinable<number>;
    public socioeconomicBenefit?: Undefinable<number>;
    public familyUnitIncome?: Undefinable<number>;
    public observations?: Undefinable<string>;
    public othersIncome?: Undefinable<number>;

    constructor(public readonly beneficiaryId: number) {}
}

export class EditIncome {
    public netSalary?: Undefinable<number>;
    public socioeconomicBenefit?: Undefinable<number>;
    public familyUnitIncome?: Undefinable<number>;
    public observations?: Undefinable<string>;
    public othersIncome?: Undefinable<number>;

    constructor(
        public readonly id: number,
        public readonly beneficiaryId: number,
    ) {}
}
