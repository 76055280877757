import { InternalNoteDto } from "@beneficiary/data/dto/internal-notes/internal-note.dto";
import { InternalNote } from "@beneficiary/domain/models/internal-notes/internal-note.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { UserMapper } from "@user/data/mappers/user.mapper";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class InternalNoteMapper {
    constructor(
        @inject(UserMapper)
        private readonly userMapper: UserMapper,
    ) {}
    map(internalNoteDto: InternalNoteDto): Nullable<InternalNote> {
        const errors = validateSync(internalNoteDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const userData = this.userMapper.map(internalNoteDto.user_data);
        if (!userData) return null;

        return new InternalNote(
            internalNoteDto.id,
            internalNoteDto.note,
            internalNoteDto.beneficiary,
            userData,
            DateTime.fromISO(internalNoteDto.modified),
        );
    }

    mapToDto(internalNote: InternalNote): InternalNoteDto {
        const user_data = this.userMapper.mapToDto(internalNote.user);

        const internalNoteDto: InternalNoteDto = {
            id: internalNote.id,
            note: internalNote.note,
            beneficiary: internalNote.beneficiary,
            user_data,
            modified: internalNote.modified.toISO() ?? "",
        };

        return internalNoteDto;
    }
}
