import { IdentificationDocumentTypeEnum } from "@core/domain/models/identification-document-type.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { IdentificationDocumentTypeEnumDto } from "../dto/identification-document-type.dto";

@injectable()
export class IdentificationDocumentTypeEnumMapper {
    map(
        identificationTypeEnumDto: IdentificationDocumentTypeEnumDto,
    ): Nullable<IdentificationDocumentTypeEnum> {
        switch (identificationTypeEnumDto) {
            case IdentificationDocumentTypeEnumDto.Dni:
                return IdentificationDocumentTypeEnum.Dni;
            case IdentificationDocumentTypeEnumDto.Nie:
                return IdentificationDocumentTypeEnum.Nie;
            case IdentificationDocumentTypeEnumDto.Cif:
                return IdentificationDocumentTypeEnum.Cif;
            case IdentificationDocumentTypeEnumDto.Nif:
                return IdentificationDocumentTypeEnum.Nif;
            case IdentificationDocumentTypeEnumDto.Passport:
                return IdentificationDocumentTypeEnum.Passport;
            default:
                return null;
        }
    }

    mapToDto(
        identificationTypeEnum: IdentificationDocumentTypeEnum,
    ): IdentificationDocumentTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (identificationTypeEnum) {
            case IdentificationDocumentTypeEnum.Cif:
                return IdentificationDocumentTypeEnumDto.Cif;
            case IdentificationDocumentTypeEnum.Nie:
                return IdentificationDocumentTypeEnumDto.Nie;
            case IdentificationDocumentTypeEnum.Dni:
                return IdentificationDocumentTypeEnumDto.Dni;
            case IdentificationDocumentTypeEnum.Nif:
                return IdentificationDocumentTypeEnumDto.Nif;
            case IdentificationDocumentTypeEnum.Passport:
                return IdentificationDocumentTypeEnumDto.Passport;
        }
    }
}
