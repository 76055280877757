import { DocumentLicenseTypeDto } from "@core/data/dto/document-license-type.dto";
import { DocumentTypeLicenseEnumMapper } from "@core/data/mappers/document-type-license-enum.mapper";
import { DocumentLicenseType } from "@core/domain/models/document-type.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class DocumentLicenseTypeMapper {
    constructor(
        @inject(DocumentTypeLicenseEnumMapper)
        private readonly documentTypeLicenseEnumMapper: DocumentTypeLicenseEnumMapper,
    ) {}
    map(
        documentLicenseTypeDto: DocumentLicenseTypeDto,
    ): Nullable<DocumentLicenseType> {
        const errors = validateSync(documentLicenseTypeDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const type = this.documentTypeLicenseEnumMapper.map(
            documentLicenseTypeDto.key,
        );

        if (!type) return null;

        return new DocumentLicenseType(type, documentLicenseTypeDto.value);
    }
}
