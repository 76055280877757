import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ObjectiveRepository } from "@project/data/repositories/technical-proposal/objective.repository";
import {
    CreateObjective,
    Objective,
} from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateObjectiveUseCase {
    constructor(
        @inject(ObjectiveRepository)
        private readonly roomRepository: ObjectiveRepository,
    ) {}

    async execute(room: CreateObjective): Promise<Nullable<Objective>> {
        const roomResult = await this.roomRepository.create(room);

        if (roomResult.isLeft()) {
            ToastManagerStore.error(roomResult.getLeftOrThrow().message);
        }

        const newObjective = roomResult.fold(
            () => null,
            (createdObjective) => createdObjective,
        );

        return newObjective;
    }
}
