import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { MaterialMapper } from "@entity/data/mappers/material/material.mapper";
import { MaterialRepository } from "@entity/data/repositories/material.repository";
import { MaterialFormValuesValidated } from "@entity/presentation/component/material-form/material-form";
import { inject, injectable } from "inversify";
import { Material } from "../../models/material/material.model";

@injectable()
export class EditMaterialUseCase {
    constructor(
        @inject(MaterialRepository)
        private readonly materialRepository: MaterialRepository,
        @inject(MaterialMapper)
        private readonly materialMapper: MaterialMapper,
    ) {}

    async execute(materialToEdit: MaterialFormValuesValidated): Promise<void> {
        const material = new Material(
            materialToEdit.id,
            materialToEdit.name,
            materialToEdit.description,
            materialToEdit.typeId,
            materialToEdit.typeLabel,
            materialToEdit.subtypeId,
            materialToEdit.subtypeLabel,
            materialToEdit.othersType,
            materialToEdit.metricUnit,
            materialToEdit.othersTypeUnit,
            materialToEdit.count,
            materialToEdit.anualCost,
            materialToEdit.entity,
        );

        const editResult = await this.materialRepository.edit(material);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }
    }
}
