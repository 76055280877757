import { AuthenticationStorageDatasource } from "@authentication/data/datasource/authentication-storage.datasource";
import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { LoginError } from "@authentication/domain/errors/login/login.error";
import { LogoutError } from "@authentication/domain/errors/logout/logout.error";
import { NoLoggedUserError } from "@authentication/domain/errors/logout/no-logged-user.error";
import { HttpUnauthorizedError } from "@core/data/infrastructures/http/errors/http-unauthorized.error";
import { EventBusRepository } from "@core/data/repositories/event-bus.repository";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { UnauthorizedEventBus } from "@core/domain/models/event-bus.model";
import { Either } from "@core/domain/types/either";
import { Nullable } from "@core/domain/types/nullable.type";
import { inject, injectable } from "inversify";
import { isEmpty } from "lodash";
import { AuthenticationRemoteDatasource } from "../datasource/authentication-remote.datasource";

@injectable()
export class AuthenticationRepository {
    get hasAccessToken(): boolean {
        return !isEmpty(this.getAccessToken());
    }

    constructor(
        @inject(AuthenticationRemoteDatasource)
        private readonly authenticationRemoteDatasource: AuthenticationRemoteDatasource,
        @inject(AuthenticationStorageDatasource)
        private readonly authenticationStorageDatasource: AuthenticationStorageDatasource,
        @inject(EventBusRepository)
        private readonly eventBusRepository: EventBusRepository,
        @inject(UserSessionRepository)
        private readonly userSessionRepository: UserSessionRepository,
    ) {}

    getAccessToken(): Nullable<string> {
        return this.authenticationStorageDatasource.getAccessToken();
    }

    async login(
        email: string,
        password: string,
    ): Promise<Either<LoginError, true>> {
        const loginResult = await this.authenticationRemoteDatasource.login(
            email,
            password,
        );

        let getCurrentUserResult:
            | Either<FallbackError | ValidationError, boolean>
            | undefined = undefined;

        if (loginResult.isRight()) {
            const loggedUser = loginResult.getOrThrow();

            this.authenticationStorageDatasource.saveAccessToken(
                loggedUser.token,
            );

            getCurrentUserResult =
                await this.userSessionRepository.loadCurrentUser();
        } else {
            // We send the event so the app can make logout and redirect to login
            this.eventBusRepository.emit(new UnauthorizedEventBus());
        }

        return loginResult.flatMap(() => {
            if (!getCurrentUserResult) return Either.Left(new FallbackError());
            if (getCurrentUserResult.isLeft()) {
                return Either.Left(getCurrentUserResult.getLeftOrThrow());
            }

            return Either.Right(true);
        });
    }

    /**
     *
     * @param onlyLocalSession: True will only close frontend session, false will also call remote logout
     */
    async logout(
        onlyLocalSession: boolean,
    ): Promise<Either<LogoutError, true>> {
        let logoutResult: Either<LogoutError, true> = Either.Right(true);

        if (!onlyLocalSession) {
            logoutResult = await this.authenticationRemoteDatasource.logout();
        }

        this.authenticationStorageDatasource.deleteAccessToken();

        this.userSessionRepository.setUser(null);

        return logoutResult.mapLeft((error) => {
            if (error instanceof HttpUnauthorizedError)
                return new NoLoggedUserError();

            return new FallbackError();
        });
    }

    async resetPassword(
        email: string,
    ): Promise<Either<ValidationError | FallbackError, true>> {
        const resetPasswordResult =
            await this.authenticationRemoteDatasource.resetPassword(email);

        return resetPasswordResult;
    }

    async resetPasswordConfirm(
        password: string,
        confirmPassword: string,
        token: string,
    ): Promise<Either<ValidationError | FallbackError, true>> {
        const resetPasswordConfirmResult =
            await this.authenticationRemoteDatasource.resetPasswordConfirm(
                token,
                password,
                confirmPassword,
            );

        return resetPasswordConfirmResult;
    }
}
