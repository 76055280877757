import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { Beneficiary } from "@beneficiary/domain/models/beneficiary.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class EditBeneficiaryAddressUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(
        beneficiaryId: number,
        usualAddressId?: Undefinable<number>,
        casualAddressId?: Undefinable<number>,
    ): Promise<Nullable<Beneficiary>> {
        const editResult = await this.beneficiaryRepository.editAddress(
            beneficiaryId,
            usualAddressId,
            casualAddressId,
        );

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (beneficiary: Beneficiary) => beneficiary,
        );
    }
}
