import { CountryEnumMapper } from "@beneficiary/data/mappers/country-enum.mapper";
import { EditAddressBody } from "@entity/data/dto/address/edit-address.body";
import { TitularityTypeEnumMapper } from "@entity/data/mappers/titularity-type-enum.mapper";
import { EditAddress } from "@entity/domain/models/address/edit-address.model";
import { AddressFormValuesValidated } from "@entity/presentation/component/entity-form/address/form/address-form";
import { inject, injectable } from "inversify";

@injectable()
export class EditAddressMapper {
    constructor(
        @inject(CountryEnumMapper)
        private readonly countryEnumMapper: CountryEnumMapper,
        @inject(TitularityTypeEnumMapper)
        private readonly titularityTypeEnumMapper: TitularityTypeEnumMapper,
    ) {}
    mapToDto(editAddress: EditAddress): EditAddressBody {
        const country = this.countryEnumMapper.mapToDto(editAddress.country);
        const titularity_type = this.titularityTypeEnumMapper.mapToDto(
            editAddress.titularityType,
        );

        const editAddressBody: EditAddressBody = {
            id: editAddress.id,
            entity: editAddress.entity,
            name: editAddress.name,
            street_name: editAddress.streetName,
            zip_code: editAddress.zipCode,
            town: editAddress.town,
            province: editAddress.province,
            street_number: editAddress.streetNumber,
            country,
            titularity_type,
            region: editAddress.region ? editAddress.region : null,
            floor: editAddress.floor ? editAddress.floor : null,
            door: editAddress.door ? editAddress.door : null,
            stair: editAddress.stair ? editAddress.stair : null,
            cadastral_value: editAddress.cadastralValue
                ? editAddress.cadastralValue
                : null,
            local: editAddress.local ?? false,
            principal_address: editAddress.principalAddress ?? false,
        };
        return editAddressBody;
    }
    mapFromModal(
        id: number,
        entityId: number,
        address: AddressFormValuesValidated,
    ): EditAddress {
        const editAddress: EditAddress = new EditAddress(
            id,
            address.name,
            address.streetName,
            address.zipCode,
            address.town,
            address.province,
            entityId,
            address.streetNumber,
            address.country,
            address.titularityType,
            address.region,
            address.floor,
            address.door,
            address.stair,
            address.cadastralValue,
            address.local,
            address.principalAddress,
        );
        return editAddress;
    }
}
