import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";
import {
    CreateProjectActivityTaskBody,
    ProjectActivityTask,
} from "../../domain/models/project-activity-task.model";
import { ProjectActivityTaskDataSource } from "../datasource/project-activity-task.datasource";

@injectable()
export class ProjectActivityTaskRepository {
    constructor(
        @inject(ProjectActivityTaskDataSource)
        private readonly projectActivityTaskDataSource: ProjectActivityTaskDataSource,
    ) {}

    async create(
        activityTask: CreateProjectActivityTaskBody,
    ): Promise<Either<ValidationError | FallbackError, ProjectActivityTask>> {
        return this.projectActivityTaskDataSource.create(activityTask);
    }

    async update(
        activityTask: ProjectActivityTask,
    ): Promise<Either<ValidationError | FallbackError, ProjectActivityTask>> {
        return this.projectActivityTaskDataSource.update(activityTask);
    }

    async delete(taskId: number): Promise<Either<FallbackError, true>> {
        return this.projectActivityTaskDataSource.delete(taskId);
    }
}
