import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { BeneficiaryIdentificationTypeEnum } from "../../domain/models/identification-document-type.model";
import { BeneficiaryIdentificationTypeEnumDto } from "../dto/beneficiary-identification-type.dto";

@injectable()
export class BeneficiaryIdentificationTypeEnumMapper {
    map(
        beneficiaryIdentificationTypeEnumDto: BeneficiaryIdentificationTypeEnumDto,
    ): Nullable<BeneficiaryIdentificationTypeEnum> {
        switch (beneficiaryIdentificationTypeEnumDto) {
            case BeneficiaryIdentificationTypeEnumDto.Nie:
                return BeneficiaryIdentificationTypeEnum.Nie;
            case BeneficiaryIdentificationTypeEnumDto.PiWhite:
                return BeneficiaryIdentificationTypeEnum.PiWhite;
            case BeneficiaryIdentificationTypeEnumDto.PiRedTemp:
                return BeneficiaryIdentificationTypeEnum.PiRedTemp;
            case BeneficiaryIdentificationTypeEnumDto.RedNoWork:
                return BeneficiaryIdentificationTypeEnum.RedNoWork;
            case BeneficiaryIdentificationTypeEnumDto.RedWork:
                return BeneficiaryIdentificationTypeEnum.RedWork;
            case BeneficiaryIdentificationTypeEnumDto.Dni:
                return BeneficiaryIdentificationTypeEnum.Dni;
            case BeneficiaryIdentificationTypeEnumDto.Passport:
                return BeneficiaryIdentificationTypeEnum.Passport;
            case BeneficiaryIdentificationTypeEnumDto.NoDocumentation:
                return BeneficiaryIdentificationTypeEnum.NoDocumentation;
            case BeneficiaryIdentificationTypeEnumDto.SolicitanteNie:
                return BeneficiaryIdentificationTypeEnum.SolicitanteNie;
            case BeneficiaryIdentificationTypeEnumDto.OtherDocumentation:
                return BeneficiaryIdentificationTypeEnum.OtherDocumentation;
            default:
                return null;
        }
    }

    mapToDto(
        beneficiaryIdentificationTypeEnum: BeneficiaryIdentificationTypeEnum,
    ): BeneficiaryIdentificationTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (beneficiaryIdentificationTypeEnum) {
            case BeneficiaryIdentificationTypeEnum.Nie:
                return BeneficiaryIdentificationTypeEnumDto.Nie;
            case BeneficiaryIdentificationTypeEnum.PiWhite:
                return BeneficiaryIdentificationTypeEnumDto.PiWhite;
            case BeneficiaryIdentificationTypeEnum.PiRedTemp:
                return BeneficiaryIdentificationTypeEnumDto.PiRedTemp;
            case BeneficiaryIdentificationTypeEnum.RedNoWork:
                return BeneficiaryIdentificationTypeEnumDto.RedNoWork;
            case BeneficiaryIdentificationTypeEnum.RedWork:
                return BeneficiaryIdentificationTypeEnumDto.RedWork;
            case BeneficiaryIdentificationTypeEnum.Dni:
                return BeneficiaryIdentificationTypeEnumDto.Dni;
            case BeneficiaryIdentificationTypeEnum.Passport:
                return BeneficiaryIdentificationTypeEnumDto.Passport;
            case BeneficiaryIdentificationTypeEnum.NoDocumentation:
                return BeneficiaryIdentificationTypeEnumDto.NoDocumentation;
            case BeneficiaryIdentificationTypeEnum.SolicitanteNie:
                return BeneficiaryIdentificationTypeEnumDto.SolicitanteNie;
            case BeneficiaryIdentificationTypeEnum.OtherDocumentation:
                return BeneficiaryIdentificationTypeEnumDto.OtherDocumentation;
        }
    }
}
