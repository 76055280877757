import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { PartnersNumberRepository } from "@entity/data/repositories/partners-number.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeletePartnersNumberUseCase {
    constructor(
        @inject(PartnersNumberRepository)
        private readonly partnersNumberRepository: PartnersNumberRepository,
    ) {}

    async execute(partnersNumberId: number): Promise<void> {
        const result =
            await this.partnersNumberRepository.delete(partnersNumberId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        ToastManagerStore.success();
    }
}
