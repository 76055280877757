import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Employee } from "@entity/domain/models/employee/employee.model";
import { GetEmployeeByIdUseCase } from "@entity/domain/usecases/employee/get-employee-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class EmployeeDetailPageViewModel extends BaseViewModel {
    employeeId: Undefinable<number> = undefined;

    @observable
    employee?: Undefinable<Employee>;

    @observable
    initiallyLoading: boolean = true;

    constructor(
        @inject(GetEmployeeByIdUseCase)
        private readonly getEmployeeByIdUseCase: GetEmployeeByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getEmployee();

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    @action
    setEmployeeId(employeeId: number): void {
        this.employeeId = employeeId;
    }

    @action
    setEmployee(employee: Employee): void {
        this.employee = employee;
    }

    async getEmployee(): Promise<void> {
        if (this.employeeId === undefined) throw Error("Missing employeeId");

        const employee = await this.getEmployeeByIdUseCase.execute(
            this.employeeId,
        );

        if (employee) {
            this.setEmployee(employee);
        }
    }
}
