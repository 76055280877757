import { DocumentTypeLicenseEnumDto } from "@core/data/dto/document.dto";
import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export class DocumentLicenseTypeDto {
    @IsEnum(DocumentTypeLicenseEnumDto)
    @IsNotEmpty()
    key!: DocumentTypeLicenseEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
