import { inject, injectable } from "inversify";
import { ProjectExpenseRepository } from "../../../data/repositories/project-expense.repository";
import { MonthType } from "../../models/expenses/month-type.model";

@injectable()
export class GetAllMonthTypesUseCase {
    constructor(
        @inject(ProjectExpenseRepository)
        private readonly projectExpenseRepository: ProjectExpenseRepository,
    ) {}

    async execute(): Promise<MonthType[]> {
        const monthTypesResult =
            await this.projectExpenseRepository.getAllMonthTypes();

        return monthTypesResult.getOrElse([]);
    }
}
