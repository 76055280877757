import { CreateExternalDerivationBody } from "@beneficiary/data/dto/external-derivation/create-external-derivation.body";
import { CenterTypeEnumMapper } from "@beneficiary/data/mappers/center-type-enum.mapper";
import { CreateExternalDerivation } from "@beneficiary/domain/models/external-derivation/create-external-derivation.model";
import { ExternalDerivationFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/external-derivation/form/external-derivation-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreateExternalDerivationMapper {
    constructor(
        @inject(CenterTypeEnumMapper)
        private readonly centerTypeEnumMapper: CenterTypeEnumMapper,
    ) {}

    mapToCreateDto(
        createExternalDerivation: CreateExternalDerivation,
    ): CreateExternalDerivationBody {
        const centerType = this.centerTypeEnumMapper.mapToDto(
            createExternalDerivation.centerType,
        );

        const createExternalDerivationDto: CreateExternalDerivationBody = {
            reason: createExternalDerivation.reason,
            center_type: centerType,
            center_name: createExternalDerivation.centerName,
            beneficiary: createExternalDerivation.beneficiary,
            is_derivate_to_external:
                createExternalDerivation.isDerivateToExternal,
            contact_name: createExternalDerivation.contactName,
            contact_phone: createExternalDerivation.contactPhone,
            contact_email: createExternalDerivation.contactEmail,
        };

        const documents =
            createExternalDerivation.documents?.map(
                (document) => document.id,
            ) ?? undefined;
        createExternalDerivationDto.documents = documents;
        return createExternalDerivationDto;
    }

    mapFromFormValues(
        beneficiaryId: number,
        externalDerivationForm: ExternalDerivationFormValuesValidated,
    ): CreateExternalDerivation {
        if (!externalDerivationForm.centerType)
            throw Error("centerType is empty");

        const createExternalDerivation: CreateExternalDerivation =
            new CreateExternalDerivation(
                externalDerivationForm.reason,
                externalDerivationForm.centerType,
                externalDerivationForm.centerName,
                beneficiaryId,
                externalDerivationForm.isDerivateToExternal,
                externalDerivationForm.contactName
                    ? externalDerivationForm.contactName
                    : undefined,
                externalDerivationForm.contactPhone
                    ? externalDerivationForm.contactPhone
                    : undefined,
                externalDerivationForm.contactEmail
                    ? externalDerivationForm.contactEmail
                    : undefined,
                externalDerivationForm.documents ?? undefined,
            );

        return createExternalDerivation;
    }
}
