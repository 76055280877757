import { coreTypes } from "@core/core-types.di";
import { EXPAND_ALL } from "@core/data/dto/fields.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { IndicatorMapper } from "@project/data/mappers/technical-proposal/indicator.mapper";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import {
    CreateIndicator,
    EditIndicator,
    Indicator,
} from "../../../domain/models/technical-proposals.model";
import {
    IndicatorSearchFilters,
    IndicatorsQuery,
    TechnicalProposalIndicatorDto,
    TechnicalProposalIndicatorsDto,
} from "../../dto/technical-proposal/technical-proposal.dto";

const OBJECTIVES_PATH = "/technical_proposals_indicators/";

@injectable()
export class IndicatorDataSource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(IndicatorMapper)
        private readonly indicatorMapper: IndicatorMapper,
    ) {}

    async edit(
        indicator: EditIndicator,
    ): Promise<Either<ValidationError | FallbackError, Indicator>> {
        const indicatorBody = this.indicatorMapper.mapToEditDto(indicator);

        const editIndicatorResult =
            await this.http.patch<TechnicalProposalIndicatorDto>(
                `${OBJECTIVES_PATH}${indicator.id}/`,
                indicatorBody,
            );

        return editIndicatorResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const indicatorResult = this.indicatorMapper.map(
                    plainToClass(TechnicalProposalIndicatorDto, response.data),
                );

                if (!indicatorResult) return Either.Left(new FallbackError());

                return Either.Right(indicatorResult);
            });
    }

    async create(
        indicator: CreateIndicator,
    ): Promise<Either<ValidationError | FallbackError, Indicator>> {
        const indicatorBody = this.indicatorMapper.mapToCreateDto(indicator);

        const createIndicatorResult =
            await this.http.post<TechnicalProposalIndicatorDto>(
                OBJECTIVES_PATH,
                indicatorBody,
            );

        return createIndicatorResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const indicatorResponse = this.indicatorMapper.map(
                    plainToClass(TechnicalProposalIndicatorDto, response.data),
                );

                if (!indicatorResponse) return Either.Left(new FallbackError());

                return Either.Right(indicatorResponse);
            });
    }

    async delete(indicatorId: number): Promise<Either<FallbackError, true>> {
        const deleteResult = await this.http.delete(
            `${OBJECTIVES_PATH}${indicatorId}/`,
        );

        return deleteResult.mapLeft(() => new FallbackError()).map(() => true);
    }

    async fetchBy(
        pagination: Pagination,
        filters: IndicatorSearchFilters,
    ): Promise<Either<FallbackError, Indicator[]>> {
        const query: IndicatorsQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
            expand: EXPAND_ALL,
        };

        const { activity, objective, project } = filters;

        if (activity) query.activity = activity;
        if (objective) query.objetive = objective;
        if (project) query.project = project;

        const responseResult =
            await this.http.get<TechnicalProposalIndicatorsDto>(
                OBJECTIVES_PATH,
                {
                    query,
                },
            );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.results.mapNotNull(
                    (technicalProposalIndicatorDto) =>
                        this.indicatorMapper.map(
                            plainToClass(
                                TechnicalProposalIndicatorDto,
                                technicalProposalIndicatorDto,
                            ),
                        ),
                ),
            );
    }

    async exportIndicatorsBy(
        technicalProposalId: number,
        filters?: IndicatorSearchFilters,
    ): Promise<Either<FallbackError, Blob>> {
        const query: IndicatorsQuery = {};

        if (filters?.project) query.project = filters.project;
        if (filters?.activity) query.activity = filters.activity;
        if (filters?.objective) query.objetive = filters.objective;

        const indicatorResult = await this.http.get<Blob>(
            `/technical_proposals/${technicalProposalId}/export_indicators_csv/`,
            { query, responseType: "blob" },
        );

        return indicatorResult
            .mapLeft(() => new FallbackError())
            .map((response) => response.data as Blob);
    }
}
