import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { SupplierRepository } from "@entity/data/repositories/supplier.repository";
import { ServiceProvided } from "@entity/domain/models/supplier/service-provided.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllServiceProvidedUseCase {
    constructor(
        @inject(SupplierRepository)
        private readonly supplierRepository: SupplierRepository,
    ) {}

    async execute(): Promise<ServiceProvided[]> {
        const result = await this.supplierRepository.getAllServiceProvided();

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.fold(
            () => [],
            (serviceProvided) => serviceProvided,
        );
    }
}
