import { Pagination } from "@core/domain/models/pagination";
import { inject, injectable } from "inversify";
import { BeneficiaryRepository } from "../../data/repositories/beneficiary.repository";
import { BeneficiarySummary } from "../models/beneficiary-summary.model";

@injectable()
export class GetAllBeneficiariesUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficaryRepository: BeneficiaryRepository,
    ) {}

    async execute(): Promise<BeneficiarySummary[]> {
        const pagination: Pagination = Pagination.NoPagination();
        const beneficiaryResult =
            await this.beneficaryRepository.getAllBy(pagination);

        return beneficiaryResult.fold(
            () => [],
            (beneficiaries) => beneficiaries.beneficiariesSummary,
        );
    }
}
