import { Nullable } from "@core/domain/types/nullable.type";
import { DateTime } from "luxon";

export class Affiliation {
    constructor(
        public id: number,
        public identification: string,
        public name: string,
        public territorialScope: string,
        public entity: number,
        public webAddress?: Nullable<string>,
        public incorporationDate?: Nullable<DateTime>,
        public contactPerson?: Nullable<string>,
        public contactPhone?: Nullable<string>,
        public contactEmail?: Nullable<string>,
    ) {}
}

export class Affiliations {
    constructor(
        public readonly affiliations: Affiliation[],
        public readonly count: number,
    ) {}
}
