import { GlobalActivityRepository } from "@project/data/repositories/global-activity.repository";
import { inject, injectable } from "inversify";
import {
    GlobalActivity,
    GlobalActivityFilters,
} from "../models/global-activity.model";

@injectable()
export class GetAllGlobalActivitiesByUseCase {
    constructor(
        @inject(GlobalActivityRepository)
        private readonly globalActivityRepository: GlobalActivityRepository,
    ) {}

    async execute(filters?: GlobalActivityFilters): Promise<GlobalActivity[]> {
        const activitysResult =
            await this.globalActivityRepository.getAllBy(filters);

        return activitysResult.fold(
            () => [],
            (globalActivities) => globalActivities,
        );
    }
}
