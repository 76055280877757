import { Nullable } from "@core/domain/types/nullable.type";
import { TypologyDto } from "@entity/data/dto/typologies.dto";
import { TypologyEnumMapper } from "@entity/data/mappers/typology-enum.mapper";
import { Typology, TypologyEnum } from "@entity/domain/models/typology.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class TypologiesMapper {
    constructor(
        @inject(TypologyEnumMapper)
        private readonly typologyEnumMapper: TypologyEnumMapper,
    ) {}

    map(typologiesDto: TypologyDto): Nullable<Typology> {
        const errors = validateSync(typologiesDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<TypologyEnum> = this.typologyEnumMapper.map(
            typologiesDto.key,
        );

        if (!id) return null;

        return new Typology(id, typologiesDto.value);
    }
}
