import { Nullable } from "@core/domain/types/nullable.type";

export class SocialNetwork {
    constructor(
        public id: number,
        public entityId: number,
        public name: string,
        public link: string,
        public followers: Nullable<number> = 0,
        public postCount: Nullable<number> = 0,
    ) {}
}
