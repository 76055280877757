import { BudgetSummaryMapper } from "@project/data/mappers/budget-summary.mapper";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { BudgetsSummary } from "../../domain/models/budget-summary.model";
import { BudgetsDto } from "../dto/budget.dto";

@injectable()
export class BudgetsSummaryMapper {
    constructor(
        @inject(BudgetSummaryMapper)
        private readonly budgetSummaryDto: BudgetSummaryMapper,
    ) {}

    map(budgetsSummaryDto: BudgetsDto): BudgetsSummary {
        const errors = validateSync(budgetsSummaryDto);

        if (errors.length) {
            console.error(errors);

            return new BudgetsSummary([], 0);
        }

        const budgetSummary = budgetsSummaryDto.results.mapNotNull(
            (budgetSummaryDto) => this.budgetSummaryDto.map(budgetSummaryDto),
        );

        return new BudgetsSummary(budgetSummary, budgetsSummaryDto.count);
    }
}
