import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { RegisteredRecordRepository } from "@entity/data/repositories/registered-record.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteRegisteredRecordUseCase {
    constructor(
        @inject(RegisteredRecordRepository)
        private readonly registeredRecordRepository: RegisteredRecordRepository,
    ) {}

    async execute(registeredRecordId: number): Promise<void> {
        const result =
            await this.registeredRecordRepository.delete(registeredRecordId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        ToastManagerStore.success();
    }
}
