import { Nullable } from "@core/domain/types/nullable.type";
import { StatusType } from "@entity/domain/models/locals/status.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { StatusTypeDto } from "../../dto/locals/status.dto";
import { StatusTypeEnumMapper } from "./status-type-enum.mapper";

@injectable()
export class StatusTypeMapper {
    constructor(
        @inject(StatusTypeEnumMapper)
        private readonly costPeriodicityTypeEnumMapper: StatusTypeEnumMapper,
    ) {}
    map(costPeriodicityDto: StatusTypeDto): Nullable<StatusType> {
        const errors = validateSync(costPeriodicityDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const type = this.costPeriodicityTypeEnumMapper.map(
            costPeriodicityDto.key,
        );

        if (!type) return null;

        return new StatusType(type, costPeriodicityDto.value);
    }
}
