import { MaritalStatusEnumDto } from "@beneficiary/data/dto/marital-status.dto";
import { MaritalStatusEnum } from "@beneficiary/domain/models/marital-status.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";

@injectable()
export class MaritalStatusEnumMapper {
    map(maritalStatusIdDto: MaritalStatusEnumDto): Nullable<MaritalStatusEnum> {
        switch (maritalStatusIdDto) {
            case MaritalStatusEnumDto.Single:
                return MaritalStatusEnum.Single;
            case MaritalStatusEnumDto.CommonLaw:
                return MaritalStatusEnum.CommonLaw;
            case MaritalStatusEnumDto.Divorced:
                return MaritalStatusEnum.Divorced;
            case MaritalStatusEnumDto.Married:
                return MaritalStatusEnum.Married;
            case MaritalStatusEnumDto.Widowed:
                return MaritalStatusEnum.Widowed;
            case MaritalStatusEnumDto.Separated:
                return MaritalStatusEnum.Separated;
            default:
                return null;
        }
    }

    mapToDto(maritalStatusId: MaritalStatusEnum): MaritalStatusEnumDto {
        // eslint-disable-next-line default-case
        switch (maritalStatusId) {
            case MaritalStatusEnum.Single:
                return MaritalStatusEnumDto.Single;
            case MaritalStatusEnum.CommonLaw:
                return MaritalStatusEnumDto.CommonLaw;
            case MaritalStatusEnum.Divorced:
                return MaritalStatusEnumDto.Divorced;
            case MaritalStatusEnum.Married:
                return MaritalStatusEnumDto.Married;
            case MaritalStatusEnum.Widowed:
                return MaritalStatusEnumDto.Widowed;
            case MaritalStatusEnum.Separated:
                return MaritalStatusEnumDto.Separated;
        }
    }
}
