import { coreTypes } from "@core/core-types.di";
import { EXPAND_ALL, FieldsQuery } from "@core/data/dto/fields.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { OrderMapper } from "@core/data/mappers/order.mapper";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Order } from "@core/domain/models/order.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Undefinable } from "@core/domain/types/undefinable.type";
import {
    ProceedingsDto,
    ProceedingsQuery,
    proceedingsOrderMap,
} from "@proceeding/data/dto/proceedings.dto";
import { EditProceedingMapper } from "@proceeding/data/mappers/edit-proceeding.mapper";
import { EditProceeding } from "@proceeding/domain/models/edit-proceeding.model";
import { ProceedingSummary } from "@proceeding/domain/models/proceeding-summary.model";
import { ProceedingsSummary } from "@proceeding/domain/models/proceedings-sumary.model";
import { Status } from "@proceeding/domain/models/status.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { CreateProceeding } from "../../domain/models/create-proceeding.model";
import { DerivationProceeding } from "../../domain/models/derivation-proceeding.model";
import {
    Proceeding,
    ProceedingSearchFilters,
} from "../../domain/models/proceeding.model";
import { CreateProceedingBody } from "../dto/create-proceeding.body";
import { DerivationProceedingBody } from "../dto/derive-proceeding.body";
import { ProceedingDto } from "../dto/proceeding.dto";
import { StatusDto } from "../dto/status.dto";
import { CreateProceedingMapper } from "../mappers/create-proceeding.mapper";
import { DeriveProceedingMapper } from "../mappers/derive-proceeding.mapper";
import { ProceedingMapper } from "../mappers/proceeding.mapper";
import { ProceedingsSummaryMapper } from "../mappers/proceedings-summary.mapper";
import { StatusEnumMapper } from "../mappers/status-enum.mapper";
import { StatusMapper } from "../mappers/status.mapper";

@injectable()
export class ProceedingDatasource {
    constructor(
        @inject(CreateProceedingMapper)
        private readonly createProceedingMapper: CreateProceedingMapper,
        @inject(DeriveProceedingMapper)
        private readonly deriveProceedingMapper: DeriveProceedingMapper,
        @inject(EditProceedingMapper)
        private readonly editProceedingMapper: EditProceedingMapper,
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(OrderMapper) private readonly orderMapper: OrderMapper,
        @inject(ProceedingMapper)
        private readonly proceedingMapper: ProceedingMapper,
        @inject(ProceedingsSummaryMapper)
        private readonly proceedingsSummaryMapper: ProceedingsSummaryMapper,
        @inject(StatusMapper)
        private readonly statusMapper: StatusMapper,
        @inject(StatusEnumMapper)
        private statusEnumMapper: StatusEnumMapper,
    ) {}

    async create(
        newProceeding: CreateProceeding,
    ): Promise<Either<ValidationError | FallbackError, Proceeding>> {
        const createdProceedingDto =
            this.createProceedingMapper.mapToDto(newProceeding);

        const createBeneficiaryQuery: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const createProceedingResult = await this.http.post<
            ProceedingDto,
            CreateProceedingBody
        >("/expedients/", createdProceedingDto, {
            query: createBeneficiaryQuery,
        });

        return createProceedingResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdProceeding = this.proceedingMapper.map(
                    plainToClass(ProceedingDto, response.data),
                );

                if (!createdProceeding) return Either.Left(new FallbackError());

                return Either.Right(createdProceeding);
            });
    }

    async update(
        editProceeding: EditProceeding,
    ): Promise<Either<ValidationError | FallbackError, Proceeding>> {
        const editProceedingDto =
            this.editProceedingMapper.mapToDto(editProceeding);

        const editBeneficiaryQuery: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const editProceedingResult = await this.http.patch<
            ProceedingDto,
            CreateProceedingBody
        >(`/expedients/${editProceeding.id}/`, editProceedingDto, {
            query: editBeneficiaryQuery,
        });

        return editProceedingResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const editedProceeding = this.proceedingMapper.map(
                    plainToClass(ProceedingDto, response.data),
                );

                if (!editedProceeding) return Either.Left(new FallbackError());

                return Either.Right(editedProceeding);
            });
    }

    async fetchAllBy(
        pagination: Pagination,
        filters?: ProceedingSearchFilters,
        order?: Order<ProceedingSummary>,
    ): Promise<Either<HttpError, ProceedingsSummary>> {
        const query: ProceedingsQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.entityId) query.entity = filters.entityId;
        if (filters?.beneficiaryId) query.beneficiary = filters.beneficiaryId;
        if (filters?.projectId) query.project = filters.projectId;
        if (filters?.activitiesIds)
            query.activities = filters.activitiesIds.toString();
        if (filters?.search) query.search = filters.search;
        if (filters?.catalogueId) query.catalogue = filters.catalogueId;

        if (filters?.derivation) {
            if (filters.derivation === "toOther") {
                query.to_derivation = true;
            } else if (filters.derivation === "fromOther") {
                query.is_derivation = true;
            } else {
                query.has_derivation = true;
            }
        }

        if (filters?.status) {
            query.status = this.statusEnumMapper.mapToDto(filters.status);
        }

        if (order) {
            const orderQuery = this.orderMapper.mapToDto<
                ProceedingSummary,
                ProceedingDto
            >(order.field, order.direction, proceedingsOrderMap);
            if (orderQuery) query.ordering = orderQuery;
        }

        const proceedingsResult = await this.http.get<ProceedingsDto>(
            "/expedients/",
            {
                query,
            },
        );

        return proceedingsResult.map((response) =>
            this.proceedingsSummaryMapper.map(
                plainToClass(ProceedingsDto, response.data),
            ),
        );
    }

    async fetchAllStatuses(): Promise<Either<FallbackError, Status[]>> {
        const responseResult = await this.http.get<StatusDto[]>(
            "/expedients/status/",
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((statusDto) =>
                    this.statusMapper.map(plainToClass(StatusDto, statusDto)),
                ),
            );
    }

    async fetchById(id: number): Promise<
        Either<
            FallbackError,
            {
                proceeding: Proceeding;
                authorizationFileId: Undefinable<number>;
                resolutionFileId: Undefinable<number>;
            }
        >
    > {
        const expedientResult = await this.http.get<ProceedingDto>(
            `/expedients/${id}/`,
        );

        return expedientResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const expedient = this.proceedingMapper.map(
                    plainToClass(ProceedingDto, response.data),
                );

                if (!expedient) return Either.Left(new FallbackError());

                const authorizationFile =
                    response.data.derivation_authorization_file ??
                    response.data.authorization_file;

                return Either.Right({
                    proceeding: expedient,
                    authorizationFileId: authorizationFile ?? undefined,
                    resolutionFileId:
                        response.data.resolution_file ?? undefined,
                });
            });
    }

    async derive(
        deriveProceeding: DerivationProceeding,
    ): Promise<Either<ValidationError | FallbackError, DerivationProceeding>> {
        const derivedProceedingDto =
            this.deriveProceedingMapper.mapToDto(deriveProceeding);

        const createDerivationProceedingQuery: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const derivateProceedingResult = await this.http.post<
            DerivationProceeding,
            DerivationProceedingBody
        >("/expedients/derivation/", derivedProceedingDto, {
            query: createDerivationProceedingQuery,
        });

        return derivateProceedingResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const derivatedProceeding = this.deriveProceedingMapper.map(
                    plainToClass(DerivationProceedingBody, response.data),
                );

                if (!derivatedProceeding)
                    return Either.Left(new FallbackError());

                return Either.Right(derivatedProceeding);
            });
    }
}
