import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateAffiliationMapper } from "@entity/data/mappers/affiliation/create-affiliation.mapper";
import { EditAffiliationMapper } from "@entity/data/mappers/affiliation/edit-affiliation.mapper";
import { Affiliation } from "@entity/domain/models/affiliation/affiliation.model";
import { CreateAffiliationUseCase } from "@entity/domain/usecases/affiliation/create-affiliation.usecase";
import { DeleteAffiliationUseCase } from "@entity/domain/usecases/affiliation/delete-affiliation.usecase";
import { EditAffiliationUseCase } from "@entity/domain/usecases/affiliation/edit-affiliation.usecase";
import { GetAllAffiliationsUseCase } from "@entity/domain/usecases/affiliation/get-all-affiliations.usecase";
import { AffiliationFormValuesValidated } from "@entity/presentation/component/entity-form/affiliation/form/affiliation-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class AffiliationTabViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    affiliations: Affiliation[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddAffiliationModal: boolean = false;

    @observable
    showEditAffiliationModal: boolean = false;

    @observable
    affiliationToEdit: Undefinable<Affiliation> = undefined;

    constructor(
        @inject(GetAllAffiliationsUseCase)
        private readonly getAllAffiliationsUseCase: GetAllAffiliationsUseCase,
        @inject(CreateAffiliationUseCase)
        private readonly createAffiliationUseCase: CreateAffiliationUseCase,
        @inject(EditAffiliationUseCase)
        private readonly editAffiliationUseCase: EditAffiliationUseCase,
        @inject(DeleteAffiliationUseCase)
        private readonly deleteAffiliationUseCase: DeleteAffiliationUseCase,
        @inject(CreateAffiliationMapper)
        private readonly createAffiliationMapper: CreateAffiliationMapper,
        @inject(EditAffiliationMapper)
        private readonly editAffiliationMapper: EditAffiliationMapper,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getAffiliations()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAffiliations(): Promise<void> {
        const affiliations = await this.getAllAffiliationsUseCase.execute();
        runInAction(() => {
            this.affiliations = affiliations;
        });
    }

    @action
    setShowAddAffiliationModal(show: boolean): void {
        this.showAddAffiliationModal = show;
    }

    @action
    closeEditAffiliationModal(): void {
        this.showEditAffiliationModal = false;
    }

    @action
    openEditAffiliationModal(affiliation: Undefinable<Affiliation>): void {
        this.affiliationToEdit = affiliation;
        this.showEditAffiliationModal = true;
    }

    async addAffiliation(
        entityId: Undefinable<number>,
        values: AffiliationFormValuesValidated,
    ): Promise<Nullable<Affiliation>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createAffiliationResult =
            await this.createAffiliationUseCase.execute(
                this.createAffiliationMapper.mapFromFormValues(
                    entityId,
                    values,
                ),
            );

        if (createAffiliationResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createAffiliationResult;
    }

    async editAffiliation(
        values: AffiliationFormValuesValidated,
    ): Promise<void> {
        if (!this.affiliationToEdit?.id || !this.affiliationToEdit.entity)
            throw Error("Missing affiliation");

        LoadLayoutStore.start();
        const editAffiliationResult = await this.editAffiliationUseCase.execute(
            this.editAffiliationMapper.mapFromModal(
                this.affiliationToEdit.id,
                this.affiliationToEdit.entity,
                values,
            ),
        );

        if (editAffiliationResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadAffiliations(): Promise<void> {
        await this.getAffiliations();
    }

    async deleteAffiliation(affiliationId: number): Promise<void> {
        LoadLayoutStore.start();

        await this.deleteAffiliationUseCase.execute(affiliationId);

        LoadLayoutStore.finish();
    }
}
