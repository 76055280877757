import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import {
    ProjectEmployeeDto,
    ProjectEmployeesDto,
    ProjectEmployeesQuery,
} from "@project/data/dto/project-employee/project-employees.dto";
import { ProjectEmployeeMapper } from "@project/data/mappers/project-employee.mapper";
import { ProjectEmployeesMapper } from "@project/data/mappers/project-employees.mapper";
import {
    CreateProjectEmployee,
    ProjectEmployee,
    ProjectEmployees,
    ProjectEmployeesSearchFilters,
    UpdateProjectEmployee,
} from "@project/domain/models/project-employees.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class ProjectEmployeeDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private http: Http,
        @inject(ProjectEmployeeMapper)
        private readonly projectEmployeeMapper: ProjectEmployeeMapper,
        @inject(ProjectEmployeesMapper)
        private readonly projectEmployeesMapper: ProjectEmployeesMapper,
    ) {}

    async fetchById(
        id: number,
    ): Promise<Either<FallbackError, ProjectEmployee>> {
        const projectEmployeesResult = await this.http.get<ProjectEmployeeDto>(
            `/projects_employees/${id}/`,
        );

        return projectEmployeesResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const projectEmployee = this.projectEmployeeMapper.map(
                    plainToClass(ProjectEmployeeDto, response.data),
                );

                if (!projectEmployee) return Either.Left(new FallbackError());

                return Either.Right(projectEmployee);
            });
    }

    async fetchBy(
        pagination?: Pagination,
        filters?: ProjectEmployeesSearchFilters,
    ): Promise<Either<FallbackError, ProjectEmployees>> {
        const query: ProjectEmployeesQuery = {};

        if (pagination) {
            query.limit = pagination.pageSize;
            query.offset = pagination.offset;
        }

        if (filters?.projectId) {
            query.project = filters.projectId;
        }

        if (filters?.employeeId) {
            query.employee = filters.employeeId;
        }

        const projectEmployeesResult = await this.http.get<ProjectEmployeesDto>(
            "/projects_employees/",
            {
                query,
            },
        );

        return projectEmployeesResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.projectEmployeesMapper.map(
                    plainToClass(ProjectEmployeesDto, response.data),
                ),
            );
    }

    async create(
        createProjectEmployee: CreateProjectEmployee,
    ): Promise<Either<ValidationError | FallbackError, ProjectEmployee>> {
        const projectEmployeeResult = await this.http.post<ProjectEmployeeDto>(
            "/projects_employees/",
            this.projectEmployeeMapper.mapToCreateDto(createProjectEmployee),
        );

        return projectEmployeeResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const projectEmployee = this.projectEmployeeMapper.map(
                    plainToClass(ProjectEmployeeDto, response.data),
                );

                if (!projectEmployee) return Either.Left(new FallbackError());

                return Either.Right(projectEmployee);
            });
    }

    async update(
        editProjectEmployee: UpdateProjectEmployee,
    ): Promise<Either<ValidationError | FallbackError, ProjectEmployee>> {
        const projectEmployeeResult = await this.http.patch<ProjectEmployeeDto>(
            `/projects_employees/${editProjectEmployee.id}/`,
            this.projectEmployeeMapper.mapToEditDto(editProjectEmployee),
        );

        return projectEmployeeResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const projectEmployee = this.projectEmployeeMapper.map(
                    plainToClass(ProjectEmployeeDto, response.data),
                );

                if (!projectEmployee) return Either.Left(new FallbackError());

                return Either.Right(projectEmployee);
            });
    }

    async delete(id: number): Promise<Either<FallbackError, true>> {
        const projectEmployeeResult = await this.http.delete(
            `/projects_employees/${id}/`,
        );

        return projectEmployeeResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
