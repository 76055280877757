import { Nullable } from "@core/domain/types/nullable.type";
import { FreelanceContract } from "@entity/domain/models/employee/contracts/freelance/freelance-contract.model";

export class CreateFreelanceContract {
    constructor(
        public service: FreelanceContract["service"],
        public professionalGroup: FreelanceContract["professionalGroup"],
        public startDate: FreelanceContract["startDate"],
        public totalHours: FreelanceContract["totalHours"],
        public hoursCost: FreelanceContract["hoursCost"],
        public totalCost: FreelanceContract["totalCost"],
        public invoicesNumber: FreelanceContract["invoicesNumber"],
        public irpf: FreelanceContract["irpf"],
        public employeeId: FreelanceContract["employeeId"],
        public endDate?: Nullable<FreelanceContract["endDate"]>,
    ) {}
}
