import { DocumentTypeLicenseEnumDto } from "@core/data/dto/document.dto";
import { DocumentTypeLicenseEnum } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";

@injectable()
export class DocumentTypeLicenseEnumMapper {
    map(
        documentTypeLicenseIdDto: DocumentTypeLicenseEnumDto,
    ): Nullable<DocumentTypeLicenseEnum> {
        switch (documentTypeLicenseIdDto) {
            case DocumentTypeLicenseEnumDto.SenseLlicencia:
                return DocumentTypeLicenseEnum.SenseLlicencia;
            case DocumentTypeLicenseEnumDto.Copyright:
                return DocumentTypeLicenseEnum.Copyright;
            case DocumentTypeLicenseEnumDto.Copyleft:
                return DocumentTypeLicenseEnum.Copyleft;
            case DocumentTypeLicenseEnumDto.CcBy:
                return DocumentTypeLicenseEnum.CcBy;
            case DocumentTypeLicenseEnumDto.CcByNc:
                return DocumentTypeLicenseEnum.CcByNc;
            case DocumentTypeLicenseEnumDto.ByNcSa:
                return DocumentTypeLicenseEnum.ByNcSa;
            case DocumentTypeLicenseEnumDto.ByNcNd:
                return DocumentTypeLicenseEnum.ByNcNd;
            case DocumentTypeLicenseEnumDto.BySa:
                return DocumentTypeLicenseEnum.BySa;
            case DocumentTypeLicenseEnumDto.ByNd:
                return DocumentTypeLicenseEnum.ByNd;
            case DocumentTypeLicenseEnumDto.Publica:
                return DocumentTypeLicenseEnum.Publica;
            default:
                return null;
        }
    }

    mapToDto(
        documentTypeLicense: DocumentTypeLicenseEnum,
    ): DocumentTypeLicenseEnumDto {
        // eslint-disable-next-line default-case
        switch (documentTypeLicense) {
            case DocumentTypeLicenseEnum.SenseLlicencia:
                return DocumentTypeLicenseEnumDto.SenseLlicencia;
            case DocumentTypeLicenseEnum.Copyright:
                return DocumentTypeLicenseEnumDto.Copyright;
            case DocumentTypeLicenseEnum.Copyleft:
                return DocumentTypeLicenseEnumDto.Copyleft;
            case DocumentTypeLicenseEnum.CcBy:
                return DocumentTypeLicenseEnumDto.CcBy;
            case DocumentTypeLicenseEnum.CcByNc:
                return DocumentTypeLicenseEnumDto.CcByNc;
            case DocumentTypeLicenseEnum.ByNcSa:
                return DocumentTypeLicenseEnumDto.ByNcSa;
            case DocumentTypeLicenseEnum.ByNcNd:
                return DocumentTypeLicenseEnumDto.ByNcNd;
            case DocumentTypeLicenseEnum.BySa:
                return DocumentTypeLicenseEnumDto.BySa;
            case DocumentTypeLicenseEnum.ByNd:
                return DocumentTypeLicenseEnumDto.ByNd;
            case DocumentTypeLicenseEnum.Publica:
                return DocumentTypeLicenseEnumDto.Publica;
        }
    }
}
