export enum CrosscuttingScopeEnum {
    Innovation = "innovation",
    GenderPerspective = "gender_perspective",
    DiversityPerspective = "diversity_perspective",
    Digitalization = "digitalization",
    ContinuousImprovement = "continuous_improvement",
    Accessibility = "accessibility",
    Sustainability = "sustainability",
    SustainableDevelopmentGoals = "sustainable_development_goals",
    Other = "other",
}

export class CrosscuttingScope {
    constructor(
        public readonly id: CrosscuttingScopeEnum,
        public readonly label: string,
    ) {}
}
