import type { Nullable } from "@core/domain/types/nullable.type";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { AdditionalExpense } from "@entity/domain/models/cost/cost.model";
import { ProjectSummary } from "@entity/domain/models/project-summary.model";
import { SupplierIdentificationType } from "@entity/domain/models/supplier-identification-type.model";
import { ServiceProvided } from "@entity/domain/models/supplier/service-provided.model";
import { SupplierType } from "@entity/domain/models/supplier/supplier-type.model";
import { GetAllServiceProvidedUseCase } from "@entity/domain/usecases/supplier/get-all-service-provided.usecase";
import { GetAllSupplierIdentificationTypesUseCase } from "@entity/domain/usecases/supplier/get-all-supplier-identification-types.usecase";
import { GetAllSupplierTypesUseCase } from "@entity/domain/usecases/supplier/get-all-suppplier-types.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class SupplierFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    supplierIdentificationTypes: SupplierIdentificationType[] = [];

    @observable
    supplierTypes: SupplierType[] = [];

    @observable
    projects: ProjectSummary[] = [];

    @observable
    serviceProvided: ServiceProvided[] = [];

    @observable
    expenseSelected: Nullable<AdditionalExpense> = null;

    constructor(
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
        @inject(GetAllSupplierTypesUseCase)
        private readonly getAllSupplierTypesUseCase: GetAllSupplierTypesUseCase,
        @inject(GetAllSupplierIdentificationTypesUseCase)
        private readonly getAllSupplierIdentificationTypesUseCase: GetAllSupplierIdentificationTypesUseCase,
        @inject(GetAllServiceProvidedUseCase)
        private readonly getAllServiceProvidedUseCase: GetAllServiceProvidedUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllSupplierTypes(),
            this.getAllIdentificationTypes(),
            this.getAllServiceProvided(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllSupplierTypes(): Promise<void> {
        const supplierTypes = await this.getAllSupplierTypesUseCase.execute();

        runInAction(() => {
            this.supplierTypes = supplierTypes;
        });
    }

    async getAllIdentificationTypes(): Promise<void> {
        const supplierIdentificationTypes =
            await this.getAllSupplierIdentificationTypesUseCase.execute();

        runInAction(() => {
            this.supplierIdentificationTypes = supplierIdentificationTypes;
        });
    }

    async getAllServiceProvided(): Promise<void> {
        const serviceProvided =
            await this.getAllServiceProvidedUseCase.execute();

        runInAction(() => {
            this.serviceProvided = serviceProvided;
        });
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        if (documentUrl) {
            await this.downloadDocumentUseCase.execute(documentUrl);
        }

        LoadLayoutStore.finish();
    }

    async deleteDocument(documentId: number): Promise<boolean> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return deleted;
    }
}
