import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { ExpenseSubTypeEnum } from "../../../domain/models/expenses/sub-type.model";
import { ExpenseSubTypeEnumDto } from "../../dto/expenses/expense-sub-type.dto";

@injectable()
export class ExpenseSubTypeEnumMapper {
    map(statusEnumDto: ExpenseSubTypeEnumDto): Nullable<ExpenseSubTypeEnum> {
        switch (statusEnumDto) {
            case ExpenseSubTypeEnumDto.AUDITING:
                return ExpenseSubTypeEnum.AUDITING;
            case ExpenseSubTypeEnumDto.COMPUTER_EQUIPMENT:
                return ExpenseSubTypeEnum.COMPUTER_EQUIPMENT;
            case ExpenseSubTypeEnumDto.CONFERENCES_LECTURES:
                return ExpenseSubTypeEnum.CONFERENCES_LECTURES;
            case ExpenseSubTypeEnumDto.CONSULTING_SERVICES:
                return ExpenseSubTypeEnum.CONSULTING_SERVICES;
            case ExpenseSubTypeEnumDto.CONSUMABLES:
                return ExpenseSubTypeEnum.CONSUMABLES;
            case ExpenseSubTypeEnumDto.EXPENSES_INCURRED_BENEFICIARIES:
                return ExpenseSubTypeEnum.EXPENSES_INCURRED_BENEFICIARIES;
            case ExpenseSubTypeEnumDto.FURNITURE:
                return ExpenseSubTypeEnum.FURNITURE;
            case ExpenseSubTypeEnumDto.INSURANCE:
                return ExpenseSubTypeEnum.INSURANCE;
            case ExpenseSubTypeEnumDto.LABOR_MANAGEMENT_LICENSES:
                return ExpenseSubTypeEnum.LABOR_MANAGEMENT_LICENSES;
            case ExpenseSubTypeEnumDto.LIABILITY_INSURANCE:
                return ExpenseSubTypeEnum.LIABILITY_INSURANCE;
            case ExpenseSubTypeEnumDto.MATERIALS_FOR_ACTIVITIES:
                return ExpenseSubTypeEnum.MATERIALS_FOR_ACTIVITIES;
            case ExpenseSubTypeEnumDto.OCCASIONAL_COLLABORATIONS:
                return ExpenseSubTypeEnum.OCCASIONAL_COLLABORATIONS;
            case ExpenseSubTypeEnumDto.OTHERS_INVESTMENT_EXPENSES:
                return ExpenseSubTypeEnum.OTHERS_INVESTMENT_EXPENSES;
            case ExpenseSubTypeEnumDto.OTHERS_MINOR_EXPENSES:
                return ExpenseSubTypeEnum.OTHERS_MINOR_EXPENSES;
            case ExpenseSubTypeEnumDto.REAL_ESTATE:
                return ExpenseSubTypeEnum.REAL_ESTATE;
            case ExpenseSubTypeEnumDto.RENT:
                return ExpenseSubTypeEnum.RENT;
            case ExpenseSubTypeEnumDto.SOFTWARE_LICENSES:
                return ExpenseSubTypeEnum.SOFTWARE_LICENSES;
            case ExpenseSubTypeEnumDto.THIRD_PARTY_ACCOUNT:
                return ExpenseSubTypeEnum.THIRD_PARTY_ACCOUNT;
            case ExpenseSubTypeEnumDto.THIRD_PARTY_SERVICES:
                return ExpenseSubTypeEnum.THIRD_PARTY_SERVICES;
            case ExpenseSubTypeEnumDto.TRANSPORT_OF_GOODS_AND_INMOVILITIES:
                return ExpenseSubTypeEnum.TRANSPORT_OF_GOODS_AND_IMMOBILITIES;
            case ExpenseSubTypeEnumDto.UTILITIES:
                return ExpenseSubTypeEnum.UTILITIES;
            case ExpenseSubTypeEnumDto.VOLUNTEERING:
                return ExpenseSubTypeEnum.VOLUNTEERING;
            case ExpenseSubTypeEnumDto.WORK_OTHER_COMPANIES:
                return ExpenseSubTypeEnum.WORK_OTHER_COMPANIES;
            case ExpenseSubTypeEnumDto.QUALITY:
                return ExpenseSubTypeEnum.QUALITY;
            case ExpenseSubTypeEnumDto.PERSONNEL:
                return ExpenseSubTypeEnum.PERSONNEL;
            default:
                return null;
        }
    }

    mapToDto(statusEnum: ExpenseSubTypeEnum): ExpenseSubTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (statusEnum) {
            case ExpenseSubTypeEnum.AUDITING:
                return ExpenseSubTypeEnumDto.AUDITING;
            case ExpenseSubTypeEnum.COMPUTER_EQUIPMENT:
                return ExpenseSubTypeEnumDto.COMPUTER_EQUIPMENT;
            case ExpenseSubTypeEnum.CONFERENCES_LECTURES:
                return ExpenseSubTypeEnumDto.CONFERENCES_LECTURES;
            case ExpenseSubTypeEnum.CONSULTING_SERVICES:
                return ExpenseSubTypeEnumDto.CONSULTING_SERVICES;
            case ExpenseSubTypeEnum.CONSUMABLES:
                return ExpenseSubTypeEnumDto.CONSUMABLES;
            case ExpenseSubTypeEnum.EXPENSES_INCURRED_BENEFICIARIES:
                return ExpenseSubTypeEnumDto.EXPENSES_INCURRED_BENEFICIARIES;
            case ExpenseSubTypeEnum.FURNITURE:
                return ExpenseSubTypeEnumDto.FURNITURE;
            case ExpenseSubTypeEnum.INSURANCE:
                return ExpenseSubTypeEnumDto.INSURANCE;
            case ExpenseSubTypeEnum.LABOR_MANAGEMENT_LICENSES:
                return ExpenseSubTypeEnumDto.LABOR_MANAGEMENT_LICENSES;
            case ExpenseSubTypeEnum.LIABILITY_INSURANCE:
                return ExpenseSubTypeEnumDto.LIABILITY_INSURANCE;
            case ExpenseSubTypeEnum.MATERIALS_FOR_ACTIVITIES:
                return ExpenseSubTypeEnumDto.MATERIALS_FOR_ACTIVITIES;
            case ExpenseSubTypeEnum.OCCASIONAL_COLLABORATIONS:
                return ExpenseSubTypeEnumDto.OCCASIONAL_COLLABORATIONS;
            case ExpenseSubTypeEnum.OTHERS_INVESTMENT_EXPENSES:
                return ExpenseSubTypeEnumDto.OTHERS_INVESTMENT_EXPENSES;
            case ExpenseSubTypeEnum.OTHERS_MINOR_EXPENSES:
                return ExpenseSubTypeEnumDto.OTHERS_MINOR_EXPENSES;
            case ExpenseSubTypeEnum.REAL_ESTATE:
                return ExpenseSubTypeEnumDto.REAL_ESTATE;
            case ExpenseSubTypeEnum.RENT:
                return ExpenseSubTypeEnumDto.RENT;
            case ExpenseSubTypeEnum.SOFTWARE_LICENSES:
                return ExpenseSubTypeEnumDto.SOFTWARE_LICENSES;
            case ExpenseSubTypeEnum.THIRD_PARTY_ACCOUNT:
                return ExpenseSubTypeEnumDto.THIRD_PARTY_ACCOUNT;
            case ExpenseSubTypeEnum.THIRD_PARTY_SERVICES:
                return ExpenseSubTypeEnumDto.THIRD_PARTY_SERVICES;
            case ExpenseSubTypeEnum.TRANSPORT_OF_GOODS_AND_IMMOBILITIES:
                return ExpenseSubTypeEnumDto.TRANSPORT_OF_GOODS_AND_INMOVILITIES;
            case ExpenseSubTypeEnum.UTILITIES:
                return ExpenseSubTypeEnumDto.UTILITIES;
            case ExpenseSubTypeEnum.VOLUNTEERING:
                return ExpenseSubTypeEnumDto.VOLUNTEERING;
            case ExpenseSubTypeEnum.WORK_OTHER_COMPANIES:
                return ExpenseSubTypeEnumDto.WORK_OTHER_COMPANIES;
            case ExpenseSubTypeEnum.QUALITY:
                return ExpenseSubTypeEnumDto.QUALITY;
            case ExpenseSubTypeEnum.PERSONNEL:
                return ExpenseSubTypeEnumDto.PERSONNEL;
            case ExpenseSubTypeEnum.NONE:
                return ExpenseSubTypeEnumDto.NONE;
        }
    }
}
