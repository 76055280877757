import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum TransportTypeEnumDto {
    NONE = "NONE",
    CAR = "CAR",
    BUS = "BUS",
    METRO = "METRO",
    TAXI = "TAXI",
    PLANE = "PLANE",
    OTHER = "OTHER",
}
export class TransportTypeDto {
    @IsEnum(TransportTypeEnumDto)
    @IsNotEmpty()
    key!: TransportTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
