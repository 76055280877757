import {
    AuthenticatedUser,
    AuthenticatedUserPermissions,
} from "@authentication/domain/models/authenticated-user.model";
import { LanguageIsoCodeEnumMapper } from "@core/data/mappers/language-iso-code-enum.mapper";
import { LanguageIsoCodeEnum } from "@core/domain/models/language.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { CurrentUserDto } from "@user/data/dto/current-user.dto";
import { UserRoleEnum } from "@user/domain/models/user-role.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { UserRoleEnumDto } from "../dto/user-role.dto";
import { UserRoleEnumMapper } from "./user-role-enum.mapper";

@injectable()
export class AuthenticatedUserMapper {
    constructor(
        @inject(UserRoleEnumMapper)
        private readonly userRoleEnumMapper: UserRoleEnumMapper,
        @inject(LanguageIsoCodeEnumMapper)
        private readonly languageIsoCodeEnumMapper: LanguageIsoCodeEnumMapper,
    ) {}
    map(currentUserDto: CurrentUserDto): Nullable<AuthenticatedUser> {
        const errors = validateSync(currentUserDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        if (
            currentUserDto.rol !== UserRoleEnumDto.SuperAdmin &&
            (!currentUserDto.entity || !currentUserDto.entity_data)
        )
            return null;

        const userRole = this.userRoleEnumMapper.map(currentUserDto.rol);

        let language: Nullish<LanguageIsoCodeEnum> = undefined;
        if (currentUserDto.language) {
            language = this.languageIsoCodeEnumMapper.map(
                currentUserDto.language,
            );

            if (language === null) return null;
        }

        if (!userRole) return null;

        const permissions: AuthenticatedUserPermissions = {
            entity: {
                create: userRole === UserRoleEnum.SuperAdmin,
                edit:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin,
                delete: userRole === UserRoleEnum.SuperAdmin,

                view:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin ||
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    userRole === UserRoleEnum.Professional,
            },
            user: {
                create:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin,
                edit:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin,
                delete:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin,
                view:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin ||
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    userRole === UserRoleEnum.Professional,
            },
            beneficiary: {
                create:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin ||
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    userRole === UserRoleEnum.Professional,
                edit:
                    (userRole === UserRoleEnum.SuperAdmin &&
                        !!currentUserDto.entity) ||
                    userRole === UserRoleEnum.EntityAdmin ||
                    userRole === UserRoleEnum.Professional,
                delete:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin ||
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    userRole === UserRoleEnum.Professional,
                view:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin ||
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    userRole === UserRoleEnum.Professional,
            },
            proceeding: {
                create:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin ||
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    userRole === UserRoleEnum.Professional,
                edit:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin ||
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    userRole === UserRoleEnum.Professional,
                delete:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin,
                view:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin ||
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    userRole === UserRoleEnum.Professional,
            },
            project: {
                create:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin,
                edit:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin,
                delete:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin,
                view:
                    userRole === UserRoleEnum.SuperAdmin ||
                    userRole === UserRoleEnum.EntityAdmin,
            },
        };

        return new AuthenticatedUser(
            currentUserDto.id,
            currentUserDto.email,
            currentUserDto.first_name ?? "",
            userRole,
            currentUserDto.entity ?? undefined,
            currentUserDto.entity_data?.name ?? undefined,
            language,
            permissions,
        );
    }
}
