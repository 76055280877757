import { AuthenticationRepository } from "@authentication/data/repositories/authentication.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class LoginRecoveryUseCase {
    constructor(
        @inject(AuthenticationRepository)
        private readonly authenticationRepository: AuthenticationRepository,
    ) {}

    async execute(email: string): Promise<boolean> {
        const result = await this.authenticationRepository.resetPassword(email);

        return result.fold(
            (error) => {
                ToastManagerStore.error(error.message);

                return false;
            },
            () => true,
        );
    }
}
