import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectAuditoryRepository } from "@entity/data/repositories/project-auditory.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteProjectAuditoryUseCase {
    constructor(
        @inject(ProjectAuditoryRepository)
        private readonly projectAuditoryRepository: ProjectAuditoryRepository,
    ) {}

    async execute(projectAuditoryId: number): Promise<void> {
        const result =
            await this.projectAuditoryRepository.delete(projectAuditoryId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        ToastManagerStore.success();
    }
}
