import { DocumentCategory } from "@core/domain/models/documents-category.model";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { LocalMapper } from "@entity/data/mappers/locals/local.mapper";
import { Local } from "@entity/domain/models/locals/local.model";
import { CreateLocalUseCase } from "@entity/domain/usecases/locals/create-local.usecase";
import { DeleteLocalUseCase } from "@entity/domain/usecases/locals/delete-local.usecase";
import { EditLocalUseCase } from "@entity/domain/usecases/locals/edit-local.usecase";
import { GetAllLocalsUseCase } from "@entity/domain/usecases/locals/get-all-locals.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { LocalFormValuesValidated } from "./form/local.form";

@injectable()
export class LocalTabViewModel extends BaseViewModel {
    constructor(
        @inject(GetAllLocalsUseCase)
        private readonly getAllLocalsUseCase: GetAllLocalsUseCase,

        @inject(CreateLocalUseCase)
        private readonly createLocalUseCase: CreateLocalUseCase,

        @inject(EditLocalUseCase)
        private readonly editLocalUseCase: EditLocalUseCase,

        @inject(DeleteLocalUseCase)
        private readonly deleteLocalUseCase: DeleteLocalUseCase,

        @inject(LocalMapper)
        private readonly localMapper: LocalMapper,
    ) {
        super();
        makeObservable(this);
    }

    @observable
    initialLoading = true;

    @observable
    locals: Local[] = [];

    @observable
    addLocalModalOpen = false;

    @observable
    editLocalModalOpen = false;

    @observable
    localToEdit: Undefinable<Local> = undefined;

    @observable
    documentCategories: DocumentCategory[] = [];

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    private async initViewData(): Promise<void> {
        await Promise.all([this.getAllLocals()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllLocals(): Promise<void> {
        const locals = await this.getAllLocalsUseCase.execute();

        runInAction(() => {
            this.locals = locals;
        });
    }

    @action
    setAddLocalModalOpen(open: boolean): void {
        this.addLocalModalOpen = open;
    }

    @action
    closeEditLocalModal(): void {
        this.editLocalModalOpen = false;
    }

    @action
    openEditLocalModal(local: Undefinable<Local>): void {
        this.localToEdit = local;
        this.editLocalModalOpen = true;
    }

    async reloadLocals(): Promise<void> {
        LoadLayoutStore.start();
        await this.getAllLocals();
        LoadLayoutStore.finish();
    }

    async deleteLocal(localId: number): Promise<void> {
        LoadLayoutStore.start();

        const success = await this.deleteLocalUseCase.execute(localId);
        if (success) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async createLocal(local: LocalFormValuesValidated): Promise<void> {
        LoadLayoutStore.start();

        const createEntityResult = await this.createLocalUseCase.execute(
            this.localMapper.mapFromFormToCreate(local),
        );

        if (createEntityResult) ToastManagerStore.success();

        LoadLayoutStore.finish();
    }

    async editLocal(local: LocalFormValuesValidated): Promise<void> {
        LoadLayoutStore.start();

        const editLocalResult = await this.editLocalUseCase.execute(
            this.localMapper.mapFromFormToEdit(local),
        );

        if (editLocalResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }
}
