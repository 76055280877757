import { Pagination } from "@core/domain/models/pagination";
import { inject, injectable } from "inversify";
import { BeneficiaryRepository } from "../../data/repositories/beneficiary.repository";
import { BeneficiariesSummary } from "../models/beneficiaries-summary.model";
import { BeneficiarySearchFilters } from "../models/beneficiary-search-filters";

@injectable()
export class SearchBeneficiariesByUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: BeneficiarySearchFilters,
    ): Promise<BeneficiariesSummary> {
        const beneficiariesResult = await this.beneficiaryRepository.getAllBy(
            pagination,
            filters,
        );

        return beneficiariesResult.fold(
            () => new BeneficiariesSummary([], 0),
            (beneficiaries) => beneficiaries,
        );
    }
}
