import { inject, injectable } from "inversify";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import { ServiceDatasource } from "@entity/data/datasources/service.datasource";
import { Service } from "@entity/domain/models/service.model";
import { Services } from "@entity/domain/models/services.model";

@injectable()
export class ServiceRepository {
    constructor(
        @inject(ServiceDatasource)
        private readonly serviceDatasource: ServiceDatasource,
    ) {}

    async getAll(): Promise<Either<FallbackError, Service[]>> {
        const servicesResult = await this.serviceDatasource.fetchAllPaginated();

        return servicesResult
            .mapLeft(() => new FallbackError())
            .map((services) => services.services);
    }

    async getAllPaginated(
        limit: number,
        offset: number,
    ): Promise<Either<FallbackError, Services>> {
        const servicesResult = await this.serviceDatasource.fetchAllPaginated(
            limit,
            offset,
        );

        return servicesResult.mapLeft(() => new FallbackError());
    }
}
