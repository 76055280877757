import { EmploymentRelevantCharacteristics } from "@beneficiary/domain/models/employment-situation-relevant-characteristics.enum";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { AssociateServiceToDependenceDto } from "../dto/associate-service-to-dependence.enum.dto";
import { EmploymentRelevantCharacteristicsDto } from "../dto/employment-situation-relevant-characteristics.dto";
import { EmploymentCharacteristicsEnumMapper } from "./employment-characteristics-enum.mapper";

@injectable()
export class EmploymentCharacteristicsMapper {
    constructor(
        @inject(EmploymentCharacteristicsEnumMapper)
        private readonly employmentCharacteristicsEnumMapper: EmploymentCharacteristicsEnumMapper,
    ) {}

    map(
        employmentChracteristic: EmploymentRelevantCharacteristicsDto,
    ): Nullable<EmploymentRelevantCharacteristics> {
        const errors = validateSync(AssociateServiceToDependenceDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const employmentChracteristicId =
            this.employmentCharacteristicsEnumMapper.map(
                employmentChracteristic.key,
            );

        if (!employmentChracteristicId) return null;

        return new EmploymentRelevantCharacteristics(
            employmentChracteristicId,
            employmentChracteristic.value,
        );
    }
}
