import { inject, injectable } from "inversify";
import { ProjectExpenseRepository } from "../../../data/repositories/project-expense.repository";
import { PaymentMethodType } from "../../models/payment-method-type.model";

@injectable()
export class GetAllPaymentMethodTypesUseCase {
    constructor(
        @inject(ProjectExpenseRepository)
        private readonly projectExpenseRepository: ProjectExpenseRepository,
    ) {}

    async execute(): Promise<PaymentMethodType[]> {
        const paymentMethodTypesResult =
            await this.projectExpenseRepository.getAllPaymentMethodTypes();

        return paymentMethodTypesResult.fold(
            () => [],
            (paymentMethodTypes) => paymentMethodTypes,
        );
    }
}
