import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectRepository } from "@project/data/repositories/project.repository";
import { inject, injectable } from "inversify";
import { EditProject } from "../models/edit-project.model";

@injectable()
export class EditProjectUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(project: EditProject): Promise<boolean> {
        const editResult = await this.projectRepository.edit(project);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.isRight();
    }
}
