import { inject, injectable } from "inversify";
import { EditUser } from "../../domain/models/edit-user.model";
import { UserFormValuesValidated } from "../../presentation/components/user-form/user-form.component";
import { EditUserBody } from "../dto/edit-user.body";
import { UserRoleEnumMapper } from "./user-role-enum.mapper";

@injectable()
export class EditUserMapper {
    constructor(
        @inject(UserRoleEnumMapper)
        private readonly userRoleEnumMapper: UserRoleEnumMapper,
    ) {}
    mapFromUserFormValues(
        userId: number,
        userForm: UserFormValuesValidated,
    ): EditUser {
        if (!userForm.role) throw new Error("Role and entity are required");
        const editUser: EditUser = {
            id: userId,
            email: userForm.email,
            role: userForm.role,
            entityId: userForm.entityId,
            relatedEmployeeId: userForm.relatedEmployeeId ?? undefined,
            isActive: userForm.isActive,
        };
        return editUser;
    }

    mapToDto(editUser: EditUser): EditUserBody {
        const rol = this.userRoleEnumMapper.mapToDto(editUser.role);
        const editBody: EditUserBody = {
            id: editUser.id,
            email: editUser.email,
            rol,
            entity: editUser.entityId,
            related_person: editUser.relatedEmployeeId,
            is_active: editUser.isActive,
        };
        return editBody;
    }
}
