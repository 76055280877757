export enum QualityCertificateTypeEnum {
    Iso260002010 = "iso_26000_2010",
    Iso90012015 = "iso_9001_2015",
    Efqm = "efqm",
    ManagementExcellenceClub = "management_excellence_club",
    Icong = "icong",
    FoundationLoyalty = "foundation_loyalty",
    MasmadridCertification = "masmadrid_certification",
    RscCertificate = "rsc_certificate",
    OtherAccreditingCompaniesCertificates = "other_accrediting_companies_certificates",
    QualityCertificateOther = "quality_certificate_other",
}

export class QualityCertificateType {
    constructor(
        public readonly id: QualityCertificateTypeEnum,
        public readonly label: string,
    ) {}
}
