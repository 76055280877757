import { Nullable } from "@core/domain/types/nullable.type";
import { convertValueField } from "@core/domain/types/undefinable.type";
import { CreateCreditorBody } from "@entity/data/dto/creditor/create-creditor.body";
import type { CreditorDto } from "@entity/data/dto/creditor/creditor.dto";
import { EditCreditorBody } from "@entity/data/dto/creditor/edit-creditor.body";
import {
    CreateCreditor,
    Creditor,
    CreditorAddress,
    CreditorContact,
    CreditorEconomicData,
    EditCreditor,
} from "@entity/domain/models/creditor/creditor.model";
import { CreditorFormValuesValidated } from "@entity/presentation/component/creditor-form/creditor-form.component";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class CreditorMapper {
    map(creditorDto: CreditorDto): Nullable<Creditor> {
        const errors = validateSync(creditorDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        if (!creditorDto.id) throw new Error("Creditor id is required");

        const address: CreditorAddress = {
            street: creditorDto.address_street,
            number: creditorDto.address_number,
            letter: creditorDto.address_letter ?? undefined,
            floor: creditorDto.address_floor ?? undefined,
            stairs: creditorDto.address_stairs ?? undefined,
            postalCode: creditorDto.postal_code,
        };

        const contact = {
            contactPerson: creditorDto.contact_person,
            phone: creditorDto.phone,
            email: creditorDto.email,
            emailRepresentative: creditorDto.email_representative ?? undefined,
        };

        const economicData = {
            debtDescription: creditorDto.debt_description,
            debtTotal: creditorDto.debt_total,
            debtPending: creditorDto.pending_amount_debt,
        };

        const creditor = new Creditor(
            creditorDto.id,
            creditorDto.name,
            creditorDto.type,
            creditorDto.category,
            creditorDto.identification_number,
            address,
            contact,
            economicData,
            creditorDto.entity,
        );

        return creditor;
    }

    mapToDto(creditor: Creditor): CreditorDto {
        return {
            id: creditor.id,
            name: creditor.name,
            type: creditor.type,
            category: creditor.category,
            identification_number: creditor.nieNif,
            address_street: creditor.address.street,
            address_number: creditor.address.number,
            address_letter: creditor.address.letter ?? null,
            address_floor: creditor.address.floor ?? null,
            address_stairs: creditor.address.stairs ?? null,
            postal_code: creditor.address.postalCode,
            contact_person: creditor.contact.contactPerson,
            phone: creditor.contact.phone,
            email: creditor.contact.email,
            email_representative: creditor.contact.emailRepresentative ?? null,
            debt_description: creditor.economicData.debtDescription,
            debt_total: creditor.economicData.debtTotal,
            pending_amount_debt: creditor.economicData.debtPending,
            entity: creditor.entityId,
        };
    }

    mapFromFormToCreate(
        creditorForm: CreditorFormValuesValidated,
    ): CreateCreditor {
        if (!creditorForm.entityId) throw new Error("Entity id is required");

        const creditorAddress: CreditorAddress = {
            street: creditorForm.street,
            number: creditorForm.number,
            letter: creditorForm.letter,
            floor: creditorForm.floor,
            stairs: creditorForm.stairs,
            postalCode: creditorForm.postalCode,
        };

        const creditorContact: CreditorContact = {
            contactPerson: creditorForm.contactPerson,
            phone: creditorForm.phone,
            email: creditorForm.email,
            emailRepresentative: creditorForm.emailRepresentative,
        };

        const creditorEconomicData: CreditorEconomicData = {
            debtDescription: creditorForm.debtDescription,
            debtTotal: creditorForm.debtTotal,
            debtPending: creditorForm.debtPending,
        };

        return new CreateCreditor(
            0,
            creditorForm.name,
            creditorForm.type,
            creditorForm.category,
            creditorForm.nieNif,
            creditorAddress,
            creditorContact,
            creditorEconomicData,
            creditorForm.entityId,
        );
    }

    mapFromFormToEdit(creditorForm: CreditorFormValuesValidated): EditCreditor {
        if (!creditorForm.id) throw new Error("Creditor id is required");
        if (!creditorForm.entityId) throw new Error("Entity id is required");

        const creditorAddress: CreditorAddress = {
            street: creditorForm.street,
            number: creditorForm.number,
            letter: creditorForm.letter,
            floor: creditorForm.floor,
            stairs: creditorForm.stairs,
            postalCode: creditorForm.postalCode,
        };

        const creditorContact: CreditorContact = {
            contactPerson: creditorForm.contactPerson,
            phone: creditorForm.phone,
            email: creditorForm.email,
            emailRepresentative: creditorForm.emailRepresentative,
        };

        const creditorEconomicData: CreditorEconomicData = {
            debtDescription: creditorForm.debtDescription,
            debtTotal: creditorForm.debtTotal,
            debtPending: creditorForm.debtPending,
        };

        return new EditCreditor(
            creditorForm.id,
            creditorForm.name,
            creditorForm.type,
            creditorForm.category,
            creditorForm.nieNif,
            creditorAddress,
            creditorContact,
            creditorEconomicData,
            creditorForm.entityId,
        );
    }

    mapToCreate(creditor: CreateCreditor): CreateCreditorBody {
        return {
            name: creditor.name,
            type: creditor.type,
            category: creditor.category,
            identification_number: creditor.nieNif,
            address_street: creditor.address.street,
            address_number: creditor.address.number,
            address_letter: creditor.address.letter ?? null,
            address_floor: creditor.address.floor ?? null,
            address_stairs: creditor.address.stairs ?? null,
            postal_code: creditor.address.postalCode,
            contact_person: creditor.contact.contactPerson,
            phone: creditor.contact.phone,
            email: creditor.contact.email,
            email_representative: creditor.contact.emailRepresentative ?? null,
            debt_description: creditor.economicData.debtDescription,
            debt_total: creditor.economicData.debtTotal,
            pending_amount_debt: creditor.economicData.debtPending,
            entity: creditor.entityId,
        };
    }

    mapToEdit(creditor: Creditor): EditCreditorBody {
        return {
            id: creditor.id,
            name: creditor.name,
            type: creditor.type,
            category: creditor.category,
            identification_number: creditor.nieNif,
            address_street: creditor.address.street,
            address_number: creditor.address.number,
            address_letter: convertValueField(creditor.address.letter),
            address_floor: convertValueField(creditor.address.floor),
            address_stairs: convertValueField(creditor.address.stairs),
            postal_code: creditor.address.postalCode,
            contact_person: creditor.contact.contactPerson,
            phone: creditor.contact.phone,
            email: creditor.contact.email,
            email_representative: convertValueField(
                creditor.contact.emailRepresentative,
            ),
            debt_description: creditor.economicData.debtDescription,
            debt_total: creditor.economicData.debtTotal,
            pending_amount_debt: creditor.economicData.debtPending,
            entity: creditor.entityId,
        };
    }
}
