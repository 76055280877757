import { HttpUnauthorizedError } from "@core/data/infrastructures/http/errors/http-unauthorized.error";
import { EventBusRepository } from "@core/data/repositories/event-bus.repository";
import { UnauthorizedEventBus } from "@core/domain/models/event-bus.model";
import { Either } from "@core/domain/types/either";
import axios, { Axios, AxiosError, AxiosResponse } from "axios";
import { inject, injectable } from "inversify";
import { AxiosHttpConfig } from "./axios-http-config";
import { HttpFailedRequestError } from "./errors/http-failed-request.error";
import { HttpRejectedRequestError } from "./errors/http-rejected-request.error";
import { HttpError } from "./errors/http.error";
import { Http } from "./http";
import { HttpConfig } from "./http-config";
import { HttpErrorCodeEnum, HttpErrorResponse } from "./http-error-response";
import { HttpResponse } from "./http-response";

@injectable()
export class AxiosHttp implements Http {
    private axiosInstance: Axios;

    private abortController: AbortController = new AbortController();

    constructor(
        @inject(AxiosHttpConfig)
        private readonly axiosHttpConfig: AxiosHttpConfig,
        @inject(EventBusRepository)
        private readonly eventBusRepository: EventBusRepository,
    ) {
        this.axiosInstance = axios.create({
            baseURL: this.axiosHttpConfig.apiUrl,
            signal: this.abortController.signal,
            withCredentials: true,
        });

        this.initResponseInterceptor();
    }

    private initResponseInterceptor(): void {
        this.axiosInstance.interceptors.response.use<
            // @ts-expect-error: This will be fixed in axios
            Either<HttpError, HttpResponse<unknown>>
        >(
            this.onResponseFulfilledInterceptor.bind(this),
            this.onResponseRejectedInterceptor.bind(this),
        );
    }

    private onResponseFulfilledInterceptor(
        response: AxiosResponse,
    ): Either<HttpError, HttpResponse<unknown>> {
        return Either.Right<HttpError, HttpResponse<unknown>>({
            // eslint-disable-next-line id-blacklist
            data: response.data,
            statusText: response.statusText,
            status: response.status,
        });
    }

    private async onResponseRejectedInterceptor(
        error: unknown,
    ): Promise<Either<HttpError, HttpResponse<unknown>> | undefined> {
        if (error instanceof AxiosError) {
            const axiosError: AxiosError<HttpErrorResponse> = error;

            // Responded request but failed
            if (axiosError.response) {
                if (
                    axiosError.response.status ===
                    HttpUnauthorizedError.HTTP_STATUS_CODE
                ) {
                    if (
                        axiosError.response.data.code ===
                        HttpErrorCodeEnum.InvalidToken
                    ) {
                        this.eventBusRepository.emit(
                            new UnauthorizedEventBus(),
                        );
                    }

                    return Either.Left(new HttpUnauthorizedError());
                }

                return Either.Left(
                    new HttpFailedRequestError(
                        axiosError.response.status,
                        axiosError.response.data.code,
                        axiosError.response.data.data,
                    ),
                );
            }

            // Unresponded request (rejected)
            return Either.Left(
                new HttpRejectedRequestError(axiosError.message),
            );
        } else if (error instanceof Either) {
            // SOLO POSIBLE SI HEMOS OBTENIDO ERROR AL OBTENER NUEVO ACCESS TOKEN
            return error;
        }
    }

    cancel(): void {
        this.abortController.abort();
    }

    async delete(
        endpoint: string,
        config?: HttpConfig,
    ): Promise<Either<HttpError, HttpResponse<never>>> {
        return this.axiosInstance.delete(
            endpoint,
            this.axiosHttpConfig.fromHttpConfig(config),
        );
    }

    async get<R>(
        endpoint: string,
        config?: HttpConfig,
    ): Promise<Either<HttpError, HttpResponse<R>>> {
        return this.axiosInstance.get<R, Either<HttpError, HttpResponse<R>>>(
            endpoint,
            this.axiosHttpConfig.fromHttpConfig(config),
        );
    }

    async post<R, B>(
        endpoint: string,
        body?: B,
        config?: HttpConfig,
    ): Promise<Either<HttpError, HttpResponse<R>>> {
        return this.axiosInstance.post<
            R,
            Either<HttpError, HttpResponse<R>>,
            B
        >(endpoint, body, this.axiosHttpConfig.fromHttpConfig(config));
    }

    async put<R, B>(
        endpoint: string,
        body?: B,
        config?: HttpConfig,
    ): Promise<Either<HttpError, HttpResponse<R>>> {
        return this.axiosInstance.put<R, Either<HttpError, HttpResponse<R>>, B>(
            endpoint,
            body,
            this.axiosHttpConfig.fromHttpConfig(config),
        );
    }

    async patch<R, B>(
        endpoint: string,
        body?: B,
        config?: HttpConfig,
    ): Promise<Either<HttpError, HttpResponse<R>>> {
        return this.axiosInstance.patch<
            R,
            Either<HttpError, HttpResponse<R>>,
            B
        >(endpoint, body, this.axiosHttpConfig.fromHttpConfig(config));
    }
}
