import {
    Address,
    CreateAddress,
    EditAddress,
} from "@beneficiary/domain/models/address.model";
import { AddressForm } from "@beneficiary/presentation/components/beneficiary-form/address/address-form.view";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    AddressDto,
    CreateAddressBody,
    EditAddressBody,
} from "../../dto/address.dto";
import { DistrictMapper } from "../district.mapper";

@injectable()
export class AddressMapper {
    constructor(
        @inject(DistrictMapper)
        private readonly districtMapper: DistrictMapper,
    ) {}

    map(addressDto: AddressDto): Nullable<Address> {
        const errors = validateSync(addressDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const address = new Address(
            addressDto.id,
            addressDto.address,
            addressDto.number,
            addressDto.postal_code,
            addressDto.city,
        );

        if (addressDto.district && addressDto.district_data) {
            address.district = this.districtMapper.map(
                addressDto.district_data,
            );
        }
        if (addressDto.stair) address.stair = addressDto.stair;
        if (addressDto.door) address.door = addressDto.door;
        if (addressDto.floor) address.floor = addressDto.floor;
        if (addressDto.letter) address.letter = addressDto.letter;

        return address;
    }

    mapToCreateBody(address: CreateAddress): CreateAddressBody {
        const addressDto: CreateAddressBody = {
            address: address.address,
            number: address.number,
            postal_code: address.postalCode,
            city: address.city,
        };

        if (address.district) addressDto.district = address.district;
        if (address.stair) addressDto.stair = address.stair;
        if (address.door) addressDto.door = address.door;
        if (address.floor) addressDto.floor = address.floor;
        if (address.letter) addressDto.letter = address.letter;

        return addressDto;
    }

    mapToEditBody(address: EditAddress): EditAddressBody {
        const addressDto: EditAddressBody = {
            id: address.id,
            address: address.address,
            number: address.number,
            postal_code: address.postalCode,
            city: address.city,
        };

        if (address.district) addressDto.district = address.district;
        if (address.stair) addressDto.stair = address.stair;
        if (address.door) addressDto.door = address.door;
        if (address.floor) addressDto.floor = address.floor;
        if (address.letter) addressDto.letter = address.letter;

        return addressDto;
    }

    mapFormToCreate(addressForm: AddressForm): CreateAddress {
        const createAddress: CreateAddress = new CreateAddress(
            addressForm.address,
            addressForm.number,
            addressForm.postalCode,
            addressForm.city,
        );

        if (addressForm.district) createAddress.district = addressForm.district;
        if (addressForm.stair) createAddress.stair = addressForm.stair;
        if (addressForm.door) createAddress.door = addressForm.door;
        if (addressForm.floor) createAddress.floor = addressForm.floor;
        if (addressForm.letter) createAddress.letter = addressForm.letter;

        return createAddress;
    }

    mapFormToEdit(addressForm: AddressForm): EditAddress {
        if (!addressForm.id)
            throw new Error("Id is required to edit an address");

        const editAddress: EditAddress = new EditAddress(
            addressForm.id,
            addressForm.address,
            addressForm.number,
            addressForm.postalCode,
            addressForm.city,
        );

        if (addressForm.district) editAddress.district = addressForm.district;
        if (addressForm.stair) editAddress.stair = addressForm.stair;
        if (addressForm.door) editAddress.door = addressForm.door;
        if (addressForm.floor) editAddress.floor = addressForm.floor;
        if (addressForm.letter) editAddress.letter = addressForm.letter;

        return editAddress;
    }
}
