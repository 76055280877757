import { OrderFieldsQuery, OrderQuery } from "@core/data/dto/order.query";
import { OrderDirection, OrderField } from "@core/domain/models/order.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { ObjectLike } from "@core/domain/types/object-like.type";
import { injectable } from "inversify";

@injectable()
export class OrderMapper {
    mapToDto<Model extends ObjectLike<Model>, Dto extends ObjectLike<Dto>>(
        field: OrderField<Model>,
        direction: OrderDirection,
        fieldsMap: Record<OrderField<Model>, Nullable<OrderFieldsQuery<Dto>>>,
    ): Nullable<OrderQuery<Dto>> {
        const mappedField: Nullish<OrderFieldsQuery<Dto>> = fieldsMap[field];

        if (!mappedField) {
            console.error(`Mising field ${field} in fieldsMap ${fieldsMap}`);

            return null;
        }

        if (direction === "desc") return `-${mappedField}`;

        return mappedField;
    }
}
