import { RegconRegisterRepository } from "@entity/data/repositories/regcon-register.repository";
import { CCAA } from "@entity/domain/models/ccaa.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllCCAAUseCase {
    constructor(
        @inject(RegconRegisterRepository)
        private readonly regconRegisterRepository: RegconRegisterRepository,
    ) {}

    async execute(): Promise<CCAA[]> {
        const ccaaResults = await this.regconRegisterRepository.getAllCCAA();

        return ccaaResults.fold(
            () => [],
            (ccaa) => ccaa,
        );
    }
}
