import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import {
    ProjectEmployeeDedicationDto,
    ProjectEmployeeDedicationsDto,
} from "@project/data/dto/project-employee-dedication/project-employee-dedication.dto";
import { ProjectEmployeesQuery } from "@project/data/dto/project-employee/project-employees.dto";
import { ProjectEmployeeDedicationMapper } from "@project/data/mappers/project-employee-dedication.mapper";
import { ProjectEmployeeDedicationsMapper } from "@project/data/mappers/project-employee-dedications.mapper";
import { CreateProjectEmployeeDedicationError } from "@project/domain/errors/create-project-employee-dedication.error";
import { EditProjectEmployeeDedicationError } from "@project/domain/errors/edit-project-employee-dedication.error";
import { InvalidDedicationDatesError } from "@project/domain/errors/invalid-dedication-dates.error";
import {
    CreateProjectEmployeeDedication,
    ProjectEmployeeDedication,
    ProjectEmployeeDedications,
    ProjectEmployeeDedicationsSearchFilters,
    UpdateProjectEmployeeDedication,
} from "@project/domain/models/project-employee-dedications.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class ProjectEmployeeDedicationDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private http: Http,
        @inject(ProjectEmployeeDedicationMapper)
        private readonly projectEmployeeDedicationMapper: ProjectEmployeeDedicationMapper,
        @inject(ProjectEmployeeDedicationsMapper)
        private readonly projectEmployeeDedicationsMapper: ProjectEmployeeDedicationsMapper,
    ) {}

    async fetchById(
        id: number,
    ): Promise<Either<FallbackError, ProjectEmployeeDedication>> {
        const projectEmployeesDedicationResult =
            await this.http.get<ProjectEmployeeDedicationDto>(
                `/projects_employees_dedication/${id}/`,
            );

        return projectEmployeesDedicationResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const projectEmployeeDedication =
                    this.projectEmployeeDedicationMapper.map(
                        plainToClass(
                            ProjectEmployeeDedicationDto,
                            response.data,
                        ),
                    );

                if (!projectEmployeeDedication)
                    return Either.Left(new FallbackError());

                return Either.Right(projectEmployeeDedication);
            });
    }

    async fetchBy(
        pagination?: Pagination,
        filters?: ProjectEmployeeDedicationsSearchFilters,
    ): Promise<Either<FallbackError, ProjectEmployeeDedications>> {
        const query: ProjectEmployeesQuery = {};

        if (pagination) {
            query.limit = pagination.pageSize;
            query.offset = pagination.offset;
        }

        if (filters?.projectId) {
            query.project = filters.projectId;
        }
        const projectEmployeesResult =
            await this.http.get<ProjectEmployeeDedicationsDto>(
                "/projects_employees_dedication/",
                {
                    query,
                },
            );

        return projectEmployeesResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.projectEmployeeDedicationsMapper.map(
                    plainToClass(ProjectEmployeeDedicationsDto, response.data),
                ),
            );
    }

    async create(
        createProjectEmployeeDedication: CreateProjectEmployeeDedication,
    ): Promise<
        Either<CreateProjectEmployeeDedicationError, ProjectEmployeeDedication>
    > {
        const projectEmployeeResult =
            await this.http.post<ProjectEmployeeDedicationDto>(
                "/projects_employees_dedication/",
                this.projectEmployeeDedicationMapper.mapToCreateDto(
                    createProjectEmployeeDedication,
                ),
            );

        return projectEmployeeResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                } else if (error instanceof HttpFailedRequestError) {
                    return new InvalidDedicationDatesError();
                }
                return new FallbackError();
            })
            .flatMap((response) => {
                const projectEmployee =
                    this.projectEmployeeDedicationMapper.map(
                        plainToClass(
                            ProjectEmployeeDedicationDto,
                            response.data,
                        ),
                    );

                if (!projectEmployee) return Either.Left(new FallbackError());

                return Either.Right(projectEmployee);
            });
    }

    async update(
        editProjectEmployeeDedication: UpdateProjectEmployeeDedication,
    ): Promise<
        Either<EditProjectEmployeeDedicationError, ProjectEmployeeDedication>
    > {
        const projectEmployeeResult =
            await this.http.patch<ProjectEmployeeDedicationDto>(
                `/projects_employees_dedication/${editProjectEmployeeDedication.id}/`,
                this.projectEmployeeDedicationMapper.mapToEditDto(
                    editProjectEmployeeDedication,
                ),
            );

        return projectEmployeeResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                } else if (error instanceof HttpFailedRequestError) {
                    return new InvalidDedicationDatesError();
                }
                return new FallbackError();
            })
            .flatMap((response) => {
                const projectEmployee =
                    this.projectEmployeeDedicationMapper.map(
                        plainToClass(
                            ProjectEmployeeDedicationDto,
                            response.data,
                        ),
                    );

                if (!projectEmployee) return Either.Left(new FallbackError());

                return Either.Right(projectEmployee);
            });
    }

    async delete(id: number): Promise<Either<FallbackError, true>> {
        const projectEmployeeDedicationResult = await this.http.delete(
            `/projects_employees_dedication/${id}/`,
        );

        return projectEmployeeDedicationResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
