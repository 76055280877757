import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectMaterialRepository } from "@project/data/repositories/project-material.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteProjectMaterialUseCase {
    constructor(
        @inject(ProjectMaterialRepository)
        private readonly projectMaterialRepository: ProjectMaterialRepository,
    ) {}

    async execute(projectMaterialId: number): Promise<void> {
        const result =
            await this.projectMaterialRepository.delete(projectMaterialId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }
    }
}
