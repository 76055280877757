import { BeneficiarySummary } from "@beneficiary/domain/models/beneficiary-summary.model";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { DateTime } from "luxon";
import { StatusEnum } from "./status.model";

export class ProceedingEntity {
    constructor(
        public readonly id: number,
        public name: string,
    ) {}
}

export class ProceedingSummary {
    constructor(
        public id: number,
        public nameProject: string,
        public status: StatusEnum,
        public statusLabel: string,
        public created: DateTime,
        public beneficiaryData: BeneficiarySummary,
        public entity: ProceedingEntity,
        public modifiedDate: Undefinable<DateTime> = undefined,
        public derivationDate: Undefinable<DateTime> = undefined,
    ) {}

    get isDerivation(): boolean {
        return this.derivationDate !== undefined;
    }
}
