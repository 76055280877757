import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { UserRepository } from "../../data/repositories/user.repository";
import { EditUser } from "../models/edit-user.model";

@injectable()
export class EditUserUseCase {
    constructor(
        @inject(UserRepository)
        private readonly userRepository: UserRepository,
    ) {}

    async execute(user: EditUser): Promise<boolean> {
        const editResult = await this.userRepository.edit(user);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.isRight();
    }
}
