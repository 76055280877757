import { Nullable } from "@core/domain/types/nullable.type";
import { CrosscuttingScopeDto } from "@entity/data/dto/crosscutting-scope-enum.dto";
import { CrosscuttingScopeEnumMapper } from "@entity/data/mappers/crosscutting-scope-enum.mapper";
import {
    CrosscuttingScope,
    CrosscuttingScopeEnum,
} from "@entity/domain/models/crosscutting-scope.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class CrosscuttingScopesMapper {
    constructor(
        @inject(CrosscuttingScopeEnumMapper)
        private readonly crosscuttingScopeEnumMapper: CrosscuttingScopeEnumMapper,
    ) {}

    map(
        crosscuttingScopeDto: CrosscuttingScopeDto,
    ): Nullable<CrosscuttingScope> {
        const errors = validateSync(crosscuttingScopeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<CrosscuttingScopeEnum> =
            this.crosscuttingScopeEnumMapper.map(crosscuttingScopeDto.key);

        if (!id) return null;

        return new CrosscuttingScope(id, crosscuttingScopeDto.value);
    }
}
