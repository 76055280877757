import { LoadLayout } from "@core/presentacion/component/feedback/load-layout/load-layout.component";
import { IncSkeleton } from "@core/presentacion/component/feedback/skeleton/inc-skeleton.component";
import { ToastManager } from "@core/presentacion/component/feedback/toast-manager/toast-manager.component";
import { useDidMount } from "@core/presentacion/hook/use-did-mount/use-did-mount.hook";
import { useViewModel } from "@core/presentacion/hook/use-view-model/use-view-model.hook";
import { container } from "@di/inversify.config";
import { LoginRoutes } from "@routes/public/login.routes";
import { App as AntdApp, ConfigProvider } from "antd";
import { Locale } from "antd/es/locale";
import ca_ES from "antd/locale/ca_ES";
import en_US from "antd/locale/en_US";
import es_ES from "antd/locale/es_ES";
import eu_ES from "antd/locale/eu_ES";
import gl_ES from "antd/locale/gl_ES";
import it_IT from "antd/locale/it_IT";
import { observer } from "mobx-react";
import { FC, ReactNode, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { theme } from "../tailwind.config";
import { AppViewModel } from "./app.viewmodel";

const _App: FC = observer(() => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const viewModel = useViewModel(() => container.get(AppViewModel));

    useDidMount(() => {
        viewModel.attachExit(() => {
            navigate(LoginRoutes.LOGIN, { replace: true });
        });
    });

    const locale = useMemo<Locale>(() => {
        if (i18n.language === "en") return en_US;
        else if (i18n.language === "ca") return ca_ES;
        else if (i18n.language === "eu") return eu_ES;
        else if (i18n.language === "gl") return gl_ES;
        else if (i18n.language === "it") return it_IT;
        return es_ES;
    }, [i18n.language]);

    return (
        <ConfigProvider
            key={`${viewModel.entityId}-${viewModel.currentLanguage}`}
            locale={locale}
            theme={{
                token: {
                    // @ts-expect-error: TW
                    fontFamily: theme.extend.fontFamily.sans.join(","),
                    // @ts-expect-error: TW
                    colorPrimary: theme.extend.colors.cyan["500"],
                    // @ts-expect-error: TW
                    colorError: theme.extend.colors["coral-red"],
                    // @ts-expect-error: TW
                    colorBorder: theme.extend.colors.grey["200"],
                    // @ts-expect-error: TW
                    colorBorderSecondary: theme.extend.colors.grey["200"],
                    // @ts-expect-error: TW
                    colorTextDescription: theme.extend.colors.grey["300"],
                    // @ts-expect-error: TW
                    colorLink: theme.extend.colors["cyan-dark"],
                    // @ts-expect-error: TW
                    colorLinkActive: theme.extend.colors.cyan["500"],
                    // @ts-expect-error: TW
                    colorBgContainer: theme.extend.colors.white,
                    colorBgContainerDisabled:
                        // @ts-expect-error: TW
                        theme.extend.colors.grey["100"],
                    borderRadius: 10,
                    // @ts-expect-error: TW
                    colorPrimaryHover: theme.extend.colors.cyan["500"],
                    // @ts-expect-error: TW
                    colorText: theme.extend.colors["cyan-dark"],
                    // @ts-expect-error: TW
                    colorTextHeading: theme.extend.colors["grey-dark"]["950"],
                },
                components: {
                    // @ts-expect-error Valid any style height value
                    Spin: { contentHeight: "100vh" },
                    Button: {
                        borderRadius: 50,
                        // @ts-expect-error: TW
                        defaultHoverBg: theme.extend.colors.cyan["600"],
                    },
                    Input: { borderRadius: 10 },
                    Menu: {
                        // @ts-expect-error: TW
                        darkItemSelectedColor: theme.extend.colors["cyan-dark"],
                        // @ts-expect-error: TW
                        darkItemBg: theme.extend.colors.white,
                        // @ts-expect-error: TW
                        darkItemColor: theme.extend.colors["cyan-dark"],
                        // @ts-expect-error: TW
                        darkItemHoverBg: theme.extend.colors["cyan-dark"],
                        // @ts-expect-error: TW
                        darkItemHoverColor: theme.extend.colors.white,
                        // @ts-expect-error: TW
                        darkGroupTitleColor: theme.extend.colors.white,
                        // @ts-expect-error: TW
                        darkItemSelectedBg: theme.extend.colors.cyan["100"],
                        // @ts-expect-error: TW
                        darkSubMenuItemBg: theme.extend.colors.white,
                        // @ts-expect-error: TW
                        darkSubMenuItemColor: theme.extend.colors.white,
                        // @ts-expect-error: TW
                        groupTitleColor: theme.extend.colors.white,
                    },
                    Tabs: {
                        lineWidth: 3,
                        // @ts-expect-error: TW
                        itemActiveColor: theme.extend.colors.black,
                        // @ts-expect-error: TW
                        itemColor: theme.extend.colors["cyan-dark"],
                        colorBgContainer:
                            // @ts-expect-error: TW
                            theme.extend.colors["grey-dark"]["950"],
                    },
                    Table: {
                        borderRadius: 10,
                        // @ts-expect-error: TW
                        borderColor: theme.extend.colors.grey["200"],
                        // @ts-expect-error: TW
                        headerBg: theme.extend.colors.grey["100"],
                    },
                    Select: {
                        borderRadius: 10,
                        // @ts-expect-error: TW
                        optionSelectedBg: theme.extend.colors.cyan["500"],
                        // @ts-expect-error: TW
                        optionSelectedColor: theme.extend.colors.white,
                    },
                },
            }}
        >
            <AntdApp>
                <ToastManager />
                <div>
                    <LoadLayout>
                        <Outlet />
                    </LoadLayout>
                </div>
            </AntdApp>
        </ConfigProvider>
    );
});

export const App: FC = () => {
    const fallback: ReactNode = <IncSkeleton loading />;

    return (
        <Suspense fallback={fallback}>
            <_App />
        </Suspense>
    );
};
