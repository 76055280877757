import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EditFinancialEntityBudgetModel } from "@project/domain/models/budget/edit-financial-entity-budget.model";
import { FinancialEntityBudget } from "@project/domain/models/budget/financial-entity-budget.model";
import { EditBudgetUseCase } from "@project/domain/usecases/budget/edit-budget.usecase";
import { GetBudgetByIdUseCase } from "@project/domain/usecases/budget/get-budget-by-id.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class EditFinancialEntityBudgetPageViewModel extends BaseViewModel {
    budgetId: Undefinable<number> = undefined;

    @observable
    initialLoading: boolean = true;

    @observable
    budget: Undefinable<FinancialEntityBudget> = undefined;
    constructor(
        @inject(GetBudgetByIdUseCase)
        private readonly getBudgetByIdUseCase: GetBudgetByIdUseCase,
        @inject(EditBudgetUseCase)
        private readonly editBudgetUseCase: EditBudgetUseCase,
    ) {
        super();
        makeObservable(this);
    }
    override async didMount(): Promise<void> {
        await super.didMount();
        await this.initViewData();
    }
    async initViewData(): Promise<void> {
        await this.getBudget();
        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async editBudget(budget: EditFinancialEntityBudgetModel): Promise<void> {
        LoadLayoutStore.start();
        if (this.budgetId) {
            const result = await this.editBudgetUseCase.execute(budget);
            if (result) {
                ToastManagerStore.success();
            }
        }
        LoadLayoutStore.finish();
    }

    async getBudget(): Promise<void> {
        if (!this.budgetId) {
            throw new Error();
        }
        const result = await this.getBudgetByIdUseCase.execute(this.budgetId);
        if (result) {
            runInAction(() => {
                this.budget = result;
            });
        }
    }
}
