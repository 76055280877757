import { Nullable } from "@core/domain/types/nullable.type";
import { CategoryRowBudgetDto } from "@project/data/dto/budget.dto";
import { CreateBudgetCategoryBody } from "@project/data/dto/budget/category/create-budget-category.body";
import { CreateBudgetCategory } from "@project/domain/models/budget/budget-category/create-budget-category.model";
import { BudgetCategory } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class CreateBudgetCategoryMapper {
    map(budgetCategoryDto: CategoryRowBudgetDto): Nullable<BudgetCategory> {
        const errors = validateSync(budgetCategoryDto);
        if (errors.length) {
            console.error(errors);
            return null;
        }
        return new BudgetCategory(
            budgetCategoryDto.id,
            budgetCategoryDto.name,
            budgetCategoryDto.budget,
        );
    }
    mapToDto(
        createBudgetCategory: CreateBudgetCategory,
    ): CreateBudgetCategoryBody {
        return {
            name: createBudgetCategory.name,
            budget: createBudgetCategory.budget,
        };
    }
}
