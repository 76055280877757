import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import {
    AffiliationDto,
    AffiliationsDto,
} from "@entity/data/dto/affiliation/affiliation.dto";
import { CreateAffiliationBody } from "@entity/data/dto/affiliation/create-affiliation.body";
import { EditAffiliationBody } from "@entity/data/dto/affiliation/edit-affiliation.body";
import { AffiliationsMapper } from "@entity/data/mappers/affiliation/affiliations.mapper";
import { CreateAffiliationMapper } from "@entity/data/mappers/affiliation/create-affiliation.mapper";
import { EditAffiliationMapper } from "@entity/data/mappers/affiliation/edit-affiliation.mapper";
import {
    Affiliation,
    Affiliations,
} from "@entity/domain/models/affiliation/affiliation.model";
import { CreateAffiliation } from "@entity/domain/models/affiliation/create-affiliation.model";
import { EditAffiliation } from "@entity/domain/models/affiliation/edit-affiliation.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const ENTITY_ASSOCIATION_PATH = "/entities_affiliations/";

@injectable()
export class AffiliationDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(AffiliationsMapper)
        private readonly affiliationsMapper: AffiliationsMapper,
        @inject(CreateAffiliationMapper)
        private readonly createAffiliationMapper: CreateAffiliationMapper,
        @inject(EditAffiliationMapper)
        private readonly editAffiliationMapper: EditAffiliationMapper,
    ) {}

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, Affiliations>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const affiliationResult = await this.http.get<AffiliationDto>(
            ENTITY_ASSOCIATION_PATH,
            {
                query,
            },
        );

        return affiliationResult.map((response) =>
            this.affiliationsMapper.map(
                plainToClass(AffiliationsDto, response.data),
            ),
        );
    }

    async create(
        newAffiliation: CreateAffiliation,
    ): Promise<Either<ValidationError | FallbackError, Affiliation>> {
        const affiliationBody =
            this.createAffiliationMapper.mapToCreateDto(newAffiliation);

        const affiliationResult = await this.http.post<
            AffiliationDto,
            CreateAffiliationBody
        >(ENTITY_ASSOCIATION_PATH, affiliationBody);

        return affiliationResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const affiliation = this.affiliationsMapper.mapAffiliation(
                    plainToClass(AffiliationDto, response.data),
                );

                if (!affiliation) return Either.Left(new FallbackError());

                return Either.Right(affiliation);
            });
    }

    async edit(
        editAffiliation: EditAffiliation,
    ): Promise<Either<ValidationError | FallbackError, Affiliation>> {
        const editedAffiliation =
            this.editAffiliationMapper.mapToDto(editAffiliation);

        const editAffiliationResult = await this.http.patch<
            AffiliationDto,
            EditAffiliationBody
        >(
            `${ENTITY_ASSOCIATION_PATH}${editAffiliation.id}/`,
            editedAffiliation,
        );

        return editAffiliationResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const affiliation = this.affiliationsMapper.mapAffiliation(
                    plainToClass(AffiliationDto, response.data),
                );

                if (!affiliation) return Either.Left(new FallbackError());

                return Either.Right(affiliation);
            });
    }

    async delete(affiliationId: number): Promise<Either<FallbackError, true>> {
        const deleteAffiliation = await this.http.delete(
            `${ENTITY_ASSOCIATION_PATH}${affiliationId}`,
        );

        return deleteAffiliation
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
