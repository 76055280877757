import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EditEmployeeMapper } from "@entity/data/mappers/employee/edit-employee.mapper";
import { Employee } from "@entity/domain/models/employee/employee.model";
import { EditEmployeeUseCase } from "@entity/domain/usecases/employee/edit-employee.usecase";
import { GetEmployeeByIdUseCase } from "@entity/domain/usecases/employee/get-employee-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { EditEmployeeFormValuesValidated } from "../../../component/employee-form/employee-form.component";

@injectable()
export class EditEmployeePageViewModel extends BaseViewModel {
    employeeId: Undefinable<number> = undefined;

    @observable
    employee: Undefinable<Employee> = undefined;

    @observable
    initiallyLoading: boolean = true;

    @observable
    showAddDedicationModal: boolean = false;

    constructor(
        @inject(EditEmployeeUseCase)
        private readonly editEmployeeUseCase: EditEmployeeUseCase,
        @inject(EditEmployeeMapper)
        private readonly editEmployeeMapper: EditEmployeeMapper,
        @inject(GetEmployeeByIdUseCase)
        private readonly getEmployeeByIdUseCase: GetEmployeeByIdUseCase,
        @inject(UserSessionRepository)
        private readonly userSessionRepository: UserSessionRepository,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getEmployee()]);

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    @action
    setEmployeeId(employeeId: number): void {
        this.employeeId = employeeId;
    }

    @action
    setEmployee(employee: Employee): void {
        this.employee = employee;
    }

    async getEmployee(): Promise<void> {
        if (this.employeeId === undefined) throw Error("Missing employeeId");

        const employee = await this.getEmployeeByIdUseCase.execute(
            this.employeeId,
        );

        if (employee) {
            this.setEmployee(employee);
        }
    }

    async editEmployee(
        values: EditEmployeeFormValuesValidated,
    ): Promise<Nullable<Employee>> {
        const entityId = this.userSessionRepository.userSyncSafe.entityId;

        if (!this.employeeId) throw Error("Missing employeeId");
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const editEmployeeResult = await this.editEmployeeUseCase.execute(
            this.editEmployeeMapper.mapFromEmployeeFormValues(
                this.employeeId,
                entityId,
                values,
            ),
        );

        if (editEmployeeResult) {
            await this.getEmployee();
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return editEmployeeResult;
    }

    @action
    setShowAddDedicationModal(show: boolean): void {
        this.showAddDedicationModal = show;
    }
}
