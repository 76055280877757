import { IncDocument } from "@core/domain/models/inc-document.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { IVAType } from "@entity/domain/models/cost/iva-type.model";
import { GetAllIvaTypesUseCase } from "@entity/domain/usecases/cost/get-all-iva-types.usecase";
import { ExpenseSubTypeSearchFilters } from "@project/domain/models/expenses/expense-search-filters";
import { ExpenseTypeEnum } from "@project/domain/models/expenses/expense-type.model";
import {
    ExpenseSubType,
    ExpenseSubTypeEnum,
} from "@project/domain/models/expenses/sub-type.model";
import { PaymentMethodType } from "@project/domain/models/payment-method-type.model";
import { GetAllProjectExpenseSubTypesUseCase } from "@project/domain/usecases/expenses/get-all-expense-sub-types.usecase";
import { GetAllPaymentMethodTypesUseCase } from "@project/domain/usecases/expenses/get-all-payment-method-types.usecase";
import { Map } from "immutable";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class ExpenseMaintenanceFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    typeSelectedId: Nullable<ExpenseTypeEnum> = null;

    @observable
    paymentMethodTypes: PaymentMethodType[] = [];

    @observable
    subTypeSelectedId: Nullable<ExpenseSubTypeEnum> = null;

    @observable
    subTypeSelectedLabel: string = "";

    @observable
    subTypes: ExpenseSubType[] = [];

    @observable
    ivaTypes: IVAType[] = [];
    documents: Map<number, IncDocument> = Map([]);

    constructor(
        @inject(GetAllPaymentMethodTypesUseCase)
        private readonly getAllPaymentMethodTypesUseCase: GetAllPaymentMethodTypesUseCase,
        @inject(GetAllProjectExpenseSubTypesUseCase)
        private readonly getAllProjectExpensesSubTypesUseCase: GetAllProjectExpenseSubTypesUseCase,
        @inject(GetAllIvaTypesUseCase)
        private readonly getAllIvaTypesUseCase: GetAllIvaTypesUseCase,
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllPaymentMethodTypes(),
            this.getAllExpenseSubTypes(),
            this.getAllIvaTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllIvaTypes(): Promise<void> {
        const ivaTypes = await this.getAllIvaTypesUseCase.execute();

        runInAction(() => {
            this.ivaTypes = ivaTypes;
        });
    }

    async getAllPaymentMethodTypes(): Promise<void> {
        const paymentMethodTypes =
            await this.getAllPaymentMethodTypesUseCase.execute();

        runInAction(() => {
            this.paymentMethodTypes = paymentMethodTypes;
        });
    }

    async getAllExpenseSubTypes(): Promise<void> {
        const filters: ExpenseSubTypeSearchFilters = {
            typeId: this.typeSelectedId,
        };
        const subtypes =
            await this.getAllProjectExpensesSubTypesUseCase.execute(filters);
        runInAction(() => {
            this.subTypes = subtypes;
        });
    }

    async deleteDocument(documentId: number): Promise<void> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(documentUrl);

        LoadLayoutStore.finish();
    }
}
