export class ProjectActivityTask {
    constructor(
        public readonly id: number,
        public description: string,
        public technicalProposalActivity: number,
    ) {}
}

export interface CreateProjectActivityTaskBody {
    description: string;
    technicalProposalActivity: number;
}
