import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum QualityCertificateTypeEnumDto {
    Iso260002010 = "iso_26000_2010",
    Iso90012015 = "iso_9001_2015",
    Efqm = "efqm",
    ManagementExcellenceClub = "management_excellence_club",
    Icong = "icong",
    FoundationLoyalty = "foundation_loyalty",
    MasmadridCertification = "masmadrid_certification",
    RscCertificate = "rsc_certificate",
    OtherAccreditingCompaniesCertificates = "other_accrediting_companies_certificates",
    QualityCertificateOther = "quality_certificate_other",
}

export class QualityCertificateTypeDto {
    @IsEnum(QualityCertificateTypeEnumDto)
    @IsNotEmpty()
    key!: QualityCertificateTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
