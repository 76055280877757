import { ProjectActivityRepository } from "@project/data/repositories/technical-proposal/activity.repository";
import { inject, injectable } from "inversify";
import { ActivitySummary } from "../../models/activity-summary.model";

@injectable()
export class GetAllProjectActivitiesSummaryByUseCase {
    constructor(
        @inject(ProjectActivityRepository)
        private readonly projectActivityRepository: ProjectActivityRepository,
    ) {}

    async execute(projectId: number): Promise<ActivitySummary[]> {
        const activitiesResult =
            await this.projectActivityRepository.getAllActivitiesByProjectIdSummary(
                projectId,
            );

        return activitiesResult.fold(
            () => [],
            (activities) => activities.activitySummary,
        );
    }
}
