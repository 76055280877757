import { InternalNotesRepository } from "@beneficiary/data/repositories/internal-notes.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteInternalNoteUseCase {
    constructor(
        @inject(InternalNotesRepository)
        private readonly internalNotesRepository: InternalNotesRepository,
    ) {}

    async execute(internalNoteId: number): Promise<boolean> {
        const result =
            await this.internalNotesRepository.delete(internalNoteId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
