import { CountryEnumMapper } from "@beneficiary/data/mappers/country-enum.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import { AddressDto } from "@entity/data/dto/address/address.dto";
import { TitularityTypeEnumMapper } from "@entity/data/mappers/titularity-type-enum.mapper";
import { Address } from "@entity/domain/models/address/address.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class AddressMapper {
    constructor(
        @inject(CountryEnumMapper)
        private readonly countryEnumMapper: CountryEnumMapper,
        @inject(TitularityTypeEnumMapper)
        private readonly titularityTypeEnumMapper: TitularityTypeEnumMapper,
    ) {}
    map(addressDto: AddressDto): Nullable<Address> {
        const errors = validateSync(addressDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const country = this.countryEnumMapper.map(addressDto.country);
        if (!country) return null;

        const titularityType = this.titularityTypeEnumMapper.map(
            addressDto.titularity_type,
        );
        if (!titularityType) return null;

        return new Address(
            addressDto.id,
            addressDto.name,
            addressDto.street_name,
            addressDto.zip_code,
            addressDto.town,
            addressDto.province,
            addressDto.entity,
            addressDto.street_number,
            country,
            titularityType,
            addressDto.region,
            addressDto.floor,
            addressDto.door,
            addressDto.stair,
            addressDto.cadastral_value,
            addressDto.local,
            addressDto.principal_address,
        );
    }

    mapToDto(address: Address): AddressDto {
        const addressDto: AddressDto = {
            id: address.id,
            name: address.name,
            street_name: address.streetName,
            zip_code: address.zipCode,
            town: address.town,
            province: address.province,
            entity: address.entity,
            street_number: address.streetNumber,
            country: this.countryEnumMapper.mapToDto(address.country),
            titularity_type: this.titularityTypeEnumMapper.mapToDto(
                address.titularityType,
            ),
            region: address.region ?? null,
            floor: address.floor ?? null,
            door: address.door ?? null,
            stair: address.stair ?? null,
            cadastral_value: address.cadastralValue ?? null,
            local: address.local ?? null,
            principal_address: address.principalAddress ?? false,
        };

        return addressDto;
    }
}
