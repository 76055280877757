import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum GenderEnumDto {
    Male = "male",
    Female = "female",
    NonBinary = "non_binary",
    Intersexual = "intersexual",
    NotStated = "not_stated",
}

export class GenderDto {
    @IsEnum(GenderEnumDto)
    @IsNotEmpty()
    key!: GenderEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
