import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContactRepository } from "@entity/data/repositories/contact.repository";
import {
    Contact,
    CreateContactBody,
} from "@proceeding/domain/models/contact.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateContactUseCase {
    constructor(
        @inject(ContactRepository)
        private readonly contactRepository: ContactRepository,
    ) {}

    async execute(contact: CreateContactBody): Promise<Nullable<Contact>> {
        const creationResult = await this.contactRepository.create(contact);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdContact) => createdContact,
        );
    }
}
