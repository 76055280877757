import { LoginDto } from "@authentication/data/dto/login.dto";
import { LoginUser } from "@authentication/domain/models/login-user.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
@injectable()
export class LoginUserMapper {
    map(authenticatedUserDto: LoginDto): LoginUser | null {
        const errors = validateSync(authenticatedUserDto);

        if (Boolean(errors.length)) {
            console.error(errors);
            return null;
        }

        return new LoginUser(
            authenticatedUserDto.id,
            authenticatedUserDto.token,
        );
    }
}
