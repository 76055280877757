import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum ServiceProvidedEnumDto {
    Water = "water",
    RentLocals = "rent_locals",
    AccouningManagement = "accouning_management",
    RentHeadquarters = "rent_headquarters",
    Auditor = "auditor",
    QualityCertificate = "quality_certificate",
    MembershipFeesNetworksPlatforms = "membership_fees_networks_platforms",
    Electricity = "electricity",
    TechnologySupplier = "technology_supplier",
    Training = "training",
    Gas = "gas",
    AccountingHhrr = "accounting_hhrr",
    AccountingFinantial = "accounting_finantial",
    Licenses = "licenses",
    OccupationalAccidentsInsurance = "occupational_accidents_insurance",
    AccountingOthers = "accounting_others",
    OccupationalSafetyPrevention = "occupational_safety_prevention",
    Food_Suppliers = "food_suppliers",
    OfficeSuppliesSuppliers = "office_supplies_suppliers",
    Insurance = "insurance",
    AdvisoryService = "advisory_service",
    InternetService = "internet_service",
    CleaningService = "cleaning_service",
    ItServices = "it_services",
    ConsultancyServices = "consultancy_services",
    PhoneServices = "phone_services",
    ProfessionalServicesFreelancer = "professional_services_freelancer",
    Software = "software",
    Others = "others",
}

export class ServiceProvidedDto {
    @IsEnum(ServiceProvidedEnumDto)
    @IsNotEmpty()
    key!: ServiceProvidedEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
