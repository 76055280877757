import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectAuditoryRepository } from "@entity/data/repositories/project-auditory.repository";
import { EditProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/edit-project-auditory.model";
import { ProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/project-auditory.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditProjectAuditoryUseCase {
    constructor(
        @inject(ProjectAuditoryRepository)
        private readonly projectAuditoryRepository: ProjectAuditoryRepository,
    ) {}

    async execute(
        projectAuditory: EditProjectAuditory,
    ): Promise<Nullable<ProjectAuditory>> {
        const editResult =
            await this.projectAuditoryRepository.edit(projectAuditory);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedProjectAuditory) => editedProjectAuditory,
        );
    }
}
