import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateRegisteredRecordMapper } from "@entity/data/mappers/registered-record/create-registered-record.mapper";
import { EditRegisteredRecordMapper } from "@entity/data/mappers/registered-record/edit-registered-record.mapper";
import { AdministrativeRecordScope } from "@entity/domain/models/administrative-record-scope.model";
import { RegisteredRecord } from "@entity/domain/models/registered-record/registered-record.model";
import { GetAllAdministrativeRecordScopeUseCase } from "@entity/domain/usecases/get-all-administrative-record-scope.usecase";
import { CreateRegisteredRecordUseCase } from "@entity/domain/usecases/registered-record/create-registered-record.usecase";
import { DeleteRegisteredRecordUseCase } from "@entity/domain/usecases/registered-record/delete-registered-record.usecase";
import { EditRegisteredRecordUseCase } from "@entity/domain/usecases/registered-record/edit-registered-record.usecase";
import { GetAllRegisteredRecordsUsecase } from "@entity/domain/usecases/registered-record/get-all-registered-records.usecase";
import { RegisteredRecordFormValuesValidated } from "@entity/presentation/component/entity-form/general/registered-record/form/registered-record-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class RegisteredRecordViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    registeredRecords: RegisteredRecord[] = [];

    @observable
    administrativeRecordScopes: AdministrativeRecordScope[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddRegisteredRecordModal: boolean = false;

    @observable
    showEditRegisteredRecordModal: boolean = false;

    @observable
    registeredRecordToEdit: Undefinable<RegisteredRecord> = undefined;

    constructor(
        @inject(GetAllRegisteredRecordsUsecase)
        private readonly getAllRegisteredRecordsUsecase: GetAllRegisteredRecordsUsecase,
        @inject(GetAllAdministrativeRecordScopeUseCase)
        private readonly getAllAdministrativeRecordScopeUseCase: GetAllAdministrativeRecordScopeUseCase,
        @inject(CreateRegisteredRecordMapper)
        private readonly createRegisteredRecordMapper: CreateRegisteredRecordMapper,
        @inject(EditRegisteredRecordMapper)
        private readonly editRegisteredRecordMapper: EditRegisteredRecordMapper,
        @inject(CreateRegisteredRecordUseCase)
        private readonly createRegisteredRecordUseCase: CreateRegisteredRecordUseCase,
        @inject(EditRegisteredRecordUseCase)
        private readonly editRegisteredRecordUseCase: EditRegisteredRecordUseCase,
        @inject(DeleteRegisteredRecordUseCase)
        private readonly deleteRegisteredRecordUseCase: DeleteRegisteredRecordUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAdministrativeRecordScope(),
            this.getRegisteredRecords(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getRegisteredRecords(): Promise<void> {
        const registeredRecords =
            await this.getAllRegisteredRecordsUsecase.execute();
        runInAction(() => {
            this.registeredRecords = registeredRecords;
        });
    }

    async getAdministrativeRecordScope(): Promise<void> {
        const administrativeRecordScopes =
            await this.getAllAdministrativeRecordScopeUseCase.execute();
        runInAction(() => {
            this.administrativeRecordScopes = administrativeRecordScopes;
        });
    }

    @action
    setShowAddRegisteredRecordModal(show: boolean): void {
        this.showAddRegisteredRecordModal = show;
    }

    @action
    closeEditRegisteredRecordModal(): void {
        this.showEditRegisteredRecordModal = false;
    }

    @action
    openEditRegisteredRecordModal(
        registeredRecord: Undefinable<RegisteredRecord>,
    ): void {
        this.registeredRecordToEdit = registeredRecord;
        this.showEditRegisteredRecordModal = true;
    }

    async addRegisteredRecord(
        entityId: Undefinable<number>,
        values: RegisteredRecordFormValuesValidated,
    ): Promise<Nullable<RegisteredRecord>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createRegisteredRecordResult =
            await this.createRegisteredRecordUseCase.execute(
                this.createRegisteredRecordMapper.mapFromFormValues(
                    entityId,
                    values,
                ),
            );

        if (createRegisteredRecordResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createRegisteredRecordResult;
    }

    async editRegisteredRecord(
        values: RegisteredRecordFormValuesValidated,
    ): Promise<void> {
        if (
            !this.registeredRecordToEdit?.id ||
            !this.registeredRecordToEdit.entity
        )
            throw Error("Missing registeredRecord");

        LoadLayoutStore.start();
        const editRegisteredRecordResult =
            await this.editRegisteredRecordUseCase.execute(
                this.editRegisteredRecordMapper.mapFromModal(
                    this.registeredRecordToEdit.id,
                    this.registeredRecordToEdit.entity,
                    values,
                ),
            );

        if (editRegisteredRecordResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadRegisteredRecords(): Promise<void> {
        await this.getRegisteredRecords();
    }

    async deleteRegisteredRecord(registeredRecordId: number): Promise<void> {
        LoadLayoutStore.start();

        await this.deleteRegisteredRecordUseCase.execute(registeredRecordId);

        LoadLayoutStore.finish();
    }
}
