import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { TerminationReasonEnum } from "@entity/domain/models/employee/termination-reason.model";
import { DateTime } from "luxon";

export class ContractTermination {
    constructor(
        public readonly id: number,
        public startDate: DateTime,
        public reason: string,
        public type: TerminationReasonEnum,
        public contractId: number,
        public cost: Nullable<number> = 0,
        public totalCost: Nullable<number> = 0,
        public endDate?: Nullable<DateTime>,
    ) {}
}

export class ContractTerminations {
    constructor(
        public readonly contractTerminations: ContractTermination[],
        public readonly count: number,
    ) {}
}

export interface ContractTerminationSearchFilters {
    employee?: Undefinable<number>;
}
