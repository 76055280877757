import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { CreateBudgetCategoryGroup } from "@project/domain/models/budget/budget-category/groups/create-budget-category-group.model";
import { BudgetCategoryGroup } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateBudgetCategoryGroupUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(
        budgetCategoryGroup: CreateBudgetCategoryGroup,
    ): Promise<Nullable<BudgetCategoryGroup>> {
        const result =
            await this.budgetRepository.createCategoryGroup(
                budgetCategoryGroup,
            );
        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }
        return result.fold(
            () => null,
            (createdConcept) => createdConcept,
        );
    }
}
