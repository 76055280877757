import { Pagination } from "@core/domain/models/pagination";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { DepositRepository } from "../../data/repositories/deposit.repository";
import { DepositSearchFilters } from "../models/deposit-search-filters";
import { Deposit } from "../models/deposit.model";

@injectable()
export class SearchDepositsByUseCase {
    constructor(
        @inject(DepositRepository)
        private readonly depositRepository: DepositRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: DepositSearchFilters,
    ): Promise<Deposit[]> {
        const depositsResult = await this.depositRepository.getAllBy(
            pagination,
            filters,
        );

        return depositsResult.fold(
            (error) => {
                ToastManagerStore.error(error.message);

                return [];
            },
            (deposits) => deposits,
        );
    }
}
