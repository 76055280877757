import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum TherapyTypeEnumDto {
    MedicalTherapies = "medical_therapies",
    PsychologicalTherapies = "psychological_therapies",
    OccupationalTherapy = "occupational_therapy",
    MusicTherapy = "music_therapy",
    OtherTherapy = "other_therapy",
}

export class TherapyTypeDto {
    @IsEnum(TherapyTypeEnumDto)
    @IsNotEmpty()
    key!: TherapyTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
