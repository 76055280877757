import { CreateDocument } from "@core/domain/models/create-document.model";
import { DocumentCategory } from "@core/domain/models/documents-category.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { CreateDocumentUseCase } from "@core/domain/usecases/create-document.usecase";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { GetAllDocumentCategoriesUseCase } from "@core/domain/usecases/get-all-document-categories.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

export interface DocumentTable {
    id: number;
    documentCategoryLabel: string;
    documentCategory: Undefinable<number>;
    name: string;
}

export interface DocumentTableFilters {
    name: string;
    category: Undefinable<number>;
}

@injectable()
export class DocumentTabViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    initialFilterValues: DocumentTableFilters = {
        name: "",
        category: undefined,
    };

    @observable
    documentFilters: DocumentTableFilters = {
        ...this.initialFilterValues,
    };

    @observable
    documentCategories: DocumentCategory[] = [];

    constructor(
        @inject(CreateDocumentUseCase)
        private readonly createDocumentUseCase: CreateDocumentUseCase,
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
        @inject(GetAllDocumentCategoriesUseCase)
        private readonly getAllDocumentCategoriesUseCase: GetAllDocumentCategoriesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getAllDocumentCategories()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllDocumentCategories(): Promise<void> {
        const categories = await this.getAllDocumentCategoriesUseCase.execute();

        runInAction(() => {
            this.documentCategories = categories;
        });
    }

    @action
    setFilters(filters: DocumentTableFilters): void {
        this.documentFilters = filters;
    }

    async uploadDocument(
        createDocument: CreateDocument,
    ): Promise<Nullable<IncDocument>> {
        LoadLayoutStore.start();

        const document =
            await this.createDocumentUseCase.execute(createDocument);

        if (document) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return document;
    }

    async deleteDocument(documentId: number): Promise<boolean> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return deleted;
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(documentUrl);

        LoadLayoutStore.finish();
    }
}
