import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    ProjectSummary,
    ProjectSummaryTransformer,
} from "../../domain/models/project-summary.model";
import { ProjectSummaryDto } from "../dto/project-summary.dto";
import { FinancialEntityTypeEnumMapper } from "./financial-entity/financial-entity-type-enum.mapper";
import { StatusEnumMapper } from "./status-enum.mapper";

@injectable()
export class ProjectSummaryMapper {
    constructor(
        @inject(FinancialEntityTypeEnumMapper)
        private readonly financialEntityTypeEnumMapper: FinancialEntityTypeEnumMapper,
        @inject(StatusEnumMapper)
        private readonly statusEnumMapper: StatusEnumMapper,
    ) {}
    mapToTransformer(
        projectSummaryDto: ProjectSummaryDto,
    ): Nullable<ProjectSummaryTransformer> {
        const errors = validateSync(projectSummaryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const financialEntityTypesId = projectSummaryDto.finantial_entity_data
            ? projectSummaryDto.finantial_entity_data.mapNotNull(
                  (financialEntity) =>
                      this.financialEntityTypeEnumMapper.map(
                          financialEntity.type,
                      ),
              )
            : [];

        const statusId = this.statusEnumMapper.map(projectSummaryDto.status);
        if (!statusId) return null;

        return {
            project: new ProjectSummary(
                projectSummaryDto.id,
                projectSummaryDto.name,
                [],
                "",
                projectSummaryDto.catalogue,
                projectSummaryDto.year,
            ),
            financialEntityTypesId,
            statusId,
        };
    }
}
