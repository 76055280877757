import { CreateStatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/create-statutory-territorial-scope.model";
import { StatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/statutory-territorial-scope.model";

export class EditStatutoryTerritorialScope extends CreateStatutoryTerritorialScope {
    constructor(
        public readonly id: StatutoryTerritorialScope["id"],
        name: StatutoryTerritorialScope["name"],
        scope: StatutoryTerritorialScope["scope"],
        entity: StatutoryTerritorialScope["entity"],
    ) {
        super(name, scope, entity);
    }
}
