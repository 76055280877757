import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { ActivityMapper } from "@project/data/mappers/technical-proposal/activity.mapper";
import { Activity } from "@project/domain/models/technical-proposals.model";
import { CreateActivityUseCase } from "@project/domain/usecases/project-activity/create-activity.usecase";
import { DeleteActivityUseCase } from "@project/domain/usecases/project-activity/delete-activity.usecase";
import { EditActivityUseCase } from "@project/domain/usecases/project-activity/edit-activiy.usecase";
import { GetAllActivitiesByUseCase } from "@project/domain/usecases/project-activity/get-all-activities-by.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { ActivityFormValuesValidated } from "../activity/activity-form.view";

@injectable()
export class ObjectiveFormViewModel extends BaseViewModel {
    @observable
    objectiveId: Undefinable<number> = undefined;

    @observable
    initiallyLoading = true;

    @observable
    isActivityModalOpen = false;

    @observable
    activities: Activity[] = [];

    @observable
    activityToEdit: Undefinable<Activity> = undefined;

    constructor(
        @inject(CreateActivityUseCase)
        private readonly createActivityUseCase: CreateActivityUseCase,
        @inject(EditActivityUseCase)
        private readonly editActivityUseCase: EditActivityUseCase,
        @inject(DeleteActivityUseCase)
        private readonly deleteActivityUseCase: DeleteActivityUseCase,
        @inject(ActivityMapper)
        private readonly activityMapper: ActivityMapper,
        @inject(GetAllActivitiesByUseCase)
        private readonly getAllActivitiesByUseCase: GetAllActivitiesByUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getAllActivities();

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    @action
    setObjectiveId(objectiveId: number): void {
        this.objectiveId = objectiveId;
    }

    @action
    setActivities(activities: Activity[]): void {
        this.activities = activities;
    }

    @action
    getObjetiveId(): Undefinable<number> {
        return this.objectiveId;
    }

    async getAllActivities(): Promise<void> {
        if (!this.getObjetiveId()) return;
        const activities = await this.getAllActivitiesByUseCase.execute({
            objetive: this.getObjetiveId(),
        });

        runInAction(() => {
            this.activities = activities;
        });
    }

    @action
    openActivityModal(activity?: Undefinable<Activity>): void {
        this.isActivityModalOpen = true;
        this.activityToEdit = activity;
    }

    @action
    closeActivityModal(): void {
        this.isActivityModalOpen = false;
    }

    async createActivity(activity: ActivityFormValuesValidated): Promise<void> {
        LoadLayoutStore.start();

        const createActivityResult = await this.createActivityUseCase.execute(
            this.activityMapper.mapFromFormToCreate(activity),
        );

        if (createActivityResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async editActivity(activity: ActivityFormValuesValidated): Promise<void> {
        LoadLayoutStore.start();

        const editActivityResult = await this.editActivityUseCase.execute(
            this.activityMapper.mapFromFormToEdit(activity),
        );

        if (editActivityResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async deleteActivity(activityId: number): Promise<void> {
        LoadLayoutStore.start();

        const success = await this.deleteActivityUseCase.execute(activityId);
        if (success) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    @action.bound
    async reloadActivities(): Promise<void> {
        LoadLayoutStore.start();
        await this.getAllActivities();
        LoadLayoutStore.finish();
    }
}
