import { AuthorityManagerDto } from "@entity/data/dto/authority-manager/authority-manager.dto";
import { MandateTerminationReasonEnumMapper } from "@entity/data/mappers/mandate-termination-reason-enum.mapper";
import { PositionTypeEnumMapper } from "@entity/data/mappers/position-type-enum.mapper";
import { AuthorityManager } from "@entity/domain/models/authority-manager/authority-manager.model";
import { inject, injectable } from "inversify";

@injectable()
export class AuthorityManagerMapper {
    constructor(
        @inject(PositionTypeEnumMapper)
        private readonly positionTypeEnumMapper: PositionTypeEnumMapper,
        @inject(MandateTerminationReasonEnumMapper)
        private readonly mandateTerminationReasonEnumMapper: MandateTerminationReasonEnumMapper,
    ) {}
    mapToDto(authorityManager: AuthorityManager): AuthorityManagerDto {
        const authorityManagerDto: AuthorityManagerDto = {
            id: authorityManager.id,
            name: authorityManager.name,
            position: authorityManager.position.map((positionEnum) =>
                this.positionTypeEnumMapper.mapToDto(positionEnum),
            ),
            entity: authorityManager.entity,
            appointment_date: authorityManager.appointmentDate
                ? authorityManager.appointmentDate.toString()
                : null,
            election_procedure: authorityManager.electionProcedure
                ? authorityManager.electionProcedure
                : null,
            mandate_termination_reason:
                authorityManager.mandateTerminationReason
                    ? this.mandateTerminationReasonEnumMapper.mapToDto(
                          authorityManager.mandateTerminationReason,
                      )
                    : null,
            other_position: authorityManager.otherPosition
                ? authorityManager.otherPosition
                : null,
            other_mandate_termination_reason:
                authorityManager.otherMandateTerminationReason
                    ? authorityManager.otherMandateTerminationReason
                    : null,
            mandate_end_date: authorityManager.terminationDate
                ? authorityManager.terminationDate.toString()
                : null,
        };

        return authorityManagerDto;
    }
}
