import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { AffiliationRepository } from "@entity/data/repositories/affiliation.repository";
import { Affiliation } from "@entity/domain/models/affiliation/affiliation.model";
import { EditAffiliation } from "@entity/domain/models/affiliation/edit-affiliation.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditAffiliationUseCase {
    constructor(
        @inject(AffiliationRepository)
        private readonly affiliationRepository: AffiliationRepository,
    ) {}

    async execute(
        affiliation: EditAffiliation,
    ): Promise<Nullable<Affiliation>> {
        const editResult = await this.affiliationRepository.edit(affiliation);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedAffiliation) => editedAffiliation,
        );
    }
}
