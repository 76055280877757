import { Nullable } from "@core/domain/types/nullable.type";
import { PaymentMethodTypeDto } from "@entity/data/dto/cost/payment-method-type.dto";
import { PaymentMethodType } from "@entity/domain/models/cost/payment-method-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { PaymentMethodTypeEnumMapper } from "./payment-method-type.mapper";

@injectable()
export class PaymentMethodMapper {
    constructor(
        @inject(PaymentMethodTypeEnumMapper)
        private readonly paymentMethoTypeEnumMapper: PaymentMethodTypeEnumMapper,
    ) {}

    map(
        paymentMethodTypeDto: PaymentMethodTypeDto,
    ): Nullable<PaymentMethodType> {
        const errors = validateSync(paymentMethodTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.paymentMethoTypeEnumMapper.map(
            paymentMethodTypeDto.key,
        );
        if (!id) return null;

        return new PaymentMethodType(id, paymentMethodTypeDto.value);
    }
}
