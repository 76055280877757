import { CreatePublicItineraryBody } from "@beneficiary/data/dto/public-itinerary/create-public-itinerary.body";
import { CreatePublicItinerary } from "@beneficiary/domain/models/public-itinerary/create-public-itinerary.model";
import { PublicItineraryFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/public-itinerary/form/public-itinerary-form";
import { injectable } from "inversify";

@injectable()
export class CreatePublicItineraryMapper {
    mapToCreateDto(
        createPublicItinerary: CreatePublicItinerary,
    ): CreatePublicItineraryBody {
        const createPublicItineraryDto: CreatePublicItineraryBody = {
            objective: createPublicItinerary.objetive,
            actions: createPublicItinerary.actions,
            actions_evaluation: createPublicItinerary.actionsEvaluation,
            beneficiary: createPublicItinerary.beneficiary,
        };

        return createPublicItineraryDto;
    }

    mapFromFormValues(
        beneficiaryId: number,
        publicItineraryForm: PublicItineraryFormValuesValidated,
    ): CreatePublicItinerary {
        const createPublicItinerary: CreatePublicItinerary =
            new CreatePublicItinerary(
                publicItineraryForm.objetive,
                publicItineraryForm.actions,
                publicItineraryForm.actionsEvaluation,
                beneficiaryId,
            );

        return createPublicItinerary;
    }
}
