import { DocumentRepository } from "@core/data/repositories/document.repository";
import { DocumentLicenseType } from "@core/domain/models/document-type.model";
import { DocumentTypeLicenseEnum } from "@core/domain/models/inc-document.model";
import { Map } from "immutable";
import { inject, injectable } from "inversify";
Number("");
@injectable()
export class GetAllDocumentTypesUseCase {
    constructor(
        @inject(DocumentRepository)
        private readonly documentRepository: DocumentRepository,
    ) {}

    async execute(): Promise<
        Map<DocumentTypeLicenseEnum, DocumentLicenseType>
    > {
        const getAllDocumentTypesResult =
            await this.documentRepository.getAllDocumentTypes();

        return getAllDocumentTypesResult.fold(
            () => Map(),
            (documentTypes) => documentTypes,
        );
    }
}
