import { MaterialRepository } from "@entity/data/repositories/material.repository";
import { inject, injectable } from "inversify";
import { MetricUnit } from "../../models/material/metric-unit.model";

@injectable()
export class GetAllMetricUnitUseCase {
    constructor(
        @inject(MaterialRepository)
        private readonly materialRepository: MaterialRepository,
    ) {}

    async execute(): Promise<MetricUnit[]> {
        const metricUnitResult =
            await this.materialRepository.getAllMetricUnits();

        return metricUnitResult.getOrElse([]);
    }
}
