import { AuthenticationRemoteDatasource } from "@authentication/data/datasource/authentication-remote.datasource";
import { AuthenticationStorageDatasource } from "@authentication/data/datasource/authentication-storage.datasource";
import { LoginUserMapper } from "@authentication/data/mappers/login-user.mapper";
import { AuthenticationRepository } from "@authentication/data/repositories/authentication.repository";
import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { GetLoggedUserUseCase } from "@authentication/domain/usecases/get-logged-user.usecase";
import { IsUserLoggedUseCase } from "@authentication/domain/usecases/is-user-logged.usecase";
import { LoadCurrentUserUseCase } from "@authentication/domain/usecases/load-current-user.usecase";
import { LoginUseCase } from "@authentication/domain/usecases/login.usecase";
import { LoginRecoveryPageViewmodel } from "@authentication/presentation/pages/login-recovery/login-recovery-page.viewmodel";
import { LoginResetPageViewmodel } from "@authentication/presentation/pages/login-reset/login-reset-page.viewmodel";
import { LoginPageViewModel } from "@authentication/presentation/pages/login/login-page.viewmodel";
import { DiModuleBuilder } from "@di/builder/di-module-builder";
import { ContainerModule } from "inversify";
import { LoginRecoveryUseCase } from "./domain/usecases/login-recovery.usecase";
import { LoginResetPasswordUseCase } from "./domain/usecases/login-reset-password.usecase";
import { LogoutUseCase } from "./domain/usecases/logout.usecase";

const authenticationModule = new DiModuleBuilder();

authenticationModule.registerSubModules(() => ({
    datasources: new ContainerModule((bind) => {
        bind(AuthenticationRemoteDatasource).toSelf();
        bind(AuthenticationStorageDatasource).toSelf();
    }),
    mappers: new ContainerModule((bind) => {
        bind(LoginUserMapper).toSelf();
    }),
    repositories: new ContainerModule((bind) => {
        bind(AuthenticationRepository).toSelf();
        bind(UserSessionRepository).toSelf().inSingletonScope();
    }),
    useCases: new ContainerModule((bind) => {
        bind(GetLoggedUserUseCase).toSelf();
        bind(IsUserLoggedUseCase).toSelf();
        bind(LoadCurrentUserUseCase).toSelf();
        bind(LoginUseCase).toSelf();
        bind(LoginRecoveryUseCase).toSelf();
        bind(LoginResetPasswordUseCase).toSelf();
        bind(LogoutUseCase).toSelf();
    }),
    viewModels: new ContainerModule((bind) => {
        bind(LoginPageViewModel).toSelf();
        bind(LoginRecoveryPageViewmodel).toSelf();
        bind(LoginResetPageViewmodel).toSelf();
    }),
}));

authenticationModule.registerModule();

export { authenticationModule };
