import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectRepository } from "@project/data/repositories/project.repository";
import { Project } from "@project/domain/models/project.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetProjectByIdUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(projectId: number): Promise<Nullable<Project>> {
        const projectResult = await this.projectRepository.findById(projectId);

        if (projectResult.isLeft()) {
            ToastManagerStore.error(projectResult.getLeftOrThrow().message);
        }

        return projectResult.fold(
            () => null,
            (project) => project,
        );
    }
}
