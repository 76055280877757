import { DiagnosisType } from "@core/domain/models/diagnosis-type.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DiagnosisTypeDto } from "../dto/diagnosis-type.dto";
import { DiagnosisTypeEnumMapper } from "./diagnosis-type-enum.mapper";

@injectable()
export class DiagnosisTypeMapper {
    constructor(
        @inject(DiagnosisTypeEnumMapper)
        private readonly diagnosisTypeEnumMapper: DiagnosisTypeEnumMapper,
    ) {}
    map(diagnosisTypeDto: DiagnosisTypeDto): Nullable<DiagnosisType> {
        const errors = validateSync(DiagnosisTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const diagnosisTypeId = this.diagnosisTypeEnumMapper.map(
            diagnosisTypeDto.key,
        );

        if (!diagnosisTypeId) return null;

        return new DiagnosisType(diagnosisTypeId, diagnosisTypeDto.value);
    }
}
