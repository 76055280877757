import { CreateBeneficiaryMapper } from "@beneficiary/data/mappers/create-beneficiary.mapper";
import {
    Beneficiary,
    BeneficiaryIdentification,
} from "@beneficiary/domain/models/beneficiary.model";
import { CreateBeneficiaryUseCase } from "@beneficiary/domain/usecases/create-beneficiary.usecase";
import { DeleteBeneficiaryUseCase } from "@beneficiary/domain/usecases/delete-beneficiary.usecase";
import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { makeObservable } from "mobx";
import { CreateBeneficiaryIdentificationUseCase } from "../../../domain/usecases/create-beneficiary-identification.usecase";
import { CreateBeneficiaryFormValuesValidated } from "../../components/beneficiary-form/beneficiary-form.component";

@injectable()
export class CreateBeneficiaryPageViewModel extends BaseViewModel {
    constructor(
        @inject(CreateBeneficiaryUseCase)
        private readonly createBeneficiaryUseCase: CreateBeneficiaryUseCase,
        private readonly createBeneficiaryIdentificationUseCase: CreateBeneficiaryIdentificationUseCase,
        private readonly deleteBeneficiaryUseCase: DeleteBeneficiaryUseCase,
        private readonly createBeneficiaryMapper: CreateBeneficiaryMapper,
    ) {
        super();
        makeObservable(this);
    }

    async createBeneficiary(
        values: CreateBeneficiaryFormValuesValidated,
    ): Promise<Nullable<Beneficiary>> {
        LoadLayoutStore.start();

        const createBeneficiaryBody =
            this.createBeneficiaryMapper.mapFromBeneficiaryFormValues(values);

        const createBeneficiaryResult =
            await this.createBeneficiaryUseCase.execute(createBeneficiaryBody);

        if (
            createBeneficiaryResult &&
            createBeneficiaryBody.identifications.isNotEmpty()
        ) {
            const createIdentificationResult = await Promise.all(
                createBeneficiaryBody.identifications.map(
                    async (identification) =>
                        this.createBeneficiaryIdentificationUseCase.execute(
                            identification,
                            createBeneficiaryResult.id,
                        ),
                ),
            );

            createBeneficiaryResult.identifications =
                createIdentificationResult.filter(
                    (
                        identification,
                    ): identification is BeneficiaryIdentification =>
                        identification !== null,
                );
        }

        LoadLayoutStore.finish();

        return createBeneficiaryResult;
    }

    async deleteBeneficiary(beneficiaryId: number): Promise<void> {
        await this.deleteBeneficiaryUseCase.execute(beneficiaryId);
    }
}
