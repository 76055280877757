import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import {
    BudgetSpreadsheet,
    BudgetSpreadsheetCategory,
    BudgetSpreadsheetGroup,
    BudgetSpreadsheetItem,
    BudgetSpreadsheetValidation,
    BudgetSpreadsheetValidationTypeEnum,
    ColumnId,
} from "@project/domain/models/budget-spreadsheet.model";
import {
    ChangedCell,
    EditBudgetSpreadsheet,
} from "@project/domain/models/edit-budget-spreadsheet.model";
import { ExportFinancialEntityUseCase } from "@project/domain/usecases/export-financial-entity.usecase";
import { GetBudgetSpreadsheetByIdUseCase } from "@project/domain/usecases/get-budget-spreadsheet-by-id.usecase";
import { SearchFinEntByProFinEntUseCase } from "@project/domain/usecases/search-financial-entity-by.usecase";
import { UpdateBudgetSpreadsheetUseCase } from "@project/domain/usecases/update-budget-spreadsheet.usecase";
import { Map, OrderedMap } from "immutable";
import { inject, injectable } from "inversify";
import { cloneDeep } from "lodash";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { v4 as uuidv4 } from "uuid";
import i18n from "../../../../../i18n";

// This index is internal, not visual
// @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
export type ColumnIndex = number;

export interface BudgetSpreadsheetPercentageValidationView {
    validateAgainstTotal: boolean;
    categoryIdToCompare: Undefinable<number>;
    groupIdToCompare: Undefinable<number>;
    itemIdToCompare: Undefinable<number>;
}

export interface BudgetSpreadsheetValidationView {
    type: BudgetSpreadsheetValidationTypeEnum;
    valueToCompare: number;
    columnId: number;
    message: string;
    percentage: Undefinable<BudgetSpreadsheetPercentageValidationView>;
}

export interface BudgetSpreadsheetValueContentView {
    id: number;
    value: number;
}

export type BudgetSpreadsheetValueView = {
    // In order to be able to generate dynamic dataIndex for each column
    // Example
    // Columns [{columnId: 34, label: "Column 1"}, {columnId: 47, label: "Column 2"}]
    // Rows [{ 34: 288, { 47: 300 }, { 34: 200, 47: 100 } ]
    [columnId: number]: BudgetSpreadsheetValueContentView;
};

export interface BudgetSpreadsheetItemView {
    itemLabel: string;
    itemId: number;
    validations?: Undefinable<
        OrderedMap<ColumnIndex, BudgetSpreadsheetValidationView>
    >;
    __children: BudgetSpreadsheetValueView[];
}

export interface BudgetSpreadsheetGroupView {
    groupLabel: string;
    groupId: number;
    validations?: Undefinable<
        OrderedMap<ColumnIndex, BudgetSpreadsheetValidationView>
    >;
    __children: BudgetSpreadsheetItemView[];
    [columnId: number]: BudgetSpreadsheetValueContentView;
}

export interface BudgetSpreadsheetCategoryView
    extends BudgetSpreadsheetValueView {
    categoryId: number;
    categoryLabel: string;
    validations?: Undefinable<
        OrderedMap<ColumnIndex, BudgetSpreadsheetValidationView>
    >;
    __children: BudgetSpreadsheetGroupView[];
}

export interface BudgetSpreadsheetColumnView {
    id: number;
    label: string;
    type: BudgetSpreadsheetValidationTypeEnum;
}

// Each cell type present in the spreadsheet
export type CellTypeData =
    | BudgetSpreadsheetCategoryView
    | BudgetSpreadsheetGroupView
    | BudgetSpreadsheetItemView
    | BudgetSpreadsheetValueView;

interface BudgetSpreadsheetView {
    uuid: string;
    columns: BudgetSpreadsheetColumnView[];
    rows: BudgetSpreadsheetCategoryView[];
}

@injectable()
export class EditBudgetPageViewModel extends BaseViewModel {
    // 1 Column Category -> 2 Column Group -> 3 Column Item -> 4,5,6 (...) (one colum per item value)

    static readonly SUMMARY_COLUMNS_OFFSET = 3;

    static readonly CATEGORY_COLUMN_INDEX = 0;

    static readonly GROUP_COLUMN_INDEX = 1;

    static readonly ITEM_COLUMN_INDEX = 2;

    static readonly VALUE_COLUMN_INDEX = 3;

    static readonly NO_GROUP_ID: number = 0;

    static readonly TOTAL_SUMMARY_CATEGORY_ID = 4800;

    @observable initialLoading = true;

    @observable
    private _budgetSpreadsheet: Undefinable<BudgetSpreadsheet> = undefined;

    // This is the original budget spreadsheet
    @observable
    budgetSpreadsheetInmutable: Undefinable<BudgetSpreadsheetView> = undefined;

    // This is the budget spreadsheet that will be modified via referencia by Handsontable
    // That's the main reason why we duplicate the budgetSpreadsheetInmutable and why is not observable (we don't want re-renders)
    budgetSpreadsheetMutable: Undefinable<BudgetSpreadsheetView> = undefined;

    projectFinancialEntityId: Undefinable<number> = undefined;

    @observable
    changedCells: Map<number, ChangedCell> = Map();

    @observable
    validatingBeforeSave: boolean = false;

    @computed
    get anyCellChanged(): boolean {
        return !this.changedCells.isEmpty();
    }

    constructor(
        @inject(GetBudgetSpreadsheetByIdUseCase)
        private readonly getBudgetSpreadsheetByIdUseCase: GetBudgetSpreadsheetByIdUseCase,
        @inject(UpdateBudgetSpreadsheetUseCase)
        private readonly updateBudgetSpreadsheetUseCase: UpdateBudgetSpreadsheetUseCase,
        @inject(ExportFinancialEntityUseCase)
        private readonly exportFinancialEntityUseCase: ExportFinancialEntityUseCase,
        @inject(SearchFinEntByProFinEntUseCase)
        private readonly searchFinancialEntityByIdUseCase: SearchFinEntByProFinEntUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        this.getBudget();
    }

    private mapValidationToView(
        columnIndex: ColumnIndex,
        columnId: ColumnId,
        validation: BudgetSpreadsheetValidation,
    ): [number, BudgetSpreadsheetValidationView] {
        return [
            columnIndex,
            {
                columnId: columnId,
                type: validation.type,
                valueToCompare: validation.valueToCompare,
                message: validation.message,
                percentage: validation.percentage
                    ? {
                          validateAgainstTotal:
                              validation.percentage.validateAgainstTotal,
                          categoryIdToCompare:
                              validation.percentage.categoryIdToCompare,
                          groupIdToCompare:
                              validation.percentage.groupIdToCompare,
                          itemIdToCompare:
                              validation.percentage.itemIdToCompare,
                      }
                    : undefined,
            },
        ];
    }

    private mapItemToView(
        item: BudgetSpreadsheetItem,
    ): BudgetSpreadsheetItemView {
        const itemsValidations: OrderedMap<
            ColumnIndex,
            BudgetSpreadsheetValidationView
        > = OrderedMap(
            item.validations
                ?.toArray()
                .map<
                    [number, BudgetSpreadsheetValidationView]
                >(([columnId, validation], columnIndex) => this.mapValidationToView(columnIndex, columnId, validation)),
        );
        const values: BudgetSpreadsheetValueView[] = item.values
            .valueSeq()
            .toArray()
            .reduce<BudgetSpreadsheetValueView[]>(
                (acc, value) => {
                    acc[0] = {
                        ...acc[0],
                        // We dynamically create the object key based on the columnId
                        [value.columnId]: {
                            id: value.id,
                            value: value.value,
                        },
                    };

                    return acc;
                },
                [{}],
            );

        return {
            itemId: item.id,
            itemLabel: item.label,
            validations: itemsValidations,
            __children: values,
        };
    }

    private mapGroupToView(
        group: BudgetSpreadsheetGroup,
    ): BudgetSpreadsheetGroupView {
        const groupValidations: OrderedMap<
            ColumnIndex,
            BudgetSpreadsheetValidationView
        > = OrderedMap(
            group.validations
                ?.toArray()
                .map<
                    [number, BudgetSpreadsheetValidationView]
                >(([columnId, validation], columnIndex) => this.mapValidationToView(columnIndex, columnId, validation)),
        );

        const items: BudgetSpreadsheetItemView[] = group.items
            .valueSeq()
            .toArray()
            .map<BudgetSpreadsheetItemView>((item) => this.mapItemToView(item));

        return {
            groupId: group.id,
            groupLabel: group.label,
            validations: groupValidations,
            __children: items,
        };
    }

    private mapCategoryToView(
        category: BudgetSpreadsheetCategory,
    ): BudgetSpreadsheetCategoryView {
        const categoryValidations: OrderedMap<
            ColumnIndex,
            BudgetSpreadsheetValidationView
        > = OrderedMap(
            category.validations
                ?.toArray()
                .map<
                    [number, BudgetSpreadsheetValidationView]
                >(([columnId, validation], columnIndex) => this.mapValidationToView(columnIndex, columnId, validation)),
        );

        const groups: BudgetSpreadsheetGroupView[] = category.group
            .concat(
                category.itemsWithoutGroup
                    ? OrderedMap([
                          [
                              0,
                              new BudgetSpreadsheetGroup(
                                  EditBudgetPageViewModel.NO_GROUP_ID,
                                  i18n.t("private:project.editBudget.noGroup"),
                                  category.itemsWithoutGroup,
                              ),
                          ],
                      ])
                    : [],
            )
            .valueSeq()
            .toArray()
            .map<BudgetSpreadsheetGroupView>((group) =>
                this.mapGroupToView(group),
            );

        return {
            categoryId: category.id,
            categoryLabel: category.label,
            validations: categoryValidations,
            __children: groups,
        };
    }

    private mapBudgetToView(
        uuid: string,
        budgetSpreadsheet: BudgetSpreadsheet,
    ): BudgetSpreadsheetView {
        const columns: BudgetSpreadsheetColumnView[] = budgetSpreadsheet.columns
            .valueSeq()
            .toArray()
            .map<BudgetSpreadsheetColumnView>((column) => ({
                id: column.id,
                label: column.label,
                type: column.type,
            }));

        const categories: BudgetSpreadsheetCategoryView[] =
            budgetSpreadsheet.categories
                .valueSeq()
                .toArray()
                .map<BudgetSpreadsheetCategoryView>((category) =>
                    this.mapCategoryToView(category),
                );

        // STATIC SUMMARY CATEGORY (TOTAL)
        categories.push({
            categoryId: EditBudgetPageViewModel.TOTAL_SUMMARY_CATEGORY_ID,
            categoryLabel: i18n
                .t("private:project.editBudget.totalSummaryCategory")
                .toUpperCase(),
            __children: [],
        });

        return {
            uuid: uuid,
            columns: columns,
            rows: categories,
        };
    }

    async getBudget(): Promise<void> {
        if (!this.projectFinancialEntityId)
            throw Error("Financial entity id is undefined");

        const budget = await this.getBudgetSpreadsheetByIdUseCase.execute(
            this.projectFinancialEntityId,
        );

        if (budget) {
            runInAction(() => {
                const uuid = uuidv4();

                this._budgetSpreadsheet = budget;
                this.budgetSpreadsheetInmutable = this.mapBudgetToView(
                    uuid,
                    budget,
                );
                this.budgetSpreadsheetMutable = cloneDeep(
                    this.mapBudgetToView(uuid, budget),
                );
            });
        }

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setValidatingBeforeSave(validating: boolean): void {
        this.validatingBeforeSave = validating;
    }

    @action
    setChangedCell(valueId: number, value: number): void {
        this.changedCells = this.changedCells.set(valueId, {
            id: valueId,
            value: value,
        });
    }

    @action
    hasChangedCell(valueId: number): boolean {
        return this.changedCells.has(valueId);
    }

    @action
    removeChangedCell(valueId: number): void {
        this.changedCells = this.changedCells.remove(valueId);
    }

    @action
    clearChangeCells(): void {
        this.changedCells = this.changedCells.clear();
    }

    async updateBudget(): Promise<boolean> {
        LoadLayoutStore.start();

        let editedSuccessfully: boolean = false;

        await runInAction(async () => {
            editedSuccessfully =
                await this.updateBudgetSpreadsheetUseCase.execute(
                    new EditBudgetSpreadsheet(this.changedCells),
                );

            if (editedSuccessfully) {
                ToastManagerStore.success();

                this.clearChangeCells();
            }
        });

        LoadLayoutStore.finish();

        return editedSuccessfully;
    }

    async exportFinancialEntity(id: number): Promise<void> {
        LoadLayoutStore.start();

        const financialEntity =
            await this.searchFinancialEntityByIdUseCase.execute(id);

        const name = financialEntity?.name ?? id;

        const blob = await this.exportFinancialEntityUseCase.execute(id);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Presupuesto - ${name}.xlsx`);
        document.body.appendChild(link);
        link.click();

        LoadLayoutStore.finish();
    }
}
