export class BeneficiarySummary {
    constructor(
        public readonly id: number,
        public name: string,
        public documentNumber?: string,
        public surname?: string,
        public project?: string[],
    ) {}

    get fullName(): string {
        return this.surname ? `${this.name} ${this.surname}` : this.name;
    }
}
