import { CreateQualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/create-quality-certificate.model";
import { QualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/quality-certificate.model";

export class EditQualityCertificate extends CreateQualityCertificate {
    constructor(
        public readonly id: QualityCertificate["id"],
        public override qualityCertificateType: CreateQualityCertificate["qualityCertificateType"],
        public override entity: CreateQualityCertificate["entity"],
        public override year: CreateQualityCertificate["year"],
        public override expirationDate?: CreateQualityCertificate["expirationDate"],
        public override otherQualityCertificate?: CreateQualityCertificate["otherQualityCertificate"],
    ) {
        super(
            qualityCertificateType,
            entity,
            year,
            expirationDate,
            otherQualityCertificate,
        );
    }
}
