import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { EducationLevelEnum } from "../../domain/models/education-level.model";
import { EducationLevelEnumDto } from "../dto/education-level.dto";
@injectable()
export class EducationLevelEnumMapper {
    map(
        relationshipIdDto: EducationLevelEnumDto,
    ): Nullable<EducationLevelEnum> {
        switch (relationshipIdDto) {
            case EducationLevelEnumDto.NoStudiesCantReadWrite:
                return EducationLevelEnum.NoStudiesCantReadWrite;
            case EducationLevelEnumDto.NoStudiesCanReadWrite:
                return EducationLevelEnum.NoStudiesCanReadWrite;
            case EducationLevelEnumDto.EarlyChildhoodFirstCycle:
                return EducationLevelEnum.EarlyChildhoodFirstCycle;
            case EducationLevelEnumDto.EarlyChildhoodSecondCycle:
                return EducationLevelEnum.EarlyChildhoodSecondCycle;
            case EducationLevelEnumDto.PrimaryEducation:
                return EducationLevelEnum.PrimaryEducation;
            case EducationLevelEnumDto.SecondaryEducation:
                return EducationLevelEnum.SecondaryEducation;
            case EducationLevelEnumDto.FpMiddleDegree:
                return EducationLevelEnum.FpMiddleDegree;
            case EducationLevelEnumDto.FpHigherDegree:
                return EducationLevelEnum.FpHigherDegree;
            case EducationLevelEnumDto.GeneralProfessionalPrograms:
                return EducationLevelEnum.GeneralProfessionalPrograms;
            case EducationLevelEnumDto.HigherStudies:
                return EducationLevelEnum.HigherStudies;
            case EducationLevelEnumDto.UnrecognizedForeignQualifications:
                return EducationLevelEnum.UnrecognizedForeignQualifications;
            case EducationLevelEnumDto.DoctoratePostgraduate:
                return EducationLevelEnum.DoctoratePostgraduate;
            default:
                return null;
        }
    }

    mapToDto(relationshipId: EducationLevelEnum): EducationLevelEnumDto {
        // eslint-disable-next-line default-case
        switch (relationshipId) {
            case EducationLevelEnum.NoStudiesCantReadWrite:
                return EducationLevelEnumDto.NoStudiesCantReadWrite;
            case EducationLevelEnum.NoStudiesCanReadWrite:
                return EducationLevelEnumDto.NoStudiesCanReadWrite;
            case EducationLevelEnum.EarlyChildhoodFirstCycle:
                return EducationLevelEnumDto.EarlyChildhoodFirstCycle;
            case EducationLevelEnum.EarlyChildhoodSecondCycle:
                return EducationLevelEnumDto.EarlyChildhoodSecondCycle;
            case EducationLevelEnum.PrimaryEducation:
                return EducationLevelEnumDto.PrimaryEducation;
            case EducationLevelEnum.SecondaryEducation:
                return EducationLevelEnumDto.SecondaryEducation;
            case EducationLevelEnum.FpMiddleDegree:
                return EducationLevelEnumDto.FpMiddleDegree;
            case EducationLevelEnum.FpHigherDegree:
                return EducationLevelEnumDto.FpHigherDegree;
            case EducationLevelEnum.GeneralProfessionalPrograms:
                return EducationLevelEnumDto.GeneralProfessionalPrograms;
            case EducationLevelEnum.HigherStudies:
                return EducationLevelEnumDto.HigherStudies;
            case EducationLevelEnum.UnrecognizedForeignQualifications:
                return EducationLevelEnumDto.UnrecognizedForeignQualifications;
            case EducationLevelEnum.DoctoratePostgraduate:
                return EducationLevelEnumDto.DoctoratePostgraduate;
        }
    }
}
