import { EducationLevelEnumMapper } from "@core/data/mappers/education-level-enum.mapper";
import { EditVolunteerContractBody } from "@entity/data/dto/employee/contracts/volunteer/edit-volunteer-contract.body";
import { EditVolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/edit-volunteer-contract.model";
import { VolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/volunteer-contract.model";
import { VolunteerContractFormValuesValidated } from "@entity/presentation/component/employee-form/contract/volunteer/form/volunteer-contract-form";
import { CatalogueEnumMapper } from "@project/data/mappers/catalogue-enum.mapper";
import { inject, injectable } from "inversify";

@injectable()
export class EditVolunteerContractMapper {
    constructor(
        @inject(CatalogueEnumMapper)
        private readonly catalogueEnumMapper: CatalogueEnumMapper,
        @inject(EducationLevelEnumMapper)
        private readonly educationLevelEnumMapper: EducationLevelEnumMapper,
    ) {}

    mapToDto(volunteerContract: VolunteerContract): EditVolunteerContractBody {
        const educationLevel = this.educationLevelEnumMapper.mapToDto(
            volunteerContract.educationLevel,
        );

        const preferences = volunteerContract.preferences.map((catalogueDto) =>
            this.catalogueEnumMapper.mapToDto(catalogueDto),
        );

        return {
            id: volunteerContract.id,
            has_insurance: volunteerContract.insurance,
            activity: volunteerContract.activity,
            education_level: educationLevel,
            weekly_workday_hours:
                volunteerContract.weeklyWorkdayHours.toString(),
            start_date: volunteerContract.startDate.toISODate() ?? "",
            availability: volunteerContract.availability,
            preferences,
            employee: volunteerContract.employeeId,
            start_date_insurance:
                volunteerContract.startDateInsurance?.toISODate() ?? null,
            other_preferences: volunteerContract.otherPreferences ?? "",
            door: volunteerContract.door ?? null,
            city: volunteerContract.city ?? null,
            end_date: volunteerContract.endDate?.toISODate() ?? null,
            end_date_insurance:
                volunteerContract.endDateInsurance?.toISODate() ?? null,
        };
    }

    mapFromVolunteerContractFormValues(
        contractId: number,
        employeeId: number,
        volunteerContractForm: VolunteerContractFormValuesValidated,
    ): EditVolunteerContract {
        if (!contractId) throw Error("Missing income contractId");
        if (!employeeId) throw Error("Missing employeeId");

        const editVolunteerContract = new EditVolunteerContract(
            contractId,
            volunteerContractForm.insurance,
            volunteerContractForm.activity,
            volunteerContractForm.educationLevel,
            volunteerContractForm.availability,
            volunteerContractForm.preferences,
            volunteerContractForm.weeklyWorkdayHours,
            volunteerContractForm.startDate,
            employeeId,
            volunteerContractForm.startDateInsurance,
            volunteerContractForm.otherPreferences
                ? volunteerContractForm.otherPreferences
                : null,
            volunteerContractForm.door,
            volunteerContractForm.city,
            volunteerContractForm.endDate,
            volunteerContractForm.endDateInsurance,
        );

        return editVolunteerContract;
    }
}
