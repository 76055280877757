import { Beneficiary } from "@beneficiary/domain/models/beneficiary.model";
import { GetBeneficiaryByIdUseCase } from "@beneficiary/domain/usecases/get-beneficiary-by-id.usecase";
import type { Nullable } from "@core/domain/types/nullable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import {
    action,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

@injectable()
export class BeneficiaryDetailPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    beneficiaryId: number = 0;

    @observable
    beneficiary?: Nullable<Beneficiary>;

    constructor(
        @inject(GetBeneficiaryByIdUseCase)
        private readonly getBeneficiaryByIdUseCase: GetBeneficiaryByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(this.getBeneficiaryById());

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setBeneficiaryId(beneficiaryId: number): void {
        this.beneficiaryId = beneficiaryId;
    }

    @flow
    async *getBeneficiaryById(): AsyncGenerator<void> {
        const beneficiary = await this.getBeneficiaryByIdUseCase.execute(
            this.beneficiaryId,
        );

        runInAction(() => {
            this.beneficiary = beneficiary;
        });
    }
}
