import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { inject, injectable } from "inversify";

@injectable()
export class CloneBudgetUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(id: number): Promise<boolean> {
        const result = await this.budgetRepository.clone(id);
        return result.isRight();
    }
}
