import type { Nullable } from "@core/domain/types/nullable.type";
import { MaterialTypeEnumDto } from "@entity/data/dto/material/material-type-enum.dto";
import { MetricUnitEnumDto } from "@entity/data/dto/material/metric-unit-enum.dto";
import {
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { MaterialSubtypeEnumDto } from "./material-subtype-enum.dto";

export class MaterialDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsOptional()
    description!: Nullable<string>;

    @IsEnum(MaterialTypeEnumDto)
    @IsNotEmpty()
    type!: MaterialTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    display_type!: string;

    @IsEnum(MaterialSubtypeEnumDto)
    @IsOptional()
    sub_type!: Nullable<MaterialSubtypeEnumDto>;

    @IsString()
    @IsOptional()
    display_subtype!: Nullable<string>;

    @IsString()
    @IsOptional()
    others_type!: Nullable<string>;

    @IsEnum(MetricUnitEnumDto)
    @IsNotEmpty()
    metric_unit!: MetricUnitEnumDto;

    @IsString()
    @IsOptional()
    others_type_unit!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    count!: number;

    @IsNumber()
    @IsNotEmpty()
    anual_cost!: number;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}
