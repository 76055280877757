import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { ProceedingRepository } from "../../data/repositories/proceeding.repository";
import { DerivationProceeding } from "../models/derivation-proceeding.model";

@injectable()
export class DeriveProceedingUseCase {
    constructor(
        @inject(ProceedingRepository)
        private readonly proceedingRepository: ProceedingRepository,
    ) {}

    async execute(
        proceeding: DerivationProceeding,
    ): Promise<Nullable<DerivationProceeding>> {
        const creationResult =
            await this.proceedingRepository.derive(proceeding);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (derivatedProceeding) => derivatedProceeding,
        );
    }
}
