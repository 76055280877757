import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { DepositRepository } from "@entity/data/repositories/deposit.repository";
import { SplitDeposit } from "@entity/domain/models/deposit.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditSplitDepositUseCase {
    constructor(
        @inject(DepositRepository)
        private readonly depositRepository: DepositRepository,
    ) {}

    async execute(splitDeposit: SplitDeposit): Promise<Nullable<SplitDeposit>> {
        if (!splitDeposit.id) throw new Error("Id is required");

        const editResult =
            await this.depositRepository.editSplitDeposit(splitDeposit);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (edited) => edited,
        );
    }
}
