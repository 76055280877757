import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { DepositOwnFinancingTypeEnum } from "../../domain/models/deposit-type.model";
import { DepositOwnFinancingTypeEnumDto } from "../dto/deposit-types.dto";

@injectable()
export class DepositOwnFinancingTypeEnumMapper {
    map(
        depositOwnFinancingTypeEnumDto: DepositOwnFinancingTypeEnumDto,
    ): Nullable<DepositOwnFinancingTypeEnum> {
        switch (depositOwnFinancingTypeEnumDto) {
            case DepositOwnFinancingTypeEnumDto.FUNDRAISING_CAMPAIGNS:
                return DepositOwnFinancingTypeEnum.FUNDRAISING_CAMPAIGNS;
            case DepositOwnFinancingTypeEnumDto.MEMBERSHIP_FEE:
                return DepositOwnFinancingTypeEnum.MEMBERSHIP_FEE;
            case DepositOwnFinancingTypeEnumDto.DONATION:
                return DepositOwnFinancingTypeEnum.DONATION;
            case DepositOwnFinancingTypeEnumDto.OTHERS_INCOME:
                return DepositOwnFinancingTypeEnum.OTHERS_INCOME;
            default:
                return null;
        }
    }

    mapToDto(
        depositOwnFinancingTypeEnum: DepositOwnFinancingTypeEnum,
    ): DepositOwnFinancingTypeEnumDto {
        switch (depositOwnFinancingTypeEnum) {
            case DepositOwnFinancingTypeEnum.FUNDRAISING_CAMPAIGNS:
                return DepositOwnFinancingTypeEnumDto.FUNDRAISING_CAMPAIGNS;
            case DepositOwnFinancingTypeEnum.MEMBERSHIP_FEE:
                return DepositOwnFinancingTypeEnumDto.MEMBERSHIP_FEE;
            case DepositOwnFinancingTypeEnum.DONATION:
                return DepositOwnFinancingTypeEnumDto.DONATION;
            case DepositOwnFinancingTypeEnum.OTHERS_INCOME:
                return DepositOwnFinancingTypeEnumDto.OTHERS_INCOME;
            default:
                return DepositOwnFinancingTypeEnumDto[""];
        }
    }
}
