import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EditCostMapper } from "@entity/data/mappers/cost/edit-cost.mapper";
import { Cost } from "@entity/domain/models/cost/cost.model";
import { GetCostByIdUseCase } from "@entity/domain/usecases/cost/get-cost-by-id.usecase";
import { UpdateCostUseCase } from "@entity/domain/usecases/cost/update-cost.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { CostFormValuesValidated } from "../../../component/cost-form/cost-form.component";

@injectable()
export class EditCostPageViewModel extends BaseViewModel {
    costId: Undefinable<number>;

    @observable
    initialLoading: boolean = true;

    @observable
    cost: Nullable<Cost> = null;

    constructor(
        @inject(GetCostByIdUseCase)
        private readonly getCostByIdUseCase: GetCostByIdUseCase,
        @inject(UpdateCostUseCase)
        private readonly updateCostUseCase: UpdateCostUseCase,
        @inject(EditCostMapper)
        private readonly editCostMapper: EditCostMapper,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getCostById()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setCostId(costId: number): void {
        this.costId = costId;
    }

    @action
    setCost(cost: Cost): void {
        this.cost = cost;
    }

    async getCostById(): Promise<void> {
        if (this.costId === undefined) throw Error("Missign costId");

        const cost = await this.getCostByIdUseCase.execute(this.costId);

        if (cost) {
            this.setCost(cost);
        }
    }

    async updateCost(
        values: CostFormValuesValidated,
        entityId: number,
    ): Promise<Nullable<Cost>> {
        if (!values.id) {
            throw Error("Missign costId");
        }
        LoadLayoutStore.start();

        const editCostResult = await this.updateCostUseCase.execute(
            this.editCostMapper.mapFromCostFormValues(
                values,
                entityId,
                values.id,
            ),
        );

        if (editCostResult) {
            ToastManagerStore.success();
            await this.getCostById();
        }

        LoadLayoutStore.finish();

        return editCostResult;
    }
}
