export enum IdentificationDocumentTypeEnum {
    Dni = "dni",
    Nie = "nie",
    Passport = "passport",
    Nif = "nif",
    Cif = "cif",
}
export class IdentificationType {
    constructor(
        public readonly id: IdentificationDocumentTypeEnum,
        public readonly label: string,
    ) {}
}
