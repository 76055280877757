import { Affiliation } from "@entity/domain/models/affiliation/affiliation.model";

export class CreateAffiliation {
    constructor(
        public identification: Affiliation["identification"],
        public name: Affiliation["name"],
        public territorialScope: Affiliation["territorialScope"],
        public entity: Affiliation["entity"],
        public webAddress?: Affiliation["webAddress"],
        public incorporationDate?: Affiliation["incorporationDate"],
        public contactPerson?: Affiliation["contactPerson"],
        public contactPhone?: Affiliation["contactPhone"],
        public contactEmail?: Affiliation["contactEmail"],
    ) {}
}
