import { Nullable } from "@core/domain/types/nullable.type";
import { MaterialSubtypeDto } from "@entity/data/dto/material/material-subtype-enum.dto";
import {
    MaterialSubtype,
    MaterialSubtypeEnum,
} from "@entity/domain/models/material/material-subtype.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { MaterialSubtypeEnumMapper } from "./material-subtype-enum.mapper";

@injectable()
export class MaterialSubtypeMapper {
    constructor(
        @inject(MaterialSubtypeEnumMapper)
        private readonly materialSubtypeEnumMapper: MaterialSubtypeEnumMapper,
    ) {}

    map(materialTypeDto: MaterialSubtypeDto): Nullable<MaterialSubtype> {
        const errors = validateSync(materialTypeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<MaterialSubtypeEnum> =
            this.materialSubtypeEnumMapper.map(materialTypeDto.key);

        if (!id) return null;

        return new MaterialSubtype(id, materialTypeDto.value);
    }
}
