import { Undefinable } from "@core/domain/types/undefinable.type";
import { SocialNetwork } from "./social-network.model";

export class CreateSocialNetwork {
    constructor(
        public entityId: SocialNetwork["entityId"],
        public name: SocialNetwork["name"],
        public link: SocialNetwork["link"],
        public followers?: Undefinable<SocialNetwork["followers"]>,
        public postCount?: Undefinable<SocialNetwork["postCount"]>,
    ) {}
}
