import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum EmployeeTypeEnumDto {
    Freelance = "freelance",
    Volunteer = "volunteer",
    Employee = "employee",
}

export class EmployeeTypeDto {
    @IsEnum(EmployeeTypeEnumDto)
    @IsNotEmpty()
    key!: EmployeeTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
