import { Undefinable } from "@core/domain/types/undefinable.type";
import { MandateTerminationReasonEnum } from "@entity/domain/models/mandate-termination-reason.model";
import { PositionTypeEnum } from "@entity/domain/models/position-type.model";
import { DateTime } from "luxon";

export class AuthorityManager {
    constructor(
        public id: number,
        public name: string,
        public position: PositionTypeEnum[],
        public entity: number,
        public appointmentDate?: Undefinable<DateTime>,
        public electionProcedure?: Undefinable<string>,
        public otherPosition?: Undefinable<string>,
        public mandateTerminationReason?: Undefinable<MandateTerminationReasonEnum>,
        public otherMandateTerminationReason?: Undefinable<string>,
        public terminationDate?: Undefinable<DateTime>,
    ) {}
}

export class AuthorityManagers {
    constructor(
        public readonly authorityManagers: AuthorityManager[],
        public readonly count: number,
    ) {}
}
