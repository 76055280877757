import { ProjectsSummaryDto } from "@project/data/dto/project-summary.dto";
import { ProjectsSummaryTransformer } from "@project/domain/models/projects-summary.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { ProjectSummaryMapper } from "./project-summary.mapper";

@injectable()
export class ProjectsSummaryMapper {
    constructor(
        @inject(ProjectSummaryMapper)
        private readonly projectSummaryMapper: ProjectSummaryMapper,
    ) {}

    mapToTransformer(
        projectsSummaryDto: ProjectsSummaryDto,
    ): ProjectsSummaryTransformer {
        const errors = validateSync(projectsSummaryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return {
                projects: [],
                count: 0,
            };
        }

        const projectsSummary = projectsSummaryDto.results.mapNotNull(
            (projectSummaryDto) =>
                this.projectSummaryMapper.mapToTransformer(projectSummaryDto),
        );

        return {
            projects: projectsSummary,
            count: projectsSummaryDto.count,
        };
    }
}
