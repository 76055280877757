import { Pagination } from "@core/domain/models/pagination";
import { QualityCertificateRepository } from "@entity/data/repositories/quality-certificate.repository";
import { QualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/quality-certificate.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllQualityCertificatesUsecase {
    constructor(
        @inject(QualityCertificateRepository)
        private readonly qualityCertificateRepository: QualityCertificateRepository,
    ) {}

    async execute(): Promise<QualityCertificate[]> {
        const qualityCertificateResults =
            await this.qualityCertificateRepository.getAll(
                Pagination.NoPagination(),
            );

        return qualityCertificateResults.fold(
            () => [],
            (qualityCertificates) => qualityCertificates.qualityCertificates,
        );
    }
}
