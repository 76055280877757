import { lazy } from "react";
import { Route } from "react-router-dom";

const HomePage = lazy(async () =>
    import(
        /* webpackChunkName: "HomePage" */ "@core/presentacion/page/home.page"
    ).then((module) => ({ default: module.HomePage })),
);

export enum HomePaths {
    Home = "home",
}

export class HomeRoutes {
    static HOME = `/${HomePaths.Home}`;
}

export const homeRoutes = (
    <Route
        element={<HomePage />}
        path={HomePaths.Home}
    />
);
