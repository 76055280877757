import { FallbackError } from "@core/domain/errors/fallback.error";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteBudgetCategoryUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}

    async execute(columnId: number): Promise<boolean | FallbackError> {
        const result = await this.budgetRepository.deleteCategory(columnId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
