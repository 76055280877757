import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { RoomDatasource } from "@entity/data/datasources/room.datasource";
import { inject, injectable } from "inversify";
import { CreateRoom, Room, Rooms } from "../../domain/models/locals/room.model";
import { RoomsSearchFilters } from "../../domain/models/locals/rooms-search-filters";

@injectable()
export class RoomRepository {
    constructor(
        @inject(RoomDatasource)
        private readonly roomDatasource: RoomDatasource,
    ) {}

    async getAll(): Promise<Either<FallbackError, Room[]>> {
        const roomsResult = await this.roomDatasource.fetchAll();

        return roomsResult
            .mapLeft(() => new FallbackError())
            .map((rooms) => rooms);
    }

    async getAllPaginated(
        limit: number,
        offset: number,
    ): Promise<Either<FallbackError, Rooms>> {
        const roomsResult = await this.roomDatasource.fetchAllPaginated(
            limit,
            offset,
        );

        return roomsResult.mapLeft(() => new FallbackError());
    }

    async getBy(
        pagination: Pagination,
        filters?: RoomsSearchFilters,
    ): Promise<Either<FallbackError, Rooms>> {
        return this.roomDatasource.fetchAllBy(pagination, filters);
    }

    async create(
        createRoom: CreateRoom,
    ): Promise<Either<ValidationError | FallbackError, Room>> {
        return this.roomDatasource.create(createRoom);
    }

    async delete(roomId: number): Promise<Either<FallbackError, boolean>> {
        return this.roomDatasource.delete(roomId);
    }
}
