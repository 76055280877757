import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum DietTypeEnumDto {
    NONE = "NONE",
    MEALS = "MEALS",
    ACCOMMODATION = "ACCOMMODATION",
}

export class DietTypeDto {
    @IsEnum(DietTypeEnumDto)
    @IsNotEmpty()
    key!: DietTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
