import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectFinancialEntityRepository } from "@project/data/repositories/project-financial-entity.repository";
import { EditBudgetSpreadsheet } from "@project/domain/models/edit-budget-spreadsheet.model";
import { inject, injectable } from "inversify";

@injectable()
export class UpdateBudgetSpreadsheetUseCase {
    constructor(
        @inject(ProjectFinancialEntityRepository)
        private readonly projectFinancialEntityRepository: ProjectFinancialEntityRepository,
    ) {}

    async execute(
        editedBudgetSpreadsheet: EditBudgetSpreadsheet,
    ): Promise<boolean> {
        const projectFinancialEntityResult =
            await this.projectFinancialEntityRepository.updateBudgetSpreadsheet(
                editedBudgetSpreadsheet,
            );

        if (projectFinancialEntityResult.isLeft()) {
            ToastManagerStore.error(
                projectFinancialEntityResult.getLeftOrThrow().message,
            );
        }

        return projectFinancialEntityResult.isRight();
    }
}
