import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    PaymentMethodType,
    PaymentMethodTypeEnum,
} from "../../../domain/models/payment-method-type.model";
import { PaymentMethodTypeDto } from "../../dto/expenses/payment-method-type.dto";
import { PaymentMethodTypeEnumMapper } from "./payment-method-type-enum.mapper";

@injectable()
export class PaymentMethodTypeMapper {
    constructor(
        @inject(PaymentMethodTypeEnumMapper)
        private readonly paymentMethodTypeEnumMapper: PaymentMethodTypeEnumMapper,
    ) {}

    map(
        paymentMethodTypeDto: PaymentMethodTypeDto,
    ): Nullable<PaymentMethodType> {
        const errors = validateSync(paymentMethodTypeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<PaymentMethodTypeEnum> =
            this.paymentMethodTypeEnumMapper.map(paymentMethodTypeDto.key);

        if (!id) return null;

        return new PaymentMethodType(id, paymentMethodTypeDto.value);
    }
}
