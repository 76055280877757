import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum ExpenseRecurrenceEnumDto {
    Monthly = "monthly",
    Yearly = "yearly",
}

export class ExpenseRecurrenceDto {
    @IsEnum(ExpenseRecurrenceEnumDto)
    @IsNotEmpty()
    key!: ExpenseRecurrenceEnumDto;
    @IsString()
    @IsNotEmpty()
    value!: string;
}
