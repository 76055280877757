import { EducationLevelDto } from "@core/data/dto/education-level.dto";
import { EducationLevelEnumMapper } from "@core/data/mappers/education-level-enum.mapper";
import { EducationLevel } from "@core/domain/models/education-level.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class EducationLevelMapper {
    constructor(
        @inject(EducationLevelEnumMapper)
        private readonly educationLevelEnumMapper: EducationLevelEnumMapper,
    ) {}

    map(educationLevelDto: EducationLevelDto): Nullable<EducationLevel> {
        const errors = validateSync(educationLevelDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.educationLevelEnumMapper.map(educationLevelDto.key);
        if (!id) return null;

        return new EducationLevel(id, educationLevelDto.value);
    }
}
