import { DocumentRepository } from "@core/data/repositories/document.repository";
import { CreateDocument } from "@core/domain/models/create-document.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class CreateDocumentUseCase {
    constructor(
        @inject(DocumentRepository)
        private readonly documentRepository: DocumentRepository,
    ) {}

    async execute(document: CreateDocument): Promise<Nullable<IncDocument>> {
        const creationResult = await this.documentRepository.create(document);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdDocument) => createdDocument,
        );
    }
}
