import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { CreateSocialNetwork } from "../../domain/models/create-social-network.model";
import { EditSocialNetwork } from "../../domain/models/edit-social-network.model";
import { SocialNetwork } from "../../domain/models/social-network.model";
import {
    SocialNetworkFilters,
    SocialNetworksSummary,
} from "../../domain/models/social-networks-summary.model";
import { CreateSocialNetworkBody } from "../dto/social-network/create-social-network.body";
import { EditSocialNetworkBody } from "../dto/social-network/edit-social-network.body";
import { SocialNetworkDto } from "../dto/social-network/social-network.dto";
import {
    SocialNetworksSummaryDto,
    SocialNetworksSummaryQuery,
} from "../dto/social-network/social-networks-summary.dto";
import { CreateSocialNetworkMapper } from "../mappers/social-network/create-social-network.mapper";
import { EditSocialNetworkMapper } from "../mappers/social-network/edit-social-network.mapper";
import { SocialNetworkMapper } from "../mappers/social-network/social-network.mapper";
import { SocialNetworksSummaryMapper } from "../mappers/social-network/social-networks-summary.mapper";

const SOCIAL_NETWORK_PATH = "/social_networks/";
@injectable()
export class SocialNetworkDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(SocialNetworkMapper)
        private readonly socialNetworkMapper: SocialNetworkMapper,
        @inject(SocialNetworksSummaryMapper)
        private readonly socialNetworksSummaryMapper: SocialNetworksSummaryMapper,
        @inject(CreateSocialNetworkMapper)
        private readonly createSocialNetworkMapper: CreateSocialNetworkMapper,
        @inject(EditSocialNetworkMapper)
        private readonly editSocialNetworkMapper: EditSocialNetworkMapper,
    ) {}

    async fetchBy(
        pagination: Pagination,
        filters?: SocialNetworkFilters,
    ): Promise<Either<HttpError, SocialNetworksSummary>> {
        const query: SocialNetworksSummaryQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.search) query.search = filters.search;

        const socialNetworksResult =
            await this.http.get<SocialNetworksSummaryDto>(SOCIAL_NETWORK_PATH, {
                query,
            });

        const result = socialNetworksResult.map((response) =>
            this.socialNetworksSummaryMapper.map(
                plainToClass(SocialNetworksSummaryDto, response.data),
            ),
        );

        return result;
    }

    async create(
        createSocialNetwork: CreateSocialNetwork,
    ): Promise<Either<ValidationError | FallbackError, SocialNetwork>> {
        const socialNetworkDto =
            this.createSocialNetworkMapper.mapToDto(createSocialNetwork);

        const socialNetworkResult = await this.http.post<
            SocialNetworkDto,
            CreateSocialNetworkBody
        >(SOCIAL_NETWORK_PATH, socialNetworkDto);

        return socialNetworkResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdSocialNetwork = this.socialNetworkMapper.map(
                    plainToClass(SocialNetworkDto, response.data),
                );

                if (!createdSocialNetwork)
                    return Either.Left(new FallbackError());

                return Either.Right(createdSocialNetwork);
            });
    }

    async edit(
        editSocialNetwork: EditSocialNetwork,
    ): Promise<Either<ValidationError | FallbackError, SocialNetwork>> {
        const editedSocialNetworkBody =
            this.editSocialNetworkMapper.mapToDto(editSocialNetwork);

        const editSocialNetworkResult = await this.http.patch<
            SocialNetworkDto,
            EditSocialNetworkBody
        >(
            `${SOCIAL_NETWORK_PATH}${editSocialNetwork.id}/`,
            editedSocialNetworkBody,
        );

        return editSocialNetworkResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const socialNetwork = this.socialNetworkMapper.map(
                    plainToClass(SocialNetworkDto, response.data),
                );

                if (!socialNetwork) return Either.Left(new FallbackError());

                return Either.Right(socialNetwork);
            });
    }

    async delete(
        socialNetworkId: number,
    ): Promise<Either<FallbackError, true>> {
        const deleteSocialNetwork = await this.http.delete(
            `${SOCIAL_NETWORK_PATH}${socialNetworkId}`,
        );

        return deleteSocialNetwork
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
