import { FallbackError } from "@core/domain/errors/fallback.error";
import { LanguageIsoCodeEnum } from "@core/domain/models/language.model";
import { Either } from "@core/domain/types/either";
import i18n from "@i18n";
import { FallbackLng } from "i18next";
import { injectable } from "inversify";
import { Settings } from "luxon";

@injectable()
export class LanguageLocalDatasource {
    getCurrent(): Either<FallbackError, string> {
        if (!i18n.isInitialized) return Either.Left(new FallbackError());

        const currentLanguage = i18n.language;

        return Either.Right(currentLanguage);
    }

    getDefault(): Either<FallbackError, false | FallbackLng | undefined> {
        if (!i18n.isInitialized) return Either.Left(new FallbackError());

        const defaultLanguage = i18n.options.fallbackLng;

        return Either.Right(defaultLanguage);
    }

    async update(
        language: LanguageIsoCodeEnum,
    ): Promise<Either<FallbackError, true>> {
        try {
            await i18n.changeLanguage(language);
            Settings.defaultLocale = i18n.language;

            return Either.Right(true);
        } catch (error) {
            return Either.Left(new FallbackError());
        }
    }
}
