import { ExpenseMapper } from "@beneficiary/data/mappers/expense.mapper";
import { IncomeMapper } from "@beneficiary/data/mappers/income.mapper";
import { Expense } from "@beneficiary/domain/models/economic-data/expense/expense.model";
import { Income } from "@beneficiary/domain/models/economic-data/income/income.model";
import { CreateExpenseUseCase } from "@beneficiary/domain/usecases/economic-data/expense/create-expense.usecase";
import { DeleteExpenseUseCase } from "@beneficiary/domain/usecases/economic-data/expense/delete-expense.usecase";
import { EditExpenseUseCase } from "@beneficiary/domain/usecases/economic-data/expense/edit-expense.usecase";
import { GetAllExpensesByUseCase } from "@beneficiary/domain/usecases/economic-data/expense/get-all-expense-by.usecase";
import { CreateIncomeUseCase } from "@beneficiary/domain/usecases/economic-data/income/create-income.usecase";
import { DeleteIncomeUseCase } from "@beneficiary/domain/usecases/economic-data/income/delete-income.usecase";
import { EditIncomeUseCase } from "@beneficiary/domain/usecases/economic-data/income/edit-income.usecase";
import { GetAllIncomesByUsecase } from "@beneficiary/domain/usecases/economic-data/income/get-all-income-by.usecase";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { IncomeFormValuesValidated } from "./beneficiary-economic-data-tab.view";
import { ExpenseFormValuesValidated } from "./expenses/expense-form.view";

@injectable()
export class EconomicDataTabViewModel extends BaseViewModel {
    _beneficiaryId: Undefinable<number> = undefined;

    @observable
    initialLoading: boolean = true;

    @observable
    expenses: Expense[] = [];

    @observable
    addExpenseModalOpen: boolean = false;

    @observable
    editExpenseModalOpen: boolean = false;

    @observable
    expenseToEdit: Undefinable<Expense> = undefined;

    @observable
    income: Undefinable<Income> = undefined;

    constructor(
        @inject(GetAllExpensesByUseCase)
        private readonly getAllExpensesByUsecase: GetAllExpensesByUseCase,
        @inject(DeleteExpenseUseCase)
        private readonly deleteExpenseUseCase: DeleteExpenseUseCase,
        @inject(CreateExpenseUseCase)
        private readonly createExpenseUseCase: CreateExpenseUseCase,
        @inject(EditExpenseUseCase)
        private readonly editExpenseUseCase: EditExpenseUseCase,
        @inject(DeleteIncomeUseCase)
        private readonly deleteIncomeUseCase: DeleteIncomeUseCase,
        @inject(CreateIncomeUseCase)
        private readonly createIncomeUseCase: CreateIncomeUseCase,
        @inject(EditIncomeUseCase)
        private readonly editIncomeUseCase: EditIncomeUseCase,
        @inject(ExpenseMapper)
        private readonly expenseMapper: ExpenseMapper,
        @inject(IncomeMapper)
        private readonly incomeMapper: IncomeMapper,
        @inject(GetAllIncomesByUsecase)
        private readonly getAllIncomesByUsecase: GetAllIncomesByUsecase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    @action
    async initViewData(): Promise<void> {
        if (!!this._beneficiaryId) {
            await this.getAllExpenses();
            await this.getIncomeByBeneficiary();
            // await this.getAllRelatives();
        }

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    async getIncomeByBeneficiary(): Promise<void> {
        if (!this._beneficiaryId) return;
        const income = await this.getAllIncomesByUsecase.execute(
            this._beneficiaryId,
        );

        runInAction(() => {
            if (income.length > 0) {
                this.income = income[0];
            } else {
                this.income = undefined;
            }
        });
    }

    @action
    async getAllExpenses(): Promise<void> {
        if (!this._beneficiaryId) return;
        const expenseData = await this.getAllExpensesByUsecase.execute(
            this._beneficiaryId,
        );
        runInAction(() => {
            this.expenses = expenseData;
        });
    }

    @action
    setAddExpenseModalOpen(open: boolean): void {
        this.addExpenseModalOpen = open;
    }

    @action
    closeEditExpenseModal(): void {
        this.editExpenseModalOpen = false;
    }

    @action
    openEditExpenseModal(expense: Undefinable<Expense>): void {
        this.expenseToEdit = expense;
        this.editExpenseModalOpen = true;
    }

    async reloadExpenses(): Promise<void> {
        LoadLayoutStore.start();
        await this.getAllExpenses();
        LoadLayoutStore.finish();
    }

    async deleteExpense(expenseId: number): Promise<void> {
        LoadLayoutStore.start();

        const success = await this.deleteExpenseUseCase.execute(expenseId);
        if (success) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async createExpense(expense: ExpenseFormValuesValidated): Promise<void> {
        LoadLayoutStore.start();

        const createEntityResult = await this.createExpenseUseCase.execute(
            this.expenseMapper.mapToCreate(expense),
        );

        if (createEntityResult) ToastManagerStore.success();

        LoadLayoutStore.finish();
    }

    async editExpense(expense: ExpenseFormValuesValidated): Promise<void> {
        LoadLayoutStore.start();

        const editExpenseResult = await this.editExpenseUseCase.execute(
            this.expenseMapper.mapToEdit(expense),
        );

        if (editExpenseResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadIncome(): Promise<void> {
        LoadLayoutStore.start();
        await this.getIncomeByBeneficiary();
        LoadLayoutStore.finish();
    }

    async deleteIncome(incomeId: number): Promise<void> {
        LoadLayoutStore.start();

        const success = await this.deleteIncomeUseCase.execute(incomeId);
        if (success) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async createIncome(income: IncomeFormValuesValidated): Promise<void> {
        LoadLayoutStore.start();

        const createEntityResult = await this.createIncomeUseCase.execute(
            this.incomeMapper.mapToCreate(income),
        );

        if (createEntityResult) ToastManagerStore.success();

        LoadLayoutStore.finish();
    }

    async editIncome(income: IncomeFormValuesValidated): Promise<void> {
        LoadLayoutStore.start();

        const editIncomeResult = await this.editIncomeUseCase.execute(
            this.incomeMapper.mapToEdit(income),
        );

        if (editIncomeResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }
}
