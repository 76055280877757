import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateFreelanceContractMapper } from "@entity/data/mappers/employee/contracts/freelance/create-freelance-contract.mapper";
import { EditFreelanceContractMapper } from "@entity/data/mappers/employee/contracts/freelance/edit-freelance-contract.mapper";
import {
    FreelanceContract,
    type FreelanceContractSearchFilters,
} from "@entity/domain/models/employee/contracts/freelance/freelance-contract.model";
import { ProfessionalGroup } from "@entity/domain/models/employee/professional-group.model";
import { CreateFreelanceContractUseCase } from "@entity/domain/usecases/employee/contracts/freelance/create-freelance-contract.usecase";
import { DeleteFreelanceContractUseCase } from "@entity/domain/usecases/employee/contracts/freelance/delete-freelance-contract.usecase";
import { EditFreelanceContractUseCase } from "@entity/domain/usecases/employee/contracts/freelance/edit-freelance-contract.usecase";
import { GetAllFreelanceContractsByUseCase } from "@entity/domain/usecases/employee/contracts/freelance/get-all-freelance-contracts-by.usecase";
import { GetAllProfessionalGroupsUseCase } from "@entity/domain/usecases/employee/get-all-professional-groups.usecase";
import { FreelanceContractFormValuesValidated } from "@entity/presentation/component/employee-form/contract/freelance/form/freelance-contract-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class FreelanceContractTabViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    freelanceContracts: FreelanceContract[] = [];

    @observable
    professionalGroups: ProfessionalGroup[] = [];

    employeeId: Undefinable<number> = undefined;

    @observable
    showAddContractModal: boolean = false;

    @observable
    showEditContractModal: boolean = false;

    @observable
    contractToEdit: Undefinable<FreelanceContract> = undefined;

    filtersValue: FreelanceContractSearchFilters = {
        employee: undefined,
    };

    @observable
    filters: FreelanceContractSearchFilters = this.filtersValue;

    constructor(
        @inject(CreateFreelanceContractUseCase)
        private readonly createFreelanceContractUseCase: CreateFreelanceContractUseCase,
        @inject(CreateFreelanceContractMapper)
        private readonly createFreelanceContractMapper: CreateFreelanceContractMapper,
        @inject(DeleteFreelanceContractUseCase)
        private readonly deleteFreelanceContractUseCase: DeleteFreelanceContractUseCase,
        @inject(EditFreelanceContractUseCase)
        private readonly editFreelanceContractUseCase: EditFreelanceContractUseCase,
        @inject(EditFreelanceContractMapper)
        private readonly editFreelanceContractMapper: EditFreelanceContractMapper,
        @inject(GetAllFreelanceContractsByUseCase)
        private readonly getAllFreelanceContractsByUseCase: GetAllFreelanceContractsByUseCase,
        @inject(GetAllProfessionalGroupsUseCase)
        private readonly getAllProfessionalGroupsUseCase: GetAllProfessionalGroupsUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getProfessionalGroups(),
            this.getFreelanceContracts(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getFreelanceContracts(): Promise<void> {
        const freelanceContracts =
            await this.getAllFreelanceContractsByUseCase.execute(this.filters);
        runInAction(() => {
            this.freelanceContracts = freelanceContracts;
        });
    }

    async getProfessionalGroups(): Promise<void> {
        const professionalGroups =
            await this.getAllProfessionalGroupsUseCase.execute();
        runInAction(() => {
            this.professionalGroups = professionalGroups;
        });
    }

    @action
    setShowAddContractModal(show: boolean): void {
        this.showAddContractModal = show;
    }

    @action
    setEmployeeId(employeeId: number): void {
        this.employeeId = employeeId;
        this.filters = {
            ...this.filters,
            employee: employeeId,
        };
    }

    async reloadContracts(): Promise<void> {
        await this.getFreelanceContracts();
    }

    async deleteContract(contractId: number): Promise<void> {
        LoadLayoutStore.start();

        const deleted =
            await this.deleteFreelanceContractUseCase.execute(contractId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async editContract(
        values: FreelanceContractFormValuesValidated,
    ): Promise<void> {
        if (!this.contractToEdit?.id || !this.contractToEdit.employeeId)
            throw Error("Missing freelanceContract or employee");

        LoadLayoutStore.start();

        const editContractResult =
            await this.editFreelanceContractUseCase.execute(
                this.editFreelanceContractMapper.mapFromFreelanceContractFormValues(
                    this.contractToEdit.id,
                    this.contractToEdit.employeeId,
                    values,
                ),
            );

        if (editContractResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async addContract(
        employee: Undefinable<number>,
        values: FreelanceContractFormValuesValidated,
    ): Promise<Nullable<FreelanceContract>> {
        if (!employee) throw Error("Missing employeeId");

        LoadLayoutStore.start();

        const createContractResult =
            await this.createFreelanceContractUseCase.execute(
                this.createFreelanceContractMapper.mapFromFormValues(
                    employee,
                    values,
                ),
            );

        if (createContractResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createContractResult;
    }

    @action
    closeEditContractModal(): void {
        this.showEditContractModal = false;
    }

    @action
    openEditContractModal(
        freelanceContract: Undefinable<FreelanceContract>,
    ): void {
        this.contractToEdit = freelanceContract;
        this.showEditContractModal = true;
    }
}
