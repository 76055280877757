import { LoginRecoveryUseCase } from "@authentication/domain/usecases/login-recovery.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { t } from "i18next";
import { inject, injectable } from "inversify";
import { ObjectSchema, object, string } from "yup";

export interface LoginRecoveryFormValues {
    email: string;
}

@injectable()
export class LoginRecoveryPageViewmodel extends BaseViewModel {
    initialValues: LoginRecoveryFormValues = {
        email: "",
    };

    validationSchema: ObjectSchema<LoginRecoveryFormValues> = object({
        email: string().email().required().trim(),
    });

    constructor(
        @inject(LoginRecoveryUseCase)
        private readonly doLoginRecoveryUseCase: LoginRecoveryUseCase,
    ) {
        super();
    }

    async submitLoginRecovery(
        values: LoginRecoveryFormValues,
    ): Promise<boolean> {
        LoadLayoutStore.start();
        const recoverySuccess = await this.doLoginRecoveryUseCase.execute(
            values.email,
        );

        if (recoverySuccess) {
            ToastManagerStore.success(
                t("public:authentication.recovery.success"),
            );
        }

        LoadLayoutStore.finish();

        return recoverySuccess;
    }
}
