import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { TechnicalProposalDatasource } from "@project/data/datasource/technical-proposal.datasource";
import { ExportBeneficiaryTechnicalProposalFilters } from "@project/domain/models/export-beneficiary-technical-proposal-filters.model";
import {
    CreateTechnicalProposal,
    EditTechnicalProposal,
    TechnicalProposal,
    TechnicalProposals,
    TechnicalProposalsFilters,
} from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";
import { ProjectActivityRepository } from "./activity.repository";

@injectable()
export class TechnicalProposalRepository {
    constructor(
        @inject(TechnicalProposalDatasource)
        private readonly technicalProposalDatasource: TechnicalProposalDatasource,
        @inject(ProjectActivityRepository)
        private readonly projectActivityRepository: ProjectActivityRepository,
    ) {}

    async getAllBy(
        pagination: Pagination,
        filters?: Undefinable<TechnicalProposalsFilters>,
    ): Promise<Either<FallbackError, TechnicalProposals>> {
        return this.technicalProposalDatasource.fetchAllBy(pagination, filters);
    }

    async getById(
        technicalProposalId: number,
    ): Promise<Either<FallbackError, Nullable<TechnicalProposal>>> {
        return this.technicalProposalDatasource.fetchById(technicalProposalId);
    }

    async edit(
        editTechnicalProposal: EditTechnicalProposal,
    ): Promise<Either<FallbackError | ValidationError, TechnicalProposal>> {
        const technicalProposalEdited =
            await this.technicalProposalDatasource.edit(editTechnicalProposal);

        return technicalProposalEdited;
    }

    async create(
        createTechnicalProposal: CreateTechnicalProposal,
    ): Promise<Either<ValidationError | FallbackError, TechnicalProposal>> {
        const technicalProposalCreated =
            await this.technicalProposalDatasource.create(
                createTechnicalProposal,
            );

        return technicalProposalCreated;
    }

    async export(
        technicalProposalId: number,
    ): Promise<Either<FallbackError, Blob>> {
        const response =
            this.technicalProposalDatasource.export(technicalProposalId);
        return response;
    }

    async exportBeneficiaryTechProposalBy(
        filters?: ExportBeneficiaryTechnicalProposalFilters,
    ): Promise<Either<FallbackError, Blob>> {
        const response =
            this.technicalProposalDatasource.exportBeneficiaryTechProposalBy(
                filters,
            );
        return response;
    }
}
