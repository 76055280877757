import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { inject, injectable } from "inversify";
import { ProjectFinancialEntityRepository } from "../../data/repositories/project-financial-entity.repository";
import { ProjectRepository } from "../../data/repositories/project.repository";
import { FinancialEntity } from "../models/financial-entity/financial-entities.model";

@injectable()
export class SearchFinEntByProFinEntUseCase {
    // search financial entity by project financial entity id
    constructor(
        @inject(ProjectFinancialEntityRepository)
        private readonly projectFinancialEntityRepository: ProjectFinancialEntityRepository,
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
        @inject(FinancialEntityRepository)
        private readonly financialEntityRepository: FinancialEntityRepository,
    ) {}

    async execute(
        projectFinancialEntityId: number,
    ): Promise<Nullable<FinancialEntity>> {
        const projectFinancialEntityResponse =
            await this.projectFinancialEntityRepository.getProjectFinancialEntityById(
                projectFinancialEntityId,
            );

        if (projectFinancialEntityResponse.isLeft()) {
            ToastManagerStore.error(
                projectFinancialEntityResponse.getLeftOrThrow().message,
            );
        }

        const projectFinancialEntity = projectFinancialEntityResponse.fold(
            () => null,
            (projectFE) => projectFE,
        );

        if (!projectFinancialEntity) return null;

        const financialEntityId = projectFinancialEntity.financialEntityId;

        const financialEntityResult =
            await this.financialEntityRepository.getById(financialEntityId);

        if (financialEntityResult.isLeft()) {
            ToastManagerStore.error(
                financialEntityResult.getLeftOrThrow().message,
            );
        }

        return financialEntityResult.fold(
            () => null,
            (financialEntity) => financialEntity,
        );
    }
}
