import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { RegconRegisterDatasource } from "@entity/data/datasources/regcon-register.datasource";
import { CCAA } from "@entity/domain/models/ccaa.model";
import { CreateRegconRegister } from "@entity/domain/models/quality-framework/regcon-register/create-regcon-register.model";
import { EditRegconRegister } from "@entity/domain/models/quality-framework/regcon-register/edit-regcon-register.model";
import {
    RegconRegister,
    RegconRegisters,
} from "@entity/domain/models/quality-framework/regcon-register/regcon-register.model";
import { inject, injectable } from "inversify";

@injectable()
export class RegconRegisterRepository {
    constructor(
        @inject(RegconRegisterDatasource)
        private readonly regconRegisterDatasource: RegconRegisterDatasource,
    ) {}

    async getAll(
        pagination: Pagination,
    ): Promise<Either<FallbackError, RegconRegisters>> {
        const regconRegisterResult =
            await this.regconRegisterDatasource.fetchAll(pagination);

        return regconRegisterResult.mapLeft(() => new FallbackError());
    }

    async create(
        regconRegister: CreateRegconRegister,
    ): Promise<Either<ValidationError | FallbackError, RegconRegister>> {
        return this.regconRegisterDatasource.create(regconRegister);
    }

    async edit(
        editRegconRegister: EditRegconRegister,
    ): Promise<Either<ValidationError | FallbackError, RegconRegister>> {
        return this.regconRegisterDatasource.edit(editRegconRegister);
    }

    async delete(
        regconRegisterId: number,
    ): Promise<Either<FallbackError, true>> {
        const regconRegisterResult =
            await this.regconRegisterDatasource.delete(regconRegisterId);
        return regconRegisterResult.mapLeft(() => new FallbackError());
    }

    async getAllCCAA(): Promise<Either<FallbackError, CCAA[]>> {
        return this.regconRegisterDatasource.fetchAllCCAA();
    }
}
