import { DocumentRepository } from "@core/data/repositories/document.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class SignDocumentUseCase {
    constructor(
        @inject(DocumentRepository)
        private readonly documentRepository: DocumentRepository,
    ) {}

    async execute(
        documentId: number,
        signature: string,
        userId: number,
    ): Promise<boolean> {
        const signDocument = await this.documentRepository.sign(
            documentId,
            signature,
            userId,
        );

        if (signDocument.isLeft()) {
            ToastManagerStore.error(signDocument.getLeftOrThrow().message);
        }

        return signDocument.isRight();
    }
}
