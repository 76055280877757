import { IsInt, IsNotEmpty, IsOptional, IsString } from "class-validator";

export class KanbanTaskDto {
    @IsInt()
    @IsOptional()
    readonly id!: number;

    @IsString()
    @IsNotEmpty()
    readonly description!: string;

    @IsInt()
    @IsNotEmpty()
    readonly technical_proposal_activity!: number;
}

export interface KanbanTaskBody {
    description: string;
    technical_proposal_activity: number;
}
