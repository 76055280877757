import { LanguageEnumDto } from "@core/data/dto/language.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";
import {
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { UserRoleEnumDto } from "./user-role.dto";

export class EntityDataDto {
    @IsNumber()
    @IsOptional()
    id!: Nullable<number>;

    @IsString()
    @IsOptional()
    name!: Nullable<string>;
}
export class CurrentUserDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    email!: string;

    @IsString()
    @IsOptional()
    first_name!: Nullable<string>;

    @IsString()
    @IsOptional()
    last_name!: Nullable<string>;

    @IsNumber()
    @IsOptional()
    entity!: Nullable<number>;

    @IsOptional()
    @Type(() => EntityDataDto)
    entity_data!: Nullable<EntityDataDto>;

    @IsEnum(UserRoleEnumDto)
    @IsNotEmpty()
    rol!: UserRoleEnumDto;

    @IsEnum(LanguageEnumDto)
    @IsOptional()
    language!: Nullable<LanguageEnumDto>;
}
