import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { ProjectRepository } from "../../data/repositories/project.repository";

@injectable()
export class ExportJustificationUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(entityId: number): Promise<Blob> {
        const justificationResult =
            await this.projectRepository.exportJustification(entityId);

        if (justificationResult.isLeft()) {
            ToastManagerStore.error(
                justificationResult.getLeftOrThrow().message,
            );
        }

        return justificationResult.getOrThrow();
    }
}
