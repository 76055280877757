import { EditPublicUtilityBody } from "@entity/data/dto/public-utility/edit-public-utility.body";
import { EditPublicUtility } from "@entity/domain/models/public-utility/edit-public-utility.model";
import { PublicUtilityFormValuesValidated } from "@entity/presentation/component/entity-form/general/public-utility/form/public-utility-form";
import { injectable } from "inversify";

@injectable()
export class EditPublicUtilityMapper {
    mapToDto(editPublicUtility: EditPublicUtility): EditPublicUtilityBody {
        const editPublicUtilityBody: EditPublicUtilityBody = {
            id: editPublicUtility.id,
            organization_type: editPublicUtility.organizationType,
            entity: editPublicUtility.entity,
            date: editPublicUtility.date.toISODate() ?? "",
        };
        return editPublicUtilityBody;
    }

    mapFromModal(
        id: number,
        entityId: number,
        publicUtility: PublicUtilityFormValuesValidated,
    ): EditPublicUtility {
        const editPublicUtility: EditPublicUtility = new EditPublicUtility(
            id,
            publicUtility.organizationType,
            entityId,
            publicUtility.date,
        );
        return editPublicUtility;
    }
}
