import { inject, injectable } from "inversify";
import { ProjectExpenseRepository } from "../../../data/repositories/project-expense.repository";
import { ExpenseSubTypeSearchFilters } from "../../models/expenses/expense-search-filters";
import { ExpenseSubType } from "../../models/expenses/sub-type.model";

@injectable()
export class GetAllProjectExpenseSubTypesUseCase {
    constructor(
        @inject(ProjectExpenseRepository)
        private readonly projectExpenseRepository: ProjectExpenseRepository,
    ) {}

    async execute(
        filters?: ExpenseSubTypeSearchFilters,
    ): Promise<ExpenseSubType[]> {
        const typesResult =
            await this.projectExpenseRepository.getAllExpenseSubTypes(filters);

        return typesResult.fold(
            () => [],
            (expensesSubType) => expensesSubType,
        );
    }
}
