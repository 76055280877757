import { LanguageIsoCodeEnum } from "@core/domain/models/language.model";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { UserRoleEnum } from "@user/domain/models/user-role.model";

export interface CRUDPermissions {
    create: boolean;
    edit: boolean;
    delete: boolean;
    view: boolean;
}

export interface AuthenticatedUserPermissions {
    entity: CRUDPermissions;
    user: CRUDPermissions;
    beneficiary: CRUDPermissions;
    proceeding: CRUDPermissions;
    project: CRUDPermissions;
}

export class AuthenticatedUser {
    constructor(
        public readonly id: number,
        public readonly email: string,
        public readonly name: string,
        public readonly rol: UserRoleEnum,
        public readonly entityId: Undefinable<number>,
        public readonly entityName: Undefinable<string>,
        public readonly language: Undefinable<LanguageIsoCodeEnum>,
        public readonly permissions: AuthenticatedUserPermissions,
    ) {}

    updateEntity(
        entityId: Undefinable<number>,
        entityName: Undefinable<string>,
    ): AuthenticatedUser {
        if (this.rol !== UserRoleEnum.SuperAdmin) {
            throw new Error(
                "Only users with the SuperAdmin role can change the entity",
            );
        }
        return new AuthenticatedUser(
            this.id,
            this.name,
            this.email,
            this.rol,
            entityId,
            entityName,
            this.language,
            this.permissions,
        );
    }

    /**
     * Check if the current User is Entity Admin or SuperAdmin
     * @returns boolean
     */
    isAdmin(): this is AuthenticatedUser & {
        rol: UserRoleEnum.EntityAdmin | UserRoleEnum.SuperAdmin;
    } {
        return this.rol === UserRoleEnum.EntityAdmin;
    }

    /**
     * Check if the current User is Professional
     * @returns boolean
     */
    isProfessional(): this is AuthenticatedUser & {
        rol: UserRoleEnum.Professional;
    } {
        return this.rol === UserRoleEnum.Professional;
    }

    /**
     * Check if the current User is Super Admin
     * @returns boolean
     */
    isSuperAdmin(): this is AuthenticatedUser & {
        rol: UserRoleEnum.SuperAdmin;
    } {
        return this.rol === UserRoleEnum.SuperAdmin;
    }

    /**
     * Check if the current User is NOT Super Admin
     * @returns boolean
     */
    isNotSuperAdmin(): this is AuthenticatedUser & { entityId: number } {
        return this.rol !== UserRoleEnum.SuperAdmin;
    }

    /**
     * Check if a Super Admin has selected an Entity
     * @returns boolean
     */
    impersonatedEntity(): this is AuthenticatedUser & {
        entityId: number;
        rol: UserRoleEnum.SuperAdmin;
    } {
        return this.isSuperAdmin() && this.entityId !== undefined;
    }

    /**
     * Check if a Super Admin has NOT selected an Entity
     * @returns boolean
     */
    notImpersonatedEntity(): this is AuthenticatedUser & {
        rol: UserRoleEnum.SuperAdmin;
    } {
        return this.isSuperAdmin() && this.entityId === undefined;
    }

    /**
     * Check if the entity Id matchs the user current Entity
     * @param entityId number
     * @returns boolena
     */
    matchEntity(
        entityId: number,
    ): this is AuthenticatedUser & { entityId: number } {
        return this.entityId === entityId;
    }
}
