import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { ActionLine } from "@entity/domain/models/action-line.model";
import { Collective } from "@entity/domain/models/collective.model";
import { GetAllActionLinesUseCase } from "@entity/domain/usecases/get-all-action-lines.usecase";
import { GetAllCollectivesUseCase } from "@entity/domain/usecases/get-all-collectives.usecase";
import { Catalogue } from "@project/domain/models/catalogue.model";
import { Status } from "@project/domain/models/status.model";
import { TerritorialScope } from "@project/domain/models/territorial-scope.model";
import { GetAllCataloguesUseCase } from "@project/domain/usecases/get-all-catalogues.usecase";
import { GetAllStatusUseCase } from "@project/domain/usecases/get-all-status.usecase";
import { GetAllTerritorialScopeUseCase } from "@project/domain/usecases/get-all-territorial-scope.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

export interface DocumentTable {
    id: number;
    name: string;
    uploadDate: string;
}

@injectable()
export class GeneralTabViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    territorialScopes: TerritorialScope[] = [];

    @observable
    private _financialEntitiesCount: number = 0;

    @observable
    status: Status[] = [];

    @observable
    actionLines: ActionLine[] = [];

    @observable
    collectives: Collective[] = [];

    @observable
    catalogues: Catalogue[] = [];

    constructor(
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
        @inject(GetAllCataloguesUseCase)
        private readonly getAllCataloguesUseCase: GetAllCataloguesUseCase,
        @inject(GetAllStatusUseCase)
        private readonly getAllStatusUseCase: GetAllStatusUseCase,
        @inject(GetAllTerritorialScopeUseCase)
        private readonly getAllTerritorialScopeUseCase: GetAllTerritorialScopeUseCase,
        @inject(GetAllCollectivesUseCase)
        private readonly getAllCollectivesUseCase: GetAllCollectivesUseCase,
        @inject(GetAllActionLinesUseCase)
        private readonly getAllActionLinesUseCase: GetAllActionLinesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllCatalogues(),
            this.getAllStatus(),
            this.getTerritorialScopes(),
            this.getAllActionLines(),
            this.getAllCollectives(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setFinancialEntitiesCount(count: number): void {
        this._financialEntitiesCount = count;
    }

    async getAllCatalogues(): Promise<void> {
        const catalogues = await this.getAllCataloguesUseCase.execute();

        runInAction(() => {
            this.catalogues = catalogues;
        });
    }

    async getAllActionLines(): Promise<void> {
        const actionLines = await this.getAllActionLinesUseCase.execute();

        runInAction(() => {
            this.actionLines = actionLines;
        });
    }

    async getAllCollectives(): Promise<void> {
        const collectives = await this.getAllCollectivesUseCase.execute();

        runInAction(() => {
            this.collectives = collectives;
        });
    }

    async getAllStatus(): Promise<void> {
        const status = await this.getAllStatusUseCase.execute();

        runInAction(() => {
            this.status = status;
        });
    }

    async getTerritorialScopes(): Promise<void> {
        const territorialScopes =
            await this.getAllTerritorialScopeUseCase.execute();

        runInAction(() => {
            this.territorialScopes = territorialScopes;
        });
    }

    async deleteDocument(documentId: number): Promise<boolean> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return deleted;
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        if (documentUrl) {
            await this.downloadDocumentUseCase.execute(documentUrl);
        }

        LoadLayoutStore.finish();
    }
}
