import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { ProjectEmployeeDatasource } from "@project/data/datasource/project-employee.datasource";
import {
    CreateProjectEmployee,
    ProjectEmployee,
    ProjectEmployees,
    ProjectEmployeesSearchFilters,
    UpdateProjectEmployee,
} from "@project/domain/models/project-employees.model";
import { inject, injectable } from "inversify";

@injectable()
export class ProjectEmployeeRepository {
    constructor(
        @inject(ProjectEmployeeDatasource)
        private readonly projectEmployeeDatasource: ProjectEmployeeDatasource,
    ) {}

    async getById(id: number): Promise<Either<FallbackError, ProjectEmployee>> {
        return this.projectEmployeeDatasource.fetchById(id);
    }

    async getBy(
        pagination?: Pagination,
        filters?: ProjectEmployeesSearchFilters,
    ): Promise<Either<FallbackError, ProjectEmployees>> {
        return this.projectEmployeeDatasource.fetchBy(pagination, filters);
    }

    async getAllBy(
        filters?: ProjectEmployeesSearchFilters,
    ): Promise<Either<FallbackError, ProjectEmployee[]>> {
        const projectEmployeesResult = await this.getBy(
            Pagination.NoPagination(),
            filters,
        );

        return projectEmployeesResult.map(
            (projectEmployees) => projectEmployees.employees,
        );
    }

    async create(
        projectEmployee: CreateProjectEmployee,
    ): Promise<Either<ValidationError | FallbackError, ProjectEmployee>> {
        return this.projectEmployeeDatasource.create(projectEmployee);
    }

    async update(
        projectEmployee: UpdateProjectEmployee,
    ): Promise<Either<ValidationError | FallbackError, ProjectEmployee>> {
        return this.projectEmployeeDatasource.update(projectEmployee);
    }

    async delete(id: number): Promise<Either<FallbackError, true>> {
        return this.projectEmployeeDatasource.delete(id);
    }
}
