import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Nullable } from "@core/domain/types/nullable.type";
import { EditExpenseAllTypes } from "@project/domain/models/expenses/edit-expense.model";
import { ExpenseAllTypes } from "@project/domain/models/expenses/expense.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { ContractType } from "../../domain/models/expenses/contract-type.model";
import { ContributionGroupType } from "../../domain/models/expenses/contribution-group-type.model";
import { CreateExpenseAllTypes } from "../../domain/models/expenses/create-expense.model";
import { DietType } from "../../domain/models/expenses/diet-type.model";
import {
    ExpenseSearchFilters,
    ExpenseSubTypeSearchFilters,
} from "../../domain/models/expenses/expense-search-filters";
import { ExpenseType } from "../../domain/models/expenses/expense-type.model";
import { Expenses } from "../../domain/models/expenses/expenses.model";
import { MonthType } from "../../domain/models/expenses/month-type.model";
import { ExpenseSubType } from "../../domain/models/expenses/sub-type.model";
import { TransportType } from "../../domain/models/expenses/transport-type.model";
import { PaymentMethodType } from "../../domain/models/payment-method-type.model";
import { ContractTypeDto } from "../dto/expenses/contract-type.dto";
import { ContributionGroupTypeDto } from "../dto/expenses/contribution-group-type.dto";
import {
    CreateExpenseAllTypesBody,
    CreateExpenseBody,
} from "../dto/expenses/create-expense.body";
import { DietTypeDto } from "../dto/expenses/diet-type.dto";
import {
    EditExpenseAllTypesBody,
    EditExpenseBody,
} from "../dto/expenses/edit-expense.body";
import {
    ExpenseSubTypeDto,
    ExpenseSubTypeQuery,
} from "../dto/expenses/expense-sub-type.dto";
import { ExpenseTypeDto } from "../dto/expenses/expense-type.dto";
import {
    ExpenseDto,
    ExpensesDto,
    ExpensesQuery,
} from "../dto/expenses/expenses.dto";
import { MonthTypeDto } from "../dto/expenses/month-type.dto";
import { PaymentMethodTypeDto } from "../dto/expenses/payment-method-type.dto";
import { TransportTypeDto } from "../dto/expenses/transport-type.dto";
import { ContractTypeMapper } from "../mappers/expenses/contract-type.mapper";
import { ContributionGroupTypeMapper } from "../mappers/expenses/contribution-group-type.mapper";
import { CreateExpenseMapper } from "../mappers/expenses/create-expense.mapper";
import { DietTypeMapper } from "../mappers/expenses/diet-type.mapper";
import { EditExpenseMapper } from "../mappers/expenses/edit-expense.mapper";
import { ExpenseSubTypeMapper } from "../mappers/expenses/expense-sub-type.mapper";
import { ExpenseTypeEnumMapper } from "../mappers/expenses/expense-type-enum.mapper";
import { ExpenseTypeMapper } from "../mappers/expenses/expense-type.mapper";
import { ExpenseMapper } from "../mappers/expenses/expense.mapper";
import { ExpensesMapper } from "../mappers/expenses/expenses.mapper";
import { MonthTypeMapper } from "../mappers/expenses/month-type.mapper";
import { PaymentMethodTypeMapper } from "../mappers/expenses/payment-method-type.mapper";
import { TransportTypeMapper } from "../mappers/expenses/transport-type.mapper";

const ROOT_PATH = "/projects_expenses/";

@injectable()
export class ProjectExpenseDatasource {
    // eslint-disable-next-line max-params
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(ExpenseTypeMapper)
        private readonly expenseTypeMapper: ExpenseTypeMapper,
        @inject(ExpenseSubTypeMapper)
        private readonly typeMapper: ExpenseSubTypeMapper,
        @inject(ExpenseTypeEnumMapper)
        private readonly expenseTypeEnumMapper: ExpenseTypeEnumMapper,
        @inject(ExpensesMapper)
        private readonly expensesMapper: ExpensesMapper,
        @inject(DietTypeMapper)
        private readonly dietTypeMapper: DietTypeMapper,
        @inject(TransportTypeMapper)
        private readonly transportTypeMapper: TransportTypeMapper,
        @inject(PaymentMethodTypeMapper)
        private readonly paymentMethodTypeMapper: PaymentMethodTypeMapper,
        @inject(MonthTypeMapper)
        private readonly monthTypeMapper: MonthTypeMapper,
        @inject(ContributionGroupTypeMapper)
        private readonly contributionGroupTypeMapper: ContributionGroupTypeMapper,
        @inject(ContractTypeMapper)
        private readonly contractTypeMapper: ContractTypeMapper,
        @inject(ExpenseMapper)
        private readonly expenseMapper: ExpenseMapper,
        @inject(CreateExpenseMapper)
        private readonly createExpenseMapper: CreateExpenseMapper,
        @inject(EditExpenseMapper)
        private readonly editExpenseMapper: EditExpenseMapper,
    ) {}

    async create(
        expense: CreateExpenseAllTypes,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<ExpenseAllTypes>>
    > {
        const createdExpenseDto = this.createExpenseMapper.mapToDto(expense);

        if (createdExpenseDto === null) {
            return Either.Left(new FallbackError());
        }

        const createExpenseResult = await this.http.post<
            CreateExpenseBody,
            CreateExpenseAllTypesBody
        >(ROOT_PATH, createdExpenseDto);

        return createExpenseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdExpense = this.expenseMapper.map(
                    plainToClass(ExpenseDto, response.data),
                );

                return Either.Right(createdExpense);
            });
    }

    async update(
        expense: EditExpenseAllTypes,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<ExpenseAllTypes>>
    > {
        const editedExpenseDto = this.editExpenseMapper.mapToDto(expense);

        if (editedExpenseDto === null) {
            return Either.Left(new FallbackError());
        }

        const editedExpenseResult = await this.http.patch<
            EditExpenseBody,
            EditExpenseAllTypesBody
        >(`${ROOT_PATH}${editedExpenseDto.id}/`, editedExpenseDto);

        return editedExpenseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const editedExpense = this.expenseMapper.map(
                    plainToClass(ExpenseDto, response.data),
                );

                // if (!editedExpense) return Either.Left(new FallbackError());

                return Either.Right(editedExpense);
            });
    }

    async delete(expenseId: number): Promise<Either<FallbackError, true>> {
        const deleteExpense = await this.http.delete(
            `${ROOT_PATH}${expenseId}`,
        );

        return deleteExpense.mapLeft(() => new FallbackError()).map(() => true);
    }

    async fetchAllExpenseTypes(): Promise<
        Either<FallbackError, ExpenseType[]>
    > {
        const results = await this.http.get<ExpenseTypeDto[]>(
            `${ROOT_PATH}get_types_list/`,
        );

        return results
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((expenseTypeDto) =>
                    this.expenseTypeMapper.map(
                        plainToClass(ExpenseTypeDto, expenseTypeDto),
                    ),
                ),
            );
    }

    async fetchAllExpenseSubTypes(
        filters?: ExpenseSubTypeSearchFilters,
    ): Promise<Either<FallbackError, ExpenseSubType[]>> {
        const query: ExpenseSubTypeQuery = {};

        if (filters?.typeId) query.type_expense = filters.typeId;

        const responseResult = await this.http.get<ExpenseSubTypeDto[]>(
            "/projects_expenses/get_subtypes_list/",
            { query },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((expenseTypeDto) =>
                    this.typeMapper.map(
                        plainToClass(ExpenseSubTypeDto, expenseTypeDto),
                    ),
                ),
            );
    }

    async fetchAllDietTypes(): Promise<Either<FallbackError, DietType[]>> {
        const responseResult = await this.http.get<DietTypeDto[]>(
            "/projects_expenses/get_type_diet_list/",
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((dietTypeDto) =>
                    this.dietTypeMapper.map(
                        plainToClass(DietTypeDto, dietTypeDto),
                    ),
                ),
            );
    }

    async fetchAllTransportTypes(): Promise<
        Either<FallbackError, TransportType[]>
    > {
        const responseResult = await this.http.get<TransportTypeDto[]>(
            "/projects_expenses/get_type_transportation_list/",
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((transportTypeDto) =>
                    this.transportTypeMapper.map(
                        plainToClass(TransportTypeDto, transportTypeDto),
                    ),
                ),
            );
    }

    async fetchAllMonthTypes(): Promise<Either<FallbackError, MonthType[]>> {
        const responseResult = await this.http.get<MonthTypeDto[]>(
            "/projects_expenses/get_months_list/",
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((monthTypeDto) =>
                    this.monthTypeMapper.map(
                        plainToClass(MonthTypeDto, monthTypeDto),
                    ),
                ),
            );
    }

    async fetchAllContributionGroupTypes(): Promise<
        Either<FallbackError, ContributionGroupType[]>
    > {
        const responseResult = await this.http.get<ContributionGroupTypeDto[]>(
            "/projects_expenses/get_contribution_group_list/",
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((expenseTypeDto) =>
                    this.contributionGroupTypeMapper.map(
                        plainToClass(ContributionGroupTypeDto, expenseTypeDto),
                    ),
                ),
            );
    }

    async fetchAllContractTypes(): Promise<
        Either<FallbackError, ContractType[]>
    > {
        const responseResult = await this.http.get<ContractType[]>(
            "/projects_expenses/get_contract_type_list/",
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((contractType) =>
                    this.contractTypeMapper.map(
                        plainToClass(ContractTypeDto, contractType),
                    ),
                ),
            );
    }

    async fetchAllPaymentMethodTypes(): Promise<
        Either<FallbackError, PaymentMethodType[]>
    > {
        const responseResult = await this.http.get<PaymentMethodTypeDto[]>(
            "/projects_expenses/get_payment_methods_list/",
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((paymentMethodTypeDto) =>
                    this.paymentMethodTypeMapper.map(
                        plainToClass(
                            PaymentMethodTypeDto,
                            paymentMethodTypeDto,
                        ),
                    ),
                ),
            );
    }

    async fetchBy(
        pagination: Pagination,
        filters?: ExpenseSearchFilters,
    ): Promise<Either<HttpError, Expenses>> {
        const query: ExpensesQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.text) {
            query.search_expenses = filters.text;
        }
        if (filters?.type) {
            query.type_expense = this.expenseTypeEnumMapper.mapToDto(
                filters.type,
            );
        }
        if (filters?.projectId) {
            query.project = filters.projectId;
        }

        const expensesResult = await this.http.get<ExpensesDto>(
            "/projects_expenses/",
            {
                query,
            },
        );

        const expenses = expensesResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.expensesMapper.map(
                    plainToClass(ExpensesDto, response.data),
                ),
            );

        return expenses;
    }

    async getExpenseById(
        additionalInvoiceId: number,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<ExpenseAllTypes>>
    > {
        const expensesResultById = await this.http.get(
            `${ROOT_PATH}${additionalInvoiceId}/`,
        );

        return expensesResultById
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdExpense = this.expenseMapper.map(
                    plainToClass(ExpenseDto, response.data),
                );

                return Either.Right(createdExpense);
            });
    }
}
