import { Pagination } from "@core/domain/models/pagination";
import { Nullable } from "@core/domain/types/nullable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import type {
    BudgetSummary,
    BudgetsSearchFilters,
} from "@project/domain/models/budget-summary.model";
import { FinancialEntitiesSearchFilters } from "@project/domain/models/financial-entity/financial-entities-search-filters";
import { FinancialEntities } from "@project/domain/models/financial-entity/financial-entities.model";
import { FinancingType } from "@project/domain/models/financial-entity/financing-type";
import { GetAllFinancialEntitiesUseCase } from "@project/domain/usecases/financial-entity/get-all-financial-entities.usecase";
import { GetAllFinancingTypesUsecase } from "@project/domain/usecases/financial-entity/get-all-financing-types.usecase";
import { GetAllBudgetsUseCase } from "@project/domain/usecases/get-all-budgets.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class ProjectFinancialEntityFormViewModel extends BaseViewModel {
    _entityId: Nullable<number> = null;

    _financialEntityId: Nullable<number> = null;

    @observable
    initiallyLoading: boolean = true;

    @observable
    loadingBudget: boolean = false;

    @observable
    financialEntitiesForm: FinancialEntities = new FinancialEntities([], 0);

    @observable
    budgetsSummary: BudgetSummary[] = [];

    @observable
    financingTypes: FinancingType[] = [];

    get entityId(): number {
        if (!this._entityId) {
            throw Error("Missing entity Id");
        }

        return this._entityId;
    }

    constructor(
        @inject(GetAllFinancialEntitiesUseCase)
        private readonly getAllFinancialEntitiesUseCase: GetAllFinancialEntitiesUseCase,
        @inject(GetAllBudgetsUseCase)
        private readonly getAllBudgetsUseCase: GetAllBudgetsUseCase,
        @inject(GetAllFinancingTypesUsecase)
        private readonly getAllFinancingTypesUseCase: GetAllFinancingTypesUsecase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllFinancialEntitiesByEntity(),
            this.getAllBudgets({
                financialEntityId: this._financialEntityId,
            }),
            this.getAllFinancingTypes(),
        ]);

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    async getAllFinancingTypes(): Promise<void> {
        const financingTypes = await this.getAllFinancingTypesUseCase.execute();

        runInAction(() => {
            this.financingTypes = financingTypes;
        });
    }

    async getAllFinancialEntitiesByEntity(): Promise<void> {
        const filters: FinancialEntitiesSearchFilters = {
            entityId: this._entityId,
            hasBudget: true,
        };

        const financialEntities =
            await this.getAllFinancialEntitiesUseCase.execute(filters);

        runInAction(() => {
            this.financialEntitiesForm = new FinancialEntities(
                financialEntities,
                financialEntities.length,
            );
        });
    }

    async getAllBudgets(filters?: BudgetsSearchFilters): Promise<void> {
        this.loadingBudget = true;

        const budgets = await this.getAllBudgetsUseCase.execute(
            Pagination.NoPagination(),
            filters,
        );

        runInAction(() => {
            this.budgetsSummary = budgets.budgetSummary;
            this.loadingBudget = false;
        });
    }
}
