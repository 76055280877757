import {
    CreateIncome,
    EditIncome,
    Income,
    Incomes,
} from "@beneficiary/domain/models/economic-data/income/income.model";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";
import { IncomeDatasource } from "../datasource/income.datasource";

@injectable()
export class IncomeRepository {
    constructor(
        @inject(IncomeDatasource)
        private readonly incomeDatasource: IncomeDatasource,
    ) {}

    async create(
        createIncome: CreateIncome,
    ): Promise<Either<ValidationError | FallbackError, Income>> {
        return this.incomeDatasource.create(createIncome);
    }

    async edit(
        income: EditIncome,
    ): Promise<Either<ValidationError | FallbackError, Income>> {
        return this.incomeDatasource.edit(income);
    }

    async delete(incomeId: number): Promise<Either<FallbackError, boolean>> {
        return this.incomeDatasource.delete(incomeId);
    }

    async getAllBy(
        beneficiaryId: number,
    ): Promise<Either<FallbackError, Incomes>> {
        const incomesSummary = await this.incomeDatasource.fetchAllBy(
            beneficiaryId,
            Pagination.NoPagination(),
        );

        return incomesSummary.mapLeft(() => new FallbackError());
    }

    async findById(incomeId: number): Promise<Either<FallbackError, Income>> {
        const incomeResult = await this.incomeDatasource.fetchById(incomeId);

        if (incomeResult.isLeft())
            return Either.Left(incomeResult.getLeftOrThrow());

        const { income } = incomeResult.getOrThrow();

        return Either.Right(income);
    }
}
