import { Incomes } from "@beneficiary/domain/models/economic-data/income/income.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { IncomesDto } from "../dto/beneficiary.dto";
import { IncomeMapper } from "./income.mapper";

@injectable()
export class IncomesMapper {
    constructor(
        @inject(IncomeMapper)
        private readonly incomeMapper: IncomeMapper,
    ) {}

    map(incomesDto: IncomesDto): Incomes {
        const errors = validateSync(incomesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Incomes([], 0);
        }

        const incomes = incomesDto.results.mapNotNull((incomeDto) =>
            this.incomeMapper.map(incomeDto),
        );

        return new Incomes(incomes, incomesDto.count);
    }
}
