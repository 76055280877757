import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum MetricUnitEnumDto {
    Kg = "kg",
    Euro = "euro",
    Units = "units",
    Other = "other",
    Litres = "l",
}

export class MetricUnitDto {
    @IsEnum(MetricUnitEnumDto)
    @IsNotEmpty()
    key!: MetricUnitEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
