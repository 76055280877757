import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectFinancialEntityRepository } from "@project/data/repositories/project-financial-entity.repository";
import { BudgetSpreadsheet } from "@project/domain/models/budget-spreadsheet.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetBudgetSpreadsheetByIdUseCase {
    constructor(
        @inject(ProjectFinancialEntityRepository)
        private readonly projectFinancialEntityRepository: ProjectFinancialEntityRepository,
    ) {}

    async execute(
        projectFinancialEntityId: number,
    ): Promise<Nullable<BudgetSpreadsheet>> {
        const projectFinancialEntityResult =
            await this.projectFinancialEntityRepository.getBudgetSpreadsheetById(
                projectFinancialEntityId,
            );

        if (projectFinancialEntityResult.isLeft()) {
            ToastManagerStore.error(
                projectFinancialEntityResult.getLeftOrThrow().message,
            );

            return null;
        }

        return projectFinancialEntityResult.getOrThrow();
    }
}
