import { IdentificationDocumentTypeEnum } from "@core/domain/models/identification-document-type.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { VolunteerInfo } from "@entity/domain/models/employee/volunteer-info.model";
import { EmployeeTypeEnum } from "./employee-type.model";

export class Employee {
    get fullName(): string {
        return `${this.name} ${this.surname}`;
    }

    get fullIdentification(): string {
        return `${this.identificationTypeLabel} - ${this.identification}`;
    }

    public isActiveContract?: boolean;
    public isActive?: boolean;
    public percentageOcupation?: number;
    public availabilityHours?: number;
    public contractedHours?: number;
    public codeVerification?: string;
    public documents?: IncDocument[];

    constructor(
        public id: number,
        public name: string,
        public surname: string,
        public identification: string,
        public identificationType: IdentificationDocumentTypeEnum,
        public identificationTypeLabel: string,
        public type: EmployeeTypeEnum,
        public typeLabel: string,
        public entity: number,
        public volunteerInfo?: Nullable<VolunteerInfo>,
    ) {}
}

export class Employees {
    constructor(
        public readonly employees: Employee[],
        public readonly count: number,
    ) {}
}

export class EmployeesCount {
    constructor(
        public employee: number,
        public volunteer: number,
        public freelance: number,
    ) {}
}

export interface EmployeeSearchFilters {
    name?: Undefinable<string>;
    entityId?: Nullable<number>;
    type?: Undefinable<EmployeeTypeEnum>;
    isActive?: Undefinable<string>;
    activeContract?: Undefinable<boolean>;
    entityIds?: Nullable<string[]>;
}
