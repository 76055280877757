import { Nullable } from "@core/domain/types/nullable.type";
import { ProfessionalGroupDto } from "@entity/data/dto/employee/professional-group.dto";
import { ProfessionalGroupEnumMapper } from "@entity/data/mappers/employee/professional-group-enum.mapper";
import {
    ProfessionalGroup,
    ProfessionalGroupEnum,
} from "@entity/domain/models/employee/professional-group.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ProfessionalGroupMapper {
    constructor(
        @inject(ProfessionalGroupEnumMapper)
        private professionalGroupEnumMapper: ProfessionalGroupEnumMapper,
    ) {}

    map(
        professionalGroupDto: ProfessionalGroupDto,
    ): Nullable<ProfessionalGroup> {
        const errors = validateSync(professionalGroupDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<ProfessionalGroupEnum> =
            this.professionalGroupEnumMapper.map(professionalGroupDto.key);

        if (!id) return null;

        return new ProfessionalGroup(id, professionalGroupDto.value);
    }
}
