"use client";
import { Skeleton } from "antd";
import { SkeletonInputProps } from "antd/es/skeleton/Input";
import { FC, PropsWithChildren } from "react";

export interface IncSkeletonInputProps
    extends PropsWithChildren<SkeletonInputProps> {
    loading?: boolean;
}

export const IncSkeletonInput: FC<IncSkeletonInputProps> = (props) => {
    const { children, active, loading = true, ...restProps } = props;

    /* This overrides the default behaviour of Ant Design */
    /* they always render the children, we will not */
    return loading ? (
        <Skeleton.Input
            {...restProps}
            active={Boolean(active)}
        />
    ) : (
        children
    );
};
