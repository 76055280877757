import { Undefinable } from "@core/domain/types/undefinable.type";
import { DateTime } from "luxon";
import { UserRoleEnum } from "./user-role.model";
import { User, UserRelatedEmployee } from "./user.model";

export class UserSummary {
    constructor(
        public readonly id: number,
        public readonly email: string,
        public readonly lastLogin: Undefinable<DateTime>,
        public readonly roleLabel: string,
        public readonly roleEnum: UserRoleEnum,
        public readonly relatedEmployee: Undefinable<UserRelatedEmployee>,
    ) {}

    isEntityUser(): this is User & { entityId: number } {
        return this.roleEnum !== UserRoleEnum.SuperAdmin;
    }
}

export class UsersSummary {
    constructor(
        public readonly usersSummary: UserSummary[],
        public readonly count: number,
    ) {}
}
