import { EmployeeRepository } from "@entity/data/repositories/employee.repository";
import { SubsidyReason } from "@entity/domain/models/employee/subsidy-reason.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllSubsidyReasonsUseCase {
    constructor(
        @inject(EmployeeRepository)
        private readonly employeeRepository: EmployeeRepository,
    ) {}

    async execute(): Promise<SubsidyReason[]> {
        const subsidiesResult =
            await this.employeeRepository.getAllSubsidyReasons();

        return subsidiesResult.fold(
            () => [],
            (subsidyReasons) => subsidyReasons,
        );
    }
}
