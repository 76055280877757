import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { ProfessionalGroupEnum } from "@entity/domain/models/employee/professional-group.model";
import { DateTime } from "luxon";

export class FreelanceContract {
    // eslint-disable-next-line max-params
    constructor(
        public readonly id: number,
        public service: string,
        public professionalGroup: ProfessionalGroupEnum,
        public startDate: DateTime,
        public totalHours: number,
        public hoursCost: number,
        public totalCost: number,
        public invoicesNumber: number,
        public irpf: number,
        public employeeId: number,
        public endDate?: Nullable<DateTime>,
    ) {}
}

export class FreelanceContracts {
    constructor(
        public readonly freelanceContracts: FreelanceContract[],
        public readonly count: number,
    ) {}
}

export interface FreelanceContractSearchFilters {
    employee?: Undefinable<number>;
}
