import { Nullable } from "@core/domain/types/nullable.type";
import { CollectiveEnumDto } from "@entity/data/dto/collectives.dto";
import { CollectiveEnum } from "@entity/domain/models/collective.model";
import { injectable } from "inversify";

@injectable()
export class CollectiveEnumMapper {
    map(collectiveEnumDto: CollectiveEnumDto): Nullable<CollectiveEnum> {
        switch (collectiveEnumDto) {
            case CollectiveEnumDto.All:
                return CollectiveEnum.All;
            case CollectiveEnumDto.Women:
                return CollectiveEnum.Women;
            case CollectiveEnumDto.GenderVictims:
                return CollectiveEnum.GenderVictims;
            case CollectiveEnumDto.AtRiskOrExclusion:
                return CollectiveEnum.AtRiskOrExclusion;
            case CollectiveEnumDto.SocialVulnerability:
                return CollectiveEnum.SocialVulnerability;
            case CollectiveEnumDto.ChildrenAndFamily:
                return CollectiveEnum.ChildrenAndFamily;
            case CollectiveEnumDto.MinimumIncomeRecipients:
                return CollectiveEnum.MinimumIncomeRecipients;
            case CollectiveEnumDto.Disability:
                return CollectiveEnum.Disability;
            case CollectiveEnumDto.Elderly:
                return CollectiveEnum.Elderly;
            case CollectiveEnumDto.Youth:
                return CollectiveEnum.Youth;
            case CollectiveEnumDto.Lgbtiq:
                return CollectiveEnum.Lgbtiq;
            case CollectiveEnumDto.MigrantsResidentsSpain:
                return CollectiveEnum.MigrantsResidentsSpain;
            case CollectiveEnumDto.MigrantsResidentsAbroad:
                return CollectiveEnum.MigrantsResidentsAbroad;
            case CollectiveEnumDto.Addicts:
                return CollectiveEnum.Addicts;
            case CollectiveEnumDto.GypsyEthnicity:
                return CollectiveEnum.GypsyEthnicity;
            case CollectiveEnumDto.EthnicMinorities:
                return CollectiveEnum.EthnicMinorities;
            case CollectiveEnumDto.HivAids:
                return CollectiveEnum.HivAids;
            case CollectiveEnumDto.ChronicIllnesses:
                return CollectiveEnum.ChronicIllnesses;
            case CollectiveEnumDto.RareDiseases:
                return CollectiveEnum.RareDiseases;
            case CollectiveEnumDto.MentalIllness:
                return CollectiveEnum.MentalIllness;
            case CollectiveEnumDto.PrisonRelated:
                return CollectiveEnum.PrisonRelated;
            case CollectiveEnumDto.Homeless:
                return CollectiveEnum.Homeless;
            case CollectiveEnumDto.RuralResidents:
                return CollectiveEnum.RuralResidents;
            case CollectiveEnumDto.Families:
                return CollectiveEnum.Families;
            case CollectiveEnumDto.Volunteers:
                return CollectiveEnum.Volunteers;
            case CollectiveEnumDto.OtherEntitiesProfessionals:
                return CollectiveEnum.OtherEntitiesProfessionals;
            case CollectiveEnumDto.GeneralPopulation:
                return CollectiveEnum.GeneralPopulation;
            case CollectiveEnumDto.Other:
                return CollectiveEnum.Other;
            default:
                return null;
        }
    }

    mapToDto(collectiveEnum: CollectiveEnum): CollectiveEnumDto {
        // eslint-disable-next-line default-case
        switch (collectiveEnum) {
            case CollectiveEnum.All:
                return CollectiveEnumDto.All;
            case CollectiveEnum.Women:
                return CollectiveEnumDto.Women;
            case CollectiveEnum.GenderVictims:
                return CollectiveEnumDto.GenderVictims;
            case CollectiveEnum.AtRiskOrExclusion:
                return CollectiveEnumDto.AtRiskOrExclusion;
            case CollectiveEnum.SocialVulnerability:
                return CollectiveEnumDto.SocialVulnerability;
            case CollectiveEnum.ChildrenAndFamily:
                return CollectiveEnumDto.ChildrenAndFamily;
            case CollectiveEnum.MinimumIncomeRecipients:
                return CollectiveEnumDto.MinimumIncomeRecipients;
            case CollectiveEnum.Disability:
                return CollectiveEnumDto.Disability;
            case CollectiveEnum.Elderly:
                return CollectiveEnumDto.Elderly;
            case CollectiveEnum.Youth:
                return CollectiveEnumDto.Youth;
            case CollectiveEnum.Lgbtiq:
                return CollectiveEnumDto.Lgbtiq;
            case CollectiveEnum.MigrantsResidentsSpain:
                return CollectiveEnumDto.MigrantsResidentsSpain;
            case CollectiveEnum.MigrantsResidentsAbroad:
                return CollectiveEnumDto.MigrantsResidentsAbroad;
            case CollectiveEnum.Addicts:
                return CollectiveEnumDto.Addicts;
            case CollectiveEnum.GypsyEthnicity:
                return CollectiveEnumDto.GypsyEthnicity;
            case CollectiveEnum.EthnicMinorities:
                return CollectiveEnumDto.EthnicMinorities;
            case CollectiveEnum.HivAids:
                return CollectiveEnumDto.HivAids;
            case CollectiveEnum.ChronicIllnesses:
                return CollectiveEnumDto.ChronicIllnesses;
            case CollectiveEnum.RareDiseases:
                return CollectiveEnumDto.RareDiseases;
            case CollectiveEnum.MentalIllness:
                return CollectiveEnumDto.MentalIllness;
            case CollectiveEnum.PrisonRelated:
                return CollectiveEnumDto.PrisonRelated;
            case CollectiveEnum.Homeless:
                return CollectiveEnumDto.Homeless;
            case CollectiveEnum.RuralResidents:
                return CollectiveEnumDto.RuralResidents;
            case CollectiveEnum.Families:
                return CollectiveEnumDto.Families;
            case CollectiveEnum.Volunteers:
                return CollectiveEnumDto.Volunteers;
            case CollectiveEnum.OtherEntitiesProfessionals:
                return CollectiveEnumDto.OtherEntitiesProfessionals;
            case CollectiveEnum.GeneralPopulation:
                return CollectiveEnumDto.GeneralPopulation;
            case CollectiveEnum.Other:
                return CollectiveEnumDto.Other;
        }
    }
}
