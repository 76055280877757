import { Nullable } from "@core/domain/types/nullable.type";
import {
    PublicUtilitiesDto,
    PublicUtilityDto,
} from "@entity/data/dto/public-utility/public-utility.dto";
import {
    PublicUtilities,
    PublicUtility,
} from "@entity/domain/models/public-utility/public-utility.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { PublicUtilityMapper } from "./public-utility.mapper";

@injectable()
export class PublicUtilitiesMapper {
    constructor(
        @inject(PublicUtilityMapper)
        private readonly publicUtilityMapper: PublicUtilityMapper,
    ) {}

    map(publicUtilitiesDto: PublicUtilitiesDto): PublicUtilities {
        const errors = validateSync(publicUtilitiesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new PublicUtilities([], 0);
        }

        const publicUtilities = publicUtilitiesDto.results.mapNotNull(
            (publicUtilityDto) =>
                this.publicUtilityMapper.map(publicUtilityDto),
        );

        return new PublicUtilities(publicUtilities, publicUtilitiesDto.count);
    }

    mapPublicUtility(
        publicUtilityDto: PublicUtilityDto,
    ): Nullable<PublicUtility> {
        if (!publicUtilityDto.id) {
            return null;
        }

        const publicUtility: PublicUtility = {
            id: publicUtilityDto.id,
            organizationType: publicUtilityDto.organization_type,
            entity: publicUtilityDto.entity,
            date: DateTime.fromISO(publicUtilityDto.date),
        };
        return publicUtility;
    }
}
