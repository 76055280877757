import { IncDocument } from "@core/domain/models/inc-document.model";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { DeleteProjectEmployeeDedicationUseCase } from "@project/domain/usecases/project-employee-dedication/delete-project-employee-dedication.usecase";
import { CreateProjectEmployeeUseCase } from "@project/domain/usecases/project-employee/create-project-employee.usecase";
import { DeleteProjectEmployeeUseCase } from "@project/domain/usecases/project-employee/delete-project-employee.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable } from "mobx";

@injectable()
export class EmployeeDetailViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    documents: IncDocument[] = [];

    @observable
    showAddDedicationModal: boolean = false;

    @observable
    showDedicationWarningModal: boolean = false;

    constructor(
        @inject(CreateProjectEmployeeUseCase)
        private readonly createProjectEmployeeUseCase: CreateProjectEmployeeUseCase,
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DeleteProjectEmployeeUseCase)
        private readonly deleteProjectEmployeeUseCase: DeleteProjectEmployeeUseCase,
        @inject(DeleteProjectEmployeeDedicationUseCase)
        private readonly deleteProjectEmployeeDedicationUseCase: DeleteProjectEmployeeDedicationUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
    ) {
        super();
        makeObservable(this);
    }

    async deleteProjectEmployee(id: number): Promise<void> {
        LoadLayoutStore.start();

        const deleted = await this.deleteProjectEmployeeUseCase.execute(id);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    @action
    setShowAddDedicationModal(show: boolean): void {
        this.showAddDedicationModal = show;
    }

    @action
    setShowDedicationWarningModal(show: boolean): void {
        this.showDedicationWarningModal = show;
    }

    async deleteDedication(id: number): Promise<void> {
        LoadLayoutStore.start();

        const deleted =
            await this.deleteProjectEmployeeDedicationUseCase.execute(id);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async deleteDocument(id: number): Promise<boolean> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(id);

        LoadLayoutStore.finish();

        return deleted;
    }

    async downloadDocument(url: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(url);

        LoadLayoutStore.finish();
    }
}
