import { RelativesDto } from "@beneficiary/data/dto/relatives/relatives.dto";
import { RelationshipEnumMapper } from "@beneficiary/data/mappers/relationship-enum.mapper";
import { Relative } from "@beneficiary/domain/models/relatives/relative.model";
import { RelativeFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/relatives/form/relative-form.view";
import { EducationLevelEnumMapper } from "@core/data/mappers/education-level-enum.mapper";
import { EmploymentStatusEnumMapper } from "@core/data/mappers/employment-status-enum.mapper";
import { EmploymentStatusEnum } from "@core/domain/models/employment-status.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { BeneficiaryIdentificationTypeEnumMapper } from "../beneficiary-identification-type-enum.mapper";

@injectable()
export class RelativeMapper {
    constructor(
        @inject(EducationLevelEnumMapper)
        private readonly educationLevelEnumMapper: EducationLevelEnumMapper,
        @inject(EmploymentStatusEnumMapper)
        private readonly employmentStatusEnumMapper: EmploymentStatusEnumMapper,
        @inject(BeneficiaryIdentificationTypeEnumMapper)
        private readonly identificationDocumentEnumMapper: BeneficiaryIdentificationTypeEnumMapper,
        @inject(RelationshipEnumMapper)
        private readonly relationshipEnumMapper: RelationshipEnumMapper,
    ) {}

    map(relativeDto: RelativesDto): Nullable<Relative> {
        const errors = validateSync(relativeDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        const relationshipId = this.relationshipEnumMapper.map(
            relativeDto.parentage,
        );
        if (!relationshipId) return null;

        const educationLevelId = this.educationLevelEnumMapper.map(
            relativeDto.level_of_education,
        );
        if (!educationLevelId) return null;

        const documentTypeId = this.identificationDocumentEnumMapper.map(
            relativeDto.identification_type,
        );
        if (!documentTypeId) return null;

        let employmentStatusId: Nullish<EmploymentStatusEnum> = undefined;
        if (relativeDto.employment_situation) {
            employmentStatusId = this.employmentStatusEnumMapper.map(
                relativeDto.employment_situation,
            );
        }
        if (employmentStatusId === null) return null;

        return new Relative(
            relativeDto.id,
            relationshipId,
            DateTime.fromISO(relativeDto.birth_date),
            educationLevelId,
            documentTypeId,
            employmentStatusId,
            relativeDto.identification ?? undefined,
            relativeDto.education_centre ?? undefined,
            relativeDto.tutelage_email ?? undefined,
            relativeDto.tutelage_phone ?? undefined,
            relativeDto.net_salary
                ? parseFloat(relativeDto.net_salary)
                : undefined,
            relativeDto.other_parentage ?? undefined,
        );
    }

    mapFromRelativesFormValues(
        relativeForm: RelativeFormValuesValidated,
    ): Nullable<Relative> {
        if (!relativeForm.id) {
            console.error("Missing relative id");

            return null;
        }

        return new Relative(
            relativeForm.id,
            relativeForm.relationship,
            relativeForm.birthdate,
            relativeForm.educationLevel,
            relativeForm.documentType,
            relativeForm.employmentStatus,
            relativeForm.documentId,
            relativeForm.courseCenter,
            relativeForm.tutorEmail,
            relativeForm.tutorPhone,
            relativeForm.netSalary ?? undefined,
            relativeForm.otherRelationship ?? undefined,
        );
    }
}
