import { EditRegconRegisterBody } from "@entity/data/dto/quality-framework/regcon-register/edit-regcon-register.body";
import { CCAAEnumMapper } from "@entity/data/mappers/ccaa-enum.mapper";
import { EditRegconRegister } from "@entity/domain/models/quality-framework/regcon-register/edit-regcon-register.model";
import { RegconRegisterFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/regcon-register/form/regcon-register-form";
import { inject, injectable } from "inversify";

@injectable()
export class EditRegconRegisterMapper {
    constructor(
        @inject(CCAAEnumMapper)
        private readonly ccaaEnumMapper: CCAAEnumMapper,
    ) {}
    mapToDto(editRegconRegister: EditRegconRegister): EditRegconRegisterBody {
        const registered_ccaa = this.ccaaEnumMapper.mapToDto(
            editRegconRegister.registeredCcaa,
        );

        const editRegconRegisterBody: EditRegconRegisterBody = {
            id: editRegconRegister.id,
            register_number: editRegconRegister.registerNumber,
            registered_ccaa,
            entity: editRegconRegister.entity,
        };
        return editRegconRegisterBody;
    }
    mapFromModal(
        id: number,
        entityId: number,
        regconRegister: RegconRegisterFormValuesValidated,
    ): EditRegconRegister {
        const editRegconRegister: EditRegconRegister = new EditRegconRegister(
            id,
            regconRegister.registerNumber,
            regconRegister.registeredCcaa,
            entityId,
        );
        return editRegconRegister;
    }
}
