import { Nullable } from "@core/domain/types/nullable.type";
import { TypologyEnumDto } from "@entity/data/dto/typologies.dto";
import { TypologyEnum } from "@entity/domain/models/typology.model";
import { injectable } from "inversify";

@injectable()
export class TypologyEnumMapper {
    map(typologyEnumDto: TypologyEnumDto): Nullable<TypologyEnum> {
        switch (typologyEnumDto) {
            case TypologyEnumDto.EntityGrouping:
                return TypologyEnum.EntityGrouping;
            case TypologyEnumDto.Association:
                return TypologyEnum.Association;
            case TypologyEnumDto.ShelteredEmploymentCenter:
                return TypologyEnum.ShelteredEmploymentCenter;
            case TypologyEnumDto.Confederation:
                return TypologyEnum.Confederation;
            case TypologyEnumDto.SocialCooperative:
                return TypologyEnum.SocialCooperative;
            case TypologyEnumDto.SocialEnterprises:
                return TypologyEnum.SocialEnterprises;
            case TypologyEnumDto.SocialEconomyEntities:
                return TypologyEnum.SocialEconomyEntities;
            case TypologyEnumDto.Federation:
                return TypologyEnum.Federation;
            case TypologyEnumDto.Foundation:
                return TypologyEnum.Foundation;
            case TypologyEnumDto.Platform:
                return TypologyEnum.Platform;
            case TypologyEnumDto.SocialClub:
                return TypologyEnum.SocialClub;
            case TypologyEnumDto.OrganizedSocialMovements:
                return TypologyEnum.OrganizedSocialMovements;
            case TypologyEnumDto.Other:
                return TypologyEnum.Other;
            default:
                return null;
        }
    }

    mapToDto(typologyEnum: TypologyEnum): TypologyEnumDto {
        // eslint-disable-next-line default-case
        switch (typologyEnum) {
            case TypologyEnum.EntityGrouping:
                return TypologyEnumDto.EntityGrouping;
            case TypologyEnum.Association:
                return TypologyEnumDto.Association;
            case TypologyEnum.ShelteredEmploymentCenter:
                return TypologyEnumDto.ShelteredEmploymentCenter;
            case TypologyEnum.Confederation:
                return TypologyEnumDto.Confederation;
            case TypologyEnum.SocialCooperative:
                return TypologyEnumDto.SocialCooperative;
            case TypologyEnum.SocialEnterprises:
                return TypologyEnumDto.SocialEnterprises;
            case TypologyEnum.SocialEconomyEntities:
                return TypologyEnumDto.SocialEconomyEntities;
            case TypologyEnum.Federation:
                return TypologyEnumDto.Federation;
            case TypologyEnum.Foundation:
                return TypologyEnumDto.Foundation;
            case TypologyEnum.Platform:
                return TypologyEnumDto.Platform;
            case TypologyEnum.SocialClub:
                return TypologyEnumDto.SocialClub;
            case TypologyEnum.OrganizedSocialMovements:
                return TypologyEnumDto.OrganizedSocialMovements;
            case TypologyEnum.Other:
                return TypologyEnumDto.Other;
        }
    }
}
