import { ProjectEmployeeRepository } from "@project/data/repositories/project-employee.repository";
import {
    ProjectEmployee,
    ProjectEmployeesSearchFilters,
} from "@project/domain/models/project-employees.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllProjectEmployeesByUseCase {
    constructor(
        @inject(ProjectEmployeeRepository)
        private readonly projectEmployeeRepository: ProjectEmployeeRepository,
    ) {}

    async execute(
        filters?: ProjectEmployeesSearchFilters,
    ): Promise<ProjectEmployee[]> {
        const projectEmployeesResult =
            await this.projectEmployeeRepository.getAllBy(filters);

        return projectEmployeesResult.getOrElse([]);
    }
}
