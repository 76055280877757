import { AuthenticatedUser } from "@authentication/domain/models/authenticated-user.model";
import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Nullable } from "@core/domain/types/nullable.type";
import { CurrentUserDto } from "@user/data/dto/current-user.dto";
import { UserDto } from "@user/data/dto/user.dto";
import { AuthenticatedUserMapper } from "@user/data/mappers/authenticated-user.mapper";
import { UserMapper } from "@user/data/mappers/user.mapper";
import { User, UserSearchFilters, Users } from "@user/domain/models/user.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { CreateUser } from "../../domain/models/create-user.model";
import { EditUser } from "../../domain/models/edit-user.model";
import { UserEntity } from "../../domain/models/user-entity.model";
import { UserRole } from "../../domain/models/user-role.model";
import { CreateUserBody } from "../dto/create-user.body";
import { EditUserBody } from "../dto/edit-user.body";
import { UpdateUserEntityBody } from "../dto/update-user-entity.body";
import { UserRoleDto } from "../dto/user-role.dto";
import { UsersDto, UsersSummaryQuery } from "../dto/users.dto";
import { CreateUserMapper } from "../mappers/create-user.mapper";
import { EditUserMapper } from "../mappers/edit-user.mapper";
import { UserEntityMapper } from "../mappers/user-entity.mapper";
import { UserRoleEnumMapper } from "../mappers/user-role-enum.mapper";
import { UserRoleMapper } from "../mappers/user-role.mapper";
import { UsersMapper } from "../mappers/users.mapper";

@injectable()
export class UserDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(AuthenticatedUserMapper)
        private readonly authenticatedUserMapper: AuthenticatedUserMapper,
        @inject(UserMapper)
        private readonly userMapper: UserMapper,
        @inject(UserEntityMapper)
        private readonly userEntityMapper: UserEntityMapper,
        @inject(UserRoleMapper)
        private readonly userRoleMapper: UserRoleMapper,
        @inject(UserRoleEnumMapper)
        private readonly userRoleEnumMapper: UserRoleEnumMapper,
        @inject(UsersMapper)
        private readonly usersMapper: UsersMapper,
        @inject(CreateUserMapper)
        private readonly createUserMapper: CreateUserMapper,
        @inject(EditUserMapper)
        private readonly editUserMapper: EditUserMapper,
    ) {}

    async fetchById(id: number): Promise<Either<FallbackError, User>> {
        const userResult = await this.http.get<UserDto>(`/users/${id}/`);

        return userResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const user = this.userMapper.map(
                    plainToClass(UserDto, response.data),
                );

                if (!user) return Either.Left(new FallbackError());

                return Either.Right(user);
            });
    }

    async create(
        newUser: CreateUser,
    ): Promise<Either<ValidationError | FallbackError, User>> {
        const createdUserDto = this.createUserMapper.mapToDto(newUser);

        const createUserResult = await this.http.post<UserDto, CreateUserBody>(
            "/users/",
            createdUserDto,
        );

        return createUserResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdUser = this.userMapper.map(
                    plainToClass(UserDto, response.data),
                );

                if (!createdUser) return Either.Left(new FallbackError());

                return Either.Right(createdUser);
            });
    }

    async edit(
        editUser: EditUser,
    ): Promise<Either<ValidationError | FallbackError, User>> {
        const editUserBody = this.editUserMapper.mapToDto(editUser);

        const editUserResult = await this.http.patch<UserDto, EditUserBody>(
            `/users/${editUser.id}/`,
            editUserBody,
        );

        return editUserResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const user = this.userMapper.map(
                    plainToClass(UserDto, response.data),
                );

                if (!user) return Either.Left(new FallbackError());

                return Either.Right(user);
            });
    }

    async fetchCurrentUser(): Promise<
        Either<FallbackError, AuthenticatedUser>
    > {
        const userResult = await this.http.get<CurrentUserDto>(
            "/users/current_user/",
        );

        return userResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const user = this.authenticatedUserMapper.map(
                    plainToClass(CurrentUserDto, response.data),
                );

                if (!user) return Either.Left(new FallbackError());

                return Either.Right(user);
            });
    }

    async updateUserEntity(
        entityId: Nullable<number>,
    ): Promise<Either<ValidationError | FallbackError, UserEntity>> {
        const updateUserEntityBody = { entity: entityId ?? null };

        const updateUserEntityResult = await this.http.post<
            CurrentUserDto,
            UpdateUserEntityBody
        >("/users/set_entity/", updateUserEntityBody);

        return updateUserEntityResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const userEntity = this.userEntityMapper.map(
                    plainToClass(CurrentUserDto, response.data),
                );

                return Either.Right(userEntity);
            });
    }

    async fetchAllRoles(): Promise<Either<FallbackError, UserRole[]>> {
        const responseResult =
            await this.http.get<UserRoleDto[]>("/users/roles/");

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((typeDto) =>
                    this.userRoleMapper.map(plainToClass(UserRoleDto, typeDto)),
                ),
            );
    }

    async fetchAllBy(
        pagination: Pagination,
        filters?: UserSearchFilters,
    ): Promise<Either<FallbackError, Users>> {
        const query: UsersSummaryQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.name) query.search = filters.name;
        if (filters?.active) query.is_active = filters.active;
        if (filters?.userRole)
            query.rol = this.userRoleEnumMapper.mapToDto(filters.userRole);
        if (filters?.entityIds) query.entities = filters.entityIds.join(",");

        const usersResult = await this.http.get<UsersDto>("/users/", {
            query,
        });

        return usersResult
            .map((response) =>
                this.usersMapper.map(plainToClass(UsersDto, response.data)),
            )
            .mapLeft(() => new FallbackError());
    }

    async toggleActivation(
        userId: number,
    ): Promise<Either<FallbackError, boolean>> {
        const userResult = await this.http.patch(
            `/users/${userId}/toggle_activation/`,
        );

        return userResult.mapLeft(() => new FallbackError()).map(() => true);
    }
}
