import { SignatureDataDto } from "@core/data/dto/signature-data.dto";
import { DocumentSignature } from "@core/domain/models/document-signatures.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class DocumentSignatureMapper {
    map(documentSignatureDto: SignatureDataDto): Nullable<DocumentSignature> {
        const errors = validateSync(documentSignatureDto);
        if (errors.length) {
            console.error(errors);
            return null;
        }

        return new DocumentSignature(
            documentSignatureDto.id,
            documentSignatureDto.document,
            documentSignatureDto.user,
            documentSignatureDto.created,
            documentSignatureDto.modified,
            documentSignatureDto.image,
            documentSignatureDto.md5,
        );
    }
}
