import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Material } from "@entity/domain/models/material/material.model";
import { CreateMaterialUseCase } from "@entity/domain/usecases/material/create-material.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable } from "mobx";
import { MaterialFormValuesValidated } from "../../../component/material-form/material-form";

@injectable()
export class CreateMaterialPageViewModel extends BaseViewModel {
    @observable
    material: Undefinable<Material> = undefined;

    constructor(
        @inject(CreateMaterialUseCase)
        private readonly createMaterialUseCase: CreateMaterialUseCase,
    ) {
        super();
        makeObservable(this);
    }

    async createMaterial(
        materialToCreate: MaterialFormValuesValidated,
    ): Promise<Nullable<Material>> {
        LoadLayoutStore.start();
        const created =
            await this.createMaterialUseCase.execute(materialToCreate);

        if (created) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return created;
    }
}
