import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum ExpenseTypeEnumDto {
    Housing = "housing",
    Supplies = "supplies",
    Transport = "transport",
    Food = "food",
    SchoolStudies = "school_studies",
    Debts = "debts",
    Other = "other",
}

export class ExpenseTypeDto {
    @IsEnum(ExpenseTypeEnumDto)
    @IsNotEmpty()
    key!: ExpenseTypeEnumDto;
    @IsString()
    @IsNotEmpty()
    value!: string;
}
