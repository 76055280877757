import { Nullish } from "@core/domain/types/nullish.type";
import { ExpenseTypeEnum } from "@project/domain/models/expenses/expense-type.model";
import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum ExpenseSubTypeEnumDto {
    NONE = "NONE",
    THIRD_PARTY_ACCOUNT = "THIRD_PARTY_ACCOUNT",
    THIRD_PARTY_SERVICES = "THIRD_PARTY_SERVICES",
    RENT = "RENT",
    UTILITIES = "UTILITIES",
    UTILITIES_CONSTRUCTION_AND_EQUIPMENT = "UTILITIES_CONSTRUCTION_AND_EQUIPMENT",
    INSURANCE = "INSURANCE",
    CONSUMABLES = "CONSUMABLES",
    MATERIALS_FOR_ACTIVITIES = "MATERIALS_FOR_ACTIVITIES",
    TRANSPORT_OF_GOODS_AND_INMOVILITIES = "TRANSPORT_OF_GOODS_AND_INMOVILITIES",
    OCCASIONAL_COLLABORATIONS = "OCASIONAL_COLLABORATIONS",
    CONFERENCES_LECTURES = "CONFERENCES_LECTURES",
    WORK_OTHER_COMPANIES = "WORK_OTHER_COMPANIES",
    VOLUNTEERING = "VOLUNTEERING",
    LIABILITY_INSURANCE = "LIABILITY_INSURANCE",
    EXPENSES_INCURRED_BENEFICIARIES = "EXPENSES_INCURRED_BENEFICIARIES",
    SOFTWARE_LICENSES = "SOFTWARE_LICENSES",
    OTHERS_MINOR_EXPENSES = "OTHERS_MINOR_EXPENSES",
    FURNITURE = "FURNITURE",
    COMPUTER_EQUIPMENT = "COMPUTER_EQUIPMENT",
    REAL_ESTATE = "REAL_ESTATE",
    OTHERS_INVESTMENT_EXPENSES = "OTHERS_INVESTMENT_EXPENSES",
    CONSULTING_SERVICES = "CONSULTING_SERVICES",
    AUDITING = "AUDITING",
    LABOR_MANAGEMENT_LICENSES = "LABOR_MANAGEMENT_LICENSES",
    QUALITY = "QUALITY",
    PERSONNEL = "PERSONNEL",
}

export class ExpenseSubTypeDto {
    @IsEnum(ExpenseSubTypeEnumDto)
    @IsNotEmpty()
    key!: ExpenseSubTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}

export type ExpenseSubTypeQuery = {
    type_expense?: Nullish<ExpenseTypeEnum>;
};
