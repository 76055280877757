import { ProjectEmployee } from "@project/domain/models/project-employees.model";
import { DateTime } from "luxon";

export class ProjectEmployeeDedication {
    constructor(
        public readonly id: number,
        public projectEmployeeId: ProjectEmployee["id"],
        public hoursDedicated: number,
        public startDate: DateTime,
        public endDate: DateTime,
    ) {}
}

export class ProjectEmployeeDedications {
    constructor(
        public readonly projectEmployeeDedications: ProjectEmployeeDedication[],
        count: number,
    ) {}
}

export interface ProjectEmployeeDedicationsSearchFilters {
    name?: string;
    projectEmployeeId?: number;
    projectId?: number;
}

export class CreateProjectEmployeeDedication {
    constructor(
        public readonly projectEmployeeId: ProjectEmployee["id"],
        public hoursDedicated: ProjectEmployeeDedication["hoursDedicated"],
        public startDate: ProjectEmployeeDedication["startDate"],
        public endDate: ProjectEmployeeDedication["endDate"],
    ) {}
}

export class UpdateProjectEmployeeDedication extends CreateProjectEmployeeDedication {
    constructor(
        public readonly id: ProjectEmployeeDedication["id"],
        projectEmployeeId: ProjectEmployee["id"],
        hoursDedicated: ProjectEmployeeDedication["hoursDedicated"],
        startDate: ProjectEmployeeDedication["startDate"],
        endDate: ProjectEmployeeDedication["endDate"],
    ) {
        super(projectEmployeeId, hoursDedicated, startDate, endDate);
    }
}
