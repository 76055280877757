import { CountryDto } from "@core/data/dto/country.dto";
import { Country } from "@core/domain/models/country.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { CountryEnumMapper } from "./country-enum.mapper";

@injectable()
export class CountryMapper {
    constructor(
        @inject(CountryEnumMapper)
        private readonly countryEnumMapper: CountryEnumMapper,
    ) {}

    map(countryDto: CountryDto): Nullable<Country> {
        const errors = validateSync(CountryDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.countryEnumMapper.map(countryDto.code);
        if (!id) return null;

        return new Country(id, countryDto.name);
    }
}
