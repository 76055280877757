import { AddressRepository } from "@beneficiary/data/repositories/address.repository";
import { Address } from "@beneficiary/domain/models/address.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class GetAddressByIdUseCase {
    constructor(
        @inject(AddressRepository)
        private readonly addressRepository: AddressRepository,
    ) {}

    async execute(addressId: number): Promise<Nullable<Address>> {
        const addressResult = await this.addressRepository.findById(addressId);

        if (addressResult.isLeft()) {
            ToastManagerStore.error(addressResult.getLeftOrThrow().message);
        }

        return addressResult.fold(
            () => null,
            (address) => address,
        );
    }
}
