import { Nullable } from "@core/domain/types/nullable.type";
import { TerminationReasonDto } from "@entity/data/dto/employee/termination-reason.dto";
import { TerminationReasonEnumMapper } from "@entity/data/mappers/employee/termination-reason-enum.mapper";
import {
    TerminationReason,
    TerminationReasonEnum,
} from "@entity/domain/models/employee/termination-reason.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class TerminationReasonMapper {
    constructor(
        @inject(TerminationReasonEnumMapper)
        private terminationReasonEnumMapper: TerminationReasonEnumMapper,
    ) {}

    map(
        terminationReasonEnumMapperDto: TerminationReasonDto,
    ): Nullable<TerminationReason> {
        const errors = validateSync(terminationReasonEnumMapperDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<TerminationReasonEnum> =
            this.terminationReasonEnumMapper.map(
                terminationReasonEnumMapperDto.key,
            );

        if (!id) return null;

        return new TerminationReason(id, terminationReasonEnumMapperDto.value);
    }
}
