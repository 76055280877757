import {
    IsArray,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

import { CountryEnumDto } from "@core/data/dto/country.dto";
import { PaginatedDto } from "@core/data/dto/paginated.dto";
import { IdentificationDocumentTypeEnum } from "@core/domain/models/identification-document-type.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";
import { CostPeriodicityTypeEnumDto } from "./cost-periodicity.dto";
import { RoomDto } from "./room.dto";
import { StatusTypeEnumDto } from "./status.dto";

export class LocalDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsOptional()
    local_code!: Nullable<string>;

    @IsString()
    @IsOptional()
    cadastral_reference!: Nullable<string>;

    @IsEnum(CostPeriodicityTypeEnumDto)
    @IsNotEmpty()
    cost_periodicity!: CostPeriodicityTypeEnumDto;

    @IsEnum(StatusTypeEnumDto)
    @IsNotEmpty()
    status!: StatusTypeEnumDto;

    @IsString()
    @IsOptional()
    other_status!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    cost!: number;

    @IsEnum(IdentificationDocumentTypeEnum)
    @IsOptional()
    identification_type!: Nullable<IdentificationDocumentTypeEnum>;

    @IsString()
    @IsOptional()
    identification!: Nullable<string>;

    @IsString()
    @IsOptional()
    business_name!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    street_name!: string;

    @IsString()
    @IsNotEmpty()
    street_number!: string;

    @IsString()
    @IsOptional()
    floor!: Nullable<string>;

    @IsString()
    @IsOptional()
    door!: Nullable<string>;

    @IsString()
    @IsOptional()
    stair!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    zip_code!: string;

    @IsString()
    @IsNotEmpty()
    town!: string;

    @IsString()
    @IsOptional()
    province!: string;

    @IsString()
    @IsOptional()
    region!: Nullable<string>;

    @IsEnum(CountryEnumDto)
    @IsOptional()
    country!: Nullable<CountryEnumDto>;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;

    @IsArray()
    @IsNumber(undefined, { each: true })
    @IsOptional()
    rooms!: number[];

    @IsArray()
    @Type(() => RoomDto)
    @IsOptional()
    rooms_data!: RoomDto[];
}

export class LocalsDto extends PaginatedDto {
    @IsArray()
    @Type(() => LocalDto)
    results!: LocalDto[];
}
