import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContractsRepository } from "@entity/data/repositories/contracts.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteEmployeeContractUseCase {
    constructor(
        @inject(ContractsRepository)
        private readonly contractsRepository: ContractsRepository,
    ) {}

    async execute(contractId: number): Promise<boolean> {
        const result =
            await this.contractsRepository.deleteEmployeeContract(contractId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
