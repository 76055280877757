import { IncDocument } from "@core/domain/models/inc-document.model";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { DietType } from "@project/domain/models/expenses/diet-type.model";
import { GetAllDietTypesUseCase } from "@project/domain/usecases/expenses/get-all-diet-types.usecase";
import { Map } from "immutable";
import { inject, injectable } from "inversify";
import { flow, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class ExpenseAccommodationFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    dietTypes: DietType[] = [];

    @observable
    documents: Map<number, IncDocument> = Map([]);

    constructor(
        @inject(GetAllDietTypesUseCase)
        private readonly getAllDietTypesUseCase: GetAllDietTypesUseCase,
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        await Promise.all([this.getAllDietTypes()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @flow
    async *getAllDietTypes(): AsyncGenerator<void> {
        const dietTypes = await this.getAllDietTypesUseCase.execute();

        runInAction(() => {
            this.dietTypes = dietTypes;
        });
    }

    async deleteDocument(documentId: number): Promise<void> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(documentUrl);

        LoadLayoutStore.finish();
    }
}
