export enum LanguageIsoCodeEnum {
    Spanish = "es",
    English = "en",
    Catalán = "ca",
    Euskera = "eu",
    Gallego = "gl",
    Italiano = "it",
}

export class Language {
    constructor(
        public readonly isoCode: LanguageIsoCodeEnum,
        public readonly label: string,
    ) {}
}

export class LanguageIsoCode {
    constructor(public readonly isoCode: LanguageIsoCodeEnum) {}
}
