import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectAuditoryRepository } from "@entity/data/repositories/project-auditory.repository";
import { CreateProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/create-project-auditory.model";
import { ProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/project-auditory.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateProjectAuditoryUseCase {
    constructor(
        @inject(ProjectAuditoryRepository)
        private readonly projectAuditoryRepository: ProjectAuditoryRepository,
    ) {}

    async execute(
        projectAuditory: CreateProjectAuditory,
    ): Promise<Nullable<ProjectAuditory>> {
        const creationResult =
            await this.projectAuditoryRepository.create(projectAuditory);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdProjectAuditory) => createdProjectAuditory,
        );
    }
}
