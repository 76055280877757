import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { ActivitySearchFilters } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { ActivitiesSummary } from "@project/domain/models/activity-summary.model";
import { inject, injectable } from "inversify";
import {
    Activity,
    CreateActivity,
    EditActivity,
} from "../../../domain/models/technical-proposals.model";
import { ProjectActivityDataSource } from "../../datasource/project-activity.datasource";

@injectable()
export class ProjectActivityRepository {
    constructor(
        @inject(ProjectActivityDataSource)
        private readonly projectActivityDataSource: ProjectActivityDataSource,
    ) {}

    async getAllBy(
        filters: ActivitySearchFilters,
    ): Promise<Either<FallbackError, Activity[]>> {
        const pagination: Pagination = Pagination.NoPagination();
        return this.projectActivityDataSource.fetchBy(pagination, filters);
    }

    async getAllActivitiesByProjectIdSummary(
        projectId: number,
    ): Promise<Either<FallbackError, ActivitiesSummary>> {
        const pagination: Pagination = Pagination.NoPagination();
        return this.projectActivityDataSource.fetchAllActivitiesSummary(
            pagination,
            projectId,
        );
    }

    async editKanban(
        activity: Activity,
    ): Promise<Either<FallbackError | ValidationError, Activity>> {
        return this.projectActivityDataSource.editKanban(activity);
    }

    async edit(
        activity: EditActivity,
    ): Promise<Either<ValidationError | FallbackError, Activity>> {
        return this.projectActivityDataSource.edit(activity);
    }

    async create(
        activity: CreateActivity,
    ): Promise<Either<ValidationError | FallbackError, Activity>> {
        return this.projectActivityDataSource.create(activity);
    }

    async delete(activityId: number): Promise<Either<FallbackError, true>> {
        return this.projectActivityDataSource.delete(activityId);
    }
}
