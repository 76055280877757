import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectEmployeeRepository } from "@project/data/repositories/project-employee.repository";
import {
    CreateProjectEmployee,
    ProjectEmployee,
} from "@project/domain/models/project-employees.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateProjectEmployeeUseCase {
    constructor(
        @inject(ProjectEmployeeRepository)
        private readonly projectEmployeeRepository: ProjectEmployeeRepository,
    ) {}

    async execute(
        createProjectEmployee: CreateProjectEmployee,
    ): Promise<Nullable<ProjectEmployee>> {
        const projectEmployeeResult =
            await this.projectEmployeeRepository.create(createProjectEmployee);

        if (projectEmployeeResult.isLeft()) {
            ToastManagerStore.error(
                projectEmployeeResult.getLeftOrThrow().message,
            );
        }

        return projectEmployeeResult.fold(
            () => null,
            (projectEmployee) => projectEmployee,
        );
    }
}
