import { Nullable } from "@core/domain/types/nullable.type";
import { MaterialDto } from "@entity/data/dto/material/material.dto";
import {
    CreateMaterial,
    Material,
} from "@entity/domain/models/material/material.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { MaterialSubtypeEnumMapper } from "./material-subtype-enum.mapper";
import { MaterialTypeEnumMapper } from "./material-type-enum.mapper";
import { MetricUnitEnumMapper } from "./metric-unit-enum.mapper";

@injectable()
export class MaterialMapper {
    constructor(
        @inject(MaterialTypeEnumMapper)
        private readonly materialTypeEnumMapper: MaterialTypeEnumMapper,
        @inject(MaterialSubtypeEnumMapper)
        private readonly materialSubtypeEnumMapper: MaterialSubtypeEnumMapper,
        @inject(MetricUnitEnumMapper)
        private readonly metricUnitEnumMapper: MetricUnitEnumMapper,
    ) {}
    map(materialDto: MaterialDto): Nullable<Material> {
        const errors = validateSync(materialDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const typeId = this.materialTypeEnumMapper.map(materialDto.type);
        if (!typeId) return null;

        const subtypeId = materialDto.sub_type
            ? this.materialSubtypeEnumMapper.map(materialDto.sub_type)
            : null;

        const metricUnit = this.metricUnitEnumMapper.map(
            materialDto.metric_unit,
        );
        if (!metricUnit) return null;

        return new Material(
            materialDto.id,
            materialDto.name,
            materialDto.description ?? undefined,
            typeId,
            materialDto.display_type,
            subtypeId,
            materialDto.display_type,
            materialDto.others_type ?? undefined,
            metricUnit,
            materialDto.others_type_unit ?? undefined,
            materialDto.count,
            materialDto.anual_cost,
            materialDto.entity,
        );
    }

    mapToMaterialDto(material: Material): Nullable<MaterialDto> {
        const errors = validateSync(material);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const typeId = this.materialTypeEnumMapper.mapToDto(material.typeId);
        const metricUnit = this.metricUnitEnumMapper.mapToDto(
            material.metricUnit,
        );

        const materialDto = new MaterialDto();

        materialDto.id = material.id;
        materialDto.name = material.name;
        materialDto.type = typeId;
        materialDto.metric_unit = metricUnit;
        materialDto.count = material.count;
        materialDto.anual_cost = material.anualCost;
        materialDto.entity = material.entity;
        materialDto.description = material.description ?? null;

        if (material.description !== undefined) {
            materialDto.description =
                material.description === "" ? null : material.description;
        }

        if (material.othersType) {
            materialDto.others_type = material.othersType;
        }

        if (material.othersTypeUnit) {
            materialDto.others_type_unit = material.othersTypeUnit;
        }

        if (material.subtypeId) {
            materialDto.sub_type = this.materialSubtypeEnumMapper.mapToDto(
                material.subtypeId,
            );
        }

        if (material.subtypeLabel) {
            materialDto.display_subtype = material.subtypeLabel;
        }

        return materialDto;
    }

    mapToCreateMaterialDto(material: CreateMaterial): Nullable<MaterialDto> {
        const errors = validateSync(material);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const typeId = this.materialTypeEnumMapper.mapToDto(material.typeId);
        const subTypeId = material.subtypeId
            ? this.materialSubtypeEnumMapper.mapToDto(material.subtypeId)
            : null;
        const metricUnit = this.metricUnitEnumMapper.mapToDto(
            material.metricUnit,
        );

        const materialDto = new MaterialDto();

        materialDto.name = material.name;
        materialDto.type = typeId;
        materialDto.sub_type = subTypeId;
        materialDto.metric_unit = metricUnit;
        materialDto.count = material.count;
        materialDto.anual_cost = material.anualCost;
        materialDto.entity = material.entity;

        if (material.description) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            materialDto.description = material.description ?? null;
        }

        if (material.othersType) {
            materialDto.others_type = material.othersType;
        }

        if (material.othersTypeUnit) {
            materialDto.others_type_unit = material.othersTypeUnit;
        }

        return materialDto;
    }
}
