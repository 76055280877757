import { Pagination } from "@core/domain/models/pagination";
import { EntityRepository } from "@entity/data/repositories/entity.repository";
import {
    EntitiesSummary,
    EntitySearchFilters,
} from "@entity/domain/models/entity-summary.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetEntitiesByUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: EntitySearchFilters,
    ): Promise<EntitiesSummary> {
        const entitiesResult = await this.entityRepository.getBy(
            pagination,
            filters,
        );

        return entitiesResult.fold(
            () => new EntitiesSummary([], 0),
            (collectives) => collectives,
        );
    }
}
