import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { TechnicalProposalRepository } from "@project/data/repositories/technical-proposal/technical-proposal.repository";
import { ExportBeneficiaryTechnicalProposalFilters } from "@project/domain/models/export-beneficiary-technical-proposal-filters.model";
import { inject, injectable } from "inversify";

@injectable()
export class ExportBeneficiaryTechProposalByUseCase {
    constructor(
        @inject(TechnicalProposalRepository)
        private readonly technicalProposalRepository: TechnicalProposalRepository,
    ) {}

    async execute(
        filters?: ExportBeneficiaryTechnicalProposalFilters,
    ): Promise<Blob> {
        const beneficiariesResult =
            await this.technicalProposalRepository.exportBeneficiaryTechProposalBy(
                filters,
            );

        if (beneficiariesResult.isLeft()) {
            ToastManagerStore.error(
                beneficiariesResult.getLeftOrThrow().message,
            );
        }

        return beneficiariesResult.getOrThrow();
    }
}
