import { DocumentDto } from "@core/data/dto/document.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { ServiceProvidedEnumDto } from "@entity/data/dto/supplier/service-provided-enum.dto";
import { SupplierIdentificationTypeEnumDto } from "@entity/data/dto/supplier/supplier-identification-type.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEmail,
    IsEnum,
    IsInt,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { SupplierTypeEnumDto } from "./supplier-type-enum.dto";

export class SupplierDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    company_name!: string;

    @IsString()
    @IsNotEmpty()
    contact_person!: string;

    @IsString()
    @IsNotEmpty()
    @IsEmail()
    responsable_email!: string;

    @IsString()
    @IsNotEmpty()
    phone1!: string;

    @IsNotEmpty()
    @IsEnum(SupplierTypeEnumDto)
    type!: SupplierTypeEnumDto;

    @IsNotEmpty()
    @IsEnum(SupplierIdentificationTypeEnumDto)
    identification_type!: SupplierIdentificationTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    identification!: string;

    @IsString()
    @IsNotEmpty()
    street!: string;

    @IsString()
    @IsNotEmpty()
    zip_code!: string;

    @IsString()
    @IsNotEmpty()
    town!: string;

    @IsString()
    @IsNotEmpty()
    province!: string;

    @IsDateString()
    @IsOptional()
    contract_start_date!: Undefinable<string>;

    @IsDateString()
    @IsOptional()
    contract_end_date!: Undefinable<string>;

    @IsEnum(ServiceProvidedEnumDto, { each: true })
    @IsOptional()
    services_provided!: Nullable<ServiceProvidedEnumDto[]>;

    @IsString()
    @IsOptional()
    other_services_provided!: Nullable<string>;

    @IsString()
    @IsOptional()
    details_services_provided!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    quality_certifications!: boolean;

    @IsDateString()
    @IsOptional()
    quality_certifications_date!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    service_evaluation!: boolean;

    @IsDateString()
    @IsOptional()
    service_evaluation_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    service_evaluation_detail!: Nullable<string>;

    @IsArray()
    @IsNumber(undefined, { each: true })
    @IsOptional()
    documents!: Nullable<number[]>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    documents_data!: Nullable<DocumentDto[]>;

    @IsBoolean()
    @IsOptional()
    active!: boolean;

    @IsInt()
    @IsNotEmpty()
    entity!: number;
}
