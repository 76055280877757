import { useState } from "react";
import { BaseViewModel } from "../../view-model/base/base.viewmodel";
import { useDidMount } from "../use-did-mount/use-did-mount.hook";
import { useWillUnmount } from "../use-will-unmount/use-will-unmount.hook";

// TODO REVISIT ONCE MOBX BUG IS FIXED

// export const useViewModel = <T extends BaseViewModel>(viewModel: T | (() => T)): T => {
export const useViewModel = <T extends BaseViewModel>(
    viewModel: () => T,
): T => {
    // TODO REVISIT ONCE MOBX BUG IS FIXED
    // const viewModelInstance: T = typeof viewModel === "function" ? viewModel() : viewModel;
    // const { current: instance } = useRef<T>(viewModelInstance);

    // eslint-disable-next-line react/hook-use-state
    const [instance] = useState<T>(() => viewModel());

    useDidMount(async () => {
        await instance.didMount();
    });

    useWillUnmount(async () => {
        await instance.willUnmount();
    });

    return instance;
};
