import { DocumentCategoryDto } from "@core/data/dto/document-categories.dto";
import { SignatureDataDto } from "@core/data/dto/signature-data.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";
import {
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export enum DocumentTypeLicenseEnumDto {
    SenseLlicencia = "sense-llicencia",
    Copyright = "copyright",
    Copyleft = "copyleft",
    CcBy = "cc-by",
    CcByNc = "cc-by-nc",
    ByNcSa = "by-nc-sa",
    ByNcNd = "by-nc-nd",
    BySa = "by-sa",
    ByNd = "by-nd",
    Publica = "publica",
}

export class DocumentDto {
    @IsNotEmpty()
    @IsNumber()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    document!: string;

    @IsString()
    @IsOptional()
    file_with_signature!: Nullable<string>;

    @IsString()
    @IsOptional()
    title!: Nullable<string>;

    @IsString()
    @IsOptional()
    author!: Nullable<string>;

    @IsString()
    @IsOptional()
    description!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    expiry_date!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    visible!: Nullable<boolean>;

    @IsNumber()
    @IsOptional()
    category!: Nullable<number>;

    @IsOptional()
    @Type(() => DocumentCategoryDto)
    category_data!: Nullable<DocumentCategoryDto>;

    @IsEnum(DocumentTypeLicenseEnumDto)
    @IsOptional()
    type_license!: Nullable<DocumentTypeLicenseEnumDto>;

    @IsDateString()
    @IsNotEmpty()
    created!: string;

    @IsDateString()
    @IsNotEmpty()
    modified!: string;

    @IsOptional()
    @Type(() => SignatureDataDto)
    signature_data!: Nullable<SignatureDataDto>;
}
