import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { PublicInterestRepository } from "@entity/data/repositories/public-interest.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeletePublicInterestUseCase {
    constructor(
        @inject(PublicInterestRepository)
        private readonly publicInterestRepository: PublicInterestRepository,
    ) {}

    async execute(publicInterestId: number): Promise<void> {
        const result =
            await this.publicInterestRepository.delete(publicInterestId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        ToastManagerStore.success();
    }
}
