import { Nullable } from "@core/domain/types/nullable.type";
import { CrosscuttingScopeEnumDto } from "@entity/data/dto/crosscutting-scope-enum.dto";
import { CrosscuttingScopeEnum } from "@entity/domain/models/crosscutting-scope.model";
import { injectable } from "inversify";

@injectable()
export class CrosscuttingScopeEnumMapper {
    map(
        crosscuttingScopeEnumDto: CrosscuttingScopeEnumDto,
    ): Nullable<CrosscuttingScopeEnum> {
        switch (crosscuttingScopeEnumDto) {
            case CrosscuttingScopeEnumDto.Innovation:
                return CrosscuttingScopeEnum.Innovation;
            case CrosscuttingScopeEnumDto.GenderPerspective:
                return CrosscuttingScopeEnum.GenderPerspective;
            case CrosscuttingScopeEnumDto.DiversityPerspective:
                return CrosscuttingScopeEnum.DiversityPerspective;
            case CrosscuttingScopeEnumDto.Digitalization:
                return CrosscuttingScopeEnum.Digitalization;
            case CrosscuttingScopeEnumDto.ContinuousImprovement:
                return CrosscuttingScopeEnum.ContinuousImprovement;
            case CrosscuttingScopeEnumDto.Accessibility:
                return CrosscuttingScopeEnum.Accessibility;
            case CrosscuttingScopeEnumDto.Sustainability:
                return CrosscuttingScopeEnum.Sustainability;
            case CrosscuttingScopeEnumDto.SustainableDevelopmentGoals:
                return CrosscuttingScopeEnum.SustainableDevelopmentGoals;
            case CrosscuttingScopeEnumDto.Other:
                return CrosscuttingScopeEnum.Other;
            default:
                return null;
        }
    }

    mapToDto(
        crosscuttingScopeEnum: CrosscuttingScopeEnum,
    ): CrosscuttingScopeEnumDto {
        // eslint-disable-next-line default-case
        switch (crosscuttingScopeEnum) {
            case CrosscuttingScopeEnum.Innovation:
                return CrosscuttingScopeEnumDto.Innovation;
            case CrosscuttingScopeEnum.GenderPerspective:
                return CrosscuttingScopeEnumDto.GenderPerspective;
            case CrosscuttingScopeEnum.DiversityPerspective:
                return CrosscuttingScopeEnumDto.DiversityPerspective;
            case CrosscuttingScopeEnum.Digitalization:
                return CrosscuttingScopeEnumDto.Digitalization;
            case CrosscuttingScopeEnum.ContinuousImprovement:
                return CrosscuttingScopeEnumDto.ContinuousImprovement;
            case CrosscuttingScopeEnum.Accessibility:
                return CrosscuttingScopeEnumDto.Accessibility;
            case CrosscuttingScopeEnum.Sustainability:
                return CrosscuttingScopeEnumDto.Sustainability;
            case CrosscuttingScopeEnum.SustainableDevelopmentGoals:
                return CrosscuttingScopeEnumDto.SustainableDevelopmentGoals;
            case CrosscuttingScopeEnum.Other:
                return CrosscuttingScopeEnumDto.Other;
        }
    }
}
