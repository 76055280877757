import { Undefinable } from "@core/domain/types/undefinable.type";
import { DateTime } from "luxon";
import { UserRoleEnum } from "./user-role.model";

export class UserRelatedEmployee {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly surname: string,
        public readonly documentNumber: string,
    ) {}
}

export class User {
    constructor(
        public readonly id: number,
        public readonly entityId: number,
        public readonly email: string,
        public readonly name: string,
        public readonly surname: string,
        public readonly isActive: boolean,
        public readonly dateJoined: DateTime,
        public readonly lastLogin: Undefinable<DateTime>,
        public readonly role: UserRoleEnum,
        public readonly relatedEmployee: Undefinable<UserRelatedEmployee>,
    ) {}

    isEntityUser(): this is User & { entityId: number } {
        return this.role !== UserRoleEnum.SuperAdmin;
    }
}

export class Users {
    constructor(
        public readonly users: User[],
        public readonly count: number,
    ) {}
}

export interface UserSearchFilters {
    name?: string;
    userRole?: Undefinable<UserRoleEnum>;
    entityIds?: Undefinable<string[]>;
    active?: Undefinable<boolean>;
}
