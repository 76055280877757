import { IncError } from "@core/domain/errors/inc.error";
import i18n from "@i18n";

export class DocumentMaxSizeError extends IncError {
    constructor() {
        super(i18n.t("error:core.file.documentMaxSizeMessage"));

        Object.setPrototypeOf(this, DocumentMaxSizeError.prototype);
    }
}
