import { Nullable } from "@core/domain/types/nullable.type";
import { AdditionalCostData } from "@entity/data/dto/cost/cost.dto";
import { AdditionalExpense } from "@entity/domain/models/cost/cost.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class CostAdditionalExpensesMapper {
    map(aditionalExpenseDto: AdditionalCostData): Nullable<AdditionalExpense> {
        const errors = validateSync(aditionalExpenseDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        return new AdditionalExpense(
            aditionalExpenseDto.id,
            aditionalExpenseDto.project,
            aditionalExpenseDto.invoice,
            aditionalExpenseDto.amount,
        );
    }
}
