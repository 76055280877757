import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContractsRepository } from "@entity/data/repositories/contracts.repository";
import { EditFreelanceContract } from "@entity/domain/models/employee/contracts/freelance/edit-freelance-contract.model";
import { FreelanceContract } from "@entity/domain/models/employee/contracts/freelance/freelance-contract.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditFreelanceContractUseCase {
    constructor(
        @inject(ContractsRepository)
        private readonly contractsRepository: ContractsRepository,
    ) {}

    async execute(
        freelanceContract: EditFreelanceContract,
    ): Promise<Nullable<FreelanceContract>> {
        const editResult =
            await this.contractsRepository.editFreelanceContract(
                freelanceContract,
            );

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedFreelanceContract) => editedFreelanceContract,
        );
    }
}
