import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { AddressRepository } from "@entity/data/repositories/address.repository";
import { Address } from "@entity/domain/models/address/address.model";
import { EditAddress } from "@entity/domain/models/address/edit-address.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditAddressUseCase {
    constructor(
        @inject(AddressRepository)
        private readonly addressRepository: AddressRepository,
    ) {}

    async execute(address: EditAddress): Promise<Nullable<Address>> {
        const editResult = await this.addressRepository.edit(address);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedAddress) => editedAddress,
        );
    }
}
