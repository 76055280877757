import { Nullable } from "@core/domain/types/nullable.type";
import { FreelanceContractDto } from "@entity/data/dto/employee/contracts/freelance/freelance-contract.dto";
import { ProfessionalGroupEnumMapper } from "@entity/data/mappers/employee/professional-group-enum.mapper";
import { FreelanceContract } from "@entity/domain/models/employee/contracts/freelance/freelance-contract.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class FreelanceContractMapper {
    constructor(
        @inject(ProfessionalGroupEnumMapper)
        private readonly professionalGroupEnumMapper: ProfessionalGroupEnumMapper,
    ) {}
    map(
        freelanceContractDto: FreelanceContractDto,
    ): Nullable<FreelanceContract> {
        const errors = validateSync(freelanceContractDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        const professionalGroup = this.professionalGroupEnumMapper.map(
            freelanceContractDto.professional_group,
        );
        if (!professionalGroup) return null;

        const freelanceContract = new FreelanceContract(
            freelanceContractDto.id,
            freelanceContractDto.service,
            professionalGroup,
            DateTime.fromISO(freelanceContractDto.start_date),
            Number(freelanceContractDto.total_hours),
            Number(freelanceContractDto.hours_cost),
            Number(freelanceContractDto.total_cost),
            Number(freelanceContractDto.invoices_number),
            Number(freelanceContractDto.IRPF),
            freelanceContractDto.employee,
        );

        if (freelanceContractDto.end_date) {
            freelanceContract.endDate = DateTime.fromISO(
                freelanceContractDto.end_date,
            );
        }

        return freelanceContract;
    }
}
