import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum PaymentMethodTypeEnumDto {
    CASH = "CASH",
    BANK_JUSTIFY = "BANK_JUSTIFY",
}
export class PaymentMethodTypeDto {
    @IsEnum(PaymentMethodTypeEnumDto)
    @IsNotEmpty()
    key!: PaymentMethodTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
