import { CreateProjectAuditoryBody } from "@entity/data/dto/quality-framework/project-auditory/create-project-auditory.body";
import { CreateProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/create-project-auditory.model";
import { ProjectAuditoryFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/project-auditory/form/project-auditory-form";
import { injectable } from "inversify";

@injectable()
export class CreateProjectAuditoryMapper {
    mapToCreateDto(
        createProjectAuditory: CreateProjectAuditory,
    ): CreateProjectAuditoryBody {
        const auditoryDto: CreateProjectAuditoryBody = {
            certification_entity: createProjectAuditory.certificationEntity,
            year: createProjectAuditory.year,
            audited_project: createProjectAuditory.auditedProject,
            entity: createProjectAuditory.entity,
        };

        return auditoryDto;
    }

    mapFromFormValues(
        entityId: number,
        projectAuditory: ProjectAuditoryFormValuesValidated,
    ): CreateProjectAuditory {
        const createProjectAuditory: CreateProjectAuditory =
            new CreateProjectAuditory(
                projectAuditory.certificationEntity,
                projectAuditory.year.year,
                projectAuditory.auditedProject,
                entityId,
            );

        return createProjectAuditory;
    }
}
