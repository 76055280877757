import { EmploymentStatusRepository } from "@core/data/repositories/employment-status.repository";
import { EmploymentStatus } from "@core/domain/models/employment-status.model";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllEmploymentStatusUseCase {
    constructor(
        @inject(EmploymentStatusRepository)
        private readonly employmentStatusRepository: EmploymentStatusRepository,
    ) {}

    async execute(): Promise<EmploymentStatus[]> {
        const relationShipsResult =
            await this.employmentStatusRepository.getAll();

        if (relationShipsResult.isLeft()) {
            ToastManagerStore.error(
                relationShipsResult.getLeftOrThrow().message,
            );
        }

        return relationShipsResult.fold(
            () => [],
            (relationShips) => relationShips,
        );
    }
}
