import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { SupplierRepository } from "@entity/data/repositories/supplier.repository";
import { EditSupplier } from "@entity/domain/models/supplier/edit-supplier.model";
import { Supplier } from "@entity/domain/models/supplier/supplier.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditSupplierUseCase {
    constructor(
        @inject(SupplierRepository)
        private readonly supplierRepository: SupplierRepository,
    ) {}

    async execute(supplier: EditSupplier): Promise<Nullable<Supplier>> {
        const creationResult = await this.supplierRepository.edit(supplier);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdSupplier) => createdSupplier,
        );
    }
}
