import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { DepositRepository } from "../../data/repositories/deposit.repository";
import { IdentificationType } from "../models/identification-type.model";

@injectable()
export class GetAllIdentificationTypesUseCase {
    constructor(
        @inject(DepositRepository)
        private readonly depositRepository: DepositRepository,
    ) {}

    async execute(): Promise<IdentificationType[]> {
        const identificationDocumentTypesResult =
            await this.depositRepository.getAllIdentificationDocumentTypes();

        if (identificationDocumentTypesResult.isLeft()) {
            ToastManagerStore.error(
                identificationDocumentTypesResult.getLeftOrThrow().message,
            );
        }

        return identificationDocumentTypesResult.fold(
            () => [],
            (identificationDocumentTypes) => identificationDocumentTypes,
        );
    }
}
