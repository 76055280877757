import { CreateProjectMaterialBody } from "@project/data/dto/materials/create-project-material.body";
import { CreateProjectMaterial } from "@project/domain/models/project-material/create-project-material.model";
import { MaterialFormValuesValidated } from "@project/presentation/components/material-resources/form/material-form";
import { injectable } from "inversify";

@injectable()
export class CreateProjectMaterialMapper {
    mapToDto(
        projectMaterial: CreateProjectMaterial,
    ): CreateProjectMaterialBody {
        const projectMaterialDto: CreateProjectMaterialBody = {
            project: projectMaterial.projectId,
            material: projectMaterial.materialId,
            hours_dedicated: projectMaterial.hoursDedicated?.toString() ?? null,
            documents: projectMaterial.documents
                ? projectMaterial.documents.map((document) => document.id)
                : null,
            start_date: projectMaterial.startDate
                ? projectMaterial.startDate.toISODate()
                : null,
            end_date: projectMaterial.endDate
                ? projectMaterial.endDate.toISODate()
                : null,
        };

        return projectMaterialDto;
    }

    mapFromProjectFormValues(
        projectId: number,
        projectMaterialForm: MaterialFormValuesValidated,
    ): CreateProjectMaterial {
        const createProjectMaterial: CreateProjectMaterial =
            new CreateProjectMaterial(
                projectId,
                projectMaterialForm.materialId,
                projectMaterialForm.hoursDedicated,
                projectMaterialForm.documents,
                projectMaterialForm.startDate,
                projectMaterialForm.endDate,
            );

        return createProjectMaterial;
    }
}
