export enum IRPFTypeEnum {
    Profesional = "PROFESIONAL",
    Entrepeneur = "ENTREPENEUR",
    ActModule = "ACT_MODULE",
    Farm = "FARM",
    Forest = "FOREST",
    PigFarm = "PIG_FARM",
}

export class IRPFType {
    constructor(
        public readonly id: IRPFTypeEnum,
        public readonly label: string,
    ) {}
}
