import { ProjectExpenseRepository } from "@project/data/repositories/project-expense.repository";
import { inject, injectable } from "inversify";
import { ExpenseType } from "../../models/expenses/expense-type.model";

@injectable()
export class GetAllProjectExpenseTypesUseCase {
    constructor(
        @inject(ProjectExpenseRepository)
        private readonly projectExpenseRepository: ProjectExpenseRepository,
    ) {}

    async execute(): Promise<ExpenseType[]> {
        const expenseTypesResult =
            await this.projectExpenseRepository.getAllExpenseTypes();

        return expenseTypesResult.getOrElse([]);
    }
}
