import { CostRepository } from "@entity/data/repositories/cost.repository";
import { inject, injectable } from "inversify";
import {
    CostSearchFilters,
    CostSummary,
} from "../../models/cost/cost-summary.model";

@injectable()
export class GetAllCostsByUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(filters?: CostSearchFilters): Promise<CostSummary[]> {
        const costsResult = await this.costRepository.getAllBy(filters);

        return costsResult.getOrElse([]);
    }
}
