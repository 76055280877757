import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { UserRelatedEmployee } from "../../domain/models/user.model";
import { UserRelatedEmployeeDto } from "../dto/user.dto";

@injectable()
export class UserRelatedEmployeeMapper {
    map(
        userRelatedEmployeeDto: UserRelatedEmployeeDto,
    ): Nullable<UserRelatedEmployee> {
        const errors = validateSync(userRelatedEmployeeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const userRelatedEmployee = new UserRelatedEmployee(
            userRelatedEmployeeDto.id,
            userRelatedEmployeeDto.name,
            userRelatedEmployeeDto.surname,
            userRelatedEmployeeDto.identification,
        );

        return userRelatedEmployee;
    }
}
