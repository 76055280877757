import { Nullable } from "@core/domain/types/nullable.type";
import { EntityTerritorialScopeEnumDto } from "@entity/data/dto/entity-territorial-scope.dto";
import { EntityTerritorialScopeEnum } from "@entity/domain/models/entity-territorial-scope.model";
import { injectable } from "inversify";

@injectable()
export class EntityTerritorialScopeEnumMapper {
    map(
        entityTerritorialScopeEnumDto: EntityTerritorialScopeEnumDto,
    ): Nullable<EntityTerritorialScopeEnum> {
        switch (entityTerritorialScopeEnumDto) {
            case EntityTerritorialScopeEnumDto.LOCAL:
                return EntityTerritorialScopeEnum.LOCAL;
            case EntityTerritorialScopeEnumDto.AUTONOMOUS_COMMUNITY:
                return EntityTerritorialScopeEnum.AUTONOMOUS_COMMUNITY;
            case EntityTerritorialScopeEnumDto.NATIONAL:
                return EntityTerritorialScopeEnum.NATIONAL;
            case EntityTerritorialScopeEnumDto.INTERNATIONAL:
                return EntityTerritorialScopeEnum.INTERNATIONAL;
            default:
                return null;
        }
    }

    mapToDto(
        entityTerritorialScopeEnum: EntityTerritorialScopeEnum,
    ): EntityTerritorialScopeEnumDto {
        // eslint-disable-next-line default-case
        switch (entityTerritorialScopeEnum) {
            case EntityTerritorialScopeEnum.LOCAL:
                return EntityTerritorialScopeEnumDto.LOCAL;
            case EntityTerritorialScopeEnum.AUTONOMOUS_COMMUNITY:
                return EntityTerritorialScopeEnumDto.AUTONOMOUS_COMMUNITY;
            case EntityTerritorialScopeEnum.NATIONAL:
                return EntityTerritorialScopeEnumDto.NATIONAL;
            case EntityTerritorialScopeEnum.INTERNATIONAL:
                return EntityTerritorialScopeEnumDto.INTERNATIONAL;
        }
    }
}
