import { EditContractTerminationBody } from "@entity/data/dto/employee/contracts/termination/edit-contract-termination.body";
import { TerminationReasonEnumMapper } from "@entity/data/mappers/employee/termination-reason-enum.mapper";
import { EditContractTermination } from "@entity/domain/models/employee/contracts/termination/edit-contract-termination.model";
import { ContractTerminationFormValuesValidated } from "@entity/presentation/component/employee-form/contract-termination/form/contract-termination-form";
import { inject, injectable } from "inversify";

@injectable()
export class EditContractTerminationMapper {
    constructor(
        @inject(TerminationReasonEnumMapper)
        private readonly terminationReasonEnumMapper: TerminationReasonEnumMapper,
    ) {}

    mapToDto(
        contractTermination: EditContractTermination,
    ): EditContractTerminationBody {
        const type = this.terminationReasonEnumMapper.mapToDto(
            contractTermination.type,
        );

        return {
            id: contractTermination.id,
            start_date: contractTermination.startDate.toISODate() ?? "",
            reason: contractTermination.reason,
            type: type,
            contract: contractTermination.contractId,
            cost: contractTermination.cost?.toString() ?? undefined,
            total_cost: contractTermination.totalCost?.toString() ?? undefined,
            end_date: contractTermination.endDate?.toISODate() ?? null,
        };
    }

    mapFromContractTerminationFormValues(
        contractTerminationId: number,
        contractTerminationForm: ContractTerminationFormValuesValidated,
    ): EditContractTermination {
        if (!contractTerminationId)
            throw Error("Missing income contractTerminationId");

        const editContractTermination = new EditContractTermination(
            contractTerminationId,
            contractTerminationForm.startDate,
            contractTerminationForm.reason,
            contractTerminationForm.terminationReason,
            contractTerminationForm.contractId,
            contractTerminationForm.cost,
            contractTerminationForm.totalCost,
            contractTerminationForm.endDate,
        );

        return editContractTermination;
    }
}
