import { Nullable } from "@core/domain/types/nullable.type";
import { SubsidyReasonEnumDto } from "@entity/data/dto/employee/subsidy-reason.dto";
import { SubsidyReasonEnum } from "@entity/domain/models/employee/subsidy-reason.model";
import { injectable } from "inversify";

@injectable()
export class SubsidyReasonEnumMapper {
    map(
        subsidyReasonEnumDto: SubsidyReasonEnumDto,
    ): Nullable<SubsidyReasonEnum> {
        switch (subsidyReasonEnumDto) {
            case SubsidyReasonEnumDto.disability:
                return SubsidyReasonEnum.DISABILITY;
            case SubsidyReasonEnumDto.ett_worker:
                return SubsidyReasonEnum.ETT_WORKER;
            case SubsidyReasonEnumDto.conciliation:
                return SubsidyReasonEnum.CONCILIATION;
            case SubsidyReasonEnumDto.employment_preservation:
                return SubsidyReasonEnum.EMPLOYMENT_PRESERVATION;
            case SubsidyReasonEnumDto.protected_collective:
                return SubsidyReasonEnum.PROTECTED_COLLECTIVE;
            case SubsidyReasonEnumDto.special_age_group:
                return SubsidyReasonEnum.SPECIAL_AGE_GROUP;
            case SubsidyReasonEnumDto.made_indedfinite:
                return SubsidyReasonEnum.MADE_INDEDFINITE;
            case SubsidyReasonEnumDto.other:
                return SubsidyReasonEnum.OTHER;
            default:
                return null;
        }
    }

    mapToDto(subsidyReasonEnum: SubsidyReasonEnum): SubsidyReasonEnumDto {
        // eslint-disable-next-line default-case
        switch (subsidyReasonEnum) {
            case SubsidyReasonEnum.DISABILITY:
                return SubsidyReasonEnumDto.disability;
            case SubsidyReasonEnum.ETT_WORKER:
                return SubsidyReasonEnumDto.ett_worker;
            case SubsidyReasonEnum.CONCILIATION:
                return SubsidyReasonEnumDto.conciliation;
            case SubsidyReasonEnum.EMPLOYMENT_PRESERVATION:
                return SubsidyReasonEnumDto.employment_preservation;
            case SubsidyReasonEnum.PROTECTED_COLLECTIVE:
                return SubsidyReasonEnumDto.protected_collective;
            case SubsidyReasonEnum.SPECIAL_AGE_GROUP:
                return SubsidyReasonEnumDto.special_age_group;
            case SubsidyReasonEnum.MADE_INDEDFINITE:
                return SubsidyReasonEnumDto.made_indedfinite;
            case SubsidyReasonEnum.OTHER:
                return SubsidyReasonEnumDto.other;
        }
    }
}
