import { AuthenticationRepository } from "@authentication/data/repositories/authentication.repository";
import { inject, injectable } from "inversify";

@injectable()
export class LogoutUseCase {
    constructor(
        @inject(AuthenticationRepository)
        private readonly authenticationRepository: AuthenticationRepository,
    ) {}

    /**
     *
     * @param onlyLocalSession: True will only close frontend session, false will also call remote logout
     */
    async execute(onlyLocalSession = false): Promise<void> {
        await this.authenticationRepository.logout(onlyLocalSession);
    }
}
