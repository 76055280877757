import { Nullable } from "@core/domain/types/nullable.type";
import { BudgetSpreadsheetColumnDto } from "@project/data/dto/budget-spreadsheet.dto";
import { BudgetSpreadsheetValidationTypeEnumMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-validation-type-enum.mapper";
import { BudgetSpreadsheetColumn } from "@project/domain/models/budget-spreadsheet.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetSpreadsheetColumnMapper {
    constructor(
        @inject(BudgetSpreadsheetValidationTypeEnumMapper)
        private readonly budgetSpreadsheetValidationTypeEnumMapper: BudgetSpreadsheetValidationTypeEnumMapper,
    ) {}

    map(
        columnDto: BudgetSpreadsheetColumnDto,
    ): Nullable<BudgetSpreadsheetColumn> {
        const errors = validateSync(columnDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        const type = this.budgetSpreadsheetValidationTypeEnumMapper.map(
            columnDto.type,
        );
        if (!type) return null;

        return new BudgetSpreadsheetColumn(columnDto.id, columnDto.name, type);
    }
}
