import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum MonthTypeEnumDto {
    NONE = "NONE",
    JANUARY = "JANUARY",
    FEBRUARY = "FEBRUARY",
    MARCH = "MARCH",
    APRIL = "APRIL",
    MAY = "MAY",
    JUNE = "JUNE",
    JULY = "JULY",
    AUGUST = "AUGUST",
    SEPTEMBER = "SEPTEMBER",
    OCTOBER = "OCTOBER",
    NOVEMBER = "NOVEMBER",
    DECEMBER = "DECEMBER",
}
export class MonthTypeDto {
    @IsEnum(MonthTypeEnumDto)
    @IsNotEmpty()
    key!: MonthTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
