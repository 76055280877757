import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { RegconRegisterRepository } from "@entity/data/repositories/regcon-register.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteRegconRegisterUseCase {
    constructor(
        @inject(RegconRegisterRepository)
        private readonly regconRegisterRepository: RegconRegisterRepository,
    ) {}

    async execute(regconRegisterId: number): Promise<void> {
        const result =
            await this.regconRegisterRepository.delete(regconRegisterId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        ToastManagerStore.success();
    }
}
