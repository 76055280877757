import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectEmployeeRepository } from "@project/data/repositories/project-employee.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteProjectEmployeeUseCase {
    constructor(
        @inject(ProjectEmployeeRepository)
        private readonly projectEmployeeRepository: ProjectEmployeeRepository,
    ) {}

    async execute(id: number): Promise<boolean> {
        const projectEmployeeResult =
            await this.projectEmployeeRepository.delete(id);

        const deleted = projectEmployeeResult.isRight();

        if (!deleted)
            ToastManagerStore.error(
                projectEmployeeResult.getLeftOrThrow().message,
            );

        return deleted;
    }
}
