import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum TitularityTypeEnumDto {
    TitularityOwned = "titularity_owned",
    TitularityRenting = "titularity_renting",
    TitularityTransfered = "titularity_transfered",
}

export class TitularityTypeDto {
    @IsEnum(TitularityTypeEnumDto)
    @IsNotEmpty()
    key!: TitularityTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
