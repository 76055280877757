import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum BudgetColumnTypeEnumDto {
    amount = "amount",
    percentage = "percentage",
}
export class BudgetColumnTypeDto {
    @IsEnum(BudgetColumnTypeEnumDto)
    @IsNotEmpty()
    key!: BudgetColumnTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
