import { BudgetsDto } from "@project/data/dto/budget.dto";
import { FinancialEntityBudgetMapper } from "@project/data/mappers/budget/financial-entity-budget.mapper";
import { FinancialEntityBudgets } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class FinancialEntityBudgetsMapper {
    constructor(
        @inject(FinancialEntityBudgetMapper)
        private readonly budgetMapper: FinancialEntityBudgetMapper,
    ) {}

    map(budgetsDto: BudgetsDto): FinancialEntityBudgets {
        const errors = validateSync(budgetsDto);

        if (errors.length) {
            console.error(errors);
            return new FinancialEntityBudgets([], 0);
        }
        const budgets = budgetsDto.results.mapNotNull((budgetDto) =>
            this.budgetMapper.map(budgetDto),
        );
        return new FinancialEntityBudgets(budgets, budgetsDto.count);
    }
}
