import { GenderDto } from "@beneficiary/data/dto/gender.dto";
import { GenderMapper } from "@beneficiary/data/mappers/gender.mapper";
import { Gender } from "@beneficiary/domain/models/gender.model";
import { coreTypes } from "@core/core-types.di";
import { type Http } from "@core/data/infrastructures/http/http";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class GendersDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(GenderMapper)
        private readonly genderMapper: GenderMapper,
    ) {}

    async fetchAllGenders(): Promise<Either<FallbackError, Gender[]>> {
        const genderResults = await this.http.get<GenderDto[]>(
            "/beneficiaries/genders/",
        );

        return genderResults
            .mapLeft(() => new FallbackError())
            .map((genderResponse) =>
                genderResponse.data.mapNotNull((genderDto) =>
                    this.genderMapper.map(plainToClass(GenderDto, genderDto)),
                ),
            );
    }
}
