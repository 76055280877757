"use client";
import { Skeleton } from "antd";
import { AvatarProps } from "antd/es/skeleton/Avatar";
import { FC, PropsWithChildren } from "react";

export interface IncSkeletonAvatarProps extends PropsWithChildren<AvatarProps> {
    loading?: boolean;
}

export const IncSkeletonAvatar: FC<IncSkeletonAvatarProps> = (props) => {
    const { children, active, loading = true, ...restProps } = props;

    /* This overrides the default behaviour of Ant Design */
    /* they always render the children, we will not */
    return loading ? (
        <Skeleton.Avatar
            {...restProps}
            active={Boolean(active)}
        />
    ) : (
        children
    );
};
