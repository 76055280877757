import { BeneficiariesSummary } from "@beneficiary/domain/models/beneficiaries-summary.model";
import type { BeneficiarySearchFilters } from "@beneficiary/domain/models/beneficiary-search-filters";
import { SearchBeneficiariesByUseCase } from "@beneficiary/domain/usecases/search-beneficiaries-by.usecase";
import { Pagination } from "@core/domain/models/pagination";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { GlobalProject } from "@project/domain/models/global-project.model";
import { GetAllProjectsUseCase } from "@project/domain/usecases/get-all-projects.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

export interface BeneficiaryListTable {
    id: number;
    name: string;
    documentNumber: string;
    projects: string[];
}

interface BeneficiariesListTable {
    beneficiaries: BeneficiaryListTable[];
    count: number;
}

@injectable()
export class BeneficiaryListPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    private _beneficiaries: BeneficiariesSummary = new BeneficiariesSummary(
        [],
        0,
    );

    @observable
    projects: GlobalProject[] = [];

    @observable
    globalEntities: GlobalEntity[] = [];

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: BeneficiarySearchFilters = {
        name: "",
        surname: "",
        documentNumber: "",
        projectIds: [],
        entityIds: [],
    };

    @observable
    filters: BeneficiarySearchFilters = this.initialFiltersValue;

    @computed
    get beneficiariesTable(): BeneficiariesListTable {
        return {
            count: this._beneficiaries.count,
            beneficiaries: this._beneficiaries.beneficiariesSummary.map(
                (beneficiarySummary) => ({
                    id: beneficiarySummary.id,
                    name: `${beneficiarySummary.name} ${beneficiarySummary.surname}`,
                    projects: beneficiarySummary.project ?? [],
                    documentNumber: beneficiarySummary.documentNumber
                        ? beneficiarySummary.documentNumber
                        : "",
                }),
            ),
        };
    }

    constructor(
        @inject(SearchBeneficiariesByUseCase)
        private readonly searchBeneficiariesByUseCase: SearchBeneficiariesByUseCase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
        @inject(GetAllProjectsUseCase)
        private readonly getAllProjectsUseCase: GetAllProjectsUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(
            Promise.all([
                this.searchBeneficiariesBy(this.filters, this.pagination),
                this.getAllGlobalEntities(),
                this.getAllProjects(),
            ]),
        );

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setFilters(filters: BeneficiarySearchFilters): void {
        this.filters = filters;
        this.pagination.reset();

        this.searchBeneficiariesBy(this.filters, this.pagination);
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.searchBeneficiariesBy(this.filters, this.pagination);
    }

    @flow
    async *searchBeneficiariesBy(
        filters: BeneficiarySearchFilters,
        pagination: Pagination,
    ): AsyncGenerator<void> {
        const beneficiaries = await this.searchBeneficiariesByUseCase.execute(
            pagination,
            filters,
        );

        runInAction(() => {
            this._beneficiaries = beneficiaries;
        });
    }

    @flow
    async *getAllGlobalEntities(): AsyncGenerator<void> {
        const globalEntities = await this.getAllGlobalEntitiesUseCase.execute();

        runInAction(() => {
            this.globalEntities = globalEntities;
        });
    }

    @flow
    async *getAllProjects(): AsyncGenerator<void> {
        const projects = await this.getAllProjectsUseCase.execute();

        runInAction(() => {
            this.projects = projects;
        });
    }
}
