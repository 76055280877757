import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ObjectiveRepository } from "@project/data/repositories/technical-proposal/objective.repository";
import { Objective } from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetObjectiveByIdUseCase {
    constructor(
        @inject(ObjectiveRepository)
        private readonly objectiveRepository: ObjectiveRepository,
    ) {}

    async execute(objectiveId: number): Promise<Nullable<Objective>> {
        const objectiveResult =
            await this.objectiveRepository.getById(objectiveId);

        if (objectiveResult.isLeft()) {
            ToastManagerStore.error(objectiveResult.getLeftOrThrow().message);
        }

        return objectiveResult.fold(
            () => null,
            (objective) => objective,
        );
    }
}
