import { PolicyProtocolRepository } from "@entity/data/repositories/policy-protocol.repository";
import { PolicyProtocolType } from "@entity/domain/models/policy-protocol-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllPolicyProtocolTypesUseCase {
    constructor(
        @inject(PolicyProtocolRepository)
        private readonly policyProtocolRepository: PolicyProtocolRepository,
    ) {}

    async execute(): Promise<PolicyProtocolType[]> {
        const policyProtocolTypesResult =
            await this.policyProtocolRepository.getAllPolicyProtocolTypes();

        return policyProtocolTypesResult.fold(
            () => [],
            (policyProtocolTypes) => policyProtocolTypes,
        );
    }
}
