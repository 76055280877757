import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { LanguageRepository } from "@core/data/repositories/language.repository";
import {
    Language,
    LanguageIsoCodeEnum,
} from "@core/domain/models/language.model";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { GetAllLanguagesUseCase } from "@core/domain/usecases/get-all-languages.usecase";
import { UpdateLanguageUseCase } from "@core/domain/usecases/update-current-language.usecase";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { ImpersonateEntityUseCase } from "@user/domain/usecases/impersonate-entity.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

@injectable()
export class IncHeaderViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    dropdownOpen: boolean = false;

    @observable
    languages: Language[] = [];

    @observable
    currentLanguage: Undefinable<LanguageIsoCodeEnum> = undefined;

    @observable
    entityId: Undefinable<number> = undefined;

    @observable
    entityName: Undefinable<string> = undefined;

    @computed
    get globalEntities(): GlobalEntity[] {
        return this.getAllGlobalEntitiesUseCase.globalEntities;
    }

    constructor(
        @inject(GetAllLanguagesUseCase)
        private readonly getAllLanguagesUseCase: GetAllLanguagesUseCase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
        @inject(UpdateLanguageUseCase)
        private readonly updateLanguageUseCase: UpdateLanguageUseCase,
        @inject(LanguageRepository)
        private readonly languageRepository: LanguageRepository,
        @inject(UserSessionRepository)
        private readonly userSessionRepository: UserSessionRepository,
        @inject(ImpersonateEntityUseCase)
        private readonly impersonateEntityUseCase: ImpersonateEntityUseCase,
    ) {
        super();
        makeObservable(this);

        this.addSub(
            this.languageRepository.currentLanguage.subscribe(
                (currentLanguage) => {
                    this.currentLanguage = currentLanguage;
                },
            ),
        );

        this.addSub(
            this.userSessionRepository.user.subscribe((user) => {
                this.entityId = user?.entityId;
                this.entityName = user?.entityName;
            }),
        );
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    async initViewData(): Promise<void> {
        Promise.all([this.getAllLanguages(), this.getGlobalAllEntities()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getGlobalAllEntities(): Promise<void> {
        await this.getAllGlobalEntitiesUseCase.execute();
    }

    @action
    async getAllLanguages(): Promise<void> {
        const languages = await this.getAllLanguagesUseCase.execute();

        runInAction(() => {
            this.languages = languages;
        });
    }

    @action
    setEntity(entityId: Undefinable<number>): void {
        this.entityId = entityId;
    }

    @action
    setCurrentLanguage(language: LanguageIsoCodeEnum): void {
        this.currentLanguage = language;
    }

    async changeLanguage(): Promise<void> {
        if (this.currentLanguage){
            await this.updateLanguageUseCase.execute(this.currentLanguage);
            await this.getAllLanguages();
        }
        
    }

    async impersonateEntity(): Promise<void> {
        await this.impersonateEntityUseCase.execute(this.entityId ?? null);
    }

    @action
    setDropdownOpen(dropdownOpen: boolean): void {
        this.dropdownOpen = dropdownOpen;
    }
}
