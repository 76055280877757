import { EducationLevelEnumMapper } from "@core/data/mappers/education-level-enum.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import { VolunteerContractDto } from "@entity/data/dto/employee/contracts/volunteer/volunteer-contract.dto";
import { VolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/volunteer-contract.model";
import { CatalogueEnumMapper } from "@project/data/mappers/catalogue-enum.mapper";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class VolunteerContractMapper {
    constructor(
        @inject(CatalogueEnumMapper)
        private readonly catalogueEnumMapper: CatalogueEnumMapper,
        @inject(EducationLevelEnumMapper)
        private readonly educationLevelEnumMapper: EducationLevelEnumMapper,
    ) {}
    map(
        volunteerContractDto: VolunteerContractDto,
    ): Nullable<VolunteerContract> {
        const errors = validateSync(volunteerContractDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        const educationLevel = this.educationLevelEnumMapper.map(
            volunteerContractDto.education_level,
        );
        if (!educationLevel) return null;

        const preferences = volunteerContractDto.preferences.mapNotNull(
            (catalogueDto) => this.catalogueEnumMapper.map(catalogueDto),
        );

        let startDateInsurance = null;
        if (volunteerContractDto.start_date_insurance) {
            startDateInsurance = DateTime.fromISO(
                volunteerContractDto.start_date_insurance,
            );
        }

        const volunteerContract = new VolunteerContract(
            volunteerContractDto.id,
            volunteerContractDto.has_insurance,
            volunteerContractDto.activity,
            educationLevel,
            volunteerContractDto.availability,
            preferences,
            DateTime.fromISO(volunteerContractDto.start_date),
            volunteerContractDto.employee,
            parseInt(volunteerContractDto.weekly_workday_hours),
            startDateInsurance,
            volunteerContractDto.other_preferences ?? null,
            volunteerContractDto.door ?? null,
            volunteerContractDto.city ?? null,
        );

        if (volunteerContractDto.end_date) {
            volunteerContract.endDate = DateTime.fromISO(
                volunteerContractDto.end_date,
            );
        }

        if (volunteerContractDto.end_date_insurance) {
            volunteerContract.endDateInsurance = DateTime.fromISO(
                volunteerContractDto.end_date_insurance,
            );
        }

        return volunteerContract;
    }
}
