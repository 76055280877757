import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { IVATypeEnumDto } from "@entity/data/dto/iva-type.dto";
import { IVAEnumMapper } from "@entity/data/mappers/cost/iva-enum.mapper";
import { CreateInvestmentExpenseBody } from "@project/data/dto/expenses/create-expense.body";
import { EditInvestmentExpenseBody } from "@project/data/dto/expenses/edit-expense.body";
import { ExpenseSubTypeEnumDto } from "@project/data/dto/expenses/expense-sub-type.dto";
import { ExpenseTypeEnumDto } from "@project/data/dto/expenses/expense-type.dto";
import { CreateInvestmentExpense } from "@project/domain/models/expenses/create-expense.model";
import { EditInvestmentExpense } from "@project/domain/models/expenses/edit-expense.model";
import { ExpenseTypeEnum } from "@project/domain/models/expenses/expense-type.model";
import { InvestmentExpense } from "@project/domain/models/expenses/expense.model";
import { ExpenseSubTypeEnum } from "@project/domain/models/expenses/sub-type.model";
import { ExpenseInvestmentFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/investment/expense-investment-form.component";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { ExpenseDto } from "../../dto/expenses/expenses.dto";
import { InvestmentDto } from "../../dto/expenses/investment.dto";
import { ExpenseSubTypeEnumMapper } from "./expense-sub-type-enum.mapper";
import { PaymentMethodTypeEnumMapper } from "./payment-method-type-enum.mapper";

const maximumDecimalsNumber = 2;

@injectable()
export class InvestmentExpenseMapper {
    constructor(
        @inject(ExpenseSubTypeEnumMapper)
        private readonly expenseSubTypeMapper: ExpenseSubTypeEnumMapper,
        @inject(PaymentMethodTypeEnumMapper)
        private readonly paymentMethodTypeEnumMapper: PaymentMethodTypeEnumMapper,
        @inject(IVAEnumMapper)
        private readonly ivaEnumMapper: IVAEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
    ) {}
    map(
        expenseDto: ExpenseDto,
        investmentData: InvestmentDto,
    ): Nullable<InvestmentExpense> {
        const errors = validateSync(investmentData);

        if (errors.length > 0) {
            console.error(errors);
            return null;
        }

        const subtype = this.expenseSubTypeMapper.map(
            investmentData.subtype_expense,
        );

        let paymentMethod = null;
        if (investmentData.payment_method)
            paymentMethod = this.paymentMethodTypeEnumMapper.map(
                investmentData.payment_method,
            );

        const ivaEnum = investmentData.iva.toString() as IVATypeEnumDto;
        const iva = this.ivaEnumMapper.map(ivaEnum);
        const documents: IncDocument[] = [];
        if (expenseDto.documents_data) {
            expenseDto.documents_data.forEach((document) => {
                const documentMapped = this.documentMapper.map(document);
                if (documentMapped) {
                    documents.push(documentMapped);
                }
            });
        }

        return new InvestmentExpense(
            expenseDto.id,
            "",
            subtype ?? ExpenseSubTypeEnum.NONE,
            "",
            investmentData.provider,
            investmentData.nif_provider,
            investmentData.description,
            investmentData.invoice_number,
            investmentData.invoice_date
                ? DateTime.fromISO(investmentData.invoice_date)
                : null,
            investmentData.expense_date
                ? DateTime.fromISO(investmentData.expense_date)
                : null,
            paymentMethod,
            investmentData.tax_base ? parseFloat(investmentData.tax_base) : 0,
            iva,
            investmentData.total_tax_with_iva
                ? parseFloat(investmentData.total_tax_with_iva)
                : 0,
            investmentData.project_allocation_percentage
                ? parseFloat(investmentData.project_allocation_percentage)
                : null,
            investmentData.amount_allocated_to_project
                ? parseFloat(investmentData.amount_allocated_to_project)
                : null,
            investmentData.payment_date
                ? DateTime.fromISO(investmentData.payment_date)
                : null,
            investmentData.concept,
            documents,
            expenseDto.project,
        );
    }

    mapFromFormValues(
        createFormValues: ExpenseInvestmentFormValuesValidated,
        projectId: number,
    ): CreateInvestmentExpense {
        return new CreateInvestmentExpense(
            projectId,
            ExpenseTypeEnum.INVESTMENT,
            createFormValues.subTypeId,
            createFormValues.provider,
            createFormValues.nifProvider ? createFormValues.nifProvider : null,
            createFormValues.description,
            createFormValues.invoiceNumber,
            createFormValues.invoiceDate,
            createFormValues.invoiceDate,
            createFormValues.paymentMethod,
            createFormValues.base,
            createFormValues.iva,
            createFormValues.baseIva,
            createFormValues.percentageImputation,
            createFormValues.amountImputation,
            createFormValues.paymentDate,
            createFormValues.documents.map((document) => document.id),
            createFormValues.concept,
        );
    }

    mapFromFormEditValues(
        editFormValues: ExpenseInvestmentFormValuesValidated,
        expenseId: number,
        projectId: number,
    ): EditInvestmentExpense {
        return new EditInvestmentExpense(
            expenseId,
            projectId,
            ExpenseTypeEnum.INVESTMENT,
            editFormValues.subTypeId,
            editFormValues.provider,
            editFormValues.nifProvider ? editFormValues.nifProvider : null,
            editFormValues.description,
            editFormValues.invoiceNumber,
            editFormValues.invoiceDate,
            editFormValues.invoiceDate,
            editFormValues.paymentMethod,
            editFormValues.base,
            editFormValues.iva,
            editFormValues.baseIva,
            editFormValues.percentageImputation,
            editFormValues.amountImputation,
            editFormValues.paymentDate,
            editFormValues.documents.map((document) => document.id),
            editFormValues.concept,
        );
    }

    mapToDto(
        createExpense: CreateInvestmentExpense,
    ): CreateInvestmentExpenseBody {
        if (!createExpense.paymentMethod) {
            throw new FallbackError();
        }

        const paymentMethod = this.paymentMethodTypeEnumMapper.mapToDto(
            createExpense.paymentMethod,
        );
        let subType = null;
        if (createExpense.subtypeExpense) {
            subType = this.expenseSubTypeMapper.mapToDto(
                createExpense.subtypeExpense,
            );
        }
        const percentageImputation =
            createExpense.percentageImputation?.toFixed(maximumDecimalsNumber);

        const amountAllocatedToProject = createExpense.amountImputation
            ? Number(
                  createExpense.amountImputation.toFixed(maximumDecimalsNumber),
              )
            : 0;

        return {
            project: createExpense.projectId,
            type_expense: ExpenseTypeEnumDto.INVESTMENT,
            subtype_expense: subType ?? ExpenseSubTypeEnumDto.NONE,
            amount_allocated_to_project: amountAllocatedToProject,
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: createExpense.documents,
            concept: createExpense.concept ?? "",
            provider: createExpense.provider,
            nif_provider: createExpense.nifProvider ?? "",
            description: createExpense.description ?? "",
            invoice_number: createExpense.invoiceNumber ?? "",
            expense_date: createExpense.invoiceDate?.toISODate() ?? "",
            tax_base: createExpense.taxBase?.toString() ?? "0",
            iva: createExpense.iva?.toString() ?? "0",
            total_tax_with_iva:
                createExpense.totalTaxWithIva?.toString() ?? "0",
            payment_method: paymentMethod,
            payment_date: createExpense.paymentDate?.toISODate() ?? "",
        };
    }

    mapToEditDto(
        editExpense: EditInvestmentExpense,
    ): EditInvestmentExpenseBody {
        if (!editExpense.paymentMethod) {
            throw new FallbackError();
        }

        const paymentMethod = this.paymentMethodTypeEnumMapper.mapToDto(
            editExpense.paymentMethod,
        );
        let subType = null;
        if (editExpense.subtypeExpense) {
            subType = this.expenseSubTypeMapper.mapToDto(
                editExpense.subtypeExpense,
            );
        }

        const percentageImputation = editExpense.percentageImputation?.toFixed(
            maximumDecimalsNumber,
        );

        return {
            id: editExpense.id,
            project: editExpense.projectId,
            type_expense: ExpenseTypeEnumDto.INVESTMENT,
            subtype_expense: subType ?? ExpenseSubTypeEnumDto.NONE,
            amount_allocated_to_project: editExpense.amountImputation ?? 0,
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: editExpense.documents,
            concept: editExpense.concept ?? "",
            provider: editExpense.provider,
            nif_provider: editExpense.nifProvider ?? "",
            description: editExpense.description ?? "",
            invoice_number: editExpense.invoiceNumber ?? "",
            expense_date: editExpense.invoiceDate?.toISODate() ?? "",
            tax_base: editExpense.taxBase?.toString() ?? "0",
            iva: editExpense.iva?.toString() ?? "0",
            total_tax_with_iva: editExpense.totalTaxWithIva?.toString() ?? "0",
            payment_method: paymentMethod,
            payment_date: editExpense.paymentDate?.toISODate() ?? "",
        };
    }
}
