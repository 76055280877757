export enum PolicyProtocolTypeEnum {
    QualityPolicy = "quality_policy",
    HumanRightsPolicy = "human_rights_policy",
    EnvironmentalPolicy = "environmental_policy",
    VolunteerPlan = "volunteer_plan",
    PreventionActionHarassmentProtocol = "prevention_action_harassment_protocol",
    TransparencyPolicy = "transparency_policy",
    EthicsConductGoodGovernance = "ethics_conduct_good_governance",
    AdvertisementCriteriaMarketing = "advertisement_criteria_marketing",
    DerivationProtocol = "derivation_protocol",
    LgtbqphobicActuationProtocol = "lgtbqphobic_actuation_protocol",
    EvaluationPolicy = "evaluation_policy",
    Other = "other",
}

export class PolicyProtocolType {
    constructor(
        public readonly id: PolicyProtocolTypeEnum,
        public readonly label: string,
    ) {}
}
