export enum StatusEnum {
    Create = "0",
    Design = "1",
    Founding = "2",
    Execution = "3",
    Justification = "4",
    Finished = "5",
    Discarded = "-1",
}

export class Status {
    constructor(
        public readonly id: StatusEnum,
        public readonly label: string,
    ) {}
}
