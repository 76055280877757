import { Nullable } from "@core/domain/types/nullable.type";
import {
    MaterialType,
    MaterialTypeEnum,
} from "@entity/domain/models/material/material-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { MaterialTypeDto } from "../../dto/material/material-type-enum.dto";
import { MaterialTypeEnumMapper } from "./material-type-enum.mapper";

@injectable()
export class MaterialTypeMapper {
    constructor(
        @inject(MaterialTypeEnumMapper)
        private readonly materialTypeEnumMapper: MaterialTypeEnumMapper,
    ) {}

    map(materialTypeDto: MaterialTypeDto): Nullable<MaterialType> {
        const errors = validateSync(materialTypeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<MaterialTypeEnum> = this.materialTypeEnumMapper.map(
            materialTypeDto.key,
        );

        if (!id) return null;

        return new MaterialType(id, materialTypeDto.value);
    }
}
