import { Pagination } from "@core/domain/models/pagination";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import {
    Creditors,
    type CreditorSearchFilters,
} from "@entity/domain/models/creditor/creditor.model";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { DeleteCreditorUseCase } from "@entity/domain/usecases/creditor/delete-creditor.usecase";
import { GetCreditorsByUseCase } from "@entity/domain/usecases/creditor/get-creditors-by.usecase";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class CreditorListPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    creditors: Creditors = new Creditors([], 0);

    @observable
    pagination: Pagination = new Pagination();

    @observable
    globalEntities: GlobalEntity[] = [];

    initialFiltersValue: CreditorSearchFilters = {
        search: "",
    };

    @observable
    filters: CreditorSearchFilters = this.initialFiltersValue;

    constructor(
        @inject(GetCreditorsByUseCase)
        private readonly getCreditorsByUseCase: GetCreditorsByUseCase,
        @inject(DeleteCreditorUseCase)
        private readonly deleteCreditorUseCase: DeleteCreditorUseCase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.searchCreditorsBy(this.filters, this.pagination),
            this.getAllGlobalEntities(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setFilters(filters: CreditorSearchFilters): void {
        this.filters = filters;

        this.pagination.reset();

        this.searchCreditorsBy(this.filters, this.pagination);
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.searchCreditorsBy(this.filters, this.pagination);
    }

    async searchCreditorsBy(
        filters: CreditorSearchFilters,
        pagination: Pagination,
    ): Promise<void> {
        const creditors = await this.getCreditorsByUseCase.execute(
            pagination,
            filters,
        );
        runInAction(() => {
            this.creditors = creditors;
        });
    }

    async deleteCreditor(creditorId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteCreditorUseCase.execute(creditorId);
        await this.reload();
        LoadLayoutStore.finish();
    }

    async getAllGlobalEntities(): Promise<void> {
        const globalEntities = await this.getAllGlobalEntitiesUseCase.execute();

        runInAction(() => {
            this.globalEntities = globalEntities;
        });
    }

    async reload(): Promise<void> {
        LoadLayoutStore.start();
        await this.searchCreditorsBy(this.filters, this.pagination);
        LoadLayoutStore.finish();
    }
}
