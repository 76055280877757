export class DocumentCategory {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly description?: string,
    ) {}
}

export class DocumentCategories {
    constructor(
        public readonly documentCategories: DocumentCategory[],
        public readonly count: number,
    ) {}
}
