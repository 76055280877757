import { CountryEnumMapper } from "@beneficiary/data/mappers/country-enum.mapper";
import { CreateAddressBody } from "@entity/data/dto/address/create-address.body";
import { TitularityTypeEnumMapper } from "@entity/data/mappers/titularity-type-enum.mapper";
import { CreateAddress } from "@entity/domain/models/address/create-address.model";
import { AddressFormValuesValidated } from "@entity/presentation/component/entity-form/address/form/address-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreateAddressMapper {
    constructor(
        @inject(CountryEnumMapper)
        private readonly countryEnumMapper: CountryEnumMapper,
        @inject(TitularityTypeEnumMapper)
        private readonly titularityTypeEnumMapper: TitularityTypeEnumMapper,
    ) {}
    mapToCreateDto(createAddress: CreateAddress): CreateAddressBody {
        const country = this.countryEnumMapper.mapToDto(createAddress.country);
        const titularity_type = this.titularityTypeEnumMapper.mapToDto(
            createAddress.titularityType,
        );

        const addressDto: CreateAddressBody = {
            name: createAddress.name,
            street_name: createAddress.streetName,
            zip_code: createAddress.zipCode,
            town: createAddress.town,
            entity: createAddress.entity,
            street_number: createAddress.streetNumber,
            country,
            titularity_type,
            region: createAddress.region ? createAddress.region : null,
            floor: createAddress.floor ? createAddress.floor : null,
            door: createAddress.door ? createAddress.door : null,
            stair: createAddress.stair ? createAddress.stair : null,
            cadastral_value: createAddress.cadastralValue
                ? createAddress.cadastralValue
                : null,
            province: createAddress.province,
            local: createAddress.local ?? false,
            principal_address: createAddress.principalAddress ?? false,
        };

        return addressDto;
    }

    mapFromFormValues(
        entityId: number,
        address: AddressFormValuesValidated,
    ): CreateAddress {
        const createAddress: CreateAddress = new CreateAddress(
            address.name,
            address.streetName,
            address.zipCode,
            address.town,
            address.province,
            entityId,
            address.streetNumber,
            address.country,
            address.titularityType,
            address.region,
            address.floor,
            address.door,
            address.stair,
            address.cadastralValue,
            address.local,
            address.principalAddress,
        );

        return createAddress;
    }
}
