import { Nullable } from "@core/domain/types/nullable.type";
import {
    PublicInterestDto,
    PublicInterestsDto,
} from "@entity/data/dto/public-interest/public-interest.dto";
import { PublicInterestMapper } from "@entity/data/mappers/public-interest/public-interest.mapper";
import {
    PublicInterest,
    PublicInterests,
} from "@entity/domain/models/public-interest/public-interest.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class PublicInterestsMapper {
    constructor(
        @inject(PublicInterestMapper)
        private readonly publicInterestMapper: PublicInterestMapper,
    ) {}

    map(publicInterestsDto: PublicInterestsDto): PublicInterests {
        const errors = validateSync(publicInterestsDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new PublicInterests([], 0);
        }

        const publicInterests = publicInterestsDto.results.mapNotNull(
            (publicInterestDto) =>
                this.publicInterestMapper.map(publicInterestDto),
        );

        return new PublicInterests(publicInterests, publicInterestsDto.count);
    }

    mapPublicInterest(
        publicInterestDto: PublicInterestDto,
    ): Nullable<PublicInterest> {
        if (!publicInterestDto.id) {
            return null;
        }

        const publicInterest: PublicInterest = {
            id: publicInterestDto.id,
            organizationType: publicInterestDto.organization_type,
            entity: publicInterestDto.entity,
            date: DateTime.fromISO(publicInterestDto.date),
        };
        return publicInterest;
    }
}
