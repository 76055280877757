import { Nullable } from "@core/domain/types/nullable.type";
import { CostIVATypeDto } from "@entity/data/dto/cost-iva-type.dto";
import { CostIVATypeEnumMapper } from "@entity/data/mappers/cost/cost-iva-type-enum.mapper";
import { CostIVAType } from "@entity/domain/models/cost/cost-iva-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class CostIVATypeMapper {
    constructor(
        @inject(CostIVATypeEnumMapper)
        private readonly costIVATypeEnumMapper: CostIVATypeEnumMapper,
    ) {}

    map(costIvaTypeDto: CostIVATypeDto): Nullable<CostIVAType> {
        const errors = validateSync(costIvaTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.costIVATypeEnumMapper.map(costIvaTypeDto.key);
        if (!id) return null;

        return new CostIVAType(id, costIvaTypeDto.value);
    }
}
