import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { CreditorRepository } from "@entity/data/repositories/creditor.repository";
import {
    Creditor,
    EditCreditor,
} from "@entity/domain/models/creditor/creditor.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditCreditorUseCase {
    constructor(
        @inject(CreditorRepository)
        private readonly creditorRepository: CreditorRepository,
    ) {}

    async execute(creditor: EditCreditor): Promise<Nullable<Creditor>> {
        const editResult = await this.creditorRepository.edit(creditor);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedCreditor) => editedCreditor,
        );
    }
}
