import { Pagination } from "@core/domain/models/pagination";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { type FinancialEntitiesSearchFilters } from "@project/domain/models/financial-entity/financial-entities-search-filters";
import { FinancialEntities } from "@project/domain/models/financial-entity/financial-entities.model";
import {
    FinancialEntityType,
    FinancialEntityTypeEnum,
} from "@project/domain/models/financial-entity/financial-entity-type";
import { GetAllFinancialEntityTypesUsecase } from "@project/domain/usecases/financial-entity/get-all-financial-entity-types.usecase";
import { SearchFinancialEntitiesByUseCase } from "@project/domain/usecases/financial-entity/search-financial-entities-by.usecase";
import { Map } from "immutable";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

export interface FinancialEntityListTable {
    id: number;
    name: string;
    type: string;
    status: boolean;
}

interface FinancialEntitiesListTable {
    financialEntities: FinancialEntityListTable[];
    count: number;
}

@injectable()
export class FinancialEntityListPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    private _financialEntities: FinancialEntities = new FinancialEntities(
        [],
        0,
    );

    @observable
    globalEntities: GlobalEntity[] = [];

    @computed
    get financialEntitiesTable(): FinancialEntitiesListTable {
        const typesMap: Map<FinancialEntityTypeEnum, string> = Map(
            this.types.mapNotNull((type: FinancialEntityType) => [
                type.id,
                type.label,
            ]),
        );
        return {
            count: this._financialEntities.count,
            financialEntities: this._financialEntities.financialEntities.map(
                (financialEntity) => ({
                    id: financialEntity.id,
                    name: financialEntity.name,
                    type: typesMap.get(financialEntity.type) ?? "",
                    status: financialEntity.isActive,
                }),
            ),
        };
    }

    @observable
    types: FinancialEntityType[] = [];

    @observable
    status: boolean = true;

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: FinancialEntitiesSearchFilters = {
        name: "",
        financialEntityType: null,
        statusFinancialEntity: true,
    };

    @observable
    filters: FinancialEntitiesSearchFilters = this.initialFiltersValue;

    constructor(
        @inject(SearchFinancialEntitiesByUseCase)
        private readonly searchFinancialEntitiesByUseCase: SearchFinancialEntitiesByUseCase,
        @inject(GetAllFinancialEntityTypesUsecase)
        private readonly getFinancialEntityTypesUseCase: GetAllFinancialEntityTypesUsecase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.searchFinancialEntitiesBy(this.filters, this.pagination),
            this.getFinancialEntityTypes(),
            this.getAllGlobalEntities(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setFilters(filters: FinancialEntitiesSearchFilters): void {
        this.filters = filters;
        this.pagination.reset();

        this.searchFinancialEntitiesBy(this.filters, this.pagination);
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.searchFinancialEntitiesBy(this.filters, this.pagination);
    }

    async getFinancialEntityTypes(): Promise<void> {
        const financialEntityTypes =
            await this.getFinancialEntityTypesUseCase.execute();

        runInAction(() => {
            this.types = financialEntityTypes;
        });
    }

    async searchFinancialEntitiesBy(
        filters: FinancialEntitiesSearchFilters,
        pagination: Pagination,
    ): Promise<void> {
        const financialEntities =
            await this.searchFinancialEntitiesByUseCase.execute(
                pagination,
                filters,
            );
        runInAction(() => {
            this._financialEntities = financialEntities;
        });
    }

    async getAllGlobalEntities(): Promise<void> {
        const globalEntities = await this.getAllGlobalEntitiesUseCase.execute();

        runInAction(() => {
            this.globalEntities = globalEntities;
        });
    }
}
