import { Nullable } from "@core/domain/types/nullable.type";
import { CategoryTypeEnumDto } from "@entity/data/dto/category-type-enum.dto";
import { CategoryTypeEnum } from "@entity/domain/models/category-type.enum";
import { injectable } from "inversify";

@injectable()
export class CategoryTypeEnumMapper {
    map(
        entityCategoryEnumDto: CategoryTypeEnumDto,
    ): Nullable<CategoryTypeEnum> {
        switch (entityCategoryEnumDto) {
            case CategoryTypeEnumDto.Association:
                return CategoryTypeEnum.Association;
            case CategoryTypeEnumDto.Confederation:
                return CategoryTypeEnum.Confederation;
            case CategoryTypeEnumDto.Cooperatives:
                return CategoryTypeEnum.Cooperatives;
            case CategoryTypeEnumDto.Delegations:
                return CategoryTypeEnum.Delegations;
            case CategoryTypeEnumDto.Federations:
                return CategoryTypeEnum.Federations;
            case CategoryTypeEnumDto.Foundation:
                return CategoryTypeEnum.Foundation;
            case CategoryTypeEnumDto.Groupings:
                return CategoryTypeEnum.Groupings;
            default:
                return null;
        }
    }

    mapToDto(entityCategoryEnum: CategoryTypeEnum): CategoryTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (entityCategoryEnum) {
            case CategoryTypeEnum.Association:
                return CategoryTypeEnumDto.Association;
            case CategoryTypeEnum.Confederation:
                return CategoryTypeEnumDto.Confederation;
            case CategoryTypeEnum.Cooperatives:
                return CategoryTypeEnumDto.Cooperatives;
            case CategoryTypeEnum.Delegations:
                return CategoryTypeEnumDto.Delegations;
            case CategoryTypeEnum.Federations:
                return CategoryTypeEnumDto.Federations;
            case CategoryTypeEnum.Foundation:
                return CategoryTypeEnumDto.Foundation;
            case CategoryTypeEnum.Groupings:
                return CategoryTypeEnumDto.Groupings;
        }
    }
}
