import {
    IncButton,
    IncButtonProps,
} from "@core/presentacion/component/general/button/inc-button.component";
import { FC } from "react";

export type IncButtonFormProps = Omit<IncButtonProps, "htmlType">;

export const IncButtonForm: FC<IncButtonFormProps> = (props) => {
    const { type = "primary", ...restProps } = props;
    return (
        <IncButton
            {...restProps}
            type={type}
            htmlType={"submit"}
        >
            {props.children}
        </IncButton>
    );
};
