import { CreateFreelanceContract } from "@entity/domain/models/employee/contracts/freelance/create-freelance-contract.model";
import { FreelanceContract } from "@entity/domain/models/employee/contracts/freelance/freelance-contract.model";

export class EditFreelanceContract extends CreateFreelanceContract {
    // eslint-disable-next-line max-params
    constructor(
        public readonly id: FreelanceContract["id"],
        service: CreateFreelanceContract["service"],
        professionalGroup: CreateFreelanceContract["professionalGroup"],
        startDate: CreateFreelanceContract["startDate"],
        totalHours: CreateFreelanceContract["totalHours"],
        hoursCost: CreateFreelanceContract["hoursCost"],
        totalCost: CreateFreelanceContract["totalCost"],
        invoicesNumber: CreateFreelanceContract["invoicesNumber"],
        irpf: CreateFreelanceContract["irpf"],
        employeeId: CreateFreelanceContract["employeeId"],
        endDate?: CreateFreelanceContract["endDate"],
    ) {
        super(
            service,
            professionalGroup,
            startDate,
            totalHours,
            hoursCost,
            totalCost,
            invoicesNumber,
            irpf,
            employeeId,
            endDate,
        );
    }
}
