import { EditFinancialEntityBudgetBody } from "@project/data/dto/budget/edit-financial-entity-budget.body";
import { EditFinancialEntityBudgetModel } from "@project/domain/models/budget/edit-financial-entity-budget.model";
import { FinancialEntityBudgetFormValuesValidated } from "@project/presentation/components/budget-form/budget-form.component";
import { injectable } from "inversify";

@injectable()
export class EditFinancialEntityBudgetMapper {
    mapFromValues(
        budgetId: number,
        formValues: FinancialEntityBudgetFormValuesValidated,
    ): EditFinancialEntityBudgetModel {
        return new EditFinancialEntityBudgetModel(
            budgetId,
            formValues.name,
            formValues.description,
            formValues.financialEntityId,
        );
    }

    mapToDto(
        editFinancialEntityBudget: EditFinancialEntityBudgetModel,
    ): EditFinancialEntityBudgetBody {
        return {
            id: editFinancialEntityBudget.id,
            name: editFinancialEntityBudget.name,
            description: editFinancialEntityBudget.description,
            finantial_entity: editFinancialEntityBudget.financialEntityId,
        };
    }
}
