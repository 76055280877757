import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectActivityRepository } from "@project/data/repositories/technical-proposal/activity.repository";
import {
    Activity,
    CreateActivity,
} from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateActivityUseCase {
    constructor(
        @inject(ProjectActivityRepository)
        private readonly projectActivityRepository: ProjectActivityRepository,
    ) {}

    async execute(
        activity: CreateActivity,
    ): Promise<Nullable<Nullable<Activity>>> {
        const creationResult =
            await this.projectActivityRepository.create(activity);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (activityResult) => activityResult,
        );
    }
}
