import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { RegisteredRecordRepository } from "@entity/data/repositories/registered-record.repository";
import { EditRegisteredRecord } from "@entity/domain/models/registered-record/edit-registered-record.model";
import { RegisteredRecord } from "@entity/domain/models/registered-record/registered-record.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditRegisteredRecordUseCase {
    constructor(
        @inject(RegisteredRecordRepository)
        private readonly registeredRecordRepository: RegisteredRecordRepository,
    ) {}

    async execute(
        registeredRecord: EditRegisteredRecord,
    ): Promise<Nullable<RegisteredRecord>> {
        const editResult =
            await this.registeredRecordRepository.edit(registeredRecord);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedRegisteredRecord) => editedRegisteredRecord,
        );
    }
}
