import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import {
    AuditoriesDto,
    AuditoryDto,
} from "@entity/data/dto/quality-framework/auditory/auditory.dto";
import { CreateAuditoryBody } from "@entity/data/dto/quality-framework/auditory/create-auditory.body";
import { EditAuditoryBody } from "@entity/data/dto/quality-framework/auditory/edit-auditory.body";
import { AuditoriesMapper } from "@entity/data/mappers/quality-framework/auditory/auditories.mapper";
import { CreateAuditoryMapper } from "@entity/data/mappers/quality-framework/auditory/create-auditory.mapper";
import { EditAuditoryMapper } from "@entity/data/mappers/quality-framework/auditory/edit-auditory.mapper";
import {
    Auditories,
    Auditory,
} from "@entity/domain/models/quality-framework/auditory/auditory.model";
import { CreateAuditory } from "@entity/domain/models/quality-framework/auditory/create-auditory.model";
import { EditAuditory } from "@entity/domain/models/quality-framework/auditory/edit-auditory.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const ENTITY_AUDITORIES_PATH = "/entities_entity_auditories/";

@injectable()
export class AuditoryDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(AuditoriesMapper)
        private readonly auditoriesMapper: AuditoriesMapper,
        @inject(CreateAuditoryMapper)
        private readonly createAuditoryMapper: CreateAuditoryMapper,
        @inject(EditAuditoryMapper)
        private readonly editAuditoryMapper: EditAuditoryMapper,
    ) {}

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, Auditories>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const auditoriesResult = await this.http.get<AuditoryDto>(
            ENTITY_AUDITORIES_PATH,
            {
                query,
            },
        );

        return auditoriesResult.map((response) =>
            this.auditoriesMapper.map(
                plainToClass(AuditoriesDto, response.data),
            ),
        );
    }

    async create(
        newAuditory: CreateAuditory,
    ): Promise<Either<ValidationError | FallbackError, Auditory>> {
        const auditoryBody =
            this.createAuditoryMapper.mapToCreateDto(newAuditory);

        const auditoryResult = await this.http.post<
            AuditoryDto,
            CreateAuditoryBody
        >(ENTITY_AUDITORIES_PATH, auditoryBody);

        return auditoryResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const auditory = this.auditoriesMapper.mapAuditory(
                    plainToClass(AuditoryDto, response.data),
                );

                if (!auditory) return Either.Left(new FallbackError());

                return Either.Right(auditory);
            });
    }

    async edit(
        editAuditory: EditAuditory,
    ): Promise<Either<ValidationError | FallbackError, Auditory>> {
        const editedAuditory = this.editAuditoryMapper.mapToDto(editAuditory);

        const editAuditoryResult = await this.http.put<
            AuditoryDto,
            EditAuditoryBody
        >(`${ENTITY_AUDITORIES_PATH}${editAuditory.id}/`, editedAuditory);

        return editAuditoryResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const auditory = this.auditoriesMapper.mapAuditory(
                    plainToClass(AuditoryDto, response.data),
                );

                if (!auditory) return Either.Left(new FallbackError());

                return Either.Right(auditory);
            });
    }

    async delete(auditoryId: number): Promise<Either<FallbackError, true>> {
        const deleteAuditory = await this.http.delete(
            `${ENTITY_AUDITORIES_PATH}${auditoryId}`,
        );

        return deleteAuditory
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
