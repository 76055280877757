import { Nullable } from "@core/domain/types/nullable.type";
import { IRPFTypeEnumDto } from "@entity/data/dto/irpf-type-enum.dto";
import { IRPFTypeEnum } from "@entity/domain/models/cost/irpf-type.model";
import { injectable } from "inversify";

@injectable()
export class IRPFTypeEnumMapper {
    map(irpfTypeEnumDto: IRPFTypeEnumDto): Nullable<IRPFTypeEnum> {
        switch (irpfTypeEnumDto) {
            case IRPFTypeEnumDto.ActModule:
                return IRPFTypeEnum.ActModule;
            case IRPFTypeEnumDto.Entrepeneur:
                return IRPFTypeEnum.Entrepeneur;
            case IRPFTypeEnumDto.Farm:
                return IRPFTypeEnum.Farm;
            case IRPFTypeEnumDto.Forest:
                return IRPFTypeEnum.Forest;
            case IRPFTypeEnumDto.PigFarm:
                return IRPFTypeEnum.PigFarm;
            case IRPFTypeEnumDto.Profesional:
                return IRPFTypeEnum.Profesional;
            default:
                return null;
        }
    }

    mapToDto(irpfTypeEnum: IRPFTypeEnum): IRPFTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (irpfTypeEnum) {
            case IRPFTypeEnum.ActModule:
                return IRPFTypeEnumDto.ActModule;
            case IRPFTypeEnum.Entrepeneur:
                return IRPFTypeEnumDto.Entrepeneur;
            case IRPFTypeEnum.Farm:
                return IRPFTypeEnumDto.Farm;
            case IRPFTypeEnum.Forest:
                return IRPFTypeEnumDto.Forest;
            case IRPFTypeEnum.PigFarm:
                return IRPFTypeEnumDto.PigFarm;
            case IRPFTypeEnum.Profesional:
                return IRPFTypeEnumDto.Profesional;
        }
    }
}
