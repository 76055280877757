import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { EditBudgetColumn } from "@project/domain/models/budget/budget-column/edit-budget-column.model";
import { BudgetColumn } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditBudgetColumnUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(column: EditBudgetColumn): Promise<Nullable<BudgetColumn>> {
        const editResult = await this.budgetRepository.editColumn(column);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }
        return editResult.fold(
            () => null,
            (editedColumn) => editedColumn,
        );
    }
}
