import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { CostRepository } from "@entity/data/repositories/cost.repository";
import { inject, injectable } from "inversify";
import {
    AdditionalExpense,
    AdditionalExpenseCreate,
} from "../../models/cost/cost.model";

@injectable()
export class UpdateAdditionalExpensesUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(
        expense: AdditionalExpenseCreate,
    ): Promise<Nullable<AdditionalExpense>> {
        const editResult =
            await this.costRepository.updateAdditionalExpense(expense);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedExpense) => editedExpense,
        );
    }
}
