import { EducationLevelEnum } from "@core/domain/models/education-level.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { CatalogueEnum } from "@project/domain/models/catalogue.model";
import { DateTime } from "luxon";

export class VolunteerContract {
    // eslint-disable-next-line max-params
    constructor(
        public readonly id: number,
        public insurance: boolean,
        public activity: string,
        public educationLevel: EducationLevelEnum,
        public availability: string,
        public preferences: CatalogueEnum[], // catalogueIds
        public startDate: DateTime,
        public employeeId: number,
        public weeklyWorkdayHours: number,
        public startDateInsurance?: Nullable<DateTime>,
        public otherPreferences?: Nullable<string>,
        public door?: Nullable<string>,
        public city?: Nullable<string>,
        public endDate?: Nullable<DateTime>,
        public endDateInsurance?: Nullable<DateTime>,
    ) {}
}

export class VolunteerContracts {
    constructor(
        public readonly volunteerContracts: VolunteerContract[],
        public readonly count: number,
    ) {}
}

export interface VolunteerContractSearchFilters {
    employee?: Undefinable<number>;
}
