import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { EditDepositMapper } from "../../data/mappers/deposit/edit-deposit.mapper";
import { DepositRepository } from "../../data/repositories/deposit.repository";
import { DepositFormValuesValidated } from "../../presentation/component/entity-form/deposits/form/deposit.form";

@injectable()
export class EditDepositUseCase {
    constructor(
        @inject(DepositRepository)
        private readonly depositRepository: DepositRepository,
        @inject(EditDepositMapper)
        private readonly editDepositMapper: EditDepositMapper,
    ) {}

    async execute(deposit: DepositFormValuesValidated): Promise<void> {
        const editResult = await this.depositRepository.edit(
            this.editDepositMapper.mapToDeposit(deposit),
        );

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }
    }
}
