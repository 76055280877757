import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { Nullish } from "@core/domain/types/nullish.type";
import { FinancialEntityTypeEnumDto } from "@project/data/dto/financial-entity/financial-entity-type.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsString,
} from "class-validator";

export class FinancialEntityDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsDateString()
    @IsNotEmpty()
    created!: string;

    @IsDateString()
    @IsNotEmpty()
    modified!: string;

    @IsBoolean()
    @IsNotEmpty()
    is_active!: boolean;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    description!: string;

    @IsEnum(FinancialEntityTypeEnumDto)
    @IsNotEmpty()
    type!: FinancialEntityTypeEnumDto;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class FinancialEntitiesDto extends PaginatedDto {
    @IsArray()
    @Type(() => FinancialEntityDto)
    results!: FinancialEntityDto[];
}

export type FinancialEntitiesQuery = PaginatedQuery & {
    entity?: number;
    has_budget?: boolean;
    search?: string;
    type?: FinancialEntityTypeEnumDto;
    is_active?: boolean;
    entities?: Nullish<string>;
};
