import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { Relationship } from "../models/relationship.model";

@injectable()
export class GetAllRelationshipsUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(): Promise<Relationship[]> {
        const relationShipsResult =
            await this.beneficiaryRepository.getAllRelationships();

        if (relationShipsResult.isLeft()) {
            ToastManagerStore.error(
                relationShipsResult.getLeftOrThrow().message,
            );
        }

        return relationShipsResult.fold(
            () => [],
            (relationShips) => relationShips,
        );
    }
}
