import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { ContractTypeEnum } from "../../../domain/models/expenses/contract-type.model";
import { ContractTypeEnumDto } from "../../dto/expenses/contract-type.dto";

@injectable()
export class ContractTypeEnumMapper {
    map(centerTypeEnumDto: ContractTypeEnumDto): Nullable<ContractTypeEnum> {
        switch (centerTypeEnumDto) {
            case ContractTypeEnumDto.FIXED_DISCONTINUED:
                return ContractTypeEnum.FIXED_DISCONTINUED;
            case ContractTypeEnumDto.INDEFINITE:
                return ContractTypeEnum.INDEFINITE;
            case ContractTypeEnumDto.TEMPORARY:
                return ContractTypeEnum.TEMPORARY;
            case ContractTypeEnumDto.TRAINING:
                return ContractTypeEnum.TRAINING;
            case ContractTypeEnumDto.OTHER:
                return ContractTypeEnum.OTHER;
            default:
                return null;
        }
    }

    mapToDto(centerTypeEnum: ContractTypeEnum): ContractTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (centerTypeEnum) {
            case ContractTypeEnum.FIXED_DISCONTINUED:
                return ContractTypeEnumDto.FIXED_DISCONTINUED;
            case ContractTypeEnum.INDEFINITE:
                return ContractTypeEnumDto.INDEFINITE;
            case ContractTypeEnum.TEMPORARY:
                return ContractTypeEnumDto.TEMPORARY;
            case ContractTypeEnum.TRAINING:
                return ContractTypeEnumDto.TRAINING;
            case ContractTypeEnum.OTHER:
                return ContractTypeEnumDto.OTHER;
        }
    }
}
