import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { CreateAccommodationExpenseBody } from "@project/data/dto/expenses/create-expense.body";
import { DietTypeEnumDto } from "@project/data/dto/expenses/diet-type.dto";
import { EditAccommodationExpenseBody } from "@project/data/dto/expenses/edit-expense.body";
import { ExpenseTypeEnumDto } from "@project/data/dto/expenses/expense-type.dto";
import { MaintenanceDto } from "@project/data/dto/expenses/maintenance.dto";
import { CreateAccommodationExpense } from "@project/domain/models/expenses/create-expense.model";
import { EditAccommodationExpense } from "@project/domain/models/expenses/edit-expense.model";
import { ExpenseTypeEnum } from "@project/domain/models/expenses/expense-type.model";
import { AccommodationExpense } from "@project/domain/models/expenses/expense.model";
import { ExpenseAccommodationFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/accommodation/expense-accommodation-form.component";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { ExpenseDto } from "../../dto/expenses/expenses.dto";

import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { DietTypeEnum } from "@project/domain/models/expenses/diet-type.model";
import { DietTypeEnumMapper } from "./diet-type-enum.mapper";

const maximumDecimalsNumber = 2;

@injectable()
export class AccommodationExpenseMapper {
    constructor(
        @inject(DietTypeEnumMapper)
        private readonly dietTypeEnumMapper: DietTypeEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
    ) {}
    map(
        expenseDto: ExpenseDto,
        AccommodationData: MaintenanceDto,
    ): Nullable<AccommodationExpense> {
        const errors = validateSync(AccommodationData);

        if (errors.length > 0) {
            console.error(errors);
            return null;
        }

        let dietType = null;
        let days = 0;
        if (AccommodationData.diet_type) {
            dietType = this.dietTypeEnumMapper.map(AccommodationData.diet_type);
            if (
                dietType === DietTypeEnum.ACCOMMODATION &&
                AccommodationData.days_accommodation
            ) {
                days = AccommodationData.days_accommodation;
            } else if (
                dietType === DietTypeEnum.MEALS &&
                AccommodationData.days
            ) {
                days = AccommodationData.days;
            }
        }

        const documents: IncDocument[] = [];
        if (expenseDto.documents_data) {
            expenseDto.documents_data.forEach((document) => {
                const documentMapped = this.documentMapper.map(document);
                if (documentMapped) {
                    documents.push(documentMapped);
                }
            });
        }

        return new AccommodationExpense(
            expenseDto.id,
            "",
            AccommodationData.full_name ?? "",
            AccommodationData.identification_document,
            AccommodationData.address,
            AccommodationData.position_function,
            AccommodationData.travel_destination,
            AccommodationData.departure_date
                ? DateTime.fromISO(AccommodationData.departure_date)
                : null,
            AccommodationData.return_date
                ? DateTime.fromISO(AccommodationData.return_date)
                : null,
            dietType,
            days,
            AccommodationData.daily_expense
                ? parseFloat(AccommodationData.daily_expense)
                : null,
            AccommodationData.total_expense
                ? parseFloat(AccommodationData.total_expense)
                : null,
            AccommodationData.project_allocation_percentage
                ? parseFloat(AccommodationData.project_allocation_percentage)
                : null,
            AccommodationData.amount_allocated_to_project
                ? parseFloat(AccommodationData.amount_allocated_to_project)
                : null,
            AccommodationData.payment_date
                ? DateTime.fromISO(AccommodationData.payment_date)
                : null,
            AccommodationData.travel_destination ?? "",
            documents,
            expenseDto.project,
        );
    }

    mapFromFormValues(
        createFormValues: ExpenseAccommodationFormValuesValidated,
        projectId: number,
    ): CreateAccommodationExpense {
        return new CreateAccommodationExpense(
            projectId,
            ExpenseTypeEnum.ACCOMMODATION_MEALS,
            createFormValues.name,
            createFormValues.dni,
            createFormValues.address,
            createFormValues.position,
            createFormValues.concept ? createFormValues.concept : "",
            createFormValues.startDate,
            createFormValues.endDate,
            createFormValues.diet,
            createFormValues.days ?? 0,
            createFormValues.expensePersonDay,
            createFormValues.totalExpense,
            createFormValues.percentageImputation,
            createFormValues.amountImputation,
            createFormValues.paymentDate,
            createFormValues.documents.map((document) => document.id),
            createFormValues.concept,
        );
    }

    mapFromFormEditValues(
        editFormValues: ExpenseAccommodationFormValuesValidated,
        expenseId: number,
        projectId: number,
    ): EditAccommodationExpense {
        return new EditAccommodationExpense(
            expenseId,
            projectId,
            ExpenseTypeEnum.ACCOMMODATION_MEALS,
            editFormValues.name,
            editFormValues.dni,
            editFormValues.address,
            editFormValues.position,
            editFormValues.concept ? editFormValues.concept : "",
            editFormValues.startDate,
            editFormValues.endDate,
            editFormValues.diet,
            editFormValues.days ?? 0,
            editFormValues.expensePersonDay,
            editFormValues.totalExpense,
            editFormValues.percentageImputation,
            editFormValues.amountImputation,
            editFormValues.paymentDate,
            editFormValues.documents.map((document) => document.id),
            editFormValues.concept,
        );
    }

    mapToDto(
        createExpense: CreateAccommodationExpense,
    ): CreateAccommodationExpenseBody {
        let dietType = null;
        if (createExpense.diet) {
            dietType = this.dietTypeEnumMapper.mapToDto(createExpense.diet);
        }

        const percentageImputation =
            createExpense.percentageImputation?.toFixed(maximumDecimalsNumber);

        return {
            project: createExpense.projectId,
            type_expense: ExpenseTypeEnumDto.ACCOMMODATION_MEALS,
            amount_allocated_to_project: createExpense.amountImputation ?? 0,
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: createExpense.documents,
            full_name: createExpense.name,
            identification_document: createExpense.dni ?? "",
            address: createExpense.address ?? "",
            position_function: createExpense.position ?? "",
            travel_destination: createExpense.travel ?? "",
            departure_date: createExpense.startDate?.toISODate() ?? "",
            return_date: createExpense.endDate?.toISODate() ?? "",
            diet_type: dietType ?? DietTypeEnumDto.NONE,
            days_accommodation: createExpense.days.toString(),
            daily_expense_accommodation:
                createExpense.expensePersonDay?.toString() ?? "",
            total_expense_accommodation:
                createExpense.totalExpense?.toString() ?? "",
            days: createExpense.days.toString(),
            daily_expense: createExpense.expensePersonDay?.toString() ?? "",
            total_expense: createExpense.totalExpense?.toString() ?? "",
            payment_date: createExpense.paymentDate?.toISODate() ?? "",
        };
    }

    mapToEditDto(
        editExpense: EditAccommodationExpense,
    ): EditAccommodationExpenseBody {
        let dietType = null;
        let daysAccommodation = "0";
        let days = "0";

        if (editExpense.diet) {
            dietType = this.dietTypeEnumMapper.mapToDto(editExpense.diet);
            if (dietType === DietTypeEnumDto.ACCOMMODATION) {
                daysAccommodation = editExpense.days.toString();
            } else if (dietType === DietTypeEnumDto.MEALS) {
                days = editExpense.days.toString();
            }
        }
        const percentageImputation = editExpense.percentageImputation?.toFixed(
            maximumDecimalsNumber,
        );

        return {
            id: editExpense.id,
            project: editExpense.projectId,
            type_expense: ExpenseTypeEnumDto.ACCOMMODATION_MEALS,
            amount_allocated_to_project: editExpense.amountImputation ?? 0,
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: editExpense.documents,
            full_name: editExpense.name,
            identification_document: editExpense.dni ?? "",
            address: editExpense.address ?? "",
            position_function: editExpense.position ?? "",
            travel_destination: editExpense.travel ?? "",
            departure_date: editExpense.startDate?.toISODate() ?? "",
            return_date: editExpense.endDate?.toISODate() ?? "",
            diet_type: dietType ?? DietTypeEnumDto.NONE,
            days_accommodation: daysAccommodation,
            daily_expense_accommodation:
                editExpense.expensePersonDay?.toString() ?? "",
            total_expense_accommodation:
                editExpense.totalExpense?.toString() ?? "",
            days,
            daily_expense: editExpense.expensePersonDay?.toString() ?? "",
            total_expense: editExpense.totalExpense?.toString() ?? "",
            payment_date: editExpense.paymentDate?.toISODate() ?? "",
        };
    }
}
