import { RelativesRepository } from "@beneficiary/data/repositories/relatives.repository";
import {
    Relative,
    RelativesSearchFilters,
} from "@beneficiary/domain/models/relatives/relative.model";
import { Pagination } from "@core/domain/models/pagination";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllRelativesUsecase {
    constructor(
        @inject(RelativesRepository)
        private readonly relativesRepository: RelativesRepository,
    ) {}

    async execute(filters?: RelativesSearchFilters): Promise<Relative[]> {
        const relativesResult = await this.relativesRepository.getAllBy(
            Pagination.NoPagination(),
            filters,
        );

        return relativesResult.fold(
            () => [],
            (relatives) => relatives,
        );
    }
}
