export enum MandateTerminationReasonEnum {
    RESIGNATION = "resignation",
    ELECTORAL_MANDATE_END = "electoral_mandate_end",
    SICKNESS = "sickness",
    INCOMPATIBILITY = "incompatibility",
    DEATH = "death",
    NO_RENOVATION = "no_renovation",
    OTHER = "other",
}

export class MandateTerminationReason {
    constructor(
        public readonly id: MandateTerminationReasonEnum,
        public readonly label: string,
    ) {}
}
