import { Row, RowProps } from "antd";
import { FC, useMemo } from "react";

/**
 * small 16px, medium 32px large 48px
 */
export type GutterSize = "none" | "small" | "medium" | "large";

const gutterSizeMap: Record<GutterSize, number> = Object.seal({
    none: 0,
    small: 16,
    medium: 32,
    large: 48,
});

export interface IncRowProps extends Omit<RowProps, "gutter"> {
    show?: boolean;
    gutter?: GutterSize | [GutterSize, GutterSize];
}

export const IncRow: FC<IncRowProps> = (props) => {
    const { show, gutter, ...restProps } = props;

    const antdGutter = useMemo<number | [number, number]>(() => {
        if (!gutter) return gutterSizeMap.none;
        if (typeof gutter === "string") return gutterSizeMap[gutter];

        return [gutterSizeMap[gutter[0]], gutterSizeMap[gutter[1]]];
    }, [gutter]);

    if (show === false) return null;

    return (
        <Row
            {...restProps}
            gutter={antdGutter}
        />
    );
};
