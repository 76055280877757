import { Nullable } from "@core/domain/types/nullable.type";
import { BudgetColumnTypeEnumDto } from "@project/data/dto/budget/column/budget-column-type.dto";
import { BudgetColumnTypeEnum } from "@project/domain/models/budget/budget-column/budget-column-type.model";
import { injectable } from "inversify";

@injectable()
export class BudgetColumnTypeEnumMapper {
    map(
        budgetColumnTypeEnum: BudgetColumnTypeEnumDto,
    ): Nullable<BudgetColumnTypeEnum> {
        switch (budgetColumnTypeEnum) {
            case BudgetColumnTypeEnumDto.amount:
                return BudgetColumnTypeEnum.amount;
            case BudgetColumnTypeEnumDto.percentage:
                return BudgetColumnTypeEnum.percentage;
            default:
                return null;
        }
    }

    mapToDto(
        budgetColumnTypeEnum: BudgetColumnTypeEnum,
    ): BudgetColumnTypeEnumDto {
        switch (budgetColumnTypeEnum) {
            case BudgetColumnTypeEnum.amount:
                return BudgetColumnTypeEnumDto.amount;
            case BudgetColumnTypeEnum.percentage:
                return BudgetColumnTypeEnumDto.percentage;
            default:
                throw new Error("BudgetColumnTypeEnum not found");
        }
    }
}
