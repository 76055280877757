import { Nullable } from "@core/domain/types/nullable.type";
import { PublicInterestDto } from "@entity/data/dto/public-interest/public-interest.dto";
import { PublicInterest } from "@entity/domain/models/public-interest/public-interest.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class PublicInterestMapper {
    map(publicInterestDto: PublicInterestDto): Nullable<PublicInterest> {
        const errors = validateSync(publicInterestDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        return new PublicInterest(
            publicInterestDto.id,
            publicInterestDto.organization_type,
            publicInterestDto.entity,
            DateTime.fromISO(publicInterestDto.date),
        );
    }

    mapToDto(publicInterest: PublicInterest): PublicInterestDto {
        const publicInterestDto: PublicInterestDto = {
            id: publicInterest.id,
            organization_type: publicInterest.organizationType,
            entity: publicInterest.entity,
            date: publicInterest.date.toISODate() ?? "",
        };

        return publicInterestDto;
    }
}
