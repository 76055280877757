import { OrderFieldsQuery, OrderQuery } from "@core/data/dto/order.query";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { OrderField } from "@core/domain/models/order.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { ProceedingDto } from "@proceeding/data/dto/proceeding.dto";
import { ProceedingSummary } from "@proceeding/domain/models/proceeding-summary.model";
import { Type } from "class-transformer";
import { IsArray } from "class-validator";
import { StatusEnumDto } from "./status-enum.dto";

export class ProceedingsDto extends PaginatedDto {
    @IsArray()
    @Type(() => ProceedingDto)
    results!: ProceedingDto[];
}

export const proceedingsOrderMap: Record<
    OrderField<ProceedingSummary>,
    Nullable<OrderFieldsQuery<ProceedingDto>>
> = {
    id: "id",
    nameProject: "project",
    status: "status",
    statusLabel: null,
    created: "created",
    isDerivation: null,
    beneficiaryData: null,
    entity: null,
    modifiedDate: "modified",
    derivationDate: "derivation_date",
    modified: "modified",
};

export type ProceedingsQuery = PaginatedQuery & {
    entity?: Undefinable<number>;
    beneficiary?: Undefinable<number>;
    project?: Undefinable<number>;
    activities?: Undefinable<string>;
    search?: Undefinable<string>;
    catalogue?: Undefinable<number>;
    is_derivation?: Undefinable<boolean>;
    to_derivation?: Undefinable<boolean>;
    has_derivation?: Undefinable<boolean>;
    status?: Undefinable<StatusEnumDto>;
    ordering?: OrderQuery<ProceedingDto>;
};
