import { IncomeRepository } from "@beneficiary/data/repositories/income.repository";
import {
    CreateIncome,
    Income,
} from "@beneficiary/domain/models/economic-data/income/income.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class CreateIncomeUseCase {
    constructor(
        @inject(IncomeRepository)
        private readonly incomesRepository: IncomeRepository,
    ) {}

    async execute(income: CreateIncome): Promise<Nullable<Income>> {
        const creationResult = await this.incomesRepository.create(income);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdIncome) => createdIncome,
        );
    }
}
