import { Undefinable } from "@core/domain/types/undefinable.type";
import { CreateSocialNetwork } from "./create-social-network.model";
import { SocialNetwork } from "./social-network.model";

export class EditSocialNetwork extends CreateSocialNetwork {
    constructor(
        public readonly id: SocialNetwork["id"],
        entityId: SocialNetwork["entityId"],
        name: SocialNetwork["name"],
        link: SocialNetwork["link"],
        followers?: Undefinable<SocialNetwork["followers"]>,
        postCount?: Undefinable<SocialNetwork["postCount"]>,
    ) {
        super(entityId, name, link, followers, postCount);
    }
}
