import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { AuthorityManagerRepository } from "@entity/data/repositories/authority-manager.repository";
import { AuthorityManager } from "@entity/domain/models/authority-manager/authority-manager.model";
import { CreateAuthorityManager } from "@entity/domain/models/authority-manager/create-authority-manager.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateAuthorityManagerUseCase {
    constructor(
        @inject(AuthorityManagerRepository)
        private readonly authorityManagerRepository: AuthorityManagerRepository,
    ) {}

    async execute(
        authorityManager: CreateAuthorityManager,
    ): Promise<Nullable<AuthorityManager>> {
        const creationResult =
            await this.authorityManagerRepository.create(authorityManager);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdAuthorityManager) => createdAuthorityManager,
        );
    }
}
