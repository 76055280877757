import { CostRepository } from "@entity/data/repositories/cost.repository";
import { CostIVAType } from "@entity/domain/models/cost/cost-iva-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllCostIvaTypesUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(): Promise<CostIVAType[]> {
        const costIvaTypesResult =
            await this.costRepository.getAllCostIvaTypes();

        return costIvaTypesResult.fold(
            () => [],
            (costIvaTypeResult) => costIvaTypeResult,
        );
    }
}
