import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class SocialNetworkSummaryDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    link!: string;

    @IsNumber()
    @IsOptional()
    followers!: Nullable<number>;

    @IsNumber()
    @IsOptional()
    num_posts!: Nullable<number>;
}

export class SocialNetworksSummaryDto extends PaginatedDto {
    @IsArray()
    @Type(() => SocialNetworkSummaryDto)
    results!: SocialNetworkSummaryDto[];
}

export type SocialNetworksSummaryQuery = PaginatedQuery & {
    search?: Nullish<string>;
};
