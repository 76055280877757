import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContractTerminationRepository } from "@entity/data/repositories/contract-termination.repository";
import { ContractTermination } from "@entity/domain/models/employee/contracts/termination/contract-termination.model";
import { CreateContractTermination } from "@entity/domain/models/employee/contracts/termination/create-contract-termination.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateContractTerminationUseCase {
    constructor(
        @inject(ContractTerminationRepository)
        private readonly contractTerminationRepository: ContractTerminationRepository,
    ) {}

    async execute(
        contractTermination: CreateContractTermination,
    ): Promise<Nullable<ContractTermination>> {
        const creationResult =
            await this.contractTerminationRepository.createContractTermination(
                contractTermination,
            );

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdContractTermination) => createdContractTermination,
        );
    }
}
