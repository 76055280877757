export enum CCAAEnum {
    Andalucia = "andalucia",
    Aragon = "aragon",
    Asturias = "asturias",
    IslasBaleares = "islas_baleares",
    Canarias = "canarias",
    Cantabria = "cantabria",
    CastillaLaMancha = "castilla_la_mancha",
    CastillaYLeon = "castilla_y_leon",
    Catalunya = "catalunya",
    Extremadura = "extremadura",
    Galicia = "galicia",
    Madrid = "madrid",
    Murcia = "murcia",
    Navarra = "navarra",
    PaisVasco = "pais_vasco",
    LaRioja = "la_rioja",
    ComunidadValenciana = "comunidad_valenciana",
    Ceuta = "ceuta",
    Melilla = "melilla",
}

export class CCAA {
    constructor(
        public readonly id: CCAAEnum,
        public readonly label: string,
    ) {}
}
