import { CreateBudgetCategoryGroup } from "@project/domain/models/budget/budget-category/groups/create-budget-category-group.model";

export class EditBudgetCategoryGroup extends CreateBudgetCategoryGroup {
    constructor(
        public readonly id: number,
        name: CreateBudgetCategoryGroup["name"],
        category: CreateBudgetCategoryGroup["category"],
    ) {
        super(name, category);
    }
}
