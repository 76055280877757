import { CreatePartnersNumberBody } from "@entity/data/dto/partners-number/create-partners-number.body";
import { CCAAEnumMapper } from "@entity/data/mappers/ccaa-enum.mapper";
import { CreatePartnersNumber } from "@entity/domain/models/partners-number/create-partners-number.model";
import { PartnersNumberFormValuesValidated } from "@entity/presentation/component/entity-form/authority-manager/partners-number-form/partners-number-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreatePartnersNumberMapper {
    constructor(
        @inject(CCAAEnumMapper)
        private readonly ccaaEnumMapper: CCAAEnumMapper,
    ) {}
    mapToCreateDto(
        partnersNumber: CreatePartnersNumber,
    ): CreatePartnersNumberBody {
        const registered_ccaa = this.ccaaEnumMapper.mapToDto(
            partnersNumber.registeredCcaa,
        );

        const partnersNumberDto: CreatePartnersNumberBody = {
            number_of_members: partnersNumber.numberOfMembers,
            registered_ccaa,
            entity: partnersNumber.entity,
        };

        return partnersNumberDto;
    }

    mapFromFormValues(
        entityId: number,
        partnersNumber: PartnersNumberFormValuesValidated,
    ): CreatePartnersNumber {
        const createPartnersNumber: CreatePartnersNumber =
            new CreatePartnersNumber(
                partnersNumber.numberOfMembers,
                partnersNumber.registeredCcaa,
                entityId,
            );

        return createPartnersNumber;
    }
}
