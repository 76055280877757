import { Nullable } from "@core/domain/types/nullable.type";
import { CategoryRowBudgetDto } from "@project/data/dto/budget.dto";
import { BudgetCategory } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class BudgetCategoryMapper {
    map(budgetCategoryDto: CategoryRowBudgetDto): Nullable<BudgetCategory> {
        const errors = validateSync(budgetCategoryDto);
        if (errors.length) {
            console.error(errors);

            return null;
        }

        return new BudgetCategory(
            budgetCategoryDto.id,
            budgetCategoryDto.name,
            budgetCategoryDto.budget,
        );
    }
}
