import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { MonthTypeEnum } from "../../../domain/models/expenses/month-type.model";
import { MonthTypeEnumDto } from "../../dto/expenses/month-type.dto";

@injectable()
export class MonthTypeEnumMapper {
    map(centerTypeEnumDto: MonthTypeEnumDto): Nullable<MonthTypeEnum> {
        switch (centerTypeEnumDto) {
            case MonthTypeEnumDto.JANUARY:
                return MonthTypeEnum.JANUARY;
            case MonthTypeEnumDto.FEBRUARY:
                return MonthTypeEnum.FEBRUARY;
            case MonthTypeEnumDto.MARCH:
                return MonthTypeEnum.MARCH;
            case MonthTypeEnumDto.APRIL:
                return MonthTypeEnum.APRIL;
            case MonthTypeEnumDto.MAY:
                return MonthTypeEnum.MAY;
            case MonthTypeEnumDto.JUNE:
                return MonthTypeEnum.JUNE;
            case MonthTypeEnumDto.JULY:
                return MonthTypeEnum.JULY;
            case MonthTypeEnumDto.AUGUST:
                return MonthTypeEnum.AUGUST;
            case MonthTypeEnumDto.SEPTEMBER:
                return MonthTypeEnum.SEPTEMBER;
            case MonthTypeEnumDto.OCTOBER:
                return MonthTypeEnum.OCTOBER;
            case MonthTypeEnumDto.NOVEMBER:
                return MonthTypeEnum.NOVEMBER;
            case MonthTypeEnumDto.DECEMBER:
                return MonthTypeEnum.DECEMBER;
            default:
                return null;
        }
    }

    mapToDto(centerTypeEnum: MonthTypeEnum): MonthTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (centerTypeEnum) {
            case MonthTypeEnum.JANUARY:
                return MonthTypeEnumDto.JANUARY;
            case MonthTypeEnum.FEBRUARY:
                return MonthTypeEnumDto.FEBRUARY;
            case MonthTypeEnum.MARCH:
                return MonthTypeEnumDto.MARCH;
            case MonthTypeEnum.APRIL:
                return MonthTypeEnumDto.APRIL;
            case MonthTypeEnum.MAY:
                return MonthTypeEnumDto.MAY;
            case MonthTypeEnum.JUNE:
                return MonthTypeEnumDto.JUNE;
            case MonthTypeEnum.JULY:
                return MonthTypeEnumDto.JULY;
            case MonthTypeEnum.AUGUST:
                return MonthTypeEnumDto.AUGUST;
            case MonthTypeEnum.SEPTEMBER:
                return MonthTypeEnumDto.SEPTEMBER;
            case MonthTypeEnum.OCTOBER:
                return MonthTypeEnumDto.OCTOBER;
            case MonthTypeEnum.NOVEMBER:
                return MonthTypeEnumDto.NOVEMBER;
            case MonthTypeEnum.DECEMBER:
                return MonthTypeEnumDto.DECEMBER;
            case MonthTypeEnum.NONE:
                return MonthTypeEnumDto.NONE;
        }
    }
}
