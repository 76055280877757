import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { AuditoryRepository } from "@entity/data/repositories/auditory.repository";
import { Auditory } from "@entity/domain/models/quality-framework/auditory/auditory.model";
import { EditAuditory } from "@entity/domain/models/quality-framework/auditory/edit-auditory.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditAuditoryUseCase {
    constructor(
        @inject(AuditoryRepository)
        private readonly auditoryRepository: AuditoryRepository,
    ) {}

    async execute(auditory: EditAuditory): Promise<Nullable<Auditory>> {
        const editResult = await this.auditoryRepository.edit(auditory);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedAuditory) => editedAuditory,
        );
    }
}
