import { GlobalEntityDatasource } from "@entity/data/datasources/global-entity.datasource";
import {
    GlobalEntity,
    GlobalEntitySearchFilters,
} from "@entity/domain/models/global-entity.model";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

@injectable()
export class GlobalEntityRepository {
    @observable
    private __globalEntities: GlobalEntity[] = [];

    constructor(
        @inject(GlobalEntityDatasource)
        private readonly globalEntityDatasource: GlobalEntityDatasource,
    ) {
        makeObservable(this);
    }

    async didMount(): Promise<void> {
        await this.initViewData();
    }

    private async initViewData(): Promise<void> {
        await this.getAll();
    }

    @computed
    get globalEntities(): GlobalEntity[] {
        return this.__globalEntities;
    }

    @action
    async getAll(filters?: GlobalEntitySearchFilters): Promise<void> {
        const globalEntitiesResponse =
            await this.globalEntityDatasource.fetchAll(filters);
        const globalEntities = globalEntitiesResponse.getOrThrow();

        runInAction(() => {
            this.__globalEntities = globalEntities;
        });
    }
}
