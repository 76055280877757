import { Pagination } from "@core/domain/models/pagination";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { UserRole, UserRoleEnum } from "@user/domain/models/user-role.model";
import type { UserSearchFilters } from "@user/domain/models/user.model";
import { UsersSummary } from "@user/domain/models/users-summary.model";
import { GetAllUserRolesUseCase } from "@user/domain/usecases/get-all-user-roles.usecase";
import { SearchUsersByUseCase } from "@user/domain/usecases/search-users-by.usecase";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

export interface UserListTable {
    id: number;
    email: string;
    lastLogin: Undefinable<string>;
    roleLabel: string;
    roleEnum: UserRoleEnum;
    relatedEmployee?: Undefinable<string>;
}

interface UsersListTable {
    users: UserListTable[];
    count: number;
}

@injectable()
export class UserListPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    private _users: UsersSummary = new UsersSummary([], 0);

    @computed
    get usersTable(): UsersListTable {
        return {
            count: this._users.count,
            users: this._users.usersSummary.map((userSummary) => ({
                id: userSummary.id,
                email: userSummary.email,
                lastLogin:
                    userSummary.lastLogin?.toLocaleString(
                        DateTime.DATETIME_SHORT,
                    ) ?? undefined,
                roleLabel: userSummary.roleLabel,
                roleEnum: userSummary.roleEnum,
                relatedEmployee: userSummary.relatedEmployee
                    ? `${userSummary.relatedEmployee.name} ${userSummary.relatedEmployee.surname} - ${userSummary.relatedEmployee.documentNumber}`
                    : undefined,
            })),
        };
    }

    @observable
    userRoles: UserRole[] = [];

    @observable
    globalEntities: GlobalEntity[] = [];

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: UserSearchFilters = {
        name: "",
        userRole: undefined,
        active: true,
    };

    @observable
    filters: UserSearchFilters = this.initialFiltersValue;

    constructor(
        @inject(SearchUsersByUseCase)
        private readonly searchUsersByUseCase: SearchUsersByUseCase,
        @inject(GetAllUserRolesUseCase)
        private readonly getAllUserRolesUseCase: GetAllUserRolesUseCase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.searchUsersBy(this.filters, this.pagination),
            this.getAllUserRoles(),
            this.getAllGlobalEntities(),
        ]);

        this.initialLoading = false;
    }

    @action
    setFilters(filters: UserSearchFilters): void {
        this.filters = filters;
        this.pagination.reset();

        this.searchUsersBy(this.filters, this.pagination);
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.searchUsersBy(this.filters, this.pagination);
    }

    async getAllUserRoles(): Promise<void> {
        const userRoles = await this.getAllUserRolesUseCase.execute();

        runInAction(() => {
            this.userRoles = userRoles;
        });
    }

    async getAllGlobalEntities(): Promise<void> {
        const globalEntities = await this.getAllGlobalEntitiesUseCase.execute();

        runInAction(() => {
            this.globalEntities = globalEntities;
        });
    }

    async searchUsersBy(
        filters: UserSearchFilters,
        pagination: Pagination,
    ): Promise<void> {
        const users = await this.searchUsersByUseCase.execute(
            pagination,
            filters,
        );
        runInAction(() => {
            this._users = users;
        });
    }
}
