import { TherapyTypeDto } from "@beneficiary/data/dto/therapy-type-enum.dto";
import { TherapyType } from "@beneficiary/domain/models/therapy-type.enum";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { TherapyTypeEnumMapper } from "./therapy-type-enum.mapper";

@injectable()
export class TherapyTypesMapper {
    constructor(
        @inject(TherapyTypeEnumMapper)
        private readonly therapyTypeEnumMapper: TherapyTypeEnumMapper,
    ) {}
    map(therapyTypeDto: TherapyTypeDto): Nullable<TherapyType> {
        const errors = validateSync(TherapyTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const therapyTypeId = this.therapyTypeEnumMapper.map(
            therapyTypeDto.key,
        );

        if (!therapyTypeId) return null;

        return new TherapyType(therapyTypeId, therapyTypeDto.value);
    }
}
