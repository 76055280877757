import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { MaterialMapper } from "@entity/data/mappers/material/material.mapper";
import { MaterialRepository } from "@entity/data/repositories/material.repository";
import { MaterialFormValuesValidated } from "@entity/presentation/component/material-form/material-form";
import { inject, injectable } from "inversify";
import { CreateMaterial, Material } from "../../models/material/material.model";

@injectable()
export class CreateMaterialUseCase {
    constructor(
        @inject(MaterialRepository)
        private readonly materialRepository: MaterialRepository,
        @inject(MaterialMapper)
        private readonly materialMapper: MaterialMapper,
    ) {}

    async execute(
        materialToCreate: MaterialFormValuesValidated,
    ): Promise<Nullable<Material>> {
        const material = new CreateMaterial(
            materialToCreate.name,
            materialToCreate.description,
            materialToCreate.typeId,
            materialToCreate.typeLabel,
            materialToCreate.subtypeId,
            materialToCreate.subtypeLabel,
            materialToCreate.othersType,
            materialToCreate.metricUnit,
            materialToCreate.othersTypeUnit,
            materialToCreate.count,
            materialToCreate.anualCost,
            materialToCreate.entity,
        );
        const editResult = await this.materialRepository.create(material);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (edited) => edited,
        );
    }
}
