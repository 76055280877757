import { Nullable } from "@core/domain/types/nullable.type";
import { IdentificationTypeEnum } from "@entity/domain/models/identification-type.model";
import { injectable } from "inversify";
import { IdentificationTypeEnumDto } from "../../dto/identification-type.dto";

@injectable()
export class IdentificationEnumMapper {
    map(
        identificationTypeEnumDto: IdentificationTypeEnumDto,
    ): Nullable<IdentificationTypeEnum> {
        switch (identificationTypeEnumDto) {
            case IdentificationTypeEnumDto.cif:
                return IdentificationTypeEnum.cif;
            case IdentificationTypeEnumDto.dni:
                return IdentificationTypeEnum.dni;
            case IdentificationTypeEnumDto.nie:
                return IdentificationTypeEnum.nie;
            case IdentificationTypeEnumDto.nif:
                return IdentificationTypeEnum.nif;
            case IdentificationTypeEnumDto.Passport:
                return IdentificationTypeEnum.passport;
            default:
                return null;
        }
    }

    mapToDto(
        identificationTypeEnum: IdentificationTypeEnum,
    ): IdentificationTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (identificationTypeEnum) {
            case IdentificationTypeEnum.cif:
                return IdentificationTypeEnumDto.cif;
            case IdentificationTypeEnum.dni:
                return IdentificationTypeEnumDto.dni;
            case IdentificationTypeEnum.nie:
                return IdentificationTypeEnumDto.nie;
            case IdentificationTypeEnum.nif:
                return IdentificationTypeEnumDto.nif;
            case IdentificationTypeEnum.passport:
                return IdentificationTypeEnumDto.Passport;
        }
    }
}
