import { Pagination } from "@core/domain/models/pagination";
import { ProjectEmployeeRepository } from "@project/data/repositories/project-employee.repository";
import {
    ProjectEmployees,
    ProjectEmployeesSearchFilters,
} from "@project/domain/models/project-employees.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetProjectEmployeesByUseCase {
    constructor(
        @inject(ProjectEmployeeRepository)
        private readonly projectEmployeeRepository: ProjectEmployeeRepository,
    ) {}

    async execute(
        pagination?: Pagination,
        filters?: ProjectEmployeesSearchFilters,
    ): Promise<ProjectEmployees> {
        const projectEmployeesResult =
            await this.projectEmployeeRepository.getBy(pagination, filters);

        return projectEmployeesResult.fold(
            () => new ProjectEmployees([], 0),
            (projectEmployees) => projectEmployees,
        );
    }
}
