import { Nullable } from "@core/domain/types/nullable.type";
import { GlobalProject } from "@project/domain/models/global-project.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { GlobalProjectDto } from "../dto/global-project.dto";

@injectable()
export class GlobalProjectMapper {
    map(globalProjectDto: GlobalProjectDto): Nullable<GlobalProject> {
        const errors = validateSync(globalProjectDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        return new GlobalProject(globalProjectDto.id, globalProjectDto.name);
    }
}
