import { Nullable } from "@core/domain/types/nullable.type";
import { IVAType } from "@entity/domain/models/cost/iva-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { IVATypeDto } from "../../dto/iva-type.dto";
import { IVAEnumMapper } from "./iva-enum.mapper";

@injectable()
export class IvaMapper {
    constructor(
        @inject(IVAEnumMapper)
        private readonly ivaEnumMapper: IVAEnumMapper,
    ) {}

    map(ivaTypeDto: IVATypeDto): Nullable<IVAType> {
        const errors = validateSync(ivaTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.ivaEnumMapper.map(ivaTypeDto.key);
        if (!id) return null;

        return new IVAType(id, ivaTypeDto.value);
    }
}
