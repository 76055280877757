import { DateTime } from "luxon";

export class PublicInterest {
    constructor(
        public id: number,
        public organizationType: string,
        public entity: number,
        public date: DateTime,
    ) {}
}

export class PublicInterests {
    constructor(
        public readonly publicInterests: PublicInterest[],
        public readonly count: number,
    ) {}
}
