import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { inject, injectable } from "inversify";
import { FinancialEntityType } from "../../models/financial-entity/financial-entity-type";

@injectable()
export class GetAllFinancialEntityTypesUsecase {
    constructor(
        @inject(FinancialEntityRepository)
        private readonly financialEntityRepository: FinancialEntityRepository,
    ) {}

    async execute(): Promise<FinancialEntityType[]> {
        const financialEntityTypesResult =
            await this.financialEntityRepository.getFinancialEntitiesTypes();

        return financialEntityTypesResult.getOrElse([]);
    }
}
