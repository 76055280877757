import { LocalRepository } from "@entity/data/repositories/local.repository";
import { StatusType } from "@entity/domain/models/locals/status.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllStatusTypesUseCase {
    constructor(
        @inject(LocalRepository)
        private readonly localRepository: LocalRepository,
    ) {}

    async execute(): Promise<StatusType[]> {
        const getAllStatusTypesResult =
            await this.localRepository.getAllStatusTypes();

        return getAllStatusTypesResult.fold(
            () => [],
            (statusTypes) => statusTypes,
        );
    }
}
