import { Nullable } from "@core/domain/types/nullable.type";
import { BudgetTypeEnumDto } from "@project/data/dto/financial-entity/budget-type.dto";
import { BudgetTypeEnum } from "@project/domain/models/budget/financial-entity-budget.model";
import { injectable } from "inversify";

@injectable()
export class BudgetTypeEnumMapper {
    map(budgetTypeEnumDto: BudgetTypeEnumDto): Nullable<BudgetTypeEnum> {
        switch (budgetTypeEnumDto) {
            case BudgetTypeEnumDto.CAM:
                return BudgetTypeEnum.CAM;
            case BudgetTypeEnumDto.GENERAL:
                return BudgetTypeEnum.GENERAL;
            case BudgetTypeEnumDto.IRPF:
                return BudgetTypeEnum.IRPF;

            default:
                return null;
        }
    }

    mapToDto(budgetTypeEnum: BudgetTypeEnum): BudgetTypeEnumDto {
        switch (budgetTypeEnum) {
            case BudgetTypeEnum.CAM:
                return BudgetTypeEnumDto.CAM;
            case BudgetTypeEnum.GENERAL:
                return BudgetTypeEnumDto.GENERAL;
            case BudgetTypeEnum.IRPF:
                return BudgetTypeEnumDto.IRPF;
        }
    }
}
