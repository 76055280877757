import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateRegconRegisterMapper } from "@entity/data/mappers/quality-framework/regcon-register/create-regcon-register.mapper";
import { EditRegconRegisterMapper } from "@entity/data/mappers/quality-framework/regcon-register/edit-regcon-register.mapper";
import { CCAA } from "@entity/domain/models/ccaa.model";
import { RegconRegister } from "@entity/domain/models/quality-framework/regcon-register/regcon-register.model";
import { GetAllCCAAUseCase } from "@entity/domain/usecases/get-all-ccaa.usecase";
import { CreateRegconRegisterUseCase } from "@entity/domain/usecases/quality-framework/regcon-register/create-regcon-register.usecase";
import { DeleteRegconRegisterUseCase } from "@entity/domain/usecases/quality-framework/regcon-register/delete-regcon-register.usecase";
import { EditRegconRegisterUseCase } from "@entity/domain/usecases/quality-framework/regcon-register/edit-regcon-register.usecase";
import { GetAllRegconRegistersUsecase } from "@entity/domain/usecases/quality-framework/regcon-register/get-all-regcon-registers.usecase";
import { RegconRegisterFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/regcon-register/form/regcon-register-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class RegconRegisterViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    regconRegisters: RegconRegister[] = [];

    @observable
    regconRegisterToEdit: Undefinable<RegconRegister> = undefined;

    @observable
    ccaa: CCAA[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddRegconRegisterModal: boolean = false;

    @observable
    showEditRegconRegisterModal: boolean = false;

    constructor(
        @inject(GetAllRegconRegistersUsecase)
        private readonly getAllRegconRegistersUsecase: GetAllRegconRegistersUsecase,
        @inject(CreateRegconRegisterUseCase)
        private readonly createRegconRegisterUseCase: CreateRegconRegisterUseCase,
        @inject(EditRegconRegisterUseCase)
        private readonly editRegconRegisterUseCase: EditRegconRegisterUseCase,
        @inject(DeleteRegconRegisterUseCase)
        private readonly deleteRegconRegisterUseCase: DeleteRegconRegisterUseCase,
        @inject(CreateRegconRegisterMapper)
        private readonly createRegconRegisterMapper: CreateRegconRegisterMapper,
        @inject(EditRegconRegisterMapper)
        private readonly editRegconRegisterMapper: EditRegconRegisterMapper,
        @inject(GetAllCCAAUseCase)
        private readonly getAllCCAAUseCase: GetAllCCAAUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getRegconRegisters(), this.getCCAA()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getRegconRegisters(): Promise<void> {
        const regconRegisters =
            await this.getAllRegconRegistersUsecase.execute();
        runInAction(() => {
            this.regconRegisters = regconRegisters;
        });
    }

    @action
    setShowAddRegconRegisterModal(show: boolean): void {
        this.showAddRegconRegisterModal = show;
    }

    @action
    closeEditRegconRegisterModal(): void {
        this.showEditRegconRegisterModal = false;
    }

    @action
    openEditRegconRegisterModal(
        regconRegister: Undefinable<RegconRegister>,
    ): void {
        this.regconRegisterToEdit = regconRegister;
        this.showEditRegconRegisterModal = true;
    }

    async addRegconRegister(
        entityId: Undefinable<number>,
        values: RegconRegisterFormValuesValidated,
    ): Promise<Nullable<RegconRegister>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createRegconRegisterResult =
            await this.createRegconRegisterUseCase.execute(
                this.createRegconRegisterMapper.mapFromFormValues(
                    entityId,
                    values,
                ),
            );

        if (createRegconRegisterResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createRegconRegisterResult;
    }

    async editRegconRegister(
        values: RegconRegisterFormValuesValidated,
    ): Promise<void> {
        if (!this.regconRegisterToEdit?.id || !this.regconRegisterToEdit.entity)
            throw Error("Missing regconRegister");

        LoadLayoutStore.start();

        const editRegconRegisterResult =
            await this.editRegconRegisterUseCase.execute(
                this.editRegconRegisterMapper.mapFromModal(
                    this.regconRegisterToEdit.id,
                    this.regconRegisterToEdit.entity,
                    values,
                ),
            );

        if (editRegconRegisterResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadRegconRegisters(): Promise<void> {
        await this.getRegconRegisters();
    }

    async deleteRegconRegister(regconRegisterId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteRegconRegisterUseCase.execute(regconRegisterId);
        LoadLayoutStore.finish();
    }

    async getCCAA(): Promise<void> {
        const ccaa = await this.getAllCCAAUseCase.execute();

        runInAction(() => {
            this.ccaa = ccaa;
        });
    }
}
