import { CreatePublicInterest } from "@entity/domain/models/public-interest/create-public-interest.model";
import { PublicInterest } from "@entity/domain/models/public-interest/public-interest.model";

export class EditPublicInterest extends CreatePublicInterest {
    constructor(
        public readonly id: PublicInterest["id"],
        organizationType: PublicInterest["organizationType"],
        entity: PublicInterest["entity"],
        date: PublicInterest["date"],
    ) {
        super(organizationType, entity, date);
    }
}
