import { DocumentDto } from "@core/data/dto/document.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { CostIVATypeEnumDto } from "@entity/data/dto/cost-iva-type.dto";
import { ServiceProvidedEnumDto } from "@entity/data/dto/supplier/service-provided-enum.dto";
import { IdentificationTypeEnum } from "@entity/domain/models/identification-type.model";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { IdentificationTypeEnumDto } from "../identification-type.dto";
import { IRPFTypeEnumDto } from "../irpf-type-enum.dto";
import { CostTypeEnumDto } from "./cost-type.dto";
import { PaymentMethodTypeEnumDto } from "./payment-method-type.dto";

export class AdditionalCostData {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsNumber()
    @IsNotEmpty()
    project!: number;

    @IsNumber()
    @IsNotEmpty()
    invoice!: number;

    @IsNumber()
    @IsNotEmpty()
    amount!: number;
}

export class CostDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    number!: string;

    @IsString()
    @IsNotEmpty()
    concept!: string;

    @IsString()
    @IsNotEmpty()
    issuer_name!: string;

    @IsEnum(IdentificationTypeEnum)
    @IsNotEmpty()
    issuer_identification_type!: IdentificationTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    issuer_identification_number!: string;

    @IsEnum(CostTypeEnumDto)
    @IsNotEmpty()
    type!: CostTypeEnumDto;

    @IsString()
    @IsOptional()
    others_type!: Nullable<string>;

    @IsDateString()
    @IsNotEmpty()
    issue_date!: string;

    @IsDateString()
    @IsNotEmpty()
    payment_date!: string;

    @IsEnum(PaymentMethodTypeEnumDto)
    @IsNotEmpty()
    payment_method!: PaymentMethodTypeEnumDto;

    @IsString()
    @IsOptional()
    other_payment_method!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    base!: number;

    @IsEnum(CostIVATypeEnumDto)
    @IsNotEmpty()
    iva!: CostIVATypeEnumDto;

    @IsString()
    @IsOptional()
    iva_other!: string;

    @IsNumber()
    @IsNotEmpty()
    total!: number;

    @IsEnum(IRPFTypeEnumDto)
    @IsOptional()
    irpf_type!: "" | IRPFTypeEnumDto;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;

    @IsNumber()
    @IsOptional()
    document!: Nullable<number>;

    @IsOptional()
    @Type(() => DocumentDto)
    document_data!: Nullable<DocumentDto>;

    @IsArray()
    @IsOptional()
    @Type(() => AdditionalCostData)
    additional_expenses_data!: Nullable<AdditionalCostData[]>;

    @IsEnum(ServiceProvidedEnumDto, { each: true })
    @IsOptional()
    categories!: Nullable<ServiceProvidedEnumDto[]>;

    @IsString()
    @IsOptional()
    other_category!: Nullable<string>;
}
