import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { LocalRepository } from "@entity/data/repositories/local.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteLocalUseCase {
    constructor(
        @inject(LocalRepository)
        private readonly localRepository: LocalRepository,
    ) {}

    async execute(costId: number): Promise<boolean> {
        const result = await this.localRepository.delete(costId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
