import { Pagination } from "@core/domain/models/pagination";
import { RegconRegisterRepository } from "@entity/data/repositories/regcon-register.repository";
import { RegconRegister } from "@entity/domain/models/quality-framework/regcon-register/regcon-register.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllRegconRegistersUsecase {
    constructor(
        @inject(RegconRegisterRepository)
        private readonly regconRegisterRepository: RegconRegisterRepository,
    ) {}

    async execute(): Promise<RegconRegister[]> {
        const regconRegisterResults =
            await this.regconRegisterRepository.getAll(
                Pagination.NoPagination(),
            );

        return regconRegisterResults.fold(
            () => [],
            (regconRegisters) => regconRegisters.regconRegisters,
        );
    }
}
