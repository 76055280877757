import { ProjectEmployeeDedicationRepository } from "@project/data/repositories/project-employee-dedication.repository";
import {
    ProjectEmployeeDedication,
    ProjectEmployeeDedicationsSearchFilters,
} from "@project/domain/models/project-employee-dedications.model";
import { inject, injectable } from "inversify";

@injectable()
// eslint-disable-next-line id-length
export class GetAllProjectEmployeeDedicationsByUseCase {
    constructor(
        @inject(ProjectEmployeeDedicationRepository)
        private readonly projectEmployeeDedicationRepository: ProjectEmployeeDedicationRepository,
    ) {}

    async execute(
        filters?: ProjectEmployeeDedicationsSearchFilters,
    ): Promise<ProjectEmployeeDedication[]> {
        const projectEmployeeDedicationsResult =
            await this.projectEmployeeDedicationRepository.getAllBy(filters);

        return projectEmployeeDedicationsResult.getOrElse([]);
    }
}
