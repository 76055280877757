import { Nullable } from "@core/domain/types/nullable.type";
import { CategoryGroupBudgetDto } from "@project/data/dto/budget.dto";
import { BudgetCategoryGroup } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class BudgetCategoryGroupMapper {
    map(
        budgetCategoryGroupDto: CategoryGroupBudgetDto,
    ): Nullable<BudgetCategoryGroup> {
        const errors = validateSync(budgetCategoryGroupDto);
        if (errors.length) {
            console.error(errors);

            return null;
        }

        return new BudgetCategoryGroup(
            budgetCategoryGroupDto.id,
            budgetCategoryGroupDto.name,
            budgetCategoryGroupDto.category,
        );
    }
}
