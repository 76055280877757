import {
    CurrentLanguageDto,
    UpdateCurrentLanguageBody,
} from "@beneficiary/data/dto/update-current-language.dto";
import { UpdateCurrentLanguageMapper } from "@beneficiary/data/mappers/update-current-language.mapper";
import { coreTypes } from "@core/core-types.di";
import { LanguageDto } from "@core/data/dto/language.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { LanguageMapper } from "@core/data/mappers/language.mapper";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import {
    Language,
    LanguageIsoCode,
    LanguageIsoCodeEnum,
} from "@core/domain/models/language.model";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class LanguageRemoteDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(LanguageMapper)
        private readonly languageMapper: LanguageMapper,
        @inject(UpdateCurrentLanguageMapper)
        private readonly updateCurrentLanguageMapper: UpdateCurrentLanguageMapper,
    ) {}

    async fetchAll(): Promise<Either<FallbackError, Language[]>> {
        const languagesResult =
            await this.http.get<LanguageDto[]>("/users/languages/");

        return languagesResult
            .mapLeft(() => new FallbackError())
            .map((languagesResponse) =>
                languagesResponse.data.mapNotNull((languageDto) =>
                    this.languageMapper.map(
                        plainToClass(LanguageDto, languageDto),
                    ),
                ),
            );
    }

    async update(
        language: LanguageIsoCodeEnum,
    ): Promise<Either<ValidationError | FallbackError, LanguageIsoCode>> {
        const languageDto = this.updateCurrentLanguageMapper.mapToDto(language);
        const updateCurrentResult = await this.http.post<
            CurrentLanguageDto,
            UpdateCurrentLanguageBody
        >("/users/set_language/", languageDto);

        return updateCurrentResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const updatedLanguage = this.languageMapper.mapToIsoCode(
                    plainToClass(CurrentLanguageDto, response.data),
                );

                if (!updatedLanguage) return Either.Left(new FallbackError());

                return Either.Right(updatedLanguage);
            });
    }
}
