import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateEntityMapper } from "@entity/data/mappers/create-entity.mapper";
import {
    Employee,
    EmployeeSearchFilters,
} from "@entity/domain/models/employee/employee.model";
import { Entity } from "@entity/domain/models/entity.model";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { GetAllEmployeesByUseCase } from "@entity/domain/usecases/employee/get-all-employees-by.usecase";
import { CreateEntityUseCase } from "@entity/domain/usecases/entity/create-entity.usecase";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { UserRole, UserRoleEnum } from "@user/domain/models/user-role.model";
import { GetAllUserRolesUseCase } from "@user/domain/usecases/get-all-user-roles.usecase";
import { ToggleActivationUserUseCase } from "@user/domain/usecases/toggle-activation-user.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

@injectable()
export class UserFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    userRoles: UserRole[] = [];

    @observable
    globalEntities: GlobalEntity[] = [];

    @observable
    employees: Employee[] = [];

    newEntityName: string = "";

    @observable
    newEntityModal: boolean = false;

    constructor(
        @inject(CreateEntityMapper)
        private readonly createEntityMapper: CreateEntityMapper,
        @inject(CreateEntityUseCase)
        private readonly createEntityUseCase: CreateEntityUseCase,
        @inject(GetAllEmployeesByUseCase)
        private readonly getAllEmployeesByUseCase: GetAllEmployeesByUseCase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
        @inject(GetAllUserRolesUseCase)
        private readonly getAllUserRolesUseCase: GetAllUserRolesUseCase,
        @inject(ToggleActivationUserUseCase)
        private readonly toggleActivationUseCase: ToggleActivationUserUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @action
    openNewEntityModal(entityName: string): void {
        this.newEntityName = entityName;
        this.newEntityModal = true;
    }

    @action
    closeNewEntityModal(): void {
        this.newEntityModal = false;
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(
            Promise.all([
                this.getAllGlobalEntities(),
                this.getAllEmployeesByEntity(),
            ]),
        );

        this.initialLoading = false;
    }

    async getAllUserRoles(hideSuperAdmin: boolean): Promise<void> {
        let userRoles = await this.getAllUserRolesUseCase.execute();

        if (hideSuperAdmin) {
            userRoles = userRoles.filter(
                (userRole) => userRole.id !== UserRoleEnum.SuperAdmin,
            );
        }

        runInAction(() => {
            this.userRoles = userRoles;
        });
    }

    // Pasar por params el Id de la entidad y actualizar el UseCase
    async getAllGlobalEntities(): Promise<void> {
        const globalEntities = await this.getAllGlobalEntitiesUseCase.execute();

        runInAction(() => {
            this.globalEntities = globalEntities;
        });
    }

    async getAllEmployeesByEntity(entityId?: number): Promise<void> {
        const filters: EmployeeSearchFilters = {};
        if (entityId) {
            filters.entityId = entityId;
        }
        const employees = await this.getAllEmployeesByUseCase.execute(filters);

        runInAction(() => {
            this.employees = employees;
        });
    }

    async createEntity(): Promise<Nullable<Entity>> {
        LoadLayoutStore.start();

        const createEntityResult = await this.createEntityUseCase.execute(
            this.createEntityMapper.mapFromName(this.newEntityName),
        );

        if (createEntityResult) {
            ToastManagerStore.success();
            this.closeNewEntityModal();
            await this.getAllGlobalEntities();
        }

        LoadLayoutStore.finish();

        return createEntityResult;
    }

    async toggleActivation(userId: number): Promise<void> {
        LoadLayoutStore.start();

        const result = await this.toggleActivationUseCase.execute(userId);

        if (result) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }
}
