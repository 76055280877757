// todo: Create mapper and ask if it would make sense to move it to core
// as there is another "statusEnum" on project but this one has an extra property "design"
export enum StatusEnum {
    Requested = "1",
    Authorized = "2",
    Management = "3",
    FavorableResolution = "4",
    DesfavorableResolution = "5",
}

export class Status {
    constructor(
        public readonly id: StatusEnum,
        public readonly label: string,
    ) {}
}
