import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { UserRepository } from "../../data/repositories/user.repository";

@injectable()
export class ToggleActivationUserUseCase {
    constructor(
        @inject(UserRepository)
        private readonly userRepository: UserRepository,
    ) {}

    async execute(userId: number): Promise<boolean> {
        const userResult = await this.userRepository.toggleActivation(userId);

        if (userResult.isLeft()) {
            ToastManagerStore.error(userResult.getLeftOrThrow().message);
            return false;
        }

        return true;
    }
}
