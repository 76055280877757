import { PolicyProtocolDto } from "@entity/data/dto/quality-framework/policy-protocol/policy-protocol.dto";
import { PolicyProtocolTypeEnumMapper } from "@entity/data/mappers/policy-protocol-type-enum.mapper";
import { PolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/policy-protocol.model";
import { inject, injectable } from "inversify";

@injectable()
export class PolicyProtocolMapper {
    constructor(
        @inject(PolicyProtocolTypeEnumMapper)
        private readonly policyProtocolTypeEnumMapper: PolicyProtocolTypeEnumMapper,
    ) {}
    mapToDto(policyProtocol: PolicyProtocol): PolicyProtocolDto {
        const policyProtocolDto: PolicyProtocolDto = {
            id: policyProtocol.id,
            name: policyProtocol.name,
            policies_protocols: policyProtocol.policiesProtocols.map(
                (policyProtocolEnum) =>
                    this.policyProtocolTypeEnumMapper.mapToDto(
                        policyProtocolEnum,
                    ),
            ),
            other_policies_protocols: policyProtocol.otherPoliciesProtocols
                ? policyProtocol.otherPoliciesProtocols
                : null,
            realization_year: policyProtocol.realizationYear,
            expiration_date: policyProtocol.expirationDate
                ? policyProtocol.expirationDate.toString()
                : null,
            entity: policyProtocol.entity,
        };

        return policyProtocolDto;
    }
}
