import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { District } from "./district.model";

export class Address {
    public district?: Nullable<District>;
    public stair?: Undefinable<string>;
    public door?: Undefinable<string>;
    public floor?: Undefinable<string>;
    public letter?: Undefinable<string>;

    constructor(
        public id: number,
        public address: string,
        public number: string,
        public postalCode: string,
        public city: string,
    ) {}
}

export class Addresses {
    constructor(
        public readonly addresses: Address[],
        public readonly count: number,
    ) {}
}

export class CreateAddress {
    public district?: Nullable<number>;
    public stair?: Address["stair"];
    public door?: Address["door"];
    public floor?: Address["floor"];
    public letter?: Address["letter"];

    constructor(
        public address: Address["address"],
        public number: Address["address"],
        public postalCode: Address["postalCode"],
        public city: Address["city"],
    ) {}
}

export class EditAddress extends CreateAddress {
    constructor(
        public id: number,
        address: Address["address"],
        number: Address["address"],
        postalCode: Address["postalCode"],
        city: Address["city"],
    ) {
        super(address, number, postalCode, city);
    }
}

export interface AddressData {
    usualAddress: Nullable<Address>;
    censusAddress: Nullable<Address>;
}
