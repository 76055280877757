import { Nullable } from "@core/domain/types/nullable.type";
import { PaymentMethodTypeEnumDto } from "@project/data/dto/expenses/payment-method-type.dto";
import { injectable } from "inversify";
import { PaymentMethodTypeEnum } from "../../../domain/models/payment-method-type.model";

@injectable()
export class PaymentMethodTypeEnumMapper {
    map(
        paymentMethodTypeEnumDto: PaymentMethodTypeEnumDto,
    ): Nullable<PaymentMethodTypeEnum> {
        switch (paymentMethodTypeEnumDto) {
            case PaymentMethodTypeEnumDto.CASH:
                return PaymentMethodTypeEnum.CASH;
            case PaymentMethodTypeEnumDto.BANK_JUSTIFY:
                return PaymentMethodTypeEnum.BANK_JUSTIFY;
            default:
                return null;
        }
    }

    mapToDto(
        paymentMethodTypeEnum: PaymentMethodTypeEnum,
    ): PaymentMethodTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (paymentMethodTypeEnum) {
            case PaymentMethodTypeEnum.CASH:
                return PaymentMethodTypeEnumDto.CASH;
            case PaymentMethodTypeEnum.BANK_JUSTIFY:
                return PaymentMethodTypeEnumDto.BANK_JUSTIFY;
        }
    }
}
