import { EditAuditoryBody } from "@entity/data/dto/quality-framework/auditory/edit-auditory.body";
import { EditAuditory } from "@entity/domain/models/quality-framework/auditory/edit-auditory.model";
import { AuditoryFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/auditory/form/auditory-form";
import { injectable } from "inversify";

@injectable()
export class EditAuditoryMapper {
    mapToDto(editAuditory: EditAuditory): EditAuditoryBody {
        const editAuditoryBody: EditAuditoryBody = {
            id: editAuditory.id,
            certification_entity: editAuditory.certificationEntity,
            year: editAuditory.year,
            entity: editAuditory.entity,
        };
        return editAuditoryBody;
    }
    mapFromModal(
        id: number,
        entityId: number,
        auditory: AuditoryFormValuesValidated,
    ): EditAuditory {
        const editAuditory: EditAuditory = new EditAuditory(
            id,
            auditory.certificationEntity,
            auditory.year.year,
            entityId,
        );
        return editAuditory;
    }
}
