import { inject, injectable } from "inversify";
import { Either } from "@core/domain/types/either";
import { coreTypes } from "@core/core-types.di";
import type { Http } from "@core/data/infrastructures/http/http";
import { ServicesDto } from "../dto/services.dto";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import { plainToClass } from "class-transformer";
import { ServicesMapper } from "@entity/data/mappers/services.mapper";
import { PaginatedQueryDto } from "@core/data/dto/paginated.dto";
import { Services } from "@entity/domain/models/services.model";

@injectable()
export class ServiceDatasource {
    constructor(
        @inject(ServicesMapper) private readonly servicesMapper: ServicesMapper,
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
    ) {}
    async fetchAllPaginated(
        limit?: number,
        offset?: number,
    ): Promise<Either<HttpError, Services>> {
        const query: PaginatedQueryDto = {
            limit,
            offset,
        };
        const servicesResult = await this.http.get<ServicesDto>("/services/", {
            query,
        });

        return servicesResult.map((response) =>
            this.servicesMapper.map(plainToClass(ServicesDto, response.data)),
        );
    }
}
