export enum TypologyEnum {
    EntityGrouping = "entity_grouping",
    Association = "association",
    ShelteredEmploymentCenter = "sheltered_employment_center",
    Confederation = "confederation",
    SocialCooperative = "social_cooperative",
    SocialEnterprises = "social_enterprises",
    SocialEconomyEntities = "social_economy_entities",
    Federation = "federation",
    Foundation = "foundation",
    Platform = "platform",
    SocialClub = "social_club",
    OrganizedSocialMovements = "organized_social_movements",
    Other = "other",
}

export class Typology {
    constructor(
        public readonly id: TypologyEnum,
        public readonly label: string,
    ) {}
}
