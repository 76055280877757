import { GetLoggedUserUseCase } from "@authentication/domain/usecases/get-logged-user.usecase";
import { BeneficiarySummary } from "@beneficiary/domain/models/beneficiary-summary.model";
import { GetAllBeneficiariesUseCase } from "@beneficiary/domain/usecases/get-all-beneficiaries.usecase";
import { Pagination } from "@core/domain/models/pagination";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { DeriveProceedingMapper } from "@proceeding/data/mappers/derive-proceeding.mapper";
import { DerivationProceeding } from "@proceeding/domain/models/derivation-proceeding.model";
import { ProceedingSearchFilters } from "@proceeding/domain/models/proceeding.model";
import { ProceedingsSummary } from "@proceeding/domain/models/proceedings-sumary.model";
import { Status, StatusEnum } from "@proceeding/domain/models/status.model";
import { DeriveProceedingUseCase } from "@proceeding/domain/usecases/derive-proceeding.usecase";
import { GetAllStatusUseCase } from "@proceeding/domain/usecases/get-all-status.usecase";
import { GetProceedingsByUseCase } from "@proceeding/domain/usecases/get-proceedings-by.usecase";
import { DerivationFormValuesValidated } from "@proceeding/presentation/components/modal/derivation-modal-form.component";
import { ActivitySummary } from "@project/domain/models/activity-summary.model";
import { Catalogue } from "@project/domain/models/catalogue.model";
import { GlobalProject } from "@project/domain/models/global-project.model";
import { GetAllCataloguesUseCase } from "@project/domain/usecases/get-all-catalogues.usecase";
import { GetAllGlobalProjectsByUseCase } from "@project/domain/usecases/get-all-global-projects-by.usecase";
import { GetAllProjectActivitiesSummaryByUseCase } from "@project/domain/usecases/project-activity/get-all-summary-activities-by.usecase";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

export interface ProceedingTable {
    id: number;
    created: DateTime;
    state: string;
    beneficiaryData: BeneficiarySummary;
    derived: boolean;
    entityId: number;
}

export interface ProceedingsTable {
    proceedingsSummary: ProceedingTable[];
    count: number;
}

export interface ProceedingListFilters {
    entityId?: Undefinable<number>;
    beneficiaryId?: Undefinable<number>;
    search?: Undefinable<string>;
    projectId?: Undefinable<number>;
    activitiesIds?: Undefinable<number[]>;
    status?: Undefinable<StatusEnum>;
    derivedProjects?: Undefinable<boolean>;
    receivedDerivated?: Undefinable<boolean>;
    catalogueId?: Undefinable<number>;
}

@injectable()
export class ProceedingListPageViewModel extends BaseViewModel {
    @observable
    showDerivationModal = false;

    @observable
    showRequireEntityGuard = false;

    @observable
    initialFiltersLoading: boolean = true;

    @observable
    activitiesLoading: boolean = false;

    @observable
    cataloguesLoading: boolean = false;

    @observable
    entitiesLoading: boolean = false;

    @observable
    initialLoading: boolean = true;

    initialFiltersValue: ProceedingListFilters = {
        entityId: undefined,
        beneficiaryId: undefined,
        projectId: undefined,
        activitiesIds: undefined,
        search: undefined,
        catalogueId: undefined,
        status: undefined,
        receivedDerivated: undefined,
        derivedProjects: undefined,
    };

    @observable
    beneficiaries: BeneficiarySummary[] = [];

    @observable
    entities: GlobalEntity[] = [];

    @observable
    projects: GlobalProject[] = [];

    @observable
    globalEntities: GlobalEntity[] = [];

    @observable
    activities: ActivitySummary[] = [];

    catalogues: Catalogue[] = [];

    @observable
    filteredCatalogues: Catalogue[] = [];

    @observable
    status: Status[] = [];

    @observable
    pagination: Pagination = new Pagination();

    @observable
    filters: ProceedingListFilters = this.initialFiltersValue;

    @observable
    proceedings: ProceedingsSummary = new ProceedingsSummary([], 0);

    @computed
    get proceedingsTable(): ProceedingsTable {
        return {
            proceedingsSummary:
                this.proceedings.proceedingsSummary.map<ProceedingTable>(
                    (proceedingSummary) => ({
                        id: proceedingSummary.id,
                        created: proceedingSummary.created,
                        state: proceedingSummary.statusLabel,
                        beneficiaryData: proceedingSummary.beneficiaryData,
                        derived: proceedingSummary.isDerivation,
                        entityId: proceedingSummary.entity.id,
                    }),
                ),
            count: this.proceedings.count,
        };
    }

    constructor(
        @inject(DeriveProceedingMapper)
        private readonly deriveProceedingMapper: DeriveProceedingMapper,
        @inject(DeriveProceedingUseCase)
        private readonly deriveProceedingUseCase: DeriveProceedingUseCase,
        @inject(GetAllBeneficiariesUseCase)
        private readonly getAllBeneficiariesUseCase: GetAllBeneficiariesUseCase,
        @inject(GetAllGlobalProjectsByUseCase)
        private readonly getAllGlobalProjectsByUseCase: GetAllGlobalProjectsByUseCase,
        @inject(GetAllProjectActivitiesSummaryByUseCase)
        private readonly getAllProjectActivitiesSummaryByUseCase: GetAllProjectActivitiesSummaryByUseCase,
        @inject(GetAllStatusUseCase)
        private readonly getAllStatusUseCase: GetAllStatusUseCase,
        @inject(GetAllCataloguesUseCase)
        private readonly getAllCataloguesUseCase: GetAllCataloguesUseCase,
        @inject(GetProceedingsByUseCase)
        private readonly getAllProceedingsUseCase: GetProceedingsByUseCase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
        @inject(GetLoggedUserUseCase)
        private readonly getLoggedUserUseCase: GetLoggedUserUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.initFilter(), this.listProceedings()]);
    }

    async initFilter(): Promise<void> {
        const filtersRequest: Promise<void>[] = [
            this.getAllBeneficiaries(),
            this.getAllProjects(),
            this.getAllStatus(),
            this.getAllCatalogues(),
        ];

        if (this.getLoggedUserUseCase.execute().isSuperAdmin()) {
            filtersRequest.push(this.getAllGlobalEntities());
        }

        await Promise.all(filtersRequest);

        runInAction(() => {
            this.initialFiltersLoading = false;
        });
    }

    async listProceedings(): Promise<void> {
        await this.getAllProceedings(this.initialFiltersValue);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllProceedings(
        viewFilters?: ProceedingListFilters,
    ): Promise<void> {
        const filters: ProceedingSearchFilters = {
            entityId: viewFilters?.entityId,
            search: viewFilters?.search,
            beneficiaryId: viewFilters?.beneficiaryId,
            projectId: viewFilters?.projectId,
            activitiesIds: viewFilters?.activitiesIds,
            status: viewFilters?.status,
            catalogueId: viewFilters?.catalogueId,
        };

        if (viewFilters?.derivedProjects && viewFilters.receivedDerivated) {
            filters.derivation = "both";
        } else if (viewFilters?.derivedProjects) {
            filters.derivation = "toOther";
        } else if (viewFilters?.receivedDerivated) {
            filters.derivation = "fromOther";
        }

        const proceedings = await this.getAllProceedingsUseCase.execute(
            this.pagination,
            filters,
        );

        runInAction(() => {
            this.proceedings = proceedings;
        });
    }

    async deriveProceeding(
        values: DerivationFormValuesValidated,
    ): Promise<Nullable<DerivationProceeding>> {
        LoadLayoutStore.start();

        const derivedProceeding = await this.deriveProceedingUseCase.execute(
            this.deriveProceedingMapper.mapFromProceedingFormValues(values),
        );

        LoadLayoutStore.finish();

        if (derivedProceeding) {
            ToastManagerStore.success();
        }

        return derivedProceeding;
    }

    async getAllCatalogues(): Promise<void> {
        const catalogues = await this.getAllCataloguesUseCase.execute();

        runInAction(() => {
            this.catalogues = catalogues;
        });
    }

    async getAllGlobalEntities(): Promise<void> {
        const globalEntities = await this.getAllGlobalEntitiesUseCase.execute();

        runInAction(() => {
            this.globalEntities = globalEntities;
        });
    }

    async getAllStatus(): Promise<void> {
        const status = await this.getAllStatusUseCase.execute();

        runInAction(() => {
            this.status = status;
        });
    }

    async getAllBeneficiaries(): Promise<void> {
        const beneficiaries = await this.getAllBeneficiariesUseCase.execute();

        runInAction(() => {
            this.beneficiaries = beneficiaries;
        });
    }

    async getAllProjects(): Promise<void> {
        const projects = await this.getAllGlobalProjectsByUseCase.execute();

        runInAction(() => {
            this.projects = projects;
        });
    }

    async getAllActivities(projectId: number): Promise<void> {
        this.activitiesLoading = true;
        const activities =
            await this.getAllProjectActivitiesSummaryByUseCase.execute(
                projectId,
            );

        runInAction(() => {
            this.activities = activities;
            this.activitiesLoading = false;
        });
    }

    @action
    setFilters(filters: ProceedingListFilters): void {
        this.filters = filters;
        this.pagination.reset();

        this.getAllProceedings(filters);
    }

    @action
    deleteCatalogues(): void {
        this.filteredCatalogues = [];
    }

    @action
    deleteActivities(): void {
        this.activities = [];
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.getAllProceedings();
    }

    @action
    setModalVisibility(visibility: boolean): void {
        this.showDerivationModal = visibility;
    }

    @action
    setRequireEntityGuardVisibility(visibility: boolean): void {
        this.showRequireEntityGuard = visibility;
    }

    async getAllEntities(): Promise<void> {
        const entities = await this.getAllGlobalEntitiesUseCase.execute();

        runInAction(() => {
            this.entities = entities;
        });
    }

    async reloadProceedings(): Promise<void> {
        LoadLayoutStore.start();

        await this.getAllProceedings();

        runInAction(() => {
            LoadLayoutStore.finish();
        });
    }
}
