import { CreatePublicItinerary } from "@beneficiary/domain/models/public-itinerary/create-public-itinerary.model";
import { PublicItinerary } from "@beneficiary/domain/models/public-itinerary/public-itinerary.model";

export class EditPublicItinerary extends CreatePublicItinerary {
    constructor(
        public readonly id: PublicItinerary["id"],
        objetive: PublicItinerary["objetive"],
        actions: PublicItinerary["actions"],
        actionsEvaluation: PublicItinerary["actionsEvaluation"],
        beneficiary: PublicItinerary["beneficiary"],
    ) {
        super(objetive, actions, actionsEvaluation, beneficiary);
    }
}
