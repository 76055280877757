import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateUserMapper } from "@user/data/mappers/create-user.mapper";
import { User } from "@user/domain/models/user.model";
import { CreateUserUseCase } from "@user/domain/usecases/create-user.usecase";
import { inject, injectable } from "inversify";
import { makeObservable } from "mobx";
import { UserFormValuesValidated } from "../../components/user-form/user-form.component";

@injectable()
export class CreateUserPageViewModel extends BaseViewModel {
    constructor(
        @inject(CreateUserUseCase)
        private readonly createUserUseCase: CreateUserUseCase,
        @inject(CreateUserMapper)
        private readonly createUserMapper: CreateUserMapper,
    ) {
        super();
        makeObservable(this);
    }

    async createUser(values: UserFormValuesValidated): Promise<Nullable<User>> {
        LoadLayoutStore.start();

        const createdUser = this.createUserMapper.mapFromUserFormValues(values);

        const createdSuccessfully =
            await this.createUserUseCase.execute(createdUser);

        LoadLayoutStore.finish();

        if (createdSuccessfully) {
            ToastManagerStore.success();
        }

        return createdSuccessfully;
    }
}
