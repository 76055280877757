import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ExportProjectExpensesMapper } from "@entity/data/mappers/deposit/export-project-expenses.mapper";
import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { ExportProjectExpensesFormValuesValidated } from "@entity/presentation/pages/cost/list/export-project-expenses-modal/export-project-expenses-modal-form.component";
import { inject, injectable } from "inversify";

@injectable()
export class ExportProjectExpensesUseCase {
    constructor(
        @inject(ExportProjectExpensesMapper)
        private readonly exportProjectExpensesMapper: ExportProjectExpensesMapper,
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}

    async execute(
        values: ExportProjectExpensesFormValuesValidated,
    ): Promise<Blob> {
        const exportResult = await this.entityRepository.exportProjectExpenses(
            this.exportProjectExpensesMapper.mapToExpense(values),
        );

        if (exportResult.isLeft()) {
            ToastManagerStore.error(exportResult.getLeftOrThrow().message);
        }

        return exportResult.getOrThrow();
    }
}
