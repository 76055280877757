import { coreTypes } from "@core/core-types.di";
import { DocumentCategoriesDto } from "@core/data/dto/document-categories.dto";
import { DocumentLicenseTypeDto } from "@core/data/dto/document-license-type.dto";
import { DocumentDto } from "@core/data/dto/document.dto";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpConfig } from "@core/data/infrastructures/http/http-config";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { CreateDocumentMapper } from "@core/data/mappers/create-document.mapper";
import { DocumentCategoriesMapper } from "@core/data/mappers/document-categories.mapper";
import { DocumentLicenseTypeMapper } from "@core/data/mappers/document-license-type.mapper";
import { DocumentSignatureMapper } from "@core/data/mappers/document-signature.mapper";
import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { CreateDocumentError } from "@core/domain/errors/create-document.error";
import { DocumentMaxSizeError } from "@core/domain/errors/document-max-size.error";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { CreateDocument } from "@core/domain/models/create-document.model";
import { DocumentLicenseType } from "@core/domain/models/document-type.model";
import { DocumentCategories } from "@core/domain/models/documents-category.model";
import {
    DocumentTypeLicenseEnum,
    IncDocument,
} from "@core/domain/models/inc-document.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { isDefined } from "@core/domain/types/undefinable.type";
import { plainToClass } from "class-transformer";
import { saveAs } from "file-saver";
import { Map } from "immutable";
import { inject, injectable } from "inversify";

@injectable()
export class DocumentDatasource {
    constructor(
        @inject(CreateDocumentMapper)
        private readonly createDocumentMapper: CreateDocumentMapper,

        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,

        @inject(DocumentCategoriesMapper)
        private readonly documentCategoriesMapper: DocumentCategoriesMapper,

        @inject(DocumentLicenseTypeMapper)
        private readonly documentLicenseTypeMapper: DocumentLicenseTypeMapper,

        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(DocumentSignatureMapper)
        private readonly documentSignatureMapper: DocumentSignatureMapper,
    ) {}

    async create(
        newDocument: CreateDocument,
    ): Promise<Either<CreateDocumentError, IncDocument>> {
        const formData: FormData = new FormData();
        const config: HttpConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        const createdDocumentDto =
            this.createDocumentMapper.mapToDto(newDocument);

        formData.append("name", createdDocumentDto.name);
        formData.append("document", newDocument.file);

        if (createdDocumentDto.title)
            formData.append("title", createdDocumentDto.title);
        if (createdDocumentDto.author)
            formData.append("author", createdDocumentDto.author);
        if (createdDocumentDto.description)
            formData.append("description", createdDocumentDto.description);
        if (createdDocumentDto.expiry_date)
            formData.append("expiry_date", createdDocumentDto.expiry_date);
        if (isDefined(createdDocumentDto.visible))
            formData.append(
                "visible",
                createdDocumentDto.visible ? "True" : "False",
            );
        if (createdDocumentDto.category)
            formData.append("category", createdDocumentDto.category.toString());
        if (createdDocumentDto.type_license)
            formData.append("type_license", createdDocumentDto.type_license);

        const createDocumentResult = await this.http.post<DocumentDto>(
            "/documents/upload_and_create/",
            formData,
            config,
        );

        return createDocumentResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                } else if (error instanceof HttpFailedRequestError) {
                    return new DocumentMaxSizeError();
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdDocument = this.documentMapper.map(
                    plainToClass(DocumentDto, response.data),
                );

                if (!createdDocument) return Either.Left(new FallbackError());

                return Either.Right(createdDocument);
            });
    }

    async fetchById(id: number): Promise<Either<FallbackError, IncDocument>> {
        const documentResult = await this.http.get<DocumentDto>(
            `/documents/${id}/`,
        );

        return documentResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const document = this.documentMapper.map(
                    plainToClass(DocumentDto, response.data),
                );

                if (!document) return Either.Left(new FallbackError());

                return Either.Right(document);
            });
    }

    async delete(documentId: number): Promise<Either<FallbackError, true>> {
        const deleteResult = await this.http.delete(
            `/documents/${documentId}/`,
        );

        return deleteResult.mapLeft(() => new FallbackError()).map(() => true);
    }

    async download(documentUrl: string, name?: string): Promise<void> {
        const documentName = name ?? documentUrl.split("/").pop();
        const documentResult = await this.http.get<Blob>(documentUrl, {
            responseType: "blob",
            // We override baseurl because media files are served outside
            baseUrl: process.env.INC_API_SERVER_URL,
        });

        if (documentResult.isRight()) {
            saveAs(documentResult.getOrThrow().data, documentName);
        }
    }

    async sign(
        documentUrl: number,
        signature: string,
        userId: number,
    ): Promise<Either<ValidationError | FallbackError, boolean>> {
        const formData: FormData = new FormData();
        const config: HttpConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        formData.append("document", documentUrl.toString());
        formData.append("user", userId.toString());

        const signatureBlob = await fetch(signature).then(async (response) =>
            response.blob(),
        );
        formData.append("image", signatureBlob, "signature.png");
        const signDocumentResult = await this.http.post(
            "/documents_signatures/",
            formData,
            config,
        );

        return signDocumentResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap(() => Either.Right(true));
    }

    async editSignature(
        signatureId: number,
        documentId: number,
        signature: string,
        userId: number,
    ): Promise<Either<ValidationError | FallbackError, boolean>> {
        const formData: FormData = new FormData();
        const config: HttpConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        formData.append("document", documentId.toString());
        formData.append("user", userId.toString());

        const signatureBlob = await fetch(signature).then(async (response) =>
            response.blob(),
        );
        formData.append("image", signatureBlob, "signature.png");

        const editSignatureResult = await this.http.put(
            `/documents_signatures/${signatureId}/`,
            formData,
            config,
        );

        return editSignatureResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap(() => Either.Right(true));
    }

    async fetchAllDocumentLicenseTypes(): Promise<
        Either<HttpError, Map<DocumentTypeLicenseEnum, DocumentLicenseType>>
    > {
        const documentTypesResult = await this.http.get<
            DocumentLicenseTypeDto[]
        >("/documents/type_licenses/");

        return documentTypesResult.map((response) => {
            const licenseTypes = response.data.mapNotNull((licenseType) =>
                this.documentLicenseTypeMapper.map(
                    plainToClass(DocumentLicenseTypeDto, licenseType),
                ),
            );

            return Map(
                licenseTypes.map<
                    [DocumentTypeLicenseEnum, DocumentLicenseType]
                >((licenseType) => [licenseType.type, licenseType]),
            );
        });
    }

    async fetchAllDocumentCategories(
        pagination: Pagination,
    ): Promise<Either<FallbackError, DocumentCategories>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        const documentCategoriesResult =
            await this.http.get<DocumentCategoriesDto>(
                "/documents_categories/",
                {
                    query,
                },
            );

        return documentCategoriesResult
            .mapLeft(() => new FallbackError())
            .map((documentCategoriesResponse) =>
                this.documentCategoriesMapper.map(
                    plainToClass(
                        DocumentCategoriesDto,
                        documentCategoriesResponse.data,
                    ),
                ),
            );
    }
}
