import { Pagination } from "@core/domain/models/pagination";
import { ProjectRepository } from "@project/data/repositories/project.repository";
import { ProjectSearchFilters } from "@project/domain/models/project-search-filters";
import { ProjectsSummary } from "@project/domain/models/projects-summary.model";
import { inject, injectable } from "inversify";

@injectable()
export class SearchProjectsByUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: ProjectSearchFilters,
    ): Promise<ProjectsSummary> {
        const projectsResult = await this.projectRepository.searchAllBy(
            pagination,
            filters,
        );

        return projectsResult.fold(
            () => new ProjectsSummary([], 0),
            (projects) => projects,
        );
    }
}
