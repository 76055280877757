import { ProjectFinancialEntityRepository } from "@project/data/repositories/project-financial-entity.repository";
import {
    ProjectFinancialEntitiesSearchFilters,
    ProjectFinancialEntity,
} from "@project/domain/models/project-financial-entities.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllProjectFinancialEntitiesUseCase {
    constructor(
        @inject(ProjectFinancialEntityRepository)
        private readonly projectFinancialEntityRepository: ProjectFinancialEntityRepository,
    ) {}

    async execute(
        filters?: ProjectFinancialEntitiesSearchFilters,
    ): Promise<ProjectFinancialEntity[]> {
        const projectFinancialEntitiesResult =
            await this.projectFinancialEntityRepository.getAllBy(filters);

        return projectFinancialEntitiesResult.fold(
            () => [],
            (projectFinancialEntities) =>
                projectFinancialEntities.projectFinancialEntities,
        );
    }
}
