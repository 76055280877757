import { Address } from "@entity/domain/models/address/address.model";

export class CreateAddress {
    constructor(
        public name: Address["name"],
        public streetName: Address["streetName"],
        public zipCode: Address["zipCode"],
        public town: Address["town"],
        public province: Address["province"],
        public entity: Address["entity"],
        public streetNumber: Address["streetNumber"],
        public country: Address["country"],
        public titularityType: Address["titularityType"],
        public region?: Address["region"],
        public floor?: Address["floor"],
        public door?: Address["door"],
        public stair?: Address["stair"],
        public cadastralValue?: Address["cadastralValue"],
        public local?: Address["local"],
        public principalAddress?: Address["principalAddress"],
    ) {}
}
