import { CreateRelativeMapper } from "@beneficiary/data/mappers/relatives/create-relative.mapper";
import { EditRelativeMapper } from "@beneficiary/data/mappers/relatives/edit-relative.mapper";
import { Relationship } from "@beneficiary/domain/models/relationship.model";
import { Relative } from "@beneficiary/domain/models/relatives/relative.model";
import { GetAllRelationshipsUseCase } from "@beneficiary/domain/usecases/get-all-relationships.usecase";
import { CreateRelativesUsecase } from "@beneficiary/domain/usecases/relatives/create-relatives.usecase";
import { DeleteRelativesUsecase } from "@beneficiary/domain/usecases/relatives/delete-relatives.usecase";
import { EditRelativesUsecase } from "@beneficiary/domain/usecases/relatives/edit-relatives.usecase";
import { GetAllRelativesUsecase } from "@beneficiary/domain/usecases/relatives/get-all-relatives.usecase";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { RelativeFormValuesValidated } from "./form/relative-form.view";

@injectable()
export class RelativesTabViewModel extends BaseViewModel {
    @observable
    initialLoading = true;

    @observable
    relationships: Relationship[] = [];

    @observable
    relatives: Relative[] = [];

    @observable
    addRelativeModalOpen = false;

    @observable
    editRelativeModalOpen = false;

    @observable
    relativeToEdit: Undefinable<Relative> = undefined;

    @observable
    beneficiaryId: Undefinable<number> = undefined;

    constructor(
        @inject(GetAllRelativesUsecase)
        private readonly getAllRelativesUseCase: GetAllRelativesUsecase,
        @inject(CreateRelativesUsecase)
        private readonly createRelativesUsecase: CreateRelativesUsecase,
        @inject(EditRelativesUsecase)
        private readonly editRelativesUsecase: EditRelativesUsecase,
        @inject(DeleteRelativesUsecase)
        private readonly deleteRelativesUsecase: DeleteRelativesUsecase,
        @inject(CreateRelativeMapper)
        private readonly createRelativeMapper: CreateRelativeMapper,
        @inject(EditRelativeMapper)
        private readonly editRelativeMapper: EditRelativeMapper,
        @inject(GetAllRelationshipsUseCase)
        private readonly getAllRelationshipsUseCase: GetAllRelationshipsUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();
        await this.initViewData();
    }

    private async initViewData(): Promise<void> {
        await Promise.all([this.getAllRelatives(), this.getAllRelationships()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllRelationships(): Promise<void> {
        const relationships = await this.getAllRelationshipsUseCase.execute();

        runInAction(() => {
            this.relationships = relationships;
        });
    }

    async getAllRelatives(): Promise<void> {
        this.relatives = await this.getAllRelativesUseCase.execute({
            beneficiaryId: this.beneficiaryId,
        });
    }

    @action
    setAddRelativeModalOpen(open: boolean): void {
        this.addRelativeModalOpen = open;
    }
    @action
    setEditRelativeModalOpen(
        open: boolean,
        relative: Undefinable<Relative>,
    ): void {
        this.relativeToEdit = relative;
        this.editRelativeModalOpen = open;
    }

    async createRelative(
        relativesValues: RelativeFormValuesValidated,
    ): Promise<void> {
        if (!this.beneficiaryId) throw Error("Missing beneficiary Id");
        LoadLayoutStore.start();

        const createRelativeResult = await this.createRelativesUsecase.execute(
            this.createRelativeMapper.mapFromFormValues(relativesValues),
            this.beneficiaryId,
        );

        if (createRelativeResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async editRelative(
        relativesValues: RelativeFormValuesValidated,
    ): Promise<void> {
        if (!this.beneficiaryId) throw Error("Missing beneficiary Id");
        if (!relativesValues.id) throw Error("Missing relative Id");

        LoadLayoutStore.start();

        const editRelativeResult = await this.editRelativesUsecase.execute(
            this.editRelativeMapper.mapFromFormValues(
                relativesValues,
                relativesValues.id,
            ),
            this.beneficiaryId,
        );

        if (editRelativeResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async deleteRelative(relativeId: number): Promise<void> {
        LoadLayoutStore.start();
        const result = await this.deleteRelativesUsecase.execute(relativeId);
        if (result) ToastManagerStore.success();
        LoadLayoutStore.finish();
    }
}
