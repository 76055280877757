import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { IndicatorRepository } from "@project/data/repositories/technical-proposal/indicator.repository";
import {
    CreateIndicator,
    Indicator,
} from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateIndicatorUseCase {
    constructor(
        @inject(IndicatorRepository)
        private readonly roomRepository: IndicatorRepository,
    ) {}

    async execute(room: CreateIndicator): Promise<Nullable<Indicator>> {
        const roomResult = await this.roomRepository.create(room);

        if (roomResult.isLeft()) {
            ToastManagerStore.error(roomResult.getLeftOrThrow().message);
        }

        const newIndicator = roomResult.fold(
            () => null,
            (createdIndicator) => createdIndicator,
        );

        return newIndicator;
    }
}
