import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { ProceedingRepository } from "../../data/repositories/proceeding.repository";
import { Proceeding } from "../models/proceeding.model";

@injectable()
export class GetProceedingByIdUseCase {
    constructor(
        @inject(ProceedingRepository)
        private readonly proceedingRepository: ProceedingRepository,
    ) {}

    async execute(proceedingId: number): Promise<Nullable<Proceeding>> {
        const proceedingResult =
            await this.proceedingRepository.getById(proceedingId);

        if (proceedingResult.isLeft()) {
            ToastManagerStore.error(proceedingResult.getLeftOrThrow().message);
        }

        return proceedingResult.fold(
            () => null,
            (proceeding) => proceeding,
        );
    }
}
