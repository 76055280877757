import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateSocialNetworkMapper } from "@entity/data/mappers/social-network/create-social-network.mapper";
import { EditSocialNetworkMapper } from "@entity/data/mappers/social-network/edit-social-network.mapper";
import { SocialNetwork } from "@entity/domain/models/social-network.model";
import { SocialNetworkSummary } from "@entity/domain/models/social-networks-summary.model";
import { CreateSocialNetworkUseCase } from "@entity/domain/usecases/social-network/create-social-network.usecase";
import { DeleteSocialNetworkUseCase } from "@entity/domain/usecases/social-network/delete-social-network.usecase";
import { EditSocialNetworkUseCase } from "@entity/domain/usecases/social-network/edit-social-network.usecase";
import { GetAllSocialNetworksUseCase } from "@entity/domain/usecases/social-network/get-all-social-networks.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { v4 as uuidv4 } from "uuid";
import { SocialNetworkFormValuesValidated } from "./form/social-network-form";

@injectable()
export class SocialNetworksViewModel extends BaseViewModel {
    @observable
    initialLoading = true;

    @observable
    socialNetworks: SocialNetworkSummary[] = [];

    @observable
    addSocialNetworkModalOpen = false;

    @observable
    editSocialNetworkModalOpen = false;

    @observable
    socialNetworkToEdit: Undefinable<SocialNetwork> = undefined;

    constructor(
        @inject(GetAllSocialNetworksUseCase)
        private readonly getAllSocialNetworksUseCase: GetAllSocialNetworksUseCase,
        @inject(CreateSocialNetworkUseCase)
        private readonly createSocialNetworkUseCase: CreateSocialNetworkUseCase,
        @inject(CreateSocialNetworkMapper)
        private readonly createSocialNetworkMapper: CreateSocialNetworkMapper,
        @inject(EditSocialNetworkUseCase)
        private readonly editSocialNetworkUseCase: EditSocialNetworkUseCase,
        @inject(EditSocialNetworkMapper)
        private readonly editSocialNetworkMapper: EditSocialNetworkMapper,
        @inject(DeleteSocialNetworkUseCase)
        private readonly deleteSocialNetworkUseCase: DeleteSocialNetworkUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @flow
    private async *initViewData(): AsyncGenerator<void> {
        yield flowResult(Promise.all([this.getAllSocialNetworks()]));

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setAddSocialNetworkModalOpen(open: boolean): void {
        this.addSocialNetworkModalOpen = open;
    }

    @action
    closeEditSocialNetworkModal(): void {
        this.editSocialNetworkModalOpen = false;
    }

    @action
    openEditSocialNetworkModal(
        socialNetwork: Undefinable<SocialNetwork>,
    ): void {
        this.socialNetworkToEdit = socialNetwork;
        this.editSocialNetworkModalOpen = true;
    }

    async getAllSocialNetworks(): Promise<void> {
        this.socialNetworks = await this.getAllSocialNetworksUseCase.execute();
    }

    async createSocialNetwork(
        entityId: Undefinable<number>,
        values: SocialNetworkFormValuesValidated,
    ): Promise<void> {
        if (!entityId) throw Error("Missing Entity");
        LoadLayoutStore.start();

        const createSocialNetworkResult =
            await this.createSocialNetworkUseCase.execute(
                this.createSocialNetworkMapper.mapFromFormValues(
                    entityId,
                    values,
                ),
            );

        if (createSocialNetworkResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async editSocialNetwork(
        values: SocialNetworkFormValuesValidated,
    ): Promise<void> {
        if (!this.socialNetworkToEdit?.id || !this.socialNetworkToEdit.entityId)
            throw Error("Missing Social Network");

        LoadLayoutStore.start();

        const editSocialNetworkResult =
            await this.editSocialNetworkUseCase.execute(
                this.editSocialNetworkMapper.mapFromModal(
                    this.socialNetworkToEdit.id,
                    this.socialNetworkToEdit.entityId,
                    values,
                ),
            );

        if (editSocialNetworkResult) {
            ToastManagerStore.success();
        }
        LoadLayoutStore.finish();
    }

    async reloadSocialNetworks(): Promise<void> {
        if (this.socialNetworkToEdit) {
            const socialNetwork = this.socialNetworks.find(
                (socialNet) => socialNet.id === this.socialNetworkToEdit?.id,
            );
            if (socialNetwork) socialNetwork.uuid = uuidv4();
        }

        await this.getAllSocialNetworks();
    }

    async deleteSocialNetwork(socialNetworkId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteSocialNetworkUseCase.execute(socialNetworkId);
        LoadLayoutStore.finish();
    }
}
