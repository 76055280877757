import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteBeneficiaryUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(beneficiaryId: number): Promise<void> {
        const result = await this.beneficiaryRepository.delete(beneficiaryId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }
    }
}
