import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EditFinancialEntityMapper } from "@project/data/mappers/financial-entity/edit-financial-entity.mapper";
import { FinancialEntity } from "@project/domain/models/financial-entity/financial-entities.model";
import { EditFinancialEntityUseCase } from "@project/domain/usecases/financial-entity/edit-financial-entity.usecase";
import { GetFinancialEntityByIdUseCase } from "@project/domain/usecases/financial-entity/get-financial-entity-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { FinancialEntityFormValuesValidated } from "../../../components/financial-entity-form/financial-entity-form.component";

@injectable()
export class EditFinancialEntityPageViewModel extends BaseViewModel {
    financialEntityId: Undefinable<number> = undefined;

    @observable
    financialEntity: Undefinable<FinancialEntity> = undefined;

    @observable
    initialLoading: boolean = true;

    constructor(
        @inject(EditFinancialEntityMapper)
        private readonly editFinancialEntityMapper: EditFinancialEntityMapper,
        @inject(EditFinancialEntityUseCase)
        private readonly editFinancialEntityUseCase: EditFinancialEntityUseCase,
        @inject(GetFinancialEntityByIdUseCase)
        private readonly getFinancialEntityByIdUseCase: GetFinancialEntityByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getFinancialEntity();
        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setFinancialEntity(financialEntity: FinancialEntity): void {
        this.financialEntity = financialEntity;
    }

    async getFinancialEntity(): Promise<void> {
        if (this.financialEntityId === undefined)
            throw Error("Missing financialEntityId");

        const financialEntity =
            await this.getFinancialEntityByIdUseCase.execute(
                this.financialEntityId,
            );

        if (financialEntity) {
            this.setFinancialEntity(financialEntity);
        }
    }

    async editFinancialEntity(
        financialEntityForm: FinancialEntityFormValuesValidated,
    ): Promise<boolean> {
        if (!this.financialEntityId) throw Error("Missing financialEntityId");

        LoadLayoutStore.start();

        const editFinancialEntity =
            this.editFinancialEntityMapper.mapFromFinancialEntityFormValues(
                this.financialEntityId,
                financialEntityForm,
            );
        const editedSuccessfully =
            await this.editFinancialEntityUseCase.execute(editFinancialEntity);

        if (editedSuccessfully) {
            await this.getFinancialEntity();
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return editedSuccessfully;
    }
}
