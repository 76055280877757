import { CreateContactBody } from "@proceeding/domain/models/contact.model";
import { injectable } from "inversify";
import { ContactFormValuesValidated } from "../../presentation/component/entity-form/contact/form/contact.form";

@injectable()
export class CreateContactMapper {
    mapToContact(contact: ContactFormValuesValidated): CreateContactBody {
        return new CreateContactBody(
            contact.entity,
            contact.name,
            contact.email,
            contact.phone1,
            contact.phone2 ?? undefined,
            contact.web ?? undefined,
            contact.observations ?? undefined,
            contact.identificationType ?? undefined,
            contact.identification ?? undefined,
            contact.isLegalRepresentative,
        );
    }
}
