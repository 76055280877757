import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Nullable } from "@core/domain/types/nullable.type";
import { EditExpenseAllTypes } from "@project/domain/models/expenses/edit-expense.model";
import { Map } from "immutable";
import { inject, injectable } from "inversify";
import { ContractType } from "../../domain/models/expenses/contract-type.model";
import { ContributionGroupType } from "../../domain/models/expenses/contribution-group-type.model";
import { CreateExpenseAllTypes } from "../../domain/models/expenses/create-expense.model";
import { DietType } from "../../domain/models/expenses/diet-type.model";
import {
    ExpenseSearchFilters,
    ExpenseSubTypeSearchFilters,
} from "../../domain/models/expenses/expense-search-filters";
import {
    ExpenseType,
    ExpenseTypeEnum,
} from "../../domain/models/expenses/expense-type.model";
import { ExpenseAllTypes } from "../../domain/models/expenses/expense.model";
import { Expenses } from "../../domain/models/expenses/expenses.model";
import { MonthType } from "../../domain/models/expenses/month-type.model";
import {
    ExpenseSubType,
    ExpenseSubTypeEnum,
} from "../../domain/models/expenses/sub-type.model";
import { TransportType } from "../../domain/models/expenses/transport-type.model";
import { PaymentMethodType } from "../../domain/models/payment-method-type.model";
import { ProjectExpenseDatasource } from "../datasource/project-expense.datasource";

@injectable()
export class ProjectExpenseRepository {
    constructor(
        @inject(ProjectExpenseDatasource)
        private readonly projectExpenseDatasource: ProjectExpenseDatasource,
    ) {}

    async create(
        expense: CreateExpenseAllTypes,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<ExpenseAllTypes>>
    > {
        return this.projectExpenseDatasource.create(expense);
    }

    async edit(
        expense: EditExpenseAllTypes,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<ExpenseAllTypes>>
    > {
        return this.projectExpenseDatasource.update(expense);
    }

    async delete(expenseId: number): Promise<Either<FallbackError, true>> {
        const result = await this.projectExpenseDatasource.delete(expenseId);
        return result.mapLeft(() => new FallbackError());
    }

    async getAllExpenseTypes(): Promise<Either<FallbackError, ExpenseType[]>> {
        return this.projectExpenseDatasource.fetchAllExpenseTypes();
    }

    async getAllExpenseSubTypes(
        filters?: ExpenseSubTypeSearchFilters,
    ): Promise<Either<FallbackError, ExpenseSubType[]>> {
        return this.projectExpenseDatasource.fetchAllExpenseSubTypes(filters);
    }

    async getAllDietTypes(): Promise<Either<FallbackError, DietType[]>> {
        return this.projectExpenseDatasource.fetchAllDietTypes();
    }

    async getAllTransportTypes(): Promise<
        Either<FallbackError, TransportType[]>
    > {
        return this.projectExpenseDatasource.fetchAllTransportTypes();
    }

    async getAllMonthTypes(): Promise<Either<FallbackError, MonthType[]>> {
        return this.projectExpenseDatasource.fetchAllMonthTypes();
    }

    async getAllContributionGroupTypes(): Promise<
        Either<FallbackError, ContributionGroupType[]>
    > {
        return this.projectExpenseDatasource.fetchAllContributionGroupTypes();
    }

    async getAllContractTypes(): Promise<
        Either<FallbackError, ContractType[]>
    > {
        return this.projectExpenseDatasource.fetchAllContractTypes();
    }

    async getAllPaymentMethodTypes(): Promise<
        Either<FallbackError, PaymentMethodType[]>
    > {
        return this.projectExpenseDatasource.fetchAllPaymentMethodTypes();
    }

    async searchBy(
        pagination: Pagination,
        filters?: ExpenseSearchFilters,
    ): Promise<Either<FallbackError, Expenses>> {
        const expensesResult = await this.projectExpenseDatasource.fetchBy(
            pagination,
            filters,
        );

        if (expensesResult.isLeft()) return Either.Left(new FallbackError());

        const expenseTypesResult = await this.getAllExpenseTypes();

        if (expenseTypesResult.isLeft()) {
            return Either.Left(expenseTypesResult.getLeftOrThrow());
        }

        const expenseTypeMap: Map<ExpenseTypeEnum, ExpenseType> = Map(
            expenseTypesResult
                .getOrThrow()
                .map((expenseTypes) => [expenseTypes.id, expenseTypes]),
        );

        const expenseSubtypesResult = await this.getAllExpenseSubTypes();

        if (expenseSubtypesResult.isLeft()) {
            return Either.Left(expenseSubtypesResult.getLeftOrThrow());
        }

        const expenseSubtypeMap: Map<ExpenseSubTypeEnum, ExpenseSubType> = Map(
            expenseSubtypesResult
                .getOrThrow()
                .map((expenseSubtypes) => [
                    expenseSubtypes.id,
                    expenseSubtypes,
                ]),
        );

        const expenseDietTypesResult = await this.getAllDietTypes();

        if (expenseDietTypesResult.isLeft()) {
            return Either.Left(expenseSubtypesResult.getLeftOrThrow());
        }

        expensesResult.getOrThrow().expenses.forEach((expense) => {
            expense.typeLabel = expenseTypeMap.get(expense.typeId)?.label ?? "";

            if ("subtypeExpense" in expense) {
                expense.subTypeLabel = expense.subtypeExpense
                    ? expenseSubtypeMap.get(expense.subtypeExpense)?.label ?? ""
                    : "";
            }
        });

        return expensesResult.mapLeft(() => new FallbackError());
    }
}
