import { Pagination } from "@core/domain/models/pagination";
import { CostRepository } from "@entity/data/repositories/cost.repository";
import { inject, injectable } from "inversify";
import {
    CostSearchFilters,
    CostsSummary,
} from "../../models/cost/cost-summary.model";

@injectable()
export class GetCostsByUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: CostSearchFilters,
    ): Promise<CostsSummary> {
        const costsResult = await this.costRepository.getBy(
            pagination,
            filters,
        );

        return costsResult.fold(
            () => new CostsSummary([], 0),
            (costs) => costs,
        );
    }
}
