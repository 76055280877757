import { DocumentDto } from "@core/data/dto/document.dto";
import { IdentificationDocumentTypeEnumDto } from "@core/data/dto/identification-document-type.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { EmployeeContractDto } from "@entity/data/dto/employee/contracts/employee/employee-contract.dto";
import { FreelanceContractDto } from "@entity/data/dto/employee/contracts/freelance/freelance-contract.dto";
import { VolunteerContractDto } from "@entity/data/dto/employee/contracts/volunteer/volunteer-contract.dto";
import { EmployeeTypeEnumDto } from "@entity/data/dto/employee/employee-type.dto";
import { VolunteerInfoDto } from "@entity/data/dto/employee/volunteer-info.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class EmployeeDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsBoolean()
    @IsOptional()
    is_active!: Nullable<boolean>;

    @IsBoolean()
    @IsOptional()
    has_active_contract!: Nullable<boolean>;

    @IsString()
    @IsNotEmpty()
    identification!: string;

    @IsEnum(IdentificationDocumentTypeEnumDto)
    @IsNotEmpty()
    identification_type!: IdentificationDocumentTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    display_identification_type!: string;

    @IsString()
    @IsOptional()
    name!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    surname!: string;

    @IsEnum(EmployeeTypeEnumDto)
    @IsNotEmpty()
    type!: EmployeeTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    display_type!: string;

    @IsString()
    @IsOptional()
    percentage_ocupation!: Nullable<string>;

    @IsString()
    @IsOptional()
    availability_hours!: Nullable<string>;

    @IsNumber()
    @IsOptional()
    contracted_hours!: Nullable<number>;

    @IsOptional()
    @Type(() => VolunteerInfoDto)
    volunteer_info!: Nullable<VolunteerInfoDto>;

    @IsArray()
    @IsOptional()
    @Type(() => EmployeeContractDto)
    employee_contracts!: Nullable<EmployeeContractDto[]>;

    @IsArray()
    @IsOptional()
    @Type(() => FreelanceContractDto)
    freelance_contracts!: Nullable<FreelanceContractDto[]>;

    @IsArray()
    @IsOptional()
    @Type(() => VolunteerContractDto)
    volunteer_contracts!: Nullable<VolunteerContractDto[]>;

    @IsArray()
    @IsNumber(undefined, { each: true })
    @IsOptional()
    documents!: Nullable<number[]>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    documents_data!: Nullable<DocumentDto[]>;

    @IsString()
    @IsOptional()
    code_verification!: Nullable<string>;

    @IsNumber()
    @IsOptional()
    entity!: number;
}

export class CreateEmployeeDto {
    @IsBoolean()
    @IsOptional()
    is_active!: Nullable<boolean>;

    @IsBoolean()
    @IsOptional()
    has_active_contract!: Nullable<boolean>;

    @IsString()
    @IsNotEmpty()
    identification!: string;

    @IsEnum(IdentificationDocumentTypeEnumDto)
    @IsNotEmpty()
    identification_type!: IdentificationDocumentTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    display_identification_type!: string;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    surname!: string;

    @IsEnum(EmployeeTypeEnumDto)
    @IsNotEmpty()
    type!: EmployeeTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    display_type!: string;

    @IsString()
    @IsOptional()
    percentage_ocupation!: Nullable<string>;

    @IsString()
    @IsOptional()
    availability_hours!: Nullable<string>;

    @IsNumber()
    @IsOptional()
    contracted_hours!: Nullable<number>;

    @IsOptional()
    @Type(() => VolunteerInfoDto)
    volunteer_info!: Nullable<VolunteerInfoDto>;

    @IsArray()
    @IsOptional()
    @Type(() => EmployeeContractDto)
    employee_contracts!: Nullable<EmployeeContractDto[]>;

    @IsArray()
    @IsOptional()
    @Type(() => FreelanceContractDto)
    freelance_contracts!: Nullable<FreelanceContractDto[]>;

    @IsArray()
    @IsOptional()
    @Type(() => VolunteerContractDto)
    volunteer_contracts!: Nullable<VolunteerContractDto[]>;

    @IsArray()
    @IsOptional()
    documents!: Nullable<[]>;

    @IsString()
    @IsOptional()
    code_verification!: Nullable<string>;

    @IsNumber()
    @IsOptional()
    entity!: Nullable<number>;
}
