import { AuthorityManagerRepository } from "@entity/data/repositories/authority-manager.repository";
import { MandateTerminationReason } from "@entity/domain/models/mandate-termination-reason.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllMandateTerminationReasonsUseCase {
    constructor(
        @inject(AuthorityManagerRepository)
        private readonly authorityManagerRepository: AuthorityManagerRepository,
    ) {}

    async execute(): Promise<MandateTerminationReason[]> {
        const mandateTerminationReasonsResult =
            await this.authorityManagerRepository.getAllMandateTerminationReasons();

        return mandateTerminationReasonsResult.fold(
            () => [],
            (mandateTerminationReasons) => mandateTerminationReasons,
        );
    }
}
