import { CreateEmployee } from "@entity/domain/models/employee/create-employee.model";
import { Employee } from "@entity/domain/models/employee/employee.model";

export class EditEmployee extends CreateEmployee {
    public documents?: Employee["documents"];

    constructor(
        public readonly id: Employee["id"],
        public override identification: Employee["identification"],
        public override identificationType: Employee["identificationType"],
        public override name: Employee["name"],
        public override surname: Employee["surname"],
        public override type: Employee["type"],
        public override entity: Employee["entity"],
        public override volunteerInfo?: Employee["volunteerInfo"],
    ) {
        super(identification, identificationType, name, surname, type, entity);
    }
}
