import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { isUndefined, Undefinable } from "@core/domain/types/undefinable.type";
import { Employee } from "@entity/domain/models/employee/employee.model";
import { ProjectEmployeeDedication } from "@project/domain/models/project-employee-dedications.model";

export class ProjectEmployee {
    private _documents: Undefinable<IncDocument[]> = undefined;

    public contractedHours?: number;

    get fullName(): string {
        return `${this.name} ${this.surname}`;
    }

    public get documents(): Undefinable<readonly IncDocument[]> {
        return this._documents;
    }

    constructor(
        public readonly id: number,
        public name: string,
        public surname: string,
        public projectId: number,
        public totalAvailableHours: number,
        public employee: Employee,
        public dedications: ProjectEmployeeDedication[] = [],
    ) {}

    addDocument(document: IncDocument): void {
        if (isUndefined(this._documents)) this._documents = [];

        this._documents.push(document);
    }

    deleteDocument(document: IncDocument): void;
    deleteDocument(id: number): void;
    deleteDocument(document: IncDocument | number): void {
        if (isUndefined(this._documents)) return;

        const documentId =
            typeof document === "number" ? document : document.id;

        const index = this._documents.findIndex((doc) => doc.id === documentId);

        if (index === -1) return;

        this._documents.splice(index, 1);
    }
}

export class CreateProjectEmployee {
    constructor(
        public readonly projectId: ProjectEmployee["projectId"],
        public employee: ProjectEmployee["employee"]["id"],
        public documents?: Undefinable<number[]>,
    ) {}
}

export class UpdateProjectEmployee extends CreateProjectEmployee {
    constructor(
        public readonly id: ProjectEmployee["id"],
        projectId: CreateProjectEmployee["projectId"],
        employee: CreateProjectEmployee["employee"],
        documents?: CreateProjectEmployee["documents"],
    ) {
        super(projectId, employee, documents);
    }
}

export class ProjectEmployees {
    constructor(
        public readonly employees: ProjectEmployee[],
        count: number,
    ) {}
}

export interface ProjectEmployeesSearchFilters {
    projectId?: Nullable<number>;
    employeeId?: Nullable<number>;
}

export class ProjectEmployeesCount {
    constructor(
        public employee: number,
        public volunteer: number,
        public freelance: number,
    ) {}
}
