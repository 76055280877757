import { PublicItineraryRepository } from "@beneficiary/data/repositories/public-itinerary.repository";
import { PublicItinerary } from "@beneficiary/domain/models/public-itinerary/public-itinerary.model";
import { Pagination } from "@core/domain/models/pagination";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllPublicItinerariesUsecase {
    constructor(
        @inject(PublicItineraryRepository)
        private readonly publicItineraryRepository: PublicItineraryRepository,
    ) {}

    async execute(beneficiaryId: number): Promise<PublicItinerary[]> {
        const publicItinerariesResult =
            await this.publicItineraryRepository.getAllBy(
                beneficiaryId,
                Pagination.NoPagination(),
            );

        return publicItinerariesResult.fold(
            () => [],
            (publicItineraries) => publicItineraries.publicItineraries,
        );
    }
}
