import { ProceedingsDto } from "@proceeding/data/dto/proceedings.dto";
import { ProceedingsSummary } from "@proceeding/domain/models/proceedings-sumary.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { ProceedingSummaryMapper } from "./proceeding-summary.mapper";

@injectable()
export class ProceedingsSummaryMapper {
    constructor(
        @inject(ProceedingSummaryMapper)
        private readonly proceedingSummaryMapper: ProceedingSummaryMapper,
    ) {}

    map(proceedingsSummaryDto: ProceedingsDto): ProceedingsSummary {
        const errors = validateSync(proceedingsSummaryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new ProceedingsSummary([], 0);
        }

        const proceedingsSummary = proceedingsSummaryDto.results.mapNotNull(
            (proceedingDto) => this.proceedingSummaryMapper.map(proceedingDto),
        );

        return new ProceedingsSummary(
            proceedingsSummary,
            proceedingsSummaryDto.count,
        );
    }
}
