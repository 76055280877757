import { CenterTypesRepository } from "@beneficiary/data/repositories/center-types.repository";
import { CenterType } from "@beneficiary/domain/models/center-type.model";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllCenterTypesUseCase {
    constructor(
        @inject(CenterTypesRepository)
        private readonly centerTypesRepository: CenterTypesRepository,
    ) {}

    async execute(): Promise<CenterType[]> {
        const centerTypesResult = await this.centerTypesRepository.getAll();

        if (centerTypesResult.isLeft()) {
            ToastManagerStore.error(centerTypesResult.getLeftOrThrow().message);
        }

        return centerTypesResult.getOrElse([]);
    }
}
