import { Pagination } from "@core/domain/models/pagination";
import { PublicInterestRepository } from "@entity/data/repositories/public-interest.repository";
import { PublicInterest } from "@entity/domain/models/public-interest/public-interest.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllPublicInterestsUsecase {
    constructor(
        @inject(PublicInterestRepository)
        private readonly publicInterestRepository: PublicInterestRepository,
    ) {}

    async execute(): Promise<PublicInterest[]> {
        const publicInterestsResult =
            await this.publicInterestRepository.getAll(
                Pagination.NoPagination(),
            );

        return publicInterestsResult.fold(
            () => [],
            (publicInterests) => publicInterests.publicInterests,
        );
    }
}
