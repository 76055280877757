import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum StatusEnumDto {
    Create = "create",
    Design = "design",
    Founding = "founding",
    Execution = "execution",
    Justification = "justification",
    Finished = "finished",
    Discarded = "discarded",
}

export class StatusDto {
    @IsEnum(StatusEnumDto)
    @IsNotEmpty()
    key!: StatusEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
