import { useEffect, useRef } from "react";

export const useDidMount = (
    functToExecute: () => Promise<void> | void,
): void => {
    const didMount = useRef<boolean>(false);

    useEffect(() => {
        if (!didMount.current) {
            functToExecute();
        }
        didMount.current = true;
    }, [functToExecute]);
};
