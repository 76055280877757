import { Nullable } from "@core/domain/types/nullable.type";
import { ProjectAuditoryDto } from "@entity/data/dto/quality-framework/project-auditory/project-auditory.dto";
import { ProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/project-auditory.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class ProjectAuditoryMapper {
    map(projectAuditoryDto: ProjectAuditoryDto): Nullable<ProjectAuditory> {
        const errors = validateSync(projectAuditoryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        return new ProjectAuditory(
            projectAuditoryDto.id,
            projectAuditoryDto.certification_entity,
            projectAuditoryDto.year,
            projectAuditoryDto.audited_project,
            projectAuditoryDto.entity,
        );
    }

    mapToDto(projectAuditory: ProjectAuditory): ProjectAuditoryDto {
        const projectAuditoryDto: ProjectAuditoryDto = {
            id: projectAuditory.id,
            certification_entity: projectAuditory.certificationEntity,
            year: projectAuditory.year,
            audited_project: projectAuditory.auditedProject,
            entity: projectAuditory.entity,
        };

        return projectAuditoryDto;
    }
}
