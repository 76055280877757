import { Nullable } from "@core/domain/types/nullable.type";
import {
    StatutoryTerritorialScopeDto,
    StatutoryTerritorialScopesDto,
} from "@entity/data/dto/statutory-territorial-scope/statutory-territorial-scope.dto";
import { EntityTerritorialScopeEnumMapper } from "@entity/data/mappers/entity-territorial-scope-enum.mapper";
import { StatutoryTerritorialScopeMapper } from "@entity/data/mappers/statutory-territorial-scope/statutory-territorial-scope.mapper";
import {
    StatutoryTerritorialScope,
    StatutoryTerritorialScopes,
} from "@entity/domain/models/statutory-territorial-scope/statutory-territorial-scope.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class StatutoryTerritorialScopesMapper {
    constructor(
        @inject(StatutoryTerritorialScopeMapper)
        private readonly statutoryTerritorialScopeMapper: StatutoryTerritorialScopeMapper,
        @inject(EntityTerritorialScopeEnumMapper)
        private readonly entityTerritorialScopeEnumMapper: EntityTerritorialScopeEnumMapper,
    ) {}

    map(
        statutoryTerritorialScopesDto: StatutoryTerritorialScopesDto,
    ): StatutoryTerritorialScopes {
        const errors = validateSync(statutoryTerritorialScopesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new StatutoryTerritorialScopes([], 0);
        }

        const statutoryTerritorialScopes =
            statutoryTerritorialScopesDto.results.mapNotNull(
                (statutoryTerritorialScopeDto) =>
                    this.statutoryTerritorialScopeMapper.map(
                        statutoryTerritorialScopeDto,
                    ),
            );

        return new StatutoryTerritorialScopes(
            statutoryTerritorialScopes,
            statutoryTerritorialScopesDto.count,
        );
    }

    mapStatutoryTerritorialScope(
        statutoryTerritorialScopeDto: StatutoryTerritorialScopeDto,
    ): Nullable<StatutoryTerritorialScope> {
        if (!statutoryTerritorialScopeDto.id) {
            return null;
        }

        const scope = this.entityTerritorialScopeEnumMapper.map(
            statutoryTerritorialScopeDto.scope,
        );
        if (!scope) return null;

        const statutoryTerritorialScope: StatutoryTerritorialScope = {
            id: statutoryTerritorialScopeDto.id,
            name: statutoryTerritorialScopeDto.name,
            scope,
            entity: statutoryTerritorialScopeDto.entity,
        };
        return statutoryTerritorialScope;
    }
}
