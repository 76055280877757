import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreatePolicyProtocolMapper } from "@entity/data/mappers/quality-framework/policy-protocol/create-policy-protocol.mapper";
import { EditPolicyProtocolMapper } from "@entity/data/mappers/quality-framework/policy-protocol/edit-policy-protocol.mapper";
import { PolicyProtocolType } from "@entity/domain/models/policy-protocol-type.model";
import { PolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/policy-protocol.model";
import { GetAllPolicyProtocolTypesUseCase } from "@entity/domain/usecases/get-all-policy-protocol-types.usecase";
import { CreatePolicyProtocolUseCase } from "@entity/domain/usecases/quality-framework/policy-protocol/create-policy-protocol.usecase";
import { DeletePolicyProtocolUseCase } from "@entity/domain/usecases/quality-framework/policy-protocol/delete-policy-protocol.usecase";
import { EditPolicyProtocolUseCase } from "@entity/domain/usecases/quality-framework/policy-protocol/edit-policy-protocol.usecase";
import { GetAllPolicyProtocolsUsecase } from "@entity/domain/usecases/quality-framework/policy-protocol/get-all-policy-protocols.usecase";
import { PolicyProtocolFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/policy-protocol/form/policy-protocol-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class PolicyProtocolViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    policyProtocols: PolicyProtocol[] = [];

    @observable
    policyProtocolToEdit: Undefinable<PolicyProtocol> = undefined;

    @observable
    policyProtocolTypes: PolicyProtocolType[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddPolicyProtocolModal: boolean = false;

    @observable
    showEditPolicyProtocolModal: boolean = false;

    constructor(
        @inject(GetAllPolicyProtocolsUsecase)
        private readonly getAllPolicyProtocolsUsecase: GetAllPolicyProtocolsUsecase,
        @inject(CreatePolicyProtocolUseCase)
        private readonly createPolicyProtocolUseCase: CreatePolicyProtocolUseCase,
        @inject(EditPolicyProtocolUseCase)
        private readonly editPolicyProtocolUseCase: EditPolicyProtocolUseCase,
        @inject(DeletePolicyProtocolUseCase)
        private readonly deletePolicyProtocolUseCase: DeletePolicyProtocolUseCase,
        @inject(GetAllPolicyProtocolTypesUseCase)
        private readonly getAllPolicyProtocolTypesUseCase: GetAllPolicyProtocolTypesUseCase,
        @inject(CreatePolicyProtocolMapper)
        private readonly createPolicyProtocolMapper: CreatePolicyProtocolMapper,
        @inject(EditPolicyProtocolMapper)
        private readonly editPolicyProtocolMapper: EditPolicyProtocolMapper,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getPolicyProtocols(),
            this.getAllPolicyProtocolTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getPolicyProtocols(): Promise<void> {
        const policyProtocols =
            await this.getAllPolicyProtocolsUsecase.execute();
        runInAction(() => {
            this.policyProtocols = policyProtocols;
        });
    }

    @action
    setShowAddPolicyProtocolModal(show: boolean): void {
        this.showAddPolicyProtocolModal = show;
    }

    @action
    closeEditPolicyProtocolModal(): void {
        this.showEditPolicyProtocolModal = false;
    }

    @action
    openEditPolicyProtocolModal(
        policyProtocol: Undefinable<PolicyProtocol>,
    ): void {
        this.policyProtocolToEdit = policyProtocol;
        this.showEditPolicyProtocolModal = true;
    }

    async addPolicyProtocol(
        entityId: Undefinable<number>,
        values: PolicyProtocolFormValuesValidated,
    ): Promise<Nullable<PolicyProtocol>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createPolicyProtocolResult =
            await this.createPolicyProtocolUseCase.execute(
                this.createPolicyProtocolMapper.mapFromFormValues(
                    entityId,
                    values,
                ),
            );

        if (createPolicyProtocolResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createPolicyProtocolResult;
    }

    async editPolicyProtocol(
        values: PolicyProtocolFormValuesValidated,
    ): Promise<void> {
        if (!this.policyProtocolToEdit?.id || !this.policyProtocolToEdit.entity)
            throw Error("Missing policyProtocol");

        LoadLayoutStore.start();

        const editPolicyProtocolResult =
            await this.editPolicyProtocolUseCase.execute(
                this.editPolicyProtocolMapper.mapFromModal(
                    this.policyProtocolToEdit.id,
                    this.policyProtocolToEdit.entity,
                    values,
                ),
            );

        if (editPolicyProtocolResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadPolicyProtocols(): Promise<void> {
        await this.getPolicyProtocols();
    }

    async deletePolicyProtocol(policyProtocolId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deletePolicyProtocolUseCase.execute(policyProtocolId);
        LoadLayoutStore.finish();
    }

    async getAllPolicyProtocolTypes(): Promise<void> {
        const policyProtocolTypes =
            await this.getAllPolicyProtocolTypesUseCase.execute();

        runInAction(() => {
            this.policyProtocolTypes = policyProtocolTypes;
        });
    }
}
