import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { PublicUtilityRepository } from "@entity/data/repositories/public-utility.repository";
import { EditPublicUtility } from "@entity/domain/models/public-utility/edit-public-utility.model";
import { PublicUtility } from "@entity/domain/models/public-utility/public-utility.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditPublicUtilityUseCase {
    constructor(
        @inject(PublicUtilityRepository)
        private readonly publicUtilityRepository: PublicUtilityRepository,
    ) {}

    async execute(
        publicUtility: EditPublicUtility,
    ): Promise<Nullable<PublicUtility>> {
        const editResult =
            await this.publicUtilityRepository.edit(publicUtility);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedPublicUtility) => editedPublicUtility,
        );
    }
}
