import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { ActivitiesSummary } from "../../domain/models/activity-summary.model";
import { ActivitiesSummaryDto } from "../dto/activity-summary.dto";
import { ActivitySummaryMapper } from "./activity-summary.mapper";

@injectable()
export class ActivitiesSummaryMapper {
    constructor(
        @inject(ActivitySummaryMapper)
        private readonly activitySummaryMapper: ActivitySummaryMapper,
    ) {}

    map(activitiesSummaryDto: ActivitiesSummaryDto): ActivitiesSummary {
        const errors = validateSync(activitiesSummaryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new ActivitiesSummary([], 0);
        }

        const activitiesSummary = activitiesSummaryDto.results.mapNotNull(
            (activityDto) => this.activitySummaryMapper.map(activityDto),
        );

        return new ActivitiesSummary(
            activitiesSummary,
            activitiesSummaryDto.count,
        );
    }
}
