import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { UserRepository } from "../../data/repositories/user.repository";
import { CreateUser } from "../models/create-user.model";
import { User } from "../models/user.model";

@injectable()
export class CreateUserUseCase {
    constructor(
        @inject(UserRepository)
        private readonly userRepostiory: UserRepository,
    ) {}

    async execute(user: CreateUser): Promise<Nullable<User>> {
        const creationResult = await this.userRepostiory.create(user);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdUser) => createdUser,
        );
    }
}
