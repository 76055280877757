import { useUser } from "@authentication/presentation/hooks/use-user.hook";
import { Nullable } from "@core/domain/types/nullable.type";
import { container } from "@di/inversify.config";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { HomeRoutes } from "@routes/private/home.routes";
import { ImpersonateEntityUseCase } from "@user/domain/usecases/impersonate-entity.usecase";
import { App } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IncSelect } from "../../component/data-entry/select/inc-select.component";
import { IncCol } from "../../component/layout/col/inc-col.component";
import { IncRow } from "../../component/layout/row/inc-row.component";
import { useDidMount } from "../use-did-mount/use-did-mount.hook";

export interface UseRequireEntityGuardProps {
    navigateTo?: string;
    isOpen?: boolean;
    onCancel?: () => void;
}

export const useRequireEntityGuard = ({
    navigateTo,
    isOpen = false,
    onCancel,
}: UseRequireEntityGuardProps): void => {
    const [entities, setEntities] = useState<GlobalEntity[]>([]);
    const { modal } = App.useApp();

    const user = useUser();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const loadEntities = useCallback(async () => {
        const getAllGlobalEntitiesUseCase =
            container.get<GetAllGlobalEntitiesUseCase>(
                GetAllGlobalEntitiesUseCase,
            );
        const loadedEntities = await getAllGlobalEntitiesUseCase.execute();
        setEntities(loadedEntities);
    }, []);

    const impersonateEntity = async (
        entityId: Nullable<number>,
    ): Promise<void> => {
        const impersonateEntityUseCase =
            container.get<ImpersonateEntityUseCase>(ImpersonateEntityUseCase);

        await impersonateEntityUseCase.execute(entityId);
    };

    const handleSelectEntity = useCallback(
        async (entityId: Nullable<number>) => {
            navigate(HomeRoutes.HOME);
            await impersonateEntity(entityId);
        },
        [navigate],
    );

    const handleCloseNotAllowedModal = useCallback(() => {
        if (onCancel && navigateTo) {
            console.warn(
                "onCancel and navigateTo are mutually exclusive, please use only one of them",
            );
        }

        if (onCancel) {
            onCancel();
        } else if (navigateTo) {
            navigate(navigateTo);
        }
        return false;
    }, [navigate, navigateTo, onCancel]);

    useDidMount(async () => {
        if (user.notImpersonatedEntity()) {
            await loadEntities();
        }
    });

    useEffect(() => {
        if (entities.length > 0 && user.notImpersonatedEntity() && isOpen) {
            modal.info({
                onCancel: handleCloseNotAllowedModal,
                closable: true,
                icon: null,
                footer: false,
                wrapClassName: "ant-modal-content",
                className: "ant-modal-content",
                rootClassName: "ant-modal-content",
                mask: false,
                width: 720,
                content: (
                    <IncRow justify={"center"}>
                        <IncCol span={24}>
                            <IncRow justify={"center"}>
                                <p
                                    className={
                                        "tw-font-bold !tw-text-cyan-dark tw-text-lg "
                                    }
                                >
                                    {t("common:entityRequired")}
                                </p>
                            </IncRow>
                            <IncRow
                                className={"tw-font-bold tw-pb-2"}
                                justify={"center"}
                                gutter={["none", "small"]}
                            >
                                <IncCol span={12}>
                                    <IncSelect
                                        className={"!tw-w-full"}
                                        onChange={handleSelectEntity}
                                    >
                                        {entities.map((entity) => (
                                            <IncSelect.Option
                                                key={entity.id}
                                                value={entity.id}
                                            >
                                                {entity.name}
                                            </IncSelect.Option>
                                        ))}
                                    </IncSelect>
                                </IncCol>
                            </IncRow>
                        </IncCol>
                    </IncRow>
                ),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entities, isOpen, user.entityId]);
};
