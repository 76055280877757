import { Undefinable } from "@core/domain/types/undefinable.type";
import { BudgetCategoryConcept } from "@project/domain/models/budget/financial-entity-budget.model";

export class CreateBudgetCategoryConcept {
    constructor(
        public name: BudgetCategoryConcept["name"],
        public category: BudgetCategoryConcept["category"],
        public group: Undefinable<BudgetCategoryConcept["group"]>,
    ) {}
}
