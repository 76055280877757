export const EXPAND_ALL = "~all";

export type FieldsQuery = {
    // Comma separated fields of the entity
    fields?: string;

    /**
     * Comma separated fields of the entity to expand.
     * Use ~all to get all expanded
     * @see EXPAND_ALL
     */
    expand?: string;
};
