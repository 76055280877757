import { DateTime } from "luxon";

export class CreateRoom {
    constructor(
        public name: Room["name"],
        public positions: Room["positions"],
        public localId?: Room["localId"],
    ) {}
}

export class Room {
    public created?: DateTime;
    public modified?: DateTime;

    constructor(
        public readonly id: number,
        public localId: number,
        public name: string,
        public positions: number,
    ) {}
}

export class Rooms {
    constructor(
        public readonly rooms: Room[],
        public readonly count: number,
    ) {}
}
