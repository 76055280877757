import {
    EditBeneficiaryBody,
    EditBeneficiaryIdentificationBody,
} from "@beneficiary/data/dto/edit-beneficiary.body";
import { Address } from "@beneficiary/domain/models/address.model";
import { BeneficiaryIdentification } from "@beneficiary/domain/models/beneficiary.model";
import { CreateBeneficiaryIdentification } from "@beneficiary/domain/models/create-beneficiary.model";
import { EditBeneficiary } from "@beneficiary/domain/models/edit-beneficiary.model";
import { EditRelative } from "@beneficiary/domain/models/relatives/edit-relative.model";
import { EditBeneficiaryFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/beneficiary-form.component";
import {
    Undefinable,
    convertEmptyToUndefined,
} from "@core/domain/types/undefinable.type";
import { injectable } from "inversify";

@injectable()
export class EditBeneficiaryMapper {
    mapFromBeneficiaryFormValues(
        beneficiaryId: number,
        beneficiaryForm: EditBeneficiaryFormValuesValidated,
    ): EditBeneficiary {
        let usualAddress: Undefinable<Address> = undefined;

        const newIdentificationFormValues =
            beneficiaryForm.general.identificationObject.filter(
                (identification) => typeof identification.uuid === "string",
            );
        const editIdentificationFormValues =
            beneficiaryForm.general.identificationObject.filter(
                (identification) => typeof identification.uuid === "number",
            );

        const newBeneficiariyIdentifications: CreateBeneficiaryIdentification[] =
            newIdentificationFormValues.map(
                (identification) =>
                    new CreateBeneficiaryIdentification(
                        identification.documentType,
                        identification.documentNumber,
                        convertEmptyToUndefined(
                            identification.documentExpiration,
                        ),
                        beneficiaryId,
                    ),
            );

        const editBeneficiaryIdentifications: BeneficiaryIdentification[] =
            editIdentificationFormValues.map(
                (identification) =>
                    new BeneficiaryIdentification(
                        typeof identification.uuid === "number"
                            ? identification.uuid
                            : -1,
                        identification.documentType,
                        identification.documentNumber,
                        convertEmptyToUndefined(
                            identification.documentExpiration,
                        ),
                        beneficiaryId,
                    ),
            );

        if (beneficiaryForm.address.usualAddress) {
            if (!beneficiaryForm.address.usualAddress.id)
                throw new Error("usualAddress.id is required.");

            usualAddress = new Address(
                beneficiaryForm.address.usualAddress.id,
                beneficiaryForm.address.usualAddress.address,
                beneficiaryForm.address.usualAddress.number,
                beneficiaryForm.address.usualAddress.postalCode,
                beneficiaryForm.address.usualAddress.city,
            );
            if (beneficiaryForm.address.usualAddress.district)
                usualAddress.district =
                    beneficiaryForm.address.usualAddress.district;
            if (beneficiaryForm.address.usualAddress.stair)
                usualAddress.stair = beneficiaryForm.address.usualAddress.stair;
            if (beneficiaryForm.address.usualAddress.door)
                usualAddress.door = beneficiaryForm.address.usualAddress.door;
            if (beneficiaryForm.address.usualAddress.floor)
                usualAddress.floor = beneficiaryForm.address.usualAddress.floor;
            if (beneficiaryForm.address.usualAddress.letter)
                usualAddress.letter =
                    beneficiaryForm.address.usualAddress.letter;
        }

        if (!beneficiaryForm.address.censusAddress.id)
            throw new Error("usualAddress.id is required.");

        const censusAddress = new Address(
            beneficiaryForm.address.censusAddress.id,
            beneficiaryForm.address.censusAddress.address,
            beneficiaryForm.address.censusAddress.number,
            beneficiaryForm.address.censusAddress.postalCode,
            beneficiaryForm.address.censusAddress.city,
        );
        if (beneficiaryForm.address.censusAddress.district)
            censusAddress.district =
                beneficiaryForm.address.censusAddress.district;
        if (beneficiaryForm.address.censusAddress.stair)
            censusAddress.stair = beneficiaryForm.address.censusAddress.stair;
        if (beneficiaryForm.address.censusAddress.door)
            censusAddress.door = beneficiaryForm.address.censusAddress.door;
        if (beneficiaryForm.address.censusAddress.floor)
            censusAddress.floor = beneficiaryForm.address.censusAddress.floor;
        if (beneficiaryForm.address.censusAddress.letter)
            censusAddress.letter = beneficiaryForm.address.censusAddress.letter;

        const relatives: EditRelative[] = [];

        const documentIds = beneficiaryForm.documents.documents.map(
            (document) => document.id,
        );

        return new EditBeneficiary(
            beneficiaryId,
            beneficiaryForm.general.name,
            beneficiaryForm.general.surname,
            newBeneficiariyIdentifications,
            editBeneficiaryIdentifications,
            beneficiaryForm.general.gender,
            beneficiaryForm.general.phone ?? undefined,
            beneficiaryForm.general.phoneOwner ?? undefined,
            beneficiaryForm.general.email ?? undefined,
            beneficiaryForm.general.emailOwner ?? undefined,
            beneficiaryForm.general.nationality,
            beneficiaryForm.general.countryOfOrigin,
            beneficiaryForm.general.birthDate,
            beneficiaryForm.general.maritalStatus,
            beneficiaryForm.general.levelOfEducation,
            beneficiaryForm.general.complementaryTraining ?? undefined,
            beneficiaryForm.general.employmentSituation,
            beneficiaryForm.general.employmentSituationDescription ?? undefined,
            beneficiaryForm.general.otherEmploymentDescription ?? null,
            beneficiaryForm.general.disability,
            beneficiaryForm.general.disabilityType
                ? beneficiaryForm.general.disabilityType
                : null,
            beneficiaryForm.general.healthObservations,
            beneficiaryForm.general.dependence,
            beneficiaryForm.general.dependence
                ? beneficiaryForm.general.dependenceGrade
                : null,
            beneficiaryForm.general.inTherapy,
            beneficiaryForm.general.inTherapy
                ? beneficiaryForm.general.therapyType
                : null,
            beneficiaryForm.general.isDiagnosed,
            beneficiaryForm.general.diagnosisType,
            beneficiaryForm.general.diagnosisDate,
            beneficiaryForm.general.hasLegalGuardian,
            beneficiaryForm.general.associateServiceToDependence,
            beneficiaryForm.general.dependenceDiagnosis,
            beneficiaryForm.general.dependenceObservations,
            beneficiaryForm.general.underProtection,
            censusAddress,
            relatives,
            usualAddress,
            documentIds,
        );
    }

    mapToDto(editBeneficiary: EditBeneficiary): EditBeneficiaryBody {
        const beneficiaryDto: EditBeneficiaryBody =
            this.mapBeneficiaryBase(editBeneficiary);

        beneficiaryDto.census_address = editBeneficiary.censusAddress.id;

        return beneficiaryDto;
    }

    mapBeneficiaryBase(editBeneficiary: EditBeneficiary): EditBeneficiaryBody {
        return {
            name: editBeneficiary.name,
            surname: editBeneficiary.surname,
            gender: editBeneficiary.gender,
            phone: convertEmptyToUndefined(editBeneficiary.phone),
            phone_owner: convertEmptyToUndefined(editBeneficiary.phone_owner),
            email: convertEmptyToUndefined(editBeneficiary.email),
            email_owner: convertEmptyToUndefined(editBeneficiary.email_owner),
            nationality: editBeneficiary.nationality,
            country_of_origin: editBeneficiary.country_of_origin,
            birth_date: editBeneficiary.birth_date.toISODate() ?? "",
            marital_status: editBeneficiary.marital_status,
            level_of_education: editBeneficiary.level_of_education,
            complementary_training: convertEmptyToUndefined(
                editBeneficiary.complementary_training,
            ),
            employment_situation: editBeneficiary.employment_situation,
            employment_situation_relevant_characteristics:
                editBeneficiary.employmentSituationDescription
                    ? editBeneficiary.employmentSituationDescription
                    : null,
            other_employment_situation_relevant_characteristics:
                editBeneficiary.otherEmploymentDescription ?? null,
            disability: editBeneficiary.disability,
            disability_types: editBeneficiary.disability_type,
            health_observations: editBeneficiary.health_observations,
            has_dependence: editBeneficiary.dependence,
            dependence_grade: convertEmptyToUndefined(
                editBeneficiary.dependence_grade,
            ),
            in_therapy: editBeneficiary.in_therapy,
            therapy_type: editBeneficiary.therapy_type,
            is_diagnosed: editBeneficiary.isDiagnosed,
            diagnosis_type: editBeneficiary.diagnosisType ?? "",
            diagnosis_date: editBeneficiary.diagnosisDate?.toISODate() ?? null,
            has_legal_guardian: editBeneficiary.hasLegalGuardian,
            associate_service_to_dependence: convertEmptyToUndefined(
                editBeneficiary.associateServiceToDependence,
            ),
            dependence_diagnosis: editBeneficiary.dependenceDiagnosis,
            dependence_observations: editBeneficiary.dependenceObservations,

            census_address: editBeneficiary.censusAddress.id,
            family_members: undefined,
            under_protection: editBeneficiary.under_protection,
            documents: convertEmptyToUndefined(editBeneficiary.documentIds),
        };
    }

    mapIdentificationToDto(
        editBeneficiaryIdentification: BeneficiaryIdentification,
    ): EditBeneficiaryIdentificationBody {
        return {
            identification_type:
                editBeneficiaryIdentification.identificationType,
            identification: convertEmptyToUndefined(
                editBeneficiaryIdentification.identification,
            ),
            identification_expiration: convertEmptyToUndefined(
                editBeneficiaryIdentification.identificationExpiration?.toISODate(),
            ),
            beneficiary: editBeneficiaryIdentification.beneficiary,
        };
    }
}
