import { ExportProjectDepositsDto } from "@entity/data/dto/export-project-deposits.dto";
import { ExportProjectDeposits } from "@entity/domain/models/export-project-deposits.model";
import { ExportProjectDepositFormValuesValidated } from "@entity/presentation/component/entity-form/deposits/export-project-deposits-modal/export-project-deposit-modal-form.component";
import { injectable } from "inversify";

@injectable()
export class ExportProjectDepositsMapper {
    mapToDeposit(
        values: ExportProjectDepositFormValuesValidated,
    ): ExportProjectDeposits {
        return new ExportProjectDeposits(
            values.id,
            values.projectIds,
            values.depositDateFrom?.toString(),
            values.depositDateTo?.toString(),
        );
    }
    mapToExportProjectDepositDto(
        exportDeposits: ExportProjectDeposits,
    ): ExportProjectDepositsDto {
        const exportDepositsDto = new ExportProjectDepositsDto();

        exportDepositsDto.id = exportDeposits.id;
        exportDepositsDto.project_ids = exportDeposits.projectIds;
        exportDepositsDto.report_start_date =
            exportDeposits.depositDateFrom ?? null;
        exportDepositsDto.report_end_date =
            exportDeposits.depositDateTo ?? null;

        return exportDepositsDto;
    }
}
