import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Nullable } from "@core/domain/types/nullable.type";
import { ObjectiveSearchFilters } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { inject, injectable } from "inversify";
import {
    CreateObjective,
    EditObjective,
    Objective,
} from "../../../domain/models/technical-proposals.model";
import { ObjectiveDataSource } from "../../datasource/technical-proposal/objective.datasource";

@injectable()
export class ObjectiveRepository {
    constructor(
        @inject(ObjectiveDataSource)
        private readonly objectiveDataSource: ObjectiveDataSource,
    ) {}

    async getAllBy(
        filters: ObjectiveSearchFilters,
    ): Promise<Either<FallbackError, Objective[]>> {
        const pagination: Pagination = Pagination.NoPagination();
        return this.objectiveDataSource.fetchBy(pagination, filters);
    }

    async getById(
        objectiveId: number,
    ): Promise<Either<FallbackError, Nullable<Objective>>> {
        return this.objectiveDataSource.fetchById(objectiveId);
    }

    async edit(
        objective: EditObjective,
    ): Promise<Either<ValidationError | FallbackError, Objective>> {
        return this.objectiveDataSource.edit(objective);
    }

    async create(
        objective: CreateObjective,
    ): Promise<Either<ValidationError | FallbackError, Objective>> {
        return this.objectiveDataSource.create(objective);
    }

    async delete(objectiveId: number): Promise<Either<FallbackError, true>> {
        return this.objectiveDataSource.delete(objectiveId);
    }
}
