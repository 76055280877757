import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum PaymentMethodTypeEnumDto {
    bank = "bank",
    credit_card = "credit_card",
    cash = "cash",
    domiciliation = "domiciliation",
    other = "other",
}

export class PaymentMethodTypeDto {
    @IsEnum(PaymentMethodTypeEnumDto)
    @IsNotEmpty()
    key!: PaymentMethodTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
