import { Nullable } from "@core/domain/types/nullable.type";
import { CategoryConceptBudgetDto } from "@project/data/dto/budget.dto";
import { BudgetCategoryConcept } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class BudgetCategoryConceptMapper {
    map(
        budgetCategoryConceptDto: CategoryConceptBudgetDto,
    ): Nullable<BudgetCategoryConcept> {
        const errors = validateSync(budgetCategoryConceptDto);
        if (errors.length) {
            console.error(errors);

            return null;
        }

        return new BudgetCategoryConcept(
            budgetCategoryConceptDto.id,
            budgetCategoryConceptDto.name,
            budgetCategoryConceptDto.category,
            budgetCategoryConceptDto.group ?? undefined,
        );
    }
}
