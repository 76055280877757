import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { CreateQualityCertificateBody } from "@entity/data/dto/quality-framework/quality-certificate/create-quality-certificate.body";
import { QualityCertificateTypeEnumMapper } from "@entity/data/mappers/quality-certificate-type-enum.mapper";
import { CreateQualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/create-quality-certificate.model";
import { QualityCertificateFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/quality-certificate/form/quality-certificate-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreateQualityCertificateMapper {
    constructor(
        @inject(QualityCertificateTypeEnumMapper)
        private readonly qualityCertificateTypeEnumMapper: QualityCertificateTypeEnumMapper,
    ) {}
    mapToCreateDto(
        createQualityCertificate: CreateQualityCertificate,
    ): CreateQualityCertificateBody {
        const quality_certificate_type =
            this.qualityCertificateTypeEnumMapper.mapToDto(
                createQualityCertificate.qualityCertificateType,
            );

        const QualityCertificateDto: CreateQualityCertificateBody = {
            quality_certificate_type,
            entity: createQualityCertificate.entity,
            year: createQualityCertificate.year,
            expiration_date: createQualityCertificate.expirationDate
                ? createQualityCertificate.expirationDate.toISODate()
                : undefined,
            other_quality_certificate:
                createQualityCertificate.otherQualityCertificate,
        };

        return QualityCertificateDto;
    }

    mapFromFormValues(
        entityId: number,
        qualityCertificate: QualityCertificateFormValuesValidated,
    ): CreateQualityCertificate {
        const createQualityCertificate: CreateQualityCertificate =
            new CreateQualityCertificate(
                qualityCertificate.qualityCertificateType,
                entityId,
                qualityCertificate.year.year,
                convertEmptyToUndefined(qualityCertificate.expirationDate),
                qualityCertificate.otherQualityCertificate,
            );

        return createQualityCertificate;
    }
}
