import { GlobalEntityRepository } from "@entity/data/repositories/global-entity.repository";
import { inject, injectable } from "inversify";
import { action, computed, makeObservable } from "mobx";
import {
    GlobalEntity,
    GlobalEntitySearchFilters,
} from "../models/global-entity.model";

@injectable()
export class GetAllGlobalEntitiesUseCase {
    constructor(
        @inject(GlobalEntityRepository)
        private readonly globalEntityRepository: GlobalEntityRepository,
    ) {
        makeObservable(this);
    }

    @action
    async execute(
        filters?: GlobalEntitySearchFilters,
    ): Promise<GlobalEntity[]> {
        await this.globalEntityRepository.getAll(filters);

        return this.globalEntityRepository.globalEntities;
    }

    @computed
    get globalEntities(): GlobalEntity[] {
        return this.globalEntityRepository.globalEntities;
    }
}
