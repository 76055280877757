import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { BeneficiaryRepository } from "../../data/repositories/beneficiary.repository";
import { BeneficiaryIdentificationType } from "../models/identification-document-type.model";

@injectable()
// eslint-disable-next-line id-length
export class GetAllBeneficiaryIdentificationTypesUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly employeeRepository: BeneficiaryRepository,
    ) {}

    async execute(): Promise<BeneficiaryIdentificationType[]> {
        const identificationTypesResult =
            await this.employeeRepository.getAllIdentificationTypes();

        if (identificationTypesResult.isLeft()) {
            ToastManagerStore.error(
                identificationTypesResult.getLeftOrThrow().message,
            );
        }

        return identificationTypesResult.fold(
            () => [],
            (identificationTypes) => identificationTypes,
        );
    }
}
