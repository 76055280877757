import { Nullish } from "@core/domain/types/nullish.type";
import { IsNotEmpty, IsNumber, IsString } from "class-validator";
import { CatalogueEnumDto } from "./catalogue.dto";
import { FinancialEntityTypeEnumDto } from "./financial-entity/financial-entity-type.dto";
import { StatusEnumDto } from "./status.dto";

export class GlobalProjectDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;
}

export type GlobalProjectsQuery = {
    search?: Nullish<string>;
    catalogue?: Nullish<CatalogueEnumDto>;
    status?: Nullish<StatusEnumDto>;
    start_date?: Nullish<string>;
    end_date?: Nullish<string>;
    active_from_date?: Nullish<string>;
    active_to_date?: Nullish<string>;
    entity?: Nullish<number>;
    finantial_entity_type?: Nullish<FinancialEntityTypeEnumDto>;
    is_active?: Nullish<boolean>;
};
