import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Material } from "@entity/domain/models/material/material.model";
import { EditMaterialUseCase } from "@entity/domain/usecases/material/edit-material.usecase";
import { GetMaterialByIdUseCase } from "@entity/domain/usecases/material/get-material-by-id.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable } from "mobx";
import { MaterialFormValuesValidated } from "../../../component/material-form/material-form";

@injectable()
export class EditMaterialPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    private _material: Undefinable<Material> = undefined;

    constructor(
        @inject(EditMaterialUseCase)
        private readonly editMaterialUseCase: EditMaterialUseCase,
        @inject(GetMaterialByIdUseCase)
        private readonly getMaterialByIdUseCase: GetMaterialByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    get material(): Undefinable<Material> {
        return this._material;
    }

    async editMaterial(
        materialToEdit: MaterialFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();
        await this.editMaterialUseCase.execute(materialToEdit);
        ToastManagerStore.success();
        LoadLayoutStore.finish();
    }

    async fetchMaterialById(materialId: number): Promise<void> {
        LoadLayoutStore.start();
        const material = await this.getMaterialByIdUseCase.execute(materialId);
        this._material = material;
        this.initialLoading = false;
        LoadLayoutStore.finish();
    }
}
