import { Pagination } from "@core/domain/models/pagination";
import { RegisteredRecordRepository } from "@entity/data/repositories/registered-record.repository";
import { RegisteredRecord } from "@entity/domain/models/registered-record/registered-record.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllRegisteredRecordsUsecase {
    constructor(
        @inject(RegisteredRecordRepository)
        private readonly registeredRecordRepository: RegisteredRecordRepository,
    ) {}

    async execute(): Promise<RegisteredRecord[]> {
        const registeredRecordsResult =
            await this.registeredRecordRepository.getAll(
                Pagination.NoPagination(),
            );

        return registeredRecordsResult.fold(
            () => [],
            (registeredRecords) => registeredRecords.registeredRecords,
        );
    }
}
