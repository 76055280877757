import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Nullable } from "@core/domain/types/nullable.type";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import {
    CreateObjective,
    EditObjective,
    Objective,
} from "../../../domain/models/technical-proposals.model";
import {
    ObjectiveQuery,
    ObjectiveSearchFilters,
    TechnicalProposalObjectiveDto,
    TechnicalProposalObjectivesDto,
} from "../../dto/technical-proposal/technical-proposal.dto";
import { ObjectiveMapper } from "../../mappers/technical-proposal/objective.mapper";

const OBJECTIVES_PATH = "/technical_proposals_objetives/";

@injectable()
export class ObjectiveDataSource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(ObjectiveMapper)
        private readonly objectiveMapper: ObjectiveMapper,
    ) {}

    async edit(
        objective: EditObjective,
    ): Promise<Either<ValidationError | FallbackError, Objective>> {
        const objectiveBody = this.objectiveMapper.mapToEditDto(objective);

        const editObjectiveResult =
            await this.http.patch<TechnicalProposalObjectiveDto>(
                `${OBJECTIVES_PATH}${objective.id}/`,
                objectiveBody,
            );

        return editObjectiveResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const objectiveResult = this.objectiveMapper.map(
                    plainToClass(TechnicalProposalObjectiveDto, response.data),
                );

                if (!objectiveResult) return Either.Left(new FallbackError());

                return Either.Right(objectiveResult);
            });
    }

    async create(
        objective: CreateObjective,
    ): Promise<Either<ValidationError | FallbackError, Objective>> {
        const objectiveBody = this.objectiveMapper.mapToCreateDto(objective);

        const createObjectiveResult =
            await this.http.post<TechnicalProposalObjectiveDto>(
                OBJECTIVES_PATH,
                objectiveBody,
            );

        return createObjectiveResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const objectiveResponse = this.objectiveMapper.map(
                    plainToClass(TechnicalProposalObjectiveDto, response.data),
                );

                if (!objectiveResponse) return Either.Left(new FallbackError());

                return Either.Right(objectiveResponse);
            });
    }

    async delete(objectiveId: number): Promise<Either<FallbackError, true>> {
        const deleteResult = await this.http.delete(
            `${OBJECTIVES_PATH}${objectiveId}/`,
        );

        return deleteResult.mapLeft(() => new FallbackError()).map(() => true);
    }

    async fetchBy(
        pagination: Pagination,
        filters: ObjectiveSearchFilters,
    ): Promise<Either<FallbackError, Objective[]>> {
        const query: ObjectiveQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        const { project, technicalProposal } = filters;

        if (project) query.project = project;
        if (technicalProposal) query.technical_proposal = technicalProposal;

        const responseResult =
            await this.http.get<TechnicalProposalObjectivesDto>(
                OBJECTIVES_PATH,
                {
                    query,
                },
            );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.results.mapNotNull(
                    (technicalProposalObjectiveDto) =>
                        this.objectiveMapper.map(
                            plainToClass(
                                TechnicalProposalObjectiveDto,
                                technicalProposalObjectiveDto,
                            ),
                        ),
                ),
            );
    }

    async fetchById(
        objectiveId: number,
    ): Promise<Either<FallbackError, Nullable<Objective>>> {
        const objectiveResult = await this.http.get(
            `${OBJECTIVES_PATH}${objectiveId}/`,
        );

        return objectiveResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.objectiveMapper.map(
                    plainToClass(TechnicalProposalObjectiveDto, response.data),
                ),
            );
    }
}
