import { GlobalProjectRepository } from "@project/data/repositories/global-project.repository";
import { inject, injectable } from "inversify";
import {
    GlobalProject,
    GlobalProjectFilters,
} from "../models/global-project.model";

@injectable()
export class GetAllGlobalProjectsByUseCase {
    constructor(
        @inject(GlobalProjectRepository)
        private readonly globalProjectRepository: GlobalProjectRepository,
    ) {}

    async execute(filters?: GlobalProjectFilters): Promise<GlobalProject[]> {
        const projectsResult =
            await this.globalProjectRepository.getAllBy(filters);

        return projectsResult.fold(
            () => [],
            (globalProjects) => globalProjects,
        );
    }
}
