import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { CreateBudgetCategoryConcept } from "@project/domain/models/budget/budget-category/concepts/create-budget-category-concept.model";
import { BudgetCategoryConcept } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateBudgetCategoryConceptUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(
        budgetCategoryConcept: CreateBudgetCategoryConcept,
    ): Promise<Nullable<BudgetCategoryConcept>> {
        const result = await this.budgetRepository.createCategoryConcept(
            budgetCategoryConcept,
        );
        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }
        return result.fold(
            () => null,
            (createdConcept) => createdConcept,
        );
    }
}
