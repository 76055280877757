import { Type } from "class-transformer";
import { IsEnum, IsNotEmpty, IsString, ValidateNested } from "class-validator";

export enum DepositTypeEnumDto {
    OWN_FINANCING = "own_financing",
    ANOTHER_SOURCES_FINANCING = "another_sources_financing",
    SUBVENTION = "subvention",
    OTHER = "other",
}

export enum DepositOwnFinancingTypeEnumDto {
    FUNDRAISING_CAMPAIGNS = "fundraising_campaigns",
    MEMBERSHIP_FEE = "membership_fee",
    DONATION = "donation",
    OTHERS_INCOME = "others_income",
    "" = "",
}

export enum DepositAnotherSourcesFinancingTypeEnumDto {
    CONTRACTING_SERVICES_PUBLIC_ADMINISTRATION = "contracting_services_public_administration",
    CONTRACTING_SERVICES_PRIVATE_ORGANIZATION = "contracting_services_private_organization",
    HIRING_SERVICES_PERSON = "hiring_services_person",
    SALE_PRODUCTS = "sale_products",
    "" = "",
}

export enum DepositSubventionTypeEnumDto {
    PRIVATE_SUBVENTION = "private_subvention",
    PUBLIC_SUBVENTION = "public_subvention",
    "" = "",
}
export class DepositTypesDto {
    @IsEnum(DepositTypeEnumDto)
    @IsNotEmpty()
    key!: DepositTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
export class DepositOwnFinancingTypesDto {
    @IsEnum(DepositOwnFinancingTypeEnumDto)
    @IsNotEmpty()
    key!: DepositOwnFinancingTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}

export class DepositAnotherSourcesFinancingTypesDto {
    @IsEnum(DepositAnotherSourcesFinancingTypeEnumDto)
    @IsNotEmpty()
    key!: DepositAnotherSourcesFinancingTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}

export class DepositSubventionTypesDto {
    @IsEnum(DepositSubventionTypeEnumDto)
    @IsNotEmpty()
    key!: DepositSubventionTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}

export class DepositGeneralTypesDto {
    @ValidateNested()
    @Type(() => DepositTypesDto)
    @IsNotEmpty()
    types!: DepositTypesDto[];

    @ValidateNested()
    @Type(() => DepositOwnFinancingTypesDto)
    @IsNotEmpty()
    own_financing_types!: DepositOwnFinancingTypesDto[];

    @ValidateNested()
    @Type(() => DepositSubventionTypesDto)
    @IsNotEmpty()
    subvention_types!: DepositSubventionTypesDto[];

    @ValidateNested()
    @Type(() => DepositAnotherSourcesFinancingTypesDto)
    @IsNotEmpty()
    others_source_financing_type!: DepositAnotherSourcesFinancingTypesDto[];
}
