export enum ExpenseRecurrenceEnum {
    Monthly = "monthly",
    Yearly = "yearly",
}
export class ExpenseRecurrence {
    constructor(
        public readonly id: ExpenseRecurrenceEnum,
        public readonly label: string,
    ) {}
}
