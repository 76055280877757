import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { DepositAnotherSourcesFinancingTypeEnum } from "../../domain/models/deposit-type.model";
import { DepositAnotherSourcesFinancingTypeEnumDto } from "../dto/deposit-types.dto";

@injectable()
export class DepositAnotherSourceTypeEnumMapper {
    map(
        depositAnotherSourceTypeEnumDto: DepositAnotherSourcesFinancingTypeEnumDto,
    ): Nullable<DepositAnotherSourcesFinancingTypeEnum> {
        switch (depositAnotherSourceTypeEnumDto) {
            case DepositAnotherSourcesFinancingTypeEnumDto.CONTRACTING_SERVICES_PUBLIC_ADMINISTRATION:
                return DepositAnotherSourcesFinancingTypeEnum.CONTRACTING_SERVICES_PUBLIC_ADMINISTRATION;
            case DepositAnotherSourcesFinancingTypeEnumDto.CONTRACTING_SERVICES_PRIVATE_ORGANIZATION:
                return DepositAnotherSourcesFinancingTypeEnum.CONTRACTING_SERVICES_PRIVATE_ORGANIZATION;
            case DepositAnotherSourcesFinancingTypeEnumDto.HIRING_SERVICES_PERSON:
                return DepositAnotherSourcesFinancingTypeEnum.HIRING_SERVICES_PERSON;
            case DepositAnotherSourcesFinancingTypeEnumDto.SALE_PRODUCTS:
                return DepositAnotherSourcesFinancingTypeEnum.SALE_PRODUCTS;
            default:
                return null;
        }
    }

    mapToDto(
        depositAnotherSourceTypeEnum: DepositAnotherSourcesFinancingTypeEnum,
    ): DepositAnotherSourcesFinancingTypeEnumDto {
        switch (depositAnotherSourceTypeEnum) {
            case DepositAnotherSourcesFinancingTypeEnum.CONTRACTING_SERVICES_PUBLIC_ADMINISTRATION:
                return DepositAnotherSourcesFinancingTypeEnumDto.CONTRACTING_SERVICES_PUBLIC_ADMINISTRATION;
            case DepositAnotherSourcesFinancingTypeEnum.CONTRACTING_SERVICES_PRIVATE_ORGANIZATION:
                return DepositAnotherSourcesFinancingTypeEnumDto.CONTRACTING_SERVICES_PRIVATE_ORGANIZATION;
            case DepositAnotherSourcesFinancingTypeEnum.HIRING_SERVICES_PERSON:
                return DepositAnotherSourcesFinancingTypeEnumDto.HIRING_SERVICES_PERSON;
            case DepositAnotherSourcesFinancingTypeEnum.SALE_PRODUCTS:
                return DepositAnotherSourcesFinancingTypeEnumDto.SALE_PRODUCTS;
            default:
                return DepositAnotherSourcesFinancingTypeEnumDto[""];
        }
    }
}
