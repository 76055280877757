import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateBudgetCategoryGroup } from "@project/domain/models/budget/budget-category/groups/create-budget-category-group.model";
import { EditBudgetCategoryGroup } from "@project/domain/models/budget/budget-category/groups/edit-budget-category-group.model";
import {
    BudgetCategoryGroup,
    BudgetCategoryGroups,
} from "@project/domain/models/budget/financial-entity-budget.model";
import { CreateBudgetCategoryGroupUseCase } from "@project/domain/usecases/budget/categories/groups/create-category-group.usecase";
import { DeleteBudgetCategoryGroupUseCase } from "@project/domain/usecases/budget/categories/groups/delete-category-group.usecase";
import { EditCategoryGroupUseCase } from "@project/domain/usecases/budget/categories/groups/edit-category-group.usecase";
import { GetAllCategoryGroupsByCategoryIdUseCase } from "@project/domain/usecases/budget/categories/groups/get-all-category-groups-by-category-id.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class BudgetCategoryGroupsViewModel extends BaseViewModel {
    _categoryId: Nullable<number> = null;

    @observable
    groups: BudgetCategoryGroups = new BudgetCategoryGroups([], 0);

    @observable
    openAddModal: boolean = false;

    @observable
    openEditModal: boolean = false;

    @observable
    groupToEdit: Undefinable<BudgetCategoryGroup>;
    constructor(
        @inject(GetAllCategoryGroupsByCategoryIdUseCase)
        private readonly getAllCategoryGroupsByCategoryIdUseCase: GetAllCategoryGroupsByCategoryIdUseCase,
        @inject(DeleteBudgetCategoryGroupUseCase)
        private readonly deleteCategoryGroupUseCase: DeleteBudgetCategoryGroupUseCase,
        @inject(CreateBudgetCategoryGroupUseCase)
        private readonly createCategoryGroupUseCase: CreateBudgetCategoryGroupUseCase,
        @inject(EditCategoryGroupUseCase)
        private readonly editCategoryGroupUseCase: EditCategoryGroupUseCase,
    ) {
        super();
        makeObservable(this);
    }
    override async didMount(): Promise<void> {
        await super.didMount();
        await this.initViewData();
    }
    async initViewData(): Promise<void> {
        await this.getGroups();
    }
    async getGroups(): Promise<void> {
        if (!this._categoryId) {
            return;
        }
        const groups =
            await this.getAllCategoryGroupsByCategoryIdUseCase.execute(
                this._categoryId,
            );
        runInAction(() => {
            this.groups = groups;
        });
    }
    handleOpenAddModal = (): void => {
        runInAction(() => {
            this.openAddModal = true;
        });
    };
    handleCloseAddModal = (): void => {
        runInAction(() => {
            this.openAddModal = false;
        });
    };

    addGroup = async (values: CreateBudgetCategoryGroup): Promise<void> => {
        if (!this._categoryId) return;

        LoadLayoutStore.start();
        const groups = await this.createCategoryGroupUseCase.execute(
            new CreateBudgetCategoryGroup(values.name, this._categoryId),
        );
        if (groups) {
            ToastManagerStore.success();
        }

        await this.getGroups();
        LoadLayoutStore.finish();
        this.handleCloseAddModal();
    };

    handleOpenEditModal = (group: BudgetCategoryGroup): void => {
        runInAction(() => {
            this.openEditModal = true;
            this.groupToEdit = group;
        });
    };

    handleCloseEditModal = (): void => {
        runInAction(() => {
            this.openEditModal = false;
            this.groupToEdit = undefined;
        });
    };

    editGroup = async (values: EditBudgetCategoryGroup): Promise<void> => {
        if (!this._categoryId) return;
        if (!this.groupToEdit) return;

        LoadLayoutStore.start();

        const result = await this.editCategoryGroupUseCase.execute(
            new EditBudgetCategoryGroup(
                values.id,
                values.name,
                values.category,
            ),
        );

        if (result) {
            ToastManagerStore.success();
        }

        await this.getGroups();
        LoadLayoutStore.finish();
        this.handleCloseEditModal();
    };

    deleteGroup = async (id: number): Promise<void> => {
        LoadLayoutStore.start();
        const result = await this.deleteCategoryGroupUseCase.execute(id);
        if (result) {
            await this.getGroups();
            ToastManagerStore.success();
        }
        LoadLayoutStore.finish();
    };
}
