import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { PartnersNumberRepository } from "@entity/data/repositories/partners-number.repository";
import { CreatePartnersNumber } from "@entity/domain/models/partners-number/create-partners-number.model";
import { PartnersNumber } from "@entity/domain/models/partners-number/partners-number.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreatePartnersNumberUseCase {
    constructor(
        @inject(PartnersNumberRepository)
        private readonly partnersNumberRepository: PartnersNumberRepository,
    ) {}

    async execute(
        partnersNumber: CreatePartnersNumber,
    ): Promise<Nullable<PartnersNumber>> {
        const creationResult =
            await this.partnersNumberRepository.create(partnersNumber);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdPartnersNumber) => createdPartnersNumber,
        );
    }
}
