import { Pagination } from "@core/domain/models/pagination";
import { PartnersNumberRepository } from "@entity/data/repositories/partners-number.repository";
import { PartnersNumber } from "@entity/domain/models/partners-number/partners-number.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllPartnersNumbersUsecase {
    constructor(
        @inject(PartnersNumberRepository)
        private readonly partnersNumberRepository: PartnersNumberRepository,
    ) {}

    async execute(): Promise<PartnersNumber[]> {
        const partnersNumberResults =
            await this.partnersNumberRepository.getAll(
                Pagination.NoPagination(),
            );

        return partnersNumberResults.fold(
            () => [],
            (partnersNumbers) => partnersNumbers.partnersNumbers,
        );
    }
}
