import { CreateRelative } from "@beneficiary/domain/models/relatives/create-relative.model";
import { EditRelative } from "@beneficiary/domain/models/relatives/edit-relative.model";
import {
    Relative,
    RelativesSearchFilters,
} from "@beneficiary/domain/models/relatives/relative.model";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";
import { RelativesDatasource } from "../datasource/relatives.datasource";

@injectable()
export class RelativesRepository {
    constructor(
        @inject(RelativesDatasource)
        private readonly relativesDatasource: RelativesDatasource,
    ) {}

    async getAllBy(
        pagination: Pagination,
        filters?: RelativesSearchFilters,
    ): Promise<Either<FallbackError, Relative[]>> {
        const relativesSummary = await this.relativesDatasource.fetchBy(
            pagination,
            filters,
        );

        return relativesSummary.mapLeft(() => new FallbackError());
    }

    async create(
        createRelative: CreateRelative,
        beneficiaryId: number,
    ): Promise<Either<FallbackError, Relative>> {
        return this.relativesDatasource.create(createRelative, beneficiaryId);
    }

    async edit(
        editRelative: EditRelative,
        beneficiaryId: number,
    ): Promise<Either<FallbackError, Relative>> {
        return this.relativesDatasource.update(editRelative, beneficiaryId);
    }

    async delete(relativeId: number): Promise<Either<FallbackError, true>> {
        const relativeResult =
            await this.relativesDatasource.delete(relativeId);
        return relativeResult.mapLeft(() => new FallbackError());
    }
}
