import {
    PublicItinerariesDto,
    PublicItineraryDto,
} from "@beneficiary/data/dto/public-itinerary/public-itinerary.dto";
import { PublicItineraryMapper } from "@beneficiary/data/mappers/public-itinerary/public-itinerary.mapper";
import {
    PublicItineraries,
    PublicItinerary,
} from "@beneficiary/domain/models/public-itinerary/public-itinerary.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class PublicItinerariesMapper {
    constructor(
        @inject(PublicItineraryMapper)
        private readonly publicItineraryMapper: PublicItineraryMapper,
    ) {}

    map(publicItinerariesDto: PublicItinerariesDto): PublicItineraries {
        const errors = validateSync(publicItinerariesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new PublicItineraries([], 0);
        }

        const publicItineraries = publicItinerariesDto.results.mapNotNull(
            (publicItineraryDto) =>
                this.publicItineraryMapper.map(publicItineraryDto),
        );

        return new PublicItineraries(
            publicItineraries,
            publicItinerariesDto.count,
        );
    }

    mapPublicItinerary(
        publicItineraryDto: PublicItineraryDto,
    ): Nullable<PublicItinerary> {
        const errors = validateSync(publicItineraryDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        return new PublicItinerary(
            publicItineraryDto.id,
            publicItineraryDto.objective,
            publicItineraryDto.actions,
            publicItineraryDto.actions_evaluation,
            publicItineraryDto.beneficiary,
            publicItineraryDto.created
                ? DateTime.fromISO(publicItineraryDto.created)
                : undefined,
            publicItineraryDto.modified
                ? DateTime.fromISO(publicItineraryDto.modified)
                : undefined,
        );
    }
}
