import type { Nullable } from "@core/domain/types/nullable.type";
import {
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { ContractTypeEnumDto } from "./contract-type.dto";
import { ContributionGroupTypeEnumDto } from "./contribution-group-type.dto";
import { ExpenseSubTypeEnumDto } from "./expense-sub-type.dto";
import { ExpenseTypeEnumDto } from "./expense-type.dto";
import { MonthTypeEnumDto } from "./month-type.dto";

export class ManagementDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsDateString()
    @IsNotEmpty()
    created!: string;

    @IsDateString()
    @IsNotEmpty()
    modified!: string;

    @IsBoolean()
    @IsNotEmpty()
    is_active!: boolean;

    @IsEnum(ExpenseTypeEnumDto)
    @IsNotEmpty()
    type_expense!: ExpenseTypeEnumDto;

    @IsEnum(ExpenseSubTypeEnumDto)
    @IsOptional()
    subtype_expense!: ExpenseSubTypeEnumDto;

    @IsString()
    @IsOptional()
    full_name!: Nullable<string>;

    @IsString()
    @IsOptional()
    identification_document!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    contract_start_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    contract_end_date!: Nullable<string>;

    @IsEnum(ContractTypeEnumDto)
    @IsOptional()
    contract_type!: Nullable<ContractTypeEnumDto>;

    @IsNumber()
    @IsOptional()
    weekly_hours!: Nullable<number>;

    @IsString()
    @IsOptional()
    // TODO: Make enum for differents professional groups
    professional_group_exp!: Nullable<string>;

    @IsEnum(ContributionGroupTypeEnumDto)
    @IsOptional()
    contribution_group!: Nullable<ContributionGroupTypeEnumDto>;

    @IsEnum(MonthTypeEnumDto)
    @IsOptional()
    month!: Nullable<MonthTypeEnumDto>;

    @IsNumber()
    @IsOptional()
    days_imputable!: Nullable<number>;

    @IsString()
    @IsOptional()
    net_salary!: Nullable<string>;

    @IsString()
    @IsOptional()
    irpf?: string;

    @IsString()
    @IsOptional()
    social_security!: Nullable<string>;

    @IsString()
    @IsOptional()
    monthly_gross_amount!: Nullable<string>;

    @IsString()
    @IsOptional()
    company_social_security!: Nullable<string>;

    @IsString()
    @IsOptional()
    total_cost!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    amount_allocated_to_project!: string;

    @IsString()
    @IsOptional()
    project_allocation_percentage_month!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    payment_date!: Nullable<string>;

    @IsNumber()
    @IsOptional()
    project_expense!: Nullable<number>;
}
