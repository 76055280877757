import { PaginatedDto } from "@core/data/dto/paginated.dto";
import { AdministrativeRecordScopeEnumDto } from "@entity/data/dto/administrative-record-scope-enum.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class RegisteredRecordDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsEnum(AdministrativeRecordScopeEnumDto)
    @IsOptional()
    registered_administrative_record_scope!: AdministrativeRecordScopeEnumDto;

    @IsString()
    @IsNotEmpty()
    registered_record_name!: string;

    @IsString()
    @IsNotEmpty()
    record_identification_code!: string;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class RegisteredRecordsDto extends PaginatedDto {
    @IsArray()
    @Type(() => RegisteredRecordDto)
    results!: RegisteredRecordDto[];
}
