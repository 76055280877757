import { LoginBody, LoginDto } from "@authentication/data/dto/login.dto";
import { LoginUserMapper } from "@authentication/data/mappers/login-user.mapper";
import { LoginError } from "@authentication/domain/errors/login/login.error";
import { LoginUser } from "@authentication/domain/models/login-user.model";
import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpUnauthorizedError } from "@core/data/infrastructures/http/errors/http-unauthorized.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { InvalidCredentialsError } from "../../domain/errors/login/invalid-credentials.error";

@injectable()
export class AuthenticationRemoteDatasource {
    constructor(
        @inject(LoginUserMapper)
        private readonly loginUserMapper: LoginUserMapper,
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
    ) {}

    async login(
        email: string,
        password: string,
    ): Promise<Either<LoginError, LoginUser>> {
        const body: LoginBody = {
            email,
            password,
        };

        const loginResult = await this.http.post<LoginDto, LoginBody>(
            "/users/login/",
            body,
        );

        return loginResult
            .mapLeft((error) => {
                if (error instanceof HttpUnauthorizedError) {
                    return new InvalidCredentialsError();
                } else if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const loginUser = this.loginUserMapper.map(
                    plainToClass(LoginDto, response.data),
                );

                if (!loginUser) return Either.Left(new FallbackError());

                return Either.Right(loginUser);
            });
    }

    async logout(): Promise<Either<HttpError, true>> {
        const logoutResult = await this.http.post("/users/logout/");

        return logoutResult.map(() => true);
    }

    async resetPassword(
        email: string,
    ): Promise<Either<ValidationError | FallbackError, true>> {
        const resetPasswordResult = await this.http.post(
            "/users/reset_password/",
            {
                email,
            },
        );

        return resetPasswordResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .map(() => true);
    }

    async resetPasswordConfirm(
        token: string,
        password: string,
        confirmPassword: string,
    ): Promise<Either<ValidationError | FallbackError, true>> {
        const resetPasswordConfirmResult = await this.http.post(
            "/users/reset_confirm_password/",
            {
                token,
                new_password1: password,
                new_password2: confirmPassword,
            },
        );

        return resetPasswordConfirmResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .map(() => true);
    }
}
