import { Status } from "@proceeding/domain/models/status.model";
import { inject, injectable } from "inversify";
import { ProceedingRepository } from "../../data/repositories/proceeding.repository";

@injectable()
export class GetAllStatusUseCase {
    constructor(
        @inject(ProceedingRepository)
        private readonly proceedingRepository: ProceedingRepository,
    ) {}

    async execute(): Promise<Status[]> {
        const statusResult = await this.proceedingRepository.getAllStatuses();

        return statusResult.getOrElse([]);
    }
}
