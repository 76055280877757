import { Nullable } from "@core/domain/types/nullable.type";
import { QualityCertificateTypeEnumDto } from "@entity/data/dto/quality-certificate-type.dto";
import { QualityCertificateTypeEnum } from "@entity/domain/models/quality-certificate-type.model";
import { injectable } from "inversify";

@injectable()
export class QualityCertificateTypeEnumMapper {
    map(
        qualityCertificateTypeEnumDto: QualityCertificateTypeEnumDto,
    ): Nullable<QualityCertificateTypeEnum> {
        switch (qualityCertificateTypeEnumDto) {
            case QualityCertificateTypeEnumDto.Iso260002010:
                return QualityCertificateTypeEnum.Iso260002010;
            case QualityCertificateTypeEnumDto.Iso90012015:
                return QualityCertificateTypeEnum.Iso90012015;
            case QualityCertificateTypeEnumDto.Efqm:
                return QualityCertificateTypeEnum.Efqm;
            case QualityCertificateTypeEnumDto.ManagementExcellenceClub:
                return QualityCertificateTypeEnum.ManagementExcellenceClub;
            case QualityCertificateTypeEnumDto.Icong:
                return QualityCertificateTypeEnum.Icong;
            case QualityCertificateTypeEnumDto.FoundationLoyalty:
                return QualityCertificateTypeEnum.FoundationLoyalty;
            case QualityCertificateTypeEnumDto.MasmadridCertification:
                return QualityCertificateTypeEnum.MasmadridCertification;
            case QualityCertificateTypeEnumDto.RscCertificate:
                return QualityCertificateTypeEnum.RscCertificate;
            case QualityCertificateTypeEnumDto.OtherAccreditingCompaniesCertificates:
                return QualityCertificateTypeEnum.OtherAccreditingCompaniesCertificates;
            case QualityCertificateTypeEnumDto.QualityCertificateOther:
                return QualityCertificateTypeEnum.QualityCertificateOther;

            default:
                return null;
        }
    }

    mapToDto(
        qualityCertificateTypeEnum: QualityCertificateTypeEnum,
    ): QualityCertificateTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (qualityCertificateTypeEnum) {
            case QualityCertificateTypeEnum.Iso260002010:
                return QualityCertificateTypeEnumDto.Iso260002010;
            case QualityCertificateTypeEnum.Iso90012015:
                return QualityCertificateTypeEnumDto.Iso90012015;
            case QualityCertificateTypeEnum.Efqm:
                return QualityCertificateTypeEnumDto.Efqm;
            case QualityCertificateTypeEnum.ManagementExcellenceClub:
                return QualityCertificateTypeEnumDto.ManagementExcellenceClub;
            case QualityCertificateTypeEnum.Icong:
                return QualityCertificateTypeEnumDto.Icong;
            case QualityCertificateTypeEnum.FoundationLoyalty:
                return QualityCertificateTypeEnumDto.FoundationLoyalty;
            case QualityCertificateTypeEnum.MasmadridCertification:
                return QualityCertificateTypeEnumDto.MasmadridCertification;
            case QualityCertificateTypeEnum.RscCertificate:
                return QualityCertificateTypeEnumDto.RscCertificate;
            case QualityCertificateTypeEnum.OtherAccreditingCompaniesCertificates:
                return QualityCertificateTypeEnumDto.OtherAccreditingCompaniesCertificates;
            case QualityCertificateTypeEnum.QualityCertificateOther:
                return QualityCertificateTypeEnumDto.QualityCertificateOther;
        }
    }
}
