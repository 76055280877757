import { Nullable } from "@core/domain/types/nullable.type";
import { TerminationReasonEnumDto } from "@entity/data/dto/employee/termination-reason.dto";
import { TerminationReasonEnum } from "@entity/domain/models/employee/termination-reason.model";
import { injectable } from "inversify";

@injectable()
export class TerminationReasonEnumMapper {
    map(
        terminationReasonEnumDto: TerminationReasonEnumDto,
    ): Nullable<TerminationReasonEnum> {
        switch (terminationReasonEnumDto) {
            case TerminationReasonEnumDto.resignation:
                return TerminationReasonEnum.RESIGNATION;
            case TerminationReasonEnumDto.objective_dismissal:
                return TerminationReasonEnum.OBJECTIVE_DISMISSAL;
            default:
                return null;
        }
    }

    mapToDto(
        terminationReasonEnum: TerminationReasonEnum,
    ): TerminationReasonEnumDto {
        // eslint-disable-next-line default-case
        switch (terminationReasonEnum) {
            case TerminationReasonEnum.RESIGNATION:
                return TerminationReasonEnumDto.resignation;
            case TerminationReasonEnum.OBJECTIVE_DISMISSAL:
                return TerminationReasonEnumDto.objective_dismissal;
        }
    }
}
