import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { ActionLine } from "@entity/domain/models/action-line.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllActionLinesUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}
    async execute(): Promise<ActionLine[]> {
        const actionLinesResult =
            await this.entityRepository.getAllActionLines();

        return actionLinesResult.getOrElse([]);
    }
}
