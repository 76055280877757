import { Nullable } from "@core/domain/types/nullable.type";
import { CreateRoomBody } from "@entity/data/dto/locals/create-room.body";
import type { RoomDto } from "@entity/data/dto/locals/room.dto";
import { CreateRoom, Room } from "@entity/domain/models/locals/room.model";
import { RoomFormValuesValidated } from "@entity/presentation/component/entity-form/locals/form/room.form";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class RoomMapper {
    map(roomDto: RoomDto): Nullable<Room> {
        const errors = validateSync(roomDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        if (!roomDto.id) throw new Error("Room id is required");
        // if (!roomDto.created) throw new Error("Room created is required");
        // if (!roomDto.modified) throw new Error("Room modified is required");

        const room = new Room(
            roomDto.id,
            roomDto.local,
            roomDto.name,
            roomDto.positions,
        );

        if (roomDto.created) room.created = DateTime.fromISO(roomDto.created);
        if (roomDto.modified)
            room.modified = DateTime.fromISO(roomDto.modified);

        return room;
    }

    mapToDto(room: Room): RoomDto {
        const created = room.created ? room.created.toISO() : null;
        const modified = room.modified ? room.modified.toISO() : null;

        const roomDto: RoomDto = {
            id: room.id,
            name: room.name,
            positions: room.positions,
            local: room.localId,
            created: created,
            modified: modified,
        };

        return roomDto;
    }

    mapFromFormToCreate(localForm: RoomFormValuesValidated): CreateRoom {
        return new CreateRoom(
            localForm.name,
            localForm.positions,
            localForm.localId,
        );
    }

    mapToCreate(room: CreateRoom): CreateRoomBody {
        if (!room.localId) throw new Error("Room local id is required");
        return {
            name: room.name,
            positions: room.positions,
            local: room.localId,
        };
    }
}
