import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Supplier } from "@entity/domain/models/supplier/supplier.model";
import { GetSupplierByIdUseCase } from "@entity/domain/usecases/supplier/get-all-supplier-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class SupplierDetailPageViewModel extends BaseViewModel {
    supplierId: Undefinable<number> = undefined;

    @observable
    supplier: Undefinable<Supplier> = undefined;

    @observable
    initialLoading: boolean = false;

    constructor(
        @inject(GetSupplierByIdUseCase)
        private readonly getSupplierByIdUseCase: GetSupplierByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getSupplierById();

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    setSupplierId(supplierId: number): void {
        this.supplierId = supplierId;
    }

    @action
    setSupplier(supplier: Supplier): void {
        this.supplier = supplier;
    }

    async getSupplierById(): Promise<void> {
        if (this.supplierId === undefined) throw Error("Missing supplierId");

        const supplier = await this.getSupplierByIdUseCase.execute(
            this.supplierId,
        );

        if (supplier) {
            this.setSupplier(supplier);
        }
    }
}
