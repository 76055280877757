import { Nullable } from "@core/domain/types/nullable.type";
import { SupplierRepository } from "@entity/data/repositories/supplier.repository";
import { inject, injectable } from "inversify";
import { Supplier } from "../../models/supplier/supplier.model";

@injectable()
export class GetSupplierByIdUseCase {
    constructor(
        @inject(SupplierRepository)
        private readonly supplierRepository: SupplierRepository,
    ) {}

    async execute(supplierId: number): Promise<Nullable<Supplier>> {
        const suppliersResult =
            await this.supplierRepository.getById(supplierId);

        return suppliersResult.fold(
            () => null,
            (supplier) => supplier,
        );
    }
}
