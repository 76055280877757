import { EntityRepository } from "@entity/data/repositories/entity.repository";
import {
    EntitySearchFilters,
    EntitySummary,
} from "@entity/domain/models/entity-summary.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllEntitiesByUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}

    async execute(filters?: EntitySearchFilters): Promise<EntitySummary[]> {
        const entitiesResult = await this.entityRepository.getAllBy(filters);

        return entitiesResult.getOrElse([]);
    }
}
