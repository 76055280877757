import { CenterTypeEnum } from "@beneficiary/domain/models/center-type.model";
import { IncDocument } from "@core/domain/models/inc-document.model";

export class ExternalDerivation {
    constructor(
        public id: number,
        public reason: string,
        public centerType: CenterTypeEnum,
        public centerName: string,
        public isDerivateToExternal: boolean,
        public beneficiary: number,
        public contactName?: string,
        public contactPhone?: string,
        public contactEmail?: string,
        public documents?: IncDocument[],
    ) {}
}

export class ExternalDerivations {
    constructor(
        public readonly externalDerivations: ExternalDerivation[],
        public readonly count: number,
    ) {}
}
