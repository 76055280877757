import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { ActivitySummary } from "../../domain/models/activity-summary.model";
import { ActivitySummaryDto } from "../dto/activity-summary.dto";

@injectable()
export class ActivitySummaryMapper {
    map(activitySummaryDto: ActivitySummaryDto): Nullable<ActivitySummary> {
        const errors = validateSync(activitySummaryDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        return new ActivitySummary(
            activitySummaryDto.id,
            activitySummaryDto.name,
            activitySummaryDto.description,
        );
    }
}
