import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EditUserMapper } from "@user/data/mappers/edit-user.mapper";
import { User } from "@user/domain/models/user.model";
import { EditUserUseCase } from "@user/domain/usecases/edit-user.usecase";
import { GetUserByIdUseCase } from "@user/domain/usecases/get-user-by-id.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { UserFormValuesValidated } from "../../components/user-form/user-form.component";
@injectable()
export class EditUserPageViewModel extends BaseViewModel {
    userId: Undefinable<number> = undefined;

    @observable
    initiallyLoading = true;

    @observable
    user: Undefinable<User> = undefined;

    constructor(
        @inject(EditUserMapper)
        private readonly editUserMapper: EditUserMapper,
        @inject(EditUserUseCase)
        private readonly editUserUseCase: EditUserUseCase,
        @inject(GetUserByIdUseCase)
        private readonly getUserByIdUseCase: GetUserByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(this.getUser());

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    @action
    setUser(user: User): void {
        this.user = user;
    }

    async getUser(): Promise<void> {
        if (this.userId === undefined) throw Error("Missing userId");

        const user = await this.getUserByIdUseCase.execute(this.userId);

        if (user) {
            this.setUser(user);
        }
    }

    async editUser(userForm: UserFormValuesValidated): Promise<boolean> {
        if (!this.userId) throw Error("Missing userId");

        LoadLayoutStore.start();

        const editUser = this.editUserMapper.mapFromUserFormValues(
            this.userId,
            userForm,
        );
        const editedSuccessfully = await this.editUserUseCase.execute(editUser);

        if (editedSuccessfully) {
            await this.getUser();
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return editedSuccessfully;
    }
}
