import { Country } from "@core/domain/models/country.model";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { CountryRepository } from "../../data/repositories/country.repository";

@injectable()
export class GetCountriesUseCase {
    constructor(
        @inject(CountryRepository)
        private readonly countryRespository: CountryRepository,
    ) {}

    async execute(): Promise<Country[]> {
        const countriesResult = await this.countryRespository.getAllCountries();

        if (countriesResult.isLeft()) {
            ToastManagerStore.error(countriesResult.getLeftOrThrow().message);
        }

        return countriesResult.fold(
            () => [],
            (countries) => countries,
        );
    }
}
