import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { CreateDocumentError } from "@core/domain/errors/create-document.error";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { CreateDocument } from "@core/domain/models/create-document.model";
import { Order } from "@core/domain/models/order.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { ProjectFinancialEntityRepository } from "@project/data/repositories/project-financial-entity.repository";
import { ProjectMaterialRepository } from "@project/data/repositories/project-material.repository";
import { TechnicalProposalRepository } from "@project/data/repositories/technical-proposal/technical-proposal.repository";
import { CreateProjectError } from "@project/domain/errors/create-project.error";
import { EditProjectError } from "@project/domain/errors/edit-project.error";
import { Catalogue } from "@project/domain/models/catalogue.model";
import { CreateProject } from "@project/domain/models/create-project.model";
import { EditProject } from "@project/domain/models/edit-project.model";
import {
    FinancialEntityType,
    FinancialEntityTypeEnum,
} from "@project/domain/models/financial-entity/financial-entity-type";
import {
    ProjectEmployees,
    ProjectEmployeesSearchFilters,
} from "@project/domain/models/project-employees.model";
import { ProjectSearchFilters } from "@project/domain/models/project-search-filters";
import { ProjectSummary } from "@project/domain/models/project-summary.model";
import { Project } from "@project/domain/models/project.model";
import { ProjectsSummary } from "@project/domain/models/projects-summary.model";
import { Status, StatusEnum } from "@project/domain/models/status.model";
import { NoEntityError } from "@user/domain/errors/no-entity.error";
import { Map } from "immutable";
import { inject, injectable } from "inversify";
import { TerritorialScope } from "../../domain/models/territorial-scope.model";
import { ProjectDatasource } from "../datasource/project.datasource";
import { ProjectExpenseRepository } from "./project-expense.repository";

@injectable()
export class ProjectRepository {
    constructor(
        @inject(ProjectDatasource)
        private readonly projectDatasource: ProjectDatasource,
        @inject(ProjectFinancialEntityRepository)
        private readonly projectFinancialEntityRepository: ProjectFinancialEntityRepository,
        @inject(TechnicalProposalRepository)
        private readonly technicalProposalRepository: TechnicalProposalRepository,
        @inject(UserSessionRepository)
        private readonly userSessionRepository: UserSessionRepository,
        @inject(ProjectMaterialRepository)
        private readonly projectMaterialRepository: ProjectMaterialRepository,
        @inject(ProjectExpenseRepository)
        private readonly projectExpensesRepository: ProjectExpenseRepository,
        @inject(FinancialEntityRepository)
        private readonly financialEntityRepository: FinancialEntityRepository,
    ) {}

    async create(
        createProject: CreateProject,
    ): Promise<Either<CreateProjectError, Project>> {
        const entityId = this.userSessionRepository.userSyncSafe.entityId;

        if (!entityId) return Either.Left(new NoEntityError());

        return this.projectDatasource.create(entityId, createProject);
    }

    async findById(projectId: number): Promise<Either<FallbackError, Project>> {
        const project = await this.projectDatasource.fetchById(projectId);

        if (project.isLeft()) return Either.Left(project.getLeftOrThrow());

        const projectFinancialEntities =
            await this.projectFinancialEntityRepository.getAllBy({ projectId });
        if (projectFinancialEntities.isLeft())
            return Either.Left(projectFinancialEntities.getLeftOrThrow());

        project.getOrThrow().financialEntities =
            projectFinancialEntities.getOrThrow().projectFinancialEntities;

        const technicalProposalsResult =
            await this.technicalProposalRepository.getAllBy(
                Pagination.NoPagination(),
                {
                    projectId,
                },
            );

        if (technicalProposalsResult.isLeft())
            return Either.Left(technicalProposalsResult.getLeftOrThrow());

        const technicalProposals = technicalProposalsResult.getOrThrow();
        // Here we are assuming that there is only one technical proposal per project
        if (technicalProposals.technicalProposals.length > 1)
            return Either.Left(new FallbackError());

        project.getOrThrow().technicalProposals =
            technicalProposals.technicalProposals[0];

        const materialsResult = await this.projectMaterialRepository.getAllBy({
            projectId: project.getOrThrow().id,
        });
        if (materialsResult.isLeft()) {
            return Either.Left(materialsResult.getLeftOrThrow());
        }

        const materials = materialsResult.getOrThrow();

        project.getOrThrow().materials = materials;

        return project;
    }

    async edit(
        editProject: EditProject,
    ): Promise<Either<EditProjectError, Project>> {
        const entityId = this.userSessionRepository.userSyncSafe.entityId;

        if (!entityId) return Either.Left(new NoEntityError());

        const editResult = await this.projectDatasource.edit(
            entityId,
            editProject,
        );

        if (editResult.isLeft()) {
            return Either.Left(editResult.getLeftOrThrow());
        }

        return editResult;
    }

    async exportProjectFinancialEntity(
        entityId: number,
    ): Promise<Either<FallbackError, Blob>> {
        const response =
            this.projectFinancialEntityRepository.exportEntity(entityId);
        return response;
    }

    async exportJustification(
        entityId: number,
    ): Promise<Either<FallbackError, Blob>> {
        const response =
            this.projectFinancialEntityRepository.exportJustification(entityId);
        return response;
    }

    async getRecentModifiedProjects(): Promise<
        Either<FallbackError, ProjectsSummary>
    > {
        const recentSize = 5;
        const pagination: Pagination = new Pagination(0, recentSize);
        const order = new Order<ProjectSummary>("modified", "desc");

        return this.searchAllBy(pagination, undefined, order);
    }

    async getAllCatalogues(): Promise<Either<FallbackError, Catalogue[]>> {
        return this.projectDatasource.fetchAllCatalogues();
    }

    async getAllTerritorialScopes(): Promise<
        Either<FallbackError, TerritorialScope[]>
    > {
        return this.projectDatasource.fetchAllTerritorialScopes();
    }

    async getAllStatus(): Promise<Either<FallbackError, Status[]>> {
        return this.projectDatasource.fetchAllStatus();
    }

    async getFinancialEntitiesTypes(): Promise<
        Either<FallbackError, FinancialEntityType[]>
    > {
        return this.financialEntityRepository.getFinancialEntitiesTypes();
    }

    async searchAllBy(
        pagination: Pagination,
        filters?: ProjectSearchFilters,
        order?: Order<ProjectSummary>,
    ): Promise<Either<FallbackError, ProjectsSummary>> {
        const projectsSummaryResult = await this.projectDatasource.fetchBy(
            pagination,
            filters,
            order,
        );

        if (projectsSummaryResult.isLeft())
            return Either.Left(projectsSummaryResult.getLeftOrThrow());

        const projectsSummaryTransformer = projectsSummaryResult.getOrThrow();

        const statusResult = await this.getAllStatus();
        if (statusResult.isLeft())
            return Either.Left(statusResult.getLeftOrThrow());

        const financialEntitiesTypesResult =
            await this.getFinancialEntitiesTypes();
        if (financialEntitiesTypesResult.isLeft())
            return Either.Left(financialEntitiesTypesResult.getLeftOrThrow());

        const statusMap: Map<StatusEnum, Status> = Map(
            statusResult.getOrThrow().map((status) => [status.id, status]),
        );
        const financialEntitiesTypesMap: Map<
            FinancialEntityTypeEnum,
            FinancialEntityType
        > = Map(
            financialEntitiesTypesResult
                .getOrThrow()
                .map((financialEntity) => [
                    financialEntity.id,
                    financialEntity,
                ]),
        );

        const projectsSummary = projectsSummaryTransformer.projects.mapNotNull(
            (projectSummaryTransformer) => {
                const status = statusMap.get(
                    projectSummaryTransformer.statusId,
                );
                if (!status) return null;

                const financialEntityLabels =
                    projectSummaryTransformer.financialEntityTypesId.mapNotNull(
                        (financialEntityTypeId) => {
                            const financialEntity =
                                financialEntitiesTypesMap.get(
                                    financialEntityTypeId,
                                );
                            if (!financialEntity) return null;

                            return financialEntity.label;
                        },
                    );

                return new ProjectSummary(
                    projectSummaryTransformer.project.id,
                    projectSummaryTransformer.project.name,
                    financialEntityLabels,
                    status.label,
                    projectSummaryTransformer.project.catalogues,
                    projectSummaryTransformer.project.year,
                );
            },
        );

        const projectsSumary = new ProjectsSummary(
            projectsSummary,
            projectsSummaryTransformer.count,
        );

        return Either.Right(projectsSumary);
    }

    async getAllProjectEmployeesBy(
        filters?: ProjectEmployeesSearchFilters,
    ): Promise<Either<FallbackError, ProjectEmployees>> {
        return this.projectDatasource.fetchAllProjectEmployeesBy(filters);
    }

    async importProjects(
        projectsImport: CreateDocument,
        entityId: number,
    ): Promise<Either<CreateDocumentError, boolean>> {
        return this.projectDatasource.importProjects(projectsImport, entityId);
    }

    async exportProjects(
        entityId: number,
    ): Promise<Either<FallbackError, Blob>> {
        const response = this.projectDatasource.exportProjects(entityId);

        return response;
    }
}
