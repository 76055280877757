import { RequireEntityGuard } from "@core/presentacion/component/routing/require-entity/require-entity.guard";
import { RequireRoleGuard } from "@core/presentacion/component/routing/require-role/require-role.guard";
import { EditBudgetPage } from "@project/presentation/pages/edit-budget/edit-budget.page";
import { UserRoleEnum } from "@user/domain/models/user-role.model";
import { lazy } from "react";
import { Route } from "react-router-dom";

const ProjectListPage = lazy(async () =>
    import(
        /* webpackChunkName: "ProjectListPage" */ "@project/presentation/pages/project/list/project-list.page"
    ).then((module) => ({ default: module.ProjectListPage })),
);

const CreateProjectPage = lazy(async () =>
    import(
        /* webpackChunkName: "CreateProjectPage" */ "@project/presentation/pages/project/create/create-project.page"
    ).then((module) => ({ default: module.CreateProjectPage })),
);

const EditProjectPage = lazy(async () =>
    import(
        /* webpackChunkName: "EditProjectPage" */ "@project/presentation/pages/project/edit/edit-project.page"
    ).then((module) => ({ default: module.EditProjectPage })),
);

const DetailProjectPage = lazy(async () =>
    import(
        /* webpackChunkName: "DetailProjectPage" */ "@project/presentation/pages/project/detail/detail-project.page"
    ).then((module) => ({ default: module.ProjectDetailPage })),
);

const ListFinancialEntityPage = lazy(async () =>
    import(
        /* webpackChunkName: "ListFinancialEntityPage" */ "@project/presentation/pages/financial-entity/list/financial-entity-list.page"
    ).then((module) => ({ default: module.FinancialEntityListPage })),
);

const CreateFinancialEntityPage = lazy(async () =>
    import(
        /* webpackChunkName: "CreateFinancialEntityPage" */ "@project/presentation/pages/financial-entity/create/create-financial-entity.page"
    ).then((module) => ({ default: module.CreateFinancialEntityPage })),
);

const EditFinancialEntityPage = lazy(async () =>
    import(
        /* webpackChunkName: "EditFinancialEntityPage" */ "@project/presentation/pages/financial-entity/edit/edit-financial-entity.page"
    ).then((module) => ({ default: module.EditFinancialEntityPage })),
);

const DetailFinancialEntityPage = lazy(async () =>
    import(
        /* webpackChunkName: "DetailFinancialEntityPage" */ "@project/presentation/pages/financial-entity/detail/detail-financial-entity.page"
    ).then((module) => ({ default: module.DetailFinancialEntityPage })),
);

const CreateFinancialEntityBudgetPage = lazy(async () =>
    import(
        /* webpackChunkName: "CreateFinancialEntityBudgetPage" */ "@project/presentation/pages/financial-entity/budget/create/create-budget.page"
    ).then((module) => ({ default: module.CreateFinancialEntityBudgetPage })),
);

const EditFinancialEntityBudgetPage = lazy(async () =>
    import(
        /* webpackChunkName: "EditFinancialEntityBudgetPage" */ "@project/presentation/pages/financial-entity/budget/edit/edit-budget.page"
    ).then((module) => ({ default: module.EditFinancialEntityBudgetPage })),
);

export class ProjectPaths {
    static readonly Root = "projects";

    static readonly List = "list";

    static readonly Create = "create";

    static readonly EditParam = ":projectId";
    static readonly Edit = `edit/${ProjectPaths.EditParam}`;

    static readonly FinancialEntityRoot = "financial-entity";
    static readonly ListFinancialEntities = "list";
    static readonly CreateFinancialEntity = "create";

    static readonly EditFinancialEntityParam = ":financialEntityId";
    static readonly EditFinancialEntity = `edit/${ProjectPaths.EditFinancialEntityParam}`;

    static readonly FinancialEntityDetailParam = ":financialEntityId";
    static readonly FinancialEntityDetail = `detail/${ProjectPaths.FinancialEntityDetailParam}`;

    static readonly CreateFinancialEntityBudget = `${ProjectPaths.FinancialEntityDetailParam}/budget/create`;

    static readonly EditFinancialEntityBudgetParam = ":budgetId";
    static readonly EditFinancialEntityBudget = `${ProjectPaths.FinancialEntityDetailParam}/budget/edit/${ProjectPaths.EditFinancialEntityBudgetParam}`;

    static readonly EditBudgetParam = ":projectFinancialEntityId";
    static readonly EditBudget = `${ProjectPaths.FinancialEntityDetailParam}/budget/edit/${ProjectPaths.EditBudgetParam}`;

    static readonly DetailParam = ":projectId";
    static readonly Detail = `detail/${ProjectPaths.DetailParam}`;
}

export class ProjectRoutes {
    static LIST = `/${ProjectPaths.Root}/${ProjectPaths.List}`;

    static LIST_FINANCIAL_ENTITY = `/${ProjectPaths.Root}/${ProjectPaths.FinancialEntityRoot}/${ProjectPaths.ListFinancialEntities}`;

    static CREATE_FINANCIAL_ENTITY = `/${ProjectPaths.Root}/${ProjectPaths.FinancialEntityRoot}/${ProjectPaths.CreateFinancialEntity}`;

    static CREATE_FINANCIAL_ENTITY_BUDGET = (
        financialEntityId: number,
    ): string =>
        `/${ProjectPaths.Root}/${
            ProjectPaths.FinancialEntityRoot
        }/${ProjectPaths.CreateFinancialEntityBudget.replace(
            ProjectPaths.FinancialEntityDetailParam,
            financialEntityId.toString(),
        )}`;

    static EDIT_FINANCIAL_ENTITY_BUDGET = (
        financialEntityId: number,
        budgetId: number,
    ): string =>
        `/${ProjectPaths.Root}/${
            ProjectPaths.FinancialEntityRoot
        }/${ProjectPaths.EditFinancialEntityBudget.replace(
            ProjectPaths.FinancialEntityDetailParam,
            financialEntityId.toString(),
        ).replace(
            ProjectPaths.EditFinancialEntityBudgetParam,
            budgetId.toString(),
        )}`;
    static EDIT_FINANCIAL_ENTITY = (financialEntityId: number): string =>
        `/${ProjectPaths.Root}/${
            ProjectPaths.FinancialEntityRoot
        }/${ProjectPaths.EditFinancialEntity.replace(
            ProjectPaths.FinancialEntityDetailParam,
            financialEntityId.toString(),
        )}`;

    static FINANCIAL_ENTITY_DETAIL = (financialEntityId: number): string =>
        `/${ProjectPaths.Root}/${
            ProjectPaths.FinancialEntityRoot
        }/${ProjectPaths.FinancialEntityDetail.replace(
            ProjectPaths.FinancialEntityDetailParam,
            financialEntityId.toString(),
        )}`;

    static CREATE = `/${ProjectPaths.Root}/${ProjectPaths.Create}`;

    static EDIT = (projectId: number): string =>
        `/${ProjectPaths.Root}/${ProjectPaths.Edit.replace(
            ProjectPaths.EditParam,
            projectId.toString(),
        )}`;

    static EDIT_BUDGET = (
        projectId: number,
        projectFinancialEntityId: number,
    ): string =>
        `${ProjectRoutes.EDIT(projectId)}/${ProjectPaths.EditBudget.replace(
            ProjectPaths.EditBudgetParam,
            projectFinancialEntityId.toString(),
        )}`;

    static DETAIL = (projectId: number): string =>
        `/${ProjectPaths.Root}/${ProjectPaths.Detail.replace(
            ProjectPaths.DetailParam,
            projectId.toString(),
        )}`;
}

export const projectRoutes = (
    <Route path={ProjectPaths.Root}>
        <Route
            element={<ProjectListPage />}
            path={ProjectPaths.List}
        />
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                    ]}
                >
                    <RequireEntityGuard redirectTo={ProjectRoutes.LIST}>
                        <CreateProjectPage />
                    </RequireEntityGuard>
                </RequireRoleGuard>
            }
            path={ProjectPaths.Create}
        />
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                    ]}
                >
                    <DetailProjectPage />
                </RequireRoleGuard>
            }
            path={ProjectPaths.Detail}
        />
        <Route path={ProjectPaths.Edit}>
            <Route
                index
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard redirectTo={ProjectRoutes.LIST}>
                            <EditProjectPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
            />
            <Route
                path={ProjectPaths.EditBudget}
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <EditBudgetPage />
                    </RequireRoleGuard>
                }
            />
        </Route>
        <Route path={ProjectPaths.FinancialEntityRoot}>
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <ListFinancialEntityPage />
                    </RequireRoleGuard>
                }
                path={ProjectPaths.ListFinancialEntities}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard
                            redirectTo={ProjectRoutes.LIST_FINANCIAL_ENTITY}
                        >
                            <CreateFinancialEntityPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={ProjectPaths.CreateFinancialEntity}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <RequireEntityGuard
                            redirectTo={ProjectRoutes.LIST_FINANCIAL_ENTITY}
                        >
                            <EditFinancialEntityPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={ProjectPaths.EditFinancialEntity}
            />
            <Route
                element={
                    <RequireRoleGuard
                        rolesIn={[
                            UserRoleEnum.SuperAdmin,
                            UserRoleEnum.EntityAdmin,
                        ]}
                    >
                        <DetailFinancialEntityPage />
                    </RequireRoleGuard>
                }
                path={ProjectPaths.FinancialEntityDetail}
            />
            <Route
                element={
                    <RequireRoleGuard rolesIn={[UserRoleEnum.SuperAdmin]}>
                        <RequireEntityGuard
                            redirectTo={ProjectRoutes.LIST_FINANCIAL_ENTITY}
                        >
                            <CreateFinancialEntityBudgetPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={ProjectPaths.CreateFinancialEntityBudget}
            />
            <Route
                element={
                    <RequireRoleGuard rolesIn={[UserRoleEnum.SuperAdmin]}>
                        <RequireEntityGuard
                            redirectTo={ProjectRoutes.LIST_FINANCIAL_ENTITY}
                        >
                            <EditFinancialEntityBudgetPage />
                        </RequireEntityGuard>
                    </RequireRoleGuard>
                }
                path={ProjectPaths.EditFinancialEntityBudget}
            />
        </Route>
    </Route>
);
