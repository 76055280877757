import { inject, injectable } from "inversify";
import { UserRepository } from "../../data/repositories/user.repository";
import { UserRole } from "../models/user-role.model";

@injectable()
export class GetAllUserRolesUseCase {
    constructor(
        @inject(UserRepository)
        private readonly userRepository: UserRepository,
    ) {}

    async execute(): Promise<UserRole[]> {
        const typesResult = await this.userRepository.getAllUserRoles();

        return typesResult.getOrElse([]);
    }
}
