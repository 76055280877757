import { TherapyType } from "@beneficiary/domain/models/therapy-type.enum";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { BeneficiaryRepository } from "../../data/repositories/beneficiary.repository";

@injectable()
export class GetTherapyTypesUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(): Promise<TherapyType[]> {
        const therapyTypesResult =
            await this.beneficiaryRepository.getAllTherapyTypes();

        if (therapyTypesResult.isLeft()) {
            ToastManagerStore.error(
                therapyTypesResult.getLeftOrThrow().message,
            );
        }

        return therapyTypesResult.fold(
            () => [],
            (therapyTypes) => therapyTypes,
        );
    }
}
