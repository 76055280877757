import { injectable } from "inversify";
import { UserEntity } from "../../domain/models/user-entity.model";
import { CurrentUserDto } from "../dto/current-user.dto";

@injectable()
export class UserEntityMapper {
    map(currentUserDto: CurrentUserDto): UserEntity {
        const entityId = currentUserDto.entity ?? undefined;
        const entityName = currentUserDto.entity_data?.name ?? undefined;

        return new UserEntity(entityId, entityName);
    }
}
