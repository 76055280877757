import { Nullable } from "@core/domain/types/nullable.type";
import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { Entity } from "@entity/domain/models/entity.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetEntityByIdUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}

    async execute(entityId: number): Promise<Nullable<Entity>> {
        const entityResult = await this.entityRepository.getById(entityId);

        return entityResult.fold(
            () => null,
            (entity) => entity,
        );
    }
}
