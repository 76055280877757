import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { AdministrativeRecordScope } from "@entity/domain/models/administrative-record-scope.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllAdministrativeRecordScopeUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}

    async execute(): Promise<AdministrativeRecordScope[]> {
        const administrativeRecordScopeResult =
            await this.entityRepository.getAllAdministrativeRecordScopes();

        return administrativeRecordScopeResult.getOrElse([]);
    }
}
