import { EditPublicItineraryBody } from "@beneficiary/data/dto/public-itinerary/edit-public-itinerary.body";
import { EditPublicItinerary } from "@beneficiary/domain/models/public-itinerary/edit-public-itinerary.model";
import { PublicItineraryFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/public-itinerary/form/public-itinerary-form";
import { injectable } from "inversify";

@injectable()
export class EditPublicItineraryMapper {
    mapToDto(
        editPublicItinerary: EditPublicItinerary,
    ): EditPublicItineraryBody {
        const editPublicItineraryBody: EditPublicItineraryBody = {
            id: editPublicItinerary.id,
            objective: editPublicItinerary.objetive,
            actions: editPublicItinerary.actions,
            actions_evaluation: editPublicItinerary.actionsEvaluation,
            beneficiary: editPublicItinerary.beneficiary,
        };
        return editPublicItineraryBody;
    }
    mapFromModal(
        id: number,
        beneficiaryId: number,
        publicItinerary: PublicItineraryFormValuesValidated,
    ): EditPublicItinerary {
        const editPublicItinerary: EditPublicItinerary =
            new EditPublicItinerary(
                id,
                publicItinerary.objetive,
                publicItinerary.actions,
                publicItinerary.actionsEvaluation,
                beneficiaryId,
            );
        return editPublicItinerary;
    }
}
