import { Nullable } from "@core/domain/types/nullable.type";
import { CatalogueDto } from "@project/data/dto/catalogue.dto";
import { CatalogueEnumMapper } from "@project/data/mappers/catalogue-enum.mapper";
import {
    Catalogue,
    CatalogueEnum,
} from "@project/domain/models/catalogue.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class CatalogueMapper {
    constructor(
        @inject(CatalogueEnumMapper)
        private readonly catalogueEnumMapper: CatalogueEnumMapper,
    ) {}

    map(catalogueDto: CatalogueDto): Nullable<Catalogue> {
        const errors = validateSync(catalogueDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<CatalogueEnum> = this.catalogueEnumMapper.map(
            catalogueDto.key,
        );

        if (!id) return null;

        return new Catalogue(id, catalogueDto.value);
    }
}
