import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import {
    Undefinable,
    convertEmptyToUndefined,
    convertValueField,
} from "@core/domain/types/undefinable.type";
import { isDefined, validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import {
    AllegationsDate,
    ConvocationDate,
    CreateProjectFinancialEntity,
    EditProjectFinancialEntity,
    JustificationDates,
    ProjectFinancialEntity,
    RequirementDate,
    ResolutionDate,
} from "../../domain/models/project-financial-entities.model";
import { FinancialEntityFormValuesValidated } from "../../presentation/components/project-form/budget/project-financial-entity-form.view";
import {
    CreateProjectFinancialEntityBody,
    EditProjectFinancialEntityBody,
} from "../dto/edit-project.body";
import { ProjectFinancialEntityDto } from "../dto/project-financial-entities.dto";
import { FinancingTypeEnumMapper } from "./financial-entity/financing-type-enum.mapper";

@injectable()
export class ProjectFinancialEntityMapper {
    constructor(
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
        @inject(FinancingTypeEnumMapper)
        private readonly financingTypeEnumMapper: FinancingTypeEnumMapper,
    ) {}
    map(
        projectFinancialEntityDto: ProjectFinancialEntityDto,
    ): Nullable<ProjectFinancialEntity> {
        const errors = validateSync(projectFinancialEntityDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        let documents: IncDocument[] = [];
        if (projectFinancialEntityDto.supporting_documents_data) {
            documents =
                projectFinancialEntityDto.supporting_documents_data.mapNotNull(
                    (document) => this.documentMapper.map(document),
                );
        }

        const convocationDate: ConvocationDate = {
            endConvocationDate: projectFinancialEntityDto.convocation_date
                ? DateTime.fromISO(projectFinancialEntityDto.convocation_date)
                : null,
            publicationConvocationDate:
                projectFinancialEntityDto.publication_convocation_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.publication_convocation_date,
                      )
                    : null,
            presentationConvocationDate:
                projectFinancialEntityDto.presentation_convocation_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.presentation_convocation_date,
                      )
                    : null,
        };

        const requirementDate: RequirementDate = {
            communicationRequirementDate:
                projectFinancialEntityDto.requirement_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.requirement_date,
                      )
                    : null,
            endRequirementDate: projectFinancialEntityDto.end_requirement_date
                ? DateTime.fromISO(
                      projectFinancialEntityDto.end_requirement_date,
                  )
                : null,
            presentationRequirementDate:
                projectFinancialEntityDto.presentation_requirement_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.presentation_requirement_date,
                      )
                    : null,
        };

        const resolutionDate: ResolutionDate = {
            communicationResolutionDate:
                projectFinancialEntityDto.communication_resolution_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.communication_resolution_date,
                      )
                    : null,
            communicationReformulationDate:
                projectFinancialEntityDto.communication_reformulation_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.communication_reformulation_date,
                      )
                    : null,
            endReformulationDate:
                projectFinancialEntityDto.end_reformulation_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.end_reformulation_date,
                      )
                    : null,
            presentationResolutionDate:
                projectFinancialEntityDto.presentation_resolution_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.presentation_resolution_date,
                      )
                    : null,
            comunicationFinalResolutionDate:
                projectFinancialEntityDto.comunication_final_resolution_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.comunication_final_resolution_date,
                      )
                    : null,
            acceptanceDate: projectFinancialEntityDto.acceptance_date
                ? DateTime.fromISO(projectFinancialEntityDto.acceptance_date)
                : null,
        };

        const allegationsDate: AllegationsDate = {
            endAllegationsDate: projectFinancialEntityDto.end_allegations_date
                ? DateTime.fromISO(
                      projectFinancialEntityDto.end_allegations_date,
                  )
                : null,
            presentationAllegationDate:
                projectFinancialEntityDto.presentation_allegation_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.presentation_allegation_date,
                      )
                    : null,
            endResourceDate: projectFinancialEntityDto.end_resource_date
                ? DateTime.fromISO(projectFinancialEntityDto.end_resource_date)
                : null,
            presentationReplacementDate:
                projectFinancialEntityDto.presentation_replacement_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.presentation_replacement_date,
                      )
                    : null,
        };

        const financingType = projectFinancialEntityDto.financing_type
            ? this.financingTypeEnumMapper.map(
                  projectFinancialEntityDto.financing_type,
              )
            : null;

        const amount = projectFinancialEntityDto.amount
            ? parseFloat(projectFinancialEntityDto.amount)
            : null;
        const requestAmount = projectFinancialEntityDto.amount_request
            ? parseFloat(projectFinancialEntityDto.amount_request)
            : null;
        const percentage = projectFinancialEntityDto.percentage
            ? parseFloat(projectFinancialEntityDto.percentage)
            : null;

        let justificationDates: JustificationDates = {};
        if (
            !!projectFinancialEntityDto.intermediate_justification_deadline ||
            !!projectFinancialEntityDto.intermediate_justification_delivery_date ||
            !!projectFinancialEntityDto.final_justification_deadline ||
            !!projectFinancialEntityDto.final_justification_delivery_date ||
            !!projectFinancialEntityDto.requirement_reception_date ||
            !!projectFinancialEntityDto.requirement_response_deadline ||
            !!projectFinancialEntityDto.requirement_response_date ||
            !!projectFinancialEntityDto.justification_additional_notes ||
            isDefined(projectFinancialEntityDto.has_intermediate_justification)
        ) {
            justificationDates = new JustificationDates(
                isDefined(
                    projectFinancialEntityDto.has_intermediate_justification,
                )
                    ? projectFinancialEntityDto.has_intermediate_justification
                    : undefined,
                projectFinancialEntityDto.intermediate_justification_deadline
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.intermediate_justification_deadline,
                      )
                    : null,
                projectFinancialEntityDto.intermediate_justification_delivery_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.intermediate_justification_delivery_date,
                      )
                    : null,
                projectFinancialEntityDto.final_justification_deadline
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.final_justification_deadline,
                      )
                    : null,
                projectFinancialEntityDto.final_justification_delivery_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.final_justification_delivery_date,
                      )
                    : null,
                projectFinancialEntityDto.requirement_reception_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.requirement_reception_date,
                      )
                    : null,
                projectFinancialEntityDto.requirement_response_deadline
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.requirement_response_deadline,
                      )
                    : null,
                projectFinancialEntityDto.requirement_response_date
                    ? DateTime.fromISO(
                          projectFinancialEntityDto.requirement_response_date,
                      )
                    : null,
                projectFinancialEntityDto.justification_additional_notes,
            );
        }

        const newProjectFinancialEntity = new ProjectFinancialEntity(
            projectFinancialEntityDto.id,
            requestAmount,
            amount,
            financingType,
            projectFinancialEntityDto.other_financing_type,
            projectFinancialEntityDto.finantial_entity,
            projectFinancialEntityDto.budget,
            percentage,
            convocationDate,
            requirementDate,
            resolutionDate,
            allegationsDate,
            justificationDates,
            documents,
        );

        if (isDefined(projectFinancialEntityDto.is_granted)) {
            newProjectFinancialEntity.isGranted =
                projectFinancialEntityDto.is_granted;
        }

        return newProjectFinancialEntity;
    }

    mapToCreate(
        projectFinancialEntityForm: FinancialEntityFormValuesValidated,
    ): CreateProjectFinancialEntity {
        let documents: Undefinable<number[]> = undefined;

        if (projectFinancialEntityForm.justification.documents.length) {
            documents = projectFinancialEntityForm.justification.documents.map(
                (document) => document.id,
            );
        }

        return new CreateProjectFinancialEntity(
            projectFinancialEntityForm.requestAmount,
            projectFinancialEntityForm.totalAmount,
            projectFinancialEntityForm.financingTypeId,
            projectFinancialEntityForm.otherFinancingType,
            projectFinancialEntityForm.financialEntityId,
            projectFinancialEntityForm.budgetId,
            projectFinancialEntityForm.financedPercentage,
            projectFinancialEntityForm.convocationDate,
            projectFinancialEntityForm.requirementDate,
            projectFinancialEntityForm.resolutionDate,
            projectFinancialEntityForm.allegationsDate,
            projectFinancialEntityForm.justificationDates,
            documents,
            projectFinancialEntityForm.isGranted,
        );
    }

    mapToEdit(
        projectFinancialEntityForm: FinancialEntityFormValuesValidated,
    ): EditProjectFinancialEntity {
        if (!projectFinancialEntityForm.id) {
            throw Error("Cant edit a project financial entity without id");
        }

        let documents: Undefinable<number[]> = undefined;

        if (projectFinancialEntityForm.justification.documents.length) {
            documents = projectFinancialEntityForm.justification.documents.map(
                (document) => document.id,
            );
        }

        return new EditProjectFinancialEntity(
            projectFinancialEntityForm.id,
            projectFinancialEntityForm.requestAmount,
            projectFinancialEntityForm.totalAmount,
            projectFinancialEntityForm.financingTypeId,
            projectFinancialEntityForm.otherFinancingType,
            projectFinancialEntityForm.financialEntityId,
            projectFinancialEntityForm.budgetId,
            projectFinancialEntityForm.financedPercentage,
            projectFinancialEntityForm.convocationDate,
            projectFinancialEntityForm.requirementDate,
            projectFinancialEntityForm.resolutionDate,
            projectFinancialEntityForm.allegationsDate,
            projectFinancialEntityForm.justificationDates,
            documents,
            projectFinancialEntityForm.isGranted,
        );
    }

    mapToCreateDto(
        projectId: number,
        projectFinancialEntity: CreateProjectFinancialEntity,
    ): CreateProjectFinancialEntityBody {
        const financingType = projectFinancialEntity.financingTypeId
            ? this.financingTypeEnumMapper.mapToDto(
                  projectFinancialEntity.financingTypeId,
              )
            : null;

        return {
            amount_request: convertValueField(
                projectFinancialEntity.requestAmount?.toString(),
            ),
            amount: convertValueField(
                projectFinancialEntity.totalAmount?.toString(),
            ),
            percentage: convertValueField(
                projectFinancialEntity.financedPercentage?.toString(),
            ),
            project: projectId,
            financing_type: convertEmptyToUndefined(financingType),
            other_financing_type: convertValueField(
                projectFinancialEntity.otherFinancingType,
            ),
            finantial_entity: projectFinancialEntity.financialEntityId,
            budget: projectFinancialEntity.budgetId,
            convocation_date: convertEmptyToUndefined(
                projectFinancialEntity.convocationDate.endConvocationDate?.toISODate(),
            ),
            publication_convocation_date: convertEmptyToUndefined(
                projectFinancialEntity.convocationDate.publicationConvocationDate?.toISODate(),
            ),
            presentation_convocation_date: convertEmptyToUndefined(
                projectFinancialEntity.convocationDate.presentationConvocationDate?.toISODate(),
            ),
            requirement_date: convertEmptyToUndefined(
                projectFinancialEntity.requirementDate.communicationRequirementDate?.toISODate(),
            ),
            end_requirement_date: convertEmptyToUndefined(
                projectFinancialEntity.requirementDate.endRequirementDate?.toISODate(),
            ),
            presentation_requirement_date: convertEmptyToUndefined(
                projectFinancialEntity.requirementDate.presentationRequirementDate?.toISODate(),
            ),
            communication_resolution_date: convertEmptyToUndefined(
                projectFinancialEntity.resolutionDate.communicationResolutionDate?.toISODate(),
            ),
            comunication_final_resolution_date: convertEmptyToUndefined(
                projectFinancialEntity.resolutionDate.comunicationFinalResolutionDate?.toISODate(),
            ),
            acceptance_date: convertEmptyToUndefined(
                projectFinancialEntity.resolutionDate.acceptanceDate?.toISODate(),
            ),
            communication_reformulation_date: convertEmptyToUndefined(
                projectFinancialEntity.resolutionDate.communicationReformulationDate?.toISODate(),
            ),
            end_reformulation_date: convertEmptyToUndefined(
                projectFinancialEntity.resolutionDate.endReformulationDate?.toISODate(),
            ),
            presentation_resolution_date: convertEmptyToUndefined(
                projectFinancialEntity.resolutionDate.presentationResolutionDate?.toISODate(),
            ),
            end_allegations_date: convertEmptyToUndefined(
                projectFinancialEntity.allegationsDate.endAllegationsDate?.toISODate(),
            ),
            end_resource_date: convertEmptyToUndefined(
                projectFinancialEntity.allegationsDate.endResourceDate?.toISODate(),
            ),
            supporting_documents: convertEmptyToUndefined(
                projectFinancialEntity.supportingDocuments,
            ),
            is_granted: convertEmptyToUndefined(
                projectFinancialEntity.isGranted,
            ),
            presentation_allegation_date: convertEmptyToUndefined(
                projectFinancialEntity.allegationsDate.presentationAllegationDate?.toISODate(),
            ),
            presentation_replacement_date: convertEmptyToUndefined(
                projectFinancialEntity.allegationsDate.presentationReplacementDate?.toISODate(),
            ),
        };
    }

    mapToEditDto(
        projectId: number,
        projectFinancialEntity: EditProjectFinancialEntity,
    ): EditProjectFinancialEntityBody {
        const financingType = projectFinancialEntity.financingTypeId
            ? this.financingTypeEnumMapper.mapToDto(
                  projectFinancialEntity.financingTypeId,
              )
            : null;

        return {
            id: projectFinancialEntity.id,
            amount_request: projectFinancialEntity.requestAmount
                ? projectFinancialEntity.requestAmount.toString()
                : null,
            amount: projectFinancialEntity.totalAmount
                ? projectFinancialEntity.totalAmount.toString()
                : null,
            percentage: projectFinancialEntity.financedPercentage
                ? projectFinancialEntity.financedPercentage.toString()
                : null,
            project: projectId,
            financing_type: convertEmptyToUndefined(financingType),
            other_financing_type: convertEmptyToUndefined(
                projectFinancialEntity.otherFinancingType,
            ),
            finantial_entity: projectFinancialEntity.financialEntityId,
            budget: projectFinancialEntity.budgetId,
            convocation_date: projectFinancialEntity.convocationDate
                .endConvocationDate
                ? projectFinancialEntity.convocationDate.endConvocationDate.toISODate()
                : null,
            publication_convocation_date: projectFinancialEntity.convocationDate
                .publicationConvocationDate
                ? projectFinancialEntity.convocationDate.publicationConvocationDate.toISODate()
                : null,
            presentation_convocation_date: projectFinancialEntity
                .convocationDate.presentationConvocationDate
                ? projectFinancialEntity.convocationDate.presentationConvocationDate.toISODate()
                : null,
            requirement_date: projectFinancialEntity.requirementDate
                .communicationRequirementDate
                ? projectFinancialEntity.requirementDate.communicationRequirementDate.toISODate()
                : null,
            end_requirement_date: projectFinancialEntity.requirementDate
                .endRequirementDate
                ? projectFinancialEntity.requirementDate.endRequirementDate.toISODate()
                : null,
            presentation_requirement_date: projectFinancialEntity
                .requirementDate.presentationRequirementDate
                ? projectFinancialEntity.requirementDate.presentationRequirementDate.toISODate()
                : null,
            communication_resolution_date: projectFinancialEntity.resolutionDate
                .communicationResolutionDate
                ? projectFinancialEntity.resolutionDate.communicationResolutionDate.toISODate()
                : null,
            comunication_final_resolution_date: projectFinancialEntity
                .resolutionDate.comunicationFinalResolutionDate
                ? projectFinancialEntity.resolutionDate.comunicationFinalResolutionDate.toISODate()
                : null,
            acceptance_date: projectFinancialEntity.resolutionDate
                .acceptanceDate
                ? projectFinancialEntity.resolutionDate.acceptanceDate.toISODate()
                : null,
            communication_reformulation_date: projectFinancialEntity
                .resolutionDate.communicationReformulationDate
                ? projectFinancialEntity.resolutionDate.communicationReformulationDate.toISODate()
                : null,
            end_reformulation_date: projectFinancialEntity.resolutionDate
                .endReformulationDate
                ? projectFinancialEntity.resolutionDate.endReformulationDate.toISODate()
                : null,
            presentation_resolution_date: projectFinancialEntity.resolutionDate
                .presentationResolutionDate
                ? projectFinancialEntity.resolutionDate.presentationResolutionDate.toISODate()
                : null,
            end_allegations_date: projectFinancialEntity.allegationsDate
                .endAllegationsDate
                ? projectFinancialEntity.allegationsDate.endAllegationsDate.toISODate()
                : null,
            presentation_allegation_date: projectFinancialEntity.allegationsDate
                .presentationAllegationDate
                ? projectFinancialEntity.allegationsDate.presentationAllegationDate.toISODate()
                : null,
            end_resource_date: projectFinancialEntity.allegationsDate
                .endResourceDate
                ? projectFinancialEntity.allegationsDate.endResourceDate.toISODate()
                : null,
            presentation_replacement_date: projectFinancialEntity
                .allegationsDate.presentationReplacementDate
                ? projectFinancialEntity.allegationsDate.presentationReplacementDate.toISODate()
                : null,
            supporting_documents: convertEmptyToUndefined(
                projectFinancialEntity.supportingDocuments,
            ),
            is_granted: convertEmptyToUndefined(
                projectFinancialEntity.isGranted,
            ),

            intermediate_justification_deadline: projectFinancialEntity
                .justificationDates.intermediateJustificationEdgeDate
                ? projectFinancialEntity.justificationDates.intermediateJustificationEdgeDate.toISODate()
                : null,
            intermediate_justification_delivery_date: projectFinancialEntity
                .justificationDates.intermediateJustificationDeliveryDate
                ? projectFinancialEntity.justificationDates.intermediateJustificationDeliveryDate.toISODate()
                : null,
            final_justification_deadline: projectFinancialEntity
                .justificationDates.finalJustificationEdgeDate
                ? projectFinancialEntity.justificationDates.finalJustificationEdgeDate.toISODate()
                : null,
            final_justification_delivery_date: projectFinancialEntity
                .justificationDates.finalJustificationDeliveryDate
                ? projectFinancialEntity.justificationDates.finalJustificationDeliveryDate.toISODate()
                : null,
            requirement_reception_date: projectFinancialEntity
                .justificationDates.requirementReceptionDate
                ? projectFinancialEntity.justificationDates.requirementReceptionDate.toISODate()
                : null,
            requirement_response_deadline: projectFinancialEntity
                .justificationDates.requirementResponseEdgeDate
                ? projectFinancialEntity.justificationDates.requirementResponseEdgeDate.toISODate()
                : null,
            requirement_response_date: projectFinancialEntity.justificationDates
                .requirementResponseDate
                ? projectFinancialEntity.justificationDates.requirementResponseDate.toISODate()
                : null,
            justification_additional_notes: convertValueField(
                projectFinancialEntity.justificationDates
                    .justificationAdditionalNotes,
            ),
            has_intermediate_justification: convertEmptyToUndefined(
                projectFinancialEntity.justificationDates
                    .hasIntermediateJustification,
            ),
        };
    }
}
