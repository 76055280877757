export enum DisabilityTypeEnum {
    PHYSICAL = "physical",
    MENTAL = "mental",
    INTELLECTUAL = "intellectual",
    SENSORIAL = "sensorial",
    PLURIDISCABILITY = "pluridiscability",
    NONE = "none",
}

export class DisabilityType {
    constructor(
        public readonly id: DisabilityTypeEnum,
        public readonly label: string,
    ) {}
}
