import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateBudgetCategory } from "@project/domain/models/budget/budget-category/create-budget-category.model";
import { EditBudgetCategory } from "@project/domain/models/budget/budget-category/edit-budget-category.model";
import {
    BudgetCategories,
    BudgetCategory,
} from "@project/domain/models/budget/financial-entity-budget.model";
import { CreateBudgetCategoryUseCase } from "@project/domain/usecases/budget/categories/create-budget-category.usecase";
import { DeleteBudgetCategoryUseCase } from "@project/domain/usecases/budget/categories/delete-budget-category.usecase";
import { EditBudgetCategoryUseCase } from "@project/domain/usecases/budget/categories/edit-budget-category.usecase";
import { GetAllBudgetCategoriesByBudgetIdUseCase } from "@project/domain/usecases/budget/categories/get-all-budget-categories-by-budget-id.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class BudgetCategoriesViewModel extends BaseViewModel {
    _budgetId: Nullable<number> = null;

    @observable
    categories: BudgetCategories = new BudgetCategories([], 0);
    @observable
    openAddBudgetCategoryModal: boolean = false;

    @observable
    openEditBudgetCategoryModal: boolean = false;

    @observable
    categoryToEdit: Undefinable<BudgetCategory>;

    @observable
    updateConceptGroups: boolean = false;

    constructor(
        @inject(GetAllBudgetCategoriesByBudgetIdUseCase)
        private readonly getBudgetCategoriesByBudgetIdUseCase: GetAllBudgetCategoriesByBudgetIdUseCase,
        @inject(CreateBudgetCategoryUseCase)
        private readonly createBudgetCategoryUseCase: CreateBudgetCategoryUseCase,
        @inject(DeleteBudgetCategoryUseCase)
        private readonly deleteBudgetCategoryUseCase: DeleteBudgetCategoryUseCase,
        @inject(EditBudgetCategoryUseCase)
        private readonly editBudgetCategoryUseCase: EditBudgetCategoryUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();
        await this.initViewData();
    }
    async initViewData(): Promise<void> {
        await this.getBudgetCategories();
    }

    async getBudgetCategories(): Promise<void> {
        if (!this._budgetId) {
            return;
        }
        const result = await this.getBudgetCategoriesByBudgetIdUseCase.execute(
            this._budgetId,
        );
        runInAction(() => {
            this.categories = result;
        });
    }
    handleOpenAddBudgetCategoryModal = (): void => {
        runInAction(() => {
            this.openAddBudgetCategoryModal = true;
        });
    };
    handleCloseAddBudgetCategoryModal = (): void => {
        runInAction(() => {
            this.openAddBudgetCategoryModal = false;
        });
    };
    addBudgetCategory = async (values: CreateBudgetCategory): Promise<void> => {
        LoadLayoutStore.start();
        const result = await this.createBudgetCategoryUseCase.execute(values);
        if (result) {
            ToastManagerStore.success();
        }
        await this.getBudgetCategories();
        LoadLayoutStore.finish();
        this.handleCloseAddBudgetCategoryModal();
    };

    handleOpenEditBudgetCategoryModal = (category: BudgetCategory): void => {
        this.openEditBudgetCategoryModal = true;
        this.categoryToEdit = category;
    };
    handleCloseEditBudgetCategoryModal = (): void => {
        this.openEditBudgetCategoryModal = false;
    };

    editBudgetCategory = async (values: EditBudgetCategory): Promise<void> => {
        if (!this.categoryToEdit) return;
        if (!this._budgetId) return;

        LoadLayoutStore.start();

        const result = await this.editBudgetCategoryUseCase.execute(
            new EditBudgetCategory(
                this.categoryToEdit.id,
                values.name,
                values.budget,
            ),
        );

        if (result) {
            ToastManagerStore.success();
        }

        await this.getBudgetCategories();
        LoadLayoutStore.finish();
        this.handleCloseEditBudgetCategoryModal();
    };

    deleteBudgetCategory = async (id: number): Promise<void> => {
        LoadLayoutStore.start();
        const deleteCategory =
            await this.deleteBudgetCategoryUseCase.execute(id);
        if (deleteCategory) {
            await this.getBudgetCategories();
            ToastManagerStore.success();
        }
        LoadLayoutStore.finish();
    };

    updateBudgetCategoriesGroups = (): void => {
        runInAction(() => {
            this.updateConceptGroups = !this.updateConceptGroups;
        });
    };
}
