import { coreTypes } from "@core/core-types.di";
import { type Http } from "@core/data/infrastructures/http/http";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { IdentificationType } from "../../domain/models/identification-document-type.model";
import { IdentificationDocumentTypeDto } from "../dto/identification-document-type.dto";
import { IdentificationDocumentTypeMapper } from "../mappers/identification-document-type.mapper";
@injectable()
export class IdentificationDocumentTypeDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(IdentificationDocumentTypeMapper)
        private readonly identificationDocumentTypeMapper: IdentificationDocumentTypeMapper,
    ) {}
    async fetchAllIdentificationDocumentTypes(): Promise<
        Either<FallbackError, IdentificationType[]>
    > {
        const identificationTypesResult = await this.http.get<
            IdentificationDocumentTypeDto[]
        >("/common_choices/identification_type/");
        return identificationTypesResult
            .mapLeft(() => new FallbackError())
            .map((identificationTypesResponse) =>
                identificationTypesResponse.data.mapNotNull(
                    (identificationType) =>
                        this.identificationDocumentTypeMapper.map(
                            plainToClass(
                                IdentificationDocumentTypeDto,
                                identificationType,
                            ),
                        ),
                ),
            );
    }
}
