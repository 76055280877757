import { Nullable } from "@core/domain/types/nullable.type";
import { ObjectLike } from "@core/domain/types/object-like.type";
import { injectable } from "inversify";

export enum LocalStorageKeysEnum {
    AccessToken = "access_token",
}
@injectable()
export class LocalStorage {
    get(key: LocalStorageKeysEnum): Nullable<string> {
        return localStorage.getItem(key);
    }

    getObject<T extends ObjectLike<T> = Record<string, unknown>>(
        key: LocalStorageKeysEnum,
    ): Nullable<T> {
        const value = this.get(key);

        if (value === null) return value;

        try {
            return JSON.parse(value) as T;
        } catch (error: unknown) {
            if (error instanceof SyntaxError) {
                console.error(error.message);
            }
            return null;
        }
    }

    set(key: LocalStorageKeysEnum, value: string): void {
        localStorage.setItem(key, value);
    }

    setObject(key: LocalStorageKeysEnum, value: Record<string, unknown>): void {
        this.set(key, JSON.stringify(value));
    }

    remove(key: LocalStorageKeysEnum): void {
        localStorage.removeItem(key);
    }
}
