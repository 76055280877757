import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum AdministrativeRecordScopeEnumDto {
    Temporary = "temporary",
    Local = "local",
    National = "national",
    AutonomousCommunity = "autonomous_community",
}

export class AdministrativeRecordScopeDto {
    @IsEnum(AdministrativeRecordScopeEnumDto)
    @IsNotEmpty()
    key!: AdministrativeRecordScopeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
