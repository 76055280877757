import { EventBus } from "@core/domain/models/event-bus.model";
import { injectable } from "inversify";
import { Observable, Subject } from "rxjs";

@injectable()
export class EventBusRepository {
    // DO NOT ADD DEPENDENCIES HERE

    private _bus = new Subject<EventBus>();
    bus: Observable<EventBus> = this._bus;

    emit(event: EventBus): void {
        this._bus.next(event);
    }
}
