import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsNotEmpty,
    IsNumber,
    IsString,
} from "class-validator";

export class SignatureDataDto {
    @IsNotEmpty()
    @IsNumber()
    id!: number;

    @IsNotEmpty()
    @IsNumber()
    document!: number;

    @IsNotEmpty()
    @IsNumber()
    user!: number;

    @IsDateString()
    @IsNotEmpty()
    created!: string;

    @IsDateString()
    @IsNotEmpty()
    modified!: string;

    @IsString()
    @IsNotEmpty()
    image!: string;

    @IsString()
    @IsNotEmpty()
    md5!: string;
}

export class SignaturesDataDto {
    @IsArray()
    @Type(() => SignatureDataDto)
    results!: SignatureDataDto[];
}
