import { CountryMapper } from "@beneficiary/data/mappers/country.mapper";
import { coreTypes } from "@core/core-types.di";
import { CountryDto } from "@core/data/dto/country.dto";
import { type Http } from "@core/data/infrastructures/http/http";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Country } from "@core/domain/models/country.model";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class CountriesDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(CountryMapper)
        private readonly countryMapper: CountryMapper,
    ) {}

    async fetchAllCountries(): Promise<Either<FallbackError, Country[]>> {
        const countriesResults = await this.http.get<CountryDto[]>(
            "/beneficiaries/countries/",
        );

        return countriesResults
            .mapLeft(() => new FallbackError())
            .map((countriesResponse) =>
                countriesResponse.data.mapNotNull((countryDto) =>
                    this.countryMapper.map(
                        plainToClass(CountryDto, countryDto),
                    ),
                ),
            );
    }
}
