import { RequireRoleGuard } from "@core/presentacion/component/routing/require-role/require-role.guard";
import { UserRoleEnum } from "@user/domain/models/user-role.model";
import { lazy } from "react";
import { Route } from "react-router-dom";

const UserListPage = lazy(async () =>
    import(
        /* webpackChunkName: "UserListPage" */ "@user/presentation/pages/list/user-list.page"
    ).then((module) => ({ default: module.UserListPage })),
);

const CreateUserPage = lazy(async () =>
    import(
        /* webpackChunkName: "CreateUserPage" */ "@user/presentation/pages/create/create-user.page"
    ).then((module) => ({ default: module.CreateUserPage })),
);

const EditUserPage = lazy(async () =>
    import(
        /* webpackChunkName: "EditUserPage" */ "@user/presentation/pages/edit/edit-user.page"
    ).then((module) => ({ default: module.EditUserPage })),
);

const DetailUserPage = lazy(async () =>
    import(
        /* webpackChunkName: "DetailUserPage" */ "@user/presentation/pages/detail/detail-user.page"
    ).then((module) => ({ default: module.DetailUserPage })),
);

export class UserPaths {
    static readonly Root = "user";

    static readonly Create = "create";

    static readonly EditParam = ":userId";
    static readonly Edit = `edit/${UserPaths.EditParam}`;

    static readonly DetailParam = ":userId";
    static readonly Detail = `detail/${UserPaths.DetailParam}`;
}

export class UserRoutes {
    static LIST = `/${UserPaths.Root}`;

    static CREATE = `/${UserPaths.Root}/${UserPaths.Create}`;

    static EDIT = (userId: number): string =>
        `/${UserPaths.Root}/${UserPaths.Edit.replace(
            UserPaths.EditParam,
            userId.toString(),
        )}`;

    static DETAIL = (userId: number): string =>
        `/${UserPaths.Root}/${UserPaths.Detail.replace(
            UserPaths.DetailParam,
            userId.toString(),
        )}`;
}

export const userRoutes = (
    <Route path={UserPaths.Root}>
        <Route
            element={<UserListPage />}
            index
        />
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                    ]}
                >
                    <CreateUserPage />
                </RequireRoleGuard>
            }
            path={UserPaths.Create}
        />
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                    ]}
                >
                    <EditUserPage />
                </RequireRoleGuard>
            }
            path={UserPaths.Edit}
        />
        <Route
            element={<DetailUserPage />}
            path={UserPaths.Detail}
        />
    </Route>
);
