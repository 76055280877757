import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { FinancialEntityDatasource } from "@project/data/datasource/financial-entity.datasource";
import { BudgetType } from "@project/domain/models/budget/financial-entity-budget.model";
import { FinancialEntitiesSearchFilters } from "@project/domain/models/financial-entity/financial-entities-search-filters";
import {
    FinancialEntities,
    FinancialEntity,
} from "@project/domain/models/financial-entity/financial-entities.model";
import { FinancialEntityType } from "@project/domain/models/financial-entity/financial-entity-type";
import { FinancingType } from "@project/domain/models/financial-entity/financing-type";
import { AddBudgetFromTemplateFormValidated } from "@project/presentation/components/financial-entity-form/add-budget-from-template-form";
import { inject, injectable } from "inversify";
import { CreateFinancialEntity } from "../../domain/models/financial-entity/create-financial-entity.model";
import { EditFinancialEntity } from "../../domain/models/financial-entity/edit-financial-entity.model";

@injectable()
export class FinancialEntityRepository {
    constructor(
        @inject(FinancialEntityDatasource)
        private readonly financialEntityDatasource: FinancialEntityDatasource,
    ) {}

    async create(
        createFinancialEntity: CreateFinancialEntity,
    ): Promise<Either<ValidationError | FallbackError, FinancialEntity>> {
        return this.financialEntityDatasource.create(createFinancialEntity);
    }

    async edit(
        financialEntity: EditFinancialEntity,
    ): Promise<Either<ValidationError | FallbackError, FinancialEntity>> {
        return this.financialEntityDatasource.update(financialEntity);
    }

    async getFinancialEntitiesTypes(): Promise<
        Either<FallbackError, FinancialEntityType[]>
    > {
        return this.financialEntityDatasource.fetchTypes();
    }
    async getAllBy(
        filters?: FinancialEntitiesSearchFilters,
    ): Promise<Either<FallbackError, FinancialEntities>> {
        return this.financialEntityDatasource.fetchAllBy(
            Pagination.NoPagination(),
            filters,
        );
    }
    async getBy(
        pagination: Pagination,
        filters?: FinancialEntitiesSearchFilters,
    ): Promise<Either<FallbackError, FinancialEntities>> {
        return this.financialEntityDatasource.fetchAllBy(pagination, filters);
    }

    async getById(
        financialEntityId: number,
    ): Promise<Either<FallbackError, FinancialEntity>> {
        return this.financialEntityDatasource.fetchById(financialEntityId);
    }

    async getAllFinancingTypes(): Promise<
        Either<FallbackError, FinancingType[]>
    > {
        return this.financialEntityDatasource.fetchFinancingTypes();
    }

    async createBudgetFromTemplate(
        budgetType: AddBudgetFromTemplateFormValidated,
        entityId: number,
    ): Promise<Either<FallbackError, boolean>> {
        const response =
            this.financialEntityDatasource.createBudgetFromTemplate(
                budgetType,
                entityId,
            );

        return response;
    }

    async getBudgetTypes(): Promise<Either<FallbackError, BudgetType[]>> {
        return this.financialEntityDatasource.fetchBudgetTypes();
    }
}
