import { inject, injectable } from "inversify";
import { DepositRepository } from "../../data/repositories/deposit.repository";
import { Deposit } from "../models/deposit.model";

@injectable()
export class GetAllDepositsUseCase {
    constructor(
        @inject(DepositRepository)
        private readonly depositRepository: DepositRepository,
    ) {}

    async execute(): Promise<Deposit[]> {
        const getAllDepositsResult = await this.depositRepository.getAll();

        return getAllDepositsResult.fold(
            () => [],
            (deposit) => deposit,
        );
    }
}
