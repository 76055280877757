import { InternalNotesRepository } from "@beneficiary/data/repositories/internal-notes.repository";
import { EditInternalNote } from "@beneficiary/domain/models/internal-notes/edit-internal-note.model";
import { InternalNote } from "@beneficiary/domain/models/internal-notes/internal-note.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class EditInternalNoteUseCase {
    constructor(
        @inject(InternalNotesRepository)
        private readonly internalNotesRepository: InternalNotesRepository,
    ) {}

    async execute(
        internalNote: EditInternalNote,
    ): Promise<Nullable<InternalNote>> {
        const editResult =
            await this.internalNotesRepository.edit(internalNote);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedInternalNote) => editedInternalNote,
        );
    }
}
