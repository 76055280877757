import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CreateFinancialEntityBody } from "@project/data/dto/financial-entity/create-financial-entity.body";
import { EditFinancialEntityBody } from "@project/data/dto/financial-entity/edit-financial-entity.body";
import {
    FinancialEntitiesDto,
    FinancialEntitiesQuery,
    FinancialEntityDto,
} from "@project/data/dto/financial-entity/financial-entities.dto";
import { FinancialEntityTypeDto } from "@project/data/dto/financial-entity/financial-entity-type.dto";
import { CreateFinancialEntityMapper } from "@project/data/mappers/financial-entity/create-financial-entity.mapper";
import { EditFinancialEntityMapper } from "@project/data/mappers/financial-entity/edit-financial-entity.mapper";
import { FinancialEntitiesMapper } from "@project/data/mappers/financial-entity/financial-entities.mapper";
import { FinancialEntityMapper } from "@project/data/mappers/financial-entity/financial-entity-mapper";
import { FinancialEntityTypeEnumMapper } from "@project/data/mappers/financial-entity/financial-entity-type-enum.mapper";
import { FinancialEntityTypeMapper } from "@project/data/mappers/financial-entity/financial-entity-type.mapper";
import { BudgetType } from "@project/domain/models/budget/financial-entity-budget.model";
import { CreateFinancialEntity } from "@project/domain/models/financial-entity/create-financial-entity.model";
import { EditFinancialEntity } from "@project/domain/models/financial-entity/edit-financial-entity.model";
import { FinancialEntitiesSearchFilters } from "@project/domain/models/financial-entity/financial-entities-search-filters";
import {
    FinancialEntities,
    FinancialEntity,
} from "@project/domain/models/financial-entity/financial-entities.model";
import { FinancialEntityType } from "@project/domain/models/financial-entity/financial-entity-type";
import { FinancingType } from "@project/domain/models/financial-entity/financing-type";
import { AddBudgetFromTemplateFormValidated } from "@project/presentation/components/financial-entity-form/add-budget-from-template-form";
import { plainToClass, plainToInstance } from "class-transformer";
import { inject, injectable } from "inversify";
import { BudgetTypeDto } from "../dto/financial-entity/budget-type.dto";
import { FinancingTypeDto } from "../dto/financial-entity/financing-type.dto";
import { BudgetTypeMapper } from "../mappers/financial-entity/budget-type-mapper";
import { FinancingTypeMapper } from "../mappers/financial-entity/financing-type-mapper";

const ROOT_PATH = "/financial_entities/";

@injectable()
export class FinancialEntityDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(FinancialEntityTypeMapper)
        private readonly financialEntityTypeMapper: FinancialEntityTypeMapper,
        @inject(FinancialEntityTypeEnumMapper)
        private readonly financialEntityTypeEnumMapper: FinancialEntityTypeEnumMapper,
        @inject(FinancialEntitiesMapper)
        private readonly financialEntitiesMapper: FinancialEntitiesMapper,
        @inject(FinancialEntityMapper)
        private readonly financialEntityMapper: FinancialEntityMapper,
        @inject(CreateFinancialEntityMapper)
        private readonly createFinancialEntityMapper: CreateFinancialEntityMapper,
        @inject(EditFinancialEntityMapper)
        private readonly editFinancialEntityMapper: EditFinancialEntityMapper,
        @inject(FinancingTypeMapper)
        private readonly financingTypeMapper: FinancingTypeMapper,
        @inject(BudgetTypeMapper)
        private readonly budgetTypeMapper: BudgetTypeMapper,
    ) {}

    async create(
        newFinancialEntity: CreateFinancialEntity,
    ): Promise<Either<ValidationError | FallbackError, FinancialEntity>> {
        const createdFinancialEntityDto =
            this.createFinancialEntityMapper.mapToDto(newFinancialEntity);

        const createFinancialEntityResult = await this.http.post<
            FinancialEntityDto,
            CreateFinancialEntityBody
        >(ROOT_PATH, createdFinancialEntityDto);

        return createFinancialEntityResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdFinancialEntity = this.financialEntityMapper.map(
                    plainToClass(FinancialEntityDto, response.data),
                );

                if (!createdFinancialEntity)
                    return Either.Left(new FallbackError());

                return Either.Right(createdFinancialEntity);
            });
    }

    async update(
        financialEntityToEdit: EditFinancialEntity,
    ): Promise<Either<ValidationError | FallbackError, FinancialEntity>> {
        const editFinancialEntityBody = this.editFinancialEntityMapper.mapToDto(
            financialEntityToEdit,
        );

        const editFinancialEntityResult = await this.http.put<
            FinancialEntityDto,
            EditFinancialEntityBody
        >(`${ROOT_PATH}${financialEntityToEdit.id}/`, editFinancialEntityBody);

        return editFinancialEntityResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const financialEntity = this.financialEntityMapper.map(
                    plainToClass(FinancialEntityDto, response.data),
                );

                if (!financialEntity) return Either.Left(new FallbackError());

                return Either.Right(financialEntity);
            });
    }

    async fetchTypes(): Promise<Either<FallbackError, FinancialEntityType[]>> {
        const responseResult = await this.http.get<FinancialEntityTypeDto[]>(
            `${ROOT_PATH}types/`,
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((financialEntityTypeDto) =>
                    this.financialEntityTypeMapper.map(
                        plainToInstance(
                            FinancialEntityTypeDto,
                            financialEntityTypeDto,
                        ),
                    ),
                ),
            );
    }

    async fetchFinancingTypes(): Promise<
        Either<FallbackError, FinancingType[]>
    > {
        const responseResult = await this.http.get<FinancingTypeDto[]>(
            `/projects_financial_entities/financing_types/`,
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((financingTypeDto) =>
                    this.financingTypeMapper.map(
                        plainToInstance(FinancingTypeDto, financingTypeDto),
                    ),
                ),
            );
    }

    async fetchAllBy(
        pagination: Pagination,
        filters?: FinancialEntitiesSearchFilters,
    ): Promise<Either<FallbackError, FinancialEntities>> {
        const query: FinancialEntitiesQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.entityId) {
            query.entity = filters.entityId;
        }
        if (filters?.name) {
            query.search = filters.name;
        }
        if (filters?.hasBudget) {
            query.has_budget = filters.hasBudget;
        }
        if (filters?.financialEntityType) {
            query.type = this.financialEntityTypeEnumMapper.mapToDto(
                filters.financialEntityType,
            );
        }

        if (filters?.statusFinancialEntity) {
            query.is_active = filters.statusFinancialEntity;
        }

        if (filters?.entityIds) query.entities = filters.entityIds.join(",");

        const responseResult = await this.http.get<FinancialEntitiesDto>(
            ROOT_PATH,
            { query },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.financialEntitiesMapper.map(
                    plainToInstance(FinancialEntitiesDto, response.data),
                ),
            );
    }
    async fetchById(
        financialEntityId: number,
    ): Promise<Either<FallbackError, FinancialEntity>> {
        const financialEntityResult = await this.http.get<FinancialEntityDto>(
            `${ROOT_PATH}${financialEntityId}/`,
        );

        return financialEntityResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const project = this.financialEntityMapper.map(
                    plainToClass(FinancialEntityDto, response.data),
                );

                if (!project) return Either.Left(new FallbackError());

                return Either.Right(project);
            });
    }

    async createBudgetFromTemplate(
        budgetType: AddBudgetFromTemplateFormValidated,
        entityId: number,
    ): Promise<Either<FallbackError, boolean>> {
        const projectsResult = await this.http.post<BudgetTypeDto>(
            `/financial_entities/${entityId}/create_budget_from_template/`,
            {
                budget_type: budgetType.budgetType,
            },
        );

        return projectsResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }

    async fetchBudgetTypes(): Promise<Either<FallbackError, BudgetType[]>> {
        const responseResult = await this.http.get<BudgetTypeDto[]>(
            `/projects_financial_entities/budget_types/`,
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((budgetTypeDto) =>
                    this.budgetTypeMapper.map(
                        plainToInstance(BudgetTypeDto, budgetTypeDto),
                    ),
                ),
            );
    }
}
