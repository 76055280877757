import { Nullable } from "@core/domain/types/nullable.type";
import { SupplierIdentificationTypeDto } from "@entity/data/dto/supplier/supplier-identification-type.dto";
import { SupplierIdentificationEnumMapper } from "@entity/data/mappers/supplier/supplier-identification-enum.mapper";
import { SupplierIdentificationType } from "@entity/domain/models/supplier-identification-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class SupplierIdentificationTypeMapper {
    constructor(
        @inject(SupplierIdentificationEnumMapper)
        private readonly supplierIdentificationEnumMapper: SupplierIdentificationEnumMapper,
    ) {}
    map(
        identificationTypeDto: SupplierIdentificationTypeDto,
    ): Nullable<SupplierIdentificationType> {
        const errors = validateSync(identificationTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.supplierIdentificationEnumMapper.map(
            identificationTypeDto.key,
        );
        if (!id) return null;

        return new SupplierIdentificationType(id, identificationTypeDto.value);
    }
}
