import { AuthenticatedUser } from "@authentication/domain/models/authenticated-user.model";
import { GetLoggedUserUseCase } from "@authentication/domain/usecases/get-logged-user.usecase";
import { CreateDocument } from "@core/domain/models/create-document.model";
import { DocumentCategory } from "@core/domain/models/documents-category.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { CreateDocumentUseCase } from "@core/domain/usecases/create-document.usecase";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { GetAllDocumentCategoriesUseCase } from "@core/domain/usecases/get-all-document-categories.usecase";
import { SignDocumentUseCase } from "@core/domain/usecases/sign-document.usecase";
import { UpdateSignDocumentUseCase } from "@core/domain/usecases/update-sign-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { User } from "@user/domain/models/user.model";
import { GetUserByIdUseCase } from "@user/domain/usecases/get-user-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

export interface DocumentTable {
    id: number;
    documentCategoryLabel: string;
    documentCategory: Undefinable<number>;
    name: string;
}

export interface DocumentTableFilters {
    name: string;
    category: Undefinable<number>;
}

@injectable()
export class DocumentsTabViewModel extends BaseViewModel {
    @observable
    initiallyLoading = true;

    private user: Undefinable<AuthenticatedUser>;

    initialFilterValues: DocumentTableFilters = {
        name: "",
        category: undefined,
    };

    @observable
    documentFilters: DocumentTableFilters = {
        ...this.initialFilterValues,
    };

    @observable
    documentCategories: DocumentCategory[] = [];

    @observable
    signatureModal = false;

    @observable
    editSignatureModal = false;

    @observable
    documentToSign: Undefinable<IncDocument>;

    @observable
    documentToEditSignature: Undefinable<IncDocument>;

    @observable
    userWhoSignedEditDocument: Nullable<User> = null;

    constructor(
        @inject(CreateDocumentUseCase)
        private readonly createDocumentUseCase: CreateDocumentUseCase,
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
        @inject(GetAllDocumentCategoriesUseCase)
        private readonly getAllDocumentCategoriesUseCase: GetAllDocumentCategoriesUseCase,
        @inject(SignDocumentUseCase)
        private readonly signDocumentUseCase: SignDocumentUseCase,
        @inject(GetLoggedUserUseCase)
        private readonly getLoggedUserUseCase: GetLoggedUserUseCase,
        @inject(UpdateSignDocumentUseCase)
        private readonly updateSignDocumentUseCase: UpdateSignDocumentUseCase,
        @inject(GetUserByIdUseCase)
        private readonly getUserByIdUseCase: GetUserByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllDocumentCategories(),
            this.getLoggedUser(),
        ]);

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    async getAllDocumentCategories(): Promise<void> {
        const categories = await this.getAllDocumentCategoriesUseCase.execute();

        runInAction(() => {
            this.documentCategories = categories;
        });
    }

    async getLoggedUser(): Promise<void> {
        const user = this.getLoggedUserUseCase.execute();

        runInAction(() => {
            this.user = user;
        });
    }

    @action
    setFilters(filters: DocumentTableFilters): void {
        this.documentFilters = filters;
    }

    async uploadDocument(
        createDocument: CreateDocument,
    ): Promise<Nullable<IncDocument>> {
        LoadLayoutStore.start();

        const document =
            await this.createDocumentUseCase.execute(createDocument);

        if (document) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return document;
    }

    async deleteDocument(documentId: number): Promise<boolean> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return deleted;
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(documentUrl);

        LoadLayoutStore.finish();
    }

    openSignatureModal(document: IncDocument): void {
        runInAction(() => {
            this.signatureModal = true;
            this.documentToSign = document;
        });
    }
    closeSignatureModal(): void {
        runInAction(() => {
            this.signatureModal = false;
            this.documentToSign = undefined;
        });
    }
    async getUserWhoSignedEditDocument(document: IncDocument): Promise<void> {
        if (document.documentSignature?.user) {
            const user = await this.getUserByIdUseCase.execute(
                document.documentSignature.user,
            );
            runInAction(() => {
                this.userWhoSignedEditDocument = user;
            });
        }
    }

    async openEditSignatureModal(document: IncDocument): Promise<void> {
        await this.getUserWhoSignedEditDocument(document);
        runInAction(() => {
            this.editSignatureModal = true;
            this.documentToEditSignature = document;
        });
    }

    closeEditSignatureModal(): void {
        runInAction(() => {
            this.editSignatureModal = false;
            this.documentToEditSignature = undefined;
            this.userWhoSignedEditDocument = null;
        });
    }

    async uploadSignature(signature: string): Promise<void> {
        LoadLayoutStore.start();

        if (this.user && this.documentToSign) {
            const signed = await this.signDocumentUseCase.execute(
                this.documentToSign.id,
                signature,
                this.user.id,
            );
            if (signed) {
                ToastManagerStore.success();
            }
        }

        LoadLayoutStore.finish();
    }

    async uploadEditSignature(signature: string): Promise<void> {
        LoadLayoutStore.start();

        if (this.user && this.documentToEditSignature?.documentSignature?.id) {
            const signed = await this.updateSignDocumentUseCase.execute(
                this.documentToEditSignature.documentSignature.id,
                this.documentToEditSignature.id,
                signature,
                this.user.id,
            );
            if (signed) {
                ToastManagerStore.success();
            }
        }

        LoadLayoutStore.finish();
    }
}
