import { type Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EditProceedingMapper } from "@proceeding/data/mappers/edit-proceeding.mapper";
import { ProceedingMapper } from "@proceeding/data/mappers/proceeding.mapper";
import { Proceeding } from "@proceeding/domain/models/proceeding.model";
import { EditProceedingUseCase } from "@proceeding/domain/usecases/edit-proceeding.usecase";
import { GetProceedingByIdUseCase } from "@proceeding/domain/usecases/get-proceeding-by-id.usecase";
import { ProceedingFormValuesValidated } from "@proceeding/presentation/components/proceeding-form.component";
import { inject, injectable } from "inversify";
import { flow, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class EditProceedingPageViewModel extends BaseViewModel {
    proceedingId: Undefinable<number> = undefined;

    @observable
    initialLoading = true;

    @observable
    proceeding: Undefinable<Proceeding> = undefined;

    constructor(
        @inject(EditProceedingMapper)
        private readonly editProceedingMapper: EditProceedingMapper,
        @inject(EditProceedingUseCase)
        private readonly editProceedingUseCase: EditProceedingUseCase,
        @inject(GetProceedingByIdUseCase)
        private readonly getProceedingByIdUseCase: GetProceedingByIdUseCase,
        @inject(ProceedingMapper)
        private readonly proceedingMapper: ProceedingMapper,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        await this.getProceeding();

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getProceeding(): Promise<void> {
        if (this.proceedingId === undefined)
            throw Error("Missing proceedingId");

        const proceeding = await this.getProceedingByIdUseCase.execute(
            this.proceedingId,
        );

        runInAction(() => {
            if (proceeding) {
                this.proceeding = proceeding;
            }
        });
    }

    async editProceeding(
        proceedingForm: ProceedingFormValuesValidated,
    ): Promise<boolean> {
        if (!this.proceedingId) throw Error("Missing proceedingId");

        LoadLayoutStore.start();

        const editProceeding = this.editProceedingMapper.mapFromFormValues(
            this.proceedingId,
            proceedingForm,
        );
        const editedSuccessfully =
            await this.editProceedingUseCase.execute(editProceeding);

        if (editedSuccessfully) {
            await this.getProceeding();
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return Boolean(editedSuccessfully);
    }
}
