import { Undefinable } from "@core/domain/types/undefinable.type";
import { CreateProceeding } from "@proceeding/domain/models/create-proceeding.model";
import {
    Proceeding,
    ProceedingResolution,
} from "@proceeding/domain/models/proceeding.model";

export class EditProceedingResolution {
    constructor(
        public description: ProceedingResolution["description"],
        public date: ProceedingResolution["date"],
        public authorizationFile: number,
    ) {}
}

export class EditProceeding extends CreateProceeding {
    constructor(
        public readonly id: number,
        beneficiary: CreateProceeding["beneficiary"],
        project: CreateProceeding["project"],
        activities: CreateProceeding["activities"],
        accountableProfessional: CreateProceeding["accountableProfessional"],
        reason: CreateProceeding["reason"],
        documents: CreateProceeding["documents"],
        public status: Proceeding["status"],
        public authorizationFile?: Undefinable<number>,
        public resolution?: EditProceedingResolution,
    ) {
        super(
            beneficiary,
            project,
            activities,
            accountableProfessional,
            reason,
            documents,
        );
    }
}
