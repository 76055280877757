import { useEffect, useRef } from "react";

export const useWillUnmount = (
    functToExecute: () => Promise<void> | void,
): void => {
    const didMount = useRef<boolean>(false);

    useEffect(
        () => () => {
            if (didMount.current) {
                functToExecute();
            }
            didMount.current = true;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
};
