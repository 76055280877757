import { CreateExternalDerivationMapper } from "@beneficiary/data/mappers/external-derivation/create-external-derivation.mapper";
import { EditExternalDerivationMapper } from "@beneficiary/data/mappers/external-derivation/edit-external-derivation.mapper";
import { CenterType } from "@beneficiary/domain/models/center-type.model";
import { ExternalDerivation } from "@beneficiary/domain/models/external-derivation/external-derivation.model";
import { CreateExternalDerivationUseCase } from "@beneficiary/domain/usecases/external-derivation/create-external-derivation.usecase";
import { DeleteExternalDerivationUseCase } from "@beneficiary/domain/usecases/external-derivation/delete-external-derivation.usecase";
import { EditExternalDerivationUseCase } from "@beneficiary/domain/usecases/external-derivation/edit-external-derivation.usecase";
import { GetAllExternalDerivationsUseCase } from "@beneficiary/domain/usecases/external-derivation/get-all-external-derivations.usecase";
import { GetAllCenterTypesUseCase } from "@beneficiary/domain/usecases/get-all-center-types.usecase";
import { ExternalDerivationFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/external-derivation/form/external-derivation-form";
import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

export interface DocumentTable {
    id: number;
    name: string;
    fileList: [];
    uploadDate: string;
}

@injectable()
export class ExternalDerivationViewModel extends BaseViewModel {
    @observable
    beneficiaryId: Undefinable<number> = undefined;

    @observable
    initialLoading: boolean = true;

    @observable
    externalDerivations: ExternalDerivation[] = [];

    @observable
    centerTypes: CenterType[] = [];

    @observable
    showAddExternalDerivationModal: boolean = false;

    @observable
    showEditExternalDerivationModal: boolean = false;

    @observable
    externalDerivationToEdit: Undefinable<ExternalDerivation> = undefined;

    constructor(
        @inject(GetAllCenterTypesUseCase)
        private readonly getAllCenterTypesUseCase: GetAllCenterTypesUseCase,
        @inject(GetAllExternalDerivationsUseCase)
        private readonly getAllExternalDerivationsUseCase: GetAllExternalDerivationsUseCase,
        @inject(CreateExternalDerivationUseCase)
        private readonly createExternalDerivationUseCase: CreateExternalDerivationUseCase,
        @inject(CreateExternalDerivationMapper)
        private readonly createExternalDerivationMapper: CreateExternalDerivationMapper,
        @inject(DeleteExternalDerivationUseCase)
        private readonly deleteExternalDerivationUseCase: DeleteExternalDerivationUseCase,
        @inject(EditExternalDerivationUseCase)
        private readonly editExternalDerivationUseCase: EditExternalDerivationUseCase,
        @inject(EditExternalDerivationMapper)
        private readonly editExternalDerivationMapper: EditExternalDerivationMapper,
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            await this.getExternalDerivations(),
            await this.getAllCenterTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getExternalDerivations(): Promise<void> {
        if (this.beneficiaryId === undefined)
            throw Error("Missing beneficiaryId");

        const externalDerivations =
            await this.getAllExternalDerivationsUseCase.execute(
                this.beneficiaryId,
            );
        runInAction(() => {
            this.externalDerivations = externalDerivations;
        });
    }

    async addExternalDerivation(
        beneficiaryId: Undefinable<number>,
        externalDerivation: ExternalDerivationFormValuesValidated,
    ): Promise<Nullable<ExternalDerivation>> {
        if (!beneficiaryId) throw Error("Missing beneficiaryId");

        LoadLayoutStore.start();

        const createExternalDerivationResult =
            await this.createExternalDerivationUseCase.execute(
                this.createExternalDerivationMapper.mapFromFormValues(
                    beneficiaryId,
                    externalDerivation,
                ),
            );

        if (createExternalDerivationResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
        return createExternalDerivationResult;
    }

    async editExternalDerivation(
        beneficiaryId: number,
        values: ExternalDerivationFormValuesValidated,
    ): Promise<void> {
        if (!this.externalDerivationToEdit?.id || !beneficiaryId)
            throw Error("Missing external derivation");

        LoadLayoutStore.start();
        const editExternalDerivationResult =
            await this.editExternalDerivationUseCase.execute(
                this.editExternalDerivationMapper.mapFromModal(
                    this.externalDerivationToEdit.id,
                    beneficiaryId,
                    values,
                ),
            );

        if (editExternalDerivationResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    @action
    openEditExternalDerivationModal(
        externalDerivation: Undefinable<ExternalDerivation>,
    ): void {
        this.externalDerivationToEdit = externalDerivation;
        this.showEditExternalDerivationModal = true;
    }

    async deleteExternalDerivation(
        externalDerivationId: number,
    ): Promise<void> {
        LoadLayoutStore.start();

        const deleted =
            await this.deleteExternalDerivationUseCase.execute(
                externalDerivationId,
            );

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async getAllCenterTypes(): Promise<void> {
        const centerTypes = await this.getAllCenterTypesUseCase.execute();

        runInAction(() => {
            this.centerTypes = centerTypes;
        });
    }

    async deleteDocument(documentId: number): Promise<boolean> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
        return deleted;
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        if (documentUrl) {
            await this.downloadDocumentUseCase.execute(documentUrl);
        }

        LoadLayoutStore.finish();
    }

    @action
    setShowAddExternalDerivationModal(show: boolean): void {
        this.showAddExternalDerivationModal = show;
    }

    @action
    setShowEditExternalDerivationModal(show: boolean): void {
        this.showEditExternalDerivationModal = show;
    }

    async reloadExternalDerivations(): Promise<void> {
        await this.getExternalDerivations();
    }
}
