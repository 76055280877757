import { PaginatedDto } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class DocumentCategoryDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsOptional()
    description!: Nullable<string>;
}

export class DocumentCategoriesDto extends PaginatedDto {
    @IsArray()
    @Type(() => DocumentCategoryDto)
    results!: DocumentCategoryDto[];
}
