import { injectable } from "inversify";
import { CreateProceeding } from "../../domain/models/create-proceeding.model";
import { ProceedingFormValuesValidated } from "../../presentation/components/proceeding-form.component";
import { CreateProceedingBody } from "../dto/create-proceeding.body";

@injectable()
export class CreateProceedingMapper {
    mapFromForm(
        proceedingForm: ProceedingFormValuesValidated,
    ): CreateProceeding {
        return new CreateProceeding(
            proceedingForm.general.beneficiary,
            proceedingForm.general.project,
            proceedingForm.general.activities,
            proceedingForm.general.accountableProfessional,
            proceedingForm.general.motivation,
        );
    }

    mapToDto(createProceeding: CreateProceeding): CreateProceedingBody {
        return {
            beneficiary: createProceeding.beneficiary,
            project: createProceeding.project,
            activities: createProceeding.activities,
            professional_responsible: createProceeding.accountableProfessional,
            reason: createProceeding.reason,
        };
    }
}
