import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum CrosscuttingScopeEnumDto {
    Innovation = "innovation",
    GenderPerspective = "gender_perspective",
    DiversityPerspective = "diversity_perspective",
    Digitalization = "digitalization",
    ContinuousImprovement = "continuous_improvement",
    Accessibility = "accessibility",
    Sustainability = "sustainability",
    SustainableDevelopmentGoals = "sustainable_development_goals",
    Other = "other",
}

export class CrosscuttingScopeDto {
    @IsEnum(CrosscuttingScopeEnumDto)
    @IsNotEmpty()
    key!: CrosscuttingScopeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
