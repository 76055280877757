import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Nullable } from "@core/domain/types/nullable.type";
import { BudgetDataSource } from "@project/data/datasource/budget.datasource";
import {
    BudgetsSearchFilters,
    BudgetsSummary,
} from "@project/domain/models/budget-summary.model";
import { CreateBudgetCategoryConcept } from "@project/domain/models/budget/budget-category/concepts/create-budget-category-concept.model";
import { EditBudgetCategoryConcept } from "@project/domain/models/budget/budget-category/concepts/edit-budget-category-concept.model";
import { CreateBudgetCategory } from "@project/domain/models/budget/budget-category/create-budget-category.model";
import { EditBudgetCategory } from "@project/domain/models/budget/budget-category/edit-budget-category.model";
import { CreateBudgetCategoryGroup } from "@project/domain/models/budget/budget-category/groups/create-budget-category-group.model";
import { EditBudgetCategoryGroup } from "@project/domain/models/budget/budget-category/groups/edit-budget-category-group.model";
import { BudgetColumnType } from "@project/domain/models/budget/budget-column/budget-column-type.model";
import { CreateBudgetColumn } from "@project/domain/models/budget/budget-column/create-budget-column.model";
import { EditBudgetColumn } from "@project/domain/models/budget/budget-column/edit-budget-column.model";
import { CreateFinancialEntityBudget } from "@project/domain/models/budget/create-financial-entity-budget.model";
import { EditFinancialEntityBudgetModel } from "@project/domain/models/budget/edit-financial-entity-budget.model";
import {
    BudgetCategories,
    BudgetCategory,
    BudgetCategoryConcept,
    BudgetCategoryConcepts,
    BudgetCategoryGroup,
    BudgetCategoryGroups,
    BudgetColumn,
    BudgetColumns,
    BudgetValidation,
    BudgetValidations,
    CreateBudgetValidation,
    FinancialEntityBudget,
} from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetRepository {
    constructor(
        @inject(BudgetDataSource)
        private readonly budgetDataSource: BudgetDataSource,
    ) {}

    async fetchAllBy(
        pagination: Pagination,
        filters?: BudgetsSearchFilters,
    ): Promise<Either<FallbackError, BudgetsSummary>> {
        return this.budgetDataSource.fetchAllBy(pagination, filters);
    }

    async getById(
        id: number,
    ): Promise<Either<FallbackError, FinancialEntityBudget>> {
        return this.budgetDataSource.getById(id);
    }

    async create(
        budget: CreateFinancialEntityBudget,
    ): Promise<Either<ValidationError | FallbackError, FinancialEntityBudget>> {
        return this.budgetDataSource.create(budget);
    }

    async edit(
        budget: EditFinancialEntityBudgetModel,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<FinancialEntityBudget>>
    > {
        return this.budgetDataSource.edit(budget);
    }

    async delete(id: number): Promise<Either<FallbackError, true>> {
        return this.budgetDataSource.delete(id);
    }
    async clone(id: number): Promise<Either<FallbackError, true>> {
        return this.budgetDataSource.clone(id);
    }
    async getColumnsByBudgetId(
        budgetId: number,
    ): Promise<Either<FallbackError, BudgetColumns>> {
        return this.budgetDataSource.getColumnsByBudgetId(budgetId);
    }
    async getColumnsTypes(): Promise<
        Either<FallbackError, BudgetColumnType[]>
    > {
        return this.budgetDataSource.getColumnsTypes();
    }
    async createColumn(
        column: CreateBudgetColumn,
    ): Promise<Either<ValidationError | FallbackError, BudgetColumn>> {
        return this.budgetDataSource.createColumn(column);
    }
    async editColumn(
        column: EditBudgetColumn,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<BudgetColumn>>
    > {
        return this.budgetDataSource.editColumn(column);
    }
    async deleteColumn(columnId: number): Promise<Either<FallbackError, true>> {
        return this.budgetDataSource.deleteColumn(columnId);
    }
    async getCategoriesByBudgetId(
        budgetId: number,
    ): Promise<Either<FallbackError, BudgetCategories>> {
        return this.budgetDataSource.getCategoriesByBudgetId(budgetId);
    }
    async createCategory(
        category: CreateBudgetCategory,
    ): Promise<Either<ValidationError | FallbackError, BudgetCategory>> {
        return this.budgetDataSource.createCategory(category);
    }

    async editCategory(
        category: EditBudgetCategory,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<BudgetCategory>>
    > {
        return this.budgetDataSource.editCategory(category);
    }

    async deleteCategory(
        categoryId: number,
    ): Promise<Either<FallbackError, true>> {
        return this.budgetDataSource.deleteCategory(categoryId);
    }

    // category groups
    async getCategoryGroupsByCategoryId(
        categoryId: number,
    ): Promise<Either<FallbackError, BudgetCategoryGroups>> {
        return this.budgetDataSource.getCategoryGroupsByCategoryId(categoryId);
    }

    async createCategoryGroup(
        categoryGroup: CreateBudgetCategoryGroup,
    ): Promise<Either<ValidationError | FallbackError, BudgetCategoryGroup>> {
        return this.budgetDataSource.createCategoryGroup(categoryGroup);
    }

    async editCategoryGroup(
        categoryGroup: EditBudgetCategoryGroup,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<BudgetCategoryGroup>>
    > {
        return this.budgetDataSource.editCategoryGroup(categoryGroup);
    }

    async deleteCategoryGroup(
        categoryGroupId: number,
    ): Promise<Either<FallbackError, true>> {
        return this.budgetDataSource.deleteCategoryGroup(categoryGroupId);
    }

    // category concepts
    async getCategoryConceptsByCategoryAndGroupId(
        categoryId: number,
        groupId?: number,
    ): Promise<Either<FallbackError, BudgetCategoryConcepts>> {
        return this.budgetDataSource.getCategoryConceptsByCategoryAndGroupId(
            categoryId,
            groupId,
        );
    }

    async getCategoryConceptsByCategoryId(
        categoryId: number,
    ): Promise<Either<FallbackError, BudgetCategoryConcepts>> {
        return this.budgetDataSource.getConceptsByCategoryId(categoryId);
    }

    async createCategoryConcept(
        categoryConcept: CreateBudgetCategoryConcept,
    ): Promise<Either<ValidationError | FallbackError, BudgetCategoryConcept>> {
        return this.budgetDataSource.createConcept(categoryConcept);
    }

    async editCategoryConcept(
        categoryConcept: EditBudgetCategoryConcept,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<BudgetCategoryConcept>>
    > {
        return this.budgetDataSource.editConcept(categoryConcept);
    }

    async deleteCategoryConcept(
        categoryConceptId: number,
    ): Promise<Either<FallbackError, true>> {
        return this.budgetDataSource.deleteConcept(categoryConceptId);
    }

    async getBudgetValidations(
        budgetId: number,
    ): Promise<Either<FallbackError, BudgetValidations>> {
        return this.budgetDataSource.getValidationsByBudgetId(budgetId);
    }

    async createValidation(
        createValidation: CreateBudgetValidation,
    ): Promise<Either<ValidationError | FallbackError, BudgetValidation>> {
        return this.budgetDataSource.createValidation(createValidation);
    }

    async editValidation(
        validation: BudgetValidation,
    ): Promise<Either<ValidationError | FallbackError, BudgetValidation>> {
        return this.budgetDataSource.editValidation(validation);
    }

    async deleteValidation(
        id: number,
    ): Promise<Either<ValidationError | FallbackError, true>> {
        return this.budgetDataSource.deleteValidation(id);
    }
}
