import { Pagination } from "@core/domain/models/pagination";
import { EmployeeRepository } from "@entity/data/repositories/employee.repository";
import {
    Employee,
    EmployeeSearchFilters,
} from "@entity/domain/models/employee/employee.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllEmployeesByUseCase {
    constructor(
        @inject(EmployeeRepository)
        private readonly employeeRepository: EmployeeRepository,
    ) {}

    async execute(filters?: EmployeeSearchFilters): Promise<Employee[]> {
        const employeesResult = await this.employeeRepository.searchAllBy(
            Pagination.NoPagination(),
            filters,
        );

        return employeesResult.fold(
            () => [],
            (employees) => employees.employees,
        );
    }
}
