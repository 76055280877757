import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { IndicatorMapper } from "@project/data/mappers/technical-proposal/indicator.mapper";
import {
    ProjectEmployee,
    ProjectEmployeesSearchFilters,
} from "@project/domain/models/project-employees.model";
import {
    MaterialData,
    SearchAllProjectMaterialBy,
} from "@project/domain/models/project-material/project-material.model";
import { Indicator } from "@project/domain/models/technical-proposals.model";
import { GetAllProjectEmployeesByUseCase } from "@project/domain/usecases/project-employee/get-all-project-employees-by.usecase";
import { GetAllProjectMaterialsUseCase } from "@project/domain/usecases/project-material/get-all-project-materials.usecase";
import { CreateIndicatorUseCase } from "@project/domain/usecases/technical-proposal/indicator/create-indicator.usecase";
import { DeleteIndicatorUseCase } from "@project/domain/usecases/technical-proposal/indicator/delete-indicator.usecase";
import { EditIndicatorUseCase } from "@project/domain/usecases/technical-proposal/indicator/edit-indicator.usecase";
import { GetAllIndicatorsByUseCase } from "@project/domain/usecases/technical-proposal/indicator/get-all-indicator-by.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { IndicatorFormValuesValidated } from "../indicator/indicator-form.view";

@injectable()
export class ActivityFormViewModel extends BaseViewModel {
    @observable
    projectId: Undefinable<number> = undefined;

    @observable
    activityId: Undefinable<number> = undefined;

    @observable
    isIndicatorModalOpen = false;

    @observable
    initiallyLoading = true;

    @observable
    projectEmployees: ProjectEmployee[] = [];

    @observable
    indicators: Indicator[] = [];

    @observable
    indicatorToEdit: Undefinable<Indicator> = undefined;

    @observable
    materials: MaterialData[] = [];

    constructor(
        @inject(GetAllProjectEmployeesByUseCase)
        private readonly getAllProjectEmployeesByUseCase: GetAllProjectEmployeesByUseCase,
        @inject(IndicatorMapper)
        private readonly indicatorMapper: IndicatorMapper,
        @inject(CreateIndicatorUseCase)
        private readonly createIndicatorUseCase: CreateIndicatorUseCase,
        @inject(EditIndicatorUseCase)
        private readonly editIndicatorUseCase: EditIndicatorUseCase,
        @inject(DeleteIndicatorUseCase)
        private readonly deleteIndicatorUseCase: DeleteIndicatorUseCase,
        @inject(GetAllIndicatorsByUseCase)
        private readonly getAllIndicatorsByUseCase: GetAllIndicatorsByUseCase,
        @inject(GetAllProjectMaterialsUseCase)
        private readonly getAllProjectMaterialsUseCase: GetAllProjectMaterialsUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllProjectMaterials(),
            this.getAllIndicators(),
            this.fetchProjectEmployees(),
        ]);
        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    async getAllProjectMaterials(): Promise<void> {
        if (!this.projectId) throw Error("Missing projectId");

        const filters: SearchAllProjectMaterialBy = {
            projectId: this.projectId,
        };

        const projectMaterials =
            await this.getAllProjectMaterialsUseCase.execute(filters);

        const material = projectMaterials.map(
            (projectMaterial) => projectMaterial.materialData,
        ) as MaterialData[];

        runInAction(() => {
            this.materials = material.length ? material : [];
        });
    }

    @action
    setActivityId(activityId: number): void {
        this.activityId = activityId;
    }

    @action
    setProjectId(projectId: number): void {
        this.projectId = projectId;
    }

    async getAllIndicators(): Promise<void> {
        if (!this.activityId) return;

        const indicators = await this.getAllIndicatorsByUseCase.execute({
            activity: this.activityId,
        });

        runInAction(() => {
            this.indicators = indicators;
        });
    }

    async fetchProjectEmployees(): Promise<void> {
        if (!this.projectId) throw Error("Missing projectId");

        const filters: ProjectEmployeesSearchFilters = {
            projectId: this.projectId,
        };
        const projectEmployees =
            await this.getAllProjectEmployeesByUseCase.execute(filters);

        runInAction(() => {
            this.projectEmployees = projectEmployees;
        });
    }

    @action
    openIndicatorModal(indicator?: Undefinable<Indicator>): void {
        this.isIndicatorModalOpen = true;
        this.indicatorToEdit = indicator;
    }

    @action
    closeIndicatorModal(): void {
        this.isIndicatorModalOpen = false;
    }

    async createIndicator(
        indicator: IndicatorFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();

        const createIndicatorResult = await this.createIndicatorUseCase.execute(
            this.indicatorMapper.mapFromFormToCreate(indicator),
        );

        if (createIndicatorResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async editIndicator(
        indicator: IndicatorFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();

        const editIndicatorResult = await this.editIndicatorUseCase.execute(
            this.indicatorMapper.mapFromFormToEdit(indicator),
        );

        if (editIndicatorResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async deleteIndicator(indicatorId: number): Promise<void> {
        LoadLayoutStore.start();

        const success = await this.deleteIndicatorUseCase.execute(indicatorId);
        if (success) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async searchIndicatorsBy(activityId: number): Promise<Indicator[]> {
        const indicators = await this.getAllIndicatorsByUseCase.execute({
            activity: activityId,
        });

        return indicators;
    }

    async reloadIndicators(): Promise<void> {
        LoadLayoutStore.start();
        await this.getAllIndicators();
        LoadLayoutStore.finish();
    }
}
