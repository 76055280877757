import { IdentificationDocumentTypeEnumMapper } from "@core/data/mappers/identification-document-type-enum.mapper";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { EditEmployeeBody } from "@entity/data/dto/employee/edit-employee.body";
import { EmployeeTypeEnumMapper } from "@entity/data/mappers/employee/employee-type-enum.mapper";
import { VolunteerInfoMapper } from "@entity/data/mappers/employee/volunteer-info.mapper";
import { EditEmployee } from "@entity/domain/models/employee/edit-employee.model";
import { EditEmployeeFormValuesValidated } from "@entity/presentation/component/employee-form/employee-form.component";
import { inject, injectable } from "inversify";

@injectable()
export class EditEmployeeMapper {
    constructor(
        @inject(IdentificationDocumentTypeEnumMapper)
        private readonly identificationDocumentTypeEnumMapper: IdentificationDocumentTypeEnumMapper,
        @inject(EmployeeTypeEnumMapper)
        private readonly employeeTypeEnumMapper: EmployeeTypeEnumMapper,
        @inject(VolunteerInfoMapper)
        private readonly volunteerInfoMapper: VolunteerInfoMapper,
    ) {}

    mapFromEmployeeFormValues(
        id: number,
        entityId: number,
        employeeForm: EditEmployeeFormValuesValidated,
    ): EditEmployee {
        const employee = new EditEmployee(
            id,
            employeeForm.general.identification,
            employeeForm.general.identificationType,
            employeeForm.general.name,
            employeeForm.general.surname,
            employeeForm.general.type,
            entityId,
        );

        if (employeeForm.general.volunteerInfo) {
            employee.volunteerInfo = this.volunteerInfoMapper.mapFromFormValues(
                employeeForm.general.volunteerInfo,
            );
        }

        employee.documents = employeeForm.documents.documents;

        return employee;
    }

    mapToDto(editEmployee: EditEmployee): EditEmployeeBody {
        const identificationType =
            this.identificationDocumentTypeEnumMapper.mapToDto(
                editEmployee.identificationType,
            );

        const type = this.employeeTypeEnumMapper.mapToDto(editEmployee.type);

        const editEmployeeBody: EditEmployeeBody = {
            id: editEmployee.id,
            name: editEmployee.name,
            surname: editEmployee.surname,
            identification: editEmployee.identification,
            identification_type: identificationType,
            type: type,
            entity: editEmployee.entity,
        };

        if (editEmployee.volunteerInfo) {
            editEmployeeBody.volunteer_info = this.volunteerInfoMapper.mapToDto(
                editEmployee.volunteerInfo,
            );
        }

        if (editEmployee.documents) {
            const documents = editEmployee.documents.map(
                (document) => document.id,
            );
            editEmployeeBody.documents = convertEmptyToUndefined(documents);
        }

        return editEmployeeBody;
    }
}
