import { createContext, FC, ReactNode, useContext, useMemo } from "react";

export interface IncFormItemContextProvidedValue {
    name: string;
}

const IncFormItemContext = createContext<IncFormItemContextProvidedValue>({
    name: "",
});

export interface IncFormItemContextProviderProps {
    name: string;
    children: ReactNode;
}

export const IncFormItemContextProvider: FC<IncFormItemContextProviderProps> = (
    props,
) => {
    const providedValue = useMemo<IncFormItemContextProvidedValue>(
        () => ({
            name: props.name,
        }),
        [props.name],
    );

    return (
        <IncFormItemContext.Provider value={providedValue}>
            {props.children}
        </IncFormItemContext.Provider>
    );
};

export const useIncFormItemContext = (): IncFormItemContextProvidedValue =>
    useContext(IncFormItemContext);
