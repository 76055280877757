import { Nullable } from "@core/domain/types/nullable.type";
import { ProjectEmployeeRepository } from "@project/data/repositories/project-employee.repository";
import { ProjectEmployee } from "@project/domain/models/project-employees.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetProjectEmployeeByIdUseCase {
    constructor(
        @inject(ProjectEmployeeRepository)
        private readonly projectEmployeeRepository: ProjectEmployeeRepository,
    ) {}

    async execute(id: number): Promise<Nullable<ProjectEmployee>> {
        const projectEmployeeResult =
            await this.projectEmployeeRepository.getById(id);

        return projectEmployeeResult.fold(
            () => null,
            (projectEmployee) => projectEmployee,
        );
    }
}
