import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum TerritorialScopeEnumDto {
    LOCAL = "local",
    REGIONAL = "regional",
    NATIONAL = "national",
    INTERNATIONAL = "international",
}

export class TerritorialScopeDto {
    @IsEnum(TerritorialScopeEnumDto)
    @IsNotEmpty()
    key!: TerritorialScopeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
