import { Nullable } from "@core/domain/types/nullable.type";
import { MandateTerminationReasonDto } from "@entity/data/dto/mandate-termination-reason-enum.dto";
import { MandateTerminationReasonEnumMapper } from "@entity/data/mappers/mandate-termination-reason-enum.mapper";
import {
    MandateTerminationReason,
    MandateTerminationReasonEnum,
} from "@entity/domain/models/mandate-termination-reason.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class MandateTerminationReasonMapper {
    constructor(
        @inject(MandateTerminationReasonEnumMapper)
        private mandateTerminationReasonEnumMapper: MandateTerminationReasonEnumMapper,
    ) {}

    map(
        mandateTerminationReasonEnumMapperDto: MandateTerminationReasonDto,
    ): Nullable<MandateTerminationReason> {
        const errors = validateSync(mandateTerminationReasonEnumMapperDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<MandateTerminationReasonEnum> =
            this.mandateTerminationReasonEnumMapper.map(
                mandateTerminationReasonEnumMapperDto.key,
            );

        if (!id) return null;

        return new MandateTerminationReason(
            id,
            mandateTerminationReasonEnumMapperDto.value,
        );
    }
}
