import { inject, injectable } from "inversify";
import { ProjectExpenseRepository } from "../../../data/repositories/project-expense.repository";
import { DietType } from "../../models/expenses/diet-type.model";

@injectable()
export class GetAllDietTypesUseCase {
    constructor(
        @inject(ProjectExpenseRepository)
        private readonly projectExpenseRepository: ProjectExpenseRepository,
    ) {}

    async execute(): Promise<DietType[]> {
        const dietTypesResult =
            await this.projectExpenseRepository.getAllDietTypes();

        return dietTypesResult.getOrElse([]);
    }
}
