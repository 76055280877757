export enum EmployeeTypeEnum {
    Freelance = "freelance",
    Volunteer = "volunteer",
    Employee = "employee",
}

export class EmployeeType {
    constructor(
        public readonly id: EmployeeTypeEnum,
        public readonly label: string,
    ) {}
}
