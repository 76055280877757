import { CreateAffiliationBody } from "@entity/data/dto/affiliation/create-affiliation.body";
import { CreateAffiliation } from "@entity/domain/models/affiliation/create-affiliation.model";
import { AffiliationFormValuesValidated } from "@entity/presentation/component/entity-form/affiliation/form/affiliation-form";
import { injectable } from "inversify";

@injectable()
export class CreateAffiliationMapper {
    mapToCreateDto(
        createAffiliation: CreateAffiliation,
    ): CreateAffiliationBody {
        const affiliationDto: CreateAffiliationBody = {
            identification: createAffiliation.identification,
            name: createAffiliation.name,
            territorial_scope: createAffiliation.territorialScope,
            entity: createAffiliation.entity,
            web_address: createAffiliation.webAddress
                ? createAffiliation.webAddress
                : null,
            incorporation_date: createAffiliation.incorporationDate
                ? createAffiliation.incorporationDate.toISODate()
                : null,
            contact_person: createAffiliation.contactPerson
                ? createAffiliation.contactPerson
                : null,
            contact_phone: createAffiliation.contactPhone
                ? createAffiliation.contactPhone
                : null,
            contact_email: createAffiliation.contactEmail
                ? createAffiliation.contactEmail
                : null,
        };

        return affiliationDto;
    }

    mapFromFormValues(
        entityId: number,
        affiliation: AffiliationFormValuesValidated,
    ): CreateAffiliation {
        const createAffiliation: CreateAffiliation = new CreateAffiliation(
            affiliation.identification,
            affiliation.name,
            affiliation.territorialScope,
            entityId,
            affiliation.webAddress,
            affiliation.incorporationDate,
            affiliation.contactPerson,
            affiliation.contactPhone,
            affiliation.contactEmail,
        );

        return createAffiliation;
    }
}
