import { Pagination } from "@core/domain/models/pagination";
import { RoomRepository } from "@entity/data/repositories/room.repository";
import { inject, injectable } from "inversify";
import { Rooms } from "../../models/locals/room.model";
import { RoomsSearchFilters } from "../../models/locals/rooms-search-filters";

@injectable()
export class SearchRoomsByUseCase {
    constructor(
        @inject(RoomRepository)
        private readonly roomRepository: RoomRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: RoomsSearchFilters,
    ): Promise<Rooms> {
        const roomsResult = await this.roomRepository.getBy(
            pagination,
            filters,
        );

        return roomsResult.fold(
            () => new Rooms([], 0),
            (rooms) => rooms,
        );
    }
}
