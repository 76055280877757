import { inject, injectable } from "inversify";
import { ServiceRepository } from "@entity/data/repositories/service.repository";
import { Service } from "../models/service.model";

@injectable()
export class GetAllServicesUseCase {
    constructor(
        @inject(ServiceRepository)
        private readonly serviceRepository: ServiceRepository,
    ) {}

    async execute(): Promise<Service[]> {
        const getAllServicesResult = await this.serviceRepository.getAll();

        return getAllServicesResult.fold(
            () => [],
            (services) => services,
        );
    }
}
