import { CreateExternalDerivation } from "@beneficiary/domain/models/external-derivation/create-external-derivation.model";
import { InternalNote } from "@beneficiary/domain/models/internal-notes/internal-note.model";

export class EditExternalDerivation extends CreateExternalDerivation {
    constructor(
        public readonly id: InternalNote["id"],
        reason: CreateExternalDerivation["reason"],
        centerType: CreateExternalDerivation["centerType"],
        centerName: CreateExternalDerivation["centerName"],
        beneficiary: CreateExternalDerivation["beneficiary"],
        isDerivateToExternal: CreateExternalDerivation["isDerivateToExternal"],
        contactName?: CreateExternalDerivation["contactName"],
        contactPhone?: CreateExternalDerivation["contactPhone"],
        contactEmail?: CreateExternalDerivation["contactEmail"],
        documents?: CreateExternalDerivation["documents"],
    ) {
        super(
            reason,
            centerType,
            centerName,
            beneficiary,
            isDerivateToExternal,
            contactName,
            contactPhone,
            contactEmail,
            documents,
        );
    }
}
