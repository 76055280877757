import { Pagination } from "@core/domain/models/pagination";
import { PublicUtilityRepository } from "@entity/data/repositories/public-utility.repository";
import { PublicUtility } from "@entity/domain/models/public-utility/public-utility.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllPublicUtilitiesUsecase {
    constructor(
        @inject(PublicUtilityRepository)
        private readonly publicUtilityRepository: PublicUtilityRepository,
    ) {}

    async execute(): Promise<PublicUtility[]> {
        const publicUtilitiesResult = await this.publicUtilityRepository.getAll(
            Pagination.NoPagination(),
        );

        return publicUtilitiesResult.fold(
            () => [],
            (publicUtilities) => publicUtilities.publicUtilities,
        );
    }
}
