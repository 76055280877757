import { Nullish } from "@core/domain/types/nullish.type";

export class GlobalEntitySearchFilters {
    catalogue?: Nullish<string>;
}

export class GlobalEntity {
    constructor(
        public readonly id: number,
        public name: string,
    ) {}
}
