import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { AuthenticatedUser } from "@authentication/domain/models/authenticated-user.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetLoggedUserUseCase {
    constructor(
        @inject(UserSessionRepository)
        private readonly userSessionRepository: UserSessionRepository,
    ) {}
    execute(): AuthenticatedUser {
        return this.userSessionRepository.userSyncSafe;
    }
}
