import type { Nullable } from "@core/domain/types/nullable.type";
import { CostRepository } from "@entity/data/repositories/cost.repository";
import { inject, injectable } from "inversify";
import { Cost } from "../../models/cost/cost.model";

@injectable()
export class GetCostByIdUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(costId: number): Promise<Nullable<Cost>> {
        const costResult = await this.costRepository.getById(costId);

        return costResult.fold(
            () => null,
            (cost) => cost,
        );
    }
}
