import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { Beneficiary } from "@beneficiary/domain/models/beneficiary.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { CreateBeneficiary } from "../models/create-beneficiary.model";

@injectable()
export class CreateBeneficiaryUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(
        beneficiary: CreateBeneficiary,
    ): Promise<Nullable<Beneficiary>> {
        const creationResult =
            await this.beneficiaryRepository.create(beneficiary);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdBeneficiary) => createdBeneficiary,
        );
    }
}
