export enum ActionLineEnum {
    SupportVictims = "support_victims",
    Digitalization = "digitalization",
    DevelopmentCooperationAid = "development_cooperation_aid",
    ProfessionalTrainingThirdSector = "professional_training_third_sector",
    EducationTraining = "education_training",
    SocialEmploymentInclusion = "social_employment_inclusion",
    KnowledgeGenerationEvaluation = "knowledge_generation_evaluation",
    Innovation = "innovation",
    TrainingBeneficiaries = "training_beneficiaries",
    PromotionPersonalAutonomy = "promotion_personal_autonomy",
    TolerancePromotion = "tolerance_promotion",
    LeisureTime = "leisure_time",
    PersonalizedGuidanceAdvice = "personalized_guidance_advice",
    PreventionAwareness = "prevention_awareness",
    EquialityPromotion = "equiality_promotion",
    HealthPromotion = "health_promotion",
    PromotionSustainableDevelopmentGoals = "promotion_sustainable_development_goals",
    PromotionVolunteeringCitizenParticipation = "promotion_volunteering_citizen_participation",
    ProtectionMinorsRights = "protection_minors_rights",
    ResidentialResources = "residential_resources",
    Return = "return",
    SocialEmergencyBasicNeedsSupport = "social_emergency_basic_needs_support",
    EquityAdaptationProperties = "equity_adaptation_properties",
    Other = "other",
}

export class ActionLine {
    constructor(
        public readonly id: ActionLineEnum,
        public readonly label: string,
    ) {}
}
