import { CostRepository } from "@entity/data/repositories/cost.repository";
import { IRPFType } from "@entity/domain/models/cost/irpf-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllIrpfTypesUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(): Promise<IRPFType[]> {
        const irpfTypesResult = await this.costRepository.getAllIrfpTypes();

        return irpfTypesResult.fold(
            () => [],
            (irpfTypeResult) => irpfTypeResult,
        );
    }
}
