import { DocumentSignature } from "@core/domain/models/document-signatures.model";
import { DocumentCategory } from "@core/domain/models/documents-category.model";
import { DateTime } from "luxon";

export enum DocumentTypeLicenseEnum {
    SenseLlicencia = "sense-llicencia",
    Copyright = "copyright",
    Copyleft = "copyleft",
    CcBy = "cc-by",
    CcByNc = "cc-by-nc",
    ByNcSa = "by-nc-sa",
    ByNcNd = "by-nc-nd",
    BySa = "by-sa",
    ByNd = "by-nd",
    Publica = "publica",
}

export class IncDocument {
    // Without extension
    static getFileName(fileName: string): string {
        const name = fileName.split(".").shift();

        if (!name) throw Error("Invalid file name extension format");

        return name;
    }

    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly documentUrl: string,
        public readonly created: DateTime,
        public readonly modified: DateTime,
        public title?: string,
        public author?: string,
        public readonly category?: DocumentCategory,
        public documentSignature?: DocumentSignature,
        public readonly documentSignedUrl?: string,
    ) {}
}
