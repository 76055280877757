import { IncDocument } from "@core/domain/models/inc-document.model";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { ContractType } from "@project/domain/models/expenses/contract-type.model";
import { ContributionGroupType } from "@project/domain/models/expenses/contribution-group-type.model";
import { MonthType } from "@project/domain/models/expenses/month-type.model";
import { GetAllContractTypesUseCase } from "@project/domain/usecases/expenses/get-all-contract-types.usecase";
import { GetAllContributionGroupTypesUseCase } from "@project/domain/usecases/expenses/get-all-contribution-group-types.usecase";
import { GetAllMonthTypesUseCase } from "@project/domain/usecases/expenses/get-all-month-types.usecase";
import { Map } from "immutable";
import { inject, injectable } from "inversify";
import { flow, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class ExpensePersonnelFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    monthTypes: MonthType[] = [];

    @observable
    contributionGroupTypes: ContributionGroupType[] = [];

    @observable
    contractTypes: ContractType[] = [];

    @observable
    documents: Map<number, IncDocument> = Map([]);

    constructor(
        @inject(GetAllContractTypesUseCase)
        private readonly getAllContractTypesUseCase: GetAllContractTypesUseCase,
        @inject(GetAllContributionGroupTypesUseCase)
        private readonly getAllContributionGroupTypesUseCase: GetAllContributionGroupTypesUseCase,
        @inject(GetAllMonthTypesUseCase)
        private readonly getAllMonthTypesUseCase: GetAllMonthTypesUseCase,
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        await Promise.all([
            this.getAllContractTypes(),
            this.getAllContributionGroupTypes(),
            this.getAllMonthTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @flow
    async *getAllContractTypes(): AsyncGenerator<void> {
        const contractTypes = await this.getAllContractTypesUseCase.execute();

        runInAction(() => {
            this.contractTypes = contractTypes;
        });
    }

    @flow
    async *getAllContributionGroupTypes(): AsyncGenerator<void> {
        const contributionGroupTypes =
            await this.getAllContributionGroupTypesUseCase.execute();

        runInAction(() => {
            this.contributionGroupTypes = contributionGroupTypes;
        });
    }

    @flow
    async *getAllMonthTypes(): AsyncGenerator<void> {
        const monthTypes = await this.getAllMonthTypesUseCase.execute();

        runInAction(() => {
            this.monthTypes = monthTypes;
        });
    }

    async deleteDocument(documentId: number): Promise<void> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(documentUrl);

        LoadLayoutStore.finish();
    }
}
