import { Pagination } from "@core/domain/models/pagination";
import { MaterialRepository } from "@entity/data/repositories/material.repository";
import { inject, injectable } from "inversify";
import {
    MaterialSearchFilters,
    Materials,
} from "../../models/material/material.model";

@injectable()
export class SearchMaterialsByUseCase {
    constructor(
        @inject(MaterialRepository)
        private readonly materialRepository: MaterialRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: MaterialSearchFilters,
    ): Promise<Materials> {
        const materialsResult = await this.materialRepository.searchAllBy(
            pagination,
            filters,
        );

        return materialsResult.fold(
            () => new Materials([], 0),
            (materials) => materials,
        );
    }
}
