import { IsNotEmpty, IsNumber, IsString } from "class-validator";

export interface LoginBody {
    email: string;
    password: string;
}

export class LoginDto {
    @IsNumber()
    @IsNotEmpty()
    public readonly id!: number;

    @IsString()
    @IsNotEmpty()
    public readonly email!: string;

    @IsString()
    @IsNotEmpty()
    public readonly token!: string;
}
