export enum RelationshipEnum {
    Father = "father",
    Mother = "mother",
    Son = "son",
    Spouse = "spouse",
    Daughter = "daughter",
    Brother = "brother",
    Sister = "sister",
    Grandfather = "grandfather",
    Grandmother = "grandmother",
    Grandson = "grandson",
    Granddaughter = "granddaughter",
    Uncle = "uncle",
    Aunt = "aunt",
    Nephew = "nephew",
    Niece = "niece",
    GreatGrandfather = "great_grandfather",
    GreatGrandmother = "great_grandmother",
    GreatGrandson = "great_grandson",
    GreatGranddaughter = "great_granddaughter",
    Cousin = "cousin",
    GreatUncle = "great_uncle",
    GreatAunt = "great_aunt",
    GreatNephew = "great_nephew",
    GreatNiece = "great_niece",
    GreatGreatGrandfather = "great_great_grandfather",
    GreatGreatGrandmother = "great_great_grandmother",
    GreatGreatGrandson = "great_great_grandson",
    GreatGreatGranddaughter = "great_great_granddaughter",
    LegalGuardian = "legal_guardian",
    Other = "other",
}

export class Relationship {
    constructor(
        public readonly id: RelationshipEnum,
        public readonly label: string,
    ) {}
}
