import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CreatePublicInterestBody } from "@entity/data/dto/public-interest/create-public-interest.body";
import { EditPublicInterestBody } from "@entity/data/dto/public-interest/edit-public-interest.body";
import {
    PublicInterestDto,
    PublicInterestsDto,
} from "@entity/data/dto/public-interest/public-interest.dto";
import { CreatePublicInterestMapper } from "@entity/data/mappers/public-interest/create-public-interest.mapper";
import { EditPublicInterestMapper } from "@entity/data/mappers/public-interest/edit-public-interest.mapper";
import { PublicInterestsMapper } from "@entity/data/mappers/public-interest/public-interests.mapper";
import { CreatePublicInterest } from "@entity/domain/models/public-interest/create-public-interest.model";
import { EditPublicInterest } from "@entity/domain/models/public-interest/edit-public-interest.model";
import {
    PublicInterest,
    PublicInterests,
} from "@entity/domain/models/public-interest/public-interest.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const ENTITY_PUBLIC_INTERESTS_PATH = "/entities_public_interests/";

@injectable()
export class PublicInterestDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(PublicInterestsMapper)
        private readonly publicInterestsMapper: PublicInterestsMapper,
        @inject(CreatePublicInterestMapper)
        private readonly createPublicInterestMapper: CreatePublicInterestMapper,
        @inject(EditPublicInterestMapper)
        private readonly editPublicInterestMapper: EditPublicInterestMapper,
    ) {}

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, PublicInterests>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const publicInterestsResult = await this.http.get<PublicInterestDto>(
            ENTITY_PUBLIC_INTERESTS_PATH,
            {
                query,
            },
        );

        return publicInterestsResult.map((response) =>
            this.publicInterestsMapper.map(
                plainToClass(PublicInterestsDto, response.data),
            ),
        );
    }

    async create(
        newPublicInterest: CreatePublicInterest,
    ): Promise<Either<ValidationError | FallbackError, PublicInterest>> {
        const publicInterestBody =
            this.createPublicInterestMapper.mapToCreateDto(newPublicInterest);

        const publicInterestResult = await this.http.post<
            PublicInterestDto,
            CreatePublicInterestBody
        >(ENTITY_PUBLIC_INTERESTS_PATH, publicInterestBody);

        return publicInterestResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const publicInterest =
                    this.publicInterestsMapper.mapPublicInterest(
                        plainToClass(PublicInterestDto, response.data),
                    );

                if (!publicInterest) return Either.Left(new FallbackError());

                return Either.Right(publicInterest);
            });
    }

    async edit(
        editPublicInterest: EditPublicInterest,
    ): Promise<Either<ValidationError | FallbackError, PublicInterest>> {
        const editedPublicInterest =
            this.editPublicInterestMapper.mapToDto(editPublicInterest);

        const editPublicInterestResult = await this.http.patch<
            PublicInterestDto,
            EditPublicInterestBody
        >(
            `${ENTITY_PUBLIC_INTERESTS_PATH}${editPublicInterest.id}/`,
            editedPublicInterest,
        );

        return editPublicInterestResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const publicInterest =
                    this.publicInterestsMapper.mapPublicInterest(
                        plainToClass(PublicInterestDto, response.data),
                    );

                if (!publicInterest) return Either.Left(new FallbackError());

                return Either.Right(publicInterest);
            });
    }

    async delete(
        publicInterestId: number,
    ): Promise<Either<FallbackError, true>> {
        const deletePublicInterest = await this.http.delete(
            `${ENTITY_PUBLIC_INTERESTS_PATH}${publicInterestId}`,
        );

        return deletePublicInterest
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
