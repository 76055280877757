import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Employee } from "@entity/domain/models/employee/employee.model";
import { GetAllEmployeesByUseCase } from "@entity/domain/usecases/employee/get-all-employees-by.usecase";
import { ProjectEmployeeMapper } from "@project/data/mappers/project-employee.mapper";
import { ProjectEmployee } from "@project/domain/models/project-employees.model";
import { GetAllProjectEmployeesByUseCase } from "@project/domain/usecases/project-employee/get-all-project-employees-by.usecase";
import { UpdateProjectEmployeeUseCase } from "@project/domain/usecases/project-employee/update-project-employee.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

@injectable()
export class EmployeesTabViewModel extends BaseViewModel {
    projectId: Nullable<number> = null;

    @observable
    initialLoading: boolean = true;

    @observable
    private _employees: Employee[] = [];

    @observable
    projectEmployees: ProjectEmployee[] = [];

    @observable
    showAddEmployeeModal: boolean = false;

    @observable
    showAddDedicationModal: boolean = false;

    @computed
    get thereAreEmployees(): boolean {
        return this.projectEmployees.length > 0;
    }

    @computed
    get employeesNotInProject(): Employee[] {
        return this._employees.filter(
            (employee) =>
                !this.projectEmployees.find(
                    (projectEmployee) =>
                        // Filter employees that are already in the project
                        projectEmployee.employee.id === employee.id,
                ),
        );
    }

    constructor(
        @inject(GetAllEmployeesByUseCase)
        private readonly getAllEmployeesByUseCase: GetAllEmployeesByUseCase,
        @inject(GetAllProjectEmployeesByUseCase)
        private readonly getAllProjectEmployeesByUseCase: GetAllProjectEmployeesByUseCase,
        @inject(ProjectEmployeeMapper)
        private readonly projectEmployeeMapper: ProjectEmployeeMapper,
        @inject(UpdateProjectEmployeeUseCase)
        private readonly updateProjectEmployeeUseCase: UpdateProjectEmployeeUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllEmployees(),
            this.getAllProjectEmployees(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllEmployees(): Promise<void> {
        const employees = await this.getAllEmployeesByUseCase.execute();

        runInAction(() => {
            // Filters employees without contractedHours
            this._employees = employees.filter(
                (employee) => employee.contractedHours,
            );
        });
    }

    async getAllProjectEmployees(): Promise<void> {
        if (!this.projectId) throw Error("Project id is required");

        const projectEmployees =
            await this.getAllProjectEmployeesByUseCase.execute({
                projectId: this.projectId,
            });

        runInAction(() => {
            this.projectEmployees = projectEmployees;
        });
    }

    async reloadProjectEmployees(): Promise<void> {
        LoadLayoutStore.start();

        await this.getAllProjectEmployees();

        LoadLayoutStore.finish();
    }

    @action
    setShowAddEmployeeModal(show: boolean): void {
        this.showAddEmployeeModal = show;
    }

    @action
    setShowAddDedicationModal(show: boolean): void {
        this.showAddDedicationModal = show;
    }

    async updateProjectEmployee(
        projectEmployee: ProjectEmployee,
    ): Promise<void> {
        LoadLayoutStore.start();

        const updateProjectEmployee =
            this.projectEmployeeMapper.mapToUpdate(projectEmployee);

        const updatedProjectEmployee =
            await this.updateProjectEmployeeUseCase.execute(
                updateProjectEmployee,
            );

        if (updatedProjectEmployee) {
            ToastManagerStore.success();
        }

        await this.reloadProjectEmployees();

        LoadLayoutStore.finish();
    }
}
