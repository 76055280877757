import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectMaterialRepository } from "@project/data/repositories/project-material.repository";
import { EditProjectMaterial } from "@project/domain/models/project-material/edit-project-material.model";
import { ProjectMaterial } from "@project/domain/models/project-material/project-material.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditProjectMaterialUseCase {
    constructor(
        @inject(ProjectMaterialRepository)
        private readonly projectMaterialRepository: ProjectMaterialRepository,
    ) {}

    async execute(
        projectMaterial: EditProjectMaterial,
    ): Promise<Nullable<ProjectMaterial>> {
        const editResult =
            await this.projectMaterialRepository.edit(projectMaterial);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedProjectMaterial) => editedProjectMaterial,
        );
    }
}
