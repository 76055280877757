import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { IndicatorRepository } from "@project/data/repositories/technical-proposal/indicator.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteIndicatorUseCase {
    constructor(
        @inject(IndicatorRepository)
        private readonly indicatorRepository: IndicatorRepository,
    ) {}

    async execute(costId: number): Promise<boolean> {
        const result = await this.indicatorRepository.delete(costId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
