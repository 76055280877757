// reflect-metadata must be the first import
// organize-imports-ignore
import "reflect-metadata";
import { Container } from "inversify";
import { coreModule } from "@core/core-module.di";
import { authenticationModule } from "@authentication/authentication-module.di";
import { beneficiaryModule } from "@beneficiary/beneficiary-module.di";
import { entityModule } from "@entity/entity-module.di";
import { proceedingModule } from "@proceeding/proceeding-module.di";
import { projectModule } from "@project/project-module.di";
import { userModule } from "@user/user-module.di";

const container = Container.merge(
    authenticationModule.module,
    beneficiaryModule.module,
    coreModule.module,
    entityModule.module,
    proceedingModule.module,
    projectModule.module,
    userModule.module,
);

export { container };
