import { SocialNetworksSummary } from "@entity/domain/models/social-networks-summary.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { SocialNetworksSummaryDto } from "../../dto/social-network/social-networks-summary.dto";
import { SocialNetworkSummaryMapper } from "./social-network-summary.mapper";

@injectable()
export class SocialNetworksSummaryMapper {
    constructor(
        @inject(SocialNetworkSummaryMapper)
        private readonly socialNetworkSummaryMapper: SocialNetworkSummaryMapper,
    ) {}

    map(
        socialNetworksSummaryDto: SocialNetworksSummaryDto,
    ): SocialNetworksSummary {
        const errors = validateSync(socialNetworksSummaryDto);

        if (errors.length) {
            console.error(errors);
            return new SocialNetworksSummary([], 0);
        }

        const socialNetworksSummary =
            socialNetworksSummaryDto.results.mapNotNull(
                (socialNetworkSummary) =>
                    this.socialNetworkSummaryMapper.map(socialNetworkSummary),
            );

        return new SocialNetworksSummary(
            socialNetworksSummary,
            socialNetworksSummaryDto.count,
        );
    }
}
