import { ExternalDerivationRepository } from "@beneficiary/data/repositories/external-derivation.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteExternalDerivationUseCase {
    constructor(
        @inject(ExternalDerivationRepository)
        private readonly externalDerivationRepository: ExternalDerivationRepository,
    ) {}

    async execute(externalDerivationId: number): Promise<boolean> {
        const result =
            await this.externalDerivationRepository.delete(
                externalDerivationId,
            );

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
