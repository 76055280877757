import { EducationLevelEnumMapper } from "@core/data/mappers/education-level-enum.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import {
    VolunteerContractDto,
    VolunteerContractsDto,
} from "@entity/data/dto/employee/contracts/volunteer/volunteer-contract.dto";
import { VolunteerContractMapper } from "@entity/data/mappers/employee/contracts/volunteer/volunteer-contract.mapper";
import {
    VolunteerContract,
    VolunteerContracts,
} from "@entity/domain/models/employee/contracts/volunteer/volunteer-contract.model";
import { CatalogueEnumMapper } from "@project/data/mappers/catalogue-enum.mapper";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class VolunteerContractsMapper {
    constructor(
        @inject(CatalogueEnumMapper)
        private readonly catalogueEnumMapper: CatalogueEnumMapper,
        @inject(EducationLevelEnumMapper)
        private readonly educationLevelEnumMapper: EducationLevelEnumMapper,
        @inject(VolunteerContractMapper)
        private readonly volunteerContractMapper: VolunteerContractMapper,
    ) {}

    map(volunteerContractsDto: VolunteerContractsDto): VolunteerContracts {
        const errors = validateSync(volunteerContractsDto);

        if (errors.length > 0) {
            console.error(errors);

            return new VolunteerContracts([], 0);
        }

        const volunteerContracts = volunteerContractsDto.results.mapNotNull(
            (volunteerDto) => this.volunteerContractMapper.map(volunteerDto),
        );

        return new VolunteerContracts(
            volunteerContracts,
            volunteerContractsDto.count,
        );
    }

    mapContract(
        volunteerContractDto: VolunteerContractDto,
    ): Nullable<VolunteerContract> {
        if (!volunteerContractDto.id) {
            return null;
        }

        const educationLevel = this.educationLevelEnumMapper.map(
            volunteerContractDto.education_level,
        );
        if (!educationLevel) return null;

        const preferences = volunteerContractDto.preferences.mapNotNull(
            (catalogueDto) => this.catalogueEnumMapper.map(catalogueDto),
        );

        const volunteerContract: VolunteerContract = {
            id: volunteerContractDto.id,
            insurance: volunteerContractDto.has_insurance,
            activity: volunteerContractDto.activity,
            educationLevel,
            availability: volunteerContractDto.availability,
            preferences,
            weeklyWorkdayHours: parseInt(
                volunteerContractDto.weekly_workday_hours,
            ),
            startDate: DateTime.fromISO(volunteerContractDto.start_date),
            employeeId: volunteerContractDto.employee,
            startDateInsurance: volunteerContractDto.start_date_insurance
                ? DateTime.fromISO(volunteerContractDto.start_date_insurance)
                : null,
            otherPreferences: volunteerContractDto.other_preferences ?? null,
            door: volunteerContractDto.door ?? null,
            city: volunteerContractDto.city ?? null,
            endDate: volunteerContractDto.end_date
                ? DateTime.fromISO(volunteerContractDto.end_date)
                : null,
            endDateInsurance: volunteerContractDto.end_date_insurance
                ? DateTime.fromISO(volunteerContractDto.end_date_insurance)
                : null,
        };

        return volunteerContract;
    }
}
