import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    ExpenseSubType,
    ExpenseSubTypeEnum,
} from "../../../domain/models/expenses/sub-type.model";
import { ExpenseSubTypeDto } from "../../dto/expenses/expense-sub-type.dto";
import { ExpenseSubTypeEnumMapper } from "./expense-sub-type-enum.mapper";

@injectable()
export class ExpenseSubTypeMapper {
    constructor(
        @inject(ExpenseSubTypeEnumMapper)
        private readonly expenseSubTypeEnumMapper: ExpenseSubTypeEnumMapper,
    ) {}
    map(expenseSubTypeDto: ExpenseSubTypeDto): Nullable<ExpenseSubType> {
        const errors = validateSync(expenseSubTypeDto);
        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id: Nullable<ExpenseSubTypeEnum> =
            this.expenseSubTypeEnumMapper.map(expenseSubTypeDto.key);
        if (!id) return null;

        return new ExpenseSubType(id, expenseSubTypeDto.value);
    }
}
