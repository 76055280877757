import { BudgetCategoryGroupsDto } from "@project/data/dto/budget.dto";
import { BudgetCategoryGroupMapper } from "@project/data/mappers/budget/categories/groups/budget-category-group.mapper";
import { BudgetCategoryGroups } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetCategoryGroupsMapper {
    constructor(
        @inject(BudgetCategoryGroupMapper)
        private readonly budgetCategoryGroupMapper: BudgetCategoryGroupMapper,
    ) {}
    map(
        budgetCategoryGroupsDto: BudgetCategoryGroupsDto,
    ): BudgetCategoryGroups {
        const errors = validateSync(budgetCategoryGroupsDto);
        if (Boolean(errors.length)) {
            console.error(errors);
            return new BudgetCategoryGroups([], 0);
        }

        const budgetCategoryGroups = budgetCategoryGroupsDto.results.mapNotNull(
            (categoryGroupBudgetDto) =>
                this.budgetCategoryGroupMapper.map(categoryGroupBudgetDto),
        );
        return new BudgetCategoryGroups(
            budgetCategoryGroups,
            budgetCategoryGroupsDto.count,
        );
    }
}
