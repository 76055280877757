import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class LoadLayoutViewModel extends BaseViewModel {
    @observable
    loading: boolean = false;

    constructor() {
        super();
        makeObservable(this);

        this.addSub(
            LoadLayoutStore.loading.subscribe((loading) => {
                runInAction(() => {
                    this.loading = loading;
                });
            }),
        );
    }

    override async didMount(): Promise<void> {
        await super.didMount();
    }
}
