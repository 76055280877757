import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { ActionLineEnum } from "@entity/domain/models/action-line.model";
import { CollectiveEnum } from "@entity/domain/models/collective.model";
import { CatalogueEnum } from "@project/domain/models/catalogue.model";
import { StatusEnum } from "@project/domain/models/status.model";
import { TechnicalProposal } from "@project/domain/models/technical-proposals.model";
import { DateTime } from "luxon";
import { Expenses } from "./expenses/expenses.model";
import { ProjectFinancialEntity } from "./project-financial-entities.model";
import { ProjectMaterial } from "./project-material/project-material.model";
import { TerritorialScopeEnum } from "./territorial-scope.model";

export class DesignPlanification {
    constructor(
        public designDate: Nullable<DateTime>,
        public designResolutionDate: Nullable<DateTime>,
    ) {}
}

export class JustificationData {
    constructor(
        public hasIntermediateJustification?: Undefinable<boolean>,
        public intermediateJustificationEdgeDate?: Nullable<DateTime>,
        public intermediateJustificationDeliveryDate?: Nullable<DateTime>,
        public finalJustificationEdgeDate?: Nullable<DateTime>,
        public finalJustificationDeliveryDate?: Nullable<DateTime>,
        public requirementReceptionDate?: Nullable<DateTime>,
        public requirementResponseEdgeDate?: Nullable<DateTime>,
        public requirementResponseDate?: Nullable<DateTime>,
        public justificationAdditionalNotes?: Nullable<string>,
    ) {}
}

export class Project {
    private _employeeIds?: Undefinable<number[]> = undefined;

    // eslint-disable-next-line max-params
    constructor(
        public id: number,
        public entityId: number,
        public name: string,
        public year: number,
        public notes: string,
        public description: string,
        public territorialScopeId: Nullable<TerritorialScopeEnum>,
        public catalogueIds: Nullable<CatalogueEnum[]>,
        public collectives: Nullable<CollectiveEnum[]>,
        public actionLines: Nullable<ActionLineEnum[]>,
        public statusId: StatusEnum,
        public documents: IncDocument[],
        public totalCost?: Nullable<number>,
        public startDate?: Nullable<DateTime>,
        public endDate?: Nullable<DateTime>,
        public designPlanification?: Nullable<DesignPlanification>,
        public isActive?: Nullable<boolean>,
        public continuity?: Nullable<boolean>,
        public financialEntities?: ProjectFinancialEntity[],
        public materials?: ProjectMaterial[],
        public technicalProposals?: Undefinable<TechnicalProposal>,
        public expenses?: Undefinable<Expenses>,
    ) {}

    get employeeIds(): Undefinable<number[]> {
        return this._employeeIds;
    }

    addEmployee(employeeId: number): void {
        if (!this._employeeIds) this._employeeIds = [];
        this._employeeIds.push(employeeId);
    }
}
