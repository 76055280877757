import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EmployeeTypeEnum } from "@entity/domain/models/employee/employee-type.model";
import { ProjectEmployeeDedicationMapper } from "@project/data/mappers/project-employee-dedication.mapper";
import { ProjectEmployee } from "@project/domain/models/project-employees.model";
import { CreateProjectEmployeeDedicationUseCase } from "@project/domain/usecases/project-employee-dedication/create-project-employee-dedication.usecase";
import { DedicationFormValuesValidated } from "@project/presentation/components/project-form/employees/add-dedication-modal-form.view";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class AddDedicationModalFormViewModel extends BaseViewModel {
    constructor(
        @inject(CreateProjectEmployeeDedicationUseCase)
        private readonly createProjectEmployeeDedicationUseCase: CreateProjectEmployeeDedicationUseCase,
        @inject(ProjectEmployeeDedicationMapper)
        private readonly projectEmployeeDedicationMapper: ProjectEmployeeDedicationMapper,
    ) {
        super();
    }

    async addDedication(
        projectEmployeeId: number,
        dedication: DedicationFormValuesValidated,
    ): Promise<boolean> {
        LoadLayoutStore.start();

        const projectEmployeeDedication =
            this.projectEmployeeDedicationMapper.mapFromFormToCreate(
                projectEmployeeId,
                dedication,
            );
        const created =
            await this.createProjectEmployeeDedicationUseCase.execute(
                projectEmployeeDedication,
            );

        if (created) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return !!created;
    }

    getRemainingEmployeeHours(
        projectEmployee: ProjectEmployee,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        startDate: DateTime<boolean>,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        endDate: DateTime<boolean>,
    ): number {
        if (
            projectEmployee.dedications.length === 0 ||
            projectEmployee.employee.type !== EmployeeTypeEnum.Freelance
        )
            return projectEmployee.employee.contractedHours ?? 0;

        const dedicatedHours = projectEmployee.dedications.reduce(
            (totalHoursDedicated, currentDedication) =>
                totalHoursDedicated + currentDedication.hoursDedicated,
            0,
        );

        return projectEmployee.totalAvailableHours - dedicatedHours;
    }
}
