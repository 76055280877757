import { Nullable } from "@core/domain/types/nullable.type";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import {
    BudgetValidation,
    CreateBudgetValidation,
} from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateBudgetValidationUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}

    async execute(
        createValidation: CreateBudgetValidation,
    ): Promise<Nullable<BudgetValidation>> {
        const budgetValidationsResult =
            await this.budgetRepository.createValidation(createValidation);

        return budgetValidationsResult.fold(
            () => null,
            (budgetValidation) => budgetValidation,
        );
    }
}
