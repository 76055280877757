import { CountryEnumMapper } from "@beneficiary/data/mappers/country-enum.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import { LocalDto } from "@entity/data/dto/locals/local.dto";
import {
    CreateLocal,
    EditLocal,
    Local,
} from "@entity/domain/models/locals/local.model";
import { CreateRoom, Room } from "@entity/domain/models/locals/room.model";
import { LocalFormValuesValidated } from "@entity/presentation/component/entity-form/locals/form/local.form";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { CreateLocalBody } from "../../dto/locals/create-local.body";
import { EditLocalBody } from "../../dto/locals/edit-local.body";
import { CostPeriodicityTypeEnumMapper } from "./cost-periodicity-type-enum.mapper";
import { RoomMapper } from "./room.mapper";
import { StatusTypeEnumMapper } from "./status-type-enum.mapper";

@injectable()
export class LocalMapper {
    constructor(
        @inject(CostPeriodicityTypeEnumMapper)
        private readonly costPeriodicityTypeEnumMapper: CostPeriodicityTypeEnumMapper,
        @inject(StatusTypeEnumMapper)
        private readonly statusTypeEnumMapper: StatusTypeEnumMapper,
        @inject(RoomMapper)
        private readonly roomMapper: RoomMapper,
        @inject(CountryEnumMapper)
        private readonly countryEnumMapper: CountryEnumMapper,
    ) {}

    map(localDto: LocalDto): Nullable<Local> {
        const errors = validateSync(localDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const costPeriodicity = this.costPeriodicityTypeEnumMapper.map(
            localDto.cost_periodicity,
        );

        const status = this.statusTypeEnumMapper.map(localDto.status);

        const rooms = localDto.rooms_data.mapNotNull((room) =>
            this.roomMapper.map(room),
        );

        if (!costPeriodicity) return null;
        if (!status) return null;

        const local = new Local(
            localDto.id,
            localDto.entity,
            localDto.name,
            costPeriodicity,
            status,
            localDto.cost,
            localDto.street_name,
            localDto.street_number,
            localDto.zip_code,
            localDto.town,
            rooms,
        );

        if (localDto.other_status) local.otherStatus = localDto.other_status;
        if (localDto.identification_type)
            local.identificationType = localDto.identification_type;
        if (localDto.identification)
            local.identification = localDto.identification;
        if (localDto.business_name) local.businessName = localDto.business_name;
        if (localDto.floor) local.floor = localDto.floor;
        if (localDto.door) local.door = localDto.door;
        if (localDto.stair) local.stair = localDto.stair;
        if (localDto.local_code) local.localCode = localDto.local_code;
        if (localDto.cadastral_reference)
            local.cadastralReference = localDto.cadastral_reference;
        if (localDto.region) local.region = localDto.region;
        if (localDto.country)
            local.country =
                this.countryEnumMapper.map(localDto.country) ?? undefined;

        return local;
    }

    mapToCreate(local: CreateLocal): CreateLocalBody {
        const costPeriodicity = this.costPeriodicityTypeEnumMapper.mapToDto(
            local.costPeriodicity,
        );
        const status = this.statusTypeEnumMapper.mapToDto(local.status);

        const rooms = local.rooms.mapNotNull((room) => {
            if (room instanceof Room) return room.id;
        });

        const localDto: CreateLocalBody = {
            name: local.name,
            entity: local.entityId,
            cost_periodicity: costPeriodicity,
            status: status,
            cost: local.cost,
            street_name: local.streetName,
            street_number: local.streetNumber,
            zip_code: local.zipCode,
            town: local.town,
            rooms,
        };

        if (local.otherStatus) localDto.other_status = local.otherStatus;
        if (local.identification)
            localDto.identification = local.identification;
        if (local.businessName) localDto.business_name = local.businessName;
        if (local.floor) localDto.floor = local.floor;
        if (local.door) localDto.door = local.door;
        if (local.stair) localDto.stair = local.stair;
        if (local.localCode) localDto.local_code = local.localCode;
        if (local.cadastralReference)
            localDto.cadastral_reference = local.cadastralReference;
        if (local.identificationType)
            localDto.identification_type = local.identificationType;
        if (local.identification)
            localDto.identification = local.identification;
        if (local.region) localDto.region = local.region;
        if (local.country)
            localDto.country = this.countryEnumMapper.mapToDto(local.country);

        return localDto;
    }

    mapToEdit(local: EditLocal): EditLocalBody {
        const costPeriodicity = this.costPeriodicityTypeEnumMapper.mapToDto(
            local.costPeriodicity,
        );
        const status = this.statusTypeEnumMapper.mapToDto(local.status);

        const localDto: EditLocalBody = {
            id: local.id,
            entity: local.entityId,
            rooms: [],
            name: local.name,
            cost_periodicity: costPeriodicity,
            status: status,
            cost: local.cost,
            street_name: local.streetName,
            street_number: local.streetNumber,
            zip_code: local.zipCode,
            town: local.town,
        };

        if (local.otherStatus) localDto.other_status = local.otherStatus;
        if (local.identification)
            localDto.identification = local.identification;
        if (local.businessName) localDto.business_name = local.businessName;
        if (local.floor) localDto.floor = local.floor;
        if (local.door) localDto.door = local.door;
        if (local.stair) localDto.stair = local.stair;
        if (local.localCode) localDto.local_code = local.localCode;
        if (local.cadastralReference)
            localDto.cadastral_reference = local.cadastralReference;
        if (local.identificationType)
            localDto.identification_type = local.identificationType;
        if (local.identification)
            localDto.identification = local.identification;
        if (local.region) localDto.region = local.region;
        if (local.country)
            localDto.country = this.countryEnumMapper.mapToDto(local.country);

        return localDto;
    }

    mapFromFormToCreate(localForm: LocalFormValuesValidated): CreateLocal {
        if (!localForm.entityId) throw new Error("Entity id is required");
        const rooms: CreateRoom[] = [
            {
                name: localForm.nameRoom,
                positions: localForm.positionsRoom,
            },
        ];

        const createLocal: CreateLocal = new CreateLocal(
            localForm.name,
            localForm.entityId,
            localForm.costPeriodicity,
            localForm.status,
            localForm.cost,
            localForm.street,
            localForm.number,
            localForm.postalCode,
            localForm.town,
            rooms,
        );

        if (localForm.otherStatus)
            createLocal.otherStatus = localForm.otherStatus;
        if (localForm.identificationType)
            createLocal.identificationType = localForm.identificationType;
        if (localForm.identification)
            createLocal.identification = localForm.identification;
        if (localForm.businessName)
            createLocal.businessName = localForm.businessName;
        if (localForm.floor) createLocal.floor = localForm.floor;
        if (localForm.door) createLocal.door = localForm.door;
        if (localForm.stair) createLocal.stair = localForm.stair;
        if (localForm.localCode) createLocal.localCode = localForm.localCode;
        if (localForm.cadastralReference)
            createLocal.cadastralReference = localForm.cadastralReference;
        if (localForm.region) createLocal.region = localForm.region;
        if (localForm.country) createLocal.country = localForm.country;

        return createLocal;
    }

    mapFromFormToEdit(localForm: LocalFormValuesValidated): EditLocal {
        if (!localForm.id) throw new Error("Local id is required");
        if (!localForm.entityId) throw new Error("Entity id is required");
        if (!localForm.rooms) throw new Error("Rooms are required");

        const editLocal: EditLocal = new EditLocal(
            localForm.id,
            localForm.entityId,
            localForm.name,
            localForm.costPeriodicity,
            localForm.status,
            localForm.cost,
            localForm.street,
            localForm.number,
            localForm.postalCode,
            localForm.town,
            localForm.rooms,
        );

        if (localForm.otherStatus)
            editLocal.otherStatus = localForm.otherStatus;
        if (localForm.identificationType)
            editLocal.identificationType = localForm.identificationType;
        if (localForm.identification)
            editLocal.identification = localForm.identification;
        if (localForm.businessName)
            editLocal.businessName = localForm.businessName;
        if (localForm.floor) editLocal.floor = localForm.floor;
        if (localForm.door) editLocal.door = localForm.door;
        if (localForm.stair) editLocal.stair = localForm.stair;
        if (localForm.localCode) editLocal.localCode = localForm.localCode;
        if (localForm.cadastralReference)
            editLocal.cadastralReference = localForm.cadastralReference;
        if (localForm.region) editLocal.region = localForm.region;
        if (localForm.country) editLocal.country = localForm.country;

        return editLocal;
    }
}
