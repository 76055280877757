import { FinancialEntityTypeEnum } from "./financial-entity/financial-entity-type";
import { StatusEnum } from "./status.model";

export class ProjectSummary {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly financialEntityTypeLabels: string[],
        public readonly statusLabel: string,
        public catalogues: string[],
        public year: number,
    ) {}
}

// This interface is intended to be used as an intermediate step to build the final ProjectsSummary model
export interface ProjectSummaryTransformer {
    project: ProjectSummary;
    statusId: StatusEnum;
    financialEntityTypesId: FinancialEntityTypeEnum[];
}
