import { Pagination } from "@core/domain/models/pagination";
import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import {
    BudgetsSearchFilters,
    BudgetsSummary,
} from "@project/domain/models/budget-summary.model";
import { BudgetType } from "@project/domain/models/budget/financial-entity-budget.model";
import { FinancialEntityType } from "@project/domain/models/financial-entity/financial-entity-type";
import { CloneBudgetUseCase } from "@project/domain/usecases/budget/clone-budget.usecase";
import { DeleteBudgetUseCase } from "@project/domain/usecases/budget/delete-budget.usecase";
import { CreateBudgetFromTemplateUseCase } from "@project/domain/usecases/financial-entity/create-budget-from-template.usecase";
import { GetAllBudgetTypesUseCase } from "@project/domain/usecases/financial-entity/get-all-budget-types.usecase";
import { GetAllFinancialEntityTypesUsecase } from "@project/domain/usecases/financial-entity/get-all-financial-entity-types.usecase";
import { GetAllBudgetsUseCase } from "@project/domain/usecases/get-all-budgets.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";
import { AddBudgetFromTemplateFormValidated } from "./add-budget-from-template-form";

export type BudgetTemplateTable = {
    id: number;
    name: string;
    createdAt: string;
};
interface BudgetsTable {
    budgets: BudgetTemplateTable[];
    count: number;
}
@injectable()
export class FinancialEntityFormViewmodel extends BaseViewModel {
    _entityId: Nullable<number> = null;

    @observable
    initialLoading: boolean = true;

    @observable
    types: FinancialEntityType[] = [];

    @observable
    private templateBudgets: BudgetsSummary = new BudgetsSummary([], 0);

    @observable
    pagination: Pagination = new Pagination();

    @observable
    addBudgetFromTemplateModalOpen: boolean = false;

    @observable
    budgetTypes: BudgetType[] = [];

    constructor(
        @inject(GetAllFinancialEntityTypesUsecase)
        private readonly getAllFinancialEntityTypesUseCase: GetAllFinancialEntityTypesUsecase,
        @inject(GetAllBudgetsUseCase)
        private readonly getAllBudgetsUseCase: GetAllBudgetsUseCase,
        @inject(DeleteBudgetUseCase)
        private readonly deleteBudgetUseCase: DeleteBudgetUseCase,
        @inject(CloneBudgetUseCase)
        private readonly cloneBudgetUseCase: CloneBudgetUseCase,
        @inject(CreateBudgetFromTemplateUseCase)
        private readonly createBudgetFromTemplateUseCase: CreateBudgetFromTemplateUseCase,
        @inject(GetAllBudgetTypesUseCase)
        private readonly getAllBudgetTypesUseCase: GetAllBudgetTypesUseCase,
    ) {
        super();
        makeObservable(this);
    }
    override async didMount(): Promise<void> {
        await super.didMount();
        await this.initViewData();
    }
    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllFinancialEntityTypes(),
            this.getAllBudgets(this.pagination),
            this.getBudgetTypes(),
        ]);
        runInAction(() => {
            this.initialLoading = false;
        });
    }
    async getAllFinancialEntityTypes(): Promise<void> {
        const types = await this.getAllFinancialEntityTypesUseCase.execute();
        runInAction(() => {
            this.types = types;
        });
    }
    async getAllBudgets(pagination: Pagination): Promise<void> {
        if (!this._entityId) return;
        const filters: BudgetsSearchFilters = {
            financialEntityId: this._entityId,
        };
        const response = await this.getAllBudgetsUseCase.execute(
            pagination,
            filters,
        );
        runInAction(() => {
            this.templateBudgets = response;
        });
    }

    async getBudgetTypes(): Promise<void> {
        if (!this._entityId) return;

        const response = await this.getAllBudgetTypesUseCase.execute();
        runInAction(() => {
            this.budgetTypes = response;
        });
    }

    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;
        this.getAllBudgets(this.pagination);
    }

    get budgetsTable(): BudgetsTable {
        return {
            count: this.templateBudgets.count,
            budgets: this.templateBudgets.budgetSummary.map((budget) => ({
                id: budget.id,
                name: budget.name,
                createdAt: budget.createdAt.toLocaleString(),
            })),
        };
    }
    async deleteBudget(id: number): Promise<void> {
        LoadLayoutStore.start();
        const result = await this.deleteBudgetUseCase.execute(id);
        if (result) {
            ToastManagerStore.success();
            await this.getAllBudgets(this.pagination);
        }
        LoadLayoutStore.finish();
    }

    async cloneBudget(id: number): Promise<void> {
        LoadLayoutStore.start();
        const result = await this.cloneBudgetUseCase.execute(id);
        if (result) {
            ToastManagerStore.success();
            await this.getAllBudgets(this.pagination);
        }
        LoadLayoutStore.finish();
    }

    setAddBudgetFromTemplateModalOpen(isOpen: boolean): void {
        this.addBudgetFromTemplateModalOpen = isOpen;
    }

    async addBudgetFromTemplate(
        budgetType: AddBudgetFromTemplateFormValidated,
    ): Promise<void> {
        if (this._entityId) {
            LoadLayoutStore.start();

            const result = await this.createBudgetFromTemplateUseCase.execute(
                budgetType,
                this._entityId,
            );

            if (result) {
                ToastManagerStore.success();
                await this.getAllBudgets(this.pagination);
                this.addBudgetFromTemplateModalOpen = false;
            }
            LoadLayoutStore.finish();
        }
    }
}
