import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum TerminationReasonEnumDto {
    resignation = "resignation",
    objective_dismissal = "objective_dismissal",
}

export class TerminationReasonDto {
    @IsEnum(TerminationReasonEnumDto)
    @IsNotEmpty()
    key!: TerminationReasonEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
