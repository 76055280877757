import { CreateDocument } from "@core/domain/models/create-document.model";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectRepository } from "@project/data/repositories/project.repository";
import { inject, injectable } from "inversify";

@injectable()
export class ImportProjectsUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(
        projectsImport: CreateDocument,
        entityId: number,
    ): Promise<boolean> {
        const importedResult = await this.projectRepository.importProjects(
            projectsImport,
            entityId,
        );

        if (importedResult.isLeft()) {
            ToastManagerStore.error(importedResult.getLeftOrThrow().message);
        }

        return importedResult.fold(
            () => true,
            (importedProjects) => importedProjects,
        );
    }
}
