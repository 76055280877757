import { Undefinable } from "@core/domain/types/undefinable.type";
import { DocumentTypeLicenseEnum } from "./inc-document.model";

export class CreateDocument {
    constructor(
        public readonly name: string,
        public file: File,
        public title?: Undefinable<string>,
        public author?: Undefinable<string>,
        public description?: Undefinable<string>,
        public expiryDate?: Undefinable<string>,
        public visible?: Undefinable<boolean>,
        public category?: Undefinable<number>,
        public typeLicense?: Undefinable<DocumentTypeLicenseEnum>,
    ) {}
}
