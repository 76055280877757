export enum IdentificationTypeEnum {
    dni = "dni",
    nie = "nie",
    passport = "passport",
    nif = "nif",
    cif = "cif",
}

export class IdentificationType {
    constructor(
        public readonly id: IdentificationTypeEnum,
        public readonly label: string,
    ) {}
}
