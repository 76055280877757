import { EducationLevel } from "@core/domain/models/education-level.model";
import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { GetAllEducationLevelsUseCase } from "@core/domain/usecases/get-all-education-levels.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateVolunteerContractMapper } from "@entity/data/mappers/employee/contracts/volunteer/create-volunteer-contract.mapper";
import { EditVolunteerContractMapper } from "@entity/data/mappers/employee/contracts/volunteer/edit-volunteer-contract.mapper";
import {
    VolunteerContract,
    type VolunteerContractSearchFilters,
} from "@entity/domain/models/employee/contracts/volunteer/volunteer-contract.model";
import { CreateVolunteerContractUseCase } from "@entity/domain/usecases/employee/contracts/volunteer/create-volunteer-contract.usecase";
import { DeleteVolunteerContractUseCase } from "@entity/domain/usecases/employee/contracts/volunteer/delete-volunteer-contract.usecase";
import { EditVolunteerContractUseCase } from "@entity/domain/usecases/employee/contracts/volunteer/edit-volunteer-contract.usecase";
import { GetAllVolunteerContractsByUseCase } from "@entity/domain/usecases/employee/contracts/volunteer/get-all-volunteer-contracts-by.usecase";
import { VolunteerContractFormValuesValidated } from "@entity/presentation/component/employee-form/contract/volunteer/form/volunteer-contract-form";
import { Catalogue } from "@project/domain/models/catalogue.model";
import { GetAllCataloguesUseCase } from "@project/domain/usecases/get-all-catalogues.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class VolunteerContractTabViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    educationLevels: EducationLevel[] = [];

    @observable
    preferences: Catalogue[] = [];

    @observable
    volunteerContracts: VolunteerContract[] = [];

    employeeId: Undefinable<number> = undefined;

    @observable
    showAddContractModal: boolean = false;

    @observable
    showEditContractModal: boolean = false;

    @observable
    contractToEdit: Undefinable<VolunteerContract> = undefined;

    filtersValue: VolunteerContractSearchFilters = {
        employee: undefined,
    };

    @observable
    filters: VolunteerContractSearchFilters = this.filtersValue;

    constructor(
        @inject(CreateVolunteerContractUseCase)
        private readonly createVolunteerContractUseCase: CreateVolunteerContractUseCase,
        @inject(CreateVolunteerContractMapper)
        private readonly createVolunteerContractMapper: CreateVolunteerContractMapper,
        @inject(DeleteVolunteerContractUseCase)
        private readonly deleteVolunteerContractUseCase: DeleteVolunteerContractUseCase,
        @inject(EditVolunteerContractUseCase)
        private readonly editVolunteerContractUseCase: EditVolunteerContractUseCase,
        @inject(EditVolunteerContractMapper)
        private readonly editVolunteerContractMapper: EditVolunteerContractMapper,
        @inject(GetAllVolunteerContractsByUseCase)
        private readonly getAllVolunteerContractsByUseCase: GetAllVolunteerContractsByUseCase,
        @inject(GetAllEducationLevelsUseCase)
        private readonly getAllEducationLevelsUseCase: GetAllEducationLevelsUseCase,
        @inject(GetAllCataloguesUseCase)
        private readonly getAllCataloguesUseCase: GetAllCataloguesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getEducationLevels(),
            this.getAllCatalogues(),
            this.getVolunteerContracts(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getVolunteerContracts(): Promise<void> {
        const volunteerContracts =
            await this.getAllVolunteerContractsByUseCase.execute(this.filters);
        runInAction(() => {
            this.volunteerContracts = volunteerContracts;
        });
    }

    async addContract(
        employee: Undefinable<number>,
        values: VolunteerContractFormValuesValidated,
    ): Promise<Nullable<VolunteerContract>> {
        if (!employee) throw Error("Missing employeeId");

        LoadLayoutStore.start();

        const createContractResult =
            await this.createVolunteerContractUseCase.execute(
                this.createVolunteerContractMapper.mapFromFormValues(
                    employee,
                    values,
                ),
            );

        if (createContractResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createContractResult;
    }

    async editContract(
        values: VolunteerContractFormValuesValidated,
    ): Promise<void> {
        if (!this.contractToEdit?.id) throw Error("Missing volunteerContract");

        if (!this.contractToEdit.employeeId) throw Error("Missing employeeId");

        LoadLayoutStore.start();

        const editContractResult =
            await this.editVolunteerContractUseCase.execute(
                this.editVolunteerContractMapper.mapFromVolunteerContractFormValues(
                    this.contractToEdit.id,
                    this.contractToEdit.employeeId,
                    values,
                ),
            );

        if (editContractResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async deleteContract(contractId: number): Promise<void> {
        LoadLayoutStore.start();

        const deleted =
            await this.deleteVolunteerContractUseCase.execute(contractId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    @action
    setEmployeeId(employeeId: number): void {
        this.employeeId = employeeId;
        this.filters = {
            ...this.filters,
            employee: employeeId,
        };
    }

    async reloadContracts(): Promise<void> {
        await this.getVolunteerContracts();
    }

    async getEducationLevels(): Promise<void> {
        const educationLevels =
            await this.getAllEducationLevelsUseCase.execute();

        runInAction(() => {
            this.educationLevels = educationLevels;
        });
    }

    async getAllCatalogues(): Promise<void> {
        const preferences = await this.getAllCataloguesUseCase.execute();

        runInAction(() => {
            this.preferences = preferences;
        });
    }

    @action
    setShowAddContractModal(show: boolean): void {
        this.showAddContractModal = show;
    }

    @action
    closeEditContractModal(): void {
        this.showEditContractModal = false;
    }

    @action
    openEditContractModal(
        volunteerContract: Undefinable<VolunteerContract>,
    ): void {
        this.contractToEdit = volunteerContract;
        this.showEditContractModal = true;
    }
}
