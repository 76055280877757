export enum SupplierTypeEnum {
    Subcontracting = "subcontracting",
    BeneficiariesCosts = "beneficiaries_costs",
}

export class SupplierType {
    constructor(
        public readonly id: SupplierTypeEnum,
        public readonly label: string,
    ) {}
}
