import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { EmployeeRepository } from "@entity/data/repositories/employee.repository";
import { inject, injectable } from "inversify";
import { Employee } from "../../models/employee/employee.model";

@injectable()
export class GetEmployeeByIdUseCase {
    constructor(
        @inject(EmployeeRepository)
        private readonly employeeRepository: EmployeeRepository,
    ) {}

    async execute(employeeId: number): Promise<Nullable<Employee>> {
        const employeeResult =
            await this.employeeRepository.findById(employeeId);

        if (employeeResult.isLeft()) {
            ToastManagerStore.error(employeeResult.getLeftOrThrow().message);
        }

        return employeeResult.fold(
            () => null,
            (employee) => employee,
        );
    }
}
