export enum EmploymentSituationRelevantCharacteristicsEnum {
    LongTermUnemployed = "long_term_unemployed",
    ExhaustedBenefits = "exhausted_benefits",
    PrecariousWork = "precarious_work",
    Minor = "minor",
    Over65 = "over_65",
    SelfEmployed = "self_employed",
    WithoutContract = "without_contract",
    OtherLaboralStatus = "other_laboral_status",
}

export class EmploymentRelevantCharacteristics {
    constructor(
        public readonly id: EmploymentSituationRelevantCharacteristicsEnum,
        public readonly label: string,
    ) {}
}
