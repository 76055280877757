import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { PolicyProtocolRepository } from "@entity/data/repositories/policy-protocol.repository";
import { EditPolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/edit-policy-protocol.model";
import { PolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/policy-protocol.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditPolicyProtocolUseCase {
    constructor(
        @inject(PolicyProtocolRepository)
        private readonly policyProtocolRepository: PolicyProtocolRepository,
    ) {}

    async execute(
        policyProtocol: EditPolicyProtocol,
    ): Promise<Nullable<PolicyProtocol>> {
        const editResult =
            await this.policyProtocolRepository.edit(policyProtocol);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedPolicyProtocol) => editedPolicyProtocol,
        );
    }
}
