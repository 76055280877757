import { Pagination } from "@core/domain/models/pagination";
import { ProjectRepository } from "@project/data/repositories/project.repository";
import { ProjectSummary } from "@project/domain/models/project-summary.model";
import { inject, injectable } from "inversify";
import { ProjectSearchFilters } from "../models/project-search-filters";

@injectable()
export class GetAllProjectsUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(filters?: ProjectSearchFilters): Promise<ProjectSummary[]> {
        const pagination: Pagination = Pagination.NoPagination();
        const projectsResult = await this.projectRepository.searchAllBy(
            pagination,
            filters,
        );

        return projectsResult.fold(
            () => [],
            (projects) => projects.projectsSummary,
        );
    }
}
