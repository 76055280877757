import { BeneficiaryDto } from "@beneficiary/data/dto/beneficiary.dto";
import { DocumentDto } from "@core/data/dto/document.dto";
import { IdentificationDocumentTypeEnum } from "@core/domain/models/identification-document-type.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import { EntitySummaryDto } from "@entity/data/dto/entities-summary.dto";
import { ProjectDto } from "@project/data/dto/project.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsEnum,
    IsInt,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { StatusEnumDto } from "./status-enum.dto";

class ProfessionalResponsibleDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    surname!: string;

    @IsEnum(IdentificationDocumentTypeEnum)
    @IsOptional()
    identification_type!: Nullable<IdentificationDocumentTypeEnum>;

    @IsString()
    @IsOptional()
    identification!: Nullable<string>;
}
class OriginEntityDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    company_name!: string;
}

export class ProceedingDto {
    @IsNotEmpty()
    @IsNumber()
    id!: number;

    @IsNotEmpty()
    @IsDateString()
    created!: string;

    @IsNotEmpty()
    @IsDateString()
    modified!: string;

    @IsNotEmpty()
    @IsNumber()
    beneficiary!: number;

    @IsNotEmpty()
    @IsString()
    reason!: string;

    @IsOptional()
    @IsNumber()
    entity!: Nullable<number>;

    @IsNotEmpty()
    @IsNumber()
    project!: number;

    @IsArray()
    @IsInt({ each: true })
    @IsNotEmpty()
    activities!: number[];

    @IsNumber()
    @IsNotEmpty()
    professional_responsible!: number;

    @IsEnum(StatusEnumDto)
    status!: StatusEnumDto;

    @IsString()
    @IsNotEmpty()
    status_label!: string;

    @IsNotEmpty()
    @Type(() => BeneficiaryDto)
    beneficiary_data!: BeneficiaryDto;

    @IsNotEmpty()
    @Type(() => ProfessionalResponsibleDto)
    professional_responsible_data!: ProfessionalResponsibleDto;

    @IsNotEmpty()
    @Type(() => EntitySummaryDto)
    entity_data!: EntitySummaryDto;

    @IsNumber()
    @IsOptional()
    authorization_file!: Nullable<number>;

    @IsNumber()
    @IsOptional()
    derivation_authorization_file!: Nullable<number>;

    @IsNotEmpty()
    documents!: number[];

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    documents_data!: Nullable<DocumentDto[]>;

    @IsNumber()
    @IsOptional()
    resolution_file!: Nullable<number>;

    @IsOptional()
    @IsString()
    resolution_date!: Nullable<string>;

    @IsOptional()
    @IsString()
    resolution_description!: Nullable<string>;

    @IsString()
    @IsOptional()
    derivation_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    derivation_description!: Nullable<string>;

    @IsNumber()
    @IsOptional()
    derivation_entity!: Nullable<number>;

    @IsArray()
    @IsInt({ each: true })
    @IsNotEmpty()
    derivation_activities!: number[];

    @IsNumber()
    @IsOptional()
    origin_entity!: Nullable<number>;

    @IsOptional()
    @Type(() => OriginEntityDto)
    origin_entity_data!: Nullable<OriginEntityDto>;

    @IsNotEmpty()
    @Type(() => ProjectDto)
    project_data!: ProjectDto;
}
