import { Pagination } from "@core/domain/models/pagination";
import { type Nullable } from "@core/domain/types/nullable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import {
    CostSearchFilters,
    CostSummary,
    CostsSummary,
} from "@entity/domain/models/cost/cost-summary.model";
import { Cost } from "@entity/domain/models/cost/cost.model";
import { GetCostByIdUseCase } from "@entity/domain/usecases/cost/get-cost-by-id.usecase";
import { GetCostsByUseCase } from "@entity/domain/usecases/cost/get-costs-by.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class EntityCostFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    costs: CostSummary[] = [];

    @observable
    costSelected: Nullable<Cost> = null;

    constructor(
        @inject(GetCostsByUseCase)
        private readonly getCostsByUseCase: GetCostsByUseCase,
        @inject(GetCostByIdUseCase)
        private readonly getCostByIdUseCase: GetCostByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.searchCostsBy()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setCosts(costs: CostsSummary): void {
        this.costs = costs.costsSummary;
    }

    @action
    async searchCostsBy(): Promise<void> {
        const filter: CostSearchFilters = {};
        const costs = await this.getCostsByUseCase.execute(
            Pagination.NoPagination(),
            filter,
        );

        this.setCosts(costs);
    }

    async getDetails(costId: string): Promise<Nullable<Cost>> {
        if (!costId) {
            return null;
        }

        this.costSelected = await this.getCostByIdUseCase.execute(
            Number(costId),
        );
        return this.costSelected;
    }
}
