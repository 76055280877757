import { DiModuleBuilder } from "@di/builder/di-module-builder";
import { UserDatasource } from "@user/data/datasources/user.datasource";
import { UserMapper } from "@user/data/mappers/user.mapper";
import { UserRepository } from "@user/data/repositories/user.repository";
import { ContainerModule } from "inversify";
import { CreateUserMapper } from "./data/mappers/create-user.mapper";
import { EditUserMapper } from "./data/mappers/edit-user.mapper";
import { UserEntityMapper } from "./data/mappers/user-entity.mapper";
import { UserRelatedEmployeeMapper } from "./data/mappers/user-related-employee.mapper";
import { UserRoleEnumMapper } from "./data/mappers/user-role-enum.mapper";
import { UserRoleMapper } from "./data/mappers/user-role.mapper";
import { UsersMapper } from "./data/mappers/users.mapper";
import { CreateUserUseCase } from "./domain/usecases/create-user.usecase";
import { EditUserUseCase } from "./domain/usecases/edit-user.usecase";
import { GetAllUserRolesUseCase } from "./domain/usecases/get-all-user-roles.usecase";
import { GetUserByIdUseCase } from "./domain/usecases/get-user-by-id.usecase";
import { ImpersonateEntityUseCase } from "./domain/usecases/impersonate-entity.usecase";
import { SearchUsersByUseCase } from "./domain/usecases/search-users-by.usecase";
import { ToggleActivationUserUseCase } from "./domain/usecases/toggle-activation-user.usecase";
import { UserFormViewModel } from "./presentation/components/user-form/user-form.viewmodel";
import { CreateUserPageViewModel } from "./presentation/pages/create/create-user-page.viewmodel";
import { DetailUserPageViewModel } from "./presentation/pages/detail/detail-user-page.viewmodel";
import { EditUserPageViewModel } from "./presentation/pages/edit/edit-user-page.viewmodel";
import { UserListPageViewModel } from "./presentation/pages/list/user-list-page-viewmodel";

const userModule = new DiModuleBuilder();

userModule.registerSubModules(() => ({
    datasources: new ContainerModule((bind) => {
        bind(UserDatasource).toSelf();
    }),
    mappers: new ContainerModule((bind) => {
        bind(CreateUserMapper).toSelf();
        bind(EditUserMapper).toSelf();
        bind(UserEntityMapper).toSelf();
        bind(UserMapper).toSelf();
        bind(UserRoleEnumMapper).toSelf();
        bind(UserRoleMapper).toSelf();
        bind(UserRelatedEmployeeMapper).toSelf();
        bind(UsersMapper).toSelf();
    }),
    repositories: new ContainerModule((bind) => {
        bind(UserRepository).toSelf();
    }),

    useCases: new ContainerModule((bind) => {
        bind(EditUserUseCase).toSelf();
        bind(CreateUserUseCase).toSelf();
        bind(ImpersonateEntityUseCase).toSelf();
        bind(GetAllUserRolesUseCase).toSelf();
        bind(GetUserByIdUseCase).toSelf();
        bind(SearchUsersByUseCase).toSelf();
        bind(ToggleActivationUserUseCase).toSelf();
    }),
    viewModels: new ContainerModule((bind) => {
        bind(CreateUserPageViewModel).toSelf();
        bind(DetailUserPageViewModel).toSelf();
        bind(EditUserPageViewModel).toSelf();
        bind(UserFormViewModel).toSelf();
        bind(UserListPageViewModel).toSelf();
    }),
}));

userModule.registerModule();

export { userModule };
