import { Nullable } from "@core/domain/types/nullable.type";
import { FinancialEntityDto } from "@project/data/dto/financial-entity/financial-entities.dto";
import { FinancialEntityTypeEnumMapper } from "@project/data/mappers/financial-entity/financial-entity-type-enum.mapper";
import { FinancialEntity } from "@project/domain/models/financial-entity/financial-entities.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class FinancialEntityMapper {
    constructor(
        @inject(FinancialEntityTypeEnumMapper)
        private readonly typeEnumMapper: FinancialEntityTypeEnumMapper,
    ) {}

    map(financialEntityDto: FinancialEntityDto): Nullable<FinancialEntity> {
        const errors = validateSync(financialEntityDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }
        const type = this.typeEnumMapper.map(financialEntityDto.type);
        if (!type) return null;

        return new FinancialEntity(
            financialEntityDto.id,
            financialEntityDto.name,
            type,
            financialEntityDto.is_active,
            financialEntityDto.description,
        );
    }
}
