import { AuthorityManager } from "@entity/domain/models/authority-manager/authority-manager.model";
import { CreateAuthorityManager } from "@entity/domain/models/authority-manager/create-authority-manager.model";

export class EditAuthorityManager extends CreateAuthorityManager {
    constructor(
        public readonly id: AuthorityManager["id"],
        public override name: CreateAuthorityManager["name"],
        public override position: CreateAuthorityManager["position"],
        public override entity: CreateAuthorityManager["entity"],
        public override appointmentDate?: CreateAuthorityManager["appointmentDate"],
        public override electionProcedure?: CreateAuthorityManager["electionProcedure"],
        public override otherPosition?: CreateAuthorityManager["otherPosition"],
        public override mandateTerminationReason?: CreateAuthorityManager["mandateTerminationReason"],
        public override otherMandateTerminationReason?: CreateAuthorityManager["otherMandateTerminationReason"],
        public override terminationDate?: CreateAuthorityManager["terminationDate"],
    ) {
        super(
            name,
            position,
            entity,
            appointmentDate,
            electionProcedure,
            otherPosition,
            mandateTerminationReason,
            otherMandateTerminationReason,
            terminationDate,
        );
    }
}
