import { Nullable } from "@core/domain/types/nullable.type";
import { EntityTerritorialScopeDto } from "@entity/data/dto/entity-territorial-scope.dto";
import { EntityTerritorialScopeEnumMapper } from "@entity/data/mappers/entity-territorial-scope-enum.mapper";
import {
    EntityTerritorialScope,
    EntityTerritorialScopeEnum,
} from "@entity/domain/models/entity-territorial-scope.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class EntityTerritorialScopeMapper {
    constructor(
        @inject(EntityTerritorialScopeEnumMapper)
        private readonly entityTerritorialScopeEnumMapper: EntityTerritorialScopeEnumMapper,
    ) {}

    map(
        entityTerritorialScopeDto: EntityTerritorialScopeDto,
    ): Nullable<EntityTerritorialScope> {
        const errors = validateSync(EntityTerritorialScopeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<EntityTerritorialScopeEnum> =
            this.entityTerritorialScopeEnumMapper.map(
                entityTerritorialScopeDto.key,
            );

        if (!id) return null;

        return new EntityTerritorialScope(id, entityTerritorialScopeDto.value);
    }
}
