import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { EmployeeMapper } from "@entity/data/mappers/employee/employee.mapper";
import { Employee } from "@entity/domain/models/employee/employee.model";
import { ProjectEmployeeFormValuesValidated as ProjectEmployeeFormEntityValuesValidated } from "@entity/presentation/component/employee-form/general/form/add-employee-from-entity-modal-form.view";
import { ProjectEmployeeDto } from "@project/data/dto/project-employee/project-employees.dto";
import { ProjectEmployeeDedicationMapper } from "@project/data/mappers/project-employee-dedication.mapper";
import { ProjectEmployeeDedication } from "@project/domain/models/project-employee-dedications.model";
import {
    CreateProjectEmployee,
    ProjectEmployee,
    UpdateProjectEmployee,
} from "@project/domain/models/project-employees.model";
import { ProjectEmployeeFormValuesValidated } from "@project/presentation/components/project-form/employees/add-employee-modal-form.view";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    CreateProjectEmployeeBody,
    EditProjectEmployeeBody,
} from "../dto/project-employee/create-project-employee.body";

@injectable()
export class ProjectEmployeeMapper {
    constructor(
        @inject(DocumentMapper) private readonly documentMapper: DocumentMapper,
        @inject(EmployeeMapper)
        private readonly employeeMapper: EmployeeMapper,
        @inject(ProjectEmployeeDedicationMapper)
        private readonly projectEmployeeDedicationMapper: ProjectEmployeeDedicationMapper,
    ) {}

    map(projectEmployeeDto: ProjectEmployeeDto): Nullable<ProjectEmployee> {
        const errors = validateSync(projectEmployeeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const employeeData: Nullable<Employee> = this.employeeMapper.map(
            projectEmployeeDto.employee_data,
        );
        if (!employeeData) return null;

        let dedications: ProjectEmployeeDedication[] = [];

        if (projectEmployeeDto.dedications_data) {
            dedications = projectEmployeeDto.dedications_data.mapNotNull(
                (dedicationDto) =>
                    this.projectEmployeeDedicationMapper.map(dedicationDto),
            );
        }

        const projectEmployee = new ProjectEmployee(
            projectEmployeeDto.id,
            employeeData.name,
            employeeData.surname,
            projectEmployeeDto.project,
            projectEmployeeDto.available_dedication,
            employeeData,
            dedications,
        );

        if (projectEmployeeDto.documents_data) {
            projectEmployeeDto.documents_data.forEach((documentDto) => {
                const document = this.documentMapper.map(documentDto);

                if (document) projectEmployee.addDocument(document);
            });
        }

        if (employeeData.contractedHours) {
            projectEmployee.contractedHours = employeeData.contractedHours;
        }

        return projectEmployee;
    }

    mapFromFormToCreate(
        projectId: number,
        projectEmployeeForm: ProjectEmployeeFormValuesValidated,
    ): CreateProjectEmployee {
        // const documents: Undefinable<number[]> =
        //     projectEmployeeForm.documents?.map((document) => document.id);

        return new CreateProjectEmployee(
            projectId,
            projectEmployeeForm.employeeId,
            // documents,
        );
    }

    mapFromFormEntityToUpdate(
        projectEmployeeForm: ProjectEmployeeFormEntityValuesValidated,
    ): UpdateProjectEmployee {
        if (!projectEmployeeForm.projectEmployeeId)
            throw new Error("Project employee id is required");
        return new UpdateProjectEmployee(
            projectEmployeeForm.projectEmployeeId,
            projectEmployeeForm.projectId,
            projectEmployeeForm.employeeId,
        );
    }

    mapToCreateDto(
        createProjectEmployee: CreateProjectEmployee,
    ): CreateProjectEmployeeBody {
        return {
            project: createProjectEmployee.projectId,
            employee: createProjectEmployee.employee,
            documents: convertEmptyToUndefined(createProjectEmployee.documents),
        };
    }

    mapToEditDto(
        editProjectEmployee: UpdateProjectEmployee,
    ): EditProjectEmployeeBody {
        return {
            id: editProjectEmployee.id,
            project: editProjectEmployee.projectId,
            employee: editProjectEmployee.employee,
            documents: convertEmptyToUndefined(editProjectEmployee.documents),
        };
    }

    mapToUpdate(projectEmployee: ProjectEmployee): UpdateProjectEmployee {
        return new UpdateProjectEmployee(
            projectEmployee.id,
            projectEmployee.projectId,
            projectEmployee.employee.id,
            projectEmployee.documents?.map((document) => document.id),
        );
    }
}
