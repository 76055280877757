import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { BudgetColumnType } from "@project/domain/models/budget/budget-column/budget-column-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllBudgetColumnTypesUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}

    async execute(): Promise<BudgetColumnType[]> {
        const types = await this.budgetRepository.getColumnsTypes();

        return types.getOrElse([]);
    }
}
