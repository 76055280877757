import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectRepository } from "@project/data/repositories/project.repository";
import { CreateProject } from "@project/domain/models/create-project.model";
import { Project } from "@project/domain/models/project.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateProjectUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(project: CreateProject): Promise<Nullable<Project>> {
        const creationResult = await this.projectRepository.create(project);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdProject) => createdProject,
        );
    }
}
