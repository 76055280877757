export enum SubsidyReasonEnum {
    DISABILITY = "disability",
    ETT_WORKER = "ett_worker",
    CONCILIATION = "conciliation",
    EMPLOYMENT_PRESERVATION = "employment_preservation",
    PROTECTED_COLLECTIVE = "protected_collective",
    SPECIAL_AGE_GROUP = "special_age_group",
    MADE_INDEDFINITE = "made_indedfinite",
    OTHER = "other",
}

export class SubsidyReason {
    constructor(
        public readonly id: SubsidyReasonEnum,
        public readonly label: string,
    ) {}
}
