import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    ContractType,
    ContractTypeEnum,
} from "../../../domain/models/expenses/contract-type.model";
import { ContractTypeDto } from "../../dto/expenses/contract-type.dto";
import { ContractTypeEnumMapper } from "./contract-type-enum.mapper";

@injectable()
export class ContractTypeMapper {
    constructor(
        @inject(ContractTypeEnumMapper)
        private readonly catalogueEnumMapper: ContractTypeEnumMapper,
    ) {}

    map(catalogueDto: ContractTypeDto): Nullable<ContractType> {
        const errors = validateSync(catalogueDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<ContractTypeEnum> = this.catalogueEnumMapper.map(
            catalogueDto.key,
        );

        if (!id) return null;

        return new ContractType(id, catalogueDto.value);
    }
}
