import { EditPublicInterestBody } from "@entity/data/dto/public-interest/edit-public-interest.body";
import { EditPublicInterest } from "@entity/domain/models/public-interest/edit-public-interest.model";
import { PublicInterestFormValuesValidated } from "@entity/presentation/component/entity-form/general/public-interest/form/public-interest-form";
import { injectable } from "inversify";

@injectable()
export class EditPublicInterestMapper {
    mapToDto(editPublicInterest: EditPublicInterest): EditPublicInterestBody {
        const editPublicInterestBody: EditPublicInterestBody = {
            id: editPublicInterest.id,
            organization_type: editPublicInterest.organizationType,
            entity: editPublicInterest.entity,
            date: editPublicInterest.date.toISODate() ?? "",
        };
        return editPublicInterestBody;
    }

    mapFromModal(
        id: number,
        entityId: number,
        publicInterest: PublicInterestFormValuesValidated,
    ): EditPublicInterest {
        const editPublicInterest: EditPublicInterest = new EditPublicInterest(
            id,
            publicInterest.organizationType,
            entityId,
            publicInterest.date,
        );
        return editPublicInterest;
    }
}
