import { ParentangeDto } from "@beneficiary/data/dto/parentange.dto";
import { RelationshipEnumMapper } from "@beneficiary/data/mappers/relationship-enum.mapper";
import { Relationship } from "@beneficiary/domain/models/relationship.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class RelationshipMapper {
    constructor(
        @inject(RelationshipEnumMapper)
        private readonly relationshipIdMapper: RelationshipEnumMapper,
    ) {}
    map(relationShipDto: ParentangeDto): Nullable<Relationship> {
        const errors = validateSync(relationShipDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const relationshipId = this.relationshipIdMapper.map(
            relationShipDto.key,
        );

        if (!relationshipId) return null;

        return new Relationship(relationshipId, relationShipDto.value);
    }
}
