import { IncError } from "@core/domain/errors/inc.error";
import i18n from "@i18n";

export class NoLoggedUserError extends IncError {
    constructor() {
        super(i18n.t("error:authentication.logout.noLoggedUserError"));

        Object.setPrototypeOf(this, NoLoggedUserError.prototype);
    }
}
