import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum ContractTypeEnumDto {
    INDEFINITE = "indefinite",
    TEMPORARY = "temporary",
    TRAINING = "training",
    FIXED_DISCONTINUED = "fixed_discontinued",
    OTHER = "other",
}
export class ContractTypeDto {
    @IsEnum(ContractTypeEnumDto)
    @IsNotEmpty()
    key!: ContractTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
