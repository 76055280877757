import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { EmploymentRelevantCharacteristics } from "../models/employment-situation-relevant-characteristics.enum";
@injectable()
export class GetAllEmploymentCharacteristicsUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}
    async execute(): Promise<EmploymentRelevantCharacteristics[]> {
        const employmentCharacResult =
            await this.beneficiaryRepository.getAllOtherEmploymentCharcteristics();
        if (employmentCharacResult.isLeft()) {
            ToastManagerStore.error(
                employmentCharacResult.getLeftOrThrow().message,
            );
        }
        return employmentCharacResult.getOrElse([]);
    }
}
