import { Nullable } from "@core/domain/types/nullable.type";
import { StatusTypeEnum } from "@entity/domain/models/locals/status.model";
import { injectable } from "inversify";
import { StatusTypeEnumDto } from "../../dto/locals/status.dto";

@injectable()
export class StatusTypeEnumMapper {
    map(entityTypeEnumDto: StatusTypeEnumDto): Nullable<StatusTypeEnum> {
        switch (entityTypeEnumDto) {
            case StatusTypeEnumDto.LOAN:
                return StatusTypeEnum.LOAN;
            case StatusTypeEnumDto.RENT:
                return StatusTypeEnum.RENT;
            case StatusTypeEnumDto.PROPERTY:
                return StatusTypeEnum.PROPERTY;
            case StatusTypeEnumDto.OTHER:
                return StatusTypeEnum.OTHER;
            default:
                return null;
        }
    }

    mapToDto(entityTypeEnum: StatusTypeEnum): StatusTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (entityTypeEnum) {
            case StatusTypeEnum.LOAN:
                return StatusTypeEnumDto.LOAN;
            case StatusTypeEnum.RENT:
                return StatusTypeEnumDto.RENT;
            case StatusTypeEnum.PROPERTY:
                return StatusTypeEnumDto.PROPERTY;
            case StatusTypeEnum.OTHER:
                return StatusTypeEnumDto.OTHER;
        }
    }
}
