import { ProjectEmployeeDedicationsDto } from "@project/data/dto/project-employee-dedication/project-employee-dedication.dto";
import { ProjectEmployeeDedicationMapper } from "@project/data/mappers/project-employee-dedication.mapper";
import { ProjectEmployeeDedications } from "@project/domain/models/project-employee-dedications.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ProjectEmployeeDedicationsMapper {
    constructor(
        @inject(ProjectEmployeeDedicationMapper)
        private readonly projectEmployeeDedicationMapper: ProjectEmployeeDedicationMapper,
    ) {}

    map(
        projectEmployeeDedicationsDto: ProjectEmployeeDedicationsDto,
    ): ProjectEmployeeDedications {
        const errors = validateSync(projectEmployeeDedicationsDto);

        if (errors.length) {
            console.error(errors);

            return new ProjectEmployeeDedications([], 0);
        }

        const dedications = projectEmployeeDedicationsDto.results.mapNotNull(
            (projectEmployeeDedicationDto) =>
                this.projectEmployeeDedicationMapper.map(
                    projectEmployeeDedicationDto,
                ),
        );

        return new ProjectEmployeeDedications(
            dedications,
            projectEmployeeDedicationsDto.count,
        );
    }
}
