import { PaginatedDto } from "@core/data/dto/paginated.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsNotEmpty,
    IsNumber,
    IsString,
} from "class-validator";

export class PublicInterestDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    organization_type!: string;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;

    @IsDateString()
    @IsNotEmpty()
    date!: string;
}

export class PublicInterestsDto extends PaginatedDto {
    @IsArray()
    @Type(() => PublicInterestDto)
    results!: PublicInterestDto[];
}
