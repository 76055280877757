import { CountryEnumMapper } from "@beneficiary/data/mappers/country-enum.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import { AddressDto, AddressesDto } from "@entity/data/dto/address/address.dto";
import { AddressMapper } from "@entity/data/mappers/address/address.mapper";
import { TitularityTypeEnumMapper } from "@entity/data/mappers/titularity-type-enum.mapper";
import {
    Address,
    Addresses,
} from "@entity/domain/models/address/address.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class AddressesMapper {
    constructor(
        @inject(AddressMapper)
        private readonly addressMapper: AddressMapper,
        @inject(CountryEnumMapper)
        private readonly countryEnumMapper: CountryEnumMapper,
        @inject(TitularityTypeEnumMapper)
        private readonly titularityTypeEnumMapper: TitularityTypeEnumMapper,
    ) {}

    map(addressesDto: AddressesDto): Addresses {
        const errors = validateSync(addressesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Addresses([], 0);
        }

        const addresses = addressesDto.results.mapNotNull((addressDto) =>
            this.addressMapper.map(addressDto),
        );

        return new Addresses(addresses, addressesDto.count);
    }

    mapAddress(addressDto: AddressDto): Nullable<Address> {
        if (!addressDto.id) {
            return null;
        }

        const country = this.countryEnumMapper.map(addressDto.country);
        if (!country) return null;

        const titularityType = this.titularityTypeEnumMapper.map(
            addressDto.titularity_type,
        );
        if (!titularityType) return null;

        const address: Address = {
            id: addressDto.id,
            name: addressDto.name,
            streetName: addressDto.street_name,
            zipCode: addressDto.zip_code,
            town: addressDto.town,
            province: addressDto.province,
            entity: addressDto.entity,
            streetNumber: addressDto.street_number,
            country,
            titularityType,
            floor: addressDto.floor ?? "",
            door: addressDto.door ?? "",
            stair: addressDto.stair ?? "",
            cadastralValue: addressDto.cadastral_value ?? "",
            local: addressDto.local ?? false,
            principalAddress: addressDto.principal_address ?? false,
        };
        return address;
    }
}
