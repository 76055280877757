import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreditorMapper } from "@entity/data/mappers/creditor/creditor.mapper";
import { Creditor } from "@entity/domain/models/creditor/creditor.model";
import { EditCreditorUseCase } from "@entity/domain/usecases/creditor/edit-creditor.usecase";
import { GetCreditorByIdUseCase } from "@entity/domain/usecases/creditor/get-creditor-by-id.usecase";
import { CreditorFormValuesValidated } from "@entity/presentation/component/creditor-form/creditor-form.component";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class EditCreditorPageViewModel extends BaseViewModel {
    creditorId: Undefinable<number> = undefined;

    @observable
    creditor: Undefinable<Creditor> = undefined;

    @observable
    initiallyLoading: boolean = false;

    constructor(
        @inject(EditCreditorUseCase)
        private readonly editCreditorUseCase: EditCreditorUseCase,
        @inject(CreditorMapper)
        private readonly creditorMapper: CreditorMapper,
        @inject(GetCreditorByIdUseCase)
        private readonly getCreditorByIdUseCase: GetCreditorByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getCreditor();

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    @action
    setCreditor(creditor: Creditor): void {
        this.creditor = creditor;
    }

    async getCreditor(): Promise<void> {
        if (!this.creditorId) throw Error("Missing creditorId");

        const creditor = await this.getCreditorByIdUseCase.execute(
            this.creditorId,
        );

        if (creditor) {
            this.setCreditor(creditor);
        }
    }

    async editCreditor(
        values: CreditorFormValuesValidated,
    ): Promise<Nullable<Creditor>> {
        if (!this.creditorId) throw Error("Missing creditorId");

        LoadLayoutStore.start();

        const editCreditorResult = await this.editCreditorUseCase.execute(
            this.creditorMapper.mapFromFormToEdit(values),
        );

        if (editCreditorResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        if (editCreditorResult) this.setCreditor(editCreditorResult);

        return editCreditorResult;
    }
}
