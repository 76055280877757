import { Nullable } from "@core/domain/types/nullable.type";
import { ExpenseDto } from "@project/data/dto/expenses/expenses.dto";
import { ExpenseAllTypes } from "@project/domain/models/expenses/expense.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { ExpenseTypeEnumDto } from "../../dto/expenses/expense-type.dto";
import { AccommodationExpenseMapper } from "./accommodation-expense.mapper";
import { InvestmentExpenseMapper } from "./investment-expense.mapper";
import { MaintenanceExpenseMapper } from "./maintenance-expense.mapper";
import { ManagementExpenseMapper } from "./management-expense.mapper";
import { PersonnelExpenseMapper } from "./personnel-expense.mapper";
import { TravelExpenseMapper } from "./travel-expense.mapper";

@injectable()
export class ExpenseMapper {
    constructor(
        @inject(InvestmentExpenseMapper)
        private readonly investmentExpenseMapper: InvestmentExpenseMapper,
        @inject(MaintenanceExpenseMapper)
        private readonly maintenanceExpenseMapper: MaintenanceExpenseMapper,
        @inject(TravelExpenseMapper)
        private readonly travelExpenseMapper: TravelExpenseMapper,
        @inject(AccommodationExpenseMapper)
        private readonly accommodationExpenseMapper: AccommodationExpenseMapper,
        @inject(ManagementExpenseMapper)
        private readonly managementExpenseMapper: ManagementExpenseMapper,
        @inject(PersonnelExpenseMapper)
        private readonly personnelExpenseMapper: PersonnelExpenseMapper,
    ) {}
    map(expenseDto: ExpenseDto): Nullable<ExpenseAllTypes> {
        const errors = validateSync(expenseDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        switch (expenseDto.type_expense) {
            case ExpenseTypeEnumDto.INVESTMENT:
                if (
                    expenseDto.investment_data &&
                    expenseDto.investment_data.length > 0
                ) {
                    return this.investmentExpenseMapper.map(
                        expenseDto,
                        expenseDto.investment_data[0],
                    );
                }
                return null;
            case ExpenseTypeEnumDto.MAINTENANCE_ACTIVITIES:
                if (
                    expenseDto.investment_data &&
                    expenseDto.investment_data.length > 0
                ) {
                    return this.maintenanceExpenseMapper.map(
                        expenseDto,
                        expenseDto.investment_data[0],
                    );
                }
                return null;
            case ExpenseTypeEnumDto.MANAGEMENT_ADMINISTRATION:
                if (
                    expenseDto.investment_data &&
                    expenseDto.investment_data.length > 0
                ) {
                    return this.managementExpenseMapper.map(
                        expenseDto,
                        expenseDto.investment_data[0],
                    );
                }
                return null;
            case ExpenseTypeEnumDto.TRAVEL:
                if (
                    expenseDto.travel_data &&
                    expenseDto.travel_data.length > 0
                ) {
                    return this.travelExpenseMapper.map(
                        expenseDto,
                        expenseDto.travel_data[0],
                    );
                }
                return null;
            case ExpenseTypeEnumDto.ACCOMMODATION_MEALS:
                if (
                    expenseDto.maintenance_data &&
                    expenseDto.maintenance_data.length > 0
                ) {
                    return this.accommodationExpenseMapper.map(
                        expenseDto,
                        expenseDto.maintenance_data[0],
                    );
                }
                return null;
            case ExpenseTypeEnumDto.PERSONNEL_MANAGEMENT_ADMINISTRATION:
                if (
                    expenseDto.management_data &&
                    expenseDto.management_data.length > 0
                ) {
                    return this.personnelExpenseMapper.map(
                        expenseDto,
                        expenseDto.management_data[0],
                    );
                }
                return null;
            default:
                return null;
        }
    }
}
