import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { MaterialRepository } from "@entity/data/repositories/material.repository";
import { inject, injectable } from "inversify";
import { Material } from "../../models/material/material.model";

@injectable()
export class GetMaterialByIdUseCase {
    constructor(
        @inject(MaterialRepository)
        private readonly materialRepository: MaterialRepository,
    ) {}

    async execute(materialId: number): Promise<Material | undefined> {
        const materialResult =
            await this.materialRepository.getMaterial(materialId);

        if (materialResult.isLeft()) {
            ToastManagerStore.error(materialResult.getLeftOrThrow().message);
        }

        return materialResult.fold(
            () => undefined,
            (material) => material,
        );
    }
}
