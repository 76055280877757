import { Nullable } from "@core/domain/types/nullable.type";
import {
    BudgetSpreadsheetColumnDto,
    BudgetSpreadsheetValueDto,
} from "@project/data/dto/budget-spreadsheet.dto";
import { BudgetSpreadsheetValue } from "@project/domain/models/budget-spreadsheet.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class BudgetSpreadsheetValueMapper {
    map(
        valueDto: BudgetSpreadsheetValueDto,
        columnDto: BudgetSpreadsheetColumnDto,
    ): Nullable<BudgetSpreadsheetValue> {
        const errors = validateSync(valueDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        return new BudgetSpreadsheetValue(
            valueDto.id,
            columnDto.id,
            valueDto.value,
        );
    }
}
