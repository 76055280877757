import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { ProjectActivityTaskRepository } from "../../../data/repositories/project-activity-task.repository";

@injectable()
export class DeleteActivityTaskUseCase {
    constructor(
        @inject(ProjectActivityTaskRepository)
        private readonly projectActivityTaskRepository: ProjectActivityTaskRepository,
    ) {}

    async delete(taskId: number): Promise<boolean> {
        const deleteResult =
            await this.projectActivityTaskRepository.delete(taskId);

        const deleted = deleteResult.isRight();

        if (!deleted)
            ToastManagerStore.error(deleteResult.getLeftOrThrow().message);

        return deleted;
    }
}
