export class ProjectAuditory {
    constructor(
        public id: number,
        public certificationEntity: string,
        public year: number,
        public auditedProject: number,
        public entity: number,
    ) {}
}

export class ProjectAuditories {
    constructor(
        public readonly projectAuditories: ProjectAuditory[],
        public readonly count: number,
    ) {}
}
