import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { PolicyProtocolRepository } from "@entity/data/repositories/policy-protocol.repository";
import { CreatePolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/create-policy-protocol.model";
import { PolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/policy-protocol.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreatePolicyProtocolUseCase {
    constructor(
        @inject(PolicyProtocolRepository)
        private readonly policyProtocolRepository: PolicyProtocolRepository,
    ) {}

    async execute(
        policyProtocol: CreatePolicyProtocol,
    ): Promise<Nullable<PolicyProtocol>> {
        const creationResult =
            await this.policyProtocolRepository.create(policyProtocol);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdPolicyProtocol) => createdPolicyProtocol,
        );
    }
}
