import { LanguageEnumDto } from "@core/data/dto/language.dto";
import { IsEnum, IsOptional } from "class-validator";

export interface UpdateCurrentLanguageBody {
    language: LanguageEnumDto;
}

export class CurrentLanguageDto {
    @IsEnum(LanguageEnumDto)
    @IsOptional()
    language!: LanguageEnumDto;
}
