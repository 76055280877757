import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { inject, injectable } from "inversify";
import { FinancialEntity } from "../../models/financial-entity/financial-entities.model";

@injectable()
export class GetFinancialEntityByIdUseCase {
    constructor(
        @inject(FinancialEntityRepository)
        private readonly financialEntityRepository: FinancialEntityRepository,
    ) {}

    async execute(
        financialEntityId: number,
    ): Promise<Nullable<FinancialEntity>> {
        const financialEntityResult =
            await this.financialEntityRepository.getById(financialEntityId);

        if (financialEntityResult.isLeft()) {
            ToastManagerStore.error(
                financialEntityResult.getLeftOrThrow().message,
            );
        }

        return financialEntityResult.fold(
            () => null,
            (financialEntity) => financialEntity,
        );
    }
}
