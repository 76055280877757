import { Pagination } from "@core/domain/models/pagination";
import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { FinancialEntityType } from "@project/domain/models/financial-entity/financial-entity-type";
import type { ProjectSearchFilters } from "@project/domain/models/project-search-filters";
import { ProjectsSummary } from "@project/domain/models/projects-summary.model";
import { Status } from "@project/domain/models/status.model";
import { ExportProjectsUseCase } from "@project/domain/usecases/export-projects.usecase";
import { GetAllFinancialEntityTypesUsecase } from "@project/domain/usecases/financial-entity/get-all-financial-entity-types.usecase";
import { GetAllStatusUseCase } from "@project/domain/usecases/get-all-status.usecase";
import { SearchProjectsByUseCase } from "@project/domain/usecases/search-projects-by.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

export interface ProjectListTable {
    id: number;
    name: string;
    financialEntitiesList: string;
    status: string;
}

export interface ProjectsListTable {
    projects: ProjectListTable[];
    count: number;
}

@injectable()
export class ProjectListPageViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    initialLoading: boolean = true;

    @observable
    private _projects: ProjectsSummary = new ProjectsSummary([], 0);

    @computed
    get projectsTable(): ProjectsListTable {
        return {
            count: this._projects.count,
            projects: this._projects.projectsSummary.map((projectSummary) => ({
                id: projectSummary.id,
                name: projectSummary.name,
                financialEntitiesList:
                    projectSummary.financialEntityTypeLabels.join(", "),
                status: projectSummary.statusLabel,
            })),
        };
    }

    @observable
    status: Status[] = [];

    @observable
    globalEntities: GlobalEntity[] = [];

    @observable
    financialEntityTypes: FinancialEntityType[] = [];

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: ProjectSearchFilters = {
        name: "",
        financialEntityType: null,
        statusProject: null,
        activeFromDate: null,
        activeToDate: null,
        continuity: null,
    };

    @observable
    filters: ProjectSearchFilters = this.initialFiltersValue;

    constructor(
        @inject(SearchProjectsByUseCase)
        private readonly searchProjectsByUseCase: SearchProjectsByUseCase,
        @inject(GetAllStatusUseCase)
        private readonly getAllStatusUseCase: GetAllStatusUseCase,
        @inject(GetAllFinancialEntityTypesUsecase)
        private readonly getFinancialEntityTypesUseCase: GetAllFinancialEntityTypesUsecase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
        @inject(ExportProjectsUseCase)
        private readonly exportProjectsUseCase: ExportProjectsUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(
            Promise.all([
                this.searchProjectsBy(this.filters, this.pagination),
                this.getAllStatus(),
                this.getAllGlobalEntities(),
                this.getFinancialEntityTypes(),
            ]),
        );

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async exportProjects(): Promise<void> {
        if (!this.entityId) throw Error("Missing entityId");

        LoadLayoutStore.start();

        const blob = await this.exportProjectsUseCase.execute(this.entityId);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Proyectos.csv`);
        document.body.appendChild(link);
        link.click();

        LoadLayoutStore.finish();
    }

    @action
    setFilters(filters: ProjectSearchFilters): void {
        this.filters = filters;
        this.pagination.reset();

        this.searchProjectsBy(this.filters, this.pagination);
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.searchProjectsBy(this.filters, this.pagination);
    }

    @flow
    async *getAllStatus(): AsyncGenerator<void> {
        const status = await this.getAllStatusUseCase.execute();

        runInAction(() => {
            this.status = status;
        });
    }

    @flow
    async *getAllGlobalEntities(): AsyncGenerator<void> {
        const globalEntities = await this.getAllGlobalEntitiesUseCase.execute();

        runInAction(() => {
            this.globalEntities = globalEntities;
        });
    }

    @flow
    async *getFinancialEntityTypes(): AsyncGenerator<void> {
        const financialEntityTypes =
            await this.getFinancialEntityTypesUseCase.execute();

        runInAction(() => {
            this.financialEntityTypes = financialEntityTypes;
        });
    }

    @flow
    async *searchProjectsBy(
        filters: ProjectSearchFilters,
        pagination: Pagination,
    ): AsyncGenerator<void> {
        const projects = await this.searchProjectsByUseCase.execute(
            pagination,
            filters,
        );
        runInAction(() => {
            this._projects = projects;
        });
    }
}
