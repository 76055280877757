import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { UserDto } from "@user/data/dto/user.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsNotEmpty,
    IsNumber,
    IsString,
} from "class-validator";

export class InternalNoteDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    note!: string;

    @IsNumber()
    @IsNotEmpty()
    beneficiary!: number;

    @IsNotEmpty()
    @Type(() => UserDto)
    user_data!: UserDto;

    @IsDateString()
    @IsNotEmpty()
    modified!: string;
}

export class InternalNotesDto extends PaginatedDto {
    @IsArray()
    @Type(() => InternalNoteDto)
    results!: InternalNoteDto[];
}

export type InternalNotesQuery = PaginatedQuery & {
    beneficiary?: number;
};
