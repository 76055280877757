import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { Type } from "class-transformer";
import { IsArray, IsNotEmpty, IsNumber, IsString } from "class-validator";

export class ActivitySummaryDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    description!: string;
}

export class ActivitiesSummaryDto extends PaginatedDto {
    @IsArray()
    @Type(() => ActivitySummaryDto)
    results!: ActivitySummaryDto[];
}

export type ActivitiesSummaryQuery = PaginatedQuery & {
    project?: Undefinable<number>;
};
