import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { EditFinancialEntityBudgetModel } from "@project/domain/models/budget/edit-financial-entity-budget.model";
import { FinancialEntityBudget } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditBudgetUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(
        budget: EditFinancialEntityBudgetModel,
    ): Promise<Nullable<FinancialEntityBudget>> {
        const editResult = await this.budgetRepository.edit(budget);

        return editResult.fold(
            (error) => {
                ToastManagerStore.error(error.message);
                return null;
            },
            (editedBudget) => editedBudget,
        );
    }
}
