import { TherapyTypeEnumDto } from "@beneficiary/data/dto/therapy-type-enum.dto";
import { TherapyTypeEnum } from "@beneficiary/domain/models/therapy-type.enum";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";

@injectable()
export class TherapyTypeEnumMapper {
    map(relationshipIdDto: TherapyTypeEnumDto): Nullable<TherapyTypeEnum> {
        switch (relationshipIdDto) {
            case TherapyTypeEnumDto.MedicalTherapies:
                return TherapyTypeEnum.MedicalTherapies;
            case TherapyTypeEnumDto.MusicTherapy:
                return TherapyTypeEnum.MusicTherapy;
            case TherapyTypeEnumDto.OccupationalTherapy:
                return TherapyTypeEnum.OccupationalTherapy;
            case TherapyTypeEnumDto.PsychologicalTherapies:
                return TherapyTypeEnum.PsychologicalTherapies;
            case TherapyTypeEnumDto.OtherTherapy:
                return TherapyTypeEnum.OtherTherapy;
            default:
                return null;
        }
    }

    mapToDto(relationshipIdDto: TherapyTypeEnum): TherapyTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (relationshipIdDto) {
            case TherapyTypeEnum.MedicalTherapies:
                return TherapyTypeEnumDto.MedicalTherapies;
            case TherapyTypeEnum.MusicTherapy:
                return TherapyTypeEnumDto.MusicTherapy;
            case TherapyTypeEnum.OccupationalTherapy:
                return TherapyTypeEnumDto.OccupationalTherapy;
            case TherapyTypeEnum.PsychologicalTherapies:
                return TherapyTypeEnumDto.PsychologicalTherapies;
            case TherapyTypeEnum.OtherTherapy:
                return TherapyTypeEnumDto.OtherTherapy;
        }
    }
}
