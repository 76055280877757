import { CreateProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/create-project-auditory.model";
import { ProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/project-auditory.model";

export class EditProjectAuditory extends CreateProjectAuditory {
    constructor(
        public readonly id: ProjectAuditory["id"],
        public override certificationEntity: CreateProjectAuditory["certificationEntity"],
        public override year: CreateProjectAuditory["year"],
        public override auditedProject: CreateProjectAuditory["auditedProject"],
        public override entity: CreateProjectAuditory["entity"],
    ) {
        super(certificationEntity, year, auditedProject, entity);
    }
}
