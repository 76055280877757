import { CountryEnumDto } from "@core/data/dto/country.dto";
import { PaginatedDto } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { TitularityTypeEnumDto } from "@entity/data/dto/titularity-type.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class AddressDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    street_name!: string;

    @IsString()
    @IsOptional()
    street_number!: string;

    @IsString()
    @IsOptional()
    floor!: Nullable<string>;

    @IsString()
    @IsOptional()
    door!: Nullable<string>;

    @IsString()
    @IsOptional()
    stair!: Nullable<string>;

    @IsString()
    @IsOptional()
    cadastral_value!: Nullable<string>;

    @IsString()
    @IsOptional()
    region!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    zip_code!: string;

    @IsString()
    @IsNotEmpty()
    town!: string;

    @IsString()
    @IsNotEmpty()
    province!: string;

    @IsBoolean()
    @IsOptional()
    local!: Nullable<boolean>;

    @IsEnum(CountryEnumDto)
    @IsNotEmpty()
    country!: CountryEnumDto;

    @IsEnum(TitularityTypeEnumDto)
    @IsNotEmpty()
    titularity_type!: TitularityTypeEnumDto;

    @IsBoolean()
    @IsOptional()
    principal_address!: Nullable<boolean>;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class AddressesDto extends PaginatedDto {
    @IsArray()
    @Type(() => AddressDto)
    results!: AddressDto[];
}
