import { EducationLevelRepository } from "@core/data/repositories/education-level.repository";
import { EducationLevel } from "@core/domain/models/education-level.model";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllEducationLevelsUseCase {
    constructor(
        @inject(EducationLevelRepository)
        private readonly educationLevelRepository: EducationLevelRepository,
    ) {}

    async execute(): Promise<EducationLevel[]> {
        const educationLevelsResult =
            await this.educationLevelRepository.getAll();

        if (educationLevelsResult.isLeft()) {
            ToastManagerStore.error(
                educationLevelsResult.getLeftOrThrow().message,
            );
        }

        return educationLevelsResult.fold(
            () => [],
            (educationLevels) => educationLevels,
        );
    }
}
