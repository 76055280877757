import { inject, injectable } from "inversify";
import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { Category } from "@entity/domain/models/category.model";

@injectable()
export class GetAllCategoriesUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}

    async execute(): Promise<Category[]> {
        const getAllCategoriesResult =
            await this.entityRepository.getAllCategories();

        return getAllCategoriesResult.fold(
            () => [],
            (categories) => categories,
        );
    }
}
