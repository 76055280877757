import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum CostPeriodicityTypeEnumDto {
    ANNUAL = "annual",
    WEEKLY = "weekly",
    JOURNEY = "journey",
    SESSION = "session",
    MONTHLY = "monthly",
}

export class CostPeriodicityTypeDto {
    @IsEnum(CostPeriodicityTypeEnumDto)
    @IsNotEmpty()
    key!: CostPeriodicityTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
