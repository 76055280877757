import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateProjectAuditoryMapper } from "@entity/data/mappers/quality-framework/project-auditory/create-project-auditory.mapper";
import { EditProjectAuditoryMapper } from "@entity/data/mappers/quality-framework/project-auditory/edit-project-auditory.mapper";
import { ProjectSummary } from "@entity/domain/models/project-summary.model";
import { ProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/project-auditory.model";
import { CreateProjectAuditoryUseCase } from "@entity/domain/usecases/quality-framework/project-auditory/create-project-auditory.usecase";
import { DeleteProjectAuditoryUseCase } from "@entity/domain/usecases/quality-framework/project-auditory/delete-project-auditory.usecase";
import { EditProjectAuditoryUseCase } from "@entity/domain/usecases/quality-framework/project-auditory/edit-project-auditory.usecase";
import { GetAllProjectAuditoriesUsecase } from "@entity/domain/usecases/quality-framework/project-auditory/get-all-project-auditories.usecase";
import { ProjectAuditoryFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/project-auditory/form/project-auditory-form";
import { GetAllProjectsUseCase } from "@project/domain/usecases/get-all-projects.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class ProjectAuditoryViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    projectAuditories: ProjectAuditory[] = [];

    @observable
    projectAuditoryToEdit: Undefinable<ProjectAuditory> = undefined;

    @observable
    projects: ProjectSummary[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddProjectAuditoryModal: boolean = false;

    @observable
    showEditProjectAuditoryModal: boolean = false;

    constructor(
        @inject(GetAllProjectAuditoriesUsecase)
        private readonly getAllProjectAuditoriesUsecase: GetAllProjectAuditoriesUsecase,
        @inject(CreateProjectAuditoryUseCase)
        private readonly createProjectAuditoryUseCase: CreateProjectAuditoryUseCase,
        @inject(EditProjectAuditoryUseCase)
        private readonly editProjectAuditoryUseCase: EditProjectAuditoryUseCase,
        @inject(DeleteProjectAuditoryUseCase)
        private readonly deleteProjectAuditoryUseCase: DeleteProjectAuditoryUseCase,
        @inject(CreateProjectAuditoryMapper)
        private readonly createProjectAuditoryMapper: CreateProjectAuditoryMapper,
        @inject(EditProjectAuditoryMapper)
        private readonly editProjectAuditoryMapper: EditProjectAuditoryMapper,
        @inject(GetAllProjectsUseCase)
        private readonly getAllProjectsUseCase: GetAllProjectsUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getProjectAuditories(), this.getAllProjects()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getProjectAuditories(): Promise<void> {
        const projectAuditories =
            await this.getAllProjectAuditoriesUsecase.execute();
        runInAction(() => {
            this.projectAuditories = projectAuditories;
        });
    }

    @action
    setShowAddProjectAuditoryModal(show: boolean): void {
        this.showAddProjectAuditoryModal = show;
    }

    @action
    closeEditProjectAuditoryModal(): void {
        this.showEditProjectAuditoryModal = false;
    }

    @action
    openEditProjectAuditoryModal(
        projectAuditory: Undefinable<ProjectAuditory>,
    ): void {
        this.projectAuditoryToEdit = projectAuditory;
        this.showEditProjectAuditoryModal = true;
    }

    async addProjectAuditory(
        entityId: Undefinable<number>,
        values: ProjectAuditoryFormValuesValidated,
    ): Promise<Nullable<ProjectAuditory>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createProjectAuditoryResult =
            await this.createProjectAuditoryUseCase.execute(
                this.createProjectAuditoryMapper.mapFromFormValues(
                    entityId,
                    values,
                ),
            );

        if (createProjectAuditoryResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createProjectAuditoryResult;
    }

    async editProjectAuditory(
        values: ProjectAuditoryFormValuesValidated,
    ): Promise<void> {
        if (
            !this.projectAuditoryToEdit?.id ||
            !this.projectAuditoryToEdit.entity
        )
            throw Error("Missing projectAuditory");

        LoadLayoutStore.start();

        const editProjectAuditoryResult =
            await this.editProjectAuditoryUseCase.execute(
                this.editProjectAuditoryMapper.mapFromModal(
                    this.projectAuditoryToEdit.id,
                    this.projectAuditoryToEdit.entity,
                    values,
                ),
            );

        if (editProjectAuditoryResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadProjectAuditories(): Promise<void> {
        await this.getProjectAuditories();
    }

    async deleteProjectAuditory(projectAuditoryId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteProjectAuditoryUseCase.execute(projectAuditoryId);
        LoadLayoutStore.finish();
    }

    async getAllProjects(): Promise<void> {
        const projects = await this.getAllProjectsUseCase.execute();

        runInAction(() => {
            this.projects = projects;
        });
    }
}
