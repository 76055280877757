import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { Nullish } from "@core/domain/types/nullish.type";
import { Type } from "class-transformer";
import { IsArray } from "class-validator";
import { UserRoleEnumDto } from "./user-role.dto";
import { UserDto } from "./user.dto";

export class UsersDto extends PaginatedDto {
    @IsArray()
    @Type(() => UserDto)
    results!: UserDto[];
}

export type UsersSummaryQuery = PaginatedQuery & {
    search?: Nullish<string>;
    rol?: Nullish<UserRoleEnumDto>;
    entities?: Nullish<string>;
    is_active?: Nullish<boolean>;
};
