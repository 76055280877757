import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteBudgetValidationUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}

    async execute(id: number): Promise<boolean> {
        const budgetValidationsResult =
            await this.budgetRepository.deleteValidation(id);

        return budgetValidationsResult.isRight();
    }
}
