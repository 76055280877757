import { MaterialFormValues } from "@entity/presentation/component/material-form/add-resource/material-project-form";
import { EditProjectMaterialBody } from "@project/data/dto/materials/edit-project-material.dto";
import { EditProjectMaterial } from "@project/domain/models/project-material/edit-project-material.model";
import { ProjectMaterial } from "@project/domain/models/project-material/project-material.model";
import { MaterialFormValuesValidated } from "@project/presentation/components/material-resources/form/material-form";
import { injectable } from "inversify";

@injectable()
export class EditProjectMaterialMapper {
    mapToDto(projectMaterial: ProjectMaterial): EditProjectMaterialBody {
        return {
            id: projectMaterial.id,
            hours_dedicated: projectMaterial.hoursDedicated?.toString() ?? null,
            project: projectMaterial.projectId,
            material: projectMaterial.materialId,
            documents: projectMaterial.documents
                ? projectMaterial.documents.map((document) => document.id)
                : null,
            material_data: projectMaterial.materialData ?? null,
            start_date: projectMaterial.startDate
                ? projectMaterial.startDate.toISODate()
                : null,
            end_date: projectMaterial.endDate
                ? projectMaterial.endDate.toISODate()
                : null,
        };
    }

    mapFromProjectFormValues(
        id: number,
        projectId: number,
        projectMaterialForm: MaterialFormValuesValidated,
    ): EditProjectMaterial {
        if (!id) throw Error("Missing income id");

        const editProjectMaterial: EditProjectMaterial =
            new EditProjectMaterial(
                id,
                projectId,
                projectMaterialForm.materialId,
                projectMaterialForm.hoursDedicated,
                projectMaterialForm.documents,
                projectMaterialForm.startDate,
                projectMaterialForm.endDate,
            );
        return editProjectMaterial;
    }

    mapFromEntityFormValues(
        projectMaterialForm: MaterialFormValues,
    ): EditProjectMaterial {
        if (!projectMaterialForm.id) throw Error("Missing income id");
        if (!projectMaterialForm.projectId) throw Error("Missing project id");

        const editProjectMaterial: EditProjectMaterial =
            new EditProjectMaterial(
                projectMaterialForm.id,
                projectMaterialForm.projectId,
                projectMaterialForm.materialId,
                projectMaterialForm.hoursDedicated,
                projectMaterialForm.documents,
                projectMaterialForm.startDate,
                projectMaterialForm.endDate,
            );
        return editProjectMaterial;
    }
}
