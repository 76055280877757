import { BeneficiarySummary } from "@beneficiary/domain/models/beneficiary-summary.model";
import { GetAllBeneficiariesUseCase } from "@beneficiary/domain/usecases/get-all-beneficiaries.usecase";
import { CreateDocument } from "@core/domain/models/create-document.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { CreateDocumentUseCase } from "@core/domain/usecases/create-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Employee } from "@entity/domain/models/employee/employee.model";
import { GetAllEmployeesByUseCase } from "@entity/domain/usecases/employee/get-all-employees-by.usecase";
import { Status } from "@proceeding/domain/models/status.model";
import { GetAllStatusUseCase } from "@proceeding/domain/usecases/get-all-status.usecase";
import { ActivitySummary } from "@project/domain/models/activity-summary.model";
import { ProjectSummary } from "@project/domain/models/project-summary.model";
import { GetAllProjectsUseCase } from "@project/domain/usecases/get-all-projects.usecase";
import { GetAllProjectActivitiesSummaryByUseCase } from "@project/domain/usecases/project-activity/get-all-summary-activities-by.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class GeneralTabViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    activitiesLoading: boolean = false;

    @observable
    beneficiaries: BeneficiarySummary[] = [];

    @observable
    projects: ProjectSummary[] = [];

    @observable
    activities: ActivitySummary[] = [];

    @observable
    employees: Employee[] = [];

    @observable
    statuses: Status[] = [];

    @observable
    derivedFromModalOpen: boolean = false;

    constructor(
        @inject(CreateDocumentUseCase)
        private readonly createDocumentUseCase: CreateDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
        @inject(GetAllBeneficiariesUseCase)
        private readonly getAllBeneficiariesUseCase: GetAllBeneficiariesUseCase,
        @inject(GetAllProjectsUseCase)
        private readonly getAllProjectsUseCase: GetAllProjectsUseCase,
        @inject(GetAllEmployeesByUseCase)
        private readonly getAllEmployeesByUseCase: GetAllEmployeesByUseCase,
        @inject(GetAllStatusUseCase)
        private readonly getAllStatusesUseCase: GetAllStatusUseCase,
        @inject(GetAllProjectActivitiesSummaryByUseCase)
        private readonly getAllProjectActivitiesSummaryByUseCase: GetAllProjectActivitiesSummaryByUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllBeneficiaries(),
            this.getAllProjects(),
            this.getAllEmployees(),
            this.getAllProceedingStatuses(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllProceedingStatuses(): Promise<void> {
        const statuses = await this.getAllStatusesUseCase.execute();

        runInAction(() => {
            this.statuses = statuses;
        });
    }

    async getAllBeneficiaries(): Promise<void> {
        const beneficiaries = await this.getAllBeneficiariesUseCase.execute();

        runInAction(() => {
            this.beneficiaries = beneficiaries;
        });
    }

    async getAllProjects(): Promise<void> {
        const projects = await this.getAllProjectsUseCase.execute({
            isActive: true,
        });

        runInAction(() => {
            this.projects = projects;
        });
    }

    async getAllActivitiesInProject(projectId: number): Promise<void> {
        this.activitiesLoading = true;
        const activities =
            await this.getAllProjectActivitiesSummaryByUseCase.execute(
                projectId,
            );

        runInAction(() => {
            this.activities = activities;
            this.activitiesLoading = false;
        });
    }

    async getAllEmployees(): Promise<void> {
        const employees = await this.getAllEmployeesByUseCase.execute();

        runInAction(() => {
            this.employees = employees;
        });
    }

    @action
    clearActivities(): void {
        this.activities = [];
    }

    async uploadDocument(
        createDocument: CreateDocument,
    ): Promise<Nullable<IncDocument>> {
        LoadLayoutStore.start();

        const document =
            await this.createDocumentUseCase.execute(createDocument);

        if (document) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return document;
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(documentUrl);

        LoadLayoutStore.finish();
    }

    @action
    setDerivedFromModalOpen(open: boolean): void {
        this.derivedFromModalOpen = open;
    }
}
