import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { FinancialEntity } from "@project/domain/models/financial-entity/financial-entities.model";
import { GetFinancialEntityByIdUseCase } from "@project/domain/usecases/financial-entity/get-financial-entity-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class DetailFinancialEntityPageViewModel extends BaseViewModel {
    @observable
    financialEntityId: number = 0;

    @observable
    financialEntity: Undefinable<FinancialEntity> = undefined;

    @observable
    initialLoading: boolean = true;

    constructor(
        @inject(GetFinancialEntityByIdUseCase)
        private readonly getFinancialEntityByIdUseCase: GetFinancialEntityByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getFinancialEntityById();

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setFinancialEntityId(financialEntityId: number): void {
        this.financialEntityId = financialEntityId;
    }

    async getFinancialEntityById(): Promise<void> {
        const financialEntity =
            await this.getFinancialEntityByIdUseCase.execute(
                this.financialEntityId,
            );

        if (financialEntity) {
            runInAction(() => {
                this.financialEntity = financialEntity;
            });
        }
    }
}
