import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { SupplierRepository } from "@entity/data/repositories/supplier.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteSupplierUseCase {
    constructor(
        @inject(SupplierRepository)
        private readonly supplierRepository: SupplierRepository,
    ) {}

    async execute(supplierId: number): Promise<void> {
        const result = await this.supplierRepository.delete(supplierId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        ToastManagerStore.success();
    }
}
