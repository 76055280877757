import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { FinancialEntityTypeEnum } from "../../../domain/models/financial-entity/financial-entity-type";
import { FinancialEntityTypeEnumDto } from "../../dto/financial-entity/financial-entity-type.dto";

@injectable()
export class FinancialEntityTypeEnumMapper {
    map(
        financialEntityTypeEnum: FinancialEntityTypeEnumDto,
    ): Nullable<FinancialEntityTypeEnum> {
        switch (financialEntityTypeEnum) {
            case FinancialEntityTypeEnumDto.ASSOCIATION:
                return FinancialEntityTypeEnum.ASSOCIATION;
            case FinancialEntityTypeEnumDto.CCAA:
                return FinancialEntityTypeEnum.CCAA;
            case FinancialEntityTypeEnumDto.BANK_ENTITY:
                return FinancialEntityTypeEnum.BANK_ENTITY;
            case FinancialEntityTypeEnumDto.IRPF:
                return FinancialEntityTypeEnum.IRPF;
            case FinancialEntityTypeEnumDto.ADMIN_LOCAL:
                return FinancialEntityTypeEnum.ADMIN_LOCAL;
            case FinancialEntityTypeEnumDto.ADMIN_REGIONAL:
                return FinancialEntityTypeEnum.ADMIN_REGIONAL;
            case FinancialEntityTypeEnumDto.OWN:
                return FinancialEntityTypeEnum.OWN;
            case FinancialEntityTypeEnumDto.PRIVATE_ENTITY:
                return FinancialEntityTypeEnum.PRIVATE_ENTITY;
            case FinancialEntityTypeEnumDto.ADMIN_INTERNATIONAL:
                return FinancialEntityTypeEnum.ADMIN_INTERNATIONAL;
            case FinancialEntityTypeEnumDto.ADMIN_NATIONAL:
                return FinancialEntityTypeEnum.ADMIN_NATIONAL;
            case FinancialEntityTypeEnumDto.GENERIC_ENTITY:
                return FinancialEntityTypeEnum.GENERIC_ENTITY;
            default:
                return null;
        }
    }

    mapToDto(
        financialEntityTypeEnum: FinancialEntityTypeEnum,
    ): FinancialEntityTypeEnumDto {
        switch (financialEntityTypeEnum) {
            case FinancialEntityTypeEnum.ASSOCIATION:
                return FinancialEntityTypeEnumDto.ASSOCIATION;
            case FinancialEntityTypeEnum.CCAA:
                return FinancialEntityTypeEnumDto.CCAA;
            case FinancialEntityTypeEnum.BANK_ENTITY:
                return FinancialEntityTypeEnumDto.BANK_ENTITY;
            case FinancialEntityTypeEnum.IRPF:
                return FinancialEntityTypeEnumDto.IRPF;
            case FinancialEntityTypeEnum.ADMIN_LOCAL:
                return FinancialEntityTypeEnumDto.ADMIN_LOCAL;
            case FinancialEntityTypeEnum.ADMIN_REGIONAL:
                return FinancialEntityTypeEnumDto.ADMIN_REGIONAL;
            case FinancialEntityTypeEnum.OWN:
                return FinancialEntityTypeEnumDto.OWN;
            case FinancialEntityTypeEnum.PRIVATE_ENTITY:
                return FinancialEntityTypeEnumDto.PRIVATE_ENTITY;
            case FinancialEntityTypeEnum.ADMIN_INTERNATIONAL:
                return FinancialEntityTypeEnumDto.ADMIN_INTERNATIONAL;
            case FinancialEntityTypeEnum.ADMIN_NATIONAL:
                return FinancialEntityTypeEnumDto.ADMIN_NATIONAL;
            case FinancialEntityTypeEnum.GENERIC_ENTITY:
                return FinancialEntityTypeEnumDto.GENERIC_ENTITY;
            default:
                throw new Error("Invalid financial entity type enum value");
        }
    }
}
