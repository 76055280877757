import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { BudgetColumns } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllBudgetColumnsByBudgetIdUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(budgetId: number): Promise<BudgetColumns> {
        const budgetColumnsResult =
            await this.budgetRepository.getColumnsByBudgetId(budgetId);

        return budgetColumnsResult.fold(
            () => new BudgetColumns([], 0),
            (budgetColumns) => budgetColumns,
        );
    }
}
