import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { IsNotEmpty, IsNumber, IsOptional, IsString } from "class-validator";

export type PaginatedQueryDto = {
    limit?: Undefinable<number>;
    offset?: Undefinable<number>;
};

/**
 * Received from backend
 */
export class PaginatedDto {
    @IsNumber()
    @IsNotEmpty()
    count!: number;

    @IsString()
    @IsOptional()
    next!: Nullable<string>;

    @IsString()
    @IsOptional()
    previous!: Nullable<string>;
}

/**
 * To send to backend
 */
export type PaginatedQuery = {
    limit?: Undefinable<number>;
    offset?: Undefinable<number>;
};
