import { IncError } from "@core/domain/errors/inc.error";
import i18n from "@i18n";

export class FallbackError extends IncError {
    // this is hack to prevent duck typing and ensure that this class is not
    // assignable to any other class that matches the structure
    private readonly __kind: "FallbackError" = "FallbackError" as const;

    constructor() {
        super(i18n.t("error:core.fallbackErrorMessage"));

        Object.setPrototypeOf(this, FallbackError.prototype);
    }
}
