import { DependenceGrade } from "@beneficiary/domain/models/dependence-grade.enum";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { BeneficiaryRepository } from "../../data/repositories/beneficiary.repository";

@injectable()
export class GetDependenceGradesUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(): Promise<DependenceGrade[]> {
        const dependenceGradesResult =
            await this.beneficiaryRepository.getAllDependenceGrades();

        if (dependenceGradesResult.isLeft()) {
            ToastManagerStore.error(
                dependenceGradesResult.getLeftOrThrow().message,
            );
        }

        return dependenceGradesResult.fold(
            () => [],
            (dependenceGrade) => dependenceGrade,
        );
    }
}
