import { Employees } from "@entity/domain/models/employee/employee.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { EmployeesDto } from "../../dto/employee/employees.dto";
import { EmployeeMapper } from "./employee.mapper";

@injectable()
export class EmployeesMapper {
    constructor(
        @inject(EmployeeMapper)
        private readonly employeeMapper: EmployeeMapper,
    ) {}

    map(employeesDto: EmployeesDto): Employees {
        const errors = validateSync(employeesDto);

        if (errors.length) {
            console.error(errors);

            return new Employees([], 0);
        }

        const employees = employeesDto.results.mapNotNull((employeeDto) =>
            this.employeeMapper.map(employeeDto),
        );

        return new Employees(employees, employeesDto.count);
    }
}
