import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { PolicyProtocolTypeDto } from "@entity/data/dto/policy-protocol-type-enum.dto";
import { CreatePolicyProtocolBody } from "@entity/data/dto/quality-framework/policy-protocol/create-policy-protocol.body";
import { EditPolicyProtocolBody } from "@entity/data/dto/quality-framework/policy-protocol/edit-policy-protocol.body";
import {
    PolicyProtocolDto,
    PolicyProtocolsDto,
} from "@entity/data/dto/quality-framework/policy-protocol/policy-protocol.dto";
import { PolicyProtocolTypeMapper } from "@entity/data/mappers/policy-protocol-type.mapper";
import { CreatePolicyProtocolMapper } from "@entity/data/mappers/quality-framework/policy-protocol/create-policy-protocol.mapper";
import { EditPolicyProtocolMapper } from "@entity/data/mappers/quality-framework/policy-protocol/edit-policy-protocol.mapper";
import { PolicyProtocolsMapper } from "@entity/data/mappers/quality-framework/policy-protocol/policy-protocols.mapper";
import { PolicyProtocolType } from "@entity/domain/models/policy-protocol-type.model";
import { CreatePolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/create-policy-protocol.model";
import { EditPolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/edit-policy-protocol.model";
import {
    PolicyProtocol,
    PolicyProtocols,
} from "@entity/domain/models/quality-framework/policy-protocol/policy-protocol.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const ENTITY_POLICY_PROTOCOLS_PATH = "/entities_policy_protocols/";

@injectable()
export class PolicyProtocolDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(PolicyProtocolsMapper)
        private readonly policyProtocolsMapper: PolicyProtocolsMapper,
        @inject(CreatePolicyProtocolMapper)
        private readonly createPolicyProtocolMapper: CreatePolicyProtocolMapper,
        @inject(EditPolicyProtocolMapper)
        private readonly editPolicyProtocolMapper: EditPolicyProtocolMapper,
        @inject(PolicyProtocolTypeMapper)
        private readonly policyProtocolTypeMapper: PolicyProtocolTypeMapper,
    ) {}

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, PolicyProtocols>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const policyProtocolsResult = await this.http.get<PolicyProtocolDto>(
            ENTITY_POLICY_PROTOCOLS_PATH,
            {
                query,
            },
        );

        return policyProtocolsResult.map((response) =>
            this.policyProtocolsMapper.map(
                plainToClass(PolicyProtocolsDto, response.data),
            ),
        );
    }

    async create(
        newPolicyProtocol: CreatePolicyProtocol,
    ): Promise<Either<ValidationError | FallbackError, PolicyProtocol>> {
        const policyProtocolBody =
            this.createPolicyProtocolMapper.mapToCreateDto(newPolicyProtocol);

        const policyProtocolResult = await this.http.post<
            PolicyProtocolDto,
            CreatePolicyProtocolBody
        >(ENTITY_POLICY_PROTOCOLS_PATH, policyProtocolBody);

        return policyProtocolResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const policyProtocol =
                    this.policyProtocolsMapper.mapPolicyProtocol(
                        plainToClass(PolicyProtocolDto, response.data),
                    );

                if (!policyProtocol) return Either.Left(new FallbackError());

                return Either.Right(policyProtocol);
            });
    }

    async edit(
        editPolicyProtocol: EditPolicyProtocol,
    ): Promise<Either<ValidationError | FallbackError, PolicyProtocol>> {
        const editedPolicyProtocol =
            this.editPolicyProtocolMapper.mapToDto(editPolicyProtocol);

        const editPolicyProtocolResult = await this.http.put<
            PolicyProtocolDto,
            EditPolicyProtocolBody
        >(
            `${ENTITY_POLICY_PROTOCOLS_PATH}${editPolicyProtocol.id}/`,
            editedPolicyProtocol,
        );

        return editPolicyProtocolResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const policyProtocol =
                    this.policyProtocolsMapper.mapPolicyProtocol(
                        plainToClass(PolicyProtocolDto, response.data),
                    );

                if (!policyProtocol) return Either.Left(new FallbackError());

                return Either.Right(policyProtocol);
            });
    }

    async delete(
        policyProtocolId: number,
    ): Promise<Either<FallbackError, true>> {
        const deletePolicyProtocol = await this.http.delete(
            `${ENTITY_POLICY_PROTOCOLS_PATH}${policyProtocolId}`,
        );

        return deletePolicyProtocol
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }

    async fetchAllPolicyProtocolTypes(): Promise<
        Either<FallbackError, PolicyProtocolType[]>
    > {
        const responseResult = await this.http.get<PolicyProtocolTypeDto[]>(
            `${ENTITY_POLICY_PROTOCOLS_PATH}policies_protocols/`,
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((policyProtocolTypeDto) =>
                    this.policyProtocolTypeMapper.map(
                        plainToClass(
                            PolicyProtocolTypeDto,
                            policyProtocolTypeDto,
                        ),
                    ),
                ),
            );
    }
}
