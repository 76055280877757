import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ActivitySearchFilters } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { ProjectActivityRepository } from "@project/data/repositories/technical-proposal/activity.repository";
import { inject, injectable } from "inversify";
import { Activity } from "../../models/technical-proposals.model";

@injectable()
export class GetAllActivitiesByUseCase {
    constructor(
        @inject(ProjectActivityRepository)
        private readonly projectActivityRepository: ProjectActivityRepository,
    ) {}

    async execute(filters: ActivitySearchFilters): Promise<Activity[]> {
        const activitiesResult =
            await this.projectActivityRepository.getAllBy(filters);

        if (activitiesResult.isLeft()) {
            ToastManagerStore.error(activitiesResult.getLeftOrThrow().message);
        }

        return activitiesResult.fold(
            () => [],
            (activities) => activities,
        );
    }
}
