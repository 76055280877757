import { Undefinable } from "@core/domain/types/undefinable.type";
import { QualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/quality-certificate.model";

export class CreateQualityCertificate {
    constructor(
        public qualityCertificateType: QualityCertificate["qualityCertificateType"],
        public entity: QualityCertificate["entity"],
        public year: QualityCertificate["year"],
        public expirationDate?: Undefinable<
            QualityCertificate["expirationDate"]
        >,
        public otherQualityCertificate?: Undefinable<string>,
    ) {}
}
