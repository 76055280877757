import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import {
    BudgetSpreadsheetCategoryDto,
    BudgetSpreadsheetColumnDto,
} from "@project/data/dto/budget-spreadsheet.dto";
import { BudgetSpreadsheetGroupMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-group.mapper";
import { BudgetSpreadsheetItemMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-item.mapper";
import { BudgetSpreadsheetValidationMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-validation.mapper";
import {
    BudgetSpreadsheetCategory,
    BudgetSpreadsheetGroup,
    BudgetSpreadsheetItem,
    BudgetSpreadsheetValidations,
} from "@project/domain/models/budget-spreadsheet.model";
import { validateSync } from "class-validator";
import { OrderedMap } from "immutable";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetSpreadsheetCategoryMapper {
    constructor(
        @inject(BudgetSpreadsheetGroupMapper)
        private readonly budgetSpreadsheetGroupMapper: BudgetSpreadsheetGroupMapper,
        @inject(BudgetSpreadsheetValidationMapper)
        private readonly budgetSpreadsheetValidationMapper: BudgetSpreadsheetValidationMapper,
        @inject(BudgetSpreadsheetItemMapper)
        private readonly budgetSpreadsheetItemMapper: BudgetSpreadsheetItemMapper,
    ) {}

    map(
        categoryDto: BudgetSpreadsheetCategoryDto,
        columnsDto: BudgetSpreadsheetColumnDto[],
    ): Nullable<BudgetSpreadsheetCategory> {
        const errors = validateSync(categoryDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        const groups: OrderedMap<number, BudgetSpreadsheetGroup> = OrderedMap(
            categoryDto.groups
                .mapNotNull((groupDto) =>
                    this.budgetSpreadsheetGroupMapper.map(groupDto, columnsDto),
                )
                .map((group) => [group.id, group]),
        );

        let itemsWithoutGroup: Undefinable<
            OrderedMap<number, BudgetSpreadsheetItem>
        > = undefined;

        if (categoryDto.items_without_group.length > 0) {
            itemsWithoutGroup = OrderedMap(
                categoryDto.items_without_group
                    .mapNotNull((itemWithoutGroup) =>
                        this.budgetSpreadsheetItemMapper.map(
                            itemWithoutGroup,
                            columnsDto,
                        ),
                    )
                    .map((item) => [item.id, item]),
            );
        }

        let validations: Undefinable<BudgetSpreadsheetValidations> = undefined;
        if (categoryDto.validations.isNotEmpty()) {
            validations = OrderedMap(
                categoryDto.validations
                    .mapNotNull((validation) =>
                        this.budgetSpreadsheetValidationMapper.map(validation),
                    )
                    .map((validation) => [validation.columnId, validation]),
            );
        }

        return new BudgetSpreadsheetCategory(
            categoryDto.id,
            categoryDto.name,
            groups,
            itemsWithoutGroup,
            validations,
        );
    }
}
