import { Nullish } from "@core/domain/types/nullish.type";
import { IsNotEmpty, IsNumber, IsString } from "class-validator";

export class GlobalEntityDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    company_name!: string;
}

export type GlobalEntityQuery = {
    catalogue?: Nullish<string>;
};
