import { Col, ColProps } from "antd";
import { FC } from "react";

export interface IncColProps extends ColProps {
    show?: boolean;
}

export const IncCol: FC<IncColProps> = (props) => {
    const { show, ...restProps } = props;

    if (show === false) return null;

    return <Col {...restProps} />;
};
