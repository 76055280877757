import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { RegisteredRecordDatasource } from "@entity/data/datasources/registered-record.datasource";
import { CreateRegisteredRecord } from "@entity/domain/models/registered-record/create-registered-record.model";
import { EditRegisteredRecord } from "@entity/domain/models/registered-record/edit-registered-record.model";
import {
    RegisteredRecord,
    RegisteredRecords,
} from "@entity/domain/models/registered-record/registered-record.model";
import { inject, injectable } from "inversify";

@injectable()
export class RegisteredRecordRepository {
    constructor(
        @inject(RegisteredRecordDatasource)
        private readonly registeredRecordDatasource: RegisteredRecordDatasource,
    ) {}

    async getAll(
        pagination: Pagination,
    ): Promise<Either<FallbackError, RegisteredRecords>> {
        const registeredRecordResult =
            await this.registeredRecordDatasource.fetchAll(pagination);

        return registeredRecordResult.mapLeft(() => new FallbackError());
    }

    async create(
        registeredRecord: CreateRegisteredRecord,
    ): Promise<Either<ValidationError | FallbackError, RegisteredRecord>> {
        return this.registeredRecordDatasource.create(registeredRecord);
    }

    async edit(
        editRegisteredRecord: EditRegisteredRecord,
    ): Promise<Either<ValidationError | FallbackError, RegisteredRecord>> {
        return this.registeredRecordDatasource.edit(editRegisteredRecord);
    }

    async delete(
        registeredRecordId: number,
    ): Promise<Either<FallbackError, true>> {
        const registeredRecordResult =
            await this.registeredRecordDatasource.delete(registeredRecordId);
        return registeredRecordResult.mapLeft(() => new FallbackError());
    }
}
