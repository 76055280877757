import { CreateFreelanceContractBody } from "@entity/data/dto/employee/contracts/freelance/create-freelance-contract.body";
import { ProfessionalGroupEnumMapper } from "@entity/data/mappers/employee/professional-group-enum.mapper";
import { CreateFreelanceContract } from "@entity/domain/models/employee/contracts/freelance/create-freelance-contract.model";
import { FreelanceContractFormValuesValidated } from "@entity/presentation/component/employee-form/contract/freelance/form/freelance-contract-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreateFreelanceContractMapper {
    constructor(
        @inject(ProfessionalGroupEnumMapper)
        private readonly professionalGroupEnumMapper: ProfessionalGroupEnumMapper,
    ) {}

    mapToCreateDto(
        createFreelanceContract: CreateFreelanceContract,
    ): CreateFreelanceContractBody {
        const professionalGroup = this.professionalGroupEnumMapper.mapToDto(
            createFreelanceContract.professionalGroup,
        );

        return {
            service: createFreelanceContract.service,
            professional_group: professionalGroup,
            start_date: createFreelanceContract.startDate.toISODate() ?? "",
            total_hours: createFreelanceContract.totalHours.toString(),
            hours_cost: createFreelanceContract.hoursCost.toString(),
            total_cost: createFreelanceContract.totalCost.toString(),
            invoices_number: createFreelanceContract.invoicesNumber.toString(),
            IRPF: createFreelanceContract.irpf.toString(),
            employee: createFreelanceContract.employeeId,
            end_date: createFreelanceContract.endDate?.toISODate() ?? null,
        };
    }

    mapFromFormValues(
        employee: number,
        freelanceContractForm: FreelanceContractFormValuesValidated,
    ): CreateFreelanceContract {
        if (!employee) throw Error("Missing employeeId");

        const createFreelanceContract = new CreateFreelanceContract(
            freelanceContractForm.service,
            freelanceContractForm.professionalGroup,
            freelanceContractForm.startDate,
            freelanceContractForm.totalHours,
            freelanceContractForm.hoursCost,
            freelanceContractForm.totalCost,
            freelanceContractForm.invoicesNumber,
            freelanceContractForm.irpf,
            employee,
            freelanceContractForm.endDate,
        );

        return createFreelanceContract;
    }
}
