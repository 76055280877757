import { Nullable } from "@core/domain/types/nullable.type";
import { BudgetSpreadsheetDto } from "@project/data/dto/budget-spreadsheet.dto";
import { EditBudgetSpreadsheetBody } from "@project/data/dto/edit-budget-spreadsheet.body";
import { BudgetSpreadsheetCategoryMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-category.mapper";
import { BudgetSpreadsheetColumnMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-column.mapper";
import {
    BudgetSpreadsheet,
    BudgetSpreadsheetCategory,
    BudgetSpreadsheetColumn,
} from "@project/domain/models/budget-spreadsheet.model";
import { EditBudgetSpreadsheet } from "@project/domain/models/edit-budget-spreadsheet.model";
import { validateSync } from "class-validator";
import { OrderedMap } from "immutable";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetSpreadsheetMapper {
    constructor(
        @inject(BudgetSpreadsheetColumnMapper)
        private readonly budgetSpreadsheetColumnMapper: BudgetSpreadsheetColumnMapper,
        @inject(BudgetSpreadsheetCategoryMapper)
        private readonly budgetSpreadsheetCategoryMapper: BudgetSpreadsheetCategoryMapper,
    ) {}

    map(budgetDto: BudgetSpreadsheetDto): Nullable<BudgetSpreadsheet> {
        const errors = validateSync(budgetDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        const columns: OrderedMap<number, BudgetSpreadsheetColumn> = OrderedMap(
            budgetDto.columns
                .mapNotNull((columnDto) =>
                    this.budgetSpreadsheetColumnMapper.map(columnDto),
                )
                .map((column) => [column.id, column]),
        );

        const categories: OrderedMap<number, BudgetSpreadsheetCategory> =
            OrderedMap(
                budgetDto.categories
                    .mapNotNull((categoryDto) =>
                        this.budgetSpreadsheetCategoryMapper.map(
                            categoryDto,
                            budgetDto.columns,
                        ),
                    )
                    .map((category) => [category.id, category]),
            );

        return new BudgetSpreadsheet(columns, categories);
    }

    mapToEditDto(
        editBudgetSpreadsheet: EditBudgetSpreadsheet,
    ): EditBudgetSpreadsheetBody {
        return editBudgetSpreadsheet.changedCells
            .toSeq()
            .toArray()
            .map(([, cell]) => ({
                id: cell.id,
                value: cell.value.toString(),
            }));
    }
}
