export enum CatalogueEnum {
    AccAdministrativeProcedures = "acc_administrative_procedures",
    SocialSupport = "social_support",
    Lodging = "lodging",
    Psychological = "psychological",
    SearchHouse = "search_house",
    SocialCanteen = "social_canteen",
    CoursesLectures = "courses-lectures",
    HousingArragements = "housing_arragements",
    Delivery = "delivery",
    Training = "training",
    SocioEducationalTraining = "socio-educational_training",
    SocioOcuppationalTraining = "socio-ocuppational_training",
    SocialIntegration = "social_integration",
    Events = "events",
    Couseling = "couseling",
    ActiveJobSearch = "active_job_search",
    Others = "others",
    LegalServices = "legal_services",
    Workshops = "workshops",
}

export class Catalogue {
    constructor(
        public readonly id: CatalogueEnum,
        public readonly label: string,
    ) {}
}
