import { MaterialRepository } from "@entity/data/repositories/material.repository";
import { MaterialSubtype } from "@entity/domain/models/material/material-subtype.model";
import { MaterialTypeEnum } from "@entity/domain/models/material/material-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllMaterialSubtypesByTypeIdUseCase {
    constructor(
        @inject(MaterialRepository)
        private readonly materialRepository: MaterialRepository,
    ) {}

    async execute(typeId: MaterialTypeEnum): Promise<MaterialSubtype[]> {
        const typesResult =
            await this.materialRepository.getAllMaterialSubtypesByTypeId(
                typeId,
            );

        return typesResult.getOrElse([]);
    }
}
