import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { LocalDatasource } from "@entity/data/datasources/local.datasource";
import { CostPeriodicityType } from "@entity/domain/models/locals/cost-periodicity.model";
import {
    CreateLocal,
    EditLocal,
    Local,
    Locals,
} from "@entity/domain/models/locals/local.model";
import { CreateRoom, Room } from "@entity/domain/models/locals/room.model";
import { inject, injectable } from "inversify";
import { StatusType } from "../../domain/models/locals/status.model";
import { RoomDatasource } from "../datasources/room.datasource";

@injectable()
export class LocalRepository {
    constructor(
        @inject(LocalDatasource)
        private readonly localDatasource: LocalDatasource,
        @inject(RoomDatasource)
        private readonly roomDatasource: RoomDatasource,
    ) {}

    async getById(localId: number): Promise<Either<FallbackError, Local>> {
        return (await this.localDatasource.fetchById(localId)).mapLeft(
            () => new FallbackError(),
        );
    }

    async getAll(): Promise<Either<FallbackError, Local[]>> {
        const localsResult = await this.localDatasource.fetchAll();

        return localsResult
            .mapLeft(() => new FallbackError())
            .map((locals) => locals);
    }

    async getAllPaginated(
        limit: number,
        offset: number,
    ): Promise<Either<FallbackError, Locals>> {
        const localsResult = await this.localDatasource.fetchAllPaginated(
            limit,
            offset,
        );

        return localsResult.mapLeft(() => new FallbackError());
    }

    async getAllCostPeriodicities(): Promise<
        Either<FallbackError, CostPeriodicityType[]>
    > {
        const costPeriodicities =
            await this.localDatasource.fetchAllCostPeriodicities();

        return costPeriodicities
            .mapLeft(() => new FallbackError())
            .map((costPeriodicity) => costPeriodicity);
    }

    async getAllStatusTypes(): Promise<Either<FallbackError, StatusType[]>> {
        const statusTypes = await this.localDatasource.fetchAllStatusTypes();

        return statusTypes
            .mapLeft(() => new FallbackError())
            .map((status) => status);
    }

    async create(
        createLocal: CreateLocal,
    ): Promise<Either<ValidationError | FallbackError, Local>> {
        return this.localDatasource.create(createLocal);
    }

    async createRoom(
        createRoom: CreateRoom,
    ): Promise<Either<ValidationError | FallbackError, Room>> {
        return this.roomDatasource.create(createRoom);
    }

    async edit(
        editLocal: EditLocal,
    ): Promise<Either<ValidationError | FallbackError, Local>> {
        return this.localDatasource.edit(editLocal);
    }

    async delete(localId: number): Promise<Either<FallbackError, boolean>> {
        return this.localDatasource.delete(localId);
    }
}
