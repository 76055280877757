import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateFinancialEntityMapper } from "@project/data/mappers/financial-entity/create-financial-entity.mapper";
import { FinancialEntity } from "@project/domain/models/financial-entity/financial-entities.model";
import { CreateFinancialEntityUseCase } from "@project/domain/usecases/financial-entity/create-financial-entity.usecase";
import { FinancialEntityFormValuesValidated } from "@project/presentation/components/financial-entity-form/financial-entity-form.component";
import { inject, injectable } from "inversify";
import { makeObservable } from "mobx";

@injectable()
export class CreateFinancialEntityPageViewModel extends BaseViewModel {
    constructor(
        @inject(CreateFinancialEntityUseCase)
        private readonly createFinancialEntityUseCase: CreateFinancialEntityUseCase,
        @inject(CreateFinancialEntityMapper)
        private readonly createFinancialEntityMapper: CreateFinancialEntityMapper,
    ) {
        super();
        makeObservable(this);
    }

    async createFinancialEntity(
        values: FinancialEntityFormValuesValidated,
    ): Promise<Nullable<FinancialEntity>> {
        LoadLayoutStore.start();

        const createFinancialEntityResult =
            await this.createFinancialEntityUseCase.execute(
                this.createFinancialEntityMapper.mapFromFinancialEntityFormValues(
                    values,
                ),
            );

        if (createFinancialEntityResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createFinancialEntityResult;
    }
}
