import { DisabilityType } from "@beneficiary/domain/models/disability-type.enum";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { BeneficiaryRepository } from "../../data/repositories/beneficiary.repository";

@injectable()
export class GetDisabilityTypesUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(): Promise<DisabilityType[]> {
        const disabilityTypesResult =
            await this.beneficiaryRepository.getAllDisabilityTypes();

        if (disabilityTypesResult.isLeft()) {
            ToastManagerStore.error(
                disabilityTypesResult.getLeftOrThrow().message,
            );
        }

        return disabilityTypesResult.fold(
            () => [],
            (disabilityTypes) => disabilityTypes,
        );
    }
}
