export enum PaymentMethodTypeEnum {
    CASH = "CASH",
    BANK_JUSTIFY = "BANK_JUSTIFY",
}

export class PaymentMethodType {
    constructor(
        public readonly id: PaymentMethodTypeEnum,
        public readonly label: string,
    ) {}
}
