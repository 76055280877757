import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum CollectiveEnumDto {
    All = "all",
    Women = "women",
    GenderVictims = "gender_victims",
    AtRiskOrExclusion = "at_risk_or_exclusion",
    SocialVulnerability = "social_vulnerability",
    ChildrenAndFamily = "children_and_family",
    MinimumIncomeRecipients = "minimum_income_recipients",
    Disability = "disability",
    Elderly = "elderly",
    Youth = "youth",
    Lgbtiq = "lgbtiq",
    MigrantsResidentsSpain = "migrants_residents_spain",
    MigrantsResidentsAbroad = "migrants_residents_abroad",
    Addicts = "addicts",
    GypsyEthnicity = "gypsy_ethnicity",
    EthnicMinorities = "ethnic_minorities",
    HivAids = "hiv_aids",
    ChronicIllnesses = "chronic_illnesses",
    RareDiseases = "rare_diseases",
    MentalIllness = "mental_illness",
    PrisonRelated = "prison_related",
    Homeless = "homeless",
    RuralResidents = "rural_residents",
    Families = "families",
    Volunteers = "volunteers",
    OtherEntitiesProfessionals = "other_entities_professionals",
    GeneralPopulation = "general_population",
    Other = "other",
    "" = "",
}

export class CollectiveDto {
    @IsEnum(CollectiveEnumDto)
    @IsNotEmpty()
    key!: CollectiveEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
