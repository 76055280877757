import { EditFinancialEntity } from "@project/domain/models/financial-entity/edit-financial-entity.model";
import { FinancialEntityFormValuesValidated } from "@project/presentation/components/financial-entity-form/financial-entity-form.component";
import { injectable } from "inversify";
import { EditFinancialEntityBody } from "../../dto/financial-entity/edit-financial-entity.body";

@injectable()
export class EditFinancialEntityMapper {
    mapFromFinancialEntityFormValues(
        financialEntityId: number,
        financialEntityForm: FinancialEntityFormValuesValidated,
    ): EditFinancialEntity {
        return new EditFinancialEntity(
            financialEntityId,
            financialEntityForm.name,
            financialEntityForm.type,
            financialEntityForm.description,
            financialEntityForm.isActive,
        );
    }

    mapToDto(
        editFinancialEntity: EditFinancialEntity,
    ): EditFinancialEntityBody {
        return {
            id: editFinancialEntity.id,
            name: editFinancialEntity.name,
            type: editFinancialEntity.type,
            description: editFinancialEntity.description,
            is_active: editFinancialEntity.isActive,
        };
    }
}
