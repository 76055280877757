import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateCostMapper } from "@entity/data/mappers/cost/create-cost.mapper";
import { Cost } from "@entity/domain/models/cost/cost.model";
import { CreateCostUseCase } from "@entity/domain/usecases/cost/create-cost.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable } from "mobx";
import { CostFormValuesValidated } from "../../../component/cost-form/cost-form.component";

@injectable()
export class CreateCostPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    constructor(
        @inject(CreateCostUseCase)
        private readonly createCostUseCase: CreateCostUseCase,
        @inject(CreateCostMapper)
        private readonly createCostMapper: CreateCostMapper,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();
    }

    async createCost(
        values: CostFormValuesValidated,
        entityId: number,
    ): Promise<Nullable<Cost>> {
        LoadLayoutStore.start();

        const createCostResult = await this.createCostUseCase.execute(
            this.createCostMapper.mapFromCostFormValues(values, entityId),
        );

        if (createCostResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createCostResult;
    }
}
