import { Rooms } from "@entity/domain/models/locals/room.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { RoomsDto } from "../../dto/locals/room.dto";
import { RoomMapper } from "./room.mapper";

@injectable()
export class RoomsMapper {
    constructor(@inject(RoomMapper) private readonly roomMapper: RoomMapper) {}
    map(roomsDto: RoomsDto): Rooms {
        const errors = validateSync(roomsDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Rooms([], 0);
        }

        const rooms = roomsDto.results.mapNotNull((roomDto) =>
            this.roomMapper.map(roomDto),
        );

        return new Rooms(rooms, roomsDto.count);
    }
}
