import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { StatutoryTerritorialScopeRepository } from "@entity/data/repositories/statutory-territorail-scope.repository";
import { EditStatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/edit-statutory-territorial-scope.model";
import { StatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/statutory-territorial-scope.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditStatutoryTerritorialScopeUseCase {
    constructor(
        @inject(StatutoryTerritorialScopeRepository)
        private readonly statutoryTerritorialScopeRepository: StatutoryTerritorialScopeRepository,
    ) {}

    async execute(
        statutoryTerritorialScope: EditStatutoryTerritorialScope,
    ): Promise<Nullable<StatutoryTerritorialScope>> {
        const editResult = await this.statutoryTerritorialScopeRepository.edit(
            statutoryTerritorialScope,
        );

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedStatutoryTerritorialScope) =>
                editedStatutoryTerritorialScope,
        );
    }
}
