import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { AuthorityManagerRepository } from "@entity/data/repositories/authority-manager.repository";
import { AuthorityManager } from "@entity/domain/models/authority-manager/authority-manager.model";
import { EditAuthorityManager } from "@entity/domain/models/authority-manager/edit-authority-manager.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditAuthorityManagerUseCase {
    constructor(
        @inject(AuthorityManagerRepository)
        private readonly authorityManagerRepository: AuthorityManagerRepository,
    ) {}

    async execute(
        address: EditAuthorityManager,
    ): Promise<Nullable<AuthorityManager>> {
        const editResult = await this.authorityManagerRepository.edit(address);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedAuthorityManager) => editedAuthorityManager,
        );
    }
}
