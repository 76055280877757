import { LanguageRepository } from "@core/data/repositories/language.repository";
import { LanguageIsoCodeEnum } from "@core/domain/models/language.model";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class UpdateLanguageUseCase {
    constructor(
        @inject(LanguageRepository)
        private readonly languageRepository: LanguageRepository,
    ) {}

    async execute(language: LanguageIsoCodeEnum): Promise<void> {
        const languageChangeResult =
            await this.languageRepository.update(language);

        if (languageChangeResult.isLeft()) {
            ToastManagerStore.error(
                languageChangeResult.getLeftOrThrow().message,
            );
        }
    }
}
