import { IncSelectFilter } from "@core/presentacion/component/data-entry/select/inc-select-filter.component";
import { IncSelectForm } from "@core/presentacion/component/data-entry/select/inc-select-form.component";
import { Select, SelectProps } from "antd";
import { DefaultOptionType } from "rc-select/lib/Select";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

export type IncSelectProps = SelectProps;

const defaultFilterOption = (
    inputValue: string,
    option?: DefaultOptionType,
): boolean => {
    let label = "";
    if (option?.children) {
        label = option.children.toString();
    } else if (option?.label) {
        label = option.label.toString();
    }

    return label.toLowerCase().includes(inputValue.toLowerCase());
};

const defaultFilterSort = (
    optionA: DefaultOptionType,
    optionB: DefaultOptionType,
): number => {
    let labelA = "";
    let labelB = "";

    if (optionA.children && optionB.children) {
        labelA = optionA.children.toString();
        labelB = optionB.children.toString();
    } else if (optionA.label && optionB.label) {
        labelA = optionA.label.toString();
        labelB = optionB.label.toString();
    }

    return labelA.localeCompare(labelB);
};

export const IncSelect: FC<IncSelectProps> & {
    Option: typeof Select.Option;
    Form: typeof IncSelectForm;
    Filter: typeof IncSelectFilter;
} = (props) => {
    const { t } = useTranslation();

    const {
        allowClear = true,
        placeholder = t("component:incSelect.selectPlaceholder"),
        showSearch = true,
        optionFilterProp = "children",
        filterOption = defaultFilterOption,
        defaultValue,
        // filterSort = defaultFilterSort,
        ...restProps
    } = props;

    const handleChange = useCallback(
        (_value: unknown, option: DefaultOptionType | DefaultOptionType[]) => {
            /*
                Here, we modify the default Ant Design behavior when a select/multi is cleared.
                Instead of sending undefined, we send null as an "empty" value.
                This also fixes a yup validation error because when a value changes from something to undefined, it assumes that the property
                is being deleted, so it purges it from the object.
                Here's an example:

                const form = { errors: { name: "Must be defined" } }

                // AFTER BEING CLEARED
                const form = { errors: { } }

                // EXPECTED AND FIXED BEHAVIOR WITH NULL

                const form = { errors: { name: undefined } }

                Both cases resolve errors.name === undefined, but in practical terms, they are quite different.
            */
            const value = _value ?? null;

            if (props.onChange) {
                props.onChange(value, option);
            }
        },
        [props.onChange],
    );

    return (
        <Select
            {...restProps}
            allowClear={allowClear}
            placeholder={placeholder}
            showSearch={showSearch}
            optionFilterProp={optionFilterProp}
            filterOption={filterOption}
            onChange={handleChange}
            defaultValue={defaultValue}
            // filterSort={filterSort}
        />
    );
};

IncSelect.Option = Select.Option;
IncSelect.Form = IncSelectForm;
IncSelect.Filter = IncSelectFilter;
