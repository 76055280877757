import {
    InternalNoteDto,
    InternalNotesDto,
} from "@beneficiary/data/dto/internal-notes/internal-note.dto";
import { InternalNoteMapper } from "@beneficiary/data/mappers/internal-notes/internal-note.mapper";
import {
    InternalNote,
    InternalNotes,
} from "@beneficiary/domain/models/internal-notes/internal-note.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { UserMapper } from "@user/data/mappers/user.mapper";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class InternalNotesMapper {
    constructor(
        @inject(InternalNoteMapper)
        private readonly internalNoteMapper: InternalNoteMapper,
        @inject(UserMapper)
        private readonly userMapper: UserMapper,
    ) {}

    map(internalNotesDto: InternalNotesDto): InternalNotes {
        const errors = validateSync(internalNotesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new InternalNotes([], 0);
        }

        const internalNotes = internalNotesDto.results.mapNotNull(
            (internalNoteDto) => this.internalNoteMapper.map(internalNoteDto),
        );

        return new InternalNotes(internalNotes, internalNotesDto.count);
    }

    mapInternalNote(internalNoteDto: InternalNoteDto): Nullable<InternalNote> {
        const errors = validateSync(internalNoteDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const userData = this.userMapper.map(internalNoteDto.user_data);
        if (!userData) return null;

        return new InternalNote(
            internalNoteDto.id,
            internalNoteDto.note,
            internalNoteDto.beneficiary,
            userData,
            DateTime.fromISO(internalNoteDto.modified),
        );
    }
}
