import { Nullable } from "@core/domain/types/nullable.type";
import { DietTypeEnumDto } from "@project/data/dto/expenses/diet-type.dto";
import { injectable } from "inversify";
import { DietTypeEnum } from "../../../domain/models/expenses/diet-type.model";

@injectable()
export class DietTypeEnumMapper {
    map(centerTypeEnumDto: DietTypeEnumDto): Nullable<DietTypeEnum> {
        switch (centerTypeEnumDto) {
            case DietTypeEnumDto.ACCOMMODATION:
                return DietTypeEnum.ACCOMMODATION;
            case DietTypeEnumDto.MEALS:
                return DietTypeEnum.MEALS;
            default:
                return null;
        }
    }

    mapToDto(centerTypeEnum: DietTypeEnum): DietTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (centerTypeEnum) {
            case DietTypeEnum.ACCOMMODATION:
                return DietTypeEnumDto.ACCOMMODATION;
            case DietTypeEnum.MEALS:
                return DietTypeEnumDto.MEALS;
            case DietTypeEnum.NONE:
                return DietTypeEnumDto.NONE;
        }
    }
}
