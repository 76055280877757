import { CenterTypeEnum } from "@beneficiary/domain/models/center-type.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { CenterTypeEnumDto } from "../dto/center-type.dto";

@injectable()
export class CenterTypeEnumMapper {
    map(centerTypeEnumDto: CenterTypeEnumDto): Nullable<CenterTypeEnum> {
        switch (centerTypeEnumDto) {
            case CenterTypeEnumDto.HealthCenter:
                return CenterTypeEnum.HealthCenter;
            case CenterTypeEnumDto.MentalHealthCenter:
                return CenterTypeEnum.MentalHealthCenter;
            case CenterTypeEnumDto.CafCenter:
                return CenterTypeEnum.CafCenter;
            case CenterTypeEnumDto.CadCenter:
                return CenterTypeEnum.CadCenter;
            case CenterTypeEnumDto.SocialServicesCenter:
                return CenterTypeEnum.SocialServicesCenter;
            case CenterTypeEnumDto.SpecializedSocialServicesCenter:
                return CenterTypeEnum.SpecializedSocialServicesCenter;
            case CenterTypeEnumDto.EducationalCenter:
                return CenterTypeEnum.EducationalCenter;
            case CenterTypeEnumDto.Others:
                return CenterTypeEnum.Others;
            default:
                return null;
        }
    }

    mapToDto(centerTypeEnum: CenterTypeEnum): CenterTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (centerTypeEnum) {
            case CenterTypeEnum.HealthCenter:
                return CenterTypeEnumDto.HealthCenter;
            case CenterTypeEnum.MentalHealthCenter:
                return CenterTypeEnumDto.MentalHealthCenter;
            case CenterTypeEnum.CafCenter:
                return CenterTypeEnumDto.CafCenter;
            case CenterTypeEnum.CadCenter:
                return CenterTypeEnumDto.CadCenter;
            case CenterTypeEnum.SocialServicesCenter:
                return CenterTypeEnumDto.SocialServicesCenter;
            case CenterTypeEnum.SpecializedSocialServicesCenter:
                return CenterTypeEnumDto.SpecializedSocialServicesCenter;
            case CenterTypeEnum.EducationalCenter:
                return CenterTypeEnumDto.EducationalCenter;
            case CenterTypeEnum.Others:
                return CenterTypeEnumDto.Others;
        }
    }
}
