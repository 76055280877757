import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { AssociateServiceToDependence } from "@beneficiary/domain/models/associate-service-to-dependence.enum";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class GetAssociateServiceToDependenceUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(): Promise<AssociateServiceToDependence[]> {
        const associateServiceToDependenceResult =
            await this.beneficiaryRepository.getAllAssociateServiceToDependence();

        if (associateServiceToDependenceResult.isLeft()) {
            ToastManagerStore.error(
                associateServiceToDependenceResult.getLeftOrThrow().message,
            );
        }

        return associateServiceToDependenceResult.fold(
            () => [],
            (associateServiceToDependenceR) => associateServiceToDependenceR,
        );
    }
}
