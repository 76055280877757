import { Nullable } from "@core/domain/types/nullable.type";
import { ExpenseTypeDto } from "@project/data/dto/expenses/expense-type.dto";
import { ExpenseTypeEnumMapper } from "@project/data/mappers/expenses/expense-type-enum.mapper";
import {
    ExpenseType,
    ExpenseTypeEnum,
} from "@project/domain/models/expenses/expense-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ExpenseTypeMapper {
    constructor(
        @inject(ExpenseTypeEnumMapper)
        private readonly expenseTypeEnumMapper: ExpenseTypeEnumMapper,
    ) {}
    map(expenseTypeDto: ExpenseTypeDto): Nullable<ExpenseType> {
        const errors = validateSync(expenseTypeDto);
        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id: Nullable<ExpenseTypeEnum> = this.expenseTypeEnumMapper.map(
            expenseTypeDto.key,
        );
        if (!id) return null;

        return new ExpenseType(id, expenseTypeDto.value);
    }
}
