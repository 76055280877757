import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { EntityTerritorialScope } from "@entity/domain/models/entity-territorial-scope.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllEntityTerritorialScopeUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}

    async execute(): Promise<EntityTerritorialScope[]> {
        const entityTerritorialScopeResult =
            await this.entityRepository.getAllEntityTerritorialScopes();

        return entityTerritorialScopeResult.getOrElse([]);
    }
}
