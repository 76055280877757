import { Nullable } from "@core/domain/types/nullable.type";
import {
    EmployeeContractDto,
    EmployeeContractsDto,
} from "@entity/data/dto/employee/contracts/employee/employee-contract.dto";
import { EmployeeContractMapper } from "@entity/data/mappers/employee/contracts/employee/employee-contract.mapper";
import { DisabilityTypeEnumMapper } from "@entity/data/mappers/employee/disability-type-enum.mapper";
import { ProfessionalGroupEnumMapper } from "@entity/data/mappers/employee/professional-group-enum.mapper";
import { SubsidyReasonEnumMapper } from "@entity/data/mappers/employee/subsidy-reason-enum.mapper";
import { TerminationReasonEnumMapper } from "@entity/data/mappers/employee/termination-reason-enum.mapper";
import {
    EmployeeContract,
    EmployeeContracts,
} from "@entity/domain/models/employee/contracts/employee/employee-contract.model";
import { ContractTypeEnumMapper } from "@project/data/mappers/expenses/contract-type-enum.mapper";
import { ContributionGroupTypeEnumMapper } from "@project/data/mappers/expenses/contribution-group-type-enum.mapper";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class EmployeeContractsMapper {
    constructor(
        @inject(ContributionGroupTypeEnumMapper)
        private readonly contributionGroupTypeEnumMapper: ContributionGroupTypeEnumMapper,
        @inject(ContractTypeEnumMapper)
        private readonly contractTypeEnumMapper: ContractTypeEnumMapper,
        @inject(DisabilityTypeEnumMapper)
        private readonly disabilityTypeEnumMapper: DisabilityTypeEnumMapper,
        @inject(EmployeeContractMapper)
        private readonly employeeContractMapper: EmployeeContractMapper,
        @inject(ProfessionalGroupEnumMapper)
        private readonly professionalGroupEnumMapper: ProfessionalGroupEnumMapper,
        @inject(TerminationReasonEnumMapper)
        private readonly terminationReasonEnumMapper: TerminationReasonEnumMapper,
        @inject(SubsidyReasonEnumMapper)
        private readonly subsidyReasonEnumMapper: SubsidyReasonEnumMapper,
    ) {}

    map(employeeContractsDto: EmployeeContractsDto): EmployeeContracts {
        const errors = validateSync(employeeContractsDto);

        if (errors.length > 0) {
            console.error(errors);

            return new EmployeeContracts([], 0);
        }

        const employeeContracts = employeeContractsDto.results.mapNotNull(
            (employeeDto) => this.employeeContractMapper.map(employeeDto),
        );

        return new EmployeeContracts(
            employeeContracts,
            employeeContractsDto.count,
        );
    }

    mapContract(
        employeeContractDto: EmployeeContractDto,
    ): Nullable<EmployeeContract> {
        if (!employeeContractDto.id) {
            return null;
        }

        const contractType = this.contractTypeEnumMapper.map(
            employeeContractDto.type,
        );
        if (!contractType) return null;

        const professionalGroup = this.professionalGroupEnumMapper.map(
            employeeContractDto.professional_group,
        );
        if (!professionalGroup) return null;

        const contributionGroup = this.contributionGroupTypeEnumMapper.map(
            employeeContractDto.contribution_group,
        );
        if (!contributionGroup) return null;

        let disabilityType = null;
        if (employeeContractDto.disability_type) {
            disabilityType = this.disabilityTypeEnumMapper.map(
                employeeContractDto.disability_type,
            );
        }

        let terminationReason = null;
        if (employeeContractDto.termination_reason) {
            terminationReason = this.terminationReasonEnumMapper.map(
                employeeContractDto.termination_reason,
            );
        }

        let subsidyReason = null;
        if (employeeContractDto.subsidy_reason) {
            subsidyReason = this.subsidyReasonEnumMapper.map(
                employeeContractDto.subsidy_reason,
            );
        }

        const volunteerContract: EmployeeContract = {
            id: employeeContractDto.id,
            type: contractType,
            startDate: DateTime.fromISO(employeeContractDto.start_date),
            hasDisability: employeeContractDto.has_disability,
            professionalGroup,
            contributionGroup,
            weeklyWorkdayHours: Number(
                employeeContractDto.weekly_workday_hours,
            ),
            netSalary: Number(employeeContractDto.net_salary),
            irpf: Number(employeeContractDto.IRPF),
            socialSecurityContribution: Number(
                employeeContractDto.social_security_contribution,
            ),
            monthlyCost: Number(employeeContractDto.monthly_cost),
            socialSecurityContributionCompany: Number(
                employeeContractDto.social_security_contribution_company,
            ),
            totalCost: Number(employeeContractDto.total_cost),
            deductibleAmountDisability: Number(
                employeeContractDto.deductible_amount_disability,
            ),
            employeeId: employeeContractDto.employee,
            disabilityType: disabilityType ?? undefined,
            terminationReason: terminationReason ?? undefined,
            otherType: employeeContractDto.other_type ?? undefined,
            isSubsidized: employeeContractDto.is_subsidized,
            subsidyDeductibleAmount: Number(
                employeeContractDto.subsidy_deductible_amount,
            ),
            subsidyReason: subsidyReason ?? undefined,
            otherSubsidyReason:
                employeeContractDto.other_subsidy_reason ?? undefined,
            subsidyScope: employeeContractDto.subsidy_scope ?? undefined,
            endDate: employeeContractDto.end_date
                ? DateTime.fromISO(employeeContractDto.end_date)
                : undefined,
        };

        return volunteerContract;
    }
}
