import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { OrderMapper } from "@core/data/mappers/order.mapper";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Order } from "@core/domain/models/order.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { DepositSearchFilters } from "../../domain/models/deposit-search-filters";
import { DepositGeneralTypes } from "../../domain/models/deposit-type.model";
import {
    CreateDeposit,
    CreateSplitDeposit,
    Deposit,
    SplitDeposit,
} from "../../domain/models/deposit.model";
import { IdentificationType } from "../../domain/models/identification-type.model";
import { DepositGeneralTypesDto } from "../dto/deposit-types.dto";
import {
    CreateSplitDepositDto,
    DepositDto,
    DepositQuery,
    DepositResultDto,
    SplitDepositDto,
    depositOrderMap,
} from "../dto/deposit.dto";
import { IdentificationTypeDto } from "../dto/identification-type.dto";
import { DepositTypesMapper } from "../mappers/deposit-type.mapper";
import { DepositMapper } from "../mappers/deposit/deposit.mapper";
import { IdentificationTypeMapper } from "../mappers/deposit/identification-type.mapper";

const DEPOSIT_PATH = "/deposits/";
const SPLIT_DEPOSIT_PATH = "/projects/split_deposits/";

@injectable()
export class DepositDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(DepositTypesMapper)
        private readonly depositTypesMapper: DepositTypesMapper,
        @inject(DepositMapper)
        private readonly depositMapper: DepositMapper,
        @inject(OrderMapper)
        private readonly orderMapper: OrderMapper,
        @inject(IdentificationTypeMapper)
        private readonly identificationTypeMapper: IdentificationTypeMapper,
    ) {}

    async fetchAllDepositTypes(): Promise<
        Either<FallbackError, DepositGeneralTypes>
    > {
        const responseResult = await this.http.get<DepositGeneralTypesDto>(
            `${DEPOSIT_PATH}types/`,
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) => {
                const allDepositTypes = this.depositTypesMapper.map(
                    plainToClass(DepositGeneralTypesDto, response.data),
                );
                if (!allDepositTypes)
                    return new DepositGeneralTypes([], [], [], []);
                return allDepositTypes;
            });
    }

    async getAllIdentificationDocumentTypes(): Promise<
        Either<FallbackError, IdentificationType[]>
    > {
        const identificationTypesResult = await this.http.get<
            IdentificationTypeDto[]
        >(`${DEPOSIT_PATH}identification_types/`);

        return identificationTypesResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((identificationType) =>
                    this.identificationTypeMapper.map(
                        plainToClass(IdentificationTypeDto, identificationType),
                    ),
                ),
            );
    }

    async fetchAll(): Promise<Either<FallbackError, Deposit[]>> {
        const depositResult =
            await this.http.get<DepositResultDto>(DEPOSIT_PATH);

        return depositResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.results.mapNotNull((deposit) =>
                    this.depositMapper.map(plainToClass(DepositDto, deposit)),
                ),
            );
    }

    async fetchBy(
        pagination: Pagination,
        filters?: DepositSearchFilters,
        order?: Order<Deposit>,
    ): Promise<Either<FallbackError, Deposit[]>> {
        const query: DepositQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.name) query.search = filters.name;
        if (filters?.type) query.type = filters.type;
        if (filters?.depositDateFrom)
            query.deposit_date__gte = filters.depositDateFrom.toISODate();
        if (filters?.depositDateTo)
            query.deposit_date__lte = filters.depositDateTo.toISODate();

        if (order) {
            const orderQuery = this.orderMapper.mapToDto<Deposit, DepositDto>(
                order.field,
                order.direction,
                depositOrderMap,
            );
            if (orderQuery) query.ordering = orderQuery;
        }

        const depositsResult = await this.http.get<DepositResultDto>(
            DEPOSIT_PATH,
            {
                query,
            },
        );

        return depositsResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.results.mapNotNull((depositDto) =>
                    this.depositMapper.map(
                        plainToClass(DepositDto, depositDto),
                    ),
                ),
            );
    }

    async create(
        deposit: CreateDeposit,
    ): Promise<Either<ValidationError | FallbackError, Deposit>> {
        const depositDto = this.depositMapper.mapToCreateDepositDto(deposit);
        const createDepositResult = await this.http.post<DepositDto>(
            DEPOSIT_PATH,
            depositDto,
        );

        return createDepositResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const depositCreated = this.depositMapper.map(
                    plainToClass(DepositDto, response.data),
                );

                if (!depositCreated) return Either.Left(new FallbackError());

                return Either.Right(depositCreated);
            });
    }

    async edit(
        deposit: Deposit,
    ): Promise<Either<ValidationError | FallbackError, Deposit>> {
        const depositDto = this.depositMapper.mapToDepositDto(deposit);

        const editDepositResult = await this.http.patch<DepositDto>(
            `${DEPOSIT_PATH}${deposit.id}/`,
            depositDto,
        );

        return editDepositResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const depositEdited = this.depositMapper.map(
                    plainToClass(DepositDto, response.data),
                );

                if (!depositEdited) return Either.Left(new FallbackError());

                return Either.Right(depositEdited);
            });
    }

    async createSplitDeposit(
        createSplitDeposit: CreateSplitDeposit,
    ): Promise<Either<ValidationError | FallbackError, SplitDeposit>> {
        const createSplitDepositDto: CreateSplitDepositDto = {
            deposit: createSplitDeposit.depositId,
            project: createSplitDeposit.projectId,
            assigned_percentage: createSplitDeposit.assignedPercentage,
        };

        const splitDepositResult = await this.http.post<SplitDepositDto>(
            SPLIT_DEPOSIT_PATH,
            createSplitDepositDto,
        );

        return splitDepositResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const splitDepositCreated = this.depositMapper.splitDepositMap(
                    plainToClass(SplitDepositDto, response.data),
                );

                if (!splitDepositCreated)
                    return Either.Left(new FallbackError());

                return Either.Right(splitDepositCreated);
            });
    }

    async editSplitDeposit(
        splitDeposit: SplitDeposit,
    ): Promise<Either<ValidationError | FallbackError, SplitDeposit>> {
        const splitDepositDto: SplitDepositDto = {
            id: splitDeposit.id,
            deposit: splitDeposit.depositId,
            project: splitDeposit.projectId,
            assigned_percentage: splitDeposit.assignedPercentage,
        };

        const editSplitDepositResult = await this.http.patch<SplitDepositDto>(
            `${SPLIT_DEPOSIT_PATH}${splitDeposit.id}/`,
            splitDepositDto,
        );

        return editSplitDepositResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const splitDepositEdited = this.depositMapper.splitDepositMap(
                    plainToClass(SplitDepositDto, response.data),
                );

                if (!splitDepositEdited)
                    return Either.Left(new FallbackError());

                return Either.Right(splitDepositEdited);
            });
    }

    async deleteSplitDeposit(
        splitDepositId: number,
    ): Promise<Either<FallbackError, boolean>> {
        const deleteSplitDepositResult = await this.http.delete(
            `${SPLIT_DEPOSIT_PATH}${splitDepositId}/`,
        );

        return deleteSplitDepositResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
    async delete(id: number): Promise<Either<FallbackError, boolean>> {
        const deleteResult = await this.http.delete(`${DEPOSIT_PATH}${id}/`);

        return deleteResult.mapLeft(() => new FallbackError()).map(() => true);
    }
}
