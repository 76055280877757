import { RelationshipEnumDto } from "@beneficiary/data/dto/parentange.dto";
import { RelationshipEnum } from "@beneficiary/domain/models/relationship.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";

@injectable()
export class RelationshipEnumMapper {
    map(relationshipIdDto: RelationshipEnumDto): Nullable<RelationshipEnum> {
        switch (relationshipIdDto) {
            case RelationshipEnumDto.Father:
                return RelationshipEnum.Father;
            case RelationshipEnumDto.Mother:
                return RelationshipEnum.Mother;
            case RelationshipEnumDto.Son:
                return RelationshipEnum.Son;
            case RelationshipEnumDto.Spouse:
                return RelationshipEnum.Spouse;
            case RelationshipEnumDto.Daughter:
                return RelationshipEnum.Daughter;
            case RelationshipEnumDto.Brother:
                return RelationshipEnum.Brother;
            case RelationshipEnumDto.Sister:
                return RelationshipEnum.Sister;
            case RelationshipEnumDto.Grandfather:
                return RelationshipEnum.Grandfather;
            case RelationshipEnumDto.Grandmother:
                return RelationshipEnum.Grandmother;
            case RelationshipEnumDto.Grandson:
                return RelationshipEnum.Grandson;
            case RelationshipEnumDto.Granddaughter:
                return RelationshipEnum.Granddaughter;
            case RelationshipEnumDto.Uncle:
                return RelationshipEnum.Uncle;
            case RelationshipEnumDto.Aunt:
                return RelationshipEnum.Aunt;
            case RelationshipEnumDto.Nephew:
                return RelationshipEnum.Nephew;
            case RelationshipEnumDto.Niece:
                return RelationshipEnum.Niece;
            case RelationshipEnumDto.GreatGrandfather:
                return RelationshipEnum.GreatGrandfather;
            case RelationshipEnumDto.GreatGrandmother:
                return RelationshipEnum.GreatGrandmother;
            case RelationshipEnumDto.GreatGrandson:
                return RelationshipEnum.GreatGrandson;
            case RelationshipEnumDto.GreatGranddaughter:
                return RelationshipEnum.GreatGranddaughter;
            case RelationshipEnumDto.Cousin:
                return RelationshipEnum.Cousin;
            case RelationshipEnumDto.GreatUncle:
                return RelationshipEnum.GreatUncle;
            case RelationshipEnumDto.GreatAunt:
                return RelationshipEnum.GreatAunt;
            case RelationshipEnumDto.GreatNephew:
                return RelationshipEnum.GreatNephew;
            case RelationshipEnumDto.GreatNiece:
                return RelationshipEnum.GreatNiece;
            case RelationshipEnumDto.GreatGreatGrandfather:
                return RelationshipEnum.GreatGreatGrandfather;
            case RelationshipEnumDto.GreatGreatGrandmother:
                return RelationshipEnum.GreatGreatGrandmother;
            case RelationshipEnumDto.GreatGreatGrandson:
                return RelationshipEnum.GreatGreatGrandson;
            case RelationshipEnumDto.GreatGreatGranddaughter:
                return RelationshipEnum.GreatGreatGranddaughter;
            case RelationshipEnumDto.LegalGuardian:
                return RelationshipEnum.LegalGuardian;
            case RelationshipEnumDto.Other:
                return RelationshipEnum.Other;
            default:
                return null;
        }
    }

    mapToDto(relationshipIdDto: RelationshipEnum): RelationshipEnumDto {
        // eslint-disable-next-line default-case
        switch (relationshipIdDto) {
            case RelationshipEnum.Father:
                return RelationshipEnumDto.Father;
            case RelationshipEnum.Mother:
                return RelationshipEnumDto.Mother;
            case RelationshipEnum.Son:
                return RelationshipEnumDto.Son;
            case RelationshipEnum.Spouse:
                return RelationshipEnumDto.Spouse;
            case RelationshipEnum.Daughter:
                return RelationshipEnumDto.Daughter;
            case RelationshipEnum.Brother:
                return RelationshipEnumDto.Brother;
            case RelationshipEnum.Sister:
                return RelationshipEnumDto.Sister;
            case RelationshipEnum.Grandfather:
                return RelationshipEnumDto.Grandfather;
            case RelationshipEnum.Grandmother:
                return RelationshipEnumDto.Grandmother;
            case RelationshipEnum.Grandson:
                return RelationshipEnumDto.Grandson;
            case RelationshipEnum.Granddaughter:
                return RelationshipEnumDto.Granddaughter;
            case RelationshipEnum.Uncle:
                return RelationshipEnumDto.Uncle;
            case RelationshipEnum.Aunt:
                return RelationshipEnumDto.Aunt;
            case RelationshipEnum.Nephew:
                return RelationshipEnumDto.Nephew;
            case RelationshipEnum.Niece:
                return RelationshipEnumDto.Niece;
            case RelationshipEnum.GreatGrandfather:
                return RelationshipEnumDto.GreatGrandfather;
            case RelationshipEnum.GreatGrandmother:
                return RelationshipEnumDto.GreatGrandmother;
            case RelationshipEnum.GreatGrandson:
                return RelationshipEnumDto.GreatGrandson;
            case RelationshipEnum.GreatGranddaughter:
                return RelationshipEnumDto.GreatGranddaughter;
            case RelationshipEnum.Cousin:
                return RelationshipEnumDto.Cousin;
            case RelationshipEnum.GreatUncle:
                return RelationshipEnumDto.GreatUncle;
            case RelationshipEnum.GreatAunt:
                return RelationshipEnumDto.GreatAunt;
            case RelationshipEnum.GreatNephew:
                return RelationshipEnumDto.GreatNephew;
            case RelationshipEnum.GreatNiece:
                return RelationshipEnumDto.GreatNiece;
            case RelationshipEnum.GreatGreatGrandfather:
                return RelationshipEnumDto.GreatGreatGrandfather;
            case RelationshipEnum.GreatGreatGrandmother:
                return RelationshipEnumDto.GreatGreatGrandmother;
            case RelationshipEnum.GreatGreatGrandson:
                return RelationshipEnumDto.GreatGreatGrandson;
            case RelationshipEnum.GreatGreatGranddaughter:
                return RelationshipEnumDto.GreatGreatGranddaughter;
            case RelationshipEnum.LegalGuardian:
                return RelationshipEnumDto.LegalGuardian;
            case RelationshipEnum.Other:
                return RelationshipEnumDto.Other;
        }
    }
}
