import { MaterialRepository } from "@entity/data/repositories/material.repository";
import { inject, injectable } from "inversify";
import { MaterialType } from "../../models/material/material-type.model";

@injectable()
export class GetAllMaterialTypesUseCase {
    constructor(
        @inject(MaterialRepository)
        private readonly materialRepository: MaterialRepository,
    ) {}

    async execute(): Promise<MaterialType[]> {
        const typesResult = await this.materialRepository.getAllMaterialTypes();

        return typesResult.getOrElse([]);
    }
}
