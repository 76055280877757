import { Nullable } from "@core/domain/types/nullable.type";
import { ActionLineEnumDto } from "@entity/data/dto/action-line-enum.dto";
import { ActionLineEnum } from "@entity/domain/models/action-line.model";
import { injectable } from "inversify";

@injectable()
export class ActionLineEnumMapper {
    map(actionLineEnumDto: ActionLineEnumDto): Nullable<ActionLineEnum> {
        switch (actionLineEnumDto) {
            case ActionLineEnumDto.SupportVictims:
                return ActionLineEnum.SupportVictims;
            case ActionLineEnumDto.Digitalization:
                return ActionLineEnum.Digitalization;
            case ActionLineEnumDto.DevelopmentCooperationAid:
                return ActionLineEnum.DevelopmentCooperationAid;
            case ActionLineEnumDto.ProfessionalTrainingThirdSector:
                return ActionLineEnum.ProfessionalTrainingThirdSector;
            case ActionLineEnumDto.EducationTraining:
                return ActionLineEnum.EducationTraining;
            case ActionLineEnumDto.SocialEmploymentInclusion:
                return ActionLineEnum.SocialEmploymentInclusion;
            case ActionLineEnumDto.KnowledgeGenerationEvaluation:
                return ActionLineEnum.KnowledgeGenerationEvaluation;
            case ActionLineEnumDto.Innovation:
                return ActionLineEnum.Innovation;
            case ActionLineEnumDto.TrainingBeneficiaries:
                return ActionLineEnum.TrainingBeneficiaries;
            case ActionLineEnumDto.PromotionPersonalAutonomy:
                return ActionLineEnum.PromotionPersonalAutonomy;
            case ActionLineEnumDto.TolerancePromotion:
                return ActionLineEnum.TolerancePromotion;
            case ActionLineEnumDto.LeisureTime:
                return ActionLineEnum.LeisureTime;
            case ActionLineEnumDto.PersonalizedGuidanceAdvice:
                return ActionLineEnum.PersonalizedGuidanceAdvice;
            case ActionLineEnumDto.PreventionAwareness:
                return ActionLineEnum.PreventionAwareness;
            case ActionLineEnumDto.EquialityPromotion:
                return ActionLineEnum.EquialityPromotion;
            case ActionLineEnumDto.HealthPromotion:
                return ActionLineEnum.HealthPromotion;
            case ActionLineEnumDto.PromotionSustainableDevelopmentGoals:
                return ActionLineEnum.PromotionSustainableDevelopmentGoals;
            case ActionLineEnumDto.PromotionVolunteeringCitizenParticipation:
                return ActionLineEnum.PromotionVolunteeringCitizenParticipation;
            case ActionLineEnumDto.ProtectionMinorsRights:
                return ActionLineEnum.ProtectionMinorsRights;
            case ActionLineEnumDto.ResidentialResources:
                return ActionLineEnum.ResidentialResources;
            case ActionLineEnumDto.Return:
                return ActionLineEnum.Return;
            case ActionLineEnumDto.SocialEmergencyBasicNeedsSupport:
                return ActionLineEnum.SocialEmergencyBasicNeedsSupport;
            case ActionLineEnumDto.EquityAdaptationProperties:
                return ActionLineEnum.EquityAdaptationProperties;
            case ActionLineEnumDto.Other:
                return ActionLineEnum.Other;
            default:
                return null;
        }
    }

    mapToDto(actionLineEnum: ActionLineEnum): ActionLineEnumDto {
        // eslint-disable-next-line default-case
        switch (actionLineEnum) {
            case ActionLineEnum.SupportVictims:
                return ActionLineEnumDto.SupportVictims;
            case ActionLineEnum.Digitalization:
                return ActionLineEnumDto.Digitalization;
            case ActionLineEnum.DevelopmentCooperationAid:
                return ActionLineEnumDto.DevelopmentCooperationAid;
            case ActionLineEnum.ProfessionalTrainingThirdSector:
                return ActionLineEnumDto.ProfessionalTrainingThirdSector;
            case ActionLineEnum.EducationTraining:
                return ActionLineEnumDto.EducationTraining;
            case ActionLineEnum.SocialEmploymentInclusion:
                return ActionLineEnumDto.SocialEmploymentInclusion;
            case ActionLineEnum.KnowledgeGenerationEvaluation:
                return ActionLineEnumDto.KnowledgeGenerationEvaluation;
            case ActionLineEnum.Innovation:
                return ActionLineEnumDto.Innovation;
            case ActionLineEnum.TrainingBeneficiaries:
                return ActionLineEnumDto.TrainingBeneficiaries;
            case ActionLineEnum.PromotionPersonalAutonomy:
                return ActionLineEnumDto.PromotionPersonalAutonomy;
            case ActionLineEnum.TolerancePromotion:
                return ActionLineEnumDto.TolerancePromotion;
            case ActionLineEnum.LeisureTime:
                return ActionLineEnumDto.LeisureTime;
            case ActionLineEnum.PersonalizedGuidanceAdvice:
                return ActionLineEnumDto.PersonalizedGuidanceAdvice;
            case ActionLineEnum.PreventionAwareness:
                return ActionLineEnumDto.PreventionAwareness;
            case ActionLineEnum.EquialityPromotion:
                return ActionLineEnumDto.EquialityPromotion;
            case ActionLineEnum.HealthPromotion:
                return ActionLineEnumDto.HealthPromotion;
            case ActionLineEnum.PromotionSustainableDevelopmentGoals:
                return ActionLineEnumDto.PromotionSustainableDevelopmentGoals;
            case ActionLineEnum.PromotionVolunteeringCitizenParticipation:
                return ActionLineEnumDto.PromotionVolunteeringCitizenParticipation;
            case ActionLineEnum.ProtectionMinorsRights:
                return ActionLineEnumDto.ProtectionMinorsRights;
            case ActionLineEnum.ResidentialResources:
                return ActionLineEnumDto.ResidentialResources;
            case ActionLineEnum.Return:
                return ActionLineEnumDto.Return;
            case ActionLineEnum.SocialEmergencyBasicNeedsSupport:
                return ActionLineEnumDto.SocialEmergencyBasicNeedsSupport;
            case ActionLineEnum.EquityAdaptationProperties:
                return ActionLineEnumDto.EquityAdaptationProperties;
            case ActionLineEnum.Other:
                return ActionLineEnumDto.Other;
        }
    }
}
