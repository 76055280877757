import { LanguageRepository } from "@core/data/repositories/language.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { LanguageIsoCodeEnum } from "../models/language.model";
import { Nullable } from "../types/nullable.type";

@injectable()
export class GetCurrentLanguageUseCase {
    constructor(
        @inject(LanguageRepository)
        private readonly languageRepository: LanguageRepository,
    ) {}

    async execute(): Promise<Nullable<LanguageIsoCodeEnum>> {
        const currentLanguageResult =
            await this.languageRepository.getCurrent();

        if (currentLanguageResult.isLeft()) {
            ToastManagerStore.error(
                currentLanguageResult.getLeftOrThrow().message,
            );
        }

        return currentLanguageResult.fold(
            () => null,
            (language) => language,
        );
    }
}
