import { CreateExternalDerivationBody } from "@beneficiary/data/dto/external-derivation/create-external-derivation.body";
import { EditExternalDerivationBody } from "@beneficiary/data/dto/external-derivation/edit-external-derivation.body";
import {
    ExternalDerivationDto,
    ExternalDerivationsDto,
    ExternalDerivationsQuery,
} from "@beneficiary/data/dto/external-derivation/external-derivation.dto";
import { CreateExternalDerivationMapper } from "@beneficiary/data/mappers/external-derivation/create-external-derivation.mapper";
import { EditExternalDerivationMapper } from "@beneficiary/data/mappers/external-derivation/edit-external-derivation.mapper";
import { ExternalDerivationsMapper } from "@beneficiary/data/mappers/external-derivation/external-derivations.mapper";
import { CreateExternalDerivation } from "@beneficiary/domain/models/external-derivation/create-external-derivation.model";
import { EditExternalDerivation } from "@beneficiary/domain/models/external-derivation/edit-external-derivation.model";
import {
    ExternalDerivation,
    ExternalDerivations,
} from "@beneficiary/domain/models/external-derivation/external-derivation.model";
import { coreTypes } from "@core/core-types.di";
import { type Http } from "@core/data/infrastructures/http/http";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const EXTERNAL_DERIVATIONS_PATH = "/external_referrals/";

@injectable()
export class ExternalDerivationDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(ExternalDerivationsMapper)
        private readonly externalDerivationsMapper: ExternalDerivationsMapper,
        @inject(CreateExternalDerivationMapper)
        private readonly createExternalDerivationMapper: CreateExternalDerivationMapper,
        @inject(EditExternalDerivationMapper)
        private readonly editExternalDerivationMapper: EditExternalDerivationMapper,
    ) {}

    async fetchAllBy(
        beneficiaryId: number,
        pagination: Pagination,
    ): Promise<Either<FallbackError, ExternalDerivations>> {
        const query: ExternalDerivationsQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
            beneficiary_id: beneficiaryId,
        };

        const externalDerivationsResult =
            await this.http.get<ExternalDerivationDto>(
                EXTERNAL_DERIVATIONS_PATH,
                {
                    query,
                },
            );

        return externalDerivationsResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const externalDerivations = this.externalDerivationsMapper.map(
                    plainToClass(ExternalDerivationsDto, response.data),
                );

                return Either.Right(externalDerivations);
            });
    }

    async create(
        newExternalDerivation: CreateExternalDerivation,
    ): Promise<Either<FallbackError, ExternalDerivation>> {
        const externalDerivationBody =
            this.createExternalDerivationMapper.mapToCreateDto(
                newExternalDerivation,
            );

        const externalDerivationResult =
            await this.http.post<CreateExternalDerivationBody>(
                EXTERNAL_DERIVATIONS_PATH,
                externalDerivationBody,
            );

        return externalDerivationResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const externalDerivation =
                    this.externalDerivationsMapper.mapExternalDerivation(
                        plainToClass(ExternalDerivationDto, response.data),
                    );

                if (!externalDerivation)
                    return Either.Left(new FallbackError());

                return Either.Right(externalDerivation);
            });
    }

    async edit(
        editExternalDerivation: EditExternalDerivation,
    ): Promise<Either<FallbackError, ExternalDerivation>> {
        const editedExternalDerivation =
            this.editExternalDerivationMapper.mapToEditDto(
                editExternalDerivation,
            );

        const editExternalDerivationResult = await this.http.patch<
            ExternalDerivationDto,
            EditExternalDerivationBody
        >(
            `${EXTERNAL_DERIVATIONS_PATH}${editExternalDerivation.id}/`,
            editedExternalDerivation,
        );

        return editExternalDerivationResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const externalDerivation =
                    this.externalDerivationsMapper.mapExternalDerivation(
                        plainToClass(ExternalDerivationDto, response.data),
                    );

                if (!externalDerivation)
                    return Either.Left(new FallbackError());

                return Either.Right(externalDerivation);
            });
    }

    async delete(
        externalDerivationId: number,
    ): Promise<Either<FallbackError, true>> {
        const deleted = await this.http.delete(
            `${EXTERNAL_DERIVATIONS_PATH}${externalDerivationId}`,
        );

        return deleted.mapLeft(() => new FallbackError()).map(() => true);
    }
}
