import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectExpenseRepository } from "@project/data/repositories/project-expense.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteExpenseUseCase {
    constructor(
        @inject(ProjectExpenseRepository)
        private readonly projectExpenseRepository: ProjectExpenseRepository,
    ) {}

    async execute(expenseId: number): Promise<void> {
        const result = await this.projectExpenseRepository.delete(expenseId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }
    }
}
