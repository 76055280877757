import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { DateTime } from "luxon";
import { DerivationProceeding } from "../../domain/models/derivation-proceeding.model";
import { DerivationFormValuesValidated } from "../../presentation/components/modal/derivation-modal-form.component";
import { DerivationProceedingBody } from "../dto/derive-proceeding.body";

@injectable()
export class DeriveProceedingMapper {
    mapFromProceedingFormValues(
        proceedingModalForm: DerivationFormValuesValidated,
    ): DerivationProceeding {
        return new DerivationProceeding(
            DateTime.now(),
            proceedingModalForm.derivationDescription,
            proceedingModalForm.derivationEntity,
            proceedingModalForm.derivationActivities,
            proceedingModalForm.originEntity,
            proceedingModalForm.beneficiary,
            proceedingModalForm.project,
            proceedingModalForm.derivationDescription,
            proceedingModalForm.documents[0].id,
        );
    }

    map(
        derivatedProceeding: DerivationProceedingBody,
    ): Nullable<DerivationProceeding> {
        const errors = validateSync(derivatedProceeding);

        if (Boolean(errors.length)) {
            console.error(errors);
            return null;
        }

        return new DerivationProceeding(
            DateTime.fromISO(derivatedProceeding.derivation_date),
            derivatedProceeding.derivation_description,
            derivatedProceeding.derivation_entity,
            derivatedProceeding.derivation_activities,
            derivatedProceeding.origin_entity,
            derivatedProceeding.beneficiary,
            derivatedProceeding.project,
            derivatedProceeding.reason,
            derivatedProceeding.derivation_authorization_file,
        );
    }

    mapToDto(deriveProceeding: DerivationProceeding): DerivationProceedingBody {
        return {
            derivation_date: deriveProceeding.derivationDate.toISO() ?? "",
            derivation_description: deriveProceeding.derivationDescription,
            derivation_entity: deriveProceeding.derivationEntity,
            derivation_activities: deriveProceeding.derivationActivities,
            origin_entity: deriveProceeding.originEntity,
            beneficiary: deriveProceeding.beneficiary,
            project: deriveProceeding.project,
            reason: deriveProceeding.reason,
            derivation_authorization_file:
                deriveProceeding.derivationAuthorizationFile,
        };
    }
}
