import type { Nullable } from "@core/domain/types/nullable.type";
import {
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { ExpenseSubTypeEnumDto } from "./expense-sub-type.dto";
import { ExpenseTypeEnumDto } from "./expense-type.dto";
import { TransportTypeEnumDto } from "./transport-type.dto";

export class TravelDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsDateString()
    @IsNotEmpty()
    created!: string;

    @IsDateString()
    @IsNotEmpty()
    modified!: string;

    @IsBoolean()
    @IsNotEmpty()
    is_active!: boolean;

    @IsEnum(ExpenseTypeEnumDto)
    @IsNotEmpty()
    type_expense!: ExpenseTypeEnumDto;

    @IsEnum(ExpenseSubTypeEnumDto)
    @IsOptional()
    subtype_expense!: ExpenseSubTypeEnumDto;

    @IsString()
    @IsOptional()
    full_name!: Nullable<string>;

    @IsString()
    @IsOptional()
    identification_document!: Nullable<string>;

    @IsString()
    @IsOptional()
    address!: Nullable<string>;

    @IsString()
    @IsOptional()
    position_function!: Nullable<string>;

    @IsString()
    @IsOptional()
    travel_destination!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    date_departure!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    date_return!: Nullable<string>;

    @IsEnum(TransportTypeEnumDto)
    @IsOptional()
    transportation_method!: TransportTypeEnumDto;

    @IsString()
    @IsOptional()
    other_transport!: Nullable<string>;

    @IsString()
    // In case is not a personal vehicle
    @IsOptional()
    amount!: Nullable<string>;

    @IsString()
    // In case it is a personal vehicle
    @IsOptional()
    kilometers!: Nullable<string>;

    @IsString()
    @IsOptional()
    // In case it is a personal vehicle
    cost_per_kilometer!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    amount_allocated_to_project!: string;

    @IsString()
    @IsOptional()
    project_allocation_percentage!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    payment_date!: Nullable<string>;

    @IsNumber()
    @IsOptional()
    project_expense!: Nullable<number>;
}
