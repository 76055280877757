import { EmployeeRepository } from "@entity/data/repositories/employee.repository";
import { ProfessionalGroup } from "@entity/domain/models/employee/professional-group.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllProfessionalGroupsUseCase {
    constructor(
        @inject(EmployeeRepository)
        private readonly employeeRepository: EmployeeRepository,
    ) {}

    async execute(): Promise<ProfessionalGroup[]> {
        const groupsResult =
            await this.employeeRepository.getAllProfessionalGroups();

        return groupsResult.fold(
            () => [],
            (groups) => groups,
        );
    }
}
