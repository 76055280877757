import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { TechnicalProposalRepository } from "@project/data/repositories/technical-proposal/technical-proposal.repository";
import { TechnicalProposal } from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetTechnicalProposalsByIdUseCase {
    constructor(
        @inject(TechnicalProposalRepository)
        private readonly technicalProposalRepository: TechnicalProposalRepository,
    ) {}

    async execute(
        technicalProposalId: number,
    ): Promise<Nullable<TechnicalProposal>> {
        const technicalProposalResult =
            await this.technicalProposalRepository.getById(technicalProposalId);

        if (technicalProposalResult.isLeft()) {
            ToastManagerStore.error(
                technicalProposalResult.getLeftOrThrow().message,
            );
        }

        return technicalProposalResult.fold(
            () => null,
            (technicalProposal) => technicalProposal,
        );
    }
}
