import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { IndicatorSearchFilters } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { IndicatorRepository } from "@project/data/repositories/technical-proposal/indicator.repository";
import { inject, injectable } from "inversify";

@injectable()
export class ExportIndicatorsByUseCase {
    constructor(
        @inject(IndicatorRepository)
        private readonly indicatorRepository: IndicatorRepository,
    ) {}

    async execute(
        technicalProposalId: number,
        filters?: IndicatorSearchFilters,
    ): Promise<Blob> {
        const indicatorsResult =
            await this.indicatorRepository.exportIndicatorsBy(
                technicalProposalId,
                filters,
            );

        if (indicatorsResult.isLeft()) {
            ToastManagerStore.error(indicatorsResult.getLeftOrThrow().message);
        }

        return indicatorsResult.getOrThrow();
    }
}
