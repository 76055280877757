import { CreateInternalNoteMapper } from "@beneficiary/data/mappers/internal-notes/create-internal-note.mapper";
import { EditInternalNoteMapper } from "@beneficiary/data/mappers/internal-notes/edit-internal-note.mapper";
import { InternalNote } from "@beneficiary/domain/models/internal-notes/internal-note.model";
import { CreateInternalNoteUseCase } from "@beneficiary/domain/usecases/internal-notes/create-internal-note.usecase";
import { DeleteInternalNoteUseCase } from "@beneficiary/domain/usecases/internal-notes/delete-internal-note.usecase";
import { EditInternalNoteUseCase } from "@beneficiary/domain/usecases/internal-notes/edit-internal-note.usecase";
import { GetAllInternalNotesByUsecase } from "@beneficiary/domain/usecases/internal-notes/get-all-internal-notes-by.usecase";
import { InternalNoteFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/internal-note/form/internal-note-form";
import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class InternalNoteTabViewModel extends BaseViewModel {
    beneficiaryId: Undefinable<number> = undefined;

    @observable
    internalNotes: InternalNote[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddInternalNoteModal: boolean = false;

    @observable
    showEditInternalNoteModal: boolean = false;

    @observable
    internalNoteToEdit: Undefinable<InternalNote> = undefined;

    constructor(
        @inject(CreateInternalNoteMapper)
        private readonly createInternalNoteMapper: CreateInternalNoteMapper,
        @inject(CreateInternalNoteUseCase)
        private readonly createInternalNoteUseCase: CreateInternalNoteUseCase,
        @inject(DeleteInternalNoteUseCase)
        private readonly deleteInternalNoteUseCase: DeleteInternalNoteUseCase,
        @inject(EditInternalNoteMapper)
        private readonly editInternalNoteMapper: EditInternalNoteMapper,
        @inject(EditInternalNoteUseCase)
        private readonly editInternalNoteUseCase: EditInternalNoteUseCase,
        @inject(GetAllInternalNotesByUsecase)
        private readonly getAllInternalNotesByUsecase: GetAllInternalNotesByUsecase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getInternalNotes();

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getInternalNotes(): Promise<void> {
        if (this.beneficiaryId === undefined)
            throw Error("Missing beneficiaryId");

        const internalNotes = await this.getAllInternalNotesByUsecase.execute(
            this.beneficiaryId,
        );
        runInAction(() => {
            this.internalNotes = internalNotes;
        });
    }

    @action
    setShowAddInternalNoteModal(show: boolean): void {
        this.showAddInternalNoteModal = show;
    }

    @action
    setShowEditInternalNoteModal(
        internalNote: Undefinable<InternalNote>,
        show: boolean,
    ): void {
        this.internalNoteToEdit = internalNote;
        this.showEditInternalNoteModal = show;
    }

    async addInternalNote(
        beneficiaryId: Undefinable<number>,
        internalNote: InternalNoteFormValuesValidated,
    ): Promise<Nullable<InternalNote>> {
        if (!beneficiaryId) throw Error("Missing beneficiaryId");

        LoadLayoutStore.start();

        const createInternalNoteResult =
            await this.createInternalNoteUseCase.execute(
                this.createInternalNoteMapper.mapFromFormValues(
                    beneficiaryId,
                    internalNote,
                ),
            );

        if (createInternalNoteResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createInternalNoteResult;
    }

    async editInternalNote(
        beneficiaryId: number,
        internalNote: InternalNoteFormValuesValidated,
    ): Promise<void> {
        if (!internalNote.id || !beneficiaryId)
            throw Error("Missing internal note");

        LoadLayoutStore.start();

        const editInternalNoteResult =
            await this.editInternalNoteUseCase.execute(
                this.editInternalNoteMapper.mapFromModal(
                    internalNote.id,
                    beneficiaryId,
                    internalNote,
                ),
            );

        if (editInternalNoteResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadInternalNotes(): Promise<void> {
        await this.getInternalNotes();
    }

    async deleteInternalNote(internalNoteId: number): Promise<void> {
        LoadLayoutStore.start();

        const deleted =
            await this.deleteInternalNoteUseCase.execute(internalNoteId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }
}
