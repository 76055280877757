export enum DisabilityTypeEnum {
    Disability1 = "disability_1",
    Disability2 = "disability_2",
    Disability3 = "disability_3",
    Disability4 = "disability_4",
    Disability5 = "disability_5",
    DisabilityNotRecognized = "disability_not_recognized",
}

export class DisabilityType {
    constructor(
        public readonly id: DisabilityTypeEnum,
        public readonly label: string,
    ) {}
}
