import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { ProjectMaterialDatasource } from "@project/data/datasource/project-material.datasource";
import { CreateProjectMaterial } from "@project/domain/models/project-material/create-project-material.model";
import {
    ProjectMaterial,
    SearchAllProjectMaterialBy,
} from "@project/domain/models/project-material/project-material.model";
import { inject, injectable } from "inversify";

@injectable()
export class ProjectMaterialRepository {
    constructor(
        @inject(ProjectMaterialDatasource)
        private readonly projectMaterialDatasource: ProjectMaterialDatasource,
    ) {}

    async create(
        projectMaterial: CreateProjectMaterial,
    ): Promise<Either<ValidationError | FallbackError, ProjectMaterial>> {
        return this.projectMaterialDatasource.create(projectMaterial);
    }

    async edit(
        projectMaterial: ProjectMaterial,
    ): Promise<Either<ValidationError | FallbackError, ProjectMaterial>> {
        return this.projectMaterialDatasource.edit(projectMaterial);
    }

    async getAllBy(
        filters?: SearchAllProjectMaterialBy,
    ): Promise<Either<FallbackError, ProjectMaterial[]>> {
        return this.projectMaterialDatasource.fetchAllBy(filters);
    }

    async delete(materialId: number): Promise<Either<FallbackError, true>> {
        return this.projectMaterialDatasource.delete(materialId);
    }
}
