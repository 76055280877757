import { DisabilityTypeDto } from "@beneficiary/data/dto/disability-type-enum.dto";
import { DisabilityType } from "@beneficiary/domain/models/disability-type.enum";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DisabilityTypesEnumMapper } from "./disability-type-enum.mapper";

@injectable()
export class DisabilityTypesMapper {
    constructor(
        @inject(DisabilityTypesEnumMapper)
        private readonly disabilityTypesEnumMapper: DisabilityTypesEnumMapper,
    ) {}
    map(disabilityTypeDto: DisabilityTypeDto): Nullable<DisabilityType> {
        const errors = validateSync(DisabilityTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const disabilityTypeId = this.disabilityTypesEnumMapper.map(
            disabilityTypeDto.key,
        );

        if (!disabilityTypeId) return null;

        return new DisabilityType(disabilityTypeId, disabilityTypeDto.value);
    }
}
