import { Pagination } from "@core/domain/models/pagination";
import { AuthorityManagerRepository } from "@entity/data/repositories/authority-manager.repository";
import { AuthorityManager } from "@entity/domain/models/authority-manager/authority-manager.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllAuthorityManagersUsecase {
    constructor(
        @inject(AuthorityManagerRepository)
        private readonly authorityManagerRepository: AuthorityManagerRepository,
    ) {}

    async execute(): Promise<AuthorityManager[]> {
        const authorityManagerResults =
            await this.authorityManagerRepository.getAll(
                Pagination.NoPagination(),
            );

        return authorityManagerResults.fold(
            () => [],
            (authorityManagers) => authorityManagers.authorityManagers,
        );
    }
}
