import { DateTime } from "luxon";

export class DerivationProceeding {
    constructor(
        public derivationDate: DateTime,
        public derivationDescription: string,
        public derivationEntity: number,
        public derivationActivities: number[],
        public originEntity: number,
        public beneficiary: number,
        public project: number,
        public reason: string,
        public derivationAuthorizationFile: number,
    ) {}
}
