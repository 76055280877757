import { Nullable } from "@core/domain/types/nullable.type";
import { IdentificationType } from "@entity/domain/models/identification-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { IdentificationTypeDto } from "../../dto/identification-type.dto";
import { IdentificationEnumMapper } from "./identification-enum.mapper";

@injectable()
export class IdentificationTypeMapper {
    constructor(
        @inject(IdentificationEnumMapper)
        private readonly identificationEnumMapper: IdentificationEnumMapper,
    ) {}
    map(
        identificationTypeDto: IdentificationTypeDto,
    ): Nullable<IdentificationType> {
        const errors = validateSync(identificationTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.identificationEnumMapper.map(identificationTypeDto.key);
        if (!id) return null;

        return new IdentificationType(id, identificationTypeDto.value);
    }
}
