import {
    Relative,
    RelativesSearchFilters,
} from "@beneficiary/domain/models/relatives/relative.model";
import { GetAllRelativesUsecase } from "@beneficiary/domain/usecases/relatives/get-all-relatives.usecase";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class BeneficiaryFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    _beneficiaryId: Undefinable<number> = undefined;

    @observable
    relatives: Relative[] = [];

    @observable
    relativesNetSalary: Undefinable<number> = 0;

    constructor(
        @inject(GetAllRelativesUsecase)
        private readonly getAllRelativesUseCase: GetAllRelativesUsecase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    @action
    async initViewData(): Promise<void> {
        if (!!this._beneficiaryId) {
            await this.getAllRelatives();
        }

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    async getAllRelatives(): Promise<void> {
        const filters: RelativesSearchFilters = {
            beneficiaryId: this._beneficiaryId,
        };
        const relatives = await this.getAllRelativesUseCase.execute(filters);
        runInAction(() => {
            this.relatives = relatives;
        });
        await this.calculateRelativesNetSalary();
    }

    @action
    async calculateRelativesNetSalary(): Promise<void> {
        let totalSalary = 0;
        this.relatives.map((relative) => {
            totalSalary += relative.netSalary ?? 0;
            return totalSalary;
        });

        runInAction(() => {
            this.relativesNetSalary = totalSalary;
        });
    }
}
