export enum TerritorialScopeEnum {
    LOCAL = "LOCAL",
    REGIONAL = "REGIONAL",
    NATIONAL = "NATIONAL",
    INTERNATIONAL = "INTERNATIONAL",
}

export class TerritorialScope {
    constructor(
        public readonly id: TerritorialScopeEnum,
        public readonly label: string,
    ) {}
}
