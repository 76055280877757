import { Nullable } from "@core/domain/types/nullable.type";
import { BudgetColumnTypeDto } from "@project/data/dto/budget/column/budget-column-type.dto";
import { BudgetColumnTypeEnumMapper } from "@project/data/mappers/budget/columns/budget-column-type-enum.mapper";
import {
    BudgetColumnType,
    BudgetColumnTypeEnum,
} from "@project/domain/models/budget/budget-column/budget-column-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetColumnTypeMapper {
    constructor(
        @inject(BudgetColumnTypeEnumMapper)
        private readonly budgetColumnTypeEnumMapper: BudgetColumnTypeEnumMapper,
    ) {}

    map(budgetColumnTypeDto: BudgetColumnTypeDto): Nullable<BudgetColumnType> {
        const errors = validateSync(budgetColumnTypeDto);
        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<BudgetColumnTypeEnum> =
            this.budgetColumnTypeEnumMapper.map(budgetColumnTypeDto.key);

        if (!id) return null;

        return new BudgetColumnType(id, budgetColumnTypeDto.value);
    }
}
