import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { IndicatorRepository } from "@project/data/repositories/technical-proposal/indicator.repository";
import {
    EditIndicator,
    Indicator,
} from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditIndicatorUseCase {
    constructor(
        @inject(IndicatorRepository)
        private readonly indicatorRepository: IndicatorRepository,
    ) {}

    async execute(local: EditIndicator): Promise<Nullable<Indicator>> {
        const indicatorResult = await this.indicatorRepository.edit(local);

        if (indicatorResult.isLeft()) {
            ToastManagerStore.error(indicatorResult.getLeftOrThrow().message);
        }

        return indicatorResult.fold(
            () => null,
            (editedIndicator) => editedIndicator,
        );
    }
}
