import { Pagination } from "@core/domain/models/pagination";
import { CreditorRepository } from "@entity/data/repositories/creditor.repository";
import {
    CreditorSearchFilters,
    Creditors,
} from "@entity/domain/models/creditor/creditor.model";

import { inject, injectable } from "inversify";

@injectable()
export class GetCreditorsByUseCase {
    constructor(
        @inject(CreditorRepository)
        private readonly creditorRepository: CreditorRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: CreditorSearchFilters,
    ): Promise<Creditors> {
        const creditorsResult = await this.creditorRepository.getBy(
            pagination,
            filters,
        );

        return creditorsResult.fold(
            () => new Creditors([], 0),
            (creditors) => creditors,
        );
    }
}
