import { Nullable } from "@core/domain/types/nullable.type";
import {
    AffiliationDto,
    AffiliationsDto,
} from "@entity/data/dto/affiliation/affiliation.dto";
import { AffiliationMapper } from "@entity/data/mappers/affiliation/affiliation.mapper";
import {
    Affiliation,
    Affiliations,
} from "@entity/domain/models/affiliation/affiliation.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class AffiliationsMapper {
    constructor(
        @inject(AffiliationMapper)
        private readonly affiliationMapper: AffiliationMapper,
    ) {}

    map(affiliationsDto: AffiliationsDto): Affiliations {
        const errors = validateSync(affiliationsDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Affiliations([], 0);
        }

        const affiliations = affiliationsDto.results.mapNotNull(
            (affiliationDto) => this.affiliationMapper.map(affiliationDto),
        );

        return new Affiliations(affiliations, affiliationsDto.count);
    }

    mapAffiliation(affiliationDto: AffiliationDto): Nullable<Affiliation> {
        if (!affiliationDto.id) {
            return null;
        }

        const affiliation: Affiliation = {
            id: affiliationDto.id,
            identification: affiliationDto.identification,
            name: affiliationDto.name,
            territorialScope: affiliationDto.territorial_scope,
            entity: affiliationDto.entity,
            webAddress: affiliationDto.web_address ?? null,
            incorporationDate: affiliationDto.incorporation_date
                ? DateTime.fromISO(affiliationDto.incorporation_date)
                : null,
            contactPerson: affiliationDto.contact_person ?? null,
            contactPhone: affiliationDto.contact_phone ?? null,
            contactEmail: affiliationDto.contact_email ?? null,
        };
        return affiliation;
    }
}
