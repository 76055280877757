import { Pagination } from "@core/domain/models/pagination";
import { EmployeeRepository } from "@entity/data/repositories/employee.repository";
import { inject, injectable } from "inversify";
import {
    EmployeeSearchFilters,
    Employees,
} from "../../models/employee/employee.model";

@injectable()
export class GetEmployeesByUseCase {
    constructor(
        @inject(EmployeeRepository)
        private readonly employeeRepository: EmployeeRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: EmployeeSearchFilters,
    ): Promise<Employees> {
        const employeesResult = await this.employeeRepository.searchAllBy(
            pagination,
            filters,
        );

        return employeesResult.fold(
            () => new Employees([], 0),
            (employees) => employees,
        );
    }
}
