import { DisabilityTypeEnumDto } from "@beneficiary/data/dto/disability-type-enum.dto";
import { DisabilityTypeEnum } from "@beneficiary/domain/models/disability-type.enum";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";

@injectable()
export class DisabilityTypesEnumMapper {
    map(
        disabilityTypeDto: DisabilityTypeEnumDto,
    ): Nullable<DisabilityTypeEnum> {
        switch (disabilityTypeDto) {
            case DisabilityTypeEnumDto.Disability1:
                return DisabilityTypeEnum.Disability1;
            case DisabilityTypeEnumDto.Disability2:
                return DisabilityTypeEnum.Disability2;
            case DisabilityTypeEnumDto.Disability3:
                return DisabilityTypeEnum.Disability3;
            case DisabilityTypeEnumDto.Disability4:
                return DisabilityTypeEnum.Disability4;
            case DisabilityTypeEnumDto.Disability5:
                return DisabilityTypeEnum.Disability5;
            case DisabilityTypeEnumDto.DisabilityNotRecognized:
                return DisabilityTypeEnum.DisabilityNotRecognized;

            default:
                return null;
        }
    }

    mapToDto(disabilityType: DisabilityTypeEnum): DisabilityTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (disabilityType) {
            case DisabilityTypeEnum.Disability1:
                return DisabilityTypeEnumDto.Disability1;
            case DisabilityTypeEnum.Disability2:
                return DisabilityTypeEnumDto.Disability2;
            case DisabilityTypeEnum.Disability3:
                return DisabilityTypeEnumDto.Disability3;
            case DisabilityTypeEnum.Disability4:
                return DisabilityTypeEnumDto.Disability4;
            case DisabilityTypeEnum.Disability5:
                return DisabilityTypeEnumDto.Disability5;
            case DisabilityTypeEnum.DisabilityNotRecognized:
                return DisabilityTypeEnumDto.DisabilityNotRecognized;
        }
    }
}
