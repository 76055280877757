import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum SubsidyReasonEnumDto {
    disability = "disability",
    ett_worker = "ett_worker",
    conciliation = "conciliation",
    employment_preservation = "employment_preservation",
    protected_collective = "protected_collective",
    special_age_group = "special_age_group",
    made_indedfinite = "made_indedfinite",
    other = "other",
}

export class SubsidyReasonDto {
    @IsEnum(SubsidyReasonEnumDto)
    @IsNotEmpty()
    key!: SubsidyReasonEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
