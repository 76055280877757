import { coreTypes } from "@core/core-types.di";
import { EXPAND_ALL, FieldsQuery } from "@core/data/dto/fields.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { CreateProjectMaterialBody } from "@project/data/dto/materials/create-project-material.body";
import { EditProjectMaterialBody } from "@project/data/dto/materials/edit-project-material.dto";
import {
    ProjectMaterialDto,
    ProjectMaterialsDto,
    ProjectMaterialsQuery,
} from "@project/data/dto/materials/project-material.dto";
import { CreateProjectMaterialMapper } from "@project/data/mappers/materials/create-project-material.mapper";
import { EditProjectMaterialMapper } from "@project/data/mappers/materials/edit-project-material.mapper";
import { ProjectMaterialMapper } from "@project/data/mappers/materials/project-material.mapper";
import { CreateProjectMaterial } from "@project/domain/models/project-material/create-project-material.model";
import { EditProjectMaterial } from "@project/domain/models/project-material/edit-project-material.model";
import {
    ProjectMaterial,
    SearchAllProjectMaterialBy,
} from "@project/domain/models/project-material/project-material.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class ProjectMaterialDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(CreateProjectMaterialMapper)
        private readonly createProjectMaterialMapper: CreateProjectMaterialMapper,
        @inject(EditProjectMaterialMapper)
        private readonly editProjectMaterialMapper: EditProjectMaterialMapper,
        @inject(ProjectMaterialMapper)
        private readonly projectMaterialMapper: ProjectMaterialMapper,
    ) {}

    async create(
        newProjectMaterial: CreateProjectMaterial,
    ): Promise<Either<ValidationError | FallbackError, ProjectMaterial>> {
        const createdProjectMaterialDto =
            this.createProjectMaterialMapper.mapToDto(newProjectMaterial);

        const createProjectMaterialResult = await this.http.post<
            ProjectMaterialDto,
            CreateProjectMaterialBody
        >("/material_projects/", createdProjectMaterialDto);

        return createProjectMaterialResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdProjectMaterial = this.projectMaterialMapper.map(
                    plainToClass(ProjectMaterialDto, response.data),
                );

                if (!createdProjectMaterial)
                    return Either.Left(new FallbackError());

                return Either.Right(createdProjectMaterial);
            });
    }

    async edit(
        projectMaterial: EditProjectMaterial,
    ): Promise<Either<ValidationError | FallbackError, ProjectMaterial>> {
        const editedProjectMaterialDto =
            this.editProjectMaterialMapper.mapToDto(projectMaterial);

        const editProjectMaterialResult = await this.http.patch<
            ProjectMaterialDto,
            EditProjectMaterialBody
        >(
            `/material_projects/${projectMaterial.id}/`,
            editedProjectMaterialDto,
        );

        return editProjectMaterialResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const editedProjectMaterial = this.projectMaterialMapper.map(
                    plainToClass(ProjectMaterialDto, response.data),
                );

                if (!editedProjectMaterial)
                    return Either.Left(new FallbackError());

                return Either.Right(editedProjectMaterial);
            });
    }

    async fetchAllBy(
        filters?: SearchAllProjectMaterialBy,
    ): Promise<Either<FallbackError, ProjectMaterial[]>> {
        const query: ProjectMaterialsQuery & FieldsQuery = {
            expand: EXPAND_ALL,
        };
        if (filters?.projectId) query.project = filters.projectId;
        if (filters?.materialId) query.material = filters.materialId;

        const projectMaterialResult = await this.http.get<ProjectMaterialsDto>(
            `/material_projects/`,
            {
                query,
            },
        );

        return projectMaterialResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.results.mapNotNull((projectMaterial) =>
                    this.projectMaterialMapper.map(
                        plainToClass(ProjectMaterialDto, projectMaterial),
                    ),
                ),
            );
    }

    async delete(materialId: number): Promise<Either<FallbackError, true>> {
        const deleteProjectMaterialResult = await this.http.delete(
            `/material_projects/${materialId}/`,
        );

        return deleteProjectMaterialResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
