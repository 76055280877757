import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreatePublicInterestMapper } from "@entity/data/mappers/public-interest/create-public-interest.mapper";
import { EditPublicInterestMapper } from "@entity/data/mappers/public-interest/edit-public-interest.mapper";
import { PublicInterest } from "@entity/domain/models/public-interest/public-interest.model";
import { CreatePublicInterestUseCase } from "@entity/domain/usecases/public-interest/create-public-interest.usecase";
import { DeletePublicInterestUseCase } from "@entity/domain/usecases/public-interest/delete-public-interest.usecase";
import { EditPublicInterestUseCase } from "@entity/domain/usecases/public-interest/edit-public-interest.usecase";
import { GetAllPublicInterestsUsecase } from "@entity/domain/usecases/public-interest/get-all-public-interests.usecase";
import { PublicInterestFormValuesValidated } from "@entity/presentation/component/entity-form/general/public-interest/form/public-interest-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class PublicInterestViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    publicInterests: PublicInterest[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddPublicInterestModal: boolean = false;

    @observable
    showEditPublicInterestModal: boolean = false;

    @observable
    publicInterestToEdit: Undefinable<PublicInterest> = undefined;

    constructor(
        @inject(GetAllPublicInterestsUsecase)
        private readonly getAllPublicInterestsUsecase: GetAllPublicInterestsUsecase,
        @inject(CreatePublicInterestMapper)
        private readonly createPublicInterestMapper: CreatePublicInterestMapper,
        @inject(EditPublicInterestMapper)
        private readonly editPublicInterestMapper: EditPublicInterestMapper,
        @inject(CreatePublicInterestUseCase)
        private readonly createPublicInterestUseCase: CreatePublicInterestUseCase,
        @inject(EditPublicInterestUseCase)
        private readonly editPublicInterestUseCase: EditPublicInterestUseCase,
        @inject(DeletePublicInterestUseCase)
        private readonly deletePublicInterestUseCase: DeletePublicInterestUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getPublicInterests()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getPublicInterests(): Promise<void> {
        const publicInterests =
            await this.getAllPublicInterestsUsecase.execute();
        runInAction(() => {
            this.publicInterests = publicInterests;
        });
    }

    @action
    setShowAddPublicInterestModal(show: boolean): void {
        this.showAddPublicInterestModal = show;
    }

    @action
    closeEditPublicInterestModal(): void {
        this.showEditPublicInterestModal = false;
    }

    @action
    openEditPublicInterestModal(
        publicInterest: Undefinable<PublicInterest>,
    ): void {
        this.publicInterestToEdit = publicInterest;
        this.showEditPublicInterestModal = true;
    }

    async addPublicInterest(
        entityId: Undefinable<number>,
        values: PublicInterestFormValuesValidated,
    ): Promise<Nullable<PublicInterest>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createPublicInterestResult =
            await this.createPublicInterestUseCase.execute(
                this.createPublicInterestMapper.mapFromFormValues(
                    entityId,
                    values,
                ),
            );

        if (createPublicInterestResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createPublicInterestResult;
    }

    async editPublicInterest(
        values: PublicInterestFormValuesValidated,
    ): Promise<void> {
        if (!this.publicInterestToEdit?.id || !this.publicInterestToEdit.entity)
            throw Error("Missing publicInterest");

        LoadLayoutStore.start();
        const editPublicInterestResult =
            await this.editPublicInterestUseCase.execute(
                this.editPublicInterestMapper.mapFromModal(
                    this.publicInterestToEdit.id,
                    this.publicInterestToEdit.entity,
                    values,
                ),
            );

        if (editPublicInterestResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadPublicInterests(): Promise<void> {
        await this.getPublicInterests();
    }

    async deletePublicInterest(publicInterestId: number): Promise<void> {
        LoadLayoutStore.start();

        await this.deletePublicInterestUseCase.execute(publicInterestId);

        LoadLayoutStore.finish();
    }
}
