import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum SupplierIdentificationTypeEnumDto {
    nif = "nif",
    cif = "cif",
}

export class SupplierIdentificationTypeDto {
    @IsEnum(SupplierIdentificationTypeEnumDto)
    @IsNotEmpty()
    key!: SupplierIdentificationTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
