import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateSupplierMapper } from "@entity/data/mappers/supplier/create-supplier.mapper";
import { Supplier } from "@entity/domain/models/supplier/supplier.model";
import { CreateSupplierUseCase } from "@entity/domain/usecases/supplier/create-supplier.usecase";
import { SupplierFormValuesValidated } from "@entity/presentation/component/supplier-form/supplier-form.component";
import { inject, injectable } from "inversify";
import { makeObservable, observable } from "mobx";

@injectable()
export class CreateSupplierPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    constructor(
        @inject(CreateSupplierUseCase)
        private readonly createSupplierUseCase: CreateSupplierUseCase,
        @inject(CreateSupplierMapper)
        private readonly createSupplierMapper: CreateSupplierMapper,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();
    }

    async createSupplier(
        values: SupplierFormValuesValidated,
        entityId: number,
    ): Promise<Nullable<Supplier>> {
        LoadLayoutStore.start();

        const created = await this.createSupplierUseCase.execute(
            this.createSupplierMapper.mapFromSupplierFormValues(
                values,
                entityId,
            ),
        );

        if (created) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return created;
    }
}
