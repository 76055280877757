import { CostRepository } from "@entity/data/repositories/cost.repository";
import { IVAType } from "@entity/domain/models/cost/iva-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllIvaTypesUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(): Promise<IVAType[]> {
        const ivaTypesResult = await this.costRepository.getAllIvaTypes();

        return ivaTypesResult.fold(
            () => [],
            (ivaTypeResult) => ivaTypeResult,
        );
    }
}
