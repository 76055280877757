import { Pagination } from "@core/domain/models/pagination";
import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { Material } from "@entity/domain/models/material/material.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllMaterialsUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}
    async execute(): Promise<Material[]> {
        const materialsResult = await this.entityRepository.getMaterials(
            Pagination.NoPagination(),
        );

        return materialsResult.fold(
            () => [],
            (materials) => materials.materials,
        );
    }
}
