import { AddressRepository } from "@beneficiary/data/repositories/address.repository";
import {
    Address,
    CreateAddress,
} from "@beneficiary/domain/models/address.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class CreateAddressUseCase {
    constructor(
        @inject(AddressRepository)
        private readonly addressRepository: AddressRepository,
    ) {}

    async execute(address: CreateAddress): Promise<Nullable<Address>> {
        const creationResult = await this.addressRepository.create(address);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdAddress) => createdAddress,
        );
    }
}
