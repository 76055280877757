import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { IsUserLoggedUseCase } from "@authentication/domain/usecases/is-user-logged.usecase";
import { LoadCurrentUserUseCase } from "@authentication/domain/usecases/load-current-user.usecase";
import { EventBusRepository } from "@core/data/repositories/event-bus.repository";
import { UnauthorizedEventBus } from "@core/domain/models/event-bus.model";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { flow, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class DashboardLayoutViewModel extends BaseViewModel {
    @observable
    loading = true;

    get isUserLogged(): boolean {
        return this.isUserLoggedUseCase.execute();
    }

    constructor(
        @inject(EventBusRepository)
        private readonly eventBusRepository: EventBusRepository,
        @inject(IsUserLoggedUseCase)
        private readonly isUserLoggedUseCase: IsUserLoggedUseCase,
        @inject(LoadCurrentUserUseCase)
        private readonly loadCurrentUserUseCase: LoadCurrentUserUseCase,
        @inject(UserSessionRepository)
        private readonly userSessionRepository: UserSessionRepository,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        const userLoaded = await this.loadCurrentUser();

        // If we cant load user, we keep the loading going until we trigger the logout via event bus
        if (userLoaded) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async loadCurrentUser(): Promise<boolean> {
        const userInitialized = this.userSessionRepository.userSync !== null;

        if (this.isUserLogged && !userInitialized) {
            const loadedSuccessfully =
                await this.loadCurrentUserUseCase.execute();

            // We send the event so the app can make logout and redirect to login
            if (!loadedSuccessfully) {
                this.eventBusRepository.emit(new UnauthorizedEventBus());
                return false;
            }
        }

        return true;
    }
}
