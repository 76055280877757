import { Nullable } from "@core/domain/types/nullable.type";
import { CostTypeDto } from "@entity/data/dto/cost/cost-type.dto";
import { CostType } from "@entity/domain/models/cost/cost-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { CostTypeEnumMapper } from "./cost-type-enum.mapper";

@injectable()
export class CostTypeMapper {
    constructor(
        @inject(CostTypeEnumMapper)
        private readonly costTypeEnumMapper: CostTypeEnumMapper,
    ) {}

    map(costTypeDto: CostTypeDto): Nullable<CostType> {
        const errors = validateSync(costTypeDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.costTypeEnumMapper.map(costTypeDto.key);
        if (!id) return null;

        return new CostType(id, costTypeDto.value);
    }
}
