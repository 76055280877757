import { Pagination } from "@core/domain/models/pagination";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EmployeeType } from "@entity/domain/models/employee/employee-type.model";
import {
    Employees,
    type EmployeeSearchFilters,
} from "@entity/domain/models/employee/employee.model";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { GetAllEmployeeTypesUseCase } from "@entity/domain/usecases/employee/get-all-employee-types.usecase";
import { GetEmployeesByUseCase } from "@entity/domain/usecases/employee/get-employees-by.usecase";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

export interface EmployeeListTable {
    id: number;
    name: string;
    isActiveContract?: Undefinable<boolean>;
    identification: string;
    type: string;
}

interface EmployeesListTable {
    employees: EmployeeListTable[];
    count: number;
}

@injectable()
export class EmployeeListPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    employeeTypes: EmployeeType[] = [];

    @observable
    globalEntities: GlobalEntity[] = [];

    @computed
    get employeesTable(): EmployeesListTable {
        return {
            count: this._employees.count,
            employees: this._employees.employees.map((employee) => ({
                id: employee.id,
                name: employee.fullName,
                isActiveContract: employee.isActiveContract,
                identification: employee.fullIdentification,
                type: employee.typeLabel,
            })),
        };
    }

    @observable
    private _employees: Employees = new Employees([], 0);

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: EmployeeSearchFilters = {
        name: "",
        type: undefined,
        isActive: undefined,
        activeContract: true,
    };

    @observable
    filters: EmployeeSearchFilters = this.initialFiltersValue;

    constructor(
        @inject(GetAllEmployeeTypesUseCase)
        private readonly getAllEmployeeTypesUseCase: GetAllEmployeeTypesUseCase,
        @inject(GetEmployeesByUseCase)
        private readonly getEmployeesByUseCase: GetEmployeesByUseCase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.searchEmployeesBy(this.filters, this.pagination),
            this.getAllEmployeeTypes(),
            this.getAllGlobalEntities(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setFilters(filters: EmployeeSearchFilters): void {
        this.filters = filters;
        this.pagination.reset();

        this.searchEmployeesBy(this.filters, this.pagination);
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.searchEmployeesBy(this.filters, this.pagination);
    }

    async searchEmployeesBy(
        filters: EmployeeSearchFilters,
        pagination: Pagination,
    ): Promise<void> {
        const employees = await this.getEmployeesByUseCase.execute(
            pagination,
            filters,
        );
        runInAction(() => {
            this._employees = employees;
        });
    }

    async getAllEmployeeTypes(): Promise<void> {
        const employeeTypes = await this.getAllEmployeeTypesUseCase.execute();

        runInAction(() => {
            this.employeeTypes = employeeTypes;
        });
    }

    async getAllGlobalEntities(): Promise<void> {
        const globalEntities = await this.getAllGlobalEntitiesUseCase.execute();

        runInAction(() => {
            this.globalEntities = globalEntities;
        });
    }
}
