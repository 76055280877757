import { injectable } from "inversify";
import type { Subscription } from "rxjs";

@injectable()
export abstract class BaseViewModel {
    private compositeSubscriptions: Subscription[] = [];

    addSub(...subscriptions: Subscription[]): void {
        this.compositeSubscriptions =
            this.compositeSubscriptions.concat(subscriptions);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function,no-empty-function
    async didMount(): Promise<void> {}

    async willUnmount(): Promise<void> {
        this.compositeSubscriptions.forEach((subscription) =>
            subscription.unsubscribe(),
        );
        this.compositeSubscriptions = [];
    }
}
