export enum HttpErrorCodeEnum {
    GenericError = "0001",
    InvalidToken = "1001",
}

export interface HttpErrorResponse {
    code: HttpErrorCodeEnum;
    message?: string;
    // eslint-disable-next-line id-blacklist
    data?: Record<string, string[]>;
}
