export enum DietTypeEnum {
    MEALS = "meals",
    ACCOMMODATION = "accommodation",
    NONE = "none",
}

export class DietType {
    constructor(
        public readonly id: DietTypeEnum,
        public readonly label: string,
    ) {}
}
