import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { StatutoryTerritorialScopeRepository } from "@entity/data/repositories/statutory-territorail-scope.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteStatutoryTerritorialScopeUseCase {
    constructor(
        @inject(StatutoryTerritorialScopeRepository)
        private readonly statutoryTerritorialScopeRepository: StatutoryTerritorialScopeRepository,
    ) {}

    async execute(statutoryTerritorialScopeId: number): Promise<void> {
        const result = await this.statutoryTerritorialScopeRepository.delete(
            statutoryTerritorialScopeId,
        );

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        ToastManagerStore.success();
    }
}
