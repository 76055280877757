import { coreTypes } from "@core/core-types.di";
import { EXPAND_ALL, FieldsQuery } from "@core/data/dto/fields.dto";
import { PaginatedQueryDto } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import {
    CreateCreditor,
    Creditor,
    CreditorSearchFilters,
    Creditors,
    EditCreditor,
} from "@entity/domain/models/creditor/creditor.model";
import { plainToClass, plainToInstance } from "class-transformer";
import { inject, injectable } from "inversify";
import { CreateCreditorBody } from "../dto/creditor/create-creditor.body";
import {
    CreditorDto,
    CreditorsDto,
    CreditorsQuery,
} from "../dto/creditor/creditor.dto";
import { EditCreditorBody } from "../dto/creditor/edit-creditor.body";
import { CreditorMapper } from "../mappers/creditor/creditor.mapper";
import { CreditorsMapper } from "../mappers/creditor/creditors.mapper";

const CREDITOR_PATH = "/creditors/";

@injectable()
export class CreditorDatasource {
    constructor(
        @inject(CreditorMapper) private readonly creditorMapper: CreditorMapper,
        @inject(CreditorsMapper)
        private readonly creditorsMapper: CreditorsMapper,
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
    ) {}

    async fetchAll(): Promise<Either<FallbackError, Creditor[]>> {
        const creditorsResult =
            await this.http.get<CreditorsDto>(CREDITOR_PATH);

        return creditorsResult
            .mapLeft(() => new FallbackError())
            .map((response) => {
                const creditors = response.data.results.mapNotNull((cred) =>
                    this.creditorMapper.map(plainToClass(CreditorDto, cred)),
                );
                return creditors;
            });
    }

    async fetchAllPaginated(
        limit?: number,
        offset?: number,
    ): Promise<Either<HttpError, Creditors>> {
        const query: PaginatedQueryDto = {
            limit,
            offset,
        };
        const creditorsResult = await this.http.get<CreditorsDto>(
            CREDITOR_PATH,
            {
                query,
            },
        );

        return creditorsResult.map((response) =>
            this.creditorsMapper.map(plainToClass(CreditorsDto, response.data)),
        );
    }

    async fetchById(
        creditorId: number,
    ): Promise<Either<FallbackError, { creditor: Creditor }>> {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const creditorResult = await this.http.get<CreditorDto>(
            `${CREDITOR_PATH}${creditorId}/`,
            { query },
        );

        return creditorResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const creditor = this.creditorMapper.map(
                    plainToClass(CreditorDto, response.data),
                );

                if (!creditor) return Either.Left(new FallbackError());

                return Either.Right({ creditor });
            });
    }

    async create(
        createCreditor: CreateCreditor,
    ): Promise<Either<ValidationError | FallbackError, Creditor>> {
        const createdCreditorDto =
            this.creditorMapper.mapToCreate(createCreditor);

        const createCreditorResult = await this.http.post<
            CreditorDto,
            CreateCreditorBody
        >(CREDITOR_PATH, createdCreditorDto);

        return createCreditorResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdCreditor = this.creditorMapper.map(
                    plainToClass(CreditorDto, response.data),
                );

                if (!createdCreditor) return Either.Left(new FallbackError());

                return Either.Right(createdCreditor);
            });
    }

    async edit(
        editCreditor: EditCreditor,
    ): Promise<Either<ValidationError | FallbackError, Creditor>> {
        const editedInternalNote = this.creditorMapper.mapToDto(editCreditor);

        const editCreditorResult = await this.http.patch<
            CreditorDto,
            EditCreditorBody
        >(`${CREDITOR_PATH}${editCreditor.id}/`, editedInternalNote);

        return editCreditorResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const creditor = this.creditorMapper.map(
                    plainToClass(CreditorDto, response.data),
                );

                if (!creditor) return Either.Left(new FallbackError());

                return Either.Right(creditor);
            });
    }

    async delete(creditorId: number): Promise<Either<FallbackError, boolean>> {
        const deleteCreditorResult = await this.http.delete(
            `${CREDITOR_PATH}${creditorId}/`,
        );

        return deleteCreditorResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }

    async fetchAllBy(
        pagination: Pagination,
        filters?: CreditorSearchFilters,
    ): Promise<Either<FallbackError, Creditors>> {
        const query: CreditorsQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.search) {
            query.search = filters.search;
        }

        if (filters?.entityIds) {
            query.entities = filters.entityIds.join(",");
        }

        const responseResult = await this.http.get<CreditorsDto>(
            CREDITOR_PATH,
            {
                query,
            },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.creditorsMapper.map(
                    plainToInstance(CreditorsDto, response.data),
                ),
            );
    }
}
