import { HotTable, HotTableClass, HotTableProps } from "@handsontable/react";
import { forwardRef, memo, useImperativeHandle, useRef } from "react";

export type IncSpreadsheetProps = HotTableProps;

const _IncSpreadsheet = forwardRef<HotTableClass, IncSpreadsheetProps>(
    (props, ref) => {
        // HotTable uses Legacy Refs, so we need to use a ref to access its methods
        const legacyRef = useRef<HotTableClass>(null);
        useImperativeHandle(
            ref,
            (): HotTableClass => legacyRef.current as HotTableClass,
        );

        return (
            <HotTable
                ref={legacyRef}
                {...props}
                licenseKey={process.env.INC_HOT_LICENSE_KEY}
            />
        );
    },
);

export const IncSpreadsheet = memo(_IncSpreadsheet);

_IncSpreadsheet.displayName = "IncSpreadsheet";
