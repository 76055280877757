import { IdentificationDocumentTypeEnum } from "@core/domain/models/identification-document-type.model";

export class Contact {
    constructor(
        public id: number,
        public entity: number,
        public name: string,
        public email: string,
        public phone1: string,
        public phone2?: string,
        public web?: string,
        public observations?: string,
        public identificationType?: IdentificationDocumentTypeEnum,
        public identification?: string,
        public isLegalRepresentative?: boolean,
    ) {}
}

export class CreateContactBody {
    constructor(
        public entity: number,
        public name: string,
        public email: string,
        public phone1: string,
        public phone2?: string,
        public web?: string,
        public observations?: string,
        public identificationType?: IdentificationDocumentTypeEnum,
        public identification?: string,
        public isLegalRepresentative?: boolean,
    ) {}
}
