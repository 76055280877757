import { Nullable } from "@core/domain/types/nullable.type";
import { CollectiveDto } from "@entity/data/dto/collectives.dto";
import { CollectiveEnumMapper } from "@entity/data/mappers/collective-enum.mapper";
import {
    Collective,
    CollectiveEnum,
} from "@entity/domain/models/collective.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class CollectiveMapper {
    constructor(
        @inject(CollectiveEnumMapper)
        private collectiveEnumMapper: CollectiveEnumMapper,
    ) {}

    map(collectiveDto: CollectiveDto): Nullable<Collective> {
        const errors = validateSync(collectiveDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<CollectiveEnum> = this.collectiveEnumMapper.map(
            collectiveDto.key,
        );

        if (!id) return null;

        return new Collective(id, collectiveDto.value);
    }
}
