import { LanguageEnumDto } from "@core/data/dto/language.dto";
import { LanguageIsoCodeEnum } from "@core/domain/models/language.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";

@injectable()
export class LanguageIsoCodeEnumMapper {
    map(languageEnumDto: LanguageEnumDto): Nullable<LanguageIsoCodeEnum> {
        switch (languageEnumDto) {
            case LanguageEnumDto.English:
                return LanguageIsoCodeEnum.English;
            case LanguageEnumDto.Spanish:
                return LanguageIsoCodeEnum.Spanish;
            case LanguageEnumDto.Catalán:
                return LanguageIsoCodeEnum.Catalán;
            case LanguageEnumDto.Euskera:
                return LanguageIsoCodeEnum.Euskera;
            case LanguageEnumDto.Gallego:
                return LanguageIsoCodeEnum.Gallego;
            case LanguageEnumDto.Italiano:
                return LanguageIsoCodeEnum.Italiano;
            default:
                return null;
        }
    }

    mapToDto(languageEnum: LanguageIsoCodeEnum): LanguageEnumDto {
        // eslint-disable-next-line default-case
        switch (languageEnum) {
            case LanguageIsoCodeEnum.English:
                return LanguageEnumDto.English;
            case LanguageIsoCodeEnum.Spanish:
                return LanguageEnumDto.Spanish;
            case LanguageIsoCodeEnum.Catalán:
                return LanguageEnumDto.Catalán;
            case LanguageIsoCodeEnum.Euskera:
                return LanguageEnumDto.Euskera;
            case LanguageIsoCodeEnum.Gallego:
                return LanguageEnumDto.Gallego;
            case LanguageIsoCodeEnum.Italiano:
                return LanguageEnumDto.Italiano;
        }
    }
}
