import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { FinancialEntityBudget } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetBudgetByIdUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(budgetId: number): Promise<Nullable<FinancialEntityBudget>> {
        const budget = await this.budgetRepository.getById(budgetId);
        if (budget.isLeft()) {
            ToastManagerStore.error(budget.getLeftOrThrow().message);
            return null;
        }
        return budget.getOrThrow();
    }
}
