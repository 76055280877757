import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateBudgetCategoryConcept } from "@project/domain/models/budget/budget-category/concepts/create-budget-category-concept.model";
import { EditBudgetCategoryConcept } from "@project/domain/models/budget/budget-category/concepts/edit-budget-category-concept.model";
import {
    BudgetCategoryConcept,
    BudgetCategoryConcepts,
    BudgetCategoryGroups,
} from "@project/domain/models/budget/financial-entity-budget.model";
import { CreateBudgetCategoryConceptUseCase } from "@project/domain/usecases/budget/categories/concepts/create-category-concept.usecase";
import { DeleteBudgetCategoryConceptUseCase } from "@project/domain/usecases/budget/categories/concepts/delete-category-concept.usecase";
import { EditCategoryConceptUseCase } from "@project/domain/usecases/budget/categories/concepts/edit-category-concept.usecase";
import { GetAllCategoryConceptsByCatIdUseCase } from "@project/domain/usecases/budget/categories/concepts/get-all-category-concepts-by-category-id.usecase";
import { GetAllCategoryGroupsByCategoryIdUseCase } from "@project/domain/usecases/budget/categories/groups/get-all-category-groups-by-category-id.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class BudgetCategoryConceptsViewModel extends BaseViewModel {
    _categoryId: Nullable<number> = null;

    @observable
    concepts: BudgetCategoryConcepts = new BudgetCategoryConcepts([], 0);

    @observable
    groups: BudgetCategoryGroups = new BudgetCategoryGroups([], 0);

    @observable
    openAddModal: boolean = false;

    @observable
    openEditModal: boolean = false;

    @observable
    conceptToEdit: Undefinable<BudgetCategoryConcept>;

    constructor(
        @inject(GetAllCategoryConceptsByCatIdUseCase)
        private readonly getAllCategoryConceptsByCategoryIdUseCase: GetAllCategoryConceptsByCatIdUseCase,
        @inject(GetAllCategoryGroupsByCategoryIdUseCase)
        private readonly getAllCategoryGroupsByCategoryIdUseCase: GetAllCategoryGroupsByCategoryIdUseCase,
        @inject(CreateBudgetCategoryConceptUseCase)
        private readonly createCategoryConceptUseCase: CreateBudgetCategoryConceptUseCase,
        @inject(DeleteBudgetCategoryConceptUseCase)
        private readonly deleteCategoryConceptUseCase: DeleteBudgetCategoryConceptUseCase,
        @inject(EditCategoryConceptUseCase)
        private readonly editCategoryConceptUseCase: EditCategoryConceptUseCase,
    ) {
        super();
        makeObservable(this);
    }
    override async didMount(): Promise<void> {
        await super.didMount();
        await this.initViewData();
    }
    async initViewData(): Promise<void> {
        await Promise.all([this.getConcepts(), this.getCategoryGroups()]);
    }
    async getConcepts(): Promise<void> {
        if (!this._categoryId) return;
        const result =
            await this.getAllCategoryConceptsByCategoryIdUseCase.execute(
                this._categoryId,
            );
        runInAction(() => {
            this.concepts = result;
        });
    }
    getCategoryGroups = async (): Promise<void> => {
        if (!this._categoryId) return;
        const groups =
            await this.getAllCategoryGroupsByCategoryIdUseCase.execute(
                this._categoryId,
            );
        runInAction(() => {
            this.groups = groups;
        });
    };

    handleOpenAddModal = (): void => {
        runInAction(() => {
            this.openAddModal = true;
        });
    };

    handleCloseAddModal = (): void => {
        runInAction(() => {
            this.openAddModal = false;
        });
    };

    addConcept = async (values: CreateBudgetCategoryConcept): Promise<void> => {
        if (!this._categoryId) return;

        LoadLayoutStore.start();
        const result = await this.createCategoryConceptUseCase.execute(
            new CreateBudgetCategoryConcept(
                values.name,
                this._categoryId,
                values.group,
            ),
        );

        if (result) {
            ToastManagerStore.success();
        }

        await this.getConcepts();
        LoadLayoutStore.finish();
        this.handleCloseAddModal();
    };

    handleOpenEditModal = (concept: BudgetCategoryConcept): void => {
        this.openEditModal = true;
        this.conceptToEdit = concept;
    };

    handleCloseEditModal = (): void => {
        this.openEditModal = false;
    };

    async editConcept(values: EditBudgetCategoryConcept): Promise<void> {
        LoadLayoutStore.start();
        if (this.conceptToEdit && this._categoryId) {
            const editedConcept = await this.editCategoryConceptUseCase.execute(
                {
                    ...values,
                    category: this._categoryId,
                    id: this.conceptToEdit.id,
                },
            );
            if (editedConcept) {
                ToastManagerStore.success();
            }
        }
        await this.getConcepts();
        LoadLayoutStore.finish();
        this.handleCloseEditModal();
    }
    deleteConcept = async (id: number): Promise<void> => {
        LoadLayoutStore.start();
        const result = await this.deleteCategoryConceptUseCase.execute(id);
        if (result) {
            await this.getConcepts();
            ToastManagerStore.success();
        }
        LoadLayoutStore.finish();
    };
}
