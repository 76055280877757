import { Supplier } from "@entity/domain/models/supplier/supplier.model";
export class CreateSupplier {
    public contractStartDate?: Supplier["contractStartDate"];
    public contractEndDate?: Supplier["contractEndDate"];
    public serviceProvided?: Supplier["serviceProvided"];
    public otherServiceProvided?: Supplier["otherServiceProvided"];
    public serviceProvidedDescription?: Supplier["serviceProvidedDescription"];
    public qualityCertifications?: Supplier["qualityCertifications"];
    public qualityCertificationsDate?: Supplier["qualityCertificationsDate"];
    public serviceEvaluation?: Supplier["serviceEvaluation"];
    public serviceEvaluationDate?: Supplier["serviceEvaluationDate"];
    public serviceEvaluationDetail?: Supplier["serviceEvaluationDetail"];
    public active?: Supplier["active"];
    public documents?: Supplier["documents"];

    // eslint-disable-next-line max-params
    constructor(
        readonly entity: Supplier["entity"],
        readonly companyName: Supplier["companyName"],
        readonly contactPerson: Supplier["contactPerson"],
        readonly responsableEmail: Supplier["responsableEmail"],
        readonly phone1: Supplier["phone1"],
        readonly type: Supplier["type"],
        readonly identificationType: Supplier["identificationType"],
        readonly identification: Supplier["identification"],
        readonly street: Supplier["street"],
        readonly zipCode: Supplier["zipCode"],
        readonly town: Supplier["town"],
        readonly province: Supplier["province"],
    ) {}
}
