import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { Users } from "../../domain/models/user.model";
import { UsersDto } from "../dto/users.dto";
import { UserMapper } from "./user.mapper";

@injectable()
export class UsersMapper {
    constructor(
        @inject(UserMapper)
        private readonly userMapper: UserMapper,
    ) {}

    map(usersDto: UsersDto): Users {
        const errors = validateSync(usersDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Users([], 0);
        }

        const users = usersDto.results.mapNotNull((userDto) =>
            this.userMapper.map(userDto),
        );

        return new Users(users, usersDto.count);
    }
}
