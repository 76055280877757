import { DocumentRepository } from "@core/data/repositories/document.repository";
import { DocumentCategory } from "@core/domain/models/documents-category.model";
import { Pagination } from "@core/domain/models/pagination";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllDocumentCategoriesUseCase {
    constructor(
        @inject(DocumentRepository)
        private readonly documentRepository: DocumentRepository,
    ) {}

    async execute(): Promise<DocumentCategory[]> {
        const documentCategoriesResult =
            await this.documentRepository.getAllDocumentCategories(
                Pagination.NoPagination(),
            );

        if (documentCategoriesResult.isLeft()) {
            ToastManagerStore.error(
                documentCategoriesResult.getLeftOrThrow().message,
            );
        }

        return documentCategoriesResult.fold(
            () => [],
            (documentCategories) => documentCategories.documentCategories,
        );
    }
}
