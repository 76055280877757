import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { TypologyEnum } from "./typology.model";

export interface EntitySearchFilters {
    name?: string;
    serviceIds?: number[];
    collectiveIds?: number[];
    typologyType?: Nullable<TypologyEnum>;
    active?: boolean;
    beneficiaryId?: number;
}

export class EntitiesSummary {
    constructor(
        public readonly entitiesSummary: EntitySummary[],
        public readonly count: number,
    ) {}
}

export class EntitySummary {
    constructor(
        public readonly id: number,
        public name: string,
        public identification: Undefinable<string>,
        public typology: Undefinable<TypologyEnum>,
        public typologyLabel: Undefinable<string>,
    ) {}
}
