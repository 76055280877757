import { Nullable } from "@core/domain/types/nullable.type";
import { AffiliationDto } from "@entity/data/dto/affiliation/affiliation.dto";
import { Affiliation } from "@entity/domain/models/affiliation/affiliation.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class AffiliationMapper {
    map(affiliationDto: AffiliationDto): Nullable<Affiliation> {
        const errors = validateSync(affiliationDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        return new Affiliation(
            affiliationDto.id,
            affiliationDto.identification,
            affiliationDto.name,
            affiliationDto.territorial_scope,
            affiliationDto.entity,
            affiliationDto.web_address,
            affiliationDto.incorporation_date
                ? DateTime.fromISO(affiliationDto.incorporation_date)
                : null,
            affiliationDto.contact_person,
            affiliationDto.contact_phone,
            affiliationDto.contact_email,
        );
    }

    mapToDto(affiliation: Affiliation): AffiliationDto {
        const affiliationDto: AffiliationDto = {
            id: affiliation.id,
            identification: affiliation.identification,
            name: affiliation.name,
            territorial_scope: affiliation.territorialScope,
            entity: affiliation.entity,
            web_address: affiliation.webAddress ?? null,
            incorporation_date:
                affiliation.incorporationDate?.toISODate() ?? null,
            contact_person: affiliation.contactPerson ?? null,
            contact_phone: affiliation.contactPhone ?? null,
            contact_email: affiliation.contactEmail ?? null,
        };

        return affiliationDto;
    }
}
