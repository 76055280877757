import {
    LocalStorage,
    LocalStorageKeysEnum,
} from "@core/data/infrastructures/storage/local-storage";
import { AxiosRequestConfig } from "axios";
import { inject, injectable } from "inversify";
import { LanguageLocalDatasource } from "../../datasources/language-local.datasource";
import { HttpConfig } from "./http-config";

@injectable()
export class AxiosHttpConfig {
    get apiUrl(): string {
        const url =
            process.env.INC_API_SERVER_URL + process.env.INC_API_BASE_URL;

        if (!url)
            throw new Error(
                `No existe base url para el entorno ${process.env.INC_REACT_APP_STAGE}`,
            );

        return url;
    }

    constructor(
        @inject(LocalStorage) private readonly localStorage: LocalStorage,
        @inject(LanguageLocalDatasource)
        private readonly languageLocalDatasource: LanguageLocalDatasource,
    ) {}

    fromHttpConfig(
        httpConfig: HttpConfig | undefined,
    ): AxiosRequestConfig | undefined {
        const token = this.localStorage.get(LocalStorageKeysEnum.AccessToken);
        const currentLanguageResult = this.languageLocalDatasource.getCurrent();
        const axiosRequestConfig: AxiosRequestConfig = {
            withCredentials: true,
        };

        const currentLanguage = currentLanguageResult.isRight()
            ? currentLanguageResult.getOrThrow()
            : undefined;

        if (token) {
            axiosRequestConfig.headers = {
                Authorization: `Token ${token}`,
            };
        }

        if (currentLanguage) {
            axiosRequestConfig.headers = {
                ...axiosRequestConfig.headers,
                "Accept-Language": currentLanguage,
            };
        }

        if (httpConfig) {
            if (httpConfig.baseUrl) {
                axiosRequestConfig.baseURL = httpConfig.baseUrl;
            }

            if (httpConfig.headers) {
                axiosRequestConfig.headers = {
                    ...httpConfig.headers,
                    ...axiosRequestConfig.headers,
                };
            }

            if (httpConfig.query) {
                axiosRequestConfig.params = httpConfig.query;
            }

            if (httpConfig.responseType) {
                axiosRequestConfig.responseType = httpConfig.responseType;
            }

            if (httpConfig.body) {
                axiosRequestConfig.params = httpConfig.body;
            }
        }

        return axiosRequestConfig;
    }
}
