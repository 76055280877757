import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { CrosscuttingScope } from "@entity/domain/models/crosscutting-scope.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllCrosscuttingScopesUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}
    async execute(): Promise<CrosscuttingScope[]> {
        const crosscuttingScopesResult =
            await this.entityRepository.getAllCrosscuttingScopes();

        return crosscuttingScopesResult.getOrElse([]);
    }
}
