import { EditBeneficiaryMapper } from "@beneficiary/data/mappers/edit-beneficiary.mapper";
import { Beneficiary } from "@beneficiary/domain/models/beneficiary.model";
import { EditBeneficiaryUseCase } from "@beneficiary/domain/usecases/edit-beneficiary.usecase";
import { GetBeneficiaryByIdUseCase } from "@beneficiary/domain/usecases/get-beneficiary-by-id.usecase";
import type { EditBeneficiaryFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/beneficiary-form.component";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import {
    action,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
@injectable()
export class EditBeneficiaryPageViewModel extends BaseViewModel {
    beneficiaryId: Undefinable<number> = undefined;

    @observable
    initiallyLoading = true;

    @observable
    beneficiary: Undefinable<Beneficiary> = undefined;

    constructor(
        @inject(EditBeneficiaryMapper)
        private readonly editBeneficiaryMapper: EditBeneficiaryMapper,

        @inject(EditBeneficiaryUseCase)
        private readonly editBeneficiaryUseCase: EditBeneficiaryUseCase,

        @inject(GetBeneficiaryByIdUseCase)
        private readonly getBeneficiaryByIdUseCase: GetBeneficiaryByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(this.getBeneficiary());

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    @action
    setBeneficiary(beneficiary: Beneficiary): void {
        this.beneficiary = beneficiary;
    }
    @action.bound
    async getBeneficiary(): Promise<void> {
        if (this.beneficiaryId === undefined)
            throw Error("Missing beneficiaryId");

        const beneficiary = await this.getBeneficiaryByIdUseCase.execute(
            this.beneficiaryId,
        );

        if (beneficiary) {
            this.setBeneficiary(beneficiary);
        }
    }

    async editBeneficiary(
        beneficiaryForm: EditBeneficiaryFormValuesValidated,
    ): Promise<boolean> {
        if (!this.beneficiaryId) throw Error("Missing beneficiaryId");

        LoadLayoutStore.start();

        const editBeneficiary =
            this.editBeneficiaryMapper.mapFromBeneficiaryFormValues(
                this.beneficiaryId,
                beneficiaryForm,
            );
        const editedSuccessfully =
            await this.editBeneficiaryUseCase.execute(editBeneficiary);

        if (editedSuccessfully) {
            await this.getBeneficiary();
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return editedSuccessfully !== null;
    }
}
