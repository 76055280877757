import { Nullable } from "@core/domain/types/nullable.type";
import { CreditorRepository } from "@entity/data/repositories/creditor.repository";
import { Creditor } from "@entity/domain/models/creditor/creditor.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetCreditorByIdUseCase {
    constructor(
        @inject(CreditorRepository)
        private readonly creditorRepository: CreditorRepository,
    ) {}

    async execute(creditorId: number): Promise<Nullable<Creditor>> {
        const creditorResult =
            await this.creditorRepository.getById(creditorId);

        return creditorResult.fold(
            () => null,
            (creditor) => creditor,
        );
    }
}
