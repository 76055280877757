import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { inject, injectable } from "inversify";
import { CreateFinancialEntity } from "../../models/financial-entity/create-financial-entity.model";
import { FinancialEntity } from "../../models/financial-entity/financial-entities.model";

@injectable()
export class CreateFinancialEntityUseCase {
    constructor(
        @inject(FinancialEntityRepository)
        private readonly financialEntityRepository: FinancialEntityRepository,
    ) {}

    async execute(
        financialEntity: CreateFinancialEntity,
    ): Promise<Nullable<FinancialEntity>> {
        const creationResult =
            await this.financialEntityRepository.create(financialEntity);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdFinancialEntity) => createdFinancialEntity,
        );
    }
}
