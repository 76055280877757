export enum TransportTypeEnum {
    NONE = "NONE",
    CAR = "CAR",
    BUS = "BUS",
    METRO = "METRO",
    TAXI = "TAXI",
    PLANE = "PLANE",
    OTHER = "OTHER",
}

export class TransportType {
    constructor(
        public readonly id: TransportTypeEnum,
        public readonly label: string,
    ) {}
}
