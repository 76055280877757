import { InternalNotesRepository } from "@beneficiary/data/repositories/internal-notes.repository";
import { InternalNote } from "@beneficiary/domain/models/internal-notes/internal-note.model";
import { Pagination } from "@core/domain/models/pagination";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllInternalNotesByUsecase {
    constructor(
        @inject(InternalNotesRepository)
        private readonly internalNotesRepository: InternalNotesRepository,
    ) {}

    async execute(beneficiaryId: number): Promise<InternalNote[]> {
        const internalNotesResult = await this.internalNotesRepository.getAllBy(
            beneficiaryId,
            Pagination.NoPagination(),
        );

        return internalNotesResult.fold(
            () => [],
            (internalNotes) => internalNotes.internalNotes,
        );
    }
}
