import { EducationLevelEnumDto } from "@core/data/dto/education-level.dto";
import { EmploymentStatusEnumDto } from "@core/data/dto/employment-status.dto";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { BeneficiaryIdentificationTypeEnumDto } from "../beneficiary-identification-type.dto";
import { RelationshipEnumDto } from "../parentange.dto";

export class RelativesDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsEnum(RelationshipEnumDto)
    @IsNotEmpty()
    parentage!: RelationshipEnumDto;

    @IsString()
    @IsOptional()
    other_parentage!: Nullable<string>;

    @IsDateString()
    @IsNotEmpty()
    birth_date!: string;

    @IsEnum(BeneficiaryIdentificationTypeEnumDto)
    @IsNotEmpty()
    identification_type!: BeneficiaryIdentificationTypeEnumDto;

    @IsString()
    @IsOptional()
    identification!: Nullable<string>;

    @IsEnum(EducationLevelEnumDto)
    @IsNotEmpty()
    level_of_education!: EducationLevelEnumDto;

    @IsString()
    @IsOptional()
    education_centre!: Nullable<string>;

    @IsEnum(EmploymentStatusEnumDto)
    @IsOptional()
    employment_situation!: Nullable<EmploymentStatusEnumDto>;

    @IsString()
    @IsOptional()
    tutelage_email!: Nullable<string>;

    @IsString()
    @IsOptional()
    tutelage_phone!: Nullable<string>;

    @IsString()
    @IsOptional()
    net_salary!: Nullable<string>;
}

export class RelativesSummaryDto extends PaginatedDto {
    @IsArray()
    @Type(() => RelativesDto)
    results!: RelativesDto[];
}

export type RelativesSummaryQuery = PaginatedQuery & {
    search?: Nullish<string>;
    beneficiary?: Nullish<number>;
};
