import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum DisabilityTypeEnumDto {
    physical = "physical",
    mental = "mental",
    intellectual = "intellectual",
    sensorial = "sensorial",
    pluridiscability = "pluridiscability",
    none = "none",
}

export class DisabilityTypeDto {
    @IsEnum(DisabilityTypeEnumDto)
    @IsNotEmpty()
    key!: DisabilityTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
