import { Nullable } from "@core/domain/types/nullable.type";
import {
    CreateAccommodationExpense,
    CreateExpenseAllTypes,
    CreateInvestmentExpense,
    CreateMaintenanceExpense,
    CreateManagementExpense,
    CreatePersonnelExpense,
    CreateTravelExpense,
} from "@project/domain/models/expenses/create-expense.model";
import { ExpenseTypeEnum } from "@project/domain/models/expenses/expense-type.model";
import { ExpenseAccommodationFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/accommodation/expense-accommodation-form.component";
import { ExpensesFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/expense-modal-body.component";
import { ExpenseInvestmentFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/investment/expense-investment-form.component";
import { ExpenseMaintenanceFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/maintenance/expense-maintenance-form.component";
import { ExpenseManagementFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/management/expense-management-form.component";
import { ExpensePersonnelFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/personnel/expense-personnel-form.component";
import { ExpenseTravelFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/travels/expense-travel-form.component";
import { inject, injectable } from "inversify";
import { CreateExpenseAllTypesBody } from "../../dto/expenses/create-expense.body";
import { AccommodationExpenseMapper } from "./accommodation-expense.mapper";
import { InvestmentExpenseMapper } from "./investment-expense.mapper";
import { MaintenanceExpenseMapper } from "./maintenance-expense.mapper";
import { ManagementExpenseMapper } from "./management-expense.mapper";
import { PersonnelExpenseMapper } from "./personnel-expense.mapper";
import { TravelExpenseMapper } from "./travel-expense.mapper";

@injectable()
export class CreateExpenseMapper {
    constructor(
        @inject(InvestmentExpenseMapper)
        private readonly investmentExpenseMapper: InvestmentExpenseMapper,
        @inject(MaintenanceExpenseMapper)
        private readonly maintenanceExpenseMapper: MaintenanceExpenseMapper,
        @inject(TravelExpenseMapper)
        private readonly travelExpenseMapper: TravelExpenseMapper,
        @inject(AccommodationExpenseMapper)
        private readonly accommodationExpenseMapper: AccommodationExpenseMapper,
        @inject(ManagementExpenseMapper)
        private readonly managementExpenseMapper: ManagementExpenseMapper,
        @inject(PersonnelExpenseMapper)
        private readonly personnelExpenseMapper: PersonnelExpenseMapper,
    ) {}

    mapFromFormValues(
        createFormValues: ExpensesFormValuesValidated,
        projectId: number,
    ): Nullable<CreateExpenseAllTypes> {
        switch (createFormValues.__type) {
            case "INVESTMENT":
                return this.investmentExpenseMapper.mapFromFormValues(
                    createFormValues as ExpenseInvestmentFormValuesValidated,
                    projectId,
                );
            case "MAINTENANCE_ACTIVITIES":
                return this.maintenanceExpenseMapper.mapFromFormValues(
                    createFormValues as ExpenseMaintenanceFormValuesValidated,
                    projectId,
                );
            case "MANAGEMENT_ADMINISTRATION":
                return this.managementExpenseMapper.mapFromFormValues(
                    createFormValues as ExpenseManagementFormValuesValidated,
                    projectId,
                );
            case "ACCOMMODATION_MEALS":
                return this.accommodationExpenseMapper.mapFromFormValues(
                    createFormValues as ExpenseAccommodationFormValuesValidated,
                    projectId,
                );
            case "TRAVEL":
                return this.travelExpenseMapper.mapFromFormValues(
                    createFormValues as ExpenseTravelFormValuesValidated,
                    projectId,
                );
            case "PERSONNEL_MANAGEMENT_ADMINISTRATION":
                return this.personnelExpenseMapper.mapFromFormValues(
                    createFormValues as ExpensePersonnelFormValuesValidated,
                    projectId,
                );
            default:
                return null;
        }
    }

    mapToDto(
        createExpense: CreateExpenseAllTypes,
    ): Nullable<CreateExpenseAllTypesBody> {
        switch (createExpense.typeId) {
            case ExpenseTypeEnum.INVESTMENT:
                return this.investmentExpenseMapper.mapToDto(
                    createExpense as CreateInvestmentExpense,
                );
            case ExpenseTypeEnum.MAINTENANCE_ACTIVITIES:
                return this.maintenanceExpenseMapper.mapToDto(
                    createExpense as CreateMaintenanceExpense,
                );
            case ExpenseTypeEnum.MANAGEMENT_ADMINISTRATION:
                return this.managementExpenseMapper.mapToDto(
                    createExpense as CreateManagementExpense,
                );
            case ExpenseTypeEnum.ACCOMMODATION_MEALS:
                return this.accommodationExpenseMapper.mapToDto(
                    createExpense as CreateAccommodationExpense,
                );
            case ExpenseTypeEnum.TRAVEL:
                return this.travelExpenseMapper.mapToDto(
                    createExpense as CreateTravelExpense,
                );
            case ExpenseTypeEnum.PERSONNEL_MANAGEMENT_ADMINISTRATION:
                return this.personnelExpenseMapper.mapToDto(
                    createExpense as CreatePersonnelExpense,
                );
            default:
                return null;
        }
    }
}
