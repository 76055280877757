import { Nullable } from "@core/domain/types/nullable.type";
import { QualityCertificateTypeDto } from "@entity/data/dto/quality-certificate-type.dto";
import { QualityCertificateTypeEnumMapper } from "@entity/data/mappers/quality-certificate-type-enum.mapper";
import {
    QualityCertificateType,
    QualityCertificateTypeEnum,
} from "@entity/domain/models/quality-certificate-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class QualityCertificateTypeMapper {
    constructor(
        @inject(QualityCertificateTypeEnumMapper)
        private qualityCertificateTypeEnumMapper: QualityCertificateTypeEnumMapper,
    ) {}

    map(
        qualityCertificateTypeEnumMapperDto: QualityCertificateTypeDto,
    ): Nullable<QualityCertificateType> {
        const errors = validateSync(qualityCertificateTypeEnumMapperDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<QualityCertificateTypeEnum> =
            this.qualityCertificateTypeEnumMapper.map(
                qualityCertificateTypeEnumMapperDto.key,
            );

        if (!id) return null;

        return new QualityCertificateType(
            id,
            qualityCertificateTypeEnumMapperDto.value,
        );
    }
}
