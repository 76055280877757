import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { RegconRegisterRepository } from "@entity/data/repositories/regcon-register.repository";
import { EditRegconRegister } from "@entity/domain/models/quality-framework/regcon-register/edit-regcon-register.model";
import { RegconRegister } from "@entity/domain/models/quality-framework/regcon-register/regcon-register.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditRegconRegisterUseCase {
    constructor(
        @inject(RegconRegisterRepository)
        private readonly regconRegisterRepository: RegconRegisterRepository,
    ) {}

    async execute(
        regconRegister: EditRegconRegister,
    ): Promise<Nullable<RegconRegister>> {
        const editResult =
            await this.regconRegisterRepository.edit(regconRegister);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedRegconRegister) => editedRegconRegister,
        );
    }
}
