import { Nullable } from "@core/domain/types/nullable.type";
import { PublicUtilityDto } from "@entity/data/dto/public-utility/public-utility.dto";
import { PublicUtility } from "@entity/domain/models/public-utility/public-utility.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class PublicUtilityMapper {
    map(publicUtilityDto: PublicUtilityDto): Nullable<PublicUtility> {
        const errors = validateSync(publicUtilityDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        return new PublicUtility(
            publicUtilityDto.id,
            publicUtilityDto.organization_type,
            publicUtilityDto.entity,
            DateTime.fromISO(publicUtilityDto.date),
        );
    }

    mapToDto(publicUtility: PublicUtility): PublicUtilityDto {
        const publicUtilityDto: PublicUtilityDto = {
            id: publicUtility.id,
            organization_type: publicUtility.organizationType,
            entity: publicUtility.entity,
            date: publicUtility.date.toISODate() ?? "",
        };

        return publicUtilityDto;
    }
}
