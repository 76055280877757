import { Undefinable } from "@core/domain/types/undefinable.type";
import { PolicyProtocolTypeEnum } from "@entity/domain/models/policy-protocol-type.model";
import { DateTime } from "luxon";

export class PolicyProtocol {
    constructor(
        public id: number,
        public name: string,
        public policiesProtocols: PolicyProtocolTypeEnum[],
        public realizationYear: number,
        public entity: number,
        public otherPoliciesProtocols?: Undefinable<string>,
        public expirationDate?: Undefinable<DateTime>,
    ) {}
}

export class PolicyProtocols {
    constructor(
        public readonly policyProtocols: PolicyProtocol[],
        public readonly count: number,
    ) {}
}
