import { Pagination } from "@core/domain/models/pagination";
import { StatutoryTerritorialScopeRepository } from "@entity/data/repositories/statutory-territorail-scope.repository";
import { StatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/statutory-territorial-scope.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllStatuoryTerritorialScopesUsecase {
    constructor(
        @inject(StatutoryTerritorialScopeRepository)
        private readonly statutoryTerritorialScopeRepository: StatutoryTerritorialScopeRepository,
    ) {}

    async execute(): Promise<StatutoryTerritorialScope[]> {
        const statutoryTerritorialScopesResult =
            await this.statutoryTerritorialScopeRepository.getAll(
                Pagination.NoPagination(),
            );

        return statutoryTerritorialScopesResult.fold(
            () => [],
            (statutoryTerritorialScopes) =>
                statutoryTerritorialScopes.statutoryTerritorialScopes,
        );
    }
}
