import { CreateDocument } from "@core/domain/models/create-document.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { CreateDocumentUseCase } from "@core/domain/usecases/create-document.usecase";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { ObjectiveMapper } from "@project/data/mappers/technical-proposal/objective.mapper";
import { Objective } from "@project/domain/models/technical-proposals.model";
import { CreateObjectiveUseCase } from "@project/domain/usecases/technical-proposal/objective/create-objective.usecase";
import { DeleteObjectiveUseCase } from "@project/domain/usecases/technical-proposal/objective/delete-objective.usecase";
import { EditObjectiveUseCase } from "@project/domain/usecases/technical-proposal/objective/edit-objective.usecase";
import { GetAllObjectivesByUseCase } from "@project/domain/usecases/technical-proposal/objective/get-all-objective-by.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { ObjectiveFormValuesValidated } from "./objective/objective-form.view";

export interface DocumentTable {
    id: number;
    name: string;
}

@injectable()
export class TechnicalProposalFormViewModel extends BaseViewModel {
    @observable
    technicalProposalId: Undefinable<number> = undefined;

    @observable
    initialLoading = true;

    @observable
    objectiveModalOpen = false;

    @observable
    objectiveToEdit: Undefinable<Objective> = undefined;

    @observable
    objectives: Objective[] = [];

    constructor(
        @inject(CreateDocumentUseCase)
        private readonly createDocumentUseCase: CreateDocumentUseCase,
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
        @inject(GetAllObjectivesByUseCase)
        private readonly getAllObjectivesByUseCase: GetAllObjectivesByUseCase,
        @inject(ObjectiveMapper)
        private readonly objectiveMapper: ObjectiveMapper,
        @inject(CreateObjectiveUseCase)
        private readonly createObjectiveUseCase: CreateObjectiveUseCase,
        @inject(EditObjectiveUseCase)
        private readonly editLocalUseCase: EditObjectiveUseCase,
        @inject(DeleteObjectiveUseCase)
        private readonly deleteObjectiveUseCase: DeleteObjectiveUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getObjectives();

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setTechnicalProposal(technicalProposalId: number): void {
        this.technicalProposalId = technicalProposalId;
    }

    async getObjectives(): Promise<void> {
        if (!this.technicalProposalId) return;
        const objectives = await this.getAllObjectivesByUseCase.execute({
            technicalProposal: this.technicalProposalId,
        });

        runInAction(() => {
            this.objectives = objectives;
        });
    }

    async uploadDocument(
        createDocument: CreateDocument,
    ): Promise<Nullable<IncDocument>> {
        LoadLayoutStore.start();

        const document =
            await this.createDocumentUseCase.execute(createDocument);

        if (document) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return document;
    }

    async deleteDocument(documentId: number): Promise<boolean> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return deleted;
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(documentUrl);

        LoadLayoutStore.finish();
    }

    @action
    closeObjectiveModal(): void {
        this.objectiveModalOpen = false;
    }

    @action
    openObjectiveModal(objective?: Undefinable<Objective>): void {
        this.objectiveToEdit = objective;
        this.objectiveModalOpen = true;
    }

    async createObjective(
        objective: ObjectiveFormValuesValidated,
    ): Promise<Nullable<Objective>> {
        LoadLayoutStore.start();

        const createResult = await this.createObjectiveUseCase.execute(
            this.objectiveMapper.mapFromFormToCreate(objective),
        );

        if (createResult) {
            ToastManagerStore.success();
        } else {
            ToastManagerStore.error("Error al crear el objetivo");
        }

        LoadLayoutStore.finish();

        return createResult;
    }

    async editObjective(
        local: ObjectiveFormValuesValidated,
    ): Promise<Nullable<Objective>> {
        LoadLayoutStore.start();

        const editLocalResult = await this.editLocalUseCase.execute(
            this.objectiveMapper.mapFromFormToEdit(local),
        );

        if (editLocalResult) {
            ToastManagerStore.success();
        } else {
            ToastManagerStore.error("Error al editar el objetivo");
        }

        LoadLayoutStore.finish();

        return editLocalResult;
    }

    async deleteObjective(objectiveId: number): Promise<void> {
        LoadLayoutStore.start();

        const success = await this.deleteObjectiveUseCase.execute(objectiveId);
        if (success) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadObjectives(): Promise<void> {
        LoadLayoutStore.start();
        await this.getObjectives();
        LoadLayoutStore.finish();
    }
}
