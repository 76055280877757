import { Pagination } from "@core/domain/models/pagination";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { ProceedingsSummary } from "@proceeding/domain/models/proceedings-sumary.model";
import { GetProceedingsByUseCase } from "@proceeding/domain/usecases/get-proceedings-by.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

const MAX_ITEMS_PER_PAGE = 10;

export interface ProceedingTable {
    id: number;
    entity: string;
    entityId: number;
    project: string;
    status: string;
    modifiedDate: string;
}

export interface ProceedingsTable {
    expedients: ProceedingTable[];
    count: number;
}

@injectable()
export class EntitiesTabViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    beneficiaryId: number = 0;

    @observable
    private _proceedings: ProceedingsSummary = new ProceedingsSummary([], 0);

    @computed
    get proceedings(): ProceedingsTable {
        return {
            expedients:
                this._proceedings.proceedingsSummary.map<ProceedingTable>(
                    (proceedingSummary) => ({
                        id: proceedingSummary.id,
                        entity: proceedingSummary.entity.name,
                        entityId: proceedingSummary.entity.id,
                        status: proceedingSummary.statusLabel,
                        modifiedDate:
                            proceedingSummary.modifiedDate?.toISODate() ?? "",
                        project: proceedingSummary.nameProject,
                    }),
                ),
            count: this._proceedings.count,
        };
    }

    @observable
    pagination: Pagination = new Pagination(1, MAX_ITEMS_PER_PAGE);

    constructor(
        @inject(GetProceedingsByUseCase)
        private readonly getProceedingsByUseCase: GetProceedingsByUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(this.getProceedingsSummary());

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.getProceedingsSummary();
    }

    @action
    setBeneficiaryId(beneficiaryId: number): void {
        this.beneficiaryId = beneficiaryId;
    }

    @flow
    async *getProceedingsSummary(): AsyncGenerator<void> {
        const proceedings = await this.getProceedingsByUseCase.execute(
            this.pagination,
            { beneficiaryId: this.beneficiaryId },
        );

        runInAction(() => {
            this._proceedings = proceedings;
        });
    }
}
