import { LocalRepository } from "@entity/data/repositories/local.repository";
import { Local } from "@entity/domain/models/locals/local.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllLocalsUseCase {
    constructor(
        @inject(LocalRepository)
        private readonly localRepository: LocalRepository,
    ) {}

    async execute(): Promise<Local[]> {
        const getAllLocalsResult = await this.localRepository.getAll();

        return getAllLocalsResult.fold(
            () => [],
            (locals) => locals,
        );
    }
}
