import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { ActivityStatusEnum } from "../../domain/models/technical-proposals.model";
import { ActivityStatusEnumDto } from "../dto/technical-proposal/technical-proposal.dto";
@injectable()
export class ActivityStatusEnumMapper {
    map(statusEnumDto: ActivityStatusEnumDto): Nullable<ActivityStatusEnum> {
        switch (statusEnumDto) {
            case ActivityStatusEnumDto.toDo:
                return ActivityStatusEnum.toDo;
            case ActivityStatusEnumDto.inProgress:
                return ActivityStatusEnum.inProgress;
            case ActivityStatusEnumDto.inTesting:
                return ActivityStatusEnum.inTesting;
            case ActivityStatusEnumDto.done:
                return ActivityStatusEnum.done;
            default:
                return null;
        }
    }
    mapToDto(statusEnum: ActivityStatusEnum): ActivityStatusEnumDto {
        // eslint-disable-next-line default-case
        switch (statusEnum) {
            case ActivityStatusEnum.toDo:
                return ActivityStatusEnumDto.toDo;
            case ActivityStatusEnum.inProgress:
                return ActivityStatusEnumDto.inProgress;
            case ActivityStatusEnum.inTesting:
                return ActivityStatusEnumDto.inTesting;
            case ActivityStatusEnum.done:
                return ActivityStatusEnumDto.done;
        }
    }
}
