import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { BeneficiaryIdentification } from "@beneficiary/domain/models/beneficiary.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { CreateBeneficiaryIdentification } from "../models/create-beneficiary.model";

@injectable()
export class CreateBeneficiaryIdentificationUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(
        identification: CreateBeneficiaryIdentification,
        beneficiaryId: number,
    ): Promise<Nullable<BeneficiaryIdentification>> {
        const createdIdentification =
            await this.beneficiaryRepository.createIdentification(
                identification,
                beneficiaryId,
            );

        if (createdIdentification.isLeft()) {
            ToastManagerStore.error(
                createdIdentification.getLeftOrThrow().message,
            );
        }

        return createdIdentification.fold(
            () => null,
            (id) => id,
        );
    }
}
