import { Undefinable } from "@core/domain/types/undefinable.type";
import { DateTime } from "luxon";

export class PublicItinerary {
    constructor(
        public id: number,
        public objetive: string,
        public actions: string,
        public actionsEvaluation: string,
        public beneficiary: number,
        public created: Undefinable<DateTime>,
        public modified: Undefinable<DateTime>,
    ) {}
}

export class PublicItineraries {
    constructor(
        public readonly publicItineraries: PublicItinerary[],
        public readonly count: number,
    ) {}
}
