import { CreateAuditoryBody } from "@entity/data/dto/quality-framework/auditory/create-auditory.body";
import { CreateAuditory } from "@entity/domain/models/quality-framework/auditory/create-auditory.model";
import { AuditoryFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/auditory/form/auditory-form";
import { injectable } from "inversify";

@injectable()
export class CreateAuditoryMapper {
    mapToCreateDto(createAuditory: CreateAuditory): CreateAuditoryBody {
        const auditoryDto: CreateAuditoryBody = {
            certification_entity: createAuditory.certificationEntity,
            year: createAuditory.year,
            entity: createAuditory.entity,
        };

        return auditoryDto;
    }

    mapFromFormValues(
        entityId: number,
        auditory: AuditoryFormValuesValidated,
    ): CreateAuditory {
        const createAuditory: CreateAuditory = new CreateAuditory(
            auditory.certificationEntity,
            auditory.year.year,
            entityId,
        );

        return createAuditory;
    }
}
