export enum CenterTypeEnum {
    HealthCenter = "health_center",
    MentalHealthCenter = "mental_health_center",
    CafCenter = "caf_center",
    CadCenter = "cad_center",
    SocialServicesCenter = "social_services_center",
    SpecializedSocialServicesCenter = "specialized_social_services_center",
    EducationalCenter = "educational_center",
    Others = "others",
}

export class CenterType {
    constructor(
        public readonly key: CenterTypeEnum,
        public readonly label: string,
    ) {}
}
