import { CreateSupplier } from "@entity/domain/models/supplier/create-supplier.model";
import { Supplier } from "@entity/domain/models/supplier/supplier.model";
export class EditSupplier extends CreateSupplier {
    public override contractStartDate?: Supplier["contractStartDate"];
    public override contractEndDate?: Supplier["contractEndDate"];
    public override serviceProvided?: Supplier["serviceProvided"];
    public override otherServiceProvided?: Supplier["otherServiceProvided"];
    public override serviceProvidedDescription?: Supplier["serviceProvidedDescription"];
    public override qualityCertifications?: Supplier["qualityCertifications"];
    public override qualityCertificationsDate?: Supplier["qualityCertificationsDate"];
    public override serviceEvaluation?: Supplier["serviceEvaluation"];
    public override serviceEvaluationDate?: Supplier["serviceEvaluationDate"];
    public override serviceEvaluationDetail?: Supplier["serviceEvaluationDetail"];
    public override active?: Supplier["active"];
    public override documents?: Supplier["documents"];

    // eslint-disable-next-line max-params
    constructor(
        readonly id: Supplier["id"],
        entity: Supplier["entity"],
        companyName: Supplier["companyName"],
        contactPerson: Supplier["contactPerson"],
        responsableEmail: Supplier["responsableEmail"],
        phone1: Supplier["phone1"],
        type: Supplier["type"],
        identificationType: Supplier["identificationType"],
        identification: Supplier["identification"],
        street: Supplier["street"],
        zipCode: Supplier["zipCode"],
        town: Supplier["town"],
        province: Supplier["province"],
    ) {
        super(
            entity,
            companyName,
            contactPerson,
            responsableEmail,
            phone1,
            type,
            identificationType,
            identification,
            street,
            zipCode,
            town,
            province,
        );
    }
}
