import { Undefinable } from "@core/domain/types/undefinable.type";
import { QualityCertificateTypeEnum } from "@entity/domain/models/quality-certificate-type.model";
import { DateTime } from "luxon";

export class QualityCertificate {
    constructor(
        public id: number,
        public qualityCertificateType: QualityCertificateTypeEnum,
        public entity: number,
        public year: number,
        public expirationDate?: Undefinable<DateTime>,
        public otherQualityCertificate?: Undefinable<string>,
    ) {}
}

export class QualityCertificates {
    constructor(
        public readonly qualityCertificates: QualityCertificate[],
        public readonly count: number,
    ) {}
}
