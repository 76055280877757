import { IsUserLoggedUseCase } from "@authentication/domain/usecases/is-user-logged.usecase";
import { container } from "@di/inversify.config";
import { LoginRoutes } from "@routes/public/login.routes";
import { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const RequireAuthGuard: FC = () => {
    const location = useLocation();
    const isUserLogged = container.get(IsUserLoggedUseCase).execute();

    return isUserLogged ? (
        <Outlet />
    ) : (
        <Navigate
            to={LoginRoutes.LOGIN}
            state={{ from: location }}
            replace
        />
    );
};
