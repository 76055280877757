import { Address } from "@entity/domain/models/address/address.model";
import { CreateAddress } from "@entity/domain/models/address/create-address.model";

export class EditAddress extends CreateAddress {
    // eslint-disable-next-line max-params
    constructor(
        public readonly id: Address["id"],
        name: Address["name"],
        streetName: Address["streetName"],
        zipCode: Address["zipCode"],
        town: Address["town"],
        province: Address["province"],
        entity: Address["entity"],
        streetNumber: Address["streetNumber"],
        country: Address["country"],
        titularityType: Address["titularityType"],
        region?: Address["region"],
        floor?: Address["floor"],
        door?: Address["door"],
        stair?: Address["stair"],
        cadastralValue?: Address["cadastralValue"],
        local?: Address["local"],
        principalAddress?: Address["principalAddress"],
    ) {
        super(
            name,
            streetName,
            zipCode,
            town,
            province,
            entity,
            streetNumber,
            country,
            titularityType,
            region,
            floor,
            door,
            stair,
            cadastralValue,
            local,
            principalAddress,
        );
    }
}
