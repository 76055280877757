import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { Typology } from "@entity/domain/models/typology.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllTypologiesUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}
    async execute(): Promise<Typology[]> {
        const typologiesResult = await this.entityRepository.getAllTypologies();

        return typologiesResult.getOrElse([]);
    }
}
