import { isNotNullable, NonNullable } from "@core/domain/types/nullable.type";

// @ts-expect-error: TS think is always true because we defined the method via augmentation, but still not implemented
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (Array.prototype.mapNotNull)
    throw Error("Use original, remove custom implementation");
Array.prototype.mapNotNull = function mapNotNull<U extends null>(
    callbackfn: (value: U, index: number, array: U[]) => U | null,
): NonNullable<U>[] {
    return this.map(callbackfn).filter(isNotNullable);
};

// @ts-expect-error: TS think is always true because we defined the method via augmentation, but still not implemented
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (Array.prototype.isNotEmpty)
    throw Error("Use original, remove custom implementation");
Array.prototype.isNotEmpty = function isNotEmpty(): boolean {
    return this.length > 0;
};

// @ts-expect-error: TS think is always true because we defined the method via augmentation, but still not implemented
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (Array.prototype.move)
    throw Error("Use original, remove custom implementation");

Array.prototype.move = function move<T>(
    this: T[],
    from: number,
    // eslint-disable-next-line id-length
    to: number,
): T[] {
    if (0 > to || this.length < to) return this;

    const srcItem = this.at(from);
    if (!srcItem) return this;

    this.splice(from, 1);
    this.splice(to, 0, srcItem);

    return this;
};
