import { Nullable } from "@core/domain/types/nullable.type";
import { ContractTerminationDto } from "@entity/data/dto/employee/contracts/termination/contract-termination.dto";
import { TerminationReasonEnumMapper } from "@entity/data/mappers/employee/termination-reason-enum.mapper";
import { ContractTermination } from "@entity/domain/models/employee/contracts/termination/contract-termination.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class ContractTerminationMapper {
    constructor(
        @inject(TerminationReasonEnumMapper)
        private readonly terminationReasonEnumMapper: TerminationReasonEnumMapper,
    ) {}
    map(
        contractTerminationDto: ContractTerminationDto,
    ): Nullable<ContractTermination> {
        const errors = validateSync(contractTerminationDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        const type = this.terminationReasonEnumMapper.map(
            contractTerminationDto.type,
        );
        if (!type) return null;

        const contractTermination = new ContractTermination(
            contractTerminationDto.id,
            DateTime.fromISO(contractTerminationDto.start_date),
            contractTerminationDto.reason,
            type,
            contractTerminationDto.contract,
            contractTerminationDto.cost
                ? Number(contractTerminationDto.cost)
                : null,
            contractTerminationDto.total_cost
                ? Number(contractTerminationDto.total_cost)
                : null,
        );

        if (contractTerminationDto.end_date) {
            contractTermination.endDate = DateTime.fromISO(
                contractTerminationDto.end_date,
            );
        }

        return contractTermination;
    }
}
