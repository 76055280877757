import { IsUserLoggedUseCase } from "@authentication/domain/usecases/is-user-logged.usecase";
import { container } from "@di/inversify.config";
import { HomeRoutes } from "@routes/private/home.routes";
import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

export const LoginFromTokenGuard: FC = () => {
    const isUserLogged = container.get(IsUserLoggedUseCase).execute();

    return isUserLogged ? (
        <Navigate
            to={HomeRoutes.HOME}
            replace
        />
    ) : (
        <Outlet />
    );
};
