import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateQualityCertificateMapper } from "@entity/data/mappers/quality-framework/quality-certificate/create-quality-certificate.mapper";
import { EditQualityCertificateMapper } from "@entity/data/mappers/quality-framework/quality-certificate/edit-quality-certificate.mapper";
import { QualityCertificateType } from "@entity/domain/models/quality-certificate-type.model";
import { QualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/quality-certificate.model";
import { CreateQualityCertificateUseCase } from "@entity/domain/usecases/quality-framework/quality-certificate/create-quality-certificate.usecase";
import { DeleteQualityCertificateUseCase } from "@entity/domain/usecases/quality-framework/quality-certificate/delete-quality-certificate.usecase";
import { EditQualityCertificateUseCase } from "@entity/domain/usecases/quality-framework/quality-certificate/edit-quality-certificate.usecase";
import { GetAllQualityCertificatesUsecase } from "@entity/domain/usecases/quality-framework/quality-certificate/get-all-quality-certificates.usecase";
import { GetQualityCertificateTypesUseCase } from "@entity/domain/usecases/quality-framework/quality-certificate/get-quality-certificate-types.usecase";
import { QualityCertificateFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/quality-certificate/form/quality-certificate-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class QualityCertificateViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    qualityCertificates: QualityCertificate[] = [];

    @observable
    qualityCertificateToEdit: Undefinable<QualityCertificate> = undefined;

    @observable
    qualityCertificateTypes: QualityCertificateType[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddQualityCertificateModal: boolean = false;

    @observable
    showEditQualityCertificateModal: boolean = false;

    constructor(
        @inject(GetAllQualityCertificatesUsecase)
        private readonly getAllQualityCertificatesUsecase: GetAllQualityCertificatesUsecase,
        @inject(GetQualityCertificateTypesUseCase)
        private readonly getQualityCertificateTypesUseCase: GetQualityCertificateTypesUseCase,
        @inject(CreateQualityCertificateUseCase)
        private readonly createQualityCertificateUseCase: CreateQualityCertificateUseCase,
        @inject(EditQualityCertificateUseCase)
        private readonly editQualityCertificateUseCase: EditQualityCertificateUseCase,
        @inject(DeleteQualityCertificateUseCase)
        private readonly deleteQualityCertificateUseCase: DeleteQualityCertificateUseCase,
        @inject(CreateQualityCertificateMapper)
        private readonly createQualityCertificateMapper: CreateQualityCertificateMapper,
        @inject(EditQualityCertificateMapper)
        private readonly editQualityCertificateMapper: EditQualityCertificateMapper,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getQualityCertificates(),
            this.getQualityCertificateTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getQualityCertificates(): Promise<void> {
        const qualityCertificates =
            await this.getAllQualityCertificatesUsecase.execute();
        runInAction(() => {
            this.qualityCertificates = qualityCertificates;
        });
    }

    @action
    setShowAddQualityCertificateModal(show: boolean): void {
        this.showAddQualityCertificateModal = show;
    }

    @action
    closeEditQualityCertificateModal(): void {
        this.showEditQualityCertificateModal = false;
    }

    @action
    openEditQualityCertificateModal(
        qualityCertificate: Undefinable<QualityCertificate>,
    ): void {
        this.qualityCertificateToEdit = qualityCertificate;
        this.showEditQualityCertificateModal = true;
    }

    async addQualityCertificate(
        entityId: Undefinable<number>,
        values: QualityCertificateFormValuesValidated,
    ): Promise<Nullable<QualityCertificate>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createQualityCertificateResult =
            await this.createQualityCertificateUseCase.execute(
                this.createQualityCertificateMapper.mapFromFormValues(
                    entityId,
                    values,
                ),
            );

        if (createQualityCertificateResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createQualityCertificateResult;
    }

    async editQualityCertificate(
        values: QualityCertificateFormValuesValidated,
    ): Promise<void> {
        if (
            !this.qualityCertificateToEdit?.id ||
            !this.qualityCertificateToEdit.entity
        )
            throw Error("Missing qualityCertificate");

        LoadLayoutStore.start();

        const editQualityCertificateResult =
            await this.editQualityCertificateUseCase.execute(
                this.editQualityCertificateMapper.mapFromModal(
                    this.qualityCertificateToEdit.id,
                    this.qualityCertificateToEdit.entity,
                    values,
                ),
            );

        if (editQualityCertificateResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadQualityCertificates(): Promise<void> {
        await this.getQualityCertificates();
    }

    async deleteQualityCertificate(
        qualityCertificateId: number,
    ): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteQualityCertificateUseCase.execute(
            qualityCertificateId,
        );
        LoadLayoutStore.finish();
    }

    async getQualityCertificateTypes(): Promise<void> {
        const qualityCertificateTypes =
            await this.getQualityCertificateTypesUseCase.execute();
        runInAction(() => {
            this.qualityCertificateTypes = qualityCertificateTypes;
        });
    }
}
