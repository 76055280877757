import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { RoomRepository } from "@entity/data/repositories/room.repository";
import { inject, injectable } from "inversify";
import { CreateRoom, Room } from "../../models/locals/room.model";

@injectable()
export class CreateRoomUseCase {
    constructor(
        @inject(RoomRepository)
        private readonly roomRepository: RoomRepository,
    ) {}

    async execute(room: CreateRoom): Promise<Nullable<Room>> {
        const roomResult = await this.roomRepository.create(room);

        if (roomResult.isLeft()) {
            ToastManagerStore.error(roomResult.getLeftOrThrow().message);
        }

        const newRoom = roomResult.fold(
            () => null,
            (createdRoom) => createdRoom,
        );

        return newRoom;
    }
}
