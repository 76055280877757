import { Nullable } from "@core/domain/types/nullable.type";
import { CostIVATypeEnumDto } from "@entity/data/dto/cost-iva-type.dto";
import { CostIVATypeEnum } from "@entity/domain/models/cost/cost-iva-type.model";
import { injectable } from "inversify";

@injectable()
export class CostIVATypeEnumMapper {
    map(costIvaTypeEnumDto: CostIVATypeEnumDto): Nullable<CostIVATypeEnum> {
        switch (costIvaTypeEnumDto) {
            case CostIVATypeEnumDto.Cero:
                return CostIVATypeEnum.Cero;
            case CostIVATypeEnumDto.Five:
                return CostIVATypeEnum.Five;
            case CostIVATypeEnumDto.Ten:
                return CostIVATypeEnum.Ten;
            case CostIVATypeEnumDto.TwentyOne:
                return CostIVATypeEnum.TwentyOne;
            case CostIVATypeEnumDto.other:
                return CostIVATypeEnum.other;
            default:
                return null;
        }
    }

    mapToDto(costIvaTypeEnum: CostIVATypeEnum): CostIVATypeEnumDto {
        // eslint-disable-next-line default-case
        switch (costIvaTypeEnum) {
            case CostIVATypeEnum.Cero:
                return CostIVATypeEnumDto.Cero;
            case CostIVATypeEnum.Five:
                return CostIVATypeEnumDto.Five;
            case CostIVATypeEnum.Ten:
                return CostIVATypeEnumDto.Ten;
            case CostIVATypeEnum.TwentyOne:
                return CostIVATypeEnumDto.TwentyOne;
            case CostIVATypeEnum.other:
                return CostIVATypeEnumDto.other;
        }
    }
}
