import { CreatePolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/create-policy-protocol.model";
import { PolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/policy-protocol.model";

export class EditPolicyProtocol extends CreatePolicyProtocol {
    constructor(
        public readonly id: PolicyProtocol["id"],
        public override name: CreatePolicyProtocol["name"],
        public override policiesProtocols: CreatePolicyProtocol["policiesProtocols"],
        public override realizationYear: CreatePolicyProtocol["realizationYear"],
        public override entity: CreatePolicyProtocol["entity"],
        public override otherPoliciesProtocols?: CreatePolicyProtocol["otherPoliciesProtocols"],
        public override expirationDate?: CreatePolicyProtocol["expirationDate"],
    ) {
        super(
            name,
            policiesProtocols,
            realizationYear,
            entity,
            otherPoliciesProtocols,
            expirationDate,
        );
    }
}
