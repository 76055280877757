import { Nullable } from "@core/domain/types/nullable.type";
import { TitularityTypeEnumDto } from "@entity/data/dto/titularity-type.dto";
import { TitularityTypeEnum } from "@entity/domain/models/titularity-type.model";
import { injectable } from "inversify";

@injectable()
export class TitularityTypeEnumMapper {
    map(
        titularityTypeEnumDto: TitularityTypeEnumDto,
    ): Nullable<TitularityTypeEnum> {
        switch (titularityTypeEnumDto) {
            case TitularityTypeEnumDto.TitularityOwned:
                return TitularityTypeEnum.TitularityOwned;
            case TitularityTypeEnumDto.TitularityRenting:
                return TitularityTypeEnum.TitularityRenting;
            case TitularityTypeEnumDto.TitularityTransfered:
                return TitularityTypeEnum.TitularityTransfered;
            default:
                return null;
        }
    }

    mapToDto(titularityTypeEnum: TitularityTypeEnum): TitularityTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (titularityTypeEnum) {
            case TitularityTypeEnum.TitularityOwned:
                return TitularityTypeEnumDto.TitularityOwned;
            case TitularityTypeEnum.TitularityRenting:
                return TitularityTypeEnumDto.TitularityRenting;
            case TitularityTypeEnum.TitularityTransfered:
                return TitularityTypeEnumDto.TitularityTransfered;
        }
    }
}
