import { EmploymentStatusDto } from "@core/data/dto/employment-status.dto";
import { EmploymentStatusEnumMapper } from "@core/data/mappers/employment-status-enum.mapper";
import { EmploymentStatus } from "@core/domain/models/employment-status.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class EmploymentStatusMapper {
    constructor(
        @inject(EmploymentStatusEnumMapper)
        private readonly employmentStatusEnumMapper: EmploymentStatusEnumMapper,
    ) {}

    map(employmentLevelDto: EmploymentStatusDto): Nullable<EmploymentStatus> {
        const errors = validateSync(employmentLevelDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const id = this.employmentStatusEnumMapper.map(employmentLevelDto.key);
        if (!id) return null;

        return new EmploymentStatus(id, employmentLevelDto.value);
    }
}
