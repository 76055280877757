import { PaginatedDto } from "@core/data/dto/paginated.dto";
import { CCAAEnumDto } from "@entity/data/dto/ccaa-enum.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsString,
} from "class-validator";

export class RegconRegisterDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    register_number!: string;

    @IsEnum(CCAAEnumDto)
    @IsNotEmpty()
    registered_ccaa!: CCAAEnumDto;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class RegconRegistersDto extends PaginatedDto {
    @IsArray()
    @Type(() => RegconRegisterDto)
    results!: RegconRegisterDto[];
}
