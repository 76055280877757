import { BeneficiarySummary } from "@beneficiary/domain/models/beneficiary-summary.model";
import { OrderFieldsQuery, OrderQuery } from "@core/data/dto/order.query";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { OrderField } from "@core/domain/models/order.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { BeneficiaryIdentificationDto } from "./beneficiary.dto";

export class ProjectDataDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;
}

export class BeneficiarySummaryDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    surname!: string;

    @IsArray()
    @Type(() => ProjectDataDto)
    @IsOptional()
    projects_data!: ProjectDataDto[];

    @IsArray()
    @Type(() => ProjectDataDto)
    @IsOptional()
    identifications_data!: Nullable<BeneficiaryIdentificationDto[]>;
}

export class BeneficiariesSummaryDto extends PaginatedDto {
    @IsArray()
    @Type(() => BeneficiarySummaryDto)
    results!: BeneficiarySummaryDto[];
}

export const beneficiariesSummaryOrderMap: Record<
    OrderField<BeneficiarySummary>,
    Nullable<OrderFieldsQuery<BeneficiarySummaryDto>>
> = {
    id: "id",
    name: "name",
    surname: "surname",
    documentNumber: null,
    fullName: null,
    modified: "modified",
    project: null,
};

export type BeneficiariesSummaryQuery = PaginatedQuery & {
    search?: Nullish<string>;
    name?: Nullish<string>;
    // Comma separated ids
    projects?: Nullish<string>;
    // Comma separated ids
    entities?: Nullish<string>;
    ordering?: OrderQuery<BeneficiarySummaryDto>;
};

export type BeneficiariesByProjectExportQuery = PaginatedQuery & {
    search?: string;
    // Comma separated ids
    projects?: string;
    // Comma separated ids
    entities?: string;
    // Comma separated ids
    activities?: string;
};

export type BeneficiaryIdentificationQuery = PaginatedQuery & {
    beneficiary?: Nullish<string>;
};
