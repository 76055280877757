import type {
    Address,
    AddressData,
} from "@beneficiary/domain/models/address.model";
import { District } from "@beneficiary/domain/models/district.model";
import { DeleteAddressUseCase } from "@beneficiary/domain/usecases/address/delete-address.usecase";
import { GetAddressByIdUseCase } from "@beneficiary/domain/usecases/address/get-address-by-id.usecase";
import { SaveAddressUseCase } from "@beneficiary/domain/usecases/address/save-address-data.usecase";
import { GetAllDistrictsUseCase } from "@beneficiary/domain/usecases/get-all-disctricts.usecase";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import type { AddressFormValuesValidated } from "./address-form.view";

@injectable()
export class AddressTabViewModel extends BaseViewModel {
    _beneficiaryId: Undefinable<number> = undefined;

    @observable
    initialLoading: boolean = true;

    @observable
    districts: District[] = [];

    @observable
    usualAddressId?: Undefinable<number> = undefined;

    @observable
    censusAddressId?: Undefinable<number> = undefined;

    @observable
    address: AddressData = {
        usualAddress: null,
        censusAddress: null,
    };

    constructor(
        @inject(GetAllDistrictsUseCase)
        private readonly getAllDistrictsUseCase: GetAllDistrictsUseCase,
        @inject(GetAddressByIdUseCase)
        private readonly getAddressByIdUseCase: GetAddressByIdUseCase,
        @inject(SaveAddressUseCase)
        private readonly saveAddressUseCase: SaveAddressUseCase,
        @inject(DeleteAddressUseCase)
        private readonly deleteAddressUseCase: DeleteAddressUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @action
    setUsualAddressId(usualAddressId: Undefinable<number>): void {
        this.usualAddressId = usualAddressId;
    }

    @action
    setCensusAddressId(censusAddressId: Undefinable<number>): void {
        this.censusAddressId = censusAddressId;
    }

    @action
    setCensusAddress(censusAddress: Address): void {
        this.address.censusAddress = censusAddress;
    }

    @action
    setUsualAddress(usualAddress: Address): void {
        this.address.usualAddress = usualAddress;
    }

    @action
    async initViewData(): Promise<void> {
        await this.getAllDistricts();
        if (!!this.usualAddressId) await this.getUsualAddress();
        if (!!this.censusAddressId) await this.getCensusAddress();

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    async getAllDistricts(): Promise<void> {
        const districts = await this.getAllDistrictsUseCase.execute();

        runInAction(() => {
            this.districts = districts;
        });
    }

    @action
    async getUsualAddress(): Promise<void> {
        if (this.usualAddressId === undefined) return;

        const addressResponse = await this.getAddressByIdUseCase.execute(
            this.usualAddressId,
        );

        if (!addressResponse) return;

        this.setUsualAddress(addressResponse);
    }

    @action
    async getCensusAddress(): Promise<void> {
        if (this.censusAddressId === undefined) return;

        const addressResponse = await this.getAddressByIdUseCase.execute(
            this.censusAddressId,
        );

        if (!addressResponse) return;

        this.setCensusAddress(addressResponse);
    }

    @action
    async saveAddressFormValues(
        addressFormValues: AddressFormValuesValidated,
    ): Promise<void> {
        if (!this._beneficiaryId) return;

        LoadLayoutStore.start();

        const addressData = await this.saveAddressUseCase.execute(
            this._beneficiaryId,
            addressFormValues,
        );

        this.address = addressData;

        LoadLayoutStore.finish();
    }

    @action
    async deleteAddressFormValues(addressId: number): Promise<void> {
        if (!this._beneficiaryId) return;

        LoadLayoutStore.start();

        const success = await this.deleteAddressUseCase.execute(addressId);
        if (success) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }
}
