import {
    CreateIncome,
    EditIncome,
    Income,
    Incomes,
} from "@beneficiary/domain/models/economic-data/income/income.model";
import { coreTypes } from "@core/core-types.di";
import { EXPAND_ALL, FieldsQuery } from "@core/data/dto/fields.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { IncomeDto, IncomesDto, IncomesQuery } from "../dto/beneficiary.dto";
import { CreateIncomeBody } from "../dto/create-beneficiary.body";
import { EditIncomeBody } from "../dto/edit-beneficiary.body";
import { IncomeMapper } from "../mappers/income.mapper";
import { IncomesMapper } from "../mappers/incomes.mapper";

const INCOMES_PATH = "/incomes/";

@injectable()
export class IncomeDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(IncomesMapper)
        private readonly incomesMapper: IncomesMapper,
        @inject(IncomeMapper)
        private readonly incomeMapper: IncomeMapper,
    ) {}

    async fetchAllBy(
        beneficiaryId: number,
        pagination: Pagination,
    ): Promise<Either<FallbackError, Incomes>> {
        const query: IncomesQuery = {
            limit: pagination.pageSize,
            beneficiary_id: beneficiaryId,
        };

        const incomesResult = await this.http.get<IncomeDto>(INCOMES_PATH, {
            query,
        });

        return incomesResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const incomes = this.incomesMapper.map(
                    plainToClass(IncomesDto, response.data),
                );

                return Either.Right(incomes);
            });
    }

    async fetchById(
        incomeId: number,
    ): Promise<Either<FallbackError, { income: Income }>> {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const incomeResult = await this.http.get<IncomeDto>(
            `${INCOMES_PATH}${incomeId}/`,
            { query },
        );

        return incomeResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const income = this.incomeMapper.map(
                    plainToClass(IncomeDto, response.data),
                );

                if (!income) return Either.Left(new FallbackError());

                return Either.Right({
                    income,
                });
            });
    }

    async create(
        newIncome: CreateIncome,
    ): Promise<Either<ValidationError | FallbackError, Income>> {
        const incomeBody = this.incomeMapper.mapToCreateDto(newIncome);

        const incomeResult = await this.http.post<CreateIncomeBody>(
            INCOMES_PATH,
            incomeBody,
        );

        return incomeResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const income = this.incomeMapper.map(
                    plainToClass(IncomeDto, response.data),
                );

                if (!income) return Either.Left(new FallbackError());

                return Either.Right(income);
            });
    }

    async edit(
        editIncome: EditIncome,
    ): Promise<Either<ValidationError | FallbackError, Income>> {
        const editedIncome = this.incomeMapper.mapToEditDto(editIncome);

        const editIncomeResult = await this.http.patch<
            IncomeDto,
            EditIncomeBody
        >(`${INCOMES_PATH}${editIncome.id}/`, editedIncome);

        return editIncomeResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const income = this.incomeMapper.map(
                    plainToClass(IncomeDto, response.data),
                );

                if (!income) return Either.Left(new FallbackError());

                return Either.Right(income);
            });
    }

    async delete(incomeId: number): Promise<Either<FallbackError, true>> {
        const deleteIncome = await this.http.delete(
            `${INCOMES_PATH}${incomeId}`,
        );

        return deleteIncome.mapLeft(() => new FallbackError()).map(() => true);
    }
}
