import { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { v4 as uuidv4 } from "uuid";

export class SocialNetworkSummary {
    constructor(
        public id: number,
        public entityId: number,
        public name: string,
        public link: string,
        public followers: Nullable<number> = 0,
        public postCount: Nullable<number> = 0,
        public uuid: string = uuidv4(),
    ) {}
}

export class SocialNetworksSummary {
    constructor(
        public readonly socialNetworkSummary: SocialNetworkSummary[],
        public readonly count: number,
    ) {}
}

export interface SocialNetworkFilters {
    search?: Nullish<string>;
}
