import { IncomeDto } from "@beneficiary/data/dto/beneficiary.dto";
import { CreateIncomeBody } from "@beneficiary/data/dto/create-beneficiary.body";
import { EditIncomeBody } from "@beneficiary/data/dto/edit-beneficiary.body";
import {
    CreateIncome,
    EditIncome,
    Income,
} from "@beneficiary/domain/models/economic-data/income/income.model";
import { IncomeFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/economyData/beneficiary-economic-data-tab.view";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class IncomeMapper {
    map(incomeDto: IncomeDto): Nullable<Income> {
        const errors = validateSync(incomeDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const income = new Income(incomeDto.id, incomeDto.beneficiary);

        if (incomeDto.net_salary) income.netSalary = incomeDto.net_salary;
        if (incomeDto.socioeconomic_benefit)
            income.socioeconomicBenefit = incomeDto.socioeconomic_benefit;
        if (incomeDto.family_unit_income)
            income.familyUnitIncome = incomeDto.family_unit_income;
        if (incomeDto.observations)
            income.observations = incomeDto.observations;
        if (incomeDto.others) income.othersIncome = incomeDto.others;

        return income;
    }

    mapToCreate(incomeForm: IncomeFormValuesValidated): CreateIncome {
        if (!incomeForm.beneficiaryId) throw new Error("Missing beneficiaryId");
        const income = new CreateIncome(incomeForm.beneficiaryId);

        if (incomeForm.netSalary) income.netSalary = incomeForm.netSalary;
        if (incomeForm.socioeconomicBenefit)
            income.socioeconomicBenefit = incomeForm.socioeconomicBenefit;
        if (incomeForm.familyUnitIncome)
            income.familyUnitIncome = incomeForm.familyUnitIncome;
        if (incomeForm.observations)
            income.observations = incomeForm.observations;
        if (incomeForm.othersIncome)
            income.othersIncome = incomeForm.othersIncome;

        return income;
    }

    mapToCreateDto(income: CreateIncome): CreateIncomeBody {
        return {
            beneficiary: income.beneficiaryId,
            net_salary: income.netSalary,
            socioeconomic_benefit: income.socioeconomicBenefit,
            family_unit_income: income.familyUnitIncome,
            observations: income.observations,
            others: income.othersIncome ?? null,
        };
    }

    mapToEdit(incomeForm: IncomeFormValuesValidated): EditIncome {
        if (!incomeForm.id) throw Error("Missing income id");
        if (!incomeForm.beneficiaryId) throw Error("Missing beneficiary id");

        const income = new Income(incomeForm.id, incomeForm.beneficiaryId);

        if (incomeForm.netSalary) income.netSalary = incomeForm.netSalary;
        if (incomeForm.socioeconomicBenefit)
            income.socioeconomicBenefit = incomeForm.socioeconomicBenefit;
        if (incomeForm.familyUnitIncome)
            income.familyUnitIncome = incomeForm.familyUnitIncome;
        if (incomeForm.observations)
            income.observations = incomeForm.observations;
        if (incomeForm.othersIncome)
            income.othersIncome = incomeForm.othersIncome;

        return income;
    }

    mapToEditDto(income: Income): EditIncomeBody {
        return {
            id: income.id,
            net_salary: income.netSalary,
            socioeconomic_benefit: income.socioeconomicBenefit,
            family_unit_income: income.familyUnitIncome,
            observations: income.observations,
            others: income.othersIncome ?? null,
        };
    }
}
