import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum FinancingTypeEnumDto {
    SUBSIDY_CALL = "subsidy_call",
    NOMINATIVE_SUBSIDY = "nominative_subsidy",
    PUBLIC_ADMINISTRATION_COLLABORATION_AGREEMENT = "public_administration_collaboration_agreement",
    PUBLIC_ADMINISTRATION_MINOR_CONTRACT = "public_administration_minor_contract",
    PUBLIC_ADMINISTRATION_OTHER_CONTRACT = "public_administration_other_contract",
    CONTRACT_WITH_PRIVATE_ORGANIZATION = "contract_with_private_organization",
    PRIVATE_ORGANIZATION_COLLABORATION_AGREEMENT = "private_organization_collaboration_agreement",
    DONATION = "donation",
    SOCIAL_INVESTMENT_FUND = "social_investment_fund",
    OWN_FINANCING = "own_financing",
    OTHER = "other",
}
export class FinancingTypeDto {
    @IsEnum(FinancingTypeEnumDto)
    @IsNotEmpty()
    key!: FinancingTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
