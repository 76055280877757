import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectMaterialRepository } from "@project/data/repositories/project-material.repository";
import { CreateProjectMaterial } from "@project/domain/models/project-material/create-project-material.model";
import { ProjectMaterial } from "@project/domain/models/project-material/project-material.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateProjectMaterialUseCase {
    constructor(
        @inject(ProjectMaterialRepository)
        private readonly projectMaterialRepository: ProjectMaterialRepository,
    ) {}

    async execute(
        projectMaterial: CreateProjectMaterial,
    ): Promise<Nullable<ProjectMaterial>> {
        const creationResult =
            await this.projectMaterialRepository.create(projectMaterial);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdProjectMaterial) => createdProjectMaterial,
        );
    }
}
