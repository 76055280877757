import { Materials } from "@entity/domain/models/material/material.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { MaterialsDto } from "../../dto/material/materials.dto";
import { MaterialMapper } from "./material.mapper";

@injectable()
export class MaterialsMapper {
    constructor(
        @inject(MaterialMapper)
        private readonly materialMapper: MaterialMapper,
    ) {}

    map(materialsDto: MaterialsDto): Materials {
        const errors = validateSync(materialsDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Materials([], 0);
        }

        const materials = materialsDto.results.mapNotNull((materialDto) =>
            this.materialMapper.map(materialDto),
        );

        return new Materials(materials, materialsDto.count);
    }
}
