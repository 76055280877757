import { PublicItineraryRepository } from "@beneficiary/data/repositories/public-itinerary.repository";
import { EditPublicItinerary } from "@beneficiary/domain/models/public-itinerary/edit-public-itinerary.model";
import { PublicItinerary } from "@beneficiary/domain/models/public-itinerary/public-itinerary.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class EditPublicItineraryUseCase {
    constructor(
        @inject(PublicItineraryRepository)
        private readonly publicItineraryRepository: PublicItineraryRepository,
    ) {}

    async execute(
        publicItinerary: EditPublicItinerary,
    ): Promise<Nullable<PublicItinerary>> {
        const editResult =
            await this.publicItineraryRepository.edit(publicItinerary);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedPublicItinerary) => editedPublicItinerary,
        );
    }
}
