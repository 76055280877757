import { ExternalDerivationDto } from "@beneficiary/data/dto/external-derivation/external-derivation.dto";
import { CenterTypeEnumMapper } from "@beneficiary/data/mappers/center-type-enum.mapper";
import { ExternalDerivation } from "@beneficiary/domain/models/external-derivation/external-derivation.model";
import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ExternalDerivationMapper {
    constructor(
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
        @inject(CenterTypeEnumMapper)
        private readonly centerTypeEnumMapper: CenterTypeEnumMapper,
    ) {}

    map(
        externalDerivationDto: ExternalDerivationDto,
    ): Nullable<ExternalDerivation> {
        const errors = validateSync(externalDerivationDto);

        if (errors.length > 0) {
            console.error(errors);
            return null;
        }

        const documentsData: IncDocument[] =
            externalDerivationDto.documents_data?.mapNotNull((doc) =>
                this.documentMapper.map(doc),
            ) ?? [];

        const centerType = this.centerTypeEnumMapper.map(
            externalDerivationDto.center_type,
        );
        if (!centerType) return null;

        return new ExternalDerivation(
            externalDerivationDto.id,
            externalDerivationDto.reason,
            centerType,
            externalDerivationDto.center_name,
            externalDerivationDto.is_derivate_to_external ?? false,
            externalDerivationDto.beneficiary,
            externalDerivationDto.contact_name ?? undefined,
            externalDerivationDto.contact_phone ?? undefined,
            externalDerivationDto.contact_email ?? undefined,
            documentsData,
        );
    }
}
