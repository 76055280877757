import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum DiagnosisTypeEnumDto {
    Social = "social",
    Conectividad = "connectivity",
    "" = "",
}

export class DiagnosisTypeDto {
    @IsEnum(DiagnosisTypeEnumDto)
    @IsNotEmpty()
    key!: DiagnosisTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
