import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum IdentificationTypeEnumDto {
    nie = "nie",
    dni = "dni",
    nif = "nif",
    cif = "cif",
    Passport = "passport",
}

export class IdentificationTypeDto {
    @IsEnum(IdentificationTypeEnumDto)
    @IsNotEmpty()
    key!: IdentificationTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
