import { IncDocument } from "@core/domain/models/inc-document.model";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { EntitySummary } from "@entity/domain/models/entity-summary.model";
import { DateTime } from "luxon";
import { StatusEnum } from "./status.model";

/**
 * toOther: Derived to other entity
 * fromOther: Derived from other entity to me
 * both: Derived to other entity and from other entity to me
 */
type DerivationStatus = "toOther" | "fromOther" | "both";

export interface ProceedingSearchFilters {
    entityId?: Undefinable<number>;
    beneficiaryId?: Undefinable<number>;
    search?: Undefinable<string>;
    projectId?: Undefinable<number>;
    activitiesIds?: Undefinable<number[]>;
    status?: Undefinable<StatusEnum>;
    derivation?: Undefinable<DerivationStatus>;
    catalogueId?: Undefinable<number>;
}

export class ProceedingDerivation {
    constructor(
        public originEntityId: number,
        public originEntityName: string,
        public entityId: number,
        public description: string,
        public date: DateTime,
    ) {}
}

export class ProceedingResolution {
    constructor(
        public description: string,
        public date: DateTime,
        public file: IncDocument,
    ) {}
}

export class Proceeding {
    // If the proceeding is derivated from another, this is the id of the origin proceeding
    get beneficiaryFullName(): string {
        return `${this.beneficiaryName} ${this.beneficiarySurname}`;
    }

    get professionalResponsibleFullName(): string {
        return `${this.professionalResponsibleName} ${this.professionalResponsibleSurname}`;
    }

    constructor(
        public readonly id: number,
        public readonly entity: EntitySummary,
        public beneficiaryId: number,
        public beneficiaryName: string,
        public beneficiarySurname: string,
        public projectId: number,
        public projectName: string,
        public status: StatusEnum,
        public activities: number[],
        public professionalResponsibleId: number,
        public professionalResponsibleName: string,
        public professionalResponsibleSurname: string,
        public motivation: string,
        public authorizationFile?: IncDocument,
        public resolution?: ProceedingResolution,
        public derivation?: ProceedingDerivation,
        public documents?: IncDocument[],
    ) {}
}
