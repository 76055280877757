import { PaginatedDto } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class AffiliationDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    identification!: string;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsOptional()
    territorial_scope!: string;

    @IsString()
    @IsOptional()
    web_address!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    incorporation_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    contact_person!: Nullable<string>;

    @IsString()
    @IsOptional()
    contact_phone!: Nullable<string>;

    @IsString()
    @IsOptional()
    contact_email!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class AffiliationsDto extends PaginatedDto {
    @IsArray()
    @Type(() => AffiliationDto)
    results!: AffiliationDto[];
}
