import { Pagination } from "@core/domain/models/pagination";
import { ContractsRepository } from "@entity/data/repositories/contracts.repository";
import {
    VolunteerContract,
    VolunteerContractSearchFilters,
} from "@entity/domain/models/employee/contracts/volunteer/volunteer-contract.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllVolunteerContractsByUseCase {
    constructor(
        @inject(ContractsRepository)
        private readonly contractsRepository: ContractsRepository,
    ) {}

    async execute(
        filters?: VolunteerContractSearchFilters,
    ): Promise<VolunteerContract[]> {
        const contractsResult =
            await this.contractsRepository.getAllVolunteerContractsBy(
                Pagination.NoPagination(),
                filters,
            );

        return contractsResult.fold(
            () => [],
            (contracts) => contracts.volunteerContracts,
        );
    }
}
