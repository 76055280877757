export enum TerminationReasonEnum {
    RESIGNATION = "resignation",
    OBJECTIVE_DISMISSAL = "objective_dismissal",
}

export class TerminationReason {
    constructor(
        public readonly id: TerminationReasonEnum,
        public readonly label: string,
    ) {}
}
