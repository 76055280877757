import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CreateEmployeeContractBody } from "@entity/data/dto/employee/contracts/employee/create-employee-contract.body";
import { EditEmployeeContractBody } from "@entity/data/dto/employee/contracts/employee/edit-employee-contract.body";
import {
    EmployeeContractDto,
    EmployeeContractQuery,
    EmployeeContractsDto,
} from "@entity/data/dto/employee/contracts/employee/employee-contract.dto";
import { CreateFreelanceContractBody } from "@entity/data/dto/employee/contracts/freelance/create-freelance-contract.body";
import { EditFreelanceContractBody } from "@entity/data/dto/employee/contracts/freelance/edit-freelance-contract.body";
import {
    FreelanceContractDto,
    FreelanceContractQuery,
    FreelanceContractsDto,
} from "@entity/data/dto/employee/contracts/freelance/freelance-contract.dto";
import { CreateVolunteerContractBody } from "@entity/data/dto/employee/contracts/volunteer/create-volunteer-contract.body";
import { EditVolunteerContractBody } from "@entity/data/dto/employee/contracts/volunteer/edit-volunteer-contract.body";
import {
    VolunteerContractDto,
    VolunteerContractQuery,
    VolunteerContractsDto,
} from "@entity/data/dto/employee/contracts/volunteer/volunteer-contract.dto";
import { CreateEmployeeContractMapper } from "@entity/data/mappers/employee/contracts/employee/create-employee-contract.mapper";
import { EditEmployeeContractMapper } from "@entity/data/mappers/employee/contracts/employee/edit-employee-contract.mapper";
import { EmployeeContractMapper } from "@entity/data/mappers/employee/contracts/employee/employee-contract.mapper";
import { EmployeeContractsMapper } from "@entity/data/mappers/employee/contracts/employee/employee-contracts.mapper";
import { CreateFreelanceContractMapper } from "@entity/data/mappers/employee/contracts/freelance/create-freelance-contract.mapper";
import { EditFreelanceContractMapper } from "@entity/data/mappers/employee/contracts/freelance/edit-freelance-contract.mapper";
import { FreelanceContractMapper } from "@entity/data/mappers/employee/contracts/freelance/freelance-contract.mapper";
import { FreelanceContractsMapper } from "@entity/data/mappers/employee/contracts/freelance/freelance-contracts.mapper";
import { CreateVolunteerContractMapper } from "@entity/data/mappers/employee/contracts/volunteer/create-volunteer-contract.mapper";
import { EditVolunteerContractMapper } from "@entity/data/mappers/employee/contracts/volunteer/edit-volunteer-contract.mapper";
import { VolunteerContractMapper } from "@entity/data/mappers/employee/contracts/volunteer/volunteer-contract.mapper";
import { VolunteerContractsMapper } from "@entity/data/mappers/employee/contracts/volunteer/volunteer-contracts.mapper";
import { CreateEmployeeContract } from "@entity/domain/models/employee/contracts/employee/create-employee-contract.model";
import { EditEmployeeContract } from "@entity/domain/models/employee/contracts/employee/edit-employee-contract.model";
import {
    EmployeeContract,
    EmployeeContractSearchFilters,
    EmployeeContracts,
} from "@entity/domain/models/employee/contracts/employee/employee-contract.model";
import { CreateFreelanceContract } from "@entity/domain/models/employee/contracts/freelance/create-freelance-contract.model";
import { EditFreelanceContract } from "@entity/domain/models/employee/contracts/freelance/edit-freelance-contract.model";
import {
    FreelanceContract,
    FreelanceContractSearchFilters,
    FreelanceContracts,
} from "@entity/domain/models/employee/contracts/freelance/freelance-contract.model";
import { CreateVolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/create-volunteer-contract.model";
import { EditVolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/edit-volunteer-contract.model";
import {
    VolunteerContract,
    VolunteerContractSearchFilters,
    VolunteerContracts,
} from "@entity/domain/models/employee/contracts/volunteer/volunteer-contract.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const FREELANCE_CONTRACTS_PATH = "/freelance_contracts/";
const VOLUNTEER_CONTRACTS_PATH = "/volunteer_contracts/";
const EMPLOYEE_CONTRACTS_PATH = "/employee_contracts/";

@injectable()
export class ContractsDatasource {
    // eslint-disable-next-line max-params
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(CreateFreelanceContractMapper)
        private readonly createFreelanceContractMapper: CreateFreelanceContractMapper,
        @inject(CreateVolunteerContractMapper)
        private readonly createVolunteerContractMapper: CreateVolunteerContractMapper,
        @inject(CreateEmployeeContractMapper)
        private readonly createEmployeeContractMapper: CreateEmployeeContractMapper,
        @inject(EditFreelanceContractMapper)
        private readonly editFreelanceContractMapper: EditFreelanceContractMapper,
        @inject(EditVolunteerContractMapper)
        private readonly editVolunteerContractMapper: EditVolunteerContractMapper,
        @inject(EditEmployeeContractMapper)
        private readonly editEmployeeContractMapper: EditEmployeeContractMapper,
        @inject(FreelanceContractsMapper)
        private readonly freelanceContractsMapper: FreelanceContractsMapper,
        @inject(FreelanceContractMapper)
        private readonly freelanceContractMapper: FreelanceContractMapper,
        @inject(VolunteerContractsMapper)
        private readonly volunteerContractsMapper: VolunteerContractsMapper,
        @inject(VolunteerContractMapper)
        private readonly volunteerContractMapper: VolunteerContractMapper,
        @inject(EmployeeContractsMapper)
        private readonly employeeContractsMapper: EmployeeContractsMapper,
        @inject(EmployeeContractMapper)
        private readonly employeeContractMapper: EmployeeContractMapper,
    ) {}

    async fetchAllFreelanceContractsBy(
        pagination: Pagination,
        filters?: FreelanceContractSearchFilters,
    ): Promise<Either<FallbackError, FreelanceContracts>> {
        const query: FreelanceContractQuery = {
            limit: pagination.pageSize,
        };
        if (filters?.employee) query.employee = filters.employee;

        const freelanceContractsResult =
            await this.http.get<FreelanceContractDto>(
                FREELANCE_CONTRACTS_PATH,
                {
                    query,
                },
            );

        return freelanceContractsResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const freelanceContracts = this.freelanceContractsMapper.map(
                    plainToClass(FreelanceContractsDto, response.data),
                );

                return Either.Right(freelanceContracts);
            });
    }

    async fetchAllVolunteerContractsBy(
        pagination: Pagination,
        filters?: VolunteerContractSearchFilters,
    ): Promise<Either<FallbackError, VolunteerContracts>> {
        const query: VolunteerContractQuery = {
            limit: pagination.pageSize,
        };
        if (filters?.employee) query.employee = filters.employee;

        const volunteerContractsResult =
            await this.http.get<VolunteerContractDto>(
                VOLUNTEER_CONTRACTS_PATH,
                {
                    query,
                },
            );

        return volunteerContractsResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const volunteerContracts = this.volunteerContractsMapper.map(
                    plainToClass(VolunteerContractsDto, response.data),
                );

                return Either.Right(volunteerContracts);
            });
    }

    async fetchAllEmployeeContractsBy(
        pagination: Pagination,
        filters?: EmployeeContractSearchFilters,
    ): Promise<Either<FallbackError, EmployeeContracts>> {
        const query: EmployeeContractQuery = {
            limit: pagination.pageSize,
        };
        if (filters?.employee) query.employee = filters.employee;

        const employeeContractsResult =
            await this.http.get<EmployeeContractDto>(EMPLOYEE_CONTRACTS_PATH, {
                query,
            });

        return employeeContractsResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const employeeContracts = this.employeeContractsMapper.map(
                    plainToClass(EmployeeContractsDto, response.data),
                );

                return Either.Right(employeeContracts);
            });
    }

    async createFreelanceContract(
        newContract: CreateFreelanceContract,
    ): Promise<Either<ValidationError | FallbackError, FreelanceContract>> {
        const contractBody =
            this.createFreelanceContractMapper.mapToCreateDto(newContract);

        const result = await this.http.post<
            FreelanceContractDto,
            CreateFreelanceContractBody
        >(FREELANCE_CONTRACTS_PATH, contractBody);

        return result
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const contract = this.freelanceContractsMapper.mapContract(
                    plainToClass(FreelanceContractDto, response.data),
                );

                if (!contract) return Either.Left(new FallbackError());

                return Either.Right(contract);
            });
    }

    async createVolunteerContract(
        newContract: CreateVolunteerContract,
    ): Promise<Either<ValidationError | FallbackError, VolunteerContract>> {
        const contractBody =
            this.createVolunteerContractMapper.mapToCreateDto(newContract);

        const result = await this.http.post<
            VolunteerContractDto,
            CreateVolunteerContractBody
        >(VOLUNTEER_CONTRACTS_PATH, contractBody);

        return result
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const contract = this.volunteerContractsMapper.mapContract(
                    plainToClass(VolunteerContractDto, response.data),
                );

                if (!contract) return Either.Left(new FallbackError());

                return Either.Right(contract);
            });
    }

    async createEmployeeContract(
        newContract: CreateEmployeeContract,
    ): Promise<Either<ValidationError | FallbackError, EmployeeContract>> {
        const contractBody =
            this.createEmployeeContractMapper.mapToCreateDto(newContract);

        const result = await this.http.post<
            EmployeeContractDto,
            CreateEmployeeContractBody
        >(EMPLOYEE_CONTRACTS_PATH, contractBody);

        return result
            .mapLeft((error) => {
                if (error instanceof HttpFailedRequestError) {
                    if (error.errorCode === HttpErrorCodeEnum.GenericError) {
                        return new ValidationError(error.data);
                    }
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const contract = this.employeeContractsMapper.mapContract(
                    plainToClass(EmployeeContractDto, response.data),
                );

                if (!contract) return Either.Left(new FallbackError());

                return Either.Right(contract);
            });
    }

    async updateFreelanceContract(
        freelanceContract: EditFreelanceContract,
    ): Promise<Either<ValidationError | FallbackError, FreelanceContract>> {
        const editedFreelanceContractDto =
            this.editFreelanceContractMapper.mapToDto(freelanceContract);

        const editProjectMaterialResult = await this.http.patch<
            FreelanceContractDto,
            EditFreelanceContractBody
        >(
            `${FREELANCE_CONTRACTS_PATH}${freelanceContract.id}/`,
            editedFreelanceContractDto,
        );

        return editProjectMaterialResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const freelanceContractEdited =
                    this.freelanceContractMapper.map(
                        plainToClass(FreelanceContractDto, response.data),
                    );
                if (!freelanceContractEdited)
                    return Either.Left(new FallbackError());

                return Either.Right(freelanceContractEdited);
            });
    }

    async updateVolunteerContract(
        volunteerContract: EditVolunteerContract,
    ): Promise<Either<ValidationError | FallbackError, VolunteerContract>> {
        const editedVolunteerContractDto =
            this.editVolunteerContractMapper.mapToDto(volunteerContract);

        const editProjectMaterialResult = await this.http.patch<
            VolunteerContractDto,
            EditVolunteerContractBody
        >(
            `${VOLUNTEER_CONTRACTS_PATH}${volunteerContract.id}/`,
            editedVolunteerContractDto,
        );

        return editProjectMaterialResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const volunteerContractEdited =
                    this.volunteerContractMapper.map(
                        plainToClass(VolunteerContractDto, response.data),
                    );
                if (!volunteerContractEdited)
                    return Either.Left(new FallbackError());

                return Either.Right(volunteerContractEdited);
            });
    }

    async updateEmployeeContract(
        employeeContract: EditEmployeeContract,
    ): Promise<Either<ValidationError | FallbackError, EmployeeContract>> {
        const editedEmployeeContractDto =
            this.editEmployeeContractMapper.mapToDto(employeeContract);

        const editProjectMaterialResult = await this.http.patch<
            EmployeeContractDto,
            EditEmployeeContractBody
        >(
            `${EMPLOYEE_CONTRACTS_PATH}${employeeContract.id}/`,
            editedEmployeeContractDto,
        );

        return editProjectMaterialResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const employeeContractEdited = this.employeeContractMapper.map(
                    plainToClass(EmployeeContractDto, response.data),
                );
                if (!employeeContractEdited)
                    return Either.Left(new FallbackError());

                return Either.Right(employeeContractEdited);
            });
    }

    async deleteFreelanceContract(
        contractId: number,
    ): Promise<Either<FallbackError, true>> {
        const result = await this.http.delete(
            `${FREELANCE_CONTRACTS_PATH}${contractId}`,
        );

        return result.mapLeft(() => new FallbackError()).map(() => true);
    }

    async deleteVolunteerContract(
        contractId: number,
    ): Promise<Either<FallbackError, true>> {
        const result = await this.http.delete(
            `${VOLUNTEER_CONTRACTS_PATH}${contractId}`,
        );

        return result.mapLeft(() => new FallbackError()).map(() => true);
    }

    async deleteEmployeeContract(
        contractId: number,
    ): Promise<Either<FallbackError, true>> {
        const result = await this.http.delete(
            `${EMPLOYEE_CONTRACTS_PATH}${contractId}`,
        );

        return result.mapLeft(() => new FallbackError()).map(() => true);
    }
}
