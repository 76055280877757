import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateFinancialEntityBudget } from "@project/domain/models/budget/create-financial-entity-budget.model";
import { FinancialEntityBudget } from "@project/domain/models/budget/financial-entity-budget.model";
import { CreateBudgetUseCase } from "@project/domain/usecases/budget/create-budget-usecase";
import { FinancialEntityBudgetFormValuesValidated } from "@project/presentation/components/budget-form/budget-form.component";
import { inject, injectable } from "inversify";
import { makeObservable } from "mobx";

@injectable()
export class CreateFinancialEntityBudgetPageViewModel extends BaseViewModel {
    _financialEntityId: Undefinable<number> = undefined;
    constructor(
        @inject(CreateBudgetUseCase)
        private readonly createBudgetUseCase: CreateBudgetUseCase,
    ) {
        super();
        makeObservable(this);
    }

    async createBudget(
        budget: FinancialEntityBudgetFormValuesValidated,
    ): Promise<Nullable<FinancialEntityBudget>> {
        if (!this._financialEntityId) return null;

        LoadLayoutStore.start();

        const result = await this.createBudgetUseCase.execute(
            new CreateFinancialEntityBudget(
                budget.name,
                budget.description,
                this._financialEntityId,
            ),
        );

        if (result) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return result;
    }
}
