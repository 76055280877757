import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ContractTypeEnumDto } from "@project/data/dto/expenses/contract-type.dto";
import { ContributionGroupTypeEnumDto } from "@project/data/dto/expenses/contribution-group-type.dto";
import { CreatePersonnelExpenseBody } from "@project/data/dto/expenses/create-expense.body";
import { EditPersonnelExpenseBody } from "@project/data/dto/expenses/edit-expense.body";
import { ExpenseTypeEnumDto } from "@project/data/dto/expenses/expense-type.dto";
import { ExpenseDto } from "@project/data/dto/expenses/expenses.dto";
import { ManagementDto } from "@project/data/dto/expenses/management.dto";
import { MonthTypeEnumDto } from "@project/data/dto/expenses/month-type.dto";
import { ContractTypeEnumMapper } from "@project/data/mappers/expenses/contract-type-enum.mapper";
import { ContributionGroupTypeEnumMapper } from "@project/data/mappers/expenses/contribution-group-type-enum.mapper";
import { ExpenseSubTypeEnumMapper } from "@project/data/mappers/expenses/expense-sub-type-enum.mapper";
import { MonthTypeEnumMapper } from "@project/data/mappers/expenses/month-type-enum.mapper";
import { CreatePersonnelExpense } from "@project/domain/models/expenses/create-expense.model";
import { EditPersonnelExpense } from "@project/domain/models/expenses/edit-expense.model";
import { ExpenseTypeEnum } from "@project/domain/models/expenses/expense-type.model";
import { PersonnelExpense } from "@project/domain/models/expenses/expense.model";
import { ExpenseSubTypeEnum } from "@project/domain/models/expenses/sub-type.model";
import { ExpensePersonnelFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/personnel/expense-personnel-form.component";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

const maximumDecimalsNumber = 2;

@injectable()
export class PersonnelExpenseMapper {
    constructor(
        @inject(ExpenseSubTypeEnumMapper)
        private readonly expenseSubTypeMapper: ExpenseSubTypeEnumMapper,
        @inject(ContractTypeEnumMapper)
        private readonly contractTypeMapper: ContractTypeEnumMapper,
        @inject(ContributionGroupTypeEnumMapper)
        private readonly contributionGroupTypeEnumMapper: ContributionGroupTypeEnumMapper,
        @inject(MonthTypeEnumMapper)
        private readonly monthTypeMapper: MonthTypeEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
    ) {}

    map(
        expenseDto: ExpenseDto,
        personnelData: ManagementDto,
    ): Nullable<PersonnelExpense> {
        const errors = validateSync(personnelData);

        if (errors.length > 0) {
            console.error(errors);
            return null;
        }

        const subtype = this.expenseSubTypeMapper.map(
            personnelData.subtype_expense,
        );

        let contractType = null;
        if (personnelData.contract_type)
            contractType = this.contractTypeMapper.map(
                personnelData.contract_type,
            );

        let contributionGroup = null;
        if (personnelData.contribution_group)
            contributionGroup = this.contributionGroupTypeEnumMapper.map(
                personnelData.contribution_group,
            );

        let monthType = null;
        if (personnelData.month)
            monthType = this.monthTypeMapper.map(personnelData.month);

        const documents: IncDocument[] = [];
        if (expenseDto.documents_data) {
            expenseDto.documents_data.forEach((document) => {
                const documentMapped = this.documentMapper.map(document);
                if (documentMapped) {
                    documents.push(documentMapped);
                }
            });
        }

        return new PersonnelExpense(
            expenseDto.id,
            "",
            subtype ?? ExpenseSubTypeEnum.NONE,
            "",
            personnelData.full_name ?? "",
            personnelData.identification_document ?? "",
            personnelData.contract_start_date
                ? DateTime.fromISO(personnelData.contract_start_date)
                : null,
            personnelData.contract_end_date
                ? DateTime.fromISO(personnelData.contract_end_date)
                : null,
            contractType,
            personnelData.weekly_hours,
            // personnelData.professional_group_exp
            contributionGroup,
            monthType,
            personnelData.days_imputable,
            personnelData.net_salary
                ? parseFloat(personnelData.net_salary)
                : null,
            personnelData.irpf ? parseInt(personnelData.irpf) : null,
            personnelData.social_security
                ? parseInt(personnelData.social_security)
                : null,
            personnelData.monthly_gross_amount
                ? parseInt(personnelData.monthly_gross_amount)
                : null,
            personnelData.company_social_security
                ? parseInt(personnelData.company_social_security)
                : null,
            personnelData.total_cost
                ? parseFloat(personnelData.total_cost)
                : null,
            personnelData.project_allocation_percentage_month
                ? parseFloat(personnelData.project_allocation_percentage_month)
                : null,
            personnelData.amount_allocated_to_project
                ? parseFloat(personnelData.amount_allocated_to_project)
                : null,
            personnelData.payment_date
                ? DateTime.fromISO(personnelData.payment_date)
                : null,
            personnelData.full_name ?? "",
            documents,
            expenseDto.project,
        );
    }

    mapFromFormValues(
        createFormValues: ExpensePersonnelFormValuesValidated,
        projectId: number,
    ): CreatePersonnelExpense {
        return new CreatePersonnelExpense(
            projectId,
            ExpenseTypeEnum.PERSONNEL_MANAGEMENT_ADMINISTRATION,
            createFormValues.concept,
            createFormValues.dni,
            createFormValues.contractStartDate,
            createFormValues.contractEndDate,
            createFormValues.contractType,
            createFormValues.workHoursWeek,
            createFormValues.contributionGroup,
            createFormValues.month,
            createFormValues.chargeableDays,
            createFormValues.liquidSalary,
            createFormValues.irpf,
            createFormValues.socialSecurity,
            createFormValues.grossIncome,
            createFormValues.socialSecurityCompany,
            createFormValues.totalCost,
            createFormValues.percentageImputation,
            createFormValues.amountImputation,
            null,
            createFormValues.documents.map((document) => document.id),
            createFormValues.concept,
        );
    }

    mapFromFormEditValues(
        editFormValues: ExpensePersonnelFormValuesValidated,
        expenseId: number,
        projectId: number,
    ): EditPersonnelExpense {
        return new EditPersonnelExpense(
            expenseId,
            projectId,
            ExpenseTypeEnum.PERSONNEL_MANAGEMENT_ADMINISTRATION,
            editFormValues.concept,
            editFormValues.dni,
            editFormValues.contractStartDate,
            editFormValues.contractEndDate,
            editFormValues.contractType,
            editFormValues.workHoursWeek,
            editFormValues.contributionGroup,
            editFormValues.month,
            editFormValues.chargeableDays,
            editFormValues.liquidSalary,
            editFormValues.irpf,
            editFormValues.socialSecurity,
            editFormValues.grossIncome,
            editFormValues.socialSecurityCompany,
            editFormValues.totalCost,
            editFormValues.percentageImputation,
            editFormValues.amountImputation,
            null,
            editFormValues.documents.map((document) => document.id),
            editFormValues.concept,
        );
    }

    mapToDto(
        createExpense: CreatePersonnelExpense,
    ): CreatePersonnelExpenseBody {
        let contractType = null;
        if (createExpense.contractType) {
            contractType = this.contractTypeMapper.mapToDto(
                createExpense.contractType,
            );
        }
        let contributionGroup = null;
        if (createExpense.contributionGroup) {
            contributionGroup = this.contributionGroupTypeEnumMapper.mapToDto(
                createExpense.contributionGroup,
            );
        }
        let monthType = null;
        if (createExpense.month) {
            monthType = this.monthTypeMapper.mapToDto(createExpense.month);
        }

        const percentageImputation =
            createExpense.percentageImputation?.toFixed(maximumDecimalsNumber);

        return {
            project: createExpense.projectId,
            type_expense:
                ExpenseTypeEnumDto.PERSONNEL_MANAGEMENT_ADMINISTRATION,
            amount_allocated_to_project: createExpense.amountImputation ?? 0,
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: createExpense.documents,
            full_name: createExpense.concept ?? "",
            identification_document: createExpense.dni,
            contract_start_date:
                createExpense.contractStartDate?.toISODate() ?? "",
            contract_end_date: createExpense.contractEndDate?.toISODate() ?? "",
            contract_type: contractType ?? ContractTypeEnumDto.TEMPORARY,
            weekly_hours: createExpense.weeklyHours ?? 0,
            contribution_group:
                contributionGroup ??
                ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_1,
            month: monthType ?? MonthTypeEnumDto.JANUARY,
            project_allocation_percentage_month: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            days_imputable: createExpense.daysImputable ?? 0,
            net_salary: createExpense.netSalary?.toString() ?? "0",
            irpf: createExpense.irpf?.toString() ?? "0",
            social_security: createExpense.socialSecurity?.toString() ?? "",
            monthly_gross_amount:
                createExpense.monthlyGrossIncome?.toString() ?? "0",
            company_social_security:
                createExpense.companySocialSecurity?.toString() ?? "",
            total_cost: createExpense.totalCost?.toString() ?? "0",
        };
    }

    mapToEditDto(editExpense: EditPersonnelExpense): EditPersonnelExpenseBody {
        let contractType = null;
        if (editExpense.contractType) {
            contractType = this.contractTypeMapper.mapToDto(
                editExpense.contractType,
            );
        }
        let contributionGroup = null;
        if (editExpense.contributionGroup) {
            contributionGroup = this.contributionGroupTypeEnumMapper.mapToDto(
                editExpense.contributionGroup,
            );
        }
        let monthType = null;
        if (editExpense.month) {
            monthType = this.monthTypeMapper.mapToDto(editExpense.month);
        }

        const percentageImputation = editExpense.percentageImputation?.toFixed(
            maximumDecimalsNumber,
        );

        return {
            id: editExpense.id,
            project: editExpense.projectId,
            type_expense:
                ExpenseTypeEnumDto.PERSONNEL_MANAGEMENT_ADMINISTRATION,
            amount_allocated_to_project: editExpense.amountImputation ?? 0,
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: editExpense.documents,
            full_name: editExpense.concept ?? "",
            identification_document: editExpense.dni,
            contract_start_date:
                editExpense.contractStartDate?.toISODate() ?? "",
            contract_end_date: editExpense.contractEndDate?.toISODate() ?? "",
            contract_type: contractType ?? ContractTypeEnumDto.TEMPORARY,
            weekly_hours: editExpense.weeklyHours ?? 0,
            contribution_group:
                contributionGroup ??
                ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_1,
            month: monthType ?? MonthTypeEnumDto.JANUARY,
            project_allocation_percentage_month: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            days_imputable: editExpense.daysImputable ?? 0,
            net_salary: editExpense.netSalary?.toString() ?? "0",
            irpf: editExpense.irpf?.toString() ?? "0",
            social_security: editExpense.socialSecurity?.toString() ?? "",
            monthly_gross_amount:
                editExpense.monthlyGrossIncome?.toString() ?? "0",
            company_social_security:
                editExpense.companySocialSecurity?.toString() ?? "",
            total_cost: editExpense.totalCost?.toString() ?? "0",
        };
    }
}
