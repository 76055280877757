import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { ProjectActivityTaskRepository } from "../../../data/repositories/project-activity-task.repository";
import {
    CreateProjectActivityTaskBody,
    ProjectActivityTask,
} from "../../models/project-activity-task.model";

@injectable()
export class CreateActivityTaskUseCase {
    constructor(
        @inject(ProjectActivityTaskRepository)
        private readonly projectActivityTaskRepository: ProjectActivityTaskRepository,
    ) {}

    async execute(
        activityTask: CreateProjectActivityTaskBody,
    ): Promise<Nullable<ProjectActivityTask>> {
        const creationResult =
            await this.projectActivityTaskRepository.create(activityTask);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdActivityTask) => createdActivityTask,
        );
    }
}
