import { Contact } from "@proceeding/domain/models/contact.model";
import { injectable } from "inversify";
import { ContactFormValuesValidated } from "../../presentation/component/entity-form/contact/form/contact.form";

@injectable()
export class EditContactMapper {
    mapToContact(contact: ContactFormValuesValidated): Contact {
        return new Contact(
            contact.id,
            contact.entity,
            contact.name,
            contact.email,
            contact.phone1,
            contact.phone2 ?? undefined,
            contact.web ?? undefined,
            contact.observations ?? undefined,
            contact.identificationType ?? undefined,
            contact.identification ?? undefined,
            contact.isLegalRepresentative,
        );
    }
}
