import { TechnicalProposalsDto } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { TechnicalProposalMapper } from "@project/data/mappers/technical-proposal/technical-proposal.mapper";
import { TechnicalProposals } from "@project/domain/models/technical-proposals.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class TechnicalProposalsMapper {
    constructor(
        @inject(TechnicalProposalMapper)
        private readonly technicalProposalMapper: TechnicalProposalMapper,
    ) {}

    map(technicalProposalDto: TechnicalProposalsDto): TechnicalProposals {
        const errors = validateSync(technicalProposalDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new TechnicalProposals([], 0);
        }

        const technicalProposals = technicalProposalDto.results.mapNotNull(
            (technicalProposal) =>
                this.technicalProposalMapper.map(technicalProposal),
        );

        return new TechnicalProposals(
            technicalProposals,
            technicalProposalDto.count,
        );
    }
}
