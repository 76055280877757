import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectEmployeeDedicationRepository } from "@project/data/repositories/project-employee-dedication.repository";
import { InvalidDedicationDatesError } from "@project/domain/errors/invalid-dedication-dates.error";
import {
    CreateProjectEmployeeDedication,
    ProjectEmployeeDedication,
} from "@project/domain/models/project-employee-dedications.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateProjectEmployeeDedicationUseCase {
    constructor(
        @inject(ProjectEmployeeDedicationRepository)
        private readonly projectEmployeeDedicationRepository: ProjectEmployeeDedicationRepository,
    ) {}

    async execute(
        createProjectEmployee: CreateProjectEmployeeDedication,
    ): Promise<Nullable<ProjectEmployeeDedication>> {
        const projectEmployeeDedicationResult =
            await this.projectEmployeeDedicationRepository.create(
                createProjectEmployee,
            );

        return projectEmployeeDedicationResult.fold(
            (error) => {
                if (error instanceof InvalidDedicationDatesError) {
                    ToastManagerStore.warning(error.message);
                } else {
                    ToastManagerStore.error(error.message);
                }

                return null;
            },
            (projectEmployeeDedication) => projectEmployeeDedication,
        );
    }
}
