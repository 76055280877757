import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import {
    BudgetSpreadsheetColumnDto,
    BudgetSpreadsheetGroupDto,
} from "@project/data/dto/budget-spreadsheet.dto";
import { BudgetSpreadsheetItemMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-item.mapper";
import { BudgetSpreadsheetValidationMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-validation.mapper";
import {
    BudgetSpreadsheetGroup,
    BudgetSpreadsheetItem,
    BudgetSpreadsheetValidations,
} from "@project/domain/models/budget-spreadsheet.model";
import { validateSync } from "class-validator";
import { OrderedMap } from "immutable";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetSpreadsheetGroupMapper {
    constructor(
        @inject(BudgetSpreadsheetItemMapper)
        private readonly budgetSpreadsheetItemMapper: BudgetSpreadsheetItemMapper,
        @inject(BudgetSpreadsheetValidationMapper)
        private readonly budgetSpreadsheetValidationMapper: BudgetSpreadsheetValidationMapper,
    ) {}

    map(
        groupDto: BudgetSpreadsheetGroupDto,
        columnsDto: BudgetSpreadsheetColumnDto[],
    ): Nullable<BudgetSpreadsheetGroup> {
        const errors = validateSync(groupDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        const items: OrderedMap<number, BudgetSpreadsheetItem> = OrderedMap(
            groupDto.items
                .mapNotNull((itemDto) =>
                    this.budgetSpreadsheetItemMapper.map(itemDto, columnsDto),
                )
                .map((item) => [item.id, item]),
        );

        let validations: Undefinable<BudgetSpreadsheetValidations> = undefined;
        if (groupDto.validations) {
            validations = OrderedMap(
                groupDto.validations
                    .mapNotNull((validation) =>
                        this.budgetSpreadsheetValidationMapper.map(validation),
                    )
                    .map((validation) => [validation.columnId, validation]),
            );
        }

        return new BudgetSpreadsheetGroup(
            groupDto.id,
            groupDto.name,
            items,
            validations,
        );
    }
}
