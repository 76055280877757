import { District } from "@beneficiary/domain/models/district.model";
import { GetAllDistrictsUseCase } from "@beneficiary/domain/usecases/get-all-disctricts.usecase";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class AddressFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    districts: District[] = [];

    constructor(
        @inject(GetAllDistrictsUseCase)
        private readonly getAllDistrictsUseCase: GetAllDistrictsUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getAllDistricts()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllDistricts(): Promise<void> {
        const districts = await this.getAllDistrictsUseCase.execute();

        runInAction(() => {
            this.districts = districts;
        });
    }
}
