import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { ProfessionalGroupEnumDto } from "@entity/data/dto/employee/professional-group.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class FreelanceContractDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsBoolean()
    @IsOptional()
    is_removed!: Nullable<boolean>;

    @IsString()
    @IsNotEmpty()
    service!: string;

    @IsEnum(ProfessionalGroupEnumDto)
    @IsNotEmpty()
    professional_group!: ProfessionalGroupEnumDto;

    @IsDateString()
    @IsNotEmpty()
    start_date!: string;

    @IsDateString()
    @IsOptional()
    end_date!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    total_hours!: string;

    @IsString()
    @IsNotEmpty()
    hours_cost!: string;

    @IsString()
    @IsNotEmpty()
    total_cost!: string;

    @IsString()
    @IsNotEmpty()
    invoices_number!: string;

    @IsString()
    @IsNotEmpty()
    IRPF!: string;

    @IsBoolean()
    @IsOptional()
    is_active!: Nullable<boolean>;

    @IsNumber()
    @IsNotEmpty()
    employee!: number;
}

export class FreelanceContractsDto extends PaginatedDto {
    @IsArray()
    @Type(() => FreelanceContractDto)
    results!: FreelanceContractDto[];
}

export type FreelanceContractQuery = PaginatedQuery & {
    employee?: Nullish<number>;
};
