import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { ContractType } from "@project/domain/models/expenses/contract-type.model";
import { GetAllContractTypesUseCase } from "@project/domain/usecases/expenses/get-all-contract-types.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class ContractTerminationFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    contractTypes: ContractType[] = [];

    constructor(
        @inject(GetAllContractTypesUseCase)
        private readonly getAllContractTypesUseCase: GetAllContractTypesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getContractTypes()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getContractTypes(): Promise<void> {
        const contractTypes = await this.getAllContractTypesUseCase.execute();

        runInAction(() => {
            this.contractTypes = contractTypes;
        });
    }

    @action
    getContractTypeLabel(typeId: string): string {
        const type = this.contractTypes.find(
            (t) => t.id === typeId.toUpperCase(),
        );
        return type ? type.label : "-";
    }
}
