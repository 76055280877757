import { RegisteredRecord } from "@entity/domain/models/registered-record/registered-record.model";

export class CreateRegisteredRecord {
    constructor(
        public registeredAdministrativeRecordScope: RegisteredRecord["registeredAdministrativeRecordScope"],
        public registeredRecordName: RegisteredRecord["registeredRecordName"],
        public recordIdentificationCode: RegisteredRecord["recordIdentificationCode"],
        public entity: RegisteredRecord["entity"],
    ) {}
}
