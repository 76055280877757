import { LocalRepository } from "@entity/data/repositories/local.repository";
import { CostPeriodicityType } from "@entity/domain/models/locals/cost-periodicity.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllCostPeriodicitiesUseCase {
    constructor(
        @inject(LocalRepository)
        private readonly localRepository: LocalRepository,
    ) {}

    async execute(): Promise<CostPeriodicityType[]> {
        const getAllCostPeriodicitiesResult =
            await this.localRepository.getAllCostPeriodicities();

        return getAllCostPeriodicitiesResult.fold(
            () => [],
            (costPeriodicities) => costPeriodicities,
        );
    }
}
