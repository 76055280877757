import { CreateInternalNoteBody } from "@beneficiary/data/dto/internal-notes/create-internal-note.body";
import { EditInternalNoteBody } from "@beneficiary/data/dto/internal-notes/edit-internal-note.body";
import {
    InternalNoteDto,
    InternalNotesDto,
    InternalNotesQuery,
} from "@beneficiary/data/dto/internal-notes/internal-note.dto";
import { CreateInternalNoteMapper } from "@beneficiary/data/mappers/internal-notes/create-internal-note.mapper";
import { EditInternalNoteMapper } from "@beneficiary/data/mappers/internal-notes/edit-internal-note.mapper";
import { InternalNotesMapper } from "@beneficiary/data/mappers/internal-notes/internal-notes.mapper";
import { CreateInternalNote } from "@beneficiary/domain/models/internal-notes/create-internal-note.model";
import { EditInternalNote } from "@beneficiary/domain/models/internal-notes/edit-internal-note.model";
import {
    InternalNote,
    InternalNotes,
} from "@beneficiary/domain/models/internal-notes/internal-note.model";
import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const INTERNAL_NOTES_PATH = "/internal_notes/";

@injectable()
export class InternalNotesDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(InternalNotesMapper)
        private readonly internalNotesMapper: InternalNotesMapper,
        @inject(CreateInternalNoteMapper)
        private readonly createInternalNoteMapper: CreateInternalNoteMapper,
        @inject(EditInternalNoteMapper)
        private readonly editInternalNoteMapper: EditInternalNoteMapper,
    ) {}

    async fetchAllBy(
        beneficiaryId: number,
        pagination: Pagination,
    ): Promise<Either<FallbackError, InternalNotes>> {
        const query: InternalNotesQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
            beneficiary: beneficiaryId,
        };

        const internalNotesResult = await this.http.get<InternalNoteDto>(
            INTERNAL_NOTES_PATH,
            {
                query,
            },
        );

        return internalNotesResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const internalNotes = this.internalNotesMapper.map(
                    plainToClass(InternalNotesDto, response.data),
                );

                return Either.Right(internalNotes);
            });
    }

    async create(
        newInternalNote: CreateInternalNote,
    ): Promise<Either<ValidationError | FallbackError, InternalNote>> {
        const internalNoteBody =
            this.createInternalNoteMapper.mapToCreateDto(newInternalNote);

        const internalNoteResult = await this.http.post<CreateInternalNoteBody>(
            INTERNAL_NOTES_PATH,
            internalNoteBody,
        );

        return internalNoteResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const internalNote = this.internalNotesMapper.mapInternalNote(
                    plainToClass(InternalNoteDto, response.data),
                );

                if (!internalNote) return Either.Left(new FallbackError());

                return Either.Right(internalNote);
            });
    }

    async edit(
        editInternalNote: EditInternalNote,
    ): Promise<Either<ValidationError | FallbackError, InternalNote>> {
        const editedInternalNote =
            this.editInternalNoteMapper.mapToDto(editInternalNote);

        const editInternalNoteResult = await this.http.patch<
            InternalNoteDto,
            EditInternalNoteBody
        >(`${INTERNAL_NOTES_PATH}${editInternalNote.id}/`, editedInternalNote);

        return editInternalNoteResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const internalNote = this.internalNotesMapper.mapInternalNote(
                    plainToClass(InternalNoteDto, response.data),
                );

                if (!internalNote) return Either.Left(new FallbackError());

                return Either.Right(internalNote);
            });
    }

    async delete(internalNoteId: number): Promise<Either<FallbackError, true>> {
        const deleteInternalNote = await this.http.delete(
            `${INTERNAL_NOTES_PATH}${internalNoteId}`,
        );

        return deleteInternalNote
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
