import { Nullable } from "@core/domain/types/nullable.type";
import { ServiceDto } from "@entity/data/dto/services.dto";
import { Service } from "@entity/domain/models/service.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class ServiceMapper {
    map(serviceDto: ServiceDto): Nullable<Service> {
        const errors = validateSync(serviceDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        return new Service(
            serviceDto.id,
            serviceDto.entity,
            serviceDto.name,
            serviceDto.description,
            serviceDto.catalogue ?? undefined,
        );
    }
}
