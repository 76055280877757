import { coreTypes } from "@core/core-types.di";
import { EXPAND_ALL, FieldsQuery } from "@core/data/dto/fields.dto";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { ActionLineDto } from "@entity/data/dto/action-line-enum.dto";
import { AdministrativeRecordScopeDto } from "@entity/data/dto/administrative-record-scope-enum.dto";
import { CategoryDto } from "@entity/data/dto/category.dto";
import { CreateEntityBody } from "@entity/data/dto/create-entity.body";
import { CrosscuttingScopeDto } from "@entity/data/dto/crosscutting-scope-enum.dto";
import { EditEntityBody } from "@entity/data/dto/edit-entity.body";
import {
    EntitiesSummaryDto,
    EntitiesSummaryQuery,
} from "@entity/data/dto/entities-summary.dto";
import { EntityTerritorialScopeDto } from "@entity/data/dto/entity-territorial-scope.dto";
import { TypologyDto } from "@entity/data/dto/typologies.dto";
import { ActionLinesMapper } from "@entity/data/mappers/action-lines.mapper";
import { AdministrativeRecordScopeMapper } from "@entity/data/mappers/administrative-record-scope.mapper";
import { CategoryMapper } from "@entity/data/mappers/category.mapper";
import { CrosscuttingScopesMapper } from "@entity/data/mappers/crosscutting-scope.mapper";
import { EntityTerritorialScopeMapper } from "@entity/data/mappers/entity-territorial-scope.mapper";
import { TypologiesMapper } from "@entity/data/mappers/typologies.mapper";
import { ActionLine } from "@entity/domain/models/action-line.model";
import { AdministrativeRecordScope } from "@entity/domain/models/administrative-record-scope.model";
import { Category } from "@entity/domain/models/category.model";
import { CreateEntity } from "@entity/domain/models/create-entity.model";
import { CrosscuttingScope } from "@entity/domain/models/crosscutting-scope.model";
import { EditEntity } from "@entity/domain/models/edit-entity.model";
import { EntityTerritorialScope } from "@entity/domain/models/entity-territorial-scope.model";
import { Entity } from "@entity/domain/models/entity.model";
import { ExportProjectDeposits } from "@entity/domain/models/export-project-deposits.model";
import { ExportProjectExpenses } from "@entity/domain/models/export-project-expenses.model";
import { Typology } from "@entity/domain/models/typology.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { CreateUserEntity } from "../../domain/models/create-lite-entity.model";
import {
    EntitiesSummary,
    EntitySearchFilters,
} from "../../domain/models/entity-summary.model";
import { Materials } from "../../domain/models/material/material.model";
import { EntityDto } from "../dto/entity.dto";
import { ExportProjectDepositsQuery } from "../dto/export-project-deposits.dto";
import { ExportProjectExpensesQuery } from "../dto/export-project-expenses.dto";
import { MaterialsDto } from "../dto/material/materials.dto";
import { CreateEntityMapper } from "../mappers/create-entity.mapper";
import { ExportProjectDepositsMapper } from "../mappers/deposit/export-project-deposits.mapper";
import { ExportProjectExpensesMapper } from "../mappers/deposit/export-project-expenses.mapper";
import { EditEntityMapper } from "../mappers/edit-entity.mapper";
import { EntitiesSummaryMapper } from "../mappers/entities-summary.mapper";
import { EntityMapper } from "../mappers/entity.mapper";
import { MaterialsMapper } from "../mappers/material/materials.mapper";
import { TypologyEnumMapper } from "../mappers/typology-enum.mapper";

@injectable()
export class EntityDatasource {
    // eslint-disable-next-line max-params
    constructor(
        @inject(CategoryMapper)
        private readonly categoryMapper: CategoryMapper,
        @inject(TypologyEnumMapper)
        private readonly typologyEnumMapper: TypologyEnumMapper,
        @inject(CreateEntityMapper)
        private readonly createEntityMapper: CreateEntityMapper,
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(EditEntityMapper)
        private readonly editEntityMapper: EditEntityMapper,
        @inject(EntitiesSummaryMapper)
        private readonly entitiesSummaryMapper: EntitiesSummaryMapper,
        @inject(EntityMapper)
        private readonly entityMapper: EntityMapper,
        @inject(MaterialsMapper)
        private readonly materialsMapper: MaterialsMapper,
        @inject(TypologiesMapper)
        private readonly typologiesMapper: TypologiesMapper,
        @inject(ActionLinesMapper)
        private readonly actionLinesMapper: ActionLinesMapper,
        @inject(CrosscuttingScopesMapper)
        private readonly crosscuttingScopesMapper: CrosscuttingScopesMapper,
        @inject(AdministrativeRecordScopeMapper)
        private readonly administrativeRecordScopeMapper: AdministrativeRecordScopeMapper,
        @inject(EntityTerritorialScopeMapper)
        private readonly entityTerritorialScopeMapper: EntityTerritorialScopeMapper,
        @inject(ExportProjectDepositsMapper)
        private readonly exportDepositsMapper: ExportProjectDepositsMapper,
        @inject(ExportProjectExpensesMapper)
        private readonly exportProjectExpensesMapper: ExportProjectExpensesMapper,
    ) {}

    async create(
        newEntity: CreateEntity | CreateUserEntity,
    ): Promise<Either<ValidationError | FallbackError, Entity>> {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };
        const entityBody = this.createEntityMapper.mapToCreateDto(newEntity);

        const entityResult = await this.http.post<EntityDto, CreateEntityBody>(
            "/entities/",
            entityBody,
            { query },
        );

        return entityResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const entity = this.entityMapper.map(
                    plainToClass(EntityDto, response.data),
                );

                if (!entity) return Either.Left(new FallbackError());

                return Either.Right(entity);
            });
    }

    async update(
        editEntity: EditEntity,
    ): Promise<Either<ValidationError | FallbackError, Entity>> {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const editedEntityDto = this.editEntityMapper.mapToDto(editEntity);

        const editEntityResult = await this.http.put<EntityDto, EditEntityBody>(
            `/entities/${editEntity.id}/`,
            editedEntityDto,
            { query },
        );

        return editEntityResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const entity = this.entityMapper.map(
                    plainToClass(EntityDto, response.data),
                );

                if (!entity) return Either.Left(new FallbackError());

                return Either.Right(entity);
            });
    }

    async fetchById(
        entityId: number,
    ): Promise<Either<FallbackError, { entity: Entity }>> {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const entityResult = await this.http.get<EntityDto>(
            `/entities/${entityId}/`,
            { query },
        );

        return entityResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const entity = this.entityMapper.map(
                    plainToClass(EntityDto, response.data),
                );

                if (!entity) return Either.Left(new FallbackError());

                return Either.Right({
                    entity,
                    collectiveId: response.data.priority_collective,
                });
            });
    }

    async fetchBy(
        pagination: Pagination,
        filters?: EntitySearchFilters,
    ): Promise<Either<HttpError, EntitiesSummary>> {
        const query: EntitiesSummaryQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.name) query.search = filters.name;
        if (filters?.typologyType)
            query.legal_typology = this.typologyEnumMapper.mapToDto(
                filters.typologyType,
            );
        if (filters?.collectiveIds)
            query.collectives = filters.collectiveIds.join(",");
        if (filters?.serviceIds) query.services = filters.serviceIds.join(",");
        if (filters?.active) query.is_active = filters.active;

        const entitiesResult = await this.http.get<EntitiesSummaryDto>(
            "/entities/",
            {
                query,
            },
        );

        return entitiesResult.map((response) =>
            this.entitiesSummaryMapper.map(
                plainToClass(EntitiesSummaryDto, response.data),
            ),
        );
    }

    async fetchAllCategories(): Promise<Either<HttpError, Category[]>> {
        const categoriesResult = await this.http.get<CategoryDto[]>(
            "/entities/get_category_types/",
        );

        return categoriesResult.map((response) =>
            response.data.mapNotNull((category) =>
                this.categoryMapper.map(plainToClass(CategoryDto, category)),
            ),
        );
    }

    async fetchMaterials(
        pagination: Pagination,
    ): Promise<Either<FallbackError, Materials>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const materialsResult = await this.http.get<MaterialsDto>(
            "/materials/",
            {
                query,
            },
        );

        return materialsResult
            .map((response) =>
                this.materialsMapper.map(
                    plainToClass(MaterialsDto, response.data),
                ),
            )
            .mapLeft(() => new FallbackError());
    }

    async fetchTypologies(
        pagination: Pagination,
    ): Promise<Either<FallbackError, Typology[]>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        const responseResult = await this.http.get<TypologyDto[]>(
            "/entities/typologies/",
            {
                query,
            },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((typologyDto) =>
                    this.typologiesMapper.map(
                        plainToClass(TypologyDto, typologyDto),
                    ),
                ),
            );
    }

    async fetchActionLines(
        pagination: Pagination,
    ): Promise<Either<FallbackError, ActionLine[]>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        const responseResult = await this.http.get<ActionLineDto[]>(
            "/entities/action_lines/",
            {
                query,
            },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((actionLineDto) =>
                    this.actionLinesMapper.map(
                        plainToClass(ActionLineDto, actionLineDto),
                    ),
                ),
            );
    }

    async fetchCrosscuttingScopes(
        pagination: Pagination,
    ): Promise<Either<FallbackError, CrosscuttingScope[]>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        const responseResult = await this.http.get<CrosscuttingScopeDto[]>(
            "/entities/crosscutting_scopes/",
            {
                query,
            },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((crosscutingScopeDto) =>
                    this.crosscuttingScopesMapper.map(
                        plainToClass(CrosscuttingScopeDto, crosscutingScopeDto),
                    ),
                ),
            );
    }

    async fetchAllAdministrativeRecordScopes(): Promise<
        Either<FallbackError, AdministrativeRecordScope[]>
    > {
        const responseResult = await this.http.get<
            AdministrativeRecordScopeDto[]
        >("/entities_registered_records/administrative_record_scope/");

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((typeDto) =>
                    this.administrativeRecordScopeMapper.map(
                        plainToClass(AdministrativeRecordScopeDto, typeDto),
                    ),
                ),
            );
    }

    async fetchAllEntityTerritorialScopes(): Promise<
        Either<FallbackError, EntityTerritorialScope[]>
    > {
        const responseResult = await this.http.get<EntityTerritorialScopeDto[]>(
            "/entities_statutory_territorial_scopes/statutory_territorial_scope/",
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((typeDto) =>
                    this.entityTerritorialScopeMapper.map(
                        plainToClass(EntityTerritorialScopeDto, typeDto),
                    ),
                ),
            );
    }

    async toggleActivation(
        entityId: number,
    ): Promise<Either<FallbackError, true>> {
        const response = await this.http.patch(
            `/entities/${entityId}/toggle_activation/`,
        );

        return response
            .map(() => true as const)
            .mapLeft(() => new FallbackError());
    }

    async exportProjectDeposit(
        exportProjectDeposits: ExportProjectDeposits,
    ): Promise<Either<FallbackError, Blob>> {
        const depositDto =
            this.exportDepositsMapper.mapToExportProjectDepositDto(
                exportProjectDeposits,
            );

        const query: ExportProjectDepositsQuery = {};

        if (depositDto.project_ids.length)
            query.project_ids = depositDto.project_ids.join(",");

        if (depositDto.report_start_date)
            query.report_start_date = depositDto.report_start_date;
        if (depositDto.report_end_date)
            query.report_end_date = depositDto.report_end_date;

        const result = await this.http.get<Blob>(
            `/entities/${depositDto.id}/export_project_deposits_csv/`,
            { query, responseType: "blob" },
        );

        return result
            .mapLeft(() => new FallbackError())
            .map((response) => response.data as Blob);
    }

    async exportProjectExpenses(
        exportProjectExpenses: ExportProjectExpenses,
    ): Promise<Either<FallbackError, Blob>> {
        const expensesDto =
            this.exportProjectExpensesMapper.mapToExportProjectExpensesDto(
                exportProjectExpenses,
            );

        const query: ExportProjectExpensesQuery = {};

        if (expensesDto.project_ids.length)
            query.project_ids = expensesDto.project_ids.join(",");

        if (expensesDto.report_start_date)
            query.report_start_date = expensesDto.report_start_date;
        if (expensesDto.report_end_date)
            query.report_end_date = expensesDto.report_end_date;

        const result = await this.http.get<Blob>(
            `/entities/${expensesDto.id}/export_project_expenses_csv/`,
            { query, responseType: "blob" },
        );

        return result
            .mapLeft(() => new FallbackError())
            .map((response) => response.data as Blob);
    }
}
