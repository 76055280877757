import { ContractTermination } from "@entity/domain/models/employee/contracts/termination/contract-termination.model";
import { CreateContractTermination } from "@entity/domain/models/employee/contracts/termination/create-contract-termination.model";

export class EditContractTermination extends CreateContractTermination {
    constructor(
        public readonly id: ContractTermination["id"],
        startDate: CreateContractTermination["startDate"],
        reason: CreateContractTermination["reason"],
        type: CreateContractTermination["type"],
        contractId: CreateContractTermination["contractId"],
        cost?: CreateContractTermination["cost"],
        totalCost?: CreateContractTermination["totalCost"],
        endDate?: CreateContractTermination["endDate"],
    ) {
        super(startDate, reason, type, contractId, cost, totalCost, endDate);
    }
}
