import { Map } from "immutable";

export interface ChangedCell {
    id: number;
    value: number;
}

export class EditBudgetSpreadsheet {
    constructor(public changedCells: Map<number, ChangedCell>) {}
}
