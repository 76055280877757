export class Pagination {
    private static NO_PAGINATION_PAGE = 1;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    private static NO_PAGINATION_PAGE_SIZE = 1000;

    private static DEFAULT_PAGINATION_PAGE = 1;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    private static DEFAULT_PAGINATION_PAGE_SIZE = 10;

    static NoPagination(): Pagination {
        return new Pagination(
            Pagination.NO_PAGINATION_PAGE,
            Pagination.NO_PAGINATION_PAGE_SIZE,
        );
    }

    private readonly initialPage: number;
    private readonly initialPageSize: number;

    constructor(
        public page: number = Pagination.DEFAULT_PAGINATION_PAGE,
        public pageSize: number = Pagination.DEFAULT_PAGINATION_PAGE_SIZE,
    ) {
        this.initialPage = page;
        this.initialPageSize = pageSize;
    }

    get offset(): number {
        // Table page 1 === Index page 0
        const pageIndex = Math.max(this.page - 1, 0);

        // If page size = 10 and page = 1 --> offset = 0
        // If page size = 10 and page = 2 --> offset = 10
        return pageIndex * this.pageSize;
    }

    reset(): void {
        this.page = this.initialPage;
        this.pageSize = this.initialPageSize;
    }
}
