import { inject, injectable } from "inversify";
import { ProjectExpenseRepository } from "../../../data/repositories/project-expense.repository";
import { ContractType } from "../../models/expenses/contract-type.model";

@injectable()
export class GetAllContractTypesUseCase {
    constructor(
        @inject(ProjectExpenseRepository)
        private readonly projectExpenseRepository: ProjectExpenseRepository,
    ) {}

    async execute(): Promise<ContractType[]> {
        const contractTypesTypesResult =
            await this.projectExpenseRepository.getAllContractTypes();

        return contractTypesTypesResult.getOrElse([]);
    }
}
