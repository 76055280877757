import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectActivityRepository } from "@project/data/repositories/technical-proposal/activity.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteActivityUseCase {
    constructor(
        @inject(ProjectActivityRepository)
        private readonly projectActivityRepository: ProjectActivityRepository,
    ) {}

    async execute(taskId: number): Promise<boolean> {
        const deleteResult =
            await this.projectActivityRepository.delete(taskId);

        const deleted = deleteResult.isRight();

        if (!deleted)
            ToastManagerStore.error(deleteResult.getLeftOrThrow().message);

        return deleted;
    }
}
