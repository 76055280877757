import { CreateProjectMaterial } from "@project/domain/models/project-material/create-project-material.model";
import { ProjectMaterial } from "@project/domain/models/project-material/project-material.model";

export class EditProjectMaterial extends CreateProjectMaterial {
    constructor(
        public readonly id: ProjectMaterial["id"],
        projectId: CreateProjectMaterial["projectId"],
        materialId: CreateProjectMaterial["materialId"],
        hoursDedicated: CreateProjectMaterial["hoursDedicated"],
        documents?: CreateProjectMaterial["documents"],
        startDate?: CreateProjectMaterial["startDate"],
        endDate?: CreateProjectMaterial["endDate"],
    ) {
        super(
            projectId,
            materialId,
            hoursDedicated,
            documents,
            startDate,
            endDate,
        );
    }
}
