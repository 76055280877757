import { CCAAEnum } from "@entity/domain/models/ccaa.model";

export class PartnersNumber {
    constructor(
        public id: number,
        public numberOfMembers: number,
        public registeredCcaa: CCAAEnum,
        public entity: number,
    ) {}
}

export class PartnersNumbers {
    constructor(
        public readonly partnersNumbers: PartnersNumber[],
        public readonly count: number,
    ) {}
}
