import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { ActionLineEnum } from "@entity/domain/models/action-line.model";
import { Address } from "@entity/domain/models/address/address.model";
import { CollectiveEnum } from "@entity/domain/models/collective.model";
import { CrosscuttingScopeEnum } from "@entity/domain/models/crosscutting-scope.model";
import { TypologyEnum } from "@entity/domain/models/typology.model";
import { Contact } from "@proceeding/domain/models/contact.model";
import { DateTime } from "luxon";
import { CCAAEnum } from "./ccaa.model";

export class Entity {
    public otherCollective?: Undefinable<string>;
    public documents?: IncDocument[];
    public contacts?: Contact[];
    public address?: Address[];
    public typologyId?: Nullable<TypologyEnum>;
    public typologyLabel?: Undefinable<string>;
    public otherTypology?: Undefinable<string>;
    public collectiveId?: Nullable<CollectiveEnum>;
    public identification?: Undefinable<string>;
    public cnaeCode?: Undefinable<string>;
    public actionLines?: ActionLineEnum[];
    public otherActionLine?: Undefinable<string>;
    public secondaryCollectives?: CollectiveEnum[];
    public actionLinesDescription?: Undefinable<string>;
    public ccaaOfAction?: CCAAEnum[];
    public crosscuttingScopes?: CrosscuttingScopeEnum[];
    public otherCrosscuttingScope?: Undefinable<string>;
    public entityPurpose?: Undefinable<string>;
    public webAddress?: Undefinable<string>;
    public legalConstitutionDate?: Undefinable<DateTime>;
    public publicInterest?: Nullable<boolean>;
    public publicUtility?: Nullable<boolean>;
    public equalityPlan?: Nullable<boolean>;
    public equalityPlanExpirationDate?: Undefinable<DateTime>;

    constructor(
        public readonly id: number,
        public name: string,
        public isActive: boolean,
    ) {}
}
