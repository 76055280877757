import { ExpenseRecurrence } from "@beneficiary/domain/models/economic-data/expense/expense-recurrence.model";
import { ExpenseType } from "@beneficiary/domain/models/economic-data/expense/expense-type.model";
import {
    CreateExpense,
    EditExpense,
    Expense,
    ExpenseSearchFilters,
    Expenses,
} from "@beneficiary/domain/models/economic-data/expense/expense.model";
import { coreTypes } from "@core/core-types.di";
import { EXPAND_ALL, FieldsQuery } from "@core/data/dto/fields.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { ExpenseDto, ExpensesDto, ExpensesQuery } from "../dto/beneficiary.dto";
import { CreateExpenseBody } from "../dto/create-beneficiary.body";
import { EditExpenseBody } from "../dto/edit-beneficiary.body";
import { ExpenseRecurrenceDto } from "../dto/expense-recurrence.dto";
import { ExpenseTypeDto } from "../dto/expense-type.dto";
import { ExpenseRecurrenceMapper } from "../mappers/expense-recurrence.mapper";
import { ExpenseTypeMapper } from "../mappers/expense-type.mapper";
import { ExpenseMapper } from "../mappers/expense.mapper";
import { ExpensesMapper } from "../mappers/expenses.mapper";

const EXPENSES_PATH = "/expenses/";

@injectable()
export class ExpenseDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(ExpenseMapper)
        private readonly expenseMapper: ExpenseMapper,
        @inject(ExpensesMapper)
        private readonly expenseesMapper: ExpensesMapper,
        @inject(ExpenseTypeMapper)
        private readonly expenseTypeMapper: ExpenseTypeMapper,
        @inject(ExpenseRecurrenceMapper)
        private readonly expenseRecurrenceMapper: ExpenseRecurrenceMapper,
    ) {}

    async create(
        newExpense: CreateExpense,
    ): Promise<Either<ValidationError | FallbackError, Expense>> {
        const createdExpenseDto = this.expenseMapper.mapToCreateDto(newExpense);

        const createExpenseQuery: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const createExpenseResult = await this.http.post<CreateExpenseBody>(
            EXPENSES_PATH,
            createdExpenseDto,
            {
                query: createExpenseQuery,
            },
        );

        return createExpenseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdExpense = this.expenseMapper.map(
                    plainToClass(ExpenseDto, response.data),
                );

                if (!createdExpense) return Either.Left(new FallbackError());

                return Either.Right(createdExpense);
            });
    }

    async update(
        expenseToEdit: EditExpense,
    ): Promise<Either<ValidationError | FallbackError, Expense>> {
        const editExpenseBody = this.expenseMapper.mapToEditDto(expenseToEdit);

        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const editExpenseResult = await this.http.patch<
            ExpenseDto,
            EditExpenseBody
        >(`${EXPENSES_PATH}${expenseToEdit.id}/`, editExpenseBody, {
            query,
        });

        return editExpenseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const expense = this.expenseMapper.map(
                    plainToClass(ExpenseDto, response.data),
                );

                if (!expense) return Either.Left(new FallbackError());

                return Either.Right(expense);
            });
    }

    async delete(expenseId: number): Promise<Either<FallbackError, true>> {
        const deleteExpense = await this.http.delete(
            `${EXPENSES_PATH}${expenseId}`,
        );

        return deleteExpense.mapLeft(() => new FallbackError()).map(() => true);
    }

    async fetchBy(
        pagination: Pagination,
        filters?: ExpenseSearchFilters,
    ): Promise<Either<HttpError, Expenses>> {
        const query: ExpensesQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.beneficiaryId)
            query.beneficiary_id = filters.beneficiaryId;

        const expenseesResult = await this.http.get<ExpenseDto>(EXPENSES_PATH, {
            query,
        });

        const expenseesDto = expenseesResult.map((response) =>
            this.expenseesMapper.map(plainToClass(ExpensesDto, response.data)),
        );

        return expenseesDto;
    }

    async fetchById(
        expenseId: number,
    ): Promise<Either<FallbackError, Expense>> {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const expenseResult = await this.http.get<ExpenseDto>(
            `${EXPENSES_PATH}${expenseId}/`,
            { query },
        );

        return expenseResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const expense = this.expenseMapper.map(
                    plainToClass(ExpenseDto, response.data),
                );

                if (!expense) return Either.Left(new FallbackError());

                return Either.Right(expense);
            });
    }

    async fetchAllExpenseTypes(): Promise<
        Either<FallbackError, ExpenseType[]>
    > {
        const expenseTypesResult =
            await this.http.get<ExpenseTypeDto[]>("/expenses/types");
        return expenseTypesResult
            .mapLeft(() => new FallbackError())
            .map((expenseTypesResponse) =>
                expenseTypesResponse.data.mapNotNull((expenseTypeDto) =>
                    this.expenseTypeMapper.map(
                        plainToClass(ExpenseTypeDto, expenseTypeDto),
                    ),
                ),
            );
    }

    async fetchAllExpenseRecurrences(): Promise<
        Either<FallbackError, ExpenseRecurrence[]>
    > {
        const expenseRecurrenceResult = await this.http.get<ExpenseTypeDto[]>(
            "/expenses/recurrences",
        );
        return expenseRecurrenceResult
            .mapLeft(() => new FallbackError())
            .map((expenseRecurrenceResponse) =>
                expenseRecurrenceResponse.data.mapNotNull(
                    (expenseRecurrenceDto) =>
                        this.expenseRecurrenceMapper.map(
                            plainToClass(
                                ExpenseRecurrenceDto,
                                expenseRecurrenceDto,
                            ),
                        ),
                ),
            );
    }
}
