import { LoadingOutlined } from "@ant-design/icons";
import { LoadLayoutViewModel } from "@core/presentacion/component/feedback/load-layout/load-layout.viewmodel";
import { IncSpin } from "@core/presentacion/component/feedback/spin/inc-spin.component";
import { useViewModel } from "@core/presentacion/hook/use-view-model/use-view-model.hook";
import { container } from "@di/inversify.config";
import { observer } from "mobx-react";
import { FC, PropsWithChildren } from "react";

export type LoadLayoutProps = PropsWithChildren;

const _LoadLayout: FC<LoadLayoutProps> = ({ children }) => {
    const viewModel = useViewModel(() => container.get(LoadLayoutViewModel));

    return (
        <IncSpin
            size={"large"}
            indicator={<LoadingOutlined />}
            spinning={viewModel.loading}
        >
            <div className={"tw-h-full"}>{children}</div>
        </IncSpin>
    );
};

export const LoadLayout = observer(_LoadLayout);
