import { CreateDocument } from "@core/domain/models/create-document.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { CreateDocumentUseCase } from "@core/domain/usecases/create-document.usecase";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";

export interface DocumentTable {
    id: number;
    name: string;
}

@injectable()
export class MaterialFormViewModel extends BaseViewModel {
    constructor(
        @inject(CreateDocumentUseCase)
        private readonly createDocumentUseCase: CreateDocumentUseCase,
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
    ) {
        super();
    }

    async uploadDocument(
        createDocument: CreateDocument,
    ): Promise<Nullable<IncDocument>> {
        LoadLayoutStore.start();

        const document =
            await this.createDocumentUseCase.execute(createDocument);

        if (document) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return document;
    }

    async deleteDocument(documentId: number): Promise<boolean> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return deleted;
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(documentUrl);

        LoadLayoutStore.finish();
    }
}
