import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectEmployeeDedicationRepository } from "@project/data/repositories/project-employee-dedication.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteProjectEmployeeDedicationUseCase {
    constructor(
        @inject(ProjectEmployeeDedicationRepository)
        private readonly projectEmployeeDedicationRepository: ProjectEmployeeDedicationRepository,
    ) {}

    async execute(id: number): Promise<boolean> {
        const projectEmployeeDedicationResult =
            await this.projectEmployeeDedicationRepository.delete(id);

        const deleted = projectEmployeeDedicationResult.isRight();

        if (!deleted)
            ToastManagerStore.error(
                projectEmployeeDedicationResult.getLeftOrThrow().message,
            );

        return deleted;
    }
}
