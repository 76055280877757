export enum MaterialTypeEnum {
    Software = "software",
    HumanResources = "human_resources",
    Subcontracting = "subcontracting",
    MaterialResources = "material_resources",
    MethodologicalResources = "methodological_resources",
    TechnologicalResources = "technological_resources",
    Others = "others",
}

export class MaterialType {
    constructor(
        public readonly id: MaterialTypeEnum,
        public readonly label: string,
    ) {}
}
