import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { DepositRepository } from "../../data/repositories/deposit.repository";
import { DepositGeneralTypes } from "../models/deposit-type.model";

@injectable()
export class GetAllDepositTypesUseCase {
    constructor(
        @inject(DepositRepository)
        private readonly depositRepository: DepositRepository,
    ) {}

    async execute(): Promise<DepositGeneralTypes> {
        const depositRepositoryResult =
            await this.depositRepository.getAllDepositTypes();

        if (depositRepositoryResult.isLeft()) {
            ToastManagerStore.error(
                depositRepositoryResult.getLeftOrThrow().message,
            );
        }
        return depositRepositoryResult.getOrElse(
            new DepositGeneralTypes([], [], [], []),
        );
    }
}
