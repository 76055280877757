import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { LocalRepository } from "@entity/data/repositories/local.repository";
import { EditLocal, Local } from "@entity/domain/models/locals/local.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditLocalUseCase {
    constructor(
        @inject(LocalRepository)
        private readonly localRepository: LocalRepository,
    ) {}

    async execute(local: EditLocal): Promise<Nullable<Local>> {
        const localResult = await this.localRepository.edit(local);

        if (localResult.isLeft()) {
            ToastManagerStore.error(localResult.getLeftOrThrow().message);
        }

        return localResult.fold(
            () => null,
            (editedLocal) => editedLocal,
        );
    }
}
