import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { TechnicalProposalRepository } from "@project/data/repositories/technical-proposal/technical-proposal.repository";
import { EditTechnicalProposal } from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditTechnicalProposalUseCase {
    constructor(
        @inject(TechnicalProposalRepository)
        private readonly technicalProposalRepository: TechnicalProposalRepository,
    ) {}

    async execute(technicalProposal: EditTechnicalProposal): Promise<boolean> {
        const editResult =
            await this.technicalProposalRepository.edit(technicalProposal);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.isRight();
    }
}
