import { LogoutOutlined } from "@ant-design/icons";
import { LogoutUseCase } from "@authentication/domain/usecases/logout.usecase";
import { useUser } from "@authentication/presentation/hooks/use-user.hook";
import {
    Language,
    LanguageIsoCodeEnum,
} from "@core/domain/models/language.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { IncAvatar } from "@core/presentacion/component/data-display/avatar/inc-avatar.component";
import { IncSelect } from "@core/presentacion/component/data-entry/select/inc-select.component";
import { IncCol } from "@core/presentacion/component/layout/col/inc-col.component";
import { IncHeaderViewModel } from "@core/presentacion/component/layout/header/inc-header-viewmodel";
import { IncRow } from "@core/presentacion/component/layout/row/inc-row.component";
import { IncDropdown } from "@core/presentacion/component/navigation/dropdown/inc-dropdown.component";
import { IncMenu } from "@core/presentacion/component/navigation/menu/inc-menu.component";
import { useViewModel } from "@core/presentacion/hook/use-view-model/use-view-model.hook";
import { container } from "@di/inversify.config";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { HomeRoutes } from "@routes/private/home.routes";
import { LoginRoutes } from "@routes/public/login.routes";
import { BasicProps, Header } from "antd/es/layout/layout";
import { observer } from "mobx-react";
import { FC, ReactElement, RefAttributes, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IncSkeleton } from "../../feedback/skeleton/inc-skeleton.component";
import { IncButton } from "../../general/button/inc-button.component";

export type IncHeaderProps = BasicProps & RefAttributes<HTMLElement>;

export const _IncHeader: FC<IncHeaderProps> = (props) => {
    const user = useUser();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const viewModel = useViewModel(() => container.get(IncHeaderViewModel));

    const handleDropdownChange = (
        isOpen: boolean,
        info: { source: "trigger" | "menu" },
    ): void => {
        if (info.source === "menu") {
            return;
        }
        viewModel.setDropdownOpen(isOpen);
    };

    const handleLogout = useCallback(async () => {
        await container.get(LogoutUseCase).execute();

        navigate(LoginRoutes.LOGIN, { replace: true });
    }, [navigate]);

    const handleSelectLanguage = useCallback(
        async (language: LanguageIsoCodeEnum) => {
            viewModel.setCurrentLanguage(language);
        },
        [viewModel],
    );

    const handleSelectEntity = useCallback(
        async (entityId: Nullable<number>) => {
            viewModel.setEntity(entityId ?? undefined);
        },
        [viewModel],
    );

    const handleSave = useCallback(async () => {
        viewModel.setDropdownOpen(false);
        await viewModel.changeLanguage();
        if (viewModel.entityId) navigate(HomeRoutes.HOME);
        if (user.isSuperAdmin()) await viewModel.impersonateEntity();
    }, [navigate, viewModel]);

    const nameInitials = useMemo<string>(
        () =>
            user.email
                .split(" ")
                .map((it) => it[0])
                .join(" "),
        [user.email],
    );

    return (
        <Header {...props}>
            <IncRow
                align={"middle"}
                justify={"space-between"}
                className={"tw-h-full tw-px-6 "}
            >
                <IncCol span={12}>
                    <h2
                        className={`tw-text-base tw-mg-0 tw-p-2  tw-inline-block tw-border-solid  tw-border-2 tw-rounded-lg ${
                            viewModel.entityName
                                ? "tw-border-cyan-strong tw-text-cyan-strong"
                                : "tw-border-gray-300 tw-text-gray-300"
                        }`}
                    >
                        {viewModel.entityName ??
                            t("component:incHeader.noEntitySelected")}
                    </h2>
                </IncCol>
                <IncCol span={12}>
                    <IncRow justify={"end"}>
                        <IncSkeleton.Avatar
                            loading={viewModel.initialLoading}
                            shape={"circle"}
                            active
                            size={"large"}
                            className={
                                "tw-bg-cyan-strong tw-rounded-full !tw-inline-flex tw-align-middle "
                            }
                        >
                            <IncDropdown
                                overlayStyle={{ width: 350 }}
                                arrow
                                open={viewModel.dropdownOpen}
                                onOpenChange={handleDropdownChange}
                                trigger={["click"]}
                                className={
                                    "tw-cursor-pointer " +
                                    "tw-transition tw-ease-in-out tw-delay-150 hover:tw--translate-y-1 hover:tw-scale-110 hover:tw-ring-2 hover:tw-ring-cyan-strong hover:tw-bg-cyan-strong tw-duration-300"
                                }
                                placement={"bottomLeft"}
                                dropdownRender={(): ReactElement => (
                                    <IncMenu className={"!tw-px-6 !tw-py-4"}>
                                        <IncMenu.Item
                                            className={"!tw-pb-4"}
                                            key={"select-language"}
                                        >
                                            <IncRow
                                                className={
                                                    "tw-font-bold tw-pb-2"
                                                }
                                                gutter={["none", "small"]}
                                            >
                                                <IncCol span={24}>
                                                    <label>
                                                        {t("common:language")}
                                                    </label>
                                                </IncCol>
                                                <IncCol span={24}>
                                                    <IncSelect
                                                        className={"tw-w-full"}
                                                        allowClear={false}
                                                        value={
                                                            viewModel.currentLanguage
                                                        }
                                                        onChange={
                                                            handleSelectLanguage
                                                        }
                                                    >
                                                        {viewModel.languages.map(
                                                            (
                                                                language: Language,
                                                            ) => (
                                                                <IncSelect.Option
                                                                    key={
                                                                        language.isoCode
                                                                    }
                                                                    value={
                                                                        language.isoCode
                                                                    }
                                                                >
                                                                    {
                                                                        language.label
                                                                    }
                                                                </IncSelect.Option>
                                                            ),
                                                        )}
                                                    </IncSelect>
                                                </IncCol>
                                            </IncRow>
                                        </IncMenu.Item>
                                        {user.isSuperAdmin() && (
                                            <IncMenu.Item
                                                key={"select-entity"}
                                                className={"!tw-pb-4"}
                                            >
                                                <IncRow
                                                    className={
                                                        "tw-font-bold tw-pb-2"
                                                    }
                                                    gutter={["none", "small"]}
                                                >
                                                    <IncCol span={24}>
                                                        <label>
                                                            {t("common:entity")}
                                                        </label>
                                                    </IncCol>
                                                    <IncCol span={24}>
                                                        <IncSelect
                                                            className={
                                                                "tw-w-full"
                                                            }
                                                            value={
                                                                viewModel.entityId
                                                            }
                                                            onChange={
                                                                handleSelectEntity
                                                            }
                                                        >
                                                            {viewModel.globalEntities.map(
                                                                (
                                                                    globalEntity: GlobalEntity,
                                                                ) => (
                                                                    <IncSelect.Option
                                                                        key={
                                                                            globalEntity.id
                                                                        }
                                                                        value={
                                                                            globalEntity.id
                                                                        }
                                                                    >
                                                                        {
                                                                            globalEntity.name
                                                                        }
                                                                    </IncSelect.Option>
                                                                ),
                                                            )}
                                                        </IncSelect>
                                                    </IncCol>
                                                </IncRow>
                                            </IncMenu.Item>
                                        )}
                                        <IncMenu.Item
                                            key={"save"}
                                            className={"!tw-pb-4"}
                                        >
                                            <IncRow
                                                className={"tw-font-bold"}
                                                justify={"center"}
                                                align={"middle"}
                                            >
                                                <IncCol span={8}>
                                                    <IncButton
                                                        onClick={handleSave}
                                                    >
                                                        {t("common:save")}
                                                    </IncButton>
                                                </IncCol>
                                            </IncRow>
                                        </IncMenu.Item>
                                        <IncMenu.Divider />
                                        <IncMenu.Item
                                            key={"cerrar"}
                                            onClick={handleLogout}
                                            className={"!tw-font-bold"}
                                        >
                                            <IncRow>
                                                <IncCol span={18}>
                                                    {t("common:logout")}
                                                </IncCol>
                                                <IncCol
                                                    span={6}
                                                    className={
                                                        "tw-flex tw-justify-center"
                                                    }
                                                >
                                                    <LogoutOutlined />
                                                </IncCol>
                                            </IncRow>
                                        </IncMenu.Item>
                                    </IncMenu>
                                )}
                            >
                                <IncAvatar
                                    className={"tw-bg-cyan-strong"}
                                    size={"large"}
                                    alt={user.email}
                                >
                                    {nameInitials.toLocaleUpperCase()}
                                </IncAvatar>
                            </IncDropdown>
                        </IncSkeleton.Avatar>
                    </IncRow>
                </IncCol>
            </IncRow>
        </Header>
    );
};

export const IncHeader = observer(_IncHeader);
