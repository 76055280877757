import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { EditStatutoryTerritorialScopeBody } from "@entity/data/dto/statutory-territorial-scope/edit-statutory-territorial-scope.body";
import {
    StatutoryTerritorialScopeDto,
    StatutoryTerritorialScopesDto,
} from "@entity/data/dto/statutory-territorial-scope/statutory-territorial-scope.dto";
import { CreateStatutoryTerritorialScopeMapper } from "@entity/data/mappers/statutory-territorial-scope/create-statutory-territorial-scope.mapper";
import { EditStatutoryTerritorialScopeMapper } from "@entity/data/mappers/statutory-territorial-scope/edit-statutory-territorial-scope.mapper";
import { StatutoryTerritorialScopesMapper } from "@entity/data/mappers/statutory-territorial-scope/statutory-territorial-scopes.mapper";
import { CreateStatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/create-statutory-territorial-scope.model";
import { EditStatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/edit-statutory-territorial-scope.model";
import {
    StatutoryTerritorialScope,
    StatutoryTerritorialScopes,
} from "@entity/domain/models/statutory-territorial-scope/statutory-territorial-scope.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const STATUTORY_TERRITORIAL_SCOPES = "/entities_statutory_territorial_scopes/";

@injectable()
export class StatutoryTerritorialScopeDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(StatutoryTerritorialScopesMapper)
        private readonly statutoryTerritorialScopesMapper: StatutoryTerritorialScopesMapper,
        @inject(CreateStatutoryTerritorialScopeMapper)
        private readonly createStatutoryTerritorialScopeMapper: CreateStatutoryTerritorialScopeMapper,
        @inject(EditStatutoryTerritorialScopeMapper)
        private readonly editStatutoryTerritorialScopeMapper: EditStatutoryTerritorialScopeMapper,
    ) {}

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, StatutoryTerritorialScopes>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const statutoryTerritorialScopesResult =
            await this.http.get<StatutoryTerritorialScopeDto>(
                STATUTORY_TERRITORIAL_SCOPES,
                {
                    query,
                },
            );

        return statutoryTerritorialScopesResult.map((response) =>
            this.statutoryTerritorialScopesMapper.map(
                plainToClass(StatutoryTerritorialScopesDto, response.data),
            ),
        );
    }

    async create(
        newStatutoryTerritorialScope: CreateStatutoryTerritorialScope,
    ): Promise<
        Either<ValidationError | FallbackError, StatutoryTerritorialScope>
    > {
        const statutoryTerritorialScopeBody =
            this.createStatutoryTerritorialScopeMapper.mapToCreateDto(
                newStatutoryTerritorialScope,
            );

        const statutoryTerritorialScopeResult =
            await this.http.post<CreateStatutoryTerritorialScope>(
                STATUTORY_TERRITORIAL_SCOPES,
                statutoryTerritorialScopeBody,
            );

        return statutoryTerritorialScopeResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const statutoryTerritorialScope =
                    this.statutoryTerritorialScopesMapper.mapStatutoryTerritorialScope(
                        plainToClass(
                            StatutoryTerritorialScopeDto,
                            response.data,
                        ),
                    );

                if (!statutoryTerritorialScope)
                    return Either.Left(new FallbackError());

                return Either.Right(statutoryTerritorialScope);
            });
    }

    async edit(
        editStatutoryTerritorialScope: EditStatutoryTerritorialScope,
    ): Promise<
        Either<ValidationError | FallbackError, StatutoryTerritorialScope>
    > {
        const editedStatutoryTerritorialScope =
            this.editStatutoryTerritorialScopeMapper.mapToDto(
                editStatutoryTerritorialScope,
            );

        const editStatutoryTerritorialScopeResult = await this.http.patch<
            StatutoryTerritorialScopeDto,
            EditStatutoryTerritorialScopeBody
        >(
            `${STATUTORY_TERRITORIAL_SCOPES}${editStatutoryTerritorialScope.id}/`,
            editedStatutoryTerritorialScope,
        );

        return editStatutoryTerritorialScopeResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const statutoryTerritorialScope =
                    this.statutoryTerritorialScopesMapper.mapStatutoryTerritorialScope(
                        plainToClass(
                            StatutoryTerritorialScopeDto,
                            response.data,
                        ),
                    );

                if (!statutoryTerritorialScope)
                    return Either.Left(new FallbackError());

                return Either.Right(statutoryTerritorialScope);
            });
    }

    async delete(
        statutoryTerritorialScopeId: number,
    ): Promise<Either<FallbackError, true>> {
        const deleteStatutoryTerritorialScope = await this.http.delete(
            `${STATUTORY_TERRITORIAL_SCOPES}${statutoryTerritorialScopeId}`,
        );

        return deleteStatutoryTerritorialScope
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
