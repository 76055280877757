import { CreatePublicItineraryMapper } from "@beneficiary/data/mappers/public-itinerary/create-public-itinerary.mapper";
import { EditPublicItineraryMapper } from "@beneficiary/data/mappers/public-itinerary/edit-public-itinerary.mapper";
import { PublicItinerary } from "@beneficiary/domain/models/public-itinerary/public-itinerary.model";
import { CreatePublicItineraryUseCase } from "@beneficiary/domain/usecases/public-itinerary/create-public-itinerary.usecase";
import { DeletePublicItineraryUseCase } from "@beneficiary/domain/usecases/public-itinerary/delete-public-itinerary.usecase";
import { EditPublicItineraryUseCase } from "@beneficiary/domain/usecases/public-itinerary/edit-public-itinerary.usecase";
import { GetAllPublicItinerariesUsecase } from "@beneficiary/domain/usecases/public-itinerary/get-all-public-itineraries.usecase";
import { PublicItineraryFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/public-itinerary/form/public-itinerary-form";
import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class PublicItineraryTabViewModel extends BaseViewModel {
    beneficiaryId: Nullable<number> = null;

    @observable
    publicItineraries: PublicItinerary[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddPublicItineraryModal: boolean = false;

    @observable
    showEditPublicItineraryModal: boolean = false;

    @observable
    publicItineraryToEdit: Undefinable<PublicItinerary> = undefined;

    constructor(
        @inject(CreatePublicItineraryMapper)
        private readonly createPublicItineraryMapper: CreatePublicItineraryMapper,
        @inject(CreatePublicItineraryUseCase)
        private readonly createPublicItineraryUseCase: CreatePublicItineraryUseCase,
        @inject(DeletePublicItineraryUseCase)
        private readonly deletePublicItineraryUseCase: DeletePublicItineraryUseCase,
        @inject(EditPublicItineraryMapper)
        private readonly editPublicItineraryMapper: EditPublicItineraryMapper,
        @inject(EditPublicItineraryUseCase)
        private readonly editPublicItineraryUseCase: EditPublicItineraryUseCase,
        @inject(GetAllPublicItinerariesUsecase)
        private readonly getAllPublicItinerariesUsecase: GetAllPublicItinerariesUsecase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getPublicItineraries()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getPublicItineraries(): Promise<void> {
        if (!this.beneficiaryId) throw Error("Missing beneficiaryId");

        const publicItineraries =
            await this.getAllPublicItinerariesUsecase.execute(
                this.beneficiaryId,
            );
        runInAction(() => {
            this.publicItineraries = publicItineraries;
        });
    }

    @action
    setShowAddPublicItineraryModal(show: boolean): void {
        this.showAddPublicItineraryModal = show;
    }

    @action
    setShowEditPublicItineraryModal(show: boolean): void {
        this.showEditPublicItineraryModal = show;
    }

    @action
    openEditPublicItineraryModal(
        publicItinerary: Undefinable<PublicItinerary>,
    ): void {
        this.publicItineraryToEdit = publicItinerary;
        this.showEditPublicItineraryModal = true;
    }

    async addPublicItinerary(
        beneficiaryId: Undefinable<number>,
        values: PublicItineraryFormValuesValidated,
    ): Promise<Nullable<PublicItinerary>> {
        if (!beneficiaryId) throw Error("Missing beneficiaryId");

        LoadLayoutStore.start();

        const createPublicItineraryResult =
            await this.createPublicItineraryUseCase.execute(
                this.createPublicItineraryMapper.mapFromFormValues(
                    beneficiaryId,
                    values,
                ),
            );

        if (createPublicItineraryResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createPublicItineraryResult;
    }

    async editPublicItinerary(
        beneficiaryId: number,
        values: PublicItineraryFormValuesValidated,
    ): Promise<void> {
        if (!this.publicItineraryToEdit?.id || !beneficiaryId)
            throw Error("Missing public itinerary");

        LoadLayoutStore.start();
        const editPublicItineraryResult =
            await this.editPublicItineraryUseCase.execute(
                this.editPublicItineraryMapper.mapFromModal(
                    this.publicItineraryToEdit.id,
                    beneficiaryId,
                    values,
                ),
            );

        if (editPublicItineraryResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadPublicItineraries(): Promise<void> {
        await this.getPublicItineraries();
    }

    async deletePublicItinerary(publicItineraryId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deletePublicItineraryUseCase.execute(publicItineraryId);
        LoadLayoutStore.finish();
    }
}
