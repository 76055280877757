import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { User } from "@user/domain/models/user.model";
import { GetUserByIdUseCase } from "@user/domain/usecases/get-user-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, flow, flowResult, makeObservable, observable } from "mobx";
@injectable()
export class DetailUserPageViewModel extends BaseViewModel {
    userId: Undefinable<number> = undefined;

    @observable
    initiallyLoading = true;

    @observable
    user: Undefinable<User> = undefined;

    constructor(
        @inject(GetUserByIdUseCase)
        private readonly getUserByIdUseCase: GetUserByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(this.getUser());

        this.initiallyLoading = false;
    }

    @action
    setUser(user: User): void {
        this.user = user;
    }

    async getUser(): Promise<void> {
        if (this.userId === undefined) throw Error("Missing userId");

        const user = await this.getUserByIdUseCase.execute(this.userId);

        if (user) {
            this.setUser(user);
        }
    }
}
