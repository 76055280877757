import { ExportProjectExpensesDto } from "@entity/data/dto/export-project-expenses.dto";
import { ExportProjectExpenses } from "@entity/domain/models/export-project-expenses.model";
import { ExportProjectExpensesFormValuesValidated } from "@entity/presentation/pages/cost/list/export-project-expenses-modal/export-project-expenses-modal-form.component";
import { injectable } from "inversify";

@injectable()
export class ExportProjectExpensesMapper {
    mapToExpense(
        values: ExportProjectExpensesFormValuesValidated,
    ): ExportProjectExpenses {
        return new ExportProjectExpenses(
            values.id,
            values.projectIds,
            values.expenseDateFrom?.toString(),
            values.expenseDateTo?.toString(),
        );
    }
    mapToExportProjectExpensesDto(
        exportExpenses: ExportProjectExpenses,
    ): ExportProjectExpensesDto {
        const exportExpensesDto = new ExportProjectExpensesDto();

        exportExpensesDto.id = exportExpenses.id;
        exportExpensesDto.project_ids = exportExpenses.projectIds;
        exportExpensesDto.report_start_date =
            exportExpenses.expenseDateFrom ?? null;
        exportExpensesDto.report_end_date =
            exportExpenses.expenseDateTo ?? null;

        return exportExpensesDto;
    }
}
