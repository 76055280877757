import { IsNotEmpty, IsString } from "class-validator";

export enum CostIVATypeEnumDto {
    "Cero" = "0",
    "Five" = "5",
    "Ten" = "10",
    "TwentyOne" = "21",
    "other" = "other",
}

export class CostIVATypeDto {
    @IsNotEmpty()
    key!: CostIVATypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
