import { Nullable } from "@core/domain/types/nullable.type";
import { ColumnBudgetDto } from "@project/data/dto/budget.dto";
import { CreateBudgetColumnBody } from "@project/data/dto/budget/column/create-budget-column.body";
import { BudgetColumnTypeEnumMapper } from "@project/data/mappers/budget/columns/budget-column-type-enum.mapper";
import { CreateBudgetColumn } from "@project/domain/models/budget/budget-column/create-budget-column.model";
import { BudgetColumn } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class CreateBudgetColumnMapper {
    constructor(
        @inject(BudgetColumnTypeEnumMapper)
        private readonly budgetColumnTypeEnumMapper: BudgetColumnTypeEnumMapper,
    ) {}
    map(budgetColumn: ColumnBudgetDto): Nullable<BudgetColumn> {
        const errors = validateSync(budgetColumn);
        if (errors.length) {
            console.error(errors);
            return null;
        }
        const type = this.budgetColumnTypeEnumMapper.map(budgetColumn.type);
        if (!type) return null;
        return new BudgetColumn(
            budgetColumn.id,
            budgetColumn.name,
            budgetColumn.budget,
            type,
        );
    }
    mapToDto(createBudgetColumn: CreateBudgetColumn): CreateBudgetColumnBody {
        return {
            name: createBudgetColumn.name,
            budget: createBudgetColumn.budget,
            type: createBudgetColumn.type,
        };
    }
}
