import { coreTypes } from "@core/core-types.di";
import type { Http } from "@core/data/infrastructures/http/http";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import {
    GlobalActivity,
    GlobalActivityFilters,
} from "@project/domain/models/global-activity.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import {
    GlobalActivitiesQuery,
    GlobalActivityDto,
} from "../dto/global-activity.dto";
import { GlobalActivityMapper } from "../mappers/global-activity.mapper";

@injectable()
export class GlobalActivityDatasource {
    constructor(
        @inject(GlobalActivityMapper)
        private readonly globalActivityMapper: GlobalActivityMapper,
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
    ) {}

    async fetchAllBy(
        filters?: GlobalActivityFilters,
    ): Promise<Either<FallbackError, GlobalActivity[]>> {
        const query: GlobalActivitiesQuery = {};

        if (filters?.name) query.search = filters.name;

        if (filters?.entityId) query.name = filters.name;

        if (filters?.objetiveId) query.objetive = filters.objetiveId;

        if (filters?.projectId) query.project = filters.projectId;

        if (filters?.technical_proposalId)
            query.technical_proposal = filters.technical_proposalId;

        const activitiesResult = await this.http.get<GlobalActivityDto[]>(
            "/technical_proposals_activities/public/",
            { query },
        );

        return activitiesResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((activityDto) =>
                    this.globalActivityMapper.map(
                        plainToClass(GlobalActivityDto, activityDto),
                    ),
                ),
            );
    }
}
