import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { ContractTermination } from "@entity/domain/models/employee/contracts/termination/contract-termination.model";

export class CreateContractTermination {
    constructor(
        public startDate: ContractTermination["startDate"],
        public reason: ContractTermination["reason"],
        public type: ContractTermination["type"],
        public contractId: ContractTermination["contractId"],
        public cost?: Undefinable<ContractTermination["cost"]>,
        public totalCost?: Undefinable<ContractTermination["totalCost"]>,
        public endDate?: Nullable<ContractTermination["endDate"]>,
    ) {}
}
