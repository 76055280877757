import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum DisabilityTypeEnumDto {
    Disability1 = "disability_1",
    Disability2 = "disability_2",
    Disability3 = "disability_3",
    Disability4 = "disability_4",
    Disability5 = "disability_5",
    DisabilityNotRecognized = "disability_not_recognized",
}

export class DisabilityTypeDto {
    @IsEnum(DisabilityTypeEnumDto)
    @IsNotEmpty()
    key!: DisabilityTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
