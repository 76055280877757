import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { IncError } from "@core/domain/errors/inc.error";
import { Undefinable } from "@core/domain/types/undefinable.type";

export class HttpFailedRequestError extends IncError {
    constructor(
        public readonly httpCode: number,
        public readonly errorCode: HttpErrorCodeEnum,
        // eslint-disable-next-line id-blacklist
        public readonly data?: Undefinable<Record<string, string[]>>,
    ) {
        const message = `Http error (${httpCode})`;

        super(message);

        Object.setPrototypeOf(this, HttpFailedRequestError.prototype);
    }
}
