import { CostRepository } from "@entity/data/repositories/cost.repository";
import { CostType } from "@entity/domain/models/cost/cost-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllCostsTypesUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(): Promise<CostType[]> {
        const costsTypesResult = await this.costRepository.getAllCostsTypes();

        return costsTypesResult.fold(
            () => [],
            (costTypeResult) => costTypeResult,
        );
    }
}
