import { Undefinable } from "@core/domain/types/undefinable.type";
import { ActionLineEnum } from "@entity/domain/models/action-line.model";
import { CollectiveEnum } from "@entity/domain/models/collective.model";
import { CreateProject } from "@project/domain/models/create-project.model";
import { Project } from "@project/domain/models/project.model";
import {
    CreateProjectFinancialEntity,
    EditProjectFinancialEntity,
} from "./project-financial-entities.model";

export class EditProject extends CreateProject {
    public startDate?: Undefinable<Project["startDate"]>;
    public endDate?: Undefinable<Project["endDate"]>;
    public designPlanification?: Undefinable<Project["designPlanification"]>;
    public totalCost?: Undefinable<Project["totalCost"]>;
    public projectFinantialEntities?: Undefinable<
        (EditProjectFinancialEntity | CreateProjectFinancialEntity)[]
    >;
    public collectives?: Undefinable<CollectiveEnum[]>;
    public actionLines?: Undefinable<ActionLineEnum[]>;

    constructor(
        public readonly id: Project["id"],
        name: CreateProject["name"],
        year: CreateProject["year"],
        statusId: CreateProject["statusId"],
    ) {
        super(name, year, statusId);
    }
}
