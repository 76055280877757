import { Nullable } from "@core/domain/types/nullable.type";
import { GlobalActivity } from "@project/domain/models/global-activity.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { GlobalActivityDto } from "../dto/global-activity.dto";

@injectable()
export class GlobalActivityMapper {
    map(globalActivityDto: GlobalActivityDto): Nullable<GlobalActivity> {
        const errors = validateSync(globalActivityDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        return new GlobalActivity(globalActivityDto.id, globalActivityDto.name);
    }
}
