import { Menu, MenuProps } from "antd";
import { FC } from "react";

export type IncMenuProps = MenuProps;

export const IncMenu: FC<IncMenuProps> & {
    Item: typeof Menu.Item;
    Divider: typeof Menu.Divider;
} = (props) => (
    <Menu
        {...props}
        style={{ fontWeight: "bold" }}
    />
);

IncMenu.Item = Menu.Item;
IncMenu.Divider = Menu.Divider;
