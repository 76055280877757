import { Nullable } from "@core/domain/types/nullable.type";
import { CategoryConceptBudgetDto } from "@project/data/dto/budget.dto";
import { CreateBudgetCategoryConceptBody } from "@project/data/dto/budget/category/concepts/create-budget-category-concept.body";
import { CreateBudgetCategoryConcept } from "@project/domain/models/budget/budget-category/concepts/create-budget-category-concept.model";
import { BudgetCategoryConcept } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class CreateBudgetCategoryConceptMapper {
    map(
        budgetCategoryConceptDto: CategoryConceptBudgetDto,
    ): Nullable<BudgetCategoryConcept> {
        const errors = validateSync(budgetCategoryConceptDto);
        if (errors.length) {
            console.error(errors);
            return null;
        }
        return new BudgetCategoryConcept(
            budgetCategoryConceptDto.id,
            budgetCategoryConceptDto.name,
            budgetCategoryConceptDto.category,
            budgetCategoryConceptDto.group ?? undefined,
        );
    }
    mapToDto(
        createBudgetCategoryGroup: CreateBudgetCategoryConcept,
    ): CreateBudgetCategoryConceptBody {
        return {
            name: createBudgetCategoryGroup.name,
            category: createBudgetCategoryGroup.category,
            group: createBudgetCategoryGroup.group,
        };
    }
}
