import { Nullable } from "@core/domain/types/nullable.type";
import { RegconRegisterDto } from "@entity/data/dto/quality-framework/regcon-register/regcon-register.dto";
import { CCAAEnumMapper } from "@entity/data/mappers/ccaa-enum.mapper";
import { RegconRegister } from "@entity/domain/models/quality-framework/regcon-register/regcon-register.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class RegconRegisterMapper {
    constructor(
        @inject(CCAAEnumMapper)
        private readonly ccaaEnumMapper: CCAAEnumMapper,
    ) {}
    map(regconRegisterDto: RegconRegisterDto): Nullable<RegconRegister> {
        const errors = validateSync(regconRegisterDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const registered_ccaa = this.ccaaEnumMapper.map(
            regconRegisterDto.registered_ccaa,
        );
        if (!registered_ccaa) return null;

        return new RegconRegister(
            regconRegisterDto.id,
            regconRegisterDto.register_number,
            registered_ccaa,
            regconRegisterDto.entity,
        );
    }

    mapToDto(regconRegister: RegconRegister): RegconRegisterDto {
        const registeredCcaa = this.ccaaEnumMapper.mapToDto(
            regconRegister.registeredCcaa,
        );
        const regconRegisterDto: RegconRegisterDto = {
            id: regconRegister.id,
            register_number: regconRegister.registerNumber,
            registered_ccaa: registeredCcaa,
            entity: regconRegister.entity,
        };

        return regconRegisterDto;
    }
}
