import { DocumentDto } from "@core/data/dto/document.dto";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsInt,
    IsNotEmpty,
    IsNumber,
    IsObject,
    IsOptional,
    IsString,
} from "class-validator";

export class MaterialData {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;
}

export class ProjectMaterialDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsNumber()
    @IsNotEmpty()
    project!: number;

    @IsNumber()
    @IsNotEmpty()
    material!: number;

    @IsString()
    @IsOptional()
    hours_dedicated!: Nullable<string>;

    @IsObject()
    @IsOptional()
    @Type(() => MaterialData)
    material_data!: Nullable<MaterialData>;

    @IsString()
    @IsOptional()
    start_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    end_date!: Nullable<string>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    readonly documents_data!: Nullable<DocumentDto[]>;

    @IsArray()
    @IsInt({ each: true })
    @IsOptional()
    readonly documents!: Nullable<number[]>;
}

export class ProjectMaterialsDto extends PaginatedDto {
    @IsArray()
    @Type(() => ProjectMaterialDto)
    results!: ProjectMaterialDto[];
}

export type ProjectMaterialsQuery = PaginatedQuery & {
    project?: Undefinable<number>;
    material?: Undefinable<number>;
};
