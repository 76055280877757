import { inject, injectable } from "inversify";
import { CreateUser } from "../../domain/models/create-user.model";
import { UserFormValuesValidated } from "../../presentation/components/user-form/user-form.component";
import { CreateUserBody } from "../dto/create-user.body";
import { UserRoleEnumMapper } from "./user-role-enum.mapper";

@injectable()
export class CreateUserMapper {
    constructor(
        @inject(UserRoleEnumMapper)
        private readonly userRoleEnumMapper: UserRoleEnumMapper,
    ) {}
    mapFromUserFormValues(userForm: UserFormValuesValidated): CreateUser {
        if (!userForm.role) throw new Error("Role and entity are required");
        const createUser: CreateUser = {
            email: userForm.email,
            role: userForm.role,
            entityId: userForm.entityId,
            relatedEmployeeId: userForm.relatedEmployeeId ?? undefined,
        };
        return createUser;
    }

    mapToDto(createUser: CreateUser): CreateUserBody {
        const rol = this.userRoleEnumMapper.mapToDto(createUser.role);

        const userBody: CreateUserBody = {
            email: createUser.email,
            rol,
            entity: createUser.entityId,
            related_person: createUser.relatedEmployeeId,
            password: "Temp@123",
        };
        return userBody;
    }
}
