import { IncError } from "@core/domain/errors/inc.error";
import { Undefinable } from "@core/domain/types/undefinable.type";

export class ValidationError extends IncError {
    static readonly CODE = 400;

    constructor(
        private readonly errors: Undefinable<Record<string, string[]>>,
    ) {
        const message = Object.entries(errors ?? {})
            .map(([key, value]) => {
                const messageError = `${value.join ? value.join("; ") : value}\n`;

                return messageError;
            })
            .join("");

        super(message);

        Object.setPrototypeOf(this, ValidationError.prototype);
    }
}
