import { Nullable } from "@core/domain/types/nullable.type";
import { VolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/volunteer-contract.model";

export class CreateVolunteerContract {
    // eslint-disable-next-line max-params
    constructor(
        public insurance: VolunteerContract["insurance"],
        public activity: VolunteerContract["activity"],
        public educationLevel: VolunteerContract["educationLevel"],
        public availability: VolunteerContract["availability"],
        public preferences: VolunteerContract["preferences"],
        public weeklyWorkdayHours: VolunteerContract["weeklyWorkdayHours"],
        public startDate: VolunteerContract["startDate"],
        public employeeId: VolunteerContract["employeeId"],
        public startDateInsurance?: VolunteerContract["startDateInsurance"],
        public otherPreferences?: Nullable<
            VolunteerContract["otherPreferences"]
        >,
        public door?: Nullable<VolunteerContract["door"]>,
        public city?: Nullable<VolunteerContract["city"]>,
        public endDate?: Nullable<VolunteerContract["endDate"]>,
        public endDateInsurance?: Nullable<
            VolunteerContract["endDateInsurance"]
        >,
    ) {}
}
