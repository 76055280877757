import { EditInternalNoteBody } from "@beneficiary/data/dto/internal-notes/edit-internal-note.body";
import { EditInternalNote } from "@beneficiary/domain/models/internal-notes/edit-internal-note.model";
import { InternalNoteFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/internal-note/form/internal-note-form";
import { injectable } from "inversify";

@injectable()
export class EditInternalNoteMapper {
    mapToDto(editInternalNote: EditInternalNote): EditInternalNoteBody {
        const editInternalNoteBody: EditInternalNoteBody = {
            id: editInternalNote.id,
            note: editInternalNote.note,
            beneficiary: editInternalNote.beneficiary,
        };
        return editInternalNoteBody;
    }
    mapFromModal(
        id: number,
        beneficiaryId: number,
        internalNote: InternalNoteFormValuesValidated,
    ): EditInternalNote {
        const editInternalNote: EditInternalNote = new EditInternalNote(
            id,
            internalNote.note,
            beneficiaryId,
        );
        return editInternalNote;
    }
}
