import "./index.scss";
import "./di/inversify.config";
import "./i18n";
// Extended native types
import "./@types/index";
// Yup extended schemas
import "./modules/core/presentacion/component/form/yup/string";
// handsontable css should be the first and then handsontable/base
// organize-imports-ignore
import "handsontable/dist/handsontable.full.min.css";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Handsontable from "handsontable/base";
import { registerAllModules } from "handsontable/registry";
import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./report-web-vitals";
import { configure } from "mobx";
import { RouterProvider } from "react-router-dom";
import { router } from "@routes/router";

configure({
    computedRequiresReaction: true,
    enforceActions: "always",
    observableRequiresReaction: true,
});

registerAllModules();

const root = createRoot(document.getElementById("root") as HTMLElement);

const index = (
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

root.render(index);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
