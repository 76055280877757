import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { FinancingType } from "@project/domain/models/financial-entity/financing-type";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllFinancingTypesUsecase {
    constructor(
        @inject(FinancialEntityRepository)
        private readonly financialEntityRepository: FinancialEntityRepository,
    ) {}

    async execute(): Promise<FinancingType[]> {
        const financialEntityTypesResult =
            await this.financialEntityRepository.getAllFinancingTypes();

        return financialEntityTypesResult.getOrElse([]);
    }
}
