import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Creditor } from "@entity/domain/models/creditor/creditor.model";
import { GetCreditorByIdUseCase } from "@entity/domain/usecases/creditor/get-creditor-by-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class CreditorDetailPageViewModel extends BaseViewModel {
    creditorId: Undefinable<number> = undefined;

    @observable
    creditor: Nullable<Creditor> = null;

    @observable
    initiallyLoading: boolean = true;

    constructor(
        @inject(GetCreditorByIdUseCase)
        private readonly getCreditorByIdUseCase: GetCreditorByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getCreditorById();

        runInAction(() => {
            this.initiallyLoading = false;
        });
    }

    @action
    setCreditorId(creditorId: number): void {
        this.creditorId = creditorId;
    }

    async getCreditorById(): Promise<void> {
        if (!this.creditorId) throw Error("Missing creditorId");

        const creditor = await this.getCreditorByIdUseCase.execute(
            this.creditorId,
        );

        if (creditor) {
            this.creditor = creditor;
        }
    }
}
