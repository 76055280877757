import { CreateInternalNote } from "@beneficiary/domain/models/internal-notes/create-internal-note.model";
import { InternalNote } from "@beneficiary/domain/models/internal-notes/internal-note.model";

export class EditInternalNote extends CreateInternalNote {
    constructor(
        public readonly id: InternalNote["id"],
        note: InternalNote["note"],
        beneficiary: InternalNote["beneficiary"],
    ) {
        super(note, beneficiary);
    }
}
