import { DocumentCategoriesDto } from "@core/data/dto/document-categories.dto";
import { DocumentCategoryMapper } from "@core/data/mappers/document-category.mapper";
import { DocumentCategories } from "@core/domain/models/documents-category.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class DocumentCategoriesMapper {
    constructor(
        @inject(DocumentCategoryMapper)
        private readonly documentCategoryMapper: DocumentCategoryMapper,
    ) {}

    map(documentCategoriesDto: DocumentCategoriesDto): DocumentCategories {
        const errors = validateSync(documentCategoriesDto);

        if (errors.length) {
            console.error(errors);

            return new DocumentCategories([], 0);
        }

        const categories = documentCategoriesDto.results.mapNotNull(
            (documentCategoryDto) =>
                this.documentCategoryMapper.map(documentCategoryDto),
        );

        return new DocumentCategories(categories, documentCategoriesDto.count);
    }
}
