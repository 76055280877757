import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { QualityCertificateRepository } from "@entity/data/repositories/quality-certificate.repository";
import { CreateQualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/create-quality-certificate.model";
import { QualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/quality-certificate.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateQualityCertificateUseCase {
    constructor(
        @inject(QualityCertificateRepository)
        private readonly qualityCertificateRepository: QualityCertificateRepository,
    ) {}

    async execute(
        qualityCertificate: CreateQualityCertificate,
    ): Promise<Nullable<QualityCertificate>> {
        const creationResult =
            await this.qualityCertificateRepository.create(qualityCertificate);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdQualityCertificate) => createdQualityCertificate,
        );
    }
}
