import { AddressRepository } from "@beneficiary/data/repositories/address.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteAddressUseCase {
    constructor(
        @inject(AddressRepository)
        private readonly addressRepository: AddressRepository,
    ) {}

    async execute(addressId: number): Promise<boolean> {
        const result = await this.addressRepository.delete(addressId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
