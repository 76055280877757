import { Nullable } from "@core/domain/types/nullable.type";
import { FinancingTypeEnumDto } from "@project/data/dto/financial-entity/financing-type.dto";
import { FinancingTypeEnum } from "@project/domain/models/financial-entity/financing-type";
import { injectable } from "inversify";

@injectable()
export class FinancingTypeEnumMapper {
    map(
        financingTypeEnumDto: FinancingTypeEnumDto,
    ): Nullable<FinancingTypeEnum> {
        switch (financingTypeEnumDto) {
            case FinancingTypeEnumDto.SUBSIDY_CALL:
                return FinancingTypeEnum.SUBSIDY_CALL;
            case FinancingTypeEnumDto.NOMINATIVE_SUBSIDY:
                return FinancingTypeEnum.NOMINATIVE_SUBSIDY;
            case FinancingTypeEnumDto.PUBLIC_ADMINISTRATION_COLLABORATION_AGREEMENT:
                return FinancingTypeEnum.PUBLIC_ADMINISTRATION_COLLABORATION_AGREEMENT;
            case FinancingTypeEnumDto.PUBLIC_ADMINISTRATION_MINOR_CONTRACT:
                return FinancingTypeEnum.PUBLIC_ADMINISTRATION_MINOR_CONTRACT;
            case FinancingTypeEnumDto.PUBLIC_ADMINISTRATION_OTHER_CONTRACT:
                return FinancingTypeEnum.PUBLIC_ADMINISTRATION_OTHER_CONTRACT;
            case FinancingTypeEnumDto.CONTRACT_WITH_PRIVATE_ORGANIZATION:
                return FinancingTypeEnum.CONTRACT_WITH_PRIVATE_ORGANIZATION;
            case FinancingTypeEnumDto.PRIVATE_ORGANIZATION_COLLABORATION_AGREEMENT:
                return FinancingTypeEnum.PRIVATE_ORGANIZATION_COLLABORATION_AGREEMENT;
            case FinancingTypeEnumDto.DONATION:
                return FinancingTypeEnum.DONATION;
            case FinancingTypeEnumDto.SOCIAL_INVESTMENT_FUND:
                return FinancingTypeEnum.SOCIAL_INVESTMENT_FUND;
            case FinancingTypeEnumDto.OWN_FINANCING:
                return FinancingTypeEnum.OWN_FINANCING;
            case FinancingTypeEnumDto.OTHER:
                return FinancingTypeEnum.OTHER;
            default:
                return null;
        }
    }

    mapToDto(financingTypeEnum: FinancingTypeEnum): FinancingTypeEnumDto {
        switch (financingTypeEnum) {
            case FinancingTypeEnum.SUBSIDY_CALL:
                return FinancingTypeEnumDto.SUBSIDY_CALL;
            case FinancingTypeEnum.NOMINATIVE_SUBSIDY:
                return FinancingTypeEnumDto.NOMINATIVE_SUBSIDY;
            case FinancingTypeEnum.PUBLIC_ADMINISTRATION_COLLABORATION_AGREEMENT:
                return FinancingTypeEnumDto.PUBLIC_ADMINISTRATION_COLLABORATION_AGREEMENT;
            case FinancingTypeEnum.PUBLIC_ADMINISTRATION_MINOR_CONTRACT:
                return FinancingTypeEnumDto.PUBLIC_ADMINISTRATION_MINOR_CONTRACT;
            case FinancingTypeEnum.PUBLIC_ADMINISTRATION_OTHER_CONTRACT:
                return FinancingTypeEnumDto.PUBLIC_ADMINISTRATION_OTHER_CONTRACT;
            case FinancingTypeEnum.CONTRACT_WITH_PRIVATE_ORGANIZATION:
                return FinancingTypeEnumDto.CONTRACT_WITH_PRIVATE_ORGANIZATION;
            case FinancingTypeEnum.PRIVATE_ORGANIZATION_COLLABORATION_AGREEMENT:
                return FinancingTypeEnumDto.PRIVATE_ORGANIZATION_COLLABORATION_AGREEMENT;
            case FinancingTypeEnum.DONATION:
                return FinancingTypeEnumDto.DONATION;
            case FinancingTypeEnum.SOCIAL_INVESTMENT_FUND:
                return FinancingTypeEnumDto.SOCIAL_INVESTMENT_FUND;
            case FinancingTypeEnum.OWN_FINANCING:
                return FinancingTypeEnumDto.OWN_FINANCING;
            case FinancingTypeEnum.OTHER:
                return FinancingTypeEnumDto.OTHER;
            default:
                throw new Error(
                    `Financing type enum ${financingTypeEnum} not supported`,
                );
        }
    }
}
