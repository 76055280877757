export enum BeneficiaryIdentificationTypeEnum {
    Nie = "nie",
    PiWhite = "pi_white",
    PiRedTemp = "pi_red_temp",
    RedNoWork = "red_no_work",
    RedWork = "red_work",
    Dni = "dni",
    Passport = "passport",
    NoDocumentation = "no_documentation",
    OtherDocumentation = "other_documentation",
    SolicitanteNie = "solicitante_nie",
}

export class BeneficiaryIdentificationType {
    constructor(
        public readonly id: BeneficiaryIdentificationTypeEnum,
        public readonly label: string,
    ) {}
}
