import { Pagination } from "@core/domain/models/pagination";
import { ContractsRepository } from "@entity/data/repositories/contracts.repository";
import {
    FreelanceContract,
    FreelanceContractSearchFilters,
} from "@entity/domain/models/employee/contracts/freelance/freelance-contract.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllFreelanceContractsByUseCase {
    constructor(
        @inject(ContractsRepository)
        private readonly contractsRepository: ContractsRepository,
    ) {}

    async execute(
        filters?: FreelanceContractSearchFilters,
    ): Promise<FreelanceContract[]> {
        const contractsResult =
            await this.contractsRepository.getAllFreelanceContractsBy(
                Pagination.NoPagination(),
                filters,
            );

        return contractsResult.fold(
            () => [],
            (contracts) => contracts.freelanceContracts,
        );
    }
}
