import { EditFreelanceContractBody } from "@entity/data/dto/employee/contracts/freelance/edit-freelance-contract.body";
import { ProfessionalGroupEnumMapper } from "@entity/data/mappers/employee/professional-group-enum.mapper";
import { EditFreelanceContract } from "@entity/domain/models/employee/contracts/freelance/edit-freelance-contract.model";
import { FreelanceContract } from "@entity/domain/models/employee/contracts/freelance/freelance-contract.model";
import { FreelanceContractFormValuesValidated } from "@entity/presentation/component/employee-form/contract/freelance/form/freelance-contract-form";
import { inject, injectable } from "inversify";

@injectable()
export class EditFreelanceContractMapper {
    constructor(
        @inject(ProfessionalGroupEnumMapper)
        private readonly professionalGroupEnumMapper: ProfessionalGroupEnumMapper,
    ) {}

    mapToDto(freelanceContract: FreelanceContract): EditFreelanceContractBody {
        const professionalGroup = this.professionalGroupEnumMapper.mapToDto(
            freelanceContract.professionalGroup,
        );

        return {
            id: freelanceContract.id,
            service: freelanceContract.service,
            professional_group: professionalGroup,
            start_date: freelanceContract.startDate.toISODate() ?? "",
            total_hours: freelanceContract.totalHours.toString(),
            hours_cost: freelanceContract.hoursCost.toString(),
            total_cost: freelanceContract.totalCost.toString(),
            invoices_number: freelanceContract.invoicesNumber.toString(),
            IRPF: freelanceContract.irpf.toString(),
            employee: freelanceContract.employeeId,
            end_date: freelanceContract.endDate?.toISODate() ?? null,
        };
    }

    mapFromFreelanceContractFormValues(
        contractId: number,
        employee: number,
        freelanceContractForm: FreelanceContractFormValuesValidated,
    ): EditFreelanceContract {
        if (!contractId) throw Error("Missing income contractId");
        if (!employee) throw Error("Missing employeeId");

        const editFreelanceContract = new EditFreelanceContract(
            contractId,
            freelanceContractForm.service,
            freelanceContractForm.professionalGroup,
            freelanceContractForm.startDate,
            freelanceContractForm.totalHours,
            freelanceContractForm.hoursCost,
            freelanceContractForm.totalCost,
            freelanceContractForm.invoicesNumber,
            freelanceContractForm.irpf,
            employee,
            freelanceContractForm.endDate,
        );

        return editFreelanceContract;
    }
}
