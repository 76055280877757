import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CCAADto } from "@entity/data/dto/ccaa-enum.dto";
import { CreateRegconRegisterBody } from "@entity/data/dto/quality-framework/regcon-register/create-regcon-register.body";
import { EditRegconRegisterBody } from "@entity/data/dto/quality-framework/regcon-register/edit-regcon-register.body";
import {
    RegconRegisterDto,
    RegconRegistersDto,
} from "@entity/data/dto/quality-framework/regcon-register/regcon-register.dto";
import { CCAAMapper } from "@entity/data/mappers/ccaa.mapper";
import { CreateRegconRegisterMapper } from "@entity/data/mappers/quality-framework/regcon-register/create-regcon-register.mapper";
import { EditRegconRegisterMapper } from "@entity/data/mappers/quality-framework/regcon-register/edit-regcon-register.mapper";
import { RegconRegistersMapper } from "@entity/data/mappers/quality-framework/regcon-register/regcon-registers.mapper";
import { CCAA } from "@entity/domain/models/ccaa.model";
import { CreateRegconRegister } from "@entity/domain/models/quality-framework/regcon-register/create-regcon-register.model";
import { EditRegconRegister } from "@entity/domain/models/quality-framework/regcon-register/edit-regcon-register.model";
import {
    RegconRegister,
    RegconRegisters,
} from "@entity/domain/models/quality-framework/regcon-register/regcon-register.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const REGCON_REGISTERS_PATH = "/entities_regcon_registers/";

@injectable()
export class RegconRegisterDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(RegconRegistersMapper)
        private readonly regconRegistersMapper: RegconRegistersMapper,
        @inject(CCAAMapper)
        private readonly ccaaMapper: CCAAMapper,
        @inject(CreateRegconRegisterMapper)
        private readonly createRegconRegisterMapper: CreateRegconRegisterMapper,
        @inject(EditRegconRegisterMapper)
        private readonly editRegconRegisterMapper: EditRegconRegisterMapper,
    ) {}

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, RegconRegisters>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const regconRegisterResult = await this.http.get<RegconRegisterDto>(
            REGCON_REGISTERS_PATH,
            {
                query,
            },
        );

        return regconRegisterResult.map((response) =>
            this.regconRegistersMapper.map(
                plainToClass(RegconRegistersDto, response.data),
            ),
        );
    }

    async create(
        newRegconRegister: CreateRegconRegister,
    ): Promise<Either<ValidationError | FallbackError, RegconRegister>> {
        const regconRegisterBody =
            this.createRegconRegisterMapper.mapToCreateDto(newRegconRegister);

        const regconRegisterResult = await this.http.post<
            RegconRegisterDto,
            CreateRegconRegisterBody
        >(REGCON_REGISTERS_PATH, regconRegisterBody);

        return regconRegisterResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const regconRegister =
                    this.regconRegistersMapper.mapRegconRegister(
                        plainToClass(RegconRegisterDto, response.data),
                    );

                if (!regconRegister) return Either.Left(new FallbackError());

                return Either.Right(regconRegister);
            });
    }

    async edit(
        editRegconRegister: EditRegconRegister,
    ): Promise<Either<ValidationError | FallbackError, RegconRegister>> {
        const editedRegconRegister =
            this.editRegconRegisterMapper.mapToDto(editRegconRegister);

        const editRegconRegisterResult = await this.http.put<
            RegconRegisterDto,
            EditRegconRegisterBody
        >(
            `${REGCON_REGISTERS_PATH}${editRegconRegister.id}/`,
            editedRegconRegister,
        );

        return editRegconRegisterResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const regconRegister =
                    this.regconRegistersMapper.mapRegconRegister(
                        plainToClass(RegconRegisterDto, response.data),
                    );

                if (!regconRegister) return Either.Left(new FallbackError());

                return Either.Right(regconRegister);
            });
    }

    async delete(
        regconRegisterId: number,
    ): Promise<Either<FallbackError, true>> {
        const deleteRegconRegister = await this.http.delete(
            `${REGCON_REGISTERS_PATH}${regconRegisterId}`,
        );

        return deleteRegconRegister
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }

    async fetchAllCCAA(): Promise<Either<FallbackError, CCAA[]>> {
        const responseResult = await this.http.get<CCAADto[]>(
            `${REGCON_REGISTERS_PATH}cc_aa/`,
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((ccaaDto) =>
                    this.ccaaMapper.map(plainToClass(CCAADto, ccaaDto)),
                ),
            );
    }
}
