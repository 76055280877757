import { ContractTerminationRepository } from "@entity/data/repositories/contract-termination.repository";
import { ContractTermination } from "@entity/domain/models/employee/contracts/termination/contract-termination.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllContractTerminationsUseCase {
    constructor(
        @inject(ContractTerminationRepository)
        private readonly contractTerminationRepository: ContractTerminationRepository,
    ) {}

    async execute(): Promise<ContractTermination[]> {
        const contractTerminationsResult =
            await this.contractTerminationRepository.getAllContractTerminations();

        return contractTerminationsResult.fold(
            () => [],
            (contractTerminations) => contractTerminations.contractTerminations,
        );
    }
}
