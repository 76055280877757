import { Nullable } from "@core/domain/types/nullable.type";
import { SupplierTypeEnumDto } from "@entity/data/dto/supplier/supplier-type-enum.dto";
import { SupplierTypeEnum } from "@entity/domain/models/supplier/supplier-type.model";
import { injectable } from "inversify";
@injectable()
export class SupplierTypeEnumMapper {
    map(supplierTypeEnumDto: SupplierTypeEnumDto): Nullable<SupplierTypeEnum> {
        switch (supplierTypeEnumDto) {
            case SupplierTypeEnumDto.BeneficiariesCosts:
                return SupplierTypeEnum.BeneficiariesCosts;
            case SupplierTypeEnumDto.Subcontracting:
                return SupplierTypeEnum.Subcontracting;
            default:
                return null;
        }
    }

    mapToDto(supplierTypeEnum: SupplierTypeEnum): SupplierTypeEnumDto {
        if (supplierTypeEnum === SupplierTypeEnum.BeneficiariesCosts)
            return SupplierTypeEnumDto.BeneficiariesCosts;
        return SupplierTypeEnumDto.Subcontracting;
    }
}
