import { Nullable } from "@core/domain/types/nullable.type";
import { PositionTypeDto } from "@entity/data/dto/position-type.dto";
import { PositionTypeEnumMapper } from "@entity/data/mappers/position-type-enum.mapper";
import {
    PositionType,
    PositionTypeEnum,
} from "@entity/domain/models/position-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class PositionTypeMapper {
    constructor(
        @inject(PositionTypeEnumMapper)
        private positionTypeEnumMapper: PositionTypeEnumMapper,
    ) {}

    map(positionTypeEnumMapperDto: PositionTypeDto): Nullable<PositionType> {
        const errors = validateSync(positionTypeEnumMapperDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<PositionTypeEnum> = this.positionTypeEnumMapper.map(
            positionTypeEnumMapperDto.key,
        );

        if (!id) return null;

        return new PositionType(id, positionTypeEnumMapperDto.value);
    }
}
