import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Employee } from "@entity/domain/models/employee/employee.model";
import { GetAllEmployeesByUseCase } from "@entity/domain/usecases/employee/get-all-employees-by.usecase";
import {
    CreateProjectActivityTaskBody,
    ProjectActivityTask,
} from "@project/domain/models/project-activity-task.model";
import type { BoardSections } from "@project/domain/models/project-kanban-activity.model";
import {
    Activity,
    ActivityStatusEnum,
} from "@project/domain/models/technical-proposals.model";
import { CreateActivityTaskUseCase } from "@project/domain/usecases/project-activity/create-activity-task.usecase";
import { DeleteActivityTaskUseCase } from "@project/domain/usecases/project-activity/delete-activity-task.usecase";
import { EditKanbanActivityUseCase } from "@project/domain/usecases/project-activity/edit-kanban-activity.usecase";
import { GetAllActivitiesByUseCase } from "@project/domain/usecases/project-activity/get-all-activities-by.usecase";
import { UpdateActivityTaskUseCase } from "@project/domain/usecases/project-activity/update-activity-task.usecase";
import { KanbanActivityFormValuesValidated } from "@project/presentation/components/project-form/kanban/form/kanban-activity.form";
import { Map } from "immutable";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class KanbanTabViewModel extends BaseViewModel {
    projectId: Undefinable<number>;

    @observable
    initialLoading: boolean = true;

    @observable
    editActivityModalOpen: boolean = false;

    @observable
    activityToEdit: Undefinable<Activity> = undefined;

    @observable
    employees: Employee[] = [];

    @observable
    boardSections: BoardSections = Map([
        [ActivityStatusEnum.toDo, []],
        [ActivityStatusEnum.inProgress, []],
        [ActivityStatusEnum.inTesting, []],
        [ActivityStatusEnum.done, []],
    ]);

    constructor(
        @inject(GetAllActivitiesByUseCase)
        private readonly getAllActivitiesByUseCase: GetAllActivitiesByUseCase,
        @inject(EditKanbanActivityUseCase)
        private readonly editKanbanActivityUseCase: EditKanbanActivityUseCase,
        @inject(GetAllEmployeesByUseCase)
        private readonly getAllEmployeesByUseCase: GetAllEmployeesByUseCase,
        @inject(CreateActivityTaskUseCase)
        private readonly createActivityTaskUseCase: CreateActivityTaskUseCase,
        @inject(DeleteActivityTaskUseCase)
        private readonly deleteActivityTaskUseCase: DeleteActivityTaskUseCase,
        @inject(UpdateActivityTaskUseCase)
        private readonly updateActivityTaskUseCase: UpdateActivityTaskUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getAllActivities(), this.getAllEmployees()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    async getAllActivities(): Promise<void> {
        if (this.projectId) {
            const activities = await this.getAllActivitiesByUseCase.execute({
                project: this.projectId,
            });

            runInAction(() => {
                this.boardSections = this.boardSections.update(
                    (boardSections) => {
                        boardSections = boardSections.set(
                            ActivityStatusEnum.toDo,
                            [],
                        );
                        boardSections = boardSections.set(
                            ActivityStatusEnum.inProgress,
                            [],
                        );
                        boardSections = boardSections.set(
                            ActivityStatusEnum.inTesting,
                            [],
                        );
                        boardSections = boardSections.set(
                            ActivityStatusEnum.done,
                            [],
                        );

                        activities.forEach((activity) => {
                            const currentActivities =
                                boardSections.get(activity.status) ?? [];

                            boardSections = boardSections.set(activity.status, [
                                ...currentActivities,
                                activity,
                            ]);
                        });

                        return boardSections;
                    },
                );
            });
        }
    }

    async getAllEmployees(): Promise<void> {
        const employees = await this.getAllEmployeesByUseCase.execute();

        runInAction(() => {
            this.employees = employees;
        });
    }

    async updateActivity(
        activity: KanbanActivityFormValuesValidated | Activity,
    ): Promise<boolean> {
        LoadLayoutStore.start();

        let updatedSuccessfully: boolean = false;

        if (activity instanceof Activity) {
            updatedSuccessfully =
                await this.editKanbanActivityUseCase.edit(activity);
        } else if (this.activityToEdit) {
            const actToEdit = new Activity(
                this.activityToEdit.id,
                activity.name,
                activity.description,
                activity.needs,
                this.activityToEdit.objective,
                this.activityToEdit.estimatedExecutionStartDate,
                activity.estimatedExecutionEndDate,
                this.activityToEdit.executionStartDate,
                this.activityToEdit.executionEndDate,
                this.activityToEdit.employeeIds,
                this.activityToEdit.indicators,
                activity.status,
                activity.assignedEmployee ?? undefined,
                activity.technicalProposalId ?? undefined,
                activity.estimatedExecutionEndDate,
                activity.comment,
                activity.tasks,
            );

            updatedSuccessfully =
                await this.editKanbanActivityUseCase.edit(actToEdit);
        }

        LoadLayoutStore.finish();

        return updatedSuccessfully;
    }

    @action
    toggleEditActivityModalOpen(): void {
        this.editActivityModalOpen = !this.editActivityModalOpen;
        if (!this.editActivityModalOpen) this.activityToEdit = undefined;
    }

    @action
    reorderInColumn(
        currentStatus: ActivityStatusEnum,
        currentIndex: number,
        nextIndex: number,
    ): void {
        this.boardSections = this.boardSections.update((boardSections) => {
            const activities = boardSections.get(currentStatus);

            if (!activities) return boardSections;

            const [removed] = activities.splice(currentIndex, 1);
            activities.splice(nextIndex, 0, removed);

            return boardSections.set(currentStatus, activities);
        });
    }

    async changeStatus(
        currentStatus: ActivityStatusEnum,
        nextStatus: ActivityStatusEnum,
        currentIndex: number,
        nextIndex: number,
    ): Promise<void> {
        this.boardSections = this.boardSections.update((boardSections) => {
            const currentActivities = boardSections.get(currentStatus);
            const nextActivities = boardSections.get(nextStatus);

            if (!currentActivities || !nextActivities) return boardSections;

            const [removedActivity] = currentActivities.splice(currentIndex, 1);

            removedActivity.status = nextStatus;

            nextActivities.splice(nextIndex, 0, removedActivity);

            return boardSections
                .set(currentStatus, currentActivities)
                .set(nextStatus, nextActivities);
        });

        const actitvityToEdit = this.boardSections
            .get(nextStatus)
            ?.at(nextIndex);
        if (!actitvityToEdit) return;

        await this.updateActivity(actitvityToEdit);
        await this.getAllActivities();
    }

    @action
    setActivityToEdit(activity: Activity): void {
        this.activityToEdit = activity;
    }

    async onAddTask(
        activityTask: CreateProjectActivityTaskBody,
    ): Promise<Nullable<ProjectActivityTask>> {
        LoadLayoutStore.start();

        const createdTask =
            await this.createActivityTaskUseCase.execute(activityTask);

        if (createdTask) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
        return createdTask;
    }

    async onDeleteTask(taskId: number): Promise<boolean> {
        LoadLayoutStore.start();

        const isSuccessfullyDeleted =
            await this.deleteActivityTaskUseCase.delete(taskId);

        if (isSuccessfullyDeleted) {
            ToastManagerStore.success();
            await this.getAllActivities();
        }

        LoadLayoutStore.finish();
        return isSuccessfullyDeleted;
    }

    async onUpdateTask(
        activityTask: ProjectActivityTask,
    ): Promise<Nullable<ProjectActivityTask>> {
        LoadLayoutStore.start();

        const updatedTask =
            await this.updateActivityTaskUseCase.update(activityTask);

        if (updatedTask) {
            ToastManagerStore.success();
            await this.getAllActivities();
        }

        LoadLayoutStore.finish();
        return updatedTask;
    }
}
