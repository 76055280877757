import { IncomeRepository } from "@beneficiary/data/repositories/income.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteIncomeUseCase {
    constructor(
        @inject(IncomeRepository)
        private readonly incomeRepository: IncomeRepository,
    ) {}

    async execute(expenseId: number): Promise<boolean> {
        const result = await this.incomeRepository.delete(expenseId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
