import { CreateRegconRegister } from "@entity/domain/models/quality-framework/regcon-register/create-regcon-register.model";
import { RegconRegister } from "@entity/domain/models/quality-framework/regcon-register/regcon-register.model";

export class EditRegconRegister extends CreateRegconRegister {
    constructor(
        public readonly id: RegconRegister["id"],
        public override registerNumber: CreateRegconRegister["registerNumber"],
        public override registeredCcaa: CreateRegconRegister["registeredCcaa"],
        public override entity: CreateRegconRegister["entity"],
    ) {
        super(registerNumber, registeredCcaa, entity);
    }
}
