import { Nullable } from "@core/domain/types/nullable.type";
import {
    MetricUnit,
    MetricUnitEnum,
} from "@entity/domain/models/material/metric-unit.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { MetricUnitDto } from "../../dto/material/metric-unit-enum.dto";
import { MetricUnitEnumMapper } from "./metric-unit-enum.mapper";

@injectable()
export class MetricUnitMapper {
    constructor(
        @inject(MetricUnitEnumMapper)
        private readonly metricUnitEnumMapper: MetricUnitEnumMapper,
    ) {}

    map(metricUnitDto: MetricUnitDto): Nullable<MetricUnit> {
        const errors = validateSync(metricUnitDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<MetricUnitEnum> = this.metricUnitEnumMapper.map(
            metricUnitDto.key,
        );

        if (!id) return null;

        return new MetricUnit(id, metricUnitDto.value);
    }
}
