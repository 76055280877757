import { LocalsDto } from "@entity/data/dto/locals/local.dto";
import { Locals } from "@entity/domain/models/locals/local.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { LocalMapper } from "./local.mapper";

@injectable()
export class LocalsMapper {
    constructor(
        @inject(LocalMapper) private readonly localMapper: LocalMapper,
    ) {}
    map(localsDto: LocalsDto): Locals {
        const errors = validateSync(localsDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Locals([], 0);
        }

        const locals = localsDto.results.mapNotNull((localDto) =>
            this.localMapper.map(localDto),
        );

        return new Locals(locals, localsDto.count);
    }
}
