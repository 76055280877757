import { Nullable } from "@core/domain/types/nullable.type";
import { SocialNetwork } from "@entity/domain/models/social-network.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { SocialNetworkDto } from "../../dto/social-network/social-network.dto";

@injectable()
export class SocialNetworkMapper {
    map(socialNetwork: SocialNetworkDto): Nullable<SocialNetwork> {
        const errors = validateSync(socialNetwork);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        return new SocialNetwork(
            socialNetwork.id,
            socialNetwork.entity,
            socialNetwork.name,
            socialNetwork.link,
            socialNetwork.followers,
            socialNetwork.num_posts,
        );
    }
}
