import { QualityCertificateRepository } from "@entity/data/repositories/quality-certificate.repository";
import { QualityCertificateType } from "@entity/domain/models/quality-certificate-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetQualityCertificateTypesUseCase {
    constructor(
        @inject(QualityCertificateRepository)
        private readonly qualityCertificateRepository: QualityCertificateRepository,
    ) {}

    async execute(): Promise<QualityCertificateType[]> {
        const qualityCertificateTypeResults =
            await this.qualityCertificateRepository.getQualityCertificateTypes();

        return qualityCertificateTypeResults.fold(
            () => [],
            (qualityCertificateType) => qualityCertificateType,
        );
    }
}
