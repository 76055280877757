import { EditPartnersNumberBody } from "@entity/data/dto/partners-number/edit-partners-number.body";
import { CCAAEnumMapper } from "@entity/data/mappers/ccaa-enum.mapper";
import { EditPartnersNumber } from "@entity/domain/models/partners-number/edit-partners-number.model";
import { PartnersNumberFormValuesValidated } from "@entity/presentation/component/entity-form/authority-manager/partners-number-form/partners-number-form";
import { inject, injectable } from "inversify";

@injectable()
export class EditPartnersNumberMapper {
    constructor(
        @inject(CCAAEnumMapper)
        private readonly ccaaEnumMapper: CCAAEnumMapper,
    ) {}
    mapToDto(editPartnersNumber: EditPartnersNumber): EditPartnersNumberBody {
        const registered_ccaa = this.ccaaEnumMapper.mapToDto(
            editPartnersNumber.registeredCcaa,
        );

        const editPartnersNumberBody: EditPartnersNumberBody = {
            id: editPartnersNumber.id,
            number_of_members: editPartnersNumber.numberOfMembers,
            registered_ccaa,
            entity: editPartnersNumber.entity,
        };
        return editPartnersNumberBody;
    }
    mapFromModal(
        id: number,
        entityId: number,
        partnersNumber: PartnersNumberFormValuesValidated,
    ): EditPartnersNumber {
        const editPartnersNumber: EditPartnersNumber = new EditPartnersNumber(
            id,
            partnersNumber.numberOfMembers,
            partnersNumber.registeredCcaa,
            entityId,
        );
        return editPartnersNumber;
    }
}
