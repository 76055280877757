import { BeneficiariesByProjectExportQuery } from "@beneficiary/data/dto/beneficiaries-summary.dto";
import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { CreateTechnicalProposalBody } from "@project/data/dto/technical-proposal/create-technical-proposal.body";
import { EditTechnicalProposalBody } from "@project/data/dto/technical-proposal/edit-technical-proposal.body";
import {
    TechnicalProposalDto,
    TechnicalProposalsDto,
    TechnicalProposalsQuery,
} from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { TechnicalProposalMapper } from "@project/data/mappers/technical-proposal/technical-proposal.mapper";
import { TechnicalProposalsMapper } from "@project/data/mappers/technical-proposal/technical-proposals.mapper";
import { ExportBeneficiaryTechnicalProposalFilters } from "@project/domain/models/export-beneficiary-technical-proposal-filters.model";
import {
    CreateTechnicalProposal,
    EditTechnicalProposal,
    TechnicalProposal,
    TechnicalProposals,
    TechnicalProposalsFilters,
} from "@project/domain/models/technical-proposals.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class TechnicalProposalDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(TechnicalProposalMapper)
        private readonly technicalProposalMapper: TechnicalProposalMapper,
        @inject(TechnicalProposalsMapper)
        private readonly technicalProposalsMapper: TechnicalProposalsMapper,
    ) {}

    async fetchAllBy(
        pagination: Pagination,
        filters?: Undefinable<TechnicalProposalsFilters>,
    ): Promise<Either<FallbackError, TechnicalProposals>> {
        const query: TechnicalProposalsQuery = {
            offset: pagination.offset,
            limit: pagination.pageSize,
        };
        if (filters?.projectId) query.project = filters.projectId;

        const technicalProposalsResult = await this.http.get(
            "/technical_proposals/",
            { query },
        );

        return technicalProposalsResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.technicalProposalsMapper.map(
                    plainToClass(TechnicalProposalsDto, response.data),
                ),
            );
    }

    async fetchById(
        technicalProposalId: number,
    ): Promise<Either<FallbackError, Nullable<TechnicalProposal>>> {
        const technicalProposalResult = await this.http.get(
            `/technical_proposals/${technicalProposalId}/`,
        );

        return technicalProposalResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.technicalProposalMapper.map(
                    plainToClass(TechnicalProposalDto, response.data),
                ),
            );
    }

    async create(
        createTechnicalProposal: CreateTechnicalProposal,
    ): Promise<Either<ValidationError | FallbackError, TechnicalProposal>> {
        const technicalProposalBody =
            this.technicalProposalMapper.mapToCreateDto(
                createTechnicalProposal,
            );

        const technicalProposalResult = await this.http.post<
            TechnicalProposalDto,
            CreateTechnicalProposalBody
        >("/technical_proposals/", technicalProposalBody);

        return technicalProposalResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const technicalProposal = this.technicalProposalMapper.map(
                    plainToClass(TechnicalProposalDto, response.data),
                );

                if (!technicalProposal) return Either.Left(new FallbackError());

                return Either.Right(technicalProposal);
            });
    }

    async edit(
        editTechnicalProposal: EditTechnicalProposal,
    ): Promise<Either<ValidationError | FallbackError, TechnicalProposal>> {
        const technicalProposalBody = this.technicalProposalMapper.mapToEditDto(
            editTechnicalProposal,
        );

        const technicalProposalResult = await this.http.patch<
            TechnicalProposalDto,
            EditTechnicalProposalBody
        >(
            `/technical_proposals/${editTechnicalProposal.id}/`,
            technicalProposalBody,
        );

        return technicalProposalResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const technicalProposal = this.technicalProposalMapper.map(
                    plainToClass(TechnicalProposalDto, response.data),
                );

                if (!technicalProposal) return Either.Left(new FallbackError());

                return Either.Right(technicalProposal);
            });
    }

    async export(
        technicalProposalId: number,
    ): Promise<Either<FallbackError, Blob>> {
        const technicalProposalResult = await this.http.get(
            `/technical_proposals/${technicalProposalId}/export_csv`,
            { responseType: "blob" },
        );

        return technicalProposalResult
            .mapLeft(() => new FallbackError())
            .map((response) => response.data as Blob);
    }

    async exportBeneficiaryTechProposalBy(
        filters?: ExportBeneficiaryTechnicalProposalFilters,
    ): Promise<Either<FallbackError, Blob>> {
        const query: BeneficiariesByProjectExportQuery = {};

        if (filters?.projectIds) query.projects = filters.projectIds.join(",");

        const beneficiariesResult = await this.http.get<Blob>(
            `/beneficiaries/export_csv/`,
            { query, responseType: "blob" },
        );

        return beneficiariesResult
            .mapLeft(() => new FallbackError())
            .map((response) => response.data as Blob);
    }
}
