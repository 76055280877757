import { coreTypes } from "@core/core-types.di";
import type { Http } from "@core/data/infrastructures/http/http";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import {
    GlobalProjectDto,
    GlobalProjectsQuery,
} from "@project/data/dto/global-project.dto";
import { CatalogueEnumMapper } from "@project/data/mappers/catalogue-enum.mapper";
import { FinancialEntityTypeEnumMapper } from "@project/data/mappers/financial-entity/financial-entity-type-enum.mapper";
import { GlobalProjectMapper } from "@project/data/mappers/global-project.mapper";
import { StatusEnumMapper } from "@project/data/mappers/status-enum.mapper";
import {
    GlobalProject,
    GlobalProjectFilters,
} from "@project/domain/models/global-project.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class GlobalProjectDatasource {
    constructor(
        @inject(FinancialEntityTypeEnumMapper)
        private readonly financialEntityTypeEnumMapper: FinancialEntityTypeEnumMapper,
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(CatalogueEnumMapper)
        private readonly catalogueEnumMapper: CatalogueEnumMapper,
        @inject(StatusEnumMapper)
        private readonly statusEnumMapper: StatusEnumMapper,
        @inject(GlobalProjectMapper)
        private readonly globalProjectMapper: GlobalProjectMapper,
    ) {}

    async fetchAllBy(
        filters?: GlobalProjectFilters,
    ): Promise<Either<FallbackError, GlobalProject[]>> {
        const query: GlobalProjectsQuery = {};

        if (filters?.name) query.search = filters.name;

        if (filters?.statusProject)
            query.status = this.statusEnumMapper.mapToDto(
                filters.statusProject,
            );

        if (filters?.financialEntityType)
            query.finantial_entity_type =
                this.financialEntityTypeEnumMapper.mapToDto(
                    filters.financialEntityType,
                );

        if (filters?.entityId) query.entity = filters.entityId;

        if (filters?.catalogueId)
            query.catalogue = this.catalogueEnumMapper.mapToDto(
                filters.catalogueId,
            );

        if (filters?.activeFromDate)
            query.active_from_date = filters.activeFromDate.toISODate();

        if (filters?.activeToDate)
            query.active_to_date = filters.activeToDate.toISODate();

        if (filters?.isActive) query.is_active = filters.isActive;

        const projectsResult = await this.http.get<GlobalProjectDto[]>(
            "/projects/public_projects/",
            { query },
        );

        return projectsResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((projectDto) =>
                    this.globalProjectMapper.map(
                        plainToClass(GlobalProjectDto, projectDto),
                    ),
                ),
            );
    }
}
