import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { MaterialSubtype } from "@entity/domain/models/material/material-subtype.model";
import {
    MaterialType,
    MaterialTypeEnum,
} from "@entity/domain/models/material/material-type.model";
import { MetricUnit } from "@entity/domain/models/material/metric-unit.model";
import { ProjectSummary } from "@entity/domain/models/project-summary.model";
import { GetAllMaterialSubtypesByTypeIdUseCase } from "@entity/domain/usecases/material/get-all-material-subtypes-by-id.usecase";
import { GetAllMaterialTypesUseCase } from "@entity/domain/usecases/material/get-all-material-types.usecase";
import { GetAllMetricUnitUseCase } from "@entity/domain/usecases/material/get-all-metric-unit.usecase";
import { CreateProjectMaterialMapper } from "@project/data/mappers/materials/create-project-material.mapper";
import { EditProjectMaterialMapper } from "@project/data/mappers/materials/edit-project-material.mapper";
import { ProjectMaterial } from "@project/domain/models/project-material/project-material.model";
import { GetAllProjectsUseCase } from "@project/domain/usecases/get-all-projects.usecase";
import { CreateProjectMaterialUseCase } from "@project/domain/usecases/project-material/create-project-material.usecase";
import { DeleteProjectMaterialUseCase } from "@project/domain/usecases/project-material/delete-project-material.usecase";
import { EditProjectMaterialUseCase } from "@project/domain/usecases/project-material/edit-project-material.usecase";
import { GetAllProjectMaterialsUseCase } from "@project/domain/usecases/project-material/get-all-project-materials.usecase";
import { inject, injectable } from "inversify";
import {
    IReactionDisposer,
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { MaterialProjectFormValuesValidated } from "./add-resource/material-project-form";

export interface ProjectListTable {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
    hoursDedicated: number;
    projectMaterial: ProjectMaterial;
}

export interface ProjectsListTable {
    projects: ProjectListTable[];
    count: number;
}
@injectable()
export class MaterialFormViewModel extends BaseViewModel {
    @observable
    materialId: Nullable<number> = null;

    @observable
    initialLoading: boolean = true;

    @observable
    typeId: Nullable<MaterialTypeEnum> = null;

    @observable
    materialTypes: MaterialType[] = [];

    @observable
    materialSubtypes: MaterialSubtype[] = [];

    @observable
    metricUnit: MetricUnit[] = [];

    @observable
    showAddMaterialModal: boolean = false;

    @observable
    projects: ProjectSummary[] = [];

    @observable
    _projectMaterials: ProjectMaterial[] = [];

    @observable
    projectListTable: Undefinable<ProjectListTable> = undefined;

    @computed
    get projectsTable(): ProjectsListTable {
        return {
            count: this.projects.length,
            projects: this._projectMaterials.map((projectMaterial) => {
                const project = this.projects.find(
                    (proj) => proj.id === projectMaterial.projectId,
                );

                const projectListTable: ProjectListTable = {
                    id: projectMaterial.projectId,
                    name: project?.name ?? "",
                    startDate:
                        projectMaterial.startDate?.toLocaleString() ?? "",
                    endDate: projectMaterial.endDate?.toLocaleString() ?? "",
                    hoursDedicated: projectMaterial.hoursDedicated ?? 0,
                    projectMaterial: projectMaterial,
                };

                return projectListTable;
            }),
        };
    }

    @computed
    get projectsSelect(): ProjectSummary[] {
        return this.projects.filter((project) =>
            this._projectMaterials.every(
                (projectMaterial) => projectMaterial.projectId !== project.id,
            ),
        );
    }

    private reactionDisposer?: IReactionDisposer;

    constructor(
        @inject(GetAllMaterialTypesUseCase)
        private readonly getAllMaterialTypesUseCase: GetAllMaterialTypesUseCase,
        @inject(GetAllMaterialSubtypesByTypeIdUseCase)
        private readonly getAllMaterialSubtypesByTypeIdUseCase: GetAllMaterialSubtypesByTypeIdUseCase,
        @inject(GetAllMetricUnitUseCase)
        private readonly getAllMetricUnitUseCase: GetAllMetricUnitUseCase,
        @inject(GetAllProjectsUseCase)
        private readonly getAllProjectsUseCase: GetAllProjectsUseCase,
        @inject(CreateProjectMaterialMapper)
        private readonly createProjectMaterialMapper: CreateProjectMaterialMapper,
        @inject(EditProjectMaterialMapper)
        private readonly editProjectMaterialMapper: EditProjectMaterialMapper,
        @inject(CreateProjectMaterialUseCase)
        private readonly createProjectMaterialUseCase: CreateProjectMaterialUseCase,
        @inject(EditProjectMaterialUseCase)
        private readonly editProjectMaterialUseCase: EditProjectMaterialUseCase,
        @inject(GetAllProjectMaterialsUseCase)
        private readonly getAllProjectMaterialsUseCase: GetAllProjectMaterialsUseCase,
        @inject(DeleteProjectMaterialUseCase)
        private readonly deleteProjectMaterialUseCase: DeleteProjectMaterialUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();
        await this.initViewData();
        this.reactionDisposer = reaction(
            () => this.typeId,
            async () => this.getAllMaterialSubtypes(),
        );
    }

    override async willUnmount(): Promise<void> {
        if (this.reactionDisposer) {
            this.reactionDisposer();
        }
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllMaterialTypes(),
            this.getAllMaterialSubtypes(),
            this.getAllMetricUnits(),
            this.getAllProjects(),
            this.getAllMaterialProjects(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setProjectMaterial(projectListTable?: Undefinable<ProjectListTable>): void {
        this.projectListTable = projectListTable;
    }

    @action
    setMaterialId(materialId: Nullable<number>): void {
        this.materialId = materialId;
    }

    async reloadMaterialProjects(): Promise<void> {
        await this.getAllMaterialProjects();
    }

    async getAllMaterialProjects(): Promise<void> {
        if (!this.materialId) return;
        const projectMaterials =
            await this.getAllProjectMaterialsUseCase.execute({
                materialId: this.materialId,
            });

        runInAction(() => {
            this._projectMaterials = projectMaterials;
        });
    }

    @action
    setTypeId(typeId: Nullable<MaterialTypeEnum>): void {
        this.typeId = typeId;
    }

    async getAllMaterialTypes(): Promise<void> {
        const materialTypes = await this.getAllMaterialTypesUseCase.execute();

        runInAction(() => {
            this.materialTypes = materialTypes;
        });
    }

    async getAllMaterialSubtypes(): Promise<void> {
        if (!this.typeId) return;
        const materialSubtypes =
            await this.getAllMaterialSubtypesByTypeIdUseCase.execute(
                this.typeId,
            );

        runInAction(() => {
            this.materialSubtypes = materialSubtypes;
        });
    }

    async getAllMetricUnits(): Promise<void> {
        const metricUnit = await this.getAllMetricUnitUseCase.execute();

        runInAction(() => {
            this.metricUnit = metricUnit;
        });
    }

    async getAllProjects(): Promise<void> {
        const projects = await this.getAllProjectsUseCase.execute();

        runInAction(() => {
            this.projects = projects;
        });
    }

    @action
    setShowAddMaterialModal(show: boolean): void {
        this.showAddMaterialModal = show;
    }

    @action
    closeAddMaterialModal(): void {
        this.showAddMaterialModal = false;
    }

    async addMaterial(
        values: MaterialProjectFormValuesValidated,
    ): Promise<Nullable<ProjectMaterial>> {
        if (!values.projectId) throw Error("Missing projectId");

        LoadLayoutStore.start();

        const createMaterialResult =
            await this.createProjectMaterialUseCase.execute(
                this.createProjectMaterialMapper.mapFromProjectFormValues(
                    values.projectId,
                    values,
                ),
            );

        if (createMaterialResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createMaterialResult;
    }

    async editMaterial(
        values: MaterialProjectFormValuesValidated,
    ): Promise<Nullable<ProjectMaterial>> {
        if (!values.projectId) throw Error("Missing projectId");

        LoadLayoutStore.start();

        const createMaterialResult =
            await this.editProjectMaterialUseCase.execute(
                this.editProjectMaterialMapper.mapFromEntityFormValues(values),
            );

        if (createMaterialResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createMaterialResult;
    }

    async deleteProject(projectId: number): Promise<void> {
        const materialProjectId = this._projectMaterials.find(
            (projectMaterial) => projectMaterial.projectId === projectId,
        )?.id;

        if (!materialProjectId) return;

        LoadLayoutStore.start();

        await this.deleteProjectMaterialUseCase.execute(materialProjectId);

        LoadLayoutStore.finish();
    }
}
