export enum ProfessionalGroupEnum {
    ProfessionalGroup0 = "professional_group_0",
    ProfessionalGroup1 = "professional_group_1",
    ProfessionalGroup2 = "professional_group_2",
    ProfessionalGroup3 = "professional_group_3",
    ProfessionalGroup4 = "professional_group_4",
    Freelance = "Autónomo",
}

export class ProfessionalGroup {
    constructor(
        public readonly id: ProfessionalGroupEnum,
        public readonly label: string,
    ) {}
}
