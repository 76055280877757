import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContractTerminationRepository } from "@entity/data/repositories/contract-termination.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteContractTerminationUseCase {
    constructor(
        @inject(ContractTerminationRepository)
        private readonly contractTerminationRepository: ContractTerminationRepository,
    ) {}

    async execute(contractId: number): Promise<boolean> {
        const result =
            await this.contractTerminationRepository.deleteContractTermination(
                contractId,
            );

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
