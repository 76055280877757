import { Nullable } from "@core/domain/types/nullable.type";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import {
    PolicyProtocolDto,
    PolicyProtocolsDto,
} from "@entity/data/dto/quality-framework/policy-protocol/policy-protocol.dto";
import { PolicyProtocolTypeEnumMapper } from "@entity/data/mappers/policy-protocol-type-enum.mapper";
import {
    PolicyProtocol,
    PolicyProtocols,
} from "@entity/domain/models/quality-framework/policy-protocol/policy-protocol.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class PolicyProtocolsMapper {
    constructor(
        @inject(PolicyProtocolTypeEnumMapper)
        private readonly policyProtocolTypeEnumMapper: PolicyProtocolTypeEnumMapper,
    ) {}

    map(policyProtocolsDto: PolicyProtocolsDto): PolicyProtocols {
        const errors = validateSync(policyProtocolsDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new PolicyProtocols([], 0);
        }

        const policyProtocols = policyProtocolsDto.results.mapNotNull(
            (policyProtocolDto) => this.mapPolicyProtocol(policyProtocolDto),
        );

        return new PolicyProtocols(policyProtocols, policyProtocolsDto.count);
    }

    mapPolicyProtocol(
        policyProtocolDto: PolicyProtocolDto,
    ): Nullable<PolicyProtocol> {
        if (!policyProtocolDto.id) {
            return null;
        }

        const policiesProtocols =
            policyProtocolDto.policies_protocols.mapNotNull(
                (policiesProtocolEnum) =>
                    this.policyProtocolTypeEnumMapper.map(policiesProtocolEnum),
            );

        const policyProtocol: PolicyProtocol = {
            id: policyProtocolDto.id,
            name: policyProtocolDto.name,
            policiesProtocols,
            realizationYear: policyProtocolDto.realization_year,
            entity: policyProtocolDto.entity,
            otherPoliciesProtocols: convertEmptyToUndefined(
                policyProtocolDto.other_policies_protocols,
            ),
            expirationDate: policyProtocolDto.expiration_date
                ? DateTime.fromISO(policyProtocolDto.expiration_date)
                : undefined,
        };
        return policyProtocol;
    }
}
