import { Nullable } from "@core/domain/types/nullable.type";
import { PaymentMethodTypeEnumDto } from "@entity/data/dto/cost/payment-method-type.dto";
import { PaymentMethodTypeEnum } from "@entity/domain/models/cost/payment-method-type.model";
import { injectable } from "inversify";

@injectable()
export class PaymentMethodTypeEnumMapper {
    map(
        paymentMethodTypeEnumDto: PaymentMethodTypeEnumDto,
    ): Nullable<PaymentMethodTypeEnum> {
        switch (paymentMethodTypeEnumDto) {
            case PaymentMethodTypeEnumDto.bank:
                return PaymentMethodTypeEnum.bank;
            case PaymentMethodTypeEnumDto.cash:
                return PaymentMethodTypeEnum.cash;
            case PaymentMethodTypeEnumDto.credit_card:
                return PaymentMethodTypeEnum.credit_card;
            case PaymentMethodTypeEnumDto.domiciliation:
                return PaymentMethodTypeEnum.domiciliation;
            case PaymentMethodTypeEnumDto.other:
                return PaymentMethodTypeEnum.other;
            default:
                return null;
        }
    }

    mapToDto(
        paymentMethodTypeEnum: PaymentMethodTypeEnum,
    ): PaymentMethodTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (paymentMethodTypeEnum) {
            case PaymentMethodTypeEnum.bank:
                return PaymentMethodTypeEnumDto.bank;
            case PaymentMethodTypeEnum.cash:
                return PaymentMethodTypeEnumDto.cash;
            case PaymentMethodTypeEnum.credit_card:
                return PaymentMethodTypeEnumDto.credit_card;
            case PaymentMethodTypeEnum.domiciliation:
                return PaymentMethodTypeEnumDto.domiciliation;
            case PaymentMethodTypeEnum.other:
                return PaymentMethodTypeEnumDto.other;
        }
    }
}
