import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { ProjectFinancialEntityDataSource } from "@project/data/datasource/project-financial-entity.datasource";
import { BudgetSpreadsheet } from "@project/domain/models/budget-spreadsheet.model";
import { EditBudgetSpreadsheet } from "@project/domain/models/edit-budget-spreadsheet.model";
import {
    ProjectFinancialEntities,
    ProjectFinancialEntitiesSearchFilters,
    ProjectFinancialEntity,
} from "@project/domain/models/project-financial-entities.model";
import { inject, injectable } from "inversify";

@injectable()
export class ProjectFinancialEntityRepository {
    constructor(
        @inject(ProjectFinancialEntityDataSource)
        private readonly projectFinancialEntityDataSource: ProjectFinancialEntityDataSource,
    ) {}

    async getAllBy(
        filters?: ProjectFinancialEntitiesSearchFilters,
    ): Promise<Either<FallbackError, ProjectFinancialEntities>> {
        return this.projectFinancialEntityDataSource.fetchAllBy(filters);
    }

    async getProjectFinancialEntityById(
        projectFinancialEntityId: number,
    ): Promise<Either<FallbackError, ProjectFinancialEntity>> {
        return this.projectFinancialEntityDataSource.fetchById(
            projectFinancialEntityId,
        );
    }

    async getBudgetSpreadsheetById(
        projectFinancialEntityId: number,
    ): Promise<Either<FallbackError, BudgetSpreadsheet>> {
        return this.projectFinancialEntityDataSource.fetchBudgetSpreadsheetById(
            projectFinancialEntityId,
        );
    }

    async updateBudgetSpreadsheet(
        editedBudgetSpreadsheet: EditBudgetSpreadsheet,
    ): Promise<Either<ValidationError | FallbackError, boolean>> {
        return this.projectFinancialEntityDataSource.updateBudgetSpreadsheet(
            editedBudgetSpreadsheet,
        );
    }
    async exportEntity(entityId: number): Promise<Either<FallbackError, Blob>> {
        return this.projectFinancialEntityDataSource.exportEntity(entityId);
    }

    async exportJustification(
        entityId: number,
    ): Promise<Either<FallbackError, Blob>> {
        return this.projectFinancialEntityDataSource.exportJustification(
            entityId,
        );
    }
}
