import { ExpenseRepository } from "@beneficiary/data/repositories/expense.repository";
import {
    CreateExpense,
    Expense,
} from "@beneficiary/domain/models/economic-data/expense/expense.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class CreateExpenseUseCase {
    constructor(
        @inject(ExpenseRepository)
        private readonly expensesRepository: ExpenseRepository,
    ) {}

    async execute(expense: CreateExpense): Promise<Nullable<Expense>> {
        const creationResult = await this.expensesRepository.create(expense);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdExpense) => createdExpense,
        );
    }
}
