import { ExpenseRepository } from "@beneficiary/data/repositories/expense.repository";
import { Expense } from "@beneficiary/domain/models/economic-data/expense/expense.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllExpensesByUseCase {
    constructor(
        @inject(ExpenseRepository)
        private readonly expensesRepository: ExpenseRepository,
    ) {}

    async execute(beneficiaryId: number): Promise<Expense[]> {
        const expensesResult = await this.expensesRepository.getAllBy({
            beneficiaryId,
        });

        return expensesResult.fold(
            () => [],
            (expenses) => expenses.expenses,
        );
    }
}
