import { DashboardLayout } from "@core/presentacion/component/layout/dashboard-layout/dashboard.layout";
import { ErrorInRoute } from "@core/presentacion/component/routing/error-in-route/error-in-route.component";
import { LoginFromTokenGuard } from "@core/presentacion/component/routing/login-from-token/login-from-token.guard";
import { MissingRoute } from "@core/presentacion/component/routing/missing-route/missing-route.component";
import { RequireAuthGuard } from "@core/presentacion/component/routing/require-auth/require-auth.guard";
import { beneficiaryRoutes } from "@routes/private/beneficiary.routes";
import { entityRoutes } from "@routes/private/entity.routes";
import { homeRoutes } from "@routes/private/home.routes";
import { proceedingRoutes } from "@routes/private/proceeding.routes";
import { projectRoutes } from "@routes/private/project.routes";
import { userRoutes } from "@routes/private/user.routes";
import { loginRoutes } from "@routes/public/login.routes";
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
} from "react-router-dom";
import { App } from "../app";

const routes = (
    <Route
        element={<App />}
        errorElement={<ErrorInRoute />}
    >
        {/* PUBLIC ROUTES */}
        <Route element={<LoginFromTokenGuard />}>{loginRoutes}</Route>

        {/* PRIVATE ROUTES */}
        <Route element={<RequireAuthGuard />}>
            <Route element={<DashboardLayout />}>
                {homeRoutes}
                {userRoutes}
                {beneficiaryRoutes}
                {entityRoutes}
                {projectRoutes}
                {proceedingRoutes}
                {userRoutes}
            </Route>
        </Route>

        {/* MISSING ROUTE */}
        <Route
            path={"*"}
            element={<MissingRoute />}
        />
    </Route>
);

export const router = createBrowserRouter(createRoutesFromElements(routes));
