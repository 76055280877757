import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum AssociateServiceToDependenceEnumDto {
    Rehab = "rehab",
    HomeHelp = "home_help",
    ServiceCheck = "service_check",
    Residence = "residence",
    DayCenter = "day_center",
    Telecare = "telecare",
    Empty = "",
}

export class AssociateServiceToDependenceDto {
    @IsEnum(AssociateServiceToDependenceEnumDto)
    @IsNotEmpty()
    key!: AssociateServiceToDependenceEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
