import { DistrictDto } from "@beneficiary/data/dto/district.dto";
import { District } from "@beneficiary/domain/models/district.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class DistrictMapper {
    map(districtDto: DistrictDto): Nullable<District> {
        const errors = validateSync(districtDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        return new District(districtDto.id, districtDto.name, districtDto.city);
    }

    mapToDistrictDto(district: District): Nullable<DistrictDto> {
        const errors = validateSync(district);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        return {
            id: district.id,
            name: district.name,
            city: district.city,
        };
    }
}
