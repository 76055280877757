import { Nullable } from "@core/domain/types/nullable.type";
import { DateTime } from "luxon";

export class BudgetSummary {
    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly createdAt: DateTime,
    ) {}
}

export class BudgetsSummary {
    constructor(
        public readonly budgetSummary: BudgetSummary[],
        public count: number,
    ) {}
}

export interface BudgetsSearchFilters {
    financialEntityId?: Nullable<number>;
}
