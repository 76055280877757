import { CreateFinancialEntity } from "@project/domain/models/financial-entity/create-financial-entity.model";
import { FinancialEntityFormValuesValidated } from "@project/presentation/components/financial-entity-form/financial-entity-form.component";
import { injectable } from "inversify";
import { CreateFinancialEntityBody } from "../../dto/financial-entity/create-financial-entity.body";

@injectable()
export class CreateFinancialEntityMapper {
    mapFromFinancialEntityFormValues(
        financialEntityForm: FinancialEntityFormValuesValidated,
    ): CreateFinancialEntity {
        return new CreateFinancialEntity(
            financialEntityForm.name,
            financialEntityForm.type,
            financialEntityForm.description,
            financialEntityForm.isActive,
        );
    }

    mapToDto(
        createFinancialEntity: CreateFinancialEntity,
    ): CreateFinancialEntityBody {
        return {
            name: createFinancialEntity.name,
            type: createFinancialEntity.type,
            description: createFinancialEntity.description,
            is_active: createFinancialEntity.isActive,
        };
    }
}
