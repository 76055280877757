import { CreateStatutoryTerritorialScopeBody } from "@entity/data/dto/statutory-territorial-scope/create-statutory-territorial-scope.body";
import { EntityTerritorialScopeEnumMapper } from "@entity/data/mappers/entity-territorial-scope-enum.mapper";
import { CreateStatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/create-statutory-territorial-scope.model";
import { StatutoryTerritorialScopeFormValuesValidated } from "@entity/presentation/component/entity-form/general/statutory-territorial-scope/form/statutory-territorial-scope-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreateStatutoryTerritorialScopeMapper {
    constructor(
        @inject(EntityTerritorialScopeEnumMapper)
        private readonly entityTerritorialScopeEnumMapper: EntityTerritorialScopeEnumMapper,
    ) {}
    mapToCreateDto(
        createStatutoryTerritorialScope: CreateStatutoryTerritorialScope,
    ): CreateStatutoryTerritorialScopeBody {
        const scope = this.entityTerritorialScopeEnumMapper.mapToDto(
            createStatutoryTerritorialScope.scope,
        );

        const statutoryTerritorialScopeDto: CreateStatutoryTerritorialScopeBody =
            {
                name: createStatutoryTerritorialScope.name,
                scope,
                entity: createStatutoryTerritorialScope.entity,
            };

        return statutoryTerritorialScopeDto;
    }

    mapFromFormValues(
        entityId: number,
        statutoryTerritorialScope: StatutoryTerritorialScopeFormValuesValidated,
    ): CreateStatutoryTerritorialScope {
        const createStatutoryTerritorialScope: CreateStatutoryTerritorialScope =
            new CreateStatutoryTerritorialScope(
                statutoryTerritorialScope.name,
                statutoryTerritorialScope.scope,
                entityId,
            );

        return createStatutoryTerritorialScope;
    }
}
