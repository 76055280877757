import { Nullable } from "@core/domain/types/nullable.type";
import { BudgetTypeDto } from "@project/data/dto/financial-entity/budget-type.dto";
import { BudgetType } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { BudgetTypeEnumMapper } from "./budget-type-enum.mapper";

@injectable()
export class BudgetTypeMapper {
    constructor(
        @inject(BudgetTypeEnumMapper)
        private readonly budgetTypeEnumMapper: BudgetTypeEnumMapper,
    ) {}

    map(financingDto: BudgetTypeDto): Nullable<BudgetType> {
        const errors = validateSync(financingDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }
        const type = this.budgetTypeEnumMapper.map(financingDto.key);
        if (!type) return null;

        return new BudgetType(type, financingDto.value);
    }
}
