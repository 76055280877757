import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { ContributionGroupTypeEnum } from "../../../domain/models/expenses/contribution-group-type.model";
import { ContributionGroupTypeEnumDto } from "../../dto/expenses/contribution-group-type.dto";

@injectable()
export class ContributionGroupTypeEnumMapper {
    map(
        centerTypeEnumDto: ContributionGroupTypeEnumDto,
    ): Nullable<ContributionGroupTypeEnum> {
        switch (centerTypeEnumDto) {
            case ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_1:
                return ContributionGroupTypeEnum.CONTRIBUTION_GROUP_1;
            case ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_2:
                return ContributionGroupTypeEnum.CONTRIBUTION_GROUP_2;
            case ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_3:
                return ContributionGroupTypeEnum.CONTRIBUTION_GROUP_3;
            case ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_4:
                return ContributionGroupTypeEnum.CONTRIBUTION_GROUP_4;
            case ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_5:
                return ContributionGroupTypeEnum.CONTRIBUTION_GROUP_5;
            case ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_6:
                return ContributionGroupTypeEnum.CONTRIBUTION_GROUP_6;
            case ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_7:
                return ContributionGroupTypeEnum.CONTRIBUTION_GROUP_7;
            case ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_8:
                return ContributionGroupTypeEnum.CONTRIBUTION_GROUP_8;
            case ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_9:
                return ContributionGroupTypeEnum.CONTRIBUTION_GROUP_9;
            case ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_10:
                return ContributionGroupTypeEnum.CONTRIBUTION_GROUP_10;
            case ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_11:
                return ContributionGroupTypeEnum.CONTRIBUTION_GROUP_11;
            default:
                return null;
        }
    }

    mapToDto(
        centerTypeEnum: ContributionGroupTypeEnum,
    ): ContributionGroupTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (centerTypeEnum) {
            case ContributionGroupTypeEnum.CONTRIBUTION_GROUP_1:
                return ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_1;
            case ContributionGroupTypeEnum.CONTRIBUTION_GROUP_2:
                return ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_2;
            case ContributionGroupTypeEnum.CONTRIBUTION_GROUP_3:
                return ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_3;
            case ContributionGroupTypeEnum.CONTRIBUTION_GROUP_4:
                return ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_4;
            case ContributionGroupTypeEnum.CONTRIBUTION_GROUP_5:
                return ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_5;
            case ContributionGroupTypeEnum.CONTRIBUTION_GROUP_6:
                return ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_6;
            case ContributionGroupTypeEnum.CONTRIBUTION_GROUP_7:
                return ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_7;
            case ContributionGroupTypeEnum.CONTRIBUTION_GROUP_8:
                return ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_8;
            case ContributionGroupTypeEnum.CONTRIBUTION_GROUP_9:
                return ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_9;
            case ContributionGroupTypeEnum.CONTRIBUTION_GROUP_10:
                return ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_10;
            case ContributionGroupTypeEnum.CONTRIBUTION_GROUP_11:
                return ContributionGroupTypeEnumDto.CONTRIBUTION_GROUP_11;
        }
    }
}
