import { Pagination } from "@core/domain/models/pagination";
import { ProjectAuditoryRepository } from "@entity/data/repositories/project-auditory.repository";
import { ProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/project-auditory.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllProjectAuditoriesUsecase {
    constructor(
        @inject(ProjectAuditoryRepository)
        private readonly projectAuditoryRepository: ProjectAuditoryRepository,
    ) {}

    async execute(): Promise<ProjectAuditory[]> {
        const projectAuditoryResults =
            await this.projectAuditoryRepository.getAll(
                Pagination.NoPagination(),
            );

        return projectAuditoryResults.fold(
            () => [],
            (projectAuditories) => projectAuditories.projectAuditories,
        );
    }
}
