import { EditExternalDerivationBody } from "@beneficiary/data/dto/external-derivation/edit-external-derivation.body";
import { CenterTypeEnumMapper } from "@beneficiary/data/mappers/center-type-enum.mapper";
import { EditExternalDerivation } from "@beneficiary/domain/models/external-derivation/edit-external-derivation.model";
import { ExternalDerivationFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/external-derivation/form/external-derivation-form";
import { inject, injectable } from "inversify";

@injectable()
export class EditExternalDerivationMapper {
    constructor(
        @inject(CenterTypeEnumMapper)
        private readonly centerTypeEnumMapper: CenterTypeEnumMapper,
    ) {}

    mapToEditDto(
        externalDerivation: EditExternalDerivation,
    ): EditExternalDerivationBody {
        const centerType = this.centerTypeEnumMapper.mapToDto(
            externalDerivation.centerType,
        );

        const externalDerivationDto: EditExternalDerivationBody = {
            id: externalDerivation.id,
            reason: externalDerivation.reason,
            center_type: centerType,
            center_name: externalDerivation.centerName,
            beneficiary: externalDerivation.beneficiary,
            is_derivate_to_external: externalDerivation.isDerivateToExternal,
            contact_name: externalDerivation.contactName,
            contact_phone: externalDerivation.contactPhone,
            contact_email: externalDerivation.contactEmail,
        };

        const documents =
            externalDerivation.documents?.map((document) => document.id) ??
            undefined;
        externalDerivationDto.documents = documents;
        return externalDerivationDto;
    }

    mapFromModal(
        externalDerivationId: number,
        beneficiaryId: number,
        externalDerivation: ExternalDerivationFormValuesValidated,
    ): EditExternalDerivation {
        if (!externalDerivation.centerType) throw Error("centerType is empty");

        const editExEditExternalDerivation: EditExternalDerivation =
            new EditExternalDerivation(
                externalDerivationId,
                externalDerivation.reason,
                externalDerivation.centerType,
                externalDerivation.centerName,
                beneficiaryId,
                externalDerivation.isDerivateToExternal,
                externalDerivation.contactName
                    ? externalDerivation.contactName
                    : undefined,
                externalDerivation.contactPhone
                    ? externalDerivation.contactPhone
                    : undefined,
                externalDerivation.contactEmail
                    ? externalDerivation.contactEmail
                    : undefined,
                externalDerivation.documents ?? undefined,
            );
        return editExEditExternalDerivation;
    }
}
