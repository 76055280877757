import { FallbackError } from "@core/domain/errors/fallback.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { ProjectEmployeeDedicationDatasource } from "@project/data/datasource/project-employee-dedication.datasource";
import { CreateProjectEmployeeDedicationError } from "@project/domain/errors/create-project-employee-dedication.error";
import { EditProjectEmployeeDedicationError } from "@project/domain/errors/edit-project-employee-dedication.error";
import {
    CreateProjectEmployeeDedication,
    ProjectEmployeeDedication,
    ProjectEmployeeDedications,
    ProjectEmployeeDedicationsSearchFilters,
    UpdateProjectEmployeeDedication,
} from "@project/domain/models/project-employee-dedications.model";
import { inject, injectable } from "inversify";

@injectable()
export class ProjectEmployeeDedicationRepository {
    constructor(
        @inject(ProjectEmployeeDedicationDatasource)
        private readonly projectEmployeeDedicationDatasource: ProjectEmployeeDedicationDatasource,
    ) {}

    async getById(
        id: number,
    ): Promise<Either<FallbackError, ProjectEmployeeDedication>> {
        return this.projectEmployeeDedicationDatasource.fetchById(id);
    }

    async getBy(
        pagination?: Pagination,
        filters?: ProjectEmployeeDedicationsSearchFilters,
    ): Promise<Either<FallbackError, ProjectEmployeeDedications>> {
        return this.projectEmployeeDedicationDatasource.fetchBy(
            pagination,
            filters,
        );
    }

    async getAllBy(
        filters?: ProjectEmployeeDedicationsSearchFilters,
    ): Promise<Either<FallbackError, ProjectEmployeeDedication[]>> {
        const projectEmployeesResult = await this.getBy(
            Pagination.NoPagination(),
            filters,
        );

        return projectEmployeesResult.map(
            (projectEmployeeDedications) =>
                projectEmployeeDedications.projectEmployeeDedications,
        );
    }

    async create(
        projectEmployeeDedication: CreateProjectEmployeeDedication,
    ): Promise<
        Either<CreateProjectEmployeeDedicationError, ProjectEmployeeDedication>
    > {
        return this.projectEmployeeDedicationDatasource.create(
            projectEmployeeDedication,
        );
    }

    async update(
        projectEmployeeDedication: UpdateProjectEmployeeDedication,
    ): Promise<
        Either<EditProjectEmployeeDedicationError, ProjectEmployeeDedication>
    > {
        return this.projectEmployeeDedicationDatasource.update(
            projectEmployeeDedication,
        );
    }

    async delete(id: number): Promise<Either<FallbackError, true>> {
        return this.projectEmployeeDedicationDatasource.delete(id);
    }
}
