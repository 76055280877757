import { Nullable } from "@core/domain/types/nullable.type";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { CreateTechnicalProposalActivityBody } from "@project/data/dto/technical-proposal/create-technical-proposal.body";
import { EditTechnicalProposalActivityBody } from "@project/data/dto/technical-proposal/edit-technical-proposal.body";
import {
    EditProjectActivityBody,
    TechnicalProposalActivityDto,
} from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { ActivityStatusEnumMapper } from "@project/data/mappers/activity-status-enum.mapper";
import { ActivityTaskMapper } from "@project/data/mappers/activity-task.mapper";
import { IndicatorMapper } from "@project/data/mappers/technical-proposal/indicator.mapper";
import {
    Activity,
    CreateActivity,
    EditActivity,
} from "@project/domain/models/technical-proposals.model";
import { ActivityFormValuesValidated } from "@project/presentation/components/project-form/technical-proposal/activity/activity-form.view";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class ActivityMapper {
    constructor(
        @inject(IndicatorMapper)
        private readonly indicatorMapper: IndicatorMapper,
        @inject(ActivityStatusEnumMapper)
        private readonly activityStatusEnumEnumMapper: ActivityStatusEnumMapper,
        @inject(ActivityTaskMapper)
        private readonly activityTaskMapper: ActivityTaskMapper,
    ) {}

    map(activityDto: TechnicalProposalActivityDto): Nullable<Activity> {
        const errors = validateSync(activityDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const indicators = activityDto.indicators_data.mapNotNull(
            (indicatorDto) => this.indicatorMapper.map(indicatorDto),
        );

        const activity = new Activity(
            activityDto.id,
            activityDto.name,
            activityDto.description,
            activityDto.needs,
            activityDto.objetive,
            activityDto.start_date_estimated
                ? DateTime.fromISO(activityDto.start_date_estimated)
                : undefined,
            activityDto.end_date_estimated
                ? DateTime.fromISO(activityDto.end_date_estimated)
                : undefined,
            activityDto.start_date_real
                ? DateTime.fromISO(activityDto.start_date_real)
                : undefined,
            activityDto.end_date_real
                ? DateTime.fromISO(activityDto.end_date_real)
                : undefined,
            activityDto.employee ?? [],
            indicators,
        );

        if (activityDto.status) {
            const status = this.activityStatusEnumEnumMapper.map(
                activityDto.status,
            );

            if (status) {
                activity.status = status;
            }
        }

        if (activityDto.assigned_employee) {
            activity.assignedEmployee = activityDto.assigned_employee;
        }

        if (activityDto.deadline) {
            activity.deadline = DateTime.fromISO(activityDto.deadline);
        }

        if (activityDto.comments) {
            activity.comment = activityDto.comments;
        }

        if (activityDto.tasks_data) {
            activity.tasks = activityDto.tasks_data.mapNotNull((task) =>
                this.activityTaskMapper.map(task),
            );
        }

        if (activityDto.resources) {
            activity.materialIds = activityDto.resources;
        }

        if (activityDto.technical_proposal_id) {
            activity.technicalProposalId = activityDto.technical_proposal_id;
        }

        return activity;
    }

    mapFromFormToCreate(
        activityForm: ActivityFormValuesValidated,
    ): CreateActivity {
        if (!activityForm.objective)
            throw new Error("Objective id is required");

        return new CreateActivity(
            activityForm.objective,
            activityForm.name,
            activityForm.description,
            activityForm.needs,
            activityForm.estimatedStartDate ?? undefined,
            activityForm.estimatedEndDate ?? undefined,
            activityForm.executionStartDate ?? undefined,
            activityForm.executionEndDate ?? undefined,
            activityForm.employees,
        );
    }

    mapFromFormToEdit(activityForm: ActivityFormValuesValidated): EditActivity {
        if (!activityForm.id) throw new Error("Activity id is required");
        if (!activityForm.objective)
            throw new Error("Objective id is required");

        return new EditActivity(
            activityForm.id,
            activityForm.objective,
            activityForm.name,
            activityForm.description,
            activityForm.needs,
            activityForm.estimatedStartDate ?? undefined,
            activityForm.estimatedEndDate ?? undefined,
            activityForm.executionStartDate ?? undefined,
            activityForm.executionEndDate ?? undefined,
            activityForm.employees,
            activityForm.materials,
        );
    }

    mapToCreateDto(
        activity: CreateActivity,
    ): CreateTechnicalProposalActivityBody {
        return {
            objetive: activity.objectiveId,
            name: activity.name,
            description: activity.description,
            needs: activity.needs,
            start_date_estimated: convertEmptyToUndefined(
                activity.estimatedExecutionStartDate?.toISODate(),
            ),
            end_date_estimated: convertEmptyToUndefined(
                activity.estimatedExecutionEndDate?.toISODate(),
            ),
            start_date_real: convertEmptyToUndefined(
                activity.executionStartDate?.toISODate(),
            ),
            end_date_real: convertEmptyToUndefined(
                activity.executionEndDate?.toISODate(),
            ),
            employee: convertEmptyToUndefined(activity.employeeIds),
            resources: convertEmptyToUndefined(activity.materialIds),
            indicators: convertEmptyToUndefined(
                activity.indicators.map((indicator) =>
                    this.indicatorMapper.mapToCreateDto(indicator),
                ),
            ),
        };
    }

    mapToEditDto(activity: EditActivity): EditTechnicalProposalActivityBody {
        return {
            id: activity.id,
            objetive: activity.objectiveId,
            name: activity.name,
            description: activity.description,
            needs: activity.needs,
            start_date_estimated: convertEmptyToUndefined(
                activity.estimatedExecutionStartDate?.toISODate(),
            ),
            end_date_estimated: convertEmptyToUndefined(
                activity.estimatedExecutionEndDate?.toISODate(),
            ),
            start_date_real: convertEmptyToUndefined(
                activity.executionStartDate?.toISODate(),
            ),
            end_date_real: convertEmptyToUndefined(
                activity.executionEndDate?.toISODate(),
            ),
            employee: convertEmptyToUndefined(activity.employeeIds),
            resources: activity.materialIds,
        };
    }

    mapToEditProjectActivityDto(activity: Activity): EditProjectActivityBody {
        const status = this.activityStatusEnumEnumMapper.mapToDto(
            activity.status,
        );

        return {
            id: activity.id,
            name: activity.name,
            end_date_estimated: convertEmptyToUndefined(
                activity.estimatedExecutionEndDate?.toISODate(),
            ),
            status: status,
            assigned_employee: activity.assignedEmployee,
            comments: activity.comment ?? "",
        };
    }
}
