export enum FinancialEntityTypeEnum {
    ADMIN_INTERNATIONAL = "admin_international",
    ADMIN_LOCAL = "admin_local",
    ADMIN_NATIONAL = "admin_national",
    ADMIN_REGIONAL = "admin_regional",
    ASSOCIATION = "association",
    BANK_ENTITY = "bank_entity",
    CCAA = "ccaa",
    IRPF = "irpf",
    OWN = "own",
    PRIVATE_ENTITY = "private_entity",
    GENERIC_ENTITY = "generic_entity",
}

export class FinancialEntityType {
    constructor(
        public readonly id: FinancialEntityTypeEnum,
        public readonly label: string,
    ) {}
}
