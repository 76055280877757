import { Nullish } from "@core/domain/types/nullish.type";
import { IsNotEmpty, IsNumber, IsString } from "class-validator";

export class GlobalActivityDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;
}

export type GlobalActivitiesQuery = {
    search?: Nullish<string>;
    name?: Nullish<string>;
    objetive?: Nullish<number>;
    technical_proposal?: Nullish<number>;
    project?: Nullish<number>;
};
