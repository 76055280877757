import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { CostRepository } from "@entity/data/repositories/cost.repository";
import { Cost } from "@entity/domain/models/cost/cost.model";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteCostUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(cost: Cost): Promise<void> {
        const costResult = await this.costRepository.getById(cost.id);

        if (costResult.isLeft()) {
            ToastManagerStore.error(costResult.getLeftOrThrow().message);
            return;
        }

        const costFull = costResult.fold(
            () => null,
            (costInstance) => costInstance,
        );

        if (!costFull) {
            ToastManagerStore.error("Cost not found");
            return;
        }

        const additionalExpensesPromises = costFull.additionalExpenses?.map(
            async (additionalExpense) =>
                this.costRepository.deleteAdditionalExpense(
                    additionalExpense.id,
                ),
        );

        if (additionalExpensesPromises) {
            await Promise.all(additionalExpensesPromises);
        }

        const result = await this.costRepository.delete(cost.id);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        if (result.isRight()) {
            ToastManagerStore.success("Cost deleted successfully");
        }
    }
}
