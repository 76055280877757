import { IncError } from "@core/domain/errors/inc.error";
import i18n from "@i18n";

export class InvalidDedicationDatesError extends IncError {
    constructor() {
        super(
            i18n.t("error:project.employee.invalidDedicationDatesErrorMessage"),
        );

        Object.setPrototypeOf(this, InvalidDedicationDatesError.prototype);
    }
}
