import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    TerritorialScope,
    TerritorialScopeEnum,
} from "../../domain/models/territorial-scope.model";
import { TerritorialScopeDto } from "../dto/territorial-scope.dto";
import { TerritorialScopeEnumMapper } from "./territorial-scope-enum.mapper";

@injectable()
export class TerritorialScopeMapper {
    constructor(
        @inject(TerritorialScopeEnumMapper)
        private readonly transportTypeEnumMapper: TerritorialScopeEnumMapper,
    ) {}

    map(transportTypeDto: TerritorialScopeDto): Nullable<TerritorialScope> {
        const errors = validateSync(TerritorialScopeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<TerritorialScopeEnum> =
            this.transportTypeEnumMapper.map(transportTypeDto.key);

        if (!id) return null;

        return new TerritorialScope(id, transportTypeDto.value);
    }
}
