import { DiModuleBuilder } from "@di/builder/di-module-builder";
import { EditProceedingMapper } from "@proceeding/data/mappers/edit-proceeding.mapper";
import { EditProceedingUseCase } from "@proceeding/domain/usecases/edit-proceeding.usecase";
import { GetRecentModifiedProceedingsUseCase } from "@proceeding/domain/usecases/get-recent-modified-proceedings.usecase";
import { DocumentsTabViewModel } from "@proceeding/presentation/components/documents/document-tab.viewmodel";
import { CreateProceedingPageViewModel } from "@proceeding/presentation/pages/create/create-proceeding-page.viewmodel";
import { ProceedingListPageViewModel } from "@proceeding/presentation/pages/list/proceeding-list-page-viewmodel";
import { GlobalActivityDatasource } from "@project/data/datasource/global-activity.datasource";
import { GlobalActivityMapper } from "@project/data/mappers/global-activity.mapper";
import { GlobalActivityRepository } from "@project/data/repositories/global-activity.repository";
import { GetAllGlobalActivitiesByUseCase } from "@project/domain/usecases/get-all-global-activities-by.usecase";
import { ContainerModule } from "inversify";
import { ProceedingDatasource } from "./data/datasource/proceeding.datasource";
import { CreateProceedingMapper } from "./data/mappers/create-proceeding.mapper";
import { DeriveProceedingMapper } from "./data/mappers/derive-proceeding.mapper";
import { ProceedingSummaryMapper } from "./data/mappers/proceeding-summary.mapper";
import { ProceedingMapper } from "./data/mappers/proceeding.mapper";
import { ProceedingsSummaryMapper } from "./data/mappers/proceedings-summary.mapper";
import { StatusEnumMapper } from "./data/mappers/status-enum.mapper";
import { StatusMapper } from "./data/mappers/status.mapper";
import { ProceedingRepository } from "./data/repositories/proceeding.repository";
import { CreateProceedingUseCase } from "./domain/usecases/create-proceeding.usecase";
import { DeriveProceedingUseCase } from "./domain/usecases/derive-proceeding.usecase";
import { GetAllStatusUseCase } from "./domain/usecases/get-all-status.usecase";
import { GetProceedingByIdUseCase } from "./domain/usecases/get-proceeding-by-id.usecase";
import { GetProceedingsByUseCase } from "./domain/usecases/get-proceedings-by.usecase";
import { GetRecentModifiedDerivationsUseCase } from "./domain/usecases/get-recent-modified-derivations.usecase";
import { GeneralTabViewModel } from "./presentation/components/general/general-tab.viewmodel";
import { DerivationModalFormViewModel } from "./presentation/components/modal/derivation-modal-form.viewmodel";
import { ProceedingDetailPageViewModel } from "./presentation/pages/detail/detail-proceeding-page.viewmodel";
import { EditProceedingPageViewModel } from "./presentation/pages/edit/edit-proceeding-page.viewmodel";

const proceedingModule = new DiModuleBuilder();

proceedingModule.registerSubModules(() => ({
    datasources: new ContainerModule((bind) => {
        bind(GlobalActivityDatasource).toSelf();
        bind(ProceedingDatasource).toSelf();
    }),
    mappers: new ContainerModule((bind) => {
        bind(CreateProceedingMapper).toSelf();
        bind(DeriveProceedingMapper).toSelf();
        bind(EditProceedingMapper).toSelf();
        bind(GlobalActivityMapper).toSelf();
        bind(ProceedingMapper).toSelf();
        bind(ProceedingsSummaryMapper).toSelf();
        bind(ProceedingSummaryMapper).toSelf();
        bind(StatusEnumMapper).toSelf();
        bind(StatusMapper).toSelf();
    }),
    repositories: new ContainerModule((bind) => {
        bind(GlobalActivityRepository).toSelf();
        bind(ProceedingRepository).toSelf();
    }),
    useCases: new ContainerModule((bind) => {
        bind(CreateProceedingUseCase).toSelf();
        bind(DeriveProceedingUseCase).toSelf();
        bind(EditProceedingUseCase).toSelf();
        bind(GetAllGlobalActivitiesByUseCase).toSelf();
        bind(GetAllStatusUseCase).toSelf();
        bind(GetRecentModifiedDerivationsUseCase).toSelf();
        bind(GetRecentModifiedProceedingsUseCase).toSelf();
        bind(GetProceedingByIdUseCase).toSelf();
        bind(GetProceedingsByUseCase).toSelf();
    }),
    viewModels: new ContainerModule((bind) => {
        bind(CreateProceedingPageViewModel).toSelf();
        bind(DerivationModalFormViewModel).toSelf();
        bind(DocumentsTabViewModel).toSelf();
        bind(EditProceedingPageViewModel).toSelf();
        bind(GeneralTabViewModel).toSelf();
        bind(ProceedingDetailPageViewModel).toSelf();
        bind(ProceedingListPageViewModel).toSelf();
    }),
}));

proceedingModule.registerModule();

export { proceedingModule };
