/* eslint-disable max-lines */
import { CountryEnumDto } from "@core/data/dto/country.dto";
import { CountryEnum } from "@core/domain/models/country.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";

@injectable()
export class CountryEnumMapper {
    map(countryIdDto: CountryEnumDto): Nullable<CountryEnum> {
        switch (countryIdDto) {
            case CountryEnumDto.Afghanistan:
                return CountryEnum.Afghanistan;
            case CountryEnumDto.AlandIslands:
                return CountryEnum.AlandIslands;
            case CountryEnumDto.Albania:
                return CountryEnum.Albania;
            case CountryEnumDto.Algeria:
                return CountryEnum.Algeria;
            case CountryEnumDto.AmericanSamoa:
                return CountryEnum.AmericanSamoa;
            case CountryEnumDto.Andorra:
                return CountryEnum.Andorra;
            case CountryEnumDto.Angola:
                return CountryEnum.Angola;
            case CountryEnumDto.Anguilla:
                return CountryEnum.Anguilla;
            case CountryEnumDto.Antarctica:
                return CountryEnum.Antarctica;
            case CountryEnumDto.AntiguaAndBarbuda:
                return CountryEnum.AntiguaAndBarbuda;
            case CountryEnumDto.Argentina:
                return CountryEnum.Argentina;
            case CountryEnumDto.Armenia:
                return CountryEnum.Armenia;
            case CountryEnumDto.Aruba:
                return CountryEnum.Aruba;
            case CountryEnumDto.Australia:
                return CountryEnum.Australia;
            case CountryEnumDto.Austria:
                return CountryEnum.Austria;
            case CountryEnumDto.Azerbaijan:
                return CountryEnum.Azerbaijan;
            case CountryEnumDto.Bahamas:
                return CountryEnum.Bahamas;
            case CountryEnumDto.Bahrain:
                return CountryEnum.Bahrain;
            case CountryEnumDto.Bangladesh:
                return CountryEnum.Bangladesh;
            case CountryEnumDto.Barbados:
                return CountryEnum.Barbados;
            case CountryEnumDto.Belarus:
                return CountryEnum.Belarus;
            case CountryEnumDto.Belgium:
                return CountryEnum.Belgium;
            case CountryEnumDto.Belize:
                return CountryEnum.Belize;
            case CountryEnumDto.Benin:
                return CountryEnum.Benin;
            case CountryEnumDto.Bermuda:
                return CountryEnum.Bermuda;
            case CountryEnumDto.Bhutan:
                return CountryEnum.Bhutan;
            case CountryEnumDto.Bolivia:
                return CountryEnum.Bolivia;
            case CountryEnumDto.BonaireSintEustatiusAndSaba:
                return CountryEnum.BonaireSintEustatiusAndSaba;
            case CountryEnumDto.BosniaAndHerzegovina:
                return CountryEnum.BosniaAndHerzegovina;
            case CountryEnumDto.Botswana:
                return CountryEnum.Botswana;
            case CountryEnumDto.BouvetIsland:
                return CountryEnum.BouvetIsland;
            case CountryEnumDto.Brazil:
                return CountryEnum.Brazil;
            case CountryEnumDto.BritishIndianOceanTerritory:
                return CountryEnum.BritishIndianOceanTerritory;
            case CountryEnumDto.Brunei:
                return CountryEnum.Brunei;
            case CountryEnumDto.Bulgaria:
                return CountryEnum.Bulgaria;
            case CountryEnumDto.BurkinaFaso:
                return CountryEnum.BurkinaFaso;
            case CountryEnumDto.Burundi:
                return CountryEnum.Burundi;
            case CountryEnumDto.CaboVerde:
                return CountryEnum.CaboVerde;
            case CountryEnumDto.Cambodia:
                return CountryEnum.Cambodia;
            case CountryEnumDto.Cameroon:
                return CountryEnum.Cameroon;
            case CountryEnumDto.Canada:
                return CountryEnum.Canada;
            case CountryEnumDto.CaymanIslands:
                return CountryEnum.CaymanIslands;
            case CountryEnumDto.CentralAfricanRepublic:
                return CountryEnum.CentralAfricanRepublic;
            case CountryEnumDto.Chad:
                return CountryEnum.Chad;
            case CountryEnumDto.Chile:
                return CountryEnum.Chile;
            case CountryEnumDto.China:
                return CountryEnum.China;
            case CountryEnumDto.ChristmasIsland:
                return CountryEnum.ChristmasIsland;
            case CountryEnumDto.CocosKeelingIslands:
                return CountryEnum.CocosKeelingIslands;
            case CountryEnumDto.Colombia:
                return CountryEnum.Colombia;
            case CountryEnumDto.Comoros:
                return CountryEnum.Comoros;
            case CountryEnumDto.Congo:
                return CountryEnum.Congo;
            case CountryEnumDto.CongoTheDemocraticRepublicOfThe:
                return CountryEnum.CongoTheDemocraticRepublicOfThe;
            case CountryEnumDto.CookIslands:
                return CountryEnum.CookIslands;
            case CountryEnumDto.CostaRica:
                return CountryEnum.CostaRica;
            case CountryEnumDto.CoteDIvoire:
                return CountryEnum.CoteDIvoire;
            case CountryEnumDto.Croatia:
                return CountryEnum.Croatia;
            case CountryEnumDto.Cuba:
                return CountryEnum.Cuba;
            case CountryEnumDto.Curacao:
                return CountryEnum.Curacao;
            case CountryEnumDto.Cyprus:
                return CountryEnum.Cyprus;
            case CountryEnumDto.Czechia:
                return CountryEnum.Czechia;
            case CountryEnumDto.Denmark:
                return CountryEnum.Denmark;
            case CountryEnumDto.Djibouti:
                return CountryEnum.Djibouti;
            case CountryEnumDto.Dominica:
                return CountryEnum.Dominica;
            case CountryEnumDto.DominicanRepublic:
                return CountryEnum.DominicanRepublic;
            case CountryEnumDto.Ecuador:
                return CountryEnum.Ecuador;
            case CountryEnumDto.Egypt:
                return CountryEnum.Egypt;
            case CountryEnumDto.ElSalvador:
                return CountryEnum.ElSalvador;
            case CountryEnumDto.EquatorialGuinea:
                return CountryEnum.EquatorialGuinea;
            case CountryEnumDto.Eritrea:
                return CountryEnum.Eritrea;
            case CountryEnumDto.Estonia:
                return CountryEnum.Estonia;
            case CountryEnumDto.Eswatini:
                return CountryEnum.Eswatini;
            case CountryEnumDto.Ethiopia:
                return CountryEnum.Ethiopia;
            case CountryEnumDto.FalklandIslandsMalvinas:
                return CountryEnum.FalklandIslandsMalvinas;
            case CountryEnumDto.FaroeIslands:
                return CountryEnum.FaroeIslands;
            case CountryEnumDto.Fiji:
                return CountryEnum.Fiji;
            case CountryEnumDto.Finland:
                return CountryEnum.Finland;
            case CountryEnumDto.France:
                return CountryEnum.France;
            case CountryEnumDto.FrenchGuiana:
                return CountryEnum.FrenchGuiana;
            case CountryEnumDto.FrenchPolynesia:
                return CountryEnum.FrenchPolynesia;
            case CountryEnumDto.FrenchSouthernTerritories:
                return CountryEnum.FrenchSouthernTerritories;
            case CountryEnumDto.Gabon:
                return CountryEnum.Gabon;
            case CountryEnumDto.Gambia:
                return CountryEnum.Gambia;
            case CountryEnumDto.Georgia:
                return CountryEnum.Georgia;
            case CountryEnumDto.Germany:
                return CountryEnum.Germany;
            case CountryEnumDto.Ghana:
                return CountryEnum.Ghana;
            case CountryEnumDto.Gibraltar:
                return CountryEnum.Gibraltar;
            case CountryEnumDto.Greece:
                return CountryEnum.Greece;
            case CountryEnumDto.Greenland:
                return CountryEnum.Greenland;
            case CountryEnumDto.Grenada:
                return CountryEnum.Grenada;
            case CountryEnumDto.Guadeloupe:
                return CountryEnum.Guadeloupe;
            case CountryEnumDto.Guam:
                return CountryEnum.Guam;
            case CountryEnumDto.Guatemala:
                return CountryEnum.Guatemala;
            case CountryEnumDto.Guernsey:
                return CountryEnum.Guernsey;
            case CountryEnumDto.Guinea:
                return CountryEnum.Guinea;
            case CountryEnumDto.GuineaBissau:
                return CountryEnum.GuineaBissau;
            case CountryEnumDto.Guyana:
                return CountryEnum.Guyana;
            case CountryEnumDto.Haiti:
                return CountryEnum.Haiti;
            case CountryEnumDto.HeardIslandAndMcDonaldIslands:
                return CountryEnum.HeardIslandAndMcDonaldIslands;
            case CountryEnumDto.HolySee:
                return CountryEnum.HolySee;
            case CountryEnumDto.Honduras:
                return CountryEnum.Honduras;
            case CountryEnumDto.HongKong:
                return CountryEnum.HongKong;
            case CountryEnumDto.Hungary:
                return CountryEnum.Hungary;
            case CountryEnumDto.Iceland:
                return CountryEnum.Iceland;
            case CountryEnumDto.India:
                return CountryEnum.India;
            case CountryEnumDto.Indonesia:
                return CountryEnum.Indonesia;
            case CountryEnumDto.Iran:
                return CountryEnum.Iran;
            case CountryEnumDto.Iraq:
                return CountryEnum.Iraq;
            case CountryEnumDto.Ireland:
                return CountryEnum.Ireland;
            case CountryEnumDto.IsleOfMan:
                return CountryEnum.IsleOfMan;
            case CountryEnumDto.Israel:
                return CountryEnum.Israel;
            case CountryEnumDto.Italy:
                return CountryEnum.Italy;
            case CountryEnumDto.Jamaica:
                return CountryEnum.Jamaica;
            case CountryEnumDto.Japan:
                return CountryEnum.Japan;
            case CountryEnumDto.Jersey:
                return CountryEnum.Jersey;
            case CountryEnumDto.Jordan:
                return CountryEnum.Jordan;
            case CountryEnumDto.Kazakhstan:
                return CountryEnum.Kazakhstan;
            case CountryEnumDto.Kenya:
                return CountryEnum.Kenya;
            case CountryEnumDto.Kiribati:
                return CountryEnum.Kiribati;
            case CountryEnumDto.Kuwait:
                return CountryEnum.Kuwait;
            case CountryEnumDto.Kyrgyzstan:
                return CountryEnum.Kyrgyzstan;
            case CountryEnumDto.Laos:
                return CountryEnum.Laos;
            case CountryEnumDto.Latvia:
                return CountryEnum.Latvia;
            case CountryEnumDto.Lebanon:
                return CountryEnum.Lebanon;
            case CountryEnumDto.Lesotho:
                return CountryEnum.Lesotho;
            case CountryEnumDto.Liberia:
                return CountryEnum.Liberia;
            case CountryEnumDto.Libya:
                return CountryEnum.Libya;
            case CountryEnumDto.Liechtenstein:
                return CountryEnum.Liechtenstein;
            case CountryEnumDto.Lithuania:
                return CountryEnum.Lithuania;
            case CountryEnumDto.Luxembourg:
                return CountryEnum.Luxembourg;
            case CountryEnumDto.Macao:
                return CountryEnum.Macao;
            case CountryEnumDto.Madagascar:
                return CountryEnum.Madagascar;
            case CountryEnumDto.Malawi:
                return CountryEnum.Malawi;
            case CountryEnumDto.Malaysia:
                return CountryEnum.Malaysia;
            case CountryEnumDto.Maldives:
                return CountryEnum.Maldives;
            case CountryEnumDto.Mali:
                return CountryEnum.Mali;
            case CountryEnumDto.Malta:
                return CountryEnum.Malta;
            case CountryEnumDto.MarshallIslands:
                return CountryEnum.MarshallIslands;
            case CountryEnumDto.Martinique:
                return CountryEnum.Martinique;
            case CountryEnumDto.Mauritania:
                return CountryEnum.Mauritania;
            case CountryEnumDto.Mauritius:
                return CountryEnum.Mauritius;
            case CountryEnumDto.Mayotte:
                return CountryEnum.Mayotte;
            case CountryEnumDto.Mexico:
                return CountryEnum.Mexico;
            case CountryEnumDto.MicronesiaFederatedStatesOf:
                return CountryEnum.MicronesiaFederatedStatesOf;
            case CountryEnumDto.Moldova:
                return CountryEnum.Moldova;
            case CountryEnumDto.Monaco:
                return CountryEnum.Monaco;
            case CountryEnumDto.Mongolia:
                return CountryEnum.Mongolia;
            case CountryEnumDto.Montenegro:
                return CountryEnum.Montenegro;
            case CountryEnumDto.Montserrat:
                return CountryEnum.Montserrat;
            case CountryEnumDto.Morocco:
                return CountryEnum.Morocco;
            case CountryEnumDto.Mozambique:
                return CountryEnum.Mozambique;
            case CountryEnumDto.Myanmar:
                return CountryEnum.Myanmar;
            case CountryEnumDto.Namibia:
                return CountryEnum.Namibia;
            case CountryEnumDto.Nauru:
                return CountryEnum.Nauru;
            case CountryEnumDto.Nepal:
                return CountryEnum.Nepal;
            case CountryEnumDto.Netherlands:
                return CountryEnum.Netherlands;
            case CountryEnumDto.NewCaledonia:
                return CountryEnum.NewCaledonia;
            case CountryEnumDto.NewZealand:
                return CountryEnum.NewZealand;
            case CountryEnumDto.Nicaragua:
                return CountryEnum.Nicaragua;
            case CountryEnumDto.Niger:
                return CountryEnum.Niger;
            case CountryEnumDto.Nigeria:
                return CountryEnum.Nigeria;
            case CountryEnumDto.Niue:
                return CountryEnum.Niue;
            case CountryEnumDto.NorfolkIsland:
                return CountryEnum.NorfolkIsland;
            case CountryEnumDto.NorthKorea:
                return CountryEnum.NorthKorea;
            case CountryEnumDto.NorthMacedonia:
                return CountryEnum.NorthMacedonia;
            case CountryEnumDto.NorthernMarianaIslands:
                return CountryEnum.NorthernMarianaIslands;
            case CountryEnumDto.Norway:
                return CountryEnum.Norway;
            case CountryEnumDto.Oman:
                return CountryEnum.Oman;
            case CountryEnumDto.Pakistan:
                return CountryEnum.Pakistan;
            case CountryEnumDto.Palau:
                return CountryEnum.Palau;
            case CountryEnumDto.PalestineStateOf:
                return CountryEnum.PalestineStateOf;
            case CountryEnumDto.Panama:
                return CountryEnum.Panama;
            case CountryEnumDto.PapuaNewGuinea:
                return CountryEnum.PapuaNewGuinea;
            case CountryEnumDto.Paraguay:
                return CountryEnum.Paraguay;
            case CountryEnumDto.Peru:
                return CountryEnum.Peru;
            case CountryEnumDto.Philippines:
                return CountryEnum.Philippines;
            case CountryEnumDto.Pitcairn:
                return CountryEnum.Pitcairn;
            case CountryEnumDto.Poland:
                return CountryEnum.Poland;
            case CountryEnumDto.Portugal:
                return CountryEnum.Portugal;
            case CountryEnumDto.PuertoRico:
                return CountryEnum.PuertoRico;
            case CountryEnumDto.Qatar:
                return CountryEnum.Qatar;
            case CountryEnumDto.Reunion:
                return CountryEnum.Reunion;
            case CountryEnumDto.Romania:
                return CountryEnum.Romania;
            case CountryEnumDto.Russia:
                return CountryEnum.Russia;
            case CountryEnumDto.Rwanda:
                return CountryEnum.Rwanda;
            case CountryEnumDto.SaintBarthelemy:
                return CountryEnum.SaintBarthelemy;
            case CountryEnumDto.SaintHelenaAscensionAndTristanDaCunha:
                return CountryEnum.SaintHelenaAscensionAndTristanDaCunha;
            case CountryEnumDto.SaintKittsAndNevis:
                return CountryEnum.SaintKittsAndNevis;
            case CountryEnumDto.SaintLucia:
                return CountryEnum.SaintLucia;
            case CountryEnumDto.SaintMartinFrenchPart:
                return CountryEnum.SaintMartinFrenchPart;
            case CountryEnumDto.SaintPierreAndMiquelon:
                return CountryEnum.SaintPierreAndMiquelon;
            case CountryEnumDto.SaintVincentAndTheGrenadines:
                return CountryEnum.SaintVincentAndTheGrenadines;
            case CountryEnumDto.Samoa:
                return CountryEnum.Samoa;
            case CountryEnumDto.SanMarino:
                return CountryEnum.SanMarino;
            case CountryEnumDto.SaoTomeAndPrincipe:
                return CountryEnum.SaoTomeAndPrincipe;
            case CountryEnumDto.SaudiArabia:
                return CountryEnum.SaudiArabia;
            case CountryEnumDto.Senegal:
                return CountryEnum.Senegal;
            case CountryEnumDto.Serbia:
                return CountryEnum.Serbia;
            case CountryEnumDto.Seychelles:
                return CountryEnum.Seychelles;
            case CountryEnumDto.SierraLeone:
                return CountryEnum.SierraLeone;
            case CountryEnumDto.Singapore:
                return CountryEnum.Singapore;
            case CountryEnumDto.SintMaartenDutchPart:
                return CountryEnum.SintMaartenDutchPart;
            case CountryEnumDto.Slovakia:
                return CountryEnum.Slovakia;
            case CountryEnumDto.Slovenia:
                return CountryEnum.Slovenia;
            case CountryEnumDto.SolomonIslands:
                return CountryEnum.SolomonIslands;
            case CountryEnumDto.Somalia:
                return CountryEnum.Somalia;
            case CountryEnumDto.SouthAfrica:
                return CountryEnum.SouthAfrica;
            case CountryEnumDto.SouthGeorgiaAndTheSouthSandwichIslands:
                return CountryEnum.SouthGeorgiaAndTheSouthSandwichIslands;
            case CountryEnumDto.SouthKorea:
                return CountryEnum.SouthKorea;
            case CountryEnumDto.SouthSudan:
                return CountryEnum.SouthSudan;
            case CountryEnumDto.Spain:
                return CountryEnum.Spain;
            case CountryEnumDto.SriLanka:
                return CountryEnum.SriLanka;
            case CountryEnumDto.Sudan:
                return CountryEnum.Sudan;
            case CountryEnumDto.Suriname:
                return CountryEnum.Suriname;
            case CountryEnumDto.SvalbardAndJanMayen:
                return CountryEnum.SvalbardAndJanMayen;
            case CountryEnumDto.Sweden:
                return CountryEnum.Sweden;
            case CountryEnumDto.Switzerland:
                return CountryEnum.Switzerland;
            case CountryEnumDto.Syria:
                return CountryEnum.Syria;
            case CountryEnumDto.Taiwan:
                return CountryEnum.Taiwan;
            case CountryEnumDto.Tajikistan:
                return CountryEnum.Tajikistan;
            case CountryEnumDto.Tanzania:
                return CountryEnum.Tanzania;
            case CountryEnumDto.Thailand:
                return CountryEnum.Thailand;
            case CountryEnumDto.TimorLeste:
                return CountryEnum.TimorLeste;
            case CountryEnumDto.Togo:
                return CountryEnum.Togo;
            case CountryEnumDto.Tokelau:
                return CountryEnum.Tokelau;
            case CountryEnumDto.Tonga:
                return CountryEnum.Tonga;
            case CountryEnumDto.TrinidadAndTobago:
                return CountryEnum.TrinidadAndTobago;
            case CountryEnumDto.Tunisia:
                return CountryEnum.Tunisia;
            case CountryEnumDto.Turkiye:
                return CountryEnum.Turkiye;
            case CountryEnumDto.Turkmenistan:
                return CountryEnum.Turkmenistan;
            case CountryEnumDto.TurksAndCaicosIslands:
                return CountryEnum.TurksAndCaicosIslands;
            case CountryEnumDto.Tuvalu:
                return CountryEnum.Tuvalu;
            case CountryEnumDto.Uganda:
                return CountryEnum.Uganda;
            case CountryEnumDto.Ukraine:
                return CountryEnum.Ukraine;
            case CountryEnumDto.UnitedArabEmirates:
                return CountryEnum.UnitedArabEmirates;
            case CountryEnumDto.UnitedKingdom:
                return CountryEnum.UnitedKingdom;
            case CountryEnumDto.UnitedStatesMinorOutlyingIslands:
                return CountryEnum.UnitedStatesMinorOutlyingIslands;
            case CountryEnumDto.UnitedStatesOfAmerica:
                return CountryEnum.UnitedStatesOfAmerica;
            case CountryEnumDto.Uruguay:
                return CountryEnum.Uruguay;
            case CountryEnumDto.Uzbekistan:
                return CountryEnum.Uzbekistan;
            case CountryEnumDto.Vanuatu:
                return CountryEnum.Vanuatu;
            case CountryEnumDto.Venezuela:
                return CountryEnum.Venezuela;
            case CountryEnumDto.Vietnam:
                return CountryEnum.Vietnam;
            case CountryEnumDto.VirginIslandsBritish:
                return CountryEnum.VirginIslandsBritish;
            case CountryEnumDto.VirginIslandsUS:
                return CountryEnum.VirginIslandsUS;
            case CountryEnumDto.WallisAndFutuna:
                return CountryEnum.WallisAndFutuna;
            case CountryEnumDto.WesternSahara:
                return CountryEnum.WesternSahara;
            case CountryEnumDto.Yemen:
                return CountryEnum.Yemen;
            case CountryEnumDto.Zambia:
                return CountryEnum.Zambia;
            case CountryEnumDto.Zimbabwe:
                return CountryEnum.Zimbabwe;
            default:
                return null;
        }
    }

    mapToDto(countryEnum: CountryEnum): CountryEnumDto {
        // eslint-disable-next-line default-case
        switch (countryEnum) {
            case CountryEnum.Afghanistan:
                return CountryEnumDto.Afghanistan;
            case CountryEnum.AlandIslands:
                return CountryEnumDto.AlandIslands;
            case CountryEnum.Albania:
                return CountryEnumDto.Albania;
            case CountryEnum.Algeria:
                return CountryEnumDto.Algeria;
            case CountryEnum.AmericanSamoa:
                return CountryEnumDto.AmericanSamoa;
            case CountryEnum.Andorra:
                return CountryEnumDto.Andorra;
            case CountryEnum.Angola:
                return CountryEnumDto.Angola;
            case CountryEnum.Anguilla:
                return CountryEnumDto.Anguilla;
            case CountryEnum.Antarctica:
                return CountryEnumDto.Antarctica;
            case CountryEnum.AntiguaAndBarbuda:
                return CountryEnumDto.AntiguaAndBarbuda;
            case CountryEnum.Argentina:
                return CountryEnumDto.Argentina;
            case CountryEnum.Armenia:
                return CountryEnumDto.Armenia;
            case CountryEnum.Aruba:
                return CountryEnumDto.Aruba;
            case CountryEnum.Australia:
                return CountryEnumDto.Australia;
            case CountryEnum.Austria:
                return CountryEnumDto.Austria;
            case CountryEnum.Azerbaijan:
                return CountryEnumDto.Azerbaijan;
            case CountryEnum.Bahamas:
                return CountryEnumDto.Bahamas;
            case CountryEnum.Bahrain:
                return CountryEnumDto.Bahrain;
            case CountryEnum.Bangladesh:
                return CountryEnumDto.Bangladesh;
            case CountryEnum.Barbados:
                return CountryEnumDto.Barbados;
            case CountryEnum.Belarus:
                return CountryEnumDto.Belarus;
            case CountryEnum.Belgium:
                return CountryEnumDto.Belgium;
            case CountryEnum.Belize:
                return CountryEnumDto.Belize;
            case CountryEnum.Benin:
                return CountryEnumDto.Benin;
            case CountryEnum.Bermuda:
                return CountryEnumDto.Bermuda;
            case CountryEnum.Bhutan:
                return CountryEnumDto.Bhutan;
            case CountryEnum.Bolivia:
                return CountryEnumDto.Bolivia;
            case CountryEnum.BonaireSintEustatiusAndSaba:
                return CountryEnumDto.BonaireSintEustatiusAndSaba;
            case CountryEnum.BosniaAndHerzegovina:
                return CountryEnumDto.BosniaAndHerzegovina;
            case CountryEnum.Botswana:
                return CountryEnumDto.Botswana;
            case CountryEnum.BouvetIsland:
                return CountryEnumDto.BouvetIsland;
            case CountryEnum.Brazil:
                return CountryEnumDto.Brazil;
            case CountryEnum.BritishIndianOceanTerritory:
                return CountryEnumDto.BritishIndianOceanTerritory;
            case CountryEnum.Brunei:
                return CountryEnumDto.Brunei;
            case CountryEnum.Bulgaria:
                return CountryEnumDto.Bulgaria;
            case CountryEnum.BurkinaFaso:
                return CountryEnumDto.BurkinaFaso;
            case CountryEnum.Burundi:
                return CountryEnumDto.Burundi;
            case CountryEnum.CaboVerde:
                return CountryEnumDto.CaboVerde;
            case CountryEnum.Cambodia:
                return CountryEnumDto.Cambodia;
            case CountryEnum.Cameroon:
                return CountryEnumDto.Cameroon;
            case CountryEnum.Canada:
                return CountryEnumDto.Canada;
            case CountryEnum.CaymanIslands:
                return CountryEnumDto.CaymanIslands;
            case CountryEnum.CentralAfricanRepublic:
                return CountryEnumDto.CentralAfricanRepublic;
            case CountryEnum.Chad:
                return CountryEnumDto.Chad;
            case CountryEnum.Chile:
                return CountryEnumDto.Chile;
            case CountryEnum.China:
                return CountryEnumDto.China;
            case CountryEnum.ChristmasIsland:
                return CountryEnumDto.ChristmasIsland;
            case CountryEnum.CocosKeelingIslands:
                return CountryEnumDto.CocosKeelingIslands;
            case CountryEnum.Colombia:
                return CountryEnumDto.Colombia;
            case CountryEnum.Comoros:
                return CountryEnumDto.Comoros;
            case CountryEnum.Congo:
                return CountryEnumDto.Congo;
            case CountryEnum.CongoTheDemocraticRepublicOfThe:
                return CountryEnumDto.CongoTheDemocraticRepublicOfThe;
            case CountryEnum.CookIslands:
                return CountryEnumDto.CookIslands;
            case CountryEnum.CostaRica:
                return CountryEnumDto.CostaRica;
            case CountryEnum.CoteDIvoire:
                return CountryEnumDto.CoteDIvoire;
            case CountryEnum.Croatia:
                return CountryEnumDto.Croatia;
            case CountryEnum.Cuba:
                return CountryEnumDto.Cuba;
            case CountryEnum.Curacao:
                return CountryEnumDto.Curacao;
            case CountryEnum.Cyprus:
                return CountryEnumDto.Cyprus;
            case CountryEnum.Czechia:
                return CountryEnumDto.Czechia;
            case CountryEnum.Denmark:
                return CountryEnumDto.Denmark;
            case CountryEnum.Djibouti:
                return CountryEnumDto.Djibouti;
            case CountryEnum.Dominica:
                return CountryEnumDto.Dominica;
            case CountryEnum.DominicanRepublic:
                return CountryEnumDto.DominicanRepublic;
            case CountryEnum.Ecuador:
                return CountryEnumDto.Ecuador;
            case CountryEnum.Egypt:
                return CountryEnumDto.Egypt;
            case CountryEnum.ElSalvador:
                return CountryEnumDto.ElSalvador;
            case CountryEnum.EquatorialGuinea:
                return CountryEnumDto.EquatorialGuinea;
            case CountryEnum.Eritrea:
                return CountryEnumDto.Eritrea;
            case CountryEnum.Estonia:
                return CountryEnumDto.Estonia;
            case CountryEnum.Eswatini:
                return CountryEnumDto.Eswatini;
            case CountryEnum.Ethiopia:
                return CountryEnumDto.Ethiopia;
            case CountryEnum.FalklandIslandsMalvinas:
                return CountryEnumDto.FalklandIslandsMalvinas;
            case CountryEnum.FaroeIslands:
                return CountryEnumDto.FaroeIslands;
            case CountryEnum.Fiji:
                return CountryEnumDto.Fiji;
            case CountryEnum.Finland:
                return CountryEnumDto.Finland;
            case CountryEnum.France:
                return CountryEnumDto.France;
            case CountryEnum.FrenchGuiana:
                return CountryEnumDto.FrenchGuiana;
            case CountryEnum.FrenchPolynesia:
                return CountryEnumDto.FrenchPolynesia;
            case CountryEnum.FrenchSouthernTerritories:
                return CountryEnumDto.FrenchSouthernTerritories;
            case CountryEnum.Gabon:
                return CountryEnumDto.Gabon;
            case CountryEnum.Gambia:
                return CountryEnumDto.Gambia;
            case CountryEnum.Georgia:
                return CountryEnumDto.Georgia;
            case CountryEnum.Germany:
                return CountryEnumDto.Germany;
            case CountryEnum.Ghana:
                return CountryEnumDto.Ghana;
            case CountryEnum.Gibraltar:
                return CountryEnumDto.Gibraltar;
            case CountryEnum.Greece:
                return CountryEnumDto.Greece;
            case CountryEnum.Greenland:
                return CountryEnumDto.Greenland;
            case CountryEnum.Grenada:
                return CountryEnumDto.Grenada;
            case CountryEnum.Guadeloupe:
                return CountryEnumDto.Guadeloupe;
            case CountryEnum.Guam:
                return CountryEnumDto.Guam;
            case CountryEnum.Guatemala:
                return CountryEnumDto.Guatemala;
            case CountryEnum.Guernsey:
                return CountryEnumDto.Guernsey;
            case CountryEnum.Guinea:
                return CountryEnumDto.Guinea;
            case CountryEnum.GuineaBissau:
                return CountryEnumDto.GuineaBissau;
            case CountryEnum.Guyana:
                return CountryEnumDto.Guyana;
            case CountryEnum.Haiti:
                return CountryEnumDto.Haiti;
            case CountryEnum.HeardIslandAndMcDonaldIslands:
                return CountryEnumDto.HeardIslandAndMcDonaldIslands;
            case CountryEnum.HolySee:
                return CountryEnumDto.HolySee;
            case CountryEnum.Honduras:
                return CountryEnumDto.Honduras;
            case CountryEnum.HongKong:
                return CountryEnumDto.HongKong;
            case CountryEnum.Hungary:
                return CountryEnumDto.Hungary;
            case CountryEnum.Iceland:
                return CountryEnumDto.Iceland;
            case CountryEnum.India:
                return CountryEnumDto.India;
            case CountryEnum.Indonesia:
                return CountryEnumDto.Indonesia;
            case CountryEnum.Iran:
                return CountryEnumDto.Iran;
            case CountryEnum.Iraq:
                return CountryEnumDto.Iraq;
            case CountryEnum.Ireland:
                return CountryEnumDto.Ireland;
            case CountryEnum.IsleOfMan:
                return CountryEnumDto.IsleOfMan;
            case CountryEnum.Israel:
                return CountryEnumDto.Israel;
            case CountryEnum.Italy:
                return CountryEnumDto.Italy;
            case CountryEnum.Jamaica:
                return CountryEnumDto.Jamaica;
            case CountryEnum.Japan:
                return CountryEnumDto.Japan;
            case CountryEnum.Jersey:
                return CountryEnumDto.Jersey;
            case CountryEnum.Jordan:
                return CountryEnumDto.Jordan;
            case CountryEnum.Kazakhstan:
                return CountryEnumDto.Kazakhstan;
            case CountryEnum.Kenya:
                return CountryEnumDto.Kenya;
            case CountryEnum.Kiribati:
                return CountryEnumDto.Kiribati;
            case CountryEnum.Kuwait:
                return CountryEnumDto.Kuwait;
            case CountryEnum.Kyrgyzstan:
                return CountryEnumDto.Kyrgyzstan;
            case CountryEnum.Laos:
                return CountryEnumDto.Laos;
            case CountryEnum.Latvia:
                return CountryEnumDto.Latvia;
            case CountryEnum.Lebanon:
                return CountryEnumDto.Lebanon;
            case CountryEnum.Lesotho:
                return CountryEnumDto.Lesotho;
            case CountryEnum.Liberia:
                return CountryEnumDto.Liberia;
            case CountryEnum.Libya:
                return CountryEnumDto.Libya;
            case CountryEnum.Liechtenstein:
                return CountryEnumDto.Liechtenstein;
            case CountryEnum.Lithuania:
                return CountryEnumDto.Lithuania;
            case CountryEnum.Luxembourg:
                return CountryEnumDto.Luxembourg;
            case CountryEnum.Macao:
                return CountryEnumDto.Macao;
            case CountryEnum.Madagascar:
                return CountryEnumDto.Madagascar;
            case CountryEnum.Malawi:
                return CountryEnumDto.Malawi;
            case CountryEnum.Malaysia:
                return CountryEnumDto.Malaysia;
            case CountryEnum.Maldives:
                return CountryEnumDto.Maldives;
            case CountryEnum.Mali:
                return CountryEnumDto.Mali;
            case CountryEnum.Malta:
                return CountryEnumDto.Malta;
            case CountryEnum.MarshallIslands:
                return CountryEnumDto.MarshallIslands;
            case CountryEnum.Martinique:
                return CountryEnumDto.Martinique;
            case CountryEnum.Mauritania:
                return CountryEnumDto.Mauritania;
            case CountryEnum.Mauritius:
                return CountryEnumDto.Mauritius;
            case CountryEnum.Mayotte:
                return CountryEnumDto.Mayotte;
            case CountryEnum.Mexico:
                return CountryEnumDto.Mexico;
            case CountryEnum.MicronesiaFederatedStatesOf:
                return CountryEnumDto.MicronesiaFederatedStatesOf;
            case CountryEnum.Moldova:
                return CountryEnumDto.Moldova;
            case CountryEnum.Monaco:
                return CountryEnumDto.Monaco;
            case CountryEnum.Mongolia:
                return CountryEnumDto.Mongolia;
            case CountryEnum.Montenegro:
                return CountryEnumDto.Montenegro;
            case CountryEnum.Montserrat:
                return CountryEnumDto.Montserrat;
            case CountryEnum.Morocco:
                return CountryEnumDto.Morocco;
            case CountryEnum.Mozambique:
                return CountryEnumDto.Mozambique;
            case CountryEnum.Myanmar:
                return CountryEnumDto.Myanmar;
            case CountryEnum.Namibia:
                return CountryEnumDto.Namibia;
            case CountryEnum.Nauru:
                return CountryEnumDto.Nauru;
            case CountryEnum.Nepal:
                return CountryEnumDto.Nepal;
            case CountryEnum.Netherlands:
                return CountryEnumDto.Netherlands;
            case CountryEnum.NewCaledonia:
                return CountryEnumDto.NewCaledonia;
            case CountryEnum.NewZealand:
                return CountryEnumDto.NewZealand;
            case CountryEnum.Nicaragua:
                return CountryEnumDto.Nicaragua;
            case CountryEnum.Niger:
                return CountryEnumDto.Niger;
            case CountryEnum.Nigeria:
                return CountryEnumDto.Nigeria;
            case CountryEnum.Niue:
                return CountryEnumDto.Niue;
            case CountryEnum.NorfolkIsland:
                return CountryEnumDto.NorfolkIsland;
            case CountryEnum.NorthKorea:
                return CountryEnumDto.NorthKorea;
            case CountryEnum.NorthMacedonia:
                return CountryEnumDto.NorthMacedonia;
            case CountryEnum.NorthernMarianaIslands:
                return CountryEnumDto.NorthernMarianaIslands;
            case CountryEnum.Norway:
                return CountryEnumDto.Norway;
            case CountryEnum.Oman:
                return CountryEnumDto.Oman;
            case CountryEnum.Pakistan:
                return CountryEnumDto.Pakistan;
            case CountryEnum.Palau:
                return CountryEnumDto.Palau;
            case CountryEnum.PalestineStateOf:
                return CountryEnumDto.PalestineStateOf;
            case CountryEnum.Panama:
                return CountryEnumDto.Panama;
            case CountryEnum.PapuaNewGuinea:
                return CountryEnumDto.PapuaNewGuinea;
            case CountryEnum.Paraguay:
                return CountryEnumDto.Paraguay;
            case CountryEnum.Peru:
                return CountryEnumDto.Peru;
            case CountryEnum.Philippines:
                return CountryEnumDto.Philippines;
            case CountryEnum.Pitcairn:
                return CountryEnumDto.Pitcairn;
            case CountryEnum.Poland:
                return CountryEnumDto.Poland;
            case CountryEnum.Portugal:
                return CountryEnumDto.Portugal;
            case CountryEnum.PuertoRico:
                return CountryEnumDto.PuertoRico;
            case CountryEnum.Qatar:
                return CountryEnumDto.Qatar;
            case CountryEnum.Reunion:
                return CountryEnumDto.Reunion;
            case CountryEnum.Romania:
                return CountryEnumDto.Romania;
            case CountryEnum.Russia:
                return CountryEnumDto.Russia;
            case CountryEnum.Rwanda:
                return CountryEnumDto.Rwanda;
            case CountryEnum.SaintBarthelemy:
                return CountryEnumDto.SaintBarthelemy;
            case CountryEnum.SaintHelenaAscensionAndTristanDaCunha:
                return CountryEnumDto.SaintHelenaAscensionAndTristanDaCunha;
            case CountryEnum.SaintKittsAndNevis:
                return CountryEnumDto.SaintKittsAndNevis;
            case CountryEnum.SaintLucia:
                return CountryEnumDto.SaintLucia;
            case CountryEnum.SaintMartinFrenchPart:
                return CountryEnumDto.SaintMartinFrenchPart;
            case CountryEnum.SaintPierreAndMiquelon:
                return CountryEnumDto.SaintPierreAndMiquelon;
            case CountryEnum.SaintVincentAndTheGrenadines:
                return CountryEnumDto.SaintVincentAndTheGrenadines;
            case CountryEnum.Samoa:
                return CountryEnumDto.Samoa;
            case CountryEnum.SanMarino:
                return CountryEnumDto.SanMarino;
            case CountryEnum.SaoTomeAndPrincipe:
                return CountryEnumDto.SaoTomeAndPrincipe;
            case CountryEnum.SaudiArabia:
                return CountryEnumDto.SaudiArabia;
            case CountryEnum.Senegal:
                return CountryEnumDto.Senegal;
            case CountryEnum.Serbia:
                return CountryEnumDto.Serbia;
            case CountryEnum.Seychelles:
                return CountryEnumDto.Seychelles;
            case CountryEnum.SierraLeone:
                return CountryEnumDto.SierraLeone;
            case CountryEnum.Singapore:
                return CountryEnumDto.Singapore;
            case CountryEnum.SintMaartenDutchPart:
                return CountryEnumDto.SintMaartenDutchPart;
            case CountryEnum.Slovakia:
                return CountryEnumDto.Slovakia;
            case CountryEnum.Slovenia:
                return CountryEnumDto.Slovenia;
            case CountryEnum.SolomonIslands:
                return CountryEnumDto.SolomonIslands;
            case CountryEnum.Somalia:
                return CountryEnumDto.Somalia;
            case CountryEnum.SouthAfrica:
                return CountryEnumDto.SouthAfrica;
            case CountryEnum.SouthGeorgiaAndTheSouthSandwichIslands:
                return CountryEnumDto.SouthGeorgiaAndTheSouthSandwichIslands;
            case CountryEnum.SouthKorea:
                return CountryEnumDto.SouthKorea;
            case CountryEnum.SouthSudan:
                return CountryEnumDto.SouthSudan;
            case CountryEnum.Spain:
                return CountryEnumDto.Spain;
            case CountryEnum.SriLanka:
                return CountryEnumDto.SriLanka;
            case CountryEnum.Sudan:
                return CountryEnumDto.Sudan;
            case CountryEnum.Suriname:
                return CountryEnumDto.Suriname;
            case CountryEnum.SvalbardAndJanMayen:
                return CountryEnumDto.SvalbardAndJanMayen;
            case CountryEnum.Sweden:
                return CountryEnumDto.Sweden;
            case CountryEnum.Switzerland:
                return CountryEnumDto.Switzerland;
            case CountryEnum.Syria:
                return CountryEnumDto.Syria;
            case CountryEnum.Taiwan:
                return CountryEnumDto.Taiwan;
            case CountryEnum.Tajikistan:
                return CountryEnumDto.Tajikistan;
            case CountryEnum.Tanzania:
                return CountryEnumDto.Tanzania;
            case CountryEnum.Thailand:
                return CountryEnumDto.Thailand;
            case CountryEnum.TimorLeste:
                return CountryEnumDto.TimorLeste;
            case CountryEnum.Togo:
                return CountryEnumDto.Togo;
            case CountryEnum.Tokelau:
                return CountryEnumDto.Tokelau;
            case CountryEnum.Tonga:
                return CountryEnumDto.Tonga;
            case CountryEnum.TrinidadAndTobago:
                return CountryEnumDto.TrinidadAndTobago;
            case CountryEnum.Tunisia:
                return CountryEnumDto.Tunisia;
            case CountryEnum.Turkiye:
                return CountryEnumDto.Turkiye;
            case CountryEnum.Turkmenistan:
                return CountryEnumDto.Turkmenistan;
            case CountryEnum.TurksAndCaicosIslands:
                return CountryEnumDto.TurksAndCaicosIslands;
            case CountryEnum.Tuvalu:
                return CountryEnumDto.Tuvalu;
            case CountryEnum.Uganda:
                return CountryEnumDto.Uganda;
            case CountryEnum.Ukraine:
                return CountryEnumDto.Ukraine;
            case CountryEnum.UnitedArabEmirates:
                return CountryEnumDto.UnitedArabEmirates;
            case CountryEnum.UnitedKingdom:
                return CountryEnumDto.UnitedKingdom;
            case CountryEnum.UnitedStatesMinorOutlyingIslands:
                return CountryEnumDto.UnitedStatesMinorOutlyingIslands;
            case CountryEnum.UnitedStatesOfAmerica:
                return CountryEnumDto.UnitedStatesOfAmerica;
            case CountryEnum.Uruguay:
                return CountryEnumDto.Uruguay;
            case CountryEnum.Uzbekistan:
                return CountryEnumDto.Uzbekistan;
            case CountryEnum.Vanuatu:
                return CountryEnumDto.Vanuatu;
            case CountryEnum.Venezuela:
                return CountryEnumDto.Venezuela;
            case CountryEnum.Vietnam:
                return CountryEnumDto.Vietnam;
            case CountryEnum.VirginIslandsBritish:
                return CountryEnumDto.VirginIslandsBritish;
            case CountryEnum.VirginIslandsUS:
                return CountryEnumDto.VirginIslandsUS;
            case CountryEnum.WallisAndFutuna:
                return CountryEnumDto.WallisAndFutuna;
            case CountryEnum.WesternSahara:
                return CountryEnumDto.WesternSahara;
            case CountryEnum.Yemen:
                return CountryEnumDto.Yemen;
            case CountryEnum.Zambia:
                return CountryEnumDto.Zambia;
            case CountryEnum.Zimbabwe:
                return CountryEnumDto.Zimbabwe;
        }
    }
}
