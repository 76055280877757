import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CreatePublicUtility } from "@entity/domain/models/public-utility/create-public-utility.model";
import { EditPublicUtility } from "@entity/domain/models/public-utility/edit-public-utility.model";
import {
    PublicUtilities,
    PublicUtility,
} from "@entity/domain/models/public-utility/public-utility.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { CreatePublicUtilityBody } from "../dto/public-utility/create-public-utility.body";
import { EditPublicUtilityBody } from "../dto/public-utility/edit-public-utility.body";
import {
    PublicUtilitiesDto,
    PublicUtilityDto,
} from "../dto/public-utility/public-utility.dto";
import { CreatePublicUtilityMapper } from "../mappers/public-utility/create-public-interest.mapper";
import { EditPublicUtilityMapper } from "../mappers/public-utility/edit-public-interest.mapper";
import { PublicUtilitiesMapper } from "../mappers/public-utility/public-utilities.mapper";

const ENTITY_PUBLIC_INTERESTS_PATH = "/entities_public_utilities/";

@injectable()
export class PublicUtilityDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(PublicUtilitiesMapper)
        private readonly publicUtilitiesMapper: PublicUtilitiesMapper,
        @inject(CreatePublicUtilityMapper)
        private readonly createPublicUtilityMapper: CreatePublicUtilityMapper,
        @inject(EditPublicUtilityMapper)
        private readonly editPublicUtilityMapper: EditPublicUtilityMapper,
    ) {}

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, PublicUtilities>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const publicUtilitiesResult = await this.http.get<PublicUtilityDto>(
            ENTITY_PUBLIC_INTERESTS_PATH,
            {
                query,
            },
        );

        return publicUtilitiesResult.map((response) =>
            this.publicUtilitiesMapper.map(
                plainToClass(PublicUtilitiesDto, response.data),
            ),
        );
    }

    async create(
        newPublicUtility: CreatePublicUtility,
    ): Promise<Either<ValidationError | FallbackError, PublicUtility>> {
        const publicUtilityBody =
            this.createPublicUtilityMapper.mapToCreateDto(newPublicUtility);

        const publicUtilityResult = await this.http.post<
            PublicUtilityDto,
            CreatePublicUtilityBody
        >(ENTITY_PUBLIC_INTERESTS_PATH, publicUtilityBody);

        return publicUtilityResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const publicUtility =
                    this.publicUtilitiesMapper.mapPublicUtility(
                        plainToClass(PublicUtilityDto, response.data),
                    );

                if (!publicUtility) return Either.Left(new FallbackError());

                return Either.Right(publicUtility);
            });
    }

    async edit(
        editPublicUtility: EditPublicUtility,
    ): Promise<Either<ValidationError | FallbackError, PublicUtility>> {
        const editedPublicUtility =
            this.editPublicUtilityMapper.mapToDto(editPublicUtility);

        const editPublicUtilityResult = await this.http.patch<
            PublicUtilityDto,
            EditPublicUtilityBody
        >(
            `${ENTITY_PUBLIC_INTERESTS_PATH}${editPublicUtility.id}/`,
            editedPublicUtility,
        );

        return editPublicUtilityResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const publicUtility =
                    this.publicUtilitiesMapper.mapPublicUtility(
                        plainToClass(PublicUtilityDto, response.data),
                    );

                if (!publicUtility) return Either.Left(new FallbackError());

                return Either.Right(publicUtility);
            });
    }

    async delete(
        publicUtilityId: number,
    ): Promise<Either<FallbackError, true>> {
        const deletePublicUtility = await this.http.delete(
            `${ENTITY_PUBLIC_INTERESTS_PATH}${publicUtilityId}`,
        );

        return deletePublicUtility
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }
}
