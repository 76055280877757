import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum TypologyEnumDto {
    EntityGrouping = "entity_grouping",
    Association = "association",
    ShelteredEmploymentCenter = "sheltered_employment_center",
    Confederation = "confederation",
    SocialCooperative = "social_cooperative",
    SocialEnterprises = "social_enterprises",
    SocialEconomyEntities = "social_economy_entities",
    Federation = "federation",
    Foundation = "foundation",
    Platform = "platform",
    SocialClub = "social_club",
    OrganizedSocialMovements = "organized_social_movements",
    Other = "other",
    "" = "",
}

export class TypologyDto {
    @IsEnum(TypologyEnumDto)
    @IsNotEmpty()
    key!: TypologyEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
