import { Nullable } from "@core/domain/types/nullable.type";
import { StatusEnumDto } from "@proceeding/data/dto/status-enum.dto";
import { StatusEnum } from "@proceeding/domain/models/status.model";
import { injectable } from "inversify";

@injectable()
export class StatusEnumMapper {
    map(proceedingStatusEnumDto: StatusEnumDto): Nullable<StatusEnum> {
        switch (proceedingStatusEnumDto) {
            case StatusEnumDto.Authorized:
                return StatusEnum.Authorized;
            case StatusEnumDto.DesfavorableResolution:
                return StatusEnum.DesfavorableResolution;
            case StatusEnumDto.FavorableResolution:
                return StatusEnum.FavorableResolution;
            case StatusEnumDto.Management:
                return StatusEnum.Management;
            case StatusEnumDto.Requested:
                return StatusEnum.Requested;
            default:
                return null;
        }
    }

    mapToDto(proceedingStatusEnum: StatusEnum): StatusEnumDto {
        // eslint-disable-next-line default-case
        switch (proceedingStatusEnum) {
            case StatusEnum.Authorized:
                return StatusEnumDto.Authorized;
            case StatusEnum.DesfavorableResolution:
                return StatusEnumDto.DesfavorableResolution;
            case StatusEnum.FavorableResolution:
                return StatusEnumDto.FavorableResolution;
            case StatusEnum.Management:
                return StatusEnumDto.Management;
            case StatusEnum.Requested:
                return StatusEnumDto.Requested;
        }
    }
}
