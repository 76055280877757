import { inject, injectable } from "inversify";
import { BeneficiaryRepository } from "../../data/repositories/beneficiary.repository";
import { BeneficiariesSummary } from "../models/beneficiaries-summary.model";

@injectable()
export class GetRecentModifiedBeneficiariesUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(): Promise<BeneficiariesSummary> {
        const beneficiariesResult =
            await this.beneficiaryRepository.getRecentModified();

        return beneficiariesResult.fold(
            () => new BeneficiariesSummary([], 0),
            (beneficiaries) => beneficiaries,
        );
    }
}
