import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { ActionLine } from "@entity/domain/models/action-line.model";
import { Category } from "@entity/domain/models/category.model";
import { CCAA } from "@entity/domain/models/ccaa.model";
import { Collective } from "@entity/domain/models/collective.model";
import { CrosscuttingScope } from "@entity/domain/models/crosscutting-scope.model";
import { Typology } from "@entity/domain/models/typology.model";
import { GetAllActionLinesUseCase } from "@entity/domain/usecases/get-all-action-lines.usecase";
import { GetAllCategoriesUseCase } from "@entity/domain/usecases/get-all-categories.usecase";
import { GetAllCCAAUseCase } from "@entity/domain/usecases/get-all-ccaa.usecase";
import { GetAllCollectivesUseCase } from "@entity/domain/usecases/get-all-collectives.usecase";
import { GetAllCrosscuttingScopesUseCase } from "@entity/domain/usecases/get-all-crosscutting-scopes.usecase";
import { GetAllTypologiesUseCase } from "@entity/domain/usecases/get-all-typologies.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class GeneralTabViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    typologies: Typology[] = [];

    @observable
    categories: Category[] = [];

    @observable
    collectives: Collective[] = [];

    @observable
    actionLines: ActionLine[] = [];

    @observable
    ccaa: CCAA[] = [];

    @observable
    crosscuttingScopes: CrosscuttingScope[] = [];

    constructor(
        @inject(GetAllCategoriesUseCase)
        private readonly getAllCategoriesUseCase: GetAllCategoriesUseCase,
        @inject(GetAllCollectivesUseCase)
        private readonly getAllCollectivesUseCase: GetAllCollectivesUseCase,
        @inject(GetAllTypologiesUseCase)
        private readonly getAllTypologiesUseCase: GetAllTypologiesUseCase,
        @inject(GetAllActionLinesUseCase)
        private readonly getAllActionLinesUseCase: GetAllActionLinesUseCase,
        @inject(GetAllCrosscuttingScopesUseCase)
        private readonly getAllCrosscuttingScopesUseCase: GetAllCrosscuttingScopesUseCase,
        @inject(GetAllCCAAUseCase)
        private readonly getAllCCAAUseCase: GetAllCCAAUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllTypologies(),
            this.getAllCategories(),
            this.getAllCollectives(),
            this.getAllActionLines(),
            this.getAllCrosscuttingScopes(),
            this.getAllCCAA(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllCCAA(): Promise<void> {
        const ccaa = await this.getAllCCAAUseCase.execute();

        runInAction(() => {
            this.ccaa = ccaa;
        });
    }

    async getAllTypologies(): Promise<void> {
        const typologies = await this.getAllTypologiesUseCase.execute();

        runInAction(() => {
            this.typologies = typologies;
        });
    }

    async getAllCategories(): Promise<void> {
        const categories = await this.getAllCategoriesUseCase.execute();

        runInAction(() => {
            this.categories = categories;
        });
    }

    async getAllCollectives(): Promise<void> {
        const collectives = await this.getAllCollectivesUseCase.execute();

        runInAction(() => {
            this.collectives = collectives;
        });
    }

    async getAllActionLines(): Promise<void> {
        const actionLines = await this.getAllActionLinesUseCase.execute();

        runInAction(() => {
            this.actionLines = actionLines;
        });
    }

    async getAllCrosscuttingScopes(): Promise<void> {
        const crosscuttingScopes =
            await this.getAllCrosscuttingScopesUseCase.execute();

        runInAction(() => {
            this.crosscuttingScopes = crosscuttingScopes;
        });
    }
}
