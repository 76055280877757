import {
    IncSelect,
    IncSelectProps,
} from "@core/presentacion/component/data-entry/select/inc-select.component";
import { useIncFilterItemContext } from "@core/presentacion/component/filters/item/inc-filter-item.context";
import { DefaultOptionType } from "antd/es/select";
import { useField } from "formik";
import { ChangeEvent, FC } from "react";

export type IncSelectFormProps = Omit<
    IncSelectProps,
    "defaultValue" | "value" | "name"
>;

/**
 * This is the variation of a select designed to be integrated exclusively with a formik form wrapped with a form.item
 */
export const IncSelectFilter: FC<IncSelectFormProps> = (props) => {
    const { name } = useIncFilterItemContext();
    const [field, meta] = useField(name);

    // We deconstruct onChange, so we can spread the rest of properties in field
    const {
        onChange,
        // @ts-expect-error: value is being injected via FormItem (antd) and we want to use the formik version (field)
        value: _restPropsValue,
        ...restProps
    } = props;

    // We deconstruct onChange, so we can spread the rest of properties in field
    const { onChange: _onChange, ...restField } = field;

    const handleChange = (
        value: unknown,
        option: DefaultOptionType | DefaultOptionType[],
    ): void => {
        const fakeEvent: ChangeEvent = {
            target: {
                name: field.name,
                value: value,
            } as unknown as EventTarget & Element,
        } as ChangeEvent;

        field.onChange(fakeEvent);

        if (props.onChange) props.onChange(value, option);
    };

    return (
        <IncSelect
            {...restField}
            {...restProps}
            defaultValue={meta.initialValue}
            onChange={handleChange}
        />
    );
};
