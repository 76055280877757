import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreditorMapper } from "@entity/data/mappers/creditor/creditor.mapper";
import { Creditor } from "@entity/domain/models/creditor/creditor.model";
import { CreateCreditorUseCase } from "@entity/domain/usecases/creditor/create-creditor.usecase";
import { CreditorFormValuesValidated } from "@entity/presentation/component/creditor-form/creditor-form.component";
import { inject, injectable } from "inversify";
import { makeObservable } from "mobx";

@injectable()
export class CreateCreditorPageViewModel extends BaseViewModel {
    constructor(
        @inject(CreateCreditorUseCase)
        private readonly createCreditorUseCase: CreateCreditorUseCase,
        @inject(CreditorMapper)
        private readonly creditorMapper: CreditorMapper,
    ) {
        super();
        makeObservable(this);
    }

    async createCreditor(
        values: CreditorFormValuesValidated,
    ): Promise<Nullable<Creditor>> {
        LoadLayoutStore.start();

        const createCreditorResult = await this.createCreditorUseCase.execute(
            this.creditorMapper.mapFromFormToCreate(values),
        );

        if (createCreditorResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createCreditorResult;
    }
}
