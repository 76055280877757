import { Nullable } from "@core/domain/types/nullable.type";
import { SocialNetworkSummary } from "@entity/domain/models/social-networks-summary.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";
import { v4 as uuidv4 } from "uuid";
import { SocialNetworkSummaryDto } from "../../dto/social-network/social-networks-summary.dto";

@injectable()
export class SocialNetworkSummaryMapper {
    map(
        socialNetworkSummaryDto: SocialNetworkSummaryDto,
    ): Nullable<SocialNetworkSummary> {
        const errors = validateSync(SocialNetworkSummaryDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        return new SocialNetworkSummary(
            socialNetworkSummaryDto.id,
            socialNetworkSummaryDto.entity,
            socialNetworkSummaryDto.name,
            socialNetworkSummaryDto.link,
            socialNetworkSummaryDto.followers,
            socialNetworkSummaryDto.num_posts,
            uuidv4(),
        );
    }
}
