import { Nullable } from "@core/domain/types/nullable.type";
import { TransportTypeDto } from "@project/data/dto/expenses/transport-type.dto";
import {
    TransportType,
    TransportTypeEnum,
} from "@project/domain/models/expenses/transport-type.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { TransportTypeEnumMapper } from "./transport-type-enum.mapper";

@injectable()
export class TransportTypeMapper {
    constructor(
        @inject(TransportTypeEnumMapper)
        private readonly transportTypeEnumMapper: TransportTypeEnumMapper,
    ) {}

    map(transportTypeDto: TransportTypeDto): Nullable<TransportType> {
        const errors = validateSync(TransportTypeDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<TransportTypeEnum> =
            this.transportTypeEnumMapper.map(transportTypeDto.key);

        if (!id) return null;

        return new TransportType(id, transportTypeDto.value);
    }
}
