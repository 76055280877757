import { Nullable } from "@core/domain/types/nullable.type";
import { BudgetDto } from "@project/data/dto/budget.dto";
import { BudgetColumnMapper } from "@project/data/mappers/budget/columns/budget-column.mapper";
import { FinancialEntityBudget } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class FinancialEntityBudgetMapper {
    constructor(
        @inject(BudgetColumnMapper)
        private readonly budgetColumnMapper: BudgetColumnMapper,
    ) {}
    map(budgetDto: BudgetDto): Nullable<FinancialEntityBudget> {
        const errors = validateSync(budgetDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }
        const columns = budgetDto.columns?.mapNotNull((column) =>
            this.budgetColumnMapper.map(column),
        );

        return new FinancialEntityBudget(
            budgetDto.id,
            budgetDto.name,
            budgetDto.description,
            budgetDto.finantial_entity,
            columns,
            budgetDto.categories ?? [],
        );
    }
}
