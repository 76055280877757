import { Nullable } from "@core/domain/types/nullable.type";
import { PolicyProtocolTypeEnumDto } from "@entity/data/dto/policy-protocol-type-enum.dto";
import { PolicyProtocolTypeEnum } from "@entity/domain/models/policy-protocol-type.model";
import { injectable } from "inversify";

@injectable()
export class PolicyProtocolTypeEnumMapper {
    map(
        policyProtocolTypeEnumDto: PolicyProtocolTypeEnumDto,
    ): Nullable<PolicyProtocolTypeEnum> {
        switch (policyProtocolTypeEnumDto) {
            case PolicyProtocolTypeEnumDto.QualityPolicy:
                return PolicyProtocolTypeEnum.QualityPolicy;
            case PolicyProtocolTypeEnumDto.HumanRightsPolicy:
                return PolicyProtocolTypeEnum.HumanRightsPolicy;
            case PolicyProtocolTypeEnumDto.EnvironmentalPolicy:
                return PolicyProtocolTypeEnum.EnvironmentalPolicy;
            case PolicyProtocolTypeEnumDto.VolunteerPlan:
                return PolicyProtocolTypeEnum.VolunteerPlan;
            case PolicyProtocolTypeEnumDto.PreventionActionHarassmentProtocol:
                return PolicyProtocolTypeEnum.PreventionActionHarassmentProtocol;
            case PolicyProtocolTypeEnumDto.TransparencyPolicy:
                return PolicyProtocolTypeEnum.TransparencyPolicy;
            case PolicyProtocolTypeEnumDto.EthicsConductGoodGovernance:
                return PolicyProtocolTypeEnum.EthicsConductGoodGovernance;
            case PolicyProtocolTypeEnumDto.AdvertisementCriteriaMarketing:
                return PolicyProtocolTypeEnum.AdvertisementCriteriaMarketing;
            case PolicyProtocolTypeEnumDto.DerivationProtocol:
                return PolicyProtocolTypeEnum.DerivationProtocol;
            case PolicyProtocolTypeEnumDto.LgtbqphobicActuationProtocol:
                return PolicyProtocolTypeEnum.LgtbqphobicActuationProtocol;
            case PolicyProtocolTypeEnumDto.EvaluationPolicy:
                return PolicyProtocolTypeEnum.EvaluationPolicy;
            case PolicyProtocolTypeEnumDto.Other:
                return PolicyProtocolTypeEnum.Other;
            default:
                return null;
        }
    }

    mapToDto(
        policyProtocolTypeEnum: PolicyProtocolTypeEnum,
    ): PolicyProtocolTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (policyProtocolTypeEnum) {
            case PolicyProtocolTypeEnum.QualityPolicy:
                return PolicyProtocolTypeEnumDto.QualityPolicy;
            case PolicyProtocolTypeEnum.HumanRightsPolicy:
                return PolicyProtocolTypeEnumDto.HumanRightsPolicy;
            case PolicyProtocolTypeEnum.EnvironmentalPolicy:
                return PolicyProtocolTypeEnumDto.EnvironmentalPolicy;
            case PolicyProtocolTypeEnum.VolunteerPlan:
                return PolicyProtocolTypeEnumDto.VolunteerPlan;
            case PolicyProtocolTypeEnum.PreventionActionHarassmentProtocol:
                return PolicyProtocolTypeEnumDto.PreventionActionHarassmentProtocol;
            case PolicyProtocolTypeEnum.TransparencyPolicy:
                return PolicyProtocolTypeEnumDto.TransparencyPolicy;
            case PolicyProtocolTypeEnum.EthicsConductGoodGovernance:
                return PolicyProtocolTypeEnumDto.EthicsConductGoodGovernance;
            case PolicyProtocolTypeEnum.AdvertisementCriteriaMarketing:
                return PolicyProtocolTypeEnumDto.AdvertisementCriteriaMarketing;
            case PolicyProtocolTypeEnum.DerivationProtocol:
                return PolicyProtocolTypeEnumDto.DerivationProtocol;
            case PolicyProtocolTypeEnum.LgtbqphobicActuationProtocol:
                return PolicyProtocolTypeEnumDto.LgtbqphobicActuationProtocol;
            case PolicyProtocolTypeEnum.EvaluationPolicy:
                return PolicyProtocolTypeEnumDto.EvaluationPolicy;
            case PolicyProtocolTypeEnum.Other:
                return PolicyProtocolTypeEnumDto.Other;
        }
    }
}
