import { FinancialEntity } from "@project/domain/models/financial-entity/financial-entities.model";

export class CreateFinancialEntity {
    constructor(
        public name: FinancialEntity["name"],
        public type: FinancialEntity["type"],
        public description: FinancialEntity["description"],
        public isActive: FinancialEntity["isActive"],
    ) {}
}
