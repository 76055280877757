import type { Nullable } from "@core/domain/types/nullable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import {
    BudgetCategory,
    BudgetCategoryConcept,
    BudgetCategoryGroup,
    BudgetCategoryGroups,
    BudgetColumn,
} from "@project/domain/models/budget/financial-entity-budget.model";
import { GetAllCategoryConceptsByCategoryAndGroupIdUseCase } from "@project/domain/usecases/budget/categories/concepts/get-all-category-concepts-by-category-and-group-id.usecase";
import { GetAllBudgetCategoriesByBudgetIdUseCase } from "@project/domain/usecases/budget/categories/get-all-budget-categories-by-budget-id.usecase";
import { GetAllCategoryGroupsByCategoryIdUseCase } from "@project/domain/usecases/budget/categories/groups/get-all-category-groups-by-category-id.usecase";
import { GetAllBudgetColumnsByBudgetIdUseCase } from "@project/domain/usecases/budget/columns/get-all-budget-columns-by-budget-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class BudgetValidationFormViewModel extends BaseViewModel {
    _budgetId: Nullable<number> = null;

    // Used to initialize group combos when editing a validation
    _initialCategoryId: Nullable<number> = null;
    _initialCategoryIdToCompare: Nullable<number> = null;

    _initialGroupId: Nullable<number> = null;
    _initialGroupIdToCompare: Nullable<number> = null;

    constructor(
        @inject(GetAllBudgetColumnsByBudgetIdUseCase)
        private readonly getAllBudgetColumnsByBudgetIdUseCase: GetAllBudgetColumnsByBudgetIdUseCase,
        @inject(GetAllBudgetCategoriesByBudgetIdUseCase)
        private readonly getAllBudgetCategoriesByBudgetIdUseCase: GetAllBudgetCategoriesByBudgetIdUseCase,
        @inject(GetAllCategoryGroupsByCategoryIdUseCase)
        private readonly getAllCategoryGroupsByCategoryIdUseCase: GetAllCategoryGroupsByCategoryIdUseCase,
        @inject(GetAllCategoryConceptsByCategoryAndGroupIdUseCase)
        private readonly getAllCategoryConceptsByCategoryAndGroupIdUseCase: GetAllCategoryConceptsByCategoryAndGroupIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    @observable
    budgetColumns: BudgetColumn[] = [];

    @observable
    budgetCategories: BudgetCategory[] = [];

    @observable
    budgetCategoryGroups: BudgetCategoryGroup[] = [];

    @observable
    budgetCategoryGroupItems: BudgetCategoryConcept[] = [];

    @observable
    loadingBudgetCategoryGroups: boolean = false;

    @observable
    loadingBudgetCategoryItems: boolean = false;

    @observable
    budgetCategoryGroupsToCompare: BudgetCategoryGroup[] = [];

    @observable
    budgetCategoryGroupItemsToCompare: BudgetCategoryConcept[] = [];

    @observable
    loadingBudgetCategoryGroupsToCompare: boolean = false;

    @observable
    loadingBudgetCategoryItemsToCompare: boolean = false;

    override async didMount(): Promise<void> {
        await super.didMount();
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        const requests = [this.getBudgetColumns(), this.getBudgetCategories()];

        if (this._initialCategoryId) {
            requests.push(
                this.getBudgetCategoryGroups(this._initialCategoryId),
            );
            requests.push(
                this.getBudgetCategoryGroupsItems(
                    this._initialCategoryId,
                    this._initialGroupId ?? undefined,
                ),
            );
        }

        if (this._initialCategoryIdToCompare) {
            requests.push(
                this.getBudgetCategoryGroupsToCompare(
                    this._initialCategoryIdToCompare,
                ),
            );
            requests.push(
                this.getBudgetCategoryGroupsItemsToCompare(
                    this._initialCategoryIdToCompare,
                    this._initialGroupIdToCompare ?? undefined,
                ),
            );
        }

        await Promise.all(requests);
    }

    async getBudgetColumns(): Promise<void> {
        if (!this._budgetId) {
            return;
        }
        const budgetColumns =
            await this.getAllBudgetColumnsByBudgetIdUseCase.execute(
                this._budgetId,
            );

        runInAction(() => {
            this.budgetColumns = budgetColumns.budgetColumns;
        });
    }

    async getBudgetCategories(): Promise<void> {
        if (!this._budgetId) {
            return;
        }
        const budgetCategories =
            await this.getAllBudgetCategoriesByBudgetIdUseCase.execute(
                this._budgetId,
            );

        runInAction(() => {
            this.budgetCategories = budgetCategories.budgetCategories;
        });
    }

    private async loadBudgetCategoryGroups(
        categoryId: number,
    ): Promise<BudgetCategoryGroups> {
        return this.getAllCategoryGroupsByCategoryIdUseCase.execute(categoryId);
    }

    async getBudgetCategoryGroups(categoryId: number): Promise<void> {
        runInAction(() => {
            this.loadingBudgetCategoryGroups = true;
        });

        const categoryGroups = await this.loadBudgetCategoryGroups(categoryId);

        runInAction(() => {
            this.budgetCategoryGroups = categoryGroups.budgetCategoryGroups;
            this.loadingBudgetCategoryGroups = false;
        });
    }

    async getBudgetCategoryGroupsItems(
        categoryId: number,
        groupId?: number,
    ): Promise<void> {
        runInAction(() => {
            this.loadingBudgetCategoryItems = true;
        });

        const categoryGroupsItems =
            await this.getAllCategoryConceptsByCategoryAndGroupIdUseCase.execute(
                categoryId,
                groupId,
            );

        runInAction(() => {
            this.budgetCategoryGroupItems =
                categoryGroupsItems.budgetCategoryConcepts;
            this.loadingBudgetCategoryItems = false;
        });
    }

    @action
    clearCategoryGroups(): void {
        this.budgetCategoryGroups = [];
    }

    @action
    clearCategoryGroupsItems(): void {
        this.budgetCategoryGroupItems = [];
    }

    async getBudgetCategoryGroupsToCompare(categoryId: number): Promise<void> {
        runInAction(() => {
            this.loadingBudgetCategoryGroupsToCompare = true;
        });

        const categoryGroups = await this.loadBudgetCategoryGroups(categoryId);

        runInAction(() => {
            this.budgetCategoryGroupsToCompare =
                categoryGroups.budgetCategoryGroups;
            this.loadingBudgetCategoryGroupsToCompare = false;
        });
    }

    async getBudgetCategoryGroupsItemsToCompare(
        categoryId: number,
        groupId?: number,
    ): Promise<void> {
        runInAction(() => {
            this.loadingBudgetCategoryItemsToCompare = true;
        });

        const categoryGroupsItems =
            await this.getAllCategoryConceptsByCategoryAndGroupIdUseCase.execute(
                categoryId,
                groupId,
            );

        runInAction(() => {
            this.budgetCategoryGroupItemsToCompare =
                categoryGroupsItems.budgetCategoryConcepts;
            this.loadingBudgetCategoryItemsToCompare = false;
        });
    }

    @action
    clearCategoryGroupsToCompare(): void {
        this.budgetCategoryGroupsToCompare = [];
    }

    @action
    clearCategoryGroupsItemsToCompare(): void {
        this.budgetCategoryGroupItemsToCompare = [];
    }
}
