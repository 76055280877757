import { GenderEnumDto } from "@beneficiary/data/dto/gender.dto";
import { GenderEnum } from "@beneficiary/domain/models/gender.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";

@injectable()
export class GenderEnumMapper {
    map(genderIdDto: GenderEnumDto): Nullable<GenderEnum> {
        switch (genderIdDto) {
            case GenderEnumDto.Male:
                return GenderEnum.Male;
            case GenderEnumDto.Female:
                return GenderEnum.Female;
            case GenderEnumDto.NotStated:
                return GenderEnum.NotStated;
            case GenderEnumDto.Intersexual:
                return GenderEnum.Intersexual;
            case GenderEnumDto.NonBinary:
                return GenderEnum.NonBinary;
            default:
                return null;
        }
    }

    mapToDto(genderId: GenderEnum): GenderEnumDto {
        // eslint-disable-next-line default-case
        switch (genderId) {
            case GenderEnum.Female:
                return GenderEnumDto.Female;
            case GenderEnum.Male:
                return GenderEnumDto.Male;
            case GenderEnum.Intersexual:
                return GenderEnumDto.Intersexual;
            case GenderEnum.NonBinary:
                return GenderEnumDto.NonBinary;
            case GenderEnum.NotStated:
                return GenderEnumDto.NotStated;
        }
    }
}
