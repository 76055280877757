import { IdentificationDocumentTypeDatasource } from "@core/data/datasources/identification-document-type.datasource";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { IdentificationType } from "@core/domain/models/identification-document-type.model";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";
@injectable()
export class IdentificationDocumentTypeRepository {
    constructor(
        @inject(IdentificationDocumentTypeDatasource)
        private readonly identificationDocumentTypeDatasource: IdentificationDocumentTypeDatasource,
    ) {}
    async getAll(): Promise<Either<FallbackError, IdentificationType[]>> {
        return this.identificationDocumentTypeDatasource.fetchAllIdentificationDocumentTypes();
    }
}
