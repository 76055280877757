import { RequireEntityGuard } from "@core/presentacion/component/routing/require-entity/require-entity.guard";
import { RequireRoleGuard } from "@core/presentacion/component/routing/require-role/require-role.guard";
import { UserRoleEnum } from "@user/domain/models/user-role.model";
import { lazy } from "react";
import { Route } from "react-router-dom";

const ListProceedingPage = lazy(async () =>
    import(
        /* webpackChunkName: "ProceedingListPage" */ "@proceeding/presentation/pages/list/proceeding-list.page"
    ).then((module) => ({ default: module.ProceedingListPage })),
);

const CreateProceedingPage = lazy(async () =>
    import(
        /* webpackChunkName: "ProceedingCreatePage" */ "@proceeding/presentation/pages/create/create-proceeding.page"
    ).then((module) => ({ default: module.ProceedingCreatePage })),
);

const EditProceedingPage = lazy(async () =>
    import(
        /* webpackChunkName: "EditProceedingPage" */ "@proceeding/presentation/pages/edit/edit-proceeding.page"
    ).then((module) => ({ default: module.EditProceedingPage })),
);

const ProceedingDetailPage = lazy(async () =>
    import(
        /* webpackChunkName: "ProceedingDetailPage" */ "@proceeding/presentation/pages/detail/detail-proceeding.page"
    ).then((module) => ({ default: module.ProceedingDetailPage })),
);

export class ProceedingPaths {
    static readonly Root = "proceeding";

    static readonly Create = "create";

    static readonly EditParam = ":proceedingId";
    static readonly Edit = `edit/${ProceedingPaths.EditParam}`;

    static readonly DetailParam = ":proceedingId";
    static readonly Detail = `detail/${ProceedingPaths.DetailParam}`;
}

export class ProceedingRoutes {
    static LIST = `/${ProceedingPaths.Root}`;
    static CREATE = `/${ProceedingPaths.Root}/${ProceedingPaths.Create}`;
    static EDIT = (proceedingId: number): string =>
        `/${ProceedingPaths.Root}/${ProceedingPaths.Edit.replace(
            ProceedingPaths.EditParam,
            proceedingId.toString(),
        )}`;

    static DETAIL = (proceedingId: number): string =>
        `/${ProceedingPaths.Root}/${ProceedingPaths.Detail.replace(
            ProceedingPaths.DetailParam,
            proceedingId.toString(),
        )}`;
}

export const proceedingRoutes = (
    <Route path={ProceedingPaths.Root}>
        <Route
            element={<ListProceedingPage />}
            index
        />
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                        UserRoleEnum.Professional,
                    ]}
                >
                    <RequireEntityGuard redirectTo={ProceedingRoutes.LIST}>
                        <CreateProceedingPage />
                    </RequireEntityGuard>
                </RequireRoleGuard>
            }
            path={ProceedingPaths.Create}
        />

        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                        UserRoleEnum.Professional,
                    ]}
                >
                    <RequireEntityGuard redirectTo={ProceedingRoutes.LIST}>
                        <EditProceedingPage />
                    </RequireEntityGuard>
                </RequireRoleGuard>
            }
            path={ProceedingPaths.Edit}
        />
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                        UserRoleEnum.Professional,
                    ]}
                >
                    <ProceedingDetailPage />
                </RequireRoleGuard>
            }
            path={ProceedingPaths.Detail}
        />
    </Route>
);
