import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { BudgetCategoryGroups } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllCategoryGroupsByCategoryIdUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(categoryId: number): Promise<BudgetCategoryGroups> {
        const categoriesResult =
            await this.budgetRepository.getCategoryGroupsByCategoryId(
                categoryId,
            );

        return categoriesResult.fold(
            () => new BudgetCategoryGroups([], 0),
            (categories) => categories,
        );
    }
}
