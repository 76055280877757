import { IncomeRepository } from "@beneficiary/data/repositories/income.repository";
import { Income } from "@beneficiary/domain/models/economic-data/income/income.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllIncomesByUsecase {
    constructor(
        @inject(IncomeRepository)
        private readonly incomesRepository: IncomeRepository,
    ) {}

    async execute(beneficiaryId: number): Promise<Income[]> {
        const incomesResult =
            await this.incomesRepository.getAllBy(beneficiaryId);

        return incomesResult.fold(
            () => [],
            (incomes) => incomes.incomes,
        );
    }
}
