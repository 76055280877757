import { IncCol } from "@core/presentacion/component/layout/col/inc-col.component";
import { IncRow } from "@core/presentacion/component/layout/row/inc-row.component";
import { Result } from "antd";
import { isError } from "lodash";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export const ErrorInRoute: FC = () => {
    const error = useRouteError();
    const { t } = useTranslation();

    let errorDetail = undefined;
    if (isRouteErrorResponse(error)) {
        errorDetail = error.statusText;
    } else if (isError(error)) {
        errorDetail = error.message;
    }

    console.error(error);

    return (
        <IncRow
            justify={"center"}
            align={"middle"}
            className={"tw-h-screen"}
        >
            <IncCol span={24}>
                <Result
                    title={t("error:core.errorInRoute.errorTitle")}
                    subTitle={errorDetail}
                    status={"500"}
                />
            </IncCol>
        </IncRow>
    );
};
