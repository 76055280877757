import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { ContactDatasource } from "@entity/data/datasources/contact.datasource";
import {
    Contact,
    CreateContactBody,
} from "@proceeding/domain/models/contact.model";
import { inject, injectable } from "inversify";

@injectable()
export class ContactRepository {
    constructor(
        @inject(ContactDatasource)
        private readonly contactDatasource: ContactDatasource,
    ) {}

    async getAll(): Promise<Either<FallbackError, Contact[]>> {
        const contactResult = await this.contactDatasource.fetchAll();

        return contactResult
            .mapLeft(() => new FallbackError())
            .map((contact) => contact);
    }

    async create(
        contact: CreateContactBody,
    ): Promise<Either<ValidationError | FallbackError, Contact>> {
        return this.contactDatasource.create(contact);
    }

    async edit(
        contact: Contact,
    ): Promise<Either<ValidationError | FallbackError, Contact>> {
        return this.contactDatasource.edit(contact);
    }

    async delete(id: number): Promise<Either<FallbackError, true>> {
        return this.contactDatasource.delete(id);
    }
}
