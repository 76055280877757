export enum BudgetColumnTypeEnum {
    amount = "amount",
    percentage = "percentage",
}

export class BudgetColumnType {
    constructor(
        public readonly id: BudgetColumnTypeEnum,
        public label: string,
    ) {}
}
