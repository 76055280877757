import { Nullable } from "@core/domain/types/nullable.type";

export class GlobalActivity {
    constructor(
        public readonly id: number,
        public name: string,
    ) {}
}

export interface GlobalActivityFilters {
    name?: string;
    entityId?: Nullable<number>;
    projectId?: Nullable<number>;
    technical_proposalId?: Nullable<number>;
    objetiveId?: Nullable<number>;
}
