import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import {
    ExpenseType,
    ExpenseTypeEnum,
} from "@project/domain/models/expenses/expense-type.model";
import { ExpenseAllTypes } from "@project/domain/models/expenses/expense.model";
import { GetAllProjectExpenseTypesUseCase } from "@project/domain/usecases/expenses/get-all-project-expense-types.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

@injectable()
export class ExpensesModalBodyViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    typeSelectedId: Nullable<ExpenseTypeEnum> = null;

    @observable
    typeSelectedLabel: string = "";

    @observable
    types: ExpenseType[] = [];

    @observable
    editExpenseForm: Undefinable<ExpenseAllTypes>;

    constructor(
        @inject(GetAllProjectExpenseTypesUseCase)
        private readonly getAllProjectExpenseTypesUseCase: GetAllProjectExpenseTypesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @action.bound
    async setType(type: ExpenseTypeEnum, label: string): Promise<void> {
        runInAction(() => {
            this.typeSelectedId = type;
            this.typeSelectedLabel = label;
        });
    }

    async getAllExpenseTypes(): Promise<void> {
        const types = await this.getAllProjectExpenseTypesUseCase.execute();

        runInAction(() => {
            this.types = types;
        });
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(this.getAllExpenseTypes());

        runInAction(() => {
            this.initialLoading = false;
        });
    }
}
