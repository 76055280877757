import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { ActionLineEnumMapper } from "@entity/data/mappers/action-line-enum.mapper";
import { CollectiveEnumMapper } from "@entity/data/mappers/collective-enum.mapper";
import { ProjectDto } from "@project/data/dto/project.dto";
import { CatalogueEnumMapper } from "@project/data/mappers/catalogue-enum.mapper";
import { StatusEnumMapper } from "@project/data/mappers/status-enum.mapper";
import {
    DesignPlanification,
    Project,
} from "@project/domain/models/project.model";
import { isDefined, validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { ProjectFinancialEntityMapper } from "./project-financial-entity.mapper";
import { TerritorialScopeEnumMapper } from "./territorial-scope-enum.mapper";

@injectable()
export class ProjectMapper {
    constructor(
        @inject(CatalogueEnumMapper)
        private readonly catalogueEnumMapper: CatalogueEnumMapper,
        @inject(CollectiveEnumMapper)
        private readonly collectiveEnumMapper: CollectiveEnumMapper,
        @inject(ActionLineEnumMapper)
        private readonly actionLineEnumMapper: ActionLineEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
        @inject(StatusEnumMapper)
        private readonly statusEnumMapper: StatusEnumMapper,
        @inject(TerritorialScopeEnumMapper)
        private readonly territorialScopeTypeEnumMapper: TerritorialScopeEnumMapper,
        @inject(ProjectFinancialEntityMapper)
        private readonly projectFinancialEntityMapper: ProjectFinancialEntityMapper,
    ) {}

    map(projectDto: ProjectDto): Nullable<Project> {
        const errors = validateSync(projectDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const catalogueId = projectDto.catalogue
            ? projectDto.catalogue.mapNotNull((catalogueDto) =>
                  this.catalogueEnumMapper.map(catalogueDto),
              )
            : null;

        const statusId = this.statusEnumMapper.map(projectDto.status);
        if (!statusId) return null;

        const totalCost = Number(projectDto.total_cost);

        let designPlanification: Undefinable<DesignPlanification> = undefined;
        if (!!projectDto.design_date || !!projectDto.design_resolution_date) {
            designPlanification = new DesignPlanification(
                projectDto.design_date
                    ? DateTime.fromISO(projectDto.design_date)
                    : null,
                projectDto.design_resolution_date
                    ? DateTime.fromISO(projectDto.design_resolution_date)
                    : null,
            );
        }

        const documents = (projectDto.documents_data ?? []).mapNotNull(
            (documentData) => this.documentMapper.map(documentData),
        );

        const territorialScopeId = projectDto.territorial_scope
            ? this.territorialScopeTypeEnumMapper.map(
                  projectDto.territorial_scope,
              )
            : null;

        const collectives = projectDto.collectives
            ? projectDto.collectives.mapNotNull((collectiveDto) =>
                  this.collectiveEnumMapper.map(collectiveDto),
              )
            : null;

        const actionLines = projectDto.action_lines
            ? projectDto.action_lines.mapNotNull((actionLinesDto) =>
                  this.actionLineEnumMapper.map(actionLinesDto),
              )
            : null;

        const project = new Project(
            projectDto.id,
            projectDto.entity,
            projectDto.name,
            projectDto.year,
            projectDto.notes ?? "",
            projectDto.description ?? "",
            territorialScopeId,
            catalogueId,
            collectives,
            actionLines,
            statusId,
            documents,
            totalCost,
            projectDto.start_date
                ? DateTime.fromISO(projectDto.start_date)
                : null,
            projectDto.end_date ? DateTime.fromISO(projectDto.end_date) : null,
            designPlanification,
        );

        if (projectDto.employees_data) {
            projectDto.employees_data.forEach((employeeDto) =>
                project.addEmployee(employeeDto.id),
            );
        }

        if (isDefined(projectDto.is_active)) {
            project.isActive = projectDto.is_active;
        }

        if (isDefined(projectDto.continuity)) {
            project.continuity = projectDto.continuity;
        }

        if (projectDto.project_finantial_entity_data) {
            project.financialEntities =
                projectDto.project_finantial_entity_data.mapNotNull(
                    (projectFinantialEntityDto) =>
                        this.projectFinancialEntityMapper.map(
                            projectFinantialEntityDto,
                        ),
                );
        }

        return project;
    }
}
