import { ProjectRepository } from "@project/data/repositories/project.repository";
import { Catalogue } from "@project/domain/models/catalogue.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllCataloguesUseCase {
    constructor(
        @inject(ProjectRepository)
        private readonly projectRepository: ProjectRepository,
    ) {}

    async execute(): Promise<Catalogue[]> {
        const cataloguesResult =
            await this.projectRepository.getAllCatalogues();

        return cataloguesResult.getOrElse([]);
    }
}
