import { RelativesRepository } from "@beneficiary/data/repositories/relatives.repository";
import { EditRelative } from "@beneficiary/domain/models/relatives/edit-relative.model";
import { Relative } from "@beneficiary/domain/models/relatives/relative.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class EditRelativesUsecase {
    constructor(
        @inject(RelativesRepository)
        private readonly relativesRepository: RelativesRepository,
    ) {}

    async execute(
        editRelative: EditRelative,
        beneficiaryId: number,
    ): Promise<Nullable<Relative>> {
        const relativesResult = await this.relativesRepository.edit(
            editRelative,
            beneficiaryId,
        );

        if (relativesResult.isLeft()) {
            ToastManagerStore.error(relativesResult.getLeftOrThrow().message);
        }

        return relativesResult.fold(
            () => null,
            (relatives) => relatives,
        );
    }
}
