import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { CostRepository } from "@entity/data/repositories/cost.repository";
import { inject, injectable } from "inversify";
import { Cost } from "../../models/cost/cost.model";
import { CreateCost } from "../../models/cost/create-cost.model";

@injectable()
export class CreateCostUseCase {
    constructor(
        @inject(CostRepository)
        private readonly costRepository: CostRepository,
    ) {}

    async execute(cost: CreateCost): Promise<Nullable<Cost>> {
        const costResult = await this.costRepository.create(cost);

        if (costResult.isLeft()) {
            ToastManagerStore.error(costResult.getLeftOrThrow().message);
        }

        return costResult.fold(
            () => null,
            (createdCost) => createdCost,
        );
    }
}
