import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ObjectiveSearchFilters } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { ObjectiveRepository } from "@project/data/repositories/technical-proposal/objective.repository";
import { inject, injectable } from "inversify";
import { Objective } from "../../../models/technical-proposals.model";

@injectable()
export class GetAllObjectivesByUseCase {
    constructor(
        @inject(ObjectiveRepository)
        private readonly objectiveRepository: ObjectiveRepository,
    ) {}

    async execute(filters: ObjectiveSearchFilters): Promise<Objective[]> {
        const objectivesResult =
            await this.objectiveRepository.getAllBy(filters);

        if (objectivesResult.isLeft()) {
            ToastManagerStore.error(objectivesResult.getLeftOrThrow().message);
        }

        return objectivesResult.fold(
            () => [],
            (objectives) => objectives,
        );
    }
}
