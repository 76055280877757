import { Nullable } from "@core/domain/types/nullable.type";
import { BudgetSpreadsheetValidationTypeEnumDto } from "@project/data/dto/budget-spreadsheet.dto";
import { BudgetSpreadsheetValidationTypeEnum } from "@project/domain/models/budget-spreadsheet.model";
import { injectable } from "inversify";

@injectable()
export class BudgetSpreadsheetValidationTypeEnumMapper {
    map(
        validationType: BudgetSpreadsheetValidationTypeEnumDto,
    ): Nullable<BudgetSpreadsheetValidationTypeEnum> {
        switch (validationType) {
            case BudgetSpreadsheetValidationTypeEnumDto.Amount:
                return BudgetSpreadsheetValidationTypeEnum.Amount;
            case BudgetSpreadsheetValidationTypeEnumDto.Percentage:
                return BudgetSpreadsheetValidationTypeEnum.Percentage;
            default:
                return null;
        }
    }
}
