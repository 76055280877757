import { BudgetValidationsDto } from "@project/data/dto/budget.dto";
import { BudgetValidationMapper } from "@project/data/mappers/budget/validations/budget-validation.mapper";
import { BudgetValidations } from "@project/domain/models/budget/financial-entity-budget.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetValidationsMapper {
    constructor(
        @inject(BudgetValidationMapper)
        private readonly budgetValidationMapper: BudgetValidationMapper,
    ) {}

    map(budgetValidationsDto: BudgetValidationsDto): BudgetValidations {
        const errors = validateSync(budgetValidationsDto);
        if (errors.length) {
            console.error(errors);
            return new BudgetValidations([], 0);
        }

        const budgetValidations = budgetValidationsDto.results.mapNotNull(
            (budgetValidationDto) =>
                this.budgetValidationMapper.map(budgetValidationDto),
        );
        return new BudgetValidations(
            budgetValidations,
            budgetValidationsDto.count,
        );
    }
}
