import { CreatePublicUtilityBody } from "@entity/data/dto/public-utility/create-public-utility.body";
import { CreatePublicUtility } from "@entity/domain/models/public-utility/create-public-utility.model";
import { PublicUtilityFormValuesValidated } from "@entity/presentation/component/entity-form/general/public-utility/form/public-utility-form";
import { injectable } from "inversify";

@injectable()
export class CreatePublicUtilityMapper {
    mapToCreateDto(
        createPublicUtility: CreatePublicUtility,
    ): CreatePublicUtilityBody {
        const publicUtilityDto: CreatePublicUtilityBody = {
            organization_type: createPublicUtility.organizationType,
            entity: createPublicUtility.entity,
            date: createPublicUtility.date.toISODate() ?? "",
        };

        return publicUtilityDto;
    }

    mapFromFormValues(
        entityId: number,
        publicUtility: PublicUtilityFormValuesValidated,
    ): CreatePublicUtility {
        const createPublicUtility: CreatePublicUtility =
            new CreatePublicUtility(
                publicUtility.organizationType,
                entityId,
                publicUtility.date,
            );

        return createPublicUtility;
    }
}
