import { Nullable } from "@core/domain/types/nullable.type";
import { ServiceProvidedDto } from "@entity/data/dto/supplier/service-provided-enum.dto";
import { ServiceProvidedEnumMapper } from "@entity/data/mappers/supplier/service-provided-enum.mapper";
import {
    ServiceProvided,
    ServiceProvidedEnum,
} from "@entity/domain/models/supplier/service-provided.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ServiceProvidedMapper {
    constructor(
        @inject(ServiceProvidedEnumMapper)
        private readonly serviceProvidedEnumMapper: ServiceProvidedEnumMapper,
    ) {}

    map(serviceProvidedDto: ServiceProvidedDto): Nullable<ServiceProvided> {
        const errors = validateSync(serviceProvidedDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<ServiceProvidedEnum> =
            this.serviceProvidedEnumMapper.map(serviceProvidedDto.key);

        if (!id) return null;

        return new ServiceProvided(id, serviceProvidedDto.value);
    }
}
