import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateStatutoryTerritorialScopeMapper } from "@entity/data/mappers/statutory-territorial-scope/create-statutory-territorial-scope.mapper";
import { EditStatutoryTerritorialScopeMapper } from "@entity/data/mappers/statutory-territorial-scope/edit-statutory-territorial-scope.mapper";
import { EntityTerritorialScope } from "@entity/domain/models/entity-territorial-scope.model";
import { StatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/statutory-territorial-scope.model";
import { GetAllEntityTerritorialScopeUseCase } from "@entity/domain/usecases/entity/get-all-entity-territorial-scope.usecase";
import { CreateStatutoryTerritorialScopeUseCase } from "@entity/domain/usecases/statutory-territorial-scope/create-statutory-territorial-scope.usecase";
import { DeleteStatutoryTerritorialScopeUseCase } from "@entity/domain/usecases/statutory-territorial-scope/delete-statutory-territorial-scope.usecase";
import { EditStatutoryTerritorialScopeUseCase } from "@entity/domain/usecases/statutory-territorial-scope/edit-statutory-territorial-scope.usecase";
import { GetAllStatuoryTerritorialScopesUsecase } from "@entity/domain/usecases/statutory-territorial-scope/get-all-statutory-territorial-scopes.usecase";
import { StatutoryTerritorialScopeFormValuesValidated } from "@entity/presentation/component/entity-form/general/statutory-territorial-scope/form/statutory-territorial-scope-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class StatutoryTerritorialScopeViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    statutoryTerritorialScopes: StatutoryTerritorialScope[] = [];

    @observable
    entityTerritorialScopes: EntityTerritorialScope[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddStatutoryTerritorialScopeModal: boolean = false;

    @observable
    showEditStatutoryTerritorialScopeModal: boolean = false;

    @observable
    statutoryTerritorialScopeToEdit: Undefinable<StatutoryTerritorialScope> =
        undefined;

    constructor(
        @inject(GetAllStatuoryTerritorialScopesUsecase)
        private readonly getAllStatuoryTerritorialScopesUsecase: GetAllStatuoryTerritorialScopesUsecase,
        @inject(CreateStatutoryTerritorialScopeMapper)
        private readonly createStatutoryTerritorialScopeMapper: CreateStatutoryTerritorialScopeMapper,
        @inject(EditStatutoryTerritorialScopeMapper)
        private readonly editStatutoryTerritorialScopeMapper: EditStatutoryTerritorialScopeMapper,
        @inject(CreateStatutoryTerritorialScopeUseCase)
        private readonly createStatutoryTerritorialScopeUseCase: CreateStatutoryTerritorialScopeUseCase,
        @inject(EditStatutoryTerritorialScopeUseCase)
        private readonly editStatutoryTerritorialScopeUseCase: EditStatutoryTerritorialScopeUseCase,
        @inject(DeleteStatutoryTerritorialScopeUseCase)
        private readonly deleteStatutoryTerritorialScopeUseCase: DeleteStatutoryTerritorialScopeUseCase,
        @inject(GetAllEntityTerritorialScopeUseCase)
        private readonly getAllEntityTerritorialScopeUseCase: GetAllEntityTerritorialScopeUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getStatutoryTerritorialScopes(),
            this.getEntityTerritorialScopes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getStatutoryTerritorialScopes(): Promise<void> {
        const statutoryTerritorialScopes =
            await this.getAllStatuoryTerritorialScopesUsecase.execute();
        runInAction(() => {
            this.statutoryTerritorialScopes = statutoryTerritorialScopes;
        });
    }

    async getEntityTerritorialScopes(): Promise<void> {
        const entityTerritorialScopes =
            await this.getAllEntityTerritorialScopeUseCase.execute();
        runInAction(() => {
            this.entityTerritorialScopes = entityTerritorialScopes;
        });
    }

    @action
    setShowAddTerritorialScopeModal(show: boolean): void {
        this.showAddStatutoryTerritorialScopeModal = show;
    }

    @action
    closeEditTerritorialScopeModal(): void {
        this.showEditStatutoryTerritorialScopeModal = false;
    }

    @action
    openEditTerritorialScopeModal(
        statutoryTerritorialScope: Undefinable<StatutoryTerritorialScope>,
    ): void {
        this.statutoryTerritorialScopeToEdit = statutoryTerritorialScope;
        this.showEditStatutoryTerritorialScopeModal = true;
    }

    async addStatutoryTerritorialScope(
        entityId: Undefinable<number>,
        values: StatutoryTerritorialScopeFormValuesValidated,
    ): Promise<Nullable<StatutoryTerritorialScope>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createStatutoryTerritorialScope =
            await this.createStatutoryTerritorialScopeUseCase.execute(
                this.createStatutoryTerritorialScopeMapper.mapFromFormValues(
                    entityId,
                    values,
                ),
            );

        if (createStatutoryTerritorialScope) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createStatutoryTerritorialScope;
    }

    async editStatutoryTerritorialScope(
        values: StatutoryTerritorialScopeFormValuesValidated,
    ): Promise<void> {
        if (
            !this.statutoryTerritorialScopeToEdit?.id ||
            !this.statutoryTerritorialScopeToEdit.entity
        )
            throw Error("Missing statutoryTerritorialScope");

        LoadLayoutStore.start();
        const editStatutoryTerritorialScopeResult =
            await this.editStatutoryTerritorialScopeUseCase.execute(
                this.editStatutoryTerritorialScopeMapper.mapFromModal(
                    this.statutoryTerritorialScopeToEdit.id,
                    this.statutoryTerritorialScopeToEdit.entity,
                    values,
                ),
            );

        if (editStatutoryTerritorialScopeResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadStatutoryTerritorialScopes(): Promise<void> {
        await this.getStatutoryTerritorialScopes();
    }

    async deleteStatutoryTerritorialScope(
        statutoryTerritorialScopeId: number,
    ): Promise<void> {
        LoadLayoutStore.start();

        await this.deleteStatutoryTerritorialScopeUseCase.execute(
            statutoryTerritorialScopeId,
        );

        LoadLayoutStore.finish();
    }
}
