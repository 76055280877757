import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum EducationLevelEnumDto {
    NoStudiesCantReadWrite = "no_studies_cant_read_write",
    NoStudiesCanReadWrite = "no_studies_can_read_write",
    EarlyChildhoodFirstCycle = "early_childhood_first_cycle",
    EarlyChildhoodSecondCycle = "early_childhood_second_cycle",
    PrimaryEducation = "primary_education",
    SecondaryEducation = "secondary_education",
    FpMiddleDegree = "fp_middle_degree",
    FpHigherDegree = "fp_higher_degree",
    GeneralProfessionalPrograms = "general_professional_programs",
    HigherStudies = "higher_studies",
    DoctoratePostgraduate = "doctorate_postgraduate",
    UnrecognizedForeignQualifications = "unrecognized_foreign_qualifications",
}

export class EducationLevelDto {
    @IsEnum(EducationLevelEnumDto)
    @IsNotEmpty()
    key!: EducationLevelEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
