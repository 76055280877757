import { Expenses } from "@beneficiary/domain/models/economic-data/expense/expense.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { ExpensesDto } from "../dto/beneficiary.dto";
import { ExpenseMapper } from "./expense.mapper";

@injectable()
export class ExpensesMapper {
    constructor(
        @inject(ExpenseMapper)
        private readonly expenseMapper: ExpenseMapper,
    ) {}

    map(expensesDto: ExpensesDto): Expenses {
        const errors = validateSync(expensesDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new Expenses([], 0);
        }

        const expenses = expensesDto.results.mapNotNull((expenseDto) =>
            this.expenseMapper.map(expenseDto),
        );

        return new Expenses(expenses, expensesDto.count);
    }
}
