import { PaginatedDto } from "@core/data/dto/paginated.dto";
import { Type } from "class-transformer";
import { IsArray, IsNotEmpty, IsNumber, IsString } from "class-validator";

export class ProjectAuditoryDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    certification_entity!: string;

    @IsNumber()
    @IsNotEmpty()
    year!: number;

    @IsNumber()
    @IsNotEmpty()
    audited_project!: number;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class ProjectAuditoriesDto extends PaginatedDto {
    @IsArray()
    @Type(() => ProjectAuditoryDto)
    results!: ProjectAuditoryDto[];
}
