// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bnmRhibeZcHj1pWrptr3 {
  width: 65%;
}`, "",{"version":3,"sources":["webpack://./src/modules/core/presentacion/component/layout/sider-menu/sider-menu.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF","sourcesContent":[".logo {\n  width: 65%;\n}\n"],"sourceRoot":""}]);
// Exports
export var logo = `bnmRhibeZcHj1pWrptr3`;
export default ___CSS_LOADER_EXPORT___;
