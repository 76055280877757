import { RequireEntityGuard } from "@core/presentacion/component/routing/require-entity/require-entity.guard";
import { RequireRoleGuard } from "@core/presentacion/component/routing/require-role/require-role.guard";
import { UserRoleEnum } from "@user/domain/models/user-role.model";
import { lazy } from "react";
import { Route } from "react-router-dom";

const BeneficiaryListPage = lazy(async () =>
    import(
        /* webpackChunkName: "BeneficiaryListPage" */ "@beneficiary/presentation/pages/list/beneficiary-list.page"
    ).then((module) => ({ default: module.BeneficiaryListPage })),
);

const CreateBeneficiaryPage = lazy(async () =>
    import(
        /* webpackChunkName: "CreateBeneficiaryPage" */ "@beneficiary/presentation/pages/create/create-beneficiary.page"
    ).then((module) => ({ default: module.CreateBeneficiaryPage })),
);

const EditBeneficiaryPage = lazy(async () =>
    import(
        /* webpackChunkName: "EditBeneficiaryPage" */ "@beneficiary/presentation/pages/edit/edit-beneficiary.page"
    ).then((module) => ({ default: module.EditBeneficiaryPage })),
);

const BeneficiaryDetailPage = lazy(async () =>
    import(
        /* webpackChunkName: "BeneficiaryDetailPage" */ "@beneficiary/presentation/pages/detail/detail-beneficiary.page"
    ).then((module) => ({ default: module.BeneficiaryDetailPage })),
);

export class BeneficiaryPaths {
    static readonly Root = "beneficiary";

    static readonly Create = "create";

    static readonly EditParam = ":beneficiaryId";
    static readonly Edit = `edit/${BeneficiaryPaths.EditParam}`;

    static readonly DetailParam = ":beneficiaryId";
    static readonly Detail = `detail/${BeneficiaryPaths.DetailParam}`;
}

export class BeneficiaryRoutes {
    static LIST = `/${BeneficiaryPaths.Root}`;

    static CREATE = `/${BeneficiaryPaths.Root}/${BeneficiaryPaths.Create}`;

    static EDIT = (beneficiaryId: number): string =>
        `/${BeneficiaryPaths.Root}/${BeneficiaryPaths.Edit.replace(
            BeneficiaryPaths.EditParam,
            beneficiaryId.toString(),
        )}`;

    static DETAIL = (beneficiaryId: number): string =>
        `/${BeneficiaryPaths.Root}/${BeneficiaryPaths.Detail.replace(
            BeneficiaryPaths.DetailParam,
            beneficiaryId.toString(),
        )}`;
}

export const beneficiaryRoutes = (
    <Route path={BeneficiaryPaths.Root}>
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                        UserRoleEnum.Professional,
                    ]}
                >
                    <BeneficiaryListPage />
                </RequireRoleGuard>
            }
            index
        />
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                        UserRoleEnum.Professional,
                    ]}
                >
                    <RequireEntityGuard redirectTo={BeneficiaryRoutes.LIST}>
                        <CreateBeneficiaryPage />
                    </RequireEntityGuard>
                </RequireRoleGuard>
            }
            path={BeneficiaryPaths.Create}
        />
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                        UserRoleEnum.Professional,
                    ]}
                >
                    <RequireEntityGuard redirectTo={BeneficiaryRoutes.LIST}>
                        <EditBeneficiaryPage />
                    </RequireEntityGuard>
                </RequireRoleGuard>
            }
            path={BeneficiaryPaths.Edit}
        />
        <Route
            element={
                <RequireRoleGuard
                    rolesIn={[
                        UserRoleEnum.SuperAdmin,
                        UserRoleEnum.EntityAdmin,
                        UserRoleEnum.Professional,
                    ]}
                >
                    <BeneficiaryDetailPage />
                </RequireRoleGuard>
            }
            path={BeneficiaryPaths.Detail}
        />
    </Route>
);
