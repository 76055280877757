import { Nullable } from "@core/domain/types/nullable.type";
import { StatutoryTerritorialScopeDto } from "@entity/data/dto/statutory-territorial-scope/statutory-territorial-scope.dto";
import { EntityTerritorialScopeEnumMapper } from "@entity/data/mappers/entity-territorial-scope-enum.mapper";
import { StatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/statutory-territorial-scope.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class StatutoryTerritorialScopeMapper {
    constructor(
        @inject(EntityTerritorialScopeEnumMapper)
        private readonly entityTerritorialScopeEnumMapper: EntityTerritorialScopeEnumMapper,
    ) {}
    map(
        statutoryTerritorialScopeDto: StatutoryTerritorialScopeDto,
    ): Nullable<StatutoryTerritorialScope> {
        const errors = validateSync(statutoryTerritorialScopeDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const scope = this.entityTerritorialScopeEnumMapper.map(
            statutoryTerritorialScopeDto.scope,
        );
        if (!scope) return null;

        return new StatutoryTerritorialScope(
            statutoryTerritorialScopeDto.id,
            statutoryTerritorialScopeDto.name,
            scope,
            statutoryTerritorialScopeDto.entity,
        );
    }

    mapToDto(
        statutoryTerritorialScope: StatutoryTerritorialScope,
    ): StatutoryTerritorialScopeDto {
        const scope = this.entityTerritorialScopeEnumMapper.mapToDto(
            statutoryTerritorialScope.scope,
        );

        const statutoryTerritorialScopeDto: StatutoryTerritorialScopeDto = {
            id: statutoryTerritorialScope.id,
            name: statutoryTerritorialScope.name,
            scope,
            entity: statutoryTerritorialScope.entity,
        };

        return statutoryTerritorialScopeDto;
    }
}
