import { inject, injectable } from "inversify";
import { ProjectExpenseRepository } from "../../../data/repositories/project-expense.repository";
import { ContributionGroupType } from "../../models/expenses/contribution-group-type.model";

@injectable()
export class GetAllContributionGroupTypesUseCase {
    constructor(
        @inject(ProjectExpenseRepository)
        private readonly projectExpenseRepository: ProjectExpenseRepository,
    ) {}

    async execute(): Promise<ContributionGroupType[]> {
        const contributionGroupTypesResult =
            await this.projectExpenseRepository.getAllContributionGroupTypes();

        return contributionGroupTypesResult.getOrElse([]);
    }
}
