import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { Beneficiary } from "@beneficiary/domain/models/beneficiary.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class GetBeneficiaryByIdUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(beneficiaryId: number): Promise<Nullable<Beneficiary>> {
        const beneficiaryResult =
            await this.beneficiaryRepository.findById(beneficiaryId);

        if (beneficiaryResult.isLeft()) {
            ToastManagerStore.error(beneficiaryResult.getLeftOrThrow().message);
        }

        return beneficiaryResult.fold(
            () => null,
            (beneficiary) => beneficiary,
        );
    }
}
