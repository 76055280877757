import { CreateContractTerminationBody } from "@entity/data/dto/employee/contracts/termination/create-contract-termination.body";
import { TerminationReasonEnumMapper } from "@entity/data/mappers/employee/termination-reason-enum.mapper";
import { CreateContractTermination } from "@entity/domain/models/employee/contracts/termination/create-contract-termination.model";
import { ContractTerminationFormValuesValidated } from "@entity/presentation/component/employee-form/contract-termination/form/contract-termination-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreateContractTerminationMapper {
    constructor(
        @inject(TerminationReasonEnumMapper)
        private readonly terminationReasonEnumMapper: TerminationReasonEnumMapper,
    ) {}

    mapToCreateDto(
        createContractTermination: CreateContractTermination,
    ): CreateContractTerminationBody {
        const type = this.terminationReasonEnumMapper.mapToDto(
            createContractTermination.type,
        );

        return {
            start_date: createContractTermination.startDate.toISODate() ?? "",
            reason: createContractTermination.reason,
            type: type,
            contract: createContractTermination.contractId,
            cost: createContractTermination.cost?.toString() ?? undefined,
            total_cost:
                createContractTermination.totalCost?.toString() ?? undefined,
            end_date: createContractTermination.endDate?.toISODate() ?? null,
        };
    }

    mapFromFormValues(
        contractTerminationForm: ContractTerminationFormValuesValidated,
    ): CreateContractTermination {
        const createVolunteerContract = new CreateContractTermination(
            contractTerminationForm.startDate,
            contractTerminationForm.reason,
            contractTerminationForm.terminationReason,
            contractTerminationForm.contractId,
            contractTerminationForm.cost,
            contractTerminationForm.totalCost,
            contractTerminationForm.endDate,
        );

        return createVolunteerContract;
    }
}
