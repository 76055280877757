import { type Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { UserRoleEnum } from "../../domain/models/user-role.model";
import { UserRoleEnumDto } from "../dto/user-role.dto";

@injectable()
export class UserRoleEnumMapper {
    map(userRoleEnumDto: UserRoleEnumDto): Nullable<UserRoleEnum> {
        switch (userRoleEnumDto) {
            case UserRoleEnumDto.SuperAdmin:
                return UserRoleEnum.SuperAdmin;
            case UserRoleEnumDto.EntityAdmin:
                return UserRoleEnum.EntityAdmin;
            case UserRoleEnumDto.Professional:
                return UserRoleEnum.Professional;
            default:
                return null;
        }
    }

    mapToDto(userRoleEnum: UserRoleEnum): UserRoleEnumDto {
        // eslint-disable-next-line default-case
        switch (userRoleEnum) {
            case UserRoleEnum.SuperAdmin:
                return UserRoleEnumDto.SuperAdmin;
            case UserRoleEnum.EntityAdmin:
                return UserRoleEnumDto.EntityAdmin;
            case UserRoleEnum.Professional:
                return UserRoleEnumDto.Professional;
        }
    }
}
