import { Container } from "inversify";
import { DiSubmodule } from "./di-submodule";

export class DiModuleBuilder {
    get module(): Container {
        return this._module;
    }

    get subModules(): DiSubmodule {
        return this._subModules;
    }

    private _subModules: DiSubmodule = {};

    private _module: Container = new Container();

    registerSubModules(register: () => DiSubmodule): void {
        this._subModules = register();
    }

    registerModule(): void {
        const module: Container = new Container();

        if (!Boolean(Object.keys(this.subModules).length)) {
            throw new Error(
                "No se han registrado submodulos, comprueba que haya y que se haya ejecutado la funcion registerSubmodules antes",
            );
        }

        Object.values(this.subModules).forEach((subModule) => {
            module.load(subModule);
        });

        this._module = module;
    }
}
