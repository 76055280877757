import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { RegisteredRecordRepository } from "@entity/data/repositories/registered-record.repository";
import { CreateRegisteredRecord } from "@entity/domain/models/registered-record/create-registered-record.model";
import { RegisteredRecord } from "@entity/domain/models/registered-record/registered-record.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateRegisteredRecordUseCase {
    constructor(
        @inject(RegisteredRecordRepository)
        private readonly registeredRecordRepository: RegisteredRecordRepository,
    ) {}

    async execute(
        registeredRecord: CreateRegisteredRecord,
    ): Promise<Nullable<RegisteredRecord>> {
        const creationResult =
            await this.registeredRecordRepository.create(registeredRecord);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdRegisteredRecord) => createdRegisteredRecord,
        );
    }
}
