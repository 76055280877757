import { CreatePartnersNumber } from "@entity/domain/models/partners-number/create-partners-number.model";
import { PartnersNumber } from "@entity/domain/models/partners-number/partners-number.model";

export class EditPartnersNumber extends CreatePartnersNumber {
    constructor(
        public readonly id: PartnersNumber["id"],
        public override numberOfMembers: CreatePartnersNumber["numberOfMembers"],
        public override registeredCcaa: CreatePartnersNumber["registeredCcaa"],
        public override entity: CreatePartnersNumber["entity"],
    ) {
        super(numberOfMembers, registeredCcaa, entity);
    }
}
