import { Pagination } from "@core/domain/models/pagination";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import type { DepositSearchFilters } from "@entity/domain/models/deposit-search-filters";
import { DepositGeneralTypes } from "@entity/domain/models/deposit-type.model";
import { Deposit } from "@entity/domain/models/deposit.model";
import { IdentificationType } from "@entity/domain/models/identification-type.model";
import { CreateDepositUseCase } from "@entity/domain/usecases/create-deposit.usecase";
import { DeleteDepositUseCase } from "@entity/domain/usecases/delete-deposit-usecase";
import { EditDepositUseCase } from "@entity/domain/usecases/edit-deposit.usecase";
import { ExportProjectDepositsUseCase } from "@entity/domain/usecases/export-project-deposits.usecase";
import { GetAllDepositTypesUseCase } from "@entity/domain/usecases/get-all-deposit-types.usecase";
import { GetAllDepositsUseCase } from "@entity/domain/usecases/get-all-deposit.usecase";
import { GetAllIdentificationTypesUseCase } from "@entity/domain/usecases/get-all-identification-types.usecase";
import { SearchDepositsByUseCase } from "@entity/domain/usecases/search-deposit-by.usecase";
import { GlobalProject } from "@project/domain/models/global-project.model";
import { GetAllGlobalProjectsByUseCase } from "@project/domain/usecases/get-all-global-projects-by.usecase";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { ExportProjectDepositFormValuesValidated } from "./export-project-deposits-modal/export-project-deposit-modal-form.component";
import { DepositFormValuesValidated } from "./form/deposit.form";

export interface DepositTable {
    id: number;
    concept: string;
    type: string;
    depositDate: DateTime | null;
    entityId: number;
}

export interface DepositsTable {
    deposits: DepositTable[];
    count: number;
}

@injectable()
export class DepositViewModel extends BaseViewModel {
    constructor(
        @inject(GetAllDepositTypesUseCase)
        private readonly getAllDepositTypesUseCase: GetAllDepositTypesUseCase,
        @inject(GetAllGlobalProjectsByUseCase)
        private readonly getAllGlobalProjectsByUseCase: GetAllGlobalProjectsByUseCase,
        @inject(GetAllIdentificationTypesUseCase)
        private readonly getAllIdentificationTypesUseCase: GetAllIdentificationTypesUseCase,
        @inject(CreateDepositUseCase)
        private readonly createDepositUseCase: CreateDepositUseCase,
        @inject(GetAllDepositsUseCase)
        private readonly getAllDepositsUseCase: GetAllDepositsUseCase,
        @inject(EditDepositUseCase)
        private readonly editDepositUseCase: EditDepositUseCase,
        @inject(DeleteDepositUseCase)
        private readonly deleteDepositUseCase: DeleteDepositUseCase,
        @inject(SearchDepositsByUseCase)
        private readonly searchDepositsByUseCase: SearchDepositsByUseCase,
        @inject(ExportProjectDepositsUseCase)
        private readonly exportProjectDepositsUseCase: ExportProjectDepositsUseCase,
    ) {
        super();
        makeObservable(this);
    }
    @observable
    _entityId: Undefinable<number> = undefined;

    @observable
    initialLoading = true;

    @observable
    _deposits: Deposit[] = [];

    @observable
    addDepositModalOpen = false;

    @observable
    editDepositModalOpen = false;

    @observable
    showDepositModalOpen = false;

    @observable
    depositIdToEdit: Undefinable<number> = undefined;

    @observable
    showExportProjectDepositModal = false;

    @observable
    depositToShow: Undefinable<Deposit> = undefined;

    @observable
    depositTypes: DepositGeneralTypes = new DepositGeneralTypes([], [], [], []);

    @observable
    identificationTypes: IdentificationType[] = [];

    @observable
    projects: GlobalProject[] = [];

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: DepositSearchFilters = {
        name: "",
        type: null,
        depositDateFrom: null,
        depositDateTo: null,
    };

    @observable
    filters: DepositSearchFilters = this.initialFiltersValue;

    @computed
    get depositsTable(): DepositsTable {
        return {
            deposits: this._deposits.map<DepositTable>((depositSummary) => ({
                id: depositSummary.id,
                concept: depositSummary.concept,
                type:
                    this.depositTypes.types.find(
                        (type) => type.id === depositSummary.type,
                    )?.label ?? "",
                depositDate: DateTime.fromISO(depositSummary.depositDate),
                entityId: depositSummary.entity,
            })),
            count: this._deposits.length,
        };
    }

    @action
    setEntityId(entityId: number): void {
        this._entityId = entityId;
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.getAllDeposit();
        this.getDepositTypes();
    }

    @action
    setFilters(filters: DepositSearchFilters): void {
        this.filters = filters;
        this.pagination.reset();

        this.searchDepositsBy(this.filters, this.pagination);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    private async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllDeposit(),
            this.getDepositTypes(),
            this.getAllProjects(),
            this.getIdentificationTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllDeposit(): Promise<void> {
        const deposits = await this.getAllDepositsUseCase.execute();
        runInAction(() => {
            this._deposits = deposits;
        });
    }

    async getDepositTypes(): Promise<void> {
        const depositTypes = await this.getAllDepositTypesUseCase.execute();
        runInAction(() => {
            this.depositTypes = depositTypes;
        });
    }

    async getIdentificationTypes(): Promise<void> {
        const identificationTypes =
            await this.getAllIdentificationTypesUseCase.execute();

        runInAction(() => {
            this.identificationTypes = identificationTypes;
        });
    }

    async getAllProjects(): Promise<void> {
        const globalProjects = await this.getAllGlobalProjectsByUseCase.execute(
            {
                entityId: this._entityId,
            },
        );
        runInAction(() => {
            this.projects = globalProjects;
        });
    }

    @action.bound
    async reloadDeposit(): Promise<void> {
        await this.getAllDeposit();
    }

    @action
    setAddDepositModalOpen(open: boolean): void {
        this.addDepositModalOpen = open;
    }

    @action
    closeEditDepositModal(): void {
        this.editDepositModalOpen = false;
    }

    @action
    setShowExportProjectDepositModal(open: boolean): void {
        this.showExportProjectDepositModal = open;
    }

    @action
    closeShowExportProjectDepositModal(): void {
        this.showExportProjectDepositModal = false;
    }

    @action
    closeShowDepositModal(): void {
        this.showDepositModalOpen = false;
    }

    @action
    openEditDepositModal(depositId: number): void {
        this.depositIdToEdit = depositId;
        this.editDepositModalOpen = true;
    }

    @action
    openShowDepositModal(depositId: number): void {
        this.depositToShow = this._deposits.find(
            (deposit) => deposit.id === depositId,
        );
        this.showDepositModalOpen = true;
    }

    async addDeposit(depositToAdd: DepositFormValuesValidated): Promise<void> {
        LoadLayoutStore.start();
        await this.createDepositUseCase.execute(depositToAdd);
        ToastManagerStore.success();
        LoadLayoutStore.finish();
    }

    async editDeposit(
        depositToEdit: DepositFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();
        await this.editDepositUseCase.execute(depositToEdit);
        ToastManagerStore.success();
        LoadLayoutStore.finish();
    }

    async deleteDeposit(depositId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteDepositUseCase.execute(depositId);
        ToastManagerStore.success();
        LoadLayoutStore.finish();
    }

    async searchDepositsBy(
        filters: DepositSearchFilters,
        pagination: Pagination,
    ): Promise<void> {
        const _deposits = await this.searchDepositsByUseCase.execute(
            pagination,
            filters,
        );
        runInAction(() => {
            this._deposits = _deposits;
        });
    }

    async exportDeposits(
        values: ExportProjectDepositFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();

        const blob = await this.exportProjectDepositsUseCase.execute(values);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Ingresos.csv");
        document.body.appendChild(link);
        link.click();

        ToastManagerStore.success();
        this.showExportProjectDepositModal = false;

        LoadLayoutStore.finish();
    }
}
