import type { Undefinable } from "@core/domain/types/undefinable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Project } from "@project/domain/models/project.model";
import { GetProjectByIdUseCase } from "@project/domain/usecases/get-project-by-id.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class DetailProjectPageViewModel extends BaseViewModel {
    projectId: Undefinable<number> = undefined;

    @observable
    loading = true;

    @observable
    project: Undefinable<Project> = undefined;

    constructor(
        @inject(GetProjectByIdUseCase)
        private readonly getProjectByIdUseCase: GetProjectByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getProject();

        runInAction(() => {
            this.loading = false;
        });
    }
    setProjectId(projectId: number): void {
        this.projectId = projectId;
    }
    async getProject(): Promise<void> {
        if (this.projectId === undefined) throw Error("Missing projectId");

        const project = await this.getProjectByIdUseCase.execute(
            this.projectId,
        );

        if (project) {
            this.project = project;
        }
    }
}
