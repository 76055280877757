import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { Nullish } from "@core/domain/types/nullish.type";
import { Type } from "class-transformer";
import { IsArray } from "class-validator";
import { MaterialTypeEnumDto } from "./material-type-enum.dto";
import { MaterialDto } from "./material.dto";

export class MaterialsDto extends PaginatedDto {
    @IsArray()
    @Type(() => MaterialDto)
    results!: MaterialDto[];
}

export type MaterialsSummaryQuery = PaginatedQuery & {
    search?: Nullish<string>;
    type?: Nullish<MaterialTypeEnumDto>;
    start_date?: Nullish<string>;
    end_date?: Nullish<string>;
    entities?: Nullish<string>;
};
