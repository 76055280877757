import { IdentificationDocumentTypeEnum } from "@core/domain/models/identification-document-type.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsEmail,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class ContactDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsNumber()
    @IsOptional()
    entity!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsEmail()
    @IsNotEmpty()
    email!: string;

    @IsString()
    @IsOptional()
    web!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    phone1!: string;

    @IsString()
    @IsOptional()
    phone2!: Nullable<string>;

    @IsString()
    @IsOptional()
    observations!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    is_legal_representative!: Nullable<boolean>;

    @IsEnum(IdentificationDocumentTypeEnum)
    @IsOptional()
    identification_type!: Nullable<IdentificationDocumentTypeEnum>;

    @IsString()
    @IsOptional()
    identification!: Nullable<string>;
}

export class CreateContactDto {
    @IsNumber()
    @IsNotEmpty()
    entity!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsEmail()
    @IsNotEmpty()
    email!: string;

    @IsString()
    @IsOptional()
    web!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    phone1!: string;

    @IsString()
    @IsOptional()
    phone2!: Nullable<string>;

    @IsString()
    @IsOptional()
    observations!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    is_legal_representative!: Nullable<boolean>;

    @IsEnum(IdentificationDocumentTypeEnum)
    @IsOptional()
    identification_type!: Nullable<IdentificationDocumentTypeEnum>;

    @IsString()
    @IsOptional()
    identification!: Nullable<string>;
}

export class ContactResultDto extends ContactDto {
    @IsArray()
    @Type(() => ContactDto)
    results!: ContactDto[];
}
