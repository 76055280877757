import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ProjectFinancialEntityRepository } from "@project/data/repositories/project-financial-entity.repository";
import { inject, injectable } from "inversify";

@injectable()
export class ExportFinancialEntityUseCase {
    constructor(
        @inject(ProjectFinancialEntityRepository)
        private readonly projectFinancialEntityRepository: ProjectFinancialEntityRepository,
    ) {}

    async execute(entityId: number): Promise<Blob> {
        const financialEntityResult =
            await this.projectFinancialEntityRepository.exportEntity(entityId);

        if (financialEntityResult.isLeft()) {
            ToastManagerStore.error(
                financialEntityResult.getLeftOrThrow().message,
            );
        }

        return financialEntityResult.getOrThrow();
    }
}
