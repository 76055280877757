import { EmployeeRepository } from "@entity/data/repositories/employee.repository";
import { DisabilityType } from "@entity/domain/models/employee/disability-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllDisabilityTypesUseCase {
    constructor(
        @inject(EmployeeRepository)
        private readonly employeeRepository: EmployeeRepository,
    ) {}

    async execute(): Promise<DisabilityType[]> {
        const disabilitiesResult =
            await this.employeeRepository.getAllDisabilityTypes();

        return disabilitiesResult.fold(
            () => [],
            (disabilities) => disabilities,
        );
    }
}
