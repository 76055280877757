import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { ProjectFinancialEntities } from "../../domain/models/project-financial-entities.model";
import { ProjectFinancialEntitiesDto } from "../dto/project-financial-entities.dto";
import { ProjectFinancialEntityMapper } from "./project-financial-entity.mapper";

@injectable()
export class ProjectFinancialEntitiesMapper {
    constructor(
        @inject(ProjectFinancialEntityMapper)
        private readonly projectFinancialEntityMapper: ProjectFinancialEntityMapper,
    ) {}

    map(
        projectFinancialEntitiesDto: ProjectFinancialEntitiesDto,
    ): ProjectFinancialEntities {
        const errors = validateSync(projectFinancialEntitiesDto);

        if (errors.length) {
            console.error(errors);

            return new ProjectFinancialEntities([], 0);
        }

        const projectFinancialEntities =
            projectFinancialEntitiesDto.results.mapNotNull(
                (projectFinancialEntityDto) =>
                    this.projectFinancialEntityMapper.map(
                        projectFinancialEntityDto,
                    ),
            );

        return new ProjectFinancialEntities(
            projectFinancialEntities,
            projectFinancialEntitiesDto.count,
        );
    }
}
