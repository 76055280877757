import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { CreateTravelExpenseBody } from "@project/data/dto/expenses/create-expense.body";
import { EditTravelExpenseBody } from "@project/data/dto/expenses/edit-expense.body";
import { ExpenseTypeEnumDto } from "@project/data/dto/expenses/expense-type.dto";
import { ExpenseDto } from "@project/data/dto/expenses/expenses.dto";
import { TravelDto } from "@project/data/dto/expenses/travel.dto";
import { TransportTypeEnumMapper } from "@project/data/mappers/expenses/transport-type-enum.mapper";
import { CreateTravelExpense } from "@project/domain/models/expenses/create-expense.model";
import { EditTravelExpense } from "@project/domain/models/expenses/edit-expense.model";
import { ExpenseTypeEnum } from "@project/domain/models/expenses/expense-type.model";
import { TravelExpense } from "@project/domain/models/expenses/expense.model";
import { TransportTypeEnum } from "@project/domain/models/expenses/transport-type.model";
import { ExpenseTravelFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/travels/expense-travel-form.component";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

const maximumDecimalsNumber = 2;

@injectable()
export class TravelExpenseMapper {
    constructor(
        @inject(TransportTypeEnumMapper)
        private readonly transportTypeEnumMapper: TransportTypeEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
    ) {}

    map(
        expenseDto: ExpenseDto,
        travelData: TravelDto,
    ): Nullable<TravelExpense> {
        const errors = validateSync(travelData);

        if (errors.length > 0) {
            console.error(errors);
            return null;
        }

        const transportType = this.transportTypeEnumMapper.map(
            travelData.transportation_method,
        );

        const documents: IncDocument[] = [];
        if (expenseDto.documents_data) {
            expenseDto.documents_data.forEach((document) => {
                const documentMapped = this.documentMapper.map(document);
                if (documentMapped) {
                    documents.push(documentMapped);
                }
            });
        }

        return new TravelExpense(
            expenseDto.id,
            "",
            travelData.identification_document,
            travelData.full_name,
            travelData.address,
            travelData.position_function,
            travelData.travel_destination,
            travelData.date_departure
                ? DateTime.fromISO(travelData.date_departure)
                : null,
            travelData.date_return
                ? DateTime.fromISO(travelData.date_return)
                : null,
            transportType ?? TransportTypeEnum.NONE,
            travelData.other_transport,
            travelData.amount ? parseFloat(travelData.amount) : 0,
            travelData.kilometers ? parseFloat(travelData.kilometers) : 0,
            travelData.cost_per_kilometer
                ? parseFloat(travelData.cost_per_kilometer)
                : null,
            travelData.project_allocation_percentage
                ? parseFloat(travelData.project_allocation_percentage)
                : null,
            travelData.amount_allocated_to_project
                ? parseFloat(travelData.amount_allocated_to_project)
                : null,
            travelData.payment_date
                ? DateTime.fromISO(travelData.payment_date)
                : null,
            travelData.travel_destination ?? "",
            documents,
            expenseDto.project,
        );
    }

    mapFromFormValues(
        createFormValues: ExpenseTravelFormValuesValidated,
        projectId: number,
    ): CreateTravelExpense {
        return new CreateTravelExpense(
            projectId,
            ExpenseTypeEnum.TRAVEL,
            createFormValues.dni,
            createFormValues.name,
            createFormValues.address,
            createFormValues.position,
            createFormValues.concept ?? "",
            createFormValues.startDate,
            createFormValues.endDate,
            createFormValues.transport ?? TransportTypeEnum.NONE,
            createFormValues.otherTransport ?? "",
            createFormValues.totalExpense,
            createFormValues.kms,
            createFormValues.expensePerKms,
            createFormValues.percentageImputation,
            createFormValues.amountImputation,
            createFormValues.paymentDate,
            createFormValues.documents.map((document) => document.id),
            createFormValues.concept,
        );
    }

    mapFromFormEditValues(
        editFormValues: ExpenseTravelFormValuesValidated,
        expenseId: number,
        projectId: number,
    ): EditTravelExpense {
        return new EditTravelExpense(
            expenseId,
            projectId,
            ExpenseTypeEnum.TRAVEL,
            editFormValues.dni,
            editFormValues.name,
            editFormValues.address,
            editFormValues.position,
            editFormValues.concept ?? "",
            editFormValues.startDate,
            editFormValues.endDate,
            editFormValues.transport ?? TransportTypeEnum.NONE,
            editFormValues.otherTransport ?? "",
            editFormValues.totalExpense,
            editFormValues.kms,
            editFormValues.expensePerKms,
            editFormValues.percentageImputation,
            editFormValues.amountImputation,
            editFormValues.paymentDate,
            editFormValues.documents.map((document) => document.id),
            editFormValues.concept,
        );
    }

    mapToDto(createExpense: CreateTravelExpense): CreateTravelExpenseBody {
        const transport = this.transportTypeEnumMapper.mapToDto(
            createExpense.transport,
        );

        const percentageImputation =
            createExpense.percentageImputation?.toFixed(maximumDecimalsNumber);

        return {
            project: createExpense.projectId,
            type_expense: ExpenseTypeEnumDto.TRAVEL,
            amount_allocated_to_project: Number(
                createExpense.amountImputation?.toPrecision(
                    maximumDecimalsNumber,
                ),
            ),
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: createExpense.documents,
            full_name: createExpense.name ?? "",
            identification_document: createExpense.dni ?? "",
            address: createExpense.address ?? "",
            position_function: createExpense.positionFunction ?? "",
            travel_destination: createExpense.travelDestination ?? "",
            transportation_method: transport,
            date_departure: createExpense.dateDeparture?.toISODate() ?? "",
            date_return: createExpense.dateReturn?.toISODate() ?? "",
            cost_per_kilometer:
                createExpense.costPerKilometer?.toString() ?? "0",
            kilometers: createExpense.kilometers?.toString() ?? "0",
            amount: createExpense.amount?.toString() ?? "0",
            payment_date: createExpense.paymentDate?.toISODate() ?? "",
            other_transport: createExpense.otherTransport ?? "",
        };
    }

    mapToEditDto(editExpense: EditTravelExpense): EditTravelExpenseBody {
        const transport = this.transportTypeEnumMapper.mapToDto(
            editExpense.transport,
        );

        const percentageImputation = editExpense.percentageImputation?.toFixed(
            maximumDecimalsNumber,
        );

        return {
            id: editExpense.id,
            project: editExpense.projectId,
            type_expense: ExpenseTypeEnumDto.TRAVEL,
            amount_allocated_to_project: editExpense.amountImputation ?? 0,
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: editExpense.documents,
            full_name: editExpense.name ?? "",
            identification_document: editExpense.dni ?? "",
            address: editExpense.address ?? "",
            position_function: editExpense.positionFunction ?? "",
            travel_destination: editExpense.travelDestination ?? "",
            transportation_method: transport,
            date_departure: editExpense.dateDeparture?.toISODate() ?? "",
            date_return: editExpense.dateReturn?.toISODate() ?? "",
            cost_per_kilometer: editExpense.costPerKilometer?.toString() ?? "0",
            kilometers: editExpense.kilometers?.toString() ?? "0",
            amount: editExpense.amount?.toString() ?? "0",
            payment_date: editExpense.paymentDate?.toISODate() ?? "",
            other_transport: editExpense.otherTransport ?? "",
        };
    }
}
