import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { EditBudgetCategoryConcept } from "@project/domain/models/budget/budget-category/concepts/edit-budget-category-concept.model";
import { BudgetCategoryConcept } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditCategoryConceptUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(
        concept: EditBudgetCategoryConcept,
    ): Promise<Nullable<BudgetCategoryConcept>> {
        const editResult =
            await this.budgetRepository.editCategoryConcept(concept);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }
        return editResult.fold(
            () => null,
            (editedConcept) => editedConcept,
        );
    }
}
