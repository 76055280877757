import { CollectiveRepository } from "@entity/data/repositories/collective.repository";
import { Collective } from "@entity/domain/models/collective.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllCollectivesUseCase {
    constructor(
        @inject(CollectiveRepository)
        private readonly collectiveRepository: CollectiveRepository,
    ) {}

    async execute(): Promise<Collective[]> {
        const collectivesResult = await this.collectiveRepository.getAll();

        return collectivesResult.getOrElse([]);
    }
}
