import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { CreatePolicyProtocolBody } from "@entity/data/dto/quality-framework/policy-protocol/create-policy-protocol.body";
import { PolicyProtocolTypeEnumMapper } from "@entity/data/mappers/policy-protocol-type-enum.mapper";
import { CreatePolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/create-policy-protocol.model";
import { PolicyProtocolFormValuesValidated } from "@entity/presentation/component/entity-form/quality-framework/policy-protocol/form/policy-protocol-form";
import { inject, injectable } from "inversify";

@injectable()
export class CreatePolicyProtocolMapper {
    constructor(
        @inject(PolicyProtocolTypeEnumMapper)
        private readonly policyProtocolTypeEnumMapper: PolicyProtocolTypeEnumMapper,
    ) {}
    mapToCreateDto(
        createPolicyProtocol: CreatePolicyProtocol,
    ): CreatePolicyProtocolBody {
        const policies_protocols = createPolicyProtocol.policiesProtocols.map(
            (policiesProtocolEnum) =>
                this.policyProtocolTypeEnumMapper.mapToDto(
                    policiesProtocolEnum,
                ),
        );

        const policyProtocolDto: CreatePolicyProtocolBody = {
            name: createPolicyProtocol.name,
            policies_protocols,
            realization_year: createPolicyProtocol.realizationYear,
            entity: createPolicyProtocol.entity,
            other_policies_protocols: convertEmptyToUndefined(
                createPolicyProtocol.otherPoliciesProtocols,
            ),
            expiration_date: createPolicyProtocol.expirationDate
                ? createPolicyProtocol.expirationDate.toISODate()
                : undefined,
        };

        return policyProtocolDto;
    }

    mapFromFormValues(
        entityId: number,
        policyProtocol: PolicyProtocolFormValuesValidated,
    ): CreatePolicyProtocol {
        const createPolicyProtocol: CreatePolicyProtocol =
            new CreatePolicyProtocol(
                policyProtocol.name,
                policyProtocol.policiesProtocols,
                policyProtocol.realizationYear.year,
                entityId,
                convertEmptyToUndefined(policyProtocol.otherPoliciesProtocols),
                convertEmptyToUndefined(policyProtocol.expirationDate),
            );

        return createPolicyProtocol;
    }
}
