import { Nullable } from "@core/domain/types/nullable.type";
import { MaterialSubtypeEnumDto } from "@entity/data/dto/material/material-subtype-enum.dto";
import { MaterialSubtypeEnum } from "@entity/domain/models/material/material-subtype.model";
import { injectable } from "inversify";

@injectable()
export class MaterialSubtypeEnumMapper {
    map(
        materialSubtypeEnumDto: MaterialSubtypeEnumDto,
    ): Nullable<MaterialSubtypeEnum> {
        switch (materialSubtypeEnumDto) {
            case MaterialSubtypeEnumDto.Personal:
                return MaterialSubtypeEnum.Personal;
            case MaterialSubtypeEnumDto.Professional:
                return MaterialSubtypeEnum.Professional;
            case MaterialSubtypeEnumDto.Volunteering:
                return MaterialSubtypeEnum.Volunteering;
            case MaterialSubtypeEnumDto.Locals:
                return MaterialSubtypeEnum.Locals;
            case MaterialSubtypeEnumDto.Supplies:
                return MaterialSubtypeEnum.Supplies;
            case MaterialSubtypeEnumDto.Phone:
                return MaterialSubtypeEnum.Phone;
            case MaterialSubtypeEnumDto.Furniture:
                return MaterialSubtypeEnum.Furniture;
            case MaterialSubtypeEnumDto.FungibleMaterial:
                return MaterialSubtypeEnum.FungibleMaterial;
            case MaterialSubtypeEnumDto.SanitaryMaterial:
                return MaterialSubtypeEnum.SanitaryMaterial;
            case MaterialSubtypeEnumDto.Insurance:
                return MaterialSubtypeEnum.Insurance;
            case MaterialSubtypeEnumDto.Vehicles:
                return MaterialSubtypeEnum.Vehicles;
            case MaterialSubtypeEnumDto.ManagementProcedures:
                return MaterialSubtypeEnum.ManagementProcedures;
            case MaterialSubtypeEnumDto.ISOS:
                return MaterialSubtypeEnum.ISOS;
            case MaterialSubtypeEnumDto.Quality:
                return MaterialSubtypeEnum.Quality;
            case MaterialSubtypeEnumDto.Protocols:
                return MaterialSubtypeEnum.Protocols;
            case MaterialSubtypeEnumDto.Plans:
                return MaterialSubtypeEnum.Plans;
            case MaterialSubtypeEnumDto.ComputerMaterial:
                return MaterialSubtypeEnum.ComputerMaterial;
            case MaterialSubtypeEnumDto.ComputerSystems:
                return MaterialSubtypeEnum.ComputerSystems;
            case MaterialSubtypeEnumDto.LicensesSoftware:
                return MaterialSubtypeEnum.LicensesSoftware;
            case MaterialSubtypeEnumDto.Platforms:
                return MaterialSubtypeEnum.Platforms;
            case MaterialSubtypeEnumDto.SocialNetworks:
                return MaterialSubtypeEnum.SocialNetworks;
            case MaterialSubtypeEnumDto.Newsletter:
                return MaterialSubtypeEnum.Newsletter;
            case MaterialSubtypeEnumDto.Others:
                return MaterialSubtypeEnum.Others;
            default:
                return null;
        }
    }

    mapToDto(materialSubtypeEnum: MaterialSubtypeEnum): MaterialSubtypeEnumDto {
        // eslint-disable-next-line default-case
        switch (materialSubtypeEnum) {
            case MaterialSubtypeEnum.Personal:
                return MaterialSubtypeEnumDto.Personal;
            case MaterialSubtypeEnum.Professional:
                return MaterialSubtypeEnumDto.Professional;
            case MaterialSubtypeEnum.Volunteering:
                return MaterialSubtypeEnumDto.Volunteering;
            case MaterialSubtypeEnum.Locals:
                return MaterialSubtypeEnumDto.Locals;
            case MaterialSubtypeEnum.Supplies:
                return MaterialSubtypeEnumDto.Supplies;
            case MaterialSubtypeEnum.Phone:
                return MaterialSubtypeEnumDto.Phone;
            case MaterialSubtypeEnum.Furniture:
                return MaterialSubtypeEnumDto.Furniture;
            case MaterialSubtypeEnum.FungibleMaterial:
                return MaterialSubtypeEnumDto.FungibleMaterial;
            case MaterialSubtypeEnum.SanitaryMaterial:
                return MaterialSubtypeEnumDto.SanitaryMaterial;
            case MaterialSubtypeEnum.Insurance:
                return MaterialSubtypeEnumDto.Insurance;
            case MaterialSubtypeEnum.Vehicles:
                return MaterialSubtypeEnumDto.Vehicles;
            case MaterialSubtypeEnum.ManagementProcedures:
                return MaterialSubtypeEnumDto.ManagementProcedures;
            case MaterialSubtypeEnum.ISOS:
                return MaterialSubtypeEnumDto.ISOS;
            case MaterialSubtypeEnum.Quality:
                return MaterialSubtypeEnumDto.Quality;
            case MaterialSubtypeEnum.Protocols:
                return MaterialSubtypeEnumDto.Protocols;
            case MaterialSubtypeEnum.Plans:
                return MaterialSubtypeEnumDto.Plans;
            case MaterialSubtypeEnum.ComputerMaterial:
                return MaterialSubtypeEnumDto.ComputerMaterial;
            case MaterialSubtypeEnum.ComputerSystems:
                return MaterialSubtypeEnumDto.ComputerSystems;
            case MaterialSubtypeEnum.LicensesSoftware:
                return MaterialSubtypeEnumDto.LicensesSoftware;
            case MaterialSubtypeEnum.Platforms:
                return MaterialSubtypeEnumDto.Platforms;
            case MaterialSubtypeEnum.SocialNetworks:
                return MaterialSubtypeEnumDto.SocialNetworks;
            case MaterialSubtypeEnum.Newsletter:
                return MaterialSubtypeEnumDto.Newsletter;
            case MaterialSubtypeEnum.Others:
                return MaterialSubtypeEnumDto.Others;
        }
    }
}
