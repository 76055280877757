import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { UserRepository } from "../../data/repositories/user.repository";
import { User } from "../models/user.model";

@injectable()
export class GetUserByIdUseCase {
    constructor(
        @inject(UserRepository)
        private readonly userRepository: UserRepository,
    ) {}

    async execute(userId: number): Promise<Nullable<User>> {
        const userResult = await this.userRepository.getById(userId);

        if (userResult.isLeft()) {
            ToastManagerStore.error(userResult.getLeftOrThrow().message);
        }

        return userResult.fold(
            () => null,
            (user) => user,
        );
    }
}
