import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { QualityCertificateRepository } from "@entity/data/repositories/quality-certificate.repository";
import { EditQualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/edit-quality-certificate.model";
import { QualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/quality-certificate.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditQualityCertificateUseCase {
    constructor(
        @inject(QualityCertificateRepository)
        private readonly qualityCertificateRepository: QualityCertificateRepository,
    ) {}

    async execute(
        qualityCertificate: EditQualityCertificate,
    ): Promise<Nullable<QualityCertificate>> {
        const editResult =
            await this.qualityCertificateRepository.edit(qualityCertificate);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedQualityCertificate) => editedQualityCertificate,
        );
    }
}
