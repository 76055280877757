import { CreatePublicInterestBody } from "@entity/data/dto/public-interest/create-public-interest.body";
import { CreatePublicInterest } from "@entity/domain/models/public-interest/create-public-interest.model";
import { PublicInterestFormValuesValidated } from "@entity/presentation/component/entity-form/general/public-interest/form/public-interest-form";
import { injectable } from "inversify";

@injectable()
export class CreatePublicInterestMapper {
    mapToCreateDto(
        createPublicInterest: CreatePublicInterest,
    ): CreatePublicInterestBody {
        const publicInterestDto: CreatePublicInterestBody = {
            organization_type: createPublicInterest.organizationType,
            entity: createPublicInterest.entity,
            date: createPublicInterest.date.toISODate() ?? "",
        };

        return publicInterestDto;
    }

    mapFromFormValues(
        entityId: number,
        publicInterest: PublicInterestFormValuesValidated,
    ): CreatePublicInterest {
        const createPublicInterest: CreatePublicInterest =
            new CreatePublicInterest(
                publicInterest.organizationType,
                entityId,
                publicInterest.date,
            );

        return createPublicInterest;
    }
}
