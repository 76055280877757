import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { TechnicalProposalRepository } from "@project/data/repositories/technical-proposal/technical-proposal.repository";
import {
    CreateTechnicalProposal,
    TechnicalProposal,
} from "@project/domain/models/technical-proposals.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateTechnicalProposalUseCase {
    constructor(
        @inject(TechnicalProposalRepository)
        private readonly technicalProposalRepository: TechnicalProposalRepository,
    ) {}

    async execute(
        technicalProposal: CreateTechnicalProposal,
    ): Promise<Nullable<TechnicalProposal>> {
        const creationResult =
            await this.technicalProposalRepository.create(technicalProposal);

        if (creationResult.isLeft()) {
            ToastManagerStore.error(creationResult.getLeftOrThrow().message);
        }

        return creationResult.fold(
            () => null,
            (createdTechnicalProposal) => createdTechnicalProposal,
        );
    }
}
