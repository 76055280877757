import { coreTypes } from "@core/core-types.di";
import { PaginatedQueryDto } from "@core/data/dto/paginated.dto";
import type { Http } from "@core/data/infrastructures/http/http";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import { CollectiveDto } from "@entity/data/dto/collectives.dto";
import { CollectivesMapper } from "@entity/data/mappers/collectives.mapper";
import { Collective } from "@entity/domain/models/collective.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class CollectiveDatasource {
    constructor(
        @inject(CollectivesMapper)
        private readonly collectivesMapper: CollectivesMapper,
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
    ) {}
    async fetchAllPaginated(
        limit?: number,
        offset?: number,
    ): Promise<Either<FallbackError, Collective[]>> {
        const query: PaginatedQueryDto = {
            limit,
            offset,
        };
        const collectivesResult = await this.http.get<CollectiveDto[]>(
            "/entities/collectives/",
            {
                query,
            },
        );

        return collectivesResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((collectiveDto) =>
                    this.collectivesMapper.map(
                        plainToClass(CollectiveDto, collectiveDto),
                    ),
                ),
            );
    }
}
