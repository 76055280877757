import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { QualityCertificateDatasource } from "@entity/data/datasources/quality-certificate.datasource";
import { QualityCertificateType } from "@entity/domain/models/quality-certificate-type.model";
import { CreateQualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/create-quality-certificate.model";
import { EditQualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/edit-quality-certificate.model";
import {
    QualityCertificate,
    QualityCertificates,
} from "@entity/domain/models/quality-framework/quality-certificate/quality-certificate.model";
import { inject, injectable } from "inversify";

@injectable()
export class QualityCertificateRepository {
    constructor(
        @inject(QualityCertificateDatasource)
        private readonly qualityCertificateDatasource: QualityCertificateDatasource,
    ) {}

    async getAll(
        pagination: Pagination,
    ): Promise<Either<FallbackError, QualityCertificates>> {
        const qualityCertificateResult =
            await this.qualityCertificateDatasource.fetchAll(pagination);

        return qualityCertificateResult.mapLeft(() => new FallbackError());
    }

    async create(
        qualityCertificate: CreateQualityCertificate,
    ): Promise<Either<ValidationError | FallbackError, QualityCertificate>> {
        return this.qualityCertificateDatasource.create(qualityCertificate);
    }

    async edit(
        editQualityCertificate: EditQualityCertificate,
    ): Promise<Either<ValidationError | FallbackError, QualityCertificate>> {
        return this.qualityCertificateDatasource.edit(editQualityCertificate);
    }

    async delete(
        qualityCertificateId: number,
    ): Promise<Either<FallbackError, true>> {
        const qualityCertificateResult =
            await this.qualityCertificateDatasource.delete(
                qualityCertificateId,
            );
        return qualityCertificateResult.mapLeft(() => new FallbackError());
    }

    async getQualityCertificateTypes(): Promise<
        Either<FallbackError, QualityCertificateType[]>
    > {
        return this.qualityCertificateDatasource.fetchQualityCertificateTypes();
    }
}
