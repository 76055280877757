import { ExpenseRecurrence } from "@beneficiary/domain/models/economic-data/expense/expense-recurrence.model";
import { ExpenseType } from "@beneficiary/domain/models/economic-data/expense/expense-type.model";
import { GetAllExpenseRecurrencesUseCase } from "@beneficiary/domain/usecases/get-all-expense-recurrences.usecase";
import { GetAllExpenseTypesUseCase } from "@beneficiary/domain/usecases/get-all-expense-types.usecase";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { flow, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class ExpenseFormViewModel extends BaseViewModel {
    @observable
    expenseTypes: ExpenseType[] = [];

    @observable
    expenseRecurrences: ExpenseRecurrence[] = [];

    @observable
    initialLoading: boolean = true;

    constructor(
        @inject(GetAllExpenseTypesUseCase)
        private readonly getAllExpenseTypesUseCase: GetAllExpenseTypesUseCase,

        @inject(GetAllExpenseRecurrencesUseCase)
        private readonly getAllExpenseRecurrencesUseCase: GetAllExpenseRecurrencesUseCase,
    ) {
        super();
        makeObservable(this);
        this.initViewData();
    }
    @flow
    private async *initViewData(): AsyncGenerator<void> {
        await Promise.all([
            this.getAllExpenseTypes(),
            this.getAllExpenseRecurrences(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }
    @flow
    async *getAllExpenseTypes(): AsyncGenerator<void> {
        const expenseTypes = await this.getAllExpenseTypesUseCase.execute();
        runInAction(() => {
            this.expenseTypes = expenseTypes;
        });
    }

    @flow
    async *getAllExpenseRecurrences(): AsyncGenerator<void> {
        const expenseRecurrences =
            await this.getAllExpenseRecurrencesUseCase.execute();
        runInAction(() => {
            this.expenseRecurrences = expenseRecurrences;
        });
    }
}
