import { QualityCertificateDto } from "@entity/data/dto/quality-framework/quality-certificate/quality-certificate.dto";
import { QualityCertificateTypeEnumMapper } from "@entity/data/mappers/quality-certificate-type-enum.mapper";
import { QualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/quality-certificate.model";
import { inject, injectable } from "inversify";

@injectable()
export class QualityCertificateMapper {
    constructor(
        @inject(QualityCertificateTypeEnumMapper)
        private readonly qualityCertificateTypeEnumMapper: QualityCertificateTypeEnumMapper,
    ) {}
    mapToDto(qualityCertificate: QualityCertificate): QualityCertificateDto {
        const qualityCertificateDto: QualityCertificateDto = {
            id: qualityCertificate.id,
            quality_certificate_type:
                this.qualityCertificateTypeEnumMapper.mapToDto(
                    qualityCertificate.qualityCertificateType,
                ),
            entity: qualityCertificate.entity,
            other_quality_certificate:
                qualityCertificate.otherQualityCertificate
                    ? qualityCertificate.otherQualityCertificate
                    : null,
            year: qualityCertificate.year,
            expiration_date: qualityCertificate.expirationDate
                ? qualityCertificate.expirationDate.toISODate()
                : null,
        };

        return qualityCertificateDto;
    }
}
