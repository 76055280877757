import { Nullable } from "@core/domain/types/nullable.type";
import { UserDto } from "@user/data/dto/user.dto";
import { User } from "@user/domain/models/user.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { UserRelatedEmployeeMapper } from "./user-related-employee.mapper";
import { UserRoleEnumMapper } from "./user-role-enum.mapper";

@injectable()
export class UserMapper {
    constructor(
        @inject(UserRoleEnumMapper)
        private readonly userRoleEnumMapper: UserRoleEnumMapper,
        @inject(UserRelatedEmployeeMapper)
        private readonly userRelatedEmployeeMapper: UserRelatedEmployeeMapper,
    ) {}
    map(userDto: UserDto): Nullable<User> {
        const errors = validateSync(userDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const roleId = this.userRoleEnumMapper.map(userDto.rol);
        if (!roleId) return null;
        const lastLogin = userDto.last_login
            ? DateTime.fromISO(userDto.last_login)
            : undefined;

        const relatedEmployee = userDto.employee_data
            ? this.userRelatedEmployeeMapper.map(userDto.employee_data)
            : undefined;

        return new User(
            userDto.id,
            userDto.entity,
            userDto.email,
            userDto.first_name ?? "",
            userDto.last_name ?? "",
            userDto.is_active,
            DateTime.fromISO(userDto.date_joined),
            lastLogin,
            roleId,
            relatedEmployee ?? undefined,
        );
    }

    mapToDto(user: User): UserDto {
        const rol = this.userRoleEnumMapper.mapToDto(user.role);
        const userDto: UserDto = {
            id: user.id,
            email: user.email,
            first_name: user.name,
            last_name: user.surname,
            rol,
            entity: user.entityId,
            date_joined: user.dateJoined.toISO() ?? "",
            last_login: user.lastLogin?.toISO() ?? null,
            employee_data: null,
            related_person: user.relatedEmployee
                ? user.relatedEmployee.id
                : null,
            is_active: user.isActive,
        };
        return userDto;
    }
}
