import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { DateTime } from "luxon";
import { FinancingTypeEnum } from "./financial-entity/financing-type";

export class ProjectFinancialEntity {
    constructor(
        public readonly id: number,
        public requestAmount: Nullable<number>,
        public totalAmount: Nullable<number>,
        public financingTypeId: Nullable<FinancingTypeEnum>,
        public otherFinancingType: Nullable<string>,
        public financialEntityId: number,
        public budgetId: number,
        public financedPercentage: Nullable<number>,
        public convocationDate: ConvocationDate,
        public requirementDate: RequirementDate,
        public resolutionDate: ResolutionDate,
        public allegationsDate: AllegationsDate,
        public justificationDates: JustificationDates,
        public documents: IncDocument[],
        public isGranted?: Nullable<boolean>,
    ) {}
}

export interface ConvocationDate {
    publicationConvocationDate: Nullable<DateTime>;
    endConvocationDate: Nullable<DateTime>;
    presentationConvocationDate: Nullable<DateTime>;
}

export interface RequirementDate {
    communicationRequirementDate: Nullable<DateTime>;
    endRequirementDate: Nullable<DateTime>;
    presentationRequirementDate: Nullable<DateTime>;
}

export interface ResolutionDate {
    communicationResolutionDate: Nullable<DateTime>;
    communicationReformulationDate: Nullable<DateTime>;
    endReformulationDate: Nullable<DateTime>;
    presentationResolutionDate: Nullable<DateTime>;
    comunicationFinalResolutionDate: Nullable<DateTime>;
    acceptanceDate: Nullable<DateTime>;
}

export interface AllegationsDate {
    endAllegationsDate: Nullable<DateTime>;
    presentationAllegationDate: Nullable<DateTime>;
    endResourceDate: Nullable<DateTime>;
    presentationReplacementDate: Nullable<DateTime>;
}

export class JustificationDates {
    constructor(
        public hasIntermediateJustification?: Undefinable<boolean>,
        public intermediateJustificationEdgeDate?: Nullable<DateTime>,
        public intermediateJustificationDeliveryDate?: Nullable<DateTime>,
        public finalJustificationEdgeDate?: Nullable<DateTime>,
        public finalJustificationDeliveryDate?: Nullable<DateTime>,
        public requirementReceptionDate?: Nullable<DateTime>,
        public requirementResponseEdgeDate?: Nullable<DateTime>,
        public requirementResponseDate?: Nullable<DateTime>,
        public justificationAdditionalNotes?: Nullable<string>,
    ) {}
}

export class CreateProjectFinancialEntity {
    constructor(
        public requestAmount: ProjectFinancialEntity["requestAmount"],
        public totalAmount: ProjectFinancialEntity["totalAmount"],
        public financingTypeId: ProjectFinancialEntity["financingTypeId"],
        public otherFinancingType: ProjectFinancialEntity["otherFinancingType"],
        public financialEntityId: ProjectFinancialEntity["financialEntityId"],
        public budgetId: ProjectFinancialEntity["budgetId"],
        public financedPercentage: ProjectFinancialEntity["financedPercentage"],
        public convocationDate: ConvocationDate,
        public requirementDate: RequirementDate,
        public resolutionDate: ResolutionDate,
        public allegationsDate: AllegationsDate,
        public justificationDates: JustificationDates,
        public supportingDocuments?: Undefinable<number[]>,
        public isGranted?: ProjectFinancialEntity["isGranted"],
    ) {}
}

export class EditProjectFinancialEntity extends CreateProjectFinancialEntity {
    constructor(
        public readonly id: ProjectFinancialEntity["id"],
        requestAmount: CreateProjectFinancialEntity["requestAmount"],
        totalAmount: CreateProjectFinancialEntity["totalAmount"],
        financingTypeId: CreateProjectFinancialEntity["financingTypeId"],
        otherFinancingType: CreateProjectFinancialEntity["otherFinancingType"],
        financialEntityId: CreateProjectFinancialEntity["financialEntityId"],
        budgetId: CreateProjectFinancialEntity["budgetId"],
        financedPercentage: CreateProjectFinancialEntity["financedPercentage"],
        convocationDate: CreateProjectFinancialEntity["convocationDate"],
        requirementDate: CreateProjectFinancialEntity["requirementDate"],
        resolutionDate: CreateProjectFinancialEntity["resolutionDate"],
        allegationsDate: CreateProjectFinancialEntity["allegationsDate"],
        justificationDates: CreateProjectFinancialEntity["justificationDates"],
        supportingDocuments?: Undefinable<
            CreateProjectFinancialEntity["supportingDocuments"]
        >,
        isGranted?: Undefinable<CreateProjectFinancialEntity["isGranted"]>,
    ) {
        super(
            requestAmount,
            totalAmount,
            financingTypeId,
            otherFinancingType,
            financialEntityId,
            budgetId,
            financedPercentage,
            convocationDate,
            requirementDate,
            resolutionDate,
            allegationsDate,
            justificationDates,
            supportingDocuments,
            isGranted,
        );
    }
}

export class ProjectFinancialEntities {
    constructor(
        public readonly projectFinancialEntities: ProjectFinancialEntity[],
        public count: number,
    ) {}
}

export interface ProjectFinancialEntitiesSearchFilters {
    projectId?: Nullable<number>;
    financialEntityId?: Nullable<number>;
    budgetId?: Nullable<number>;
}
