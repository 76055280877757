import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum UserRoleEnumDto {
    SuperAdmin = "superadmin",
    EntityAdmin = "entity_admin",
    Professional = "intervention_professional",
}

export class UserRoleDto {
    @IsEnum(UserRoleEnumDto)
    @IsNotEmpty()
    key!: UserRoleEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
