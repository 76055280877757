import { EntityTerritorialScopeEnum } from "@entity/domain/models/entity-territorial-scope.model";
export class StatutoryTerritorialScope {
    constructor(
        public id: number,
        public name: string,
        public scope: EntityTerritorialScopeEnum,
        public entity: number,
    ) {}
}

export class StatutoryTerritorialScopes {
    constructor(
        public readonly statutoryTerritorialScopes: StatutoryTerritorialScope[],
        public readonly count: number,
    ) {}
}
