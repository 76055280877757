import { Nullable } from "@core/domain/types/nullable.type";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import {
    AuthorityManagerDto,
    AuthorityManagersDto,
} from "@entity/data/dto/authority-manager/authority-manager.dto";
import { MandateTerminationReasonEnumMapper } from "@entity/data/mappers/mandate-termination-reason-enum.mapper";
import { PositionTypeEnumMapper } from "@entity/data/mappers/position-type-enum.mapper";
import {
    AuthorityManager,
    AuthorityManagers,
} from "@entity/domain/models/authority-manager/authority-manager.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class AuthorityManagersMapper {
    constructor(
        @inject(PositionTypeEnumMapper)
        private readonly positionTypeEnumMapper: PositionTypeEnumMapper,
        @inject(MandateTerminationReasonEnumMapper)
        private readonly mandateTerminationReasonEnumMapper: MandateTerminationReasonEnumMapper,
    ) {}

    map(authorityManagersDto: AuthorityManagersDto): AuthorityManagers {
        const errors = validateSync(authorityManagersDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return new AuthorityManagers([], 0);
        }

        const authorityManagers = authorityManagersDto.results.mapNotNull(
            (authorityManagerDto) =>
                this.mapAuthorityManager(authorityManagerDto),
        );

        return new AuthorityManagers(
            authorityManagers,
            authorityManagersDto.count,
        );
    }

    mapAuthorityManager(
        authorityManagerDto: AuthorityManagerDto,
    ): Nullable<AuthorityManager> {
        if (!authorityManagerDto.id) {
            return null;
        }

        const position = authorityManagerDto.position.mapNotNull(
            (positionEnum) => this.positionTypeEnumMapper.map(positionEnum),
        );

        let mandateTerminationReason = null;
        if (authorityManagerDto.mandate_termination_reason) {
            mandateTerminationReason =
                this.mandateTerminationReasonEnumMapper.map(
                    authorityManagerDto.mandate_termination_reason,
                );
        }

        const authorityManager: AuthorityManager = {
            id: authorityManagerDto.id,
            name: authorityManagerDto.name,
            position,
            entity: authorityManagerDto.entity,
            appointmentDate: authorityManagerDto.appointment_date
                ? DateTime.fromISO(authorityManagerDto.appointment_date)
                : undefined,
            electionProcedure: convertEmptyToUndefined(
                authorityManagerDto.election_procedure,
            ),
            otherPosition: convertEmptyToUndefined(
                authorityManagerDto.other_position,
            ),
            mandateTerminationReason: convertEmptyToUndefined(
                mandateTerminationReason,
            ),
            otherMandateTerminationReason: convertEmptyToUndefined(
                authorityManagerDto.other_mandate_termination_reason,
            ),
            terminationDate: authorityManagerDto.mandate_end_date
                ? DateTime.fromISO(authorityManagerDto.mandate_end_date)
                : undefined,
        };
        return authorityManager;
    }
}
