import { Nullable } from "@core/domain/types/nullable.type";
import { TypologyDto } from "@entity/data/dto/typologies.dto";
import { TypologyEnumMapper } from "@entity/data/mappers/typology-enum.mapper";
import { Typology, TypologyEnum } from "@entity/domain/models/typology.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class TypologyMapper {
    constructor(
        @inject(TypologyEnumMapper)
        private readonly typologyEnumMapper: TypologyEnumMapper,
    ) {}

    map(typologyDto: TypologyDto): Nullable<Typology> {
        const errors = validateSync(typologyDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<TypologyEnum> = this.typologyEnumMapper.map(
            typologyDto.key,
        );

        if (!id) return null;

        return new Typology(id, typologyDto.value);
    }
}
