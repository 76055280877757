import { Affiliation } from "@entity/domain/models/affiliation/affiliation.model";
import { CreateAffiliation } from "@entity/domain/models/affiliation/create-affiliation.model";

export class EditAffiliation extends CreateAffiliation {
    constructor(
        public readonly id: Affiliation["id"],
        identification: Affiliation["identification"],
        name: Affiliation["name"],
        territorialScope: Affiliation["territorialScope"],
        entity: Affiliation["entity"],
        webAddress?: Affiliation["webAddress"],
        incorporationDate?: Affiliation["incorporationDate"],
        contactPerson?: Affiliation["contactPerson"],
        contactPhone?: Affiliation["contactPhone"],
        contactEmail?: Affiliation["contactEmail"],
    ) {
        super(
            identification,
            name,
            territorialScope,
            entity,
            webAddress,
            incorporationDate,
            contactPerson,
            contactPhone,
            contactEmail,
        );
    }
}
