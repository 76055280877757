import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { ProjectEmployee } from "@project/domain/models/project-employees.model";
import { GetAllProjectEmployeesByUseCase } from "@project/domain/usecases/project-employee/get-all-project-employees-by.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class AddEmployeeFromEntityModalFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    showAddEmployeeModal: boolean = false;

    projectEmployeeSelected: ProjectEmployee | undefined;

    constructor(
        @inject(GetAllProjectEmployeesByUseCase)
        private readonly getAllProjectEmployeesByUseCase: GetAllProjectEmployeesByUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setShowAddEmployeeModal(show: boolean): void {
        this.showAddEmployeeModal = show;
    }

    async getAllProjectEmployees(
        projectId: number,
        employeeId: number,
    ): Promise<void> {
        this.projectEmployeeSelected = undefined;
        if (!projectId) return;

        const projectEmployees =
            await this.getAllProjectEmployeesByUseCase.execute({
                projectId,
            });

        this.projectEmployeeSelected = projectEmployees.find(
            (projectEmployee) => projectEmployee.employee.id === employeeId,
        );
    }
}
