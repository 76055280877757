import { Nullable } from "@core/domain/types/nullable.type";
import { CreateTechnicalProposalObjectiveBody } from "@project/data/dto/technical-proposal/create-technical-proposal.body";
import { EditTechnicalProposalObjectiveBody } from "@project/data/dto/technical-proposal/edit-technical-proposal.body";
import { TechnicalProposalObjectiveDto } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import { ActivityMapper } from "@project/data/mappers/technical-proposal/activity.mapper";
import {
    CreateObjective,
    EditObjective,
    Objective,
} from "@project/domain/models/technical-proposals.model";
import { ObjectiveFormValuesValidated } from "@project/presentation/components/project-form/technical-proposal/objective/objective-form.view";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ObjectiveMapper {
    constructor(
        @inject(ActivityMapper) private readonly activityMapper: ActivityMapper,
    ) {}

    map(objectiveDto: TechnicalProposalObjectiveDto): Nullable<Objective> {
        const errors = validateSync(objectiveDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const activities = objectiveDto.activities_data.mapNotNull(
            (activityDto) => this.activityMapper.map(activityDto),
        );

        return new Objective(
            objectiveDto.id,
            objectiveDto.name,
            objectiveDto.description,
            objectiveDto.technical_proposal,
            activities,
        );
    }

    mapFromFormToCreate(
        objectiveForm: ObjectiveFormValuesValidated,
    ): CreateObjective {
        if (!objectiveForm.technicalProposalId)
            throw new Error("Technical proposal id is required");

        return new CreateObjective(
            objectiveForm.name,
            objectiveForm.description,
            objectiveForm.technicalProposalId,
        );
    }

    mapFromFormToEdit(
        objectiveForm: ObjectiveFormValuesValidated,
    ): EditObjective {
        if (!objectiveForm.id) throw new Error("Objective id is required");
        if (!objectiveForm.technicalProposalId)
            throw new Error("Technical proposal id is required");

        return new EditObjective(
            objectiveForm.id,
            objectiveForm.name,
            objectiveForm.description,
            objectiveForm.technicalProposalId,
        );
    }

    mapToCreateDto(
        objective: CreateObjective,
    ): CreateTechnicalProposalObjectiveBody {
        if (!objective.technicalProposalId)
            throw new Error("Technical proposal id is required");
        return {
            name: objective.name,
            description: objective.description,
            technical_proposal: objective.technicalProposalId,
        };
    }

    mapToEditDto(objective: EditObjective): EditTechnicalProposalObjectiveBody {
        if (!objective.technicalProposalId)
            throw new Error("Technical proposal id is required");

        return {
            id: objective.id,
            name: objective.name,
            description: objective.description,
            technical_proposal: objective.technicalProposalId,
        };
    }
}
