import { EditAffiliationBody } from "@entity/data/dto/affiliation/edit-affiliation.body";
import { EditAffiliation } from "@entity/domain/models/affiliation/edit-affiliation.model";
import { AffiliationFormValuesValidated } from "@entity/presentation/component/entity-form/affiliation/form/affiliation-form";
import { injectable } from "inversify";

@injectable()
export class EditAffiliationMapper {
    mapToDto(editAffiliation: EditAffiliation): EditAffiliationBody {
        const editAffiliationBody: EditAffiliationBody = {
            id: editAffiliation.id,
            identification: editAffiliation.identification,
            name: editAffiliation.name,
            territorial_scope: editAffiliation.territorialScope,
            entity: editAffiliation.entity,
            web_address: editAffiliation.webAddress
                ? editAffiliation.webAddress
                : null,
            incorporation_date: editAffiliation.incorporationDate
                ? editAffiliation.incorporationDate.toISODate()
                : null,
            contact_person: editAffiliation.contactPerson
                ? editAffiliation.contactPerson
                : null,
            contact_phone: editAffiliation.contactPhone
                ? editAffiliation.contactPhone
                : null,
            contact_email: editAffiliation.contactEmail
                ? editAffiliation.contactEmail
                : null,
        };
        return editAffiliationBody;
    }
    mapFromModal(
        id: number,
        entityId: number,
        affiliation: AffiliationFormValuesValidated,
    ): EditAffiliation {
        const editAffiliation: EditAffiliation = new EditAffiliation(
            id,
            affiliation.identification,
            affiliation.name,
            affiliation.territorialScope,
            entityId,
            affiliation.webAddress,
            affiliation.incorporationDate,
            affiliation.contactPerson,
            affiliation.contactPhone,
            affiliation.contactEmail,
        );
        return editAffiliation;
    }
}
