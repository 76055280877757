import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ContactRepository } from "@entity/data/repositories/contact.repository";
import { Contact } from "@proceeding/domain/models/contact.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetAllContactsUseCase {
    constructor(
        @inject(ContactRepository)
        private readonly contactRepository: ContactRepository,
    ) {}

    async execute(): Promise<Contact[]> {
        const contactsResult = await this.contactRepository.getAll();

        if (contactsResult.isLeft()) {
            ToastManagerStore.error(contactsResult.getLeftOrThrow().message);
        }

        return contactsResult.getOrElse([]);
    }
}
