import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { DepositSubventionTypeEnum } from "../../domain/models/deposit-type.model";
import { DepositSubventionTypeEnumDto } from "../dto/deposit-types.dto";

@injectable()
export class DepositSubventionTypeEnumMapper {
    map(
        depositSubventionTypeEnumDto: DepositSubventionTypeEnumDto,
    ): Nullable<DepositSubventionTypeEnum> {
        switch (depositSubventionTypeEnumDto) {
            case DepositSubventionTypeEnumDto.PRIVATE_SUBVENTION:
                return DepositSubventionTypeEnum.PRIVATE_SUBVENTION;
            case DepositSubventionTypeEnumDto.PUBLIC_SUBVENTION:
                return DepositSubventionTypeEnum.PUBLIC_SUBVENTION;
            default:
                return null;
        }
    }

    mapToDto(
        depositSubventionTypeEnum: DepositSubventionTypeEnum,
    ): DepositSubventionTypeEnumDto {
        switch (depositSubventionTypeEnum) {
            case DepositSubventionTypeEnum.PRIVATE_SUBVENTION:
                return DepositSubventionTypeEnumDto.PRIVATE_SUBVENTION;
            case DepositSubventionTypeEnum.PUBLIC_SUBVENTION:
                return DepositSubventionTypeEnumDto.PUBLIC_SUBVENTION;
            default:
                return DepositSubventionTypeEnumDto[""];
        }
    }
}
