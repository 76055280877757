import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { Nullish } from "@core/domain/types/nullish.type";
import { Type } from "class-transformer";
import { IsArray } from "class-validator";
import { CostTypeEnumDto } from "./cost-type.dto";
import { CostDto } from "./cost.dto";

export class CostsDto extends PaginatedDto {
    @IsArray()
    @Type(() => CostDto)
    results!: CostDto[];
}

export type CostsSummaryQuery = PaginatedQuery & {
    search?: Nullish<string>;
    type?: Nullish<CostTypeEnumDto>;
    invoice_from_date?: Nullish<string>;
    invoice_to_date?: Nullish<string>;
    entities?: Nullish<string>;
};
