import { Nullable } from "@core/domain/types/nullable.type";
import { FinancingTypeDto } from "@project/data/dto/financial-entity/financing-type.dto";
import { FinancingType } from "@project/domain/models/financial-entity/financing-type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { FinancingTypeEnumMapper } from "./financing-type-enum.mapper";

@injectable()
export class FinancingTypeMapper {
    constructor(
        @inject(FinancingTypeEnumMapper)
        private readonly financingTypeEnumMapper: FinancingTypeEnumMapper,
    ) {}

    map(financingDto: FinancingTypeDto): Nullable<FinancingType> {
        const errors = validateSync(financingDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }
        const type = this.financingTypeEnumMapper.map(financingDto.key);
        if (!type) return null;

        return new FinancingType(type, financingDto.value);
    }
}
