import { EducationLevelEnumMapper } from "@core/data/mappers/education-level-enum.mapper";
import { CreateVolunteerContractBody } from "@entity/data/dto/employee/contracts/volunteer/create-volunteer-contract.body";
import { CreateVolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/create-volunteer-contract.model";
import { VolunteerContractFormValuesValidated } from "@entity/presentation/component/employee-form/contract/volunteer/form/volunteer-contract-form";
import { CatalogueEnumMapper } from "@project/data/mappers/catalogue-enum.mapper";
import { inject, injectable } from "inversify";

@injectable()
export class CreateVolunteerContractMapper {
    constructor(
        @inject(CatalogueEnumMapper)
        private readonly catalogueEnumMapper: CatalogueEnumMapper,
        @inject(EducationLevelEnumMapper)
        private readonly educationLevelEnumMapper: EducationLevelEnumMapper,
    ) {}

    mapToCreateDto(
        createVolunteerContract: CreateVolunteerContract,
    ): CreateVolunteerContractBody {
        const educationLevel = this.educationLevelEnumMapper.mapToDto(
            createVolunteerContract.educationLevel,
        );

        const preferences = createVolunteerContract.preferences.map(
            (catalogueDto) => this.catalogueEnumMapper.mapToDto(catalogueDto),
        );
        const createVolunteerContractBody: CreateVolunteerContractBody = {
            has_insurance: createVolunteerContract.insurance,
            activity: createVolunteerContract.activity,
            education_level: educationLevel,
            availability: createVolunteerContract.availability,
            preferences: preferences,
            weekly_workday_hours:
                createVolunteerContract.weeklyWorkdayHours.toString(),
            start_date: createVolunteerContract.startDate.toISODate() ?? "",
            employee: createVolunteerContract.employeeId,
            start_date_insurance:
                createVolunteerContract.startDateInsurance?.toISODate() ?? null,
            other_preferences: createVolunteerContract.otherPreferences?.length
                ? createVolunteerContract.otherPreferences
                : "",
            door: createVolunteerContract.door ?? "",
            city: createVolunteerContract.city ?? "",
            end_date: createVolunteerContract.endDate?.toISODate() ?? null,
            end_date_insurance:
                createVolunteerContract.endDateInsurance?.toISODate() ?? null,
        };

        return createVolunteerContractBody;
    }

    mapFromFormValues(
        employeeId: number,
        volunteerContractForm: VolunteerContractFormValuesValidated,
    ): CreateVolunteerContract {
        if (!employeeId) throw Error("Missing employeeId");

        const createVolunteerContract = new CreateVolunteerContract(
            volunteerContractForm.insurance,
            volunteerContractForm.activity,
            volunteerContractForm.educationLevel,
            volunteerContractForm.availability,
            volunteerContractForm.preferences,
            volunteerContractForm.weeklyWorkdayHours,
            volunteerContractForm.startDate,
            employeeId,
            volunteerContractForm.startDateInsurance,
            volunteerContractForm.otherPreferences,
            volunteerContractForm.door,
            volunteerContractForm.city,
            volunteerContractForm.endDate,
            volunteerContractForm.endDateInsurance,
        );

        return createVolunteerContract;
    }
}
