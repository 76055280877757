import { PaginatedDto } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { PolicyProtocolTypeEnumDto } from "@entity/data/dto/policy-protocol-type-enum.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class PolicyProtocolDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsEnum(PolicyProtocolTypeEnumDto, { each: true })
    @IsNotEmpty()
    policies_protocols!: PolicyProtocolTypeEnumDto[];

    @IsString()
    @IsOptional()
    other_policies_protocols!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    realization_year!: number;

    @IsDateString()
    @IsOptional()
    expiration_date!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;
}

export class PolicyProtocolsDto extends PaginatedDto {
    @IsArray()
    @Type(() => PolicyProtocolDto)
    results!: PolicyProtocolDto[];
}
