import { Nullable } from "@core/domain/types/nullable.type";
import { ContactDto, CreateContactDto } from "@entity/data/dto/contact.dto";
import {
    Contact,
    CreateContactBody,
} from "@proceeding/domain/models/contact.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class ContactsMapper {
    map(contactDto: ContactDto): Nullable<Contact> {
        const errors = validateSync(contactDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        return new Contact(
            contactDto.id,
            contactDto.entity,
            contactDto.name,
            contactDto.email,
            contactDto.phone1,
            contactDto.phone2 ?? undefined,
            contactDto.web ?? undefined,
            contactDto.observations ?? undefined,
            contactDto.identification_type ?? undefined,
            contactDto.identification ?? undefined,
            contactDto.is_legal_representative ?? false,
        );
    }

    mapToContactDto(contact: Contact): ContactDto {
        const contactDto = new ContactDto();
        contactDto.id = contact.id;
        contactDto.entity = contact.entity;
        contactDto.name = contact.name;
        contactDto.email = contact.email;
        contactDto.phone1 = contact.phone1;
        contactDto.phone2 = contact.phone2 ?? null;
        contactDto.web = contact.web ?? null;
        contactDto.observations = contact.observations ?? null;
        contactDto.identification_type = contact.identificationType ?? null;
        contactDto.identification = contact.identification ?? null;
        contactDto.is_legal_representative =
            contact.isLegalRepresentative ?? false;

        return contactDto;
    }

    mapToCreateContactDto(contact: CreateContactBody): CreateContactDto {
        const contactDto = new CreateContactDto();
        contactDto.entity = contact.entity;
        contactDto.name = contact.name;
        contactDto.email = contact.email;
        contactDto.phone1 = contact.phone1;
        contactDto.phone2 = contact.phone2 ?? null;
        contactDto.web = contact.web ?? null;
        contactDto.observations = contact.observations ?? null;
        contactDto.identification_type = contact.identificationType ?? null;
        contactDto.identification = contact.identification ?? null;
        contactDto.is_legal_representative =
            contact.isLegalRepresentative ?? false;

        return contactDto;
    }
}
