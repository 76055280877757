import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { Nullish } from "@core/domain/types/nullish.type";
import { Type } from "class-transformer";
import { IsArray } from "class-validator";
import { EmployeeTypeEnumDto } from "./employee-type.dto";
import { EmployeeDto } from "./employee.dto";

export class EmployeesDto extends PaginatedDto {
    @IsArray()
    @Type(() => EmployeeDto)
    results!: EmployeeDto[];
}

export type EmployeesSummaryQuery = PaginatedQuery & {
    search?: Nullish<string>;
    type?: Nullish<EmployeeTypeEnumDto>;
    entity?: Nullish<number>;
    is_active?: Nullish<string>;
    active_contract?: Nullish<boolean>;
    entities?: Nullish<string>;
};
