import { Nullable } from "@core/domain/types/nullable.type";
import { CCAAEnumDto } from "@entity/data/dto/ccaa-enum.dto";
import { CCAAEnum } from "@entity/domain/models/ccaa.model";
import { injectable } from "inversify";
@injectable()
export class CCAAEnumMapper {
    map(ccaaEnumDto: CCAAEnumDto): Nullable<CCAAEnum> {
        switch (ccaaEnumDto) {
            case CCAAEnumDto.Andalucia:
                return CCAAEnum.Andalucia;
            case CCAAEnumDto.Aragon:
                return CCAAEnum.Aragon;
            case CCAAEnumDto.Asturias:
                return CCAAEnum.Asturias;
            case CCAAEnumDto.IslasBaleares:
                return CCAAEnum.IslasBaleares;
            case CCAAEnumDto.Canarias:
                return CCAAEnum.Canarias;
            case CCAAEnumDto.Cantabria:
                return CCAAEnum.Cantabria;
            case CCAAEnumDto.CastillaLaMancha:
                return CCAAEnum.CastillaLaMancha;
            case CCAAEnumDto.CastillaYLeon:
                return CCAAEnum.CastillaYLeon;
            case CCAAEnumDto.Catalunya:
                return CCAAEnum.Catalunya;
            case CCAAEnumDto.Extremadura:
                return CCAAEnum.Extremadura;
            case CCAAEnumDto.Galicia:
                return CCAAEnum.Galicia;
            case CCAAEnumDto.Madrid:
                return CCAAEnum.Madrid;
            case CCAAEnumDto.Murcia:
                return CCAAEnum.Murcia;
            case CCAAEnumDto.Navarra:
                return CCAAEnum.Navarra;
            case CCAAEnumDto.PaisVasco:
                return CCAAEnum.PaisVasco;
            case CCAAEnumDto.LaRioja:
                return CCAAEnum.LaRioja;
            case CCAAEnumDto.ComunidadValenciana:
                return CCAAEnum.ComunidadValenciana;
            case CCAAEnumDto.Ceuta:
                return CCAAEnum.Ceuta;
            case CCAAEnumDto.Melilla:
                return CCAAEnum.Melilla;
            default:
                return null;
        }
    }

    mapToDto(ccaaEnum: CCAAEnum): CCAAEnumDto {
        // eslint-disable-next-line default-case
        switch (ccaaEnum) {
            case CCAAEnum.Andalucia:
                return CCAAEnumDto.Andalucia;
            case CCAAEnum.Aragon:
                return CCAAEnumDto.Aragon;
            case CCAAEnum.Asturias:
                return CCAAEnumDto.Asturias;
            case CCAAEnum.IslasBaleares:
                return CCAAEnumDto.IslasBaleares;
            case CCAAEnum.Canarias:
                return CCAAEnumDto.Canarias;
            case CCAAEnum.Cantabria:
                return CCAAEnumDto.Cantabria;
            case CCAAEnum.CastillaLaMancha:
                return CCAAEnumDto.CastillaLaMancha;
            case CCAAEnum.CastillaYLeon:
                return CCAAEnumDto.CastillaYLeon;
            case CCAAEnum.Catalunya:
                return CCAAEnumDto.Catalunya;
            case CCAAEnum.Extremadura:
                return CCAAEnumDto.Extremadura;
            case CCAAEnum.Galicia:
                return CCAAEnumDto.Galicia;
            case CCAAEnum.Madrid:
                return CCAAEnumDto.Madrid;
            case CCAAEnum.Murcia:
                return CCAAEnumDto.Murcia;
            case CCAAEnum.Navarra:
                return CCAAEnumDto.Navarra;
            case CCAAEnum.PaisVasco:
                return CCAAEnumDto.PaisVasco;
            case CCAAEnum.LaRioja:
                return CCAAEnumDto.LaRioja;
            case CCAAEnum.ComunidadValenciana:
                return CCAAEnumDto.ComunidadValenciana;
            case CCAAEnum.Ceuta:
                return CCAAEnumDto.Ceuta;
            case CCAAEnum.Melilla:
                return CCAAEnumDto.Melilla;
        }
    }
}
