import { IdentificationDocumentTypeEnumDto } from "@core/data/dto/identification-document-type.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";
import {
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    ValidateIf,
} from "class-validator";
import { UserRoleEnumDto } from "./user-role.dto";

export class UserRelatedEmployeeDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    identification!: string;

    @IsEnum(IdentificationDocumentTypeEnumDto)
    @IsNotEmpty()
    identification_type!: IdentificationDocumentTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    surname!: string;
}

export class UserDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    email!: string;

    @IsString()
    @IsOptional()
    first_name!: Nullable<string>;

    @IsString()
    @IsOptional()
    last_name!: Nullable<string>;

    // Super admin don't belong to any entity
    @ValidateIf((user) => (user as UserDto).rol !== UserRoleEnumDto.SuperAdmin)
    @IsNumber()
    @IsNotEmpty()
    entity!: number;

    @IsNumber()
    @IsOptional()
    related_person!: Nullable<number>;

    @IsEnum(UserRoleEnumDto)
    @IsNotEmpty()
    rol!: UserRoleEnumDto;

    @IsDateString()
    @IsNotEmpty()
    date_joined!: string;

    @IsBoolean()
    is_active!: boolean;

    @IsDateString()
    @IsOptional()
    last_login!: Nullable<string>;

    @IsOptional()
    @Type(() => UserRelatedEmployeeDto)
    employee_data!: Nullable<UserRelatedEmployeeDto>;
}
