import { Pagination } from "@core/domain/models/pagination";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Collective } from "@entity/domain/models/collective.model";
import {
    EntitiesSummary,
    type EntitySearchFilters,
} from "@entity/domain/models/entity-summary.model";
import { Typology } from "@entity/domain/models/typology.model";
import { GetEntitiesByUseCase } from "@entity/domain/usecases/entity/get-entities-by.usecase";
import { GetAllCollectivesUseCase } from "@entity/domain/usecases/get-all-collectives.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";
import { GetAllTypologiesUseCase } from "../../../../domain/usecases/get-all-typologies.usecase";

@injectable()
export class EntityListPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    entities: EntitiesSummary = new EntitiesSummary([], 0);

    @observable
    typologies: Typology[] = [];

    @observable
    collectives: Collective[] = [];

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: EntitySearchFilters = {
        name: "",
        collectiveIds: [],
        typologyType: null,
        active: true,
    };

    @observable
    filters: EntitySearchFilters = this.initialFiltersValue;

    constructor(
        @inject(GetAllCollectivesUseCase)
        private readonly getAllCollectivesUsecase: GetAllCollectivesUseCase,
        @inject(GetAllTypologiesUseCase)
        private readonly getAllTypologiesUseCase: GetAllTypologiesUseCase,
        @inject(GetEntitiesByUseCase)
        private readonly getEntitiesByUseCase: GetEntitiesByUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.searchEntitiesBy(this.filters, this.pagination),
            this.getAllTypologies(),
            this.getAllCollectives(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setFilters(filters: EntitySearchFilters): void {
        this.filters = filters;
        this.pagination.reset();

        this.searchEntitiesBy(this.filters, this.pagination);
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.searchEntitiesBy(this.filters, this.pagination);
    }

    async searchEntitiesBy(
        filters: EntitySearchFilters,
        pagination: Pagination,
    ): Promise<void> {
        const entities = await this.getEntitiesByUseCase.execute(
            pagination,
            filters,
        );
        runInAction(() => {
            this.entities = entities;
        });
    }

    async getAllTypologies(): Promise<void> {
        const typologies = await this.getAllTypologiesUseCase.execute();

        runInAction(() => {
            this.typologies = typologies;
        });
    }

    async getAllCollectives(): Promise<void> {
        const collectives = await this.getAllCollectivesUsecase.execute();

        runInAction(() => {
            this.collectives = collectives;
        });
    }
}
