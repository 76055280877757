import { UserSessionRepository } from "@authentication/data/repositories/user-session.repository";
import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class ImpersonateEntityUseCase {
    constructor(
        @inject(UserSessionRepository)
        private readonly userSessionRepository: UserSessionRepository,
    ) {}

    async execute(entityId: Nullable<number>): Promise<void> {
        const impersonateEntityResult =
            await this.userSessionRepository.impersonateEntity(entityId);

        if (impersonateEntityResult.isLeft()) {
            ToastManagerStore.error(
                impersonateEntityResult.getLeftOrThrow().message,
            );
        }

        const loadCurrentUserResult =
            await this.userSessionRepository.loadCurrentUser();

        if (loadCurrentUserResult.isLeft()) {
            ToastManagerStore.error(
                loadCurrentUserResult.getLeftOrThrow().message,
            );
        }
    }
}
