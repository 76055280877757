import { IncError } from "@core/domain/errors/inc.error";
import i18n from "@i18n";

export class InternalInvalidSessionUserError extends IncError {
    constructor() {
        super(i18n.t("error:authentication.internal.invalidSessionUser"));

        Object.setPrototypeOf(this, InternalInvalidSessionUserError.prototype);
    }
}
