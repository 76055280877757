import { Nullable } from "@core/domain/types/nullable.type";
import { ServiceProvidedEnumDto } from "@entity/data/dto/supplier/service-provided-enum.dto";
import { ServiceProvidedEnum } from "@entity/domain/models/supplier/service-provided.model";
import { injectable } from "inversify";

@injectable()
export class ServiceProvidedEnumMapper {
    map(
        serviceProvidedEnumDto: ServiceProvidedEnumDto,
    ): Nullable<ServiceProvidedEnum> {
        switch (serviceProvidedEnumDto) {
            case ServiceProvidedEnumDto.Others:
                return ServiceProvidedEnum.Others;
            case ServiceProvidedEnumDto.Water:
                return ServiceProvidedEnum.Water;
            case ServiceProvidedEnumDto.RentLocals:
                return ServiceProvidedEnum.RentLocals;
            case ServiceProvidedEnumDto.AccouningManagement:
                return ServiceProvidedEnum.AccouningManagement;
            case ServiceProvidedEnumDto.RentHeadquarters:
                return ServiceProvidedEnum.RentHeadquarters;
            case ServiceProvidedEnumDto.Auditor:
                return ServiceProvidedEnum.Auditor;
            case ServiceProvidedEnumDto.QualityCertificate:
                return ServiceProvidedEnum.QualityCertificate;
            case ServiceProvidedEnumDto.MembershipFeesNetworksPlatforms:
                return ServiceProvidedEnum.MembershipFeesNetworksPlatforms;
            case ServiceProvidedEnumDto.Electricity:
                return ServiceProvidedEnum.Electricity;
            case ServiceProvidedEnumDto.TechnologySupplier:
                return ServiceProvidedEnum.TechnologySupplier;
            case ServiceProvidedEnumDto.Training:
                return ServiceProvidedEnum.Training;
            case ServiceProvidedEnumDto.Gas:
                return ServiceProvidedEnum.Gas;
            case ServiceProvidedEnumDto.AccountingHhrr:
                return ServiceProvidedEnum.AccountingHhrr;
            case ServiceProvidedEnumDto.AccountingFinantial:
                return ServiceProvidedEnum.AccountingFinantial;
            case ServiceProvidedEnumDto.Licenses:
                return ServiceProvidedEnum.Licenses;
            case ServiceProvidedEnumDto.OccupationalAccidentsInsurance:
                return ServiceProvidedEnum.OccupationalAccidentsInsurance;
            case ServiceProvidedEnumDto.AccountingOthers:
                return ServiceProvidedEnum.AccountingOthers;
            case ServiceProvidedEnumDto.OccupationalSafetyPrevention:
                return ServiceProvidedEnum.OccupationalSafetyPrevention;
            case ServiceProvidedEnumDto.Food_Suppliers:
                return ServiceProvidedEnum.Food_Suppliers;
            case ServiceProvidedEnumDto.OfficeSuppliesSuppliers:
                return ServiceProvidedEnum.OfficeSuppliesSuppliers;
            case ServiceProvidedEnumDto.Insurance:
                return ServiceProvidedEnum.Insurance;
            case ServiceProvidedEnumDto.AdvisoryService:
                return ServiceProvidedEnum.AdvisoryService;
            case ServiceProvidedEnumDto.InternetService:
                return ServiceProvidedEnum.InternetService;
            case ServiceProvidedEnumDto.CleaningService:
                return ServiceProvidedEnum.CleaningService;
            case ServiceProvidedEnumDto.ItServices:
                return ServiceProvidedEnum.ItServices;
            case ServiceProvidedEnumDto.ConsultancyServices:
                return ServiceProvidedEnum.ConsultancyServices;
            case ServiceProvidedEnumDto.PhoneServices:
                return ServiceProvidedEnum.PhoneServices;
            case ServiceProvidedEnumDto.ProfessionalServicesFreelancer:
                return ServiceProvidedEnum.ProfessionalServicesFreelancer;
            case ServiceProvidedEnumDto.Software:
                return ServiceProvidedEnum.Software;
            default:
                return null;
        }
    }

    mapToDto(serviceProvidedEnum: ServiceProvidedEnum): ServiceProvidedEnumDto {
        // eslint-disable-next-line default-case
        switch (serviceProvidedEnum) {
            case ServiceProvidedEnum.Others:
                return ServiceProvidedEnumDto.Others;
            case ServiceProvidedEnum.Water:
                return ServiceProvidedEnumDto.Water;
            case ServiceProvidedEnum.RentLocals:
                return ServiceProvidedEnumDto.RentLocals;
            case ServiceProvidedEnum.AccouningManagement:
                return ServiceProvidedEnumDto.AccouningManagement;
            case ServiceProvidedEnum.RentHeadquarters:
                return ServiceProvidedEnumDto.RentHeadquarters;
            case ServiceProvidedEnum.Auditor:
                return ServiceProvidedEnumDto.Auditor;
            case ServiceProvidedEnum.QualityCertificate:
                return ServiceProvidedEnumDto.QualityCertificate;
            case ServiceProvidedEnum.MembershipFeesNetworksPlatforms:
                return ServiceProvidedEnumDto.MembershipFeesNetworksPlatforms;
            case ServiceProvidedEnum.Electricity:
                return ServiceProvidedEnumDto.Electricity;
            case ServiceProvidedEnum.TechnologySupplier:
                return ServiceProvidedEnumDto.TechnologySupplier;
            case ServiceProvidedEnum.Training:
                return ServiceProvidedEnumDto.Training;
            case ServiceProvidedEnum.Gas:
                return ServiceProvidedEnumDto.Gas;
            case ServiceProvidedEnum.AccountingHhrr:
                return ServiceProvidedEnumDto.AccountingHhrr;
            case ServiceProvidedEnum.AccountingFinantial:
                return ServiceProvidedEnumDto.AccountingFinantial;
            case ServiceProvidedEnum.Licenses:
                return ServiceProvidedEnumDto.Licenses;
            case ServiceProvidedEnum.OccupationalAccidentsInsurance:
                return ServiceProvidedEnumDto.OccupationalAccidentsInsurance;
            case ServiceProvidedEnum.AccountingOthers:
                return ServiceProvidedEnumDto.AccountingOthers;
            case ServiceProvidedEnum.OccupationalSafetyPrevention:
                return ServiceProvidedEnumDto.OccupationalSafetyPrevention;
            case ServiceProvidedEnum.Food_Suppliers:
                return ServiceProvidedEnumDto.Food_Suppliers;
            case ServiceProvidedEnum.OfficeSuppliesSuppliers:
                return ServiceProvidedEnumDto.OfficeSuppliesSuppliers;
            case ServiceProvidedEnum.Insurance:
                return ServiceProvidedEnumDto.Insurance;
            case ServiceProvidedEnum.AdvisoryService:
                return ServiceProvidedEnumDto.AdvisoryService;
            case ServiceProvidedEnum.InternetService:
                return ServiceProvidedEnumDto.InternetService;
            case ServiceProvidedEnum.CleaningService:
                return ServiceProvidedEnumDto.CleaningService;
            case ServiceProvidedEnum.ItServices:
                return ServiceProvidedEnumDto.ItServices;
            case ServiceProvidedEnum.ConsultancyServices:
                return ServiceProvidedEnumDto.ConsultancyServices;
            case ServiceProvidedEnum.PhoneServices:
                return ServiceProvidedEnumDto.PhoneServices;
            case ServiceProvidedEnum.ProfessionalServicesFreelancer:
                return ServiceProvidedEnumDto.ProfessionalServicesFreelancer;
            case ServiceProvidedEnum.Software:
                return ServiceProvidedEnumDto.Software;
        }
    }
}
