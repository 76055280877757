import { Nullable } from "@core/domain/types/nullable.type";
import { MandateTerminationReasonEnumDto } from "@entity/data/dto/mandate-termination-reason-enum.dto";
import { MandateTerminationReasonEnum } from "@entity/domain/models/mandate-termination-reason.model";
import { injectable } from "inversify";

@injectable()
export class MandateTerminationReasonEnumMapper {
    map(
        mandateTerminationReasonEnumDto: MandateTerminationReasonEnumDto,
    ): Nullable<MandateTerminationReasonEnum> {
        switch (mandateTerminationReasonEnumDto) {
            case MandateTerminationReasonEnumDto.resignation:
                return MandateTerminationReasonEnum.RESIGNATION;
            case MandateTerminationReasonEnumDto.electoral_mandate_end:
                return MandateTerminationReasonEnum.ELECTORAL_MANDATE_END;
            case MandateTerminationReasonEnumDto.sickness:
                return MandateTerminationReasonEnum.SICKNESS;
            case MandateTerminationReasonEnumDto.incompatibility:
                return MandateTerminationReasonEnum.INCOMPATIBILITY;
            case MandateTerminationReasonEnumDto.death:
                return MandateTerminationReasonEnum.DEATH;
            case MandateTerminationReasonEnumDto.no_renovation:
                return MandateTerminationReasonEnum.NO_RENOVATION;
            case MandateTerminationReasonEnumDto.other:
                return MandateTerminationReasonEnum.OTHER;
            default:
                return null;
        }
    }

    mapToDto(
        mandateTerminationReasonEnum: MandateTerminationReasonEnum,
    ): MandateTerminationReasonEnumDto {
        // eslint-disable-next-line default-case
        switch (mandateTerminationReasonEnum) {
            case MandateTerminationReasonEnum.RESIGNATION:
                return MandateTerminationReasonEnumDto.resignation;
            case MandateTerminationReasonEnum.ELECTORAL_MANDATE_END:
                return MandateTerminationReasonEnumDto.electoral_mandate_end;
            case MandateTerminationReasonEnum.SICKNESS:
                return MandateTerminationReasonEnumDto.sickness;
            case MandateTerminationReasonEnum.INCOMPATIBILITY:
                return MandateTerminationReasonEnumDto.incompatibility;
            case MandateTerminationReasonEnum.DEATH:
                return MandateTerminationReasonEnumDto.death;
            case MandateTerminationReasonEnum.NO_RENOVATION:
                return MandateTerminationReasonEnumDto.no_renovation;
            case MandateTerminationReasonEnum.OTHER:
                return MandateTerminationReasonEnumDto.other;
        }
    }
}
