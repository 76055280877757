import { PublicItineraryRepository } from "@beneficiary/data/repositories/public-itinerary.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class DeletePublicItineraryUseCase {
    constructor(
        @inject(PublicItineraryRepository)
        private readonly publicItineraryRepository: PublicItineraryRepository,
    ) {}

    async execute(publicItineraryId: number): Promise<void> {
        const result =
            await this.publicItineraryRepository.delete(publicItineraryId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        ToastManagerStore.success();
    }
}
