import { EducationLevelEnumDto } from "@core/data/dto/education-level.dto";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { CatalogueEnumDto } from "@project/data/dto/catalogue.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class VolunteerContractDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsDateString()
    @IsNotEmpty()
    start_date!: string;

    @IsDateString()
    @IsOptional()
    end_date!: Nullable<string>;

    @IsBoolean()
    @IsNotEmpty()
    has_insurance!: boolean;

    @IsString()
    @IsNotEmpty()
    weekly_workday_hours!: string;

    @IsDateString()
    @IsOptional()
    start_date_insurance!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    end_date_insurance!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    activity!: string;

    @IsEnum(EducationLevelEnumDto)
    @IsNotEmpty()
    education_level!: EducationLevelEnumDto;

    @IsString()
    @IsNotEmpty()
    availability!: string;

    @IsEnum(CatalogueEnumDto, { each: true })
    @IsNotEmpty()
    preferences!: CatalogueEnumDto[];

    @IsString()
    @IsOptional()
    other_preferences!: Nullable<string>;

    @IsString()
    @IsOptional()
    door!: Nullable<string>;

    @IsString()
    @IsOptional()
    city!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    employee!: number;
}

export class VolunteerContractsDto extends PaginatedDto {
    @IsArray()
    @Type(() => VolunteerContractDto)
    results!: VolunteerContractDto[];
}

export type VolunteerContractQuery = PaginatedQuery & {
    employee?: Nullish<number>;
};
