import type { Nullable } from "@core/domain/types/nullable.type";
import {
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { ExpenseSubTypeEnumDto } from "./expense-sub-type.dto";
import { ExpenseTypeEnumDto } from "./expense-type.dto";
import { PaymentMethodTypeEnumDto } from "./payment-method-type.dto";

export class InvestmentDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsDateString()
    @IsNotEmpty()
    created!: string;

    @IsDateString()
    @IsNotEmpty()
    modified!: string;

    @IsBoolean()
    @IsNotEmpty()
    is_active!: boolean;

    @IsEnum(ExpenseTypeEnumDto)
    @IsNotEmpty()
    type_expense!: ExpenseTypeEnumDto;

    @IsEnum(ExpenseSubTypeEnumDto)
    @IsOptional()
    subtype_expense!: ExpenseSubTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    concept!: string;

    @IsString()
    @IsNotEmpty()
    provider!: string;

    @IsString()
    @IsOptional()
    nif_provider!: Nullable<string>;

    @IsString()
    @IsOptional()
    description!: Nullable<string>;

    @IsString()
    @IsOptional()
    invoice_number!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    invoice_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    expense_date!: Nullable<string>;

    @IsEnum(PaymentMethodTypeEnumDto)
    @IsOptional()
    // TODO: Create a new Enum called Payment Method Choices
    payment_method!: Nullable<PaymentMethodTypeEnumDto>;

    @IsString()
    @IsOptional()
    tax_base!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    iva!: number;

    @IsString()
    @IsOptional()
    total_tax_with_iva!: Nullable<string>;

    @IsString()
    @IsNotEmpty()
    amount_allocated_to_project!: string;

    @IsString()
    @IsOptional()
    project_allocation_percentage!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    payment_date!: Nullable<string>;

    @IsNumber()
    @IsOptional()
    project_expense!: Nullable<string>;
}
