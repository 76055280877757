import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { EditAuthorityManagerBody } from "@entity/data/dto/authority-manager/edit-authority-manager.body";
import { MandateTerminationReasonEnumMapper } from "@entity/data/mappers/mandate-termination-reason-enum.mapper";
import { PositionTypeEnumMapper } from "@entity/data/mappers/position-type-enum.mapper";
import { EditAuthorityManager } from "@entity/domain/models/authority-manager/edit-authority-manager.model";
import { AuthorityManagerFormValuesValidated } from "@entity/presentation/component/entity-form/authority-manager/form/authority-manager-form";
import { inject, injectable } from "inversify";

@injectable()
export class EditAuthorityManagerMapper {
    constructor(
        @inject(PositionTypeEnumMapper)
        private readonly positionTypeEnumMapper: PositionTypeEnumMapper,
        @inject(MandateTerminationReasonEnumMapper)
        private readonly mandateTerminationReasonEnumMapper: MandateTerminationReasonEnumMapper,
    ) {}
    mapToDto(
        editAuthorityManager: EditAuthorityManager,
    ): EditAuthorityManagerBody {
        const position = editAuthorityManager.position.map((positionEnum) =>
            this.positionTypeEnumMapper.mapToDto(positionEnum),
        );
        let mandateTerminationReason = null;
        if (editAuthorityManager.mandateTerminationReason) {
            mandateTerminationReason =
                this.mandateTerminationReasonEnumMapper.mapToDto(
                    editAuthorityManager.mandateTerminationReason,
                );
        }

        const editAuthorityManagerBody: EditAuthorityManagerBody = {
            id: editAuthorityManager.id,
            entity: editAuthorityManager.entity,
            name: editAuthorityManager.name,
            position,
            appointment_date: convertEmptyToUndefined(
                editAuthorityManager.appointmentDate?.toISODate(),
            ),
            election_procedure: convertEmptyToUndefined(
                editAuthorityManager.electionProcedure,
            ),
            other_position: convertEmptyToUndefined(
                editAuthorityManager.otherPosition,
            ),
            mandate_termination_reason: convertEmptyToUndefined(
                mandateTerminationReason,
            ),
            other_mandate_termination_reason: convertEmptyToUndefined(
                editAuthorityManager.otherMandateTerminationReason,
            ),
            mandate_end_date: convertEmptyToUndefined(
                editAuthorityManager.terminationDate?.toISODate(),
            ),
        };
        return editAuthorityManagerBody;
    }
    mapFromModal(
        id: number,
        entityId: number,
        authorityManager: AuthorityManagerFormValuesValidated,
    ): EditAuthorityManager {
        const editAuthorityManager: EditAuthorityManager =
            new EditAuthorityManager(
                id,
                authorityManager.name,
                authorityManager.position,
                entityId,
                convertEmptyToUndefined(authorityManager.appointmentDate),
                convertEmptyToUndefined(authorityManager.electionProcedure),
                convertEmptyToUndefined(authorityManager.otherPosition),
                convertEmptyToUndefined(
                    authorityManager.mandateTerminationReason,
                ),
                convertEmptyToUndefined(
                    authorityManager.otherMandateTerminationReason,
                ),
                convertEmptyToUndefined(authorityManager.terminationDate),
            );
        return editAuthorityManager;
    }
}
