import { IdentificationType } from "@core/domain/models/identification-document-type.model";
import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { GetAllIdentificationDocumentTypesUseCase } from "@core/domain/usecases/get-all-identification-document-types.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateContactMapper } from "@entity/data/mappers/create-contact.mapper";
import { EditContactMapper } from "@entity/data/mappers/edit-contact.mapper";
import { CreateContactUseCase } from "@entity/domain/usecases/create-contact.usecase";
import { DeleteContactUseCase } from "@entity/domain/usecases/delete-contact.usecase";
import { EditContactUseCase } from "@entity/domain/usecases/edit-contact.usecase";
import { GetAllContactsUseCase } from "@entity/domain/usecases/get-all-contacts.usecase";
import { Contact } from "@proceeding/domain/models/contact.model";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { ContactFormValuesValidated } from "./form/contact.form";

@injectable()
export class ContactViewModel extends BaseViewModel {
    constructor(
        @inject(CreateContactUseCase)
        private readonly createContactUseCase: CreateContactUseCase,
        @inject(GetAllContactsUseCase)
        private readonly getAllContactsUseCase: GetAllContactsUseCase,
        @inject(EditContactUseCase)
        private readonly editContactUseCase: EditContactUseCase,
        @inject(DeleteContactUseCase)
        private readonly deleteContactUseCase: DeleteContactUseCase,
        @inject(CreateContactMapper)
        private readonly createContactMapper: CreateContactMapper,
        @inject(EditContactMapper)
        private readonly editContactMapper: EditContactMapper,
        @inject(GetAllIdentificationDocumentTypesUseCase)
        private readonly getAllIdentificationDocumentTypesUseCase: GetAllIdentificationDocumentTypesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    @observable
    initialLoading = true;

    @observable
    contacts: Contact[] = [];

    @observable
    addContactModalOpen = false;

    @observable
    editContactModalOpen = false;

    @observable
    contactToEdit: Undefinable<Contact> = undefined;

    @observable
    identificationTypes: IdentificationType[] = [];

    @computed
    get isLegalRepresentative(): Undefinable<Contact> {
        return this.contacts.find((contact) => contact.isLegalRepresentative);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    private async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllContacts(),
            this.getAllIdentificationDocumentTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllContacts(): Promise<void> {
        const contacts = await this.getAllContactsUseCase.execute();

        runInAction(() => {
            this.contacts = contacts;
        });
    }

    async reloadContacts(): Promise<void> {
        await this.getAllContacts();
    }

    @action
    handleAddContactModal(open: boolean): void {
        this.addContactModalOpen = open;
    }

    @action
    closeEditContactModal(): void {
        this.editContactModalOpen = false;
    }

    @action
    openEditContactModal(contact: Undefinable<Contact>): void {
        this.contactToEdit = contact;
        this.editContactModalOpen = true;
    }

    async addContact(
        contactToAdd: ContactFormValuesValidated,
    ): Promise<Nullable<Contact>> {
        LoadLayoutStore.start();

        const createdContactResult = await this.createContactUseCase.execute(
            this.createContactMapper.mapToContact(contactToAdd),
        );

        if (createdContactResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createdContactResult;
    }

    async editContact(
        contactToEdit: ContactFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();

        const editContactResult = await this.editContactUseCase.execute(
            this.editContactMapper.mapToContact(contactToEdit),
        );

        if (editContactResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async deleteContact(contactId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteContactUseCase.execute(contactId);
        LoadLayoutStore.finish();
    }

    async getAllIdentificationDocumentTypes(): Promise<void> {
        const identificationDocumentTypes =
            await this.getAllIdentificationDocumentTypesUseCase.execute();

        runInAction(() => {
            this.identificationTypes = identificationDocumentTypes;
        });
    }
}
