import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { ObjectiveRepository } from "@project/data/repositories/technical-proposal/objective.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DeleteObjectiveUseCase {
    constructor(
        @inject(ObjectiveRepository)
        private readonly objectiveRepository: ObjectiveRepository,
    ) {}

    async execute(objectiveId: number): Promise<boolean> {
        const result = await this.objectiveRepository.delete(objectiveId);

        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }

        return result.isRight();
    }
}
