import { PaginatedDto } from "@core/data/dto/paginated.dto";
import {
    IsDateString,
    IsNotEmpty,
    IsNumber,
    IsNumberString,
} from "class-validator";

export class ProjectEmployeeDedicationDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsNumberString()
    @IsNotEmpty()
    hours_dedicated!: string;

    @IsNumber()
    @IsNotEmpty()
    project_employee!: number;

    @IsDateString()
    @IsNotEmpty()
    start_date!: string;

    @IsDateString()
    @IsNotEmpty()
    end_date!: string;
}

export class ProjectEmployeeDedicationsDto extends PaginatedDto {
    results!: ProjectEmployeeDedicationDto[];
}
