import { EmployeeContract } from "@entity/domain/models/employee/contracts/employee/employee-contract.model";

export class CreateEmployeeContract {
    // eslint-disable-next-line max-params
    constructor(
        public type: EmployeeContract["type"],
        public startDate: EmployeeContract["startDate"],
        public hasDisability: EmployeeContract["hasDisability"],
        public professionalGroup: EmployeeContract["professionalGroup"],
        public contributionGroup: EmployeeContract["contributionGroup"],
        public weeklyWorkdayHours: EmployeeContract["weeklyWorkdayHours"],
        public netSalary: EmployeeContract["netSalary"],
        public irpf: EmployeeContract["irpf"],
        public socialSecurityContribution: EmployeeContract["socialSecurityContribution"],
        public monthlyCost: EmployeeContract["monthlyCost"],
        public socialSecurityContributionCompany: EmployeeContract["socialSecurityContributionCompany"],
        public totalCost: EmployeeContract["totalCost"],
        public deductibleAmountDisability: EmployeeContract["deductibleAmountDisability"],
        public employeeId: EmployeeContract["employeeId"],
        public disabilityType?: EmployeeContract["disabilityType"],
        public terminationReason?: EmployeeContract["terminationReason"],
        public otherType?: EmployeeContract["otherType"],
        public isSubsidized?: EmployeeContract["isSubsidized"],
        public subsidyDeductibleAmount?: EmployeeContract["subsidyDeductibleAmount"],
        public subsidyReason?: EmployeeContract["subsidyReason"],
        public otherSubsidyReason?: EmployeeContract["otherSubsidyReason"],
        public subsidyScope?: EmployeeContract["subsidyScope"],
        public endDate?: EmployeeContract["endDate"],
    ) {}
}
