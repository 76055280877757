import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { BudgetColumnType } from "@project/domain/models/budget/budget-column/budget-column-type.model";
import { CreateBudgetColumn } from "@project/domain/models/budget/budget-column/create-budget-column.model";
import { EditBudgetColumn } from "@project/domain/models/budget/budget-column/edit-budget-column.model";
import type { BudgetColumn } from "@project/domain/models/budget/financial-entity-budget.model";
import { BudgetColumns } from "@project/domain/models/budget/financial-entity-budget.model";
import { CreateBudgetColumnUseCase } from "@project/domain/usecases/budget/columns/create-budget-column.usecase";
import { DeleteBudgetColumnUseCase } from "@project/domain/usecases/budget/columns/delete-budget-column.usecase";
import { EditBudgetColumnUseCase } from "@project/domain/usecases/budget/columns/edit-budget-column.usecase";
import { GetAllBudgetColumnTypesUseCase } from "@project/domain/usecases/budget/columns/get-all-budget-column-types.usecase";
import { GetAllBudgetColumnsByBudgetIdUseCase } from "@project/domain/usecases/budget/columns/get-all-budget-columns-by-budget-id.usecase";
import { inject, injectable } from "inversify";
import { flow, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class BudgetColumnViewModel extends BaseViewModel {
    _budgetId: Nullable<number> = null;
    @observable
    columns: BudgetColumns = new BudgetColumns([], 0);

    @observable
    types: BudgetColumnType[] = [];

    @observable
    openAddBudgetColumnModal: boolean = false;

    @observable
    openEditBudgetColumnModal: boolean = false;

    @observable
    columnToEdit: Undefinable<BudgetColumn> = undefined;

    constructor(
        @inject(GetAllBudgetColumnsByBudgetIdUseCase)
        private readonly getBudgetColumnsByBudgetIdUseCase: GetAllBudgetColumnsByBudgetIdUseCase,
        @inject(GetAllBudgetColumnTypesUseCase)
        private readonly getAllBudgetColumnTypesUseCase: GetAllBudgetColumnTypesUseCase,
        @inject(CreateBudgetColumnUseCase)
        private readonly createBudgetColumnUseCase: CreateBudgetColumnUseCase,
        @inject(DeleteBudgetColumnUseCase)
        private readonly deleteBudgetColumnUseCase: DeleteBudgetColumnUseCase,
        @inject(EditBudgetColumnUseCase)
        private readonly editBudgetColumnUseCase: EditBudgetColumnUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getBudgetColumns();
        const types = await this.getAllBudgetColumnTypesUseCase.execute();
        runInAction(() => {
            this.types = types;
        });
    }

    async getBudgetColumns(): Promise<void> {
        if (!this._budgetId) {
            return;
        }
        const columns = await this.getBudgetColumnsByBudgetIdUseCase.execute(
            this._budgetId,
        );
        runInAction(() => {
            this.columns = columns;
        });
    }
    handleOpenAddBudgetColumnModal(): void {
        runInAction(() => {
            this.openAddBudgetColumnModal = true;
        });
    }
    handleCloseAddBudgetColumnModal(): void {
        runInAction(() => {
            this.openAddBudgetColumnModal = false;
        });
    }
    @flow
    async *handleAddBudgetColumn(
        values: CreateBudgetColumn,
    ): AsyncGenerator<void> {
        LoadLayoutStore.start();
        if (this._budgetId) {
            const response = await this.createBudgetColumnUseCase.execute({
                type: values.type,
                name: values.name,
                budget: this._budgetId,
            });
            if (response) {
                ToastManagerStore.success();
            }
        }
        await this.getBudgetColumns();
        LoadLayoutStore.finish();
        this.handleCloseAddBudgetColumnModal();
    }
    async handleDeleteBudgetColumn(id: number): Promise<void> {
        LoadLayoutStore.start();
        const response = await this.deleteBudgetColumnUseCase.execute(id);
        if (response) {
            await this.getBudgetColumns();
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    handleOpenEditBudgetColumnModal(column: BudgetColumn): void {
        runInAction(() => {
            this.openEditBudgetColumnModal = true;
            this.columnToEdit = column;
        });
    }
    handleCloseEditBudgetColumnModal(): void {
        runInAction(() => {
            this.openEditBudgetColumnModal = false;
        });
    }
    async handleEditBudgetColumn(values: EditBudgetColumn): Promise<void> {
        LoadLayoutStore.start();
        if (this.columnToEdit && this._budgetId) {
            const response = await this.editBudgetColumnUseCase.execute({
                type: values.type,
                name: values.name,
                budget: this._budgetId,
                id: this.columnToEdit.id,
            });
            if (response) {
                ToastManagerStore.success();
            }
        }
        await this.getBudgetColumns();
        this.handleCloseEditBudgetColumnModal();
        LoadLayoutStore.finish();
    }
}
