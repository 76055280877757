import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { CreateBudgetCategory } from "@project/domain/models/budget/budget-category/create-budget-category.model";
import { BudgetCategory } from "@project/domain/models/budget/financial-entity-budget.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreateBudgetCategoryUseCase {
    constructor(
        @inject(BudgetRepository)
        private readonly budgetRepository: BudgetRepository,
    ) {}
    async execute(
        budgetCategory: CreateBudgetCategory,
    ): Promise<Nullable<BudgetCategory>> {
        const result =
            await this.budgetRepository.createCategory(budgetCategory);
        if (result.isLeft()) {
            ToastManagerStore.error(result.getLeftOrThrow().message);
        }
        return result.fold(
            () => null,
            (createdBudgetCategory) => createdBudgetCategory,
        );
    }
}
