import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum MaterialTypeEnumDto {
    Software = "software",
    HumanResources = "human_resources",
    Subcontracting = "subcontracting",
    MaterialResources = "material_resources",
    MethodologicalResources = "methodological_resources",
    TechnologicalResources = "technological_resources",
    Others = "others",
}

export class MaterialTypeDto {
    @IsEnum(MaterialTypeEnumDto)
    @IsNotEmpty()
    key!: MaterialTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
