import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { ExpenseType } from "../models/economic-data/expense/expense-type.model";
@injectable()
export class GetAllExpenseTypesUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}
    async execute(): Promise<ExpenseType[]> {
        const expenseTypesResult =
            await this.beneficiaryRepository.getAllExpenseTypes();
        if (expenseTypesResult.isLeft()) {
            ToastManagerStore.error(
                expenseTypesResult.getLeftOrThrow().message,
            );
        }
        return expenseTypesResult.getOrElse([]);
    }
}
