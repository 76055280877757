import { Nullable } from "@core/domain/types/nullable.type";
import { AuditoryDto } from "@entity/data/dto/quality-framework/auditory/auditory.dto";
import { Auditory } from "@entity/domain/models/quality-framework/auditory/auditory.model";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class AuditoryMapper {
    map(auditoryDto: AuditoryDto): Nullable<Auditory> {
        const errors = validateSync(auditoryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        return new Auditory(
            auditoryDto.id,
            auditoryDto.certification_entity,
            auditoryDto.year,
            auditoryDto.entity,
        );
    }

    mapToDto(auditory: Auditory): AuditoryDto {
        const auditoryDto: AuditoryDto = {
            id: auditory.id,
            certification_entity: auditory.certificationEntity,
            year: auditory.year,
            entity: auditory.entity,
        };

        return auditoryDto;
    }
}
