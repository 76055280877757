import { Pagination } from "@core/domain/models/pagination";
import { inject, injectable } from "inversify";
import { ProceedingRepository } from "../../data/repositories/proceeding.repository";
import { ProceedingSearchFilters } from "../models/proceeding.model";
import { ProceedingsSummary } from "../models/proceedings-sumary.model";

@injectable()
export class GetProceedingsByUseCase {
    constructor(
        @inject(ProceedingRepository)
        private readonly proceedingRepository: ProceedingRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: ProceedingSearchFilters,
    ): Promise<ProceedingsSummary> {
        const proceedingsResults = await this.proceedingRepository.getAllBy(
            pagination,
            filters,
        );

        return proceedingsResults.fold(
            () => new ProceedingsSummary([], 0),
            (proceedingsSummary) => proceedingsSummary,
        );
    }
}
