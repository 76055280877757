import { Pagination } from "@core/domain/models/pagination";
import { SupplierRepository } from "@entity/data/repositories/supplier.repository";
import {
    SupplierSearchFilters,
    Suppliers,
} from "@entity/domain/models/supplier/suppliers.model";
import { inject, injectable } from "inversify";

@injectable()
export class GetSuppliersByUseCase {
    constructor(
        @inject(SupplierRepository)
        private readonly supplierRepository: SupplierRepository,
    ) {}

    async execute(
        pagination: Pagination,
        filters?: SupplierSearchFilters,
    ): Promise<Suppliers> {
        const supplierResult = await this.supplierRepository.getBy(
            pagination,
            filters,
        );

        return supplierResult.fold(
            () => new Suppliers([], 0),
            (suppliers) => suppliers,
        );
    }
}
