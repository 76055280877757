import { DocumentRepository } from "@core/data/repositories/document.repository";
import { inject, injectable } from "inversify";

@injectable()
export class DownloadDocumentUseCase {
    constructor(
        @inject(DocumentRepository)
        private readonly documentRepository: DocumentRepository,
    ) {}

    /**
     *
     * @param documentUrl
     * @param name: will override original file name
     */
    async execute(documentUrl: string, name?: string): Promise<void> {
        await this.documentRepository.download(documentUrl, name);
    }
}
