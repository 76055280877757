import { FinancialEntityTypeEnum } from "@project/domain/models/financial-entity/financial-entity-type";

export class FinancialEntity {
    constructor(
        public readonly id: number,
        public name: string,
        public type: FinancialEntityTypeEnum,
        public isActive: boolean,
        public description: string,
    ) {}
}

export class FinancialEntities {
    constructor(
        public readonly financialEntities: FinancialEntity[],
        public count: number,
    ) {}
}
